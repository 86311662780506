import { Col, Row } from 'antd'
import R from 'app/assets/R'
import { AppointmentProvider } from 'app/context/AppointmentContext'
import { PageAppointmentProvider } from 'app/context/PageAppointmentContext'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SidebarRight from './SideBarRight'
import SidebarLeft from './SidebarLeft'
import './index.css'
import { HeadPageWrapper, HeadingTitlePage, RootWrapper } from './styles'
import { PatientProvider } from 'app/context/PatientContext'
import { DATE } from 'app/common/constants'
import { ItemCategoryProvider } from 'app/context/ItemCategoryContext'
import { ItemProvider } from 'app/context/ItemContext'

const Appointment = () => {
  const { t } = useTranslation()
  const [view, setView] = useState('week')
  const [selectedDate, setSelectedDate] = useState(
    moment().format(DATE.YYYY_MM_DD),
  )

  const onSelect = value => {
    setSelectedDate(value.format(DATE.YYYY_MM_DD))
  }

  return (
    <PageAppointmentProvider>
      <AppointmentProvider>
        <PatientProvider>
          <ItemCategoryProvider>
            <ItemProvider>
              <RootWrapper className="create-appointment">
                <HeadPageWrapper justify={'space-between'} align={'middle'}>
                  <HeadingTitlePage>
                    {t(R.strings.customer_care)}
                  </HeadingTitlePage>
                </HeadPageWrapper>
                <Row>
                  <Col span={6}>
                    <SidebarLeft
                      onSelect={onSelect}
                      selectedDate={selectedDate}
                    />
                  </Col>
                  <Col span={18}>
                    <SidebarRight
                      selectedDate={selectedDate}
                      view={view}
                      setView={setView}
                      setSelectedDate={setSelectedDate}
                    />
                  </Col>
                </Row>
              </RootWrapper>
            </ItemProvider>
          </ItemCategoryProvider>
        </PatientProvider>
      </AppointmentProvider>
    </PageAppointmentProvider>
  )
}

export default Appointment
