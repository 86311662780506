import R from 'app/assets/R'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { useModalHook } from './hook'
import { IPropsDeleteDegree } from './type'
import { BaseCol } from 'app/components/common/BaseCol'
import * as S from './styles'

export const Delete = (props: IPropsDeleteDegree) => {
  const { visible, isLoading, showModal, handleCancel, handleSubmit, t } =
    useModalHook(props)

  return (
    <BaseModalManagement
      isLoading={isLoading}
      typeButtonOpen="delete"
      titleKeyI18n={R.strings.delete}
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.delete}
      confirmKeyI18n={R.strings.delete}
      handleSubmit={handleSubmit}
      visible={visible}
      widthModal={500}
      descriptionDelete={
        <>
          <BaseCol>
            <span>
              {t(R.strings.degree_content_are_you_want_delete)}{' '}
              <S.TextDegree>{props?.name ?? ''}</S.TextDegree>
            </span>
          </BaseCol>
          <BaseCol>
            {t(
              R.strings
                .degree_content_once_the_degree_is_locked_it_cannot_be_recovered,
            )}
          </BaseCol>
        </>
      }
    />
  )
}
