import { BaseRow } from 'app/components/common/BaseRow'
import { Loading } from 'app/components/common/Loading'

const LoadingPage = () => {
  return (
    <BaseRow
      style={{ width: '100%', height: '100vh' }}
      justify={'center'}
      align={'middle'}
    >
      <Loading />
    </BaseRow>
  )
}
export default LoadingPage
