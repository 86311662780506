import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import {
  AppointmentLayout,
  CallCenterLayout,
  CallScheduleLayout,
  DentalHealthHistoryLayout,
  EmergencyContactLayout,
  PatientJourneyLayout,
  PaymentHistoryLayout,
  RelativesLayout,
  TotalInfoPaymentLayout,
  TransactionHistoryLayout,
  TreatmentScheduleLayout,
} from './layouts'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

const TreatmentSchedule = () => {
  const { t } = useTranslation()
  return (
    <SectionBase>
      <BaseSpace>
        <TotalInfoPaymentLayout />
        <Tabs
          defaultActiveKey={R.strings.treatment_schedule}
          tabPosition={'top'}
          items={[
            {
              label: t(R.strings.treatment_schedule),
              key: R.strings.treatment_schedule,
              children: <TreatmentScheduleLayout />,
            },
            {
              label: t(R.strings.patient_journey),
              key: R.strings.patient_journey,
              children: <PatientJourneyLayout />,
            },
            {
              label: t(R.strings.emergency_contact),
              key: R.strings.emergency_contact,
              children: <EmergencyContactLayout />,
            },
            {
              label: t(R.strings.next_of_kin),
              key: R.strings.next_of_kin,
              children: <RelativesLayout />,
            },
            {
              label: t(R.strings.appointment),
              key: R.strings.appointment,
              children: <AppointmentLayout />,
            },
            {
              label: t(R.strings.call_schedule),
              key: R.strings.call_schedule,
              children: <CallScheduleLayout />,
            },
            {
              label: t(R.strings.call_center),
              key: R.strings.call_center,
              children: <CallCenterLayout />,
            },
            {
              label: t(R.strings.dental_health_history),
              key: R.strings.dental_health_history,
              children: <DentalHealthHistoryLayout />,
            },
            {
              label: t(R.strings.transaction_history),
              key: R.strings.transaction_history,
              children: <TransactionHistoryLayout />,
            },
            {
              label: t(R.strings.payment_history),
              key: R.strings.payment_history,
              children: <PaymentHistoryLayout />,
            },
          ]}
        />
      </BaseSpace>
    </SectionBase>
  )
}

export default TreatmentSchedule
