// Message code define
export const ItemMessageCode = {
  ItemManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20000',
  },
}

// Endpoint
export const ItemEndPoint = {
  ItemManagement: {
    request: 'treatment/v1/items',
  },
}
