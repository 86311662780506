import { SearchOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const FilterLayout = () => {
  const { t } = useTranslation()

  const defaultOptions = useMemo(() => {
    return [
      {
        label: t(R.strings.all),
        value: R.strings.all,
      },
      {
        label: 'A',
        value: 'A',
      },
      {
        label: 'B',
        value: 'B',
      },
    ]
  }, [t])

  return (
    <BaseForm>
      <RootWrapper size={0}>
        <BaseRow gutter={[16, 8]} align={'middle'}>
          <BaseCol xl={12}>
            <BaseFormItem hiddenLabel>
              <BaseInput
                placeholder={t(R.strings.enter_job_title)}
                prefix={<SearchOutlinedIcon rev={undefined} />}
              />
            </BaseFormItem>
          </BaseCol>
          <BaseCol xl={12}>
            <BaseRow gutter={[16, 8]} align={'middle'}>
              <BaseCol xl={12}>
                <BaseFormItem hiddenLabel>
                  <SelectBaseStyled
                    $prefix={t(R.strings.department)}
                    $width={90}
                    maxTagCount={0}
                    placeholder={t(R.strings.select_department)}
                    defaultValue={R.strings.all}
                    value={R.strings.all}
                    options={defaultOptions}
                  />
                </BaseFormItem>
              </BaseCol>

              <BaseCol xl={12}>
                <BaseFormItem hiddenLabel>
                  <SelectBaseStyled
                    $prefix={t(R.strings.approve_type)}
                    $width={90}
                    maxTagCount={0}
                    placeholder={t(R.strings.select_approve_type)}
                    defaultValue={R.strings.all}
                    value={R.strings.all}
                    options={defaultOptions}
                  />
                </BaseFormItem>
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[16, 8]} align={"middle"}>
          <BaseCol xl={12}>
            <BaseFormItem hiddenLabel>
              <SelectBaseStyled
                $prefix={t(R.strings.sender_job_title)}
                $width={150}
                maxTagCount={0}
                placeholder={t(R.strings.select_sender_job_title)}
                defaultValue={R.strings.all}
                value={R.strings.all}
                options={defaultOptions}
              />
            </BaseFormItem>
          </BaseCol>

          <BaseCol xl={12}>
            <BaseFormItem hiddenLabel>
              <SelectBaseStyled
                $prefix={t(R.strings.approver_job_title)}
                $width={170}
                maxTagCount={0}
                placeholder={t(R.strings.select_approver_job_title)}
                defaultValue={R.strings.all}
                value={R.strings.all}
                options={defaultOptions}
              />
            </BaseFormItem>
          </BaseCol>
        </BaseRow>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`

const SearchOutlinedIcon = styled(SearchOutlined)`
  opacity: 0.5;
`

export const SelectBaseStyled = styled(BaseSelect)<{
  $prefix: string
  $width?: number
}>`
  width: 100%;
  .ant-select-selector::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$width ?? 70}px;
    align-items: center;
    display: flex;
  }
`
