import * as S from 'app/components/tables/BaseTableManagement/styles'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

import {
  ButtonExcel,
  BuyBox,
  CustomSelectSearch,
  FormItemUser,
  InputBox,
  RowLine,
} from './styles'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { PurchaseOrderStatusEnum, StatusTableEnum } from 'app/common/enum'
import { useHook } from './hook'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { Option } from 'app/components/common/selects/BaseSelect'
import { useContext, useEffect, useState } from 'react'
import { ProfileContext } from 'app/context/ProfileContext'
import { IUser } from 'app/model/user.model'
import { useAppSelector } from 'app/redux/hooks'
import {
  DropdownRender,
  SearchOutlinedStyled,
  SearchWrapper,
} from 'app/components/common/selects/SelectSearch/styles'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {  IWarehouse } from 'app/api/inventory-new/model/management'
import {
  requestDetailWarehousePurchaseManagement,
  requestGetWarehouseManagement,
} from 'app/api/inventory-new'
import { get } from 'lodash'
import { useParams } from 'react-router'
import { SvgDownloadIcon } from 'app/assets/svg-assets'
import { WarehouseGoodsReceiptContext } from '../../context'
import { SelectInputSearch } from 'app/containers/InventoryManagement/WarehousePurchase/components/FilterManagementTable/styles'
import dayjs from 'dayjs'
import { notificationController } from 'app/controllers/notification-controller'

export type StatusWarehouseGoodsReceiptType = keyof typeof StatusTableEnum

const BuyInfo = () => {
  const { t } = useTranslation()
  const { rules, downloadTemplate, isDownloading } = useHook()
  const params = useParams()
  const [searchUser, setSearchUser] = useState('')
  const { fetchData: fetchUsers, profiles: users } = useContext(ProfileContext)
  const profile = useAppSelector(state => state.profileSlice)?.profile
  const {
    warehouseGoodsReceiptDetail,
    form,
    setWarehouseGoodsReceiptDetail,
    setPurchaseOrders,
  } = useContext(WarehouseGoodsReceiptContext)
  const [warehouses, setWarehouses] = useState<IWarehouse[]>([])
  const [warehouseKeyword, setWarehouseKeyword] = useState('')

  useEffect(() => {
    const timer = setTimeout(async () => {
      const response = await requestGetWarehouseManagement({
        keyword: warehouseKeyword,
      })
      const data: IWarehouse[] = get(response, 'data', [])
      setWarehouses(data)
    }, 500)
    return () => clearTimeout(timer)
  }, [warehouseKeyword])

  const getWarehousePurchaseDetail = async () => {
    try {
      const { data } = await requestDetailWarehousePurchaseManagement(
        params?.id,
      )

      const orders = data?.orders?.map(order => ({
        ...order,
        name: order?.product?.name,
        code: order?.product?.code,
        purchaseMeasurementUnit: order?.measurementUnit?.name,
        measurementUnitId: order?.measurementUnitId,
      }))

      setPurchaseOrders?.(orders)
      await form?.setFieldsValue({
        ...data,
        paymentDueDate: dayjs(data?.paymentDueDate),
        estimateDeliveryDate: dayjs(data?.estimateDeliveryDate),
      })

      setWarehouseGoodsReceiptDetail?.({
        ...data,
        paymentDueDate: dayjs(data?.paymentDueDate),
        estimateDeliveryDate: dayjs(data?.estimateDeliveryDate),
      })
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }
  }

  useEffect(() => {
    getWarehousePurchaseDetail()
  }, [])

  useEffect(() => {
    fetchUsers?.({
      page: 0,
      pageSize: 20,
      sort: '-1',
      keyword: searchUser,
    })
  }, [searchUser])

  const usersWithPersonProfiles = users.concat(profile)

  const isDisabled = !!(
    params?.id &&
    PurchaseOrderStatusEnum.DRAFT !==
      Number(warehouseGoodsReceiptDetail?.status)
  )
  return (
    <BuyBox>
      <BaseForm
        layout="vertical"
        requiredMark="optional"
        initialValues={{
          purchaserId: profile?._id,
          isSentToSupplier: true,
        }}
        form={form}
      >
        <BaseRow gutter={20} align={'top'} justify={'space-between'}>
          <BaseCol xl={12}>
            <InputBox>
              <S.FormItem
                name="code"
                label={t(R.strings.buy_ticket)}
                rules={[
                  {
                    required: true,
                    message: t(R.strings.buy_ticket),
                  },
                ]}
                required
              >
                <S.FormInput
                  placeholder={t(R.strings.enter_field, {
                    field: t(R.strings.buy_ticket),
                  })}
                  disabled={isDisabled}
                  addonAfter={
                    <>
                      <ButtonExcel
                        icon={<SvgDownloadIcon />}
                        loading={isDownloading}
                        onClick={async () => await downloadTemplate()}
                      >
                        {t(R.strings.excel)}
                      </ButtonExcel>
                    </>
                  }
                />
              </S.FormItem>
            </InputBox>
          </BaseCol>
          <BaseCol xl={12}>
            <S.FormItem
              name="warehouseReceiveId"
              label={t(R.strings.warehouse_import)}
              rules={rules.warehouseReceiveId}
              required
            >
              <SelectInputSearch
                placeholder={t(R.strings.select)}
                // onChange={value =>
                //   setFilter(prev => ({
                //     ...prev,
                //     warehouseId: value,
                //   }))
                // }
                filterOption={false}
                dropdownRender={menu => (
                  <DropdownRender>
                    <SearchWrapper>
                      <SearchOutlinedStyled
                        className="search__icon"
                        rev=""
                        size={20}
                      />
                      <BaseInput
                        className="search__input"
                        placeholder={t(R.strings.search)}
                        onChange={e => setWarehouseKeyword(e?.target?.value)}
                        allowClear
                      />
                    </SearchWrapper>
                    {menu}
                  </DropdownRender>
                )}
                allowClear
                disabled={isDisabled}
              >
                {warehouses?.map((warehouse, index) => {
                  return (
                    <Option value={warehouse?._id} key={index}>
                      {warehouse?.name}
                    </Option>
                  )
                })}
              </SelectInputSearch>
            </S.FormItem>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={20} align={'top'} justify={'space-between'}>
          <BaseCol xl={12}>
            <FormItemUser>
              <S.FormItem
                name="purchaserId"
                label={t(R.strings.purchaser)}
                rules={rules.purchaserId}
                required
              >
                <CustomSelectSearch
                  allowClear
                  onSearch={keyword => setSearchUser(keyword)}
                  options={usersWithPersonProfiles.map((user: IUser) => ({
                    value: String(user?._id),
                    name: user?.name,
                    avatar: user?.avatar,
                    description:
                      user?.jobTitleName?.replaceAll(',', ' &') || '',
                  }))}
                  optionLabelProp=""
                  disabled={isDisabled}
                />
              </S.FormItem>
            </FormItemUser>
          </BaseCol>
          <BaseCol xl={12}>
            <FormItemUser>
              <S.FormItem
                name="receiverId"
                label={t(R.strings.receiver)}
                rules={rules.receiverId}
                required
              >
                <CustomSelectSearch
                  allowClear
                  onSearch={keyword => setSearchUser(keyword)}
                  options={users.map((user: IUser) => ({
                    value: String(user?._id),
                    name: user?.name,
                    avatar: user?.avatar,
                    description:
                      user?.jobTitleName?.replaceAll(',', ' &') || '',
                  }))}
                  optionLabelProp=""
                  disabled={isDisabled}
                />
              </S.FormItem>
            </FormItemUser>
          </BaseCol>
        </BaseRow>
        <RowLine />
        {/* <S.FormItem name="reason" label={t(R.strings.goods_receipt_reason)}>
          <S.FormInput.TextArea
            placeholder={t(R.strings.enter_field, {
              field: t(R.strings.goods_receipt_reason),
            })}
            disabled={isDisabled}
          />
        </S.FormItem> */}

        <S.FormItem name="note" label={t(R.strings.note)}>
          <S.FormInput.TextArea
            placeholder={t(R.strings.enter_field, {
              field: t(R.strings.note),
            })}
            disabled={isDisabled}
          />
        </S.FormItem>
      </BaseForm>
    </BuyBox>
  )
}

export default BuyInfo
