import { useCreateUpdateServiceContext } from '../../context'

export const useHook = () => {
  const { productProperties, isLoadingProductProperty, rules, form } =
    useCreateUpdateServiceContext()

  const mapProductProperties = []
  productProperties?.map(item => {
    mapProductProperties[item._id ?? ''] = item.name
  })

  const productPropertiesChildren = productProperties?.flatMap(
    prop => prop.productPropertiesChildren,
  )

  const onCloseCollapse = (id: string): boolean => {
    if (!id) {
      console.warn('Missing id collapse')
      return false
    }

    const expandCollapse = document.querySelector<HTMLDivElement>(
      `.${id} .ant-collapse-expand-icon`,
    )

    if (!expandCollapse) {
      console.warn('Missing expandCollapse')
      return false
    }

    expandCollapse.click()

    return true
  }
  return {
    rules,
    productProperties,
    isLoadingProductProperty,
    productPropertiesChildren,
    mapProductProperties,
    onCloseCollapse,
    form,
  }
}
