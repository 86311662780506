import { ApiClientTreatment } from 'app/service/api-service'
import { ProductPropertyEndPoint } from './constant'
import {
  IPayloadCreateProductPropertyManagement,
  IPayloadUpdateProductPropertyManagement,
} from './model/management'

export const requestGetProductPropertyManagement = () =>
  ApiClientTreatment.Get({
    url: ProductPropertyEndPoint.ProductPropertyManagement.request,
    params: {},
  })

export const requestCreateProductPropertyManagement = (
  body: IPayloadCreateProductPropertyManagement,
) =>
  ApiClientTreatment.Post({
    url: ProductPropertyEndPoint.ProductPropertyManagement.request,
    body,
  })

export const requestUpdateProductPropertyManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateProductPropertyManagement
}) =>
  ApiClientTreatment.Put({
    url: `${ProductPropertyEndPoint.ProductPropertyManagement.request}/${id}`,
    body,
  })

export const requestDetailProductPropertyManagement = (id?: string | number) =>
  ApiClientTreatment.Get({
    url: `${ProductPropertyEndPoint.ProductPropertyManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteProductPropertyManagement = (id?: string | number) =>
  ApiClientTreatment.Delete({
    url: `${ProductPropertyEndPoint.ProductPropertyManagement.request}/${id}`,
    body: {},
  })

export const requestGetAllProductPropertyManagement = () =>
  ApiClientTreatment.Get({
    url: `${ProductPropertyEndPoint.ProductPropertyManagement.request}/get-all`,
    params: {},
  })
