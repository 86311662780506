import { Select as AntSelect } from 'antd'
import { RefSelectProps } from 'antd/lib/select'
import { Dimension } from 'parkway-web-common'
import React, { ComponentProps } from 'react'
import * as S from './styles'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'

export const { Option } = AntSelect

export interface BaseSelectProps extends ComponentProps<typeof AntSelect> {
  width?: Dimension
  shadow?: boolean
  className?: string

  $prefix?: string
  $prefixWidth?: number
}

export const BaseSelect = React.forwardRef<RefSelectProps, BaseSelectProps>(
  ({ className, width, shadow, children, placeholder, ...props }, ref) => (
    <S.Select
      getPopupContainer={triggerNode => triggerNode}
      ref={ref}
      className={className}
      $width={width}
      $shadow={shadow}
      placeholder={<S.Placeholder>{placeholder}</S.Placeholder>}
      suffixIcon={<SvgTableCloseIcon />}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '')
          .toLowerCase()
          .localeCompare((optionB?.label ?? '').toLowerCase())
      }
      {...props}
    >
      {children}
    </S.Select>
  ),
)
