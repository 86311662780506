import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import R from 'app/assets/R'
import { AppointmentStatusCancel, ListAppointmentStatus } from 'app/constant'
import { Moment } from 'moment'
import React, { useEffect } from 'react'
import { IArea, IClinic } from '../../../SidebarLeft/type'
import { IListAppointmentStatus, IService } from '../../../type'
import './index.css'
import { CustomSelectSearch, NoteBlock, NumberCharacter } from './styles'
import { NOTE_MAX_LENGTH } from 'app/common/config'
import { BaseSelectSearch } from 'app/components/common/BaseSelectSearch'
import { IPatient } from 'app/api/patient/model/patient'
import _ from 'lodash'
import { DATE } from 'app/common/constants'
import { IFormModal, PageType } from './type'
import { ITreatment } from 'app/containers/Accounting/ItemManagement/model/treatment'
import { transformDataSelectTree } from 'app/components/common/selects/BaseTreeSelect/helper'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseTreeSelect } from 'app/components/common/selects/BaseTreeSelect'
import { PHONE_REGEX } from 'parkway-web-common'

// eslint-disable-next-line @typescript-eslint/no-explicit-any

export enum PATIENT_TYPE {
  NEW = 1,
  UPDATE = 2,
}
const { Option } = Select
const FormModal: React.FC<IFormModal> = props => {
  const {
    handleOk,
    form,
    newAppointment,
    onSelectPatient,
    setSearchPatient,
    patients,
    t,
    handleArea,
    units,
    clinic,
    handleCategoryService,
    categoryServices = [],
    handleService,
    setSearchDoctor,
    doctors,
    services,
    disabledDate,
    timeslots,
    setNote,
    note,
    submitButton,
    treatments,
    onChangeClinic,
    required = true,
    patientProfiles,
    onSelectPatientProfile,
    setPhone,
    phone,
  } = props

  const treeData = transformDataSelectTree(categoryServices, {
    title: 'name',
    value: '_id',
  })

  const rules = {
    areaId: [
      {
        required,
        message: t(R.strings.area_label_required),
      },
    ],
    clinicId: [
      {
        required,
        message: t(R.strings.clinic_label_required),
      },
    ],
    categoryServiceId: [
      {
        required,
        message: t(R.strings.category_service_label_required),
      },
    ],
    doctorId: [
      {
        required,
        message: t(R.strings.doctor_label_placeholder),
      },
    ],
    services: [
      {
        required,
        message: t(R.strings.service_label_required),
      },
    ],
    reason: [
      {
        required,
        message: t(R.strings.reason_label_required),
      },
    ],
    date: [
      {
        required,
        message: t(R.strings.day_label_placeholder),
      },
    ],
    time: [
      {
        required,
        message: t(R.strings.time_label_placeholder),
      },
    ],
    duration: [
      {
        required,
        message: t(R.strings.duration_label_placeholder),
      },
    ],
    scheduleStatus: [
      {
        required,
        message: t(R.strings.status_label_placeholder),
      },
    ],
  }

  useEffect(() => {
    console.log("form?.getFieldValue('patientId')", form?.getFieldValue('patientId'))
    if (!newAppointment?.patient?._id || !patientProfiles?.length) {
      form?.setFieldValue('patientId', PATIENT_TYPE.NEW)
    } else {
      form?.setFieldValue('patientId', newAppointment?.patient?._id)
    }
  }, [newAppointment, phone, patientProfiles])

  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleOk}
        autoComplete="off"
        form={form}
        initialValues={newAppointment}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label={t(R.strings.patient_name)}
              name="fullName"
              rules={[
                {
                  required: true,
                  message: t(R.strings.patient_name_required),
                },
              ]}
            >
              <CustomSelectSearch
                mode="tags"
                onChange={value => onSelectPatient(value)}
                onSearch={keyword => setSearchPatient(keyword)}
                placeholder={t(R.strings.patient_name_placeholder)}
                options={(patients || [])?.map((patient: IPatient) => ({
                  value: String(patient?._id),
                  name: String(patient?.name),
                  avatar: String(patient?.avatar),
                  description: String(patient?.phone),
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t(R.strings.phone_label)}
              name="phone"
              rules={[
                {
                  required: true,
                  message: t(R.strings.phone_label_required),
                },
                {
                  pattern: PHONE_REGEX,
                  message: t(R.strings.regex_phone),
                },
              ]}
            >
              <Input
                placeholder={t(R.strings.phone_label_placeholder)}
                onChange={e => setPhone(String(e?.target?.value))}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="patientId"
              label={t(R.strings.patient_profile)}
              rules={[
                {
                  required: true,
                  message: t(R.strings.patient_profile_required),
                },
              ]}
            >
              <Select
                placeholder={t(R.strings.select_patient_record)}
                onChange={value => onSelectPatientProfile([value])}
                allowClear
              >
                <Option
                  value={PATIENT_TYPE.NEW}
                  key={R.strings.new_patient_profile}
                >
                  {t(R.strings.new_patient_profile)}
                </Option>

                {patientProfiles?.map((patient: IPatient, key) => (
                  <Option value={patient?._id} key={key}>
                    {patient?.id} {patient?.id && '-'} {patient?.name} -
                    {patient?.phone}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="areaId"
              label={t(R.strings.area_label)}
              rules={rules?.areaId}
            >
              <Select
                placeholder={t(R.strings.area_label_placeholder)}
                onChange={value => handleArea(value)}
                allowClear
                disabled={
                  props?.page === PageType.APPOINTMENT && !newAppointment?._id
                }
              >
                {units?.map((area: IArea, key) => (
                  <Option value={area?._id} key={key}>
                    {area?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="clinicId"
              label={t(R.strings.clinic_label)}
              rules={rules?.areaId}
            >
              <Select
                placeholder={t(R.strings.clinic_label_placeholder)}
                onChange={onChangeClinic}
                allowClear
                disabled={
                  props?.page === PageType.APPOINTMENT && !newAppointment?._id
                }
              >
                {clinic?.map((c: IClinic, key) => (
                  <Option value={c?._id} key={key}>
                    {c?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="categoryServiceId"
              label={t(R.strings.category_service_label)}
              rules={rules?.categoryServiceId}
            >
              <BaseTreeSelect
                placeholder={t(
                  R.strings.item_management_content_select_category,
                )}
                suffixIcon={<SvgTableCloseIcon />}
                treeData={treeData}
                treeDefaultExpandAll
                allowClear
                onChange={value => handleCategoryService(value)}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="doctorId"
              label={t(R.strings.doctor_label)}
              rules={rules?.doctorId}
            >
              <BaseSelectSearch
                placeholder={t(R.strings.doctor_label_placeholder)}
                onSearch={keyword => setSearchDoctor(keyword)}
                disabled={
                  props?.page === PageType.APPOINTMENT && !newAppointment?._id
                }
                options={(doctors || [])?.map((doctor) => ({
                  value: String(doctor?._id),
                  name: doctor?.name,
                  avatar: doctor?.avatar,
                  description:
                    doctor?.jobTitleName?.replaceAll(',', ' &') || '',
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="services"
              label={t(R.strings.service_label)}
              rules={rules?.services}
            >
              <Select
                placeholder={t(R.strings.service_label_placeholder)}
                onChange={value => handleService(value)}
                allowClear
              >
                {services?.map((service: IService, key) => (
                  <Option value={service?._id} key={key}>
                    {service?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="treatments" label={t(R.strings.treatment_label)}>
              <Select
                placeholder={t(R.strings.treatment_label_placeholder)}
                allowClear
              >
                {treatments?.map((treatment: ITreatment, key) => (
                  <Option value={treatment?._id} key={key}>
                    {treatment?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t(R.strings.reason_label)}
              name="reason"
              rules={rules?.reason}
            >
              <Input placeholder={t(R.strings.reason_label_placeholder)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t(R.strings.day_label)}
              name="date"
              rules={rules?.date}
            >
              <DatePicker disabledDate={disabledDate} />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              name="time"
              label={t(R.strings.time_label)}
              rules={rules?.time}
            >
              <Select
                placeholder={t(R.strings.time_label_placeholder)}
                // onChange={onGenderChange}
                allowClear
              >
                {timeslots?.map((m: Moment, key) => (
                  <Option value={m.format(DATE.LT)} key={key}>
                    {m
                      .format(DATE.LT_A)
                      .replace(DATE.SA, t(R.strings.SA))
                      .replace(DATE.CH, t(R.strings.CH))}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              className="duration"
              name="duration"
              label={t(R.strings.duration_label)}
              rules={rules?.duration}
            >
              <Select
                placeholder={t(R.strings.duration_label_placeholder)}
                // onChange={onGenderChange}
                allowClear
              >
                {_.range(15, 121, 15).map((m: number, key) => (
                  <Option value={m} key={key}>
                    {m}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {newAppointment?._id && props?.page === PageType.APPOINTMENT && (
            <Col span={24}>
              <Form.Item
                name="scheduleStatus"
                label={t(R.strings.status_label)}
                rules={rules?.scheduleStatus}
              >
                <Select
                  placeholder={t(R.strings.status_label_placeholder)}
                  // onChange={onGenderChange}
                  allowClear
                >
                  {[...ListAppointmentStatus, AppointmentStatusCancel].map(
                    (m: IListAppointmentStatus, key) => (
                      <Option value={m.status} key={key}>
                        {m.label}
                      </Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <NoteBlock>
              <Form.Item
                label={t(R.strings.note_label)}
                name="note"
                rules={[
                  {
                    max: NOTE_MAX_LENGTH,
                    message: t(R.strings.note_max_length),
                  },
                ]}
              >
                <TextArea
                  className="note"
                  placeholder={t(R.strings.note_label_placeholder)}
                  onChange={e => setNote(e?.target?.value)}
                />
              </Form.Item>
              <NumberCharacter>
                {t(R.strings.number_of_character)} {note?.length}/
                {NOTE_MAX_LENGTH}
              </NumberCharacter>
            </NoteBlock>
          </Col>
          <Button
            key="submit"
            type="default"
            htmlType="submit"
            className="hidden"
            ref={submitButton}
          ></Button>
        </Row>
      </Form>
    </>
  )
}

export default FormModal
