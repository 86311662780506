import { IParamsGetLocationsWithoutPaid } from 'app/api/accounting/model/orthodontic-without-paid'

export const WithoutPaidKeys = {
  allWithoutPaid: ['WithoutPaid'] as const,
  generalInfos: () =>
    [...WithoutPaidKeys.allWithoutPaid, 'generalInfos'] as const,
  generalInfo: (filters?: IParamsGetLocationsWithoutPaid) =>
    [...WithoutPaidKeys.generalInfos(), { filters }] as const,

  patientInfos: () =>
    [...WithoutPaidKeys.allWithoutPaid, 'patientInfos'] as const,
  patientInfo: (filters?: IParamsGetLocationsWithoutPaid) =>
    [...WithoutPaidKeys.patientInfos(), { filters }] as const,
}
