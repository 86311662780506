import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import Report012Container from 'app/containers/Report/Report012'

const Report012PageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.report_012)}</PageTitle>
      <Report012Container />
    </>
  )
}
const Report012Page = memo(Report012PageCpn, isEqual)

export default Report012Page
