import { PrinterOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { ModalComponent } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/components'
import { useTranslation } from 'react-i18next'

export const PrintTreatmentReceiptModal = () => {
  const { t } = useTranslation()

  return (
    <ModalComponent
      iconOpenModal={
        <BaseCol>
          <PrinterOutlined rev={undefined} />
        </BaseCol>
      }
      titleOpenModal={t(R.strings.print_treatment_receipt)}
      titleModal={t(R.strings.print_treatment_receipt)}
      renderContent={
        <BaseSpace>
          <BaseText children={t(R.strings.print_treatment_receipt)} />
        </BaseSpace>
      }
    />
  )
}
