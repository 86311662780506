import { IVoucherReferralStatistics } from 'app/api/marketing/discount-voucher/model'
import R from 'app/assets/R'
import { BaseTable } from 'app/components/common/BaseTable'
import BaseText from 'app/components/common/BaseText'
import {
  convertColumnTable,
  ExpandedTableValue,
} from 'app/components/tables/BaseTableReport/hook'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import {
  FONT_SIZE,
  FONT_WEIGHT,
  formatMoney,
  PADDING,
} from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DefineUserDataByVoucherEnum } from '../../../ListVoucherCode/constant'
import { IDataTable } from './type'
import { PatientTable } from './layouts'

export const VoucherTable = ({
  vouchers,
}: {
  vouchers: IVoucherReferralStatistics[]
}) => {
  const { t } = useTranslation()

  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

  const handleExpand = (expanded: boolean, newKey?: number) => {
    if (!newKey) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const data: IDataTable[] = useMemo(() => {
    return vouchers.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [vouchers])

  const columns = useMemo(() => {
    return [
      convertColumnTable<IDataTable>({
        key: '_id',
        title: ' ',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'small-column',
        render: () => {
          return <BaseText children={''} />
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.voucher_code),
        key: 'code',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'medium-column',
        width: '20%',
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)
          const _handleExpand = () => {
            handleExpand(!isExpanded, record?.key)
          }

          return (
            <ExpandedTableValue
              isExpanded={isExpanded}
              handleExpand={_handleExpand}
              value={record?.code}
              isHaveAreaWhenNotHaveActionExpanded
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.number_of_use),
        key: 'used',
        render: (_, record) => {
          return (
            <BaseText
              children={`${formatMoney(record?.used ?? '0', '')}/${formatMoney(
                record?.maxUsage ?? '0',
                '',
              )}`}
              fontWeight="medium"
              opacity="0.7"
              colorText={
                isEqual(record?.used, record?.maxUsage)
                  ? 'statesGreenColor'
                  : 'primaryColor'
              }
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.number_of_users),
        key: 'voucherLogs',
        render: (_, record) => {
          return (
            <BaseText
              children={`${formatMoney(record?.voucherLogs?.length ?? 0, '')}`}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.status),
        key: 'statusUsed',
        render: (_, record) => {
          if (record?.statusUsed === undefined) return
          const define = DefineUserDataByVoucherEnum[record?.statusUsed]
          return (
            <BaseStatusWrapper $backgroundImage={define.background}>
              <BaseText
                children={t(define?.key18n)}
                fontWeight="medium"
                colorText={define?.colorText}
              />
            </BaseStatusWrapper>
          )
        },
      }),
    ]
  }, [expandedRowKeys, t])

  return (
    <TableWrapper
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={{
        x: true,
      }}
      expandable={{
        expandedRowKeys,
        onExpand: handleExpand,
        expandIcon: () => null,
        showExpandColumn: false,
        expandedRowRender: (record: IDataTable) => (
          <PatientTable users={record?.voucherLogs ?? []} />
        ),
      }}
    />
  )
}

const TableWrapper = styled(BaseTable)`
  table > thead > tr:first-child > *:first-child {
    border-radius: 0px !important;
  }
  table > thead > tr:last-child > *:last-child {
    border-radius: 0px !important;
  }
  .ant-table-thead > tr > th {
    border-right: 0px !important;
    border-left: 0px !important;
    background: #ebeef2;
    padding: 8px 16px;
    color: ${convertedVariables.primaryColor};
    opacity: 0.7;
    font-weight: ${FONT_WEIGHT.semibold};
    font-size: ${FONT_SIZE.xxs};
  }
  .ant-table {
    margin-inline: -16px !important;
  }

  .very-big-column {
    min-width: 300px !important;
  }

  .big-column {
    min-width: 220px !important;
  }

  .normal-column {
    min-width: 170px !important;
  }

  .medium-column {
    min-width: 100px !important;
  }

  .small-column {
    width: 30px !important;
  }

  .class-left {
    .ant-row {
      display: flex;
      justify-content: flex-end;
    }
    text-align: right !important;
  }
`

const BaseStatusWrapper = styled.div<{
  $backgroundImage: string
}>`
  padding: 5px 10px;
  border-radius: ${PADDING.xl};
  background-image: ${props => props.$backgroundImage};
  width: fit-content;
`
