import { Button, Modal } from 'antd'
import R from 'app/assets/R'
import { AppointmentContext } from 'app/context/AppointmentContext'
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { IAppointment } from '../../../type'
import './index.css'
import { ModalMessage, ModalPatientName, ModalTitle } from './styles'
import { Moment } from 'moment'
import { DATE } from 'app/common/constants'

const ConfirmModal: React.FC<{
  setOpenConfirmModal: Dispatch<SetStateAction<boolean>>
  openConfirmModal: boolean
  cancelAppointmentItem: IAppointment
  setCancelAppointmentItem: Dispatch<SetStateAction<IAppointment | string>>
}> = props => {
  const {
    setOpenConfirmModal,
    openConfirmModal,
    cancelAppointmentItem,
    setCancelAppointmentItem,
  } = props
  const [loading, setLoading] = useState(false)
  const { cancelAppointment } = useContext(AppointmentContext)
  const { t } = useTranslation()

  const handleOk = async () => {
    setLoading(true)
    const date = cancelAppointmentItem?.date as Moment
    await cancelAppointment?.(
      {
        ...cancelAppointmentItem,
        date: date.format(DATE.YYYY_MM_DD),
        services: [cancelAppointmentItem?.services],
        treatments: [cancelAppointmentItem?.treatments],
      },
      cancelAppointmentItem?._id,
    )
    if (cancelAppointmentItem?.fetch) {
      await cancelAppointmentItem?.fetch()
    }
    setCancelAppointmentItem('')
    setLoading(false)
    setOpenConfirmModal(false)
  }

  const handleCancel = () => {
    setOpenConfirmModal(false)
  }

  return (
    <>
      <Modal
        open={openConfirmModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t(R.strings.cancel)}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            {t(R.strings.confirm)}
          </Button>,
        ]}
        wrapClassName="confirm-modal"
      >
        <ModalTitle>{t(R.strings.guest_cancels_appointment)}</ModalTitle>
        <ModalMessage>
          {t(R.strings.confirm_guest_cancels_appointment)}
          <ModalPatientName>{cancelAppointmentItem?.fullName}</ModalPatientName>
          {t(R.strings.confirm_describe_guest_cancels_appointment)}
        </ModalMessage>
      </Modal>
    </>
  )
}

export default ConfirmModal
