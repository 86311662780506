import { useMemo } from 'react'
import { dataNotificationDashboard } from './data'
import * as S from './styles'
import BaseText from 'app/components/common/BaseText'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
export const NotificationDoctorComponent = () => {
  const { t } = useTranslation()
  const notificationList = useMemo(() => {
    return {
      tablet: dataNotificationDashboard.map((item, index) => (
        <S.ItemWrapper key={index}>
          <S.TitleItemText>{item?.title}</S.TitleItemText>
          <S.DescriptionItemText>{item?.description}</S.DescriptionItemText>
        </S.ItemWrapper>
      )),
    }
  }, [dataNotificationDashboard])

  return (
    <S.RootWrapper>
      <BaseText
        children={t(R.strings.notification)}
        fontSize="md"
        fontWeight="semibold"
      />
      <S.CarouselWrapper dotPosition={'bottom'} draggable autoplay>
        {notificationList.tablet}
      </S.CarouselWrapper>
    </S.RootWrapper>
  )
}
