import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { IWorkInfo } from 'app/model/user.model'
import { useAppSelector } from 'app/redux/hooks'
import React, { useMemo, useState } from 'react'
import { ModalChangePassword } from '../ModalChangePassword'
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay'
import * as S from './styles'

export const GenerateWorkInfo = (workInfos?: IWorkInfo[]) => {
  if (!workInfos) return ''
  return workInfos?.map(component => `${component.jobtitlename}`).join(' & ')
}

export const ProfileDropdown: React.FC = () => {
  // const { isTablet } = useResponsive()
  const [open, setOpen] = useState(false)

  const [visibleModalChangePassword, setVisibleModalChangePassword] =
    useState(false)

  const showModal = () => {
    setVisibleModalChangePassword(true)
  }

  const hide = () => {
    setOpen(false)
  }

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  const profile = useAppSelector(state => state.profileSlice)?.profile

  const renderName = useMemo(() => {
    return `${profile?.name ?? ''}`
  }, [profile])

  const onHideModalChangePassword = () => {
    setVisibleModalChangePassword(false)
  }

  return (
    <>
      <S.ProfileDropdownHeader
        as={BaseRow}
        gutter={[10, 10]}
        align="middle"
        wrap={false}
      >
        <BaseAvatarProfile
          jobTitle={profile?.jobTitleName}
          name={renderName}
          src={profile?.avatar}
          isShowToolTip
        />
        <BaseCol>
          <S.BasePopoverCustom
            content={<ProfileOverlay onHide={hide} showModal={showModal} />}
            trigger="click"
            onOpenChange={handleOpenChange}
            open={open}
          >
            <S.ProfileDropdownHeader
              as={BaseRow}
              gutter={[10, 10]}
              align="middle"
              wrap={false}
            >
              <BaseCol>
                <BaseImage
                  src={R.images.ic_chevron_down}
                  preview={false}
                  width={16}
                  height={16}
                />
              </BaseCol>
            </S.ProfileDropdownHeader>
          </S.BasePopoverCustom>
        </BaseCol>
      </S.ProfileDropdownHeader>

      <ModalChangePassword
        isVisible={visibleModalChangePassword}
        onHideModal={onHideModalChangePassword}
      />
    </>
  )
}
