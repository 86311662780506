import { TypeId } from 'app/api/profile/model/management'
import { StatusEnum, StatusTableType } from 'app/common/enum'
import { t } from 'i18next'
import { ActionAccountEnum } from './type'

export function convertToStatusProfile(
  statusFilter?: StatusTableType,
): StatusEnum {
  let status = StatusEnum.DELETED
  switch (statusFilter) {
    case 'active':
      status = StatusEnum.ACTIVE
      break
    case 'inactive':
      status = StatusEnum.INACTIVE
      break
    // case 'error':
    //   status = StatusEnum.BLOCK
    //   break
    default:
      status = StatusEnum.DELETED
      break
  }
  return status
}

export function convertProfileType(unitTypeId?: TypeId | undefined): string {
  switch (unitTypeId) {
    case TypeId.AREA:
      return t(unitTypeId)
    case TypeId.CLINIC:
      return t(unitTypeId)
    case TypeId.DEPARTMENT:
      return t(unitTypeId)
    default:
      return '-'
  }
}

export function convertToOptionActionAccount(
  statusFilter?: ActionAccountEnum,
): string {
  let status = ''
  switch (statusFilter) {
    case ActionAccountEnum.create:
      status = t('profile_content.option_create')
      break
    case ActionAccountEnum.select_id:
      status = t('profile_content.option_select_id')
      break
    case ActionAccountEnum.non:
      status = t('profile_content.option_non')
      break
    default:
      status = ''
      break
  }

  return status
}
