import React from 'react'
import { ButtonProps as AntButtonProps, Button as AntdButton } from 'antd'
import * as S from './styles'
import { Severity } from 'parkway-web-common'

export const { Group: ButtonGroup } = AntdButton

export interface BaseButtonProps extends AntButtonProps {
  severity?: Severity
  noStyle?: boolean
}

export const BaseButton = React.forwardRef<HTMLElement, BaseButtonProps>(
  ({ className, severity, noStyle, children, ...props }, ref) => (
    <S.Button
      ref={ref}
      className={className}
      $noStyle={noStyle}
      {...props}
      $severity={severity}
    >
      {children}
    </S.Button>
  ),
)
