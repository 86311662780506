import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import {
  SvgDeleteDesignIcon,
  SvgInformationCircleOrangeIcon,
} from 'app/assets/svg-assets'
import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BasePopconfirm } from 'app/components/common/BasePopconfirm'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import DividerLine from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/DividerLine'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { IProductServicePurchaseItem } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/purchase-product-service'
import { BORDER_RADIUS, formatMoney } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

interface IProps {
  item: IProductServicePurchaseItem
}

export const ProductServiceItem = ({ item }: IProps) => {
  const { t } = useTranslation()
  const { onChangeQuantityProductServiceItem, onDeleteProductServiceItem } =
    useProfilePatientContext()

  const _onMinusQuantity = () => {
    if (item?.quantity > 1) {
      onChangeQuantityProductServiceItem?.({
        productServiceId: item.id,
        newQuantity: item?.quantity - 1,
      })
    }
  }

  const onPlusQuantity = () => {
    onChangeQuantityProductServiceItem?.({
      productServiceId: item.id,
      newQuantity: item?.quantity + 1,
    })
  }

  const onChangeQuantity = (quantity: number) => {
    onChangeQuantityProductServiceItem?.({
      productServiceId: item.id,
      newQuantity: Number(quantity),
    })
  }

  const isDisableMinus = useMemo(() => {
    return !(item?.quantity > 1)
  }, [item?.quantity])

  const _onDeleteProductServiceItem = () => {
    onDeleteProductServiceItem?.(item?.id)
  }

  const amount = useMemo(() => {
    return item?.quantity * item?.pricePerItem
  }, [item?.quantity, item?.pricePerItem])

  const totalAmount = useMemo(() => {
    const value = amount - (item?.discount?.value ?? 0)
    if (value <= 0) return 0

    return value
  }, [amount, item?.discount])

  return (
    <S.RootWrapper direction="vertical" size={15}>
      <BaseRow align={'middle'} gutter={[10, 10]} justify={'space-between'}>
        <BaseCol>
          <BaseRow align={'middle'} gutter={[10, 10]}>
            <BaseCol>
              <BaseAvatar
                src={R.images.img_bg_auth}
                alt="Nha khoa"
                style={{ borderRadius: BORDER_RADIUS }}
                size={40}
              />
            </BaseCol>
            <BaseCol>
              <BaseSpace direction="vertical" size={5}>
                <BaseText
                  fontWeight={'semibold'}
                  fontSize="xs"
                  children={item.name ?? '-'}
                />
                <BaseRow align={'middle'} gutter={[15, 10]}>
                  <BaseCol>
                    <BaseRow align={'middle'} gutter={[8, 8]}>
                      <BaseCol>
                        <BaseText fontWeight="medium">
                          {`${t(R.strings.price)}:`}
                        </BaseText>
                      </BaseCol>
                      <BaseCol>
                        <BaseText
                          colorText={'statesGreenColor'}
                          fontWeight={'semibold'}
                        >
                          {formatMoney(item?.pricePerItem)}
                        </BaseText>
                      </BaseCol>
                    </BaseRow>
                  </BaseCol>

                  <BaseCol>
                    <BaseText
                      children={`${t(R.strings.unit_of_measure)}: (${
                        item?.uom
                      })`}
                      fontWeight="medium"
                    />
                  </BaseCol>
                </BaseRow>
              </BaseSpace>
            </BaseCol>
          </BaseRow>
        </BaseCol>

        <BaseCol>
          <BaseRow gutter={[15, 10]} align={'middle'}>
            <BaseCol>
              <S.ButtonQuantity
                onClick={_onMinusQuantity}
                disabled={isDisableMinus}
              >
                <MinusOutlined rev={undefined} />
              </S.ButtonQuantity>
            </BaseCol>
            <BaseCol>
              <S.BaseFormItem hiddenLabel>
                <InputNumber
                  value={item?.quantity}
                  onChange={value => onChangeQuantity(value as number)}
                  min={1}
                  controls={false}
                />
              </S.BaseFormItem>
            </BaseCol>
            <BaseCol>
              <S.ButtonQuantity onClick={onPlusQuantity}>
                <PlusOutlined rev={undefined} />
              </S.ButtonQuantity>
            </BaseCol>
            <BaseCol>
              <S.LineVertical />
            </BaseCol>
            <BasePopconfirm
              title={t(R.strings.notification)}
              description={t(R.strings.are_you_want_delete_name, {
                name: item?.name,
              })}
              okText={t(R.strings.confirm)}
              cancelText={t(R.strings.no)}
              onConfirm={_onDeleteProductServiceItem}
            >
              <SvgDeleteDesignIcon />
            </BasePopconfirm>
          </BaseRow>
        </BaseCol>
      </BaseRow>

      <S.DiscountArea
        gutter={[15, 10]}
        align={'middle'}
        justify={'space-between'}
      >
        <BaseCol>
          <BaseRow gutter={[15, 10]} align={'middle'}>
            <BaseCol>
              <BaseImage src={R.images.ic_bell} width={24} height={24} />
            </BaseCol>
            <BaseCol>
              <BaseRow align={'middle'} gutter={[8, 8]}>
                <BaseCol>
                  <BaseText children={t(R.strings.promotion)} />
                </BaseCol>
                <BaseCol>
                  <BaseText
                    colorText={'statesRedColor'}
                    fontWeight={'semibold'}
                    children={item?.discount?.name ?? '-'}
                  />
                </BaseCol>
                <BaseCol>
                  <BaseText children={'áp dụng từ 08/08/2025 - 08/12/2025'} />
                </BaseCol>
              </BaseRow>
            </BaseCol>
          </BaseRow>
        </BaseCol>
        <SvgInformationCircleOrangeIcon />
      </S.DiscountArea>

      <DividerLine />

      <BaseRow align={'top'} gutter={[15, 10]} justify={'space-between'}>
        <BaseCol>
          <BaseText fontWeight={'semibold'} children={t(R.strings.total)} />
        </BaseCol>
        <BaseCol xl={5}>
          <BaseSpace direction="vertical" size={10} style={{ width: '100%' }}>
            <BaseRow justify={'space-between'} align={'middle'} gutter={15}>
              <BaseCol>
                <BaseText children={`${item?.name} x ${item.quantity}`} />
              </BaseCol>
              <BaseCol>
                <BaseText children={formatMoney(amount)} />
              </BaseCol>
            </BaseRow>
            <BaseRow justify={'space-between'} align={'middle'} gutter={15}>
              <BaseCol>
                <BaseText children={t(R.strings.discount)} />
              </BaseCol>
              <BaseCol>
                <BaseText children={formatMoney(item?.discount?.value ?? 0)} />
              </BaseCol>
            </BaseRow>
            <BaseRow justify={'space-between'} align={'middle'} gutter={15}>
              <BaseCol>
                <BaseText
                  fontWeight="semibold"
                  children={t(R.strings.total_amount)}
                />
              </BaseCol>
              <BaseCol>
                <BaseText
                  colorText={'statesGreenColor'}
                  fontWeight={'semibold'}
                  children={formatMoney(totalAmount)}
                />
              </BaseCol>
            </BaseRow>
          </BaseSpace>
        </BaseCol>
      </BaseRow>
    </S.RootWrapper>
  )
}
