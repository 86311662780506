import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import HeaderPage from 'app/components/header/components/HeaderPage'
import * as S from 'app/components/tables/BaseTableReport/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import 'dayjs/locale/vi'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'
import { FilterLayout, ModalCreateApproveFlowLayout } from './layouts'
import { BORDER_RADIUS } from 'parkway-web-common'
import { useHook } from './hook'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'

const ApproveFlowContainer: React.FC = () => {
  const { column, data } = useHook()
  return (
    <S.BaseReportWrapper>
      <HeaderPage
        titleI18nKey={R.strings.approve_flow}
        rightComponent={<ModalCreateApproveFlowLayout />}
      />
      <RootWrapper>
        <FilterLayout />
        <BaseTableManagement
          columns={column}
          dataSource={data}
          pagination={{ total: 20 }}
        />
      </RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default ApproveFlowContainer

const RootWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};

  // set header table antd color opcacity 0.7
  .ant-table-thead > tr > th {
    opacity: 0.7;
  }
`
