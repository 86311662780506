import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import { Dates, SelectQuarterEnum } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import { useOrthodonticWithoutPaid } from '../../context'
import * as S from './styles'

export const Filter: React.FC = () => {
  const { filterResponse, setFilterResponse } = useOrthodonticWithoutPaid()
  const { t } = useTranslation()
  const onChangeYearFrom = (_, year: string) => {
    setFilterResponse?.(prev => ({
      ...prev,
      from: {
        ...prev.from,
        year: +year,
      },
    }))
  }

  const onChangeSelectQuarterFrom = (type: SelectQuarterEnum) => {
    setFilterResponse?.(prev => ({
      ...prev,
      from: {
        ...prev.from,
        quarter: +type,
      },
    }))
  }

  const onChangeYearTo = (_, year: string) => {
    setFilterResponse?.(prev => ({
      ...prev,
      to: {
        ...prev.to,
        year: +year,
      },
    }))
  }

  const onChangeSelectQuarterTo = (type: SelectQuarterEnum) => {
    setFilterResponse?.(prev => ({
      ...prev,
      to: {
        ...prev.to,
        quarter: +type,
      },
    }))
  }

  return (
    <S.HeadWrapper direction="vertical" size={0}>
      <S.BaseWrapper>
        <BaseRow gutter={20} align={'middle'} justify={'start'}>
          <BaseCol xl={5}>
            <BaseForm.Item>
              <S.Picker
                picker={'year'}
                onChange={onChangeYearFrom}
                value={Dates.getDate(`${filterResponse?.from?.year}-01-01`)}
                allowClear={false}
                $prefix={t(R.strings.from_year)}
              />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xl={5}>
            <BaseForm.Item name="select">
              <S.SelectBaseStyled
                defaultValue={filterResponse?.from.quarter}
                value={filterResponse?.from.quarter}
                placeholder={'Select type'}
                onChange={type =>
                  onChangeSelectQuarterFrom(type as SelectQuarterEnum)
                }
                allowClear={false}
                $prefix={t(R.strings.from_quarter)}
              >
                <Option value={SelectQuarterEnum.QUARTER_1}>
                  {t(R.strings.quarter_num, { quarter: 1 })}
                </Option>
                <Option value={SelectQuarterEnum.QUARTER_2}>
                  {t(R.strings.quarter_num, { quarter: 2 })}
                </Option>
                <Option value={SelectQuarterEnum.QUARTER_3}>
                  {t(R.strings.quarter_num, { quarter: 3 })}
                </Option>
                <Option value={SelectQuarterEnum.QUARTER_4}>
                  {t(R.strings.quarter_num, { quarter: 4 })}
                </Option>
              </S.SelectBaseStyled>
            </BaseForm.Item>
          </BaseCol>
          <BaseCol>
            <BaseText children={'-'} opacity="0.4" fontWeight="medium" />
          </BaseCol>
          <BaseCol xl={5}>
            <BaseForm.Item>
              <S.Picker
                picker={'year'}
                onChange={onChangeYearTo}
                value={Dates.getDate(`${filterResponse?.to.year}-01-01`)}
                allowClear={false}
                $prefix={t(R.strings.to_year)}
              />
            </BaseForm.Item>
          </BaseCol>

          <BaseCol xl={5}>
            <BaseForm.Item name="select">
              <S.SelectBaseStyled
                defaultValue={filterResponse?.to?.quarter}
                value={filterResponse?.to?.quarter}
                placeholder={'Select type'}
                onChange={type =>
                  onChangeSelectQuarterTo(type as SelectQuarterEnum)
                }
                allowClear={false}
                $prefix={t(R.strings.to_quarter)}
              >
                <Option value={SelectQuarterEnum.QUARTER_1}>
                  {t(R.strings.quarter_num, { quarter: 1 })}
                </Option>
                <Option value={SelectQuarterEnum.QUARTER_2}>
                  {t(R.strings.quarter_num, { quarter: 2 })}
                </Option>
                <Option value={SelectQuarterEnum.QUARTER_3}>
                  {t(R.strings.quarter_num, { quarter: 3 })}
                </Option>
                <Option value={SelectQuarterEnum.QUARTER_4}>
                  {t(R.strings.quarter_num, { quarter: 4 })}
                </Option>
              </S.SelectBaseStyled>
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>
      </S.BaseWrapper>
    </S.HeadWrapper>
  )
}
