import { TreeSelect as AntTreeSelect } from 'antd'
import { RefSelectProps } from 'antd/lib/select'
import { Dimension } from 'parkway-web-common'
import React, { ComponentProps } from 'react'
import * as S from './styles'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'

export interface BaseTreeSelectProps
  extends ComponentProps<typeof AntTreeSelect> {
  width?: Dimension
  shadow?: boolean
  className?: string
}

export const BaseTreeSelect = React.forwardRef<
  RefSelectProps,
  BaseTreeSelectProps
>(({ className, width, shadow, children, placeholder, ...props }, ref) => (
  <S.TreeSelect
    getPopupContainer={triggerNode => triggerNode}
    ref={ref}
    className={className}
    $width={width}
    $shadow={shadow}
    suffixIcon={<SvgTableCloseIcon />}
    placeholder={<S.Placeholder>{placeholder}</S.Placeholder>}
    {...props}
  >
    {children}
  </S.TreeSelect>
))
