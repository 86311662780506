import dayjs, { Dayjs } from 'dayjs'
import { createContext, useState } from 'react'


interface IPageAppointmentContext {
  doctorSelected: {
    name?: string
  },
  setDoctorSelected?: (params) => void,
  filter: {
    date?: Dayjs,
    areaId?: string
    clinicId?: string
    filter?: string
    categoryServiceId?: string
    doctorId?: string
  },
  setFilter?: (params) => void,
}

export const PageAppointmentContext = createContext<IPageAppointmentContext>({
  doctorSelected: {},
  filter: {},
})

export const PageAppointmentProvider = ({ children }) => {
  const [doctorSelected, setDoctorSelected] = useState({})
  const [filter, setFilter] = useState({ date: dayjs() })

  return (
    <PageAppointmentContext.Provider
      value={{
        doctorSelected,
        setDoctorSelected,
        filter,
        setFilter,
      }}
    >
      {children}
    </PageAppointmentContext.Provider>
  )
}
