import * as S from 'app/components/tables/BaseTableManagement/styles'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

import {
  ButtonExcel,
  BuyBox,
  CustomSelectSearch,
  FormItemUser,
  InputBox,
  RowLine,
} from './styles'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { StatusTableEnum } from 'app/common/enum'
import { useHook } from './hook'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { Option } from 'app/components/common/selects/BaseSelect'
import { useContext, useEffect, useState } from 'react'
import { ProfileContext } from 'app/context/ProfileContext'
import { IUser } from 'app/model/user.model'
import {
  DropdownRender,
  SearchOutlinedStyled,
  SearchWrapper,
} from 'app/components/common/selects/SelectSearch/styles'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { IWarehouse } from 'app/api/inventory-new/model/management'
import { requestGetWarehouseManagement } from 'app/api/inventory-new'
import { get } from 'lodash'
import { useParams } from 'react-router'
import { SvgDownloadIcon } from 'app/assets/svg-assets'
import { GoodsIssueReceiptContext } from '../../context'
import { SelectInputSearch } from 'app/containers/InventoryManagement/WarehousePurchase/components/FilterManagementTable/styles'
import dayjs from 'dayjs'
import { requestDetailWarehouseGoodsIssueManagement } from 'app/api/inventory-new/api/warehouse-goods-issue'
import { notificationController } from 'app/controllers/notification-controller'
import { useAppSelector } from 'app/redux/hooks'

export type StatusGoodsIssueReceiptType = keyof typeof StatusTableEnum

const BuyInfo = () => {
  const { t } = useTranslation()
  const { rules, downloadTemplate, isDownloading } = useHook()
  const params = useParams()
  const [searchUser, setSearchUser] = useState('')
  const { fetchData: fetchUsers, profiles: users } = useContext(ProfileContext)
  const profile = useAppSelector(state => state.profileSlice)?.profile
  const { form, setGoodsIssueReceiptDetail, setGoodsIssues } = useContext(
    GoodsIssueReceiptContext,
  )
  const [warehouses, setWarehouses] = useState<IWarehouse[]>([])
  const [warehouseKeyword, setWarehouseKeyword] = useState('')

  useEffect(() => {
    const timer = setTimeout(async () => {
      const response = await requestGetWarehouseManagement({
        keyword: warehouseKeyword,
      })
      const data: IWarehouse[] = get(response, 'data', [])
      setWarehouses(data)
    }, 500)
    return () => clearTimeout(timer)
  }, [warehouseKeyword])

  const geGoodsIssueDetail = async () => {
    try {
      const { data } = await requestDetailWarehouseGoodsIssueManagement(
        params?.id,
      )

      const orders = data?.orders?.map(order => ({
        ...order,
        name: order?.product?.name,
        code: order?.product?.code,
        purchaseMeasurementUnit: order?.measurementUnit?.name,
        measurementUnitId: order?.measurementUnitId,
      }))

      setGoodsIssues?.(orders)
      await form?.setFieldsValue({
        ...data,
        createAtDate: dayjs(data?.createAtDate),
      })

      setGoodsIssueReceiptDetail?.({
        ...data,
        paymentDueDate: dayjs(data?.paymentDueDate),
      })
    } catch (error) {
      notificationController.error({
        message: get(error, 'message', ''),
      })
    }
  }

  useEffect(() => {
    geGoodsIssueDetail()
  }, [])

  useEffect(() => {
    fetchUsers?.({
      page: 0,
      pageSize: 20,
      sort: '-1',
      keyword: searchUser,
    })
  }, [searchUser])

  const usersWithPersonProfiles = users.concat(profile)

  const isDisabled = !!params?.id
  return (
    <BuyBox>
      <BaseForm
        layout="vertical"
        requiredMark="optional"
        initialValues={{
          purchaserId: profile?._id,
          isSentToSupplier: true,
        }}
        form={form}
      >
        <BaseRow gutter={24} align={'top'} justify={'space-between'}>
          <BaseCol xl={12}>
            <S.FormItem
              name="warehouseIssueId"
              shouldUpdate
              label={t(R.strings.warehouse_export)}
              rules={rules.warehouseIssueId}
              required
            >
              <SelectInputSearch
                placeholder={t(R.strings.warehouse_select)}
                filterOption={false}
                dropdownRender={menu => (
                  <DropdownRender>
                    <SearchWrapper>
                      <SearchOutlinedStyled
                        className="search__icon"
                        rev=""
                        size={20}
                      />
                      <BaseInput
                        className="search__input"
                        placeholder={t(R.strings.search)}
                        onChange={e => setWarehouseKeyword(e?.target?.value)}
                        allowClear
                      />
                    </SearchWrapper>
                    {menu}
                  </DropdownRender>
                )}
                allowClear
                disabled={isDisabled}
              >
                {warehouses?.map((warehouse, index) => {
                  return (
                    <Option value={warehouse?._id} key={index}>
                      {warehouse?.name}
                    </Option>
                  )
                })}
              </SelectInputSearch>
            </S.FormItem>
          </BaseCol>
          <BaseCol xl={12}>
            <S.FormItem
              name="warehouseReceiveId"
              shouldUpdate
              label={t(R.strings.warehouse_import)}
              rules={rules.warehouseReceiveId}
              required
            >
              <SelectInputSearch
                placeholder={t(R.strings.warehouse_select)}
                filterOption={false}
                dropdownRender={menu => (
                  <DropdownRender>
                    <SearchWrapper>
                      <SearchOutlinedStyled
                        className="search__icon"
                        rev=""
                        size={20}
                      />
                      <BaseInput
                        className="search__input"
                        placeholder={t(R.strings.search)}
                        onChange={e => setWarehouseKeyword(e?.target?.value)}
                        allowClear
                      />
                    </SearchWrapper>
                    {menu}
                  </DropdownRender>
                )}
                allowClear
                disabled={isDisabled}
              >
                {warehouses?.map((warehouse, index) => {
                  return (
                    <Option value={warehouse?._id} key={index}>
                      {warehouse?.name}
                    </Option>
                  )
                })}
              </SelectInputSearch>
            </S.FormItem>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={24} align={'top'} justify={'space-between'}>
          <BaseCol xl={12}>
            <FormItemUser>
              <S.FormItem
                name="issuerId"
                label={t(R.strings.warehouse_goods_issue_issuer)}
                rules={rules.issuerId}
                required
                shouldUpdate
              >
                <CustomSelectSearch
                  allowClear
                  onSearch={keyword => setSearchUser(keyword)}
                  options={usersWithPersonProfiles.map((user: IUser) => ({
                    value: String(user?._id),
                    name: user?.name,
                    avatar: user?.avatar,
                    description:
                      user?.jobTitleName?.replaceAll(',', ' &') || '',
                  }))}
                  optionLabelProp=""
                  disabled={isDisabled}
                />
              </S.FormItem>
            </FormItemUser>
          </BaseCol>
          <BaseCol xl={12}>
            <FormItemUser>
              <S.FormItem
                name="receiverId"
                label={t(R.strings.warehouse_goods_issue_receiver)}
                rules={rules.receiverId}
                required
                shouldUpdate
              >
                <CustomSelectSearch
                  allowClear
                  onSearch={keyword => setSearchUser(keyword)}
                  options={users.map((user: IUser) => ({
                    value: String(user?._id),
                    name: user?.name,
                    avatar: user?.avatar,
                    description:
                      user?.jobTitleName?.replaceAll(',', ' &') || '',
                  }))}
                  optionLabelProp=""
                  disabled={isDisabled}
                />
              </S.FormItem>
            </FormItemUser>
          </BaseCol>
        </BaseRow>

        <RowLine />

        <S.FormItem
          name="reason"
          label={t(R.strings.warehouse_goods_issue_reason)}
          shouldUpdate
        >
          <S.FormInput.TextArea
            placeholder={t(R.strings.enter_field, {
              field: t(R.strings.reason),
            })}
            disabled={isDisabled}
          />
        </S.FormItem>

        <InputBox>
          <S.FormItem
            name="code"
            label={t(R.strings.receive_code)}
            rules={[
              {
                required: true,
                message: t(R.strings.receive_code),
              },
            ]}
            required
          >
            <S.FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.receive_code),
              })}
              disabled={isDisabled}
              addonAfter={
                <>
                  <ButtonExcel
                    icon={<SvgDownloadIcon />}
                    loading={isDownloading}
                    onClick={async () => await downloadTemplate()}
                  >
                    {t(R.strings.excel)}
                  </ButtonExcel>
                </>
              }
            />
          </S.FormItem>
        </InputBox>

        <S.FormItem name="note" label={t(R.strings.note)} shouldUpdate>
          <S.FormInput
            placeholder={t(R.strings.enter_field, {
              field: t(R.strings.note),
            })}
            disabled={isDisabled}
          />
        </S.FormItem>
      </BaseForm>
    </BuyBox>
  )
}

export default BuyInfo
