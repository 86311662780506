import { requestCreateJobTitleLevelManagement } from 'app/api/jobtitlelevel'
import { JobTitleLevelMessageCode } from 'app/api/jobtitlelevel/constant'
import {
  IPayloadCreateJobTitleLevelManagement,
} from 'app/api/jobtitlelevel/model/job-title-level-management'
import R from 'app/assets/R'
import { StatusEnum } from 'app/common/enum'
import { convertArrayStatusFilterToArrayStatusNumber } from 'app/common/helpers'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { StatusTableType } from 'app/components/tables/BaseTableManagement/hook'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPropsCreateJobTitleLevel, StatusJobTitleLevelType } from '.'
import { IFormData, IFormRule } from './type'

export const useModalHook = (props: IPropsCreateJobTitleLevel) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState<StatusJobTitleLevelType | undefined>()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)
    try {
      const payload: IPayloadCreateJobTitleLevelManagement = {
        name: values?.name,
        description: values?.description,
        code: values?.code,
        level:  values?.level,
        status:
          convertArrayStatusFilterToArrayStatusNumber([
            values?.status,
          ] as StatusTableType[])?.[0] ?? StatusEnum.BLOCK,
      }

      const response: ResponseType<string> =
        await requestCreateJobTitleLevelManagement(payload)

      if (
        isEqual(
          response?.msgcode,
          JobTitleLevelMessageCode.JobTitleLevelManagement.successPostData,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        props?.fetchTable?.()
      } else {
        notificationController.error({
          message: t(R.strings.job_title_level_content_add),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const rules: IFormRule = useMemo(() => {
    return {
      code: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.job_title_level_field_job_title_level_string_id),
          }),
        },
      ],
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.job_title_level_field_name),
          }),
        },
      ],
      description: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.job_title_level_field_description),
          }),
        },
      ],
      level: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.level),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    setStatus,
    status,
  }
}
