/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestExportExcelBrandByProduct } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { IResponseExportExcel } from 'app/api/report/model/common'
import {
  IParamsGetReport06,
  ProductReport06,
} from 'app/api/report/model/report-06'
import R from 'app/assets/R'
import {
  ExpandedTableValue,
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import {
  IBaseColumnReport,
  IDataBaseTable,
  IOnCell
} from 'app/components/tables/BaseTableReport/type'
import { TreatmentGroupEnum } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { convertToTitleCase } from 'app/hook'
import { isEqual } from 'lodash'
import { PERCENT_PREFIX, PeriodType, ResponseType } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataReport006 } from './data'

export const useCommonHook = ({
  expandedRowKeys,
  typeHook,
  handleExpand,
  currentFilter,
  fetchLoading,
  listServiceName,
}: {
  expandedRowKeys: number[]
  typeHook: PeriodType
  tableData: Omit<IDataBaseTable<IDataReport006>, "pagination">
  handleExpand: (isExpanded: boolean, key?: number) => void
  currentFilter?: IParamsGetReport06
  fetchLoading?: (isLoading: boolean) => void
  listServiceName?: string[]
}) => {
  const { t } = useTranslation()

  const exportExcel = async () => {
    try {
      const params: IParamsGetReport06 = {
        ...currentFilter,
      }
      fetchLoading?.(true)
      const res: ResponseType<IResponseExportExcel> =
        await requestExportExcelBrandByProduct(params)

      if (
        res?.msgcode === ReportMessageCode.Report06.successGetData &&
        res?.data?.success
      ) {
        window.open(res?.data?.data ?? '')
        notificationController.success({
          message: t(R.strings.download_excel_success),
        })
      }
    } catch (error: any) {
      // console.log(error)
      notificationController.error(error)
    } finally {
      fetchLoading?.(false)
    }
  }

  const columns = useMemo(() => {
    type TypeColumn = IDataReport006

    const onCellArea = (record: TypeColumn): IOnCell => {
      if (record?.isTotalTable && expandedRowKeys.length) return { colSpan: 2 }

      if (record?.isTotalTable && !expandedRowKeys.length) return { colSpan: 1 }

      const isExpanded = expandedRowKeys.includes(record?.key ?? 0)
      if (!isExpanded && record?.children && expandedRowKeys?.length) {
        return { rowSpan: 1, colSpan: 2 }
      }

      if (!isExpanded && record?.children) {
        return { rowSpan: 1 }
      }

      if (isExpanded && !record?.children?.length) {
        return { rowSpan: 1 }
      }

      return {
        rowSpan: record.children?.length ? record.children?.length + 1 : 0,
      }
    }

    const onCellClinic = (record: TypeColumn): IOnCell => {
      if (record?.isTotalTable) {
        return { colSpan: 0 }
      }

      const isExpanded = expandedRowKeys.includes(record.key ?? 0)
      if (!isExpanded && record?.children && !!expandedRowKeys?.length) {
        return { colSpan: 0 }
      }
      return {}
    }

    let options: IBaseColumnReport<TypeColumn> = [
      convertColumnTable<TypeColumn>({
        title: t(R.strings.area),
        key: 'area',
        onCell: onCellArea,
        fixed: 'left',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)
          if (record?.isTotalTable) {
            return <ExpandedTableValue value={record?.area} isTotalTable />
          }

          if (!record?.children) return null

          const _handleExpand = () => {
            return handleExpand(!isExpanded, record?.key)
          }

          return (
            <ExpandedTableValue
              isExpanded={isExpanded}
              handleExpand={_handleExpand}
              value={record?.area}
            />
          )
        },
      }),
    ]

    if (expandedRowKeys?.length) {
      options = options?.concat([
        convertColumnTable<TypeColumn>({
          title: t(R.strings.clinic),
          key: 'clinic',
          fixed: 'left',
          onCell: onCellClinic,
          classNameWidthColumnOverwrite: 'big-column',
          render: (_, record) => {
            return (
              <RenderValueTableReport
                record={record}
                valueString={record?.clinic}
                expandedRowKeys={expandedRowKeys}
                justify="start"
              />
            )
          },
        }),
      ])
    }

    const treatmentGroupNamesList: string[] = listServiceName?.length
      ? listServiceName
      : Object.values(TreatmentGroupEnum)
    const products = treatmentGroupNamesList?.length
      ? treatmentGroupNamesList?.map((product, index) => {
          return convertColumnTable<TypeColumn>({
            title: convertToTitleCase(product ?? ''),
            key: `a${index + 1}Implant` as any,
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.[`a${index + 1}Implant`] ?? 0,
                secondLevelValue: record?.[`a${index + 1}ImplantTotal`] ?? 0,
                isFillBlueInTotal: true,
                expandedRowKeys,
              })
            },
          })
        })
      : []

    return [
      ...options,
      {
        title: t(R.strings.group_product),
        children: products,
      },
      convertColumnTable<TypeColumn>({
        title: t(R.strings.total_revenue_projected),
        key: 'totalRevenueActual',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.totalRevenueActual ?? 0,
            secondLevelValue: record?.totalRevenueActualTotal ?? 0,
            isFillBlueInTotal: true,
            expandedRowKeys,
          }),
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.total_revenue_planned),
        key: 'totalRevenuePlan',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.totalRevenuePlan ?? 0,
            secondLevelValue: record?.totalRevenuePlanTotal ?? 0,
            expandedRowKeys,
            isFillBlueInTotal: true,
          }),
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.percent_of_target_complete),
        key: 'percentTargetComplete',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.percentTargetComplete ?? 0,
            secondLevelValue: record?.percentTargetCompleteTotal ?? 0,
            prefix: PERCENT_PREFIX,
            isFillBlueInTotal: true,
            expandedRowKeys,
          }),
      }),
    ]
  }, [t, expandedRowKeys, typeHook, listServiceName])

  return {
    columns,
    exportExcel,
  }
}

export function getTreatmentTotalPricesByGroupNames(
  data: ProductReport06[],
  groupNames: TreatmentGroupEnum[],
): Record<TreatmentGroupEnum, number> {
  const result: Record<TreatmentGroupEnum, number> = {} as Record<
    TreatmentGroupEnum,
    number
  >

  groupNames.forEach(groupName => {
    const item = data.find(entry => {
      // console.log({ enty: entry.treatmentgroupName, groupName })
      return isEqual(entry.treatmentgroupName, groupName)
    })
    result[groupName] = item && item?.totalActual ? item?.totalActual : 0
  })

  return result
}

export const convertDataToTable = (
  data: ProductReport06[],
  type: 'total' | 'normal',
): IDataReport006 => {
  const allGroupNames: TreatmentGroupEnum[] = Object.values(TreatmentGroupEnum)
  const totalPrices = getTreatmentTotalPricesByGroupNames(data, allGroupNames)

  if (type === 'normal') {
    let result: IDataReport006 = {}
    allGroupNames?.map((product, index) => {
      result = {
        ...result,
        [`a${index + 1}Implant`]: totalPrices?.[product],
      }
    })
    return result
  }

  let result: IDataReport006 = {}
  allGroupNames?.map((product, index) => {
    result = {
      ...result,
      [`a${index + 1}ImplantTotal`]: totalPrices?.[product],
    }
  })
  return result
}
