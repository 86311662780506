import R from 'app/assets/R'
import { FunctionPermissionEnum } from 'app/common/permission-module'
import { IGroupReport } from 'app/components/common/BaseLayoutReport/type'
import {
  INVENTORY_CATEGORY_PATH,
  INVENTORY_MATERIAL_CATEGORY_PATH,
  INVENTORY_MATERIAL_EXPORT_PATH,
  INVENTORY_MATERIAL_IMPORT_PATH,
  INVENTORY_PROVIDER_PATH,
  INVENTORY_SUPPLIER_PATH,
  INVENTORY_WAREHOUSE_CATEGORY_PATH,
  INVENTORY_WAREHOUSE_GOODS_ISSUE_FOR_UNIT_PATH,
  INVENTORY_WAREHOUSE_GOODS_ISSUE_PATH,
  INVENTORY_WAREHOUSE_INVENTORY_PATH,
  INVENTORY_WAREHOUSE_PURCHASE_PATH,
} from 'app/components/router/route-path'

export const data: IGroupReport[] = [
  {
    id: 1,
    titleI18nKey: R.strings.category_management,
    reports: [
      {
        id: 1,
        label: '',
        descriptionI18nKey: R.strings.item_category,
        updateAt: '30/12/2023',
        path: INVENTORY_CATEGORY_PATH,
        permission: FunctionPermissionEnum.CATEGORY_READ,
      },
      {
        id: 1,
        label: '',
        descriptionI18nKey: R.strings.warehouse_category,
        updateAt: '05/02/2024',
        path: INVENTORY_WAREHOUSE_CATEGORY_PATH,
        permission: FunctionPermissionEnum.WAREHOUSE_READ,
        isHideLabel: true,
      },
    ],
  },
  {
    id: 3,
    titleI18nKey: R.strings.provider_management,
    reports: [
      {
        id: 3,
        label: '',
        descriptionI18nKey: R.strings.provider,
        updateAt: '05/01/2024',
        path: INVENTORY_SUPPLIER_PATH,
        permission: FunctionPermissionEnum.PROVIDER_READ,
        isHideLabel: true,
      },
    ],
  },
  {
    id: 3,
    titleI18nKey: R.strings.warehouse_purchase,
    reports: [
      {
        id: 3,
        label: '',
        descriptionI18nKey: R.strings.warehouse_purchase,
        updateAt: '05/01/2024',
        path: INVENTORY_WAREHOUSE_PURCHASE_PATH,
        permission: FunctionPermissionEnum.WAREHOUSE_READ,
        isHideLabel: true,
      },
    ],
  },

  {
    id: 3,
    titleI18nKey: R.strings.warehouse_inventory_management,
    reports: [
      {
        id: 3,
        label: '',
        descriptionI18nKey: R.strings.warehouse_inventory,
        updateAt: '05/01/2024',
        path: INVENTORY_WAREHOUSE_INVENTORY_PATH,
        permission: FunctionPermissionEnum.WAREHOUSE_READ,
        isHideLabel: true,
      },
      {
        id: 3,
        label: '',
        descriptionI18nKey: R.strings.warehouse_goods_issue,
        updateAt: '05/01/2024',
        path: INVENTORY_WAREHOUSE_GOODS_ISSUE_PATH,
        permission: FunctionPermissionEnum.WAREHOUSE_READ,
        isHideLabel: true,
      },
      {
        id: 3,
        label: '',
        descriptionI18nKey: R.strings.warehouse_goods_issue_for_unit,
        updateAt: '05/01/2024',
        path: INVENTORY_WAREHOUSE_GOODS_ISSUE_FOR_UNIT_PATH,
        permission: FunctionPermissionEnum.WAREHOUSE_READ,
        isHideLabel: true,
      },
    ],
  },


  {
    id: 2,
    titleI18nKey: R.strings.material_management,
    reports: [
      {
        id: 2,
        label: '',
        descriptionI18nKey: R.strings.provider,
        updateAt: '01/04/2024',
        path: INVENTORY_PROVIDER_PATH,
        permission: FunctionPermissionEnum.PROVIDER_READ,
        isHideLabel: true,
      },
      {
        id: 2,
        label: '',
        descriptionI18nKey: R.strings.material_import,
        updateAt: '01/04/2024',
        path: INVENTORY_MATERIAL_IMPORT_PATH,
        permission: FunctionPermissionEnum.MATERIAL_READ,
        isHideLabel: true,
      },
      {
        id: 2,
        label: '',
        descriptionI18nKey: R.strings.material_export,
        updateAt: '01/04/2024',
        path: INVENTORY_MATERIAL_EXPORT_PATH,
        permission: FunctionPermissionEnum.MATERIAL_READ,
        isHideLabel: true,
      },
      {
        id: 3,
        label: '',
        descriptionI18nKey: R.strings.material_category,
        updateAt: '01/04/2024',
        path: INVENTORY_MATERIAL_CATEGORY_PATH,
        permission: FunctionPermissionEnum.MATERIAL_CATEGORY_READ,
        isHideLabel: true,
      },
    ],
  },
]
