import { ClockCircleOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { SvgClipboardPlusIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { TagStatusComponent } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/components'
import { ITreatmentInAppointmentSession } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  DoctorAndNurseLayout,
  InfoMaterialsLayout,
  PaymentInformationLayout,
} from './layouts'
import { AddTreatmentInAppointmentSession } from './modal'

export const TreatmentsLayout = ({
  treatments,
  onAddTreatmentInAppointmentSession,
}: {
  treatments: ITreatmentInAppointmentSession[]
  onAddTreatmentInAppointmentSession?: ({
    newTreatments,
  }: {
    newTreatments?: ITreatmentInAppointmentSession[]
  }) => void
}) => {
  const { t } = useTranslation()

  const _onChangeAppointmentSession = (newTreatment?: ITreatmentInAppointmentSession) => {
    if (!newTreatment) return

    onAddTreatmentInAppointmentSession?.({
      newTreatments: treatments?.concat([newTreatment]),
    })
  }

  return (
    <BaseSpace>
      <BaseRow
        style={{ marginInline: 0 }}
        align={'middle'}
        justify={'space-between'}
        gutter={[15, 10]}
      >
        <BaseCol>
          <BaseRow gutter={[15, 10]} align={'middle'}>
            <SvgClipboardPlusIcon />
            <BaseCol>
              <BaseText
                children={t(R.strings.procedures_within_appointment)}
                fontWeight="semibold"
                fontSize="xs"
              />
            </BaseCol>
            <NumberWrapper>
              <BaseText
                children={treatments?.length}
                colorText="backgroundColor"
                fontWeight="semibold"
              />
            </NumberWrapper>
          </BaseRow>
        </BaseCol>
        <BaseCol>
          <AddTreatmentInAppointmentSession
            onChangeTreatmentInAppointmentSession={_onChangeAppointmentSession}
          />
        </BaseCol>
      </BaseRow>
      {treatments?.map((item, index) => {
        return (
          <CollapseWrapper
            items={[
              {
                label: (
                  <BaseText
                    children={item?.name ?? '-'}
                    fontWeight="semibold"
                  />
                ),
                extra: (
                  <BaseRow gutter={[10, 10]} align={'middle'}>
                    <BaseCol>
                      <ClockCircleOutlined
                        rev={undefined}
                        style={{ opacity: 0.7 }}
                      />
                    </BaseCol>
                    <BaseCol>
                      <BaseText
                        children={'00:09:00'}
                        fontWeight="medium"
                        opacity="0.7"
                      />
                    </BaseCol>
                    <BaseCol>
                      <TagStatusComponent
                        backgroundColor={
                          convertedVariables.statesBlueLightColor
                        }
                        color={convertedVariables?.otherBlueColor}
                        textKeyI18n={R.strings.completed}
                      />
                    </BaseCol>
                  </BaseRow>
                ),
                children: (
                  <BaseSpace>
                    <InfoMaterialsLayout suppliers={item?.materials} />
                    <PaymentInformationLayout treatment={item} />
                    <DoctorAndNurseLayout treatment={item} />
                  </BaseSpace>
                ),
              },
            ]}
            key={index}
          />
        )
      })}
    </BaseSpace>
  )
}

const CollapseWrapper = styled(BaseCollapse)`
  border: 1px solid ${convertedVariables.neutralBlack9Color} !important;

  .ant-collapse-content {
    border-top: 0px;
    padding-left: 48px;
  }

  .ant-collapse-header {
    background-color: ${convertedVariables.backgroundColor} !important;
    color: ${convertedVariables.primaryColor} !important;
  }
`

const NumberWrapper = styled(BaseCol)`
  border-radius: ${PADDING.xl};
  background-color: #629daa; //need change
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
`
