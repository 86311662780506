import { ApiClientTreatment } from 'app/service/api-service'
import { EndPoint } from './constant'
import {
  IParamsGetSupplierManagement,
  IParamsGetWarehouseManagement,
  IParamsGetWarehousePurchaseManagement,
  IPayloadCreateSupplierManagement,
  IPayloadCreateWarehouseManagement,
  IPayloadCreateWarehousePurchaseManagement,
  IPayloadUpdateSupplierManagement,
  IPayloadUpdateWarehouseManagement,
  IPayloadUpdateWarehousePurchaseManagement,
  IWarehouseGoodsReceipt,
} from './model/management'

export const requestGetSupplierManagement = (
  params: IParamsGetSupplierManagement,
) =>
  ApiClientTreatment.Get({
    url: EndPoint.SupplierManagement.request,
    params: { params },
  })

export const requestCreateSupplierManagement = (
  body: IPayloadCreateSupplierManagement,
) =>
  ApiClientTreatment.Post({
    url: EndPoint.SupplierManagement.request,
    body,
  })

export const requestUpdateSupplierManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateSupplierManagement
}) =>
  ApiClientTreatment.Patch({
    url: `${EndPoint.SupplierManagement.request}/${id}`,
    body,
  })

export const requestDetailSupplierManagement = (id?: string | number) =>
  ApiClientTreatment.Get({
    url: `${EndPoint.SupplierManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteSupplierManagement = (id?: string | number) =>
  ApiClientTreatment.Delete({
    url: `${EndPoint.SupplierManagement.request}/${id}`,
    body: {},
  })

export const requestExportSupplierManagement = (
  params: IParamsGetSupplierManagement,
) =>
  ApiClientTreatment.Get({
    url: `${EndPoint.SupplierManagement.request}/export`,
    params: { params },
  })

export const requestGetWarehouseManagement = (
  params: IParamsGetWarehouseManagement,
) =>
  ApiClientTreatment.Get({
    url: EndPoint.WarehouseManagement.request,
    params: { params },
  })

export const requestCreateWarehouseManagement = (
  body: IPayloadCreateWarehouseManagement,
) =>
  ApiClientTreatment.Post({
    url: EndPoint.WarehouseManagement.request,
    body,
  })

export const requestUpdateWarehouseManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateWarehouseManagement
}) =>
  ApiClientTreatment.Patch({
    url: `${EndPoint.WarehouseManagement.request}/${id}`,
    body,
  })

export const requestDetailWarehouseManagement = (id?: string | number) =>
  ApiClientTreatment.Get({
    url: `${EndPoint.WarehouseManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteWarehouseManagement = (id?: string | number) =>
  ApiClientTreatment.Delete({
    url: `${EndPoint.WarehouseManagement.request}/${id}`,
    body: {},
  })

export const requestExportWarehouseManagement = (
  params: IParamsGetWarehouseManagement,
) =>
  ApiClientTreatment.Get({
    url: `${EndPoint.WarehouseManagement.request}/export`,
    params: { params },
  })

export const requestGetWarehousePurchaseManagement = (
  params: IParamsGetWarehousePurchaseManagement,
) =>
  ApiClientTreatment.Get({
    url: EndPoint.WarehousePurchaseManagement.request,
    params: { params },
  })

export const requestCreateWarehousePurchaseManagement = (
  body: IPayloadCreateWarehousePurchaseManagement,
) =>
  ApiClientTreatment.Post({
    url: EndPoint.WarehousePurchaseManagement.request,
    body,
  })

export const requestUpdateWarehousePurchaseManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateWarehousePurchaseManagement
}) =>
  ApiClientTreatment.Patch({
    url: `${EndPoint.WarehousePurchaseManagement.request}/${id}`,
    body,
  })

export const requestDetailWarehousePurchaseManagement = (
  id?: string | number,
) =>
  ApiClientTreatment.Get({
    url: `${EndPoint.WarehousePurchaseManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteWarehousePurchaseManagement = (
  id?: string | number,
) =>
  ApiClientTreatment.Delete({
    url: `${EndPoint.WarehousePurchaseManagement.request}/${id}`,
    body: {},
  })

export const requestExportWarehousePurchaseManagement = (
  params: IParamsGetWarehousePurchaseManagement,
) =>
  ApiClientTreatment.Get({
    url: `${EndPoint.WarehousePurchaseManagement.request}/export`,
    params: { params },
  })

export const requestWarehouseGoodsReceiptTemplate = (
  params: IWarehouseGoodsReceipt,
) =>
  ApiClientTreatment.Get({
    url: `${EndPoint.WarehouseGoodsReceiptManagement.request}/template-file`,
    params: { params },
  })

export const requestWarehouseGoodsReceiptImport = (
  body: FormData,
) =>
  ApiClientTreatment.Post({
    url: `${EndPoint.WarehouseGoodsReceiptManagement.request}/import-product`,
    body,
  })

export const requestGetWarehouseUnit = () => {
  return ApiClientTreatment.Get({
    url: `${EndPoint.WarehouseManagement.request}/unit`,
    params: {},
  })
}