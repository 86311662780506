import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { useHookTable } from './hook'
import { BaseRow } from 'app/components/common/BaseRow'
import { FilterManagement } from './components/FilterManagementTable'
const PatientOudatedContainer = () => {
  const { tableData, columns, handleTableChange, filter, handleChangeFilter } =
    useHookTable()

  return (
    <S.BaseManagementWrapper>
      <HeaderPage
        titleI18nKey={R.strings.patient_outdated}
        rightComponent={<></>}
      />
      <S.RootWrapper>
        <BaseRow>
          <FilterManagement
            handleChangeFilter={handleChangeFilter}
            filterData={filter}
          />
        </BaseRow>
        <BaseTableManagement
          columns={columns}
          dataSource={tableData.data}
          pagination={tableData.pagination}
          loading={tableData.loading}
          onChange={handleTableChange}
          expandable={{
            expandIcon: () => null,
          }}
        />
      </S.RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default PatientOudatedContainer
