import { UserDataByVoucherEnum } from 'app/api/marketing/discount-voucher/enum'
import R from 'app/assets/R'
import { IResponseUserDataByVoucherEnum } from './type'

export const DefineUserDataByVoucherEnum: IResponseUserDataByVoucherEnum = {
  [UserDataByVoucherEnum.NotYetUsed]: {
    key18n: R.strings.not_yet_used,
    background: 'linear-gradient(180deg, #F8F8F8, #F8F8F8)',
    colorText: 'primaryColor',
  },
  [UserDataByVoucherEnum.Using]: {
    key18n: R.strings.using,
    background: 'linear-gradient(180deg, #2F7EFF, #659FFF)',
    colorText: 'backgroundColor',
  },
  [UserDataByVoucherEnum.Used]: {
    key18n: R.strings.used,
    background: 'linear-gradient(180deg, #629DAA, #6DAEBD)',
    colorText: 'backgroundColor',
  },
}
