import { useHook } from './hook'
import { TableWrapper } from './styles'


export const ListPatientWithoutPaidLayout = () => {
  const { columns, tableData, handleTableChange } = useHook()
  
  return (
      <TableWrapper
        columns={columns}
        dataSource={tableData.data}
        loading={tableData.loading}
        pagination={tableData.pagination}
        onChange={handleTableChange}
        scroll={{
          x: true,
        }}
      />
  )
}
