import { DeleteOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { ModalComponent } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/components'
import { useTranslation } from 'react-i18next'

export const DeleteAppointmentSessionModal = () => {
  const { t } = useTranslation()

  return (
    <ModalComponent
    iconOpenModal={
      <BaseCol>
        <DeleteOutlined rev={undefined} />
      </BaseCol>
    }
    titleOpenModal={t(R.strings.delete)}
    titleModal={t(R.strings.delete_appointment_session)}
    renderContent={
      <BaseSpace>
        <BaseText children={t(R.strings.delete_appointment_session)} />
      </BaseSpace>
    }
    />
  )
}
