/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestExportExcelPatientProduct } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { IResponseExportExcel } from 'app/api/report/model/common'
import {
  IParamsGetReport09,
  IProductReport09,
} from 'app/api/report/model/report-09'
import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import {
  ExpandedTableValue,
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import { AreaRowWrapper } from 'app/components/tables/BaseTableReport/styles'
import {
  IBaseColumnReport,
  IOnCell,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { TreatmentGroupEnum } from 'app/constant'
import { convertToTitleCase } from 'app/hook'
import { isEqual } from 'lodash'
import {
  PeriodType,
  ResponseType,
  SelectQuarterEnum,
  getQuarterOfYear,
} from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataReport09Quarter } from './Quarter/data'
import { IDataReport09Year } from './Year/data'
import { notificationController } from 'app/controllers/notification-controller'

export const useCommonHook = ({
  expandedRowKeys,
  typeHook,
  currentFilter,
  fetchLoading,
}: {
  expandedRowKeys: number[]
  typeHook: PeriodType
  currentFilter?: IParamsHookReport
  fetchLoading?: (isLoading: boolean) => void
}) => {
  const { t } = useTranslation()

  const exportExcel = async () => {
    try {
      if (!currentFilter?.year) {
        return
      }

      let params: IParamsGetReport09 = {
        year: currentFilter?.year,
      }

      switch (typeHook) {
        case 'week': {
          const quarterParams = getQuarterOfYear({ year: currentFilter.year })[
            currentFilter?.quarter ?? SelectQuarterEnum?.QUARTER_1
          ]
          params = {
            ...params,
            startDate: quarterParams?.start,
            endDate: quarterParams?.end,
          }
        }
      }

      fetchLoading?.(true)
      const res: ResponseType<IResponseExportExcel> =
        await requestExportExcelPatientProduct(params)

      if (
        res?.msgcode === ReportMessageCode.Report09.successGetData &&
        res?.data?.success
      ) {
        window.open(res?.data?.data ?? '')
        notificationController.success({
          message: t(R.strings.download_excel_success),
        })
      }
    } catch (error: any) {
      console.log(error)
      notificationController.error(error)
    } finally {
      fetchLoading?.(false)
    }
  }

  const columns = useMemo(() => {
    const onCellGender = (record): IOnCell => {
      if (
        (record?.isTotalTable || record?.isTotalGroupTable) &&
        expandedRowKeys.length
      )
        return { colSpan: 3 }

      return {
        rowSpan: record.children?.length ? record.children?.length + 1 : 0,
      }
    }

    const onTypeAge = (record: TypeColumn): IOnCell => {
      if (record?.typeAge && record?.numOfChild)
        return { rowSpan: record?.numOfChild }
      return { rowSpan: 0 }
    }

    const onCellAge = (record): IOnCell => {
      if (record?.isTotalTable || record?.isTotalGroupTable) {
        return { colSpan: 0 }
      }

      return {}
    }
    type TypeColumn = IDataReport09Quarter | IDataReport09Year

    let options: IBaseColumnReport<TypeColumn> = [
      convertColumnTable<TypeColumn>({
        title: t(R.strings.gender),
        key: 'gender',
        fixed: 'left',
        onCell: onCellGender,
        render: (_, record) => {
          if (record?.isTotalGroupTable) {
            return (
              <ExpandedTableValue
                value={record?.gender}
                isTotalTable
                className={'is-total-group-table'}
              />
            )
          }
          return (
            <BaseRow>
              <ExpandedTableValue
                value={record?.gender}
                isNotHaveActionExpanded
                className={`${record?.isTotalTable ? 'is-total-table' : ''}`}
              />
            </BaseRow>
          )
        },
      }),
      convertColumnTable<TypeColumn>({
        title: ' ',
        key: 'typeAge',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'medium-column',
        onCell: onTypeAge,
        render: (_, record) => {
          let className = ''

          if (record?.index && record?.typeAge && record?.index % 2 === 0) {
            className = 'is-expanded'
          }

          return (
            <AreaRowWrapper className={className}>
              {record?.typeAge}
            </AreaRowWrapper>
          )
        },
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.age),
        key: 'age',
        fixed: 'left',
        onCell: onCellAge,
        render: (_, record) => {
          return (
            <RenderValueTableReport
              record={record}
              valueString={record?.age}
              expandedRowKeys={expandedRowKeys}
              justify="start"
            />
          )
        },
      }),
    ]

    const treatmentGroupNamesList: string[] = Object.values(TreatmentGroupEnum)
    const countProducts = treatmentGroupNamesList?.map((product, index) => {
      return convertColumnTable<any>({
        title: convertToTitleCase(product ?? ''),
        key: `countA${index + 1}Implant`,
        render: (_, record) => {
          return RenderValueTableReport({
            record,
            firstLevelValue: record?.[`countA${index + 1}Implant`] ?? 0,
            secondLevelValue: record?.[`countA${index + 1}ImplantTotal`] ?? 0,
            isFillBlueInTotal: record?.isTotalTable,
            expandedRowKeys,
            prefix: '',
          })
        },
      })
    })

    const revenueProducts = treatmentGroupNamesList?.map((product, index) => {
      return convertColumnTable<any>({
        title: convertToTitleCase(product ?? ''),
        key: `revenueA${index + 1}Implant`,
        render: (_, record) => {
          return RenderValueTableReport({
            record,
            firstLevelValue: record?.[`revenueA${index + 1}Implant`] ?? 0,
            secondLevelValue: record?.[`revenueA${index + 1}ImplantTotal`] ?? 0,
            isFillBlueInTotal: record?.isTotalTable,
            expandedRowKeys,
          })
        },
      })
    })

    options = options.concat([
      {
        title: t(R.strings.cases_in_product_group),
        children: countProducts,
      },
      {
        title: t(R.strings.revenue_of_product_group),
        children: revenueProducts,
      },
    ])
    return options
  }, [t, expandedRowKeys, typeHook])

  return {
    columns,
    exportExcel,
  }
}

export const sumDataReport09 = (
  total: IDataReport09Quarter,
  newData: IDataReport09Quarter,
) => {
  return {
    countA1Implant:
      (total?.countA1Implant ?? 0) + (newData?.countA1Implant ?? 0),
    countA2Implant:
      (total?.countA2Implant ?? 0) + (newData?.countA2Implant ?? 0),
    countA3Implant:
      (total?.countA3Implant ?? 0) + (newData?.countA3Implant ?? 0),
    countA4Implant:
      (total?.countA4Implant ?? 0) + (newData?.countA4Implant ?? 0),
    countA5Implant:
      (total?.countA5Implant ?? 0) + (newData?.countA5Implant ?? 0),
    countA6Implant:
      (total?.countA6Implant ?? 0) + (newData?.countA6Implant ?? 0),
    countA7Implant:
      (total?.countA7Implant ?? 0) + (newData?.countA7Implant ?? 0),
    countA8Implant:
      (total?.countA8Implant ?? 0) + (newData?.countA8Implant ?? 0),
    countA9Implant:
      (total?.countA9Implant ?? 0) + (newData?.countA9Implant ?? 0),
    countA10Implant:
      (total?.countA10Implant ?? 0) + (newData?.countA10Implant ?? 0),
    countA11Implant:
      (total?.countA11Implant ?? 0) + (newData?.countA11Implant ?? 0),
    countA12Implant:
      (total?.countA12Implant ?? 0) + (newData?.countA12Implant ?? 0),
    countA13Implant:
      (total?.countA13Implant ?? 0) + (newData?.countA13Implant ?? 0),
    countA14Implant:
      (total?.countA14Implant ?? 0) + (newData?.countA14Implant ?? 0),
    countA15Implant:
      (total?.countA15Implant ?? 0) + (newData?.countA15Implant ?? 0),
    countA16Implant:
      (total?.countA16Implant ?? 0) + (newData?.countA16Implant ?? 0),
    countA17Implant:
      (total?.countA17Implant ?? 0) + (newData?.countA17Implant ?? 0),
    countA18Implant:
      (total?.countA18Implant ?? 0) + (newData?.countA18Implant ?? 0),

    revenueA1Implant:
      (total?.revenueA1Implant ?? 0) + (newData?.revenueA1Implant ?? 0),
    revenueA2Implant:
      (total?.revenueA2Implant ?? 0) + (newData?.revenueA2Implant ?? 0),
    revenueA3Implant:
      (total?.revenueA3Implant ?? 0) + (newData?.revenueA3Implant ?? 0),
    revenueA4Implant:
      (total?.revenueA4Implant ?? 0) + (newData?.revenueA4Implant ?? 0),
    revenueA5Implant:
      (total?.revenueA5Implant ?? 0) + (newData?.revenueA5Implant ?? 0),
    revenueA6Implant:
      (total?.revenueA6Implant ?? 0) + (newData?.revenueA6Implant ?? 0),
    revenueA7Implant:
      (total?.revenueA7Implant ?? 0) + (newData?.revenueA7Implant ?? 0),
    revenueA8Implant:
      (total?.revenueA8Implant ?? 0) + (newData?.revenueA8Implant ?? 0),
    revenueA9Implant:
      (total?.revenueA9Implant ?? 0) + (newData?.revenueA9Implant ?? 0),
    revenueA10Implant:
      (total?.revenueA10Implant ?? 0) + (newData?.revenueA10Implant ?? 0),
    revenueA11Implant:
      (total?.revenueA11Implant ?? 0) + (newData?.revenueA11Implant ?? 0),
    revenueA12Implant:
      (total?.revenueA12Implant ?? 0) + (newData?.revenueA12Implant ?? 0),
    revenueA13Implant:
      (total?.revenueA13Implant ?? 0) + (newData?.revenueA13Implant ?? 0),
    revenueA14Implant:
      (total?.revenueA14Implant ?? 0) + (newData?.revenueA14Implant ?? 0),
    revenueA15Implant:
      (total?.revenueA15Implant ?? 0) + (newData?.revenueA15Implant ?? 0),
    revenueA16Implant:
      (total?.revenueA16Implant ?? 0) + (newData?.revenueA16Implant ?? 0),
    revenueA17Implant:
      (total?.revenueA17Implant ?? 0) + (newData?.revenueA17Implant ?? 0),
    revenueA18Implant:
      (total?.revenueA18Implant ?? 0) + (newData?.revenueA18Implant ?? 0),
  }
}

export function getTreatmentTotalPricesByGroupNames(
  data: IProductReport09[],
  groupNames: TreatmentGroupEnum[],
): Record<TreatmentGroupEnum, number> {
  const result: Record<TreatmentGroupEnum, number> = {} as Record<
    TreatmentGroupEnum,
    number
  >

  groupNames.forEach(groupName => {
    const item = data.find(entry => {
      // console.log({ enty: entry.nameTreatment, groupName })
      return isEqual(entry.nameTreatment, groupName)
    })
    result[groupName] = item && item?.payments ? item?.payments : 0
  })

  return result
}

export function getTreatmentCountByGroupNames(
  data: IProductReport09[],
  groupNames: TreatmentGroupEnum[],
): Record<TreatmentGroupEnum, number> {
  const result: Record<TreatmentGroupEnum, number> = {} as Record<
    TreatmentGroupEnum,
    number
  >

  groupNames.forEach(groupName => {
    const item = data.find(entry => {
      // console.log({ enty: entry.nameTreatment, groupName })
      return isEqual(entry.nameTreatment, groupName)
    })
    result[groupName] = item && item?.count ? item?.count : 0
  })

  return result
}

export const convertDataToTable = (
  data: IProductReport09[],
): IDataReport09Quarter => {
  const allGroupNames: TreatmentGroupEnum[] = Object.values(TreatmentGroupEnum)
  const totalPrices = getTreatmentTotalPricesByGroupNames(data, allGroupNames)
  const totalCount = getTreatmentCountByGroupNames(data, allGroupNames)
  let result: IDataReport09Quarter = {}
  allGroupNames?.map((product, index) => {
    result = {
      ...result,
      [`countA${index + 1}Implant`]: totalCount?.[product],
    }
  })

  allGroupNames?.map((product, index) => {
    result = {
      ...result,
      [`revenueA${index + 1}Implant`]: totalPrices?.[product],
    }
  })
  return result
}
