import { RemoveCircleIcon } from 'app/common/icons'
import { FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import styled from 'styled-components'

export const SkillWrapper = styled.div`
  display: flex;
  background-color: var(--neutral-black-16-color);
  padding: ${PADDING.xxxxs};
  border-radius: 100px;
  gap: 10px;
  align-items: center;
`

export const LevelTag = styled.div<{ color?: string }>`
  padding: 4px ${PADDING.xxxxs};
  border-radius: 20px;
  color: ${colors.white};
  background-color: ${props => (props.color ? props.color : colors.grayC4)};
  font-size: ${PADDING.xxxs};
  font-weight: ${FONT_WEIGHT.bold};

  &::before {
    content: '•';
    font-size: 100%;
    margin-right: 4px;
    font-weight: ${FONT_WEIGHT.black};
  }
`

export const SkillTitle = styled.div``

export const RemoveCircleIconStyled = styled(RemoveCircleIcon)`
  &:hover {
    cursor: pointer;
  }
`
