import { ItemCategoryProvider } from 'app/context/ItemCategoryContext'
import { WarehousePurchaseProvider } from '../../context'
import Reorder from './reorder'

const ReorderWarehousePurchaseContainer = () => {
  return (
    <WarehousePurchaseProvider>
      <ItemCategoryProvider>
        <Reorder />
      </ItemCategoryProvider>
    </WarehousePurchaseProvider>
  )
}

export default ReorderWarehousePurchaseContainer
