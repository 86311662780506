import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { CreateVoucherProvider } from './context'
import { ContentLayout } from './content'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { useEffect } from 'react'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'

const CreateVoucherLayout = () => {
  const { user, isHavePermissionFunctionAndGoBack } =
    useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.CREATE_DISCOUNT_VOUCHER,
      R.strings.create_voucher,
    )
  }, [user])

  return (
    <CreateVoucherProvider>
      <S.BaseManagementWrapper>
        <HeaderPage titleI18nKey={R.strings.create_voucher} />
        <ContentLayout />
      </S.BaseManagementWrapper>
    </CreateVoucherProvider>
  )
}

export default CreateVoucherLayout
