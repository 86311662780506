import { get, isEqual } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { SearchDropdown } from './search-dropdown'
import * as S from './styles'
import {
  IFilterProps,
  IResponseFilter,
  initResponseFilterTableManagement,
} from './type'
import { useTranslation } from 'react-i18next'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import R from 'app/assets/R'
import { Option } from 'app/components/common/selects/BaseSelect'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  DropdownRender,
  SearchOutlinedStyled,
  SearchWrapper,
} from 'app/components/common/selects/SelectSearch/styles'
import { IWarehouse } from 'app/api/inventory-new/model/management'
import {
  requestGetWarehouseManagement,
} from 'app/api/inventory-new'
import { GoodsIssueStatusName } from '../../constant'
import dayjs from 'dayjs'
import { DATE } from 'app/common/constants'
import { WarehouseGoodsIssueContext } from '../../context'

export const FilterManagement = ({
  handleChangeFilter,
  filterData,
}: IFilterProps) => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const { isForUnit } = useContext(WarehouseGoodsIssueContext)
  const [warehouses, setWarehouses] = useState<IWarehouse[]>([])
  const [warehouseKeyword, setWarehouseKeyword] = useState('')

  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  useEffect(() => {
    const timer = setTimeout(async () => {
      const response = await requestGetWarehouseManagement({
        keyword: warehouseKeyword,
      })
      const data: IWarehouse[] = get(response, 'data', [])
      setWarehouses(data)
    }, 500)
    return () => clearTimeout(timer)
  }, [warehouseKeyword])

  const [isOverlayOpen, setOverlayOpen] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      handleChangeFilter?.(filter)
    }, 500)
    return () => clearTimeout(timer)
  }, [filter])

  useEffect(() => {
    if (filterData && !isEqual(filterData, filter)) {
      setFilter(filterData)
    }
  }, [filterData])

  useEffect(() => {
    setOverlayOpen(false)
  }, [pathname])

  const onChangeSearch = (search: string) => {
    setFilter(prev => {
      return {
        ...prev,
        search,
      }
    })
  }

  return (
    <S.HeadWrapper gutter={20} align={'middle'}>
      <S.CustomBaseCol>
        <SearchDropdown
          query={filter?.search ?? ''}
          setQuery={onChangeSearch}
          data={[]}
          isOverlayOpen={isOverlayOpen}
          setOverlayOpen={setOverlayOpen}
        />
      </S.CustomBaseCol>
      {!isForUnit && (
        <>
          <S.CustomBaseCol>
            <S.SelectInputSearch
              placeholder={t(R.strings.warehouse_export)}
              onChange={value =>
                setFilter(prev => ({
                  ...prev,
                  warehouseIssueId: value,
                }))
              }
              filterOption={false}
              dropdownRender={menu => (
                <DropdownRender>
                  <SearchWrapper>
                    <SearchOutlinedStyled
                      className="search__icon"
                      rev=""
                      size={20}
                    />
                    <BaseInput
                      className="search__input"
                      placeholder={t(R.strings.search)}
                      onChange={e => setWarehouseKeyword(e?.target?.value)}
                      allowClear
                    />
                  </SearchWrapper>
                  {menu}
                </DropdownRender>
              )}
              allowClear
            >
              {warehouses?.map((supplier, index) => {
                return (
                  <Option value={supplier?._id} key={index}>
                    {supplier?.name}
                  </Option>
                )
              })}
            </S.SelectInputSearch>
          </S.CustomBaseCol>
          <S.CustomBaseCol>
            <S.SelectInputSearch
              placeholder={t(R.strings.warehouse_import)}
              onChange={value =>
                setFilter(prev => ({
                  ...prev,
                  warehouseReceiveId: value,
                }))
              }
              filterOption={false}
              dropdownRender={menu => (
                <DropdownRender>
                  <SearchWrapper>
                    <SearchOutlinedStyled
                      className="search__icon"
                      rev=""
                      size={20}
                    />
                    <BaseInput
                      className="search__input"
                      placeholder={t(R.strings.search)}
                      onChange={e => setWarehouseKeyword(e?.target?.value)}
                      allowClear
                    />
                  </SearchWrapper>
                  {menu}
                </DropdownRender>
              )}
              allowClear
            >
              {warehouses?.map((supplier, index) => {
                return (
                  <Option value={supplier?._id} key={index}>
                    {supplier?.name}
                  </Option>
                )
              })}
            </S.SelectInputSearch>
          </S.CustomBaseCol>
          <S.ColumnLine />
        </>
      )}
      <S.CustomBaseCol>
        <S.SelectBaseStyled
          placeholder={t(R.strings.status)}
          suffixIcon={<SvgTableCloseIcon />}
          allowClear
          onChange={value =>
            setFilter(prev => ({
              ...prev,
              status: Number(value) || undefined,
            }))
          }
        >
          {Object.entries(GoodsIssueStatusName).map(([key, value]) => {
            return (
              <Option value={Number(key)} key={key}>
                {t(value)}
              </Option>
            )
          })}
        </S.SelectBaseStyled>
      </S.CustomBaseCol>
      <S.CustomBaseCol>
        <S.CustomDatePicker
          placeholder={t(R.strings.choose_date)}
          onChange={value =>
            setFilter(prev => ({
              ...prev,
              startDate: dayjs(value).format(DATE.YYYY_MM_DD),
            }))
          }
        />
      </S.CustomBaseCol>
      -
      <S.CustomBaseCol>
        <S.CustomDatePicker
          placeholder={t(R.strings.choose_date)}
          onChange={value =>
            setFilter(prev => ({
              ...prev,
              endDate: dayjs(value).format(DATE.YYYY_MM_DD),
            }))
          }
        />
      </S.CustomBaseCol>
    </S.HeadWrapper>
  )
}
