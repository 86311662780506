import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import * as S from 'app/components/tables/BaseTableReport/styles'
import 'dayjs/locale/vi'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {
  CreateUpdateContext,
  CreateUpdateProvider,
} from '../TreatmentManagement/CreateUpdateProductServiceManagement/context'
import { ExpandedRowRender } from './components/ExpandedRowRender'
import { FilterItemManagement } from './components/FilterItemManagement'
import { CreateItem } from './components/modal/item-management/CreateItem'
import { useTableHook } from './hook'

import { CreateUpdateProvider as CreateUpdateProviderItem } from './CreateUpdateProductServiceManagement/context'
import { BaseTableWrapper } from './styles'

const ItemManagementContainer: React.FC = () => {
  const {
    columns,
    tableData,
    handleTableChange,
    expandedRowKeys,
    handleExpand,
    handleChangeFilter,
    exportExcel,
  } = useTableHook()

  return (
    <CreateUpdateProviderItem>
      <CreateUpdateProvider>
        <CreateUpdateContext.Consumer>
          {() => (
            <S.BaseReportWrapper>
              <HeaderPage
                titleI18nKey={R.strings.item_management}
                isExport
                onPressExport={exportExcel}
                rightComponent={<CreateItem />}
              />
              <S.RootWrapper>
                <FilterItemManagement handleChangeFilter={handleChangeFilter} />
                <BaseTableWrapper
                  columns={columns}
                  dataSource={tableData?.data}
                  loading={tableData?.loading}
                  onChange={handleTableChange}
                  expandable={{
                    expandedRowKeys,
                    onExpand: handleExpand,
                    expandIcon: () => null,
                    showExpandColumn: false,
                    expandedRowRender: record => (
                      <ExpandedRowRender record={record} />
                    ),
                  }}
                  rowClassName="row-overwrite-style"
                  pagination={tableData?.pagination}
                  scroll={{
                    x: true,
                    y: tableData?.data?.length ? 480 : undefined,
                  }}
                />
              </S.RootWrapper>
            </S.BaseReportWrapper>
          )}
        </CreateUpdateContext.Consumer>
      </CreateUpdateProvider>
    </CreateUpdateProviderItem>
  )
}

export default ItemManagementContainer
