import 'dayjs/locale/vi'
import React, { useContext, useEffect } from 'react'
import { ListTotal } from 'app/constant'
import { Row } from 'antd'
import { PageSearchAppointmentContext } from 'app/context/PageSearchAppointmentContext'
import { AppointmentContext } from 'app/context/AppointmentContext'
import { IListTotal } from '../../AppointmentPage/type'
import { ScheduleTotal } from '../../../../components/common/ScheduleTotal'
import moment from 'moment'
import { scheduleStatusSearchAppointmentFilter } from 'app/api/appointment/constant'
import { DATE } from 'app/common/constants'
import { APPOINTMENT_VIEW_TYPE } from 'app/common/enum'

const ListTotalPage: React.FC = () => {
  const { filter } = useContext(PageSearchAppointmentContext)

  const { getCallCenterScheduleTotal, appointmentTotal } =
    useContext(AppointmentContext)

  useEffect(() => {
    if (filter?.startDate) {
      getCallCenterScheduleTotal?.({
        ...filter,
        startDate: moment(filter?.startDate)?.format(DATE.YYYY_MM_DD_HH_MM),
        endDate: moment(filter?.endDate).format(DATE.YYYY_MM_DD_HH_MM),
        view: APPOINTMENT_VIEW_TYPE.LIST,
        scheduleStatus: scheduleStatusSearchAppointmentFilter,
      })
    }
  }, [filter])

  return (
    <Row
      className="p-5"
      style={{
        marginBottom: 15,
      }}
    >
      {ListTotal.map((total, index) => {
        const itemTotal: IListTotal | undefined = appointmentTotal?.find(
          (aTotal: IListTotal) => total?.status === aTotal?._id,
        )

        return (
          <ScheduleTotal
            background={total?.background}
            border={total?.border}
            label={total?.label}
            total={itemTotal ? itemTotal['total'] : total?.total}
            key={index}
          />
        )
      })}
    </Row>
  )
}

export default ListTotalPage
