import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { useHookTable } from './hook'
import { Tabs, type TabsProps } from 'antd'
import { BookingStatusEnum } from 'app/api/booking/model/management'
import { toString } from 'lodash'
import { t } from 'i18next'
import { ProfileProvider } from 'app/context/ProfileContext'
import { Create } from './components/Create'
import { Assign } from './components/Assign'

const BookingManagementContainer = () => {
  const {
    tableData,
    columns,
    rowSelection,
    handleTableChangeTable,
    fetchNewData,
    handleChangeFilter,
    showByManager,
    selectedRowKeys,
    selectedRows,
    setSelectedRows,
  } = useHookTable()

  const children = (
    <>
      <Assign
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        fetchNewData={fetchNewData}
      />
      <BaseTableManagement
        columns={columns}
        dataSource={tableData.data}
        rowSelection={{ ...rowSelection, selectedRowKeys }}
        pagination={tableData.pagination}
        loading={tableData.loading}
        onChange={handleTableChangeTable}
      />
    </>
  )

  const itemShowByManager = showByManager
    ? [
        {
          key: toString(BookingStatusEnum.ASSIGNED),
          label: t(R.strings.requested),
          children: children,
        },
      ]
    : []
  const items: TabsProps['items'] = [
    {
      key: toString(BookingStatusEnum.REQUEST),
      label: t(R.strings.created),
      children: children,
    },
    ...itemShowByManager,
    {
      key: toString(BookingStatusEnum.RECALL),
      label: t(R.strings.recall),
      children: children,
    },
    {
      key: toString(BookingStatusEnum.CONFIRM),
      label: t(R.strings.confirmed),
      children: children,
    },
    {
      key: toString(BookingStatusEnum.CANCEL),
      label: t(R.strings.cancel),
      children: children,
    },
  ]

  return (
    <ProfileProvider>
      <S.BaseManagementWrapper>
        <HeaderPage
          titleI18nKey={
            showByManager
              ? R.strings.booking_content_management
              : R.strings.booking_employee
          }
          isExport={false}
          rightComponent={showByManager && <Create fetchTable={fetchNewData} />}
        />
        <S.RootWrapper>
          <Tabs
            defaultActiveKey="1"
            items={items}
            onTabClick={value => {
              const assigned =
                showByManager && Number(value) === BookingStatusEnum.ASSIGNED
                  ? { isAssigned: true }
                  : {}
              handleChangeFilter({
                status: value as unknown as BookingStatusEnum,
                ...assigned,
              })
            }}
          />
        </S.RootWrapper>
      </S.BaseManagementWrapper>
    </ProfileProvider>
  )
}
export default BookingManagementContainer
