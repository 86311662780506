import { MoreOutlined } from '@ant-design/icons'
import { IDiscountCampaign } from 'app/api/marketing/discount-campaign/model'
import {
  DefineDiscountTypeEnum,
  DefineMethodIssueEnum,
  DefineVoucherTemplateStatusEnum,
  DefineVoucherTemplateStatusIssueEnum,
} from 'app/api/marketing/discount-voucher/constant'
import {
  DiscountTypeEnum,
  VoucherTemplateStatusEnum,
  VoucherTemplateStatusIssueEnum,
} from 'app/api/marketing/discount-voucher/enum'
import { IDiscountVoucher } from 'app/api/marketing/discount-voucher/model'
import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { BaseButton } from 'app/components/common/BaseButton'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import {
  MARKETING_ADDITIONAL_SERVICE_VOUCHER_PATH,
  MARKETING_CAMPAIGN_PATH,
  MARKETING_CREATE_VOUCHER_PATH,
  MARKETING_DETAIL_VOUCHER_PATH,
  MARKETING_LIST_VOUCHER_CODE_PATH
} from 'app/components/router/route-path'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { FORMAT_DD_MM_YYYY_HH_MM, initPagination } from 'app/constant'
import { useDebounce, usePagination } from 'app/hook'
import { useGetVoucherList } from 'app/react-query/hook/voucher'
import { isEqual } from 'lodash'
import {
  DateUtil,
  formatMoney,
  PADDING,
  Pagination,
  ResponseType
} from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'
import { RestoreVoucher, ReTryVoucher, StopApplyVoucher } from './layouts'
import { IDataTable, IFilterDataTableReport } from './type'

export const useHookTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const [pagination, setPagination] = useState(initPagination)

  const [filter, setFilter] = useState<IFilterDataTableReport>({})

  const { flattenDataList } = usePagination()

  const { user, isHavePermissionFunction, isHavePermissionFunctionAndGoBack } =
    useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.READ_DISCOUNT_VOUCHER,
      R.strings.voucher_management,
    )
  }, [user])

  const itemCampaign: IDiscountCampaign = useMemo(() => {
    return location?.state?.item
  }, [location])

  useEffect(() => {
    if (!itemCampaign) {
      navigate(MARKETING_CAMPAIGN_PATH, {
        replace: true,
      })
    }
  }, [itemCampaign])

  const filterDebounce = useDebounce<IFilterDataTableReport>(filter, 500)

  const { data: dataApi, isLoading } = useGetVoucherList({
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
    campaignId: itemCampaign?._id,
    keyword: filterDebounce?.keyword,
    status: filterDebounce?.status,
    methodIssue: filterDebounce?.methodIssue,
    statusIssue: filterDebounce?.createType,
    discountType: filterDebounce?.type,
    startDate: filterDebounce?.startDate,
    endDate: filterDebounce?.endDate,
  })

  const dataFlatten = useMemo(() => {
    const res: ResponseType<IDiscountVoucher[]> = flattenDataList(dataApi)
    return res
  }, [dataApi])

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [dataFlatten])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (newFilter: IFilterDataTableReport) => {
    setFilter(newFilter)
    setPagination({ ...pagination, current: 1 })
  }

  const navigateCreate = () => {
    navigate(MARKETING_CREATE_VOUCHER_PATH, {
      state: {
        item: itemCampaign,
      },
    })
  }

  const permission = useMemo(() => {
    const isHavePermissionCreate = isHavePermissionFunction(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.CREATE_DISCOUNT_VOUCHER,
    )

    const isHavePermissionStop = isHavePermissionFunction(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.STOP_DISCOUNT_VOUCHER,
    )

    const isHavePermissionReadVoucherCode = isHavePermissionFunction(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.READ_LIST_VOUCHER_CODE,
    )

    return {
      isHavePermissionCreate,
      isHavePermissionStop,
      isHavePermissionReadVoucherCode,
    }
  }, [user])

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IDataTable>({
        title: t(R.strings.voucher_name),
        key: 'name',
        fixed: 'left',
        render: (text?: string) => {
          return (
            <BaseText
              children={text ?? '-'}
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.method_issue_voucher),
        key: 'methodIssue',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.methodIssue
                  ? t(DefineMethodIssueEnum[record?.methodIssue]?.keyI18n)
                  : ''
              }
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.discount_type),
        key: 'discountType',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.discountType
                  ? t(DefineDiscountTypeEnum[record?.discountType]?.keyI18n)
                  : '-'
              }
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),

      convertColumnTable<IDataTable>({
        title: t(R.strings.discount_value),
        key: 'amount',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.amount
                  ? formatMoney(
                      record?.amount ?? 0,
                      isEqual(record?.discountType, DiscountTypeEnum.Percent)
                        ? '%'
                        : 'đ',
                    )
                  : '-'
              }
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.start_date),
        key: 'startDate',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.startDate
                  ? DateUtil.formatDate(
                      record?.startDate ?? '',
                      FORMAT_DD_MM_YYYY_HH_MM,
                    )
                  : '-'
              }
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.end_date),
        key: 'endDate',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.endDate
                  ? DateUtil.formatDate(
                      record?.endDate ?? '',
                      FORMAT_DD_MM_YYYY_HH_MM,
                    )
                  : '-'
              }
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.total_voucher),
        key: 'quantity',
        render: (_, record) => {
          return (
            <BaseText
              children={formatMoney(record?.quantity ?? 0, '')}
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.created_text),
        key: 'quantityIssue',
        render: (_, record) => {
          return (
            <BaseText
              children={formatMoney(record?.quantityIssue ?? 0, '')}
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.used),
        key: 'countMaxUsageVouchers',
        render: (value?: number) => {
          return (
            <BaseText
              children={formatMoney(value ?? 0, '')}
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.unused),
        key: 'countUnUseVouchers',
        render: (value?: number) => {
          return (
            <BaseText
              children={formatMoney(value ?? 0, '')}
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),

      convertColumnTable<IDataTable>({
        title: t(R.strings.total_number_of_visits),
        key: 'totalMaxUsage',
        render: (value?: number) => {
          return (
            <BaseText
              children={formatMoney(value ?? 0, '')}
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.total_number_of_used),
        key: 'used',
        render: (value?: number) => {
          return (
            <BaseText
              children={formatMoney(value ?? 0, '')}
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.total_number_of_not_used),
        key: 'quantityAvailable',
        render: (value?: number) => {
          return (
            <BaseText
              children={formatMoney(value ?? 0, '')}
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),

      convertColumnTable<IDataTable>({
        title: t(R.strings.created_at),
        key: 'createdAt',
        render: (_, record) => {
          return (
            <BaseText
              children={DateUtil.formatDate(
                record?.createdAt ?? '',
                FORMAT_DD_MM_YYYY_HH_MM,
              )}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),

      convertColumnTable<IDataTable>({
        title: t(R.strings.updated_at),
        key: 'updatedAt',
        render: (_, record) => {
          return (
            <BaseText
              children={DateUtil.formatDate(
                record?.updatedAt ?? '',
                FORMAT_DD_MM_YYYY_HH_MM,
              )}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),

      convertColumnTable<IDataTable>({
        title: t(R.strings.status_create),
        key: 'statusIssue',
        className: 'status-issue-column',
        render: (_, record) => {
          if (!record?.statusIssue) return null
          const item =
            DefineVoucherTemplateStatusIssueEnum?.[record?.statusIssue]
          return (
            <BaseStatusWrapper $backgroundImage={item?.backgroundStatus}>
              <BaseText
                children={t(item?.keyI18n)}
                colorText={item?.textStatusColor}
                fontWeight="medium"
              />
            </BaseStatusWrapper>
          )
        },
      }),

      convertColumnTable<IDataTable>({
        title: t(R.strings.status),
        key: 'status',
        fixed: 'right',
        className: 'status-column',
        render: (_, record) => {
          if (!record?.status) return null
          const item = DefineVoucherTemplateStatusEnum?.[record?.status]
          return (
            <BaseStatusWrapper $backgroundImage={item?.backgroundStatus}>
              <BaseText
                children={t(item?.keyI18n ?? record?.status ?? '')}
                colorText={item?.textStatusColor}
                fontWeight="medium"
              />
            </BaseStatusWrapper>
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: '',
        key: 'status',
        classNameWidthColumnOverwrite: 'number-column',
        fixed: 'right',
        render: (_, record) => {
          return (
            <BasePopover
              content={
                <BaseSpace>
                  <BaseButtonWrapper
                    onClick={() =>
                      navigate(MARKETING_DETAIL_VOUCHER_PATH, {
                        state: {
                          itemVoucher: record,
                          itemCampaign,
                        },
                      })
                    }
                    type="text"
                  >
                    {t(R.strings.see_detail)}
                  </BaseButtonWrapper>

                  {(() => {
                    switch (record?.status) {
                      case VoucherTemplateStatusEnum.UpComing:
                      case VoucherTemplateStatusEnum.Applying:
                      case VoucherTemplateStatusEnum.WaitingApply:
                        return (
                          <BaseButtonWrapper
                            onClick={() =>
                              navigate(
                                MARKETING_ADDITIONAL_SERVICE_VOUCHER_PATH,
                                {
                                  state: {
                                    itemVoucher: record,
                                    itemCampaign,
                                  },
                                },
                              )
                            }
                            type="text"
                          >
                            {t(R.strings.additional_service)}
                          </BaseButtonWrapper>
                        )

                      default:
                        return null
                    }
                  })()}

                  {(() => {
                    switch (record?.statusIssue) {
                      case VoucherTemplateStatusIssueEnum.Creating:
                      case VoucherTemplateStatusIssueEnum.Done:
                        if (!permission?.isHavePermissionReadVoucherCode)
                          return null

                        return (
                          <BaseButtonWrapper
                            onClick={() =>
                              navigate(MARKETING_LIST_VOUCHER_CODE_PATH, {
                                state: {
                                  item: record,
                                },
                              })
                            }
                            type="text"
                          >
                            {t(R.strings.view_voucher_code_list)}
                          </BaseButtonWrapper>
                        )

                      default:
                        return null
                    }
                  })()}

                  {(() => {
                    if (!permission?.isHavePermissionCreate) return null

                    switch (record?.statusIssue) {
                      case VoucherTemplateStatusIssueEnum.Done:
                        return null

                      default:
                        return (
                          <ReTryVoucher id={record?._id} name={record?.name} />
                        )
                    }
                  })()}

                  {(() => {
                    if (!permission?.isHavePermissionStop) return null
                    switch (record?.status) {
                      case VoucherTemplateStatusEnum.StopApply:
                        return (
                          <RestoreVoucher
                            id={record?._id}
                            name={record?.name}
                          />
                        )
                      default:
                        return (
                          <StopApplyVoucher
                            id={record?._id}
                            name={record?.name}
                          />
                        )
                    }
                  })()}
                </BaseSpace>
              }
              trigger={'click'}
            >
              <BaseButton type="text" icon={<MoreOutlined rev={undefined} />} />
            </BasePopover>
          )
        },
      }),
    ]
    return option
  }, [t, itemCampaign])

  return {
    data,
    columns,
    isLoading,
    filter,
    handleTableChange,
    pagination: {
      ...pagination,
      total: dataFlatten?.metadata?.totalItem ?? 0,
    },
    handleChangeFilter,
    navigateCreate,
    itemCampaign,
  }
}

const BaseStatusWrapper = styled.div<{
  $backgroundImage: string
}>`
  padding: 5px 10px;
  border-radius: ${PADDING.xl};
  background-image: ${props => props.$backgroundImage};
  width: fit-content;
`

const BaseButtonWrapper = styled(BaseButton)`
  width: 100%;
  justify-content: flex-start;
`
