import { Tabs } from 'antd'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import {
  MailBoxDoctorComponent,
  NewsDoctorComponent,
  NotificationDoctorComponent,
  StatisticsDoctorLayout,
  TaskComponent,
  TreatmentPlanDoctorLayout,
} from './components'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, LAYOUT, PADDING, media } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const RightSideDoctorLayout = () => {
  const { t } = useTranslation()
  return (
    <RightSideCol>
      <BaseSpace>
        <SectionBase>
          <Tabs
            defaultActiveKey={R.strings.notification}
            tabPosition={'top'}
            items={[
              {
                label: (
                  <BaseRow gutter={[8, 8]} align={'middle'}>
                    <BaseCol>
                      <BaseText
                        fontWeight="semibold"
                        fontSize="xs"
                        children={t(R.strings.notification)}
                      />
                    </BaseCol>
                    <BaseCol>
                      <NumberWrapper>
                        <BaseText
                          children={'3'}
                          fontWeight="semibold"
                          colorText="statesOrangeColor"
                        />
                      </NumberWrapper>
                    </BaseCol>
                  </BaseRow>
                ),
                key: R.strings.notification,
                children: <NotificationDoctorComponent />,
              },
              {
                label: (
                  <BaseRow gutter={[8, 8]} align={'middle'}>
                    <BaseCol>
                      <BaseText
                        fontWeight="semibold"
                        fontSize="xs"
                        children={t(R.strings.news)}
                      />
                    </BaseCol>
                  </BaseRow>
                ),
                key: R.strings.news,
                children: <NewsDoctorComponent />,
              },
            ]}
          />
        </SectionBase>
        <TaskComponent />
        <TreatmentPlanDoctorLayout />
        <MailBoxDoctorComponent />
        <StatisticsDoctorLayout />
      </BaseSpace>
    </RightSideCol>
  )
}

const RightSideCol = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: ${BORDER_RADIUS};
  margin-left: ${PADDING.md};
  min-width: 260px;
  @media only screen and ${media.xl} {
    height: calc(100vh - ${LAYOUT.desktop.headerHeight} - 10vh);
    overflow: auto;
  }
  .ant-tabs-ink-bar {
    background-color: ${convertedVariables.statesOrangeColor};
  }
`

const NumberWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: ${PADDING.lg};
  background-color: ${convertedVariables.statesOrangeLightColor};
  display: flex;
  align-items: center;
  justify-content: center;
`
