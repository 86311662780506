import { DefineUnWorkingDayTypeEnum } from 'app/api/hr/constant'
import { UnWorkingDayTypeEnum } from 'app/api/hr/models/approve-day-off'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { DownArrow } from 'app/components/header/components/profile/ProfileDropdown/styles'
import { TypeDayOffWrapperStyled } from 'app/containers/HumanResources/ApproveDayOff/components'

export const NotePopover = () => {
  return (
    <BaseRow align={'middle'} gutter={[4, 4]} wrap={false}>
      <BaseCol>
        <BaseText children={'Chú thích'} fontWeight="medium" />
      </BaseCol>
      <BaseCol>
        <BasePopover
          trigger={'click'}
          content={
            <BaseSpace>
              <BaseRow align={'middle'} justify={'start'} gutter={[8, 8]}>
                <BaseCol>
                  <TypeDayOffWrapperStyled
                    $backgroundColor={
                      DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.DO]
                        .backgroundLight
                    }
                    $color={
                      DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.DO].color
                    }
                  >
                    {`${
                      DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.DO].text
                    }: 2`}
                  </TypeDayOffWrapperStyled>
                </BaseCol>

                <BaseCol>
                  <BaseText
                    children={'Số người đăng ký DO đã duyệt'}
                    fontWeight="medium"
                    fontSize="xs"
                  />
                </BaseCol>
              </BaseRow>
              <BaseRow align={'middle'} justify={'start'} gutter={[8, 8]}>
                <BaseCol>
                  <TypeDayOffWrapperStyled
                    $backgroundColor={
                      DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.UL]
                        .backgroundLight
                    }
                    $color={
                      DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.UL].color
                    }
                  >
                    {`${
                      DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.UL].text
                    }: 2`}
                  </TypeDayOffWrapperStyled>
                </BaseCol>

                <BaseCol>
                  <BaseText
                    children={'Số người đăng ký UL đã duyệt'}
                    fontWeight="medium"
                    fontSize="xs"
                  />
                </BaseCol>
              </BaseRow>
            </BaseSpace>
          }
          placement="bottomLeft"
        >
          <BaseButton
            type="text"
            icon={<DownArrow rev={undefined} />}
            size="small"
          />
        </BasePopover>
      </BaseCol>
    </BaseRow>
  )
}
