import { BaseButton } from 'app/components/common/BaseButton'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { InputPassword } from 'app/components/common/inputs/InputPassword'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const ButtonCreateAction = styled(BaseButton)`
  background-color: var(--states-orange-color);
  color: var(--background-color);
  transition: all 0.5s ease;
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--background-color);
    color: var(--states-orange-color);
    border-color: var(--states-orange-color);
    transition: all 0.5s ease;
  }
`
export const LabelWrapper = styled(BaseRow)`
  color: var(--main-2-color);
`

export const RequireDot = styled.div`
  color: var(--states-red-color);
`
export const DashLine = styled(BaseDivider)``

export const TitleForm = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.bold};
`

export const FormItem = styled(BaseForm.Item)``

export const FormInput = styled(BaseInput)``

export const FormInputNumber = styled(InputNumber)`
  width: 100%;
`
export const FormInputPassword = styled(InputPassword)``

export const SelectBaseStyled = styled(BaseSelect)``
