// Message code define
export const MarketingMessageCode = {
  MarketingPlan: {
    successImport: '20001',
    successGetData: '20001',
  },
}

// Endpoint
export const MarketingEndPoint = {
  MarketingPlan: {
    requestImportFile: 'report/v1/marketingplan/import',
    requestGetData: 'report/v1/marketingplan/getGroupByService',
  },
}

// const Key import
export const KeyMarketingImport = {
  All: {
    periodType: 'periodtype',
    importType: 'importtype',
    file: 'file',
  },
}
