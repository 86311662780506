// Message code define
export const RangeExperienceMessageCode = {
  RangeExperienceManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20001',
  },
}

// Endpoint
export const RangeExperienceEndPoint = {
  RangeExperienceManagement: {
    request: 'hr/v1/rangeexperience',
  },
}
