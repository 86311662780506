import { Carousel } from 'antd'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  border-radius: ${BORDER_RADIUS};
  background-color: var(--white);
  padding: ${PADDING.md};
`
export const CarouselWrapper = styled(Carousel)`
  border-radius: ${BORDER_RADIUS};
  background-color: var(--states-orange-color);
  .slick-dots-bottom {
    bottom: -20px;
    position: absolute;
  }

  .slick-dots-bottom li {
    width: 6px !important;
    height: 6px !important;
    background-color: #d9d9d9; //change
    border-radius: ${BORDER_RADIUS};
  }

  .slick-dots-bottom .slick-active {
    background-color: var(--other-blue-color);
    border-radius: ${BORDER_RADIUS};
    opacity: 1;
  }

  .slick-dots-bottom li.slick-active button {
    background-color: transparent;
    border-radius: ${BORDER_RADIUS};
  }
`

export const ItemWrapper = styled.div`
  height: 98px;
  width: fit-content;
  padding: ${PADDING.xs};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TitleItemText = styled.p`
  color: var(--white);
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.xs};
`

export const DescriptionItemText = styled.span`
  color: var(--white);
  font-weight: ${FONT_WEIGHT.medium};
  font-size: ${FONT_SIZE.xxs};
`

export const HeadWrapper = styled(BaseRow)`
  padding-bottom: ${PADDING.xl};
`

export const TitleText = styled.span`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
`
export const CountWrapper = styled.span`
  border-radius: ${BORDER_RADIUS};
  background-color: #f3f4f6; //change
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
  padding: 3px ${PADDING.xxxxs};
`
