import { InfoCircleOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { SvgInformationCircleOrangeIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { PADDING, formatMoney } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as S from './styles'
export const HeadLayout = () => {
  const { t } = useTranslation()
  return (
    <RootWrapper gutter={[15, 10]} justify={'space-between'}>
      <BaseCol>
        <S.SpaceWrapper direction="vertical" size={5}>
          <BaseText opacity="0.7" fontSize={'xxxs'}>
            {t(R.strings.order_id)}
          </BaseText>
          <BaseRow gutter={15} align={'middle'}>
            <BaseCol>
              <BaseText
                fontWeight={'semibold'}
                colorText={'statesOrangeColor'}
                fontSize="xs"
              >
                {'PWMH8888888'}
              </BaseText>
            </BaseCol>
            <BaseCol>
              <InfoCircleOutlined rev={undefined} />
            </BaseCol>
          </BaseRow>
        </S.SpaceWrapper>
      </BaseCol>
      <BaseCol>
        <S.SpaceWrapper
          direction="vertical"
          size={5}
          style={{ alignItems: 'flex-end' }}
        >
          <BaseText opacity="0.7" fontSize={'xxxs'} fontWeight="medium">
            {t(R.strings.monthly_installment_plan)}
          </BaseText>
          <S.MonthlyInstallmentPlan gutter={10} align={'middle'}>
            <BaseCol>
              <BaseText colorText="statesGreenColor" fontWeight="medium">
                {formatMoney('1000')}
              </BaseText>
            </BaseCol>

            <BaseCol>
              <BaseText fontWeight={'medium'}>
                {`/ ${formatMoney('2000')}`}
              </BaseText>
            </BaseCol>

            <SvgInformationCircleOrangeIcon />
          </S.MonthlyInstallmentPlan>
        </S.SpaceWrapper>
      </BaseCol>
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
`
