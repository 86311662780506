import * as S from 'app/components/tables/BaseTableManagement/styles'
import HeaderPage from 'app/components/header/components/HeaderPage'
import R from 'app/assets/R'
import CompanyInfo from '../components/Info'
import BuyInfo from '../components/BuyInfo'
import OrderInfo from '../components/OrderInfo'
import { WarehouseGoodsIssueContext } from '../../context'
import Footer from '../components/Footer'
import { useParams } from 'react-router'
import { useContext, useEffect } from 'react'
import { cloneDeep, get } from 'lodash'
import { requestDetailWarehouseGoodsIssueManagement } from 'app/api/inventory-new/api/warehouse-goods-issue'
import dayjs from 'dayjs'
import { notificationController } from 'app/controllers/notification-controller'

const Return = () => {
  const params = useParams()
  const { setOrders, form, setWarehouseGoodsIssueDetail, setDiscount } =
    useContext(WarehouseGoodsIssueContext)
  const getWarehouseGoodsIssueDetail = async () => {
    try {
      const { data } = await requestDetailWarehouseGoodsIssueManagement(
        params?.id,
      )

      const modifyData = cloneDeep(data)
      delete modifyData._id

      const orders = modifyData?.orders?.map(order => ({
        ...order,
        name: order?.product?.name,
        code: order?.product?.code,
        purchaseMeasurementUnit: order?.measurementUnit?.name,
        measurementUnitId: order?.measurementUnitId,
      }))

      setOrders?.(orders)
      await form?.setFieldsValue({
        ...modifyData,
        createAtDate: dayjs(data?.createAtDate),
        warehouseIssueId: data?.warehouseReceiveId,
        warehouseReceiveId: data?.warehouseIssueId,
      })

      setWarehouseGoodsIssueDetail?.({
        ...modifyData,
        createAtDate: dayjs(data?.createAtDate),
        warehouseIssueId: data?.warehouseReceiveId,
        warehouseReceiveId: data?.warehouseIssueId,
      })

      setDiscount?.(modifyData?.discount)
    } catch (error) {
      notificationController.error({
        message: get(error, 'message', '')
      })
    }
  }

  useEffect(() => {
    getWarehouseGoodsIssueDetail()
  }, [])

  return (
    <>
      <S.BaseManagementWrapper>
        <HeaderPage
          titleI18nKey={R.strings.warehouse_goods_issue_add_return}
          rightComponent={<></>}
        />
        <div>
          <div>
            <CompanyInfo />
            <BuyInfo />
          </div>
        </div>

        <div>
          <div>
            <OrderInfo />
          </div>
        </div>
      </S.BaseManagementWrapper>

      <Footer />
    </>
  )
}

export default Return
