import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const TreatmentInformationLayout = () => {
  const { t } = useTranslation()
  return (
    <BaseSpace>
      <BaseText
        children={t(R.strings.lasted_appointment)}
        fontWeight="semibold"
        fontSize="md"
      />

      <TreatmentInfoWrapper>
        <BaseSpace style={{ padding: PADDING.xxs }}>
          <BaseText
            children={'Chi nhánh Nam Kỳ - 12/11/2023 (15:00)'}
            fontWeight="medium"
            fontSize="xs"
          />
          <BaseText children={t(R.strings.content)} fontWeight="semibold" />
          <BaseText
            children={'Thay chain đóng khoảng, kiểm tra cân nhắc TMC'}
          />
          <BaseText children={t(R.strings.note)} fontWeight="semibold" />
          <BaseText
            children={
              '1 Tiếng 30 phút --- > bs Tiên => bs Trang ---BN có lịch 20/10 không đến được, Bn chỉ đi được chủ nhật do có việc bận và bác sĩ off chủ nhật nên dời lại lịch hẹn hôm nay ạ.'
            }
          />
        </BaseSpace>
      </TreatmentInfoWrapper>
    </BaseSpace>
  )
}

const TreatmentInfoWrapper = styled(BaseSpace)`
  border-radius: ${BORDER_RADIUS};
  background-color: #fcfcfd;
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  height: 100%;
`
