import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormRule, IPropsUpdateWarehouse } from './type'
import { IWarehouse } from 'app/api/inventory-new/model/management'
import { requestUpdateWarehouseManagement } from 'app/api/inventory-new'

export const useModalHook = (props: IPropsUpdateWarehouse) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IWarehouse>()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(props?.warehouse)
    }
  }, [visible])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IWarehouse) => {
    setIsLoading(true)
    try {
      await requestUpdateWarehouseManagement({
        id: props?.warehouse?._id,
        body: values,
      })

      setIsLoading(false)
      handleCancel()
      props?.fetchTable?.()
      notificationController.success({
        message: t(R.strings.provider_update),
        description: t(R.strings.success),
      })

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const rules: IFormRule = useMemo(() => {
    return {
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.warehouse_name),
          }),
        },
      ],
      code: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.warehouse_code),
          }),
        },
      ],
      roadWarehouseCode: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.warehouse_road_code),
          }),
        },
      ],
      unitId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.branch),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
  }
}
