import { requestUpdateBookingManagement } from 'app/api/booking'
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormData, IFormRule, IPropsRecallBooking } from './type'
import {
  IIndustry,
  IPayloadUpdateBookingManagement,
  BookingStatusEnum,
} from 'app/api/booking/model/management'
import { BookingMessageCode } from 'app/api/booking/constant'
import { requestGetAllIndustryManagement } from 'app/api/industry'
import { IndustryMessageCode } from 'app/api/industry/constant'
import { dayjsToString } from 'app/helpers/date.helper'

export const useModalHook = (props: IPropsRecallBooking) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormData>()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [industries, setIndustry] = useState<IIndustry[]>()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)
    try {
      const body: IPayloadUpdateBookingManagement = {
        status: BookingStatusEnum.RECALL,
        recallAt: values.recallAt ? dayjsToString(values.recallAt) : undefined,
      }

      const response: ResponseType<string> =
        await requestUpdateBookingManagement({
          id: props?.id,
          body,
        })

      if (
        isEqual(
          response?.msgcode,
          BookingMessageCode.BookingManagement.successPutData,
        )
      ) {
        setIsLoading(false)
        notificationController.success({
          message: t(R.strings.update),
          description: response?.message,
        })
        handleCancel()
      } else {
        notificationController.error({
          message: t(R.strings.update),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const getAllIndustry = async () => {
    try {
      const res: ResponseType<IIndustry[]> =
        await requestGetAllIndustryManagement()

      if (
        isEqual(
          res?.msgcode,
          IndustryMessageCode.IndustryManagement.successGetData,
        )
      ) {
        const data: IIndustry[] = res?.data || []
        setIndustry(data)
      }
    } catch (error) {
     // console.log({ error })
    }
  }

  useEffect(() => {
    visible && getAllIndustry()
  }, [visible])

  const rules: IFormRule = useMemo(() => {
    return {
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.booking_field_name),
          }),
        },
      ],
      phone: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.booking_field_phone),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    industries,
  }
}
