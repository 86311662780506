import { ApiClientHr } from 'app/service/api-service'
import {
  IParamsGetJobTitleLevelManagement,
  IPayloadCreateJobTitleLevelManagement,
  IPayloadUpdateJobTitleLevelManagement,
} from './model/job-title-level-management'
import { JobTitleLevelEndPoint } from './constant'

export const requestGetJobTitleLevelManagement = (params: IParamsGetJobTitleLevelManagement) =>
  ApiClientHr.Get({
    url: JobTitleLevelEndPoint.JobTitleLevelManagement.request,
    params: { params },
  })

export const requestCreateJobTitleLevelManagement = (
  body: IPayloadCreateJobTitleLevelManagement,
) =>
  ApiClientHr.Post({
    url: JobTitleLevelEndPoint.JobTitleLevelManagement.request,
    body,
  })

export const requestUpdateJobTitleLevelManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateJobTitleLevelManagement
}) =>
  ApiClientHr.Put({
    url: `${JobTitleLevelEndPoint.JobTitleLevelManagement.request}/${id}`,
    body,
  })

export const requestDetailJobTitleLevelManagement = (id?: string | number) =>
  ApiClientHr.Get({
    url: `${JobTitleLevelEndPoint.JobTitleLevelManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteJobTitleLevelManagement = (id?: string | number) =>
  ApiClientHr.Delete({
    url: `${JobTitleLevelEndPoint.JobTitleLevelManagement.request}/${id}`,
    body: {},
  })

export const requestGetAllJobTitleLevelManagement = () =>
  ApiClientHr.Get({
    url: `${JobTitleLevelEndPoint.JobTitleLevelManagement.request}/get-all`,
    params: {},
  })
