import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'
import { randomMoney } from 'app/components/tables/common-table/constant'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_WEIGHT, formatMoney } from 'parkway-web-common'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CollapseComponent from '../../components/CollapseComponent'
import { useProfilePatientContext } from '../../context'
import { TagStatusComponent } from '../TreatmentSchedule/components'
import OrderItem from './OrderItem'
import * as S from './styles'

const PaymentDebt = () => {
  const { t } = useTranslation()

  const { debtPayment } = useProfilePatientContext()

  const items = useMemo(() => {
    const style: React.CSSProperties = {
      fontWeight: FONT_WEIGHT.semibold,
    }

    const key = R.strings.payment_debt

    return [
      {
        key: key,
        label: (
          <BaseText children={t(key)} fontSize="md" fontWeight="semibold" />
        ),
        extra: (
          <TagStatusComponent
            color={convertedVariables.statesRedColor}
            backgroundColor={convertedVariables?.statesRedLightColor}
            textKeyI18n={formatMoney(randomMoney())}
          />
        ),
        children: (
          <S.BaseSpaceWrapper direction="vertical" size={15}>
            {debtPayment?.orderDebtPayments?.map((item, index) => {
              return <OrderItem orderItem={item} key={index} />
            })}
          </S.BaseSpaceWrapper>
        ),
        style,
      },
    ]
  }, [t, debtPayment?.orderDebtPayments])

  return <CollapseComponent items={items} expandIconPosition="end" />
}
export default PaymentDebt
