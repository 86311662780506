import { BaseSpace } from 'app/components/common/BaseSpace'
import { BundleBillDiscountLayout, ListBillDiscountLayout } from './layouts'

export const TotalBillTypeLayout = () => {
  return (
    <BaseSpace>
      <ListBillDiscountLayout />
      <BundleBillDiscountLayout />
    </BaseSpace>
  )
}
