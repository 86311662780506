import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import WarehousePurchaseContainer from 'app/containers/InventoryManagement/WarehousePurchase'

const WarehousePurchaseContainerPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.inventory_management)}</PageTitle>
      <WarehousePurchaseContainer />
    </>
  )
}
const WarehousePurchaseContainerPage = memo(WarehousePurchaseContainerPageCpn, isEqual)

export default WarehousePurchaseContainerPage
