import R from 'app/assets/R'
import {
  ExportOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { useTranslation } from 'react-i18next'
import { ButtonAction } from 'app/components/header/components/HeaderPage/styles'
import { useState } from 'react'

const Export: React.FC<{
  onPressExport: () => void
}> = ({ onPressExport }) => {
  const { t } = useTranslation()
  const [exportLoading, setExportLoading] = useState(false)

  const handleExport = async () => {
    setExportLoading(true)
    await onPressExport()
    setExportLoading(false)
  }

  return (
    <>
      <BaseCol>
        <ButtonAction onClick={handleExport}>
          <BaseRow gutter={8}>
            <BaseCol>
              <ExportOutlined rev={undefined} />
            </BaseCol>
            <BaseCol>{t(R.strings.export_excel)}</BaseCol>
            <BaseCol>
              {exportLoading && <LoadingOutlined rev={undefined} />}
            </BaseCol>
          </BaseRow>
        </ButtonAction>
      </BaseCol>
    </>
  )
}

export default Export
