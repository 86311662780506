import { requestGetServices } from 'app/api/treatment-group'
import { ITreatmentGroup } from 'app/api/treatment-group/model/management'
import { ITreatment } from 'app/containers/Accounting/ItemManagement/model/treatment'
import { notificationController } from 'app/controllers/notification-controller'
import { get } from 'lodash'
import { createContext, useState } from 'react'

interface ITreatmentGroupContext {
  loading: boolean
  TreatmentGroup?: ITreatmentGroup[]
  services: ITreatment[]
  setLoading?: (loading) => void
  setServices?: (services) => void
  fetchServices?: (params) => void
}
export const TreatmentGroupContext = createContext<ITreatmentGroupContext>({
  loading: false,
  TreatmentGroup: [],
  services: [],
})

export const TreatmentGroupProvider = ({ children }) => {
  const [services, setServices] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchServices = async params => {
    setLoading(true)
    try {
      const response = await requestGetServices(params)
      setServices(response?.data)
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }
    setLoading(false)
  }

  return (
    <TreatmentGroupContext.Provider
      value={{ services, loading, setLoading, fetchServices, setServices }}
    >
      {children}
    </TreatmentGroupContext.Provider>
  )
}
