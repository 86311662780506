import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BuyOneGetOneItem } from 'app/containers/Marketing/DiscountCampaignManagement/CreateCampaign/type'
import styled from 'styled-components'

export const LabelCollapseComponent = ({
  item,
}: {
  item: BuyOneGetOneItem
  onChange?: (value: string) => void
}) => {
  return (
    <div>
      <BaseRow gutter={[16, 8]} align={'middle'}>
        <BaseCol>
          <div
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <BaseFormItem hiddenLabel>
              <BaseText
                children={item?.name ?? 'Chưa nhập tên'}
                fontWeight={item?.name ? 'semibold' : 'medium'}
                opacity={item?.name ? '1' : '0.5'}
                fontSize={item?.name ? 'xs' : 'xxs'}
              />
            </BaseFormItem>
          </div>
        </BaseCol>
      </BaseRow>
    </div>
  )
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
