import R from 'app/assets/R'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { useHookTable } from './hook'
import { BaseRow } from 'app/components/common/BaseRow'
import { useTranslation } from 'react-i18next'
import { TotalPage } from './styles'
import { FilterManagement } from './components/FilterManagementTable'
import { ItemCategoryProvider } from 'app/context/ItemCategoryContext'
import { WarehouseGoodsIssueProvider } from './context'
import Header from './components/Header'

const WarehouseGoodsIssueContainer = () => {
  const { tableData, columns, handleTableChange, filter, handleChangeFilter } =
    useHookTable()

  const { t } = useTranslation()

  return (
    <WarehouseGoodsIssueProvider>
      <ItemCategoryProvider>
        <S.BaseManagementWrapper>
          <Header handleChangeFilter={handleChangeFilter} filter={filter} />
          <S.RootWrapper>
            <BaseRow>
              <FilterManagement
                handleChangeFilter={handleChangeFilter}
                filterData={filter}
              />
            </BaseRow>
            <BaseTableManagement
              columns={columns}
              dataSource={tableData.data}
              pagination={{
                ...tableData.pagination,
                showTotal: total => {
                  return (
                    <TotalPage>
                      {t(R.strings.total_page, {
                        total,
                      })}
                    </TotalPage>
                  )
                },
              }}
              loading={tableData.loading}
              onChange={handleTableChange}
              expandable={{
                expandIcon: () => null,
              }}
            />
          </S.RootWrapper>
        </S.BaseManagementWrapper>
      </ItemCategoryProvider>
    </WarehouseGoodsIssueProvider>
  )
}
export default WarehouseGoodsIssueContainer
