import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import QRGenerateContainer from 'app/containers/Utilities/QRGenerate'

const QRGeneratePageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.qr_generate)}</PageTitle>
      <QRGenerateContainer />
    </>
  )
}
const QRGeneratePage = memo(QRGeneratePageCpn, isEqual)

export default QRGeneratePage
