import { StatusTableType } from '../hook'

export interface IResponseFilter {
  status?: StatusTableType[]
  search?: string
}

export interface IFilterProps {
  handleChangeFilter?: (data: IResponseFilter) => void
  filterData?: IResponseFilter
  placeholderSearch?: string
  placeholderFilterStatus?: string
  otherFilterComponent?: React.ReactNode
  xlSize?: number
}

export const initResponseFilterTableManagement = {
  search: '',
  status: [],
  warehouseId: undefined
}
