import { Pagination } from 'parkway-web-common'

export const initialPagination: Pagination = {
  current: 1,
  pageSize: 20,
}


interface Treatment {
  name?: string;
  _id?: string;
  groupId?: string;
  treatmentPrice?: number;
  treatmentDate?: string;
  paymentMethod?: string
}

interface AllTotal {
  _id?: string;
  totalPaid?: number;
  totalNetAmount?: number;
  totalRefund?: number;
  remain?: number;
}

export interface IGeneralDebtContractTable {
  _id?: string;
  __v?: number;
  date?: string;
  dateStartProcedures?: string[];
  deposit?: number;
  location?: string;
  netAmount?: number;
  paidType?: string;
  patientCode?: string;
  patientId?: string;
  patientName?: string;
  refund?: number;
  treatments?: Treatment;
  allTotal?: AllTotal;
  totalNetAmount?: number;
  totalRefund?: number;
  totalPaid?: number;
  dateStartProcedure?: string;
  dateEndProcedure?: string;
  endProcedureTreatmentName?: string;
  manage_customer_debt_refund_total?: string;
}
