import { ApiClientTreatment } from 'app/service/api-service'
import { EndPoint } from '../constant'
import { IParamsGetWarehouseGoodsIssueManagement, IPayloadCreateWarehouseGoodsIssueManagement, IPayloadUpdateWarehouseGoodsIssueManagement } from '../model/warehouse-goods-issue'



export const requestGetWarehouseGoodsIssueManagement = (
  params: IParamsGetWarehouseGoodsIssueManagement,
) =>
  ApiClientTreatment.Get({
    url: EndPoint.WarehouseGoodsIssueManagement.request,
    params: { params },
  })

export const requestCreateWarehouseGoodsIssueManagement = (
  body: IPayloadCreateWarehouseGoodsIssueManagement,
) =>
  ApiClientTreatment.Post({
    url: EndPoint.WarehouseGoodsIssueManagement.request,
    body,
  })

export const requestUpdateWarehouseGoodsIssueManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateWarehouseGoodsIssueManagement
}) =>
  ApiClientTreatment.Patch({
    url: `${EndPoint.WarehouseGoodsIssueManagement.request}/${id}`,
    body,
  })

export const requestDetailWarehouseGoodsIssueManagement = (
  id?: string | number,
) =>
  ApiClientTreatment.Get({
    url: `${EndPoint.WarehouseGoodsIssueManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteWarehouseGoodsIssueManagement = (
  id?: string | number,
) =>
  ApiClientTreatment.Delete({
    url: `${EndPoint.WarehouseGoodsIssueManagement.request}/${id}`,
    body: {},
  })

export const requestExportWarehouseGoodsIssueManagement = (
  params: IParamsGetWarehouseGoodsIssueManagement,
) =>
  ApiClientTreatment.Get({
    url: `${EndPoint.WarehouseGoodsIssueManagement.request}/export`,
    params: { params },
  })

export const requestWarehouseGoodIssueImportProduct = (
    body: FormData,
  ) =>
    ApiClientTreatment.Post({
      url: `${EndPoint.WarehouseGoodsIssueManagement.request}/import-product`,
      body,
    })
