import { DownOutlined } from '@ant-design/icons'
import { BasePopover } from 'app/components/common/BasePopover'
import { FONT_WEIGHT, colors, media } from 'parkway-web-common'
import styled from 'styled-components'
import { HeaderActionWrapper } from '../../../styles'

export const ProfileDropdownHeader = styled(HeaderActionWrapper)`
  cursor: pointer;

  @media only screen and ${media.md} {
    border-radius: 50px;
    padding: 0.3125rem 0.2rem;
  }
`

export const TitleProfile = styled(HeaderActionWrapper)`
  color: ${colors.primary};
  font-weight: bold;
  font-size: 12px;
`

export const PositionProfile = styled(HeaderActionWrapper)`
  color: ${colors.primary};
  font-weight: ${FONT_WEIGHT.medium};
  font-size: 10px;
  display: inline-block;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  line-height: 1 !important;
`

export const DownArrow = styled(DownOutlined)`
  color: var(--text-secondary-color);

  @media only screen and ${media.md} {
    color: var(--text-main-color);
  }
`

export const BasePopoverCustom = styled(BasePopover)`
  .ant-popover-inner {
    background-color: blue;
  }
`
