/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetProductPropertyManagement } from 'app/api/product-property'
import { ProductPropertyMessageCode } from 'app/api/product-property/constant'
import {
  IProductProperty,
  IResponseGetProductProperty,
} from 'app/api/product-property/model/management'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { useInfiniteQuery } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { ProductPropertyKeys } from '../query-key/product-property'

export function useGetProductPropertyTreatmentList(enabled = true) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IProductProperty[]>, undefined>(
    ProductPropertyKeys.listProductProperty(),
    async () => {
      try {
        const res: IResponseGetProductProperty =
          await requestGetProductPropertyManagement()

        if (
          isEqual(
            res?.msgcode,
            ProductPropertyMessageCode.ProductPropertyManagement.successGetData,
          )
        ) {
          const data: IProductProperty[] = res?.data || []

          return {
            data,
            total: res.metadata.totalItem || 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
