import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import { FilterReport } from 'app/components/tables/BaseTableReport/FilterReport'
import * as S from 'app/components/tables/BaseTableReport/styles'
import { URL_DOWNLOAD_EXAMPLE } from 'app/constant'
import 'dayjs/locale/vi'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTableHook } from './hook'

const CustomerCarePlan: React.FC = () => {
  const {
    columns,
    tableData,
    handleTableChange,
    handleChangeFilter,
    onClickImport,
  } = useTableHook()

  return (
    <S.BaseReportWrapper>
      <HeaderPage
        titleI18nKey={R.strings.customer_care_plan}
        isImport
        urlExample={URL_DOWNLOAD_EXAMPLE.CustomerCarePlan}
        nameFileExample={'CustomerCarePlan'}
        onPressImport={onClickImport}
      />
      <S.RootWrapper>
        <S.HeadWrapper>
          <FilterReport
            isSelectFilter
            handleChangeFilter={handleChangeFilter}
            isUsingWeekMonthCorrect
            selectFilterGroup="WEEK_MONTH"
          />
        </S.HeadWrapper>
        <BaseTableReport
          columns={columns}
          dataSource={tableData.data}
          loading={tableData.loading}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
          pagination={tableData.pagination}
        />
      </S.RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default CustomerCarePlan
