import { Flex, Popover } from 'antd'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import {
  ButtonQuantityPopover,
  ButtonQuantityPopoverStatus,
  QuantityPopoverText,
  QuantityPopoverTitle,
  QuantityPopoverTable,
  QuantityPopoverClose,
  QuantityPopoverCode,
  TotalPage,
} from './styles'
import {
  EWarehouseInventoryType,
  IWarehouseInventoryQuantityItem,
  WarehouseInventoryType,
} from './type'
import moment from 'moment'
import { DATE } from 'app/common/constants'
import { SvgCloseIcon, SvgInformationCircleIcon } from 'app/assets/svg-assets'
import { useHookTableManagement } from 'app/components/tables/BaseTableManagement/hook'
import {
  EWarehouseInventoryColumnType,
  IGeneralWarehouseInventoryManagementTable,
} from '../../type'
import { useState } from 'react'
import { Pagination, initialPagination } from 'parkway-web-common'
import { IResponseFilter } from '../FilterManagementTable/type'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { isEmpty, isEqual } from 'lodash'
import { MessageCode } from 'app/api/inventory-new/constant'
import {
  requestWarehouseInventoryAvailable,
  requestWarehouseInventoryOutbound,
} from 'app/api/inventory-new/api/warehouse-inventory'
import { IResponseGetWarehouseInventoryQuantityItem } from 'app/api/inventory-new/model/warehouse-inventory'

const QuantityPopoverContent = (t, type, params) => {
  const [filter] = useState<IResponseFilter>({
    search: '',
    ...params,
  })

  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IResponseFilter,
  ) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IResponseGetWarehouseInventoryQuantityItem>
    try {
      let params = {
        page: pagination?.current,
        pagesize: pagination?.pageSize,
        keyword: newFilter?.search ?? filter?.search,
      }

      params = { ...filter, ...params, ...newFilter }

      let response: IResponseGetWarehouseInventoryQuantityItem | null = null

      if (type === EWarehouseInventoryColumnType.OUTBOUND) {
        response = await requestWarehouseInventoryOutbound(params)
      } else {
        response = await requestWarehouseInventoryAvailable(params)
      }

      if (isEqual(response?.msgcode, MessageCode.successGetData)) {
        const data = response?.data
        const metadata = response?.metadata

        const startIndex =
          ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

        const transformData = data => {
          if (isEmpty(data)) {
            return data
          }

          return data?.map((item, index) => {
            return {
              key: `${item?._id}-${index + 1}`,
              _id: item?._id ?? '-',
              ordinalNumber: startIndex + index + 1,
              ...item,
            }
          })
        }

        const dataTables = transformData(data)

        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pageSize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IGeneralWarehouseInventoryManagementTable>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, fetch } = useHookTableManagement({ getDataTable })

  const handleTableChange = pagination => {
    fetch(pagination, filter)
  }

  const code =
    type === EWarehouseInventoryColumnType.OUTBOUND
      ? [
          {
            title: t(R.strings.transferred_goods_code),
            key: 'code',
            render: (_, record) => (
              <QuantityPopoverCode>{record?.code}</QuantityPopoverCode>
            ),
          },
        ]
      : []
  const columns = [
    ...code,
    {
      title: t(R.strings.day),
      key: 'createdAt',
      render: (_, record) => (
        <QuantityPopoverText>
          {moment(record?.createdAt).format(DATE.YYYY_MM_DD_HH_MM)}
        </QuantityPopoverText>
      ),
    },
    {
      title: t(R.strings.quantity),
      key: 'quantity',
      render: (_, record) => (
        <QuantityPopoverText>{record?.quantity}</QuantityPopoverText>
      ),
    },
    {
      title: t(R.strings.item_management_content_type),
      key: 'type',
      render: (_, record) => {
        return (
          <ButtonQuantityPopoverStatus
            $isReceipt={record?.type === EWarehouseInventoryType.GOODS_RECEIPT}
          >
            &#x2022; {t(WarehouseInventoryType[record?.type])}
          </ButtonQuantityPopoverStatus>
        )
      },
    },
  ]

  return (
    <>
      <QuantityPopoverTable
        dataSource={tableData.data}
        columns={columns}
        pagination={{
          ...tableData.pagination,
          showTotal: total => {
            return (
              <TotalPage>
                {t(R.strings.total_page, {
                  total,
                })}
              </TotalPage>
            )
          },
          hideOnSinglePage: true,
        }}
        onChange={handleTableChange}
      ></QuantityPopoverTable>
    </>
  )
}

export const QuantityPopover = ({
  title,
  type,
  productId,
  warehouseId,
}: IWarehouseInventoryQuantityItem) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <Popover
      content={() =>
        QuantityPopoverContent(t, type, {
          warehouseId,
          productId,
        })
      }
      trigger="click"
      title={
        <Flex justify="space-between" align="center">
          <QuantityPopoverTitle>{title}</QuantityPopoverTitle>

          <QuantityPopoverClose onClick={() => setOpen(false)}>
            <SvgCloseIcon />
          </QuantityPopoverClose>
        </Flex>
      }
      open={open}
      onOpenChange={open => setOpen(open)}
    >
      <ButtonQuantityPopover>
        <SvgInformationCircleIcon />
      </ButtonQuantityPopover>
    </Popover>
  )
}
