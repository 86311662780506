import { FormInstance } from 'antd'
import { IWarehouseGoodsIssue } from 'app/api/inventory-new/model/warehouse-goods-issue'
import { IItem } from 'app/api/item/model'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { INVENTORY_WAREHOUSE_GOODS_ISSUE_FOR_UNIT_PATH } from 'app/components/router/route-path'
import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react'
import { useLocation } from 'react-router'

export interface IOrderItem extends IItem {
  quantity?: number
  price?: number
}
interface IWarehouseGoodsIssueContext {
  orders?: IOrderItem[]
  setOrders?: Dispatch<SetStateAction<IItem[]>>
  form?: FormInstance
  discount?: number
  setDiscount?: Dispatch<SetStateAction<number>>
  warehouseGoodsIssueDetail?: IWarehouseGoodsIssue
  setWarehouseGoodsIssueDetail?: Dispatch<SetStateAction<IItem>>
  isForUnit: boolean
}
export const WarehouseGoodsIssueContext =
  createContext<IWarehouseGoodsIssueContext>({ orders: [], isForUnit: false })

export const WarehouseGoodsIssueProvider = ({ children }) => {
  const [orders, setOrders] = useState<IItem[]>([])
  const [warehouseGoodsIssueDetail, setWarehouseGoodsIssueDetail] =
    useState<IItem>({})
  const [form] = BaseForm.useForm()
  const [discount, setDiscount] = useState(0)
  const [isForUnit, setIsForUnit] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (
      location?.pathname?.includes(
        INVENTORY_WAREHOUSE_GOODS_ISSUE_FOR_UNIT_PATH,
      )
    ) {
      setIsForUnit(true)
    }
  }, [])
  console.log('location', location)
  return (
    <WarehouseGoodsIssueContext.Provider
      value={{
        orders,
        setOrders,
        form,
        discount,
        setDiscount,
        warehouseGoodsIssueDetail,
        setWarehouseGoodsIssueDetail,
        isForUnit,
      }}
    >
      {children}
    </WarehouseGoodsIssueContext.Provider>
  )
}
