import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { DetailCampaignProvider } from './context'
import { RootLayout } from './RootLayout'

const DetailCampaignLayout = () => {
  return (
    <DetailCampaignProvider>
      <S.BaseManagementWrapper>
        <HeaderPage titleI18nKey={R.strings.detail_campaign} />

        <RootLayout />
      </S.BaseManagementWrapper>
    </DetailCampaignProvider>
  )
}

export default DetailCampaignLayout
