import { IUnitManagement } from 'app/api/unit/model/unit-management'
import {
  SvgAreaOrganizationalChartTreeIcon,
  SvgDownArrowIcon,
  SvgHotelFillIcon,
  SvgHumanResourcesIcon
} from 'app/assets/svg-assets'
import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { PADDING } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { TreeNode } from 'react-organizational-chart'
import styled from 'styled-components'
interface IProfile {
  name?: string
  jobTitle?: string
  children?: IProfile[]
}

interface IItemTree extends IUnitManagement {
  profile?: IProfile
  avatar?: string
}

export const ItemTreeComponent = ({
  item,
  borderColor,
}: {
  item: IItemTree
  borderColor?: string
}) => {
  const [isCollapse, setIsCollapse] = useState(false)

  const handleCollapse = () => {
    setIsCollapse(!isCollapse)
  }

  const backgroundIcon = useMemo(() => {
    switch (item?.unitTypeName) {
      case 'Department':
      case 'Clinic':
      case 'Area':
        return '#365B7E'
    }
  }, [item?.unitTypeName])

  const icon = useMemo(() => {
    if (item?.avatar) return item?.avatar

    switch (item?.unitTypeName) {
      case 'Department':
      case 'Clinic':
        return <SvgHotelFillIcon />
      case 'Area':
        return <SvgAreaOrganizationalChartTreeIcon />
    }
  }, [item])

  return (
    <TreeNode
      label={
        <RootWrapper>
          <BaseCol />
          <ImageLogoWrapper justify={'center'}>
            <ImageLogo
              $borderColor={borderColor}
              $backgroundColor={backgroundIcon}
            >
              <BaseAvatar
                alt={item?.name}
                src={icon}
                style={{
                  width: icon ? 'fit-content' : '100%',
                  height: '100%',
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                  padding: 4,
                  textAlign: 'center',
                }}
              />
            </ImageLogo>
          </ImageLogoWrapper>
          <BaseText
            children={item?.name}
            fontWeight="semibold"
            fontSize="xs"
            colorText="primary1Color"
          />
          <JobTitleWrapper>
            <BaseText
              children={item?.unitTypeName ?? '-'}
              fontWeight="medium"
              fontSize="xxxs"
            />
          </JobTitleWrapper>

          <BaseRow align={'middle'} gutter={16}>
            <BaseCol>
              <BaseRow align={'middle'} gutter={8}>
                <SvgHumanResourcesIcon />
                <BaseCol>
                  <BaseText
                    children={item?.children?.length?? 0}
                    fontWeight="semibold"
                    opacity="0.7"
                    fontSize="xs"
                  />
                </BaseCol>
              </BaseRow>
            </BaseCol>
          </BaseRow>

          <CollapseWrapper onClick={handleCollapse}>
            <SvgDownArrowIconWrapper $isActive={isCollapse} />
          </CollapseWrapper>
        </RootWrapper>
      }
    />
  )
}

const RootWrapper = styled(BaseSpace)<{ $borderTopColor?: string }>`
  position: relative;
  border-radius: ${PADDING.xs};
  align-items: center;
  width: 250px;
  background-color: ${convertedVariables.backgroundColor};
  border-top: 2px solid ${({ $borderTopColor }) => $borderTopColor ?? '#365B7E'};
  margin-top: 30px;
`

const ImageLogoWrapper = styled(BaseRow)`
  position: absolute;
  top: -25px;
  width: 100%;
  left: 0px;
`

const ImageLogo = styled.div<{
  $borderColor?: string
  $backgroundColor?: string
}>`
  border-radius: 50%;
  width: 50px !important;
  height: 50px !important;
  border: 2px solid
    ${props => props.$borderColor ?? convertedVariables.backgroundColor};
  background-color: ${props =>
    props?.$backgroundColor ?? convertedVariables.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
`

const JobTitleWrapper = styled(BaseRow)`
  background-color: ${convertedVariables.neutralBlack9Color};
  border-radius: ${PADDING.xs};
  padding: ${PADDING.xxxs};
  width: fit-content;
`

const CollapseWrapper = styled.div`
  background-color: #f8f8f8 !important;
  padding: ${PADDING.xxs} ${PADDING.md};
  width: 250px;
  cursor: pointer;
  border-bottom-left-radius: ${PADDING.xs};
  border-bottom-right-radius: ${PADDING.xs};
`

const SvgDownArrowIconWrapper = styled(SvgDownArrowIcon)<{
  $isActive?: boolean
}>`
  transform: rotate(${props => (props?.$isActive ? 0 : -180)}deg);
  transition: 0.5s ease;
`
