import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { useHookTable } from './hook'
import { BaseRow } from 'app/components/common/BaseRow'
import { Create } from './components/Create'
import { useTranslation } from 'react-i18next'
import { TotalPage } from './styles'
import { FilterManagement } from './components/FilterManagementTable'
const WarehouseContainer = () => {
  const {
    tableData,
    columns,
    handleTableChange,
    filter,
    handleChangeFilter,
    fetchNewData,
  } = useHookTable()

  const { t } = useTranslation()
  return (
    <S.BaseManagementWrapper>
      <HeaderPage
        titleI18nKey={R.strings.warehouse_category}
        rightComponent={
          <>
            {/* <Export onPressExport={onClickExport}/> */}
            <Create fetchTable={fetchNewData} />
          </>
        }
      />
      <S.RootWrapper>
        <BaseRow>
          <FilterManagement
            handleChangeFilter={handleChangeFilter}
            filterData={filter}
          />
        </BaseRow>
        <BaseTableManagement
          columns={columns}
          dataSource={tableData.data}
          pagination={{
            ...tableData.pagination,
            showTotal: total => {
              return (
                <TotalPage>
                  {t(R.strings.total_page, {
                    total,
                  })}
                </TotalPage>
              )
            },
          }}
          loading={tableData.loading}
          onChange={handleTableChange}
          expandable={{
            expandIcon: () => null,
          }}
        />
      </S.RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default WarehouseContainer
