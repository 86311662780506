import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestGetUserInfo } from 'app/api/profile'
import { ProfileMessageCode } from 'app/api/profile/constant'
import { IProfileUser } from 'app/api/profile/model/profile'
import { NotificationType } from 'app/common/enum'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'

export interface ProfileState {
  profile?: IProfileUser
  isLoading?: boolean
  message?: {
    type: NotificationType,
    currentTime: Date,
    payload?: string
  }
}

const initialState: ProfileState = {
  profile: undefined,
  isLoading: false,
  message: undefined,
}

export const getUserInfo = createAsyncThunk('get/user-info', async () => {
  const response: ResponseType<IProfileUser> = await requestGetUserInfo()
  if (
    isEqual(
      response.msgcode,
      ProfileMessageCode.ProfileManagement.successGetData,
    )
  ) {
    return response?.data
  }
  throw response
})

export const profileSlice = createSlice({
  name: 'user-info',
  initialState,
  reducers: {
    changeProfile: (state, action) => {
      state.profile = action.payload
    },
    resetProfile: state => {
      state.profile = undefined
    },
    changeMessage: (state, action) => {
      state.message = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getUserInfo.pending, state => {
      state.isLoading = true
    }),
      builder.addCase(getUserInfo.fulfilled, (state, action) => {
        state.profile = action.payload
        state.isLoading = false
      })
    builder.addCase(getUserInfo.rejected, state => {
      state.profile = undefined
      state.isLoading = false
    })
  },
})

export const { resetProfile, changeProfile, changeMessage } = profileSlice.actions

export default profileSlice.reducer
