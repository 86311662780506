/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const useModalHook = () => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return {
    visible,
    t,
    showModal,
    handleCancel,
  }
}
