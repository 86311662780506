import R from 'app/assets/R'
import { PageTitle } from 'app/components/common/PageTitle'
import CreateUpdateProductServiceManagement from 'app/containers/Accounting/ItemManagement/CreateUpdateProductServiceManagement'
import { ItemCategoryProvider } from 'app/context/ItemCategoryContext'
import { ItemProvider } from 'app/context/ItemContext'
import { TreatmentProvider } from 'app/context/TreatmentContext'
import { isEqual } from 'lodash'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

export enum ActionProductServiceEnum {
  create = 'create',
  update = 'update',
}

const AccountingItemManagementAddProductServiceCpn = ({
  type = ActionProductServiceEnum.create,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <ItemCategoryProvider>
        <TreatmentProvider>
          <ItemProvider>
            <PageTitle>{t(R.strings.item_management)}</PageTitle>
            <CreateUpdateProductServiceManagement type={type} />
          </ItemProvider>
        </TreatmentProvider>
      </ItemCategoryProvider>
    </>
  )
}
const AccountingItemManagementAddProductServicePage = memo(
  AccountingItemManagementAddProductServiceCpn,
  isEqual,
)

export default AccountingItemManagementAddProductServicePage
