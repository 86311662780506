import styled from 'styled-components'

export const TimelineName = styled.div`
  position: absolute;
  left: 6px;
  top: 0px;
  font-family: Inter;
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--collapse-background-color);
  transform: translateY(-100%);
  z-index: 3;
`

export const Timeline = styled.div`
  position: absolute;
  left: 6px;
  top: 0px;
  font-family: Inter;
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--collapse-background-color);
  transform: translateY(-100%);
  z-index: 3;
`

export const AddNew = styled.div`
  padding: 8px;
  border: 1px;
  gap: 9px;
  background: var(--states-blue-light-color);
  border: 1px dotted #5585f4;
  min-height: 90px;
  text-align: center;
  cursor: pointer;
  display: none;
`
export const AddNewImg = styled.img`
  margin: auto;
  margin-top: 2%;
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 50px;
  gap: 10px;
`
export const AddNewLabel = styled.span`
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--collapse-background-color);
`
