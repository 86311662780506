import R from 'app/assets/R'
import { PaymentMethodEnum } from 'app/common/enum'
import { PaymentMethodSelectComponent } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/PaymentMethodSelectComponent'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { IPaymentMethodDebtPayment } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/debt-payment'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'

interface IProps {
  item?: IPaymentMethodDebtPayment
  index: number
}

const SelectProgramsComponent = ({ item, index }: IProps) => {

  const {
    purchaseProductService,
    onChangePaymentMethodTotalInvoice,
    onDeletePaymentMethodTotalInvoice,
  } = useProfilePatientContext()

  const paymentMethods = useMemo(() => {
    return purchaseProductService?.totalInvoice?.paymentMethods
  }, [purchaseProductService?.totalInvoice?.paymentMethods])

  const listProgramsCanSelect = useMemo(() => {
    const listPaymentMethod = Object?.values(PaymentMethodEnum)
    return listPaymentMethod?.filter(itemSelect => {
      if (itemSelect === item?.method) return true

      return !paymentMethods?.find(itm => itm?.method === itemSelect)
    })
  }, [paymentMethods])

  const _onChangeSelect = (method: PaymentMethodEnum) => {
    const newValue: IPaymentMethodDebtPayment = {
      amount: item?.amount ?? 0,
      method,
    }

    onChangePaymentMethodTotalInvoice?.(index, newValue)
  }

  const _onChangeAmount = (amount: number) => {
    const newValue: IPaymentMethodDebtPayment = {
      amount,
      method: item?.method,
    }

    onChangePaymentMethodTotalInvoice?.(index, newValue)
  }

  const _onDelete = () => {
    onDeletePaymentMethodTotalInvoice?.(index)
  }

  const isDisableDeleteButton = useMemo(() => {
    return paymentMethods?.length === 1
  }, [paymentMethods])

  const valueSelect = useMemo(() => {
    return isEmpty(item?.method) ? undefined : item?.method
  }, [item])

  return (
    <PaymentMethodSelectComponent
      amount={item?.amount ?? 0}
      index={index + 1}
      isDisableDeleteButton={isDisableDeleteButton}
      valueSelect={valueSelect}
      listSelect={listProgramsCanSelect}
      onChangeAmount={_onChangeAmount}
      onChangeSelect={_onChangeSelect}
      onDelete={_onDelete}
      placeholderSelectKeyI18n={R.strings.select_payment_method}
    />
  )
}

export default SelectProgramsComponent
