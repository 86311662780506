import { SearchInput } from "app/components/common/inputs/SearchInput";
import { BORDER_RADIUS, PADDING, media } from "parkway-web-common";
import styled from "styled-components";

export const InputSearch = styled(SearchInput)`
  .ant-input-group-addon {
    display: none;
  }

  @media only screen and (${media.md}) {
    .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
      border-radius: ${BORDER_RADIUS};
      border-color: var(--neutral-black-color);
      padding: 0px ${PADDING.xxxxs};
    }
  }
`