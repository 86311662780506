import React from 'react'
import * as S from './styles'

interface IProps {
  index: number
  prefix?: string
  name?: string
  color?: string
  onRemove?: (key: number) => void
  stylesContainer?: React.CSSProperties
  stylesName?: React.CSSProperties
}

export const BaseChip: React.FC<IProps> = ({
  index,
  prefix,
  name,
  onRemove,
  color,
  stylesContainer,
  stylesName,
  ...props
}) => {
  return (
    <S.ItemWrapper
      key={index}
      align={'middle'}
      gutter={[10, 10]}
      style={stylesContainer}
      {...props}
    >
      {prefix ? <S.PrefixTag color={color}>{prefix}</S.PrefixTag> : null}
      <S.NameText style={stylesName}>{name ?? '-'}</S.NameText>
      {!!onRemove && (
        <S.RemoveCircleIconStyled onClick={() => onRemove?.(index)} />
      )}
    </S.ItemWrapper>
  )
}
