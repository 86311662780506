import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { randomMoney } from 'app/components/tables/common-table/constant'
import { IProductServicePurchaseItem } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/purchase-product-service'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING, formatMoney } from 'parkway-web-common'
import styled from 'styled-components'

interface IProps {
  item?: IProductServicePurchaseItem
  index: number
}

export const ProductServiceItem = ({ index }: IProps) => {
  return (
    <HeadItemWrapper align={'middle'} gutter={15} wrap={false}>
      <BaseCol xl={1}>
        <BaseText
          children={index}
          opacity="0.7"
          fontSize="xxxs"
          fontWeight="medium"
        />
      </BaseCol>
      <BaseCol xl={15}>
        <BaseRow gutter={[10, 10]} align={'middle'} wrap={false}>
          <BaseCol>
            <BaseAvatar
              alt={'Niềng răng invisalign'}
              style={{
                borderRadius: BORDER_RADIUS,
              }}
            />
          </BaseCol>

          <BaseCol>
            <BaseText
              children={'Niềng răng invisalign'}
              fontWeight="semibold"
              colorText="primaryColor"
              fontSize="xs"
              lineHeight="1.45"
            />
            <BaseText
              children={'Đơn vị : (gói)'}
              fontWeight="medium"
              fontSize="xxxs"
            />
          </BaseCol>
        </BaseRow>
      </BaseCol>
      <BaseCol xl={2}>
        <BaseText
          children={2}
          opacity="0.7"
          fontSize="xxxs"
          fontWeight="medium"
          textAlign="center"
        />
      </BaseCol>
      <BaseCol xl={2}>
        <BaseText
          children={formatMoney(randomMoney())}
          opacity="0.7"
          fontSize="xxxs"
          fontWeight="medium"
          textAlign="center"
        />
      </BaseCol>
      <BaseCol xl={2}>
        <BaseText
          children={'Có'}
          opacity="0.7"
          fontSize="xxxs"
          fontWeight="medium"
          textAlign="center"
        />
      </BaseCol>
      <BaseCol xl={2}>
        <BaseText
          children={formatMoney(randomMoney())}
          fontSize="xxxs"
          fontWeight="bold"
          colorText="statesGreenColor"
          textAlign="center"
        />
      </BaseCol>
    </HeadItemWrapper>
  )
}

const HeadItemWrapper = styled(BaseRow)`
  padding: ${PADDING.xxs};
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};
`
