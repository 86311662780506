import { createContext, useContext } from 'react'
import { ToothSizeAndMorphologyData } from './constant'
import { useToothSizeAndMorphologyCurrentStatus, useToothSizeAndMorphologyTabResult } from './hook'
import {
  IBridgeTooth,
  IToothSizeAndMorphology,
  PositionToothEnum,
  ToothNumberEnum,
  ToothStatusEnum,
} from './type'

interface IContextTreatmentPlanContext {
  name?: string

  // tab current status
  bridgeToothBottomTabCurrentStatus?: IBridgeTooth[]
  bridgeToothTopTabCurrentStatus?: IBridgeTooth[]
  onAddBridgeBottomTabCurrentStatus?: (
    start: number,
    indexStart: number,
  ) => void
  onChangeBridgeBottomTabCurrentStatus?: (
    id?: string,
    end?: ToothNumberEnum,
    indexEnd?: number,
  ) => void
  onAddBridgeTopTabCurrentStatus?: (start: number, indexStart: number) => void
  onChangeBridgeTopTabCurrentStatus?: (
    id?: string,
    end?: ToothNumberEnum,
    indexEnd?: number,
  ) => void
  toothSizeAndMorphologyTabCurrentStatus: IToothSizeAndMorphology
  onChangeStatusToothSizeAndMorphology?: (
    position: PositionToothEnum,
    key: ToothNumberEnum,
    newStatus?: ToothStatusEnum,
  ) => void
  onChangeValueToothSizeAndMorphology?: (
    position: PositionToothEnum,
    key: ToothNumberEnum,
    newValue?: number,
  ) => void
  bridgeBottomTabCurrentStatusSelecting?: IBridgeTooth
  onDeleteBridgeBottomTabCurrentStatus?: (ids: string[]) => void

  bridgeTopTabCurrentStatusSelecting?: IBridgeTooth
  onDeleteBridgeTopTabCurrentStatus?: (ids: string[]) => void

  // tab result
  bridgeToothBottomTabResult?: IBridgeTooth[]
  bridgeToothTopTabResult?: IBridgeTooth[]
  onAddBridgeBottomTabResult?: (start: number, indexStart: number) => void
  onChangeBridgeBottomTabResult?: (
    id?: string,
    end?: ToothNumberEnum,
    indexEnd?: number,
  ) => void
  onAddBridgeTopTabResult?: (start: number, indexStart: number) => void
  onChangeBridgeTopTabResult?: (
    id?: string,
    end?: ToothNumberEnum,
    indexEnd?: number,
  ) => void
  toothSizeAndMorphologyTabResult: IToothSizeAndMorphology
  onChangeStatusToothSizeAndMorphologyTabResult?: (
    position: PositionToothEnum,
    key: ToothNumberEnum,
    newStatus?: ToothStatusEnum,
  ) => void
  onChangeValueToothSizeAndMorphologyTabResult?: (
    position: PositionToothEnum,
    key: ToothNumberEnum,
    newValue?: number,
  ) => void
  bridgeBottomTabResultSelecting?: IBridgeTooth
  onDeleteBridgeBottomTabResult?: (ids: string[]) => void

  bridgeTopTabResultSelecting?: IBridgeTooth
  onDeleteBridgeTopTabResult?: (ids: string[]) => void
}

export const TreatmentPlanContext = createContext<IContextTreatmentPlanContext>(
  {
    toothSizeAndMorphologyTabCurrentStatus: ToothSizeAndMorphologyData,
    toothSizeAndMorphologyTabResult: ToothSizeAndMorphologyData,
  },
)

export const TreatmentPlanProvider = ({ children }) => {
  const toothSizeAndMorphologyTabCurrentStatus = useToothSizeAndMorphologyCurrentStatus()
  const toothSizeAndMorphologyTabResult = useToothSizeAndMorphologyTabResult()

  return (
    <TreatmentPlanContext.Provider
      value={{
        ...toothSizeAndMorphologyTabCurrentStatus,
        ...toothSizeAndMorphologyTabResult
      }}
    >
      {children}
    </TreatmentPlanContext.Provider>
  )
}

export function useTreatmentPlanContext() {
  const context = useContext(TreatmentPlanContext)

  if (context === undefined) {
    throw new Error(
      'useTreatmentPlanContext must be used within a TreatmentPlanContext',
    )
  }
  return context
}
