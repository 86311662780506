import { Space } from 'antd'
import type { SpaceProps } from 'antd'

import styled from 'styled-components'

export type BaseSpaceProps = SpaceProps
export const BaseSpace: React.FC<BaseSpaceProps> = props => {
  return <SpaceStyled direction="vertical" size={16} {...props} />
}

const SpaceStyled = styled(Space)`
  width: 100%;
`
