import { ApiClient } from 'app/service/api-service'
import { CategoryEndPoint } from './constant'
import { IParamsGetCategoryTreatment } from './model'

export const requestGetCategoryTreatment = (
  params?: IParamsGetCategoryTreatment,
) =>
  ApiClient.Get({
    url: `${CategoryEndPoint.CategoryTreatment.request}`,
    params: { params },
  })
