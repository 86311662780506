import { Avatar, AvatarProps } from 'antd'
import { useBaseImage } from 'app/hook'
import { BORDER_RADIUS } from 'parkway-web-common'
import styled from 'styled-components'

const BaseImageWithCharacter: React.FC<AvatarProps> = props => {
  const { firstCharacterOfName, backgroundColor, color } = useBaseImage({
    alt: props?.alt,
    src: props?.src,
  })

  return (
    <BaseImageWrapper
      $color={!props?.src && color}
      $backgroundColor={!props?.src && backgroundColor}
      {...props}
    >
      {!props?.src && firstCharacterOfName}
    </BaseImageWrapper>
  )
}

export default BaseImageWithCharacter

const BaseImageWrapper = styled(Avatar)<{
  $color: string
  $backgroundColor: string
}>`
  border-radius: ${BORDER_RADIUS};
  background-color: ${props => props.$backgroundColor ?? ''};
  color: ${props => props.$color ?? ''};
`
