import R from 'app/assets/R'
import { DateUtil } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import { dataNewDashboard } from './data'
import * as S from './styles'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import BaseText from 'app/components/common/BaseText'
import { BaseButton } from 'app/components/common/BaseButton'

export const NewsDoctorComponent = () => {
  const { t } = useTranslation()
  return (
    <S.RootWrapper>
      <BaseRow align={'middle'} gutter={[16, 16]} justify={'space-between'}>
        <BaseCol>
          <BaseText
            children={t(R.strings.news)}
            fontSize="md"
            fontWeight="semibold"
          />
        </BaseCol>
        <BaseCol>
          <BaseButton type="link">
            <BaseText children={t(R.strings.news)} colorText="otherBlueColor" />
          </BaseButton>
        </BaseCol>
      </BaseRow>
      {dataNewDashboard.map((item, index) => {
        return (
          <S.ItemWrapper key={index}>
            <S.TitleTextItem>{item?.title}</S.TitleTextItem>
            <S.FooterItemWrapper justify={'space-between'} align={'middle'}>
              <S.ReadMoreTextFooterItem>
                {t(R.strings.show_less)}...
              </S.ReadMoreTextFooterItem>
              <S.DateTextFooterItem>
                {DateUtil.formatHHssDDMMYYYY(item.date)}
              </S.DateTextFooterItem>
            </S.FooterItemWrapper>
          </S.ItemWrapper>
        )
      })}
    </S.RootWrapper>
  )
}
