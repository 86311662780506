export enum VoucherTypeEnum {
  ActiveCampaign = 'active_campaign',
  Discount = 'discount',
}
export enum MethodIssueEnum {
  Auto = 'auto',
  Fillable = 'fillable',
}
export enum VerifyRuleConditionEnum {
  Equal = 'eq',
  NotEqual = 'ne',
  GreaterThan = 'gt',
  GreaterThanOrEqual = 'gte',
  LessThan = 'lt',
  LessThanOrEqual = 'lte',
  In = 'in',
}

export enum TargetUserEnum {
  CompanyEmployee = 'company_employee',
  VipCustomer = 'vip_customer',
  Customer = 'customer',
}

export enum VoucherTemplateStatusEnum {
  WaitingApply = 'waiting_apply',
  Applying = 'applying',
  StopApply = 'stop_apply',
  UpComing = 'up_coming',
  Ended = 'ended',
}

export enum VoucherTemplateStatusIssueEnum {
  Waiting = 'waiting',
  Creating = 'creating',
  Error = 'error',
  Done = 'done',
}
export enum DiscountTypeEnum {
  Percent = 'percent',
  Amount = 'amount',
}

export enum EVerifyRuleKey {
  PatientId = 'patientId',
  NewPatient = 'newPatient',
  All = 'all',
  MaxUsage = 'maxUsage',
}

export enum UserDataByVoucherEnum {
  NotYetUsed = -1,
  Using = 0,
  Used = 1,
}
