import { ApiClient } from 'app/service/api-service'
import {
  IParamsGetDoctorsWithoutPaid,
  IParamsGetLocationsWithoutPaid,
  IParamsGetOrthodonticWithoutPaid,
  IParamsGetPatientWithoutPaid,
} from '../model/orthodontic-without-paid'
import { AccountingEndPoint } from '../constant'

export const requestOrthodonticWithoutPaid = (
  params: IParamsGetOrthodonticWithoutPaid,
) =>
  ApiClient.Get({
    url: AccountingEndPoint.OrthodonticWithoutPaid.requestGetData,
    params: { params },
  })

export const requestPatientWithoutPaid = (
  params: IParamsGetPatientWithoutPaid,
) =>
  ApiClient.Get({
    url: AccountingEndPoint.OrthodonticWithoutPaid.patientWithoutPaid,
    params: { params },
  })

export const requestGetDoctorsWithoutPaid = (
  params: IParamsGetDoctorsWithoutPaid,
) =>
  ApiClient.Get({
    url: AccountingEndPoint.OrthodonticWithoutPaid.doctors,
    params: { params },
  })

export const requestGetLocationsWithoutPaid = (
  params: IParamsGetLocationsWithoutPaid,
) =>
  ApiClient.Get({
    url: AccountingEndPoint.OrthodonticWithoutPaid.locations,
    params: { params },
  })

export const requestGetTotalWithoutPaid = (
  params: IParamsGetLocationsWithoutPaid,
) =>
  ApiClient.Get({
    url: AccountingEndPoint.OrthodonticWithoutPaid.total,
    params: { params },
  })
