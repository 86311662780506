/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetUnit } from 'app/api/hr'
import { IParamsGetUnit, IResponseGetUnitArea } from 'app/api/hr/model'
import { UnitMessageCode } from 'app/api/unit/constant'
import { IUnit } from 'app/model/unit.model'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { useInfiniteQuery } from 'react-query'
import { LocationKeys } from '../query-key/location'

export function useGetAreaList(params: IParamsGetUnit, enabled = true) {
  const token = getToken()
  return useInfiniteQuery<IUnit[], undefined>(
    LocationKeys.getListArea(params),
    async () => {
      try {
        const res: IResponseGetUnitArea = await requestGetUnit(params)
        if (
          isEqual(res?.msgcode, UnitMessageCode.UnitManagement.successGetData)
        ) {
          const data: IUnit[] = res?.data?.rows || []
          return data
        }
        return []
      } catch (error: any) {
        return []
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
