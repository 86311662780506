import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseDebtTreeSelect, TreeSelectAll, TreeUnSelectAll } from './styles'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import { transformDataSelectTree } from 'app/components/common/selects/BaseTreeSelect/helper'
import { capitalizeFirstLetter } from 'app/common/helpers'
import { removeVietnameseTones } from 'app/helpers/string.helper'
import { TreeSelect } from 'antd'

const { SHOW_CHILD } = TreeSelect

export const DebtTreeSelect: React.FC<{
  treatments
  isTreatmentLoading
  selectedValues
  setSelectedValues
  type
  placeholder
}> = ({
  treatments,
  isTreatmentLoading,
  selectedValues,
  setSelectedValues,
  type,
  placeholder,
}) => {
  const { t } = useTranslation()
  const [searchData, setSearchData] = useState<string[]>([])
  const [keyword, setKeyword] = useState('')

  const filterTreeNode = (value, treeNode) => {
    return removeVietnameseTones(treeNode?.title)
      .toLowerCase()
      .includes(removeVietnameseTones(value).toLowerCase())
  }
  const treeDataBefore = useMemo(() => {
    return transformDataSelectTree(
      treatments?.map(treatment => ({
        ...treatment,
        name: capitalizeFirstLetter(treatment?.name?.toLocaleLowerCase()),
        treatments: treatment?.treatments?.filter(item =>
          type?.includes(item?.ttype),
        ),
      })),
      {
        title: 'name',
        value: '_id',
        children: 'treatments',
      },
    )
  }, [treatments])

  const treeData = useMemo(() => {
    return treeDataBefore?.filter(itm => itm?.children?.length) ?? []
  }, [treeDataBefore])

  useEffect(() => {
    setTimeout(() => {
      let filteredData: string[] = []
      treeData
        ?.map(node => {
          const nodeChildren = node?.children?.filter(children =>
            filterTreeNode(keyword, children),
          )
          return {
            ...node,
            children: nodeChildren?.map(({ value }) => value),
          }
        })
        ?.forEach(item => {
          if (item?.children?.length)
            filteredData = [...filteredData, ...item.children]
          if (!item?.children?.length && filterTreeNode(keyword, item))
            [...filteredData, item?.value]
        })

      setSearchData(filteredData)
    }, 500)
  }, [keyword, treeData])

  const allIds: string[] | undefined = useMemo(() => {
    return treeData?.map(({ value }) => value)
  }, [treeData])

  const handleOnChange = (ids: string[]) => {
    const childrenIds = treatments
      ?.filter(parent => ids?.includes(parent?._id))
      ?.map(parent => parent?.treatments?.map(child => child?._id))
      .flat(Infinity)

    const selectedIds = [...ids, ...childrenIds]
    const idsMatchingSearch = searchData.filter(e => selectedIds.includes(e))
    const idsMatchingValues = selectedValues.filter(e => ids.includes(e))
    const idsMatching: string[] = keyword ? idsMatchingSearch : ids

    setSelectedValues([...idsMatching, ...idsMatchingValues])
  }
  return (
    <>
      <BaseDebtTreeSelect
        placeholder={placeholder}
        suffixIcon={<SvgTableCloseIcon />}
        dropdownRender={menu => (
          <>
            <TreeSelectAll
              onClick={() =>
                setSelectedValues(
                  (searchData?.length
                    ? [...selectedValues, ...searchData]
                    : allIds) || [],
                )
              }
            >
              {t(R.strings.select_all)}
            </TreeSelectAll>
            &nbsp;&nbsp;&nbsp;
            <TreeUnSelectAll onClick={() => setSelectedValues([])}>
              {t(R.strings.unselect_all)}
            </TreeUnSelectAll>
            {menu}
          </>
        )}
        treeData={treeData}
        allowClear
        onChange={ids => handleOnChange(ids as string[])}
        value={selectedValues}
        onSearch={keyword => setKeyword(keyword)}
        treeCheckable={true}
        style={{ minWidth: 200 }}
        loading={isTreatmentLoading}
        showCheckedStrategy={SHOW_CHILD}
        filterTreeNode={filterTreeNode}
        searchValue={keyword}
        maxTagCount={1}
      />
    </>
  )
}
