import { Checkbox } from 'antd'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { InputPassword } from 'app/components/common/inputs/InputPassword'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { FONT_SIZE, FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import styled from 'styled-components'

export const ButtonCreateAction = styled(BaseButton)`
  background-color: var(--states-orange-color);
  color: var(--background-color);
  transition: all 0.5s ease;
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--background-color);
    color: var(--states-orange-color);
    border-color: var(--states-orange-color);
    transition: all 0.5s ease;
  }
`
export const LabelWrapper = styled(BaseRow)`
  color: var(--main-2-color);
`

export const RequireDot = styled.div`
  color: var(--states-red-color);
`
export const DashLine = styled(BaseDivider)``

export const TitleForm = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.bold};
`

export const FormItem = styled(BaseForm.Item)``

export const FormInput = styled(BaseInput)``

export const FormInputNumber = styled(InputNumber)`
  width: 100%;
`
export const FormInputPassword = styled(InputPassword)``

export const SelectBaseStyled = styled(BaseSelect)`
  width: 100%;
`

export const AreaAddNewSupplies = styled(BaseRow)`
  width: 100%;
`

export const ButtonAddNewSupplies = styled(BaseButton)``

export const SkillWrapper = styled(BaseRow)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: unset !important;
`

export const CheckboxGroup = styled(Checkbox.Group)`
  width: 100%;
  gap: 8px;
`

export const SkillTitle = styled.div`
  width: 100%;
  display: flex;
  padding-left: 20px;
  padding-right: 38px;
  margin-bottom: 12px;

  span:nth-child(1) {
    flex-basis: 25%;
  }

  span:nth-child(2) {
    flex-basis: 75%;
  }

  span:nth-child(3) {
    flex-basis: 20%;
  }
  span:nth-child(4) {
    flex-basis: 5%;
  }
`
export const SkillList = styled.div`
  width: 100%;
  display: flex;
  padding: 8px;
  background-color: var(--neutral-black-16-color);
  border-radius: 4px;
  border: 1px solid #f0f1f3;
  flex-direction: column;
  gap: 12px;
  max-height: 355px;
  overflow-y: scroll;
  gap: 8px;
`

export const SkillItem = styled.div`
  width: 100%;
  display: flex;
  background-color: var(--white);
  padding: 12px;
  border-radius: 4px;

  span:nth-child(1) {
    flex-basis: 25%;
  }

  span:nth-child(2) {
    flex-basis: 75%;
  }

  span:nth-child(3) {
    flex-basis: 20%;
  }
  span:nth-child(4) {
    flex-basis: 5%;
  }
`

export const LevelTag = styled.div<{ color?: string }>`
  padding: 4px ${PADDING.xxxxs};
  border-radius: 20px;
  color: ${colors.white};
  background-color: ${props => (props.color ? props.color : colors.grayC4)};
  font-size: ${PADDING.xxxs};
  font-weight: ${FONT_WEIGHT.bold};
  width: fit-content;

  &::before {
    content: '•';
    font-size: 100%;
    margin-right: 4px;
    font-weight: ${FONT_WEIGHT.black};
  }
`

export const SelectedSkillText = styled.span`
  margin-top: 26px;
  font-size: 14px;

  .number {
    color: #28ba59;
    font-weight: ${FONT_WEIGHT.semibold};
  }
`
