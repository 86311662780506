import { PlusOutlined } from '@ant-design/icons'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputPassword } from 'app/components/common/inputs/InputPassword'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const ButtonCreateAction = styled(BaseButton)`
  background-color: var(--states-orange-color);
  color: var(--background-color);
  transition: all 0.5s ease;
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--background-color);
    color: var(--states-orange-color);
    border-color: var(--states-orange-color);
    transition: all 0.5s ease;
  }
`
export const LabelWrapper = styled(BaseRow)`
  color: var(--main-2-color);
`

export const RequireDot = styled.div`
  color: var(--states-red-color);
`
export const DashLine = styled(BaseDivider)``

export const TitleForm = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.bold};
`

export const FormItem = styled(BaseForm.Item)`
  .ant-form-item-label {
    display: none;
  }
  margin-bottom: 0px !important;
`

export const FormInput = styled(BaseInput)`
  min-width: 250px;
  .ant-form-item-label {
    display: none;
  }
`

export const FormInputPassword = styled(InputPassword)``

export const SelectBaseStyled = styled(BaseSelect)`
  width: 100%;
`

export const PermissionHeadButton = styled(BaseButton)``

export const PermissionHeadButtonText = styled.div`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`
export const PlusIcon = styled(PlusOutlined)``

export const ContentWrapper = styled(BaseSpace)`
  width: 100%;
`

export const FilterWrapper = styled(BaseRow)`
  margin: 0px !important;
`
export const DataWrapper = styled(BaseSpace)`
  width: 100%;
`
export const ItemData = styled(BaseRow)`
  padding: 10px;
  border-radius: ${BORDER_RADIUS};
  background-color: white;
  margin: 0px !important;
  cursor: pointer;
`

export const ItemHeader = styled(BaseRow)`
  padding-left: ${PADDING.xxxl};
  padding-right: ${PADDING.xxxl};
  border-radius: ${BORDER_RADIUS};
  margin: 0px !important;
`

export const TextHead = styled.div`
  color: #002244; // need change
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.xs};
`

export const TextData = styled.div`
  color: var(--primary-color); // need change
  font-weight: ${FONT_WEIGHT.medium};
  font-size: ${FONT_SIZE.xs};
`

export const ListWrapper = styled(BaseSpace)`
  padding: ${PADDING.xs};
  padding-right: 0px;
  background-color: #f0f1f3;
  border-radius: ${BORDER_RADIUS};
  width: 100%;
  overflow-y: scroll;
  max-height: 350px;
  height: fit-content;
`

export const IDWrapper = styled(BaseCol)`
  width: 150px;
`

export const FooterWrapper = styled(BaseRow)`
width: 100%;
`

export const TextTotalPermissionSelected = styled.div`
color: var(--states-green-color);
`