import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import { ToothActionCurrentStatusTabComponent } from '../../../../components'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  IToothData,
  PositionToothEnum,
} from 'app/containers/DentalTreatmentManagement/TreatmentPlan/type'
import { isEqual } from 'lodash'
import { useMemo } from 'react'
import { useTreatmentPlanContext } from 'app/containers/DentalTreatmentManagement/TreatmentPlan/context'

interface IProps {
  index: number
  item?: IToothData
}

export const ItemToothCurrentStatusComponent = (props: IProps) => {
  const { item } = props

  const { onChangeValueToothSizeAndMorphology } = useTreatmentPlanContext()

  const isUpTooth = useMemo(() => {
    return isEqual(item?.position, PositionToothEnum.Top)
  }, [item?.position])

  const onChangeValue = (value: number) => {
    if (!item) return

    onChangeValueToothSizeAndMorphology?.(item?.position, item?.key, value)
  }

  return (
    <RootWrapper size={5}>
      {isEqual(item?.position, PositionToothEnum.Top) ? (
        <>
          <RootWrapper size={15}>
            <InputNumberWrapper
              controls={false}
              placeholder="0"
              value={item?.value}
              onChange={value => onChangeValue(value as number)}
            />
            <NumberWrapper>
              <BaseText
                children={item?.key}
                fontWeight="semibold"
                colorText="backgroundColor"
                fontSize="xxxs"
              />
            </NumberWrapper>
          </RootWrapper>
          <ToothActionCurrentStatusTabComponent {...props} item={item} isUpTooth={isUpTooth} />
        </>
      ) : (
        <>
          <ToothActionCurrentStatusTabComponent {...props} item={item} />
          <RootWrapper size={15}>
            <InputNumberWrapper
              controls={false}
              placeholder="0"
              value={item?.value}
              onChange={value => onChangeValue(value as number)}
            />
            <NumberWrapper>
              <BaseText
                children={item?.key}
                fontWeight="semibold"
                colorText="backgroundColor"
                fontSize="xxxs"
              />
            </NumberWrapper>
          </RootWrapper>
        </>
      )}
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseSpace)`
  align-items: center;
`

const NumberWrapper = styled.div`
  padding: 4px ${PADDING.xxxs};
  background-color: #629daa !important; // need change
  border-radius: ${BORDER_RADIUS};
  width: 32px;
`
const InputNumberWrapper = styled(InputNumber)`
  width: 40px;
  background-color: ${convertedVariables.backgroundColor};
  .ant-input-number-input {
    padding: 4px 0px;
    text-align: center;
  }
`
