import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import * as S from 'app/components/tables/BaseTableReport/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS } from 'parkway-web-common'
import styled from 'styled-components'
import { useHook } from './hook'
import { FilterLayout } from './layouts/Filter'

const Report016Container = () => {
  const { columns, data, filter, onChangeFilter, onChangeTable, pagination, isLoading} =
    useHook()
  return (
    <S.BaseReportWrapper>
      <HeaderPage titleI18nKey={R.strings.report_16} isShowTooltip />
      <RootWrapper size={4}>
        <FilterLayout filter={filter} onChangeFilter={onChangeFilter} />
        <BaseTableReportWrapper
          columns={columns}
          dataSource={data}
          onChange={onChangeTable}
          rowClassName="row-overwrite-style"
          pagination={pagination}
          loading={isLoading}
        />
      </RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default Report016Container

const RootWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
`

const BaseTableReportWrapper = styled(BaseTableReport)`
  .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 0px;
  }

  .ant-table-container table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 0px !important;
  }

  table > thead {
    opacity: 0.7;
  }
`
