import { ContentTreatmentPhaseModal } from './ContentTreatmentPhaseModal'
import { ProfilePatientProvider } from './context'

export const AddTreatmentPhase = ({ idSchedule }: { idSchedule?: string }) => {
  return (
    <ProfilePatientProvider idSchedule={idSchedule}>
      <ContentTreatmentPhaseModal />
    </ProfilePatientProvider>
  )
}
