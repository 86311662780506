import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import AccountingContainer from 'app/containers/Accounting'

const AccountingPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.accounting)}</PageTitle>
      <AccountingContainer />
    </>
  )
}
const AccountingPage = memo(AccountingPageCpn, isEqual)

export default AccountingPage
