import R from 'app/assets/R'
import {
  SvgClockCircleDesignIcon,
  SvgTableCloseIcon,
  SvgTableOpenIcon,
  SvgTreatmentPhaseCollapseIcon,
  SvgTreatmentPhaseIcon,
} from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AppointmentSessionItem } from './AppointmentSessionItem'

export const TreatmentPhaseLayout = () => {
  const { t } = useTranslation()
  return (
    <RootWrapper>
      <HeaderWrapper>
        <BaseRow gutter={[16, 10]} align={'middle'}>
          <SvgTreatmentPhaseIcon />
          <BaseCol>
            <BaseText
              children={`${t(R.strings.required_doctor_level)}`}
              fontWeight="semibold"
              fontSize="xs"
            />
          </BaseCol>
          <BaseText children={`(2)`} fontWeight="semibold" fontSize="xs" />
        </BaseRow>
      </HeaderWrapper>
      <ContentWrapper>
        <BaseCollapseWrapper
          items={[
            {
              key: 1,
              extra: (
                <TimeWrapperCollapse
                  gutter={[16, 10]}
                  align={'middle'}
                  style={{ marginRight: 0 }}
                >
                  <SvgClockCircleDesignIconStyled />
                  <BaseCol>
                    <BaseText
                      children={`${90} ${t(R.strings.day)}`}
                      fontWeight="medium"
                      fontSize="xs"
                      opacity="0.7"
                    />
                  </BaseCol>
                </TimeWrapperCollapse>
              ),
              className: 'parent-collapse',
              label: (
                <BaseRow
                  gutter={[16, 10]}
                  align={'middle'}
                  className="label-parent"
                >
                  <BaseCol />
                  <SvgTreatmentPhaseCollapseIcon />
                  <BaseCol>
                    <BaseText
                      children={'Giai đoạn A'}
                      fontWeight="semibold"
                      fontSize="xs"
                    />
                  </BaseCol>
                  <CountWrapper>
                    <BaseText
                      children={'6'}
                      fontWeight="semibold"
                      fontSize="xs"
                    />
                  </CountWrapper>
                </BaseRow>
              ),
              children: <AppointmentSessionItem />,
            },
          ]}
          expandIcon={({ isActive }) =>
            isActive ? <SvgTableOpenIcon /> : <SvgTableCloseIcon />
          }
        />
      </ContentWrapper>
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  width: 100%;
`

const HeaderWrapper = styled.div`
  width: 100%;
  padding: ${PADDING.xl};
  padding-left: 60px;
  align-items: center;
  display: flex;
`

const ContentWrapper = styled.div`
  width: 100%;
  background-color: ${convertedVariables.backgroundColor};
  padding: ${PADDING.md};
  padding-left: 40px;

  .ant-collapse:has(.parent-collapse) {
    border: 0px !important;
  }
`

const BaseCollapseWrapper = styled(BaseCollapse)`
  .parent-collapse {
    border: 0px !important;
    .ant-collapse-content {
      border-top: 0px;
    }

    .ant-collapse-header:has(.label-parent) {
      border: 0px !important;
      background-color: var(--background-color) !important;
      align-items: center !important;
    }
  }
`

const CountWrapper = styled.div`
  padding: 2px 6px;
  border-radius: ${PADDING.md};
  background-color: #ebeef2; //need change
`

const TimeWrapperCollapse = styled(BaseRow)`
  padding: 2px 6px;
  border-radius: ${PADDING.md};
  background-color: #f8f8f8; //need change
  border: 1px solid ${convertedVariables.neutralBlack2Color};
`

const SvgClockCircleDesignIconStyled = styled(SvgClockCircleDesignIcon)`
  path {
    stroke: ${convertedVariables.statesOrangeColor};
  }
`
