// Message code define
export const DashboardMessageCode = {
  RevenueToday: {
    successGetData: '20001',
  },
  RevenueClinicChart: {
    successGetData: '20001',
  },
  GeneralInfoDashboard: {
    successGetData: '20001',
  },
}

// Endpoint
export const DashboardEndPoint = {
  RevenueToday: {
    requestGetData: 'report/v1/revenue/getRevenueAndPercentage',
  },
  RevenueClinicChart: {
    requestGetData: 'report/v1/revenue/getRevenueClinic',
  },
  GeneralInfoDashboard: {
    requestGetData: 'report/v1/revenue/getGeneralInfoDashboard',
  },
}
