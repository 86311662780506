import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { ReactNode } from 'react'

export const InfoItem = ({
  label,
  value,
  customValue,
}: {
  label?: string
  value?: string
  customValue?: ReactNode
}) => {
  return (
    <BaseSpace size={8}>
      <BaseText children={label ?? ''} fontWeight="medium" opacity="0.7" />
      {customValue ? (
        customValue
      ) : (
        <BaseText children={value ?? ''} fontWeight="medium" fontSize="xs" />
      )}
    </BaseSpace>
  )
}
