import { IItemManagement } from 'app/api/item/model/management'
import { DiscountTypeEnum } from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useCreateCampaignContext } from 'app/containers/Marketing/DiscountCampaignManagement/CloneCampaign/context'
import { IBillDiscountInTable } from 'app/containers/Marketing/DiscountCampaignManagement/CloneCampaign/type'
import { isEqual } from 'lodash'
import { formatMoney } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const useHook = () => {
  const { t } = useTranslation()
  const {
    listTotalBill,
    onDeleteTotalBill,
    onChangeDiscountTypeTotalBill,
    onChangeDiscountValueTotalBill,
    isLoadingArea,
  } = useCreateCampaignContext()

  const data = useMemo(() => {
    return listTotalBill?.map((item, index) => ({
      ...item,
      key: index + 1,
    }))
  }, [listTotalBill])

  const columns = useMemo(() => {
    return [
      convertColumnTable<IBillDiscountInTable>({
        key: 'key',
        width: '3%',
        title: '#',
        classNameWidthColumnOverwrite: 'number-column',
        render: value => {
          return <BaseText fontWeight="medium" children={value} fontSize="xs" />
        },
      }),
      convertColumnTable<IBillDiscountInTable>({
        title: t(R.strings.value_total_bill),
        key: 'totalBill',
        fixed: 'left',
        className: 'name-service-column',
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              children={formatMoney(record?.totalBill ?? 0)}
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<IBillDiscountInTable>({
        title: t(R.strings.discount_value),
        key: 'discount',
        classNameWidthColumnOverwrite: 'medium-column',
        width: '15%',
        render: (_, record) => {
          return (
            <BaseFormItem hiddenLabel>
              <InputNumberWrapper
                value={record?.discount ?? 0}
                onChange={e =>
                  onChangeDiscountValueTotalBill?.(e ? +e : 0, record?._id)
                }
                min={0}
                max={
                  isEqual(record?.dType, DiscountTypeEnum.Percent)
                    ? 100
                    : undefined
                }
                precision={
                  isEqual(record?.dType, DiscountTypeEnum.Percent) ? 1 : 0
                }
                disabled={record?.dType === undefined}
              />
            </BaseFormItem>
          )
        },
      }),
      convertColumnTable<IBillDiscountInTable>({
        title: t(R.strings.discount_type),
        key: 'dType',
        width: '15%',
        render: (_, record) => {
          return (
            <BaseFormItem hiddenLabel>
              <BaseSelect
                value={record?.dType}
                placeholder={t(R.strings.select)}
                onChange={value =>
                  onChangeDiscountTypeTotalBill?.(
                    value as DiscountTypeEnum,
                    record?._id,
                  )
                }
                options={[
                  {
                    label: t(R.strings.cash),
                    value: DiscountTypeEnum.Amount,
                  },
                  {
                    label: t(R.strings.discount_percent),
                    value: DiscountTypeEnum.Percent,
                  },
                ]}
                style={{
                  minWidth: 100,
                }}
              />
            </BaseFormItem>
          )
        },
      }),
      convertColumnTable<IItemManagement>({
        title: '',
        key: '_id',
        width: '3%',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          return (
            <BaseButton
              onClick={() => onDeleteTotalBill?.(record?._id)}
              icon={<SvgDeleteDesignIcon />}
              type="link"
            />
          )
        },
      }),
    ]
  }, [t, listTotalBill])

  return {
    columns,
    data,
    isLoadingArea,
  }
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`
const InputNumberWrapper = styled(InputNumber)`
  width: 100%;
`
