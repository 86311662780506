/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchOutlined } from '@ant-design/icons'
import { IParamsGetReport20 } from 'app/api/report/model/report-20'
import R from 'app/assets/R'
import { StatusEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { usePagination } from 'app/hook'
import { IClinic, IUnit } from 'app/model/unit.model'
import { useGetAreaList } from 'app/react-query/hook/location'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import {
  Dates,
  FONT_SIZE,
  FONT_WEIGHT,
  FORMAT_ONLY_MONTH,
} from 'parkway-web-common'
import React, { useMemo } from 'react'
import styled from 'styled-components'

interface IProps {
  onChange?: (value: IParamsGetReport20) => void
  formData?: IParamsGetReport20
}

export const FilterLayout: React.FC<IProps> = ({ formData, onChange }) => {
  const { flattenDataList } = usePagination()

  const { data: areaApi, isLoading: isLoadingArea } = useGetAreaList({
    typeid: 'area',
    status: StatusEnum.ACTIVE,
  })

  const areaList = useMemo(() => {
    const res: IUnit[] = flattenDataList(areaApi)

    let arr: IClinic[] = []
    res?.forEach(i => {
      arr = [...arr, ...(i?.childs || [])]
    })

    return arr?.map(e => ({ ...e, _id: e?._oldId ?? e?._id }))
  }, [areaApi])

  const onChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onChange && onChange({ ...formData, keyword: value })
  }

  const onChangeClinic = (id: string) => {
    onChange && onChange({ ...formData, location: id })
  }

  const onChangeYear = (value: string) => {
    onChange && onChange({ ...formData, year: value })
  }

  const onChangeMonth = (value: string) => {
    onChange && onChange({ ...formData, month: value })
  }

  return (
    <BaseForm>
      <RootWrapper gutter={[16, 8]} align={'middle'}>
        <BaseCol xl={5}>
          <BaseFormItem label={t(R.strings.search)}>
            <BaseInput
              prefix={<SearchOutlined rev={undefined} />}
              value={formData?.keyword}
              placeholder={t(R.strings.enter_keyword_search)}
              onChange={onChangeKeyword}
              allowClear
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={9}>
          <BaseFormItem label={t(R.strings.clinic)} required>
            <BaseSelectWrapper
              showSearch
              placeholder={t(R.strings.choose_clinic)}
              allowClear
              onChange={id => onChangeClinic?.(id as string)}
              options={areaList?.map(item => ({
                value: item._id,
                label: item.name,
              }))}
              loading={isLoadingArea}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={5}>
          <BaseFormItem label={t(R.strings.year)} required>
            <Picker
              // $prefix={`${t(R.strings.year)}*`}
              picker="year"
              value={
                formData?.year ? Dates.getDate(`${formData?.year}-01-01`) : null
              }
              onChange={(_, value) => onChangeYear(value)}
              allowClear={false}
              $prefixWidth={0}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={5}>
          <BaseFormItem label={t(R.strings.month)} required>
            <Picker
              // $prefix={t(R.strings.month)}
              disabled={!formData?.year}
              picker="month"
              value={
                formData?.year && formData?.month
                  ? Dates.getDate(`${formData?.year}-${formData?.month}-01`)
                  : null
              }
              onChange={(_, value) => onChangeMonth(value)}
              allowClear={false}
              $prefixWidth={0}
              format={FORMAT_ONLY_MONTH}
            />
          </BaseFormItem>
        </BaseCol>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseRow)``
const Picker = styled(BaseDatePicker)<{
  $prefix?: string
  $prefixWidth?: number
}>`
  width: 100%;

  &:hover {
    border: 1px solid ${convertedVariables.neutralBlack9Color};
  }
  .ant-picker-input::before {
    // content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
  }
`

const BaseSelectWrapper = styled(BaseSelect)<{
  $prefix?: string
  $prefixWidth?: number
}>`
  width: 100%;
  border: none !important;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important!;
  }
  .ant-select-selector::before {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    align-items: center;
    display: flex;
    padding-left: 4px;
  }

  .ant-select-selection-placeholder {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
