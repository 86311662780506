import { requestGetTreatmentGroupOldDB } from 'app/api/old-db-user'
import { OldDBMessageCode } from 'app/api/old-db-user/constant'
import { ITreatmentGroupOldDb } from 'app/api/old-db-user/model'
import { usePagination } from 'app/hook'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { ParamsList, ResponseType } from 'parkway-web-common'
import { useInfiniteQuery } from 'react-query'
import { OldDBKeys } from '../query-key/old-db'

export function useGetTreatmentGroupList(params?: ParamsList, enabled = true) {
  const { getNextPageParam } = usePagination()
  const token = getToken()
  return useInfiniteQuery<ResponseType<ITreatmentGroupOldDb[]>, undefined>(
    OldDBKeys.listTreatmentGroups(params),
    async data => {
      const res: ResponseType<ITreatmentGroupOldDb[]> =
        await requestGetTreatmentGroupOldDB({
          ...params,
          ...data?.pageParam,
        })

      if (
        isEqual(res?.msgcode, OldDBMessageCode.TreatmentGroup.successGetData)
      ) {
        return res
      }

      return Promise.reject(res)
    },
    {
      enabled: !!token && enabled,
      getNextPageParam,
    },
  )
}
