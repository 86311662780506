import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import ApproveTreatmentPhaseContainer from 'app/containers/DentalTreatmentManagement/ApproveTreatmentPhase'

const ApproveTreatmentPhasePageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.approve_treatment_phase)}</PageTitle>
      <ApproveTreatmentPhaseContainer />
    </>
  )
}
const ApproveTreatmentPhasePage = memo(ApproveTreatmentPhasePageCpn, isEqual)

export default ApproveTreatmentPhasePage
