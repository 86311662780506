import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import DetailCampaignContainer from 'app/containers/Marketing/DiscountCampaignManagement/DetailCampaign'

const DetailCampaignPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.detail_campaign)}</PageTitle>
      <DetailCampaignContainer />
    </>
  )
}
const DetailCampaignPage = memo(DetailCampaignPageCpn, isEqual)

export default DetailCampaignPage
