import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import Report003Container from 'app/containers/Report/Report003'

const Report003PageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.report_003)}</PageTitle>
      <Report003Container />
    </>
  )
}
const Report003Page = memo(Report003PageCpn, isEqual)

export default Report003Page
