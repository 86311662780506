import { BaseCol } from 'app/components/common/BaseCol'
import { FONT_WEIGHT } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const DescriptionModal = ({
  titleKey,
  contentKey,
  name,
}: {
  titleKey: string
  contentKey: string
  name?: string
}) => {
  const { t } = useTranslation()
  return (
    <>
      <BaseCol>
        <span>
          {`${t(titleKey)} `}
          {name && <TextAccount>{name ?? ''}</TextAccount>}
        </span>
      </BaseCol>
      <BaseCol>{t(contentKey)}</BaseCol>
    </>
  )
}

const TextAccount = styled.span`
  color: var(--states-red-color);
  font-weight: ${FONT_WEIGHT.bold};
`
