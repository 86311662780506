import { useMemo, useState } from 'react'
import { ToothSizeAndMorphologyData } from '../constant'
import {
  IBridgeTooth,
  IToothSizeAndMorphology,
  PositionToothEnum,
  ToothNumberEnum,
  ToothStatusEnum,
} from '../type'
import { notificationController } from 'app/controllers/notification-controller'
import { getRandomUuid } from 'parkway-web-common'
import { isEqual } from 'lodash'

export const useToothSizeAndMorphologyCurrentStatus = () => {
  const [toothSizeAndMorphologyTabCurrentStatus, setToothSizeAndMorphologyTabCurrentStatus] =
    useState<IToothSizeAndMorphology>(ToothSizeAndMorphologyData)

  const [bridgeToothBottomTabCurrentStatus, setBridgeToothBottomTabCurrentStatus] = useState<IBridgeTooth[]>([])

  const onAddBridgeBottomTabCurrentStatus = (start: ToothNumberEnum, indexStart: number) => {
    const newItem: IBridgeTooth = {
      id: getRandomUuid(),
      start,
      indexStart,
    }

    setBridgeToothBottomTabCurrentStatus(prev => prev.concat([newItem]))
  }

  const onChangeBridgeBottomTabCurrentStatus = (
    id?: string,
    end?: ToothNumberEnum,
    indexEnd?: number,
  ) => {
    setBridgeToothBottomTabCurrentStatus(prev =>
      prev?.map(item => {
        if (isEqual(item?.id, id) && item?.indexStart !== undefined) {
          if (indexEnd !== undefined && item?.indexStart > indexEnd) {
            const oldIndexStart = item.indexStart
            const oldStart = item.start

            return {
              ...item,
              end: oldStart,
              start: end,
              indexEnd: oldIndexStart,
              indexStart: indexEnd,
            }
          }
          return {
            ...item,
            indexEnd,
            end,
          }
        }
        return item
      }),
    )
  }

  const onDeleteBridgeBottomTabCurrentStatus = (ids: string[]) => {
    setBridgeToothBottomTabCurrentStatus(prev => prev?.filter(item => !ids?.find((itm) => itm === item?.id)))
  }

  const [bridgeToothTopTabCurrentStatus, setBridgeToothTopTabCurrentStatus] = useState<IBridgeTooth[]>([])

  const onAddBridgeTopTabCurrentStatus = (start: ToothNumberEnum, indexStart: number) => {
    const itemErrorExist = bridgeToothTopTabCurrentStatus?.find(
      itm => (itm?.start ?? 0) < indexStart && indexStart < (itm?.end ?? 0),
    )

    if (itemErrorExist) {
      notificationController.warning({
        message: `Không thể chọn cầu răng này vì đã tồn tại cầu (R${itemErrorExist?.start}, R(${itemErrorExist.end}))`,
      })
      return
    }

    const newItem: IBridgeTooth = {
      id: getRandomUuid(),
      start,
      indexStart,
    }

    setBridgeToothTopTabCurrentStatus(prev => prev.concat([newItem]))
  }

  const onChangeBridgeTopTabCurrentStatus = (
    id?: string,
    end?: ToothNumberEnum,
    indexEnd?: number,
  ) => {
    setBridgeToothTopTabCurrentStatus(prev =>
      prev?.map(item => {
        if (isEqual(item?.id, id) && item?.indexStart !== undefined) {
          if (indexEnd !== undefined && item?.indexStart > indexEnd) {
            const oldIndexStart = item.indexStart
            const oldStart = item.start

            return {
              ...item,
              end: oldStart,
              start: end,
              indexEnd: oldIndexStart,
              indexStart: indexEnd,
            }
          }
          return {
            ...item,
            indexEnd,
            end,
          }
        }
        return item
      }),
    )
  }

  const onDeleteBridgeTopTabCurrentStatus = (ids: string[]) => {
    setBridgeToothTopTabCurrentStatus(prev => prev?.filter(item => !ids?.find((itm) => itm === item?.id)))
  }

  const onChangeStatusToothSizeAndMorphology = (
    position: PositionToothEnum,
    key: ToothNumberEnum,
    newStatus?: ToothStatusEnum,
  ) => {
    setToothSizeAndMorphologyTabCurrentStatus(prev => {
      const itemValue = {
        ...prev[position][key],
        currentStatus: newStatus,
      }

      const positionValue = {
        ...prev[position],
        [key]: itemValue,
      }

      return {
        ...prev,
        [position]: positionValue,
      }
    })
  }

  const onChangeValueToothSizeAndMorphology = (
    position: PositionToothEnum,
    key: ToothNumberEnum,
    newValue?: number,
  ) => {
    setToothSizeAndMorphologyTabCurrentStatus(prev => {
      const itemValue = {
        ...prev[position][key],
        value: newValue,
      }

      const positionValue = {
        ...prev[position],
        [key]: itemValue,
      }

      return {
        ...prev,
        [position]: positionValue,
      }
    })
  }

  const bridgeBottomTabCurrentStatusSelecting = useMemo(() => {
    return bridgeToothBottomTabCurrentStatus?.find(itm => itm?.id && !itm?.end)
  }, [bridgeToothBottomTabCurrentStatus])

  const bridgeTopTabCurrentStatusSelecting = useMemo(() => {
    return bridgeToothTopTabCurrentStatus?.find(itm => itm?.id && !itm?.end)
  }, [bridgeToothTopTabCurrentStatus])

  return {
    bridgeBottomTabCurrentStatusSelecting,
    onChangeStatusToothSizeAndMorphology,
    onChangeBridgeBottomTabCurrentStatus,
    onAddBridgeBottomTabCurrentStatus,
    onDeleteBridgeBottomTabCurrentStatus,
    bridgeToothBottomTabCurrentStatus,

    onChangeBridgeTopTabCurrentStatus,
    onAddBridgeTopTabCurrentStatus,
    onDeleteBridgeTopTabCurrentStatus,
    bridgeToothTopTabCurrentStatus,
    toothSizeAndMorphologyTabCurrentStatus,
    bridgeTopTabCurrentStatusSelecting,
    onChangeValueToothSizeAndMorphology
  }
}
