/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetAllJobTitleManagement } from 'app/api/jobtitle'
import { JobTitleMessageCode } from 'app/api/jobtitle/constant'
import { IJobTitleManagement } from 'app/api/jobtitle/model/job-title-management'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useInfiniteQuery } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { JobTitleKeys } from '../query-key/job-title'

export function useGetJobTitleManagementList(enabled = true) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IJobTitleManagement[]>>(
    // <IResponseQueryList<IJobTitleManagement[]>>
    JobTitleKeys.listJobTitle(),
    async () => {
      try {
        const res: ResponseType<IJobTitleManagement[]> =
          await requestGetAllJobTitleManagement()

        if (
          isEqual(
            res?.msgcode,
            JobTitleMessageCode.JobTitleManagement.successGetData,
          )
        ) {
          const data: IJobTitleManagement[] = res?.data || []
          return {
            data,
            total: res?.data.length ?? 0,
          }
        }
        return {
          data: [],
          total: res?.data.length,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
