import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { ISupplies } from 'app/containers/Accounting/ItemManagement/model/supplies'
import { useTranslation } from 'react-i18next'

export const InfoMaterialsLayout = ({
  suppliers,
}: {
  suppliers: ISupplies[]
}) => {
  const { t } = useTranslation()
  return (
    <BaseSpace>
      <BaseText
        children={t(R.strings.equipment_information)}
        fontWeight="semibold"
      />

      {suppliers?.map((item, index) => {
        return (
          <BaseRow
            key={index}
            align={'middle'}
            justify={'space-between'}
            gutter={[15, 10]}
          >
            <BaseCol>
              <BaseText children={item?.name} opacity="0.5" />
            </BaseCol>
            <BaseCol>
              <BaseText
                children={`${item?.quantity} (Cái)`}
                fontWeight="medium"
              />
            </BaseCol>
          </BaseRow>
        )
      })}
    </BaseSpace>
  )
}
