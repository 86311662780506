import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import TextCustomComponent from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/TextCustomComponent'
import { FONT_WEIGHT, formatMoney } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  totalPayment?: number
  isComplete?: boolean
}

const DetailPaymentLayout = ({ totalPayment = 0 }: IProps) => {
  const { t } = useTranslation()
  const [customerPayment, setCustomerPayment] = useState(0)

  const excessMoney = useMemo(() => {
    if (totalPayment >= customerPayment) {
      return 0
    }

    return customerPayment - totalPayment
  }, [totalPayment, customerPayment])

  const totalPaymentMade = useMemo(() => {
    if (excessMoney) return totalPayment

    return customerPayment
  }, [excessMoney, customerPayment])

  const onChangeCustomerPayment = (value: string) => {
    setCustomerPayment(Number(value))
  }


  return (
    <SpaceWrapper direction="vertical" size={15}>
      <TextCustomComponent fontWeight={FONT_WEIGHT.semibold}>
        {t(R.strings.detail_payment)}
      </TextCustomComponent>

      <BaseRow gutter={[10, 10]} align={'middle'} justify={'space-between'}>
        <BaseCol>
          <TextCustomComponent opacity="0.7">
            {t(R.strings.total_payment_required)}
          </TextCustomComponent>
        </BaseCol>
        <BaseCol>
          <TextCustomComponent>{formatMoney(totalPayment)}</TextCustomComponent>
        </BaseCol>
      </BaseRow>

      <BaseRow gutter={[10, 10]} align={'middle'} justify={'space-between'}>
        <BaseCol>
          <TextCustomComponent opacity="0.7">
            {t(R.strings.amount_paid_by_customer)}
          </TextCustomComponent>
        </BaseCol>
        <BaseCol>
          <BaseFormItem hiddenLabel>
            <BaseInputNumber
              placeholder={t(R.strings.enter_amount_paid_by_customer)}
              onChange={value => onChangeCustomerPayment(value as string)}
            />
          </BaseFormItem>
        </BaseCol>
      </BaseRow>

      <BaseRow gutter={[10, 10]} align={'middle'} justify={'space-between'}>
        <BaseCol>
          <TextCustomComponent opacity="0.7">
            {t(R.strings.excess_money)}
          </TextCustomComponent>
        </BaseCol>
        <BaseCol>
          <TextCustomComponent>{formatMoney(excessMoney)}</TextCustomComponent>
        </BaseCol>
      </BaseRow>

      <BaseRow gutter={[10, 10]} align={'middle'} justify={'space-between'}>
        <BaseCol>
          <TextCustomComponent opacity="0.7">
            {t(R.strings.total_payment_made)}
          </TextCustomComponent>
        </BaseCol>
        <BaseCol>
          <TextCustomComponent>
            {formatMoney(totalPaymentMade)}
          </TextCustomComponent>
        </BaseCol>
      </BaseRow>
    </SpaceWrapper>
  )
}

export default DetailPaymentLayout

export const SpaceWrapper = styled(BaseSpace)`
  width: 100%;
`
export const BaseInputNumber = styled(InputNumber)`
  width: 100%;
`
export const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
  min-width: 300px;

  .ant-form-item-control-input {
    min-height: unset !important;
  }
`
