import {
  requestDetailBookingManagement,
  requestUpdateBookingManagement,
} from 'app/api/booking'
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { first, isEqual } from 'lodash'
import { PHONE_REGEX, ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormData, IFormRule, IPropsUpdateBooking } from './type'
import {
  IIndustry,
  IPayloadUpdateBookingManagement,
  IBooking,
  BookingStatusEnum,
} from 'app/api/booking/model/management'
import { BookingMessageCode } from 'app/api/booking/constant'
import { requestGetAllIndustryManagement } from 'app/api/industry'
import { IndustryMessageCode } from 'app/api/industry/constant'
import {
  formatDateDayjs,
  formatTimeDayjs,
} from 'app/helpers/date.helper'

export const useModalHook = (props: IPropsUpdateBooking) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormData>()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [industries, setIndustry] = useState<IIndustry[]>()

  const getDetail = async () => {
    if (!props?.id) {
      return
    }
    try {
      const res: ResponseType<IBooking> = await requestDetailBookingManagement(
        props?.id,
      )

      if (
        isEqual(
          res?.msgcode,
          BookingMessageCode.BookingManagement.successGetData,
        )
      ) {
        const data = res?.data

        form.setFieldsValue({
          _id: data._id,
          fullName: data?.fullName,
          phone: data.phone ?? undefined,
          treatments: first(data.treatments) ?? undefined,
          areaId: data.areaId ?? undefined,
          clinicId: data.clinicId ?? undefined,
          doctorId: data.doctorId ?? undefined,
          callCenterId: data.callCenterId ?? undefined,
          date: data.date ? formatDateDayjs(data.date) : undefined,
          time: data.time ? formatTimeDayjs(data.time) : undefined,
          note: data.note ?? '',
          status: data.status ?? undefined,
        })
      }
    } catch (error) {
     // console.log({ error })
    }
  }

  useEffect(() => {
    visible && getDetail()
  }, [props?.id, visible])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (
    id: string,
    action?: BookingStatusEnum,
  ) => {
    setIsLoading(true)
    try {
      const body: IPayloadUpdateBookingManagement = {
        status: props.bodyStatus ?? action ?? undefined,
      }

      const response: ResponseType<string> =
        await requestUpdateBookingManagement({
          id,
          body,
        })

      if (
        isEqual(
          response?.msgcode,
          BookingMessageCode.BookingManagement.successPutData,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        props?.fetchTable?.()
        notificationController.success({
          message: t(R.strings.cancel),
          description: response?.message,
        })
      } else {
        notificationController.error({
          message: t(R.strings.cancel),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const getAllIndustry = async () => {
    try {
      const res: ResponseType<IIndustry[]> =
        await requestGetAllIndustryManagement()

      if (
        isEqual(
          res?.msgcode,
          IndustryMessageCode.IndustryManagement.successGetData,
        )
      ) {
        const data: IIndustry[] = res?.data || []
        setIndustry(data)
      }
    } catch (error) {
     // console.log({ error })
    }
  }

  useEffect(() => {
    visible && getAllIndustry()
  }, [visible])

  const rules: IFormRule = useMemo(() => {
    return {
      fullName: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.booking_field_name),
          }),
        },
      ],
      phone: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.booking_field_phone),
          }),
        },
        {
          pattern: PHONE_REGEX,
          message: t(R.strings.regex_phone),
        },
      ],
      areaId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.booking_field_area),
          }),
        },
      ],
      clinicId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.booking_field_clinic),
          }),
        },
      ],
      treatments: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.booking_field_treatments),
          }),
        },
      ],
      time: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.booking_field_time),
          }),
        },
      ],
      date: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.booking_field_date),
          }),
        },
      ],
      doctorId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.booking_field_doctor),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    industries,
    id: props.id,
  }
}
