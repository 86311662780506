import R from 'app/assets/R'
import { YesNoEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRadio } from 'app/components/common/BaseRadio'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import TextCustomComponent from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/TextCustomComponent'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { IOrderDebtPayment } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/debt-payment'
import { FONT_WEIGHT } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const IssueInvoiceLayout = ({
  orderItem,
}: {
  orderItem: IOrderDebtPayment
}) => {
  const { t } = useTranslation()
  const { onChangeIssueInvoiceDebtPayment } = useProfilePatientContext()

  const onChange = (value: YesNoEnum) => {
    onChangeIssueInvoiceDebtPayment?.(orderItem?.orderId, value)
  }

  return (
    <BaseSpace>
      <TextCustomComponent fontWeight={FONT_WEIGHT.semibold}>
        {t(R.strings.issue_invoice)}
      </TextCustomComponent>

      <BaseRadioGroup
        defaultValue={YesNoEnum.Yes}
        value={orderItem.issueInvoice}
        onChange={e => onChange(e?.target?.value as YesNoEnum)}
      >
        <BaseRow gutter={[15, 10]} align={'middle'}>
          <BaseCol xl={6}>
            <RadioBtn value={YesNoEnum.Yes}>
              <TextCustomComponent>{t(R.strings.yes)}</TextCustomComponent>
            </RadioBtn>
          </BaseCol>
          <BaseCol xl={6}>
            <RadioBtn value={YesNoEnum.No}>
              <TextCustomComponent>{t(R.strings.no)}</TextCustomComponent>
            </RadioBtn>
          </BaseCol>
        </BaseRow>
      </BaseRadioGroup>
    </BaseSpace>
  )
}

export default IssueInvoiceLayout

const RadioBtn = styled(BaseRadio)`
  font-size: 0.875rem;
`
const BaseRadioGroup = styled(BaseRadio.Group)`
  width: 100%;
`
