import R from 'app/assets/R'
import {
  BracesTagEnum,
  IToothSizeAndMorphology,
  PositionToothEnum,
  ToothNumberEnum,
} from './type'

export const ToothSizeAndMorphologyData: IToothSizeAndMorphology = {
  [PositionToothEnum.Top]: {
    [ToothNumberEnum.Tooth18]: {
      imageToothImplant: R.images.ic_teeth_18_implant,
      imageTooth: R.images.ic_teeth_18,
      key: ToothNumberEnum.Tooth18,
      position: PositionToothEnum.Top,
    },
    [ToothNumberEnum.Tooth17]: {
      imageToothImplant: R.images.ic_teeth_17_implant,
      imageTooth: R.images.ic_teeth_17,
      key: ToothNumberEnum.Tooth17,
      position: PositionToothEnum.Top,
    },
    [ToothNumberEnum.Tooth16]: {
      imageToothImplant: R.images.ic_teeth_16_implant,
      imageTooth: R.images.ic_teeth_16,
      key: ToothNumberEnum.Tooth16,
      position: PositionToothEnum.Top,
    },
    [ToothNumberEnum.Tooth15]: {
      imageToothImplant: R.images.ic_teeth_15_implant,
      imageTooth: R.images.ic_teeth_15,
      key: ToothNumberEnum.Tooth15,
      position: PositionToothEnum.Top,
    },
    [ToothNumberEnum.Tooth14]: {
      imageToothImplant: R.images.ic_teeth_14_implant,
      imageTooth: R.images.ic_teeth_14,
      key: ToothNumberEnum.Tooth14,
      position: PositionToothEnum.Top,
    },
    [ToothNumberEnum.Tooth13]: {
      imageToothImplant: R.images.ic_teeth_13_implant,
      imageTooth: R.images.ic_teeth_13,
      key: ToothNumberEnum.Tooth13,
      position: PositionToothEnum.Top,
    },
    [ToothNumberEnum.Tooth12]: {
      imageToothImplant: R.images.ic_teeth_12_implant,
      imageTooth: R.images.ic_teeth_12,
      key: ToothNumberEnum.Tooth12,
      position: PositionToothEnum.Top,
    },
    [ToothNumberEnum.Tooth11]: {
      imageToothImplant: R.images.ic_teeth_11_implant,
      imageTooth: R.images.ic_teeth_11,
      key: ToothNumberEnum.Tooth11,
      position: PositionToothEnum.Top,
    },

    [ToothNumberEnum.Tooth21]: {
      imageToothImplant: R.images.ic_teeth_21_implant,
      imageTooth: R.images.ic_teeth_21,
      key: ToothNumberEnum.Tooth21,
      position: PositionToothEnum.Top,
    },
    [ToothNumberEnum.Tooth22]: {
      imageToothImplant: R.images.ic_teeth_22_implant,
      imageTooth: R.images.ic_teeth_22,
      key: ToothNumberEnum.Tooth22,
      position: PositionToothEnum.Top,
    },
    [ToothNumberEnum.Tooth23]: {
      imageToothImplant: R.images.ic_teeth_23_implant,
      imageTooth: R.images.ic_teeth_23,
      key: ToothNumberEnum.Tooth23,
      position: PositionToothEnum.Top,
    },
    [ToothNumberEnum.Tooth24]: {
      imageToothImplant: R.images.ic_teeth_24_implant,
      imageTooth: R.images.ic_teeth_24,
      key: ToothNumberEnum.Tooth24,
      position: PositionToothEnum.Top,
    },
    [ToothNumberEnum.Tooth25]: {
      imageToothImplant: R.images.ic_teeth_25_implant,
      imageTooth: R.images.ic_teeth_25,
      key: ToothNumberEnum.Tooth25,
      position: PositionToothEnum.Top,
    },
    [ToothNumberEnum.Tooth26]: {
      imageToothImplant: R.images.ic_teeth_26_implant,
      imageTooth: R.images.ic_teeth_26,
      key: ToothNumberEnum.Tooth26,
      position: PositionToothEnum.Top,
    },
    [ToothNumberEnum.Tooth27]: {
      imageToothImplant: R.images.ic_teeth_27_implant,
      imageTooth: R.images.ic_teeth_27,
      key: ToothNumberEnum.Tooth27,
      position: PositionToothEnum.Top,
    },
    [ToothNumberEnum.Tooth28]: {
      imageToothImplant: R.images.ic_teeth_28_implant,
      imageTooth: R.images.ic_teeth_28,
      key: ToothNumberEnum.Tooth28,
      position: PositionToothEnum.Top,
    },
  },
  [PositionToothEnum.Bottom]: {
    [ToothNumberEnum.Tooth48]: {
      imageToothImplant: R.images.ic_teeth_48_implant,
      imageTooth: R.images.ic_teeth_48,
      key: ToothNumberEnum.Tooth48,
      position: PositionToothEnum.Bottom,
    },
    [ToothNumberEnum.Tooth47]: {
      imageToothImplant: R.images.ic_teeth_47_implant,
      imageTooth: R.images.ic_teeth_47,
      key: ToothNumberEnum.Tooth47,
      position: PositionToothEnum.Bottom,
    },
    [ToothNumberEnum.Tooth46]: {
      imageToothImplant: R.images.ic_teeth_46_implant,
      imageTooth: R.images.ic_teeth_46,
      key: ToothNumberEnum.Tooth46,
      position: PositionToothEnum.Bottom,
    },
    [ToothNumberEnum.Tooth45]: {
      imageToothImplant: R.images.ic_teeth_45_implant,
      imageTooth: R.images.ic_teeth_45,
      key: ToothNumberEnum.Tooth45,
      position: PositionToothEnum.Bottom,
    },
    [ToothNumberEnum.Tooth44]: {
      imageToothImplant: R.images.ic_teeth_44_implant,
      imageTooth: R.images.ic_teeth_44,
      key: ToothNumberEnum.Tooth44,
      position: PositionToothEnum.Bottom,
    },
    [ToothNumberEnum.Tooth43]: {
      imageToothImplant: R.images.ic_teeth_43_implant,
      imageTooth: R.images.ic_teeth_43,
      key: ToothNumberEnum.Tooth43,
      position: PositionToothEnum.Bottom,
    },
    [ToothNumberEnum.Tooth42]: {
      imageToothImplant: R.images.ic_teeth_42_implant,
      imageTooth: R.images.ic_teeth_42,
      key: ToothNumberEnum.Tooth42,
      position: PositionToothEnum.Bottom,
    },
    [ToothNumberEnum.Tooth41]: {
      imageToothImplant: R.images.ic_teeth_41_implant,
      imageTooth: R.images.ic_teeth_41,
      key: ToothNumberEnum.Tooth41,
      position: PositionToothEnum.Bottom,
    },

    [ToothNumberEnum.Tooth31]: {
      imageToothImplant: R.images.ic_teeth_31_implant,
      imageTooth: R.images.ic_teeth_31,
      key: ToothNumberEnum.Tooth31,
      position: PositionToothEnum.Bottom,
    },
    [ToothNumberEnum.Tooth32]: {
      imageToothImplant: R.images.ic_teeth_32_implant,
      imageTooth: R.images.ic_teeth_32,
      key: ToothNumberEnum.Tooth32,
      position: PositionToothEnum.Bottom,
    },
    [ToothNumberEnum.Tooth33]: {
      imageToothImplant: R.images.ic_teeth_33_implant,
      imageTooth: R.images.ic_teeth_33,
      key: ToothNumberEnum.Tooth33,
      position: PositionToothEnum.Bottom,
    },
    [ToothNumberEnum.Tooth34]: {
      imageToothImplant: R.images.ic_teeth_34_implant,
      imageTooth: R.images.ic_teeth_34,
      key: ToothNumberEnum.Tooth34,
      position: PositionToothEnum.Bottom,
    },
    [ToothNumberEnum.Tooth35]: {
      imageToothImplant: R.images.ic_teeth_35_implant,
      imageTooth: R.images.ic_teeth_35,
      key: ToothNumberEnum.Tooth35,
      position: PositionToothEnum.Bottom,
    },
    [ToothNumberEnum.Tooth36]: {
      imageToothImplant: R.images.ic_teeth_36_implant,
      imageTooth: R.images.ic_teeth_36,
      key: ToothNumberEnum.Tooth36,
      position: PositionToothEnum.Bottom,
    },
    [ToothNumberEnum.Tooth37]: {
      imageToothImplant: R.images.ic_teeth_37_implant,
      imageTooth: R.images.ic_teeth_37,
      key: ToothNumberEnum.Tooth37,
      position: PositionToothEnum.Bottom,
    },
    [ToothNumberEnum.Tooth38]: {
      imageToothImplant: R.images.ic_teeth_38_implant,
      imageTooth: R.images.ic_teeth_38,
      key: ToothNumberEnum.Tooth38,
      position: PositionToothEnum.Bottom,
    },
  },
}

export const ListTopToothKey = [
  ToothNumberEnum.Tooth18,
  ToothNumberEnum.Tooth17,
  ToothNumberEnum.Tooth16,
  ToothNumberEnum.Tooth15,
  ToothNumberEnum.Tooth14,
  ToothNumberEnum.Tooth13,
  ToothNumberEnum.Tooth12,
  ToothNumberEnum.Tooth11,
  ToothNumberEnum.Tooth21,
  ToothNumberEnum.Tooth22,
  ToothNumberEnum.Tooth23,
  ToothNumberEnum.Tooth24,
  ToothNumberEnum.Tooth25,
  ToothNumberEnum.Tooth26,
  ToothNumberEnum.Tooth27,
  ToothNumberEnum.Tooth28,
]

export const ListBottomToothKey = [
  ToothNumberEnum.Tooth48,
  ToothNumberEnum.Tooth47,
  ToothNumberEnum.Tooth46,
  ToothNumberEnum.Tooth45,
  ToothNumberEnum.Tooth44,
  ToothNumberEnum.Tooth43,
  ToothNumberEnum.Tooth42,
  ToothNumberEnum.Tooth41,
  ToothNumberEnum.Tooth31,
  ToothNumberEnum.Tooth32,
  ToothNumberEnum.Tooth33,
  ToothNumberEnum.Tooth34,
  ToothNumberEnum.Tooth35,
  ToothNumberEnum.Tooth36,
  ToothNumberEnum.Tooth37,
  ToothNumberEnum.Tooth38,
]

export const DefineBracesTagEnum = {
  [BracesTagEnum.Adult]: {
    text: BracesTagEnum.Adult,
    backgroundColor: 'linear-gradient(90deg, #FFAE49, #FF7617)',
  },
  [BracesTagEnum.Child]: {
    text: BracesTagEnum.Child,
    backgroundColor: 'linear-gradient(90deg, #FFAE49, #FF7617)',
  },
  [BracesTagEnum.Teenager]: {
    text: BracesTagEnum.Teenager,
    backgroundColor: 'linear-gradient(90deg, #FFAE49, #FF7617)',
  },

  [BracesTagEnum.ConvexProfile]: {
    text: BracesTagEnum.ConvexProfile,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.ConcaveProfile]: {
    text: BracesTagEnum.ConcaveProfile,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.StraightProfile]: {
    text: BracesTagEnum.StraightProfile,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.ProtrudedChin]: {
    text: BracesTagEnum.ProtrudedChin,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.RetrudedChin]: {
    text: BracesTagEnum.RetrudedChin,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },

  [BracesTagEnum.Dolichofacial]: {
    text: BracesTagEnum.Dolichofacial,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.Mesofacial]: {
    text: BracesTagEnum.Mesofacial,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.Brachyfacial]: {
    text: BracesTagEnum.Brachyfacial,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.DentalClassI]: {
    text: BracesTagEnum.DentalClassI,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.DentalClassIIDivI]: {
    text: BracesTagEnum.DentalClassIIDivI,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.DentalClassIIDiv2]: {
    text: BracesTagEnum.DentalClassIIDiv2,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.DentalClassIII]: {
    text: BracesTagEnum.DentalClassIII,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.SkeletalClassI]: {
    text: BracesTagEnum.SkeletalClassI,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.SkeletalClassII]: {
    text: BracesTagEnum.SkeletalClassII,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.SkeletalClassIII]: {
    text: BracesTagEnum.SkeletalClassIII,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.PosteriorCrossbite]: {
    text: BracesTagEnum.PosteriorCrossbite,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.AnteriorCrossbite]: {
    text: BracesTagEnum.AnteriorCrossbite,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.EdgeToEdgeBite]: {
    text: BracesTagEnum.EdgeToEdgeBite,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.IncreaseedOverjet]: {
    text: BracesTagEnum.IncreaseedOverjet,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.OpenBite]: {
    text: BracesTagEnum.OpenBite,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.DeepBite]: {
    text: BracesTagEnum.DeepBite,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.NarrowArches]: {
    text: BracesTagEnum.NarrowArches,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.AsymmetricArches]: {
    text: BracesTagEnum.AsymmetricArches,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.MLD]: {
    text: BracesTagEnum.MLD,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.MidlineShift]: {
    text: BracesTagEnum.MidlineShift,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.Crowding]: {
    text: BracesTagEnum.Crowding,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.Rotations]: {
    text: BracesTagEnum.Rotations,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.ImpactedCanine]: {
    text: BracesTagEnum.ImpactedCanine,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [BracesTagEnum.Spacing]: {
    text: BracesTagEnum.Spacing,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },

  [BracesTagEnum.Distalization]: {
    text: BracesTagEnum.Distalization,
    backgroundColor: 'linear-gradient(90deg, #FD7B59, #F75428)',
  },
  [BracesTagEnum.Mesialization]: {
    text: BracesTagEnum.Mesialization,
    backgroundColor: 'linear-gradient(90deg, #FD7B59, #F75428)',
  },
  [BracesTagEnum.LelelingCurveOfSpee]: {
    text: BracesTagEnum.LelelingCurveOfSpee,
    backgroundColor: 'linear-gradient(90deg, #FD7B59, #F75428)',
  },
  [BracesTagEnum.Extrusion]: {
    text: BracesTagEnum.Extrusion,
    backgroundColor: 'linear-gradient(90deg, #FD7B59, #F75428)',
  },
  [BracesTagEnum.Expansion]: {
    text: BracesTagEnum.Expansion,
    backgroundColor: 'linear-gradient(90deg, #FD7B59, #F75428)',
  },
  [BracesTagEnum.Proclination]: {
    text: BracesTagEnum.Proclination,
    backgroundColor: 'linear-gradient(90deg, #FD7B59, #F75428)',
  },
  [BracesTagEnum.FiestPremolarExtraction]: {
    text: BracesTagEnum.FiestPremolarExtraction,
    backgroundColor: 'linear-gradient(90deg, #FD7B59, #F75428)',
  },
  [BracesTagEnum.SecondPremolarExtraction]: {
    text: BracesTagEnum.SecondPremolarExtraction,
    backgroundColor: 'linear-gradient(90deg, #FD7B59, #F75428)',
  },
  [BracesTagEnum.LowerIncisorExtraction]: {
    text: BracesTagEnum.LowerIncisorExtraction,
    backgroundColor: 'linear-gradient(90deg, #FD7B59, #F75428)',
  },
  [BracesTagEnum.OtherExtraction]: {
    text: BracesTagEnum.OtherExtraction,
    backgroundColor: 'linear-gradient(90deg, #FD7B59, #F75428)',
  },
  [BracesTagEnum.IPR]: {
    text: BracesTagEnum.IPR,
    backgroundColor: 'linear-gradient(90deg, #FD7B59, #F75428)',
  },
  [BracesTagEnum.TADs]: {
    text: BracesTagEnum.TADs,
    backgroundColor: 'linear-gradient(90deg, #FD7B59, #F75428)',
  },
  [BracesTagEnum.SpaceClosure]: {
    text: BracesTagEnum.SpaceClosure,
    backgroundColor: 'linear-gradient(90deg, #FD7B59, #F75428)',
  },
  [BracesTagEnum.FunctinalAppliances]: {
    text: BracesTagEnum.FunctinalAppliances,
    backgroundColor: 'linear-gradient(90deg, #FD7B59, #F75428)',
  },
  [BracesTagEnum.PreRestorative]: {
    text: BracesTagEnum.PreRestorative,
    backgroundColor: 'linear-gradient(90deg, #FD7B59, #F75428)',
  },
  [BracesTagEnum.PreSurgical]: {
    text: BracesTagEnum.PreSurgical,
    backgroundColor: 'linear-gradient(90deg, #FD7B59, #F75428)',
  },
  [BracesTagEnum.Retroclination]: {
    text: BracesTagEnum.Retroclination,
    backgroundColor: 'linear-gradient(90deg, #FD7B59, #F75428)',
  },

  [BracesTagEnum.Implants]: {
    text: BracesTagEnum.Implants,
    backgroundColor: 'linear-gradient(90deg, #365B7E, #365B7E)',
  },
  [BracesTagEnum.Restoration]: {
    text: BracesTagEnum.Restoration,
    backgroundColor: 'linear-gradient(90deg, #365B7E, #365B7E)',
  },
}

export const ListBracesTagKey = Object.keys(BracesTagEnum)
