import R from 'app/assets/R'
import { LeftSideValueTable, RightSideValueTable } from '../../styles'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseCol } from 'app/components/common/BaseCol'
import { ItemDiagnosis } from '../../components'

export const ToothLayout = () => {
  return (
    <BaseRow>
      <LeftSideValueTable xl={3} className="child-wrapper">
        <BaseText children={'Răng (II)'} fontWeight="medium" fontSize="xs" />
      </LeftSideValueTable>
      <RightSideValueTable xl={21} className="child-wrapper">
        <BaseSpace size={16}>
          <BaseRow gutter={10} align="top">
            <BaseCol xl={8}>
              <ItemDiagnosis
                label={'Tương quan R6 (Phải):'}
                stt={1}
                placeholder={R.strings.enter_content}
                isTwoInput
                placeholderSecond="n/a"
                suffixSecond="(mm)"
              />
            </BaseCol>
            <BaseCol xl={8}>
              <ItemDiagnosis
                label={'Tương quan răng cửa'}
                stt={1}
                placeholder={R.strings.enter_content}
              />
            </BaseCol>
            <BaseCol xl={8}>
              <ItemDiagnosis
                label={'Đường giữa hàm trên so với đường giữa mặt'}
                stt={1}
                placeholder={R.strings.enter_content}
              />
            </BaseCol>
          </BaseRow>

          <BaseRow gutter={10}>
            <BaseCol xl={8}>
              <ItemDiagnosis
                label={'Tương quan R6 (Trái):'}
                stt={2}
                placeholder={R.strings.enter_content}
                isTwoInput
                placeholderSecond="n/a"
                suffixSecond="(mm)"
              />
            </BaseCol>
            <BaseCol xl={8}>
              <ItemDiagnosis
                label="Cắn trùm / hở"
                stt={2}
                placeholder={'n/a'}
                suffix="(mm)"
                note="(Giá trị tương đương cho cắn trùm, giá trị âm cho
                cắn hở)"
              />
            </BaseCol>
            <BaseCol xl={8}>
              <ItemDiagnosis
                label={'Mức độ lệch'}
                stt={2}
                placeholder={'n/a'}
                suffix="(mm)"
              />
            </BaseCol>
          </BaseRow>

          <BaseRow gutter={10}>
            <BaseCol xl={8}>
              <ItemDiagnosis
                label={'Tương quan R3 (Phải):'}
                stt={3}
                placeholder={R.strings.enter_content}
                isTwoInput
                placeholderSecond="n/a"
                suffixSecond="(mm)"
              />
            </BaseCol>
            <BaseCol xl={8}>
              <ItemDiagnosis
                label={'Đường cười'}
                stt={3}
                placeholder={R.strings.enter_content}
              />
            </BaseCol>
            <BaseCol xl={8}>
              <ItemDiagnosis
                label={'Đường giữa hàm dưới so với đường giữa mặt'}
                stt={3}
                placeholder={R.strings.enter_content}
              />
            </BaseCol>
          </BaseRow>

          <BaseRow gutter={10}>
            <BaseCol xl={8}>
              <ItemDiagnosis
                label={'Tương quan R3 (Trái):'}
                stt={4}
                placeholder={R.strings.enter_content}
                isTwoInput
                placeholderSecond="n/a"
                suffixSecond="(mm)"
              />
            </BaseCol>
            <BaseCol xl={8}>
              <ItemDiagnosis
                label={'Cười hở lợi'}
                stt={4}
                placeholder="n/a"
                suffix="(mm)"
              />
            </BaseCol>
            <BaseCol xl={8}>
              <ItemDiagnosis
                label={'Mức độ lệch'}
                stt={4}
                placeholder="n/a"
                suffix="(mm)"
              />
            </BaseCol>
          </BaseRow>

          <BaseRow gutter={10}>
            <BaseCol xl={8}>
              <ItemDiagnosis
                label={'Cắn chìa:'}
                stt={5}
                placeholder="n/a"
                suffix="(mm)"
              />
            </BaseCol>
            <BaseCol xl={8}>
              <BaseSpace>
                <BaseRow gutter={10}>
                  <BaseCol xl={12}>
                    <ItemDiagnosis
                      label={'Từ răng:'}
                      stt={5}
                      placeholder={R.strings.enter_content}
                    />
                  </BaseCol>
                  <BaseCol xl={12}>
                    <ItemDiagnosis
                      label={'đến răng'}
                      placeholder={R.strings.enter_content}
                    />
                  </BaseCol>
                </BaseRow>
                <ItemDiagnosis
                  label={'Cung cười'}
                  placeholder={R.strings.enter_content}
                />
              </BaseSpace>
            </BaseCol>
            <BaseCol xl={8}>
              <BaseSpace>
                <ItemDiagnosis
                  label={'Cắn chéo răng hàm'}
                  placeholder={R.strings.enter_content}
                  stt={5}
                />
                <ItemDiagnosis label={'Tại răng'} placeholder={'n/a'} />
              </BaseSpace>
            </BaseCol>
          </BaseRow>

          <BaseRow gutter={10}>
            <BaseCol xl={8}>
              <BaseSpace size={10}>
                <ItemDiagnosis
                  label={'Cắn ngược răng cửa / nanh:'}
                  stt={6}
                  placeholder={R.strings.enter_content}
                />
                <ItemDiagnosis label={'Tại răng'} placeholder={'n/a'} />
              </BaseSpace>
            </BaseCol>
            <BaseCol xl={8}>
              <ItemDiagnosis
                label={'Mặt phẳng nhai'}
                stt={6}
                placeholder={R.strings.enter_content}
              />
            </BaseCol>
            <BaseCol xl={8}>
              <BaseSpace size={10}>
                <ItemDiagnosis
                  label={'Cắn cắt kéo răng hàm'}
                  stt={6}
                  placeholder={R.strings.enter_content}
                />
                <ItemDiagnosis label={'Tại răng'} placeholder={'n/a'} />
                <ItemDiagnosis
                  label={'Thừa khoảng / thiếu khoảng hàm trên'}
                  stt={7}
                  placeholder={'n/a'}
                  suffix="(mm)"
                  note="(Giá trị tương đương cho thiếu khoảng - chen chúc, giá trị âm cho thừ khoảng - khe thưa)"
                />
                <ItemDiagnosis
                  label={'Thừa khoảng / thiếu khoảng hàm dưới'}
                  stt={8}
                  placeholder={'n/a'}
                  suffix="(mm)"
                  note="(Giá trị tương đương cho thiếu khoảng - chen chúc, giá trị âm cho thừ khoảng - khe thưa)"
                />
              </BaseSpace>
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      </RightSideValueTable>
    </BaseRow>
  )
}
