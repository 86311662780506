// Message code define
export const SurveyMessageCode = {
  SurveyReport: {
    successGetData: '90000',
    successPutData: '90000',
    successDeleteData: '90000',
    successPostData: '90000',
  },
}

// Endpoint
export const SurveyEndPoint = {
  SurveyManagement: {
    request: 'survey/v1/survey',
  },
  SurveyReport: {
    request: 'survey/v1/survey/report',
  },
}
