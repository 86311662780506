import { DiscountTypeEnum } from 'app/api/marketing/discount-campaign/model'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { ITreatmentInTable } from '../type'

export const useTreatmentTypeHook = () => {
  const [listService, setListService] = useState<ITreatmentInTable[]>([])

  const onChangeDiscountValue = (value: number, id?: string) => {
    const newListService = listService.map(item => {
      if (isEqual(item?._id, id)) {
        return {
          ...item,
          discountValue: value,
        }
      }
      return item
    })
    setListService(newListService)
  }

  const onChangeDiscountType = (value: DiscountTypeEnum, id?: string) => {
    const newListService = listService.map(item => {
      if (isEqual(item?._id, id)) {
        return {
          ...item,
          discountType: value,
        }
      }
      return item
    })
    setListService(newListService)
  }

  const onChangeService = (service: ITreatmentInTable[]) => {
    setListService(service)
  }

  const onDeleteService = (id?: string) => {
    const newListService = listService.filter(item => !isEqual(item?._id, id))
    setListService(newListService)
  }

  const onInitListService = (data: ITreatmentInTable[]) => {
    setListService(data)
  }

  return {
    onChangeService,
    listService,
    onChangeDiscountType,
    onChangeDiscountValue,
    onDeleteService,
    onInitListService,
  }
}
