import {
  requestCreateVoucher,
  requestDeleteVoucher,
  requestGetDiscountVoucher,
  requestReTryVoucher,
  requestStopApplyVoucher,
  requestUpdateVoucherWithInsertOnly
} from 'app/api/marketing/discount-voucher'
import { DiscountVoucherMessageCode } from 'app/api/marketing/discount-voucher/constant'
import {
  IDiscountVoucher,
  IParamsGetDiscountVoucher,
  IPayloadCreateVoucher,
  IPayloadUpdateWithInsertOnlyInVoucher
} from 'app/api/marketing/discount-voucher/model'
import R from 'app/assets/R'
import { _DEV_ } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { getToken } from 'app/service/storage/storage-service'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { VoucherKeys } from '../query-key/voucher'

export function useGetVoucherList(
  params?: IParamsGetDiscountVoucher,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<ResponseType<IDiscountVoucher[]>, undefined>(
    VoucherKeys.listVouchers(params),
    async () => {
      const res: ResponseType<IDiscountVoucher[]> =
        await requestGetDiscountVoucher({
          ...params
        })

      if (
        isEqual(
          res?.msgcode,
          DiscountVoucherMessageCode.DiscountVoucher.successGetData,
        )
      ) {
        return res
      }

      return Promise.reject(res)
    },
    {
      enabled: !!token && enabled,
      refetchInterval: 30000,
    },
  )
}

export const useDeleteDiscountVoucher = () => {
  const query = useQueryClient()
  return useMutation<
    ResponseType<unknown>,
    unknown,
    { id: string },
    unknown
  >(
    async (body: { id: string }) => {
      return await requestDeleteVoucher(body?.id)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: VoucherKeys.allVouchers,
        })
      },
    },
  )
}

export const useReTryDiscountVoucher = () => {
  const query = useQueryClient()
  return useMutation<
    ResponseType<unknown>,
    unknown,
    { id: string },
    unknown
  >(
    async (body: { id: string }) => {
      return await requestReTryVoucher(body?.id)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: VoucherKeys.allVouchers,
        })
      },
    },
  )
}

export const useStopApplyDiscountVoucher = () => {
  const query = useQueryClient()
  return useMutation<
    ResponseType<unknown>,
    unknown,
    { id: string },
    unknown
  >(
    async (body: { id: string }) => {
      return await requestStopApplyVoucher(body?.id)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: VoucherKeys.allVouchers,
        })
      },
    },
  )
}

export const useCreateDiscountVoucher = () => {
  const query = useQueryClient()
  const navigate = useNavigate()
  return useMutation<unknown, unknown, IPayloadCreateVoucher, unknown>(
    async (body: IPayloadCreateVoucher) => {
      try {
        await requestCreateVoucher(body)
        notificationController?.success({
          message: t(R.strings.create_voucher_success),
        })
        navigate(-1)
      } catch (error) {
        _DEV_ && console.log('error', error)
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: VoucherKeys.allVouchers,
        })
      },
    },
  )
}


export const useUpdateDiscountVoucherWithInsertOnly = () => {
  const query = useQueryClient()
  const navigate = useNavigate()
  return useMutation<unknown, unknown, IPayloadUpdateWithInsertOnlyInVoucher, unknown>(
    async (body: IPayloadUpdateWithInsertOnlyInVoucher) => {
      try {
        await requestUpdateVoucherWithInsertOnly(body)
        notificationController?.success({
          message: t(R.strings.additional_service_success),
        })
        navigate(-1)
      } catch (error) {
        _DEV_ && console.log('error', error)
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: VoucherKeys.allVouchers,
        })
      },
    },
  )
}
