import { CompanyInfoComponent } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/CompanyComponent'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { useMemo } from 'react'

export const CompanyInfoLayout = () => {
  const { purchaseProductService, onChangeCompanyInfoTotalInvoice } =
    useProfilePatientContext()

  const company = useMemo(() => {
    return purchaseProductService?.totalInvoice?.company
  }, [purchaseProductService?.totalInvoice?.company])

  const _onChangeCompanyName = (value: string) => {
    onChangeCompanyInfoTotalInvoice?.('name', value)
  }

  const _onChangeCompanyTaxId = (value: string) => {
    onChangeCompanyInfoTotalInvoice?.('mst', value)
  }

  const _onChangeCompanyAddress = (value: string) => {
    onChangeCompanyInfoTotalInvoice?.('address', value)
  }

  const _onChangeCompanyEmail = (value: string) => {
    onChangeCompanyInfoTotalInvoice?.('email', value)
  }

  return (
    <CompanyInfoComponent
      company={company}
      onChangeCompanyAddress={_onChangeCompanyAddress}
      onChangeCompanyEmail={_onChangeCompanyEmail}
      onChangeCompanyName={_onChangeCompanyName}
      onChangeCompanyTaxId={_onChangeCompanyTaxId}
    />
  )
}
