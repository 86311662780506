import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IFormRule,
  IPropsUpdateProvider,
} from './type'
import { IProvider } from 'app/api/inventory/model/management'
import { requestUpdateProviderManagement } from 'app/api/inventory'
import { MessageCode } from 'app/api/inventory/constant'

export const useModalHook = (props: IPropsUpdateProvider) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IProvider>()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if(visible){
      form.setFieldsValue(props?.provider)
    }
  }, [visible])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IProvider) => {
    setIsLoading(true)
    try {
      const response: ResponseType<string> =
        await requestUpdateProviderManagement({
          id: props?.provider?._id,
          body: values,
        })

      if (
        isEqual(
          response?.msgcode,
          MessageCode.successPutData
        )
      ) {
        setIsLoading(false)
        handleCancel()
        props?.fetchTable?.()
        notificationController.success({
          message: t(R.strings.provider_update),
          description: t(R.strings.success),
        })
      } else {
        notificationController.error({
          message: t(R.strings.provider_update),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const rules: IFormRule = useMemo(() => {
    return {
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.name),
          }),
        },
      ],
      contact: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.contact),
          }),
        },
      ],
      TIN: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.TIN),
          }),
        },
      ],
      code: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.code),
          }),
        },
      ],
    }
  }, [t])


  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
  }
}
