import { EditFilled, PlusOutlined } from '@ant-design/icons'
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import R from 'app/assets/R'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ItemTreatment } from './components'
import * as S from './styles'

export const TreatmentSchedule = () => {
  const { t } = useTranslation()
  const [items, setItems] = useState([1, 2, 3])
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      setItems(items => {
        const oldIndex = items.indexOf(active.id)
        const newIndex = items.indexOf(over.id)

        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }
  return (
    <S.RootWrapper>
      <S.HeadWrapper>
        <BaseText
          children={t(R.strings.treatment_schedule)}
          fontWeight="semibold"
          fontSize="md"
        />
      </S.HeadWrapper>
      <S.ContentWrapper>
        <S.BaseCollapseWrapper
          items={[
            {
              key: 1,
              label: (
                <BaseRow
                  justify={'space-between'}
                  gutter={[16, 8]}
                  align={'middle'}
                >
                  <BaseCol>
                    <BaseRow align={'middle'} gutter={[8, 8]}>
                      <BaseCol>
                        <BaseText
                          children={'Giai đoạn 1'}
                          fontWeight="semibold"
                          fontSize="xs"
                        />
                      </BaseCol>
                      <BaseCol>
                        <EditFilled rev={undefined} />
                      </BaseCol>
                    </BaseRow>
                  </BaseCol>

                  <BaseCol>
                    <div onClick={e => e?.stopPropagation()}>
                      <BaseRow gutter={[16, 8]} align={'middle'}>
                        <BaseCol>
                          <BaseText
                            children={`${t(R.strings.time)}:`}
                            fontSize="xs"
                            fontWeight="medium"
                          />
                        </BaseCol>
                        <BaseCol>
                          <S.InputNumberWrapper
                            placeholder={t(R.strings.enter_number_day)}
                          />
                        </BaseCol>

                        <BaseButton
                          icon={<SvgDeleteDesignIcon />}
                          type="text"
                        />
                      </BaseRow>
                    </div>
                  </BaseCol>
                </BaseRow>
              ),
              children: (
                <S.BaseCollapseChild
                  items={[
                    {
                      key: 11,
                      label: (
                        <BaseRow
                          justify={'space-between'}
                          gutter={[16, 8]}
                          align={'middle'}
                        >
                          <BaseCol>
                            <BaseText
                              children={'1. Buổi hẹn 1'}
                              fontWeight="semibold"
                              fontSize="xs"
                            />
                          </BaseCol>

                          <div onClick={e => e?.stopPropagation()}>
                            <BaseButton
                              icon={<SvgDeleteDesignIcon />}
                              type="text"
                            />
                          </div>
                        </BaseRow>
                      ),
                      children: (
                        <BaseSpace>
                          <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                          >
                            <SortableContext
                              items={items}
                              strategy={verticalListSortingStrategy}
                            >
                              <BaseSpace>
                                {items.map((id, indx) => (
                                  <ItemTreatment
                                    key={id}
                                    index={indx + 1}
                                    id={id}
                                  />
                                ))}
                              </BaseSpace>
                            </SortableContext>
                          </DndContext>
                          <S.ButtonAddTreatment gutter={[8, 8]} align={'middle'}>
                            <BaseCol>
                              <PlusOutlined rev={undefined} />
                            </BaseCol>
                            <BaseCol>
                              <BaseText
                                children={t(
                                  R.strings
                                    .item_management_content_add_treatment,
                                )}
                                fontWeight="medium"
                                colorText="collapseBackgroundColor"
                              />
                            </BaseCol>
                          </S.ButtonAddTreatment>
                        </BaseSpace>
                      ),
                    },
                  ]}
                  expandIconPosition="right"
                />
              ),
            },
          ]}
          expandIconPosition="right"
        />
      </S.ContentWrapper>
    </S.RootWrapper>
  )
}