import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { requestGetCampaignAvailable } from 'app/api/marketing/discount-campaign'
import { DiscountCampaignMessageCode } from 'app/api/marketing/discount-campaign/constant'
import { IDiscountCampaign } from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import { BORDER_RADIUS, getRandomUuid, ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useCreateCampaignContext } from '../../../../context'
import { RowDragTable } from '../RowDrag'
import { BaseButton } from 'app/components/common/BaseButton'

interface IDataTable extends IDiscountCampaign {
  key: string
  isChange?: boolean
}

export const ModalPriority = () => {
  const { onChangeNameInfo, info } = useCreateCampaignContext()
  const { t } = useTranslation()
  const modalRef = useRef<IRefModal>({})
  const [data, setData] = useState<IDataTable[]>([])
  const [loading, setLoading] = useState(false)

  const getData = async () => {
    if (!info?.endDate || !info?.startDate || !info?.clinics?.length) return

    setLoading(true)
    try {
      const res: ResponseType<IDiscountCampaign[]> =
        await requestGetCampaignAvailable({
          clinics: info?.clinics,
          start: info?.startDate?.toISOString(),
          end: info?.endDate?.toISOString(),
        })

      if (
        isEqual(
          res?.msgcode,
          DiscountCampaignMessageCode.DiscountCampaign.success,
        )
      ) {
        const newRecord: IDataTable = {
          key: getRandomUuid(),
          name: info?.name ?? 'Chiến dịch mới',
          priority: 1,
          isChange: true,
        }

        let newData: IDataTable[] = res?.data
          ?.map((item, index) => ({
            ...item,
            key: item?._id ?? index.toString(),
          }))
          ?.concat([newRecord])

        if (info?.priority) {
          newData = arrayMove(newData, newData.length - 1, info?.priority - 1)
        }

        setData(newData)
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getData()
    }, 700)

    return () => clearTimeout(timer)
  }, [info])

  const openModal = () => {
    modalRef.current?.open?.()
  }

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setData(previous => {
        const activeIndex = previous.findIndex(
          i => i?.key === active?.id && i?.isChange,
        )
        const overIndex = previous.findIndex(i => i?.key === over?.id)
        return arrayMove(previous, activeIndex, overIndex)
      })
    }
  }

  const handleSubmit = () => {
    const indexItem = data.findIndex(i => i?.isChange)

    if (indexItem !== -1) {
      onChangeNameInfo?.(indexItem + 1, 'priority')
    }

    modalRef.current?.hide?.()
  }

  const isDisableOpenModal = useMemo(() => {
    return (
      !info?.name ||
      !info?.clinics?.length ||
      !info?.startDate ||
      !info?.endDate
    )
  }, [info])

  return (
    <ModalComponent
      titleModal="Sắp xếp độ ưu tiên"
      ref={modalRef}
      buttonOpenCustom={
        <ButtonOpenCustom onClick={openModal} disabled={isDisableOpenModal}>
          <BaseText children={'Ưu tiên'} fontWeight="medium" />
        </ButtonOpenCustom>
      }
      widthModal={800}
      isShowLineBottom={false}
      isShowLineTop={false}
      handleSubmit={handleSubmit}
      renderContent={
        <BaseSpace>
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
          >
            <SortableContext
              items={data.map(i => i?.key)}
              strategy={verticalListSortingStrategy}
            >
              <BaseTableManagement
                columns={[
                  {
                    key: 'sort',
                    width: '5%',
                  },
                  convertColumnTable<IDataTable>({
                    title: t(R.strings.campaign_name),
                    key: 'name',
                    render: value => {
                      return <BaseText children={value} fontWeight="medium" />
                    },
                  }),
                  {
                    title: 'Thứ tự áp dụng',
                    key: 'key',
                    width: '20%',
                    render: (_, record, index) => {
                      return (
                        <BaseText
                          children={index + 1}
                          fontWeight="semibold"
                          textAlign="center"
                        />
                      )
                    },
                  },
                ]}
                dataSource={data}
                components={{
                  body: {
                    row: RowDragTable,
                  },
                }}
                rowKey="key"
                scroll={{ x: true }}
                pagination={false}
                rowClassName="row-overwrite-style"
                loading={loading}
              />
            </SortableContext>
          </DndContext>
        </BaseSpace>
      }
      paddingContent="0px"
    />
  )
}

const ButtonOpenCustom = styled(BaseButton)`
  padding: 4px 10px;
  border-radius: 0px !important;
  border-top-left-radius: ${BORDER_RADIUS} !important;
  border-bottom-left-radius: ${BORDER_RADIUS} !important;
  cursor: pointer;
  background-color: ${convertedVariables.backgroundColor};
`
