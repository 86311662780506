import { BaseSpace } from 'app/components/common/BaseSpace'
import { BillDiscountListLayout, BundleBillDiscountTypeLayout } from './layouts'

export const TotalBillTypeLayout = () => {
  return (
    <BaseSpace>
      <BillDiscountListLayout />
      <BundleBillDiscountTypeLayout />
    </BaseSpace>
  )
}
