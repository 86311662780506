import { Rule } from 'antd/es/form'
import { EDayOffType } from 'app/api/jobtitle/model/job-title-management'
import { EYesNo } from 'app/common/enum'

export interface IFormData {
  code?: string
  jobTitleLevelId?: string
  jobTitleIdManagement?: string | null
  jobTitleGroupId?: string
  name?: string
  description?: {
    level?: {
      content?: string
    }
  }
  _id?: string
  status?: string
  hasTimekeeping?: EYesNo
  dayOffType?: EDayOffType
  staticDayOffInWeek?: Array<number>
  totalDynamicDayOffInWeek?: number
}

export interface IFormRule {
  name?: Rule[]
  code?: Rule[]
  jobTitleLevelId?: Rule[]
  jobTitleIdManagement?: Rule[]
  role?: Rule[]
  description?: Rule[]
  status?: Rule[]
  hasTimekeeping?: Rule[]
  dayOffType?: Rule[]
  staticDayOffInWeek?: Rule[]
  totalDynamicDayOffInWeek?: Rule[]
}

export const initValues: IFormData = {
  code: '',
  name: '',
  description: {
    level: {content: ''}
  },
  _id: '',
  hasTimekeeping: EYesNo.yes,
  dayOffType: EDayOffType.Dynamic,
  staticDayOffInWeek: undefined,
  totalDynamicDayOffInWeek: undefined,
}

export enum StatusJobTitleEnum {
  active = 'active',
  inactive = 'inactive',
  deleted = 'deleted',
}

export type StatusJobTitleType = keyof typeof StatusJobTitleEnum

export interface IPropsUpdateJobTitle {
  fetchTable: () => void
  id?: string | number
}
