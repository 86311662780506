// Message code define
export const BookingMessageCode = {
  BookingManagement: {
    successGetData: '80000',
    successPutData: '80000',
    successDeleteData: '80000',
    successPostData: '80001',
  },
}

// Endpoint
export const BookingEndPoint = {
  BookingManagement: {
    request: 'crm/v1/booking',
  },
}
