import { withLoading } from 'app/hocs/with-loading.hoc'
import HumanResourcesPage from 'app/page/menu/human-resources'
import { Route } from 'react-router-dom'
import {
  ACCOUNT_MANAGEMENT_PATH,
  HUMAN_RESOURCES_PATH,
  JOB_TITLE_PATH,
  JOB_TITLE_LEVEL_PATH,
  UNIT_PATH,
  PROFILE_MANAGEMENT_PATH,
  PROFILE_MANAGEMENT_CREATE_PATH,
  PROFILE_MANAGEMENT_UPDATE_PATH,
  PROFILE_MANAGEMENT_UPDATE_NO_ID_PATH,
  SKILL_MANAGEMENT_PATH,
  DEGREE_MANAGEMENT_PATH,
  ROLE_MANAGEMENT,
  MONTHLY_ROSTER_PATH,
  REPORT_LEAVE_PATH,
  REPORT_DOCTOR_WORKING_HOUR_PATH,
  STRATEGIC_DOCTOR_PATH,
  APPROVE_DAY_OFF_PATH,
} from './route-path'
import AccountManagementPage from 'app/page/menu/human-resources/account-management'
import JobTitleLevelManagementPage from 'app/page/menu/human-resources/job-title-level'
import JobTitleManagementPage from 'app/page/menu/human-resources/job-title'
import UnitManagementPage from 'app/page/menu/human-resources/unit'
import ProfileManagementPage from 'app/page/menu/human-resources/profile'
import ProfileCreatePage from 'app/page/menu/human-resources/profile/create'
import ProfileUpdatePage from 'app/page/menu/human-resources/profile/update'
import SkillManagementPage from 'app/page/menu/human-resources/skill'
import DegreeManagementPage from 'app/page/menu/human-resources/degree'
import RoleManagementPage from 'app/page/menu/human-resources/role-management'
import MonthlyRosterPage from 'app/page/menu/human-resources/roster-report'
import ReportLeavePage from 'app/page/menu/human-resources/leave-report'
import ReportDoctorWorkTimePage from 'app/page/menu/human-resources/report-doctor-work-time'
import StrategicDoctorPage from 'app/page/menu/human-resources/strategic-doctor'
import ApproveDayOffPage from 'app/page/menu/human-resources/approve-day-off'

const HumanResources = withLoading(HumanResourcesPage)
const AccountManagement = withLoading(AccountManagementPage)
const JobTitleManagement = withLoading(JobTitleManagementPage)
const JobTitleLevelManagement = withLoading(JobTitleLevelManagementPage)
const UnitManagement = withLoading(UnitManagementPage)
const ProfileManagement = withLoading(ProfileManagementPage)
const ProfileCreate = withLoading(ProfileCreatePage)
const ProfileUpdate = withLoading(ProfileUpdatePage)
const SkillManagement = withLoading(SkillManagementPage)
const DegreeManagement = withLoading(DegreeManagementPage)
const RoleManagement = withLoading(RoleManagementPage)
const MonthlyRoster = withLoading(MonthlyRosterPage)
const ReportLeave = withLoading(ReportLeavePage)
const ReportDoctorWorkTime = withLoading(ReportDoctorWorkTimePage)
const StrategicDoctor = withLoading(StrategicDoctorPage)
const ApproveDayOff = withLoading(ApproveDayOffPage)

export const HumanResourcesRouter = () => {
  return (
    <>
      <Route path={HUMAN_RESOURCES_PATH} element={<HumanResources />} />
      <Route path={ACCOUNT_MANAGEMENT_PATH} element={<AccountManagement />} />
      <Route path={JOB_TITLE_PATH} element={<JobTitleManagement />} />
      <Route
        path={JOB_TITLE_LEVEL_PATH}
        element={<JobTitleLevelManagement />}
      />
      <Route path={UNIT_PATH} element={<UnitManagement />} />
      <Route path={PROFILE_MANAGEMENT_PATH} element={<ProfileManagement />} />
      <Route
        path={PROFILE_MANAGEMENT_CREATE_PATH}
        element={<ProfileCreate />}
      />
      <Route
        path={PROFILE_MANAGEMENT_UPDATE_PATH}
        element={<ProfileUpdate />}
      />
      <Route
        path={PROFILE_MANAGEMENT_UPDATE_NO_ID_PATH}
        element={<ProfileUpdate />}
      />
      <Route path={SKILL_MANAGEMENT_PATH} element={<SkillManagement />} />
      <Route path={DEGREE_MANAGEMENT_PATH} element={<DegreeManagement />} />
      <Route path={ROLE_MANAGEMENT} element={<RoleManagement />} />
      <Route path={MONTHLY_ROSTER_PATH} element={<MonthlyRoster />} />
      <Route path={REPORT_LEAVE_PATH} element={<ReportLeave />} />
      <Route
        path={REPORT_DOCTOR_WORKING_HOUR_PATH}
        element={<ReportDoctorWorkTime />}
      />
      <Route path={STRATEGIC_DOCTOR_PATH} element={<StrategicDoctor />} />
      <Route path={APPROVE_DAY_OFF_PATH} element={<ApproveDayOff />} />
    </>
  )
}
