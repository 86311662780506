import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IFormRule,
  IPropsUpdateMaterialCategory,
} from './type'
import { IMaterialCategory } from 'app/api/inventory/model/management'
import { requestUpdateMaterialCategoryManagement } from 'app/api/inventory'
import { MessageCode } from 'app/api/inventory/constant'

export const useModalHook = (props: IPropsUpdateMaterialCategory) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IMaterialCategory>()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if(visible){
      form.setFieldsValue(props?.materialCategory)
    }
  }, [visible])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IMaterialCategory) => {
    setIsLoading(true)
    try {
      const response: ResponseType<string> =
        await requestUpdateMaterialCategoryManagement({
          id: props?.materialCategory?._id,
          body: values,
        })

      if (
        isEqual(
          response?.msgcode,
          MessageCode.successPutData
        )
      ) {
        setIsLoading(false)
        handleCancel()
        props?.fetchTable?.()
        notificationController.success({
          message: t(R.strings.material_category_update),
          description: t(R.strings.success),
        })
      } else {
        notificationController.error({
          message: t(R.strings.material_category_update),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const rules: IFormRule = useMemo(() => {
    return {
      TIN: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.TIN),
          }),
        },
      ],
      vat: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.material_vat),
          }),
        },
      ],
    }
  }, [t])


  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
  }
}
