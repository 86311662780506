import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BaseSection } from 'app/components/common/SectionBase/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const TreatmentPlanDoctorLayout = () => {
  const { t } = useTranslation()
  return (
    <BaseSection>
      <HeadWrapper justify={'space-between'} align={'middle'} gutter={[16, 8]}>
        <BaseCol>
          <BaseText
            children={t(R.strings.treatment_plan)}
            fontSize="md"
            fontWeight="semibold"
          />
        </BaseCol>
        <BaseCol>
          <BaseText
            children={t(R.strings.see)}
            fontWeight="medium"
            fontSize="xs"
            colorText="otherBlueColor"
          />
        </BaseCol>
      </HeadWrapper>
      <BaseRow justify={'space-between'} align={'middle'} gutter={[16, 16]}>
        <BaseCol>
          <BaseText
            children={t(R.strings.pending_approval)}
            fontWeight="medium"
          />
        </BaseCol>

        <BaseCol>
          <NumberWrapper>
            <BaseText
              children={3}
              colorText="statesGreenColor"
              fontWeight="medium"
            />
          </NumberWrapper>
        </BaseCol>
      </BaseRow>
    </BaseSection>
  )
}

export const HeadWrapper = styled(BaseRow)`
  padding-bottom: ${PADDING.md};
`
export const NumberWrapper = styled.div`
  padding: 4px;
  border-radius: ${PADDING.xxl};
  background-color: ${convertedVariables.statesGreenLightColor};
`
