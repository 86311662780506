import * as S from 'app/components/tables/BaseTableManagement/styles'
import R from 'app/assets/R'
import CompanyInfo from '../components/Info'
import BuyInfo from '../components/BuyInfo'
import OrderInfo from '../components/OrderInfo'
import { WarehouseGoodsIssueContext } from '../../context'
import Footer from '../components/Footer'
import { useParams } from 'react-router'
import { useContext, useEffect } from 'react'
import dayjs from 'dayjs'
import { HeadingTitlePage } from 'app/components/tables/BaseTableReport/styles'
import { useTranslation } from 'react-i18next'
import { HeadingTitleCode } from './styles'
import { BaseRow } from 'app/components/common/BaseRow'
import { Print } from '../components/Print'
import { requestDetailWarehouseGoodsIssueManagement } from 'app/api/inventory-new/api/warehouse-goods-issue'
import { notificationController } from 'app/controllers/notification-controller'
import { get } from 'lodash'

const View = () => {
  const params = useParams()
  const { t } = useTranslation()
  const {
    setOrders,
    form,
    setWarehouseGoodsIssueDetail,
    setDiscount,
    warehouseGoodsIssueDetail,
  } = useContext(WarehouseGoodsIssueContext)
  const getWarehouseGoodsIssueDetail = async () => {
    try {
      const { data } = await requestDetailWarehouseGoodsIssueManagement(
        params?.id,
      )

      const orders = data?.orders?.map(order => ({
        ...order,
        name: order?.product?.name,
        code: order?.product?.code,
        purchaseMeasurementUnit: order?.measurementUnit?.name,
        measurementUnitId: order?.measurementUnitId,
      }))

      setOrders?.(orders)
      await form?.setFieldsValue({
        ...data,
        createAtDate: dayjs(data?.createAtDate),
      })

      setWarehouseGoodsIssueDetail?.({
        ...data,
        createAtDate: dayjs(data?.createAtDate),
      })

      setDiscount?.(data?.discount)
    } catch (error) {
      notificationController.error({
        message: get(error, 'message', '')
      })
    }
  }

  useEffect(() => {
    getWarehouseGoodsIssueDetail()
  }, [])

  return (
    <>
      <S.BaseManagementWrapper>
        <BaseRow justify={'space-between'} align={'middle'}>
          <BaseRow justify={'start'} align={'middle'}>
            <HeadingTitlePage>
              {t(R.strings.warehouse_goods_issue)}
            </HeadingTitlePage>
            <HeadingTitleCode>
              {warehouseGoodsIssueDetail?.code}
            </HeadingTitleCode>
          </BaseRow>
          <Print />
        </BaseRow>
        <div>
          <div>
            <CompanyInfo />
            <BuyInfo />
          </div>
        </div>

        <div>
          <div>
            <OrderInfo />
          </div>
        </div>
      </S.BaseManagementWrapper>

      <Footer />
    </>
  )
}

export default View
