import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import UpdateDiscountCampaignContainer from 'app/containers/Marketing/DiscountCampaignManagement/UpdateCampaign'

const UpdateDiscountCampaignPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.update_campaign)}</PageTitle>
      <UpdateDiscountCampaignContainer />
    </>
  )
}
const UpdateDiscountCampaignPage = memo(UpdateDiscountCampaignPageCpn, isEqual)

export default UpdateDiscountCampaignPage
