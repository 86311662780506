import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { Report020Container } from 'app/containers/Report/Report020'

const Report020PageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.report_20)}</PageTitle>
      <Report020Container />
    </>
  )
}
const Report020Page = memo(Report020PageCpn, isEqual)

export default Report020Page
