import { isEqual } from 'lodash'
import { getRandomUuid } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import {
  DiscountTypeEnum,
  IDataUpdateCampaign,
} from 'app/api/marketing/discount-campaign/model'
import { IBundleServiceItem, ITreatmentInTable } from '../type'
import { IResponseImportBundle } from '../RootLayout/Contents/layouts/BundleTypeLayout/ImportModal/type'

export const useBundleBillDiscountHook = () => {
  const [initBundleBillDiscountService, setInitListBundleBillDiscountService] =
    useState<IBundleServiceItem[]>([])
  const [listBundleBillDiscountService, setListBundleBillDiscountService] =
    useState<IBundleServiceItem[]>([])

  const [updateListBundleBillDiscount, setUpdateListBundleBillDiscount] =
    useState<IBundleServiceItem[]>([])
  const [deleteListBundleBillDiscount, setDeleteListBundleBillDiscount] =
    useState<IBundleServiceItem[]>([])
  const [addListBundleBillDiscount, setAddListBundleBillDiscount] = useState<
    IBundleServiceItem[]
  >([])

  const onAddNewBundleBillDiscountService = () => {
    const newItem: IBundleServiceItem = {
      id: getRandomUuid(),
      name: undefined,
      selectedTreatment: [],
    }

    setListBundleBillDiscountService([
      ...listBundleBillDiscountService,
      newItem,
    ])
    setAddListBundleBillDiscount([...addListBundleBillDiscount, newItem])
  }

  const onInitBundleBillDiscountItem = (items: IBundleServiceItem[]) => {
    setListBundleBillDiscountService(items)
    setInitListBundleBillDiscountService(items)
    setAddListBundleBillDiscount([])
    setUpdateListBundleBillDiscount([])
    setDeleteListBundleBillDiscount([])
  }

  const onChangeUpdateListBundleBillDiscount = (data: IBundleServiceItem) => {
    if (
      initBundleBillDiscountService.find(item => isEqual(item?.id, data?.id))
    ) {
      if (
        updateListBundleBillDiscount.find(item => isEqual(item?.id, data?.id))
      ) {
        const newUpdateList = updateListBundleBillDiscount.map(item => {
          if (isEqual(item?.id, data?.id)) {
            return data
          }
          return item
        })
        setUpdateListBundleBillDiscount(newUpdateList)
      } else {
        setUpdateListBundleBillDiscount([...updateListBundleBillDiscount, data])
      }
    }
  }

  const onChangeAddListBundleBillDiscount = (data: IBundleServiceItem) => {
    if (
      initBundleBillDiscountService.find(item => isEqual(item?.id, data?.id))
    ) {
      return
    }

    const newAddList = addListBundleBillDiscount.map(item => {
      if (isEqual(item?.id, data?.id)) {
        return data
      }
      return item
    })
    setAddListBundleBillDiscount(newAddList)
  }

  const onChangeListBundleBillDiscount = (data: IBundleServiceItem) => {
    const newList = listBundleBillDiscountService.map(item => {
      if (isEqual(item?.id, data?.id)) {
        return data
      }
      return item
    })
    setListBundleBillDiscountService(newList)
  }

  const onChangeNameBundleBillDiscountByIndex = (
    index: number,
    name: string,
  ) => {
    const itemExist = listBundleBillDiscountService.find((_, i) =>
      isEqual(i, index),
    )

    if (!itemExist) {
      return
    }

    onChangeUpdateListBundleBillDiscount({
      ...itemExist,
      name,
    })

    onChangeAddListBundleBillDiscount({
      ...itemExist,
      name,
    })

    onChangeListBundleBillDiscount({
      ...itemExist,
      name,
    })
  }

  const onRemoveBundleBillDiscountServiceByIndex = (index: number) => {
    const itemExist = listBundleBillDiscountService.find((_, i) =>
      isEqual(i, index),
    )

    if (!itemExist) return

    const itemInit = initBundleBillDiscountService.find(itm =>
      isEqual(itemExist.id, itm?.id),
    )

    if (itemInit) {
      setDeleteListBundleBillDiscount([
        ...deleteListBundleBillDiscount,
        itemInit,
      ])
    }

    const newAddListBundleBillDiscount = addListBundleBillDiscount.filter(
      itm => !isEqual(itemExist.id, itm?.id),
    )
    setAddListBundleBillDiscount(newAddListBundleBillDiscount)

    const newUpdateListBundleBillDiscount = updateListBundleBillDiscount.filter(
      itm => !isEqual(itemExist.id, itm?.id),
    )
    setUpdateListBundleBillDiscount(newUpdateListBundleBillDiscount)

    const newList = listBundleBillDiscountService.filter(
      itm => !isEqual(itm.id, itemExist.id),
    )
    setListBundleBillDiscountService(newList)
  }

  const onChangeItemBundleBillDiscountServiceByIndex = (
    index: number,
    item: IBundleServiceItem,
  ) => {
    const itemExist = listBundleBillDiscountService.find((_, i) =>
      isEqual(i, index),
    )

    if (!itemExist) {
      return
    }

    onChangeUpdateListBundleBillDiscount({
      ...itemExist,
      ...item,
    })

    onChangeAddListBundleBillDiscount({
      ...itemExist,
      ...item,
    })

    onChangeListBundleBillDiscount({
      ...itemExist,
      ...item,
    })
  }

  const onChangeDiscountValueItemBundleBillDiscountService = (
    index: number,
    treatmentIndex: number,
    discountValue: number,
  ) => {
    const itemExist = listBundleBillDiscountService.find((_, i) =>
      isEqual(i, index),
    )

    if (!itemExist) return

    const treatmentExist = itemExist?.selectedTreatment?.find((_, idx) =>
      isEqual(idx, treatmentIndex),
    )

    if (!treatmentExist) return

    const newTreatment = itemExist?.selectedTreatment?.map((itm, idx) => {
      if (isEqual(idx, treatmentIndex)) {
        return {
          ...itm,
          discountValue,
        }
      }
      return itm
    })

    const newItemBundleBillDiscount = {
      ...itemExist,
      selectedTreatment: newTreatment,
    }

    onChangeUpdateListBundleBillDiscount(newItemBundleBillDiscount)

    onChangeAddListBundleBillDiscount(newItemBundleBillDiscount)

    onChangeListBundleBillDiscount(newItemBundleBillDiscount)
  }

  const onChangeDiscountTypeItemBundleBillDiscountService = (
    index: number,
    treatmentIndex: number,
    discountType: DiscountTypeEnum,
  ) => {
    const itemExist = listBundleBillDiscountService.find((_, i) =>
      isEqual(i, index),
    )

    if (!itemExist) return

    const treatmentExist = itemExist?.selectedTreatment?.find((_, idx) =>
      isEqual(idx, treatmentIndex),
    )

    if (!treatmentExist) return

    const newTreatment = itemExist?.selectedTreatment?.map((itm, idx) => {
      if (isEqual(idx, treatmentIndex)) {
        return {
          ...itm,
          discountType,
        }
      }
      return itm
    })

    const newItemBundleBillDiscount = {
      ...itemExist,
      selectedTreatment: newTreatment,
    }

    onChangeUpdateListBundleBillDiscount(newItemBundleBillDiscount)

    onChangeAddListBundleBillDiscount(newItemBundleBillDiscount)

    onChangeListBundleBillDiscount(newItemBundleBillDiscount)
  }

  const onChangeQuantityItemBundleBillDiscountService = (
    index: number,
    treatmentIndex: number,
    quantity: number,
  ) => {
    const itemExist = listBundleBillDiscountService.find((_, i) =>
      isEqual(i, index),
    )

    if (!itemExist) return

    const treatmentExist = itemExist?.selectedTreatment?.find((_, idx) =>
      isEqual(idx, treatmentIndex),
    )

    if (!treatmentExist) return

    const newTreatment = itemExist?.selectedTreatment?.map((itm, idx) => {
      if (isEqual(idx, treatmentIndex)) {
        return {
          ...itm,
          quantity,
        }
      }
      return itm
    })

    const newItemBundleBillDiscount = {
      ...itemExist,
      selectedTreatment: newTreatment,
    }

    onChangeUpdateListBundleBillDiscount(newItemBundleBillDiscount)

    onChangeAddListBundleBillDiscount(newItemBundleBillDiscount)

    onChangeListBundleBillDiscount(newItemBundleBillDiscount)
  }

  const onDeleteItemInSelectedTreatment = (
    index: number,
    treatmentIndex: number,
  ) => {
    const itemExist = listBundleBillDiscountService.find((_, i) =>
      isEqual(i, index),
    )

    if (!itemExist) return

    const treatmentAfterDeleted = itemExist?.selectedTreatment?.filter(
      (_, idx) => idx !== treatmentIndex,
    )

    const newItemBundleBillDiscount = {
      ...itemExist,
      selectedTreatment: treatmentAfterDeleted,
    }

    onChangeUpdateListBundleBillDiscount(newItemBundleBillDiscount)

    onChangeAddListBundleBillDiscount(newItemBundleBillDiscount)

    onChangeListBundleBillDiscount(newItemBundleBillDiscount)
  }

  const dataUpdateBundleBillDiscount: IDataUpdateCampaign = useMemo(() => {
    const payload: IDataUpdateCampaign = {
      pushTreatments: addListBundleBillDiscount.map(item => {
        return {
          name: item.name,
          buyTreatment: item?.selectedTreatment?.map(itm => {
            return {
              treatment: itm?._id,
              amount: 1,
            }
          }),
          discountTreatment: [],
        }
      }),
      removeTreatments: deleteListBundleBillDiscount.map(
        item => item?.id ?? '',
      ),
      updateTreatments: updateListBundleBillDiscount.map(item => {
        return {
          _id: item.id,
          name: item.name,
          buyTreatment: item?.selectedTreatment?.map(itm => {
            return {
              treatment: itm?._id,
              amount: 1,
            }
          }),
          discountTreatment: [],
        }
      }),
    }
    return payload
  }, [
    updateListBundleBillDiscount,
    deleteListBundleBillDiscount,
    addListBundleBillDiscount,
  ])

  const handleImportBundleBillDiscount = (data: IResponseImportBundle) => {
    const listDelete = data?.remove?.map(item => {
      return {
        id: item?._id,
        name: item?.setName,
        selectedTreatment: item?.buyTreatment?.map(itm => ({
          ...itm,
          _id: itm?._id,
          name: itm?.name,
          discountValue: itm?.discount,
          discountType: itm?.dType,
          quantity: itm?.amount,
          regionalPrice: itm?.regionalPrice,
        })) as ITreatmentInTable[],
      }
    })

    const listUpdate = data?.modify?.map(item => {
      return {
        id: item?._id,
        name: item?.setName,
        selectedTreatment: item?.buyTreatment?.map(itm => ({
          ...itm,
          _id: itm?._id,
          name: itm?.name,
          discountValue: itm?.discount,
          discountType: itm?.dType,
          quantity: itm?.amount,
          regionalPrice: itm?.regionalPrice,
        })) as ITreatmentInTable[],
      }
    })

    const listAdd = data?.insert?.map(item => {
      return {
        id: item?._id ?? getRandomUuid(),
        name: item?.setName,
        selectedTreatment: item?.buyTreatment?.map(itm => ({
          ...itm,
          _id: itm?._id,
          name: itm?.name,
          discountValue: itm?.discount,
          discountType: itm?.dType,
          quantity: itm?.amount,
          regionalPrice: itm?.regionalPrice,
        })) as ITreatmentInTable[],
      }
    })

    const newListService = initBundleBillDiscountService
      // xoá item đã bị xoá khỏi danh sách
      .filter(
        item =>
          !listDelete.some(deleteItem => isEqual(deleteItem?.id, item?.id)),
      )
      // cập nhật lại item đã bị sửa
      .map(item => {
        const itemUpdate = listUpdate.find(itm => isEqual(itm?.id, item?.id))
        if (itemUpdate) {
          return itemUpdate
        }
        return item
      })
      .concat(listAdd)

    setListBundleBillDiscountService(newListService)
    setUpdateListBundleBillDiscount(listUpdate)
    setDeleteListBundleBillDiscount(listDelete)
    setAddListBundleBillDiscount(listAdd)
  }

  return {
    onAddNewBundleBillDiscountService,
    listBundleBillDiscountService,
    onRemoveBundleBillDiscountServiceByIndex,
    onChangeNameBundleBillDiscountByIndex,
    onChangeDiscountTypeItemBundleBillDiscountService,
    onChangeDiscountValueItemBundleBillDiscountService,
    onChangeItemBundleBillDiscountServiceByIndex,
    onChangeQuantityItemBundleBillDiscountService,
    onDeleteItemInSelectedTreatment,
    onInitBundleBillDiscountItem,
    handleImportBundleBillDiscount,
    dataUpdateBundleBillDiscount,

    updateListBundleBillDiscount,
    addListBundleBillDiscount,
    deleteListBundleBillDiscount,
    initBundleBillDiscountService,
  }
}
