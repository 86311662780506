import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { initFilterTable } from 'app/hook'
import { isEqual } from 'lodash'
import { DateUtil, FORMAT_YYYYMMDD, SelectFilterEnum } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTableMonthlyHook } from './Monthly/hook'
import { useTableWeeklyHook } from './Weekly/hook'
import { initialPagination } from './common-model'

export const useTableHook = () => {
  const { t } = useTranslation()

  const [filterResponse, setFilterResponse] = useState<IResponseFilter>(
    initFilterTable(),
  )

  const hookWeekly = useTableWeeklyHook({
    year: filterResponse.year,
    month: filterResponse?.month,
    startDate: DateUtil.formatDate(
      filterResponse?.week?.startDate ?? '',
      FORMAT_YYYYMMDD,
    ),
    endDate: DateUtil.formatDate(
      filterResponse?.week?.endDate ?? '',
      FORMAT_YYYYMMDD,
    ),
  })
  const hookMonthly = useTableMonthlyHook({
    year: filterResponse.year,
    month: filterResponse?.month,
  })

  const expandedRowKeys = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.expandedRowKeys
      case SelectFilterEnum.MONTH:
        return hookMonthly.expandedRowKeys
      default:
        return hookWeekly.expandedRowKeys
    }
  }, [filterResponse?.selectFilterType, hookWeekly, hookMonthly])

  const tableData = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return {
          ...hookWeekly.tableData,
          data: hookWeekly.data,
        }
      case SelectFilterEnum.MONTH:
        return {
          ...hookMonthly.tableData,
          data: hookMonthly.data,
        }
      default:
        return {
          ...hookWeekly.tableData,
          data: hookWeekly.data,
        }
    }
  }, [filterResponse?.selectFilterType, hookWeekly, hookMonthly])

  const handleTableChange = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.handleTableChange
      case SelectFilterEnum.MONTH:
        return hookMonthly.handleTableChange
      default:
        return hookWeekly.handleTableChange
    }
  }, [filterResponse?.selectFilterType, hookWeekly, hookMonthly])

  const handleExpand = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.handleExpand
      case SelectFilterEnum.MONTH:
        return hookMonthly.handleExpand
      default:
        return hookWeekly.handleExpand
    }
  }, [filterResponse?.selectFilterType, hookWeekly, hookMonthly])

  const handleChangeFilter = (filter: IResponseFilter) => {
    const { year, month, week } = filter
    setFilterResponse(filter)
    if (year && month && week?.startDate && week?.endDate) {
      if (
        !isEqual(
          { week, year, month },
          {
            week: filterResponse?.week,
            year: filterResponse?.year,
            month: filterResponse?.month,
          },
        )
      ) {
        hookWeekly.fetch(initialPagination, {
          year,
          month,
          startDate: DateUtil.formatDate(
            week?.startDate ?? '',
            FORMAT_YYYYMMDD,
          ),
          endDate: DateUtil.formatDate(week?.endDate ?? '', FORMAT_YYYYMMDD),
        })
      }
    }

    if (year && month) {
      if (
        !isEqual(
          { year, month },
          {
            year: filterResponse?.year,
            month: filterResponse?.month,
          },
        )
      ) {
        hookMonthly.fetch(initialPagination, { year, month })
      }
    }
  }

  const columns = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.columns
      case SelectFilterEnum.MONTH:
        return hookMonthly.columns
      default:
        return hookWeekly.columns
    }
  }, [t, expandedRowKeys, filterResponse?.selectFilterType])

  const exportExcel = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.exportExcel
      case SelectFilterEnum.MONTH:
        return hookMonthly.exportExcel
      default:
        return hookWeekly.exportExcel
    }
  }, [t, expandedRowKeys, filterResponse?.selectFilterType])

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleChangeFilter,
    filterResponse,
    exportExcel,
  }
}
