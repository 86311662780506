/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestDetailAccountManagement,
  requestUpdateAccountManagement,
} from 'app/api/human'
import { HumanMessageCode } from 'app/api/human/constant'
import {
  IAccountManagement,
  IPayloadUpdateAccountManagement,
} from 'app/api/human/model/account-management'
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { randomMoney } from 'app/components/tables/common-table/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { PHONE_REGEX, REG_EMAIL, ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormData, IFormRule, IPropsUpdateAccount } from './type'
import { StatusEnum } from 'app/common/enum'
import { convertArrayStatusFilterToArrayStatusNumber, convertStatusToFilterStatus } from 'app/common/helpers'

export const useModalHook = (props: IPropsUpdateAccount) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormData>()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getDetail = async () => {
    if (!props?.id) {
      return
    }
    try {
      const res: ResponseType<IAccountManagement> =
        await requestDetailAccountManagement(props?.id)

      if (
        isEqual(res?.msgcode, HumanMessageCode.AccountManagement.successGetData)
      ) {
        const data = res?.data
        form.setFieldsValue({
          code: data?.code ?? '',
          email: data?.email ?? '',
          firstName: data?.firstname ?? '',
          lastName: data?.lastname ?? '',
          employeeId: `NV${randomMoney()}`,
          phone: data?.mobile ?? '',
          status: convertStatusToFilterStatus(data?.status),
        })
      }
    } catch (error) {
     // console.log({ error })
    }
  }

  useEffect(() => {
    visible && getDetail()
  }, [props?.id, visible])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)
    try {
      const body: IPayloadUpdateAccountManagement = {
        firstname: values?.firstName,
        lastname: values?.lastName,
        mobile: values?.phone,
        email: values?.email,
        code: values?.code,
        status:
          convertArrayStatusFilterToArrayStatusNumber([values?.status] as any)?.[0] ??
          StatusEnum.BLOCK,
      }

      const response: ResponseType<string> =
        await requestUpdateAccountManagement({ id: props?.id, body })

      if (
        isEqual(
          response?.msgcode,
          HumanMessageCode.AccountManagement.successPutData,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        props?.fetchTable?.()
        notificationController.success({
          message: t(R.strings.update_account),
          description: response?.message,
        })
      } else {
        notificationController.error({
          message: t(R.strings.update_account),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const rules: IFormRule = useMemo(() => {
    return {
      code: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.profile_content_employeeid),
          }),
        },
      ],
      firstName: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.first_name),
          }),
        },
      ],
      lastName: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.last_name),
          }),
        },
      ],
      employeeId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.employee_id),
          }),
        },
      ],
      phone: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.phone),
          }),
        },
        {
          pattern: PHONE_REGEX,
          message: t(R.strings.regex_phone),
        },
      ],
      email: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.email),
          }),
        },
        {
          pattern: REG_EMAIL,
          message: t(R.strings.regex_email),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
  }
}
