import R from 'app/assets/R'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { useModalHook } from './hook'

import { ACCOUNTING_ITEM_MANAGEMENT_ADD_PRODUCT_SERVICE_PATH } from 'app/components/router/route-path'
import { TypeItemEnum } from 'app/containers/Accounting/ItemManagement/common-model'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import * as S from './styles'

const DefineTextTypeItem = {
  [TypeItemEnum.service]: {
    labelKey18n: R.strings.add_service,
    descriptionKeyI18n:
      R.strings.item_management_content_description_add_service,
  },
  [TypeItemEnum.product]: {
    labelKey18n: R.strings.add_product,
    descriptionKeyI18n:
      R.strings.item_management_content_description_add_product,
  },
  [TypeItemEnum.combo]: {
    labelKey18n: R.strings.add_combo,
    descriptionKeyI18n: R.strings.item_management_content_description_add_combo,
  },
  [TypeItemEnum.supplies]: {
    labelKey18n: R.strings.add_supplies,
    descriptionKeyI18n:
      R.strings.item_management_content_description_add_supplies,
  },
  [TypeItemEnum.asset]: {
    labelKey18n: R.strings.add_asset,
    descriptionKeyI18n: R.strings.item_management_content_description_add_asset,
  },
  [TypeItemEnum.tools]: {
    labelKey18n: R.strings.add_tool,
    descriptionKeyI18n: R.strings.item_management_content_description_add_tool,
  },
}

export const CreateItem = () => {
  const { visible, showModal, handleCancel } = useModalHook()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleChooseTypeItem = (type: TypeItemEnum) => {
    const path = ACCOUNTING_ITEM_MANAGEMENT_ADD_PRODUCT_SERVICE_PATH.replace(
      ':type',
      type,
    )
    navigate(path)
  }

  return (
    <BaseModalManagement
      titleKeyI18n={R.strings.item_management_content_add_product_service}
      content={
        <S.ContentWrapper direction="vertical" size={20}>
          {Object.keys(TypeItemEnum).map((item, index) => {
            const data = DefineTextTypeItem[item as TypeItemEnum]
            return (
              <S.ItemSelect
                key={index}
                direction="vertical"
                size={10}
                onClick={() => handleChooseTypeItem(item as TypeItemEnum)}
              >
                <div className="label">{t(data?.labelKey18n)}</div>
                <div className="description">{t(data?.descriptionKeyI18n)}</div>
              </S.ItemSelect>
            )
          })}
        </S.ContentWrapper>
      }
      handleSubmit={() => undefined}
      handleCancel={handleCancel}
      showModal={showModal}
      textOpenModalI18n={R.strings.item_management_content_add_product_service}
      visible={visible}
      typeButtonOpen="choose"
    />
  )
}
