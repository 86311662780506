import { Button } from 'antd'
import { BaseSpace } from 'app/components/common/BaseSpace'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  flex: 1;
  justify-content: end;
  width: 100%;
`

export const Cancel = styled(Button)`
  border: initial;
`

export const Confirm = styled(Button)`
  border: initial;
  background: #639aff;
`
