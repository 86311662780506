import R from 'app/assets/R'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormRule } from './type'
import { StatusWarehouseGoodsReceiptType } from '.'
import { notificationController } from 'app/controllers/notification-controller'
import { WarehouseGoodsReceiptContext } from 'app/containers/InventoryManagement/WarehousePurchaseGoodsReceipt/context'
import { useNavigate } from 'react-router'
import { INVENTORY_WAREHOUSE_PURCHASE_PATH } from 'app/components/router/route-path'
import { get } from 'lodash'
import { requestCreateWarehouseGoodsReceiptManagement } from 'app/api/inventory-new/api/warehouse-goods-receipt'
import { requestWarehouseGoodsReceiptTemplate } from 'app/api/inventory-new'

export const useHook = () => {
  const { t } = useTranslation()
  const { form, warehouseGoodsReceiptDetail } = useContext(
    WarehouseGoodsReceiptContext,
  )
  const [isDownloading, setIsDownloading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState<
    StatusWarehouseGoodsReceiptType | undefined
  >()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigate = useNavigate()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form?.resetFields()
    navigate(INVENTORY_WAREHOUSE_PURCHASE_PATH)
  }

  const handleCreate = async (orders, purchaseOrder) => {
    setIsLoading(true)

    try {
      const orderModify = orders?.map(item => {
        return {
          _id: item?._id,
          productId: item?.productId,
          quantity: Number(item?.quantity || 0),
          price: Number(item?.price || 0),
          measurementUnitId: item?.measurementUnitId,
        }
      })

      if (!orderModify.length) {
        setIsLoading(false)
        return notificationController.error({
          message: t(R.strings.warehouse_purchase_add),
          description: t(R.strings.goods_require),
        })
      }

      if (
        !orderModify.every(order => order?.quantity > 0) ||
        !orderModify.every(order => order?.price > 0)
      ) {
        setIsLoading(false)
        return notificationController.error({
          message: t(R.strings.warehouse_purchase_add),
          description: t(R.strings.goods_please_input_quantity_unit_price),
        })
      }

      const payload = {
        purchaseOrderId: purchaseOrder?._id,
        reason: purchaseOrder?.reason,
        note: purchaseOrder?.note,
        orders: orderModify,
      }

      await requestCreateWarehouseGoodsReceiptManagement(payload)

      setIsLoading(false)
      handleCancel()

      notificationController.success({
        message: t(R.strings.warehouse_purchase_add),
        description: t(R.strings.success),
      })

      navigate(INVENTORY_WAREHOUSE_PURCHASE_PATH)
    } catch (error) {
      setIsLoading(false)
      return notificationController.error({
        message: t(R.strings.warehouse_purchase_add),
        description: get(error, 'message'),
      })
    }
  }

  const downloadTemplate = async () => {
    setIsDownloading(true)
    try {
      const response = await requestWarehouseGoodsReceiptTemplate({
        purchaseOrderId: warehouseGoodsReceiptDetail?._id,
      })
      window.open(response?.data?.Url, '_blank', 'noopener,noreferrer')
    } catch (error) {
      notificationController.error({
        message: t(R.strings.warehouse_purchase_update),
        description: get(error, 'message'),
      })
    }
    setIsDownloading(false)
  }

  const rules: IFormRule = useMemo(() => {
    return {
      purchaserId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.purchaser),
          }),
        },
      ],

      receiverId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.receiver),
          }),
        },
      ],

      phone: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.phone),
          }),
        },
      ],

      warehouseReceiveId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.warehouse_receive),
          }),
        },
      ],

      address: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.address),
          }),
        },
      ],

      estimateDeliveryDate: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.estimate_delivery_date),
          }),
        },
      ],

      paymentDueDate: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.payment_due_date),
          }),
        },
      ],

      supplierId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.supplier),
          }),
        },
      ],
    }
  }, [t])

  return {
    visible,
    t,
    showModal,
    handleCreate,
    handleCancel,
    rules,
    setStatus,
    status,
    isLoading,
    isDownloading,
    downloadTemplate,
  }
}
