import * as S from './styles'

interface ISectionBaseProp {
  children: React.ReactNode
  title?: string | undefined
  style?: React.CSSProperties
}

export const SectionBase: React.FC<ISectionBaseProp> = ({
  children,
  title,
  style,
}) => {
  return (
    <S.BaseSection style={style}>
      {title && <S.Title>{title}</S.Title>}
      {children}
    </S.BaseSection>
  )
}
