import { ApiClientHr } from 'app/service/api-service'
import {
  IParamsGetDegreeManagement,
  IPayloadCreateDegreeManagement,
  IPayloadUpdateDegreeManagement,
} from './model/management'
import { DegreeEndPoint } from './constant'

export const requestGetDegreeManagement = (params: IParamsGetDegreeManagement) =>
  ApiClientHr.Get({
    url: DegreeEndPoint.DegreeManagement.request,
    params: { params },
  })

export const requestCreateDegreeManagement = (
  body: IPayloadCreateDegreeManagement,
) =>
  ApiClientHr.Post({
    url: DegreeEndPoint.DegreeManagement.request,
    body,
  })

export const requestUpdateDegreeManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateDegreeManagement
}) =>
  ApiClientHr.Put({
    url: `${DegreeEndPoint.DegreeManagement.request}/${id}`,
    body,
  })

export const requestDetailDegreeManagement = (id?: string | number) =>
  ApiClientHr.Get({
    url: `${DegreeEndPoint.DegreeManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteDegreeManagement = (id?: string | number) =>
  ApiClientHr.Delete({
    url: `${DegreeEndPoint.DegreeManagement.request}/${id}`,
    body: {},
  })

export const requestGetAllDegreeManagement = () =>
  ApiClientHr.Get({
    url: `${DegreeEndPoint.DegreeManagement.request}/get-all`,
    params: {},
  })
