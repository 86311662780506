import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'

import { requestExportExcelReport18 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import {
  DefineDiscountTypeEnum,
  IParamsGetReport18,
  IReport018,
} from 'app/api/report/model/report-18'
import { getDetailPatientUrl } from 'app/common/helpers'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { initPagination } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { useDebounce, usePagination } from 'app/hook'
import { useGetFilterReport18 } from 'app/react-query/hook/report'
import { isEqual } from 'lodash'
import {
  DateUtil,
  Pagination,
  formatMoney,
  moment
} from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable } from './type'

export const useTable = () => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState(initPagination)

  const [filter, setFilter] = useState<IParamsGetReport18>({
    year: `${moment()?.year()}`,
  })

  const { flattenDataList } = usePagination()

  const filterDebounce = useDebounce<IParamsGetReport18>(filter, 500)

  const { data: dataInfinity, isLoading } = useGetFilterReport18({
    year: filterDebounce?.year,
    quarter: filterDebounce?.quarter,
    month: filterDebounce?.month,
    week: filterDebounce?.week,
    keyword: filterDebounce?.keyword ?? '',
    clinicIds: filterDebounce?.clinicIds,
    campaignIds: filterDebounce?.campaignIds,
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
  })

  const handleExportExcel = async () => {
    try {
      const response = await requestExportExcelReport18({
        startDate: filterDebounce?.startDate,
        endDate: filterDebounce?.endDate,
        keyword: filterDebounce?.keyword ?? '',
        clinicIds: filterDebounce?.clinicIds,
        campaignIds: filterDebounce?.campaignIds,
        page: pagination.current,
        pageSize: pagination.pageSize,
        pagesize: pagination.pageSize,
      })
      if (
        isEqual(
          response?.msgcode,
          ReportMessageCode.Report18.successExportExcel,
        )
      ) {
        window.open(response?.data?.url, '_blank', 'noopener,noreferrer')

        notificationController?.success({
          message: t(R.strings.export_excel_success),
        })
      }
    } catch (error) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.with_error_please_contact_with_admin),
      })
    }
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IDataTable>({
        title: t(R.strings.apply_date),
        key: 'apply_date',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={
                record?.date ? DateUtil.formatDDMMYYYHhss(record?.date) : '-'
              }
              fontWeight="medium"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.clinic),
        key: 'clinic',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={record?.clinic?.name}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.manage_customer_patient_code),
        key: 'manage_customer_patient_code',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={record?.patient?.id}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.customer_name),
        key: 'customer_name',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record: IReport018) => {
          return (
            <RenderValueTable
              value={record?.patient?.name ?? '-'}
              type="OtherLink"
              hrefOtherLink={getDetailPatientUrl(record?.patient?._id)}
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.phone),
        key: 'phone',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={record?.patient?.contact ?? '-'}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.apply_service),
        key: 'apply_service',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={record?.treatment?.name ?? '-'}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.discount_amount),
        key: 'discount_amount',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={record?.used ?? '-'}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.discount_value),
        key: 'discount_value',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={record?.voucher?.amount ?? '-'}
              fontWeight="medium"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.discount_type),
        key: 'discount_type',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={
                record?.voucher?.discountType
                  ? t(
                      DefineDiscountTypeEnum?.[record?.voucher?.discountType]
                        ?.keyI18n,
                    )
                  : '-'
              }
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.retail_price),
        key: 'retail_price',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={
                record?.retailPrice === undefined
                  ? '-'
                  : formatMoney(record?.retailPrice)
              }
              fontWeight="medium"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.discounted_total),
        key: 'discountedTotal',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={
                record?.discountedTotal === undefined
                  ? '-'
                  : formatMoney(record?.discountedTotal)
              }
              fontWeight="medium"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.voucher_code),
        key: 'voucher_code',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={record?.voucher?.code ?? '-'}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.campaign_name),
        key: 'campaign_name',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={record?.campaign?.name}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.priority),
        key: 'priority',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={record?.campaign?.priority}
              fontWeight="medium"
              opacity="0.5"
              textAlign="right"
            />
          )
        },
      }),
    ]
    return option
  }, [t])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (newFilter: IParamsGetReport18) => {
    setFilter(newFilter)
    setPagination({ ...pagination, current: 1 })
  }

  const dataFlatten = useMemo(() => {
    return flattenDataList(dataInfinity)
  }, [dataInfinity])

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => {
      return {
        ...item,
        key: index,
      } as IDataTable
    })
  }, [dataFlatten])

  return {
    data,
    columns,
    filter,
    isLoading,
    handleTableChange,
    pagination: {
      ...pagination,
      total: dataFlatten?.total ?? 0,
    },
    handleChangeFilter,
    handleExportExcel,
  }
}
