import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { t } from 'i18next'
import Images from '../../components/Images'
import { CreateUpdateContext } from '../../context'
import * as S from '../../styles'
import { InfoComboLayout, ProductServiceInfoLayout } from './layouts'

export const ComboLayout = () => {
  return (
    <CreateUpdateContext.Consumer>
      {({ form, handleSubmit }) => {
        return (
          <S.RootWrapper direction="vertical" size={10}>
            <HeaderPage titleI18nKey={R.strings.add_combo} />
            <BaseForm
              layout="vertical"
              onFinish={handleSubmit}
              requiredMark="optional"
              form={form}
            >
              <BaseSpace size={10}>
                <InfoComboLayout />
                <Images />
                <ProductServiceInfoLayout />
              </BaseSpace>
              <S.FormItem>
                <S.ButtonActionGroup>
                  <S.ButtonCancel
                    onClick={() => {
                      console.log('cancel')
                    }}
                  >
                    {t(R.strings.cancel)}
                  </S.ButtonCancel>
                  <S.ButtonSubmit
                    onClick={() => {
                      console.log('submit')
                    }}
                  >
                    {t(R.strings.confirm)}
                  </S.ButtonSubmit>
                </S.ButtonActionGroup>
              </S.FormItem>
            </BaseForm>
          </S.RootWrapper>
        )
      }}
    </CreateUpdateContext.Consumer>
  )
}