export enum StatusServiceTreatmentScheduleEnum {
  Completed = 'completed',
  InTreatment = 'in_treatment',
  Suspended = 'suspended',
  Cancelled = 'cancelled',
}

export enum StatusTreatmentPlanEnum {
  Approved = 'approved',
  CancelApproval = 'cancel_approval',
  PendingApproval = 'pending_approval',
  NotAvailable = 'not_available',
}

export enum StatusAppointmentSessionTreatmentPlanEnum {
  Occurred = 'occurred',
  InTreatment = 'in_treatment',
  NotOccurred = 'not_occurred',
  HaveProblem = 'have_problem',
}

export enum StatusTreatmentInAppointmentSessionEnum {
  Completed = 'completed',
  InTreatment = 'in_treatment',
  PendingExecution = 'pending_execution',
  Suspended = 'suspended',
  StopPerforming = "stop_performing",
  PendingPayment = "pending_payment"
}
