/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  DownloadOutlined,
  FolderAddOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import R from 'app/assets/R'
import { SvgDeleteDesignIcon, SvgImageFolderIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import CollapseComponent from '../../components/CollapseComponent'
import { useProfilePatientContext } from '../../context'
import { ChildrenFolderImageItem } from './components'
import BaseText from 'app/components/common/BaseText'

const ImagePatientContainer = () => {
  const { t } = useTranslation()
  const { imagePatient, onDeleteParentImagePatient } =
    useProfilePatientContext()

  return (
    <BaseSpace>
      {imagePatient?.parentFolder?.map((parent, index) => {
        return (
          <CollapseWrapper
            label={
              <BaseRow gutter={15} align={'middle'}>
                <BaseCol style={{ display: 'flex', alignItems: 'center' }}>
                  <SvgImageFolderIcon />
                </BaseCol>
                <BaseCol>
                  <BaseText
                    children={parent?.name ?? '-'}
                    fontWeight="semibold"
                    fontSize="xs"
                  />
                </BaseCol>
              </BaseRow>
            }
            extra={
              <BaseRow gutter={[5, 5]} align={'middle'}>
                <BaseCol>
                  <BaseButton
                    icon={<FolderAddOutlined rev={undefined} />}
                    type="text"
                  />
                </BaseCol>

                <BaseCol>
                  <BaseButton
                    icon={<DownloadOutlined rev={undefined} />}
                    type="text"
                  />
                </BaseCol>
                <BaseCol>
                  <BaseButton
                    icon={<SvgDeleteDesignIcon />}
                    type="text"
                    onClick={() => onDeleteParentImagePatient?.(parent?.name)}
                  />
                </BaseCol>
              </BaseRow>
            }
            children={
              <BaseSpace>
                {parent?.child?.map((itm, idx) => {
                  return (
                    <ChildrenFolderImageItem
                      item={itm}
                      parent={parent}
                      key={idx}
                    />
                  )
                })}
              </BaseSpace>
            }
            key={index}
          />
        )
      })}

      <BaseButton icon={<PlusOutlined rev={undefined} />} type="link">
        {t(R.strings.add_treatment_procedure_folder)}
      </BaseButton>
    </BaseSpace>
  )
}

export default ImagePatientContainer

const CollapseWrapper = styled(CollapseComponent)`
  border: 0px !important;
  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-content {
    border-top: 0px;
  }
`
