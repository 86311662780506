import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import DiscountCampaignManagementContainer from 'app/containers/Marketing/DiscountCampaignManagement'

const DiscountCampaignManagementPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.discount_campaign_management)}</PageTitle>
      <DiscountCampaignManagementContainer />
    </>
  )
}
const DiscountCampaignManagementPage = memo(
  DiscountCampaignManagementPageCpn,
  isEqual,
)

export default DiscountCampaignManagementPage
