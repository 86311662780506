/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchOutlined } from '@ant-design/icons'
import {
  IParamsGetReport21,
  IReferralSource,
} from 'app/api/report/model/report-21'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { randomMoney } from 'app/components/tables/common-table/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { useDebounce, usePagination } from 'app/hook'
import { useGetTreatmentGroupList } from 'app/react-query/hook/old-db'
import { useGetReferralSource } from 'app/react-query/hook/report'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { Dayjs } from 'dayjs'
import { t } from 'i18next'
import {
  Dates,
  FONT_SIZE,
  FONT_WEIGHT,
  Pagination,
  ResponseType,
  initialPagination,
} from 'parkway-web-common'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { EFilterDate } from '../../type'
import { TotalText, VerticalDivider } from '../../styles'
import BaseText from 'app/components/common/BaseText'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

interface IProps {
  onChange?: (value: IParamsGetReport21) => void
  formData?: IParamsGetReport21
  totalItem?: number
}

export const FilterLayout: React.FC<IProps> = ({
  formData,
  onChange,
  totalItem,
}) => {
  const { flattenDataList, flattenData } = usePagination()
  const [keywordSearchTreatment, setKeywordSearchTreatment] = useState<
    string | undefined
  >(undefined)
  const [filterDateType, setFilterDateType] = useState<EFilterDate | undefined>(
    undefined,
  )
  const [loadMoreTreatment, setLoadMoreTreatment] = useState(0)
  const loadMoreTreatmentDebounce = useDebounce(loadMoreTreatment, 200)
  const keywordSearchTreatmentDebounce = useDebounce(
    keywordSearchTreatment,
    200,
  )

  const [pagination, setPagination] = useState<Pagination>({
    ...initialPagination,
    pageSize: 30,
  })
  const { data: dataReferralSource, isLoading: isLoadingReferralSource } =
    useGetReferralSource()

  const FilterDateOptions = useMemo(
    () => [
      {
        label: t(R.strings.voucher_applied_date),
        value: EFilterDate.apply,
      },
      {
        label: t(R.strings.start_date_using_service),
        value: EFilterDate.using,
      },
    ],
    [],
  )

  const referralSourceList = useMemo(() => {
    const res: ResponseType<IReferralSource[]> =
      flattenDataList(dataReferralSource)
    return res?.data
  }, [dataReferralSource])

  const {
    data: treatmentGroupApi,
    isLoading,
    hasNextPage: hasNextPageTreatment,
    fetchNextPage: fetchNextPageTreatment,
    isFetchingNextPage,
  } = useGetTreatmentGroupList({
    keyword: keywordSearchTreatmentDebounce,
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
  })

  const onChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onChange && onChange({ ...formData, keyword: value })
  }

  const onChangeReferralSource = (ids: string[]) => {
    onChange && onChange({ ...formData, referer: ids })
  }

  const onChangeDateFilterType = (id: EFilterDate) => {
    setFilterDateType(id)
    if (id === EFilterDate.apply) {
      onChange &&
        onChange({
          ...formData,
          startUsingAtStart: undefined,
          startUsingAtEnd: undefined,
        })
    } else if (id === EFilterDate.using) {
      onChange &&
        onChange({
          ...formData,
          appliedAtStart: undefined,
          appliedAtEnd: undefined,
        })
    } else {
      onChange &&
        onChange({
          ...formData,
          startUsingAtStart: undefined,
          startUsingAtEnd: undefined,
          appliedAtStart: undefined,
          appliedAtEnd: undefined,
        })
    }
  }

  const dataTreatmentFlatten = useMemo(() => {
    return flattenData(treatmentGroupApi)
  }, [treatmentGroupApi])

  const dataTreatmentGroup = useMemo(() => {
    return dataTreatmentFlatten?.map(i => ({
      label: i?.name,
      value: i?._id,
    }))
  }, [treatmentGroupApi])

  const onPopupTreatmentScroll = e => {
    const heightChange = e?.target?.scrollTop + e?.target?.clientHeight ?? 0
    const scrollHeight = e?.target?.scrollHeight ?? 0
    const isEndOfList = heightChange >= 0.7 * scrollHeight

    if (isEndOfList && hasNextPageTreatment) {
      setLoadMoreTreatment(randomMoney())
    }
  }

  const onChangeTreatment = (ids: string[]) => {
    onChange &&
      onChange({
        ...formData,
        treatmentIds: ids as string[],
      })
  }

  const onChangeTreatmentSearch = (keyword: string) => {
    setKeywordSearchTreatment(keyword)
    setPagination({ ...pagination, current: 1 })
  }

  const onChangeApplyStartDate = (day?: Dayjs) => {
    if (day && formData?.appliedAtEnd && day.isAfter(formData.appliedAtEnd)) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.start_date_must_be_before_end_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    onChange && onChange({ ...formData, appliedAtStart: value })
  }

  const onChangeApplyEndDate = (day?: Dayjs) => {
    if (
      day &&
      formData?.appliedAtStart &&
      day.isBefore(formData.appliedAtStart)
    ) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.end_date_must_be_after_start_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    onChange && onChange({ ...formData, appliedAtEnd: value })
  }

  const onChangeUsingStartDate = (day?: Dayjs) => {
    if (
      day &&
      formData?.startUsingAtEnd &&
      day.isAfter(formData.startUsingAtEnd)
    ) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.start_date_must_be_before_end_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    onChange && onChange({ ...formData, startUsingAtStart: value })
  }

  const onChangeUsingEndDate = (day?: Dayjs) => {
    if (
      day &&
      formData?.startUsingAtStart &&
      day.isBefore(formData.startUsingAtStart)
    ) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.end_date_must_be_after_start_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    onChange && onChange({ ...formData, startUsingAtEnd: value })
  }

  const onCheckBoxClick = (e: CheckboxChangeEvent) => {
    onChange && onChange({ ...formData, isFullData: e?.target?.checked })
  }

  useEffect(() => {
    if (loadMoreTreatmentDebounce) {
      fetchNextPageTreatment()
    }
  }, [loadMoreTreatmentDebounce])

  return (
    <BaseForm>
      <RootWrapper gutter={[16, 8]} align={'middle'}>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseInput
              prefix={<SearchOutlined rev={undefined} />}
              value={formData?.keyword}
              placeholder={t(R.strings.enter_name_customer_code)}
              onChange={onChangeKeyword}
              allowClear
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel required>
            <BaseSelectWrapper
              $prefix={t(R.strings.referral_source)}
              $prefixWidth={90}
              mode="multiple"
              showSearch
              placeholder={t(R.strings.all)}
              allowClear
              onChange={ids => onChangeReferralSource?.(ids as string[])}
              options={referralSourceList?.map(item => ({
                value: item.key,
                label: item.value,
              }))}
              loading={isLoadingReferralSource}
              maxTagCount={0}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseFormItem hiddenLabel required>
            <BaseSelectWrapper
              allowClear
              mode="multiple"
              $prefix={t(R.strings.package)}
              value={formData?.treatmentIds}
              $prefixWidth={85}
              placeholder={t(R.strings.all)}
              onSearch={keyword => onChangeTreatmentSearch(keyword)}
              options={dataTreatmentGroup}
              loading={isLoading || isFetchingNextPage}
              onChange={ids => onChangeTreatment(ids as string[])}
              onPopupScroll={onPopupTreatmentScroll}
              maxTagCount={0}
              filterSort={() => 0}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={8}>
          <BaseFormItem hiddenLabel>
            <BaseSelectWrapper
              $prefix={t(R.strings.see_with)}
              $prefixWidth={80}
              placeholder={t(R.strings.all)}
              allowClear
              onChange={id => onChangeDateFilterType?.(id as EFilterDate)}
              options={FilterDateOptions?.map(item => ({
                value: item.value,
                label: item.label,
              }))}
            />
          </BaseFormItem>
        </BaseCol>

        {filterDateType === EFilterDate.apply && (
          <>
            <BaseCol xl={8}>
              <BaseFormItem hiddenLabel>
                <Picker
                  $prefix={t(R.strings.start_date)}
                  $prefixWidth={180}
                  picker="date"
                  value={
                    formData?.appliedAtStart
                      ? Dates.getDate(formData?.appliedAtStart)
                      : null
                  }
                  onChange={day => onChangeApplyStartDate(day as Dayjs)}
                  allowClear
                  format={'DD-MM-YYYY'}
                  placeholder={t(R.strings.all)}
                  showTime
                />
              </BaseFormItem>
            </BaseCol>
            <BaseCol xl={8}>
              <BaseFormItem hiddenLabel>
                <Picker
                  $prefix={t(R.strings.end_date)}
                  $prefixWidth={190}
                  value={
                    formData?.appliedAtEnd
                      ? Dates.getDate(formData?.appliedAtEnd)
                      : null
                  }
                  picker="date"
                  onChange={day => onChangeApplyEndDate(day as Dayjs)}
                  allowClear
                  format={'DD-MM-YYYY'}
                  placeholder={t(R.strings.all)}
                  showTime
                />
              </BaseFormItem>
            </BaseCol>
          </>
        )}
        {filterDateType === EFilterDate.using && (
          <>
            <BaseCol xl={8}>
              <BaseFormItem hiddenLabel>
                <Picker
                  $prefix={t(R.strings.start_date)}
                  $prefixWidth={180}
                  picker="date"
                  value={
                    formData?.startUsingAtStart
                      ? Dates.getDate(formData?.startUsingAtStart)
                      : null
                  }
                  onChange={day => onChangeUsingStartDate(day as Dayjs)}
                  allowClear
                  format={'DD-MM-YYYY'}
                  placeholder={t(R.strings.all)}
                  showTime
                />
              </BaseFormItem>
            </BaseCol>
            <BaseCol xl={8}>
              <BaseFormItem hiddenLabel>
                <Picker
                  $prefix={t(R.strings.end_date)}
                  $prefixWidth={190}
                  value={
                    formData?.startUsingAtEnd
                      ? Dates.getDate(formData?.startUsingAtEnd)
                      : null
                  }
                  picker="date"
                  onChange={day => onChangeUsingEndDate(day as Dayjs)}
                  allowClear
                  format={'DD-MM-YYYY'}
                  placeholder={t(R.strings.all)}
                  showTime
                />
              </BaseFormItem>
            </BaseCol>
          </>
        )}

        <BaseCol xl={24}>
          <BaseRow>
            <BaseText
              children={t(R.strings.found)}
              fontWeight="regular"
              fontSize="xs"
            />
            <TotalText
              children={`${totalItem}`}
              fontWeight="semibold"
              fontSize="xs"
            />
            <BaseText
              children={t(R.strings._result)}
              fontWeight="regular"
              fontSize="xs"
            />
            <VerticalDivider />
            <Checkbox checked={formData?.isFullData} onChange={onCheckBoxClick}>
              {t(R.strings.show_full_list)}
            </Checkbox>
          </BaseRow>
        </BaseCol>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseRow)``

const Checkbox = styled(BaseCheckbox)`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  color: rgba(109, 117, 128);
`
const Picker = styled(BaseDatePicker)<{
  $prefix?: string
  $prefixWidth?: number
}>`
  width: 100%;

  &:hover {
    border: 1px solid ${convertedVariables.neutralBlack9Color};
  }
  .ant-picker-input::before {
    content: '${props => props?.$prefix ?? '-'}:';
    width: ${props => props?.$prefixWidth ?? 40}px;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
  }
  .ant-picker-input > input {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
  }
`

const BaseSelectWrapper = styled(BaseSelect)<{
  $prefix: string
  $prefixWidth?: number
}>`
  width: 100%;
  .ant-select-selector::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$prefixWidth ?? 40}px;
    align-items: center;
    display: flex;
    padding-left: 4px;
  }

  .ant-select-selection-placeholder {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }

  .ant-select-selection-item {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.9;
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
