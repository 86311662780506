import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import { useModalHook } from './hook'
import * as S from './styles'
import { IFormSkillModalData } from './type'
import { IIndustry, ILevelSkill, ISkill } from 'app/api/skill/model/management'
import { useState } from 'react'

export const initValues: IFormSkillModalData = {
  name: '',
}

export interface IPropsUpdateSkill {
  industryOptions: IIndustry[]
  levelSkillOptions: ILevelSkill[]
  skillOptions: ISkill[]
  handleSubmit: (values) => boolean
}

enum StatusSkillOptionsEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
}

export type StatusSkillType = keyof typeof StatusSkillOptionsEnum

export const SkillModal = ({
  industryOptions,
  levelSkillOptions,
  skillOptions,
  handleSubmit,
}: IPropsUpdateSkill) => {
  const { form, visible, t, showModal, handleCancel, onFinish, rules } =
    useModalHook({
      handleSubmit,
    })

  const [skillFiltered, setSkillFiltered] = useState<ISkill[]>([])

  const handleActionAccountOnChange = key => {
    const skillFiltered = skillOptions.filter(skill => skill.industry === key)
    setSkillFiltered(skillFiltered)
    form.setFieldValue('skill', null)
  }

  return (
    <BaseModalManagement
      typeButtonOpen="hyperlink"
      titleKeyI18n={R.strings.skill_content_add}
      content={
        <BaseForm
          layout="vertical"
          onFinish={onFinish}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <S.FormItem
            label={t(R.strings.skill_content_industry)}
            name="industry"
            rules={rules.industry}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.skill_content_select_industry)}
              onChange={handleActionAccountOnChange}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {industryOptions?.map((industry, index) => {
                return (
                  <Option value={industry._id} key={index}>
                    {industry.name}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>
          <S.FormItem
            label={t(R.strings.skill_content_title)}
            name="skill"
            rules={rules.skill}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.profile_content_please_select_skill)}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {skillFiltered?.map((skill, index) => {
                return (
                  <Option value={skill._id} key={index}>
                    {skill.name}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>
          <S.FormItem
            label={t(R.strings.profile_content_please_select_level)}
            name="levelSkill"
            rules={rules.levelSkill}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.skill_content_select_industry)}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {levelSkillOptions?.map((industry, index) => {
                return (
                  <Option value={industry._id} key={index}>
                    {industry.name}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.skill_content_add}
      confirmKeyI18n={R.strings.skill_content_add}
      handleSubmit={form.submit}
      visible={visible}
    />
  )
}
