import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { useHookTable } from './hook'
import { BaseRow } from 'app/components/common/BaseRow'
import { useTranslation } from 'react-i18next'
import { TotalPage } from './styles'
import { FilterManagement } from './components/FilterManagementTable'
import { ItemCategoryProvider } from 'app/context/ItemCategoryContext'
import { WarehousePurchaseProvider } from './context'
import { BaseCol } from 'app/components/common/BaseCol'
import { PlusOutlined } from '@ant-design/icons'
import { INVENTORY_WAREHOUSE_PURCHASE_CREATE_PATH } from 'app/components/router/route-path'
import { useNavigate } from 'react-router'
const WarehousePurchaseContainer = () => {
  const { tableData, columns, handleTableChange, filter, handleChangeFilter } =
    useHookTable()

  const { t } = useTranslation()
  const navigate = useNavigate()
  
  return (
    <WarehousePurchaseProvider>
      <ItemCategoryProvider>
        <S.BaseManagementWrapper>
          <HeaderPage
            titleI18nKey={R.strings.warehouse_purchase}
            rightComponent={
              <>
                <BaseCol>
                  <S.ButtonCreateAction onClick={() => navigate(INVENTORY_WAREHOUSE_PURCHASE_CREATE_PATH)}>
                    <BaseRow gutter={8}>
                      <BaseCol>
                        <PlusOutlined rev={undefined} />
                      </BaseCol>
                      <BaseCol>{t(R.strings.warehouse_purchase_add)}</BaseCol>
                    </BaseRow>
                  </S.ButtonCreateAction>
                </BaseCol>
              </>
            }
          />
          <S.RootWrapper>
            <BaseRow>
              <FilterManagement
                handleChangeFilter={handleChangeFilter}
                filterData={filter}
              />
            </BaseRow>
            <BaseTableManagement
              columns={columns}
              dataSource={tableData.data}
              pagination={{
                ...tableData.pagination,
                showTotal: total => {
                  return (
                    <TotalPage>
                      {t(R.strings.total_page, {
                        total,
                      })}
                    </TotalPage>
                  )
                },
              }}
              loading={tableData.loading}
              onChange={handleTableChange}
              expandable={{
                expandIcon: () => null,
              }}
            />
          </S.RootWrapper>
        </S.BaseManagementWrapper>
      </ItemCategoryProvider>
    </WarehousePurchaseProvider>
  )
}
export default WarehousePurchaseContainer
