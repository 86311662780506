/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CloseCircleOutlined,
  DownloadOutlined,
  MessageOutlined,
  PaperClipOutlined,
  SendOutlined,
} from '@ant-design/icons'
import { UploadFile } from 'antd'
import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseUpload } from 'app/components/common/BaseUpload'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { _DEV_ } from 'app/constant'
import CollapseComponent from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/CollapseComponent'
import {
  IProfessionalExchangeWitPhysician
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SUFFIX_IMAGE } from './constant'
import { fileToDataUri, getDataByFileType, useHook } from './hook'

export const ProfessionalExchangeWithPhysicianItem = ({
  professionalExchangeWitPhysicians,
}: {
  professionalExchangeWitPhysicians: IProfessionalExchangeWitPhysician[]
}) => {

  const { t } = useTranslation()
  const [dataUri, setDataUri] = useState<
    { uploadFile: UploadFile; uri: string }[]
  >([])
  const { form, props, fileList, onRemove } = useHook()

  const imageList = useMemo(() => {
    return fileList.filter(file => {
      const fileType = file?.name?.split('.')?.pop()?.toLowerCase()
      if (!fileType) return false

      return SUFFIX_IMAGE.includes(fileType)
    })
  }, [fileList])

  const files = useMemo(() => {
    return fileList.filter(file => {
      const fileType = file?.name?.split('.')?.pop()?.toLowerCase()
      if (!fileType) return false
      return !SUFFIX_IMAGE.includes(fileType)
    })
  }, [fileList])

  const getImage = async () => {
    try {
      const listImageCallFile = imageList?.map(item => {
        return fileToDataUri(item)
      })
      const res = await Promise.all(listImageCallFile)

      setDataUri(
        imageList?.map((file, idx) => {
          return {
            uploadFile: file,
            uri: res?.[idx] as string,
          }
        }),
      )
    } catch (error) {
      _DEV_ && console.log({ error })
    }
  }

  useEffect(() => {
    getImage()
  }, [imageList])

  return (
    <CollapseComponent
      expandIconPosition="end"
      items={[
        {
          label: (
            <BaseRow gutter={[15, 10]} align={'middle'}>
              <BaseCol>
                <BaseText
                  children={t(R.strings.professional_exchange_with_physician)}
                  fontWeight="semibold"
                  fontSize="md"
                />
              </BaseCol>
              <BaseCol>
                <MessageOutlined rev={undefined} />
              </BaseCol>
            </BaseRow>
          ),
          children: (
            <BaseSpace>
              {professionalExchangeWitPhysicians?.map((itm, idx) => {
                return (
                  <BaseSpace key={idx}>
                    <BaseAvatarProfile
                      name={itm?.doctor?.name}
                      jobTitle={itm?.doctor?.jobTitle}
                      src={R.images.ic_avatar}
                    />
                    <BaseText children={itm?.message} fontWeight="medium" />

                    {itm?.images?.length ? (
                      <BaseRow gutter={[15, 15]} align={'middle'}>
                        {itm?.images?.map((img, idxImg) => {
                          return (
                            <BaseCol key={idxImg}>
                              <BaseImageWrapper
                                src={img}
                                width={100}
                                height={100}
                                style={{ borderRadius: BORDER_RADIUS }}
                                preview
                              />
                            </BaseCol>
                          )
                        })}
                      </BaseRow>
                    ) : null}
                    {itm?.files?.length ? (
                      <BaseRow gutter={[20, 20]} align={'middle'}>
                        {itm?.files?.map((itm, idx) => {
                          const dataFile = getDataByFileType({
                            name: itm,
                            uid: itm,
                          })
                          const icon = dataFile?.icon
                          return (
                            <BaseCol key={idx}>
                              <FileItemSelected
                                gutter={[15, 10]}
                                align={'middle'}
                              >
                                {icon}
                                <BaseCol>
                                  <BaseText children={itm} />
                                </BaseCol>
                                <BaseCol>
                                  <DownloadDelete rev={undefined} />
                                </BaseCol>
                              </FileItemSelected>
                            </BaseCol>
                          )
                        })}
                      </BaseRow>
                    ) : null}
                  </BaseSpace>
                )
              })}

              <BaseForm form={form}>
                <ContentInputWrapper>
                  <BaseForm.Item label={t(R.strings.content)}>
                    <BaseInput
                      placeholder={t(R.strings.enter_content)}
                      suffix={
                        <BaseRow gutter={[15, 10]} align={'middle'}>
                          <BaseCol>
                            <BaseUpload
                              showUploadList={false}
                              {...props}
                              type="select"
                              multiple
                            >
                              <BaseButton
                                type="text"
                                icon={<PaperClipOutlined rev={undefined} />}
                              />
                            </BaseUpload>
                          </BaseCol>
                          <BaseCol>
                            <BaseButton type="text">
                              <SendOutlined rev={undefined} />
                            </BaseButton>
                          </BaseCol>
                        </BaseRow>
                      }
                    />
                  </BaseForm.Item>

                  <BaseRow justify={'end'}>
                    <BaseText
                      children={`* ${t(R.strings.upload_size_max_10_mb)}`}
                      fontSize="xxs"
                    />
                  </BaseRow>

                  {files?.length || dataUri?.length ? (
                    <>
                      <BaseText
                        children={t(R.strings.attached)}
                        fontWeight="semibold"
                      />
                      <AttachedAreaWrapper>
                        {dataUri?.length ? (
                          <BaseRow
                            gutter={[20, 5]}
                            align={'middle'}
                            style={{ marginLeft: 0, marginRight: 0 }}
                          >
                            {dataUri?.map((itm, idx) => {
                              return (
                                <BaseCol key={idx}>
                                  <FileItemSelected
                                    gutter={[15, 10]}
                                    align={'middle'}
                                  >
                                    <BaseImage
                                      src={itm?.uri}
                                      width={100}
                                      height={100}
                                    />
                                  </FileItemSelected>
                                </BaseCol>
                              )
                            })}
                          </BaseRow>
                        ) : null}
                        {files?.length ? (
                          <BaseRow
                            gutter={[20, 5]}
                            align={'middle'}
                            style={{ marginLeft: 0, marginRight: 0 }}
                          >
                            {files?.map((itm, idx) => {
                              const dataFile = getDataByFileType(itm)
                              const icon = dataFile?.icon
                              return (
                                <BaseCol key={idx}>
                                  <FileItemSelected
                                    gutter={[15, 10]}
                                    align={'middle'}
                                  >
                                    {icon}
                                    <BaseCol>
                                      <BaseText children={itm?.name} />
                                    </BaseCol>
                                    <BaseCol onClick={() => onRemove(itm)}>
                                      <ButtonDelete rev={undefined} />
                                    </BaseCol>
                                  </FileItemSelected>
                                </BaseCol>
                              )
                            })}
                          </BaseRow>
                        ) : null}
                      </AttachedAreaWrapper>
                    </>
                  ) : null}
                </ContentInputWrapper>
              </BaseForm>
            </BaseSpace>
          ),
        },
      ]}
    />
  )
}

const BaseImageWrapper = styled(BaseImage)`
  .ant-image-mask {
    border-radius: ${BORDER_RADIUS} !important;
  }
`

const ButtonDelete = styled(CloseCircleOutlined)`
  cursor: pointer;
`

const DownloadDelete = styled(DownloadOutlined)`
  cursor: pointer;
`

const FileItemSelected = styled(BaseRow)`
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  width: fit-content;
  padding: ${PADDING.xxxxs};
  background-color: ${convertedVariables.backgroundColor};
`

const ContentInputWrapper = styled(BaseSpace)`
  background-color: #f9fafb; // need change
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.xxs};
`

const AttachedAreaWrapper = styled(BaseSpace)`
  background-color: #f3f4f6; // need change
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.xxs};
  .ant-image-img {
    object-fit: contain !important;
  }
`
