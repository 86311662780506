import { ApiClientHr } from 'app/service/api-service'
import { RoleEndPoint } from './constant'
import {
  IParamsGetRoleManagement,
  IPayloadCreateRole,
  IPayloadUpdateRole,
} from './models/role'
import { IParamsGetRoleRights } from './models/rights'
import {
  IPayloadCreateJobTitleRole,
  IPayloadUpdateJobTitleRole,
} from './models/job-title-role'

export const requestGetRolesManagement = (params: IParamsGetRoleManagement) =>
  ApiClientHr.Get({
    url: RoleEndPoint.RoleManagement.request,
    params: { params },
  })

export const requestPostRolesManagement = (body: IPayloadCreateRole) =>
  ApiClientHr.Post({
    url: RoleEndPoint.RoleManagement.request,
    body,
  })

export const requestPutRolesManagement = (body: IPayloadUpdateRole) =>
  ApiClientHr.Put({
    url: `${RoleEndPoint.RoleManagement.request}/${body.id}`,
    body,
  })

export const requestDeleteRolesManagement = (id: string) =>
  ApiClientHr.Delete({
    url: `${RoleEndPoint.RoleManagement.request}/${id}`,
    body: {},
  })

export const requestGetRights = (params: IParamsGetRoleRights) =>
  ApiClientHr.Get({
    url: RoleEndPoint.RoleManagement.requestRight,
    params: { params },
  })

export const requestPostJobTitleRole = (body: IPayloadCreateJobTitleRole) =>
  ApiClientHr.Post({
    url: RoleEndPoint.RoleManagement.jobTitleRoles,
    body,
  })

export const requestPutJobTitleRole = (body: IPayloadUpdateJobTitleRole) => {
  const { jobTitleRoleId, ...otherBody } = body
  return ApiClientHr.Put({
    url: `${RoleEndPoint.RoleManagement.jobTitleRoles}/${jobTitleRoleId}`,
    body: otherBody,
  })
}

export const requestGetJobTitleRoleByJobTitleId = (id?: string | number) =>
  ApiClientHr.Get({
    url: `${RoleEndPoint.RoleManagement.jobTitleRoleByJobTitle}/${id}`,
    params: { id },
  })
