import R from 'app/assets/R'
import { BaseLayoutReport } from 'app/components/common/BaseLayoutReport'
import { data } from './data'

const DentalTreatmentManagementContainer = () => {


  return (
    <BaseLayoutReport
      data={data}
      titleI18nKey={R.strings.dental_treatment_management}
    />
  )
}

export default DentalTreatmentManagementContainer
