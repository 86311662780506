import { FormInstance } from 'antd/lib'
import { ICategoryTreatment } from 'app/api/category/model'
import { IJobTitleManagement } from 'app/api/jobtitle/model/job-title-management'
import { IJobTitleLevelManagement } from 'app/api/jobtitlelevel/model/job-title-level-management'
import { usePagination } from 'app/hook'
import { useGetCategoryTreatmentList } from 'app/react-query/hook/category'
import { useGetItemBrandList } from 'app/react-query/hook/item-brand'
import { IResponseQueryList } from 'app/react-query/model/common'
import { useMemo, useState } from 'react'
import { IFormData } from '../../../../type'
import { StatusEnum } from 'app/common/enum'

export const useHook = ({ form }: { form?: FormInstance<IFormData> }) => {
  const { flattenDataList } = usePagination()

  const { data: categoryTreatmentData, isLoading: isLoadingCategoryTreatment } =
    useGetCategoryTreatmentList({ status: StatusEnum.ACTIVE, pagesize: 1000 })

  const { data: itemBrandData, isLoading: isLoadingItemBrand } =
    useGetItemBrandList()

  const categoryTreatments = useMemo(() => {
    const flattenData: IResponseQueryList<ICategoryTreatment[]> =
      flattenDataList(categoryTreatmentData)
    return flattenData?.data ?? []
  }, [categoryTreatmentData])

  const itemBrands = useMemo(() => {
    const flattenData: IResponseQueryList<ICategoryTreatment[]> =
      flattenDataList(itemBrandData)
    return flattenData?.data ?? []
  }, [itemBrandData])

  // const [jobTitleSelected, setJobTitle] = useState<IJobTitleManagement[]>([])
  const [jobTitleLevelSelected, setJobTitleLevel] = useState<
    IJobTitleLevelManagement[]
  >([])

  const doctorJobTitleLevelIds = form?.getFieldValue('doctorJobTitleLevelIds')

  const onChangeJobTitle = (value: IJobTitleManagement) => {
    const doctorJobTitleLevelIds = form?.getFieldValue('doctorJobTitleLevelIds')
    form?.setFieldValue('doctorJobTitleLevelIds', [
      ...(doctorJobTitleLevelIds || []),
      value,
    ])
  }

  const onDeleteJobTitle = (index: number) => {
    const newJobTitleSelected = doctorJobTitleLevelIds.filter(
      (_, i) => i !== index,
    )
    form?.setFieldValue('doctorJobTitleLevelIds', newJobTitleSelected)
  }

  const onChangeJobTitleLevel = (value: IJobTitleManagement) => {
    const itemExist = jobTitleLevelSelected.find(
      item => item?._id === value?._id,
    )

   // console.log({ value, itemExist })

    if (itemExist) {
      return
    }

    setJobTitleLevel([...jobTitleLevelSelected, value])
  }

  const onDeleteJobTitleLevelLevel = (index: number) => {
    const newJobTitleLevelSelected = jobTitleLevelSelected.filter(
      (_, i) => i !== index,
    )
    setJobTitleLevel(newJobTitleLevelSelected)
  }

  return {
    categoryTreatments,
    itemBrands,
    isLoadingCategoryTreatment,
    isLoadingItemBrand,
    onChangeJobTitle,
    onChangeJobTitleLevel,
    jobTitleSelected: doctorJobTitleLevelIds,
    jobTitleLevelSelected,
    onDeleteJobTitle,
    onDeleteJobTitleLevelLevel,
  }
}
