import { PlusOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { notificationController } from 'app/controllers/notification-controller'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { DateUtil } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import { ActionComponent } from '../../components/ActionComponent'
import DividerLine from '../../components/DividerLine'
import { useProfilePatientContext } from '../../context'
import { TagStatusComponent } from '../TreatmentSchedule/components'
import * as S from './styles'
import { SvgPriceManagementAddIcon } from 'app/assets/svg-assets'

const ItemInfo = ({
  labelI18nKey,
  value,
  isCopy,
  isFocus,
}: {
  labelI18nKey: string
  value: string
  isCopy?: boolean
  isFocus?: boolean
}) => {
  const { t } = useTranslation()
  const handleCopy = () => {
    value &&
      navigator.clipboard.writeText(value).then(() => {
        notificationController.info({ message: t(R.strings.copy_success) })
      })
  }

  return (
    <S.BaseSpaceWrapper direction="vertical" size={5}>
      <S.TitleItemInfo>{t(labelI18nKey)}</S.TitleItemInfo>
      <BaseRow gutter={[10, 10]} align={'middle'}>
        <S.ValueInfo $color={isFocus ? 'var(--states-red-color)' : undefined}>
          {value}
        </S.ValueInfo>
        {isCopy ? <S.CopyIcon onClick={handleCopy} /> : null}
      </BaseRow>
    </S.BaseSpaceWrapper>
  )
}

const InfoPatientComponent = () => {
  const { t } = useTranslation()
  const { handleOpenPurchase } = useProfilePatientContext()
  return (
    <BaseRow gutter={[15, 15]} align={'top'}>
      <BaseCol xl={16}>
        <S.SectionBaseStyled>
          <S.BaseSpaceWrapper direction="vertical" size={15}>
            <S.HeaderWrapper
              align={'middle'}
              justify={'space-between'}
              gutter={[15, 15]}
            >
              <BaseCol>
                <BaseText fontWeight="semibold" fontSize="md">
                  {t(R.strings.profile_patient_record)}
                </BaseText>
              </BaseCol>
              <BaseCol>
                <ActionComponent
                  content={<BaseText children={R.strings.update} />}
                />
              </BaseCol>
            </S.HeaderWrapper>

            <S.ContentWrapper direction="vertical" size={16}>
              <BaseRow gutter={[15, 15]}>
                <BaseCol xl={12}>
                  <ItemInfo
                    labelI18nKey={R.strings.msbn}
                    value="PW0008898988"
                    isCopy
                  />
                </BaseCol>
                <BaseCol xl={12}>
                  <ItemInfo
                    labelI18nKey={R.strings.full_name}
                    value="PW0008898988"
                  />
                </BaseCol>
              </BaseRow>
              <BaseRow gutter={[15, 15]}>
                <BaseCol xl={12}>
                  <ItemInfo labelI18nKey={R.strings.gender} value="Nữ" />
                </BaseCol>
                <BaseCol xl={12}>
                  <ItemInfo
                    labelI18nKey={R.strings.birthday}
                    value="08/08/2008"
                  />
                </BaseCol>
              </BaseRow>
              <BaseRow gutter={[15, 15]}>
                <BaseCol xl={12}>
                  <ItemInfo
                    labelI18nKey={R.strings.phone}
                    value="0989 889 888"
                  />
                </BaseCol>
                <BaseCol xl={12}>
                  <ItemInfo
                    labelI18nKey={R.strings.email}
                    value="nguyenlananh008@gmail.com"
                  />
                </BaseCol>
              </BaseRow>
              <BaseRow>
                <BaseCol xl={24}>
                  <ItemInfo
                    labelI18nKey={R.strings.address}
                    value="18 Trần Hưng Đạo, P. Nguyễn Cư Trinh, Quận 1, Tp. Hồ Chí Minh"
                  />
                </BaseCol>
              </BaseRow>
              <BaseRow>
                <BaseCol xl={24}>
                  <ItemInfo
                    labelI18nKey={R.strings.customer_source}
                    value="Quảng cáo (do CSKH tạo)"
                  />
                </BaseCol>
              </BaseRow>
              <BaseRow>
                <BaseCol xl={24}>
                  <ItemInfo
                    labelI18nKey={R.strings.important_note}
                    value="BN mua gói combo ( GIá trị sử dụng đến ngày 20/08/2024)"
                    isFocus
                  />
                </BaseCol>
              </BaseRow>
              <BaseSpace size={10}>
                <BaseText
                  opacity="0.7"
                  colorText="primary1Color"
                  fontWeight="medium"
                >
                  {t(R.strings.update_by)}
                </BaseText>

                <BaseRow
                  gutter={[10, 10]}
                  align={'middle'}
                  justify={'space-between'}
                >
                  <BaseCol>
                    <BaseAvatarProfile name="Khánh" jobTitle="Tư vấn viên" />
                  </BaseCol>
                  <BaseCol>
                    <BaseText
                      opacity="0.7"
                      colorText="primary1Color"
                      fontWeight="medium"
                    >
                      {DateUtil.formatDDMMYYYHhss(new Date())}
                    </BaseText>
                  </BaseCol>
                </BaseRow>
              </BaseSpace>

              <BaseRow gutter={[10, 10]} align={'middle'} justify={'start'}>
                <BaseCol>
                  <S.HistoryIcon rev={undefined} />
                </BaseCol>
                <BaseCol>
                  <BaseText
                    children={t(R.strings.see_history_update_note)}
                    fontWeight="medium"
                    fontSize="xs"
                    colorText="otherBlueColor"
                  />
                </BaseCol>
              </BaseRow>
            </S.ContentWrapper>
          </S.BaseSpaceWrapper>
        </S.SectionBaseStyled>
      </BaseCol>
      <BaseCol xl={8}>
        <S.BaseSpaceWrapper direction="vertical" size={16}>
          <S.SectionBaseStyled>
            <S.BaseSpaceWrapper direction="vertical" size={15}>
              <S.HeaderWrapper align={'middle'}>
                <BaseCol>
                  <BaseText
                    children={t(R.strings.lasted_appointment)}
                    fontWeight="semibold"
                    fontSize="md"
                  />
                </BaseCol>
              </S.HeaderWrapper>
              <S.ContentWrapper direction="vertical" size={16}>
                <BaseRow align={'bottom'} gutter={15}>
                  <BaseCol>
                    <ItemInfo
                      labelI18nKey={R.strings.date_time}
                      value="20/08/2024"
                    />
                  </BaseCol>
                  <BaseCol>
                    <TagStatusComponent
                      backgroundColor={convertedVariables.statesOrangeColor}
                      color={convertedVariables.backgroundColor}
                      textKeyI18n={R.strings.today}
                      prefixIcon={<S.DotStatusDay />}
                    />
                  </BaseCol>
                </BaseRow>
                <ItemInfo
                  labelI18nKey={R.strings.main_content}
                  value={'HCM2024_11_SV: Hẹn 2 tháng NR14,24, thay cả hàm'}
                />
                <S.BaseSpaceWrapper direction="vertical" size={15}>
                  <S.TitleItemInfo>
                    {t(R.strings.doctor_in_charge_of_the_shift)}
                  </S.TitleItemInfo>

                  <BaseAvatarProfile name="Đình Thu" jobTitle="bác sĩ chính" />
                </S.BaseSpaceWrapper>
                <BaseRow align={'middle'} gutter={10}>
                  <BaseCol xl={12}>
                    <ItemInfo
                      labelI18nKey={R.strings.note}
                      value={'HCM2024_11_SV'}
                    />
                  </BaseCol>
                  <BaseCol xl={12}>
                    <ItemInfo
                      labelI18nKey={R.strings.address}
                      value={'Hồ Chí Minh'}
                    />
                  </BaseCol>
                </BaseRow>
                <DividerLine />

                <BaseRow align={'middle'} gutter={15}>
                  <BaseCol xl={12}>
                    <S.ButtonCreate
                      $backgroundColor="var(--other-blue-color)"
                      icon={<PlusOutlined rev={undefined} />}
                    >
                      {t(R.strings.appointment)}
                    </S.ButtonCreate>
                  </BaseCol>
                  <BaseCol xl={12}>
                    <S.ButtonCreate
                      icon={<PlusOutlined rev={undefined} />}
                    >
                      {t(R.strings.call_reminder_schedule)}
                    </S.ButtonCreate>
                  </BaseCol>
                </BaseRow>
              </S.ContentWrapper>
            </S.BaseSpaceWrapper>
          </S.SectionBaseStyled>
          <S.ButtonCreatePrice icon={<SvgPriceManagementAddIcon />}>
            {t(R.strings.price)}
          </S.ButtonCreatePrice>
          <S.ButtonPurchase
            type="primary"
            icon={<ShoppingCartOutlined rev={undefined} />}
            onClick={handleOpenPurchase}
          >
            {t(R.strings.purchase_dental_products_services)}
          </S.ButtonPurchase>
        </S.BaseSpaceWrapper>
      </BaseCol>
    </BaseRow>
  )
}

export default InfoPatientComponent
