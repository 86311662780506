import { StatusTableType } from '../hook'

export interface IResponseFilter {
  status?: StatusTableType[]
  search?: string
}

export interface IFilterProps {
  handleChangeFilter?: (data: IResponseFilter) => void
  filterData?: IResponseFilter
}

export const initResponseFilterTableManagement = {
  search: '',
  status: [],
}

export enum PaidTypeTableEnum {
  deposit = 'deposit',
  installment = 'installment',
  one_time_payment = 'one_time_payment',
  no_paid = 'no_paid',
  end_date = 'end_date',
}

export enum InvoicePaidTypeTableEnum {
  cash = 'cash',
  card = 'card',
  transfer = 'transfer',
  app_momo = 'app_momo',
  payoo_link = 'payoo_link',
  fundiin = 'fundiin',
  daily_invoice_payment_for_treatment = 'daily_invoice_payment_for_treatment',
  daily_invoice_payment_for_receivable = 'daily_invoice_payment_for_receivable',
  net_amount = 'net_amount',
  paid = 'paid',
}

export type PaidTypeTableType = keyof typeof PaidTypeTableEnum
declare type EventValue<DateType> = DateType | null
export declare type RangeValue<DateType> =
  | [EventValue<DateType>, EventValue<DateType>]
  | null

export interface IFilter {
  keyword: string | undefined
  treatments: string[] | undefined
  paidType: string[] | unknown
  startDate: string | undefined
  endDate: string | undefined
  search?: string | undefined
}

export interface IProps {
  handleChangeFilter
  filterData
  listPaidType?: string[]
  isTreatment?: boolean
  listPaymentMethod?: string[]
}

export enum PaymentMethodEnum {
  payment_method_full = 'FULL',
  payment_method_pw = 'INSTALLMENT_PW',
  payment_method_fundiin = 'INSTALLMENT_FUNDIIN',
  payment_method_credit = 'INSTALLMENT_CREDIT',
}

export enum PaymentMethodTranslateEnum {
  FULL = "payment_method_full",
  INSTALLMENT_PW = 'payment_method_pw',
  INSTALLMENT_FUNDIIN = 'payment_method_fundiin',
  INSTALLMENT_CREDIT = 'payment_method_credit',
}