/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestDetailItemCategoryManagement,
  requestUpdateItemCategoryManagement,
} from 'app/api/item-category'
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IFormData,
  IFormRule,
  IPropsUpdateItemCategory,
  StatusItemCategoryEnum,
} from './type'
import {
  IPayloadUpdateItemCategoryManagement,
  IItemCategory,
} from 'app/api/item-category/model/management'
import { ItemCategoryMessageCode } from 'app/api/item-category/constant'
import {
  convertArrayStatusFilterToArrayStatusNumber,
  convertStatusToFilterStatus,
} from 'app/common/helpers'

export const useModalHook = (props: IPropsUpdateItemCategory) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormData>()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getDetail = async () => {
    if (!props?.id) {
      return
    }
    try {
      const res: ResponseType<IItemCategory> =
        await requestDetailItemCategoryManagement(props?.id)

      if (
        isEqual(
          res?.msgcode,
          ItemCategoryMessageCode.ItemCategoryManagement.successGetData,
        )
      ) {
        const data = res?.data

        form.setFieldsValue({
          _id: data._id,
          name: data?.name,
          status: convertStatusToFilterStatus(data?.status),
        })
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  useEffect(() => {
    visible && getDetail()
  }, [props?.id, visible])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)
    try {
      const body: IPayloadUpdateItemCategoryManagement = {
        _id: values?._id,
        name: values?.name,
        status:
          convertArrayStatusFilterToArrayStatusNumber([
            values?.status,
          ] as any)?.[0] ?? StatusItemCategoryEnum.active,
      }

      const response: ResponseType<string> =
        await requestUpdateItemCategoryManagement({
          id: props?.id,
          body,
        })

      if (
        isEqual(
          response?.msgcode,
          ItemCategoryMessageCode.ItemCategoryManagement.successPutData,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        props?.fetchTable?.()
        notificationController.success({
          message: t(R.strings.update),
          description: response?.message,
        })
      } else {
        notificationController.error({
          message: t(R.strings.update),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const rules: IFormRule = useMemo(() => {
    return {
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.skill_field_name),
          }),
        },
      ],
      industry: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.skill_field_industry),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
  }
}
