import { BaseTable } from 'app/components/common/BaseTable'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const Title = styled.div`
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.xs};
  color: #000000;
  margin-bottom: 20px;
`

export const BaseTableWrapper = styled(BaseTable)`
  .row-overwrite-style {
    .ant-table-cell {
      border: 0.5px solid var(--neutral-black-2-color) !important;
    }

    .ant-table-cell-row-hover {
      background: white !important;
    }
  }

  .ant-table-thead > tr > th {
    border: 0.5px solid var(--neutral-black-2-color) !important;
    text-align: center !important;
    font-size: 12px !important;
  }

  .very-big-column {
    min-width: 300px !important;
  }

  .big-column {
    min-width: 220px !important;
  }

  .normal-column {
    min-width: 170px !important;
  }

  .medium-column {
    min-width: 100px !important;
  }

  .small-column {
    width: 60px !important;
  }

  .number-column {
    min-width: 60px !important;
  }

  .center {
    text-align: center;
  }

  .title {
    &-right {
      text-align-last: right;
      padding-right: 24px;
    }
  }

  .flex-row {
    .ant-row {
      margin-left: 5px;
      display: inline-block;
    }
  }

  .all-column {
    background-color: white;
  }

  .ant-table-expanded-row > td {
    padding: 0px !important;
  }

  .larger-column {
    min-width: 700px !important;
  }
`
