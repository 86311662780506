import R from 'app/assets/R'
import { PaymentOptionEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRadio } from 'app/components/common/BaseRadio'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { IOrderDebtPayment } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/debt-payment'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const PaymentOptionLayout = ({
  orderItem,
}: {
  orderItem: IOrderDebtPayment
}) => {
  const { t } = useTranslation()
  const { onChangePaymentOptionDebtPayment } = useProfilePatientContext()

  const _onChange = (option: PaymentOptionEnum) => {
    onChangePaymentOptionDebtPayment?.(orderItem?.paymentOption, option)
  }

  return (
    <BaseSpace>
      <BaseText fontWeight={'semibold'} fontSize="xs">
        {t(R.strings.payment_option)}
      </BaseText>

      <BaseRadioGroup
        value={orderItem?.paymentOption}
        onChange={e => _onChange(e?.target?.value as PaymentOptionEnum)}
        defaultValue={PaymentOptionEnum.LatePaymentProcessing}
      >
        <BaseRow gutter={[15, 10]} align={'middle'}>
          <BaseCol xl={6}>
            <RadioBtn value={PaymentOptionEnum.LatePaymentProcessing}>
              <BaseText fontSize="xs">
                {t(R.strings.late_payment_processing)}
              </BaseText>
            </RadioBtn>
          </BaseCol>

          <BaseCol xl={6}>
            <RadioBtn value={PaymentOptionEnum.CompleteSettlement}>
              <BaseText fontSize="xs">
                {t(R.strings.complete_settlement)}
              </BaseText>
            </RadioBtn>
          </BaseCol>
        </BaseRow>
      </BaseRadioGroup>
    </BaseSpace>
  )
}
export default PaymentOptionLayout
const RadioBtn = styled(BaseRadio)`
  font-size: 0.875rem;
`

 const BaseRadioGroup = styled(BaseRadio.Group)`
  width: 100%;
`
