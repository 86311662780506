import R from 'app/assets/R'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useModalHook } from './hook'
import * as S from './styles'
import { StatusTableEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { DefineStatusWithoutDeleted } from 'app/common/constants'
import { Option } from 'app/components/common/selects/BaseSelect'
import { UnitContext } from 'app/context/UnitContext'
import { useContext } from 'react'
import { get } from 'lodash'

export const initValues = {
  name: '',
}

export type StatusWarehouseType = keyof typeof StatusTableEnum

export interface IPropsCreateWarehouse {
  fetchTable: () => void
  parentId?: string
}

export const Create = (props: IPropsCreateWarehouse) => {
  const {
    form,
    visible,
    t,
    showModal,
    handleSubmit,
    handleCancel,
    isLoading,
    rules,
  } = useModalHook(props)
  const { units } = useContext(UnitContext)
  const clinics = units?.map(unit => get(unit, 'childs', [])).flat(Infinity)

  return (
    <BaseModalManagement
      typeButtonOpen={props.parentId ? 'update' : 'add'}
      titleKeyI18n={R.strings.warehouse_add}
      isLoading={isLoading}
      content={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <BaseRow gutter={20} align={'top'} justify={'space-between'}>
            <BaseCol xl={12}>
              <S.FormItem
                name="code"
                label={t(R.strings.warehouse_code)}
                rules={rules.code}
                required
              >
                <S.FormInput
                  placeholder={t(R.strings.enter_field, {
                    field: t(R.strings.warehouse_code),
                  })}
                />
              </S.FormItem>
            </BaseCol>
            <BaseCol xl={12}>
              <S.FormItem
                name="roadWarehouseCode"
                label={t(R.strings.warehouse_road_code)}
                rules={rules.roadWarehouseCode}
                required
              >
                <S.FormInput
                  placeholder={t(R.strings.enter_field, {
                    field: t(R.strings.warehouse_road_code),
                  })}
                />
              </S.FormItem>
            </BaseCol>
          </BaseRow>

          <S.FormItem
            name="name"
            label={t(R.strings.warehouse_name)}
            rules={rules.name}
            required
          >
            <S.FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.warehouse_name),
              })}
            />
          </S.FormItem>

          <S.FormItem
            name="unitId"
            label={t(R.strings.branch)}
            rules={rules.unitId}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.branch)}
              suffixIcon={<SvgTableCloseIcon />}
              options={
                clinics?.map(item => ({
                  value: get(item, '_id'),
                  label: get(item, 'name'),
                })) || []
              }
            />
          </S.FormItem>

          <S.FormItem
            name="status"
            label={t(R.strings.status)}
            rules={rules.status}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.please_select_status)}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {Object.entries(DefineStatusWithoutDeleted).map(
                ([key, value]) => {
                  return (
                    <Option value={Number(key)} key={key}>
                      {t(value)}
                    </Option>
                  )
                },
              )}
            </S.SelectBaseStyled>
          </S.FormItem>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.warehouse_add}
      confirmKeyI18n={R.strings.confirm}
      handleSubmit={form.submit}
      visible={visible}
    />
  )
}
