import { BaseButton } from 'app/components/common/BaseButton'
import { ActionsDropdown } from 'app/components/tables/BaseTableManagement/ActionDropdown'
import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 0px ${PADDING.md};
`

export const TotalPage = styled.div`
  color: var(--main-color-text);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  opacity: 0.7;
`

export const StatusButton = styled(BaseButton)<{
  $color?: string
  $background?: string
}>`
  color: ${props => props?.$color};
  background: ${props => props?.$background};
  :hover {
    color: ${props => props?.$color};
  }
`

export const ActionButton = styled(BaseButton)`
  border: initial;
  box-shadow: initial;
`

export const ActionDropdown = styled(ActionsDropdown)`
  .ant-space-item-split {
    display: none;
  }
`