import { DashOutlined } from '@ant-design/icons'
import { BasePopover } from 'app/components/common/BasePopover'
import React, { ReactNode, useState } from 'react'
import { ActionsOverlay } from './ActionsOverlay'
export interface PropsOverlay {
  onClickUpdate?: () => void
  updateComponent?: ReactNode

  onClickDelete?: () => void
  deleteComponent?: ReactNode

  actionComponent?: ReactNode

  onHide?: () => void
}

export const ActionsDropdown: React.FC<PropsOverlay> = ({ ...props }) => {
  const [open, setOpen] = useState(false)

  const hide = () => {
    setOpen(false)
  }

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  return (
    <BasePopover
      placement="bottomLeft"
      trigger="click"
      content={<ActionsOverlay onHide={hide} {...props} />}
      onOpenChange={handleOpenChange}
      open={open}
    >
      <DashOutlined rev={undefined} rotate={90} />
    </BasePopover>
  )
}
