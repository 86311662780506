import styled from 'styled-components'
import { media } from 'parkway-web-common'
import { BaseLayout } from 'app/components/common/BaseLayout'

export const LayoutMaster = styled(BaseLayout)`
  height: 100vh;
`

export const LayoutMain = styled(BaseLayout)`
  @media only screen and ${media.xl} {
    margin-left: unset;
  }
`
