/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEqual } from 'lodash'
import { useUpdateCampaignContext } from '../../context'

export const useHook = () => {
  const {
    areaList,
    isLoadingArea,
    filterTreeNode,
    keywordSearchArea,
    setKeywordSearchArea,
    onChangeClinic,
    selectedClinic,
    campaignType,
    isApplyWithOtherCampaign,
    setIsApplyWithOtherCampaign,
    stackedWithCampaigns,
    campaignApplyCommon,
    onChangeCampaignApplyCommon,
    onChangeStackedWithCampaigns,
  } = useUpdateCampaignContext()
  
  const onDeleteStackedWithCampaign = (id?: string) => {
    const newStackedWithCampaigns =
      stackedWithCampaigns?.filter(item => !isEqual(item?._id, id)) ?? []
    onChangeStackedWithCampaigns?.(newStackedWithCampaigns)
  }

  return {
    areaList,
    isLoadingArea,
    filterTreeNode,
    keywordSearchArea,
    setKeywordSearchArea,
    onChangeClinic,
    selectedClinic,
    campaignType,
    isApplyWithOtherCampaign,
    setIsApplyWithOtherCampaign,
    stackedWithCampaigns,
    campaignApplyCommon,
    onChangeCampaignApplyCommon,
    onDeleteStackedWithCampaign,
  }
}
