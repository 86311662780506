import { IRefModal } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/components'
import {
    IDoctorTreatmentSchedule,
    ITreatmentInAppointmentSession,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { isEmpty } from 'lodash'
import { getRandomUuid } from 'parkway-web-common'
import { useMemo, useRef, useState } from 'react'

interface IProps {
  onSubmit?: (item: ITreatmentInAppointmentSession) => void
}

const initialData: ITreatmentInAppointmentSession = {
  materials: [],
  paymentInfo: {
    discountAmount: 0,
    paid: 0,
    totalAmount: 0,
    unitPrice: 0,
  },
}

export const useHook = ({ onSubmit }: IProps) => {
  const modalRef = useRef<IRefModal>({})

  const [treatment, setTreatment] =
    useState<ITreatmentInAppointmentSession>(initialData)

  const onChangeTreatment = (treatment?: string) => {
    setTreatment(prev => ({
      ...prev,
      name: treatment ?? '',
    }))
  }

  const onChangeDoctor = (doctor?: IDoctorTreatmentSchedule) => {
    setTreatment(prev => ({
      ...prev,
      doctor,
    }))
  }

  const onChangeNurses = (nurses?: IDoctorTreatmentSchedule[]) => {
    setTreatment(prev => ({
      ...prev,
      nurses,
    }))
  }

  const onDeleteNurses = () => {
    setTreatment(prev => ({
      ...prev,
      nurses: [],
    }))
  }

  const onDeleteDoctor = () => {
    setTreatment(prev => ({
      ...prev,
      doctor: undefined,
    }))
  }

  const _onSubmit = () => {
    onSubmit?.({
      ...treatment,
      id: getRandomUuid()
    })
    setTreatment(initialData)
    modalRef?.current?.hide?.()
  }

  const isDisableSubmit = useMemo(() => {
    if (isEmpty(treatment?.name)) return true

    if (!treatment?.nurses?.length) return true

    if (!treatment?.doctor) return true

    return false
  }, [treatment])

  return {
    doctor: treatment?.doctor,
    nurses: treatment?.nurses ?? [],
    treatment,
    onChangeTreatment,
    onChangeDoctor,
    onChangeNurses,
    onDeleteNurses,
    onDeleteDoctor,
    modalRef,
    _onSubmit,
    isDisableSubmit
  }
}
