import R from 'app/assets/R'
import { BaseChip } from 'app/components/common/BaseChip'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { initValueFormRoles } from '../../constant'
import { UpdatePermissionOfRole } from '../UpdatePermissionOfRole'
import { useModalHook } from './hook'
import * as S from './styles'
import { IPropsUpdateRole } from './type'
import { Option } from 'app/components/common/selects/BaseSelect'

export const UpdateRole = (props: IPropsUpdateRole) => {
  const {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    onChangePermission,
    permissions,
    onRemovePermission,
    modules,
    isDisableSubmit
  } = useModalHook(props)

  return (
    <BaseModalManagement
      titleKeyI18n={R.strings.update}
      isLoading={isLoading}
      typeButtonOpen="update"
      content={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValueFormRoles}
          form={form}
        >
          <S.FormItem
            name="name"
            label={t(R.strings.role_content_name_role)}
            rules={rules.name}
            required
          >
            <S.FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.role_content_name_role),
              })}
            />
          </S.FormItem>
          <S.FormItem
            name="description"
            label={t(R.strings.role_content_description_role)}
            rules={rules.description}
          >
            <S.FormInput
              placeholder={t(R.strings.role_content_enter_description_role)}
            />
          </S.FormItem>
          <S.FormItem
            label={t(R.strings.role_content_group_permission)}
            required
            rules={rules.module}
            name={'module'}
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.role_content_choose_group_permission)}
              allowClear
            >
              {modules?.map((item, index) => {
                return (
                  <Option value={item._id} key={index}>
                    {t(`${item?._id ?? ''}`.replace(/-/g, '_'))}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>
          <S.PermissionContainer direction="vertical" size={15}>
            <S.PermissionHead justify={'space-between'} align={'middle'}>
              <BaseCol>
                <S.PermissionHeadTitle>
                  {t(R.strings.role_content_permission)}
                </S.PermissionHeadTitle>
              </BaseCol>
              <BaseCol>
                <UpdatePermissionOfRole
                  onSubmit={onChangePermission}
                  permissions={permissions}
                />
              </BaseCol>
            </S.PermissionHead>
            <S.AreaShowPermission gutter={[16, 16]} align={'middle'}>
              {permissions?.length ? (
                permissions.map((per, index) => {
                  return (
                    <BaseCol key={index}>
                      <BaseChip
                        index={index}
                        name={per?.name}
                        onRemove={onRemovePermission}
                      />
                    </BaseCol>
                  )
                })
              ) : (
                <BaseChip
                  index={0}
                  name={t(R.strings.role_content_not_choose_permission)}
                />
              )}
            </S.AreaShowPermission>
          </S.PermissionContainer>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      confirmKeyI18n={R.strings.confirm}
      handleSubmit={form.submit}
      visible={visible}
      textOpenModalI18n={R.strings.update}
      isDisableSubmit={isDisableSubmit}
    />
  )
}
