/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestDetailJobTitleManagement,
  requestGetAllJobTitleManagement,
  requestUpdateJobTitleManagement,
} from 'app/api/jobtitle'
import { JobTitleMessageCode } from 'app/api/jobtitle/constant'
import {
  IJobTitle,
  IJobTitleManagement,
  IPayloadUpdateJobTitleManagement,
} from 'app/api/jobtitle/model/job-title-management'
import { requestGetAllJobTitleGroup } from 'app/api/jobtitlegroup'
import { IJobTitleGroup } from 'app/api/jobtitlegroup/model/job-title-management'
import { requestGetAllJobTitleLevelManagement } from 'app/api/jobtitlelevel'
import {
  IAllJobTitleLevel,
  IJobTitleLevel,
} from 'app/api/jobtitlelevel/model/job-title-level-management'
import {
  requestGetJobTitleRoleByJobTitleId,
  requestPostJobTitleRole,
  requestPutJobTitleRole,
} from 'app/api/roles'
import { RoleMessageCode } from 'app/api/roles/constant'
import { IJobTitleRole } from 'app/api/roles/models/job-title-role'
import { IRoleManagement } from 'app/api/roles/models/role'
import R from 'app/assets/R'
import { EYesNo } from 'app/common/enum'
import {
  convertArrayStatusFilterToArrayStatusNumber,
  convertStatusToFilterStatus
} from 'app/common/helpers'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IFormData,
  IFormRule,
  IPropsUpdateJobTitle,
  StatusJobTitleEnum,
} from './type'

export const useModalHook = (props: IPropsUpdateJobTitle) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [jobTitleLevels, setJobTitleLevels] = useState<IJobTitleLevel[]>([])
  const [jobTitles, setJobTitles] = useState<IJobTitle[]>([])
  const [jobTitleGroups, setJobTitleGroups] = useState<IJobTitleGroup[]>([])
  const [jobTitleRole, setJobTitleRole] = useState<IJobTitleRole>()
  const [roles, setRoles] = useState<IRoleManagement[]>([])
  const [description, setDescription] = useState<string | undefined>('')

  const getDetail = async () => {
    if (!props?.id) {
      return
    }
    try {
      const res: ResponseType<IJobTitleManagement> =
        await requestDetailJobTitleManagement(props?.id)

      if (
        isEqual(
          res?.msgcode,
          JobTitleMessageCode.JobTitleManagement.successGetData,
        )
      ) {
        const data = res?.data
        form.setFieldsValue({
          name: data?.name ?? '',
          code: data?.code ?? '',
          jobTitleLevelId: data?.jobTitleLevelId ?? '',
          jobTitleIdManagement: data?.jobTitleIdManagement ?? '',
          jobTitleGroupId: data?.jobTitleGroupId,
          hasTimekeeping: data?.hasTimekeeping ? EYesNo.yes : EYesNo.no,
          dayOffType: data?.dayOffType,
          staticDayOffInWeek: data?.staticDayOffInWeek ?? undefined,
          totalDynamicDayOffInWeek: data?.totalDynamicDayOffInWeek ?? undefined,
          _id: data._id,
          status: convertStatusToFilterStatus(data?.status),
          description: data?.description,
        })
        setDescription(data?.description)
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  const getJobTitleRole = async () => {
    try {
      const response: ResponseType<IJobTitleRole> =
        await requestGetJobTitleRoleByJobTitleId(props?.id)

      if (
        isEqual(response.msgcode, RoleMessageCode.RoleManagement.successGetData)
      ) {
        setJobTitleRole(response?.data)
        setRoles(response?.data?.roles ?? [])
      }
    } catch (error) {
      //  console.log(error);
    }
  }

  const getAllJobTitleLevel = async () => {
    try {
      const res: ResponseType<IAllJobTitleLevel> =
        await requestGetAllJobTitleLevelManagement()

      if (
        isEqual(
          res?.msgcode,
          JobTitleMessageCode.JobTitleManagement.successGetData,
        )
      ) {
        const data: IJobTitleLevel[] = res?.data?.rows || []
        setJobTitleLevels(data)
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  const getAllJobTitle = async () => {
    try {
      const res: ResponseType<IJobTitle[]> =
        await requestGetAllJobTitleManagement()
      if (
        isEqual(
          res?.msgcode,
          JobTitleMessageCode.JobTitleManagement.successGetData,
        )
      ) {
        const data: IJobTitle[] = res?.data || []
        setJobTitles(data)
      }
    } catch (error) {
      // console.log({ error })
    }
    return []
  }

  const getAllJobTitleGroup = async () => {
    try {
      const res: ResponseType<IJobTitleGroup[]> =
        await requestGetAllJobTitleGroup({})

      if (
        isEqual(
          res?.msgcode,
          JobTitleMessageCode.JobTitleManagement.successGetData,
        )
      ) {
        const data: IJobTitleLevel[] = res?.data || []
        setJobTitleGroups(data)
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  useEffect(() => {
    visible && getDetail()
    visible && getAllJobTitleLevel()
    visible && getAllJobTitle()
    visible && getAllJobTitleGroup()
    visible && getJobTitleRole()
  }, [props?.id, visible])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    // setIsLoading(true)
    console.log({ values })

    try {
      const body: IPayloadUpdateJobTitleManagement = {
        name: values?.name,
        description,
        code: values?.code,
        jobTitleIdManagement: values?.jobTitleIdManagement ?? null,
        jobTitleGroupId: values?.jobTitleGroupId,
        jobTitleLevelId: values?.jobTitleLevelId,
        _id: values?._id,
        hasTimekeeping: !!values?.hasTimekeeping,
        dayOffType: values?.dayOffType,
        staticDayOffInWeek: values?.staticDayOffInWeek ?? undefined,
        totalDynamicDayOffInWeek: values?.totalDynamicDayOffInWeek ?? undefined,
        status:
          convertArrayStatusFilterToArrayStatusNumber([
            values?.status,
          ] as any)?.[0] ?? StatusJobTitleEnum.active,
      }

      let msCodeRole = ''

      if (jobTitleRole?._id) {
        const responseUpdateJobTitleRole: ResponseType<string> =
          await requestPutJobTitleRole({
            roles: roles?.map(itm => itm?._id ?? '') ?? [],
            jobTitleRoleId: jobTitleRole?._id,
            description: jobTitleRole?.description ?? '',
          })
        msCodeRole = responseUpdateJobTitleRole?.msgcode
      } else {
        const responseCreateJobTitleRole: ResponseType<string> =
          await requestPostJobTitleRole({
            jobTitleId: `${props?.id}`,
            description: values?.name,
            roles: roles?.map(itm => itm?._id ?? '') ?? [],
          })
        msCodeRole = responseCreateJobTitleRole?.msgcode
      }

      const response: ResponseType<string> =
        await requestUpdateJobTitleManagement({ id: props?.id, body })

      if (
        isEqual(
          response?.msgcode,
          JobTitleMessageCode.JobTitleManagement.successPutData,
        ) &&
        isEqual(msCodeRole, RoleMessageCode.RoleManagement.successGetData)
      ) {
        setIsLoading(false)
        handleCancel()
        props?.fetchTable?.()
        notificationController.success({
          message: t(R.strings.update),
          description: response?.message,
        })
      } else {
        notificationController.error({
          message: t(R.strings.update),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      console.log({ error })

      setIsLoading(false)
    }
  }

  const rules: IFormRule = useMemo(() => {
    return {
      code: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.job_title_field_job_title_string_id),
          }),
        },
      ],
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.job_title_field_name),
          }),
        },
      ],
      description: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.job_title_level_field_job_title_level_id),
          }),
        },
      ],
      jobTitleIdManagement: [
        {
          required: false,
          message: t(R.strings.require_field, {
            field: t(R.strings.unit_field_job_title_management),
          }),
        },
      ],
      jobTitleGroupId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.job_title_content_group),
          }),
        },
      ],
      jobTitleLevelId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.job_title_level_content_id),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
      role: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.job_title_field_role),
          }),
        },
      ],
    }
  }, [t])

  const onChangeRole = (newRole: IRoleManagement[]) => {
    setRoles(newRole)
  }

  const onRemoveRole = (index: number) => {
    setRoles(prev => prev?.filter((_, idx) => idx !== index))
  }

  const onChangeDescription = (value?: string) => {
    setDescription(value)
  }

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    jobTitleLevels,
    jobTitles,
    jobTitleGroups,
    roles,
    onChangeRole,
    onRemoveRole,
    onChangeDescription,
    description,
  }
}
