import { FormInstance } from 'antd'
import { IWarehousePurchase } from 'app/api/inventory-new/model/management'
import { IItem } from 'app/api/item/model'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Dispatch, SetStateAction, createContext, useState } from 'react'

export interface IOrderItem extends IItem {
  quantity?: number
  price?: number
}
interface IWarehousePurchaseContext {
  orders?: IOrderItem[]
  setOrders?: Dispatch<SetStateAction<IItem[]>>
  form?: FormInstance
  discount?: number
  setDiscount?: Dispatch<SetStateAction<number>>
  warehousePurchaseDetail?: IWarehousePurchase
  setWarehousePurchaseDetail?: Dispatch<SetStateAction<IItem>>
}
export const WarehousePurchaseContext =
  createContext<IWarehousePurchaseContext>({ orders: [] })

export const WarehousePurchaseProvider = ({ children }) => {
  const [orders, setOrders] = useState<IItem[]>([])
  const [warehousePurchaseDetail, setWarehousePurchaseDetail] = useState<IItem>(
    {},
  )
  const [form] = BaseForm.useForm()
  const [discount, setDiscount] = useState(0)

  return (
    <WarehousePurchaseContext.Provider
      value={{
        orders,
        setOrders,
        form,
        discount,
        setDiscount,
        warehousePurchaseDetail,
        setWarehousePurchaseDetail,
      }}
    >
      {children}
    </WarehousePurchaseContext.Provider>
  )
}
