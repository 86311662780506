import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import { useModalHook } from './hook'
import * as S from './styles'
import { IPropsUpdateDegree, initValues } from './type'
import { StatusTableEnum } from 'app/common/enum'

export const Update = (props: IPropsUpdateDegree) => {
  const {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    industries,
  } = useModalHook(props)

  return (
    <BaseModalManagement
      isLoading={isLoading}
      typeButtonOpen="update"
      titleKeyI18n={R.strings.update}
      content={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <S.FormItem
            name="name"
            label={t(R.strings.degree_content_name_create)}
            rules={rules.name}
            required
          >
            <S.FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.degree_content_name_create),
              })}
            />
          </S.FormItem>
          <S.FormItem
            label={t(R.strings.degree_content_industry)}
            name="industry"
            rules={rules.industry}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.degree_content_select_industry)}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {industries?.map((industry, index) => {
                return (
                  <Option value={industry._id} key={index}>
                    {industry.name}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>
          <S.FormItem
            label={t(R.strings.status)}
            name="status"
            rules={rules.status}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.please_select_status)}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {Object.values(StatusTableEnum)?.map((item, index) => {
                return (
                  <Option value={item} key={index}>
                    {t(item)}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.update}
      confirmKeyI18n={R.strings.update}
      handleSubmit={form.submit}
      visible={visible}
    />
  )
}
