import * as S from 'app/components/tables/BaseTableManagement/styles'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

import {
  BuyBox,
  BuyTitle,
  CustomCheckbox,
  CustomDatePicker,
  CustomSelectSearch,
  FormItem,
  FormItemCheckbox,
  FormItemUser,
  RowLine,
} from './styles'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { PurchaseOrderStatusEnum, StatusTableEnum } from 'app/common/enum'
import { useHook } from './hook'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { Option } from 'app/components/common/selects/BaseSelect'
import { useContext, useEffect, useState } from 'react'
import { ProfileContext } from 'app/context/ProfileContext'
import { IUser } from 'app/model/user.model'
import { Divider } from 'antd'
import { useAppSelector } from 'app/redux/hooks'
import { PHONE_REGEX } from 'parkway-web-common'
import { SelectInputSearch } from 'app/containers/InventoryManagement/WarehousePurchase/components/FilterManagementTable/styles'
import {
  DropdownRender,
  SearchOutlinedStyled,
  SearchWrapper,
} from 'app/components/common/selects/SelectSearch/styles'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { ISupplier, IWarehouse } from 'app/api/inventory-new/model/management'
import {
  requestGetSupplierManagement,
  requestGetWarehouseManagement,
} from 'app/api/inventory-new'
import { get } from 'lodash'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import {
  PurchaseOrderFromStatus,
  PurchaseOrderStatusName,
} from 'app/common/constants'
import { WarehousePurchaseContext } from '../../../context'

export type StatusWarehousePurchaseType = keyof typeof StatusTableEnum

const BuyInfo = () => {
  const { t } = useTranslation()
  const { rules } = useHook()
  const [searchUser, setSearchUser] = useState('')
  const { fetchData: fetchUsers, profiles: users } = useContext(ProfileContext)
  const profile = useAppSelector(state => state.profileSlice)?.profile
  const { warehousePurchaseDetail, form } = useContext(WarehousePurchaseContext)
  const [warehouses, setWarehouses] = useState<IWarehouse[]>([])
  const [warehouseKeyword, setWarehouseKeyword] = useState('')
  const [suppliers, setSuppliers] = useState<ISupplier[]>([])
  const [supplierKeyword, setSupplierKeyword] = useState('')

  useEffect(() => {
    const timer = setTimeout(async () => {
      const response = await requestGetWarehouseManagement({
        keyword: warehouseKeyword,
      })
      const data: IWarehouse[] = get(response, 'data', [])
      setWarehouses(data)
    }, 500)
    return () => clearTimeout(timer)
  }, [warehouseKeyword])

  useEffect(() => {
    const timer = setTimeout(async () => {
      const response = await requestGetSupplierManagement({
        keyword: supplierKeyword,
      })
      const data: ISupplier[] = get(response, 'data', [])
      setSuppliers(data)
    }, 500)
    return () => clearTimeout(timer)
  }, [supplierKeyword])

  useEffect(() => {
    fetchUsers?.({
      page: 0,
      pageSize: 20,
      sort: '-1',
      keyword: searchUser,
    })
  }, [searchUser])

  const usersWithPersonProfiles = users.concat(profile)

  const handleSelectSupplier = supplierId => {
    const supplier = suppliers?.find(item => item?._id === supplierId)
    form?.setFieldsValue({
      bankAccount: supplier?.bankAccount,
      bankName: supplier?.bankName,
      contactName: '',
      supplierEmail: '',
      supplierPhone: supplier?.phone,
    })
  }

  useEffect(() => {
    const supplier = warehousePurchaseDetail?.supplier
    if (supplier) {
      form?.setFieldsValue({
        bankAccount: supplier?.bankAccount,
        bankName: supplier?.bankName,
        contactName: '',
        supplierEmail: '',
        supplierPhone: supplier?.phone,
      })
    }
  }, [warehousePurchaseDetail])

  const isDisabled = !!(
    warehousePurchaseDetail?._id &&
    PurchaseOrderStatusEnum.DRAFT !== Number(warehousePurchaseDetail?.status)
  )
  return (
    <BuyBox>
      <BuyTitle>{t(R.strings.buy_info)}</BuyTitle>
      <BaseForm
        layout="vertical"
        requiredMark="optional"
        initialValues={{
          purchaserId: profile?._id,
          isSentToSupplier: true,
        }}
        form={form}
      >
        <BaseRow gutter={20} align={'top'} justify={'space-between'}>
          <BaseCol xl={12}>
            <FormItemUser>
              <S.FormItem
                name="purchaserId"
                label={t(R.strings.purchaser)}
                rules={rules.purchaserId}
                required
                shouldUpdate
              >
                <CustomSelectSearch
                  allowClear
                  onSearch={keyword => setSearchUser(keyword)}
                  options={usersWithPersonProfiles.map((user: IUser) => ({
                    value: String(user?._id),
                    name: user?.name,
                    avatar: user?.avatar,
                    description:
                      user?.jobTitleName?.replaceAll(',', ' &') || '',
                  }))}
                  optionLabelProp=""
                  disabled={isDisabled}
                />
              </S.FormItem>
            </FormItemUser>
          </BaseCol>
          <BaseCol xl={12}>
            <FormItemUser>
              <S.FormItem
                name="receiverId"
                label={t(R.strings.receiver)}
                rules={rules.receiverId}
                required
                shouldUpdate
              >
                <CustomSelectSearch
                  allowClear
                  onSearch={keyword => setSearchUser(keyword)}
                  options={users.map((user: IUser) => ({
                    value: String(user?._id),
                    name: user?.name,
                    avatar: user?.avatar,
                    description:
                      user?.jobTitleName?.replaceAll(',', ' &') || '',
                  }))}
                  optionLabelProp=""
                  disabled={isDisabled}
                />
              </S.FormItem>
            </FormItemUser>
          </BaseCol>
        </BaseRow>
        <RowLine />
        <BaseRow gutter={20} align={'top'} justify={'space-between'}>
          <BaseCol xl={12}>
            <BaseRow gutter={20} align={'top'} justify={'space-between'}>
              <BaseCol xl={12}>
                <S.FormItem
                  name="phone"
                  shouldUpdate
                  label={t(R.strings.phone)}
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.phone_label_required),
                    },
                    {
                      pattern: PHONE_REGEX,
                      message: t(R.strings.regex_phone),
                    },
                  ]}
                  required
                >
                  <S.FormInput
                    placeholder={t(R.strings.enter_field, {
                      field: t(R.strings.phone),
                    })}
                    disabled={isDisabled}
                  />
                </S.FormItem>
              </BaseCol>
              <BaseCol xl={12}>
                <S.FormItem
                  name="warehouseReceiveId"
                  shouldUpdate
                  label={t(R.strings.warehouse_receive)}
                  rules={rules.warehouseReceiveId}
                  required
                >
                  <SelectInputSearch
                    placeholder={t(R.strings.select)}
                    // onChange={value =>
                    //   setFilter(prev => ({
                    //     ...prev,
                    //     warehouseId: value,
                    //   }))
                    // }
                    filterOption={false}
                    dropdownRender={menu => (
                      <DropdownRender>
                        <SearchWrapper>
                          <SearchOutlinedStyled
                            className="search__icon"
                            rev=""
                            size={20}
                          />
                          <BaseInput
                            className="search__input"
                            placeholder={t(R.strings.search)}
                            onChange={e =>
                              setWarehouseKeyword(e?.target?.value)
                            }
                            allowClear
                          />
                        </SearchWrapper>
                        {menu}
                      </DropdownRender>
                    )}
                    allowClear
                    disabled={isDisabled}
                  >
                    {warehouses?.map((warehouse, index) => {
                      return (
                        <Option value={warehouse?._id} key={index}>
                          {warehouse?.name}
                        </Option>
                      )
                    })}
                  </SelectInputSearch>
                </S.FormItem>
              </BaseCol>
            </BaseRow>
          </BaseCol>
          <BaseCol xl={12}>
            <S.FormItem
              name="address"
              shouldUpdate
              label={t(R.strings.address)}
              rules={rules.address}
              required
            >
              <S.FormInput
                placeholder={t(R.strings.enter_field, {
                  field: t(R.strings.address),
                })}
                disabled={isDisabled}
              />
            </S.FormItem>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={20} align={'top'} justify={'space-between'}>
          <BaseCol xl={warehousePurchaseDetail?._id ? 12 : 24}>
            <BaseRow gutter={20} align={'top'} justify={'space-between'}>
              <BaseCol xl={12}>
                <S.FormItem
                  name="estimateDeliveryDate"
                  shouldUpdate
                  label={t(R.strings.estimate_delivery_date)}
                  rules={rules.estimateDeliveryDate}
                  required
                >
                  <CustomDatePicker
                    placeholder={t(R.strings.choose_date)}
                    disabled={isDisabled}
                  />
                </S.FormItem>
              </BaseCol>
              <BaseCol xl={12}>
                <S.FormItem
                  name="paymentDueDate"
                  shouldUpdate
                  label={t(R.strings.payment_due_date)}
                  rules={rules.paymentDueDate}
                  required
                >
                  <CustomDatePicker
                    placeholder={t(R.strings.choose_date)}
                    disabled={isDisabled}
                  />
                </S.FormItem>
              </BaseCol>
            </BaseRow>
          </BaseCol>
          {!!warehousePurchaseDetail?._id && (
            <BaseCol xl={12}>
              <BaseRow gutter={20} align={'top'} justify={'space-between'}>
                <BaseCol xl={24}>
                  <S.FormItem
                    name="status"
                    shouldUpdate
                    label={t(R.strings.status)}
                    rules={rules.status}
                    required
                  >
                    <S.SelectBaseStyled
                      placeholder={t(R.strings.please_select_status)}
                      suffixIcon={<SvgTableCloseIcon />}
                      defaultValue={Number(warehousePurchaseDetail?.status)}
                      onChange={value => form?.setFieldValue('status', value)}
                      disabled={
                        PurchaseOrderStatusEnum.SENT_TO_SUPPLIER ===
                        Number(warehousePurchaseDetail?.status)
                      }
                    >
                      <Option
                        value={Number(warehousePurchaseDetail?.status)}
                        key={Number(warehousePurchaseDetail?.status)}
                      >
                        {t(
                          PurchaseOrderStatusName[
                            Number(warehousePurchaseDetail?.status)
                          ],
                        )}
                      </Option>
                      {PurchaseOrderFromStatus[
                        Number(warehousePurchaseDetail?.status)
                      ]?.map(item => {
                        return (
                          <Option value={Number(item)} key={Number(item)}>
                            {t(PurchaseOrderStatusName[Number(item)])}
                          </Option>
                        )
                      })}
                    </S.SelectBaseStyled>
                  </S.FormItem>
                </BaseCol>
              </BaseRow>
            </BaseCol>
          )}
        </BaseRow>

        <S.FormItem name="note" label={t(R.strings.note)} shouldUpdate>
          <S.FormInput
            placeholder={t(R.strings.enter_field, {
              field: t(R.strings.note),
            })}
            disabled={isDisabled}
          />
        </S.FormItem>
        <Divider />
        <BaseRow gutter={20} align={'top'} justify={'space-between'}>
          <BaseCol xl={12}>
            <S.FormItem
              name="supplierId"
              shouldUpdate
              label={t(R.strings.supplier)}
              rules={rules.supplierId}
              required
            >
              <SelectInputSearch
                placeholder={t(R.strings.select)}
                onChange={value => handleSelectSupplier(value)}
                filterOption={false}
                dropdownRender={menu => (
                  <DropdownRender>
                    <SearchWrapper>
                      <SearchOutlinedStyled
                        className="search__icon"
                        rev=""
                        size={20}
                      />
                      <BaseInput
                        className="search__input"
                        placeholder={t(R.strings.search)}
                        onChange={e => setSupplierKeyword(e?.target?.value)}
                        allowClear
                      />
                    </SearchWrapper>
                    {menu}
                  </DropdownRender>
                )}
                allowClear
                disabled={isDisabled}
              >
                {suppliers?.map((supplier, index) => {
                  return (
                    <Option value={supplier?._id} key={index}>
                      {supplier?.name}
                    </Option>
                  )
                })}
              </SelectInputSearch>
            </S.FormItem>
          </BaseCol>
          <BaseCol xl={12}>
            <BaseRow gutter={20} align={'top'} justify={'space-between'}>
              <BaseCol xl={12}>
                <FormItem name="bankAccount" label={t(R.strings.bank_account)}>
                  <S.FormInput disabled />
                </FormItem>
              </BaseCol>
              <BaseCol xl={12}>
                <FormItem name="bankName" label={t(R.strings.bank_name)}>
                  <S.FormInput disabled />
                </FormItem>
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={20} align={'top'} justify={'space-between'}>
          <BaseCol xl={12}>
            <FormItem name="contactName" label={t(R.strings.contact_name)}>
              <S.FormInput disabled />
            </FormItem>
          </BaseCol>
          <BaseCol xl={12}>
            <BaseRow gutter={20} align={'top'} justify={'space-between'}>
              <BaseCol xl={12}>
                <FormItem name="supplierEmail" label={t(R.strings.email)}>
                  <S.FormInput disabled />
                </FormItem>
              </BaseCol>
              <BaseCol xl={12}>
                <FormItem name="supplierPhone" label={t(R.strings.phone)}>
                  <S.FormInput disabled />
                </FormItem>
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>
        <FormItemCheckbox name="isSentToSupplier">
          <CustomCheckbox defaultChecked={true}>
            {t(R.strings.sent_to_supplier)}
          </CustomCheckbox>
        </FormItemCheckbox>
      </BaseForm>
    </BuyBox>
  )
}

export default BuyInfo
