import { SvgSelectBlockIcon, SvgSelectListIcon } from 'app/assets/svg-assets'
import { BaseRow } from 'app/components/common/BaseRow'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  LAYOUT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: ${PADDING.md};
`

export const HeadPageWrapper = styled(BaseRow)`
  padding-bottom: ${LAYOUT.desktop.headerPadding};
`

export const HeadingTitlePage = styled.p`
  font-size: ${FONT_SIZE.xxl};
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.bold};
`

export const GroupWrapper = styled.div`
  padding: ${PADDING.xs} 0px;
`

export const GroupHeadTitle = styled.span`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
  color: var(--primary-color);
`
export const GroupHeadLine = styled.div`
  display: flex;
  flex: 1;
  height: 1px;
  background-color: var(--checkbox-border-color); // need change
  margin-left: ${PADDING.md};
`

export const SelectionWrapper = styled(BaseRow)`
  background-color: var(--white);
  border-radius: ${BORDER_RADIUS};
`

export const ButtonSelection = styled.div`
  background-color: var(--white);
  border: none;
  cursor: pointer;
  padding: ${PADDING.xxxxs};
  transition: cursor 0.3s ease;
  &:hover {
    cursor: pointer;
  }
`

export const BlockSelectIcon = styled(SvgSelectBlockIcon)<{
  $isBlock?: boolean
}>`
  & > g > path {
    stroke: ${props => (props?.$isBlock ? 'var(--collapse-background-color)' : 'var(--primary-color)')};
  }
`

export const ListSelectIcon = styled(SvgSelectListIcon)<{
  $isList?: boolean
}>`
  & > g > path {
    stroke: ${props => (props?.$isList ? 'var(--collapse-background-color)' : 'var(--primary-color)')};
  }
`

export const CenterLineSelection = styled.div`
  width: 1px;
  height: 10px;
  background-color: #d9d9d9; //need change
`

// block item
export const BlockWrapper = styled(BaseRow)`
  margin-top: ${PADDING.md};
`

export const AnnotateTitle = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--main-color-text);
`
