import { getArray } from 'app/components/tables/common-table/constant'
import {
  IDentalHealthHistory,
  OralHygieneToolsYouUsuallyUseEnum,
} from '../type/dental-health-history'
import { DateUtil } from 'parkway-web-common'
import _ from 'lodash'
import R from 'app/assets/R'

export const DentalHealthHistory: IDentalHealthHistory = {
  createAt: DateUtil.formatDDMMYYY(new Date()),
  createBy: {
    name: 'Phạm Văn Khánh',
    jobTitle: 'Bác sĩ máy tính',
  },
  address: '228 Trần Hưng Đạo, Quận 1, HCM',
  do_you_have_any_areas_where_food_tends_to_get_stuck_or_impacted: !!_.random(1),
  do_you_have_any_areas_that_need_to_be_re_filled_or_do_you_have_any_old_fillings_that_need_to_be_checked:
    !!_.random(1),
  do_your_gums_bleed_when_you_brush_or_floss: !!_.random(1),
  have_you_ever_had_gingivitis_or_periodontitis: !!_.random(1),
  when_was_your_last_dental_cleaning: !!_.random(1),
  do_you_experience_any_unpleasant_taste_in_your_mouth_or_unpleasant_bad_breath:
    !!_.random(1),
  how_many_times_a_day_do_you_usually_brush_your_teeth: !!_.random(1),
  oral_hygiene_tools_you_usually_use: [
    OralHygieneToolsYouUsuallyUseEnum.DentalFloss,
    OralHygieneToolsYouUsuallyUseEnum.ElectricToothbrush,
  ],
  are_you_currently_using_any_prescription_toothpaste_or_mouthwash: !!_.random(1),
  would_you_like_a_consultation_for_teeth_whitening: !!_.random(1),
  are_there_any_spots_or_stains_on_your_teeth_that_you_are_not_happy_with:
    !!_.random(1),
  have_you_ever_had_orthodontic_treatment_braces: !!_.random(1),
  would_you_like_to_have_straighter_teeth: !!_.random(1),
  do_you_often_bite_your_cheek_when_chewing: !!_.random(1),
  do_you_often_get_mouth_ulcers: !!_.random(1),
  are_you_currently_using_any_special_dental_appliances: !!_.random(1),
  do_you_snore_or_have_jaw_pain_especially_in_the_morning: !!_.random(1),
  do_you_wear_dentures_or_partial_dentures_at_home: !!_.random(1),
}

export const ListDentalHealthHistory = getArray(4).map(
  () => DentalHealthHistory,
)


export const ListKey = [
  R.strings.do_you_have_any_areas_where_food_tends_to_get_stuck_or_impacted,
  R.strings
    .do_you_have_any_areas_that_need_to_be_re_filled_or_do_you_have_any_old_fillings_that_need_to_be_checked,
  R.strings.do_your_gums_bleed_when_you_brush_or_floss,
  R.strings.have_you_ever_had_gingivitis_or_periodontitis,
  R.strings.when_was_your_last_dental_cleaning,
  R.strings
    .do_you_experience_any_unpleasant_taste_in_your_mouth_or_unpleasant_bad_breath,
  R.strings.how_many_times_a_day_do_you_usually_brush_your_teeth,
  R.strings.oral_hygiene_tools_you_usually_use,
  R.strings.are_you_currently_using_any_prescription_toothpaste_or_mouthwash,
  R.strings.would_you_like_a_consultation_for_teeth_whitening,
  R.strings
    .are_there_any_spots_or_stains_on_your_teeth_that_you_are_not_happy_with,
  R.strings.have_you_ever_had_orthodontic_treatment_braces,
  R.strings.would_you_like_to_have_straighter_teeth,
  R.strings.do_you_often_bite_your_cheek_when_chewing,
  R.strings.do_you_often_get_mouth_ulcers,
  R.strings.are_you_currently_using_any_special_dental_appliances,
  R.strings.do_you_snore_or_have_jaw_pain_especially_in_the_morning,
  R.strings.do_you_wear_dentures_or_partial_dentures_at_home,
]

export const ListOralHygieneToolsYouUsuallyUseEnum = Object.keys(
  OralHygieneToolsYouUsuallyUseEnum,
)
