import { useCallback } from 'react'
import { useAdditionalServiceCampaignContext } from '../../context'
import { CampaignTypeEnum } from 'app/api/marketing/discount-campaign/model'
import {
  BundleTypeLayout,
  BuyOneGetOneTypeLayout,
  TotalBillTypeLayout,
  TreatmentTypeLayout,
} from './layouts'

export const ContentLayout = () => {
  const { campaignType } = useAdditionalServiceCampaignContext()

  const renderContent = useCallback(() => {
    switch (campaignType) {
      case CampaignTypeEnum.Treatments:
        return <TreatmentTypeLayout />
      case CampaignTypeEnum.Voucher:
        return null
      case CampaignTypeEnum.BuyOneGetOne:
        return <BuyOneGetOneTypeLayout />
      case CampaignTypeEnum.Bundle:
        return <BundleTypeLayout />
      case CampaignTypeEnum.TotalBill:
        return <TotalBillTypeLayout />
      default:
        return null
    }
  }, [campaignType])()

  return <>{renderContent}</>
}
