import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useRef, useState } from 'react'
import { ProfilePatientTypeEnum } from '../../enum'
import { IFormEmergencyContact } from '../../type'
import { _DEV_ } from 'app/constant'
import { IRefModal } from '../../../../components'

export const useHook = () => {
  const [form] = BaseForm.useForm<IFormEmergencyContact>()
  const ref = useRef<IRefModal>({})

  const [profilePatientType, setProfilePatientType] =
    useState<ProfilePatientTypeEnum>(ProfilePatientTypeEnum.AlreadyAPatient)

  const handleSubmit = async (values: IFormEmergencyContact) => {
    _DEV_ && console.log({ values })
    ref?.current?.hide?.()
  }

  return {
    form,
    profilePatientType,
    setProfilePatientType,
    handleSubmit,
    ref
  }
}
