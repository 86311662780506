import { EditOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { ContentModalAppointmentSession, ModalComponent } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/components'
import { useTranslation } from 'react-i18next'
import { useHook } from './hook'

export const UpdateAppointmentSessionModal = () => {
  const { t } = useTranslation()

  const {form} = useHook() 

  return (
    <ModalComponent
      iconOpenModal={
        <BaseCol>
          <EditOutlined rev={undefined} />
        </BaseCol>
      }
      titleOpenModal={t(R.strings.update)}
      titleModal={t(R.strings.update_appointment_session)}
      widthModal={1100}
      isShowLineBottom={false}
      isShowLineTop={false}
      paddingContent={`0px`}
      renderContent={<ContentModalAppointmentSession form={form} />}
    />
  )
}
