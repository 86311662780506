import {
  IParamsGetBookingManagement,
  IPayloadAssignMultiple,
  IPayloadCreateBookingManagement,
  IPayloadUpdateBookingManagement,
} from './model/management'
import { BookingEndPoint } from './constant'
import { ApiClientCRM } from '../../service/api-service'

export const requestGetBookingManagement = (
  params: IParamsGetBookingManagement,
) =>
  ApiClientCRM.Get({
    url: BookingEndPoint.BookingManagement.request,
    params: { params },
  })

export const requestCreateBookingManagement = (
  body: IPayloadCreateBookingManagement,
) =>
  ApiClientCRM.Post({
    url: BookingEndPoint.BookingManagement.request,
    body,
  })

export const requestUpdateBookingManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateBookingManagement
}) =>
  ApiClientCRM.Put({
    url: `${BookingEndPoint.BookingManagement.request}/${id}`,
    body,
  })

export const requestDetailBookingManagement = (id?: string | number) =>
  ApiClientCRM.Get({
    url: `${BookingEndPoint.BookingManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteBookingManagement = (id?: string | number) =>
  ApiClientCRM.Delete({
    url: `${BookingEndPoint.BookingManagement.request}/${id}`,
    body: {},
  })

export const requestGetAllBookingManagement = () =>
  ApiClientCRM.Get({
    url: `${BookingEndPoint.BookingManagement.request}/get-all`,
    params: {},
  })

export const requestUpdateCallCenterBookingManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateBookingManagement
}) =>
  ApiClientCRM.Put({
    url: `${BookingEndPoint.BookingManagement.request}/call-center/${id}`,
    body,
  })

export const requestAssignMultipleCallCenterBookingManagement = ({
  body,
}: {
  body?: IPayloadAssignMultiple
}) =>
  ApiClientCRM.Post({
    url: `${BookingEndPoint.BookingManagement.request}/assign-multiple`,
    body,
  })
