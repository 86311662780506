import { InboxOutlined } from '@ant-design/icons'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseButtonsForm } from 'app/components/common/forms/BaseButtonsForm'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseRow)`
  padding: ${PADDING.md} 0px;
`

export const HeadingTitlePage = styled.p`
  font-size: ${FONT_SIZE.xxl};
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.bold};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

export const ButtonAction = styled(BaseButton)<{
  backgroundColor?: string
  color?: string
}>`
  background-color: ${props =>
    props?.backgroundColor ?? 'var(--background-color)'};
  color: ${props => props?.color ?? 'var(--primary-color)'};
`

export const DraggerInput = styled(BaseButtonsForm.Item)``

export const DraggerWrapper = styled.div`
  padding: ${PADDING.xxl} 0px;
  & svg {
    fill: var(--gray);
  }
`

export const IconMailBox = styled(InboxOutlined)`
  & svg {
    width: ${FONT_SIZE.xxxl};
    height: ${FONT_SIZE.xxxl};
  }
`

export const FileNameDisplayWrapper = styled(BaseRow)`
  color: var(--primary-color);
  padding-top: ${PADDING.xxs};
  & svg {
    fill: red;
  }
`
