import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { IFormData, IFormRule } from './type'
import { useGetAreaList } from 'app/react-query/hook/location'
import { usePagination } from 'app/hook'
import { IUnit } from 'app/model/unit.model'
import { StatusEnum } from 'app/common/enum'

export const useModalHook = () => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormData>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [area, setArea] = useState<string>()
  const [clinic, setClinic] = useState<string>()
  const navigate = useNavigate()

  const { data: areaBeforeCustom, isLoading: loadingArea } = useGetAreaList({
    status: StatusEnum.ACTIVE,
    typeid: 'area',
  })

  const { flattenDataList } = usePagination()

  const areas = useMemo(() => {
    return flattenDataList(areaBeforeCustom) as IUnit[]
  }, [areaBeforeCustom])

  const clinics = useMemo(() => {
    const itemArea = areas?.find(itm => itm?._id === area)
    return itemArea?.childs ?? []
  }, [areas, area])

  const goBack = () => {
    navigate(-1)
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)
    console.log({ values })

    // try {
    //   const payload: IPayloadCreateItemCategoryManagement = {
    //     name: values.name,
    //     // parentId: parentId ?? undefined,
    //     status: first(
    //       convertStatusFilterToStatus([values.status] as StatusTableType[]),
    //     ),
    //   }
    //   const response: ResponseType<string> =
    //     await requestCreateItemCategoryManagement(payload)

    //   if (
    //     isEqual(
    //       response?.msgcode,
    //       ItemCategoryMessageCode.ItemCategoryManagement.successPostData,
    //     )
    //   ) {
    //     setIsLoading(false)
    //     goBack()
    //     // fetchTable?.()
    //   } else {
    //     notificationController.error({
    //       message: t(R.strings.item_category_content_add),
    //       description: response?.message,
    //     })
    //   }
    // // eslint-disable-next-line
    // } catch (error: any) {
    //   notificationController.error({
    //     message: t(R.strings.item_category_content_add),
    //     description: error?.message,
    //   })
    //   setIsLoading(false)
    // }
  }

  const onChangeArea = (value: string) => {
    setArea(value)
    setClinic(undefined)
    form?.setFieldsValue({
      clinic: undefined,
    })
  }

  const onChangeClinic = (value: string) => {
    setClinic(value)
  }

  const rules: IFormRule = useMemo(() => {
    return {
      first_name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.first_name),
          }),
        },
      ],
      last_name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.last_name),
          }),
        },
      ],
      gender: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.gender),
          }),
        },
      ],
      birthday: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.birthday),
          }),
        },
      ],
      msbn: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.msbn),
          }),
        },
      ],
      phone: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.phone),
          }),
        },
      ],
      email: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.email),
          }),
        },
      ],
      province: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.province),
          }),
        },
      ],
      district: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.district),
          }),
        },
      ],
      ward: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.ward),
          }),
        },
      ],
      address: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.address),
          }),
        },
      ],
      area: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.area),
          }),
        },
      ],
      clinic: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.clinic),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    t,
    handleSubmit,
    rules,
    isLoading,
    goBack,
    loadingArea,
    areas,
    area,
    onChangeArea,
    onChangeClinic,
    clinic,
    clinics,
  }
}
