import { requestGetOrganizationalChart } from 'app/api/company/organizational-chart'
import {
  IOrganizationalChart,
  ResponseOrganizationalChartType,
} from 'app/api/company/organizational-chart/model'
import R from 'app/assets/R'
import {
  SvgDownArrowIcon,
  SvgLeadingIconIcon,
  SvgOrganizationalIcon,
} from 'app/assets/svg-assets'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS } from 'parkway-web-common'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ItemCollapseLayout } from './components'

export const ListLayout = () => {
  const [data, setData] = useState<IOrganizationalChart[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const getData = async () => {
    try {
      setLoading(true)

      const res: ResponseOrganizationalChartType =
        await requestGetOrganizationalChart({})

      res?.data && setData(res.data)
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <CollapseWrapper
      items={[
        {
          key: 1,
          label: (
            <BaseAvatarProfile
              name="Nha Khoa Parkway"
              jobTitle="Công ty"
              src={R.images.ic_logo_parkway_collapsed}
            />
          ),
          extra: (
            <BaseRow align={'middle'} gutter={8}>
              <SvgOrganizationalIcon />
              <BaseCol>
                <BaseText
                  children={data?.length ?? 0}
                  fontWeight="semibold"
                  opacity="0.7"
                  fontSize="xs"
                />
              </BaseCol>
            </BaseRow>
          ),
          children: (
            <BaseSpace size={16}>
              {data?.map((item, indx) => {
                return (
                  <ItemCollapseLayout
                    key={indx}
                    keyIndex={indx}
                    total={0}
                    icon={<SvgLeadingIconIcon />}
                    item={item}
                    isShowIconExpanse={false}
                  />
                )
              })}
              {loading && <Loading isAbsolute size="small" />}
            </BaseSpace>
          ),
        },
      ]}
      expandIcon={({ isActive }) => (
        <SvgDownArrowIconWrapper $isActive={isActive} />
      )}
      defaultActiveKey={1}
    />
  )
}

const CollapseWrapper = styled(BaseCollapse)`
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  background-color: transparent !important;

  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-content {
    border-top: 1px solid ${convertedVariables.neutralBlack9Color};
    background-color: transparent !important;
  }

  .ant-collapse-header {
    background-color: transparent !important;
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
    padding: 0px !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
`

const SvgDownArrowIconWrapper = styled(SvgDownArrowIcon)<{
  $isActive?: boolean
}>`
  transform: rotate(${props => (props?.$isActive ? 0 : -180)}deg);
  transition: 0.5s ease;
`
