import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { formatMoney } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  totalPayment: number
  excessMoney: number
  totalPaymentMade: number
  onChangeCustomerPayment?: (value: number) => void
  customerPayment?: number
}

export const DetailPaymentComponent = ({
  excessMoney,
  totalPayment,
  totalPaymentMade,
  onChangeCustomerPayment,
  customerPayment,
}: IProps) => {
  const { t } = useTranslation()
  return (
    <BaseSpace>
      <BaseText fontSize="xs" fontWeight={'semibold'}>
        {t(R.strings.detail_payment)}
      </BaseText>

      <BaseRow gutter={[10, 10]} align={'middle'} justify={'space-between'}>
        <BaseCol>
          <BaseText fontSize="xs" opacity="0.7">
            {t(R.strings.total_payment_required)}
          </BaseText>
        </BaseCol>
        <BaseCol>
          <BaseText fontSize="xs" fontWeight="medium">
            {formatMoney(totalPayment)}
          </BaseText>
        </BaseCol>
      </BaseRow>

      <BaseRow gutter={[10, 10]} align={'middle'} justify={'space-between'}>
        <BaseCol>
          <BaseText fontSize="xs" opacity="0.7">
            {t(R.strings.amount_paid_by_customer)}
          </BaseText>
        </BaseCol>
        <BaseCol>
          <BaseFormItem hiddenLabel>
            <BaseInputNumber
              placeholder={t(R.strings.enter_amount_paid_by_customer)}
              onChange={value => onChangeCustomerPayment?.(value as number)}
              value={customerPayment}
              min={0}
            />
          </BaseFormItem>
        </BaseCol>
      </BaseRow>

      <BaseRow gutter={[10, 10]} align={'middle'} justify={'space-between'}>
        <BaseCol>
          <BaseText fontSize="xs" opacity="0.7">
            {t(R.strings.excess_money)}
          </BaseText>
        </BaseCol>
        <BaseCol>
          <BaseText fontSize="xs" fontWeight="medium">
            {formatMoney(excessMoney)}
          </BaseText>
        </BaseCol>
      </BaseRow>

      <BaseRow gutter={[10, 10]} align={'middle'} justify={'space-between'}>
        <BaseCol>
          <BaseText fontSize="xs" opacity="0.7">
            {t(R.strings.total_payment_made)}
          </BaseText>
        </BaseCol>
        <BaseCol>
          <BaseText fontSize="xs" fontWeight="medium">
            {formatMoney(totalPaymentMade)}
          </BaseText>
        </BaseCol>
      </BaseRow>
    </BaseSpace>
  )
}

export const BaseInputNumber = styled(InputNumber)`
  width: 100%;
`
export const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
  min-width: 300px;

  .ant-form-item-control-input {
    min-height: unset !important;
  }
`
