import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import SurveyReportContainer from 'app/containers/CustomerCare/SurveyReport'

const SurveyReportPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.survey_report)}</PageTitle>
      <SurveyReportContainer />
    </>
  )
}
const SurveyReportPage = memo(SurveyReportPageCpn, isEqual)

export default SurveyReportPage
