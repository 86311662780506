import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { ListOralHygieneToolsYouUsuallyUseEnum } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/constant/dental-health-history'
import { OralHygieneToolsYouUsuallyUseEnum } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/dental-health-history'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import { BORDER_RADIUS, DateUtil, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ModalComponent } from '../../../../components'
import { CheckBoxItem, ContentItem } from '../OtherComponents'
import { useHook } from './hook'

export const ActionAddDentalHealthHistory = () => {
  const { t } = useTranslation()
  const {
    listItem,
    handleSubmit,
    onChangeBoolData,
    onChangeOralHygiene,
    handleCancel,
    modalRef,
  } = useHook()
  return (
    <ModalComponent
      titleModal={t(R.strings.add_survey)}
      titleOpenModal={t(R.strings.add_survey)}
      iconOpenModal={<PlusOutlined rev={undefined} />}
      styleButtonOpen={{
        backgroundColor: convertedVariables.backgroundColor,
        marginLeft: 0,
        marginRight: 0,
      }}
      ref={modalRef}
      borderRadiusButtonOpen={BORDER_RADIUS}
      widthModal={1000}
      handleCancel={handleCancel}
      isShowLineBottom={false}
      renderContent={
        <BaseSpace>
          <HeadItemWrapper>
            <BaseText
              children={t(R.strings.general_info)}
              fontWeight="semibold"
              fontSize="xs"
            />

            <BaseRow align={'middle'} gutter={[15, 10]}>
              <BaseCol xl={6}>
                <BaseText
                  children={t(R.strings.search_appointment_patient)}
                  opacity="0.7"
                />
              </BaseCol>
              <BaseCol xl={18}>
                <BaseText children={'Nguyễn Lan Anh'} fontWeight="semibold" />
              </BaseCol>
            </BaseRow>

            <BaseRow align={'middle'} gutter={[15, 10]}>
              <BaseCol xl={6}>
                <BaseText children={t(R.strings.time)} opacity="0.7" />
              </BaseCol>
              <BaseCol xl={18}>
                <BaseText
                  children={DateUtil.formatDate(
                    new Date(),
                    'DD/MM/YYYYY HH:mm',
                  )}
                  fontWeight="semibold"
                />
              </BaseCol>
            </BaseRow>

            <BaseRow align={'middle'} gutter={[15, 10]}>
              <BaseCol xl={6}>
                <BaseText
                  children={t(R.strings.search_appointment_location)}
                  opacity="0.7"
                />
              </BaseCol>
              <BaseCol xl={18}>
                <BaseText
                  children={'Parkway Trần Hưng Đạo'}
                  fontWeight="semibold"
                />
              </BaseCol>
            </BaseRow>
            <BaseRow align={'middle'} gutter={[15, 10]}>
              <BaseCol xl={6}>
                <BaseText children={t(R.strings.create_by)} opacity="0.7" />
              </BaseCol>
              <BaseCol xl={18}>
                <BaseAvatarProfile name="Phạm Văn Khánh" jobTitle="Bác sĩ" />
              </BaseCol>
            </BaseRow>
            <BaseText
              children={t(R.strings.survey_information)}
              fontWeight="semibold"
              fontSize="xs"
            />
          </HeadItemWrapper>
          <ContentItemWrapper>
            {listItem?.map((itm, index) => {
              if (
                isEqual(
                  itm?.key18n,
                  R.strings.oral_hygiene_tools_you_usually_use,
                )
              ) {
                return (
                  <BaseSpace>
                    <BaseText
                      children={`${8}. ${t(
                        R.strings.oral_hygiene_tools_you_usually_use,
                      )}`}
                      fontWeight="medium"
                    />
                    <BaseRow align={'middle'} gutter={[15, 10]}>
                      {ListOralHygieneToolsYouUsuallyUseEnum?.slice(0, 4)?.map(
                        (itmEnum, idxEnum) => {
                          return (
                            <CheckBoxItemCustom
                              keyEnum={
                                OralHygieneToolsYouUsuallyUseEnum?.[itmEnum]
                              }
                              listSelected={itm?.listEnumSelected ?? []}
                              key={idxEnum}
                              onChange={onChangeOralHygiene}
                            />
                          )
                        },
                      )}
                    </BaseRow>
                    <BaseRow align={'middle'} gutter={[15, 10]}>
                      {ListOralHygieneToolsYouUsuallyUseEnum?.slice(4, 8)?.map(
                        (itmEnum, idxEnum) => {
                          return (
                            <CheckBoxItemCustom
                              keyEnum={
                                OralHygieneToolsYouUsuallyUseEnum?.[itmEnum]
                              }
                              listSelected={itm?.listEnumSelected ?? []}
                              key={idxEnum}
                              onChange={onChangeOralHygiene}
                            />
                          )
                        },
                      )}
                    </BaseRow>
                  </BaseSpace>
                )
              }
              return (
                <ContentItemCustom
                  key18n={itm.key18n}
                  oriNumber={index + 1}
                  isChecked={itm?.isChecked}
                  onChange={onChangeBoolData}
                />
              )
            })}
          </ContentItemWrapper>
        </BaseSpace>
      }
      handleSubmit={handleSubmit}
    />
  )
}

const CheckBoxItemCustom = styled(CheckBoxItem)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${convertedVariables.otherBlueColor} !important;
    border: 1px solid ${convertedVariables.otherBlueColor};
    opacity: 1;
  }
`

const ContentItemCustom = styled(ContentItem)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${convertedVariables.otherBlueColor};
    border: 1px solid ${convertedVariables.otherBlueColor};
    opacity: 1;
  }
`

const ContentItemWrapper = styled(BaseSpace)`
  background-color: #fcfcfc;
  padding: ${PADDING.md} 0px;
`

const HeadItemWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
`
