import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseRow } from 'app/components/common/BaseRow'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { DENTAL_TREATMENT_PATIENT_RECORD_ADD_PATH } from 'app/components/router/route-path'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { useNavigate } from 'react-router'
import { FilterManagement } from './components/FilterManagementTable'
import { useHookTable } from './hook'
const PatientRecordManagementContainer = () => {
  const {
    tableData,
    columns,
    handleTableChange,
    filter,
    handleChangeFilter,
    t,
  } = useHookTable()

  const navigate = useNavigate()

  return (
    <S.BaseManagementWrapper>
      <HeaderPage
        titleI18nKey={R.strings.patient_record_management}
        isExport
        rightComponent={
          <BaseButton
            onClick={() => navigate(DENTAL_TREATMENT_PATIENT_RECORD_ADD_PATH)}
            icon={<PlusOutlined rev={undefined} />}
          >
            {t(R.strings.add_patient_record)}
          </BaseButton>
        }
      />

      <S.RootWrapper>
        <BaseRow>
          <FilterManagement
            handleChangeFilter={handleChangeFilter}
            filterData={filter}
          />
        </BaseRow>
        <BaseTableManagement
          columns={columns}
          dataSource={tableData.data}
          pagination={tableData.pagination}
          loading={tableData.loading}
          onChange={handleTableChange}
          expandable={{
            expandIcon: () => null,
          }}
        />
      </S.RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default PatientRecordManagementContainer
