import { useMemo, useState } from 'react'
import {
  IDiscountProfilePatient,
  IProductServicePurchaseItem,
  IPurchaseProductService,
  ITotalInvoicePurchaseProductService,
} from '../type/purchase-product-service'
import { getRandomUuid } from 'parkway-web-common'
import {
  PaymentOptionPurchaseProductServiceEnum,
  YesNoEnum,
} from 'app/common/enum'
import {
  ICompanyGetInvoice,
  IPaymentMethodDebtPayment,
} from '../type/debt-payment'

interface IProps {
  purchaseProductService: IPurchaseProductService
  onChangePurchaseProductService: (newData: IPurchaseProductService) => void
}

export const usePurchaseProductService = ({
  onChangePurchaseProductService,
  purchaseProductService,
}: IProps) => {
  const [isOpenPurchaseProductService, setIsOpenPurchaseProductService] =
    useState(false)

  const productServices = useMemo(() => {
    return purchaseProductService?.productServices ?? []
  }, [purchaseProductService?.productServices])

  const totalInvoice = useMemo(() => {
    return purchaseProductService?.totalInvoice
  }, [purchaseProductService?.totalInvoice])

  const onChangeProductServices = (
    productServices: IProductServicePurchaseItem[],
  ) => {
    onChangePurchaseProductService({
      ...purchaseProductService,
      productServices,
    })
  }

  const onAddProductServiceItem = (
    newItemProductService: Omit<IProductServicePurchaseItem, 'id'>,
  ) => {
    const newItem: IProductServicePurchaseItem = {
      ...newItemProductService,
      id: getRandomUuid(),
    }
    onChangeProductServices(productServices?.concat([newItem]))
  }

  const onChangeQuantityProductServiceItem = ({
    newQuantity,
    productServiceId,
  }: {
    productServiceId: string
    newQuantity: number
  }) => {
    const newProductService = productServices?.map(item => {
      if (item?.id === productServiceId) {
        item.quantity = newQuantity
      }
      return item
    })

    onChangeProductServices(newProductService)
  }

  const onChangeDiscountProductServiceItem = ({
    newDiscount,
    productServiceId,
  }: {
    productServiceId: string
    newDiscount: IDiscountProfilePatient
  }) => {
    const newProductService = productServices?.map(item => {
      if (item?.id === productServiceId) {
        item.discount = newDiscount
      }
      return item
    })

    onChangeProductServices(newProductService)
  }

  const onDeleteProductServiceItem = (productServiceId: string) => {
    const newProductService = productServices?.filter(
      item => item?.id !== productServiceId,
    )
    onChangeProductServices(newProductService)
  }

  const onChangeTotalInvoice = (
    totalInvoice: ITotalInvoicePurchaseProductService,
  ) => {
    onChangePurchaseProductService({
      ...purchaseProductService,
      totalInvoice,
    })
  }

  const onChangeDiscountTotalInvoice = (discount: IDiscountProfilePatient) => {
    const data: ITotalInvoicePurchaseProductService = {
      ...totalInvoice,
      discount,
    }
    onChangeTotalInvoice(data)
  }

  const onAddAlternativeApplicationProgram = (newValue: string) => {
    const list = totalInvoice?.alternativeApplicationProgram ?? []
    const itemExist = list?.find(item => item === newValue)

    if (!itemExist) {
      const alternativeApplicationProgram = list?.concat([newValue])
      onChangeTotalInvoice({
        ...totalInvoice,
        alternativeApplicationProgram,
      })
    }
  }

  const onChangeAlternativeApplicationProgram = (
    index: number,
    newValue: string,
  ) => {
    const list = totalInvoice?.alternativeApplicationProgram ?? []
    const alternativeApplicationProgram = list?.map((itm, idx) => {
      if (idx === index) {
        itm = newValue
      }
      return itm
    })
    onChangeTotalInvoice({
      ...totalInvoice,
      alternativeApplicationProgram,
    })
  }

  const onDeleteAlternativeApplicationProgram = (index: number) => {
    const list = totalInvoice?.alternativeApplicationProgram ?? []
    const alternativeApplicationProgram = list?.filter(
      (_, idx) => index !== idx,
    )
    onChangeTotalInvoice({
      ...totalInvoice,
      alternativeApplicationProgram,
    })
  }

  const onChangePaymentOptionTotalInvoice = (
    paymentOption: PaymentOptionPurchaseProductServiceEnum,
  ) => {
    onChangeTotalInvoice({
      ...totalInvoice,
      paymentOption,
    })
  }

  const onAddPaymentMethodTotalInvoice = (
    paymentMethod: IPaymentMethodDebtPayment,
  ) => {
    const oldPaymentMethods = totalInvoice?.paymentMethods ?? []
    const paymentMethods = oldPaymentMethods?.concat([paymentMethod])
    onChangeTotalInvoice({
      ...totalInvoice,
      paymentMethods,
    })
  }

  const onChangePaymentMethodTotalInvoice = (
    index: number,
    paymentMethod: IPaymentMethodDebtPayment,
  ) => {
    const oldPaymentMethods = totalInvoice?.paymentMethods ?? []
    const paymentMethods = oldPaymentMethods?.map((itm, idx) => {
      if (idx === index) {
        return paymentMethod
      }
      return itm
    })

    onChangeTotalInvoice({
      ...totalInvoice,
      paymentMethods,
    })
  }

  const onDeletePaymentMethodTotalInvoice = (index: number) => {
    const list = totalInvoice?.paymentMethods ?? []
    const paymentMethods = list?.filter((_, idx) => index !== idx)
    onChangeTotalInvoice({
      ...totalInvoice,
      paymentMethods,
    })
  }

  const onChangeIssueInvoiceTotalInvoice = (issuesInvoice: YesNoEnum) => {
    onChangeTotalInvoice({
      ...totalInvoice,
      issuesInvoice,
    })
  }

  const onChangeCompanyInfoTotalInvoice = (
    key: keyof ICompanyGetInvoice,
    value: string,
  ) => {
    const company: ICompanyGetInvoice = {
      ...totalInvoice.company,
      [key]: value,
    }
    onChangeTotalInvoice({
      ...totalInvoice,
      company,
    })
  }

  const onChangeCustomerOrderTotalInvoice = (customerPayment: number) => {
    onChangeTotalInvoice({
      ...totalInvoice,
      customerPayment,
    })
  }

  const handleOpenPurchase = () => {
    setIsOpenPurchaseProductService(true)
  }

  const handleHidePurchase = () => {
    setIsOpenPurchaseProductService(false)
  }

  return {
    onAddProductServiceItem,
    onChangeQuantityProductServiceItem,
    onDeleteProductServiceItem,
    onChangeDiscountProductServiceItem,

    onChangeDiscountTotalInvoice,
    onAddAlternativeApplicationProgram,
    onDeleteAlternativeApplicationProgram,
    onChangePaymentOptionTotalInvoice,
    onChangePaymentMethodTotalInvoice,
    onAddPaymentMethodTotalInvoice,
    onDeletePaymentMethodTotalInvoice,
    onChangeIssueInvoiceTotalInvoice,
    onChangeCompanyInfoTotalInvoice,
    handleOpenPurchase,
    handleHidePurchase,
    isOpenPurchaseProductService,
    onChangeCustomerOrderTotalInvoice,
    onChangeAlternativeApplicationProgram,
  }
}
