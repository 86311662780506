import { useMemo, useState } from 'react'
import { dataNotificationDashboard } from './data'
import * as S from './styles'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
export const NotificationComponent = () => {
  const { t } = useTranslation()
  const [currentDot, setCurrentDot] = useState(0)

  const handleDotChange = (current: number) => {
    setCurrentDot(current)
  }
  const notificationList = useMemo(() => {
    return {
      tablet: dataNotificationDashboard.map((item, index) => (
        <S.ItemWrapper key={index}>
          <S.TitleItemText>{item?.title}</S.TitleItemText>
          <S.DescriptionItemText>{item?.description}</S.DescriptionItemText>
        </S.ItemWrapper>
      )),
    }
  }, [dataNotificationDashboard])

  const countData = useMemo(() => {
    return `${currentDot + 1}/${dataNotificationDashboard?.length}`
  }, [currentDot, dataNotificationDashboard])

  return (
    <S.RootWrapper>
      <S.HeadWrapper justify={'space-between'} align={'middle'}>
        <S.TitleText>{t(R.strings.notification)}</S.TitleText>
        <S.CountWrapper>{countData}</S.CountWrapper>
      </S.HeadWrapper>
      <S.CarouselWrapper
        dotPosition={'bottom'}
        draggable
        afterChange={handleDotChange}
        autoplay
      >
        {notificationList.tablet}
      </S.CarouselWrapper>
    </S.RootWrapper>
  )
}
