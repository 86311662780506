import { BaseButton } from 'app/components/common/BaseButton'
import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 0px ${PADDING.md};
`

export const TotalPage = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  opacity: 0.7;
`

export const StatusButton = styled(BaseButton)<{
  $color?: string
  $background?: string
}>`
  color: ${props => props?.$color};
  background: ${props => props?.$background};
`

export const CodeText = styled.div`
  color: #216ae2;
  font-size: 14px;
  font-weight: 500;
`
