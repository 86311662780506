import { MoreOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseButton } from 'app/components/common/BaseButton'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import {
    RenderValueTable,
    useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { getArray } from 'app/components/tables/common-table/constant'
import { IAppointmentDentalTreatment } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/appointment'
import { DateUtil, Pagination, initialPagination } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useTableHook = () => {
  const { t } = useTranslation()
  const getDataTable = async (pagination: Pagination) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IAppointmentDentalTreatment>
    try {
      return {
        ...initData,
        data: getArray(50)?.map(index => {
          return {
            key: index,
            ordinalNumber: index,
            doctor: {
              jobTitle: 'Bác sĩ',
              name: `Lê Thị Bảo Ngọc`,
            },
            address: `228 Trần Hưng Đạo, Nguyễn Cư Trinh, Quận 1, TP.HCM`,
            dateTime: DateUtil.formatDate(new Date(), 'HH:mm DD/MM/YYYY'),
          } as IAppointmentDentalTreatment
        }),
      }
    } catch (error) {
      return initData
    }
  }

  const { tableData, handleTableChange, fetch } = useHookTableManagement({
    getDataTable,
  })

  const fetchNewData = () => {
    fetch(initialPagination)
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IAppointmentDentalTreatment>({
        title: t(R.strings.ordinal_number),
        key: 'ordinalNumber',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <RenderValueTable value={record.ordinalNumber} type="Number" />
        ),
        width: '5%',
      }),
      convertColumnTable<IAppointmentDentalTreatment>({
        title: t(R.strings.date_time),
        key: 'dateTime',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <RenderValueTable value={record?.dateTime ?? '-'} type="Main" />
        ),
      }),
      convertColumnTable<IAppointmentDentalTreatment>({
        title: t(R.strings.search_appointment_doctor),
        key: 'doctor',
        fixed: 'left',
        render: (_, record) => (
          <BaseAvatarProfile
            jobTitle={record?.doctor?.jobTitle}
            name={record?.doctor?.name}
            src={record?.doctor?.avatar}
          />
        ),
      }),
      convertColumnTable<IAppointmentDentalTreatment>({
        title: t(R.strings.address),
        key: 'address',
        fixed: 'left',
        render: (_, record) => (
          <RenderValueTable value={record?.address ?? '-'} type="Main" />
        ),
      }),
      convertColumnTable<IAppointmentDentalTreatment>({
        title: t(R.strings.search_appointment_treatment),
        key: 'treatment',
        fixed: 'left',
        render: (_, record) => (
          <RenderValueTable value={record?.treatment ?? '-'} type="Main" />
        ),
      }),
      convertColumnTable<IAppointmentDentalTreatment>({
        key: 'key',
        fixed: 'left',
        render: () => (
          <BasePopover
            content={
              <BaseSpace>
                <BaseButton type="text">{t(R.strings.update)}</BaseButton>
                <BaseButton type="text">{t(R.strings.delete)}</BaseButton>
              </BaseSpace>
            }
          >
            <BaseButton type="text" icon={<MoreOutlined rev={undefined} />} />
          </BasePopover>
        ),
        width: '5%',
        classNameWidthColumnOverwrite: 'small-column',
      }),
    ]
    return option
  }, [t, fetchNewData])

  return {
    columns,
    tableData,
    handleTableChange,
  }
}
