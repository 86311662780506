import { notification, Spin } from 'antd'
import styled from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'
import { ArgsProps } from 'antd/lib/notification'
import {
  SvgDownloadedIcon,
  SvgDownloadErroredIcon,
  SvgWaitingForDownloadingIcon,
} from 'app/assets/svg-assets'

interface IconWrapperProps {
  $isOnlyTitle: boolean
}

const IconWrapper = styled.div<IconWrapperProps>`
  font-size: ${props => (props.$isOnlyTitle ? '1.125rem' : '1.25rem')};
  line-height: 1rem;
`

const EmptyDescription = styled.div``

interface NotificationProps extends ArgsProps {
  cancel: string
}
const openWaitingForDownloading = (config: NotificationProps): void => {
  notification.open({
    ...config,
    icon: (
      <IconWrapper $isOnlyTitle={!config.description}>
        <SvgWaitingForDownloadingIcon />
      </IconWrapper>
    ),
    message: (
      <div className={`title ${!config.description && `title-only`}`}>
        {config.message}
      </div>
    ),
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <EmptyDescription />
    ),
    className: 'download-notification',
    closeIcon: <div className="cancel">{config.cancel}</div>,
  })
}

const openDownloading = (config: NotificationProps): void => {
  notification.open({
    ...config,
    icon: (
      <IconWrapper $isOnlyTitle={!config.description}>
        <Spin indicator={<LoadingOutlined rev spin />} />
      </IconWrapper>
    ),
    message: (
      <div className={`title ${!config.description && `title-only`}`}>
        {config.message}
      </div>
    ),
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <EmptyDescription />
    ),
    className: 'download-notification',
    closeIcon: <div className="cancel">{config.cancel}</div>,
  })
}

const openDownloadErrored = (config: NotificationProps): void => {
  notification.open({
    ...config,
    icon: (
      <IconWrapper $isOnlyTitle={!config.description}>
        <SvgDownloadErroredIcon />
      </IconWrapper>
    ),
    message: (
      <div className={`title ${!config.description && `title-only`}`}>
        {config.message}
      </div>
    ),
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <EmptyDescription />
    ),
    className: 'download-notification',
    closeIcon: <div className="cancel">{config.cancel}</div>,
  })
}

const openDownloaded = (config: NotificationProps): void => {
  notification.open({
    ...config,
    icon: (
      <IconWrapper $isOnlyTitle={!config.description}>
        <SvgDownloadedIcon />
      </IconWrapper>
    ),
    message: (
      <div className={`title ${!config.description && `title-only`}`}>
        {config.message}
      </div>
    ),
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <EmptyDescription />
    ),
    className: 'download-notification',
    closeIcon: <div className="cancel">{config.cancel}</div>,
  })
}

const closeDownload = (key: string) => {
  notification.destroy(key)
}

export const downloadNotificationController = {
  waiting: openWaitingForDownloading,
  downloading: openDownloading,
  downloadErrored: openDownloadErrored,
  downloaded: openDownloaded,
  close: closeDownload,
}
