import R from 'app/assets/R'
import { BaseTabs } from 'app/components/common/BaseTabs'
import HeaderPage from 'app/components/header/components/HeaderPage'
import * as S from 'app/components/tables/BaseTableReport/styles'
import 'dayjs/locale/vi'
import React, { useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { HeadTabComponent } from './components'
import { ApprovedLayout, PendingLayout, RejectLayout } from './layouts'
import _ from 'lodash'

const ApproveTreatmentPhaseContainer: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(R.strings.wait_for_approval)
  return (
    <S.BaseReportWrapper>
      <HeaderPage titleI18nKey={R.strings.approve_treatment_phase} />
      <S.RootWrapper>
        <BaseTabs
          defaultActiveKey={tabIndex}
          tabPosition={'top'}
          onChange={setTabIndex}
          items={[
            {
              label: (
                <HeadTabComponent
                  keyTitle={R.strings.wait_for_approval}
                  total={_.random(1, 10)}
                  currentTab={tabIndex}
                />
              ),
              key: R.strings.wait_for_approval,
              children: <PendingLayout />,
            },
            {
              label: (
                <HeadTabComponent
                  keyTitle={R.strings.approved}
                  total={_.random(1, 10)}
                  currentTab={tabIndex}
                />
              ),
              key: R.strings.approved,
              children: <ApprovedLayout />,
            },
            {
              label: (
                <HeadTabComponent
                  keyTitle={R.strings.reject}
                  total={_.random(1, 10)}
                  currentTab={tabIndex}
                />
              ),
              key: R.strings.reject,
              children: <RejectLayout />,
            },
          ]}
        />
      </S.RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default ApproveTreatmentPhaseContainer
