import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import { useAdditionalServiceVoucherContext } from './context'
import {
  FooterLayout,
  ImportFileModal,
  InfoVoucherLayout,
  SettingVoucherLayout,
} from './layouts'
import { initValues } from './type'

export const ContentLayout = () => {
  const { form, handleSubmit, openImportArea } = useAdditionalServiceVoucherContext()
  
  return (
    <BaseForm form={form} onFinish={handleSubmit} initialValues={initValues}>
      <RootWrapper>
        <InfoVoucherLayout />
        <SettingVoucherLayout />
        <FooterLayout />
      </RootWrapper>

      {openImportArea && <ImportFileModal />}
    </BaseForm>
  )
}

const RootWrapper = styled(BaseSpace)`
  padding-bottom: ${PADDING.xxl};
`
