import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import * as S from 'app/components/tables/BaseTableReport/styles'
import 'dayjs/locale/vi'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTableHook } from './hook'
import './styles.css'
import { FilterBracesTray } from 'app/components/tables/BaseTableManagement/FilterBracesTrayTable'

const BracesTrayContainer: React.FC = () => {
  const {
    columns,
    tableData,
    handleTableChange,
    handleChangeFilter,
    filterResponse,
    onClickExport,
  } = useTableHook()

  return (
    <S.BaseReportWrapper>
      <HeaderPage
        titleI18nKey={R.strings.braces_tray}
        isExport
        onPressExport={() => onClickExport({ ...filterResponse })}
      />
      <S.RootWrapper>
        <S.HeadWrapper>
          <BaseRow>
            <FilterBracesTray
              handleChangeFilter={handleChangeFilter}
              filterData={filterResponse}
            />
          </BaseRow>
        </S.HeadWrapper>
        <BaseTableReport
          columns={columns}
          dataSource={tableData.data}
          loading={tableData.loading}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
          pagination={tableData.pagination}
          scroll={{ x: true, y: tableData?.data?.length ? 480 : undefined }}
          // rowSelection={rowSelection}
        />
      </S.RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default BracesTrayContainer
