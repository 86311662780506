import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormRule, IPropsUpdateSupplier } from './type'
import { ISupplier } from 'app/api/inventory-new/model/management'
import { requestUpdateSupplierManagement } from 'app/api/inventory-new'
import { StatusEnum } from 'app/common/enum'

export const useModalHook = (props: IPropsUpdateSupplier) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<ISupplier>()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(props?.supplier)
    }
  }, [visible])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: ISupplier) => {
    setIsLoading(true)
    try {
      await requestUpdateSupplierManagement({
        id: props?.supplier?._id,
        body: { ...values, status: StatusEnum.ACTIVE },
      })

      setIsLoading(false)
      handleCancel()
      props?.fetchTable?.()
      notificationController.success({
        message: t(R.strings.provider_update),
        description: t(R.strings.success),
      })

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const rules: IFormRule = useMemo(() => {
    return {
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.supplier_name),
          }),
        },
      ],
      code: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.supplier_code),
          }),
        },
      ],
      address: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.address),
          }),
        },
      ],
      bankAccount: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.bank_account),
          }),
        },
      ],
      bankName: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.bank_name),
          }),
        },
      ],
      taxIdNumber: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.TIN),
          }),
        },
      ],
      phone: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.phone),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
  }
}
