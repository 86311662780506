import R from 'app/assets/R'
import { ModulePermissionEnum } from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { BaseLayoutReport } from 'app/components/common/BaseLayoutReport'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { data } from './data'
import { ShowListType } from '../../components/common/BaseLayoutReport/type'

const InventoryManagementContainer = () => {
  const { t } = useTranslation()

  const { isHavePermissionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionAndGoBack(
      ModulePermissionEnum.INVENTORY,
      R.strings.inventory_management,
    )
  }, [t])

  return (
    <BaseLayoutReport
      data={data}
      titleI18nKey={R.strings.inventory_management}
      modulePermission={ModulePermissionEnum.INVENTORY}
      type={ShowListType.LIST}
    />
  )
}

export default InventoryManagementContainer
