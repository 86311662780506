import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import SuppliesOptionSelect from '../components/SuppliesOptionSelect'
import { useModalHook } from './hook'
import * as S from './styles'
import { IFormData, IPropsCreateSupplies } from './type'
import { useCreateUpdateServiceContext } from 'app/containers/Accounting/TreatmentManagement/CreateUpdateProductServiceManagement/context'

export const initValues: IFormData = {}

export const CreateSupplies = (props: IPropsCreateSupplies) => {
  const {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
  } = useModalHook(props)
  const { itemSupplies, isLoadingSupply } = useCreateUpdateServiceContext()

  return (
    <BaseModalManagement
      titleKeyI18n={R.strings.add_supplies}
      isLoading={isLoading}
      content={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <S.FormItem
            label={t(R.strings.item_management_content_supplies)}
            name="supplies"
            rules={rules.supplies}
            required
          >
            <S.SelectBaseStyled
              loading={isLoadingSupply}
              placeholder={t(R.strings.item_management_content_select_supplies)}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {itemSupplies?.map(supply => {
                return (
                  <Option value={supply._id} key={`supply-${supply._id}`}>
                    <SuppliesOptionSelect item={supply} />
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>

          <S.FormItem
            name="quantity"
            label={t(R.strings.quantity)}
            rules={rules.quantity}
            required
          >
            <S.FormInputNumber
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.quantity),
              })}
            />
          </S.FormItem>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.add_supplies}
      confirmKeyI18n={R.strings.confirm}
      handleSubmit={form.submit}
      visible={visible}
      typeButtonOpen="update"
    />
  )
}
