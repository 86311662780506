import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import {
  SvgClipboard1Icon,
  SvgClipboardPlusSize16Icon,
  SvgNotesIcon,
  SvgStethoscopeLineIcon,
  SvgTableCloseIcon,
} from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { DefineBracesTagEnum } from 'app/containers/DentalTreatmentManagement/TreatmentPlan/constant'
import { BracesTagEnum } from 'app/containers/DentalTreatmentManagement/TreatmentPlan/type'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const TreatmentInformationLayout = () => {
  const { t } = useTranslation()
  return (
    <BaseSpace>
      <BaseRow align={'middle'} justify={'space-between'} gutter={[16, 16]}>
        <BaseCol>
          <BaseText
            children={t(R.strings.treatment_label)}
            fontWeight="semibold"
            fontSize="md"
          />
        </BaseCol>
        <BaseCol>
          <BaseRow gutter={[16, 10]} align={'middle'}>
            <BaseCol>
              <Line />
            </BaseCol>
            <BaseCol>
              <BaseButton icon={<LeftOutlined rev={undefined} />} />
            </BaseCol>
            <BaseCol>
              <BaseButton icon={<RightOutlined rev={undefined} />} />
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>

      <TreatmentInfoWrapper>
        <BaseSpace style={{ padding: PADDING.xxs }}>
          <BaseRow align={'top'} gutter={[8, 8]}>
            <BaseCol>
              <SvgClipboardPlusSize16Icon />
            </BaseCol>
            <BaseCol>
              <BaseSpace size={10}>
                <BaseText
                  children={t(R.strings.procedure_implementation)}
                  fontWeight="semibold"
                />
                <BaseText children={'Thủ thuật kiểm tra răng'} />
              </BaseSpace>
            </BaseCol>
          </BaseRow>
          <BaseRow align={'top'} gutter={[8, 8]}>
            <BaseCol>
              <SvgClipboard1Icon />
            </BaseCol>
            <BaseCol>
              <BaseSpace size={10}>
                <BaseText
                  children={t(R.strings.symptoms_diagnosis)}
                  fontWeight="semibold"
                />
                <BaseText
                  children={
                    'CVR+ĐB. Gắn bộ MCKRot #22. Gắn 4M R7 #22. Thay dây Niti'
                  }
                />
              </BaseSpace>
            </BaseCol>
          </BaseRow>
          <BaseRow align={'top'} gutter={[8, 8]}>
            <BaseCol>
              <SvgStethoscopeLineIcon />
            </BaseCol>
            <BaseCol>
              <BaseSpace size={10}>
                <BaseText
                  children={t(R.strings.treatment_direction)}
                  fontWeight="semibold"
                />
                <BaseText
                  children={
                    'CVR+ĐB. Gắn bộ MCKRot #22. Gắn 4M R7 #22. Thay dây Niti'
                  }
                />
              </BaseSpace>
            </BaseCol>
          </BaseRow>
          <BaseRow align={'top'} gutter={[8, 8]}>
            <BaseCol>
              <SvgNotesIcon />
            </BaseCol>
            <BaseCol>
              <BaseSpace size={10}>
                <BaseText children={t(R.strings.note)} fontWeight="semibold" />
                <BaseText
                  children={
                    'Khách hàng yêu cầu dời ngày khám sang ngày 08/08/2024.'
                  }
                  colorText="statesRedColor"
                />
              </BaseSpace>
            </BaseCol>
          </BaseRow>
        </BaseSpace>
        <TreatmentStatusWrapper>
          <TagStatus
            $backgroundColor={
              DefineBracesTagEnum?.[BracesTagEnum.DeepBite].backgroundColor
            }
            gutter={[10, 10]}
            align={"middle"}
          >
            <BaseCol>
              <BaseText
                children={t(R.strings.pending_execution)}
                colorText="backgroundColor"
                fontWeight="semibold"
                fontSize='xs'
              />
            </BaseCol>
            <BaseCol>
              <SvgTableCloseIcon />
            </BaseCol>
          </TagStatus>
        </TreatmentStatusWrapper>
      </TreatmentInfoWrapper>
    </BaseSpace>
  )
}

const Line = styled.div`
  width: 1.5px;
  height: 25px;
  background-color: #f0f1f3;
`

const TreatmentInfoWrapper = styled(BaseSpace)`
  border-radius: ${BORDER_RADIUS};
  background-color: #fcfcfd;
  border: 1px solid ${convertedVariables.neutralBlack9Color};
`

const TreatmentStatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem 1rem;
  background-color: #F9F9F9;
`

const TagStatus = styled(BaseRow)<{ $backgroundColor: string }>`
  padding: 5px ${PADDING.xxxs};
  border-radius: ${PADDING.md};
  background-image: ${props => props?.$backgroundColor};
  width: fit-content;
`
