import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { LevelSkillItem } from '../../../../LevelSkillItem'
import * as S from './styles'
import { CreateLevelSkill } from '../../../level-skill-modal'
import { isEqual } from 'lodash'

const SelectionSkillLevel = ({ form }) => {
  const { t } = useTranslation()
  const onDeleteData = id => {
    const skillsInfo = form?.getFieldValue('skillsInfo') ?? []
    const filteredSkills = skillsInfo?.filter(item => item.id !== id)
    form?.setFieldValue('skillsInfo', filteredSkills)
  }
  return (
    <S.RootWrapper direction="vertical">
      <S.HeaderWrapper>
        <S.TitleSupplies>
          {t(R.strings.item_management_content_skill)}
        </S.TitleSupplies>
        <S.ActionGroup>
          <CreateLevelSkill />
        </S.ActionGroup>
      </S.HeaderWrapper>

      <S.SectionSkill>
        <div className="section-skill__header">
          <span className="section-skill__title">
            {t(R.strings.skill_content_name_create)}
          </span>
          <span className="section-skill__title">
            {t(R.strings.skill_content_levelSkill)}
          </span>
        </div>
        <S.FormItem hidden name="skillsInfo"></S.FormItem>
        <S.FormItem
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            !isEqual(prevValues.skillsInfo, currentValues.skillsInfo)
          }
        >
          {({ getFieldValue }) => {
            const skills = getFieldValue('skillsInfo') ?? []
            return (
              <>
                <S.ListSuppliesWrapper>
                  {skills?.map(item => {
                    return (
                      <S.SkillWrapper key={skills.id}>
                        <LevelSkillItem
                          levelSkill={item?.levelSkillName}
                          skill={item?.skillName}
                          color={item?.levelSkillColor}
                          onRemove={() => onDeleteData(item.id)}
                          key={skills.id}
                          index={skills.id}
                        />
                      </S.SkillWrapper>
                    )
                  })}
                  {skills?.length === 0 && (
                    <span>
                      {t(R.strings.item_management_content_not_select_skill)}
                    </span>
                  )}
                </S.ListSuppliesWrapper>
                {skills?.length !== 0 && (
                  <S.SelectedSkillText>
                    {t(
                      R.strings
                        .treatment_management_content_selected_total_skill,
                    )}
                    : <span className="number">{skills.length}</span>
                  </S.SelectedSkillText>
                )}
              </>
            )
          }}
        </S.FormItem>
      </S.SectionSkill>
    </S.RootWrapper>
  )
}

export default SelectionSkillLevel
