import { MoreOutlined } from '@ant-design/icons'
import { requestGetOrganizationalChart } from 'app/api/company/organizational-chart'
import { ResponseOrganizationalChartType } from 'app/api/company/organizational-chart/model'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import {
  ExpandedTableValue,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import { initPagination } from 'app/constant'
import { PADDING, Pagination, moment } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { UpdateModalLayout } from './layouts'
import { IFilterStrategicDoctorReport, IMonthlyRoster } from './type'

export const useHookTable = () => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState(initPagination)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IMonthlyRoster[]>([])
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

  const handleExpand = (expanded: boolean, newKey?: number) => {
    if (!newKey) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const [filter, setFilter] = useState<IFilterStrategicDoctorReport>({
    year: moment().year().toString(),
    month:
      moment().month() + 1 > 9
        ? `${moment().month() + 1}`
        : `0${moment().month() + 1}`,
    area: undefined,
  })

  const getData = async () => {
    try {
      setLoading(true)

      const res: ResponseOrganizationalChartType =
        await requestGetOrganizationalChart({})

      res?.data &&
        setData(
          res.data?.map((item, index) => ({
            ...item,
            key: index + 1,
            children: undefined,
          })),
        )
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IMonthlyRoster>({
        title: t(R.strings.clinic),
        key: 'name',
        fixed: 'left',
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)
          const _handleExpand = () => {
            return handleExpand(!isExpanded, record?.key)
          }

          const style: React.CSSProperties | undefined = isExpanded
            ? { position: 'relative', marginTop: -16, paddingBottom: 15 }
            : undefined

          return (
            <ExpandedTableValue
              isExpanded={isExpanded}
              handleExpand={_handleExpand}
              isHaveAreaWhenNotHaveActionExpanded
              style={style}
              alignContainer="middle"
              valueCustom={
                <BaseRow
                  gutter={[16, 8]}
                  align={'middle'}
                  justify={'space-between'}
                  style={{ flex: 1 }}
                >
                  <BaseCol>
                    <BaseText children={record?.name} fontWeight="semibold" />
                  </BaseCol>

                  <BaseCol>
                    <BaseRow gutter={16} align={'middle'} wrap={false}>
                      <BaseCol>
                        <StatusWrapper>
                          <BaseText
                            children={t(R.strings.flagship)}
                            colorText="backgroundColor"
                            fontWeight="semibold"
                          />
                        </StatusWrapper>
                      </BaseCol>
                      <BaseCol>
                        <BasePopover
                          content={
                            <BaseSpace size={8}>
                              <UpdateModalLayout />
                            </BaseSpace>
                          }
                          trigger={'click'}
                        >
                          <BaseButton
                            type="text"
                            icon={<MoreOutlined rev={undefined} />}
                          />
                        </BasePopover>
                      </BaseCol>
                    </BaseRow>
                  </BaseCol>
                </BaseRow>
              }
            />
          )
        },
      }),
    ]
    return option
  }, [t, expandedRowKeys])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (newFilter: IFilterStrategicDoctorReport) => {
    setFilter(newFilter)
  }

  return {
    tableData: {
      data,
    },
    columns,
    loading,
    filter,
    handleTableChange,
    pagination,
    handleChangeFilter,
    handleExpand,
    expandedRowKeys,
  }
}

const StatusWrapper = styled.div`
  background-image: linear-gradient(90deg, #2f7eff, #659fff);
  padding: 4px ${PADDING.xxs};
  border-radius: ${PADDING.xl};
`
