import { BaseRadio } from 'app/components/common/BaseRadio'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import {
  BORDER_RADIUS
} from 'parkway-web-common'
import styled from 'styled-components'

export const SectionWrapper = styled(SectionBase)``

export const MonthlyInstallmentPlan = styled(BaseRow)`
  background-color: #f8f8f8; //need change
  border-radius: ${BORDER_RADIUS};
  padding: 4px 8px;
`

export const SpaceWrapper = styled(BaseSpace)`
  width: 100%;
`
export const RadioBtn = styled(BaseRadio)`
  font-size: 0.875rem;
`
