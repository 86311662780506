import { ApiClient } from 'app/service/api-service'
import { CustomerCareEndPoint } from './constant'
import { IResponseGetCheckInServicePlan } from './model/check-in-service-plan'
import {
  IParamsGetCustomerCarePlan,
  IParamsGetReportBookingWebsite,
  IResponseGetCheckInPlan,
  IResponseGetCustomerCarePlan,
} from './model/customer-care'

export const requestGetCustomerCarePlan = (
  params: IParamsGetCustomerCarePlan,
): Promise<IResponseGetCustomerCarePlan> =>
  ApiClient.Get({
    url: CustomerCareEndPoint.CustomerCarePlan.requestGetData,
    params: { params },
  })

export const requestImportCustomerCarePlan = (body: FormData) =>
  ApiClient.Post({
    url: CustomerCareEndPoint.CustomerCarePlan.requestImportFile,
    body,
  })

export const requestGetCheckInCustomerCarePlan = (
  params: IParamsGetCustomerCarePlan,
): Promise<IResponseGetCheckInPlan> =>
  ApiClient.Get({
    url: CustomerCareEndPoint.CheckInPlan.requestGetData,
    params: { params },
  })

export const requestImportCheckInCustomerCarePlan = (body: FormData) =>
  ApiClient.Post({
    url: CustomerCareEndPoint.CheckInPlan.requestImportFile,
    body,
  })

export const requestGetCheckInServicePlan = (
  params: IParamsGetCustomerCarePlan,
): Promise<IResponseGetCheckInServicePlan> =>
  ApiClient.Get({
    url: CustomerCareEndPoint.CheckInPlanByService.requestGetData,
    params: { params },
  })

export const requestImportCheckInServicePlan = (body: FormData) =>
  ApiClient.Post({
    url: CustomerCareEndPoint.CheckInPlanByService.requestImportFile,
    body,
  })

export const requestGetReportBookingFromWebsite = (params: IParamsGetReportBookingWebsite) =>
  ApiClient.Get({
    url: CustomerCareEndPoint.ReportBooking.getReportBookingWebsite,
    params: { params },
  })
