import { useEffect, useState } from 'react'
import { IResponseFilter } from './components/FilterSurveyReport/type'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'

export enum StatusTableEnum {
  active = 'active',
  deleted = 'deleted',
  // error = 'error',
  inactive = 'inactive',
}

export type StatusTableType = keyof typeof StatusTableEnum

const initResponseFilterTableManagement = {
  area: '',
  clinic: '',
}

export const useReportHook = () => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.CUSTOMER_CARE,
      FunctionPermissionEnum.REPORT_READ_REPORT_13,
      R.strings.survey_report,
    )
  }, [t])

  const handleChangeFilter = (filter: IResponseFilter) => {
    setFilter(filter)
  }

  return {
    handleChangeFilter,
    filter,
  }
}
