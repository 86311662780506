import { withLoading } from 'app/hocs/with-loading.hoc'
import PromotionOnGoingPage from 'app/page/menu/my-desk/promotion-ongoing'
import { Route } from 'react-router-dom'
import { PROMOTION_ON_GOING_PATH } from './route-path'

const PromotionOnGoing = withLoading(PromotionOnGoingPage)

export const MyDeskRouter = () => {
  return (
    <>
      <Route path={PROMOTION_ON_GOING_PATH} element={<PromotionOnGoing />} />
    </>
  )
}
