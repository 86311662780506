import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import ImportMarketingPlanContainer from 'app/containers/Marketing/ImportMarketingPlan'

const ImportMarketingPlanPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.marketing_plan)}</PageTitle>
      <ImportMarketingPlanContainer />
    </>
  )
}
const ImportMarketingPlanPage = memo(ImportMarketingPlanPageCpn, isEqual)

export default ImportMarketingPlanPage
