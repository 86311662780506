import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import {
  SvgCalendarDesignIcon,
  SvgDeleteDesignIcon,
} from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import CollapseComponent from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/CollapseComponent'
import { ModalComponent } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/components'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TreatmentInPhaseModal } from '../../components'
import { AddTreatmentInTreatmentPhase } from '../AddTreatmentInTreatmentPhase'
import { AddAppointmentSession } from './components'
import { useAddTreatmentPhaseContext } from './context'
import { ITreatmentInAppointmentSession } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'

export const ContentTreatmentPhaseModal = () => {
  const { t } = useTranslation()

  const {
    form,
    modalRef,
    handleSubmit,
    listAppointmentSession,
    onAddListAppointmentSession,
    onDeleteAppointmentSession,
    onAddTreatmentInAppointmentSession,
    onDeleteTreatmentInAppointmentSession,
    onChangeTreatmentInAppointmentSession,
  } = useAddTreatmentPhaseContext()

  const isDisableSubmit = useMemo(() => {
    if (!listAppointmentSession?.length) return true

    return false
  }, [listAppointmentSession])

  const onChangeTreatment = (
    idAppointment?: string,
    newTreatment?: ITreatmentInAppointmentSession,
  ) => {
    if (!idAppointment || !newTreatment) return

    onChangeTreatmentInAppointmentSession?.({
      newTreatment,
      appointmentSessionId: idAppointment,
    })
  }

  return (
    <ModalComponent
      iconOpenModal={
        <BaseCol>
          <PlusOutlined rev={undefined} />
        </BaseCol>
      }
      widthModal={900}
      titleOpenModal={t(R.strings.add_phase)}
      titleModal={t(R.strings.add_phase)}
      ref={modalRef}
      handleSubmit={form?.submit}
      isDisableSubmit={isDisableSubmit}
      renderContent={
        <BaseForm form={form} onFinish={handleSubmit}>
          <BaseSpace size={8}>
            <BaseForm.Item
              name={'phaseName'}
              label={t(R.strings.phase_name)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.phase_name),
                  }),
                },
              ]}
            >
              <BaseInput placeholder={t(R.strings.enter_phase_name)} />
            </BaseForm.Item>
            <BaseRow gutter={[16, 10]} align={'top'}>
              <DateWrapper>
                <BaseFormItem
                  name={'startDate'}
                  label={t(R.strings.start_date)}
                  required
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: t(R.strings.start_date),
                      }),
                    },
                  ]}
                >
                  <BaseDatePickerWrapper
                    suffixIcon={<SvgCalendarDesignIconStyled />}
                    placeholder={t(R.strings.choose_date)}
                  />
                </BaseFormItem>
              </DateWrapper>
              <DateWrapper>
                <BaseFormItem
                  name={'endDate'}
                  label={t(R.strings.end_date)}
                  required
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: t(R.strings.end_date),
                      }),
                    },
                  ]}
                >
                  <BaseDatePickerWrapper
                    suffixIcon={<SvgCalendarDesignIconStyled />}
                    placeholder={t(R.strings.choose_date)}
                  />
                </BaseFormItem>
              </DateWrapper>
            </BaseRow>
            <BaseRow justify={'space-between'} align={'middle'} gutter={15}>
              <BaseCol>
                <BaseText
                  children={t(R.strings.appointment_session_information)}
                  fontWeight="semibold"
                  fontSize="xs"
                />
              </BaseCol>
              <BaseCol>
                <AddAppointmentSession
                  handleSubmit={onAddListAppointmentSession}
                />
              </BaseCol>
            </BaseRow>

            <BaseSpace>
              {listAppointmentSession?.map((item, index) => {
                const indexNumber = index + 1
                return (
                  <CollapseComponentWrapper
                    label={
                      <BaseText
                        children={`${indexNumber}. ${item?.name}`}
                        fontSize="xs"
                        fontWeight="medium"
                      />
                    }
                    extra={
                      <div onClick={event => event?.stopPropagation()}>
                        <BaseButton
                          onClick={() => onDeleteAppointmentSession?.(index)}
                          type="text"
                          icon={<SvgDeleteDesignIcon />}
                        />
                      </div>
                    }
                    expandIconPosition="end"
                    style={{ alignItems: 'center' }}
                    titleKeyI18n={item?.id}
                    children={
                      <div onClick={event => event?.stopPropagation()}>
                        <BaseSpace>
                          <BaseRow
                            justify={'space-between'}
                            align={'middle'}
                            gutter={15}
                          >
                            <BaseCol>
                              <BaseText
                                children={t(R.strings.treatment_information)}
                                fontWeight="medium"
                                fontSize="xs"
                              />
                            </BaseCol>
                            <BaseCol>
                              <AddTreatmentInTreatmentPhase
                                onChangeTreatmentInAppointmentSession={newTreatment => {
                                  if (!newTreatment) return

                                  onAddTreatmentInAppointmentSession?.({
                                    newTreatment,
                                    appointmentSessionId: item?.id,
                                  })
                                }}
                              />
                            </BaseCol>
                          </BaseRow>

                          <BaseSpace>
                            {item?.treatmentInAppointmentSession?.map(
                              (itm, idx) => {
                                return (
                                  <TreatmentInPhaseModal
                                    key={idx}
                                    index={idx + 1}
                                    item={itm}
                                    onDelete={() =>
                                      onDeleteTreatmentInAppointmentSession?.({
                                        indexTreatment: idx,
                                        appointmentSessionId: item?.id,
                                      })
                                    }
                                    onChangeTreatment={(
                                      treatment: ITreatmentInAppointmentSession,
                                    ) => onChangeTreatment(itm?.id, treatment)}
                                  />
                                )
                              },
                            )}
                          </BaseSpace>
                        </BaseSpace>
                      </div>
                    }
                    key={index}
                  />
                )
              })}
            </BaseSpace>
          </BaseSpace>
        </BaseForm>
      }
      borderRadiusButtonOpen={BORDER_RADIUS}
      borderButtonOpen={`1px solid ${convertedVariables.neutralBlack9Color}`}
      styleButtonOpen={{
        backgroundColor: convertedVariables?.backgroundColor,
      }}
    />
  )
}

const BaseFormItem = styled(BaseForm.Item)`
  width: 100%;
`

const BaseDatePickerWrapper = styled(BaseDatePicker)`
  width: 100%;
`

const DateWrapper = styled(BaseCol)`
  display: flex;
  flex: 1;
`

const SvgCalendarDesignIconStyled = styled(SvgCalendarDesignIcon)`
  path {
    fill: ${convertedVariables.primaryColor};
    opacity: 0.7;
  }
`

const CollapseComponentWrapper = styled(CollapseComponent)`
  .ant-collapse-content {
    border-top: 0px !important;
  }
  .ant-collapse-header {
    background-color: #fcfcfc;
    border: 1px solid ${convertedVariables.neutralBlack9Color};
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
  }
  border: 0px;
`
