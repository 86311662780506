import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { useTranslation } from 'react-i18next'
import { useHook } from './hook'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'

export const ListWorkThisWeekLayout = () => {
  const { t } = useTranslation()
  const { columns, tableData } = useHook()
  return (
    <SectionBase>
      <BaseSpace>
        <BaseText
          children={t(R.strings.work_this_week)}
          fontWeight="semibold"
          fontSize="md"
        />
        <BaseTableManagement
          columns={columns}
          dataSource={tableData?.data}
          loading={tableData?.loading}
          pagination={{
            total: 100,
            pageSize: 5
          }}
          scroll={{
            x: true,
          }}
        />
      </BaseSpace>
    </SectionBase>
  )
}
