/* eslint-disable @typescript-eslint/no-explicit-any */
import { DownloadOutlined } from '@ant-design/icons'
import { CampaignTypeEnum } from 'app/api/marketing/discount-campaign/model'
import {
  DefineApplyRuleKey,
  DefineTargetUserEnum,
  URL_FILE_TEMPLATE_PATIENT_IN_VOUCHER,
} from 'app/api/marketing/discount-voucher/constant'
import {
  EVerifyRuleKey,
  MethodIssueEnum,
  TargetUserEnum,
} from 'app/api/marketing/discount-voucher/enum'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { isEqual } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAdditionalServiceVoucherContext } from '../../../../context'
import { ModalUserDisplayLayout } from '../../../../modal'

export const InfoCustomerLayout = () => {
  const { t } = useTranslation()
  const {
    campaignType,
    file,
    listUserData,
    methodIssueVoucher,
    itemCampaign,
    verifyRuleKey,
  } = useAdditionalServiceVoucherContext()

  const renderFileName = useMemo(() => {
    if (file) {
      return file.name
    }

    return ''
  }, [file])

  return (
    <BaseSpace size={4}>
      <FormItem label={t(R.strings.apply_rule)} required name={'verifyRuleKey'}>
        <BaseSelect
          placeholder={t(R.strings.select_apply_rule)}
          options={[
            {
              label: t(DefineApplyRuleKey?.[EVerifyRuleKey.All]?.keyI18n),
              value: EVerifyRuleKey.All,
            },
            {
              label: t(
                DefineApplyRuleKey?.[EVerifyRuleKey.NewPatient]?.keyI18n,
              ),
              value: EVerifyRuleKey.NewPatient,
            },
            {
              label: t(DefineApplyRuleKey?.[EVerifyRuleKey.PatientId]?.keyI18n),
              value: EVerifyRuleKey.PatientId,
            },
          ]}
          disabled
        />
      </FormItem>

      {isEqual(methodIssueVoucher, MethodIssueEnum.Auto) &&
      !isEqual(verifyRuleKey, EVerifyRuleKey.NewPatient) ? (
        <FormItem label={t(R.strings.target_user)} required name={'targetUser'}>
          <BaseSelect
            placeholder={t(R.strings.select_target_user)}
            options={[
              {
                label: t(
                  DefineTargetUserEnum?.[TargetUserEnum.Customer]?.keyI18n,
                ),
                value: TargetUserEnum.Customer,
              },
              {
                label: t(
                  DefineTargetUserEnum?.[TargetUserEnum.VipCustomer]?.keyI18n,
                ),
                value: TargetUserEnum.VipCustomer,
              },
              {
                label: t(
                  DefineTargetUserEnum?.[TargetUserEnum.CompanyEmployee]
                    ?.keyI18n,
                ),
                value: TargetUserEnum.CompanyEmployee,
              },
            ]}
            disabled
          />
        </FormItem>
      ) : null}

      {isEqual(methodIssueVoucher, MethodIssueEnum.Auto) &&
      isEqual(verifyRuleKey, EVerifyRuleKey.PatientId) ? (
        <FormItem hiddenLabel>
          <BaseRow
            className="custom-characters-wrapper"
            gutter={[16, 8]}
            align={'middle'}
            justify={'space-between'}
            style={{
              marginLeft: 0,
              marginRight: 0,
            }}
          >
            <BaseCol>
              <BaseRow gutter={8} align={'middle'}>
                <BaseCol>
                  <BaseText
                    children={`${t(R.strings.customer_list)}:`}
                    fontWeight="medium"
                    fontSize="xs"
                    opacity="0.7"
                  />
                </BaseCol>
                {file ? (
                  <>
                    <BaseCol>
                      <ModalUserDisplayLayout
                        fileName={renderFileName}
                        users={listUserData}
                      />
                    </BaseCol>
                  </>
                ) : (
                  <BaseCol>
                    <BaseText
                      children={t(R.strings.not_select_file)}
                      opacity="0.2"
                      fontSize="xs"
                      fontWeight="medium"
                    />
                  </BaseCol>
                )}
              </BaseRow>
            </BaseCol>

            <BaseCol>
              <BaseRow gutter={16} align={'middle'}>
                <a href={URL_FILE_TEMPLATE_PATIENT_IN_VOUCHER}>
                  <BaseCol>
                    <BaseButton icon={<DownloadOutlined rev={undefined} />}>
                      {t(R.strings.file_template)}
                    </BaseButton>
                  </BaseCol>
                </a>
              </BaseRow>
            </BaseCol>
          </BaseRow>
        </FormItem>
      ) : null}

      {!isEqual(itemCampaign?.campaignType, CampaignTypeEnum.Voucher) ? (
        <FormItem
          label={t(R.strings.number_of_uses)}
          required
          name={'numberOfUses'}
        >
          <InputNumberWrapper
            placeholder={t(R.strings.enter_number_of_uses)}
            min={1}
            step={1}
            precision={0}
            disabled
          />
        </FormItem>
      ) : null}

      {isEqual(campaignType, CampaignTypeEnum.Voucher) ? (
        <BaseRow gutter={[16, 8]} align={'top'}>
          <BaseCol xl={24}>
            <FormItem
              label={t(R.strings.discount_value)}
              required
              name={'discountValue'}
            >
              <InputNumberWrapper
                placeholder={t(R.strings.enter_discount_value)}
                min={0}
                step={1}
                precision={0}
                disabled
              />
            </FormItem>
          </BaseCol>
        </BaseRow>
      ) : null}

      <BaseRow gutter={[16, 8]} align={'top'}>
        <BaseCol xl={12}>
          <BaseForm.Item
            name={'startDate'}
            required
            label={t(R.strings.start_date)}
          >
            <BaseDatePickerStyled
              format={'DD-MM-YYYY HH:mm'}
              placeholder={t(R.strings.start_date)}
              showTime
              disabled
            />
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseForm.Item
            name={'endDate'}
            label={t(R.strings.end_date)}
            required
          >
            <BaseDatePickerStyled
              format={'DD-MM-YYYY HH:mm'}
              placeholder={t(R.strings.end_date)}
              allowClear
              showTime
              disabled
            />
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>
    </BaseSpace>
  )
}

const FormItem = styled(BaseForm.Item)`
  width: 100%;
`

const InputNumberWrapper = styled(InputNumber)`
  width: 100%;
`

const BaseDatePickerStyled = styled(BaseDatePicker)`
  width: 100%;
`
