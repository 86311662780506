import { BaseSpace } from 'app/components/common/BaseSpace'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const ContentWrapper = styled(BaseSpace)`
  width: 100%;
`
export const ItemSelect = styled(BaseSpace)`
  width: 100%;
  border-radius: ${BORDER_RADIUS};
  border: 1px solid var(--neutral-black-16-color);
  padding: ${PADDING.xs};
  background-color: white;
  .label {
    color: var(--primary-color);
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.semibold};
  }
  .description {
    color: #858c94;
    font-size: ${FONT_SIZE.xxs};
    font-weight: ${FONT_WEIGHT.medium};
  }

  &:hover {
    cursor: pointer;
    background-color: var(--other-blue-color);
    .label {
      color: white;
    }
    .description {
      color: white;
    }
  }
`
