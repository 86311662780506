import { ApiClientTreatment } from 'app/service/api-service'
import { ItemBrandEndPoint } from './constant'
import {
  IParamsGetItemBrandManagement,
  IPayloadCreateItemBrandManagement,
  IPayloadUpdateItemBrandManagement,
} from './model/management'

export const requestGetItemBrandManagement = (params: IParamsGetItemBrandManagement) =>
  ApiClientTreatment.Get({
    url: ItemBrandEndPoint.ItemBrandManagement.request,
    params: { params },
  })

export const requestCreateItemBrandManagement = (
  body: IPayloadCreateItemBrandManagement,
) =>
  ApiClientTreatment.Post({
    url: ItemBrandEndPoint.ItemBrandManagement.request,
    body,
  })

export const requestUpdateItemBrandManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateItemBrandManagement
}) =>
  ApiClientTreatment.Put({
    url: `${ItemBrandEndPoint.ItemBrandManagement.request}/${id}`,
    body,
  })

export const requestDetailItemBrandManagement = (id?: string | number) =>
  ApiClientTreatment.Get({
    url: `${ItemBrandEndPoint.ItemBrandManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteItemBrandManagement = (id?: string | number) =>
  ApiClientTreatment.Delete({
    url: `${ItemBrandEndPoint.ItemBrandManagement.request}/${id}`,
    body: {},
  })

export const requestGetAllItemBrandManagement = () =>
  ApiClientTreatment.Get({
    url: `${ItemBrandEndPoint.ItemBrandManagement.request}/get-all`,
    params: {},
  })
