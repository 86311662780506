import { BaseTreeSelect } from "app/components/common/selects/BaseTreeSelect"
import styled from "styled-components"

export const BaseDebtTreeSelect = styled(BaseTreeSelect)`
  .ant-select-arrow {
    top: 50%;
  }
  width: 100%;
  .ant-select-selection-overflow-item {
    max-width: 80%;
  }
`

export const TreeSelectAll = styled.span`
  display: inline-block;
  color: #286fbe;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 10px;
`
export const TreeUnSelectAll = styled.span`
  display: inline-block;
  color: #286fbe;
  cursor: pointer;
  margin-bottom: 10px;
`
