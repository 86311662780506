import R from 'app/assets/R'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import addNew from './../../../../../../assets/images/addNew.png'
import { AddNew, AddNewImg, AddNewLabel, TimelineName } from './styles'
import { TIMESLOT_STEP } from 'app/common/config'
import { DATE } from 'app/common/constants'
import dayjs from 'dayjs'

moment.locale('vi')

export default function TimeSlotWrapper(props) {
  const { t } = useTranslation()
  const seconds =
    (moment().valueOf() - moment(props.value).valueOf()) / (60 * 1000)
  const isCurrentTimeSlot = seconds <= TIMESLOT_STEP && seconds > 0

  const timeSecondCurrent = Number(moment().format(DATE.LT).replace(':', ''))
  const timeLineCurrent = Number(
    moment(props.value).format(DATE.LT).replace(':', ''),
  )
  const isCurrentTimeLine =
    timeSecondCurrent - timeLineCurrent <= TIMESLOT_STEP &&
    timeSecondCurrent - timeLineCurrent > 0

  const isOpenTimeWeek =
    props?.children?.props?.children &&
    isCurrentTimeLine &&
    props?.view === 'week'

  const time = moment(props.value).format(DATE.HH_MM)
  const date = dayjs(props.value)
  if (isOpenTimeWeek) props?.setIsOpenTime(true)
  return React.cloneElement(
    <div
      style={{
        background: seconds > 0 ? 'rgb(244, 246, 249)' : 'initial',
        minHeight: isCurrentTimeSlot
          ? `${Number((seconds * 3).toFixed(0))}px`
          : '90px',
      }}
      className={`${!props?.children?.props?.children && seconds < 0 && 'new'}`}
    >
      {props.children}
      {isOpenTimeWeek && (
        <TimelineName
          style={{
            top: `${Number((timeSecondCurrent - timeLineCurrent) * 3).toFixed(
              0,
            )}px`,
          }}
          ref={props?.currentTimeRef}
        >
          {moment()
            .format(DATE.LT_A)
            .replace(DATE.SA, DATE.AM)
            .replace(DATE.CH, DATE.PM)}
        </TimelineName>
      )}
      {isCurrentTimeSlot && props?.view === 'day' && (
        <div
          className={`custom-time-indicator ${
            !props?.children?.props?.children ? 'now' : ''
          }`}
          style={{
            top: `${Number((seconds * 3).toFixed(0))}px`,
          }}
        >
          <div
            className="timeline"
            ref={props?.currentTimeRef}
            style={{
              top: `${Number((seconds * 3).toFixed(0))}px`,
            }}
          ></div>
          <span>
            {moment()
              .format(DATE.LT_A)
              .replace(DATE.SA, DATE.AM)
              .replace(DATE.CH, DATE.PM)}
          </span>
        </div>
      )}

      {!props?.children?.props?.children && seconds < 0 && (
        <AddNew
          className="add-new"
          onClick={() => {
            props?.setOpenCreateUpdate(true)
            props?.setAppointment({ time, date })
          }}
        >
          <span>
            <AddNewImg src={addNew} alt="" />
          </span>
          <AddNewLabel>{t(R.strings.schedule_booking)}</AddNewLabel>
        </AddNew>
      )}
    </div>,
    {
      'data-time': time,
    },
  )
}
