import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { Content } from 'antd/lib/layout/layout'
import styled from 'styled-components'

export const BaseSection = styled(Content)`
  background-color: var(--background-color);
  color: var(--primary-color);
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
`
export const Title = styled.h3`
  padding-bottom: ${PADDING.md};
  border-bottom: 1px solid var(--revenue-dashboard-border-color);
  margin-bottom: ${PADDING.md};
`
