import { MethodIssueEnum } from 'app/api/marketing/discount-voucher/enum'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSwitch } from 'app/components/common/BaseSwitch'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { getArray } from 'app/components/tables/common-table/constant'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAdditionalServiceVoucherContext } from '../../../../context'

export const CharacterLayout = () => {
  const { t } = useTranslation()
  const { methodIssueVoucher, isEditPrefix, prefixLength, prefix } =
    useAdditionalServiceVoucherContext()

  return (
    <BaseSpace size={16}>
      {isEqual(methodIssueVoucher, MethodIssueEnum.Auto) ? (
        <FormItem
          label={t(R.strings.number_of_characters)}
          required
          name="prefixLength"
        >
          <InputNumberWrapper
            min={6}
            max={25}
            placeholder={t(R.strings.enter_number_of_characters)}
            value={prefixLength}
            step={1}
            precision={0}
            disabled
          />
        </FormItem>
      ) : null}

      {isEqual(methodIssueVoucher, MethodIssueEnum.Auto) ? (
        <BaseSpace className="custom-characters-wrapper" size={0}>
          <BaseRow gutter={8} align={'middle'}>
            <BaseCol>
              <FormItem
                hiddenLabel
                style={{ marginBottom: 0 }}
                name={'isCustomPrefix'}
              >
                <BaseSwitch checked={isEditPrefix} disabled />
              </FormItem>
            </BaseCol>

            <BaseCol>
              <BaseText
                children={t(R.strings.custom_first_characters)}
                fontWeight="medium"
                opacity="0.7"
              />
            </BaseCol>
          </BaseRow>
          {!!prefixLength && (
            <BaseRow gutter={16}>
              {isEditPrefix ? (
                <BaseCol xl={6}>
                  <FormItem
                    label={`${t(R.strings.discount_code)}:`}
                    name={'prefix'}
                    required={isEditPrefix}
                  >
                    <BaseInput
                      showCount
                      placeholder={t(R.strings.enter_characters)}
                      maxLength={prefixLength - 1}
                      disabled
                    />
                  </FormItem>
                </BaseCol>
              ) : null}

              <BaseCol xl={isEditPrefix ? 18 : 24}>
                <FormItem
                  label={
                    !isEditPrefix ? `${t(R.strings.discount_code)}:` : undefined
                  }
                >
                  <BaseInput
                    value={getArray(prefixLength - (prefix?.length ?? 0))
                      .map(() => 'X')
                      .join('')}
                    disabled
                  />
                </FormItem>
              </BaseCol>
            </BaseRow>
          )}
          <BaseText children={t(R.strings.discount_code_note)} opacity="0.7" />
        </BaseSpace>
      ) : (
        <BaseSpace className="custom-characters-wrapper" size={0}>
          <FormItem
            label={`${t(R.strings.discount_code)}:`}
            name={'code'}
            required={true}
          >
            <BaseInput
              placeholder={t(R.strings.enter_characters)}
              minLength={6}
              maxLength={25}
              disabled
            />
          </FormItem>
          <BaseText children={t(R.strings.discount_code_note)} opacity="0.7" />
        </BaseSpace>
      )}

      {isEqual(methodIssueVoucher, MethodIssueEnum.Auto) ? (
        <FormItem
          label={t(R.strings.total_voucher)}
          name={'totalVoucher'}
          required
        >
          <InputNumberWrapper
            placeholder={t(R.strings.enter_quantity)}
            min={1}
            max={10000}
            step={1}
            precision={0}
            disabled
          />
        </FormItem>
      ) : null}
    </BaseSpace>
  )
}

const FormItem = styled(BaseForm.Item)`
  width: 100%;
  margin-bottom: 0px !important;
`

const InputNumberWrapper = styled(InputNumber)`
  width: 100%;
`
