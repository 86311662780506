import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseTabs } from 'app/components/common/BaseTabs'
import { useGetTotalPatientWithoutPaid } from 'app/react-query/hook/without-paid'
import { useState } from 'react'
import { HeadTabComponent } from '../../components'
import { useOrthodonticWithoutPaid } from '../../context'
import { CureIsNeededForThePatient } from '../CureIsNeededForThePatient'
import { DoctorWithOrthodonticAppointment } from '../DoctorWithOrthodonticAppointment'
import { LocationWithOrthodonticAppointment } from '../LocationWithOrthodonticAppointment'
import { PatientWithOrthodonticAppointment } from '../PatientWithOrthodonticAppointment'
import { ThePatientNeedsToAlertTheDoctor } from '../ThePatientNeedsToAlertTheDoctor'

export const TabsLayout = () => {
  const { params } = useOrthodonticWithoutPaid()

  const [tabIndex, setTabIndex] = useState(
    R.strings.orthodontic_patient_list_zero_payment,
  )

  const { data: dataTotal } = useGetTotalPatientWithoutPaid({
    ...params,
  })

  return (
    <BaseSpace>
      <BaseTabs
        defaultActiveKey={tabIndex}
        tabPosition={'top'}
        onChange={setTabIndex}
        items={[
          {
            label: (
              <HeadTabComponent
                keyTitle={R.strings.orthodontic_patient_list_zero_payment}
                total={dataTotal?.totalWithoutPaid ?? 0}
                currentTab={tabIndex}
              />
            ),
            key: R.strings.orthodontic_patient_list_zero_payment,
            children: <PatientWithOrthodonticAppointment />,
          },
          {
            label: (
              <HeadTabComponent
                keyTitle={R.strings.cure_is_needed_for_the_patient}
                total={dataTotal?.totalNeedReview ?? 0}
                currentTab={tabIndex}
              />
            ),
            key: R.strings.cure_is_needed_for_the_patient,
            children: <CureIsNeededForThePatient />,
          },
          {
            label: (
              <HeadTabComponent
                keyTitle={R.strings.the_patient_needs_to_alert_the_doctor}
                total={dataTotal?.totalWarning ?? 0}
                currentTab={tabIndex}
              />
            ),
            key: R.strings.the_patient_needs_to_alert_the_doctor,
            children: <ThePatientNeedsToAlertTheDoctor />,
          },
          {
            label: (
              <HeadTabComponent
                keyTitle={R.strings.warning_doctor}
                total={dataTotal?.totalDoctorNeedWarning ?? 0}
                currentTab={tabIndex}
              />
            ),
            key: R.strings.warning_doctor,
            children: <DoctorWithOrthodonticAppointment />,
          },
          {
            label: (
              <HeadTabComponent
                keyTitle={R.strings.warning_clinic}
                total={dataTotal?.totalClinicNeedWarning ?? 0}
                currentTab={tabIndex}
              />
            ),
            key: R.strings.warning_clinic,
            children: <LocationWithOrthodonticAppointment />,
          },
        ]}
      />
    </BaseSpace>
  )
}
