// Message code define
export const RefererReportMessageCode = {
  RefererReport: {
    successGetData: '80000',
  },
}

// Endpoint
export const RefererReportEndPoint = {
  RefererReport: {
    request: 'report/v1/patient-referer-report',
  },
}
