/* eslint-disable @typescript-eslint/no-explicit-any */
import { Rule } from 'antd/es/form'
import { IItemManagement } from 'app/api/item/model/management'
import { IProductPropertiesCombinedData } from 'app/api/product-property/model/management'

export enum ItemType {
  service = 'service',
  combo = 'combo',
  product = 'product',
  supplies = 'supplies',
  asset = 'asset',
  tools = 'tools',
  voucher = 'voucher',
  treatment = 'treatment',
}

export interface IFormData {
  _id?: string
  oldId?: string
  categoryid?: string
  createduserid?: string
  createddate?: string
  updateduserid?: string
  updatedate?: string
  changeLogs?: string
  description?: any
  executeTime?: string
  skillsInfo?: string
  itemIds?: IItemManagement[]
  productPropertiesIds?: string
  productPropertiesImages?: string
  isInventoryManagement?: boolean
  purchaseMeasurementUnitId?: string
  usingMeasurementUnitId?: string
  productPropertiesCombinedData?: IProductPropertiesCombinedData[]
  name?: string
  type?: string
  status?: string | number
  code?: string
  itemBrandId?: string
  detailImagesUrl?: string[]
  mainImagesUrl?: string
  categoryTreatmentId?: string
  serviceName?: string
  serviceCode?: string
  supplierId?: string
  statusService?: string
  doctorJobTitleLevelIds?: any

  // add new 08/05/2024
  productCode?: string
  serviceType?: string
  barcode?: string
  minimumOneTimePayment?: string
  minimumInstallmentPaymentInClinic?: string
  endOfShiftPayment?: string
  purchaseUnit?: string
  usingUnit?: string
  warrantyMonth?: string

  descriptionService?: string
  descriptionWarranty?: string
}

export interface IFormRule {
  name?: Rule[]
  categoryid?: Rule[]
  type?: Rule[]
  status?: Rule[]
  createduserid?: Rule[]
  createddate?: Rule[]
  updateduserid?: Rule[]
  updatedate?: Rule[]
  changeLogs?: Rule[]
  code?: Rule[]
  description?: Rule[]
  mainImagesUrl?: Rule[]
  detailImagesUrl?: Rule[]
  executeTime?: Rule[]
  skillsInfo?: Rule[]
  itemIds?: Rule[]
  productPropertiesIds?: Rule[]
  productPropertiesImages?: Rule[]
  itemBrandId?: Rule[]
  isInventoryManagement?: Rule[]
  purchaseMeasurementUnitId?: Rule[]
  usingMeasurementUnitId?: Rule[]
}
