import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import {
  Dates,
  FONT_SIZE,
  FONT_WEIGHT,
  FORMAT_ONLY_MONTH,
  PADDING,
} from 'parkway-web-common'
import React from 'react'
import styled from 'styled-components'
import { IFilterDataTableReport } from '../../type'

interface IProps {
  onChange?: (value: IFilterDataTableReport) => void
  formData?: IFilterDataTableReport
}

export const FilterLayout: React.FC<IProps> = ({ formData, onChange }) => {
  const onChangeYear = (value: string) => {
    onChange && onChange({ ...formData, year: value })
  }

  const onChangeMonth = (value: string) => {
    onChange && onChange({ ...formData, month: value })
  }

  const onChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange({ ...formData, keyword: e.target.value })
  }

  return (
    <BaseForm>
      <RootWrapper gutter={[16, 8]} align={'middle'}>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseInput
              value={formData?.keyword}
              placeholder={t(R.strings.enter_customer_name)}
              onChange={onChangeKeyword}
              allowClear
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <Picker
              $prefix={t(R.strings.year)}
              picker="year"
              value={
                formData?.year ? Dates.getDate(`${formData?.year}-01-01`) : null
              }
              onChange={(_, value) => onChangeYear(value)}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <Picker
              $prefix={t(R.strings.month)}  
              picker="month"
              value={
                formData?.year && formData?.month
                  ? Dates.getDate(`${formData?.year}-${formData?.month}-01`)
                  : null
              }
              onChange={(_, value) => onChangeMonth(value)}
              $prefixWidth={70}
              format={FORMAT_ONLY_MONTH}
            />
          </BaseFormItem>
        </BaseCol>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
`
const Picker = styled(BaseDatePicker)<{
  $prefix: string
  $prefixWidth?: number
}>`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  &:hover {
    border: 1px solid ${convertedVariables.neutralBlack9Color};
  }
  .ant-picker-input::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$prefixWidth ?? 50}px;
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
