import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { ModalComponent } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHook } from './hook'
import { IProps } from './type'
import { BasePagination } from 'app/components/common/BasePagination'

const listSenderJobTitleAll = [
  'Doctor',
  'Nurse',
  'Receptionist',
  'Accountant',
  'Manager',
]

export const ModalChooseSenderLayout = (props: IProps) => {
  const { t } = useTranslation()
  const {
    listSenderJobTitle,
    modalRef,
    handleSubmit,
    _onChangeListSenderJobTitle,
    page,
    pageSize,
    setPage,
    setPageSize,
  } = useHook(props)

  return (
    <ModalComponent
      ref={modalRef}
      titleModal={t(R.strings.add_sender)}
      titleOpenModal={t(R.strings.add)}
      borderRadiusButtonOpen={BORDER_RADIUS}
      borderButtonOpen={`1px solid ${convertedVariables.neutralBlack9Color}`}
      styleButtonOpen={{
        backgroundColor: convertedVariables?.backgroundColor,
      }}
      iconOpenModal={<PlusOutlinedIcon rev={undefined} />}
      handleSubmit={handleSubmit}
      renderContent={
        <BaseForm>
          <BaseSpace>
            <BaseFormItem hiddenLabel>
              <BaseInput placeholder={t(R.strings.enter_job_title)} />
            </BaseFormItem>

            <HeaderListSenderWrapper>
              <BaseCol>
                <BaseText
                  children={t(R.strings.profile_content_job_title)}
                  fontWeight="semibold"
                  opacity="0.7"
                />
              </BaseCol>
            </HeaderListSenderWrapper>

            <ListSenderWrapper>
              {listSenderJobTitleAll?.map((item, index) => {
                const isExist = listSenderJobTitle.includes(item)

                return (
                  <ItemSenderWrapper
                    key={index}
                    align={'middle'}
                    gutter={8}
                    justify={'space-between'}
                  >
                    <BaseCol>
                      <BaseText children={item} fontWeight="medium" />
                    </BaseCol>
                    <BaseCol>
                      <BaseCheckbox
                        checked={isExist}
                        onChange={() => _onChangeListSenderJobTitle(item)}
                      />
                    </BaseCol>
                  </ItemSenderWrapper>
                )
              })}
            </ListSenderWrapper>
            <FooterWrapper
              justify={'space-between'}
              align={'middle'}
              gutter={[10, 10]}
            >
              <BaseCol>
                <BaseRow gutter={[5, 5]} align={'middle'}>
                  <BaseCol>
                    <BaseText>{`${t(R.strings.total_selected)}: `}</BaseText>
                  </BaseCol>
                  <BaseCol>
                    <BaseText colorText="statesGreenColor">
                      {listSenderJobTitle?.length}
                    </BaseText>
                  </BaseCol>
                </BaseRow>
              </BaseCol>
              <BaseCol>
                <BasePagination
                  defaultCurrent={page}
                  pageSize={pageSize}
                  total={listSenderJobTitleAll?.length}
                  size='small'
                  onChange={(page: number, pageSize?: number) => {
                    if (page && pageSize) {
                      setPageSize(pageSize)
                      setPage(page)
                    }
                  }}
                  pageSizeOptions={[10, 20, 50, 100]}
                />
              </BaseCol>
            </FooterWrapper>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}

const PlusOutlinedIcon = styled(PlusOutlined)`
  svg {
    opacity: 0.7;
  }
`

const ItemSenderWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};
`

const ListSenderWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
  background-color: ${convertedVariables.neutralBlack9Color};
  border-radius: ${BORDER_RADIUS};
  margin-left: 0px !important;
  margin-right: 0px !important;
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`

const HeaderListSenderWrapper = styled(BaseRow)`
  padding-left: 32px;
`

const FooterWrapper = styled(BaseRow)`
  width: 100%;
`
