import R from 'app/assets/R'

export enum MailDashboardType {
  NEW = 1,
  IMPORTANT,
}

export interface IMailDashboard {
  id: number
  titleI18nKey: string
  count: number
  type: MailDashboardType
}

export const dataMailDashboard: IMailDashboard[] = [
  {
    id: 1,
    titleI18nKey: R.strings.new_mail,
    count: 2208,
    type: MailDashboardType.NEW,
  },
  {
    id: 1,
    titleI18nKey: R.strings.important,
    count: 88,
    type: MailDashboardType.IMPORTANT,
  },
]
