import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  backgroundColor: string
  color: string
  textKeyI18n: string
  expandIcon?: React.ReactNode
  prefixIcon?: React.ReactNode
  border?: string
}

export const TagStatusComponent = ({
  backgroundColor,
  color,
  textKeyI18n,
  expandIcon,
  prefixIcon,
  border,
}: IProps) => {
  const { t } = useTranslation()
  return (
    <RootWrapper
      $backgroundColor={backgroundColor}
      gutter={20}
      justify={'center'}
      align={'middle'}
      $border={border}
    >
      {prefixIcon}
      <BaseCol>
        <Text children={t(textKeyI18n)} $color={color} />
      </BaseCol>
      {expandIcon}
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseRow)<{
  $backgroundColor: string
  $border?: string
}>`
  background-color: ${props => props?.$backgroundColor};
  border: ${props => props?.$border};
  padding: 3px ${PADDING.xxxs};
  border-radius: ${PADDING.xxl};
  margin: unset !important;
`

const Text = styled.div<{ $color: string }>`
  color: ${props => props?.$color};
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.xs};
`
