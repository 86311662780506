import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 0px ${PADDING.md};
`

export const PlaceholderStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
