import R from 'app/assets/R'
import { initResponseFilterTableManagement } from 'app/components/tables/BaseTableManagement/FilterManagementTable/type'
import {
  RenderValueTable,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { cloneDeep, get } from 'lodash'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IGeneralGoodsManagementTable, IResponseFilter } from './type'

import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { ValueTableWrapper } from 'app/components/tables/BaseTableManagement/styles'
import { WarehouseGoodsReceiptContext } from 'app/containers/InventoryManagement/WarehousePurchaseGoodsReceipt/context'
import { InputText } from './styles'
import { IItem } from 'app/api/item/model'
import { addPrefixImage } from 'app/common/helpers'
import { SvgTrashWithoutColorIcon } from 'app/assets/svg-assets'
import { notificationController } from 'app/controllers/notification-controller'
import { requestWarehouseGoodsReceiptImport, requestWarehouseGoodsReceiptTemplate } from 'app/api/inventory-new'
import { OrderNameBox } from 'app/containers/InventoryManagement/WarehousePurchase/pages/components/CreateGoods/styles'

interface IItemEdit extends IItem {
  price?: number
}

export const useHook = () => {
  const { t } = useTranslation()

  const [filter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const { orders, setOrders } = useContext(WarehouseGoodsReceiptContext)
  const [orderEdit, setOrderEdit] = useState<
    { [key: string]: IItemEdit | null } | null | undefined
  >(null)

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.INVENTORY,
      FunctionPermissionEnum.PROVIDER_READ,
      R.strings.role,
    )
  }, [])

  const handleFinishedEdit = (event, orderEdit) => {
    if (event?.key === 'Enter') {
      const currentOrderEditKey = `${orderEdit._id}_${event?.target?.name}`
      setOrderEdit(prev => ({
        ...prev,
        [currentOrderEditKey]: null,
      }))
    }
  }

  const handleEdit = (event, orderEdit) => {
    const orderIndex = orders?.findIndex(order => order?._id === orderEdit?._id)
    const newOrders = cloneDeep(orders || [])
    newOrders[Number(orderIndex)][String(event?.target?.name)] =
      event?.target?.value
    setOrders?.(newOrders)
  }

  const downloadTemplate = async id => {
    try {
      const response = await requestWarehouseGoodsReceiptTemplate({
        purchaseOrderId: id || '',
      })
      window.open(response?.data?.Url, '_blank', 'noopener,noreferrer')
    } catch (error) {
      notificationController.error({
        message: t(R.strings.warehouse_purchase_update),
        description: get(error, 'message'),
      })
    }
  }

  const handleRemove = record => {
    setOrders?.(prev => prev?.filter(item => item?._id !== record?._id))
  }

  const upload = async (options, purchaseOrderId, callback) => {
    const { onSuccess, onError, file } = options

    const fmData = new FormData()
    fmData.append('file', file)
    if(purchaseOrderId) fmData.append('purchaseOrderId', purchaseOrderId)
    try {
      const res = await requestWarehouseGoodsReceiptImport(fmData)
      if (callback) callback(res)
      onSuccess('Ok')
    } catch (err) {
      onError({ err })
    }
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralGoodsManagementTable>({
        title: '#',
        key: 'ordinalNumber',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => (
          <RenderValueTable value={record.ordinalNumber} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralGoodsManagementTable>({
        title: t(R.strings.goods),
        key: 'name',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <OrderNameBox>
            <img src={addPrefixImage(record?.mainImagesUrl)} alt="" />
            <p>{record?.name}</p>
          </OrderNameBox>
        ),
      }),

      convertColumnTable<IGeneralGoodsManagementTable>({
        title: t(R.strings.goods_code),
        key: 'code',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.code} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralGoodsManagementTable>({
        title: t(R.strings.unit_of_measure),
        key: 'purchaseMeasurementUnit',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          return (
            <RenderValueTable
              value={record?.purchaseMeasurementUnit}
              type="Base"
            />
          )
        },
      }),

      convertColumnTable<IGeneralGoodsManagementTable>({
        title: t(R.strings.warehouse_import_amount),
        key: 'quantity',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          const currentOrderEditKey = `${record._id}_quantity`
          return (
            <div
              onClick={() =>
                setOrderEdit(prev => ({
                  ...prev,
                  [currentOrderEditKey]: record,
                }))
              }
            >
              {get(orderEdit, `${currentOrderEditKey}._id`, '') ===
              record?._id ? (
                <InputText
                  defaultValue={record?.quantity}
                  name="quantity"
                  onKeyDown={event => handleFinishedEdit(event, record)}
                  onChange={e => handleEdit(e, record)}
                />
              ) : (
                <RenderValueTable value={record?.quantity} type="Number" />
              )}
            </div>
          )
        },
      }),

      convertColumnTable<IGeneralGoodsManagementTable>({
        title: '',
        key: 'action',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <ValueTableWrapper
            justify={'end'}
            align={'middle'}
            gutter={[10, 10]}
            wrap={false}
          >
            <SvgTrashWithoutColorIcon onClick={() => handleRemove(record)} />
          </ValueTableWrapper>
        ),
      }),
    ]
    return option
  }, [t, orderEdit, orders])

  return {
    columns,
    filter,
    downloadTemplate,
    upload
  }
}
