/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteFilled, InboxOutlined } from '@ant-design/icons'
import { BaseModal } from 'app/components/common/BaseModal'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseButtonsForm } from 'app/components/common/forms/BaseButtonsForm'
import { FONT_SIZE, normFile, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useCreateVoucherContext } from '../../context'
import R from 'app/assets/R'
import { isNull } from 'lodash'
import { BaseUpload } from 'app/components/common/BaseUpload'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseButton } from 'app/components/common/BaseButton'
import { useEffect, useState } from 'react'

export const ImportFileModal = () => {
  const { t } = useTranslation()
  const { file, openImportArea, onCloseModal, onConfirmModal } =
    useCreateVoucherContext()

  const [fileSelected, setFileSelected] = useState<File | null | undefined>(
    null,
  )

  const _onConfirmModal = () => {
    if (!fileSelected) return

    onConfirmModal?.(fileSelected)
  }

  const onRemoveFile = () => {
    setFileSelected(null)
  }

  const onChangeFile = (values: { file: any }) => {
    setTimeout(() => {
      const { file } = values
      const { lastModified, name, type, originFileObj } = file
      const newFile = new File([originFileObj], name, { type, lastModified })
      setFileSelected(newFile)
    }, 500)
  }


  useEffect(() => {
    if (openImportArea) {
      const timer = setTimeout(() => {
        setFileSelected?.(file)
      }, 500)

      return () => clearTimeout(timer)
    }
  }, [file, openImportArea])

  return (
    <BaseModal
      title={t(R.strings.import_file)}
      open={openImportArea}
      onOk={_onConfirmModal}
      okButtonProps={{ disabled: isNull(fileSelected) }}
      onCancel={onCloseModal}
      okText={t(R.strings.import)}
      cancelText={t(R.strings.cancel)}
    >
      <DraggerInput
        name="dragger"
        valuePropName="file"
        getValueFromEvent={normFile}
        noStyle
      >
        <BaseUpload.Dragger
          name="files"
          onChange={onChangeFile}
          customRequest={() => undefined}
          showUploadList={false}
          accept=".xls, .xlsx"
        >
          <DraggerWrapper>
            <IconMailBox rev={undefined} size={24} />
          </DraggerWrapper>
        </BaseUpload.Dragger>
      </DraggerInput>
      {fileSelected && (
        <FileNameDisplayWrapper gutter={20} justify={'start'} align={'middle'}>
          <BaseCol>
            <span>{fileSelected?.name}</span>
          </BaseCol>
          <BaseCol>
            <BaseButton
              icon={<DeleteFilled rev={undefined} onClick={onRemoveFile} />}
            />
          </BaseCol>
        </FileNameDisplayWrapper>
      )}
    </BaseModal>
  )
}

const DraggerInput = styled(BaseButtonsForm.Item)``

const DraggerWrapper = styled.div`
  padding: ${PADDING.xxl} 0px;
  & svg {
    fill: var(--gray);
  }
`

const IconMailBox = styled(InboxOutlined)`
  & svg {
    width: ${FONT_SIZE.xxxl};
    height: ${FONT_SIZE.xxxl};
  }
`

const FileNameDisplayWrapper = styled(BaseRow)`
  color: var(--primary-color);
  padding-top: ${PADDING.xxs};
  & svg {
    fill: red;
  }
`
