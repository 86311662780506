import { InputNumber } from 'antd'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import styled from 'styled-components'

export const OrderInfoHeader = styled.div`
  margin-top: 20px;
  padding: 20px;
  gap: 20px;
  border: 0px 1px 1px 1px;
  opacity: 0px;
  border-width: 0px 1px 1px 1px;

  border-style: solid;

  border-color: var(--neutral-black-2-color);
  background: var(--inventory-bg);

  border-radius: 8px 8px 0px 0px;
`
export const OrderInfoTitle = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  padding: 0px 0px 10px 0px;
`

export const OrderInfoContent = styled.div`
  gap: 20px;
  border-color: var(--inventory-border-bg);
  background: var(--inventory-border-bg);
  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-radius: initial;
  }
  .ant-table-wrapper .ant-table {
    background: transparent;
  }

  .ant-table-header {
    border-bottom: 1px solid var(--neutral-black-2-color);
  }

  .ant-table-thead > tr > th {
    border: initial !important;
  }
  .ant-table-body table {
    border-collapse: separate;
    border-spacing: 0px 8px;
    padding: 0px 8px;
  }

  .ant-table-tbody {
    min-height: initial;
  }

  .ant-table-tbody > tr > *:first-child {
    border-radius: 8px;
  }

  .ant-table-tbody > tr > *:last-child {
    border-radius: 8px;
  }

  .ant-table-pagination.ant-pagination {
    background: white;
    padding: 10px;
    margin: 0px;
  }
`

export const CustomTableManagement = styled(BaseTableManagement)`
  .ant-table-body {
    max-height: initial;
    min-height: initial;
  }
`
export const InputText = styled(BaseInput)`
  border-color: var(--neutral-black-2-color);
  border-radius: 6px;
  &:focus {
    border: 1px solid var(--neutral-black-2-color);
  }

  .ant-input-group > .ant-input {
    border: initial;
  }

  .ant-input-group > .ant-input:first-child {
    border-right: initial !important;
  }

  .ant-input-group-addon:last-child {
    padding: 5px;
    background: initial;
    border-left: initial !important;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--checkbox-border-color);
    min-width: initial;
    border: initial;
  }
`

export const InputNumberEdit = styled(InputNumber)`
  border: initial;
  &:focus {
    border: 1px solid var(--neutral-black-2-color);
  }

  .ant-input-number-group > .ant-input-number:first-child {
    border: initial;
  }

  .ant-input-number-group-addon {
    padding: 2px;
    background: initial;
    border-left: initial !important;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--checkbox-border-color);
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`

export const TotalNetAmount = styled.div`
  div {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    color: var(--states-green-color);
  }
`

export const TotalPage = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  opacity: 0.7;
`

export const OrderInfoFooter = styled.div`
  padding: 20px;
  gap: 20px;
  border: 0px 1px 1px 1px;
  opacity: 0px;
  background: white;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  & > div {
    width: 250px;
  }
  div > span {
    width: 100px;
  }
  .ant-row {
    margin-top: 10px;
  }
`
