import { IItemManagement } from 'app/api/item/model/management'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { isEmpty, isEqual } from 'lodash'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as S from './styles'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BaseButton } from 'app/components/common/BaseButton'

interface IItemManagementCombo extends IItemManagement {
  quantity: number
}

const getListProductService = ({
  totalList,
  currentItem,
  selectedItems,
}: {
  totalList: IItemManagementCombo[]
  selectedItems: IItemManagementCombo[]
  currentItem: IItemManagementCombo
}) => {
  return totalList?.filter(item => {
    if (isEqual(item?._id, currentItem?._id)) {
      return true
    }

    return !selectedItems?.find(itm => isEqual(itm?._id, item?._id))
  })
}

export const ProductServiceInfoLayout = () => {
  const { t } = useTranslation()

  const [selectedItems, setSelectedItems] = useState<IItemManagementCombo[]>([])

  const isDisableAddProductService = useMemo(() => {
    return !!selectedItems?.find(itm => !itm?._id || isEmpty(itm?._id))
  }, [selectedItems])

  const onAddProductService = () => {
    if (isDisableAddProductService) return
    setSelectedItems(prev =>
      prev?.concat([
        {
          _id: undefined,
        } as IItemManagementCombo,
      ]),
    )
  }

  const onDeleteProductService = (index: number) => {
    if (!index) return
    setSelectedItems(prev => prev?.filter((_, idx) => isEqual(index, idx)))
  }

  const onPlusQuantity = (id?: string) => {
    setSelectedItems(prev =>
      prev?.map(item => {
        if (isEqual(item?._id, id)) {
          return {
            ...item,
            quantity: (item?.quantity ?? 1) + 1,
          }
        }
        return item
      }),
    )
  }

  const onMinusQuantity = (id?: string) => {
    setSelectedItems(prev =>
      prev?.map(item => {
        if (isEqual(item?._id, id)) {
          return {
            ...item,
            quantity: (item?.quantity ?? 1) - 1,
          }
        }
        return item
      }),
    )
  }

  return (
    <S.RootWrapper direction="vertical" size={10}>
      <BaseText
        children={t(R.strings.combo_info)}
        fontWeight="semibold"
        fontSize="md"
      />
      {selectedItems?.map((item, index) => {
        return (
          <TreatmentItem gutter={16} align={'middle'} justify={'space-between'}>
            <BaseCol xl={16}>
              <BaseRow gutter={5} align={'middle'}>
                <BaseCol xl={1}>
                  <BaseText
                    children={`${index + 1}.`}
                    fontWeight="medium"
                    fontSize="xs"
                  />
                </BaseCol>
                <BaseCol xl={23}>
                  <BaseForm.Item hiddenLabel>
                    <BaseSelect
                      value={item?._id}
                      placeholder={t(R.strings.select_service_product)}
                    >
                      {getListProductService({
                        currentItem: item,
                        selectedItems: selectedItems,
                        totalList: [],
                      })?.map((itm, idx) => {
                        return (
                          <Option
                            key={idx}
                            children={itm?.name ?? '-'}
                            value={item?._id}
                          />
                        )
                      })}
                    </BaseSelect>
                  </BaseForm.Item>
                </BaseCol>
              </BaseRow>
            </BaseCol>

            <BaseCol xl={8}>
              <BaseRow
                gutter={[8, 8]}
                align={'middle'}
                justify={'end'}
                wrap={false}
              >
                <BaseCol>
                  <BaseText
                    children={t(R.strings.quantity)}
                    fontWeight="medium"
                  />
                </BaseCol>
                <BaseCol>
                  <BaseButton
                    type="text"
                    onClick={() => onMinusQuantity(item?._id)}
                  >
                    <MinusCircleOutlined rev={undefined} />
                  </BaseButton>
                </BaseCol>
                <BaseCol xl={8}>
                  <InputNumberStyled value={item?.quantity ?? 1} min={1} />
                </BaseCol>
                <BaseCol>
                  <BaseButton
                    type="text"
                    onClick={() => onPlusQuantity(item?._id)}
                  >
                    <PlusCircleOutlined rev={undefined} />
                  </BaseButton>
                </BaseCol>

                <LineWrapper />

                <BaseCol>
                  <BaseButton
                    onClick={() => onDeleteProductService?.(index)}
                    icon={<SvgDeleteDesignIcon />}
                    type="text"
                  />
                </BaseCol>
              </BaseRow>
            </BaseCol>
          </TreatmentItem>
        )
      })}
      <ButtonAddProductService
        align={'middle'}
        gutter={[16, 8]}
        onClick={onAddProductService}
        $isDisable={isDisableAddProductService}
      >
        <BaseCol>
          <PlusOutlined rev={undefined} />
        </BaseCol>
        <BaseCol>
          <BaseText
            children={t(R.strings.add_product_service)}
            colorText={
              isDisableAddProductService
                ? 'disabledColor'
                : 'collapseBackgroundColor'
            }
            fontWeight="medium"
            fontSize="xs"
          />
        </BaseCol>
      </ButtonAddProductService>
    </S.RootWrapper>
  )
}

const TreatmentItem = styled(BaseRow)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  padding: ${PADDING.xxs};
  border-radius: ${BORDER_RADIUS};
  margin-left: 0px !important;
  margin-right: 0px !important;

  .ant-form-item {
    margin-bottom: 0px;
  }
`

const ButtonAddProductService = styled(BaseRow)<{ $isDisable?: boolean }>`
  width: fix-content !important;
  cursor: pointer;
  svg {
    fill: ${props =>
      props?.$isDisable
        ? convertedVariables.disabledColor
        : convertedVariables.collapseBackgroundColor};
  }
`

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`
const LineWrapper = styled.div`
  width: 1px;
  height: 15px;
  background-color: ${convertedVariables.neutralBlack2Color};
`
