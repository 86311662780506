/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  onChangeSelect?: (data: any) => void
  index: number
  placeholderSelectKeyI18n?: string
  valueSelect?: string
  listSelect?: string[]
  amount: number
  onChangeAmount?: (amount: number) => void
  onDelete?: () => void
  isDisableDeleteButton?: boolean
}

export const PaymentMethodSelectComponent = ({
  onChangeSelect,
  index,
  placeholderSelectKeyI18n,
  valueSelect,
  listSelect,
  onChangeAmount,
  amount,
  onDelete,
  isDisableDeleteButton,
}: IProps) => {
  const { t } = useTranslation()
  return (
    <ItemPaymentMethodWrapper
      gutter={15}
      wrap={false}
      align={'middle'}
      justify={'space-between'}
    >
      <BaseCol>
        <BaseText children={`${index}.`} />
      </BaseCol>
      <BaseCol xl={11}>
        <SelectWrapper
          onChange={value => onChangeSelect?.(value as any)}
          placeholder={
            placeholderSelectKeyI18n ? t(placeholderSelectKeyI18n) : undefined
          }
          value={valueSelect}
        >
          {listSelect?.map((item, index) => {
            return (
              <Option value={item} key={index}>
                {t(item)}
              </Option>
            )
          })}
        </SelectWrapper>
      </BaseCol>
      <BaseCol xl={11}>
        <BaseRow
          gutter={15}
          align={'middle'}
        >
          <BaseCol>
            <BaseText children={t(R.strings.amount)} fontSize="xs" />
          </BaseCol>
          <BaseCol style={{ display: 'flex', flex: 1 }}>
            <InputNumberWrapper
              value={amount}
              min={0}
              onChange={value => onChangeAmount?.(value as number)}
            />
          </BaseCol>
        </BaseRow>
      </BaseCol>
      <BaseCol>
        <BaseButton
          onClick={onDelete}
          disabled={isDisableDeleteButton}
          type="text"
          icon={<SvgDeleteDesignIcon />}
        />
      </BaseCol>
    </ItemPaymentMethodWrapper>
  )
}

const SelectWrapper = styled(BaseSelect)`
  .ant-select-arrow {
    top: 50%;
  }
  width: 100%;
`

const InputNumberWrapper = styled(InputNumber)`
  width: 100%;
`

export const ItemPaymentMethodWrapper = styled(BaseRow)`
  padding: ${PADDING.xxxs} ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  background-color: #F6F6F6; //need change
  width: 100%;
  margin: 0px !important;
  border: 1px solid ${convertedVariables.neutralBlack2Color};
`
