import { KeyImport } from 'app/api/import/constant'
import { ParamsImportType } from 'app/api/import/model'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertToFormDateImport = (data: ParamsImportType) => {
  const { file, periodtype, importtype } = data
  const formData = new FormData()
  formData.append(KeyImport.All.file, file)
  formData.append(KeyImport.All.importType, importtype)
  formData.append(KeyImport.All.periodType, periodtype)
  return formData
}

export const handleDownloadFile = async ({
  fileUrl,
  fileName,
  actionSuccess,
}: {
  fileUrl: string
  fileName: string
  actionSuccess?: () => void
}) => {
  try {
    const a = document.createElement('a')
    a.href = fileUrl
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    actionSuccess?.()
  } catch (error) {
    console.error('Error downloading file:', error)
  }
}
