import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTablePaymentTypeHook } from './PaymentType/hook'
import { initialPagination } from './common-model'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { FunctionPermissionEnum, ModulePermissionEnum } from 'app/common/permission-module'
import R from 'app/assets/R'

export const useTableHook = () => {
  const { t } = useTranslation()

  const [filterResponse, setFilterResponse] = useState<IResponseFilter>()

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.ACCOUNTING,
      FunctionPermissionEnum.READ_BRACES_TRAY,
      R.strings.role,
    )
  }, [])

  const PaymentTypeHook = useTablePaymentTypeHook(filterResponse)

  const expandedRowKeys = useMemo(() => {
    return PaymentTypeHook.expandedRowKeys
  }, [PaymentTypeHook])

  const tableData = useMemo(() => {
    return PaymentTypeHook.tableData
  }, [PaymentTypeHook])

  const handleExpand = useMemo(() => {
    return PaymentTypeHook.handleExpand
  }, [PaymentTypeHook])

  const onClickExport = useMemo(() => {
    return PaymentTypeHook.onClickExport
  }, [PaymentTypeHook])

  const handleChangeFilter = filter => {
    setFilterResponse(filter)
    PaymentTypeHook?.fetch(initialPagination, filter)
  }

  const columns = useMemo(() => {
    return PaymentTypeHook.columns
  }, [t])

  const handleTableChange = pagination => {
    PaymentTypeHook?.fetch(pagination, filterResponse)
  }

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleChangeFilter,
    filterResponse,
    onClickExport,
  }
}
