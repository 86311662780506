export interface IDataTable {
  stt: number
  name?: string
  date?: string
  key: string
  status?: StatusDataTable
}

export enum StatusDataTable {
  Doing = 1,
  Pending = 2
}