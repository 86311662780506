import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'
import { MailDashboardType } from './data'

export const RootWrapper = styled.div`
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  background-color: var(--white);
`
export const HeadWrapper = styled(BaseRow)`
  padding-bottom: ${PADDING.md};
`

export const TitleText = styled.span`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
`
export const DetailWrapper = styled.span`
  color: var(--other-blue-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
`
export const ItemWrapper = styled(BaseCol)<{ type: MailDashboardType }>`
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.xxs};
  background-color: ${props => {
    switch (props.type) {
      case MailDashboardType.NEW:
        return 'var(--states-green-light-color)'
      case MailDashboardType.IMPORTANT:
        return 'var(--states-orange-light-color)'
      default:
        return 'var(--states-green-light-color)'
    }
  }};
  width: 45%;
`
export const HeadLeftItem = styled.div<{ type: MailDashboardType }>`
  background-color: ${props => {
    switch (props.type) {
      case MailDashboardType.NEW:
        return 'var(--states-green-color)'
      case MailDashboardType.IMPORTANT:
        return 'var(--states-orange-color)'
      default:
        return 'var(--states-green-color)'
    }
  }};
  width: 4px;
  height: 10px;
  border-radius: ${BORDER_RADIUS};
`
export const HeadTextItem = styled.span<{ type: MailDashboardType }>`
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.semibold};
  margin-left: ${PADDING.xxxxs};
  color: ${props => {
    switch (props.type) {
      case MailDashboardType.NEW:
        return 'var(--states-green-color)'
      case MailDashboardType.IMPORTANT:
        return 'var(--states-orange-color)'
      default:
        return 'var(--states-green-color)'
    }
  }};
`

export const CountTextItem = styled.span`
  margin-top: ${PADDING.md};
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
`
