import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit'
import { IProfile } from 'app/api/profile/model/management'
import { IUser } from 'app/model/user.model'
import { getUserStorage } from 'app/service/storage/storage-service'

export interface UserState {
  user: IUser | null
  profile?: IProfile
}

const initialState: UserState = {
  user: getUserStorage(),
}

export const setUser = createAction<PrepareAction<IUser>>(
  'user/setUser',
  newUser => {
    // persistUser(newUser);
    return {
      payload: newUser,
    }
  },
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeProfile: (state, action) => {
      state.profile = action.payload
    },
    resetProfile: state => {
      state.user = null
      state.profile = undefined
    },
  },
  extraReducers: builder => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload
    })
  },
})

export const { resetProfile, changeProfile } = userSlice.actions

export default userSlice.reducer
