import { ApiClientTreatment } from 'app/service/api-service'
import { EndPoint } from '../constant'
import { IParamsGetWarehouseGoodsReceiptManagement, IPayloadCreateWarehouseGoodsReceiptManagement, IPayloadUpdateWarehouseGoodsReceiptManagement } from '../model/warehouse-goods-receipt'



export const requestGetWarehouseGoodsReceiptManagement = (
  params: IParamsGetWarehouseGoodsReceiptManagement,
) =>
  ApiClientTreatment.Get({
    url: EndPoint.WarehouseGoodsReceiptManagement.request,
    params: { params },
  })

export const requestCreateWarehouseGoodsReceiptManagement = (
  body: IPayloadCreateWarehouseGoodsReceiptManagement,
) =>
  ApiClientTreatment.Post({
    url: EndPoint.WarehouseGoodsReceiptManagement.request,
    body,
  })

export const requestUpdateWarehouseGoodsReceiptManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateWarehouseGoodsReceiptManagement
}) =>
  ApiClientTreatment.Patch({
    url: `${EndPoint.WarehouseGoodsReceiptManagement.request}/${id}`,
    body,
  })

export const requestDetailWarehouseGoodsReceiptManagement = (
  id?: string | number,
) =>
  ApiClientTreatment.Get({
    url: `${EndPoint.WarehouseGoodsReceiptManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteWarehouseGoodsReceiptManagement = (
  id?: string | number,
) =>
  ApiClientTreatment.Delete({
    url: `${EndPoint.WarehouseGoodsReceiptManagement.request}/${id}`,
    body: {},
  })

export const requestExportWarehouseGoodsReceiptManagement = (
  params: IParamsGetWarehouseGoodsReceiptManagement,
) =>
  ApiClientTreatment.Get({
    url: `${EndPoint.WarehouseGoodsReceiptManagement.request}/export`,
    params: { params },
  })

