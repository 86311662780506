import { IParamsGetReport04 } from 'app/api/report/model/report-04'
import { getStartEndDateOfWeek } from 'app/common/helpers'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { _DEV_ } from 'app/constant'
import { initFilterTable } from 'app/hook'
import { isEqual } from 'lodash'
import { Pagination, initialPagination } from 'parkway-web-common'
import { useState } from 'react'
import { getDataReport04FromApi, useCommonHook } from './common-hook'
import { IDataReport004, IFilterReport04 } from './common-model'
import { totalKeyReport04 } from './constant'

export const useTableHook = () => {
  const [filterResponse, setFilterResponse] = useState<IFilterReport04>({
    ...initFilterTable(),
    selectFilterType: 'WEEK',
    quarter: 1,
  })

  const getDataWeekly = async (
    pagination: Pagination,
    params: IFilterReport04,
  ) => {
    _DEV_ && console.log({ params })

    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IDataReport004>
    try {
      let currentParams: IParamsGetReport04 = {
        year: params?.year ?? filterResponse.year,
      }

      const type = params?.selectFilterType ?? filterResponse?.selectFilterType

      switch (type) {
        case 'WEEK': {
          const weekOfMonth = getStartEndDateOfWeek({
            date:
              params?.week?.startDate ??
              filterResponse?.week?.startDate ??
              '01',
            month: params?.month ?? filterResponse?.month ?? '',
            year: params?.year ?? filterResponse.year ?? '',
          })

          currentParams = {
            ...currentParams,
            week: weekOfMonth?.noCurrentWeekOfMonth.toString(),
            month: params?.month ?? filterResponse?.month,
          }

          break
        }
        case 'MONTH': {
          currentParams = {
            ...currentParams,
            month: params?.month ?? filterResponse?.month,
          }

          break
        }
        case 'QUARTER': {
          currentParams = {
            ...currentParams,
            quarter: (params?.quarter ?? filterResponse?.quarter)?.toString(),
          }

          break
        }
      }

      let key = totalKeyReport04

      if (params?.service) {
        currentParams = {
          ...currentParams,
          service: params?.service,
        }
        key = params?.service
      }

      const newData: IDataReport004[] =
        (await getDataReport04FromApi(currentParams, key)) ?? []

      return {
        ...initData,
        data: newData,
      }
    } catch (e) {
      _DEV_ && console.log({ e })

      return initData
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    fetch,
    fetchLoading,
    handleExpandArea,
    expandedAreaKeys,
  } = useBaseTableReportHook({ getTreeTableData: getDataWeekly })

  const { columns, exportExcel, data } = useCommonHook({
    expandedRowKeys,
    fetch,
    fetchLoading,
    handleExpand,
    currentFilter: filterResponse,
    dataTable: tableData?.data,
    expandedAreaKeys,
    handleExpandArea,
  })

  const handleChangeFilter = ({
    month,
    quarter,
    year,
    selectFilterType,
    week
  }: IFilterReport04) => {
    const newData = { month, quarter, year, selectFilterType, week}
    const oldData = {
      month: filterResponse.month,
      quarter: filterResponse.quarter,
      year: filterResponse.year,
      selectFilterType: filterResponse.selectFilterType,
      week: filterResponse.week
    }

    setFilterResponse(filter => ({
      ...filter,
      ...newData,
    }))

    if (!isEqual(newData, oldData)) {
      _DEV_ && console.log("handleChangeFilter", fetch)
      fetch(initialPagination, {
        ...filterResponse,
        ...newData,
      })
    }
  }

  const handleChangeService = (service?: string) => {
    _DEV_ && console.log('handleChangeService', service)

    setFilterResponse(prev => ({
      ...prev,
      service,
    }))
    if (!isEqual(service, filterResponse?.service)) {
      fetch(initialPagination, {
        ...filterResponse,
        service,
      })
    }
  }

  return {
    columns,
    tableData: {
      ...tableData,
      data,
    },
    handleExpand,
    expandedRowKeys,
    handleChangeFilter,
    filterResponse,
    exportExcel,
    handleChangeService,
  }
}
