import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { BaseSpace } from 'app/components/common/BaseSpace'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS } from 'parkway-web-common'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHookTable } from './hook'
import { FilterLayout, VoucherTable } from './layouts'
import { IDataTable } from './type'

const ReferralVoucherUserStatisticsContainer = () => {
  const { t } = useTranslation()
  const {
    data,
    columns,
    pagination,
    loading: isLoading,
    handleTableChange,
    exportVoucherCode,
    total,
    filter,
    onChangeFilter,
    expandedRowKeys,
    handleExpand,
  } = useHookTable()

  const { user, isHavePermissionFunctionAndGoBack, isHavePermissionFunction } =
    useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.READ_LIST_VOUCHER_CODE,
      R.strings.voucher_code_list,
    )
  }, [user])

  return (
    <S.BaseManagementWrapper>
      <HeaderPage
        title={t(R.strings.referral_voucher_user_statistics)}
        isExport={isHavePermissionFunction(
          ModulePermissionEnum.MARKETING,
          FunctionPermissionEnum.EXPORT_LIST_VOUCHER_CODE,
        )}
        onPressExport={exportVoucherCode}
      />
      <RootWrapper size={8}>
        <FilterLayout filter={filter} onChangeFilter={onChangeFilter} />
        <BaseTableWrapper
          columns={columns}
          dataSource={data}
          pagination={{
            ...pagination,
            total,
          }}
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            expandIcon: () => null,
            showExpandColumn: false,
            expandedRowRender: (record: IDataTable) => (
              <VoucherTable vouchers={record?.vouchers ?? []} />
            ),
          }}
          loading={isLoading}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
        />
      </RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default ReferralVoucherUserStatisticsContainer

const RootWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
`

const BaseTableWrapper = styled(BaseTableManagement)`
  tr.ant-table-expanded-row > td {
    background: ${convertedVariables.backgroundColor};
  }

  tr.ant-table-expanded-row:hover > td {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell {
    border: 0px !important;
    border-bottom: 0.5px solid ${convertedVariables.neutralBlack2Color} !important;
  }

  .all-column:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell-row-hover:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor} !important;
  }

  .ant-table-tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed
    > .ant-table-wrapper
    .ant-table {
    margin-block: -16px;
    margin-inline: 32px -16px;
  }

  .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-left-radius: 0px !important;
  }

  .ant-table-thead > tr > th {
    color: rgba(0, 34, 68, 0.7) !important;
    font-weight: 600 !important;
  }

  .small-column {
    min-width: 10px !important;
  }
`
