import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import BookingWebsiteReportContainer from 'app/containers/CustomerCare/BookingWebsiteReport'

const BookingWebsiteReportPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.booking_website_report)}</PageTitle>
      <BookingWebsiteReportContainer />
    </>
  )
}
const BookingWebsiteReportPage = memo(BookingWebsiteReportPageCpn, isEqual)

export default BookingWebsiteReportPage
