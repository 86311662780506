import R from 'app/assets/R'
import { SvgOrganizationalIcon, SvgSelectListIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { isEqual } from 'lodash'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  LAYOUT,
  PADDING,
} from 'parkway-web-common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ListLayout, TreeLayout } from './layouts'
import { DisplayType } from './type'

export const OrganizationalChartContainer = () => {
  const { t } = useTranslation()
  const [typeList, setTypeList] = useState(DisplayType.LIST)

  const onClickSetTypeList = () => {
    setTypeList(DisplayType.LIST)
  }

  const onClickSetTypeThree = () => {
    setTypeList(DisplayType.TREE)
  }

  return (
    <RootWrapper>
      <HeadPageWrapper justify={'space-between'} align={'middle'}>
        <HeadingTitlePage>{t(R.strings.organizational_chart)}</HeadingTitlePage>
        <SelectionWrapper gutter={10} align={'middle'}>
          <BaseCol>
            <ButtonSelection onClick={onClickSetTypeList}>
              <ListSelectIcon $isList={isEqual(typeList, DisplayType.LIST)} />
            </ButtonSelection>
          </BaseCol>
          <CenterLineSelection />
          <BaseCol>
            <ButtonSelection onClick={onClickSetTypeThree}>
              <BlockSelectIcon $isBlock={isEqual(typeList, DisplayType.TREE)} />
            </ButtonSelection>
          </BaseCol>
        </SelectionWrapper>
      </HeadPageWrapper>

      {isEqual(typeList, DisplayType.LIST) ? <ListLayout /> : <TreeLayout />}
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  padding: ${PADDING.md};
`

const HeadPageWrapper = styled(BaseRow)`
  padding-bottom: ${LAYOUT.desktop.headerPadding};
`

const HeadingTitlePage = styled.p`
  font-size: ${FONT_SIZE.xxl};
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.bold};
`

const SelectionWrapper = styled(BaseRow)`
  background-color: var(--white);
  border-radius: ${BORDER_RADIUS};
`

const ButtonSelection = styled.div`
  background-color: var(--white);
  border: none;
  cursor: pointer;
  padding: ${PADDING.xxxxs};
  transition: cursor 0.3s ease;
  &:hover {
    cursor: pointer;
  }
`

const BlockSelectIcon = styled(SvgOrganizationalIcon)<{
  $isBlock?: boolean
}>`
  path {
    fill: ${props => (props?.$isBlock ? '#216AE2' : 'var(--primary-color)')};
  }
`

const ListSelectIcon = styled(SvgSelectListIcon)<{
  $isList?: boolean
}>`
  & > g > path {
    stroke: ${props => (props?.$isList ? '#216AE2' : 'var(--primary-color)')};
  }
`

const CenterLineSelection = styled.div`
  width: 1px;
  height: 10px;
  background-color: #d9d9d9; //need change
`
