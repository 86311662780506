import { Rule } from 'antd/es/form'
import { IProvider } from 'app/api/inventory/model/management'



export interface IFormRule {
  name?: Rule[]
  status?: Rule[]
  bankinfo?: Rule[]
  contact?: Rule[]
  TIN?: Rule[]
  code?: Rule[]
}

export const initValues: IProvider = {
  _id: '',
  name: '',
}

export enum StatusProviderEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
  deleted = 'deleted',
}

export type StatusProviderType = keyof typeof StatusProviderEnum

export interface IPropsUpdateProvider {
  fetchTable: () => void
  id?: string | number
  provider: IProvider
}
