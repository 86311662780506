/* eslint-disable @typescript-eslint/no-explicit-any */
import { StatusEnum } from 'app/common/enum'
import { IMetadata, ResponseType } from 'parkway-web-common'

export interface IJobTitleManagement {
  _id?: string
  code?: string
  jobTitleLevelId?: string
  jobTitleIdManagement?: string | null
  jobTitleGroupId?: string
  name?: string
  description?: string
  jobTitleLevelName?: string
  status?: number
  createddate?: string
  hasTimekeeping?: boolean
  dayOffType?: EDayOffType
  totalDynamicDayOffInWeek?: number
  staticDayOffInWeek?: Array<number>
}

export enum EDayOffType {
  Dynamic = 'DYNAMIC',
  Static = 'STATIC',
}

export type IResponseGetJobTitleManagement = ResponseType<{
  rows: IJobTitleManagement[]
  metadata: IMetadata
}>

export interface IParamsGetJobTitleManagement {
  keyword?: string
  status?: StatusEnum[]
  pageSize?: number
  page?: number
}

export interface IPayloadCreateJobTitleManagement {
  code?: string
  jobTitleLevelId?: string
  name?: string
  description?: string
  status?: StatusEnum
  jobTitleIdManagement?: string
  hasTimekeeping?: boolean
  jobTitleGroupId?: string
  dayOffType?: EDayOffType
  staticDayOffInWeek?: Array<number>
  totalDynamicDayOffInWeek?: number
}

export interface IPayloadUpdateJobTitleManagement {
  code?: string
  jobTitleLevelId?: string
  jobTitleIdManagement?: string | null
  jobTitleGroupId?: string
  name?: string
  description?: string
  status?: StatusEnum
  _id?: string
  hasTimekeeping?: boolean
  dayOffType?: EDayOffType
  staticDayOffInWeek?: Array<number>
  totalDynamicDayOffInWeek?: number
}

export interface IJobTitle {
  _id?: string
  name?: string
  jobTitleGroupId?: string
}
