import { memo } from 'react'
import { isEqual } from 'lodash'
import { ReportContainer } from 'app/containers/Report'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'

const ReportPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.report)}</PageTitle>
      <ReportContainer />
    </>
  )
}
const ReportPage = memo(ReportPageCpn, isEqual)

export default ReportPage
