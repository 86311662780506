export interface IResponseFilter {
  status?: number
  search?: string
}

export interface IFilterProps {
  handleChangeFilter?: (data: IResponseFilter) => void
  filterData?: IResponseFilter
}

export const initResponseFilterTableManagement = {
  search: '',
}
