/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestBracesTray } from 'app/api/accounting'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import { Pagination } from 'parkway-web-common'
import { useCommonHook } from '../common-hook'
import { initialPagination } from './data'
import { IBracesTrayParams, IBracesTrayResponse } from '../type'

export const useTablePaymentTypeHook = props => {
  const getData = async (
    pagination: Pagination,
    params: IBracesTrayParams,
  ): Promise<any> => {
    try {
      if (params?.startDate && params?.endDate) {
        const response: IBracesTrayResponse = await requestBracesTray({
          page: (pagination?.current || 0) - 1,
          pageSize: pagination?.pageSize,
          startDate: String(params?.startDate),
          endDate: String(params?.endDate),
          paidTypes: params?.paidType?.join(','),
          keyword: params?.search,
        })
        return {
          pagination: { ...pagination, total: response?.metadata?.total },
          data: response?.data?.map(patient => ({
            ...patient,
            key: patient?._id,
          })),
        }
      }

      return {
        pagination: pagination ?? initialPagination,
        data: [],
      }
    } catch (error) {
      return {
        pagination: pagination ?? initialPagination,
        data: [],
      }
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,

    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({ getTreeTableData: getData })

  const { columns, onClickExport } = useCommonHook({
    expandedRowKeys,
    fetch,
    fetchLoading,
    handleExpand,
    currentFilter: props,
  })

  const _onClickExport = async params => {
    onClickExport(params)
  }

  return {
    columns,
    tableData,
    handleExpand,
    expandedRowKeys,

    handleResetExpand,
    fetch,
    fetchLoading,
    onClickExport: _onClickExport,
  }
}
