import { DualAxes } from '@ant-design/plots'
import { StartIcon } from 'app/common/icons'
import * as S from './styles'
import { t } from 'i18next'
import R from 'app/assets/R'
import { IResponseFilter } from '../FilterSurveyReport/type'
import { requestGetReportAverageByDay } from 'app/api/survey'
import { SurveyMessageCode } from 'app/api/survey/constant'
import { isEmpty, isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useState } from 'react'

interface AverageByDay {
  totalItems: number
  dayMonth: string
  value: number
  type: string
}

interface IResponseReportAverageByDay {
  average?: Array<AverageByDay>
  max?: Array<AverageByDay>
  current?: Array<AverageByDay>
}

const lineWidth = 2
const lineColor = {
  current: '#FA9F34',
  average: '#629DAA',
  top: '#9747FF',
}

enum TypeReportLineEnum {
  current = 'Phòng khám đã chọn',
  average = 'Trung bình tất cả phòng khám',
  top = 'Đánh giá cao nhất',
}

const convertToLineColor = type => {
  switch (type) {
    case TypeReportLineEnum.average:
      return lineColor.average
    case TypeReportLineEnum.current:
      return lineColor.current

    default:
      return lineColor.top
  }
}

export function ChartByDay({ location, clinic, area }: IResponseFilter) {
  const [reportAverageByDay, setReportAverageByDay] =
    useState<IResponseReportAverageByDay>({})

  const fetchReportAverageByDay = async () => {
    try {
      const param = {
        location: location ?? null,
      }

      const res: ResponseType<IResponseReportAverageByDay> =
        await requestGetReportAverageByDay(param)
      if (
        isEqual(res?.msgcode, SurveyMessageCode.SurveyReport.successGetData)
      ) {
        const data: IResponseReportAverageByDay = res?.data || {}
        setReportAverageByDay(data)
      }
    } catch (error) {
      console.error({ error })
    }
    return []
  }

  useEffect(() => {
    fetchReportAverageByDay()
  }, [clinic, area])

  const averageData = reportAverageByDay?.average ?? []

  const currentData = reportAverageByDay?.current ?? []

  const currentFollowByAverage = averageData.map(item => {
    const current = currentData.find(
      current => current.dayMonth === item.dayMonth,
    )
    return {
      dayMonth: item.dayMonth,
      value: current?.value ?? null,
      type: current?.type ?? TypeReportLineEnum.current,
    }
  })

  const topData = reportAverageByDay?.max ?? []

  const averageCurrentData = [...averageData, ...currentFollowByAverage]
  const lineData = [...averageCurrentData, ...topData]

  const config = {
    scrollbar: {
      x: {
        ratio: 0.8,
        scrollable: true,
        slidable: true,
      },
    },
    axis: {
      x: {
        label: true,
      },
      y: {
        labelFormatter: datum => {
          let labelTitle = ''
          switch (datum) {
            case 0:
              labelTitle = t(R.strings.survey_report_content_date)
              break

            case 6:
              labelTitle = t(R.strings.survey_report_content_start_no)
              break

            default:
              labelTitle = `${datum} ${t(R.strings.survey_report_content_star)}`
              break
          }

          return labelTitle
        },
      },
    },
    legend: {
      color: {
        layout: {
          justifyContent: 'center',
          alignItems: 'flex-end',
          flexDirection: 'column',
        },
      },
    },
    xField: 'dayMonth',
    children: [
      {
        data: averageCurrentData,
        type: 'interval',
        yField: 'value',
        colorField: 'type',
        style: { maxWidth: 28 },
        scale: {
          y: { domainMin: 0, domainMax: 6 },
        },
        interaction: {
          tooltip: {
            render: (event, { items, title }) => {
              const dataRender = !isEmpty(currentData)
                ? currentData
                : averageData

              const filterData = !isEmpty(currentData)
                ? TypeReportLineEnum.current
                : TypeReportLineEnum.average

              const getTotal = dataRender?.find(
                // eslint-disable-next-line no-use-before-define
                current => current.dayMonth === title,
              )
              const current = items.find(item => item.name === filterData)

              return (
                <S.TooltipWrapper>
                  <S.TooltipHeader>
                    <S.TooltipStar>
                      <StartIcon />
                    </S.TooltipStar>
                    <S.TooltipTitleStar>
                      <S.TooltipNumberStar>
                        {current?.value}
                      </S.TooltipNumberStar>{' '}
                      {t(
                        R.strings
                          .survey_report_content_chart_by_day_label_header,
                      )}
                    </S.TooltipTitleStar>
                  </S.TooltipHeader>
                  <S.TooltipFooter>
                    <div>
                      {t(
                        R.strings
                          .survey_report_content_chart_by_day_label_footer,
                      )}
                    </div>
                    <S.TooltipTotal>{getTotal?.totalItems ?? 0}</S.TooltipTotal>
                  </S.TooltipFooter>
                </S.TooltipWrapper>
              )
            },
          },
        },
      },
      {
        data: lineData,
        type: 'line',
        yField: 'value',
        colorField: 'type',
        style: {
          lineWidth,
          stroke: d => {
            return convertToLineColor(d[0].type)
          },
        },
        axis: { y: false },
        shapeField: 'smooth',
        scale: {
          y: { domainMin: 0, domainMax: 6 },
          series: { independent: true },
        },
        interaction: {
          tooltip: {
            crosshairs: false,
            marker: false,
          },
        },
      },
    ],
    theme: {
      category10: ['#FFF5E5', '#639AFF', '#FA9F34', '#629DAA'],
    },
  }

  return <DualAxes {...config} />
}
