import R from 'app/assets/R'
import { EWarehouseInventoryColumnType } from '../../type'

export enum EWarehouseInventoryType {
  GOODS_RECEIPT = 'GOODS_RECEIPT',
  TRANSFERRED_GOODS = 'TRANSFERRED_GOODS',
}

export const WarehouseInventoryType  = {
  [EWarehouseInventoryType.GOODS_RECEIPT]:  R.strings.goods_receipt,
  [EWarehouseInventoryType.TRANSFERRED_GOODS]: R.strings.transferred_goods
}
export interface IWarehouseInventoryQuantityItem {
  data?: [
    {
      warehouseGoodsReceiptId?: string
      createdAt?: Date
      quantity?: string
      type?: string
    },
  ]
  title?: string,
  type: EWarehouseInventoryColumnType,
  warehouseId?: string,
  productId?: string,
}
