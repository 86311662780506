import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import MaterialCategoryContainer from 'app/containers/InventoryManagement/OldInventory/MaterialCategory'

const MaterialCategoryContainerPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.inventory_management)}</PageTitle>
      <MaterialCategoryContainer />
    </>
  )
}
const MaterialCategoryContainerPage = memo(MaterialCategoryContainerPageCpn, isEqual)

export default MaterialCategoryContainerPage
