import { CampaignTypeEnum } from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Loading } from 'app/components/common/Loading'
import { PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAdditionalServiceCampaignContext } from '../context'
import { ContentLayout } from './Contents'
import { InfoCampaignLayout } from './InfoCampaign'
import HeaderPage from 'app/components/header/components/HeaderPage'
import * as S from 'app/components/tables/BaseTableManagement/styles'

export const RootLayout = () => {
  const { t } = useTranslation()
  const {
    form,
    handleSubmit,
    isDisableSubmit,
    isLoadingSubmit,
    handleGoBack,
    isLoading,
    itemCampaign,
  } = useAdditionalServiceCampaignContext()

  return (
    <S.BaseManagementWrapper>
      <HeaderPage
        titleI18nKey={(() => {
          switch (itemCampaign?.campaignType) {
            case CampaignTypeEnum.TotalBill:
              return R.strings.additional_bill_discount

            default:
              return R.strings.additional_campaign
          }
        })()}
      />
      <BaseForm
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          campaignType: CampaignTypeEnum.Treatments,
        }}
      >
        <RootContentWrapper>
          <InfoCampaignLayout />
          <ContentLayout />
          <BaseRow justify={'end'} align={'middle'}>
            <BaseCol>
              <BaseRow gutter={[16, 8]} align={'middle'}>
                <BaseCol>
                  <BaseButton onClick={handleGoBack}>
                    {t(R.strings.cancel)}
                  </BaseButton>
                </BaseCol>
                <BaseCol>
                  <BaseButton
                    onClick={form?.submit}
                    disabled={isDisableSubmit}
                    loading={isLoadingSubmit}
                  >
                    {t(R.strings.additional)}
                  </BaseButton>
                </BaseCol>
              </BaseRow>
            </BaseCol>
          </BaseRow>
          {isLoading && <Loading isAbsolute size="small" />}
        </RootContentWrapper>
      </BaseForm>
    </S.BaseManagementWrapper>
  )
}

const RootContentWrapper = styled(BaseSpace)`
  padding-bottom: ${PADDING.md};
  position: relative;
`
