import { ApiClientHr } from 'app/service/api-service'
import { OldDBEndPoint } from './constant'
import { ParamsList } from 'parkway-web-common'

export const requestGetOldDoctor = (params: ParamsList) =>
  ApiClientHr.Get({
    url: OldDBEndPoint.Doctors.requestGetData,
    params: { params },
  })

export const requestGetTreatmentGroupOldDB = (params?: ParamsList) =>
  ApiClientHr.Get({
    url: OldDBEndPoint.TreatmentGroup.requestGetData,
    params: { params },
  })
