import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  border-radius: ${BORDER_RADIUS};
  background-color: var(--white);
  padding: ${PADDING.md};
`
export const HeadWrapper = styled(BaseRow)`
  padding-bottom: ${PADDING.md};
`

export const TitleText = styled.span`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
`
export const TotalWrapper = styled.span`
  color: var(--other-blue-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
`

export const ItemWrapper = styled.div`
  padding: ${PADDING.xxxxs} 0px;
`

export const TitleTextItem = styled.span`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
  color: var(--primary-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const FooterItemWrapper = styled(BaseRow)`
  padding-top: ${PADDING.xxs};
`

export const ReadMoreTextFooterItem = styled.span`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
  color: #216ae2; // state blue
`
export const DateTextFooterItem = styled.span`
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
  color: var(--main-color-main-optional-primary-3-color);
`
