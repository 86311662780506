import { InputNumber as AntInputNumber } from 'antd';
import { convertedVariables } from 'app/styles/themes/themeVariables';
import styled from 'styled-components';

interface InputNumberProps {
  $block?: boolean;
}

export const InputNumber = styled(AntInputNumber)<InputNumberProps>`
  ${(props) => props.$block && 'display: block; width: 100%'};

  background: ${convertedVariables.backgroundColor};
  border: 1px solid #F0F1F3; // need change

  & input.ant-input {
    background: ${convertedVariables.backgroundColor};
  }
`;
