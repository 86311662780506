import {
  DiscountTypeEnum,
  IRegionalPrice,
} from 'app/api/marketing/discount-campaign/model'

export enum StatusEnum {
  DUPLICATED = 'duplicated',
  WRONG_DISCOUNT_TYPE = 'wrong_discount_type',
  WRONG_DISCOUNT_INPUT = 'wrong_discount_input',
  NOT_FOUND_TREATMENT = 'not_found_treatment',
  SUCCESS = 'success',
  WRONG_QUANTITY_INPUT = 'wrong_quantity_input',
}

export enum StatusSetEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  EMPTY_DISCOUNT_TREATMENT = 'empty_discount_treatment',
  EMPTY_BUY_TREATMENT = 'empty_buy_treatment',
}

export interface IResponseImportBuyOneGetOne {
  setName: string
  buyTreatment: {
    _id: string
    name: string
    amount: number
    dType: DiscountTypeEnum
    discount: number
    rowNumber: number
    status: StatusEnum
    regionalPrice?: IRegionalPrice[]
  }[]
  discountTreatment: {
    _id: string
    name: string
    amount: number
    dType: DiscountTypeEnum
    discount: number
    rowNumber: number
    status: StatusEnum
    regionalPrice?: IRegionalPrice[]
  }[]
  status: StatusSetEnum
}
