import { memo } from 'react'
import { isEqual } from 'lodash'
import DashboardConsultationContainer from 'app/containers/Dashboard/DashboardConsultation'
import { PageTitle } from 'app/components/common/PageTitle'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

const DashboardConsultationCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.dashboard_consultation)}</PageTitle>
      <DashboardConsultationContainer />
    </>
  )
}
export const DashboardConsultationPage = memo(DashboardConsultationCpn, isEqual)
