import { BaseButton } from 'app/components/common/BaseButton'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseTypography } from 'app/components/common/BaseTypography'
import { colors, media } from 'parkway-web-common'
import styled from 'styled-components'

export const ActionsOverlayMenu = styled.div`
  max-width: 15rem;

  @media only screen and ${media.md} {
    max-width: 25rem;
  }
`

export const SplitDivider = styled(BaseDivider)`
  margin: 0 0.5rem;
`

export const Text = styled(BaseTypography.Text)`
  display: block;
  text-align: center;
`

export const ButtonAction = styled(BaseButton)`
  width: 100%;
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled) {
    color: var(--primary-color);
    border: none;
    transition: all 0.5s ease;
  }
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--other-blue-color);
    color: ${colors.white};
    transition: all 0.5s ease;
  }
`
