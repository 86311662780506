import { Select as AntSelect } from 'antd'
import { normalizeProp } from 'app/hook'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  BORDER_RADIUS,
  Dimension,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export interface InternalSelectProps {
  $width?: Dimension
  $shadow?: boolean
  $sizeIconClear?: string

  $prefix?: string
  $prefixWidth?: number
}

export const Select = styled(AntSelect)<InternalSelectProps>`
  width: ${props => props.$width && normalizeProp(props.$width)};

  font-weight: ${FONT_WEIGHT.medium};

  box-shadow: ${props => props.$shadow && 'var(--box-shadow)'};

  &.ant-select-borderless {
    background: var(--secondary-background-color);

    border-radius: ${BORDER_RADIUS};
  }

  .ant-select-selection-placeholder {
    font-size: ${FONT_SIZE.xs};

    color: var(--text-main-color);
  }

  .ant-select-arrow {
    color: var(--text-main-color);
    top: calc(50% - 4px);
    right: 8px;
  }

  &.ant-select-multiple.ant-select-sm .ant-select-selection-item {
    height: 0.875rem;
    line-height: ${FONT_SIZE.xs};
    font-size: ${FONT_SIZE.xs};
    margin-top: 0.1875rem;
    margin-bottom: 0.1875rem;
  }

  .ant-select-selector {
    border: 1px solid #f0f1f3 !important;
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: var(--disabled-color);
  }

  .ant-select-clear {
    color: var(--disabled-color);
  }
  .ant-select-selection-item-remove {
    color: var(--icon-color);
    &:hover {
      color: var(--icon-hover-color);
    }
  }
  .ant-select-item-option-disabled {
    color: var(--disabled-color);
  }

  min-width: 200px;

  .ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--background-color) !important;
    transition: all 0.5s ease;

    .ant-select-item-option-content {
      color: var(--primary-color) !important;
      transition: all 0.5s ease;
    }
  }

  .ant-select-dropdown .ant-select-item-option {
    padding-top: ${PADDING.xxxs} !important;
    padding-bottom: ${PADDING.xxxs} !important;
  }

  .ant-select-clear {
    margin-right: 8px;
    margin-top: -10px;
    svg {
      width: ${props => props?.$sizeIconClear ?? '20px'};
      height: ${props => props?.$sizeIconClear ?? '20px'};
      fill: #d9dee3;
    }
  }

  ${({ $prefix, $prefixWidth }) =>
    $prefix &&
    `.ant-select-selector::before {
        content: '${$prefix ?? '-'}:';
        font-size: ${FONT_SIZE.xs};
        font-weight: ${FONT_WEIGHT.medium};
        color: ${convertedVariables.primaryColor};
        opacity: 0.4;
        width: ${$prefixWidth ?? 0}px;
        align-items: center;
        display: flex;
      }`}
`
export const Placeholder = styled.div`
  color: var(--main-2-color);
  font-weight: ${FONT_WEIGHT.regular};
  font-size: ${FONT_SIZE.xs};
`
