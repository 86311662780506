/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestCreateAccountManagement } from 'app/api/human'
import { HumanMessageCode } from 'app/api/human/constant'
import { IPayloadCreateAccountManagement } from 'app/api/human/model/account-management'
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { PHONE_REGEX, REG_EMAIL, ResponseType } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormData, IFormRule, IPropsCreateAccount } from './type'
import { StatusEnum } from 'app/common/enum'
import { convertArrayStatusFilterToArrayStatusNumber } from 'app/common/helpers'

export const useModalHook = (props: IPropsCreateAccount) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)
    try {
      const payload: IPayloadCreateAccountManagement = {
        code: values?.code,
        firstname: values?.firstName,
        lastname: values?.lastName,
        mobile: values?.phone,
        email: values?.email,
        password: values?.password,
        profile: values?.profile,
        status:
          convertArrayStatusFilterToArrayStatusNumber([
            values.status,
          ] as any)?.[0] ?? StatusEnum.BLOCK,
      }

      const response: ResponseType<string> =
        await requestCreateAccountManagement(payload)

      if (
        isEqual(
          response?.msgcode,
          HumanMessageCode.AccountManagement.successPostData,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        props?.fetchTable?.()
      } else {
        notificationController.error({
          message: t(R.strings.add_account),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const rules: IFormRule = useMemo(() => {
    return {
      code: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.profile_content_employeeid),
          }),
        },
      ],
      firstName: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.first_name),
          }),
        },
      ],
      lastName: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.last_name),
          }),
        },
      ],
      employeeId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.employee_id),
          }),
        },
      ],
      phone: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.phone),
          }),
        },
        {
          pattern: PHONE_REGEX,
          message: t(R.strings.regex_phone),
        },
      ],
      email: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.email),
          }),
        },
        {
          pattern: REG_EMAIL,
          message: t(R.strings.regex_email),
        },
      ],
      password: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.password),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
  }
}
