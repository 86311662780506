/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'

import { requestExportExcelReport20 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import {
  IDayData,
  IParamsGetReport20,
  IReport020,
} from 'app/api/report/model/report-20'
import { convertToDayOfWeek } from 'app/common/helpers'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { initPagination } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { getDaysInMonth } from 'app/helpers/date.helper'
import { useDebounce, usePagination } from 'app/hook'
import { useGetFilterReport20 } from 'app/react-query/hook/report'
import { isEqual } from 'lodash'
import { Pagination, moment } from 'parkway-web-common'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DayContainer, DayText } from './styles'
import { IDataTable } from './type'

export const useTable = () => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState(initPagination)

  const [filter, setFilter] = useState<IParamsGetReport20>({})

  const { flattenDataList } = usePagination()

  const filterDebounce = useDebounce<IParamsGetReport20>(filter, 500)

  const { data: dataInfinity, isLoading } = useGetFilterReport20({
    month: filterDebounce?.month,
    year: filterDebounce?.year,
    keyword: filterDebounce?.keyword ?? '',
    location: filterDebounce?.location ?? '',
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
  })

  const dataFlatten = useMemo(() => {
    return flattenDataList(dataInfinity)
  }, [dataInfinity])

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => {
      return {
        ...item,
        key: index,
      } as IDataTable
    })
  }, [dataFlatten])

  const monthTitle =
    filterDebounce?.year && filterDebounce?.month
      ? `${t(R.strings.month)} ${filterDebounce?.month}, ${
          filterDebounce?.year
        }`
      : ''

  const handleExportExcel = async () => {
    try {
      const response = await requestExportExcelReport20({
        month: filterDebounce?.month,
        year: filterDebounce?.year,
        keyword: filterDebounce?.keyword ?? '',
        location: filterDebounce?.location ?? '',
        page: pagination.current,
        pageSize: pagination.pageSize,
        pagesize: pagination.pageSize,
      })
      if (
        isEqual(response?.msgcode, ReportMessageCode.Report20.successExportData)
      ) {
        window.open(response?.data?.url, '_blank', 'noopener,noreferrer')

        notificationController?.success({
          message: t(R.strings.export_excel_success),
        })
      }
    } catch (error) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.with_error_please_contact_with_admin),
      })
    }
  }

  const dayList = useCallback(() => {
    if (!filter?.month || !filter?.year) {
      return []
    }

    const days = getDaysInMonth(Number(filter?.month), Number(filter?.year))
    return days?.map((item, index) => {
      const dayFormat = item > 10 ? `${item}` : `0${item}`
      const day = moment()
        .set({
          day: item,
          month: Number(filter?.month),
          year: Number(filter?.year),
        })
        ?.day()
      const isWeekend = day === 6 || day === 0
      return convertColumnTable<any>({
        title: (
          <DayContainer size={8}>
            <DayText
              children={convertToDayOfWeek(day)}
              textAlign="center"
              fontWeight="semibold"
            />
            <DayText
              children={`${dayFormat}/${filter?.month}`}
              textAlign="center"
              fontWeight="semibold"
            />
          </DayContainer>
        ),

        key: `day-${index}`,
        classNameWidthColumnOverwrite: 'medium-column',
        className: isWeekend ? 'weekend-appointment' : '',
        render: (_, record) => {
          const dayData = record?.values?.find((e: IDayData) => e?.day === item)
          return (
            <BaseText
              children={dayData?.appointmentTotal ?? '-'}
              fontWeight="medium"
              opacity="0.7"
              textAlign="center"
            />
          )
        },
      })
    })
  }, [filter?.month, filter?.year])

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IReport020>({
        title: t(R.strings.doctor),
        key: 'doctor',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record: IReport020) => {
          return (
            <BaseText
              children={record?.doctor?.name}
              fontWeight="semibold"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IReport020>({
        title: t(R.strings.employee_id),
        key: 'doctor',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.doctor?.eID}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IReport020>({
        title: t(R.strings.clinic),
        key: 'clinic',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport020) => {
          return (
            <BaseText
              children={record?.clinic?.name}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      {
        title: monthTitle,
        children: dayList(),
      },
    ]
    return option
  }, [t, filterDebounce])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (newFilter: IParamsGetReport20) => {
    setFilter(newFilter)
    setPagination({ ...pagination, current: 1 })
  }

  return {
    data,
    columns,
    filter,
    isLoading,
    handleTableChange,
    pagination: {
      ...pagination,
      total: dataFlatten?.total ?? 0,
    },
    handleChangeFilter,
    handleExportExcel,
  }
}
