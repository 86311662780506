import { requestGetUnit } from 'app/api/hr'
import { IResponseGetUnitArea } from 'app/api/hr/model'
import { UnitMessageCode } from 'app/api/unit/constant'
import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import { IClinic, IUnit } from 'app/model/unit.model'
import { first, isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import {
  IFilterProps,
  IResponseFilter,
  initResponseFilterTableManagement,
} from './type'
import { StatusEnum } from 'app/common/enum'

export const FilterSurveyReport = ({ handleChangeFilter }: IFilterProps) => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const [units, setUnits] = useState<IUnit[] | null>(null)

  useEffect(() => {
    const clinicsFilter = filter.clinic
      ? clinics?.filter(clinic => clinic._id === filter.clinic)
      : clinics

    const location =
      (clinicsFilter?.map((clinic: IClinic) => clinic.oldid) as string[]) ?? null

    handleChangeFilter?.({ ...filter, location })
  }, [filter.area, filter.clinic])

  const fetchUnitArea = async () => {
    try {
      const res: IResponseGetUnitArea = await requestGetUnit({
        typeid: 'area',
        status: StatusEnum.ACTIVE,
      })
      if (
        isEqual(res?.msgcode, UnitMessageCode.UnitManagement.successGetData)
      ) {
        const data: IUnit[] = res?.data?.rows || {}
        setUnits(data)
      }
    } catch (error) {
      console.error({ error })
    }
  }

  useEffect(() => {
    fetchUnitArea()
  }, [])

  const clinics = units?.find(item => item._id === filter.area)?.childs

  useEffect(() => {
    setFilter({
      ...filter,
    })
  }, [first(clinics)?._id])

  const onChangeFilterArea = (area: string) => {
    setFilter({
      area,
      clinic: null,
    })

    const filterClinic = document.querySelector(
      '.clinic-filter .ant-select-selection-item',
    ) as HTMLElement

    if (filterClinic) {
      filterClinic.innerText = t(R.strings.survey_report_content_select_clinic)
    }
  }

  const onChangeFilterClinic = (clinic: string) => {
    setFilter(prev => {
      return {
        ...prev,
        clinic,
      }
    })
  }

  return (
    <S.HeadWrapper gutter={20} align={'middle'}>
      <BaseForm.Item name="area" required={false}>
        <S.SelectBaseStyled
          value={filter.area}
          allowClear
          placeholder={t(R.strings.survey_report_content_select_area)}
          suffixIcon={!filter.area?.length && <SvgTableCloseIcon />}
          onChange={type => onChangeFilterArea(type as string)}
        >
          <Option>{t(R.strings.survey_report_content_select_area)}</Option>
          {units?.map((item, index) => {
            return (
              <Option value={item._id} key={index}>
                {item.name}
              </Option>
            )
          })}
        </S.SelectBaseStyled>
      </BaseForm.Item>
      <BaseForm.Item name="clinic" className="clinic-filter" required={false}>
        <S.SelectBaseStyled
          value={filter.clinic ?? null}
          allowClear
          disabled={!filter?.area}
          placeholder={t(R.strings.survey_report_content_select_clinic)}
          suffixIcon={!filter.clinic?.length && <SvgTableCloseIcon />}
          onChange={type => onChangeFilterClinic(type as string)}
        >
          <Option>{t(R.strings.survey_report_content_select_clinic)}</Option>
          {clinics?.map((item, index) => {
            return (
              <Option value={item._id} key={index}>
                {item.name}
              </Option>
            )
          })}
        </S.SelectBaseStyled>
      </BaseForm.Item>
    </S.HeadWrapper>
  )
}
