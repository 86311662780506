import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { StatusTableEnum } from 'app/common/enum'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import { useModalHook } from './hook'
import * as S from './styles'
import { IFormData, StatusTableEnumCreate } from './type'

export const initValues: IFormData = {
  name: '',
}

export type StatusItemCategoryType = keyof typeof StatusTableEnum

export interface IPropsCreateItemCategory {
  fetchTable: () => void
  parentId?: string
}

export const Create = (props: IPropsCreateItemCategory) => {
  const {
    form,
    visible,
    t,
    showModal,
    handleSubmit,
    handleCancel,
    isLoading,
    rules,
  } = useModalHook(props)

  return (
    <BaseModalManagement
      typeButtonOpen={props.parentId ? 'update' : 'add'}
      titleKeyI18n={
        !props.parentId
          ? R.strings.item_category_content_add
          : R.strings.item_category_content_add_sub
      }
      isLoading={isLoading}
      content={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          {props.parentId && (
            <S.FormItem name="parentId" hidden>
              <S.FormInput hidden />
            </S.FormItem>
          )}
          <S.FormItem
            name="name"
            label={t(R.strings.item_category_content_name)}
            rules={rules.name}
            required
          >
            <S.FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.item_category_content_name),
              })}
            />
          </S.FormItem>
          <S.FormItem
            label={t(R.strings.status)}
            name="status"
            rules={rules.status}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.please_select_status)}
              suffixIcon={<SvgTableCloseIcon />}
              defaultValue={StatusTableEnumCreate.active}
            >
              {Object.values(StatusTableEnumCreate)?.map((item, index) => {
                return (
                  <Option value={item} key={index}>
                    {t(item)}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={
        !props.parentId
          ? R.strings.item_category_content_add
          : R.strings.item_category_content_add_sub
      }
      confirmKeyI18n={
        !props.parentId
          ? R.strings.item_category_content_add
          : R.strings.item_category_content_add_sub
      }
      handleSubmit={form.submit}
      visible={visible}
    />
  )
}
