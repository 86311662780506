import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseTextEditor from 'app/components/common/BaseTextEditor'
import { SectionBase } from 'app/components/common/SectionBase'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import HeaderPage from 'app/components/header/components/HeaderPage'
import * as SManagement from 'app/components/tables/BaseTableManagement/styles'
import { GET_GENDER_I18N_KEY } from 'app/constant'
import { GenderEnum } from 'parkway-web-common'
import { useModalHook } from './hook'
import * as S from './styles'
import { IFormData } from './type'

export const initValues: IFormData = {
  first_name: '',
  address: '',
}

export const CreatePatientRecordContainer = () => {
  const {
    form,
    t,
    handleSubmit,
    rules,
    isLoading,
    goBack,
    loadingArea,
    areas,
    area,
    onChangeArea,
    clinics,
  } = useModalHook()

  return (
    <SManagement.BaseManagementWrapper>
      <HeaderPage titleI18nKey={R.strings.add_patient_record} />

      <S.BaseSpaceWrapper direction="vertical" size={15}>
        <SectionBase title={t(R.strings.patient_info)}>
          <BaseForm
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={initValues}
            form={form}
          >
            <BaseRow align={'top'} gutter={[10, 10]}>
              <BaseCol xl={6}>
                <S.FormItem
                  name="first_name"
                  label={t(R.strings.first_name)}
                  rules={rules.first_name}
                  required
                >
                  <S.FormInput
                    placeholder={t(R.strings.enter_field, {
                      field: t(R.strings.first_name).toLowerCase(),
                    })}
                  />
                </S.FormItem>
              </BaseCol>
              <BaseCol xl={6}>
                <S.FormItem
                  name="last_name"
                  label={t(R.strings.last_name)}
                  rules={rules.last_name}
                  required
                >
                  <S.FormInput
                    placeholder={t(R.strings.enter_field, {
                      field: t(R.strings.last_name).toLowerCase(),
                    })}
                  />
                </S.FormItem>
              </BaseCol>
              <BaseCol xl={6}>
                <S.FormItem
                  name="gender"
                  label={t(R.strings.gender)}
                  rules={rules.gender}
                  required
                >
                  <S.SelectBaseStyled
                    placeholder={t(R.strings.please_select_gender)}
                  >
                    {Object.values(GenderEnum)?.map((item, index) => {
                      return (
                        <Option value={item} key={index}>
                          {t(GET_GENDER_I18N_KEY[item])}
                        </Option>
                      )
                    })}
                  </S.SelectBaseStyled>
                </S.FormItem>
              </BaseCol>
              <BaseCol xl={6}>
                <S.FormItem
                  name="birthday"
                  label={t(R.strings.birthday)}
                  rules={rules.birthday}
                  required
                >
                  <S.BaseDatePickerStyled
                    placeholder={t(R.strings.select_birthday)}
                  />
                </S.FormItem>
              </BaseCol>
            </BaseRow>

            <BaseRow align={'top'} gutter={[10, 10]}>
              <BaseCol xl={6}>
                <S.FormItem
                  name="msbn"
                  label={t(R.strings.msbn)}
                  rules={rules.msbn}
                  required
                >
                  <S.FormInput placeholder={t(R.strings.enter_patient_code)} />
                </S.FormItem>
              </BaseCol>
              <BaseCol xl={6}>
                <S.FormItem
                  name="phone"
                  label={t(R.strings.phone)}
                  rules={rules.phone}
                  required
                >
                  <S.FormInput placeholder={t(R.strings.enter_phone)} />
                </S.FormItem>
              </BaseCol>
              <BaseCol xl={12}>
                <S.FormItem
                  name="email"
                  label={t(R.strings.email)}
                  rules={rules.email}
                  required
                >
                  <S.FormInput placeholder={t(R.strings.enter_email)} />
                </S.FormItem>
              </BaseCol>
            </BaseRow>

            <S.FormItem label={t(R.strings.address)} required>
              <BaseRow gutter={[10, 10]} align={'top'}>
                <BaseCol xl={6}>
                  <S.FormItem
                    name="province"
                    rules={rules.province}
                    hiddenLabel
                  >
                    <S.SelectBaseStyled
                      placeholder={t(R.strings.choose_province)}
                    >
                      {Object.values(GenderEnum)?.map((item, index) => {
                        return (
                          <Option value={item} key={index}>
                            {t(item)}
                          </Option>
                        )
                      })}
                    </S.SelectBaseStyled>
                  </S.FormItem>
                </BaseCol>
                <BaseCol xl={6}>
                  <S.FormItem
                    name="district"
                    rules={rules.district}
                    hiddenLabel
                  >
                    <S.SelectBaseStyled
                      placeholder={t(R.strings.choose_district_or_city)}
                    >
                      {Object.values(GenderEnum)?.map((item, index) => {
                        return (
                          <Option value={item} key={index}>
                            {t(item)}
                          </Option>
                        )
                      })}
                    </S.SelectBaseStyled>
                  </S.FormItem>
                </BaseCol>
                <BaseCol xl={6}>
                  <S.FormItem name="ward" rules={rules.ward} hiddenLabel>
                    <S.SelectBaseStyled placeholder={t(R.strings.choose_ward)}>
                      {Object.values(GenderEnum)?.map((item, index) => {
                        return (
                          <Option value={item} key={index}>
                            {t(item)}
                          </Option>
                        )
                      })}
                    </S.SelectBaseStyled>
                  </S.FormItem>
                </BaseCol>
                <BaseCol xl={6}>
                  <S.FormItem name="address" hiddenLabel rules={rules.address}>
                    <S.FormInput placeholder={t(R.strings.enter_address)} />
                  </S.FormItem>
                </BaseCol>
              </BaseRow>
            </S.FormItem>

            <S.FormItem
              name="customer_source"
              rules={rules.customer_source}
              label={t(R.strings.customer_source)}
              required
            >
              <S.SelectBaseStyled placeholder={t(R.strings.choose_source)}>
                {Object.values(GenderEnum)?.map((item, index) => {
                  return (
                    <Option value={item} key={index}>
                      {t(item)}
                    </Option>
                  )
                })}
              </S.SelectBaseStyled>
            </S.FormItem>

            <BaseRow align={'middle'} gutter={[10, 10]}>
              <BaseCol xl={12}>
                <S.FormItem
                  name="area"
                  rules={rules.area}
                  label={t(R.strings.area)}
                  required
                >
                  <S.SelectBaseStyled
                    placeholder={t(R.strings.choose_area)}
                    loading={loadingArea}
                    onChange={value => onChangeArea(value as string)}
                  >
                    {areas?.map((item, index) => {
                      return (
                        <Option value={item._id} key={index}>
                          {item?.name}
                        </Option>
                      )
                    })}
                  </S.SelectBaseStyled>
                </S.FormItem>
              </BaseCol>
              <BaseCol xl={12}>
                <S.FormItem
                  name="clinic"
                  rules={rules.clinic}
                  label={t(R.strings.clinic)}
                  required
                >
                  <S.SelectBaseStyled
                    placeholder={t(R.strings.choose_clinic)}
                    disabled={!area}
                    loading={loadingArea}
                  >
                    {clinics?.map((item, index) => {
                      return (
                        <Option value={item._id} key={index}>
                          {item.name}
                        </Option>
                      )
                    })}
                  </S.SelectBaseStyled>
                </S.FormItem>
              </BaseCol>
            </BaseRow>
            <S.FormItem
              name={'important_note'}
              required
              label={t(R.strings.important_note)}
            >
              <BaseTextEditor placeholder={t(R.strings.important_note)} />
            </S.FormItem>
          </BaseForm>
        </SectionBase>
        <BaseRow justify={'end'}>
          <BaseRow gutter={[10, 10]} align={'middle'}>
            <BaseCol>
              <BaseButton onClick={goBack}>{t(R.strings.cancel)}</BaseButton>
            </BaseCol>
            <BaseCol>
              <BaseButton loading={isLoading} onClick={form.submit}>
                {t(R.strings.confirm)}
              </BaseButton>
            </BaseCol>
          </BaseRow>
        </BaseRow>
      </S.BaseSpaceWrapper>
    </SManagement.BaseManagementWrapper>
  )
}
