import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const FormItem = styled(BaseForm.Item)``

export const FormInput = styled(BaseInput)``

export const SelectBaseStyled = styled(BaseSelect)``

export const BaseSpaceWrapper = styled(BaseSpace)`
  width: 100%;
  padding-bottom: ${PADDING.md};
`
export const BaseDatePickerStyled = styled(BaseDatePicker)`
  width: 100%;
`
export const SectionBaseStyled = styled(SectionBase)``
