import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import Report009Container from 'app/containers/Report/Report009'

const Report009PageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.report_009)}</PageTitle>
      <Report009Container />
    </>
  )
}
const Report009Page = memo(Report009PageCpn, isEqual)

export default Report009Page
