import { useMemo } from 'react'
import { IProfilePatient } from '../type'

export const useCalculateData = ({
  debtPayment,
  imagePatient,
  infoPatient,
  purchaseProductService,
}: IProfilePatient) => {
  const productServices = useMemo(() => {
    return purchaseProductService?.productServices ?? []
  }, [purchaseProductService?.productServices])

  const totalInvoice = useMemo(() => {
    return purchaseProductService?.totalInvoice
  }, [purchaseProductService?.totalInvoice])

  const totalQuantity = useMemo(() => {
    const quantityList = productServices?.map(itm => itm?.quantity ?? 0)
    const sumQuantity = quantityList?.reduce((a, b) => a + b)

    return sumQuantity ?? 0
  }, [productServices])

  const totalAmount = useMemo(() => {
    const amountList = productServices?.map(
      itm => (itm?.quantity ?? 0) * (itm?.pricePerItem ?? 0),
    )
    const sumAmount = amountList?.reduce((a, b) => a + b)

    return sumAmount ?? 0
  }, [productServices])

  const totalDiscount = useMemo(() => {
    const amountList = productServices?.map(itm => itm?.discount?.value ?? 0)
    const sumDiscount = amountList?.reduce((a, b) => a + b)

    return sumDiscount ?? 0
  }, [productServices])

  const totalPayment = useMemo(() => {
    return totalAmount - totalDiscount
  }, [totalAmount, totalDiscount])

  const excessMoney = useMemo(() => {
    if (totalPayment >= totalInvoice?.customerPayment) {
      return 0
    }

    return totalInvoice?.customerPayment - totalPayment
  }, [totalPayment, totalInvoice?.customerPayment])

  const totalPaymentMade = useMemo(() => {
    if (excessMoney) return totalPayment

    return totalInvoice?.customerPayment
  }, [excessMoney, totalInvoice?.customerPayment])


  const alternativeApplicationPrograms = useMemo(() => {
    return (
      purchaseProductService?.totalInvoice
        ?.alternativeApplicationProgram ?? []
    )
  }, [
    purchaseProductService?.totalInvoice
      ?.alternativeApplicationProgram,
  ])

  return {
    totalQuantity,
    totalPayment,
    totalAmount,
    totalDiscount,
    totalPaymentMade,
    excessMoney,

    totalInvoice,
    productServices,

    debtPayment,
    purchaseProductService,
    imagePatient,
    infoPatient,
    alternativeApplicationPrograms
  }
}
