import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import styled from 'styled-components'

export const BaseTableWrapper = styled(BaseTableManagement)`
  .very-big-column {
    min-width: 450px !important;
  }

  .all-column {
    background-color: white;
    border: 0px !important;
    border-top: 0.5px solid #ebeef2 !important;
  }

  .ant-table-thead .ant-table-cell {
    border: 0px !important;
    border-bottom: 0.5px solid #ebeef2 !important;
    text-align: left !important;
    color: rgba(0, 34, 68, 0.7) !important;
  }

  .row-overwrite-style {
    .ant-table-cell {
      border: 0px !important;
      border-bottom: 0.5px solid #ebeef2 !important;
      border-top: 0.5px solid #ebeef2 !important;
    }
  }
`
