import R from 'app/assets/R'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { DescriptionModal } from '../../../DescriptionModal'
import { useModalHook } from './hook'
import { IPropsDeleteItem } from './type'

export const DeleteItem = (props: IPropsDeleteItem) => {
  const { visible, isLoading, showModal, handleCancel, handleSubmit } =
    useModalHook(props)

  return (
    <BaseModalManagement
      isLoading={isLoading}
      typeButtonOpen="delete"
      titleKeyI18n={R.strings.item_management_content_delete_treatment}
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.delete}
      confirmKeyI18n={R.strings.confirm}
      handleSubmit={handleSubmit}
      visible={visible}
      widthModal={500}
      descriptionDelete={
        <DescriptionModal
          contentKey={
            R.strings
              .item_management_content_once_the_treatment_is_locked_it_cannot_be_recovered
          }
          titleKey={
            R.strings.item_management_content_are_you_want_delete_treatment
          }
          name={props.name}
        />
      }
    />
  )
}
