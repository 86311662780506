import { BaseSpace } from 'app/components/common/BaseSpace'
import CollapseComponent from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/CollapseComponent'
import {
  IAppointmentSession,
  ITreatmentPhaseTreatmentSchedule,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { AppointmentSession, HeaderLayout } from './layouts'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'

export const TreatmentPhaseItem = ({
  item,
  idSchedule,
}: {
  item: ITreatmentPhaseTreatmentSchedule
  idSchedule?: string
}) => {
  const { onChangeAppointmentSession } = useProfilePatientContext()

  const _onChangeAppointmentSession = ({
    newAppointmentSession,
  }: {
    newAppointmentSession?: IAppointmentSession[]
  }) => {
    if (!idSchedule || !item?.id) return
    onChangeAppointmentSession?.({
      idSchedule,
      idTreatmentPhase: item?.id,
      appointmentSession: newAppointmentSession,
    })
  }

  return (
    <CollapseComponent
      items={[
        {
          key: '-',
          children: (
            <BaseSpace>
              <AppointmentSession
                appointmentSessions={item?.appointmentSession}
                onChangeAppointmentSession={_onChangeAppointmentSession}
              />
            </BaseSpace>
          ),
          label: <HeaderLayout item={item} idSchedule={idSchedule} />,
        },
      ]}
      expandIcon={() => null}
      defaultActiveKey={['-']}
    />
  )
}
