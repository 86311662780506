import R from 'app/assets/R'
import CollapseComponent from '../../components/CollapseComponent'
import ImagePatientContainer from './ImagePatientContainer'

const ImagePatient = () => {
  return (
    <CollapseComponent
      titleKeyI18n={R.strings.image_patient}
      children={<ImagePatientContainer />}
      expandIconPosition="end"
      expandIcon={() => null}
    />
  )
}

export default ImagePatient
