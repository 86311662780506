import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import { useCallback, useEffect, useState } from 'react'
import * as S from './styles'
import {
  DefineStatusItemManagement,
  IFilterProps,
  IFilterResponseTreatmentManagement,
  StatusItemManagementEnum,
} from './type'
import R from 'app/assets/R'
import { t } from 'i18next'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseRow } from 'app/components/common/BaseRow'

export const FilterItemManagement = ({ handleChangeFilter }: IFilterProps) => {
  const [filter, setFilter] = useState<IFilterResponseTreatmentManagement>({})

  useEffect(() => {
    const timer = setTimeout(() => {
      handleChangeFilter?.(filter)
    }, 500)
    return () => clearTimeout(timer)
  }, [filter])

  const onChangeSelectFilter = (status: StatusItemManagementEnum) => {
    setFilter(prev => {
      return {
        ...prev,
        status,
      }
    })
  }

  const onChangeSearch = e => {
    const keyword = e.target.value
    setFilter(prev => {
      return {
        ...prev,
        keyword,
      }
    })
  }

  const renderSelectFilter = useCallback(() => {
    return (
      <BaseRow
        style={{ marginLeft: 4 }}
        gutter={8}
        align={'middle'}
        onChange={e => onChangeSearch(e)}
      >
        <BaseCol>
          <BaseInput
            placeholder={t(
              R.strings.treatment_management_content_please_enter_id_treatment,
            )}
          />
        </BaseCol>
        <BaseCol>
          <BaseForm.Item name="select">
            <S.SelectBaseStyled
              value={filter}
              placeholder={t(R.strings.please_select_status)}
              suffixIcon={<SvgTableCloseIcon />}
              onChange={type =>
                onChangeSelectFilter(type as StatusItemManagementEnum)
              }
            >
              {Object.keys(StatusItemManagementEnum).map((key, index) => {
                return (
                  <Option key={index} value={StatusItemManagementEnum[key]}>
                    {DefineStatusItemManagement[StatusItemManagementEnum[key]]}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>
    )
  }, [])()

  return (
    <S.HeadWrapper gutter={20} align={'middle'}>
      {renderSelectFilter}
    </S.HeadWrapper>
  )
}
