// Message code define
export const CategoryMessageCode = {
  CategoryTreatment: {
    successGetData: '80000',
  },
}

// Endpoint
export const CategoryEndPoint = {
  CategoryTreatment: {
    request: 'treatment/v1/item-category',
  },
}
