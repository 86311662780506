import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useTranslation } from 'react-i18next'
import { TableWrapper } from '../../styles'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import DividerLine from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/DividerLine'
import TextArea from 'antd/es/input/TextArea'
import styled from 'styled-components'
import { BaseButton } from 'app/components/common/BaseButton'
import { PlusOutlined } from '@ant-design/icons'
import { convertedVariables } from 'app/styles/themes/themeVariables'

const ItemTreatmentStages = () => {
  const { t } = useTranslation()
  return (
    <TableWrapper>
      <BaseRow
        className="child-wrapper"
        justify={'space-between'}
        align={'middle'}
      >
        <BaseCol>
          <BaseText
            children={'Giai đoạn 1'}
            fontWeight="medium"
            fontSize="xs"
          />
        </BaseCol>
        <SvgDeleteDesignIcon />
      </BaseRow>
      <DividerLine />
      <ContentWrapper className="child-wrapper">
        <BaseText
          children={t(R.strings.treatment_request)}
          fontWeight="semibold"
          opacity="0.7"
        />
        <TextArea placeholder={t(R.strings.enter_content)} rows={3} />
        <BaseRow gutter={[15, 15]}>
          <BaseCol xl={12}>
            <BaseSpace>
              <BaseText
                children={t(R.strings.basic_forecast)}
                fontWeight="medium"
                opacity="0.7"
                fontSize="xxxs"
              />
              <TextArea placeholder={t(R.strings.enter_note)} rows={1} />
            </BaseSpace>
          </BaseCol>
          <BaseCol xl={12}>
            <BaseSpace>
              <BaseText
                children={t(R.strings.incident_forecast)}
                fontWeight="medium"
                opacity="0.7"
                fontSize="xxxs"
              />
              <TextArea placeholder={t(R.strings.enter_note)} rows={1} />
            </BaseSpace>
          </BaseCol>
        </BaseRow>
      </ContentWrapper>
    </TableWrapper>
  )
}

export const TreatmentStagesLayout = () => {
  const { t } = useTranslation()
  return (
    <BaseSpace>
      <BaseText
        children={`${t(R.strings.part_number, { part: 4 })}. ${t(
          R.strings.treatment_stages,
        )}`}
        fontWeight="semibold"
        fontSize="xs"
      />
      <ItemTreatmentStages />

      <BaseButton
        icon={
          <PlusOutlined
            rev={undefined}
            color={convertedVariables.otherBlueColor}
          />
        }
        children={t(R.strings.additional_troubleshooting)}
        type="link"
      />
    </BaseSpace>
  )
}

const ContentWrapper = styled(BaseSpace)`
  background-color: #fbfbfb; // need change
`
