import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import DailyInvoiceContainer from 'app/containers/Accounting/DailyInvoice'

const AccountingDailyInvoiceCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>
        {t(R.strings.daily_invoice)}
      </PageTitle>
      <DailyInvoiceContainer />
    </>
  )
}
const AccountingDailyInvoice = memo(
  AccountingDailyInvoiceCpn,
  isEqual,
)

export default AccountingDailyInvoice
