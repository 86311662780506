// Message code define
export const AppointmentMessageCode = {
  AppointmentManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20001',
  },
}

// Endpoint
export const AppointmentEndPoint = {
  AppointmentManagement: {
    request: 'appointment/v1/booking',
  },
  AppointmentBookingCallCenterManagement: {
    request: 'appointment/v1/booking/call-center/schedule',
    total: 'appointment/v1/booking/call-center/schedule/total',
    sendSms: 'appointment/v1/booking/call-center/send-sms',
    export: 'appointment/v1/booking/call-center/export',
  },
}


export const scheduleStatusSearchAppointment = '2,3,4,5,6,7,8'
export const scheduleStatusSearchAppointmentFilter = '1,2,3,4,5,5,6,7'


export const scheduleStatusAppointment = '2,3,4,6,8'
export const scheduleStatusAppointmentFilter = '1,2,3,4,5,5,6,7'