import * as S from './styles'
import { ILevelSkill, ISkill } from 'app/api/skill/model/management'

export interface IPropsUpdateSkill {
  index: number
  levelSkill?: ILevelSkill
  skill?: ISkill
  showDelete?: boolean
  onRemove?: (key: number) => void
}

export const SkillItem = ({
  index,
  levelSkill,
  skill,
  showDelete = true,
  onRemove,
}: IPropsUpdateSkill) => {
  return (
    <S.SkillWrapper key={index}>
      <S.LevelTag color={levelSkill?.color}>
        {levelSkill?.name ?? 'unknown'}
      </S.LevelTag>
      <S.SkillTitle>{skill?.name ?? 'unknown'}</S.SkillTitle>
      {showDelete && (
        <S.RemoveCircleIconStyled onClick={() => onRemove && onRemove(index)} />
      )}
    </S.SkillWrapper>
  )
}
