import { DiscountCampaignMessageCode } from 'app/api/marketing/discount-campaign/constant'
import R from 'app/assets/R'
import { _DEV_ } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import {
  useFinishDiscountCampaign
} from 'app/react-query/hook/campaign'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPropsFinishCampaign } from './type'

export const useModalHook = (props: IPropsFinishCampaign) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const { mutateAsync: mutateAsyncFinishCampaign, isLoading } =
    useFinishDiscountCampaign()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleSubmit = async () => {
    if (!props?.id) return
    try {
      const response: ResponseType<unknown> =
        (await mutateAsyncFinishCampaign?.({
          id: props?.id,
          end: new Date().toISOString(),
        })) as ResponseType<unknown>

      if (
        isEqual(
          response?.msgcode,
          DiscountCampaignMessageCode.DiscountCampaign.success,
        )
      ) {
        handleCancel()
        notificationController.success({
          message: t(R.strings.finish_campaign),
          description: t(R.strings.finish_campaign_success),
        })
      } else {
        notificationController.error({
          message: t(R.strings.finish_campaign),
          description: response?.message,
        })
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    }
  }

  return {
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
  }
}
