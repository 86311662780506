import { BaseSelectSearch } from 'app/components/common/BaseSelectSearch'
import styled from 'styled-components'

export const NumberCharacter = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  position: absolute;
  right: 0;
  top: 20px;
  color: var(--main-color-text);
  opacity: 70%;
`

export const NoteBlock = styled.div`
  position: relative;
`

export const CustomSelectSearch = styled(BaseSelectSearch)`
  && {
    .ant-select-selection-item {
      background: initial !important;
    }
    .ant-select-selection-item-remove {
      display: none !important;
    }
  }
`
