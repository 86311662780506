import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseSection } from 'app/components/common/SectionBase/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const TreatmentPlanDoctorLayout = () => {
  const { t } = useTranslation()
  return (
    <BaseSection>
      <BaseSpace>
        <HeadWrapper
          justify={'space-between'}
          align={'middle'}
          gutter={[16, 8]}
        >
          <BaseCol>
            <BaseText
              children={t(R.strings.pending_approval)}
              fontSize="md"
              fontWeight="semibold"
            />
          </BaseCol>
          <BaseCol>
            <BaseText
              children={t(R.strings.see)}
              fontWeight="medium"
              fontSize="xs"
              colorText="otherBlueColor"
            />
          </BaseCol>
        </HeadWrapper>
        <ItemWrapper
          justify={'space-between'}
          align={'middle'}
          gutter={[16, 16]}
        >
          <BaseCol>
            <BaseText
              children={t(R.strings.treatment_plan)}
              fontWeight="medium"
            />
          </BaseCol>

          <BaseCol>
            <BaseRow gutter={0} wrap={false} align={'middle'}>
              <BaseCol className="see-more">
                <BaseRow align={'middle'}>
                  <BaseCol>
                    <BaseText
                      children={t(R.strings.see)}
                      fontWeight="medium"
                      colorText="collapseBackgroundColor"
                      style={{ cursor: 'pointer' }}
                    />
                  </BaseCol>
                  <BaseCol>
                    <BaseDivider type="vertical" />
                  </BaseCol>
                </BaseRow>
              </BaseCol>
              <BaseCol>
                <NumberWrapper>
                  <BaseText
                    children={3}
                    colorText="statesGreenColor"
                    fontWeight="medium"
                  />
                </NumberWrapper>
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </ItemWrapper>

        <ItemWrapper
          justify={'space-between'}
          align={'middle'}
          gutter={[16, 16]}
        >
          <BaseCol>
            <BaseText
              children={t(R.strings.treatment_phase)}
              fontWeight="medium"
            />
          </BaseCol>

          <BaseCol>
            <BaseRow gutter={0} wrap={false} align={'middle'}>
              <BaseCol className="see-more">
                <BaseRow align={'middle'}>
                  <BaseCol>
                    <BaseText
                      children={t(R.strings.see)}
                      fontWeight="medium"
                      colorText="collapseBackgroundColor"
                      style={{ cursor: 'pointer' }}
                    />
                  </BaseCol>
                  <BaseCol>
                    <BaseDivider type="vertical" />
                  </BaseCol>
                </BaseRow>
              </BaseCol>
              <BaseCol>
                <NumberWrapper>
                  <BaseText
                    children={2}
                    colorText="statesGreenColor"
                    fontWeight="medium"
                  />
                </NumberWrapper>
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </ItemWrapper>
      </BaseSpace>
    </BaseSection>
  )
}

const HeadWrapper = styled(BaseRow)``
const NumberWrapper = styled.div`
  padding: 4px;
  border-radius: ${PADDING.xxl};
  background-color: ${convertedVariables.statesGreenLightColor};
`
const ItemWrapper = styled(BaseRow)`
  .see-more {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  &:hover .see-more {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
`
