import BaseText from 'app/components/common/BaseText'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { IProductServicePurchaseItem } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/purchase-product-service'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductServiceItem } from '../../components'
import * as S from '../../styles'
interface IGroupData {
  type?: string
  data?: IProductServicePurchaseItem[]
}

const ProductServiceListLayout = () => {
  const { t } = useTranslation()

  const { isOpenPurchaseProductService, profilePatient } =
    useProfilePatientContext()

  const productServicesGroups = useMemo(() => {
    const listAll =
      profilePatient?.purchaseProductService?.productServices ?? []
    let listGroupByType: IGroupData[] = []

    listAll?.forEach(item => {
      const typeExist = listGroupByType?.find(itm => itm?.type === item?.type)
      if (!typeExist) {
        listGroupByType = [
          ...listGroupByType,
          { type: item?.type, data: [item] },
        ]
      } else {
        listGroupByType = listGroupByType?.map(itm => {
          if (itm?.type === item?.type) {
            itm.data = itm?.data?.concat(item)
          }
          return itm
        })
      }
    })
    return listGroupByType ?? []
  }, [profilePatient?.purchaseProductService, isOpenPurchaseProductService])

  return (
    <S.ContentWrapper direction="vertical" size={15}>
      {productServicesGroups?.map((item, index) => {
        return (
          <S.ContentWrapper direction="vertical" size={15} key={index}>
            <BaseText fontWeight='medium'>{`${t(
              item?.type ?? '',
            )} (${item?.data?.length ?? 0})`}</BaseText>

            {item?.data?.map((itm, idx) => {
              return <ProductServiceItem item={itm} key={idx} />
            })}
          </S.ContentWrapper>
        )
      })}
    </S.ContentWrapper>
  )
}

export default ProductServiceListLayout
