import { BaseButton } from 'app/components/common/BaseButton'
import { BaseUpload } from 'app/components/common/BaseUpload'
import styled from 'styled-components'

export const UploadAvatar = styled(BaseUpload)`
  display: flex;

  .ant-upload-list {
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    animation: none !important;
  }

  .ant-upload-animate-inline-leave {
    display: none !important;
  }

  & .ant-upload-list-item-container {
    animation: none !important;
    transition: none !important;
  }

  & .ant-upload-list-item-container,
  .ant-upload.ant-upload-select {
    display: flex;
    width: unset !important;
    height: unset !important;
    background-color: transparent !important;
    border: unset !important;
    justify-content: center !important;

    & .avatar-container {
      display: flex;
      flex-direction: column;
      gap: 60px;
      align-items: center;

      img {
        border-radius: 50%;
        width: 220px;
        height: 220px;
      }
    }
  }
`

export const ButtonUpload = styled(BaseButton)`
  width: 112px;
  height: 32px;
  background-color: #639aff;
  color: #fff;
  position: absolute;
  bottom: 62px;

  &:hover {
    color: #fff !important;
  }
`

export const ButtonRemove = styled(BaseButton)`
  width: 100px;
  height: 32px;
  background-color: var(--neutral-black-16-color);
  color: var(--text-primary2-color);

  &:hover {
    color: var(--text-primary2-color) !important;
  }
`
