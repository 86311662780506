import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { useTranslation } from 'react-i18next'
import { ICompanyGetInvoice } from '../../type/debt-payment'
import * as S from './styles'

interface IProps {
  company?: ICompanyGetInvoice
  onChangeCompanyName: (value: string) => void
  onChangeCompanyTaxId: (value: string) => void
  onChangeCompanyAddress: (value: string) => void
  onChangeCompanyEmail: (value: string) => void
}

export const CompanyInfoComponent = ({
  company,
  onChangeCompanyAddress,
  onChangeCompanyEmail,
  onChangeCompanyName,
  onChangeCompanyTaxId,
}: IProps) => {
  const { t } = useTranslation()
  return (
    <S.CompanyWrapper gutter={[15, 15]} align={'middle'}>
      <BaseCol xl={12}>
        <BaseRow align={'middle'}>
          <BaseCol xl={4}>
            <BaseText
              children={t(R.strings.company)}
              fontWeight="medium"
              fontSize="xs"
            />
          </BaseCol>
          <BaseCol xl={20}>
            <S.InputWrapper
              value={company?.name}
              placeholder={t(R.strings.enter_name_company)}
              onChange={e => onChangeCompanyName(e?.target?.value)}
              allowClear
            />
          </BaseCol>
        </BaseRow>
      </BaseCol>
      <BaseCol xl={12}>
        <BaseRow align={'middle'}>
          <BaseCol xl={4}>
            <BaseText
              children={t(R.strings.tax_id)}
              fontWeight="medium"
              fontSize="xs"
            />
          </BaseCol>
          <BaseCol xl={20}>
            <S.InputWrapper
              value={company?.mst}
              placeholder={t(R.strings.enter_tax_id)}
              onChange={e => onChangeCompanyTaxId(e?.target?.value)}
              allowClear
            />
          </BaseCol>
        </BaseRow>
      </BaseCol>
      <BaseCol xl={12}>
        <BaseRow align={'middle'}>
          <BaseCol xl={4}>
            <BaseText
              children={t(R.strings.address)}
              fontWeight="medium"
              fontSize="xs"
            />
          </BaseCol>
          <BaseCol xl={20}>
            <S.InputWrapper
              value={company?.address}
              placeholder={t(R.strings.enter_address)}
              onChange={e => onChangeCompanyAddress(e?.target?.value)}
              allowClear
            />
          </BaseCol>
        </BaseRow>
      </BaseCol>
      <BaseCol xl={12}>
        <BaseRow align={'middle'}>
          <BaseCol xl={4}>
            <BaseText
              children={t(R.strings.email)}
              fontWeight="medium"
              fontSize="xs"
            />
          </BaseCol>
          <BaseCol xl={20}>
            <S.InputWrapper
              value={company?.email}
              placeholder={t(R.strings.email)}
              onChange={e => onChangeCompanyEmail(e?.target?.value)}
              allowClear
            />
          </BaseCol>
        </BaseRow>
      </BaseCol>
    </S.CompanyWrapper>
  )
}
