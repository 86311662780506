import { Rule } from 'antd/es/form'

export interface IFormData {
  _id: string
  name?: string
  status?: string
  industry: string
}

export interface IFormRule {
  name?: Rule[]
  status?: Rule[]
  industry?: Rule[]
}

export const initValues: IFormData = {
  _id: '',
  name: '',
  industry: '',
  status: '',
}

export enum StatusSkillEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
  deleted = 'deleted',
}

export type StatusSkillType = keyof typeof StatusSkillEnum

export interface IPropsUpdateSkill {
  fetchTable: () => void
  id?: string | number
}
