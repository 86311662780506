import { ApiClient } from 'app/service/api-service'
import { OrganizationalChartEndPoint } from './constant'
import {
  IParamsGetOrganizationalChart,
  IParamsGetOrganizationalChartByJobTitle,
} from './model'

export const requestGetOrganizationalChart = (
  params?: IParamsGetOrganizationalChart,
) =>
  ApiClient.Get({
    url: `${OrganizationalChartEndPoint.OrganizationalChart.request}`,
    params: { params },
  })

export const requestGetOrganizationalChartByJobTitle = (
  params?: IParamsGetOrganizationalChartByJobTitle,
) =>
  ApiClient.Get({
    url: `${OrganizationalChartEndPoint.OrganizationalChartByJobTitle.request}`,
    params: { params },
  })
