import { IBaseTableReport } from 'app/components/tables/BaseTableReport/type'
import { Pagination } from 'parkway-web-common'

export const initialPagination: Pagination = {
  current: 1,
  pageSize: 100,
}

export interface IGeneralReport01Table<T> extends IBaseTableReport<T> {
  service?: string
  isNotExpanded?: boolean

  budget?: number
  spentActual?: number
  percentBudgetUsing?: number
  qTechPlaning?: number
  qTechActual?: number
  qTechAchievement?: number

  cpqlPlaning?: number
  cpqlActual?: number

  numberCheckingPlaning?: number
  numberCheckingActual?: number
  numberCheckingAchievement?: number
  numberCheckingPrice?: number
  numberCheckingTargetApi?: number

  kpiPlan?: number
  kpiActual?: number
  kpiAchievement?: number

  budgetTotal?: number
  spentActualTotal?: number
  percentBudgetUsingTotal?: number
  qTechPlaningTotal?: number
  qTechActualTotal?: number
  qTechAchievementTotal?: number

  cpqlPlaningTotal?: number
  cpqlActualTotal?: number

  numberCheckingPlaningTotal?: number
  numberCheckingActualTotal?: number
  numberCheckingAchievementTotal?: number
  numberCheckingPriceTotal?: number
  numberCheckingTargetApiTotal?: number

  kpiPlanTotal?: number
  kpiActualTotal?: number
  kpiAchievementTotal?: number

  isService?: boolean
}
