import { IItemBrand } from 'app/api/item-brand/model/management'
import {
  requestMeasurementUnitData,
  requestTreatmentItemBrandData,
} from 'app/api/treatment'
import { IMeasurementUnit } from 'app/api/treatment/model'
import { notificationController } from 'app/controllers/notification-controller'
import { get } from 'lodash'
import { createContext, useState } from 'react'

interface ITreatmentContext {
  loading: boolean
  itemBrands: IItemBrand[]
  fetchItemBrands?: (params) => void
  isMeasurementUnitLoading: boolean
  measurementUnits: IMeasurementUnit[]
  fetchMeasurementUnits?: (params) => void
}

export const TreatmentContext = createContext<ITreatmentContext>({
  loading: false,
  itemBrands: [],
  isMeasurementUnitLoading: false,
  measurementUnits: [],
})

export const TreatmentProvider = ({ children }) => {
  const [itemBrands, setItemBrands] = useState([])
  const [measurementUnits, setMeasurementUnits] = useState([])
  const [loading, setLoading] = useState(false)
  const [isMeasurementUnitLoading, setIsMeasurementUnitLoading] =
    useState(false)

  const fetchItemBrands = async params => {
    setLoading(true)
    try {
      const response = await requestTreatmentItemBrandData(params)
      setItemBrands(response?.data)
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }
    setLoading(false)
  }

  const fetchMeasurementUnits = async params => {
    setIsMeasurementUnitLoading(true)
    try {
      const response = await requestMeasurementUnitData(params)
      setMeasurementUnits(response?.data)
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }
    setIsMeasurementUnitLoading(false)
  }

  return (
    <TreatmentContext.Provider
      value={{
        itemBrands,
        loading,
        fetchItemBrands,
        fetchMeasurementUnits,
        measurementUnits,
        isMeasurementUnitLoading,
      }}
    >
      {children}
    </TreatmentContext.Provider>
  )
}
