/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestRequestImportTreatment } from 'app/api/marketing/discount-campaign'
import { DiscountCampaignMessageCode } from 'app/api/marketing/discount-campaign/constant'
import { _DEV_ } from 'app/constant'
import { ImportBundleBillDiscountCommon } from 'app/containers/Marketing/DiscountCampaignManagement/components/bill-discount'
import {
  IResponseImportBundle,
  StatusSetEnum,
} from 'app/containers/Marketing/DiscountCampaignManagement/components/bundle/ImportModal/type'
import { useCreateCampaignContext } from 'app/containers/Marketing/DiscountCampaignManagement/CreateCampaign/context'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useMemo, useState } from 'react'

export const ImportBundle = () => {
  const { campaignType, onSuccessImportBundleBillDiscount } =
    useCreateCampaignContext()
  const [fileSelected, setFileSelected] = useState<File | null | undefined>(
    null,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [listBundle, setListBundle] = useState<IResponseImportBundle[]>([])

  const _onConfirmModal = async () => {
    if (successData?.length) {
      onSuccessImportBundleBillDiscount?.(successData)
    }
    setFileSelected(null)
    setListBundle([])
  }

  const onRemoveFile = () => {
    setFileSelected(null)
    setListBundle([])
  }

  const onChangeFile = async (values: { file: any }) => {
    const { file } = values
    const { lastModified, name, type, originFileObj } = file
    const newFile = new File([originFileObj], name, { type, lastModified })
    if (!newFile) return
    if (!campaignType) return

    setFileSelected(newFile)

    try {
      setIsLoading(true)
      const formData = new FormData()

      formData.append('file', newFile)
      formData.append('campaignType', campaignType)

      const res: ResponseType<IResponseImportBundle[]> =
        await requestRequestImportTreatment(formData)
      if (
        isEqual(
          res?.msgcode,
          DiscountCampaignMessageCode.DiscountCampaign.success,
        )
      ) {
        setListBundle(res.data)
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const successData = useMemo(() => {
    return listBundle?.filter(item =>
      isEqual(item.status, StatusSetEnum.SUCCESS),
    )
  }, [listBundle])

  const handleCancel = () => {
    setFileSelected(null)
    setListBundle([])
  }

  return (
    <ImportBundleBillDiscountCommon
      handleCancel={handleCancel}
      handleConfirm={_onConfirmModal}
      isLoading={isLoading}
      listBundle={listBundle}
      onChangeFile={onChangeFile}
      onRemoveFile={onRemoveFile}
      fileSelected={fileSelected}
    />
  )
}
