import { CampaignReferralEnum } from 'app/api/marketing/discount-campaign/model'
import {
  DiscountTypeEnum,
  EVerifyRuleKey,
  MethodIssueEnum,
  TargetUserEnum,
} from 'app/api/marketing/discount-voucher/enum'
import { IUserDataVoucher } from 'app/api/marketing/discount-voucher/model'
import { ITreatmentGroupOldDb } from 'app/api/old-db-user/model'
import { Dayjs } from 'dayjs'

export interface IFormData {
  prefix?: string
  isCustomPrefix?: boolean
  totalVoucher?: number
  prefixLength?: number

  campaignId?: string
  methodIssue?: MethodIssueEnum
  discountType?: DiscountTypeEnum

  targetUser?: TargetUserEnum
  numberOfUses?: number

  discountValue?: number
  startDate?: Dayjs
  endDate?: Dayjs

  code?: string

  file?: File

  name?: string

  verifyRuleKey?: EVerifyRuleKey

  referralTarget?: CampaignReferralEnum
  fileReferralTargetData?: File

  numberOfUsesPerCustomer?: number
  referralList?: IUserDataVoucher[]
}

export const initValues: IFormData = {
  prefixLength: 6,
  methodIssue: MethodIssueEnum.Auto,
}

export interface ITreatmentInTable extends ITreatmentGroupOldDb {
  discountValue?: number
  discountType?: DiscountTypeEnum
  key?: number
  index?: number
  quantity?: number
}

export interface BuyOneGetOneItem {
  id?: string
  name?: string

  selectedTreatment?: ITreatmentInTable[]
  discountTreatment?: ITreatmentInTable[]
}

export interface IBundleServiceItem {
  id?: string
  name?: string
  selectedTreatment?: ITreatmentInTable[]
}
