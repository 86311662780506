import { CaretDownOutlined, EditOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { ITreatmentInformationTreatmentSchedule } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TreatmentPlanAction } from './TreatmentPlanAction'

export const TreatmentInformationLayout = ({
  treatmentInformation,
}: {
  treatmentInformation: ITreatmentInformationTreatmentSchedule
}) => {
  const { t } = useTranslation()
  return (
    <BaseSpace>
      <BaseText
        children={t(R.strings.treatment_information)}
        fontSize="xs"
        fontWeight="semibold"
      />
      <BaseRow>
        <BaseCol xl={4}>
          <BaseRow align={'middle'} gutter={10}>
            <BaseCol>
              <BaseText
                children={t(R.strings.assigned_physician)}
                opacity="0.5"
                fontWeight="medium"
              />
            </BaseCol>
            <BaseCol>
              <EditOutlined rev={undefined} />
            </BaseCol>
          </BaseRow>
        </BaseCol>
        {treatmentInformation?.assignedPhysician ? (
          <BaseCol>
            <BaseRow gutter={15} align={'middle'}>
              <BaseCol>
                <BaseAvatarProfile
                  name={treatmentInformation?.assignedPhysician?.name}
                  jobTitle={`${treatmentInformation?.assignedPhysician?.jobTitle}`}
                />
              </BaseCol>
              <BaseCol>
                <BaseButton
                  type="text"
                  icon={<CaretDownIcon rev={undefined} />}
                />
              </BaseCol>
            </BaseRow>
          </BaseCol>
        ) : (
          <BaseText
            children={t(R.strings.no_assigned_physician)}
            opacity="0.5"
            fontWeight="medium"
          />
        )}
      </BaseRow>
      <BaseRow>
        <BaseCol xl={4}>
          <BaseRow align={'middle'} gutter={10}>
            <BaseCol>
              <BaseText
                children={t(R.strings.treating_physician)}
                opacity="0.5"
                fontWeight="medium"
              />
            </BaseCol>
            <BaseCol>
              <EditOutlined rev={undefined} />
            </BaseCol>
          </BaseRow>
        </BaseCol>
        {treatmentInformation?.treatingPhysician ? (
          <BaseCol>
            <BaseRow gutter={15} align={'middle'}>
              <BaseCol>
                <BaseAvatarProfile
                  name={treatmentInformation?.treatingPhysician?.name}
                  jobTitle={`${treatmentInformation?.treatingPhysician?.jobTitle}`}
                />
              </BaseCol>
              <BaseCol>
                <BaseButton
                  type="text"
                  icon={<CaretDownIcon rev={undefined} />}
                />
              </BaseCol>
            </BaseRow>
          </BaseCol>
        ) : (
          <BaseText
            children={t(R.strings.no_treating_physician)}
            opacity="0.5"
            fontWeight="medium"
          />
        )}
      </BaseRow>

      <BaseRow>
        <BaseCol xl={4}>
          <BaseText
            children={t(R.strings.treatment_plan)}
            opacity="0.5"
            fontWeight="medium"
          />
        </BaseCol>
        <TreatmentPlanAction />
      </BaseRow>
    </BaseSpace>
  )
}

const CaretDownIcon = styled(CaretDownOutlined)<{ $fill?: string }>`
  svg {
    width: 16px !important;
    height: 16px !important;
    fill: ${props => props?.$fill};
  }
`
