import styled from 'styled-components'
import { BaseButton } from '../BaseButton'

export const ButtonUpdateAction = styled(BaseButton)`
  border-color: transparent;
  transition: all 0.5s ease;
  color: var(--primary-color) !important;
  box-shadow: none;
  &:hover {
    background-color: var(--other-blue-color);
    color: var(--background-color) !important;
    transition: all 0.5s ease;
    border-color: transparent !important;
    box-shadow: none;
  }
`