import { Button } from 'app/components/common/BaseButton/styles'
import { BaseTable } from 'app/components/common/BaseTable'
import styled from 'styled-components'

export const ButtonQuantityPopover = styled(Button)<{
  $border?: string
  $background?: string
}>`
  border: initial;
  box-shadow: initial;
  margin-left: 5px;
`

export const ButtonQuantityPopoverStatus = styled(Button)<{
  $isReceipt?: boolean
}>`
  border: initial;
  box-shadow: initial;
  color: white;
  background: ${props =>
    props?.$isReceipt
      ? 'var(--states-orange-color)'
      : 'linear-gradient(316.55deg, #629DAA 0%, #6DAEBD 100%)'};
  box-shadow: 0px 1px 6px 0px #0000000a;
  font-weight: bold;
  border-radius: 20px;
  font-size: 10px;
  line-height: 14px;
  height: initial;
  padding: 4px 8px 4px 8px;

  span {
    padding: initial;
    line-height: initial;
  }
  &:hover {
    color: white;
  }
`

export const QuantityPopoverTable = styled(BaseTable)`
  min-width: 500px;
  .ant-table-thead {
    border-bottom: 1px solid var(--revenue-dashboard-border-color);
  }

  .ant-table-thead .ant-table-cell {
    border: initial;
    text-align: left;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    opacity: 0.5;
    color: var(--main-color-text);
    background: white;
    border-bottom: 1px solid var(--revenue-dashboard-border-color);
  }

  .ant-table-thead tr > *:first-child {
    text-align: left;
  }
  .ant-table-cell {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    color: var(--main-color-text);
    opacity: 0.7;
    padding: 5px !important;
  }
  .ant-pagination-item {
    border: initial;
  }

  .ant-pagination-item a {
    font-size: 12px;
  }

`

export const QuantityPopoverTitle = styled(Button)`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  opacity: 0.7;
  color: var(--main-color-text);
  border: initial;
  padding: 5px;
`

export const QuantityPopoverText = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  color: var(--main-color-text);
  opacity: 0.7;
  text-align: left;
`

export const QuantityPopoverCode = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: var(--collapse-background-color);
`

export const QuantityPopoverUser = styled.p`
  min-width: 220px;
`

export const QuantityPopoverClose = styled.div``


export const TotalPage = styled.div`
  color: var(--main-color-text);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  opacity: 0.7;
`