import { requestGetGeneralInfo } from 'app/api/dashboard'
import { DashboardMessageCode } from 'app/api/dashboard/constant'
import {
  IGeneralInfo,
  IParamsGeneralInfo,
  IResponseGeneralInfo,
} from 'app/api/dashboard/model'
import { isEqual } from 'lodash'
import { useQuery } from 'react-query'
import { DashboardKeys } from '../query-key/dashboard'

export function useGetGeneralInfo(params?: IParamsGeneralInfo, enabled = true) {
  return useQuery<IGeneralInfo, IParamsGeneralInfo>(
    DashboardKeys.generalInfo(params),
    async () => {
      try {
        const response: IResponseGeneralInfo = await requestGetGeneralInfo(
          params,
        )
        if (
          isEqual(
            response?.msgcode,
            DashboardMessageCode.GeneralInfoDashboard.successGetData,
          )
        ) {
          return response?.data
        }
        return {}
      } catch (error) {
        return {}
      }
    },
    {
      enabled,
    },
  )
}
