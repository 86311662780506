import TextArea from 'antd/es/input/TextArea'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  ItemDiagnosis
} from '../../components'
import { CurrentStatusTab, ResultTab } from './layout'
import { StatusTabEnum } from './type'

export const ToothSizeAndMorphology = () => {
  const { t } = useTranslation()
  const [tab, setTab] = useState(StatusTabEnum.CurrentStatus)

  const onSetCurrentStatusTab = () => {
    if (!isEqual(tab, StatusTabEnum.CurrentStatus)) {
      setTab(StatusTabEnum.CurrentStatus)
    }
  }

  const onSetResultTab = () => {
    if (!isEqual(tab, StatusTabEnum.Result)) {
      setTab(StatusTabEnum.Result)
    }
  }

  return (
    <BaseSpace>
      <BaseText
        children={t(R.strings.tooth_size_and_morphology_mm)}
        fontWeight="medium"
        opacity="0.7"
      />
      <RootWrapper>
        <BaseRow justify={'end'}>
          <TabSelectedWrapper gutter={[5, 5]} align={'middle'}>
            <BaseCol>
              <TabItemSelect
                $isSelected={isEqual(tab, StatusTabEnum.CurrentStatus)}
                onClick={onSetCurrentStatusTab}
              >
                <BaseText children={t(R.strings.current_status)} />
              </TabItemSelect>
            </BaseCol>

            <BaseCol>
              <TabItemSelect
                $isSelected={isEqual(tab, StatusTabEnum.Result)}
                onClick={onSetResultTab}
              >
                <BaseText children={t(R.strings.result)} />
              </TabItemSelect>
            </BaseCol>
          </TabSelectedWrapper>
        </BaseRow>
        {isEqual(tab, StatusTabEnum.CurrentStatus) ? (
          <CurrentStatusTab />
        ) : (
          <ResultTab />
        )}
      </RootWrapper>

      <BaseRow gutter={[15, 15]}>
        <BaseCol xl={12}>
          <ItemDiagnosis label={'Chỉ số Bolton B6'} placeholder="n/a" />
        </BaseCol>
        <BaseCol xl={12}>
          <ItemDiagnosis label={'Chỉ số Bolton B12'} placeholder="n/a" />
        </BaseCol>
      </BaseRow>
      <BaseRow>
        <BaseCol xl={24}>
          <BaseSpace>
            <BaseRow align={'top'} gutter={5}>
              <BaseCol>
                <BaseText
                  children={'Các ghi chú khác (răng, xương, mô mềm):'}
                  fontWeight="medium"
                />
              </BaseCol>
              <BaseCol>
                <BaseText
                  children={'(IV.2)'}
                  fontWeight="medium"
                  fontSize="xxxs"
                />
              </BaseCol>
            </BaseRow>
            <TextArea rows={3} placeholder={t(R.strings.note)} />
          </BaseSpace>
        </BaseCol>
      </BaseRow>
    </BaseSpace>
  )
}

const RootWrapper = styled(BaseSpace)`
  background-color: #f8f8f8;
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
`

const TabSelectedWrapper = styled(BaseRow)`
  background-color: #f3f3f3;
  padding: 4px 2px;
  border-radius: 4px;
`

const TabItemSelect = styled.div<{ $isSelected?: boolean }>`
  cursor: pointer;
  background-color: ${props =>
    props?.$isSelected ? convertedVariables.backgroundColor : 'transparent'};
  padding: ${PADDING.xxxxs} ${PADDING.xs};
  border-radius: 4px;
`
