import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { ModalComponent } from '../../../../components'
import { FormContentModal } from '../FormRelativesModal'
import { useHook } from './hook'

export const UpdateRelativesModal = () => {
  const { t } = useTranslation()

  const { ref, form, profilePatientType, setProfilePatientType, handleSubmit } =
    useHook()

  return (
    <ModalComponent
      titleOpenModal={t(R.strings.update)}
      titleModal={t(R.strings.update_relatives)}
      handleSubmit={form.submit}
      ref={ref}
      renderContent={
        <FormContentModal
          form={form}
          onChangeProfilePatientType={setProfilePatientType}
          profilePatientType={profilePatientType}
          handleSubmit={handleSubmit}
        />
      }
    />
  )
}
