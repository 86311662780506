import BaseText from 'app/components/common/BaseText'
import { BaseTableWrapper } from 'app/components/tables/BaseTableReport/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 0px ${PADDING.md};
`
export const BaseTextRegionName = styled(BaseText)`
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.medium} !important;
  opacity: 0.5;
`
export const BaseTextRegionPrice = styled(BaseText)`
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.medium} !important;
  color: ${convertedVariables.statesGreenColor};
  margin-right: 24px;
`
export const BaseTable = styled(BaseTableWrapper)`
  .ant-table-thead > tr > th {
    border: none !important;
  }
  .ant-table-thead > tr > th:first-child {
    text-align: left !important;
    border-start-start-radius: 0 !important;
  }
  .ant-table-thead > tr > th:last-child {
    text-align: right !important;
    border-start-end-radius: 0 !important;
  }
  .ant-table-cell {
    border: none !important;
    border-bottom: 0.5px solid var(--neutral-black-2-color) !important;
  }
  .ant-table-thead .ant-table-cell {
    font-size: ${FONT_SIZE.xxs}!important;
    font-weight: ${FONT_WEIGHT.semibold}!important;
    color: rgba(0, 34, 68, 0.7) !important;
  }
`
