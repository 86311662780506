import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import Report008Container from 'app/containers/Report/Report008'

const Report008PageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.report_008)}</PageTitle>
      <Report008Container />
    </>
  )
}
const Report008Page = memo(Report008PageCpn, isEqual)

export default Report008Page
