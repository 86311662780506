import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const NPSChart = styled.div`
  position: relative;

  &::before {
    content: '';
    font-size: 20px;
    width: calc(100% - 130px);
    height: calc(50% - 65px);
    right: 20px;
    bottom: 87px;
    background-color: #fffbfb;
    color: black;
    position: absolute;
    border-bottom: 1px solid #FD4444;
  }
`
export const Label = styled.div`
  position: absolute;
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.xxxs};

  &.excellent {
    color: #216ae2;
    top: 65px;
    left: 15px;
  }

  &.good {
    color: #28ba59;
    top: 25%;
    left: 40px;
  }

  &.middle {
    color: #fedd00;
    top: 40%;
    left: 20px;
  }

  &.bad {
    color: #ea2727;
    top: 65%;
    left: 30px;
  }
`
