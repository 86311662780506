import { useMemo, useState } from 'react'
import { ToothSizeAndMorphologyData } from '../constant'
import {
  IBridgeTooth,
  IToothSizeAndMorphology,
  PositionToothEnum,
  ToothNumberEnum,
  ToothStatusEnum,
} from '../type'
import { notificationController } from 'app/controllers/notification-controller'
import { getRandomUuid } from 'parkway-web-common'
import { isEqual } from 'lodash'

export const useToothSizeAndMorphologyTabResult = () => {
  const [toothSizeAndMorphologyTabResult, setToothSizeAndMorphologyTabResult] =
    useState<IToothSizeAndMorphology>(ToothSizeAndMorphologyData)

  const [bridgeToothBottomTabResult, setBridgeToothBottomTabResult] =
    useState<IBridgeTooth[]>([])

  const onAddBridgeBottomTabResult = (
    start: ToothNumberEnum,
    indexStart: number,
  ) => {
    const newItem: IBridgeTooth = {
      id: getRandomUuid(),
      start,
      indexStart,
    }

    setBridgeToothBottomTabResult(prev => prev.concat([newItem]))
  }

  const onChangeBridgeBottomTabResult = (
    id?: string,
    end?: ToothNumberEnum,
    indexEnd?: number,
  ) => {
    setBridgeToothBottomTabResult(prev =>
      prev?.map(item => {
        if (isEqual(item?.id, id) && item?.indexStart !== undefined) {
          if (indexEnd !== undefined && item?.indexStart > indexEnd) {
            const oldIndexStart = item.indexStart
            const oldStart = item.start

            return {
              ...item,
              end: oldStart,
              start: end,
              indexEnd: oldIndexStart,
              indexStart: indexEnd,
            }
          }
          return {
            ...item,
            indexEnd,
            end,
          }
        }
        return item
      }),
    )
  }

  const onDeleteBridgeBottomTabResult = (ids: string[]) => {
    setBridgeToothBottomTabResult(prev =>
      prev?.filter(item => !ids?.find(itm => itm === item?.id)),
    )
  }

  const [bridgeToothTopTabResult, setBridgeToothTopTabResult] =
    useState<IBridgeTooth[]>([])

  const onAddBridgeTopTabResult = (
    start: ToothNumberEnum,
    indexStart: number,
  ) => {
    const itemErrorExist = bridgeToothTopTabResult?.find(
      itm => (itm?.start ?? 0) < indexStart && indexStart < (itm?.end ?? 0),
    )

    if (itemErrorExist) {
      notificationController.warning({
        message: `Không thể chọn cầu răng này vì đã tồn tại cầu (R${itemErrorExist?.start}, R(${itemErrorExist.end}))`,
      })
      return
    }

    const newItem: IBridgeTooth = {
      id: getRandomUuid(),
      start,
      indexStart,
    }

    setBridgeToothTopTabResult(prev => prev.concat([newItem]))
  }

  const onChangeBridgeTopTabResult = (
    id?: string,
    end?: ToothNumberEnum,
    indexEnd?: number,
  ) => {
    setBridgeToothTopTabResult(prev =>
      prev?.map(item => {
        if (isEqual(item?.id, id) && item?.indexStart !== undefined) {
          if (indexEnd !== undefined && item?.indexStart > indexEnd) {
            const oldIndexStart = item.indexStart
            const oldStart = item.start

            return {
              ...item,
              end: oldStart,
              start: end,
              indexEnd: oldIndexStart,
              indexStart: indexEnd,
            }
          }
          return {
            ...item,
            indexEnd,
            end,
          }
        }
        return item
      }),
    )
  }

  const onDeleteBridgeTopTabResult = (ids: string[]) => {
    setBridgeToothTopTabResult(prev =>
      prev?.filter(item => !ids?.find(itm => itm === item?.id)),
    )
  }

  const onChangeStatusToothSizeAndMorphologyTabResult = (
    position: PositionToothEnum,
    key: ToothNumberEnum,
    newStatus?: ToothStatusEnum,
  ) => {

    setToothSizeAndMorphologyTabResult(prev => {
      const itemValue = {
        ...prev[position][key],
        currentStatus: newStatus,
      }

      const positionValue = {
        ...prev[position],
        [key]: itemValue,
      }

      return {
        ...prev,
        [position]: positionValue,
      }
    })
  }

  const onChangeValueToothSizeAndMorphologyTabResult = (
    position: PositionToothEnum,
    key: ToothNumberEnum,
    newValue?: number,
  ) => {
    setToothSizeAndMorphologyTabResult(prev => {
      const itemValue = {
        ...prev[position][key],
        value: newValue,
      }

      const positionValue = {
        ...prev[position],
        [key]: itemValue,
      }

      return {
        ...prev,
        [position]: positionValue,
      }
    })
  }

  const bridgeBottomTabResultSelecting = useMemo(() => {
    return bridgeToothBottomTabResult?.find(itm => itm?.id && !itm?.end)
  }, [bridgeToothBottomTabResult])

  const bridgeTopTabResultSelecting = useMemo(() => {
    return bridgeToothTopTabResult?.find(itm => itm?.id && !itm?.end)
  }, [bridgeToothTopTabResult])

  return {
    bridgeBottomTabResultSelecting,
    onChangeStatusToothSizeAndMorphologyTabResult,
    onChangeBridgeBottomTabResult,
    onAddBridgeBottomTabResult,
    onDeleteBridgeBottomTabResult,
    bridgeToothBottomTabResult,

    onChangeBridgeTopTabResult,
    onAddBridgeTopTabResult,
    onDeleteBridgeTopTabResult,
    bridgeToothTopTabResult,
    toothSizeAndMorphologyTabResult,
    bridgeTopTabResultSelecting,
    onChangeValueToothSizeAndMorphologyTabResult,
  }
}
