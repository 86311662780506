import styled from 'styled-components'
import { BaseTable } from 'app/components/common/BaseTable'
import { Collapse } from 'antd'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'

export interface ImagePropertyTDProps {
  flex?: string
  width?: string
}

export const Title = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
`

export const PropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .property-product {
    &__item {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__button-group {
      display: flex;
      gap: 8px;
      padding: 12px 16px;
      justify-content: flex-end;

      background-color: #f9fafb;
      border: 1px solid var(--neutral-black-9-color);
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
`

export const ImagePropertyWrapper = styled.div`
  .image-property {
    &__body {
    }

    &__tr {
      display: flex;
    }

    &__td {
      display: flex;
      flex: 1;
    }
  }
`

export const ImagePropertyBody = styled.div`
  padding: 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  &.image-property {
    &__tbody {
      background-color: #f9fafb;
    }
  }
`

export const ImagePropertyTR = styled.div`
  display: flex;
`

export const ImagePropertyTD = styled.div<ImagePropertyTDProps>`
  display: flex;
  flex: ${props => props.flex ?? 'unset'};
  width: ${props => props.width ?? 'auto'};
`

export const ImagePropertyCollapse = styled(Collapse)`
  border: 1px solid var(--neutral-black-9-color);
  /* border-top-right-radius: unset;
  border-top-left-radius: unset; */
  border-radius: unset;

  .ant-collapse-item {
    border-bottom: 1px solid var(--neutral-black-9-color);

    .ant-collapse-header {
      padding: 18px;
      background-color: var(--white);
      border-radius: unset;
      display: flex;
      align-items: center;
    }

    .ant-collapse-content {
      border-top: 1px solid var(--neutral-black-9-color);

      background-color: var(--neutral-black-9-color);
    }
  }

  &:last-child {
    border-radius: 8px;

    .ant-collapse-header {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
`

export const ImagePropertyHeaderCollapse = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.semibold};
  }

  .ant-form-item {
    margin-bottom: 0;

    .ant-form-item-control-input {
      min-height: 100%;
    }
  }
`

export const BaseTableWrapper = styled(BaseTable)`
  .row-overwrite-style {
    .ant-table-cell {
      border: 0.5px solid var(--neutral-black-2-color) !important;
    }

    .ant-table-cell-row-hover {
      background: white !important;
    }

    .ant-table-cell-row-hover:has(.is-first-col-expanded-row) {
      border-bottom: none !important;
    }
  }

  .ant-table-thead > tr > th {
    border: 0.5px solid var(--neutral-black-2-color) !important;
    text-align: center !important;
    font-size: 12px !important;
  }

  .very-big-column {
    min-width: 300px !important;
  }

  .big-column {
    min-width: 220px !important;
  }

  .normal-column {
    min-width: 170px !important;
  }

  .medium-column {
    min-width: 100px !important;
  }

  .small-column {
    width: 60px !important;
  }

  .number-column {
    min-width: 60px !important;
  }

  .title {
    &-right {
      text-align-last: right;
      padding-right: 24px;
    }
  }

  .flex-row {
    .ant-row {
      margin-left: 5px;
      display: inline-block;
    }
  }

  .all-column {
    background-color: white;
  }

  .ant-table-expanded-row-fixed {
    padding: 0px !important;
  }

  .all-column:has(.is-first-col-expanded-row) {
    border-bottom: none !important;
  }
`
