/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetLocationsPatientWithoutPaid } from 'app/api/accounting/api/orthodontic-patient-without-paid'
import { AccountingMessageCode } from 'app/api/accounting/constant'
import { GroupTypeEnum, IParamsGetLocationsWithoutPaid, IResponseLocationsWithoutPaid, ReportTypeEnum } from 'app/api/accounting/model/orthodontic-patient-without-paid'
import { _DEV_, initPagination } from 'app/constant'
import { isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import { useEffect, useState } from 'react'
import { useOrthodonticWithoutPaid } from '../../context'
import { IDataTable, useCommonHook } from './common-hook'

export const useTableHook = () => {
  const { params } = useOrthodonticWithoutPaid()

  const [pagination, setPagination] = useState<Pagination | undefined>(
    initPagination,
  )

  const [total, setTotal] = useState(0)

  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

  const [data, setData] = useState<IDataTable[]>([])

  const [loading, setLoading] = useState(false)

  const getData = async () => {
    setLoading(true)

    try {
      const paramsApi: IParamsGetLocationsWithoutPaid = {
        page: (pagination?.current ?? 1) - 1,
        pageSize: pagination?.pageSize ?? 10,
        ...params,
        groupType: GroupTypeEnum.doctor,
        reportType: ReportTypeEnum.withoutPaid,
      }

      const res: IResponseLocationsWithoutPaid =
        await requestGetLocationsPatientWithoutPaid(paramsApi)

      if (
        res?.msgcode &&
        isEqual(
          AccountingMessageCode?.OrthodonticWithoutPaid?.getLocationSuccess,
          res?.msgcode,
        )
      ) {
        const newData: IDataTable[] = res?.data?.map((item, index) => {
          return {
            key: index + 1,
            doctor: item?.name,
            total: item?.total ?? 0,
            id: item?._id,
            totalAll: 1
          }
        })

        setData(newData)

        setTotal(res?.metadata?.totalItem ?? 0)
      }
    } catch (error) {
      _DEV_ && console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  const handleExpand = (expanded: boolean, newKey?: number) => {
    if (!newKey) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const { columns } = useCommonHook({
    expandedRowKeys,
    handleExpand,
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      getData()
      setExpandedRowKeys([])
    }, 500)

    return () => clearTimeout(timer)
  }, [params, pagination])

  const handleTableChange = (pagination?: Pagination) => {
    setPagination(pagination)
  }

  return {
    columns,
    tableData: {
      data,
      loading,
      pagination: {
        ...pagination,
        total,
      },
    },
    expandedRowKeys,
    handleExpand,
    handleTableChange
  }
}
