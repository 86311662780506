import { Rule } from 'antd/es/form'
import { IMaterialCategory } from 'app/api/inventory/model/management'



export interface IFormRule {
  name?: Rule[]
  status?: Rule[]
  bankinfo?: Rule[]
  contact?: Rule[]
  TIN?: Rule[]
  code?: Rule[]
}

export const initValues: IMaterialCategory = {
  _id: '',
  name: '',
}

export enum StatusMaterialCategoryEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
  deleted = 'deleted',
}

export type StatusMaterialCategoryType = keyof typeof StatusMaterialCategoryEnum

export interface IPropsUpdateMaterialCategory {
  fetchTable: () => void
  id?: string | number
  materialCategory: IMaterialCategory
}
