import { BaseCol } from 'app/components/common/BaseCol'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { SearchDropdown } from './search-dropdown'
import * as S from './styles'
import { IFilterProps, IResponseFilter } from './type'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import {
  DefineValueTypeItem,
  TypeItemEnum,
} from 'app/containers/Accounting/ItemManagement/common-model'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { initResponseFilterTableManagement } from '../../type'
const { Option } = Select

export const FilterManagement = ({
  handleChangeFilter,
  filterData,
}: IFilterProps) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const [isOverlayOpen, setOverlayOpen] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      handleChangeFilter?.(filter)
    }, 500)
    return () => clearTimeout(timer)
  }, [filter])

  useEffect(() => {
    if (filterData && !isEqual(filterData, filter)) {
      setFilter(filterData)
    }
  }, [filterData])

  useEffect(() => {
    setOverlayOpen(false)
  }, [pathname])

  const onChangeSearch = (search: string) => {
    setFilter(prev => {
      return {
        ...prev,
        search,
      }
    })
  }

  return (
    <S.HeadWrapper gutter={20} align={'middle'}>
      <BaseCol>
        <SearchDropdown
          query={filter?.search ?? ''}
          setQuery={onChangeSearch}
          data={[]}
          isOverlayOpen={isOverlayOpen}
          setOverlayOpen={setOverlayOpen}
        />
      </BaseCol>
      <BaseCol>
        <S.SelectBaseStyled
          placeholder={t(R.strings.all_type)}
          suffixIcon={<SvgTableCloseIcon />}
          onChange={type =>
            setFilter(prev => {
              return {
                ...prev,
                type: String(type || ''),
              }
            })
          }
          allowClear
        >
          {Object.keys(TypeItemEnum).map((key, index) => {
            return (
              <Option key={index} value={key}>
                {DefineValueTypeItem[key]}
              </Option>
            )
          })}
        </S.SelectBaseStyled>
      </BaseCol>
    </S.HeadWrapper>
  )
}
