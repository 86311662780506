import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAdditionalServiceCampaignContext } from '../../../../context'
import { IDiscountCampaign } from 'app/api/marketing/discount-campaign/model'
import { BaseButton } from 'app/components/common/BaseButton'
import {
  initialPagination,
  PADDING,
  Pagination,
  ResponseType,
} from 'parkway-web-common'
import { useGetCampaignList } from 'app/react-query/hook/campaign'
import { usePagination } from 'app/hook'
import { SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import BaseText from 'app/components/common/BaseText'
import { BasePagination } from 'app/components/common/BasePagination'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'

export const ModalAddCampaign = () => {
  const { t } = useTranslation()
  const modalRef = useRef<IRefModal>({})

  const [selectedCampaign, setSelectedCampaign] = useState<IDiscountCampaign[]>(
    [],
  )

  const [pagination, setPagination] = useState<Pagination>({
    current: initialPagination.current,
    pageSize: 5,
  })

  const [searchCampaign, setSearchCampaign] = useState<string>('')
  const { flattenDataList } = usePagination()

  const { data: dataApi, isLoading } = useGetCampaignList({
    page: pagination?.current,
    pageSize: pagination?.pageSize,
    pagesize: pagination?.pageSize,
    keyword: searchCampaign,
  })

  const dataFlatten = useMemo(() => {
    const res: ResponseType<IDiscountCampaign[]> = flattenDataList(dataApi)

    return res
  }, [dataApi])

  const { stackedWithCampaigns, onChangeStackedWithCampaigns } =
    useAdditionalServiceCampaignContext()

  const openModal = () => {
    modalRef.current?.open?.()
  }

  const hideModal = () => {
    modalRef.current?.hide?.()
  }

  const handleSubmit = () => {
    onChangeStackedWithCampaigns?.(selectedCampaign)
    hideModal()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setSelectedCampaign(stackedWithCampaigns ?? [])
    }, 100)

    return () => clearTimeout(timer)
  }, [stackedWithCampaigns])

  const onChangePagination = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const onChangeSearchCampaign = (value: string) => {
    setSearchCampaign(value)
    setPagination({ ...pagination, current: 1 })
  }

  const onTabSelectCampaign = (campaign?: IDiscountCampaign) => {
    const isExist = selectedCampaign?.findIndex(
      item => item?._id === campaign?._id,
    )

    if (campaign) {
      if (isExist > -1) {
        setSelectedCampaign(prev =>
          prev?.filter(item => item?._id !== campaign?._id),
        )
      } else {
        setSelectedCampaign(prev => [...prev, campaign])
      }
    }
  }

  return (
    <ModalComponent
      titleModal={t(R.strings.add_campaign)}
      ref={modalRef}
      buttonOpenCustom={
        <BaseButton onClick={openModal}>{t(R.strings.add_campaign)}</BaseButton>
      }
      handleSubmit={handleSubmit}
      paddingContent="0px"
      widthModal={1000}
      renderContent={
        <RootWrapper>
          <BaseRow className="head">
            <BaseCol xl={24}>
              <BaseForm.Item hiddenLabel>
                <BaseInput
                  placeholder={t(R.strings.enter_campaign_name)}
                  value={searchCampaign}
                  onChange={e =>
                    onChangeSearchCampaign?.(e?.target?.value ?? '')
                  }
                  allowClear
                  prefix={<SearchOutlined rev={undefined} />}
                />
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>

          <BaseTableManagement
            columns={[
              convertColumnTable<IDiscountCampaign>({
                title: t(R.strings.campaign_name),
                key: 'name',
                render: (text, record) => {
                  const itemSelected = selectedCampaign?.find(
                    item => item?._id === record?._id,
                  )
                  return <div
                    className="name-column"
                    onClick={() => {
                      if (itemSelected?.isInit) return
                      onTabSelectCampaign(record)
                    }}
                  >
                    <BaseText
                      children={text}
                      fontWeight="medium"
                      fontSize="xs"
                    />
                  </div>
                },
              }),
              convertColumnTable<IDiscountCampaign>({
                key: '_id',
                width: '12%',
                classNameWidthColumnOverwrite: 'number-column',
                // title: (
                //   <BaseRow gutter={8} wrap={false}>
                //     <BaseCol>
                //       <BaseText
                //         children={t(R.strings.all)}
                //         fontWeight="medium"
                //         opacity="0.7"
                //       />
                //     </BaseCol>

                //     <BaseCol>
                //       <BaseCheckbox />
                //     </BaseCol>
                //   </BaseRow>
                // ),
                title: '',
                render: (_, record) => {
                  const itemSelected = selectedCampaign?.find(
                    item => item?._id === record?._id,
                  )
                  return (
                    <BaseRow gutter={16} justify={'center'}>
                      <BaseCol />
                      <BaseCheckbox
                        value={!!itemSelected}
                        checked={!!itemSelected}
                        onChange={() => onTabSelectCampaign(record)}
                        disabled={itemSelected?.isInit}
                      />
                    </BaseRow>
                  )
                },
              }),
            ]}
            dataSource={dataFlatten?.data?.map((item, index) => {
              return {
                ...item,
                key: index + 1,
              }
            })}
            loading={isLoading}
            pagination={false}
            rowClassName="row-overwrite-style"
            scroll={{ x: true }}
          />

          <BaseRow
            className="footer"
            justify={'space-between'}
            gutter={[16, 8]}
            align={'middle'}
          >
            <BaseCol>
              <BaseRow gutter={8} align={'middle'}>
                <BaseCol>
                  <BaseText
                    children={`${t(R.strings.total_selected)}:`}
                    fontWeight="medium"
                  />
                </BaseCol>
                <BaseCol>
                  <BaseText
                    children={selectedCampaign?.length}
                    colorText="statesGreenColor"
                    fontWeight="medium"
                  />
                </BaseCol>
              </BaseRow>
            </BaseCol>
            <BaseCol>
              <BasePagination
                current={pagination.current}
                pageSize={pagination.pageSize}
                total={dataFlatten?.metadata?.totalItem ?? 0}
                onChange={(page, pageSize) => {
                  onChangePagination({
                    ...pagination,
                    current: page,
                    pageSize,
                  })
                }}
              />
            </BaseCol>
          </BaseRow>
        </RootWrapper>
      }
    />
  )
}

const RootWrapper = styled(BaseSpace)`
  .ant-table-wrapper .ant-table .ant-table-header {
    border-radius: 0px !important;
  }

  .ant-table-thead > tr > th {
    border: 0px !important;
  }

  .row-overwrite-style .ant-table-cell {
    border-left: 0px !important;
    border-right: 0px !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0px;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0px !important;
  }

  .head {
    padding: 0px ${PADDING.md};
  }

  .footer {
    padding: 0px ${PADDING.md};
  }

  .name-column {
    cursor: pointer;
  }
`
