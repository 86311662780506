import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable, StatusTableEnum } from './type'
import { _DEV_ } from 'app/constant'
import { getArray } from 'app/components/tables/common-table/constant'
import { BORDER_RADIUS, DateUtil, PADDING } from 'parkway-web-common'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import R from 'app/assets/R'
import _ from 'lodash'
import styled from 'styled-components'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import BaseText from 'app/components/common/BaseText'

export const useHook = () => {
  const [data, setData] = useState<IDataTable[]>([])
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()
  const getData = async () => {
    setLoading(true)
    try {
      const data = getArray(5).map(i => {
        return {
          key: i.toString(),
          stt: i,
          date: DateUtil.formatDate(new Date(), 'HH:mm DD/MM/YYYY'),
          patientName: 'Phạm Văn Khánh',
          service: 'Niềng răng mắc cài gói tiết kiệm',
          status: _.random(1, 4),
        } as IDataTable
      })
      setData(data)
    } catch (error) {
      _DEV_ && console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getData()
    }, 500)

    return () => clearTimeout(timer)
  }, [])

  const columns = useMemo(() => {
    return [
      convertColumnTable<IDataTable>({
        title: t(R.strings.ordinal_number),
        key: 'stt',
        render: (_, record) => (
          <RenderValueTable
            value={record?.stt ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
        classNameWidthColumnOverwrite: 'number-column',
        fixed: 'left',
        className: 'class-left',
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.patient_name),
        key: 'patientName',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.patientName ?? '-'}
            type="OtherLink"
          />
        ),
        fixed: 'left',
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.service),
        key: 'service',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.service ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.appointment_date),
        key: 'date',
        render: (_, record) => (
          <RenderValueTable
            value={record?.date ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.status),
        key: 'status',
        align:"end",
        render: (_, record) => (
          <StatusWrapper $type={record?.status}>
            <BaseText
              children={(() => {
                switch (record?.status) {
                  case StatusTableEnum.InExamination: {
                    return t(R.strings.in_examination)
                  }
                  case StatusTableEnum.HasArrived: {
                    return t(R.strings.has_arrived)
                  }
                  case StatusTableEnum.HasNotArrived: {
                    return t(R.strings.has_not_arrived)
                  }
                  case StatusTableEnum.Canceled: {
                    return t(R.strings.cancelled)
                  }
                }
              })()}
              fontWeight="semibold"
              fontSize="xs"
              colorText={(() => {
                switch (record?.status) {
                  case StatusTableEnum.InExamination: {
                    return 'otherBlueColor'
                  }
                  case StatusTableEnum.HasArrived: {
                    return 'statesGreenColor'
                  }
                  case StatusTableEnum.HasNotArrived: {
                    return 'statesOrangeColor'
                  }
                  case StatusTableEnum.Canceled: {
                    return 'statesRedColor'
                  }
                }
              })()}
            />
          </StatusWrapper>
        ),
      }),
    ]
  }, [t])

  return {
    columns,
    tableData: {
      data,
      loading,
    },
  }
}

const StatusWrapper = styled.div<{ $type?: StatusTableEnum }>`
  background-color: ${props => {
    switch (props?.$type) {
      case StatusTableEnum.InExamination:
        return convertedVariables.statesBlueLightColor
      case StatusTableEnum.HasArrived:
        return convertedVariables.statesGreenLightColor
      case StatusTableEnum.HasNotArrived:
        return convertedVariables.statesOrangeLightColor
      case StatusTableEnum.Canceled:
        return convertedVariables.statesRedLightColor
    }
  }};
  padding: ${PADDING.xxs};
  border-radius: ${BORDER_RADIUS};
  width: fit-content;
`
