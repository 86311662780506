import { BaseSpace } from 'app/components/common/BaseSpace'
import { ActionComponent } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/ActionComponent'
import {
  AddAppointmentSessionModal,
  DeleteTreatmentPhaseModal,
  UpdateTreatmentPhaseModal,
} from '../../modal'
import DividerLine from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/DividerLine'
import { IAppointmentSession, ITreatmentPhaseTreatmentSchedule } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'

interface IProps {
  idSchedule?: string
  handleSubmit?: (item: IAppointmentSession) => void
  treatmentPhase?: ITreatmentPhaseTreatmentSchedule
}

export const TreatmentPhaseAction: React.FC<IProps> = ({ ...props }) => {
  return (
    <ActionComponent
      content={
        <BaseSpace size={5}>
          <UpdateTreatmentPhaseModal {...props} />
          <DividerLine />
          <AddAppointmentSessionModal {...props} />
          <DividerLine />
          <DeleteTreatmentPhaseModal />
        </BaseSpace>
      }
    />
  )
}
