import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import { ModalComponent } from '../../../../components'
import { FormContentModal } from '../FormContentModal'
import { useHook } from './hook'

export const AddContactModal = () => {
  const { t } = useTranslation()

  const { form, ref, profilePatientType, setProfilePatientType, handleSubmit } =
    useHook()

  return (
    <ModalComponent
      iconOpenModal={<PlusOutlined rev={undefined} />}
      titleOpenModal={t(R.strings.add_contact)}
      titleModal={t(R.strings.add_contact)}
      styleButtonOpen={{
        backgroundColor: convertedVariables.backgroundColor,
        marginLeft: 0,
        marginRight: 0,
      }}
      ref={ref}
      borderRadiusButtonOpen={BORDER_RADIUS}
      handleSubmit={form.submit}
      renderContent={
        <FormContentModal
          form={form}
          onChangeProfilePatientType={setProfilePatientType}
          profilePatientType={profilePatientType}
          handleSubmit={handleSubmit}
        />
      }
    />
  )
}
