/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dayjs } from 'dayjs'
import { isEqual } from 'lodash'
import { useCreateCampaignContext } from '../../context'

export const useHook = () => {
    const {
        areaList,
        isLoadingArea,
        filterTreeNode,
        keywordSearchArea,
        setKeywordSearchArea,
        onChangeClinic,
        selectedClinic,
        campaignType,
        onChangeCampaignType,
        onChangeNameInfo,
        info,
        setIsApplyWithOtherCampaign,
        isApplyWithOtherCampaign,
        stackedWithCampaigns,
        campaignApplyCommon,
        onChangeCampaignApplyCommon,
        onChangeStackedWithCampaigns,
      } = useCreateCampaignContext()
    
      const onChangeName = (name?: string) => {
        onChangeNameInfo?.(name, 'name')
      }
    
      const onChangeStart = (start?: Dayjs | null) => {
        onChangeNameInfo?.(start, 'startDate')
      }
    
      const onChangeEnd = (end?: Dayjs | null) => {
        onChangeNameInfo?.(end, 'endDate')
      }
    
      const onDeleteStackedWithCampaign = (id?: string) => {
        const newStackedWithCampaigns =
          stackedWithCampaigns?.filter(item => !isEqual(item?._id, id)) ?? []
        onChangeStackedWithCampaigns?.(newStackedWithCampaigns)
      }
    
      return {
        areaList,
        isLoadingArea,
        filterTreeNode,
        keywordSearchArea,
        setKeywordSearchArea,
        onChangeClinic,
        selectedClinic,
        campaignType,
        onChangeCampaignType,
        onChangeNameInfo,
        info,
        setIsApplyWithOtherCampaign,
        isApplyWithOtherCampaign,
        onChangeName,
        onChangeStart,
        onChangeEnd,
        stackedWithCampaigns,
        campaignApplyCommon,
        onChangeCampaignApplyCommon,
        onDeleteStackedWithCampaign,
      }
}
