import { IProfileOrganization } from 'app/api/company/organizational-chart/model'
import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseSpace } from 'app/components/common/BaseSpace'


export const ItemProfileComponent = ({
  profile,
  className,
}: {
  profile: IProfileOrganization
  className?: string
}) => {
  
  return (
    <BaseSpace className={className ?? 'child'}>
      <BaseAvatarProfile
        name={profile?.name}
        jobTitle={profile?.workinfos?.jobTitle?.name ?? ''}
        src={R.images.ic_avatar}
      />
      {profile?.children?.length ? (
        <BaseSpace className="child-of-child">
          {profile?.children?.map((itm, idx) => {
            return (
              <ItemProfileComponent
                className="child-of-child-item"
                profile={itm}
                key={idx}
              />
            )
          })}
        </BaseSpace>
      ) : null}
    </BaseSpace>
  )
}