// Message code define
export const IndustryMessageCode = {
  IndustryManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20001',
  },
}

// Endpoint
export const IndustryEndPoint = {
  IndustryManagement: {
    request: 'hr/v1/industry',
    requestAll: 'hr/v1/industry/get-all',
  },
}
