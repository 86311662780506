import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { formatMoney } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
const ItemTotalInvoice = ({
  nameKeyI18n,
  value,
  prefix,
}: {
  nameKeyI18n: string
  value: number
  prefix?: string
}) => {
  const { t } = useTranslation()
  return (
    <BaseRow align={'middle'} justify={'space-between'} gutter={[15, 10]}>
      <BaseCol>
        <BaseText children={t(nameKeyI18n)} opacity="0.7" fontSize={'xs'} />
      </BaseCol>
      <BaseCol>
        <BaseText fontWeight='medium' children={formatMoney(value ?? 0, prefix)} fontSize={'xs'} />
      </BaseCol>
    </BaseRow>
  )
}

export const PaymentLayout = () => {
  const { t } = useTranslation()
  const { totalDiscount, totalQuantity, totalAmount, totalPayment } =
    useProfilePatientContext()

  return (
    <RootWrapper direction="vertical" size={15}>
      <BaseText
        children={t(R.strings.total_invoice)}
        fontWeight={'semibold'}
        fontSize={'xs'}
      />
      <ItemTotalInvoice
        nameKeyI18n={R.strings.number_of_products_services}
        value={totalQuantity}
        prefix=" "
      />
      <ItemTotalInvoice
        nameKeyI18n={R.strings.subtotal_amount}
        value={totalAmount}
      />
      <ItemTotalInvoice
        nameKeyI18n={R.strings.discount}
        value={totalDiscount}
      />

      <BaseRow align={'middle'} justify={'space-between'} gutter={[15, 10]}>
        <BaseCol>
          <BaseRow align={'middle'} gutter={[15, 10]}>
            <BaseCol>
              <BaseText
                children={t(R.strings.disount_code)}
                opacity="0.7"
                fontSize={'xs'}
              />
            </BaseCol>
            <BaseCol>
              <BaseInput placeholder={t(R.strings.enter_discount_code)} />
            </BaseCol>
          </BaseRow>
        </BaseCol>
        <BaseCol>
          <BaseText fontWeight='medium' children={formatMoney(0)} fontSize={'xs'} />
        </BaseCol>
      </BaseRow>

      <BaseRow align={'middle'} justify={'end'}>
        <BaseRow align={'middle'} gutter={[15, 10]}>
          <BaseCol>
            <BaseText
              fontWeight={'semibold'}
              children={t(R.strings.total_payment)}
              fontSize='xs'
            />
          </BaseCol>
          <BaseCol>
            <BaseText
              colorText={'statesGreenColor'}
              fontWeight={'semibold'}
              children={formatMoney(totalPayment)}
              fontSize='xs'
            />
          </BaseCol>
        </BaseRow>
      </BaseRow>
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseSpace)`
  width: 100%;
`
