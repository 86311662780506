import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { isEqual } from 'lodash'
import { TabKey } from '../../layouts/TreatmentTypeLayout/layouts/ImportModal/constant'
import styled from 'styled-components'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { useMemo } from 'react'

export const HeaderTabImportModal = ({
  tabKey,
  number,
  title,
  correctKey,
}: {
  tabKey: TabKey
  number?: number
  title: string
  correctKey?: TabKey
}) => {
  const isActive = useMemo(() => {
    return isEqual(tabKey, correctKey)
  }, [tabKey, correctKey])
  return (
    <BaseRow gutter={8} align={'middle'}>
      <BaseCol>
        <BaseText
          children={title}
          fontWeight="medium"
          colorText={isActive ? 'collapseBackgroundColor' : 'primaryColor'}
          fontSize="xs"
        />
      </BaseCol>

      <BaseCol>
        <NumberWrapper>
          <BaseText children={number} fontWeight="medium" colorText='backgroundColor' />
        </NumberWrapper>
      </BaseCol>
    </BaseRow>
  )
}

const NumberWrapper = styled.div`
  height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${convertedVariables.collapseBackgroundColor};
  border-radius: 20px;
  transition: 0.3s all ease-in-out;
`
