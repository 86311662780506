import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { ListTreatmentExample } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/constant'
import { ModalComponent } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/components'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AddDoctorModal } from '../AddDoctorModal'
import { AddNurseModal } from '../AddNurseModal'
import { useHook } from './hook'
import { ITreatmentInAppointmentSession } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'

export const AddTreatmentInTreatmentPhase = ({
  onChangeTreatmentInAppointmentSession,
}: {
  onChangeTreatmentInAppointmentSession?: (
    data?: ITreatmentInAppointmentSession,
  ) => void
}) => {
  const { t } = useTranslation()
  const {
    doctor,
    nurses,
    onChangeDoctor,
    onChangeNurses,
    onChangeTreatment,
    onDeleteDoctor,
    onDeleteNurses,
    modalRef,
    _onSubmit,
    isDisableSubmit,
    treatment,
  } = useHook({ onSubmit: onChangeTreatmentInAppointmentSession })
  return (
    <ModalComponent
      iconOpenModal={
        <BaseCol>
          <PlusOutlined rev={undefined} />
        </BaseCol>
      }
      ref={modalRef}
      handleSubmit={_onSubmit}
      titleOpenModal={t(R.strings.item_management_content_add_treatment)}
      titleModal={t(R.strings.item_management_content_add_treatment)}
      renderContent={
        <BaseSpace size={0}>
          <BaseForm.Item
            label={t(R.strings.item_management_content_treatment)}
            required
          >
            <BaseSelectWrapper
              placeholder={t(
                R.strings.item_management_content_select_treatment,
              )}
              onChange={value => onChangeTreatment(value as string)}
              value={treatment?.name}
            >
              {ListTreatmentExample?.map((item, index) => {
                return <Option key={index} value={item} children={item} />
              })}
            </BaseSelectWrapper>
          </BaseForm.Item>
          <BaseSpace>
            <BaseText
              children={t(R.strings.doctors_perform)}
              opacity="0.5"
              fontWeight="medium"
            />
            <BaseRow justify={'space-between'} align={'middle'}>
              {doctor ? (
                <BaseCol xl={6}>
                  <BaseAvatarProfile
                    name={doctor?.name}
                    jobTitle={doctor?.jobTitle}
                    src={doctor?.avatar}
                  />
                </BaseCol>
              ) : (
                <BaseCol xl={7}>
                  <AddDoctorModal onPress={onChangeDoctor} />
                </BaseCol>
              )}
              <BaseButton
                onClick={onDeleteDoctor}
                type="text"
                icon={<SvgDeleteDesignIcon />}
              />
            </BaseRow>
            <BaseText
              children={t(R.strings.nurses_perform)}
              opacity="0.5"
              fontWeight="medium"
            />
            <BaseRow justify={'space-between'} align={'middle'}>
              <BaseCol xl={20}>
                {nurses?.length ? (
                  <BaseRow align={'middle'} gutter={[15, 10]}>
                    {nurses?.map((itm, idx) => {
                      return (
                        <BaseCol key={idx} xl={6}>
                          <BaseAvatarProfile
                            name={itm?.name}
                            jobTitle={itm?.jobTitle}
                            src={itm?.avatar}
                            key={idx}
                          />
                        </BaseCol>
                      )
                    })}
                  </BaseRow>
                ) : (
                  <BaseCol>
                    <AddNurseModal onSubmit={onChangeNurses} />
                  </BaseCol>
                )}
              </BaseCol>
              <BaseButton
                onClick={onDeleteNurses}
                type="text"
                icon={<SvgDeleteDesignIcon />}
              />
            </BaseRow>
          </BaseSpace>
        </BaseSpace>
      }
      borderRadiusButtonOpen={BORDER_RADIUS}
      borderButtonOpen={`1px solid ${convertedVariables.neutralBlack9Color}`}
      styleButtonOpen={{
        backgroundColor: convertedVariables?.backgroundColor,
      }}
      isDisableSubmit={isDisableSubmit}
    />
  )
}

const BaseSelectWrapper = styled(BaseSelect)`
  width: 100%;
`
