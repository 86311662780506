import { BaseButton } from 'app/components/common/BaseButton'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseModal } from 'app/components/common/BaseModal'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputPassword } from 'app/components/common/inputs/InputPassword'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const LabelWrapper = styled(BaseRow)`
  color: var(--main-2-color);
`

export const RequireDot = styled.div`
  color: var(--states-red-color);
`
export const DashLine = styled(BaseDivider)``

export const TitleForm = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.bold};
`

export const FormItem = styled(BaseForm.Item)``

export const FormInput = styled(BaseInput)``

export const SelectBaseStyled = styled(BaseSelect)``

export const PermissionContainer = styled(BaseSpace)`
  width: 100%;
`

export const FormInputPassword = styled(InputPassword)``

export const PermissionHead = styled(BaseRow)``

export const PermissionHeadTitle = styled.div`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
`
export const PermissionHeadButton = styled(BaseButton)``

export const PermissionHeadButtonText = styled(BaseButton)`
  color: var(--states-blue-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
`
export const AreaShowPermission = styled(BaseRow)`
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  border: 1px solid #f0f1f3; // need change
  margin: 0px !important;
`
export const BaseModalCustom = styled(BaseModal)`
  .ant-modal-close {
    position: unset;
  }
`