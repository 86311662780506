import { BaseButton } from 'app/components/common/BaseButton'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.xxs};
`
export const BaseFormItem = styled(BaseForm.Item)`
  .ant-form-item-control-input {
    min-height: unset !important;
  }
  margin-bottom: 0px;
`
export const ButtonQuantity = styled(BaseButton)`
  width: 20px;
  height: 20px;
  padding: ${PADDING.xxxs};
  border-radius: ${PADDING.xxxxl};
  svg {
    width: 10px;
    height: 10px;
  }
`
export const LineVertical = styled.div`
  width: 1px;
  height: 15px;
  background-color: ${convertedVariables.neutralBlack2Color};
`
export const DiscountArea = styled(BaseRow)`
  width: 100%;
  padding: ${PADDING.xxs};
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.statesOrangeLightColor};
  margin: unset !important;
`