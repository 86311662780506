import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import UtilitiesContainer from 'app/containers/Utilities'

const UtilitiesPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.utilities)}</PageTitle>
      <UtilitiesContainer />
    </>
  )
}
const UtilitiesPage = memo(UtilitiesPageCpn, isEqual)

export default UtilitiesPage
