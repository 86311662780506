import { requestGetJobTitleManagement } from 'app/api/jobtitle'
import { JobTitleMessageCode } from 'app/api/jobtitle/constant'
import R from 'app/assets/R'
import {
  convertArrayStatusFilterToArrayStatusNumber,
  convertStatusToFilterStatus,
  convertToDayOfWeek,
} from 'app/common/helpers'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import {
  IResponseFilter,
  initResponseFilterTableManagement,
} from 'app/components/tables/BaseTableManagement/FilterManagementTable/type'
import {
  RenderValueTable,
  StatusTableEnum,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { initPagination } from 'app/constant'
import { isArray, isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  EDayOffType,
  IParamsGetJobTitleManagement,
  IResponseGetJobTitleManagement,
} from '../../../api/jobtitle/model/job-title-management'
import { DeleteJobTitle } from './components/DeleteJobTitle'
import { UpdateJobTitle } from './components/UpdateJobTitle'
import { convertDayOffType } from './constant'
import { IGeneralJobTitleManagementTable } from './type'

export const useHookTable = () => {
  const { t } = useTranslation()

  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.HR,
      FunctionPermissionEnum.JOB_TITLE_READ,
      R.strings.job_title,
    )
  }, [t])

  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IResponseFilter,
  ) => {
    const initData = {
      pagination: pagination ?? initPagination,
      data: [],
    } as IDataBaseTable<IGeneralJobTitleManagementTable>
    try {
      const status = convertArrayStatusFilterToArrayStatusNumber(
        newFilter?.status ?? filter?.status,
      )
      let params: IParamsGetJobTitleManagement = {
        page: pagination?.current,
        pageSize: pagination?.pageSize,
        keyword: newFilter?.search ?? filter?.search,
      }

      if (status !== undefined) {
        params = { ...params, status }
      }

      const response: IResponseGetJobTitleManagement =
        await requestGetJobTitleManagement(params)

      if (
        isEqual(
          response?.msgcode,
          JobTitleMessageCode.JobTitleManagement.successGetData,
        )
      ) {
        const data = response?.data?.rows
        const metadata = response?.data?.metadata

        const startIndex =
          ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

        const dataTables = data?.map((item, index) => {
          return {
            key: index + 1,
            ordinalNumber: startIndex + index + 1,
            name: item?.name ?? '-',
            description: item?.description ?? '-',
            code: item?.code ?? '-',
            _id: item?._id ?? '-',
            hasTimekeeping: item?.hasTimekeeping ?? '-',
            dayOffType: item?.dayOffType ?? '-',
            totalDynamicDayOffInWeek: item?.totalDynamicDayOffInWeek ?? '-',
            staticDayOffInWeek: item?.staticDayOffInWeek ?? '-',
            jobTitleLevelName: item?.jobTitleLevelName ?? '-',
            status: convertStatusToFilterStatus(item?.status),
          }
        })

        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pageSize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IGeneralJobTitleManagementTable>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, selectRowsTable, handleTableChange, fetch, rowSelection } =
    useHookTableManagement({ getDataTable })

  const handleChangeFilter = (newFilter: IResponseFilter) => {
    if (!isEqual(newFilter, filter)) {
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initPagination.current,
        },
        newFilter,
      )
    }
  }

  const fetchNewData = () => {
    fetch(initPagination)
    setFilter(initResponseFilterTableManagement)
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralJobTitleManagementTable>({
        title: t(R.strings.ordinal_number),
        key: 'ordinalNumber',
        fixed: 'left',
        className: 'stt-column',
        render: (_, record) => (
          <RenderValueTable value={record.ordinalNumber} type="Number" />
        ),
      }),
      convertColumnTable<IGeneralJobTitleManagementTable>({
        title: t(R.strings.job_title_content_name),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <RenderValueTable value={record.name} type="Main" />
        ),
      }),
      convertColumnTable<IGeneralJobTitleManagementTable>({
        title: t(R.strings.id),
        key: 'code',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <RenderValueTable value={record.code} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralJobTitleManagementTable>({
        title: t(R.strings.job_title_level_content_title),
        key: 'jobTitleLevelName',
        className: 'level-column',
        render: (_, record) => (
          <RenderValueTable value={record.jobTitleLevelName} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralJobTitleManagementTable>({
        title: t(R.strings.attendance_record),
        key: 'hasTimekeeping',
        className: 'yes-no-column',
        render: (_, record) => (
          <RenderValueTable
            value={record.hasTimekeeping ? t(R.strings.yes) : t(R.strings.no)}
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralJobTitleManagementTable>({
        title: t(R.strings.job_title_content_dayOffType),
        key: 'dayOffType',
        className: 'day-off-type-column',
        render: (_, record) => {
          const days =
            record.dayOffType === EDayOffType.Static
              ? isArray(record.staticDayOffInWeek) &&
                record.staticDayOffInWeek
                  ?.map(day => convertToDayOfWeek(day))
                  .join(', ')
              : undefined
          return (
            <RenderValueTable
              value={`${convertDayOffType(record.dayOffType)}${
                days ? `(${days})` : ''
              }`}
              type="Base"
            />
          )
        },
      }),
      convertColumnTable<IGeneralJobTitleManagementTable>({
        title: t(R.strings.status),
        fixed: 'right',
        key: 'status',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => (
          <RenderValueTable
            value={record.status}
            type="Status"
            actionComponent={
              <>
                <UpdateJobTitle id={record?._id} fetchTable={fetchNewData} />
                {!isEqual(record?.status, StatusTableEnum.deleted) && (
                  <DeleteJobTitle
                    id={record?._id}
                    fetchTable={fetchNewData}
                    name={record?.name}
                  />
                )}
              </>
            }
          />
        ),
      }),
    ]
    return option
  }, [t, fetchNewData])

  return {
    tableData,
    selectRowsTable,
    handleTableChange,
    fetchNewData,
    rowSelection,
    columns,
    handleChangeFilter,
    filter,
  }
}
