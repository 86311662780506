import { ApiClientTreatment } from 'app/service/api-service'
import { MeasurementUnitEndPoint } from './constant'
import {
  IPayloadCreateMeasurementUnitManagement,
  IPayloadUpdateMeasurementUnitManagement,
} from './model/management'

export const requestGetMeasurementUnitManagement = params =>
  ApiClientTreatment.Get({
    url: MeasurementUnitEndPoint.MeasurementUnitManagement.request,
    params: params,
  })

export const requestCreateMeasurementUnitManagement = (
  body: IPayloadCreateMeasurementUnitManagement,
) =>
  ApiClientTreatment.Post({
    url: MeasurementUnitEndPoint.MeasurementUnitManagement.request,
    body,
  })

export const requestUpdateMeasurementUnitManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateMeasurementUnitManagement
}) =>
  ApiClientTreatment.Put({
    url: `${MeasurementUnitEndPoint.MeasurementUnitManagement.request}/${id}`,
    body,
  })

export const requestDetailMeasurementUnitManagement = (id?: string | number) =>
  ApiClientTreatment.Get({
    url: `${MeasurementUnitEndPoint.MeasurementUnitManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteMeasurementUnitManagement = (id?: string | number) =>
  ApiClientTreatment.Delete({
    url: `${MeasurementUnitEndPoint.MeasurementUnitManagement.request}/${id}`,
    body: {},
  })

export const requestGetAllMeasurementUnitManagement = () =>
  ApiClientTreatment.Get({
    url: `${MeasurementUnitEndPoint.MeasurementUnitManagement.request}/get-all`,
    params: {},
  })
