import { ApiClientInventory } from 'app/service/api-service'
import { EndPoint } from './constant'
import {
  IParamsGetMaterialCategoryManagement,
  IParamsGetMaterialManagement,
  IParamsGetProviderManagement,
  IPayloadCreateMaterialCategoryManagement,
  IPayloadCreateMaterialManagement,
  IPayloadCreateProviderManagement,
  IPayloadUpdateMaterialCategoryManagement,
  IPayloadUpdateMaterialManagement,
  IPayloadUpdateProviderManagement,
} from './model/management'

export const requestGetProviderManagement = (
  params: IParamsGetProviderManagement,
) =>
  ApiClientInventory.Get({
    url: EndPoint.ProviderManagement.request,
    params: { params },
  })

export const requestCreateProviderManagement = (
  body: IPayloadCreateProviderManagement,
) =>
  ApiClientInventory.Post({
    url: EndPoint.ProviderManagement.request,
    body,
  })

export const requestUpdateProviderManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateProviderManagement
}) =>
  ApiClientInventory.Put({
    url: `${EndPoint.ProviderManagement.request}/${id}`,
    body,
  })

export const requestDetailProviderManagement = (id?: string | number) =>
  ApiClientInventory.Get({
    url: `${EndPoint.ProviderManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteProviderManagement = (id?: string | number) =>
  ApiClientInventory.Delete({
    url: `${EndPoint.ProviderManagement.request}/${id}`,
    body: {},
  })

export const requestExportProviderManagement = (
  params: IParamsGetProviderManagement,
) =>
  ApiClientInventory.Get({
    url: `${EndPoint.ProviderManagement.request}/export`,
    params: { params },
  })

export const requestGetMaterialManagement = (
  params: IParamsGetMaterialManagement,
) =>
  ApiClientInventory.Get({
    url: EndPoint.MaterialManagement.request,
    params: { params },
  })

export const requestCreateMaterialManagement = (
  body: IPayloadCreateMaterialManagement,
) =>
  ApiClientInventory.Post({
    url: EndPoint.MaterialManagement.request,
    body,
  })

export const requestUpdateMaterialManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateMaterialManagement
}) =>
  ApiClientInventory.Put({
    url: `${EndPoint.MaterialManagement.request}/${id}`,
    body,
  })

export const requestDetailMaterialManagement = (id?: string | number) =>
  ApiClientInventory.Get({
    url: `${EndPoint.MaterialManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteMaterialManagement = (id?: string | number) =>
  ApiClientInventory.Delete({
    url: `${EndPoint.MaterialManagement.request}/${id}`,
    body: {},
  })

export const requestExportMaterialManagement = (
  params: IParamsGetMaterialManagement,
) =>
  ApiClientInventory.Get({
    url: `${EndPoint.MaterialManagement.request}/export`,
    params: { params },
  })

export const requestGetMaterialCategoryManagement = (
  params: IParamsGetMaterialCategoryManagement,
) =>
  ApiClientInventory.Get({
    url: EndPoint.MaterialCategoryManagement.request,
    params: { params },
  })

export const requestCreateMaterialCategoryManagement = (
  body: IPayloadCreateMaterialCategoryManagement,
) =>
  ApiClientInventory.Post({
    url: EndPoint.MaterialCategoryManagement.request,
    body,
  })

export const requestUpdateMaterialCategoryManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateMaterialCategoryManagement
}) =>
  ApiClientInventory.Put({
    url: `${EndPoint.MaterialCategoryManagement.request}/${id}`,
    body,
  })

export const requestDetailMaterialCategoryManagement = (id?: string | number) =>
  ApiClientInventory.Get({
    url: `${EndPoint.MaterialCategoryManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteMaterialCategoryManagement = (id?: string | number) =>
  ApiClientInventory.Delete({
    url: `${EndPoint.MaterialCategoryManagement.request}/${id}`,
    body: {},
  })

export const requestExportMaterialCategoryManagement = (
  params: IParamsGetMaterialCategoryManagement,
) =>
  ApiClientInventory.Get({
    url: `${EndPoint.MaterialCategoryManagement.request}/export`,
    params: { params },
  })
