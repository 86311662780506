import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import SelectProgramsComponent from './SelectProgramComponent'

export const AlternativeApplicationProgramLayout = () => {
  const { t } = useTranslation()

  const { onAddAlternativeApplicationProgram, alternativeApplicationPrograms } =
    useProfilePatientContext()

  const isDisableAddButton = useMemo(() => {
    const itemEmpty = alternativeApplicationPrograms?.findIndex(itm =>
      isEmpty(itm),
    )
    if (itemEmpty !== -1) return true

    return false
  }, [alternativeApplicationPrograms])

  const _onAddAlternativeApplicationProgram = () => {
    onAddAlternativeApplicationProgram?.('')
  }

  return (
    <BaseSpace>
      <BaseText
        fontWeight="semibold"
        children={t(R.strings.alternative_application_program)}
        fontSize="xs"
      />
      <BaseSpace>
        {alternativeApplicationPrograms?.map((item, index) => {
          return (
            <SelectProgramsComponent item={item} index={index} key={index} />
          )
        })}
      </BaseSpace>

      <BaseButton
        type="link"
        icon={<PlusOutlined rev={undefined} />}
        disabled={isDisableAddButton}
        onClick={_onAddAlternativeApplicationProgram}
      >
        {t(R.strings.add_alternative_application_program)}
      </BaseButton>
    </BaseSpace>
  )
}
