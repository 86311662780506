import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseRow)`
  width: 100%;
  background-color: #f4f6f9; // need change
`

export const ExpandedRowRenderWrapper = styled(BaseSpace)`
  width: calc(100% - 78.5px);
  padding: ${PADDING.md};
`
export const FirstColWrapper = styled(BaseCol)`
  width: 78.5px;
  background-color: white;
  border: 0.5px solid #e8e8e8;
  border-top: none;
  border-bottom: none;
  margin: none !important;
`
export const TitleRequestJobTitleLevelDoctor = styled.div`
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.xs};
  color: #000000;
`
