import { DeleteOutlined } from '@ant-design/icons'
import { BaseCol } from 'app/components/common/BaseCol'
import { ISupplies } from 'app/containers/Accounting/ItemManagement/model/supplies'
import { useMemo } from 'react'
import BaseImageWithCharacter from '../../../../ItemManagement/components/BaseImageWithCharacter'
import * as S from './styles'
interface ISuppliesItemProps {
  supplies: ISupplies
  onChangeQuantity?: (id?: string, quantity?: number) => void
  onDeleteSupplies?: (id?: string) => void
}

const SuppliesItem = ({
  supplies,
  onChangeQuantity,
  onDeleteSupplies,
}: ISuppliesItemProps) => {
  const onPlus = () => {
    if (supplies.quantity) {
      supplies.quantity += 1
      onChangeQuantity?.(supplies.itemId, supplies.quantity)
    }
  }

  const onMinus = () => {
    if (supplies.quantity && supplies.quantity > 1) {
      supplies.quantity -= 1
      onChangeQuantity?.(supplies.itemId, supplies.quantity)
    }
  }

  const isDisableMinus = useMemo(() => {
    return supplies.quantity === 1
  }, [supplies.quantity])

  const onDelete = () => {
    onDeleteSupplies?.(supplies.itemId)
  }

  return (
    <S.RootWrapper align={'middle'} justify={'space-between'} gutter={[10, 10]}>
      <BaseCol>
        <S.InfoWrapper align={'middle'} gutter={[10, 10]}>
          <BaseCol>
            <BaseImageWithCharacter
              src={supplies?.mainImagesUrl}
              alt={supplies?.name}
            />
          </BaseCol>
          <BaseCol>
            <S.InfoSupplies direction="vertical" size={5}>
              <div className="name">{supplies.name}</div>
              <div className="uom">{supplies.usingMeasurementUnitId}</div>
            </S.InfoSupplies>
          </BaseCol>
        </S.InfoWrapper>
      </BaseCol>

      <BaseCol>
        <S.QuantitySupplies gutter={[10, 10]} align={'middle'}>
          <S.ButtonActionQuantity onClick={onMinus} disabled={isDisableMinus}>
            {'-'}
          </S.ButtonActionQuantity>
          <div className="quantity">{supplies.quantity}</div>
          <S.ButtonActionQuantity onClick={onPlus}>
            {'+'}
          </S.ButtonActionQuantity>
          <S.LineVertical />
          <BaseCol>
            <DeleteOutlined rev={undefined} onClick={onDelete} />
          </BaseCol>
        </S.QuantitySupplies>
      </BaseCol>
    </S.RootWrapper>
  )
}

export default SuppliesItem
