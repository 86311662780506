import { BasePopover } from 'app/components/common/BasePopover'
import { CategoryComponents } from 'app/components/header/components/HeaderSearch'
import { HeaderActionWrapper } from 'app/components/header/styles'
import React, { useRef } from 'react'
import { InputSearch } from '../styles'

interface SearchOverlayProps {
  query: string
  setQuery: (query: string) => void
  data: CategoryComponents[] | null
  isOverlayOpen: boolean
  setOverlayOpen: (state: boolean) => void
  placeholder: string
}

export const SearchDropdown: React.FC<SearchOverlayProps> = ({
  query,
  setQuery,
  isOverlayOpen,
  placeholder,
}) => {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null)

  return (
    <>
      <BasePopover
        overlayClassName="search-overlay"
        open={isOverlayOpen}
        getPopupContainer={() => ref.current}
      >
        <HeaderActionWrapper>
          <InputSearch
            width="100%"
            value={query}
            placeholder={placeholder}
            onChange={event => setQuery(event.target.value)}
            enterButton={null}
            addonAfter={null}
          />
          <div ref={ref} />
        </HeaderActionWrapper>
      </BasePopover>
    </>
  )
}
