/* eslint-disable @typescript-eslint/no-empty-function */
import { IAppointment } from 'app/api/appointment/model/appointment'
import { Dayjs } from 'dayjs'
import moment from 'moment'
import { createContext, useState } from 'react'

interface IPageSearchAppointmentContext {
  filter: {
    date?: Dayjs
    areaId?: string
    clinicId?: string
    filter?: string
    categoryServiceId?: string
    doctorId?: string
    keyword?: string
    startDate?
    endDate?
  }
  setFilter: (filter) => void
  openAppointment: boolean
  setOpenAppointment,
  openCreateUpdate: boolean
  setOpenCreateUpdate,
  appointment: IAppointment
  setAppointment: (appointment) => void
  cancelAppointmentItem: IAppointment
  setCancelAppointmentItem: (appointment) => void
  openConfirmModal: boolean
  setOpenConfirmModal,
}
export const PageSearchAppointmentContext = createContext<IPageSearchAppointmentContext>({
  filter: {},
  openAppointment: false,
  openCreateUpdate: false,
  appointment: {},
  cancelAppointmentItem: {},
  openConfirmModal: false,
  setFilter: () => {},
  setOpenAppointment: () => {},
  setOpenCreateUpdate: () => {},
  setAppointment: () => {},
  setCancelAppointmentItem: () => {},
  setOpenConfirmModal: () => {},
})

export const PageSearchAppointmentProvider = ({ children }) => {
  const [filter, setFilter] = useState({
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day'),
  })

  const [openAppointment, setOpenAppointment] = useState(false)
  const [openCreateUpdate, setOpenCreateUpdate] = useState(false)
  const [appointment, setAppointment] = useState({})

  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [cancelAppointmentItem, setCancelAppointmentItem] = useState({})

  return (
    <PageSearchAppointmentContext.Provider
      value={{
        filter,
        setFilter,
        openAppointment,
        setOpenAppointment,
        openCreateUpdate,
        setOpenCreateUpdate,
        appointment,
        setAppointment,
        cancelAppointmentItem,
        setCancelAppointmentItem,
        openConfirmModal,
        setOpenConfirmModal,
      }}
    >
      {children}
    </PageSearchAppointmentContext.Provider>
  )
}
