import { Modal } from 'antd'
import React from 'react'
import './index.css'

import { IProps } from './type'
import FormModal from './FormModal'
import { CloseOutlined } from '@ant-design/icons'
import FormFooter from './FormFooter'
import { useCommonHook } from './hook'

const CreateAndUpdate: React.FC<IProps> = props => {
  const {
    title,
    open,
    handleOk,
    confirmLoading,
    handleCancel,
    newAppointment,
    setCallCenter,
    submitButton,
    t,
    setRequired,
    form,
    onSelectPatient,
    onSelectPatientProfile,
    setSearchPatient,
    patients,
    handleArea,
    units,
    clinic,
    handleCategoryService,
    categoryServices,
    setSearchDoctor,
    doctors,
    items,
    disabledDate,
    timeslots,
    setNote,
    note,
    handleService,
    treatments,
    onChangeClinic,
    patientProfiles,
    required,
    setPhone,
    phone
  } = useCommonHook(props)

  return (
    <>
      <Modal
        title={title}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        wrapClassName="create-appointment modal"
        footer={
          <FormFooter
            newAppointment={newAppointment}
            props={props}
            setCallCenter={setCallCenter}
            submitButton={submitButton}
            confirmLoading={confirmLoading}
            handleCancel={handleCancel}
            t={t}
            setRequired={setRequired}
          />
        }
        closeIcon={<CloseOutlined rev={true} onClick={handleCancel} />}
      >
        <FormModal
          handleOk={handleOk}
          form={form}
          newAppointment={newAppointment}
          onSelectPatient={onSelectPatient}
          onSelectPatientProfile={onSelectPatientProfile}
          setSearchPatient={setSearchPatient}
          patients={patients}
          t={t}
          handleArea={handleArea}
          units={units}
          clinic={clinic}
          handleCategoryService={handleCategoryService}
          categoryServices={categoryServices}
          setSearchDoctor={setSearchDoctor}
          doctors={doctors}
          services={items}
          disabledDate={disabledDate}
          timeslots={timeslots}
          setNote={setNote}
          note={note}
          submitButton={submitButton}
          page={props?.page}
          handleService={handleService}
          treatments={treatments}
          onChangeClinic={onChangeClinic}
          patientProfiles={patientProfiles}
          preserve={false}
          required={required}
          setPhone={setPhone}
          phone={phone}
        />
      </Modal>
    </>
  )
}

export default CreateAndUpdate
