import { useTranslation } from 'react-i18next'
import {
  AssignBlock,
  AssignItem,
  AssignItemButtonCancel,
  AssignItemButtonConfirm,
  AssignItemNumber,
  AssignItemRequire,
  AssignItemSelect,
  AssignFlex,
} from './styles'
import { IProps } from './type'
import R from 'app/assets/R'
import { useContext, useEffect, useState } from 'react'
import { ProfileContext } from 'app/context/ProfileContext'
import { JobTitleGroup } from 'app/common/enum'
import { Option } from 'app/components/common/selects/BaseSelect'
import {
  DropdownRender,
  SearchWrapper,
  SearchOutlinedStyled,
} from 'app/components/common/selects/SelectSearch/styles'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { sumBy, values, map } from 'lodash'
import { requestAssignMultipleCallCenterBookingManagement } from 'app/api/booking'
import { notificationController } from 'app/controllers/notification-controller'

export const Assign = (props: IProps) => {
  const { selectedRows, setSelectedRows, fetchNewData } = props
  const { profiles: callCenters, fetchData: fetchCallCenters } =
    useContext(ProfileContext)
  const [searchCallCenter, setSearchCallCenter] = useState('')
  const [callCenter, setCallCenter] = useState<string>('')
  const [isCheck, setIsCheck] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const getCallCenterData = () => {
    fetchCallCenters?.({
      page: 0,
      pagesize: 50,
      sort: '-1',
      group: JobTitleGroup.callCenter,
      keyword: searchCallCenter,
    })
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getCallCenterData()
    }, 500)
    return () => clearTimeout(timer)
  }, [searchCallCenter])

  const { t } = useTranslation()

  const arrSelectedRows = values(selectedRows)
  const selectedTotal = sumBy(arrSelectedRows, 'selectedRows.length')

  const reset = () => {
    setSelectedRows({})
    getCallCenterData()
    setIsCheck(false)
    setCallCenter('')
  }
  const handleConfirm = async () => {
    if (!callCenter) return setIsCheck(true)
    setLoading(true)
    try {
      const ids = arrSelectedRows
        ?.map(item => map(item?.selectedRows, '_id'))
        ?.flat(Infinity)

      await requestAssignMultipleCallCenterBookingManagement({
        body: {
          ids,
          callCenterId: callCenter,
          isAssigned: true,
        },
      })
      await fetchNewData()
      notificationController.success({
        message: t(R.strings.update),
        description: t(R.strings.success),
      })
    } catch (error) {
      notificationController.error({ message: String(error) })
    }
    setLoading(false)
    reset()
  }

  const handleCancel = () => {
    reset()
  }
  return (
    <>
      {selectedTotal > 0 && (
        <AssignBlock>
          <AssignItem>
            {t(R.strings.selecting)}
            <AssignItemNumber>{selectedTotal}</AssignItemNumber>
          </AssignItem>

          <AssignItem>
            <AssignFlex>
              <AssignItemSelect
                onSelect={() => setIsCheck(false)}
                placeholder={t(
                  R.strings.booking_content_select_profile_call_center,
                )}
                onChange={value => setCallCenter(String(value))}
                filterOption={false}
                dropdownRender={menu => (
                  <DropdownRender>
                    <SearchWrapper>
                      <SearchOutlinedStyled
                        className="search__icon"
                        rev=""
                        size={20}
                      />
                      <BaseInput
                        className="search__input"
                        placeholder={t(R.strings.search)}
                        onChange={value =>
                          setSearchCallCenter(value?.target?.value)
                        }
                        allowClear
                      />
                    </SearchWrapper>
                    {menu}
                  </DropdownRender>
                )}
                className={isCheck ? 'require' : ''}
                value={callCenter || undefined}
              >
                {callCenters?.map(
                  (callCenter: { _id: string; name: string }, index) => {
                    return (
                      <Option value={callCenter?._id} key={index}>
                        {callCenter?.name}
                      </Option>
                    )
                  },
                )}
              </AssignItemSelect>
              {isCheck && (
                <AssignItemRequire>
                  {t(R.strings.please_select_employee)}
                </AssignItemRequire>
              )}
            </AssignFlex>
          </AssignItem>

          <AssignItem>
            <AssignItemButtonConfirm onClick={handleConfirm} loading={loading}>
              {t(R.strings.confirm)}
            </AssignItemButtonConfirm>
            <AssignItemButtonCancel onClick={handleCancel}>
              {t(R.strings.cancel)}
            </AssignItemButtonCancel>
          </AssignItem>
        </AssignBlock>
      )}
    </>
  )
}
