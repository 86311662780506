import { DiscountVoucherMessageCode } from 'app/api/marketing/discount-voucher/constant'
import R from 'app/assets/R'
import { _DEV_ } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { useReTryDiscountVoucher } from 'app/react-query/hook/voucher'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPropsReTryVoucher } from './type'
import { useQueryClient } from 'react-query'
import { VoucherKeys } from 'app/react-query/query-key/voucher'

export const useModalHook = (props: IPropsReTryVoucher) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const { mutateAsync: mutateAsyncReTry, isLoading } = useReTryDiscountVoucher()
  const query = useQueryClient()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleSubmit = async () => {
    if (!props?.id) return
    try {
      const response: ResponseType<unknown> = await mutateAsyncReTry?.({
        id: props?.id,
      })

      if (
        isEqual(
          response?.msgcode,
          DiscountVoucherMessageCode.DiscountVoucher.success,
        )
      ) {
        handleCancel()
        query.fetchQuery({
          queryKey: VoucherKeys.allVouchers
        })
        notificationController.success({
          message: t(R.strings.retry_generate_voucher),
          description: t(R.strings.success),
        })
      } else {
        notificationController.error({
          message: t(R.strings.retry_generate_voucher),
          description: response?.message,
        })
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    }
  }

  return {
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
  }
}
