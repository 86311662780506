/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteFilled, ImportOutlined, InboxOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseUpload } from 'app/components/common/BaseUpload'
import { BaseButtonsForm } from 'app/components/common/forms/BaseButtonsForm'
import { Loading } from 'app/components/common/Loading'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  normFile,
  PADDING
} from 'parkway-web-common'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ProfileServiceComponent } from '../../common'
import { DefineStatusEnum, DefineStatusSetEnum } from './constant'
import { IResponseImportBundle, StatusSetEnum } from './type'

interface IProps {
  onChangeFile?: (values: { file: any }) => void
  isLoading?: boolean
  handleConfirm?: () => void
  listBundle?: IResponseImportBundle[]
  onRemoveFile?: () => void
  handleCancel?: () => void
  fileSelected?: File | null | undefined
}

export const ImportBundleBillDiscountCommon = ({
  onChangeFile,
  isLoading,
  handleConfirm,
  listBundle,
  onRemoveFile,
  handleCancel,
  fileSelected,
}: IProps) => {
  const refModal = useRef<IRefModal>({})
  const { t } = useTranslation()

  const _onConfirmModal = async () => {
    handleConfirm?.()
    refModal?.current?.hide?.()
  }

  const onOpenModal = () => {
    refModal.current?.open?.()
  }

  const _handleCancel = () => {
    handleCancel?.()
  }

  return (
    <ModalComponent
      titleModal={R.strings.import_file}
      widthModal={1000}
      buttonOpenCustom={
        <BaseButton
          icon={<ImportOutlined rev={undefined} />}
          onClick={onOpenModal}
        >
          {t(R.strings.import)}
        </BaseButton>
      }
      ref={refModal}
      handleSubmit={_onConfirmModal}
      handleCancel={_handleCancel}
      renderContent={
        <BaseSpace size={8}>
          <DraggerInput
            name="dragger"
            valuePropName="file"
            getValueFromEvent={normFile}
            noStyle
          >
            <BaseUpload.Dragger
              name="files"
              onChange={onChangeFile}
              customRequest={() => undefined}
              showUploadList={false}
              accept=".xls, .xlsx"
            >
              <DraggerWrapper>
                <IconMailBox rev={undefined} size={32} />
              </DraggerWrapper>
            </BaseUpload.Dragger>
          </DraggerInput>
          {fileSelected && (
            <FileNameDisplayWrapper
              gutter={20}
              justify={'start'}
              align={'middle'}
            >
              <BaseCol>
                <BaseText
                  fontSize="xs"
                  fontWeight="medium"
                  colorText="statesGreenColor"
                >
                  {fileSelected?.name}
                </BaseText>
              </BaseCol>
              <BaseCol>
                <BaseButton
                  icon={<DeleteFilled rev={undefined} onClick={onRemoveFile} />}
                  size="small"
                />
              </BaseCol>
            </FileNameDisplayWrapper>
          )}

          {fileSelected ? (
            <BaseSpace size={8}>
              <BaseRow
                align={'middle'}
                justify={'space-between'}
                gutter={[8, 8]}
              >
                <BaseCol>
                  <BaseText
                    children={'Dịch vụ áp dụng'}
                    fontWeight="medium"
                    fontSize="xs"
                    opacity="0.7"
                  />
                </BaseCol>
              </BaseRow>
              <ContentListWrapper size={8}>
                {listBundle?.length ? (
                  listBundle?.map((item, index) => {
                    return (
                      <CollapseWrapper
                        items={[
                          {
                            key: index,
                            label: (
                              <BaseText
                                children={item?.setName}
                                fontWeight="semibold"
                                fontSize="xs"
                              />
                            ),
                            extra: (
                              <BaseText
                                children={t(
                                  DefineStatusSetEnum[item?.status]?.text,
                                )}
                                fontWeight="medium"
                                style={{
                                  color:
                                    DefineStatusSetEnum[item?.status]?.color,
                                }}
                              />
                            ),
                            style: {
                              alignItems: 'center',
                            },
                            children: (
                              <BaseSpace size={0}>
                                <BaseRow
                                  gutter={[16, 8]}
                                  align={'middle'}
                                  className="header-content"
                                  justify={'space-between'}
                                >
                                  <BaseCol xl={2}>
                                    <BaseText
                                      children={t(R.strings.row)}
                                      fontWeight="semibold"
                                    />
                                  </BaseCol>
                                  <BaseCol xl={17}>
                                    <BaseText
                                      children={t(R.strings.buy_service)}
                                      fontWeight="semibold"
                                    />
                                  </BaseCol>

                                  <BaseCol xl={5}>
                                    <BaseText
                                      children={t(R.strings.status)}
                                      fontWeight="semibold"
                                    />
                                  </BaseCol>
                                </BaseRow>

                                <BaseSpace className="list-item-content-wrapper">
                                  {item?.buyTreatment?.length ? (
                                    item?.buyTreatment?.map((itm, idx) => {
                                      return (
                                        <BaseRow
                                          key={idx}
                                          align={'middle'}
                                          gutter={[16, 8]}
                                          justify={'space-between'}
                                          style={{
                                            marginLeft: 0,
                                            marginRight: 0,
                                          }}
                                        >
                                          <BaseCol xl={2}>
                                            <BaseText
                                              children={itm?.rowNumber ?? '-'}
                                              fontWeight="medium"
                                            />
                                          </BaseCol>
                                          <BaseCol xl={17}>
                                            <ProfileServiceComponent
                                              itm={itm}
                                            />
                                          </BaseCol>

                                          <BaseCol xl={5}>
                                            <BaseText
                                              children={t(
                                                DefineStatusEnum?.[itm?.status]
                                                  ?.text,
                                              )}
                                              fontWeight="medium"
                                              style={{
                                                color:
                                                  DefineStatusEnum?.[
                                                    itm?.status
                                                  ]?.color,
                                              }}
                                            />
                                          </BaseCol>
                                        </BaseRow>
                                      )
                                    })
                                  ) : (
                                    <BaseText
                                      children={t(R.strings.empty_list)}
                                      fontWeight="medium"
                                      opacity="0.5"
                                    />
                                  )}
                                </BaseSpace>
                              </BaseSpace>
                            ),
                          },
                        ]}
                        expandIconPosition="right"
                        key={index}
                        defaultActiveKey={
                          !isEqual(item?.status, StatusSetEnum.SUCCESS)
                            ? [index]
                            : []
                        }
                      />
                    )
                  })
                ) : (
                  <BaseEmpty />
                )}
              </ContentListWrapper>

              {isLoading ? <Loading isAbsolute size="small" /> : null}
            </BaseSpace>
          ) : null}
        </BaseSpace>
      }
    />
  )
}

const DraggerInput = styled(BaseButtonsForm.Item)``

const DraggerWrapper = styled.div`
  padding: ${PADDING.xxl} 0px;
  & svg {
    fill: var(--gray);
  }
`

const IconMailBox = styled(InboxOutlined)`
  & svg {
    width: ${FONT_SIZE.xxxl};
    height: ${FONT_SIZE.xxxl};
  }
`

const FileNameDisplayWrapper = styled(BaseRow)`
  color: var(--primary-color);
  padding-top: ${PADDING.xxs};
  & svg {
    fill: red;
  }
`

const ContentListWrapper = styled(BaseSpace)`
  overflow-y: auto;
  max-height: 400px;
  background-color: ${convertedVariables.neutralBlack9Color};
  padding: ${PADDING.xxs};
  border-radius: ${BORDER_RADIUS};

  &::-webkit-scrollbar {
    display: none;
  }
`

const CollapseWrapper = styled(BaseCollapse)`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};

  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-content {
    border-top: 1px solid ${convertedVariables.neutralBlack9Color};
    background-color: ${convertedVariables.neutralBlack9Color};
    .ant-collapse-content-box {
      padding: 0px !important;
    }
  }

  .ant-collapse-header {
    background-color: var(--background-color);
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background-color: #f9fafb;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .list-item-content-wrapper {
    padding: ${PADDING.md};
    background-color: ${convertedVariables.backgroundColor};
  }
`
