import R from 'app/assets/R'
import { useModalHook } from './hook'
import * as S from './styles'
import { IPropsUpdateBooking } from './type'
import { CallingIcon, PhoneIcon } from 'app/common/icons'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { BookingStatusEnum } from 'app/api/booking/model/management'
import { Recall } from '../Recall'
import { useEffect, useState } from 'react'
import CreateAndUpdate from 'app/containers/CustomerCare/AppointmentPage/SideBarRight/CalendarRight/CreateAndUpdate'
import { useTranslation } from 'react-i18next'
import { PageType } from 'app/containers/CustomerCare/AppointmentPage/SideBarRight/CalendarRight/CreateAndUpdate/type'
import { ButtonUpdateAction } from 'app/components/common/BaseModalManagement/styles'
import dayjs from 'dayjs'
import { get } from 'lodash'
import { EScheduleStatus } from 'app/api/appointment/model'
import { DATE } from 'app/common/constants'
import { APPOINTMENT_VIEW_TYPE } from 'app/common/enum'

export const Update = (props: IPropsUpdateBooking) => {
  const { id } = useModalHook(props)

  const {
    fetchTable,
    titleButton,
    showIcon,
    isCalling = true,
    isUpdate = false,
    nameCalling,
  } = props
  const [open, setOpen] = useState(false)
  const [statusOnSubmit, setStatusOnSubmit] = useState<number | null>(null)
  const [appointment, setAppointment] = useState(props?.appointment)
  const { t } = useTranslation()

  useEffect(() => {
    setAppointment(props?.appointment)
  }, [props?.appointment])


  const footer = handleAction => {
    return (
      <S.FooterModal>
        <S.ButtonAction
          className="confirm"
          loading={
            statusOnSubmit === BookingStatusEnum.CONFIRM &&
            handleAction?.confirmLoading
          }
          onClick={() => {
            setStatusOnSubmit(BookingStatusEnum.CONFIRM)
            handleAction?.setCallCenter({
              status: BookingStatusEnum.CONFIRM,
              scheduleStatus: EScheduleStatus.SCHEDULE,
              fetch: () => {
                setOpen(false)
                fetchTable()
              },
            })
            handleAction?.setRequired(true)
            handleAction?.submitButton?.current?.click()
          }}
        >
          {t(R.strings.confirm)}
        </S.ButtonAction>
        <Recall
          loading={
            statusOnSubmit === BookingStatusEnum.RECALL &&
            handleAction?.confirmLoading
          }
          id={id}
          handleSubmit={(values) => {
            setStatusOnSubmit(BookingStatusEnum.RECALL)
            handleAction?.setCallCenter({
              status: BookingStatusEnum.RECALL,
              scheduleStatus: EScheduleStatus.DEFAULT,
              recallAt: values?.recallAt?.format(DATE.YYYY_MM_DD_HH_MM),
              fetch: () => {
                setOpen(false)
                fetchTable()
              },
            })
            handleAction?.setRequired(false)
            handleAction?.submitButton?.current?.click()
          }}
        />
        <S.ButtonAction
          className="cancel"
          loading={
            statusOnSubmit === BookingStatusEnum.CANCEL &&
            handleAction?.confirmLoading
          }
          onClick={async () => {
            setStatusOnSubmit(BookingStatusEnum.CANCEL)
            handleAction?.setCallCenter({
              status: BookingStatusEnum.CANCEL,
              scheduleStatus: EScheduleStatus.DEFAULT,
              fetch: () => {
                setOpen(false)
                fetchTable()
              },
            })
            handleAction?.setRequired(false)
            handleAction?.submitButton?.current?.click()
          }}
        >
          {t(R.strings.cancel)}
        </S.ButtonAction>
      </S.FooterModal>
    )
  }

  const addFieldApointment = isUpdate
    ? {
        scheduleStatus: EScheduleStatus.DEFAULT,
        status: BookingStatusEnum.REQUEST,
      }
    : {}
  return (
    <>
      {open && (
        <CreateAndUpdate
          required={false}
          open={open}
          setOpen={setOpen}
          appointment={{
            ...appointment,
            ...addFieldApointment,
            fetch: props?.fetchTable,
            date: dayjs(appointment?.date),
            services: get(appointment, 'services[0]._id', ''),
            treatments: get(appointment, 'treatments[0]._id', ''),
          }}
          setAppointment={e => console.log(e)}
          page={PageType.CALLCENTER}
          view={APPOINTMENT_VIEW_TYPE.LIST}
          footer={footer}
          isCalling={isCalling}
          title={
            <span>
              {isCalling ? (
                <S.ModalTitle>
                  <CallingIcon />
                  {t(R.strings.booking_content_calling, { name: nameCalling })}
                  ...
                </S.ModalTitle>
              ) : (
                t(R.strings.update_schedule)
              )}
            </span>
          }
        />
      )}
      <BaseCol>
        <ButtonUpdateAction onClick={() => setOpen(true)}>
          <BaseRow gutter={8} justify={'center'} align={'middle'}>
            {showIcon && <PhoneIcon />}
            <BaseCol>{titleButton}</BaseCol>
          </BaseRow>
        </ButtonUpdateAction>
      </BaseCol>
    </>
  )
}
