import {
    ITargetUserFile,
    IUserDataVoucher,
} from 'app/api/marketing/discount-voucher/model'
import { useState } from 'react'

export const useReferralTargetHook = () => {
  const [targetReferralFile, setTargetReferralFile] = useState<
    ITargetUserFile | undefined
  >(undefined)

  const [lisCustomerReferral, setListCustomerReferral] = useState<IUserDataVoucher[]>([])

  const onChangeTargetReferralFile = (file?: ITargetUserFile) => {
    setTargetReferralFile(file)
  }

  const onChangeListCustomerReferral = (users: IUserDataVoucher[]) => {
    setListCustomerReferral(users)
  }

  return {
    targetReferralFile,
    onChangeTargetReferralFile,
    onChangeListCustomerReferral,
    lisCustomerReferral,
  }
}
