import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import CollapseComponent from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/CollapseComponent'
import { TagStatusComponent } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/components'
import { ITreatmentInAppointmentSession } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export interface IItemTreatmentCollapse extends ITreatmentInAppointmentSession {
  time?: string
}

export const ItemTreatmentCollapse = ({
  index,
  item,
}: {
  index: number
  item: IItemTreatmentCollapse
}) => {
  const { t } = useTranslation()
  return (
    <CollapseWrapper
      label={
        <BaseRow gutter={[10, 10]} align={'middle'} justify={'space-between'}>
          <BaseCol>
            <BaseRow gutter={[10, 10]} align={'middle'}>
              <BaseCol>
                <DotNumber>
                  <BaseText
                    children={index}
                    fontWeight="extraBold"
                    fontSize="xxxs"
                    opacity="0.7"
                  />
                </DotNumber>
              </BaseCol>
              <BaseCol>
                <BaseText
                  children={item.name}
                  fontWeight="semibold"
                  opacity="0.7"
                />
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol>
            <TagStatusComponent
              color={convertedVariables.statesGreenColor}
              backgroundColor={convertedVariables?.statesGreenLightColor}
              textKeyI18n={R.strings.completed}
            />
          </BaseCol>
        </BaseRow>
      }
      expandIconPosition="end"
      children={
        <BaseSpaceWrapper>
          <BaseText
            children={t(R.strings.item_management_content_time_perform)}
            fontWeight="extraBold"
            fontSize="xxxs"
            opacity="0.7"
          />
          <BaseRow align={'middle'} gutter={[15, 15]} justify={'start'}>
            <BaseCol xl={8}>
              <BaseText
                children={'Thời gian ước tính thực hiện: 15 phút'}
                fontWeight="medium"
              />
            </BaseCol>
            <BaseCol xl={8}>
              <BaseRow gutter={[15, 10]} align={'middle'}>
                <BaseCol>
                  <BaseText
                    fontWeight="medium"
                    children={'Thời gian thực tế thực hiện:'}
                  />
                </BaseCol>
                <BaseCol>
                  <BaseText
                    fontWeight="medium"
                    children={'15 phút'}
                    colorText="statesRedColor"
                  />
                </BaseCol>
              </BaseRow>
            </BaseCol>
          </BaseRow>

          <BaseText
            children={t(R.strings.performer)}
            fontWeight="extraBold"
            fontSize="xxxs"
            opacity="0.7"
          />

          <BaseRow gutter={[15, 15]}>
            <BaseCol xl={8}>
              <BaseSpace>
                <BaseText
                  children={t(R.strings.doctors_perform)}
                  fontWeight="medium"
                  fontSize="xxxs"
                  opacity="0.7"
                />
                <BaseAvatarProfile
                  jobTitle={item?.doctor?.jobTitle}
                  name={item?.doctor?.name}
                />
              </BaseSpace>
            </BaseCol>
            <BaseCol xl={16}>
              <BaseSpace>
                <BaseText
                  children={t(R.strings.nurses_perform)}
                  fontWeight="medium"
                  fontSize="xxxs"
                  opacity="0.7"
                />
                <BaseRow gutter={[8, 8]} align={'middle'} >
                  {item?.nurses?.map((nurse, indexNurse) => {
                    return (
                      <BaseCol key={indexNurse} xl={8}>
                        <BaseAvatarProfile
                          jobTitle={nurse?.jobTitle}
                          name={nurse?.name}
                        />
                      </BaseCol>
                    )
                  })}
                </BaseRow>
              </BaseSpace>
            </BaseCol>
          </BaseRow>
        </BaseSpaceWrapper>
      }
    />
  )
}

const CollapseWrapper = styled(CollapseComponent)`
  .ant-collapse-content {
    background-color: ${convertedVariables.neutralBlack9Color};
  }
`

const DotNumber = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: #e8f6f9;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const BaseSpaceWrapper = styled(BaseSpace)`
  padding: ${PADDING.md} !important;
`
