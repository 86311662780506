/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance, UploadFile, UploadProps } from 'antd'
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { ISupplies } from '../model/supplies'
import { IJobTitleOfTreatment, ITreatment } from '../model/treatment'
import { IFormData, IFormRule, ItemType } from './type'
import { IProductProperty } from 'app/api/product-property/model/management'
import { isEqual } from 'lodash'
import {
  requestCreateItemManagement,
  requestUpdateItemManagement,
} from 'app/api/item'
import { ItemMessageCode } from 'app/api/item/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { useParams } from 'react-router'
import { IItemManagement } from 'app/api/item/model/management'
import { requestDetailItemManagement } from 'app/api/item'
import { ResponseType } from 'parkway-web-common'
import { useGetItemList, useGetSupplies } from 'app/react-query/hook/item'
import { usePagination } from 'app/hook'
import { IResponseQueryList } from 'app/react-query/model/common'
import { IIndustry, ILevelSkill } from 'app/api/skill/model/management'
import { useGetIndustryList } from 'app/react-query/hook/industry'
import { useGetLevelSkillManagementList } from 'app/react-query/hook/level-skill'
import { appendIds } from 'app/common/helpers'
import { useGetMeasurementUnitTreatmentList } from 'app/react-query/hook/measurement-units'
import { IMeasurementUnit } from 'app/api/treatment/model'

interface IListSkill {
  id: string
  industryId?: string
  industryName?: string
  skillId?: string
  skillName?: string
  levelSkillName?: string
  levelSkillId?: string
  levelSkillColor?: string
}

interface ICreateUpdateServiceContext {
  form?: FormInstance<IFormData>
  handleSubmit?: (values: IFormData) => void
  rules?: IFormRule
  onChangeMainImage?: (e: { fileList: UploadFile[] }) => void
  handleChangeListImage?: UploadProps['onChange']
  mainImage?: UploadFile[]
  listImage?: UploadFile[]

  listTreatment?: ITreatment[]
  itemTreatments?: ITreatment[]
  itemSupplies?: ITreatment[]
  listSkill?: IListSkill[]
  industries?: IIndustry[]
  measurementUnits?: IMeasurementUnit[]

  isLoadingTreatment?: boolean
  isLoadingSupply?: boolean
  isLoadingMeasurementUnit?: boolean
  onAddNewTreatment?: () => void
  onRemoveTreatment?: (index: number) => void
  onAddSuppliesOfTreatment?: (treatmentId: string, supplies: ISupplies) => void
  onRemoveSuppliesOfTreatment?: (
    treatmentId?: string,
    suppliesId?: string,
  ) => void
  onAddJobTitleOfTreatmentOfTreatment?: (treatmentId: string) => void
  onRemoveJobTitleOfTreatmentOfTreatment?: (
    treatmentId: string,
    jobTitleId: string,
  ) => void
  onChangeTimeOfTreatment?: (treatmentId: string, time: number) => void
  onChangeSuppliesOfTreatment?: (
    treatmentId: string,
    index: number,
    supplies: ISupplies,
  ) => void
  onChangeJobTitleOfTreatmentOfTreatment?: (
    treatmentId: string,
    index: number,
    jobTitle: IJobTitleOfTreatment,
  ) => void
  onChangeTreatmentInfo?: (index: number, newTreatment: ITreatment) => void
  editorRef?
  onAddNewProductProperties?: () => void
  onRemoveProductProperties?: (index: number) => void
  onChangeProductPropertiesInfo?: (
    index: number,
    newProductProperties: IProductProperty,
  ) => void
}

export const CreateUpdateContext = createContext<ICreateUpdateServiceContext>({
  form: undefined,
})

interface ICreateUpdateServiceProviderProps {
  children: any
}

export const CreateUpdateProvider: React.FC<
  ICreateUpdateServiceProviderProps
> = ({ children }) => {
  const { id } = useParams()
  const { flattenDataList } = usePagination()

  const [form] = BaseForm.useForm<IFormData>()
  const editorRef = useRef<HTMLFormElement>(null)

  const [listSkill, setListSkill] = useState<IListSkill[]>()
  const { data: industryData, isLoading: loadingIndustry } =
    useGetIndustryList()
  const industries = useMemo(() => {
    const flattenData: IResponseQueryList<IIndustry[]> =
      flattenDataList(industryData)
    return flattenData?.data ?? []
  }, [industryData])

  const { data: levelSkillData, isLoading: loadingLevelSkill } =
    useGetLevelSkillManagementList()

  const levelSkills = useMemo(() => {
    const flattenData: IResponseQueryList<ILevelSkill[]> =
      flattenDataList(levelSkillData)
    return flattenData?.data ?? []
  }, [levelSkillData])
  const skills: IListSkill[] = []
  Array.isArray(levelSkills) &&
    levelSkills.map(levelSkill =>
      industries.map(industry =>
        industry?.skills?.map(skill =>
          skills.push({
            id: `${appendIds([
              skill._id ?? '',
              industry._id ?? '',
              levelSkill._id ?? '',
            ])}`,
            industryId: industry._id,
            industryName: industry.name,
            skillId: skill._id,
            skillName: skill.name,
            levelSkillName: levelSkill.name,
            levelSkillId: levelSkill._id,
            levelSkillColor: levelSkill.color,
          }),
        ),
      ),
    )

  useEffect(() => {
    setListSkill(skills)
  }, [skills.length, loadingIndustry, loadingLevelSkill])

  const getDetail = async () => {
    if (!id) {
      return
    }
    try {
      const res: ResponseType<IItemManagement[]> =
        await requestDetailItemManagement(id)

      if (
        isEqual(res?.msgcode, ItemMessageCode.ItemManagement.successGetData)
      ) {
        const data = res?.data?.[0]

        const skillInfoData = listSkill
          ? data.skillsInfo?.map(skillInfo =>
              listSkill?.find(skillItem => {
                const id = appendIds([
                  skillInfo.skillId,
                  skillInfo.industryId,
                  skillInfo.levelSkillId,
                ])

                return skillItem.id === id
              }),
            )
          : []

        form.setFieldsValue({
          _id: data._id,
          name: data.name,
          code: data.code,
          oldId: data.oldId,
          type: ItemType.treatment,
          description: data.description ?? '',
          executeTime: data.executeTime ?? undefined,
          doctorTime: data.doctorTime ?? undefined,
          chairTime: data.chairTime ?? undefined,
          skillsInfo: skillInfoData ?? [],
          itemIds: data.itemIds ?? [],
          status: data?.status,
        })
      }
    } catch (error) {
      console.error({ error })
      return null
    }
  }

  useEffect(() => {
    getDetail()
  }, [listSkill?.length])

  const { data: dataTreatment, isLoading: isLoadingTreatment } = useGetItemList(
    {
      type: ItemType.treatment,
    },
  )
  const itemTreatments = useMemo(() => {
    const flattenData: IResponseQueryList<ITreatment[]> =
      flattenDataList(dataTreatment)
    return flattenData?.data ?? []
  }, [dataTreatment])

  const { data: dataSupply, isLoading: isLoadingSupply } = useGetSupplies({
    pagesize: 1000,
  })
  const itemSupplies = useMemo(() => {
    const flattenData: IResponseQueryList<ITreatment[]> =
      flattenDataList(dataSupply)
    return flattenData?.data ?? []
  }, [dataSupply])

  const { data: measurementUnitData, isLoading: isLoadingMeasurementUnit } =
    useGetMeasurementUnitTreatmentList({ pagesize: 1000 })

  const measurementUnits = useMemo(() => {
    const flattenData: IResponseQueryList<IMeasurementUnit[]> =
      flattenDataList(measurementUnitData)
    return flattenData?.data ?? []
  }, [measurementUnitData])

  const { t } = useTranslation()

  const rules = useMemo(() => {
    return {
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.treatment_management_content_treatment_name),
          }),
        },
      ],
      code: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.treatment_management_content_treatment_code),
          }),
        },
      ],
      executeTime: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(
              R.strings.treatment_management_content_treatment_execute_time,
            ),
          }),
        },
      ],
      doctorTime: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(
              R.strings.treatment_management_content_treatment_doctor_time,
            ),
          }),
        },
      ],
      chairTime: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(
              R.strings.treatment_management_content_treatment_chair_time,
            ),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
    } as IFormRule
  }, [t])

  const handleSubmit = async (values: IFormData) => {
    const itemIds = form.getFieldValue('itemIds')

    const params = {
      name: values.name,
      code: values.code,
      oldId: values.oldId,
      type: ItemType.treatment,
      description: values.description?.lastLevel?.content ?? '-',
      executeTime: values.executeTime ?? undefined,
      doctorTime: values.doctorTime ?? undefined,
      chairTime: values.chairTime ?? undefined,
      status: values.status ?? undefined,
      skillsInfo: values?.skillsInfo ?? [],
      itemIds: itemIds ?? [],
    }

    try {
      let response

      if (id) {
        response = await requestUpdateItemManagement({
          id,
          body: params,
        })
      } else {
        response = await requestCreateItemManagement(params)
      }

      if (
        isEqual(
          response?.msgcode,
          ItemMessageCode.ItemManagement.successPostData,
        )
      ) {
        notificationController.success({
          message: 'Success',
        })
      } else {
        notificationController.error({
          message: response?.message,
        })
      }
    } catch (error: any) {
      notificationController.error({
        message: error.message,
      })
    }
  }

  return (
    <CreateUpdateContext.Provider
      value={{
        form,
        handleSubmit,
        rules,

        listSkill,
        industries,

        editorRef,

        itemTreatments,
        itemSupplies,
        isLoadingTreatment,
        isLoadingSupply,
        measurementUnits,
        isLoadingMeasurementUnit,
      }}
    >
      {children}
    </CreateUpdateContext.Provider>
  )
}

export function useCreateUpdateServiceContext() {
  const context = useContext(CreateUpdateContext)

  if (context === undefined) {
    throw new Error(
      'useCreateUpdateService must be used within a CreateUpdateServiceProvider',
    )
  }
  return context
}
