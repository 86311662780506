import { ApiClient } from 'app/service/api-service'
import { TreatmentEndPoint } from './constant'
import {
  IParams,
  IParamsExportPatientSearch,
  IParamsGetPatientSearch,
} from './model'

export const requestRecordTreatment = (params: IParams) =>
  ApiClient.Get({
    url: TreatmentEndPoint.recordTreatment.requestGetData,
    params: { params },
  })

export const requestTreatmentItemBrandData = (params: IParams) =>
  ApiClient.Get({
    url: TreatmentEndPoint.treatment.requestGetItemBrandData,
    params: { params },
  })

export const requestMeasurementUnitData = (params: IParams) =>
  ApiClient.Get({
    url: TreatmentEndPoint.treatment.requestGetMeasurementUnitData,
    params: { params },
  })

export const requestGetPatientSearch = (body?: IParamsGetPatientSearch) =>
  ApiClient.Post({
    url: TreatmentEndPoint.PatientSearch.requestGetData,
    body,
  })
export const requestExportExcelPatientSearch = (
  body: IParamsExportPatientSearch,
) =>
  ApiClient.Post({
    url: TreatmentEndPoint.PatientSearch.exportExcel,
    body,
  })
