import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { useEffect } from 'react'
import { AdditionalServiceCampaignProvider } from './context'
import { RootLayout } from './RootLayout'

const AdditionalServiceCampaignLayout = () => {
  const { user, isHavePermissionFunctionAndGoBack } =
    useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.UPDATE_DISCOUNT_CAMPAIGN,
      R.strings.update_campaign,
    )
  }, [user])

  return (
    <AdditionalServiceCampaignProvider>
      <RootLayout />
    </AdditionalServiceCampaignProvider>
  )
}

export default AdditionalServiceCampaignLayout
