import { ParamsList } from 'parkway-web-common'

export const OldDBKeys = {
  allOldDBs: ['OldDBs'] as const,

  listTreatmentGroup: () =>
    [...OldDBKeys.allOldDBs, 'TreatmentGroups'] as const,
  listTreatmentGroups: (filter?: ParamsList) =>
    [...OldDBKeys.listTreatmentGroup(), { filter }] as const,
}
