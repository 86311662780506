import { useTranslation } from 'react-i18next'
import * as S from './styles'
import R from 'app/assets/R'
import { StatusTaskEnum, dataTasksDashboard } from './data'
import { BaseCol } from 'app/components/common/BaseCol'

export const TaskComponent = () => {
  const { t } = useTranslation()

  const defineStatusText = (props: { status: StatusTaskEnum }) => {
    switch (props.status) {
      case StatusTaskEnum.NOT_DONE:
        return t(R.strings.not_done)
      case StatusTaskEnum.IN_PROGRESS:
        return t(R.strings.in_progress)
      case StatusTaskEnum.DONE:
        return t(R.strings.done)
      default:
        return t(R.strings.not_done)
    }
  }

  return (
    <S.RootWrapper>
      <S.HeadWrapper justify={'space-between'} align={'middle'}>
        <S.TitleText>{t(R.strings.my_tasks)}</S.TitleText>
        <S.CountWrapper>{dataTasksDashboard.length}</S.CountWrapper>
      </S.HeadWrapper>
      <S.Line />
      <BaseCol>
        {dataTasksDashboard?.map((item, index) => {
          return (
            <S.ItemTaskWrapper
              key={`${item.id} ${index}`}
              justify={'space-between'}
              align={'middle'}
            >
              <S.TitleItemText>{item?.title}</S.TitleItemText>
              <S.StatusItemText status={item?.status}>
                {defineStatusText({ status: item?.status })}
              </S.StatusItemText>
            </S.ItemTaskWrapper>
          )
        })}
      </BaseCol>
    </S.RootWrapper>
  )
}
