import R from 'app/assets/R'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {
  ToolbarCurrentTime,
  ToolbarCurrentTimeDay,
  ToolbarCurrentTimeDayCurrent,
  ToolbarCurrentTimeDayName,
  ToolbarWrapper,
} from './styles'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { DATE } from 'app/common/constants'

moment.locale('vi')
export const navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
}

const Toolbar = props => {
  const { t } = useTranslation()
  const isSelected =
    moment(props?.selectedDate).format(DATE.YYYYMMDD) ===
    moment(props?.date).format(DATE.YYYYMMDD)
  return (
    <div className={`rbc-toolbar ${isSelected && 'seleted'}`}>
      <ToolbarWrapper>
        <span className="rbc-btn-group">
          <button
            type="button"
            onClick={() => props?.onNavigate(navigate.PREVIOUS)}
          >
            <LeftOutlined rev={undefined} className="appointment-fontsize" />
          </button>
          <button
            type="button"
            onClick={() => props?.onNavigate(navigate.NEXT)}
          >
            <RightOutlined rev className="appointment-fontsize" />
          </button>
        </span>
        <ToolbarCurrentTime className="rbc-toolbar-label toolbar-current-time">
          <div>
            <ToolbarCurrentTimeDayName>
              {moment(props?.date).format(DATE.DDDD)}
            </ToolbarCurrentTimeDayName>
            {moment().format(DATE.YYYYMMDD) ===
              moment(props?.date).format(DATE.YYYYMMDD) && (
              <ToolbarCurrentTimeDayCurrent>
                {t(R.strings.today)}
              </ToolbarCurrentTimeDayCurrent>
            )}
          </div>
          <div>
            <ToolbarCurrentTimeDay>
              {moment(props?.date).format(DATE.DD)}
            </ToolbarCurrentTimeDay>
          </div>
        </ToolbarCurrentTime>
      </ToolbarWrapper>
    </div>
  )
}

export default Toolbar
