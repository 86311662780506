import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { CardPaymentItem } from '../../components'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import R from 'app/assets/R'
import { useMemo } from 'react'

export const TotalInfoPaymentLayout = () => {
  const { profilePatient } = useProfilePatientContext()

  const totalInfoPayment = useMemo(() => {
    return profilePatient?.infoTreatmentSchedule?.totalInfoPayment
  }, [profilePatient?.infoTreatmentSchedule?.totalInfoPayment])

  return (
    <BaseRow gutter={[10, 10]} align={'middle'}>
      <BaseCol style={{display: 'flex', flex: 1}}>
        <CardPaymentItem
          titleKeyI18n={R.strings.total_amount_service}
          value={totalInfoPayment?.totalAmountService}
        />
      </BaseCol>
      <BaseCol style={{display: 'flex', flex: 1}}>
        <CardPaymentItem
          titleKeyI18n={R.strings.total_payment}
          value={totalInfoPayment?.totalPayment}
        />
      </BaseCol>

      <BaseCol style={{display: 'flex', flex: 1}}>
        <CardPaymentItem
          titleKeyI18n={R.strings.paid_amount}
          value={totalInfoPayment?.paidAmount}
        />
      </BaseCol>

      <BaseCol style={{display: 'flex', flex: 1}}>
        <CardPaymentItem
          titleKeyI18n={R.strings.refund_amount}
          value={totalInfoPayment?.refundAmount}
        />
      </BaseCol>
      <BaseCol style={{display: 'flex', flex: 1}}>
        <CardPaymentItem
          titleKeyI18n={R.strings.remaining_amount}
          value={totalInfoPayment?.remainingAmount}
          isDebtPayment
        />
      </BaseCol>
    </BaseRow>
  )
}
