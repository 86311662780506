import { IContactEmergency } from "../type/emergency-contact";

export const ExamplePatient: IContactEmergency = {
    _id: '1',
    address: '123 Example Street, City, Country',
    contact: 'John Doe (Emergency Contact)',
    name: 'John Doe',
    nameLower: 'john doe',
    dob: '1990-05-15',
    gender: 'Male',
    id: 'A123456789',
    avatar: 'https://example.com/avatar.jpg',
    jobTitle: 'Software Engineer',
    phone: '123-456-7890',
    relation: 'Mom',
  }
  