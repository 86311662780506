import R from 'app/assets/R'
import { SvgClinicIcon, SvgMedicalExaminationIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import CollapseComponent from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/CollapseComponent'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { DateUtil, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TagStatusComponent } from '../../../../components'
import {
  ArriveAtTheClinic,
  IPatientAction,
  IPatientCounseledByACounselor,
  PatientAction,
  PatientCounseledByACounselor,
  PatientLeave,
} from './components'

export interface IPatientJourneyItem {
  id?: string
  name?: string
  note?: string
  treatment?: string
  location?: string
  patientAction?: IPatientAction[]
  startDate?: string
  endTime?: string
  patientCounseledByACounselor?: IPatientCounseledByACounselor
}

export const PatientJourneyItem = ({ item }: { item: IPatientJourneyItem }) => {
  const { t } = useTranslation()
  return (
    <CollapseWrapper
      label={
        <BaseSpace size={5}>
          <BaseText children={item?.name} fontWeight="semibold" fontSize="xs" />
          <BaseText
            children={`${t(R.strings.note)}: ${item?.note}`}
            fontWeight="medium"
            opacity="0.7"
          />
        </BaseSpace>
      }
      extra={
        <TagStatusComponent
          color={convertedVariables.statesGreenColor}
          backgroundColor={convertedVariables?.statesGreenLightColor}
          textKeyI18n={t(R.strings.completed)}
        />
      }
      expandIconPosition="end"
      children={
        <BaseSpace>
          <RootWrapper>
            <ArriveAtTheClinic timeString={DateUtil.formatDate(item?.startDate ?? '', "HH:mm")}/>
            <PatientCounseledByACounselor item={item?.patientCounseledByACounselor} />
            {item?.patientAction?.map((itm, idx) => {
              return <PatientAction key={idx} item={itm} />
            })}
            <PatientLeave timeString={DateUtil.formatDate(item?.endTime ?? '', "HH:mm")} />
          </RootWrapper>
          <FooterWrapper gutter={20}>
            <BaseCol>
              <BaseRow gutter={15}>
                <SvgMedicalExaminationIcon opacity={0.7} />
                <BaseCol>
                  <BaseText
                    children={item?.treatment ?? '-'}
                    fontWeight="medium"
                    opacity="0.7"
                  />
                </BaseCol>
              </BaseRow>
            </BaseCol>
            <BaseCol>
              <BaseRow gutter={15}>
                <SvgClinicIcon opacity={0.7} />
                <BaseCol>
                  <BaseText
                    children={item?.location ?? '-'}
                    fontWeight="medium"
                    opacity="0.7"
                  />
                </BaseCol>
              </BaseRow>
            </BaseCol>
          </FooterWrapper>
        </BaseSpace>
      }
    />
  )
}

const CollapseWrapper = styled(CollapseComponent)`
  .ant-collapse-content-box {
    padding: unset !important;
  }
`

const RootWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
  background-color: #fcfcfc;
`

const FooterWrapper = styled(BaseRow)`
  background-color: ${convertedVariables.backgroundColor};
  padding: ${PADDING.md};
  margin-left: 0px !important;
  margin-right: 0px !important;
`
