import R from 'app/assets/R'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPropsDeleteSkill } from './type'
import { requestDeleteSkillManagement } from 'app/api/skill'
import { SkillMessageCode } from 'app/api/skill/constant'

export const useModalHook = (props: IPropsDeleteSkill) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const response: ResponseType<string> = await requestDeleteSkillManagement(
        props?.id,
      )

      if (
        isEqual(
          response?.msgcode,
          SkillMessageCode.SkillManagement.successDeleteData,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        props?.fetchTable?.()
        notificationController.success({
          message: t(R.strings.delete),
          description: response?.message,
        })
      } else {
        notificationController.error({
          message: t(R.strings.delete),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  return {
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
  }
}
