import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { URL_DOWNLOAD_EXAMPLE } from 'app/constant'
import moment from 'moment'
import { SelectFilterEnum } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTableMonthlyHook } from './Monthly/hook'
import { useTableWeeklyHook } from './Weekly/hook'
import { initialPagination } from './common-model'

export const useTableHook = () => {
  const { t } = useTranslation()

  const [filterResponse, setFilterResponse] = useState<IResponseFilter>({
    year: `${moment().year()}`,
    month: `${moment().month() + 1}`,
  })

  const hookWeekly = useTableWeeklyHook({
    year: filterResponse.year,
    month: filterResponse?.month,
  })
  const hookMonthly = useTableMonthlyHook({ year: filterResponse.year })

  const handleResetExpand = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.handleResetExpand
      case SelectFilterEnum.MONTH:
        return hookMonthly.handleResetExpand
      default:
        return hookWeekly.handleResetExpand
    }
  }, [filterResponse?.selectFilterType, hookWeekly, hookMonthly])

  const expandedRowKeys = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.expandedRowKeys
      case SelectFilterEnum.MONTH:
        return hookMonthly.expandedRowKeys
      default:
        return hookWeekly.expandedRowKeys
    }
  }, [filterResponse?.selectFilterType, hookWeekly, hookMonthly])

  const tableData = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return {
          ...hookWeekly.tableData,
          data: hookWeekly.data,
        }
      case SelectFilterEnum.MONTH:
        return {
          ...hookMonthly.tableData,
          data: hookMonthly.data,
        }
      default:
        return {
          ...hookWeekly.tableData,
          data: hookWeekly.data,
        }
    }
  }, [filterResponse?.selectFilterType, hookWeekly, hookMonthly])

  const handleTableChange = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.handleTableChange
      case SelectFilterEnum.MONTH:
        return hookMonthly.handleTableChange
      default:
        return hookWeekly.handleTableChange
    }
  }, [filterResponse?.selectFilterType, hookWeekly, hookMonthly])

  const handleExpand = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.handleExpand
      case SelectFilterEnum.MONTH:
        return hookMonthly.handleExpand
      default:
        return hookWeekly.handleExpand
    }
  }, [filterResponse?.selectFilterType, hookWeekly, hookMonthly])

  const onClickImport = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.onClickImport
      case SelectFilterEnum.MONTH:
        return hookMonthly.onClickImport
      default:
        return hookWeekly.onClickImport
    }
  }, [filterResponse?.selectFilterType, hookWeekly, hookMonthly])

  const handleChangeFilter = (filter: IResponseFilter) => {
    const { year, month, selectFilterType } = filter
    switch (selectFilterType) {
      case SelectFilterEnum.WEEK:
        if (year && month) {
          handleResetExpand()
          setFilterResponse(filter)
          hookWeekly.fetch(initialPagination, { year, month })
        }

        break
      case SelectFilterEnum.MONTH:
        if (year) {
          handleResetExpand()
          setFilterResponse(filter)
          hookMonthly.fetch(initialPagination, { year })
        }
        break
    }
  }

  const columns = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.columns
      case SelectFilterEnum.MONTH:
        return hookMonthly.columns
      default:
        return hookWeekly.columns
    }
  }, [t, expandedRowKeys, filterResponse?.selectFilterType])

  const urlDownload = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return {
          url: URL_DOWNLOAD_EXAMPLE.MarketingWeekPlan,
          name: 'MarketingWeekPlan.xlsx',
        }
      case SelectFilterEnum.MONTH:
        return {
          url: URL_DOWNLOAD_EXAMPLE.MarketingMonthPlan,
          name: 'MarketingMonthPlan.xlsx',
        }
      default:
        return {
          url: URL_DOWNLOAD_EXAMPLE.MarketingWeekPlan,
          name: 'MarketingWeekPlan.xlsx',
        }
    }
  }, [filterResponse?.selectFilterType])

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleChangeFilter,
    filterResponse,
    urlDownload,
    onClickImport,
  }
}
