import { MethodIssueEnum } from 'app/api/marketing/discount-voucher/enum'
import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDetailVoucherContext } from '../../context'
import { DefineMethodIssueEnum } from 'app/api/marketing/discount-voucher/constant'

export const InfoVoucherLayout = () => {
  const { t } = useTranslation()
  const { onChangeMethodIssueVoucher, itemCampaign } = useDetailVoucherContext()

  return (
    <RootWrapper>
      <BaseRow className="head">
        <BaseText
          children={t(R.strings.voucher_info)}
          fontWeight="semibold"
          fontSize="md"
        />
      </BaseRow>
      <BaseSpace className="body-content" size={8}>
        <FormItem label={t(R.strings.campaign_apply)} required>
          <BaseInput
            defaultValue={itemCampaign?.name}
            value={itemCampaign?.name}
            disabled
          />
        </FormItem>

        <FormItem label={t(R.strings.voucher_name)} required name={'name'}>
          <BaseInput disabled placeholder={t(R.strings.enter_voucher_name)} />
        </FormItem>

        <FormItem
          label={t(R.strings.method_issue_voucher)}
          required
          name="methodIssue"
        >
          <BaseSelect
            placeholder={t(R.strings.select_method_issue_voucher)}
            onChange={value =>
              onChangeMethodIssueVoucher?.(value as MethodIssueEnum)
            }
            options={[
              {
                label: t(DefineMethodIssueEnum[MethodIssueEnum.Auto].keyI18n),
                value: MethodIssueEnum.Auto,
              },
              {
                label: t(
                  DefineMethodIssueEnum[MethodIssueEnum.Fillable].keyI18n,
                ),
                value: MethodIssueEnum.Fillable,
              },
            ]}
            showSearch
            disabled
          />
        </FormItem>
      </BaseSpace>
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  border-radius: ${BORDER_RADIUS};
  .head {
    padding: ${PADDING.md};
    border-start-start-radius: ${BORDER_RADIUS};
    border-start-end-radius: ${BORDER_RADIUS};
    background-color: ${convertedVariables.backgroundColor};
    border-bottom: 1px solid ${convertedVariables.neutralBlack2Color};
  }
  .body-content {
    padding: ${PADDING.md};
    border-end-end-radius: ${BORDER_RADIUS};
    border-end-start-radius: ${BORDER_RADIUS};
    background-color: #fcfcfc;
  }
`

const FormItem = styled(BaseForm.Item)`
  width: 100%;
  margin-bottom: 0px !important;
`
