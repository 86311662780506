import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableWrapper } from 'app/components/tables/BaseTableReport/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 0px ${PADDING.md} ${PADDING.md} ${PADDING.md};
`

export const HeadPageWrapper = styled(HeaderPage)`
  padding-bottom: 0px !important;
`

export const BaseTableReport = styled(BaseTableWrapper)`
  .ant-table-thead > tr > th {
    text-align: center !important;
    font-size: ${FONT_SIZE.xxs} !important;
    font-weight: ${FONT_WEIGHT.semibold} !important;
    color: rgba(0, 34, 68, 0.7) !important;
  }
  .money-column {
    min-width: 120px !important;
  }
`
export const DayText = styled(BaseText)`
  font-size: ${FONT_SIZE.xxs} !important;
  font-weight: ${FONT_WEIGHT.semibold} !important;
  color: ${convertedVariables.primaryColor};
  opacity: 0.4;
`
export const TotalText = styled(BaseText)`
  margin: 0 3px;
`
export const DayContainer = styled(BaseSpace)``
export const VerticalDivider = styled.div`
  width: 1px;
  height: 24px;
  background-color: var(--neutral-black-2-color);
  margin: 0 12px;
`
