import { BaseButton } from 'app/components/common/BaseButton'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import styled from 'styled-components'

export const AssignBlock = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: baseline;
`

export const AssignItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--main-color-text);
`

export const AssignFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
`

export const AssignItemRequire = styled.p`
  color: #ff4d4f;
`

export const AssignItemNumber = styled.p`
  margin-left: 5px;
  color: #216ae2;
`

export const AssignItemButtonConfirm = styled(BaseButton)`
  background: #639aff;
  color: white;
  width: 120px;
  height: 36px;
  margin-right: 12px;
  &:hover {
    color: white !important;
  }
`

export const AssignItemButtonCancel = styled(BaseButton)`
  width: 120px;
  height: 36px;
`

export const AssignItemSelect = styled(BaseSelect)`
  .ant-select-selector {
    min-width: 232px;
  }
  .ant-select-arrow {
    top: 50% !important;
  }

  &.require {
    border: 1px solid #ff4d4f;
    border-radius: 7px;
  }
`
