/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetCustomerCarePlan } from 'app/api/customer-care'
import { CustomerCareMessageCode } from 'app/api/customer-care/constant'
import {
  IParamsGetCustomerCarePlan,
  IResponseGetCustomerCarePlan,
} from 'app/api/customer-care/model/customer-care'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import {
  IDataBaseTable,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { isEqual } from 'lodash'
import { Pagination, initialPagination } from 'parkway-web-common'
import { useCommonHook } from '../common-hook'
import { IDataCustomerCarePlanMonthly } from './data'

export const useTableMonthlyHook = (props: IParamsHookReport) => {
  const getDataMonthly = async (
    pagination: Pagination,
    params: IParamsGetCustomerCarePlan,
  ) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IDataCustomerCarePlanMonthly>
    try {
      const response: IResponseGetCustomerCarePlan =
        await requestGetCustomerCarePlan({
          periodType: 'month',
          ...props,
          ...params,
          page: pagination?.current ? pagination?.current - 1 : 0,
          pageSize: pagination?.pageSize ?? 10,
        })
      if (
        isEqual(
          response?.msgcode,
          CustomerCareMessageCode.CustomerCarePlan.successGetData,
        )
      ) {
        const dataResponse = response?.data?.rows ?? []
        const data: IDataCustomerCarePlanMonthly[] = dataResponse.map(
          (item, index) => {
            const page = pagination?.current ?? 0
            const pageSize = pagination?.pageSize ?? 10

            const firstNumber = (page - 1) * pageSize + index + 1
            return {
              firstNumber,
              employeeName: item?.employeeName,
              qLeadActual: item?.qLeadActual,
              numCustomerReCare: item?.reCareOldCustomerActual,
              index: index + 1,
            }
          },
        )

        const metadata = response?.data?.metadata
        const totalItem = metadata?.totalItem ?? 0

        return {
          pagination: {
            ...pagination,
            total: totalItem,
          },
          data,
        } as IDataBaseTable<IDataCustomerCarePlanMonthly>
      }
      return initData
    } catch (error) {
      return initData
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,

    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({ getTreeTableData: getDataMonthly })

  const { columns, onClickImport } = useCommonHook({
    fetch,
    fetchLoading,
    typeHook: 'month',
    currentFilter: props,
  })

  const _onClickImport = async (file: any) => {
    onClickImport(file, 'month')
  }

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleResetExpand,
    fetch,
    fetchLoading,
    onClickImport: _onClickImport,
  }
}
