import { FONT_SIZE, FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 0px ${PADDING.md};
`
export const TextJobTitleInProfileName = styled.div`
  color: ${colors.primary};
  font-weight: ${FONT_WEIGHT.regular};
  font-size: ${FONT_SIZE.xxs};
`
