import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { getArray } from 'app/components/tables/common-table/constant'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ProductServiceItem } from './components'

export const PaymentHistoryLayout = () => {
  const { t } = useTranslation()
  return (
    <RootWrapper>
      <HeadItemWrapper align={'middle'} gutter={15} wrap={false}>
        <BaseCol xl={1}>
          <BaseText
            children={t(R.strings.ordinal_number)}
            opacity="0.7"
            fontSize="xxxs"
            fontWeight="bold"
          />
        </BaseCol>
        <BaseCol xl={3}>
          <BaseText
            children={t(R.strings.date_time)}
            opacity="0.7"
            fontSize="xxxs"
            fontWeight="bold"
          />
        </BaseCol>
        <BaseCol xl={7}>
          <BaseText
            children={t(R.strings.order_id)}
            opacity="0.7"
            fontSize="xxxs"
            fontWeight="bold"
          />
        </BaseCol>
        <BaseCol xl={3}>
          <BaseText
            children={t(R.strings.amount)}
            opacity="0.7"
            fontSize="xxxs"
            fontWeight="bold"
          />
        </BaseCol>
        <BaseCol xl={3}>
          <BaseText
            children={t(R.strings.total_payment_made)}
            opacity="0.7"
            fontSize="xxxs"
            fontWeight="bold"
          />
        </BaseCol>
        <BaseCol xl={4}>
          <BaseText
            children={t(R.strings.payment_method)}
            opacity="0.7"
            fontSize="xxxs"
            fontWeight="bold"
          />
        </BaseCol>
        <BaseCol xl={3}>
          <BaseText
            children={t(R.strings.issue_invoice)}
            opacity="0.7"
            fontSize="xxxs"
            fontWeight="semibold"
            textAlign='center'
          />
        </BaseCol>
      </HeadItemWrapper>
      <BaseSpace size={10}>
        {getArray(5).map(itm => {
          return <ProductServiceItem index={itm} />
        })}
      </BaseSpace>
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
  background-color: #f9fafb; // need change
  border-radius: ${BORDER_RADIUS};
`

const HeadItemWrapper = styled(BaseRow)`
  padding: ${PADDING.xxs};
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.neutralBlack2Color};
`
