import { ItemCategoryProvider } from 'app/context/ItemCategoryContext'
import { WarehouseGoodsIssueProvider } from '../../context'
import Return from './return'

const ReturnWarehouseGoodsIssueContainer = () => {
  return (
    <WarehouseGoodsIssueProvider>
      <ItemCategoryProvider>
        <Return />
      </ItemCategoryProvider>
    </WarehouseGoodsIssueProvider>
  )
}

export default ReturnWarehouseGoodsIssueContainer
