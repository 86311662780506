import { IWarehouseGoodsIssue } from 'app/api/inventory-new/model/warehouse-goods-issue'
import { IBaseTableCommon } from 'app/components/tables/common-table/model'

export interface IGeneralWarehouseGoodsIssueManagementTable
  extends IBaseTableCommon,
    IWarehouseGoodsIssue {
  _id?: string
  ordinalNumber?: number
  action?: string
  createAtDate?: Date
}

export const enum GoodsIssueStatusEnum {
  CANCEL = 4,
  DELIVERING = 9,
  SHORT_RECEIVED = 11,
  CORRECT_RECEIVED = 12,
  OVER_RECEIVED = 13
}


export enum GoodsIssueActionEnum {
  UPDATE = 1,
  RECEIVE = 2,
  RETURN = 3,
  CANCEL = 4,
  VIEW_DETAIL = 5,
}

export enum GoodsIssueActionFormEnum {
  UPDATE = 1,
  CREATE = 2,
  VIEW = 3
}

