import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { t } from 'i18next'
import Images from '../../components/Images'
import { TreatmentSchedule } from './layouts/TreatmentSchedule'
import { CreateUpdateContext } from '../../context'
import * as S from '../../styles'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { InfoServiceLayout } from './layouts'

export const ServiceLayout = ({ typeAction }) => {
  const title =
    typeAction === 'update' ? R.strings.update_service : R.strings.add_service
  return (
    <CreateUpdateContext.Consumer>
      {({ form, handleSubmit }) => {
        return (
          <S.RootWrapper direction="vertical" size={0}>
            <HeaderPage titleI18nKey={title} />
            <BaseForm
              layout="vertical"
              onFinish={handleSubmit}
              requiredMark="optional"
              form={form}
            >
              <BaseSpace size={10}>
                <InfoServiceLayout form={form} />
                <Images />
                <TreatmentSchedule />
              </BaseSpace>
              <S.FormItem>
                <S.ButtonActionGroup>
                  <S.ButtonCancel
                    onClick={() => {
                      console.log('cancel')
                    }}
                  >
                    {t(R.strings.cancel)}
                  </S.ButtonCancel>
                  <S.ButtonSubmit htmlType="submit">
                    {t(R.strings.confirm)}
                  </S.ButtonSubmit>
                </S.ButtonActionGroup>
              </S.FormItem>
            </BaseForm>
          </S.RootWrapper>
        )
      }}
    </CreateUpdateContext.Consumer>
  )
}