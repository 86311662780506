/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetProfileManagement } from 'app/api/profile'
import { IProfile } from 'app/api/profile/model/management'
import { IDoctor } from 'app/containers/CustomerCare/AppointmentPage/SidebarLeft/DoctorList/type'
import { createContext, useState } from 'react'

interface IProfileContext {
  loading: boolean
  profiles: IProfile[] | IDoctor[] | any
  setLoading?: (loading) => void
  fetchData?: (params) => void
  fetchMoreData?: (params) => void
  total: number
}
export const ProfileContext = createContext<IProfileContext>({
  loading: false,
  profiles: [],
  total: 0,
})

export const ProfileProvider = ({ children }) => {
  const [data, setData] = useState<IProfile[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const fetchData = async params => {
    setLoading(true)
    const profiles = await requestGetProfileManagement(params)
    setData(profiles?.data?.rows ?? profiles?.data)
    setTotal(
      (profiles?.data?.metadata?.totalItem ?? profiles?.metadata?.totalItem) ||
        0,
    )
    setLoading(false)
  }

  const fetchMoreData = async params => {
    setLoading(true)
    const profiles = await requestGetProfileManagement(params)
    const uniqueProfiles: IProfile[] = [
      ...data,
      ...((profiles?.data?.rows ?? profiles?.data) || []),
    ].filter(
      (obj, index, self) => index === self.findIndex(o => o?._id === obj?._id),
    )
    setData(uniqueProfiles)
    setTotal(
      (profiles?.data?.metadata?.totalItem ?? profiles?.metadata?.totalItem) ||
        0,
    )
    setLoading(false)
  }

  return (
    <ProfileContext.Provider
      value={{
        profiles: data,
        loading,
        setLoading,
        fetchData,
        fetchMoreData,
        total,
      }}
    >
      {children}
    </ProfileContext.Provider>
  )
}
