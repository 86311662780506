import { DatePicker } from 'antd'
import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { DATE } from 'app/common/constants'
import { BaseCol } from 'app/components/common/BaseCol'
import { UnitContext } from 'app/context/UnitContext'
import dayjs from 'dayjs'
import { get, isEqual } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { SearchDropdown } from './search-dropdown'
import * as S from './styles'
import {
  IFilterProps,
  IResponseFilter,
  initResponseFilterTableManagement,
} from './type'

export const FilterManagement = ({
  handleChangeFilter,
  filterData
}: IFilterProps) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const { units } = useContext(UnitContext)
  const [isOverlayOpen, setOverlayOpen] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      handleChangeFilter?.(filter)
    }, 500)
    return () => clearTimeout(timer)
  }, [filter])


  useEffect(() => {
    if (filterData && !isEqual(filterData, filter)) {
      setFilter(filterData)
    }
  }, [filterData])

  useEffect(() => {
    setOverlayOpen(false)
  }, [pathname])

  const onChangeSearch = (search: string) => {
    setFilter(prev => {
      return {
        ...prev,
        search,
      }
    })
  }

  const clinics = units?.map(unit => get(unit, 'childs', [])).flat(Infinity)

  return (
    <S.HeadWrapper gutter={20} align={'middle'}>
      <BaseCol>
        <SearchDropdown
          query={filter?.search ?? ''}
          setQuery={onChangeSearch}
          data={[]}
          isOverlayOpen={isOverlayOpen}
          setOverlayOpen={setOverlayOpen}
        />
      </BaseCol>
      <BaseCol>
        <S.SelectBaseStyled
          placeholder={t(R.strings.location)}
          suffixIcon={<SvgTableCloseIcon />}
          onChange={value =>
            setFilter(prev => ({
              ...prev,
              location: String(value || ""),
            }))            
          }
          allowClear
          showSearch
          options={
            clinics?.map(item => {
              return {
                value: get(item, 'oldid'),
                label: get(item, 'name'),
              }
            }) || []
          }
        />
      </BaseCol>
      <BaseCol>
        <DatePicker
          defaultValue={filter?.startDate}
          onChange={value =>
            setFilter(prev => ({
              ...prev,
              startDate: dayjs(value?.format(DATE.YYYY_MM_DD)),
            }))
          }
          placeholder={t(R.strings.choose_date)}
        />
      </BaseCol>
      {'-'}
      <BaseCol>
        <DatePicker
          defaultValue={filter?.endDate}
          onChange={value =>
            setFilter(prev => ({
              ...prev,
              endDate: dayjs(value?.format(DATE.YYYY_MM_DD)),
            }))
          }
          placeholder={t(R.strings.choose_date)}
        />
      </BaseCol>
    </S.HeadWrapper>
  )
}
