import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { SvgParkwayLogoIcon } from 'app/assets/svg-assets'
import { CompanyAddress, CompanyBox, CompanyLogo, CompanyName } from './styles'
import { BaseRow } from 'app/components/common/BaseRow'
import { Flex } from 'antd'
import { StatusButton } from '../../../styles'
import {
  PurchaseOrderStatusColor,
  PurchaseOrderStatusIcon,
  PurchaseOrderStatusName,
} from 'app/common/constants'
import { useContext } from 'react'
import { WarehousePurchaseContext } from '../../../context'

const CompanyInfo = () => {
  const { t } = useTranslation()
  const { warehousePurchaseDetail } = useContext(WarehousePurchaseContext)
  const statusColor =
    PurchaseOrderStatusColor[Number(warehousePurchaseDetail?.status)]
  const IconComponent =
    PurchaseOrderStatusIcon[Number(warehousePurchaseDetail?.status)]
  return (
    <CompanyBox>
      <BaseRow justify={'space-between'} align={'middle'}>
        <Flex justify={'start'} align={'middle'} gap={10}>
          <CompanyLogo>
            <SvgParkwayLogoIcon />
          </CompanyLogo>
          <div>
            <CompanyName>{t(R.strings.company_name)}</CompanyName>
            <CompanyAddress>{t(R.strings.company_address)}</CompanyAddress>
          </div>
        </Flex>

        {!!warehousePurchaseDetail?._id && (
          <div>
            <StatusButton
              $color={statusColor?.color}
              $background={statusColor?.background}
            >
              {IconComponent && <IconComponent />}
              {t(
                PurchaseOrderStatusName[
                  Number(warehousePurchaseDetail?.status)
                ],
              )}
            </StatusButton>
          </div>
        )}
      </BaseRow>
    </CompanyBox>
  )
}

export default CompanyInfo
