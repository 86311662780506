import R from 'app/assets/R'
import { PageTitle } from 'app/components/common/PageTitle'
import { NotFoundContainer } from 'app/containers/NotFound'
import { isEqual } from 'lodash'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

const DevelopmentPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.development)}</PageTitle>
      <NotFoundContainer />
    </>
  )
}
const DevelopmentPage = memo(DevelopmentPageCpn, isEqual)

export default DevelopmentPage
