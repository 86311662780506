// Message code define
export const ItemCategoryMessageCode = {
  ItemCategoryManagement: {
    successGetData: '80000',
    successPutData: '80000',
    successDeleteData: '80000',
    successPostData: '20001',
  },
}

// Endpoint
export const ItemCategoryEndPoint = {
  ItemCategoryManagement: {
    request: 'treatment/v1/item-category',
  },
}
