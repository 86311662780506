/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd'
import {
  requestGetDetailCampaign,
  requestGetImportTemplateForUpdate,
} from 'app/api/marketing/discount-campaign'
import { DiscountCampaignMessageCode } from 'app/api/marketing/discount-campaign/constant'
import {
  CampaignReferralEnum,
  CampaignStatusEnum,
  CampaignTypeEnum,
  DiscountTypeEnum,
  IDataUpdateCampaign,
  IDiscountCampaign,
  IPayloadUpdateCampaign,
} from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { YesNoEnum } from 'app/common/enum'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { _DEV_ } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { IUnit } from 'app/model/unit.model'
import { useUpdateDiscountCampaign } from 'app/react-query/hook/campaign'
import dayjs from 'dayjs'
import { isEmpty, isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { useBundleHook } from './hook/bundle-hook'
import { useBuyOneGetOneHook } from './hook/buy-one-get-one-hook'
import { useHookInfo } from './hook/info-hook'
import { useTreatmentTypeHook } from './hook/treatment-type-hook'
import { IResponseImportBundle } from './RootLayout/Contents/layouts/BundleTypeLayout/ImportModal/type'
import { IResponseImportFile } from './RootLayout/Contents/layouts/TreatmentTypeLayout/layouts/ImportModal/type'
import {
  BuyOneGetOneItem,
  IBillDiscountInTable,
  IBundleServiceItem,
  IFormData,
  ITreatmentInTable,
} from './type'
import { IResponseImportBuyOneGetOne } from './RootLayout/Contents/layouts/BuyOneGetOneTypeLayout/ImportModal/type'
import { useTotalBillTypeHook } from './hook/total-bill-type-hook'
import { useBundleBillDiscountHook } from './hook/bundle-bill-discount-hook'

interface IUpdateCampaignContext {
  form?: FormInstance<IFormData>
  handleSubmit?: (values: IFormData) => void
  areaList?: IUnit[]
  isLoadingArea?: boolean
  keywordSearchArea?: string
  setKeywordSearchArea?: Dispatch<SetStateAction<string>>
  filterTreeNode?: (value?: string, treeNode?: any) => any
  onChangeClinic?: (ids: string[]) => void
  selectedClinic?: string[]
  campaignType?: CampaignTypeEnum
  onChangeCampaignType?: (value: CampaignTypeEnum) => void

  onChangeService?: (service: ITreatmentInTable[]) => void
  listService?: ITreatmentInTable[]
  onChangeDiscountType?: (value: DiscountTypeEnum, id?: string) => void
  onChangeDiscountValue?: (value: number, id?: string) => void
  onDeleteService?: (id?: string) => void

  isDisableSubmit?: boolean

  onAddNewBuyOneGetOne?: () => void
  listBuyOneGetOne?: BuyOneGetOneItem[]
  onRemoveBuyOneGetOneByIndex?: (index: number) => void
  onChangeNameByIndex?: (index: number, name: string) => void
  onChangeSelectedTreatmentByIndex?: (
    index: number,
    selectedTreatment: ITreatmentInTable[],
  ) => void
  onChangeDiscountTreatmentByIndex?: (
    index: number,
    discountTreatment: ITreatmentInTable[],
  ) => void
  onChangeItemByIndex?: (index: number, item: BuyOneGetOneItem) => void
  onChangeDiscountTypeInDiscountTreatment?: (
    index: number,
    treatmentIndex: number,
    discountType: DiscountTypeEnum,
  ) => void
  onChangeDiscountValueInDiscountTreatment?: (
    index: number,
    treatmentIndex: number,
    discountValue: number,
  ) => void

  onAddNewBundleService?: () => void
  listBundleService?: IBundleServiceItem[]
  onRemoveBundleServiceByIndex?: (index: number) => void
  onChangeNameBundleByIndex?: (index: number, name: string) => void
  onChangeDiscountTypeItemBundleService?: (
    index: number,
    treatmentIndex: number,
    discountType: DiscountTypeEnum,
  ) => void
  onChangeDiscountValueItemBundleService?: (
    index: number,
    treatmentIndex: number,
    discountValue: number,
  ) => void
  onChangeItemBundleServiceByIndex?: (
    index: number,
    item: IBundleServiceItem,
  ) => void
  onChangeQuantityItemBundleService?: (
    index: number,
    treatmentIndex: number,
    quantity: number,
  ) => void
  onDeleteItemInSelectedTreatment?: (
    index: number,
    treatmentIndex: number,
  ) => void

  isLoadingSubmit?: boolean

  handleGoBack?: () => void
  isLoading?: boolean

  addList?: ITreatmentInTable[]
  initListService?: ITreatmentInTable[]
  deleteList?: ITreatmentInTable[]
  updateList?: ITreatmentInTable[]

  updateListBundle?: IBundleServiceItem[]
  addListBundle?: IBundleServiceItem[]
  deleteListBundle?: IBundleServiceItem[]
  initBundleService?: IBundleServiceItem[]

  itemCampaign?: IDiscountCampaign
  handleDownloadTemplateTreatment?: () => void

  isLoadingDownload?: boolean

  handleImportTreatment?: (data: IResponseImportFile) => void
  handleImportBundle?: (data: IResponseImportBundle) => void
  handleImportBuyOneGetOne?: (data: IResponseImportBuyOneGetOne) => void

  onChangeTotalBill?: (services: IBillDiscountInTable[]) => void
  listTotalBill?: IBillDiscountInTable[]
  onChangeDiscountTypeTotalBill?: (value: DiscountTypeEnum, id?: string) => void
  addListTotalBill?: IBillDiscountInTable[]
  onDeleteTotalBill?: (id?: string) => void
  onChangeDiscountValueTotalBill?: (value: number, id?: string) => void
  onInitListTotalBill?: (data: IBillDiscountInTable[]) => void
  dataUpdateTotalBill?: IDataUpdateCampaign
  initTotalBill?: IBillDiscountInTable[]
  onAddNewBillTotal?: (bill: IBillDiscountInTable) => void

  // bundle total bill
  onAddNewBundleBillDiscountService?: () => void
  listBundleBillDiscountService?: IBundleServiceItem[]
  onRemoveBundleBillDiscountServiceByIndex?: (index: number) => void
  onChangeNameBundleBillDiscountByIndex?: (index: number, name: string) => void
  onChangeDiscountTypeItemBundleBillDiscountService?: (
    index: number,
    treatmentIndex: number,
    discountType: DiscountTypeEnum,
  ) => void
  onChangeDiscountValueItemBundleBillDiscountService?: (
    index: number,
    treatmentIndex: number,
    discountValue: number,
  ) => void
  onChangeItemBundleBillDiscountServiceByIndex?: (
    index: number,
    item: IBundleServiceItem,
  ) => void
  onChangeQuantityItemBundleBillDiscountService?: (
    index: number,
    treatmentIndex: number,
    quantity: number,
  ) => void
  onDeleteItemInSelectedTreatmentBundleBillDiscount?: (
    index: number,
    treatmentIndex: number,
  ) => void
  updateListBundleBillDiscount?: IBundleServiceItem[]
  addListBundleBillDiscount?: IBundleServiceItem[]
  deleteListBundleBillDiscount?: IBundleServiceItem[]
  initBundleBillDiscountService?: IBundleServiceItem[]
  handleImportBundleBillDiscount?: (data: IResponseImportBundle) => void

  isApplyWithOtherCampaign?: YesNoEnum
  setIsApplyWithOtherCampaign?: Dispatch<SetStateAction<YesNoEnum>>
  stackedWithCampaigns?: IDiscountCampaign[]
  onChangeStackedWithCampaigns?: (campaigns: IDiscountCampaign[]) => void

  campaignApplyCommon?: string
  onChangeCampaignApplyCommon?: (value: string) => void
}
export const UpdateCampaignContext = createContext<IUpdateCampaignContext>({})

export const UpdateCampaignProvider = ({ children }) => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const navigate = useNavigate()

  const [form] = BaseForm.useForm<IFormData>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingDownload, setIsLoadingDownload] = useState(false)

  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingSubmit } =
    useUpdateDiscountCampaign()

  const hookInfo = useHookInfo({ form })

  const hookTreatmentType = useTreatmentTypeHook()

  const hookBuyOneGetOne = useBuyOneGetOneHook()

  const hookBundle = useBundleHook()

  const hookTotalBill = useTotalBillTypeHook()

  const hookBundleBillDiscount = useBundleBillDiscountHook()

  const itemCampaign = useMemo(() => {
    return state?.item as IDiscountCampaign
  }, [state?.item])

  const isDisableSubmit = useMemo(() => {
    switch (hookInfo.campaignType) {
      case CampaignTypeEnum.Treatments:
        return (
          !!hookTreatmentType.listService?.find(
            item => !item.discountValue || !item.discountType,
          ) ||
          !!hookTreatmentType.listService?.find(
            item =>
              item?.discountValue &&
              item?.discountValue > 100 &&
              isEqual(DiscountTypeEnum.Percent, item?.discountType),
          )
        )
      case CampaignTypeEnum.TotalBill:
        return (
          !hookTotalBill.listTotalBill?.length ||
          !!hookTotalBill.listTotalBill?.find(
            item => !item.discount || !item.dType,
          ) ||
          !!hookTotalBill.listTotalBill?.find(
            item =>
              item?.discount &&
              item?.discount > 100 &&
              isEqual(DiscountTypeEnum.Percent, item?.dType),
          ) ||
          hookTotalBill.listTotalBill?.length !==
            new Set(hookTotalBill.listTotalBill?.map(item => item.totalBill))
              .size ||
          !!hookBundleBillDiscount?.listBundleBillDiscountService?.find(
            item => isEmpty(item?.name) || !item.selectedTreatment?.length,
          )
        )
      case CampaignTypeEnum.BuyOneGetOne:
        return (
          !hookBuyOneGetOne?.listBuyOneGetOne?.length ||
          !!hookBuyOneGetOne?.listBuyOneGetOne?.find(
            item =>
              isEmpty(item?.name) ||
              !item.selectedTreatment?.length ||
              !item.discountTreatment?.length ||
              !!item.discountTreatment?.find(
                itm => !itm.discountValue || !itm.discountType,
              ),
          )
        )
      case CampaignTypeEnum.Bundle:
        return (
          !hookBundle?.listBundleService?.length ||
          !!hookBundle?.listBundleService?.find(
            item =>
              isEmpty(item?.name) ||
              !item.selectedTreatment?.length ||
              !!item.selectedTreatment?.find(
                itm =>
                  itm.discountValue === undefined ||
                  !itm.discountType ||
                  !itm.quantity,
              ) ||
              !item.selectedTreatment?.find(item => item?.discountValue),
          )
        )
    }
  }, [
    hookTreatmentType?.listService,
    hookInfo?.campaignType,
    hookBuyOneGetOne?.listBuyOneGetOne,
    hookBundle?.listBundleService,
    hookTotalBill.listTotalBill,
    hookBundleBillDiscount?.listBundleBillDiscountService,
  ])

  const getDetail = async () => {
    try {
      setIsLoading(true)
      const res: ResponseType<IDiscountCampaign> =
        await requestGetDetailCampaign(state?.item?._id)

      if (
        isEqual(
          res?.msgcode,
          DiscountCampaignMessageCode.DiscountCampaign.successGetData,
        )
      ) {
        const data = res?.data

        const campaignApplyCommon = data?.stackedWithCampaigns?.length
          ? R.strings.campaign_list
          : R.strings.all

        const newForm: IFormData = {
          applyWithOtherCampaign: data?.isStackedDiscount
            ? YesNoEnum.Yes
            : YesNoEnum.No,
          activeType: data?.applyType,
          clinics: data?.clinics?.map(item => item._id),
          startDate: dayjs(data?.start),
          endDate: dayjs(data?.end),
          campaignType: data?.campaignType,
          campaigns: data?.stackedWithCampaigns?.map(item => item._id ?? ''),
          campaign_apply_common: campaignApplyCommon,
          referral: data?.referralType ?? CampaignReferralEnum.Normal,
          priority: data?.priority,
          name: data?.name
        }

        if (data?.campaignType) {
          hookInfo.onChangeCampaignType(data?.campaignType)
        }

        hookInfo?.setIsApplyWithOtherCampaign(
          newForm.applyWithOtherCampaign ?? YesNoEnum.No,
        )
        form.setFieldsValue(newForm)
        hookInfo.onChangeClinic(data?.clinics?.map(item => item._id) ?? [])
        hookInfo.onChangeStackedWithCampaigns(data?.stackedWithCampaigns ?? [])
        hookInfo?.onChangeCampaignApplyCommon(campaignApplyCommon)

        switch (data?.campaignType) {
          case CampaignTypeEnum.Treatments:
            hookTreatmentType?.onInitListService(
              data?.discounts?.map(item => ({
                _id: item?.treatment?._id,
                name: item?.treatment?.name,
                discountValue: item?.discount,
                discountType: item?.dType,
                quantity: item?.amount,
                regionalPrice: item?.treatment?.regionalPrice,
                parentId: item?._id,
              })) ?? [],
            )
            break
          case CampaignTypeEnum.Bundle:
            hookBundle.onInitBundleItem(
              data?.boughtTreatmentsDiscount?.map(item => ({
                id: item?._id,
                name: item?.name,
                selectedTreatment: item?.buyTreatment?.map(itm => ({
                  _id: itm?.treatment?._id,
                  name: itm?.treatment?.name,
                  discountValue: itm?.discount,
                  discountType: itm?.dType,
                  quantity: itm?.amount,
                  regionalPrice: itm?.treatment?.regionalPrice,
                })) as ITreatmentInTable[],
              })) ?? [],
            )
            break
          case CampaignTypeEnum.BuyOneGetOne:
            hookBuyOneGetOne.initBuyOneGetOne(
              data?.boughtTreatmentsDiscount?.map(item => ({
                id: item._id,
                name: item?.name,
                selectedTreatment: item?.buyTreatment?.map(itm => ({
                  _id: itm?.treatment?._id,
                  name: itm?.treatment?.name,
                  discountValue: itm?.discount,
                  discountType: itm?.dType,
                  regionalPrice: itm?.treatment?.regionalPrice,
                })) as ITreatmentInTable[],
                discountTreatment: item?.discountTreatment?.map(itm => ({
                  _id: itm?.treatment?._id,
                  name: itm?.treatment?.name,
                  discountValue: itm?.discount,
                  discountType: itm?.dType,
                  regionalPrice: itm?.treatment?.regionalPrice,
                })) as ITreatmentInTable[],
              })) ?? [],
            )
            break
          case CampaignTypeEnum.TotalBill:
            hookTotalBill.onInitListTotalBill(
              data?.billDiscounts?.map(item => ({
                _id: item?._id,
                totalBill: item?.totalBill,
                discount: item?.discount,
                dType: item?.dType,
              })) ?? [],
            )
            hookBundleBillDiscount.onInitBundleBillDiscountItem(
              data?.boughtTreatmentsDiscount?.map(item => ({
                id: item?._id,
                name: item?.name,
                selectedTreatment: item?.buyTreatment?.map(itm => ({
                  _id: itm?.treatment?._id,
                  name: itm?.treatment?.name,
                  regionalPrice: itm?.treatment?.regionalPrice,
                })) as ITreatmentInTable[],
              })) ?? [],
            )
            break
        }
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const item = state?.item as IDiscountCampaign
      if (item) {
        getDetail()
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [state])

  const handleGoBack = () => {
    navigate?.(-1)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (itemCampaign?.campaignStatus !== CampaignStatusEnum.UpComing) {
        notificationController.warning({
          type: 'warning',
          message: t(R.strings.campaign_cant_update),
        })
        handleGoBack()
      }
    }, 100)

    return () => clearTimeout(timer)
  }, [itemCampaign])

  const handleSubmit = (values: IFormData) => {
    let payload: IPayloadUpdateCampaign = {
      id: state?.item?._id ?? '',
      name: values.name,
      campaignType: hookInfo.campaignType,
      clinics: hookInfo.selectedClinic,
      start: values?.startDate?.toISOString(),
      end: values?.endDate?.toISOString(),
      priority: values?.priority,
      isStackedDiscount: !!isEqual(
        YesNoEnum.Yes,
        values?.applyWithOtherCampaign,
      ),
      applyType: values?.activeType,
      boughtTreatmentsDiscount: [],
      discounts: [],
      stackedWithCampaigns: [],
      referralType: values?.referral,
    }

    if (
      isEqual(hookInfo?.isApplyWithOtherCampaign, YesNoEnum.Yes) &&
      hookInfo?.stackedWithCampaigns?.length &&
      isEqual(values?.campaign_apply_common, R.strings.campaign_list)
    ) {
      payload = {
        ...payload,
        stackedWithCampaigns:
          hookInfo?.stackedWithCampaigns?.map(item => item?._id ?? '') ?? [],
      }
    }

    switch (hookInfo.campaignType) {
      case CampaignTypeEnum.Treatments:
        payload = {
          ...payload,
          ...hookTreatmentType.dataUpdate,
        }
        break
      case CampaignTypeEnum.BuyOneGetOne:
        payload = {
          ...payload,
          ...hookBuyOneGetOne.dataUpdateBuyOneGetOne,
        }
        break
      case CampaignTypeEnum.Voucher:
        break
      case CampaignTypeEnum.Bundle:
        payload = {
          ...payload,
          ...hookBundle.dataUpdateBundle,
        }
        break
      case CampaignTypeEnum.TotalBill:
        payload = {
          ...payload,
          ...hookTotalBill.dataUpdateTotalBill,
          ...hookBundleBillDiscount.dataUpdateBundleBillDiscount,
        }
        break
    }

    mutateAsyncUpdate?.(payload)
  }

  const handleDownloadTemplateTreatment = async () => {
    if (!itemCampaign?._id) {
      return
    }

    setIsLoadingDownload(true)
    try {
      const res: ResponseType<{ url?: string }> =
        await requestGetImportTemplateForUpdate(itemCampaign?._id)

      if (
        isEqual(
          res?.msgcode,
          DiscountCampaignMessageCode.DiscountCampaign.success,
        ) &&
        res?.data?.url
      ) {
        window.open(res?.data?.url, '_blank', 'noopener')
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoadingDownload(false)
    }
  }

  return (
    <UpdateCampaignContext.Provider
      value={{
        form,
        handleSubmit,
        isDisableSubmit,
        isLoadingSubmit,
        isLoading,
        handleGoBack,
        itemCampaign,
        handleDownloadTemplateTreatment,
        isLoadingDownload,
        ...hookInfo,
        ...hookTreatmentType,
        ...hookBuyOneGetOne,
        ...hookBundle,
        ...hookTotalBill,
        ...hookBundleBillDiscount,
      }}
    >
      {children}
    </UpdateCampaignContext.Provider>
  )
}

export function useUpdateCampaignContext() {
  const context = useContext(UpdateCampaignContext)

  if (context === undefined) {
    throw new Error(
      'useUpdateCampaign must be used within a UpdateCampaignProvider',
    )
  }
  return context
}
