/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetItemCategoryManagement } from 'app/api/item-category'
import { notificationController } from 'app/controllers/notification-controller'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useGetIndustryList } from 'app/react-query/hook/industry'
import { usePagination } from 'app/hook'
import { useGetLevelSkillManagementList } from 'app/react-query/hook/level-skill'
import { appendIds } from 'app/common/helpers'
import { useGetCategoryTreatmentList } from 'app/react-query/hook/category'
import { get } from 'lodash'
import { IItemCategory } from 'app/api/item-category/model/management'
import { IPayLoadGetList } from 'app/common/interface'
import { IParamsGetCategoryTreatment } from 'app/api/category/model'

interface IItemCategoryContext {
  loading?: boolean,
  itemCategories: IItemCategory[],
  fetchItemCategory?: (params)=> void,
  categories: [],
  listSkill?: [],
}

export const ItemCategoryContext = createContext<IItemCategoryContext>({
  loading: false,
  itemCategories: [],
  categories: [],
  listSkill: [],
})

const body: IPayLoadGetList | IParamsGetCategoryTreatment = { pagesize: 1000 }

export const ItemCategoryProvider = ({ children }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const { flattenDataList } = usePagination()

  const [listSkill, setListSkill] = useState()
  const { data: industryData } = useGetIndustryList(body)
  const { data: levelSkillData } = useGetLevelSkillManagementList(body)
  const { data: categoryData } = useGetCategoryTreatmentList(body)

  const industries = useMemo(() => {
    const flattenData = flattenDataList(industryData)
    return flattenData?.data ?? []
  }, [industryData])
  const categories = useMemo(() => {
    const flattenData = flattenDataList(categoryData)
    return flattenData?.data ?? []
  }, [categoryData])
  const levelSkills = useMemo(() => {
    const flattenData = flattenDataList(levelSkillData)
    return flattenData?.data ?? []
  }, [levelSkillData])

  const skills: any = []
  Array.isArray(levelSkills) &&
    levelSkills.map(levelSkill =>
      industries.map(industry =>
        industry?.skills?.map(skill =>
          skills?.push({
            id: `${appendIds([
              skill._id ?? '',
              industry._id ?? '',
              levelSkill._id ?? '',
            ])}`,
            industryId: industry._id,
            industryName: industry.name,
            skillId: skill._id,
            skillName: skill.name,
            levelSkillName: levelSkill.name,
            levelSkillId: levelSkill._id,
            levelSkillColor: levelSkill.color,
          }),
        ),
      ),
    )

  useEffect(() => {
    setListSkill(skills)
  }, [skills.length])

  const fetchData = async params => {
    setLoading(true)
    try {
      const itemCategories = await requestGetItemCategoryManagement(params)
      setData(itemCategories?.data?.rows)
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }
    setLoading(false)
  }

  return (
    <ItemCategoryContext.Provider
      value={{
        itemCategories: data,
        loading,
        listSkill,
        categories,
        fetchItemCategory: fetchData,
      }}
    >
      {children}
    </ItemCategoryContext.Provider>
  )
}
