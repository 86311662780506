import { MoreOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseButton } from 'app/components/common/BaseButton'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import {
  RenderValueTable,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { getArray } from 'app/components/tables/common-table/constant'
import { ICallCenter } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/call-center'
import _ from 'lodash'
import { DateUtil, FORMAT_DD_MM_YYYY, Pagination, initialPagination } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useTableHook = () => {
  const { t } = useTranslation()
  const getDataTable = async (pagination: Pagination) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<ICallCenter>
    try {
      return {
        ...initData,
        data: getArray(50)?.map(index => {
          return {
            key: index,
            ordinalNumber: index,
            employee: {
              jobTitle: 'Nhân viên call center',
              name: `Hoàng Lan Anh`,
            },
            address: `228 Trần Hưng Đạo, Nguyễn Cư Trinh, Quận 1, TP.HCM`,
            dateTime: DateUtil.formatDate(new Date(), FORMAT_DD_MM_YYYY),
            note: '-',
            reason: '-',
          } as ICallCenter
        }),
      }
    } catch (error) {
      return initData
    }
  }

  const { tableData, handleTableChange, fetch } = useHookTableManagement({
    getDataTable,
  })

  const fetchNewData = () => {
    fetch(initialPagination)
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<ICallCenter>({
        title: t(R.strings.ordinal_number),
        key: 'ordinalNumber',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => (
          <RenderValueTable value={record.ordinalNumber} type="Number" />
        ),
      }),
      convertColumnTable<ICallCenter>({
        title: t(R.strings.date_time),
        key: 'dateTime',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <RenderValueTable value={record?.dateTime ?? '-'} type="Main" />
        ),
      }),
      convertColumnTable<ICallCenter>({
        title: t(R.strings.call_reminder),
        key: 'employee',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <BaseAvatarProfile
            jobTitle={record?.employee?.jobTitle}
            name={record?.employee?.name}
          />
        ),
      }),
      convertColumnTable<ICallCenter>({
        title: t(R.strings.address),
        key: 'address',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <RenderValueTable value={record?.address ?? '-'} type="Main" />
        ),
      }),
      convertColumnTable<ICallCenter>({
        title: t(R.strings.reason),
        key: 'reason',
        render: (_, record) => (
          <RenderValueTable value={record?.reason ?? '-'} type="Main" />
        ),
      }),
      convertColumnTable<ICallCenter>({
        title: t(R.strings.note),
        key: 'note',
        render: (_, record) => (
          <RenderValueTable value={record?.note ?? '-'} type="Main" />
        ),
      }),
      convertColumnTable<ICallCenter>({
        title: t(R.strings.status),
        key: 'status',
        render: () => (
          <BaseForm>
            <BaseForm.Item hiddenLabel>
              <BaseSelect defaultValue={_.random(1, 2)}>
                <Option value={1} children={'Đã nhắc'} />
                <Option value={2} children={'Chưa nhắc'} />
              </BaseSelect>
            </BaseForm.Item>
          </BaseForm>
        ),
      }),
      convertColumnTable<ICallCenter>({
        key: 'key',
        render: () => (
          <BasePopover
            content={
              <BaseSpace>
                <BaseButton type="text">{t(R.strings.update)}</BaseButton>
                <BaseButton type="text">{t(R.strings.delete)}</BaseButton>
              </BaseSpace>
            }
          >
            <BaseButton type="text" icon={<MoreOutlined rev={undefined} />} />
          </BasePopover>
        ),
        width: '5%',
        classNameWidthColumnOverwrite: 'small-column',
      }),
    ]
    return option
  }, [t, fetchNewData])

  return {
    columns,
    tableData,
    handleTableChange,
  }
}
