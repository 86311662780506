/* eslint-disable @typescript-eslint/no-explicit-any */
import { StatusTableEnum } from 'app/common/enum'
import { IBaseTableReport } from 'app/components/tables/BaseTableReport/type'

export enum TypeItemEnum {
  service = 'service',
  product = 'product',
  combo = 'combo',
  supplies = 'supplies',
  asset = 'asset',
  tools = 'tools',
}

export const DefineValueTypeItem = {
  [TypeItemEnum.service]: 'Dịch vụ',
  [TypeItemEnum.product]: 'Sản phẩm',
  [TypeItemEnum.combo]: 'Combo',
  [TypeItemEnum.asset]: 'Tài sản',
  [TypeItemEnum.tools]: 'Dụng cụ',
  [TypeItemEnum.supplies]: 'Vật tư',
}

export interface IDataTableItemManagement
  extends IBaseTableReport<IDataTableItemManagement> {
  _id: string
  executeTime: number
  doctorTime: number
  chairTime: number
  status: StatusTableEnum
  code: string
  type: TypeItemEnum
  name?: string
  service?: string
  categoryid?: string
  ordinalNumber?: number
  skillsInfo?: any
}
