import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import * as S from 'app/components/tables/BaseTableReport/styles'
import 'dayjs/locale/vi'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { ExpandedRowRender } from './components/ExpandedRowRender'
import { FilterItemManagement } from './components/FilterItemManagement'
import { useTableHook } from './hook'
import * as SLocal from './styles'
import { ButtonCreate } from 'app/components/common/ButtonCreate'
import { ACCOUNTING_TREATMENT_MANAGEMENT_ADD_PRODUCT_SERVICE_PATH } from 'app/components/router/route-path'
import { t } from 'i18next'
import {
  CreateUpdateProvider,
  CreateUpdateContext,
} from './CreateUpdateProductServiceManagement/context'

const ItemManagementContainer: React.FC = () => {
  const {
    columns,
    tableData,
    handleTableChange,
    expandedRowKeys,
    handleExpand,
    handleChangeFilter,
    exportExcel,
    listSkill,
    fetchNewData,
  } = useTableHook()

  return (
    <CreateUpdateProvider>
      <CreateUpdateContext.Consumer>
        {() => (
          <S.BaseReportWrapper>
            <HeaderPage
              titleI18nKey={R.strings.treatment_management}
              isExport
              onPressExport={exportExcel}
              rightComponent={
                <ButtonCreate
                  title={t(R.strings.item_management_content_add_treatment)}
                  path={
                    ACCOUNTING_TREATMENT_MANAGEMENT_ADD_PRODUCT_SERVICE_PATH
                  }
                />
              }
            />
            <S.RootWrapper>
              <S.HeadWrapper>
                <FilterItemManagement handleChangeFilter={handleChangeFilter} />
              </S.HeadWrapper>
              <SLocal.BaseTableWrapper
                columns={columns}
                dataSource={tableData?.data}
                loading={tableData?.loading}
                onChange={handleTableChange}
                expandable={{
                  expandedRowKeys,
                  onExpand: handleExpand,
                  expandIcon: () => null,
                  showExpandColumn: false,
                  expandedRowRender: record => (
                    <ExpandedRowRender
                      fetchNewData={fetchNewData}
                      listSkill={listSkill}
                      record={record}
                    />
                  ),
                }}
                rowClassName="row-overwrite-style"
                pagination={tableData?.pagination}
                scroll={{
                  x: true,
                  y: tableData?.data?.length ? 480 : undefined,
                }}
              />
            </S.RootWrapper>
          </S.BaseReportWrapper>
        )}
      </CreateUpdateContext.Consumer>
    </CreateUpdateProvider>
  )
}

export default ItemManagementContainer
