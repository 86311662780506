import { SvgInformationCircleOrangeIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'

interface IProps {
  tag?: string
  showInfo?: boolean
  typeLayout: 'full' | 'half'
  borderColor?: string
  backgroundColor?: string
  value: string
  colorValue?: string
}

export const DayOffItem = ({
  tag,
  showInfo,
  typeLayout,
  backgroundColor,
  borderColor,
  value,
  colorValue,
}: IProps) => {
  return (
    <DayItemWrapper
      $width={isEqual(typeLayout, 'full') ? '100%' : '50%'}
      $borderColor={borderColor}
      $backgroundColor={backgroundColor}
    >
      <BaseRow align={'middle'} justify={'center'} gutter={4}>
        <BaseCol>
          <BaseText
            children={value}
            fontWeight="semibold"
            style={{
              color: colorValue,
            }}
          />
        </BaseCol>

        {showInfo ? (
          <BaseCol>
            <BasePopover
              trigger={'hover'}
              placement="bottom"
              content={
                <BaseSpace size={8}>
                  <BaseText
                    children={'Thông tin thay đổi'}
                    fontWeight="semibold"
                    fontSize="xs"
                  />
                  <BaseText children={'Ngày nghỉ đã duyệt'} opacity="0.5" />
                  <BaseText children={'T4 03/11 (Cả ngày)'} />
                  <BaseText children={'Ngày nghỉ mới'} opacity="0.5" />
                  <BaseText
                    children={'T6 05/11 (Buổi chiều) & T7 06/11 (Buổi chiều)'}
                  />
                  <BaseText children={'Lý do'} opacity="0.5" />
                  <BaseText children={'Nội dung'} />
                </BaseSpace>
              }
            >
              <BaseButton
                icon={<SvgInformationCircleOrangeIcon />}
                type="text"
              />
            </BasePopover>
          </BaseCol>
        ) : null}
      </BaseRow>

      {tag ? (
        <div className="tag">
          <BaseText
            children={tag}
            fontWeight="semibold"
            colorText="backgroundColor"
            fontSize="xxxs"
          />
        </div>
      ) : null}
    </DayItemWrapper>
  )
}

const DayItemWrapper = styled.div<{
  $width?: string
  $borderColor?: string
  $backgroundColor?: string
}>`
  position: relative;
  width: ${({ $width }) => $width ?? '100%'};
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $borderColor }) =>
    $borderColor &&
    `
      border-left: 3px solid ${$borderColor};
    `};

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    `
      background-color: ${$backgroundColor};
    `};

  .tag {
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: ${convertedVariables.collapseBackgroundColor};
    padding: 0px 6px;
    border-radius: ${PADDING.xs};
  }
`
