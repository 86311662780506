import R from 'app/assets/R'
import { BasicRecord } from '../BasicRecord'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseButton } from 'app/components/common/BaseButton'
import { EditOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { useState } from 'react'
import { Input } from 'antd'
import styled from 'styled-components'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BaseCol } from 'app/components/common/BaseCol'

const { TextArea } = Input

export interface IPatientCounseledByACounselor {
  time?: string
  name?: string
  lastedUpdate?: string
  counselor?: {
    name?: string
    jobTitle?: string
  }
}

export const PatientCounseledByACounselor = ({
  item,
}: {
  item?: IPatientCounseledByACounselor
}) => {
  const { t } = useTranslation()
  const [note, setNote] = useState('')
  const [isEdit, setIsEdit] = useState(false)

  const onOpenEdit = () => {
    setIsEdit(!isEdit)
  }

  const onCancelEdit = () => {
    setIsEdit(!isEdit)
  }
  
  return (
    <BasicRecord
      labelKeyI18n={R.strings.the_patient_is_counseled_by_a_counselor}
      content={
        <BaseSpace size={10}>
          <BaseAvatarProfile
            name={item?.counselor?.name}
            jobTitle={item?.counselor?.jobTitle}
          />
          <TextAreaWrapper
            value={note}
            rows={4}
            onChange={e => setNote(e.target?.value)}
            disabled={!isEdit}
          />
          <BaseRow justify={'end'}>
            {isEdit ? (
              <BaseRow gutter={[10, 10]}>
                <BaseCol>
                  <BaseButton onClick={onCancelEdit}>
                    {t(R.strings.cancel)}
                  </BaseButton>
                </BaseCol>
                <BaseCol>
                  <BaseButton onClick={onCancelEdit} type="default">
                    {t(R.strings.confirm)}
                  </BaseButton>
                </BaseCol>
              </BaseRow>
            ) : (
              <BaseText
                children={`Cập nhật lần cuối ${item?.lastedUpdate}`}
                opacity="0.7"
              />
            )}
          </BaseRow>
        </BaseSpace>
      }
      actionInHead={
        isEdit ? null : (
          <BaseButton
            icon={<EditOutlined rev={undefined} />}
            onClick={onOpenEdit}
          >
            {t(R.strings.update)}
          </BaseButton>
        )
      }
    />
  )
}

const TextAreaWrapper = styled(TextArea)``
