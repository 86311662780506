import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const SpaceWrapper = styled.div`
  width: 100%;
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${convertedVariables.neutralBlack9Color};
`

export const ContentWrapper = styled(BaseSpace)`
  background-color: #fcfcfc; //need change
  padding: ${PADDING.md};
  border-top: 1px solid ${convertedVariables.neutralBlack9Color};
  border-bottom: 1px solid ${convertedVariables.neutralBlack9Color};
`
export const BottomWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
`
