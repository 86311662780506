/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetProfileManagement } from 'app/api/profile'
import { ProfileMessageCode } from 'app/api/profile/constant'
import {
  IParamsGetProfileManagement,
  IProfileManagement,
  IResponseGetProfileManagement,
} from 'app/api/profile/model/management'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { useInfiniteQuery } from 'react-query'
import { IResponseQueryListNoTotal } from '../model/common'
import { ProfileKeys } from '../query-key/profile-profile'

export function useGetProfileList(
  params?: IParamsGetProfileManagement,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<
    IResponseQueryListNoTotal<IProfileManagement[]>,
    undefined
  >(
    ProfileKeys.listProfile(params),
    async () => {
      try {
        const res: IResponseGetProfileManagement =
          await requestGetProfileManagement({
            ...params,
          })

        if (
          isEqual(
            res?.msgcode,
            ProfileMessageCode.ProfileManagement.successGetData,
          )
        ) {
          const data: IProfileManagement[] = res?.data ?? []

          return {
            data,
            total: res.metadata.totalItem || 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
