import {
  DiscountTypeEnum,
  IDataUpdateCampaign,
} from 'app/api/marketing/discount-campaign/model'
import { isEqual } from 'lodash'
import { useMemo, useState } from 'react'
import { IBillDiscountInTable } from '../type'

export const useTotalBillTypeHook = () => {
  const [initTotalBill, setInitTotalBill] = useState<IBillDiscountInTable[]>([])
  const [listTotalBill, setListTotalBill] = useState<IBillDiscountInTable[]>([])

  const [updateListTotalBill, setUpdateList] = useState<IBillDiscountInTable[]>(
    [],
  )
  const [deleteListTotalBill, setDeleteList] = useState<IBillDiscountInTable[]>(
    [],
  )
  const [addListTotalBill, setAddList] = useState<IBillDiscountInTable[]>([])

  const onChangeUpdateList = (data: IBillDiscountInTable) => {
    if (initTotalBill.find(item => isEqual(item?._id, data?._id))) {
      if (updateListTotalBill.find(item => isEqual(item?._id, data?._id))) {
        const newUpdateList = updateListTotalBill.map(item => {
          if (isEqual(item?._id, data?._id)) {
            return data
          }
          return item
        })
        setUpdateList(newUpdateList)
      } else {
        setUpdateList([...updateListTotalBill, data])
      }
    }
  }

  const onChangeAddList = (data: IBillDiscountInTable) => {
    if (initTotalBill.find(item => isEqual(item?._id, data?._id))) {
      return
    }

    if (addListTotalBill.find(item => isEqual(item?._id, data?._id))) {
      const newAddList = addListTotalBill.map(item => {
        if (isEqual(item?._id, data?._id)) {
          return data
        }
        return item
      })
      setAddList(newAddList)
    } else {
      setAddList([...addListTotalBill, data])
    }
  }

  const onChangeDiscountValueTotalBill = (value: number, id?: string) => {
    const itemUpdate = listTotalBill.find(item => isEqual(item?._id, id))

    if (!itemUpdate) return

    const newListTotalBill = listTotalBill.map(item => {
      if (isEqual(item?._id, id)) {
        return {
          ...item,
          discount: value,
        }
      }
      return item
    })

    onChangeUpdateList({
      ...itemUpdate,
      discount: value,
    })
    onChangeAddList({
      ...itemUpdate,
      discount: value,
    })
    setListTotalBill(newListTotalBill)
  }

  const onInitListTotalBill = (data: IBillDiscountInTable[]) => {
    setListTotalBill(data)
    setInitTotalBill(data)
    setAddList([])
    setDeleteList([])
    setUpdateList([])
  }

  const onChangeDiscountTypeTotalBill = (
    value: DiscountTypeEnum,
    id?: string,
  ) => {
    const itemUpdate = listTotalBill.find(item => isEqual(item?._id, id))

    if (!itemUpdate) return
    const newListTotalBill = listTotalBill.map(item => {
      if (isEqual(item?._id, id)) {
        return {
          ...item,
          dType: value,
        }
      }
      return item
    })
    onChangeUpdateList({
      ...itemUpdate,
      dType: value,
    })
    onChangeAddList({
      ...itemUpdate,
      dType: value,
    })
    setListTotalBill(newListTotalBill)
  }

  const onChangeTotalBill = (services: IBillDiscountInTable[]) => {
    // lấy item đã bị xoá nhưng được thêm mới vào
    const newUpdateList = deleteListTotalBill.filter(item =>
      services.some(initItem => isEqual(initItem?._id, item?._id)),
    )

    // lấy danh sách được thêm mới vào
    const newAddList = services.filter(
      item =>
        !initTotalBill.some(initItem => isEqual(initItem?._id, item?._id)),
    )

    // lấy danh sách bị xóa đi khỏi danh sách ban đầu
    const newDeleteList = initTotalBill.filter(
      item => !services.some(newItem => isEqual(newItem?._id, item?._id)),
    )

    setListTotalBill(
      services?.map(item => {
        const itemUpdate = newUpdateList.find(updateItem =>
          isEqual(updateItem?._id, item?._id),
        )

        return itemUpdate ?? item
      }),
    )

    setAddList(newAddList)
    setDeleteList(newDeleteList)
  }

  const onDeleteTotalBill = (id?: string) => {
    // check xem item có trong danh sách ban đầu không, nếu có thì thêm nó vào danh sách xóa
    const itemInit = initTotalBill.find(item => isEqual(item?._id, id))

    if (itemInit) {
      setDeleteList([...deleteListTotalBill, itemInit])
    }

    // check xem item có trong danh sách thêm mới không, nếu có thì xóa nó đi
    const newAddList = addListTotalBill.filter(item => !isEqual(item?._id, id))
    setAddList(newAddList)

    // check xem item có trong danh sách cập nhật không, nếu có thì xóa nó đi
    const newUpdateList = updateListTotalBill.filter(
      item => !isEqual(item?._id, id),
    )
    setUpdateList(newUpdateList)

    const newListTotalBill = listTotalBill.filter(
      item => !isEqual(item?._id, id),
    )
    setListTotalBill(newListTotalBill)
  }

  const dataUpdateTotalBill: IDataUpdateCampaign = useMemo(() => {
    const payload: IDataUpdateCampaign = {
      push: addListTotalBill.map(item => {
        return {
          totalBill: item.totalBill,
          discount: item.discount,
          dType: item.dType,
        }
      }),
      removes: deleteListTotalBill.map(item => item?._id ?? '') ?? [],
      updates: updateListTotalBill.map(item => {
        return {
          totalBill: item.totalBill,
          discount: item.discount,
          dType: item.dType,
          _id: item._id,
        }
      }),
    }
    return payload
  }, [updateListTotalBill, deleteListTotalBill, addListTotalBill])

  const onAddNewBillTotal = (bill: IBillDiscountInTable) => {
    setListTotalBill([...listTotalBill, bill])
    setAddList([...addListTotalBill, bill])
  }

  return {
    onInitListTotalBill,
    dataUpdateTotalBill,
    initTotalBill,
    deleteListTotalBill,
    updateListTotalBill,
    addListTotalBill,
    onChangeTotalBill,
    listTotalBill,
    onChangeDiscountTypeTotalBill,
    onChangeDiscountValueTotalBill,
    onDeleteTotalBill,
    onAddNewBillTotal,
  }
}
