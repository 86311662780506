// Message code define
export const ImportMessageCode = {
  Budget: {
    successImport: '20003',
    successGetData: '20001',
  },
  CPQL: {
    successImport: '20003',
    successGetData: '20001',
  },
  QLead: {
    successImport: '20003',
    successGetData: '20001',
  },
  Appointment: {
    successImport: '20003',
    successGetData: '20001',
  },
  RevenuePlan: {
    successImport: '20003',
    successGetData: '20001',
  },
}

// Endpoint
export const ImportEndPoint = {
  Budget: {
    requestImportFile: 'report/v1/budgetplan/import',
    requestGetData: 'report/v1/budgetplan',
  },
  CPQL: {
    requestImportFile: 'report/v1/cpqlplan/import',
    requestGetData: 'report/v1/cpqlplan',
  },
  QLead: {
    requestImportFile: 'report/v1/leadplan/import',
    requestGetData: 'report/v1/leadplan',
  },
  Appointment: {
    requestImportFile: 'report/v1/appointmentplan/import',
    requestGetData: 'report/v1/appointmentplan',
  },
  RevenuePlan: {
    requestImportFile: 'report/v1/revenueplan/import',
    requestGetDataWeekOfMonth: 'report/v1/revenue/getRevenueWeekOfMonth',
    requestGetDataMonthOfYear: 'report/v1/revenue/getRevenueMonthOfYear',
  },
}

// const Key import
export const KeyImport = {
  All: {
    periodType: 'periodtype',
    importType: 'importtype',
    file: 'file',
  },
}
