import { DetailPaymentComponent } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/DetailPaymentComponent'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'

export const DetailPaymentLayout = () => {
  const {
    onChangeCustomerOrderTotalInvoice,
    totalPayment,
    excessMoney,
    totalPaymentMade,
    totalInvoice
  } = useProfilePatientContext()

  const onChangeCustomerPayment = (value: number) => {
    onChangeCustomerOrderTotalInvoice?.(value)
  }

  return (
    <DetailPaymentComponent
      excessMoney={excessMoney}
      totalPayment={totalPayment}
      totalPaymentMade={totalPaymentMade}
      onChangeCustomerPayment={onChangeCustomerPayment}
      customerPayment={totalInvoice?.customerPayment}
    />
  )
}
