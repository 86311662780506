/* eslint-disable @typescript-eslint/no-explicit-any */
import { IRoleManagement } from 'app/api/roles/models/role'
import { compareArrays, usePagination } from 'app/hook'
import { useGetRoleManagement } from 'app/react-query/hook/role'
import { IResponseQueryList } from 'app/react-query/model/common'
import { isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPropsUpdateRole } from './type'

export const useModalHook = ({
  roles,
  onSubmit,
}: IPropsUpdateRole) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [module, setModule] = useState<string | undefined>()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [rolesSelected, setRoleSelected] = useState<IRoleManagement[]>(
    [],
  )

  const [keyword, setKeyword] = useState('')

  const { flattenDataList } = usePagination()

  const { data: dataRolesBefore, isLoading: isLoadingRights } =
    useGetRoleManagement({ keyword, pagesize: 1000})

  const dataRoles = useMemo(() => {
    const flattenDta: IResponseQueryList<IRoleManagement[]> =
      flattenDataList(dataRolesBefore)
    return flattenDta?.data ?? []
  }, [dataRolesBefore])

  useEffect(() => {
    const timer = setTimeout(() => {
      setKeyword(search)
      setCurrentPage(1)
    }, 500)
    return () => clearTimeout(timer)
  }, [search])

  const onChangeRole = (newRole: IRoleManagement[]) => {
    setRoleSelected(newRole)
  }

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const rolesAfterSelected = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize
    const endIndex = startIndex + pageSize
    const listRoleAfterPagination = dataRoles?.slice(
      startIndex,
      endIndex,
    )
    return listRoleAfterPagination?.map(item => {
      const isSelected = !!rolesSelected?.find(itm =>
        isEqual(itm?._id, item?._id),
      )
      return {
        ...item,
        isSelected,
      }
    })
  }, [rolesSelected, currentPage, pageSize, dataRoles])

  useEffect(() => {
    setRoleSelected(roles ?? [])
  }, [roles])

 // console.log({ rolesSelected })

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      onSubmit?.(rolesSelected)
      setRoleSelected([])
      setModule(undefined)
    } catch (error) {
     // console.log({ error })
    } finally {
      setIsLoading(false)
      handleCancel()
    }
  }

  const onChangeModules = (value: string) => {
    setModule(value)
  }

  const onChangeSearch = (value: string) => {
    setSearch(value)
  }

  const isSelectAll = useMemo(() => {
    const listSelectedKey =
      rolesSelected?.map(item => item?._id ?? '') ?? []
    const listRoleKey = dataRoles?.map(item => item?._id ?? '') ?? []

    return compareArrays(listSelectedKey, listRoleKey)
  }, [rolesSelected, dataRoles])

  const onClickSelectAll = () => {
    if (isSelectAll) {
      setRoleSelected([])
    } else {
      setRoleSelected(dataRoles)
    }
  }

  const onChangeRoles = (per: IRoleManagement) => {
    const itemExist = rolesSelected?.find(itm => itm?._id === per?._id)

    if (itemExist) {
      setRoleSelected(prev => prev?.filter(itm => itm?._id !== per?._id))
      return
    }

    setRoleSelected(prev => prev?.concat([per]))
  }

  const onChangePageSize = (newPageSize: number) => {
    setPageSize(newPageSize)
  }

  const onChangePage = (page: number) => {
    setCurrentPage(page)
  }

  return {
    visible,
    t,
    isLoading: isLoading || isLoadingRights,
    showModal,
    handleSubmit,
    handleCancel,
    onChangeRole,
    rolesSelected,
    onChangeSearch,
    search,
    modules: dataRoles,
    module,
    onChangeModules,
    rolesAfterSelected,
    isSelectAll,
    onClickSelectAll,
    onChangeRoles,
    pageSize,
    currentPage,
    onChangePageSize,
    onChangePage,
    dataRoles,
  }
}
