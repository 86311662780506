import {
  IParamsGetPatientSearch,
  IPatientSearch,
  ResponsePatientSearchType,
} from 'app/api/treatment/model'
import { getToken } from 'app/service/storage/storage-service'
import { useInfiniteQuery } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { IPatient } from 'app/api/patient/model/patient'
import { TreatmentKeys } from '../query-key/treatment'
import { requestGetPatientSearch } from 'app/api/treatment'
import { TreatmentMessageCode } from 'app/api/treatment/constant'
import { isEqual } from 'lodash'

export function useGetPatientSearch(
  params?: IParamsGetPatientSearch,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IPatientSearch[]>, undefined>(
    TreatmentKeys.listPatientSearch(params),
    async () => {
      try {
        const res: ResponsePatientSearchType = await requestGetPatientSearch(
          params,
        )
        if (
          isEqual(
            res?.msgcode,
            TreatmentMessageCode.PatientSearch.successGetData,
          )
        ) {
          return {
            data: res?.data || [],
            total: res?.metadata?.totalItem,
          }
        }

        return {
          data: [],
          total: 0,
        }
      } catch (error) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
