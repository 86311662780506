import { IRegionalPrice } from 'app/api/marketing/discount-campaign/model'

export enum StatusEnum {
  DUPLICATED = 'duplicated',
  NOT_FOUND_TREATMENT = 'not_found_treatment',
  SUCCESS = 'success',
}

export interface IResponseImportFile {
  vat?: string
  time?: number
  deactivated?: boolean
  orthodontic?: boolean
  defaultTags?: string[]
  prognosisTemplate?: string
  treatmentTemplate?: string
  preparationTemplate?: string
  ttype?: string
  maxNurse?: number
  regionalPrice?: IRegionalPrice[]
  _id?: string
  name?: string
  price?: number
  unit?: string
  trimmedName?: string
  status?: StatusEnum

  rowNumber?: number
}
