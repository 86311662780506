import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseTag } from 'app/components/common/BaseTag'
import { SectionBase } from 'app/components/common/SectionBase'
import { BORDER_RADIUS } from 'parkway-web-common'
import styled from 'styled-components'
import CollapseComponent from '../../components/CollapseComponent'

export const RootWrapper = styled(CollapseComponent)`
`
export const SectionWrapper = styled(SectionBase)``

export const BaseSpaceWrapper = styled(BaseSpace)`
  width: 100%;
`
export const TagWrapper = styled(BaseTag)`
  border-radius: ${BORDER_RADIUS}
`