import { SearchOutlined } from '@ant-design/icons'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { BaseTreeSelect } from 'app/components/common/selects/BaseTreeSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const HeadWrapper = styled(BaseRow)`
  padding-bottom: ${PADDING.xs};
  .ant-form-item {
    margin-bottom: 0px;
  }
`
export const TitleTable = styled.span`
  font-size: ${FONT_SIZE.xs};
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.bold};
`
export const Picker = styled(BaseDatePicker)`
  min-width: 160px;
`

export const TextFilter = styled.span`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  color: var(--primary-color);
`

export const SelectBaseStyled = styled(BaseSelect)``

export const TreeSelectStyled = styled(BaseTreeSelect)`
  .ant-select-selector {
    border: 1px solid ${convertedVariables.neutralBlack2Color} !important;
  }
`

export const BaseColWrapper = styled(BaseCol)<{ $isDisable?: boolean }>`
  opacity: ${({ $isDisable }) => ($isDisable ? 0 : 1)};
`

export const SearchOutlinedIcon = styled(SearchOutlined)`
  svg {
    fill: #b0bac5;
  }
`
