import R from 'app/assets/R'
import { YesNoEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

export const IssueInvoiceLayout = () => {
  const { t } = useTranslation()

  const { purchaseProductService, onChangeIssueInvoiceTotalInvoice } =
    useProfilePatientContext()

  const onChange = (value: YesNoEnum) => {
    onChangeIssueInvoiceTotalInvoice?.(value)
  }

  return (
    <BaseSpace>
      <BaseText fontSize='xs' children={t(R.strings.issue_invoice)} fontWeight="semibold" />
      <S.BaseRadioGroup
        value={purchaseProductService?.totalInvoice?.issuesInvoice}
        defaultValue={YesNoEnum.Yes}
        onChange={e => onChange(e?.target?.value as YesNoEnum)}
      >
        <BaseRow gutter={[15, 10]} align={'middle'}>
          <BaseCol xl={6}>
            <S.RadioBtn value={YesNoEnum.Yes}>
              <BaseText fontSize='xs'>{t(R.strings.yes)}</BaseText>
            </S.RadioBtn>
          </BaseCol>
          <BaseCol xl={6}>
            <S.RadioBtn value={YesNoEnum.No}>
              <BaseText fontSize='xs'>{t(R.strings.no)}</BaseText>
            </S.RadioBtn>
          </BaseCol>
        </BaseRow>
      </S.BaseRadioGroup>
    </BaseSpace>
  )
}
