import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import DentalTreatmentManagementContainer from 'app/containers/DentalTreatmentManagement'

const DentalTreatmentManagementPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.dental_treatment_management)}</PageTitle>
      <DentalTreatmentManagementContainer />
    </>
  )
}
const DentalTreatmentManagementPage = memo(DentalTreatmentManagementPageCpn, isEqual)

export default DentalTreatmentManagementPage
