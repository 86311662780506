/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchOutlined } from '@ant-design/icons'
import { IParamsGetReport18 } from 'app/api/report/model/report-18'
import R from 'app/assets/R'
import { StatusEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { BaseTreeSelect } from 'app/components/common/selects/BaseTreeSelect'
import { transformDataSelectTree } from 'app/components/common/selects/BaseTreeSelect/helper'
import { removeVietnameseTones } from 'app/helpers/string.helper'
import { useDebounce, usePagination } from 'app/hook'
import { IUnit } from 'app/model/unit.model'
import { useGetAreaList } from 'app/react-query/hook/location'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import {
  Dates,
  FONT_SIZE,
  FONT_WEIGHT,
  initialPagination,
} from 'parkway-web-common'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { getTotalWeekNumbersOfMonth } from 'app/common/helpers'
import { randomMoney } from 'app/components/tables/common-table/constant'
import { useGetCampaignList } from 'app/react-query/hook/campaign'

interface IProps {
  onChange?: (value: IParamsGetReport18) => void
  formData?: IParamsGetReport18
}

export const FilterLayout: React.FC<IProps> = ({ formData, onChange }) => {
  const [keywordSearchArea, setKeywordSearchArea] = useState('')
  const { flattenData, flattenDataList } = usePagination()

  const { data: areaApi, isLoading: isLoadingArea } = useGetAreaList({
    typeid: 'area',
    status: StatusEnum.ACTIVE,
  })

  const [loadMoreValue, setLoadMoreValue] = useState(0)

  const loadMoreValueDebounce = useDebounce(loadMoreValue, 200)

  const {
    data: dataApi,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetCampaignList({
    page: initialPagination?.current,
    pageSize: 50,
    pagesize: 50,
  })

  const dataFlatten = useMemo(() => {
    return flattenData(dataApi)
  }, [dataApi])

  const dataFilter = useMemo(() => {
    return dataFlatten?.map(i => ({
      label: i?.name,
      value: i?._id,
    }))
  }, [dataApi])

  const dataMonth = useMemo(() => {
    if (!formData?.quarter) {
      return [
        {
          label: `${t(R.strings.all)} `,
          value: '',
        },
      ]
    }
    const startedMonth = (Number(formData?.quarter) - 1) * 3
    return [
      {
        label: `${t(R.strings.all)} `,
        value: '',
      },
      {
        label: `${t(R.strings.month)} ${startedMonth + 1}`,
        value: `${startedMonth + 1}`,
      },
      {
        label: `${t(R.strings.month)} ${startedMonth + 2}`,
        value: `${startedMonth + 2}`,
      },
      {
        label: `${t(R.strings.month)} ${startedMonth + 3}`,
        value: `${startedMonth + 3}`,
      },
    ]
  }, [formData?.quarter])

  const dataWeek = useMemo(() => {
    if (!formData?.month) {
      return [
        {
          label: `${t(R.strings.all)} `,
          value: '',
        },
      ]
    }
    const weekNumbers: number =
      getTotalWeekNumbersOfMonth(
        Number(formData?.year),
        Number(formData?.month),
      ) ?? 0

    return [
      {
        label: `${t(R.strings.all)} `,
        value: '',
      },
      ...(Array(weekNumbers)
        ?.fill('')
        ?.map((_, index) => ({
          label: `${t(R.strings.week)} ${index + 1}`,
          value: `${index + 1}`,
        })) ?? []),
    ]
  }, [formData?.month])

  const areaList = useMemo(() => {
    const res: IUnit[] = flattenDataList(areaApi)
    return res?.map(item => ({
      ...item,
      childs: item?.childs
        ?.filter(child => !!child?._oldId)
        ?.map(item => ({
          ...item,
          _id: item?._oldId ?? item?._id,
        })),
    }))
  }, [areaApi])

  const filterTreeNode = (value?: string, treeNode?: any) => {
    return removeVietnameseTones(treeNode?.title)
      .toLowerCase()
      .includes(removeVietnameseTones(value).toLowerCase())
  }

  const onChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onChange && onChange({ ...formData, keyword: value })
  }

  const onChangeClinic = (ids: string[]) => {
    onChange && onChange({ ...formData, clinicIds: ids as any })
  }

  const onChangeCampaign = (ids: string[]) => {
    onChange && onChange({ ...formData, campaignIds: ids as any })
  }
  const onChangeYear = (value: string) => {
    onChange && onChange({ ...formData, year: value })
  }
  const onChangeMonth = (value: string) => {
    onChange && onChange({ ...formData, month: value, week: '' })
  }
  const onChangeQuarter = (value: string) => {
    onChange &&
      onChange({ ...formData, quarter: value ?? '', month: '', week: '' })
  }
  const onChangeWeek = (value: string) => {
    onChange && onChange({ ...formData, week: value ?? '' })
  }

  const onPopupCampaignScroll = e => {
    const heightChange = e?.target?.scrollTop + e?.target?.clientHeight ?? 0
    const scrollHeight = e?.target?.scrollHeight ?? 0
    const isEndOfList = heightChange >= 0.7 * scrollHeight

    if (isEndOfList && hasNextPage) {
      setLoadMoreValue(randomMoney())
    }
  }

  useEffect(() => {
    if (loadMoreValueDebounce) {
      fetchNextPage()
    }
  }, [loadMoreValueDebounce])

  return (
    <BaseForm>
      <RootWrapper gutter={[16, 8]} align={'middle'}>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BasePicker
              $prefix={t(R.strings.year)}
              $prefixWidth={40}
              picker="year"
              value={
                formData?.year ? Dates.getDate(`${formData?.year}-01-01`) : null
              }
              onChange={(_, value) => onChangeYear(value)}
              allowClear={false}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseSelectWrapper
              disabled={!formData?.year}
              $prefix={t(R.strings.quarter)}
              $prefixWidth={40}
              placeholder={t(R.strings.all)}
              onChange={value => onChangeQuarter(value as string)}
              value={formData?.quarter}
            >
              <Option value={''} key={`${0}`}>
                {t(R.strings.all)}
              </Option>
              {['1', '2', '3', '4']?.map((item, index) => {
                return (
                  <Option value={item} key={`${index + 1}`}>
                    {item}
                  </Option>
                )
              })}
            </BaseSelectWrapper>
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseSelectWrapper
              placeholder={t(R.strings.all)}
              $prefix={t(R.strings.month)}
              $prefixWidth={60}
              disabled={!formData?.quarter}
              value={formData?.month ?? null}
              onChange={value => onChangeMonth(value as string)}
              options={dataMonth}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseSelectWrapper
              placeholder={t(R.strings.all)}
              $prefix={t(R.strings.week)}
              $prefixWidth={60}
              disabled={!formData?.month}
              value={formData?.week ?? null}
              onChange={value => onChangeWeek(value as string)}
              options={dataWeek}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseTreeSelectWrapper
              placeholder={t(R.strings.all)}
              $prefix={t(R.strings.clinic)}
              $prefixWidth={100}
              loading={isLoadingArea}
              width={'100%'}
              treeData={transformDataSelectTree(areaList ?? [], {
                title: 'name',
                value: '_id',
                children: 'childs',
              })}
              value={formData?.clinicIds}
              multiple
              treeCheckable={true}
              onSearch={keyword => setKeywordSearchArea?.(keyword)}
              filterTreeNode={filterTreeNode}
              searchValue={keywordSearchArea}
              maxTagCount={0}
              onChange={ids => onChangeClinic?.(ids as string[])}
              allowClear
              style={{
                minWidth: 220,
              }}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseFormItem hiddenLabel>
            <BaseSelectWrapper
              allowClear
              $prefix={t(R.strings.campaign)}
              value={formData?.campaignIds}
              $prefixWidth={100}
              placeholder={t(R.strings.all)}
              onChange={(ids: any) => onChangeCampaign(ids as string[])}
              mode="multiple"
              maxTagCount={0}
              options={dataFilter}
              loading={isLoading || isFetchingNextPage}
              onPopupScroll={onPopupCampaignScroll}
              filterSort={() => 0}
              listHeight={450}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseInput
              prefix={<SearchOutlined rev={undefined} />}
              value={formData?.keyword}
              placeholder={t(R.strings.enter_keyword_search)}
              onChange={onChangeKeyword}
              allowClear
            />
          </BaseFormItem>
        </BaseCol>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseRow)``
const Picker = styled(BaseDatePicker)<{
  $prefix: string
  $prefixWidth?: number
}>`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  &:hover {
    border: 1px solid ${convertedVariables.neutralBlack9Color};
  }
  .ant-picker-input::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    min-width: ${props => props?.$prefixWidth ?? 105}px;
  }
`

const BaseSelectWrapper = styled(BaseSelect)<{
  $prefix: string
  $prefixWidth?: number
}>`
  width: 100%;
  .ant-select-selector::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$prefixWidth ?? 40}px;
    align-items: center;
    display: flex;
    padding-left: 4px;
  }

  .ant-select-selection-placeholder {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }
`
const BaseTreeSelectWrapper = styled(BaseTreeSelect)<{
  $prefix: string
  $prefixWidth?: number
}>`
  width: 100%;
  .ant-select-selector::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$prefixWidth ?? 40}px;
    align-items: center;
    display: flex;
    padding-left: 4px;
  }

  .ant-select-selection-placeholder {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
const BasePicker = styled(Picker)`
  font-weight: ${FONT_WEIGHT.medium} !important;
`
