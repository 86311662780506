// Message code define
export const JobTitleMessageCode = {
  JobTitleManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20001',
  },
}

// Endpoint
export const JobTitleEndPoint = {
  JobTitleManagement: {
    request: 'hr/v1/jobtitle',
  },
}
