import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import styled from 'styled-components'
import { useHookTable } from './hook'
import { IProps } from './type'

export const ChildTableLayout = (props: IProps) => {
  const {
    columns,
    expandedRowKeys,
    handleExpand,
    handleTableChange,
    loading,
    tableData,
    columnProfile,
    profiles,
  } = useHookTable(props)

  return (
    <>
      {profiles?.length ? (
        <BaseTableWrapper
          columns={columnProfile}
          dataSource={profiles}
          pagination={{
            total: profiles.length,
          }}
          loading={loading}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
          scroll={{ x: true }}
          expandable={{
            expandIcon: () => null,
            showExpandColumn: false,
          }}
        />
      ) : null}

      {tableData.data?.length ? (
        <BaseTableWrapper
          columns={columns}
          dataSource={tableData.data}
          pagination={false}
          loading={loading}
          onChange={handleTableChange}
          showHeader={false}
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            expandIcon: () => null,
            showExpandColumn: false,
            expandedRowRender: record => (
              <ChildTableLayout filter={props?.filter} record={record} />
            ),
          }}
          rowClassName="row-overwrite-style"
          scroll={{ x: true }}
        />
      ) : null}
    </>
  )
}

const BaseTableWrapper = styled(BaseTableManagement)`
  tr.ant-table-expanded-row > td {
    background: ${convertedVariables.backgroundColor};
  }

  tr.ant-table-expanded-row:hover > td {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell {
    border: 0px !important;
    border-bottom: 0.5px solid ${convertedVariables.neutralBlack2Color} !important;
    border-top: 0.5px solid ${convertedVariables.neutralBlack2Color} !important;
  }

  .all-column:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell-row-hover:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor} !important;
  }

  .ant-table-tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed
    > .ant-table-wrapper
    .ant-table {
    margin-block: -16px;
    margin-inline: 32px -16px;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-radius: 0px !important;
  }

  .ant-table-thead > tr > th {
    border: 0px !important;
  }

  .main-column {
    align-content: start;
  }
`
