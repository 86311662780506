import { requestGetRefererReport } from 'app/api/marketing/referer-report'
import { RefererReportMessageCode } from 'app/api/marketing/referer-report/constant'
import {
  IParamsGetRefererReport,
  IRefererReport,
} from 'app/api/marketing/referer-report/model'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useInfiniteQuery } from 'react-query'
import { RefererReportKeys } from '../query-key/referer-report'

export function useGetRefererReportList(
  params?: IParamsGetRefererReport,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<ResponseType<IRefererReport[]>, undefined>(
    RefererReportKeys.listRefererReports(params),
    async () => {
      const res: ResponseType<IRefererReport[]> = await requestGetRefererReport(
        {
          ...params,
        },
      )

      if (
        isEqual(
          res?.msgcode,
          RefererReportMessageCode.RefererReport.successGetData,
        )
      ) {
        return res
      }

      return Promise.reject(res)
    },
    {
      enabled: !!token && enabled,
      cacheTime: 1000,
    },
  )
}
