import R from 'app/assets/R'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { IResponseImportBundle, StatusEnum, StatusSetEnum } from './type'

export const DefineStatusEnum = {
  [StatusEnum.DUPLICATED]: {
    text: R.strings.duplicated_treatment,
    color: convertedVariables.statesRedColor,
  },
  [StatusEnum.WRONG_DISCOUNT_TYPE]: {
    text: R.strings.wrong_discount_type,
    color: convertedVariables.statesRedColor,
  },
  [StatusEnum.WRONG_DISCOUNT_INPUT]: {
    text: R.strings.wrong_discount_input,
    color: convertedVariables.statesRedColor,
  },
  [StatusEnum.NOT_FOUND_TREATMENT]: {
    text: R.strings.not_found_treatment,
    color: convertedVariables.statesRedColor,
  },
  [StatusEnum.SUCCESS]: {
    text: R.strings.success,
    color: convertedVariables.statesGreenColor,
  },
  [StatusEnum.WRONG_QUANTITY_INPUT]: {
    text: R.strings.wrong_quantity_input,
    color: convertedVariables.statesRedColor,
  },
}

export const DefineStatusSetEnum = {
  [StatusSetEnum.SUCCESS]: {
    text: R.strings.success,
    color: convertedVariables.statesGreenColor,
  },
  [StatusSetEnum.ERROR]: {
    text: R.strings.error_vn_text,
    color: convertedVariables.statesRedColor,
  },
}

export const InitResponseImport: IResponseImportBundle = {
  insert: [],
  modify: [],
  remove: [],
}
