import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import ReturnWarehouseGoodsIssueContainer from 'app/containers/InventoryManagement/WarehouseGoodsIssue/pages/return'

const ReturnWarehouseGoodsIssueContainerPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.inventory_management)}</PageTitle>
      <ReturnWarehouseGoodsIssueContainer />
    </>
  )
}
const ReturnWarehouseGoodsIssueContainerPage = memo(
  ReturnWarehouseGoodsIssueContainerPageCpn,
  isEqual,
)

export default ReturnWarehouseGoodsIssueContainerPage
