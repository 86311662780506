import R from 'app/assets/R'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormRule } from './type'
import { StatusWarehouseGoodsIssueType } from '.'
import { notificationController } from 'app/controllers/notification-controller'
import { WarehouseGoodsIssueContext } from 'app/containers/InventoryManagement/WarehouseGoodsIssue/context'
import moment from 'moment'
import { DATE } from 'app/common/constants'
import { useNavigate } from 'react-router'
import {
  INVENTORY_WAREHOUSE_GOODS_ISSUE_FOR_UNIT_PATH,
  INVENTORY_WAREHOUSE_GOODS_ISSUE_PATH,
} from 'app/components/router/route-path'
import { get } from 'lodash'
import {
  requestCreateWarehouseGoodsIssueManagement,
  requestUpdateWarehouseGoodsIssueManagement,
} from 'app/api/inventory-new/api/warehouse-goods-issue'
interface IProps {
  unitId?: string
}
export const useHook = (params: IProps) => {
  const { t } = useTranslation()
  const { form } = useContext(WarehouseGoodsIssueContext)
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState<
    StatusWarehouseGoodsIssueType | undefined
  >()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form?.resetFields()
    if (params?.unitId) {
      navigate(INVENTORY_WAREHOUSE_GOODS_ISSUE_FOR_UNIT_PATH)
    } else {
      navigate(INVENTORY_WAREHOUSE_GOODS_ISSUE_PATH)
    }
  }

  const handleCreate = async (values, orders, discount, status) => {
    setIsLoading(true)

    try {
      const orderModify = orders?.map(item => {
        return {
          productId: item?.productId,
          quantity: Number(item?.quantity || 0),
          price: Number(item?.price || 0),
          measurementUnitId: item?.measurementUnitId,
        }
      })

      const isError = await validatePayload(orderModify)
      if (isError) return

      const payload = {
        ...values,
        estimateDeliveryDate: moment(values?.estimateDeliveryDate).format(
          DATE.YYYY_MM_DD,
        ),
        paymentDueDate: moment(values?.paymentDueDate).format(DATE.YYYY_MM_DD),
        orders: orderModify,
        discount,
        status,
      }

      await requestCreateWarehouseGoodsIssueManagement(payload)

      setIsLoading(false)
      handleCancel()

      notificationController.success({
        message: t(R.strings.warehouse_purchase_add),
        description: t(R.strings.success),
      })

      if (params?.unitId) {
        navigate(INVENTORY_WAREHOUSE_GOODS_ISSUE_FOR_UNIT_PATH)
      } else {
        navigate(INVENTORY_WAREHOUSE_GOODS_ISSUE_PATH)
      }
    } catch (error) {
      if (get(error, 'errorFields', []).length) {
        notificationController.error({
          message: t(R.strings.warehouse_purchase_add),
          description: t(R.strings.please_input_all_field),
        })
      } else {
        notificationController.error({
          message: t(R.strings.warehouse_purchase_add),
          description: get(error, 'message'),
        })
      }
      setIsLoading(false)
    }
  }

  const handleUpdate = async (values, orders, discount, id) => {
    setIsLoading(true)

    try {
      const orderModify = orders?.map(item => {
        return {
          productId: item?.productId,
          quantity: Number(item?.quantity || 0),
          price: Number(item?.price || 0),
          measurementUnitId: item?.measurementUnitId,
        }
      })

      const isError = await validatePayload(orderModify)
      console.log('isError', isError)
      if (isError) return

      const payload = {
        ...values,
        estimateDeliveryDate: moment(values?.estimateDeliveryDate).format(
          DATE.YYYY_MM_DD,
        ),
        paymentDueDate: moment(values?.paymentDueDate).format(DATE.YYYY_MM_DD),
        orders: orderModify,
        discount,
      }

      await requestUpdateWarehouseGoodsIssueManagement({ id, body: payload })

      setIsLoading(false)
      handleCancel()

      notificationController.success({
        message: t(R.strings.warehouse_purchase_update),
        description: t(R.strings.success),
      })

      navigate(INVENTORY_WAREHOUSE_GOODS_ISSUE_PATH)
    } catch (error) {
      if (get(error, 'errorFields', []).length) {
        notificationController.error({
          message: t(R.strings.warehouse_purchase_update),
          description: t(R.strings.please_input_all_field),
        })
      } else {
        notificationController.error({
          message: t(R.strings.warehouse_purchase_update),
          description: get(error, 'message'),
        })
      }
      setIsLoading(false)
    }
  }

  const validatePayload = async orderModify => {
    await form?.validateFields()
    if (!orderModify?.length) {
      setIsLoading(false)
      notificationController.error({
        message: t(R.strings.warehouse_purchase_add),
        description: t(R.strings.goods_require),
      })

      return true
    }

    if (!orderModify.every(order => order?.quantity > 0)) {
      setIsLoading(false)
      notificationController.error({
        message: t(R.strings.warehouse_purchase_add),
        description: t(R.strings.goods_please_input_quantity),
      })
      return true
    }

    return false
  }
  const rules: IFormRule = useMemo(() => {
    return {
      warehouseIssueId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.warehouse_export),
          }),
        },
      ],

      warehouseReceiveId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.warehouse_import),
          }),
        },
      ],

      issuerId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.warehouse_goods_issue_issuer),
          }),
        },
      ],

      receiverId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.warehouse_goods_issue_receiver),
          }),
        },
      ],

      createAtDate: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.created_date_ticket),
          }),
        },
      ],
    }
  }, [t])

  return {
    visible,
    t,
    showModal,
    handleCreate,
    handleUpdate,
    handleCancel,
    rules,
    setStatus,
    status,
    isLoading,
  }
}
