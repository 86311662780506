import { convertedVariables } from "app/styles/themes/themeVariables";
import { StatusTreatmentInAppointmentSessionEnum, StatusServiceTreatmentScheduleEnum, StatusAppointmentSessionTreatmentPlanEnum } from "../enum";
import R from "app/assets/R";

export const StatusTreatmentInAppointmentSessionEnumDefine = {
    [StatusTreatmentInAppointmentSessionEnum.Completed]: {
        color: convertedVariables.otherBlueColor,
        backgroundColor: convertedVariables?.statesBlueLightColor,
        i18nKey: R.strings.completed, 
    },
    [StatusTreatmentInAppointmentSessionEnum.InTreatment]: {
        color: convertedVariables.otherBlueColor,
        backgroundColor: convertedVariables?.statesBlueLightColor,
        i18nKey: R.strings.in_treatment, 
    },
    [StatusTreatmentInAppointmentSessionEnum.PendingExecution]: {
        color: '#629DAA', // need change
        backgroundColor: '#E8F6F9', // need change
        i18nKey: R.strings.pending_execution, 
    },
    [StatusTreatmentInAppointmentSessionEnum.Suspended]: {
        color: convertedVariables.statesOrangeColor,
        backgroundColor: convertedVariables?.statesOrangeLightColor,
        i18nKey: R.strings.suspended, 
    },
    [StatusTreatmentInAppointmentSessionEnum.StopPerforming]: {
        color: convertedVariables.statesRedColor,
        backgroundColor: convertedVariables?.statesRedLightColor,
        i18nKey: R.strings.stop_performing, 
    },
    [StatusTreatmentInAppointmentSessionEnum.PendingPayment]: {
        color: convertedVariables.primaryColor,
        backgroundColor: convertedVariables?.backgroundColor,
        i18nKey: R.strings.pending_payment, 
    }
}


export const StatusServiceTreatmentScheduleEnumDefine = {
    [StatusServiceTreatmentScheduleEnum.Completed]: {
        color: convertedVariables.otherBlueColor,
        backgroundColor: convertedVariables?.statesBlueLightColor,
        i18nKey: R.strings.completed, 
    },
    [StatusServiceTreatmentScheduleEnum.InTreatment]: {
        color: convertedVariables.otherBlueColor,
        backgroundColor: convertedVariables?.statesBlueLightColor,
        i18nKey: R.strings.in_treatment, 
    },
    [StatusServiceTreatmentScheduleEnum.Suspended]: {
        color: convertedVariables.statesOrangeColor,
        backgroundColor: convertedVariables?.statesOrangeLightColor,
        i18nKey: R.strings.suspended, 
    },
    [StatusServiceTreatmentScheduleEnum.Cancelled]: {
        color: convertedVariables.statesRedColor,
        backgroundColor: convertedVariables?.statesRedLightColor,
        i18nKey: R.strings.cancelled, 
    },
}


export const StatusAppointmentSessionTreatmentPlanEnumDefine = {
    [StatusAppointmentSessionTreatmentPlanEnum.Occurred]: {
        color: convertedVariables.statesGreenColor,
        backgroundColor: convertedVariables?.statesGreenLightColor,
        i18nKey: R.strings.occurred, 
    },
    [StatusAppointmentSessionTreatmentPlanEnum.InTreatment]: {
        color: convertedVariables.otherBlueColor,
        backgroundColor: convertedVariables?.statesBlueLightColor,
        i18nKey: R.strings.in_treatment, 
    },
    [StatusAppointmentSessionTreatmentPlanEnum.NotOccurred]: {
        color: convertedVariables.statesOrangeColor,
        backgroundColor: '#F8F8F8', // need change
        i18nKey: R.strings.not_occurred, 
    },
    [StatusAppointmentSessionTreatmentPlanEnum.HaveProblem]: {
        color: convertedVariables.statesOrangeColor,
        backgroundColor: convertedVariables?.statesOrangeLightColor,
        i18nKey: R.strings.have_problem, 
    },
}
