import { ApiClientTreatment } from 'app/service/api-service'
import { EndPoint } from '../constant'
import {
  IParamsGetWarehouseInventoryManagement,
  IPayloadCreateWarehouseInventoryManagement,
  IPayloadUpdateWarehouseInventoryManagement,
} from '../model/warehouse-inventory'

export const requestGetWarehouseInventoryManagement = (
  params: IParamsGetWarehouseInventoryManagement,
) =>
  ApiClientTreatment.Get({
    url: EndPoint.WarehouseInventoryManagement.request,
    params: { params },
  })

export const requestCreateWarehouseInventoryManagement = (
  body: IPayloadCreateWarehouseInventoryManagement,
) =>
  ApiClientTreatment.Post({
    url: EndPoint.WarehouseInventoryManagement.request,
    body,
  })

export const requestUpdateWarehouseInventoryManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateWarehouseInventoryManagement
}) =>
  ApiClientTreatment.Patch({
    url: `${EndPoint.WarehouseInventoryManagement.request}/${id}`,
    body,
  })

export const requestDetailWarehouseInventoryManagement = (
  id?: string | number,
) =>
  ApiClientTreatment.Get({
    url: `${EndPoint.WarehouseInventoryManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteWarehouseInventoryManagement = (
  id?: string | number,
) =>
  ApiClientTreatment.Delete({
    url: `${EndPoint.WarehouseInventoryManagement.request}/${id}`,
    body: {},
  })

export const requestExportWarehouseInventoryManagement = (
  params: IParamsGetWarehouseInventoryManagement,
) =>
  ApiClientTreatment.Get({
    url: `${EndPoint.WarehouseInventoryManagement.request}/export`,
    params: { params },
  })

export const requestWarehouseInventoryAvailable = (
  params: IParamsGetWarehouseInventoryManagement,
) =>
  ApiClientTreatment.Get({
    url: `${EndPoint.WarehouseInventoryManagement.request}/available-product-info`,
    params: { params },
  })


export const requestWarehouseInventoryOutbound = (
    params: IParamsGetWarehouseInventoryManagement,
  ) =>
    ApiClientTreatment.Get({
      url: `${EndPoint.WarehouseInventoryManagement.request}/outbound-product-info`,
      params: { params },
    })

export const requestWarehouseInventoryProduct = (
  params: IParamsGetWarehouseInventoryManagement,
) =>
  ApiClientTreatment.Get({
    url: `${EndPoint.WarehouseInventoryManagement.request}/product`,
    params: { params },
  })

