import { BaseForm } from 'app/components/common/forms/BaseForm'
import { IFormAppointmentSession } from './type'
import { useMemo, useRef, useState } from 'react'
import { IRefModal } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/components'
import {
  IAppointmentSession,
  ITreatmentInAppointmentSession,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { isEqual } from 'lodash'
import { PaymentMethodEnum } from 'app/common/enum'
import { StatusAppointmentSessionTreatmentPlanEnum } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/enum'
import { getRandomUuid, moment } from 'parkway-web-common'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'

export const useHook = ({
  handleSubmit,
}: {
  handleSubmit?: (item: IAppointmentSession) => void
}) => {
  const [form] = BaseForm.useForm<IFormAppointmentSession>()
  const refModal = useRef<IRefModal>({})

  const [listTreatment, setListTreatment] = useState<
    ITreatmentInAppointmentSession[]
  >([])

  const onAddTreatment = (treatment?: ITreatmentInAppointmentSession) => {
    if (!treatment) return
    setListTreatment(prev => prev?.concat([treatment]))
  }

  const onChangeTreatment = (treatment?: ITreatmentInAppointmentSession) => {
    setListTreatment(prev =>
      prev?.map(item => {
        if (isEqual(item?.id, treatment?.id) && treatment) {
          return treatment
        }
        return item
      }),
    )
  }

  const onDeleteTreatment = (index?: number) => {
    setListTreatment(prev => prev?.filter((_, idx) => idx !== index))
  }

  const onChangeTreatmentItem = ({
    key,
    id,
    value,
  }: {
    id?: string
    key: keyof ITreatmentInAppointmentSession
    value?: unknown
  }) => {
    setListTreatment(prev => {
      return prev?.map(item => {
        if (isEqual(item?.id, id)) {
          return {
            ...item,
            [key]: value,
          }
        }
        return item
      })
    })
  }

  const onSubmit = (values?: unknown) => {
    const valuesData = values as IFormAppointmentSession

    const item: IAppointmentSession = {
      id: getRandomUuid(),
      name: valuesData?.appointmentSessionName ?? '',
      paymentInformation: {
        paidAmount: 0,
        paymentMethod: PaymentMethodEnum.Bank,
        totalAmount: 0,
      },
      status: StatusAppointmentSessionTreatmentPlanEnum.InTreatment,
      timeStartTreatment: moment().format(FORMAT_DD_MM_YYYY_HH_MM),
      treatmentInformation: {
        note: valuesData?.note,
        symptomsDiagnosis: valuesData?.symptomsDiagnosis ?? '',
        treatmentDirection: valuesData?.treatmentDirection ?? '',
      },
      treatmentInAppointmentSession: listTreatment,
    }
    handleSubmit?.(item)
    setListTreatment([])
    form?.resetFields()
    refModal?.current?.hide?.()
  }

  const isDisableSubmit = useMemo(() => {
    if (!listTreatment?.length) return true
    return false
  }, [listTreatment])

  return {
    form,
    refModal,
    listTreatment,
    onAddTreatment,
    onDeleteTreatment,
    onChangeTreatmentItem,
    onChangeTreatment,
    isDisableSubmit,
    onSubmit,
  }
}
