import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import * as S from 'app/components/tables/BaseTableReport/styles'
import 'dayjs/locale/vi'
import React, { useContext, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTableHook } from './hook'
import {
  FilterRow,
  FilterRowBottom,
  FilterSearchAppointmentBox,
  ResultDateRange,
  ResultDateRangeNumber,
  SendSMSBox,
} from './styles'
import ListTotalPage from './ListTotalPage'
import './styles.css'
import { FilterSearchAppointment } from 'app/components/tables/BaseTableManagement/FilterSearchAppointmentTable'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import AppointmentModal from './AppointmentModal'
import CreateAndUpdate from '../AppointmentPage/SideBarRight/CalendarRight/CreateAndUpdate'
import { PageSearchAppointmentContext } from 'app/context/PageSearchAppointmentContext'
import ConfirmModal from '../AppointmentPage/SideBarRight/CalendarRight/ConfirmModal'
import { AppointmentContext } from 'app/context/AppointmentContext'
import { EScheduleStatus } from 'app/api/appointment/model'
import { IListTotal } from '../AppointmentPage/type'
import moment from 'moment'
import { initialPagination } from './common-model'
import { scheduleStatusSearchAppointment } from 'app/api/appointment/constant'
import HeaderPageAction from './HeaderPageAction'
import { DATE } from 'app/common/constants'
import { APPOINTMENT_VIEW_TYPE } from 'app/common/enum'

const SearchAppointmentPage: React.FC = () => {
  const [filter, setFilter] = useState<{
    startDate
    endDate
  }>({
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day'),
  })

  const {
    openAppointment,
    setOpenAppointment,
    openCreateUpdate,
    setOpenCreateUpdate,
    appointment,
    setAppointment,
    openConfirmModal,
    setOpenConfirmModal,
    cancelAppointmentItem,
    setCancelAppointmentItem,
  } = useContext(PageSearchAppointmentContext)
  const { appointmentTotal, sendSmsAppointment } =
    useContext(AppointmentContext)
  const { t } = useTranslation()

  const { columns, tableData, handleTableChange, onChangeFilter } =
    useTableHook({ filter, setFilter })

  const scheduleTotal: IListTotal | undefined = appointmentTotal?.find(
    (total: IListTotal) => total?._id === EScheduleStatus.TOTAL,
  )

  const handleSendSms = () => {
    const pageSize =
      (tableData.pagination['pageSize'] || 0) > 10
        ? tableData.pagination?.pageSize
        : initialPagination.pageSize
    sendSmsAppointment?.({
      ...filter,
      page: (tableData.pagination?.current || 0) - 1,
      pageSize,
      startDate: moment(filter?.startDate || moment().startOf('day'))?.format(
        DATE.YYYY_MM_DD_HH_MM,
      ),
      endDate: moment(filter?.endDate || moment().endOf('day')).format(
        DATE.YYYY_MM_DD_HH_MM,
      ),
      view: APPOINTMENT_VIEW_TYPE.LIST,
      scheduleStatus: scheduleStatusSearchAppointment,
    })
  }
  
  return (
    <S.BaseReportWrapper className="search-appointment">
      <AppointmentModal open={openAppointment} setOpen={setOpenAppointment} />
      <CreateAndUpdate
        open={openCreateUpdate}
        setOpen={setOpenCreateUpdate}
        appointment={appointment}
        setAppointment={setAppointment}
        view={APPOINTMENT_VIEW_TYPE.LIST}
      />
      <ConfirmModal
        openConfirmModal={openConfirmModal}
        setOpenConfirmModal={setOpenConfirmModal}
        cancelAppointmentItem={cancelAppointmentItem}
        setCancelAppointmentItem={setCancelAppointmentItem}
      />
      <HeaderPage
        titleI18nKey={R.strings.search_appointment}
        rightComponent={
          <HeaderPageAction setOpenAppointment={setOpenAppointment}  pagination={tableData.pagination}/>
        }
      />
      <S.RootWrapper>
        <S.HeadWrapper>
          <FilterRow>
            <FilterSearchAppointmentBox>
              <FilterSearchAppointment
                onChangeFilter={onChangeFilter}
                isSearch
              />
            </FilterSearchAppointmentBox>
            <SendSMSBox>
              <Button onClick={handleSendSms}>
                {t(R.strings.search_appointment_send_all)}
              </Button>
            </SendSMSBox>
          </FilterRow>

          <FilterRowBottom>
            <FilterSearchAppointment
              onChangeFilter={onChangeFilter}
              isSearch={false}
            />
          </FilterRowBottom>
          <ResultDateRange>
            {t(R.strings.total_schedule)}
            <ResultDateRangeNumber>
              {(scheduleTotal && scheduleTotal['total']) || 0}
            </ResultDateRangeNumber>
          </ResultDateRange>
        </S.HeadWrapper>
        <ListTotalPage />
        <BaseTableReport
          columns={columns}
          dataSource={tableData.data}
          loading={tableData.loading}
          onChange={handleTableChange}
          rowClassName={record =>
            `row-overwrite-style row-status-${record?.scheduleStatus}`
          }
          pagination={tableData.pagination}
          scroll={{ x: true, y: tableData?.data?.length ? 480 : undefined }}
        />
      </S.RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default SearchAppointmentPage
