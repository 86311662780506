/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetReport01 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import {
  IParamsGetReport01,
  IResponseGetReport01,
} from 'app/api/report/model/report-01'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import {
  IDataBaseTable,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { randomMoney } from 'app/components/tables/common-table/constant'
import { isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import {
  convertGeneralReport01Value,
  sumDataReport01,
  useCommonHook,
} from '../common-hook'
import { initialPagination } from '../common-model'
import { IDataReport01Weekly } from './data'

export const useTableWeeklyHook = (props: IParamsHookReport) => {
  const getDataWeek = async (
    pagination: Pagination,
    params: IParamsHookReport,
  ) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IDataReport01Weekly>
    try {
      const dataToSend: IParamsGetReport01 = {
        year: params?.year ?? props?.year,
        month: params?.month ?? props?.month,
        startdate: params?.startDate ?? props?.startDate,
        enddate: params?.endDate ?? props?.endDate,
      }
      const response: IResponseGetReport01 = await requestGetReport01(
        dataToSend,
      )
      if (
        isEqual(response?.msgcode, ReportMessageCode.Report01.successGetData)
      ) {
        const data = response?.data ?? []
        let dataTable: IDataReport01Weekly[] = []
        let dataTotalOfTable: IDataReport01Weekly = {}
        data.forEach((service, indexService) => {
          const [firstArea, ...otherArea] = service?.area ?? []
          const [firstClinicOfFirstArea, ...otherClinicOfFirstArea] =
            firstArea?.clinic ?? []

          const keyOfService = indexService + 1

          const keyOfFirstClinic = Number(`${keyOfService * 10}1`)
          const dataOfFirstArea = convertGeneralReport01Value(
            firstArea,
            'total',
          )
          const dataOfFirstClinic = convertGeneralReport01Value(
            firstClinicOfFirstArea,
            'normal',
          )

          const saveData = {
            key: keyOfService,
            service: service?.serviceName,
            isNotExpanded: true,

            area: firstArea?.areaName,
            childrenKey: keyOfFirstClinic,
            clinic: firstClinicOfFirstArea?.clinicName,

            ...dataOfFirstArea,
            ...dataOfFirstClinic,
            childOfFirstChild: otherClinicOfFirstArea?.map(
              (clinic, indexClinic) => {
                const dataOfClinic = convertGeneralReport01Value(
                  clinic,
                  'normal',
                )
                const keyChild = Number(`${keyOfFirstClinic}${indexClinic}`)
                return {
                  key: keyChild,
                  index: indexClinic + 1,
                  clinic: clinic.clinicName,
                  parentKey: keyOfFirstClinic,
                  ...dataOfClinic,
                }
              },
            ),
            children: otherArea?.map((otherArea, indexOtherArea) => {
              const [firstClinicOfArea, ...otherClinicOfOtherArea] =
                otherArea?.clinic ?? []
              const dataOfOtherArea = convertGeneralReport01Value(
                otherArea,
                'total',
              )
              const dataOfFirstClinicOfOtherArea = convertGeneralReport01Value(
                firstClinicOfArea,
                'normal',
              )

              const keyOfClinic = Number(
                `${(indexService + 1) * 10}${indexOtherArea + 2}`,
              )

              return {
                key: keyOfClinic,
                area: otherArea?.areaName,
                clinic: firstClinicOfArea?.clinicName,

                ...dataOfOtherArea,
                ...dataOfFirstClinicOfOtherArea,

                children: otherClinicOfOtherArea?.map((clinic, idxChild) => {
                  const dataOfClinic = convertGeneralReport01Value(
                    clinic,
                    'normal',
                  )
                  const keyChild = Number(`${keyOfClinic}${idxChild}`)
                  return {
                    key: keyChild,
                    clinic: clinic.clinicName,
                    index: idxChild + 1,
                    ...dataOfClinic,
                  }
                }),
              }
            }),
          } as IDataReport01Weekly

          const dataOfService = convertGeneralReport01Value(service, 'normal')

          dataTotalOfTable = sumDataReport01(dataTotalOfTable, dataOfService)
          dataTable = dataTable.concat([
            saveData,
            {
              key: randomMoney(),
              service: 'Tổng',
              isTotalGroupTable: true,
              ...dataOfService,
            },
          ])
        })
        const lastData = dataTable.concat([
          {
            key: randomMoney(),
            service: 'Tổng tuần',
            isTotalGroupTable: true,
            isTotalTable: true,
            ...dataTotalOfTable,
          },
        ])

        return {
          ...initData,
          data: lastData,
        }
      }
      return initData
    } catch (error) {
      return initData
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,
    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({
    getTreeTableData: getDataWeek,
    isExpandedWhenStart: true,
  })

  const { columns, data, exportExcel } = useCommonHook({
    expandedRowKeys,
    typeHook: 'week',
    handleExpand,
    tableData,
    fetchLoading,
    currentFilter: props,
  })

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleResetExpand,
    fetch,
    fetchLoading,
    data,
    exportExcel,
  }
}
