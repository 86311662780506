import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import ImportAccountingSoldSellingExpensesWeeklyContainer from 'app/containers/Accounting/CostPlan'

const ImportAccountingSoldSellingExpensesWeeklyPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>
        {t(R.strings.import_cost_of_goods_sold_and_selling_expenses_weekly)}
      </PageTitle>
      <ImportAccountingSoldSellingExpensesWeeklyContainer />
    </>
  )
}
const ImportAccountingSoldSellingExpensesWeeklyPage = memo(
  ImportAccountingSoldSellingExpensesWeeklyPageCpn,
  isEqual,
)

export default ImportAccountingSoldSellingExpensesWeeklyPage
