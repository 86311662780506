import { BaseTable } from 'app/components/common/BaseTable'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const TableWrapper = styled(BaseTable)`
  table > thead > tr:first-child > *:first-child {
    border-radius: 0px !important;
  }
  table > thead > tr:last-child > *:last-child {
    border-radius: 0px !important;
  }
  .ant-table-thead > tr > th {
    border-right: 0px !important;
    border-left: 0px !important;
    background: #ebeef2;
    padding: 8px 16px;
    color: ${convertedVariables.primaryColor};
    opacity: 0.7;
    font-weight: ${FONT_WEIGHT.semibold};
    font-size: ${FONT_SIZE.xxs};
  }
  .ant-table {
    margin-inline: -16px !important;
  }

  .very-big-column {
    min-width: 300px !important;
  }

  .big-column {
    min-width: 220px !important;
  }

  .normal-column {
    min-width: 170px !important;
  }

  .medium-column {
    min-width: 100px !important;
  }

  .small-column {
    width: 72px !important;
  }

  .class-left {
    .ant-row {
        display: flex;
        justify-content: flex-end;
    }
    text-align: right !important;
  }
`
