import {
  requestCreateAppointmentCallCenterManagement,
  requestExportCallCenter,
  requestGetAppointmentManagement,
  requestGetCallCenterScheduleTotal,
  requestUpdateAppointmentCallCenterManagement,
  requestsendSmsCallCenter,
} from 'app/api/appointment'
import { EScheduleStatus } from 'app/api/appointment/model'
import { notificationController } from 'app/controllers/notification-controller'
import React, { createContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { IAppointment } from 'app/api/appointment/model/appointment'
import { get } from 'lodash'

interface IAppointmentContext {
  loading?: boolean
  setLoading?: (params) => void
  fetchData?: (params) => void
  postAppointment?: (payload) => void
  cancelAppointment?: (payload, id) => void
  updateAppointment?: (payload, id) => Promise<IAppointment>
  getCallCenterScheduleTotal?: (params) => void
  setData?: (params) => void
  sendSmsAppointment?: (params) => void
  PrintAppointment?: (params) => Promise<IAppointment>
  appointments: IAppointment[]
  appointmentTotal?: {
    _id?: number
    scheduleStatus?: {
      _id?: number
      total?: number
    }[]
  }[]
}

export const AppointmentContext = createContext<IAppointmentContext>({
  loading: false,
  appointments: [],
  appointmentTotal: [],
})

export const AppointmentProvider = ({ children }) => {
  const [data, setData] = useState<IAppointment[]>([])
  const [total, setTotal] = useState([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const fetchData = async params => {
    setLoading(true)
    const appointments = await requestGetAppointmentManagement(params)
    setData(appointments?.data)
    setLoading(false)
  }

  const sendSmsAppointment = async params => {
    setLoading(true)
    try {
      const appointments = await requestsendSmsCallCenter(params)
      if (appointments?.statusCode != 200) {
        notificationController.error({ message: t(appointments?.message) })
      } else {
        setData([...data, appointments?.data])
        notificationController.success({
          message: t(R.strings.sms_successfully),
        })
      }
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }

    setLoading(false)
  }

  const PrintAppointment = async params => {
    setLoading(true)
    try {
      const appointment = await requestExportCallCenter(params)
      if (appointment?.statusCode != 200) {
        notificationController.error({ message: t(appointment?.message) })
      }
      return appointment
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }

    setLoading(false)
  }

  const postAppointment = async body => {
    setLoading(true)
    try {
      const appointments = await requestCreateAppointmentCallCenterManagement(
        body,
      )
      if (appointments?.statusCode != 200) {
        notificationController.error({ message: t(appointments?.message) })
      } else {
        setData([...data, appointments?.data])
      }
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }

    setLoading(false)
  }

  const cancelAppointment = async (payload, id) => {
    setLoading(true)
    try {
      const result = await requestUpdateAppointmentCallCenterManagement({
        id,
        body: { ...payload, scheduleStatus: EScheduleStatus.CANCEL },
      })

      if (result?.statusCode != 200) {
        notificationController.error({ message: t(result?.message) })
      } else {
        setData([
          ...data.filter(appointment => appointment?._id !== id),
          result?.data,
        ])
      }
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }
    setLoading(false)
  }

  const updateAppointment = async (payload, id) => {
    setLoading(true)

    try {
      const result = await requestUpdateAppointmentCallCenterManagement({
        id,
        body: payload,
      })

      if (result?.statusCode != 200) {
        notificationController.error({
          message: t(R.strings.error),
          description: t(result?.message),
        })
      } else {
        setData([
          ...data.filter(appointment => appointment?._id !== id),
          result?.data,
        ])

        notificationController.success({
          message: t(R.strings.update),
          description: t(R.strings.success),
        })
      }

      return result
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }
    setLoading(false)
  }

  const getCallCenterScheduleTotal = async params => {
    setLoading(true)
    try {
      const result = await requestGetCallCenterScheduleTotal(params)
      const data = result?.data
      setTotal(data.length ? data[0].scheduleStatus : [])
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }
    setLoading(false)
  }

  return (
    <AppointmentContext.Provider
      value={{
        appointments: data,
        loading,
        setLoading,
        fetchData,
        postAppointment,
        cancelAppointment,
        updateAppointment,
        getCallCenterScheduleTotal,
        appointmentTotal: total,
        setData,
        sendSmsAppointment,
        PrintAppointment,
      }}
    >
      {children}
    </AppointmentContext.Provider>
  )
}
