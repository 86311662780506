import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import DebtContainer from 'app/containers/Accounting/Debt'

const AccountingDebtCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>
        {t(R.strings.manage_customer_debt)}
      </PageTitle>
      <DebtContainer />
    </>
  )
}
const AccountingDebt = memo(
  AccountingDebtCpn,
  isEqual,
)

export default AccountingDebt
