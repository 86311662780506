import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import { useModalHook } from './hook'
import * as S from './styles'

import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { Checkbox } from 'antd'
import { isEqual } from 'lodash'
import { BaseButton } from 'app/components/common/BaseButton'
import { PlusOutlined } from '@ant-design/icons'

export const CreateLevelSkill = () => {
  const {
    form,
    visible,
    t,
    showModal,
    handleSubmit,
    handleCancel,
    onChangeIndustry,
    industries,
    handleSelectAllChange,
    handleCheckboxChange,
    listSkill,
  } = useModalHook()

  return (
    <BaseModalManagement
      titleKeyI18n={R.strings.profile_content_add_skill}
      widthModal={1024}
      content={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          form={form}
        >
          <S.AreaAddNewSupplies
            gutter={20}
            justify={'space-between'}
            align={'top'}
          >
            <BaseCol xl={12}>
              <S.SelectBaseStyled
                placeholder={t(R.strings.skill_content_select_industry)}
                suffixIcon={<SvgTableCloseIcon />}
                onChange={onChangeIndustry}
              >
                {industries?.map((item, index) => {
                  return (
                    <Option value={item._id} key={index}>
                      {item?.name}
                    </Option>
                  )
                })}
              </S.SelectBaseStyled>
            </BaseCol>

            <BaseCol xl={12}>
              <BaseInput placeholder="Nhập tên kỹ năng" />
            </BaseCol>
          </S.AreaAddNewSupplies>
          <BaseDivider />
          <S.SkillWrapper>
            <S.SkillTitle>
              <span>{t(R.strings.profile_content_industry)}</span>
              <span>{t(R.strings.skill_content_name_create)}</span>
              <span>{t(R.strings.skill_content_levelSkill)}</span>
              <span>
                <BaseCheckbox onChange={handleSelectAllChange} />
              </span>
            </S.SkillTitle>
            <S.SkillList>
              <BaseForm.Item hiddenLabel name="skillsInfo">
                <S.CheckboxGroup onChange={handleCheckboxChange}>
                  {listSkill?.map(skill => (
                    <S.SkillItem key={skill.id}>
                      <span>{skill.industryName}</span>
                      <span>{skill.skillName}</span>
                      <span>
                        <S.LevelTag color={skill.levelSkillColor}>
                          {skill.levelSkillName}
                        </S.LevelTag>
                      </span>
                      <span>
                        <Checkbox key={skill.id} value={skill.id} />
                      </span>
                    </S.SkillItem>
                  ))}
                </S.CheckboxGroup>
              </BaseForm.Item>
            </S.SkillList>
            <S.FormItem
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                !isEqual(prevValues.skillsInfo, currentValues.skillsInfo)
              }
            >
              {({ getFieldValue }) => {
                const skillsInfo = getFieldValue('skillsInfo') ?? []
                return (
                  <S.SelectedSkillText>
                    {t(
                      R.strings
                        .treatment_management_content_selected_total_skill,
                    )}
                    : <span className="number">{skillsInfo.length}</span>
                  </S.SelectedSkillText>
                )
              }}
            </S.FormItem>
          </S.SkillWrapper>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.profile_content_add_skill}
      confirmKeyI18n={R.strings.confirm}
      handleSubmit={
        form
          ? form?.submit
          : () => {
              console.error('Submit is empty')
            }
      }
      visible={visible}
      typeButtonOpen="custom"
      buttonComponent={
        <BaseButton onClick={showModal} icon={<PlusOutlined rev={undefined} />}>
          {t(R.strings.profile_content_add_skill)}
        </BaseButton>
      }
    />
  )
}
