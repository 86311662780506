import { SearchOutlined } from '@ant-design/icons'
import { IJobTitleGroup } from 'app/api/jobtitlegroup/model/job-title-management'
import { IUnit } from 'app/api/unit/model/unit-management'
import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { ListStatusTable, StatusTableType } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { Option } from 'app/components/common/BaseSelectSearch'
import { Loading } from 'app/components/common/Loading'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { BaseTreeSelect } from 'app/components/common/selects/BaseTreeSelect'
import { transformDataSelectTree } from 'app/components/common/selects/BaseTreeSelect/helper'
import { usePagination } from 'app/hook'
import { useGetJobTitleGroup } from 'app/react-query/hook/job-title-group'
import { useGetUnitList } from 'app/react-query/hook/unit'
import {
  IResponseQueryList,
  IResponseQueryListNoTotal,
} from 'app/react-query/model/common'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { IMetadata } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IFilterProfile } from '../../type'

interface IProps {
  onChangeFilter?: (params: IFilterProfile) => void
  filter?: IFilterProfile
}

const FilterLayout = ({ onChangeFilter, filter }: IProps) => {
  const { t } = useTranslation()
  const { data: jobTitleGroupsData, isLoading } = useGetJobTitleGroup()
  const { data: unitListData, isLoading: isLoadingUnit } = useGetUnitList()

  const { flattenDataList } = usePagination()
  const [jobTitleGroupId, setJobTitleGroupId] = useState<string | undefined>(
    undefined,
  )
  const [jobTitleIds, setJobTitleIds] = useState<string[]>([])

  const jobTitleGroups = useMemo(() => {
    const flattenData: IResponseQueryList<IJobTitleGroup[]> =
      flattenDataList(jobTitleGroupsData)
    return flattenData?.data ?? []
  }, [jobTitleGroupsData])

  const jobTiles = useMemo(() => {
    const itemGroup = jobTitleGroups?.find(itm => itm?._id === jobTitleGroupId)
    if (!itemGroup) return []

    return itemGroup?.jobTitles ?? []
  }, [jobTitleGroupId, jobTitleGroups])

  const onChangeGroupTitle = (value: string) => {
    setJobTitleGroupId(value)
    setJobTitleIds([])
  }

  const onChangeJobTitle = (values: string[]) => {
    setJobTitleIds(values)
  }

  useEffect(() => {
    onChangeFilter?.({
      group: jobTitleGroupId,
      jobTitles: jobTitleIds ?? [],
    })
  }, [jobTitleIds, jobTitleGroupId])

  const unitsData: IResponseQueryListNoTotal<{
    rows: IUnit[]
    metadata?: IMetadata
    total?: number
  }> = useMemo(() => {
    return flattenDataList(unitListData)
  }, [unitListData])

  const onChangeUnits = (units: string[]) => {
    onChangeFilter?.({ ...filter, units })
  }

  const onChangeStatus = (status: StatusTableType[]) => {
    onChangeFilter?.({ ...filter, status })
  }

  const onChangeSearch = (search: string) => {
    onChangeFilter?.({ ...filter, search })
  }

  return (
    <RootWrapper align={'middle'} gutter={[16, 16]}>
      <BaseCol xl={5}>
        <FormItem hiddenLabel>
          <BaseInput
            prefix={<SearchOutlined rev={undefined} />}
            value={filter?.search}
            placeholder={t(R.strings.enter_keyword_search)}
            onChange={e => onChangeSearch?.(e?.target?.value ?? '')}
            allowClear
          />
        </FormItem>
      </BaseCol>
      <BaseCol xl={4}>
        <FormItem hiddenLabel required={false}>
          <SelectBaseStyled
            defaultValue={filter?.status}
            value={filter?.status}
            mode="multiple"
            allowClear
            placeholder={t(R.strings.all_status)}
            suffixIcon={!filter?.status?.length && <SvgTableCloseIcon />}
            onChange={type => onChangeStatus?.(type as StatusTableType[])}
          >
            {ListStatusTable?.map((item, index) => {
              return (
                <Option value={item} key={index}>
                  {t(item)}
                </Option>
              )
            })}
          </SelectBaseStyled>
        </FormItem>
      </BaseCol>
      <BaseCol xl={5}>
        <FormItem>
          <SelectBaseStyled
            placeholder={t(
              R.strings.job_title_content_please_select_job_title_group,
            )}
            onChange={value => onChangeGroupTitle(value as string)}
            value={jobTitleGroupId}
            allowClear
          >
            {jobTitleGroups?.map((item, index) => {
              return (
                <Option value={item._id} key={index}>
                  {item.name}
                </Option>
              )
            })}
          </SelectBaseStyled>
        </FormItem>
      </BaseCol>

      {jobTiles?.length ? (
        <BaseCol xl={5}>
          <FormItem>
            <SelectBaseStyled
              placeholder={t(R.strings.profile_content_please_select_job_title)}
              onChange={values => onChangeJobTitle(values as string[])}
              value={jobTitleIds}
              allowClear
              mode="multiple"
              maxTagCount={0}
            >
              {jobTiles?.map((item, index) => {
                return (
                  <Option value={item._id} key={index}>
                    {item.name}
                  </Option>
                )
              })}
            </SelectBaseStyled>
            {isLoading && <Loading />}
          </FormItem>
        </BaseCol>
      ) : null}

      <BaseCol xl={5}>
        <FormItem name="unit">
          <TreeSelectStyled
            onChange={values => onChangeUnits(values as string[])}
            placeholder={t(R.strings.select_unit)}
            suffixIcon={<SvgTableCloseIcon />}
            treeData={transformDataSelectTree(unitsData?.data?.rows ?? [], {
              title: 'name',
              value: '_id',
              children: 'children',
            })}
            allowClear
            loading={isLoadingUnit}
            multiple
            maxTagCount={1}
          />
        </FormItem>
      </BaseCol>
    </RootWrapper>
  )
}

export default FilterLayout

const RootWrapper = styled(BaseRow)``

export const FormItem = styled(BaseForm.Item)``

export const SelectBaseStyled = styled(BaseSelect)`
  min-width: 150px !important;
`

export const TreeSelectStyled = styled(BaseTreeSelect)`
  .ant-select-selector {
    border: 1px solid ${convertedVariables.neutralBlack2Color} !important;
  }
`
