import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import { RadioBtn } from '../SettingsOverlay/styles'
import { BaseRadio } from 'app/components/common/BaseRadio'
import { BaseSpace } from 'app/components/common/BaseSpace'
import {
  getCurrentLanguage,
  setCurrentLanguage,
} from 'app/service/storage/storage-service'

export const LanguagePicker: React.FC = () => {
  return (
    <BaseRadio.Group
      defaultValue={getCurrentLanguage}
      onChange={e => setCurrentLanguage(e.target.value)}
    >
      <BaseSpace direction="vertical">
        <RadioBtn value="en">
          <BaseSpace align="center">
            English
            <ReactCountryFlag svg countryCode="GB" />
          </BaseSpace>
        </RadioBtn>
        <RadioBtn value="de">
          <BaseSpace align="center">
            Viet Nam
            <ReactCountryFlag svg countryCode="DE" />
          </BaseSpace>
        </RadioBtn>
      </BaseSpace>
    </BaseRadio.Group>
  )
}
