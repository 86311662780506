import { BaseSpace } from 'app/components/common/BaseSpace'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, LAYOUT, PADDING, media } from 'parkway-web-common'
import styled from 'styled-components'
import {
  MailBoxDoctorComponent,
  NewsDoctorComponent,
  NotificationDoctorComponent,
  TaskComponent,
} from './components'

export const RightSideDoctorLayout = () => {
  return (
    <RightSideCol>
      <BaseSpace>
        <NotificationDoctorComponent />
        <TaskComponent />
        <MailBoxDoctorComponent />
        <NewsDoctorComponent />
      </BaseSpace>
    </RightSideCol>
  )
}

const RightSideCol = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: ${BORDER_RADIUS};
  margin-left: ${PADDING.md};
  min-width: 260px;
  @media only screen and ${media.xl} {
    height: calc(100vh - ${LAYOUT.desktop.headerHeight} - 10vh);
    overflow: auto;
  }
  .ant-tabs-ink-bar {
    background-color: ${convertedVariables.statesOrangeColor};
  }
`