import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { PADDING } from 'parkway-web-common'

export const NotFoundContainer = ({
  title = 'Đang phát triển',
}: {
  title?: string
}) => {
  return (
    <BaseRow gutter={16} style={{ padding: PADDING.md }}>
      <BaseCol>{title}</BaseCol>
    </BaseRow>
  )
}
