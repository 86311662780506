import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { useHook } from './hook'
import { useHookCommon } from '../hook'

export const PendingLayout = () => {
  const { data } = useHook()
  const { column } = useHookCommon()
  return (
    <BaseTableManagement
      columns={column}
      dataSource={data}
      pagination={{ total: 20 }}
    />
  )
}
