import { requestGetOrganizationalChart } from 'app/api/company/organizational-chart'
import {
  IOrganizationalChart,
  IProfileOrganization,
  ResponseOrganizationalChartType,
} from 'app/api/company/organizational-chart/model'
import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import BaseText from 'app/components/common/BaseText'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import {
  DateUtil,
  Pagination,
  getRandomUuid,
  initialPagination,
} from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IProfileMonthlyRoster } from '../../type'
import { IProps } from './type'
import { randomPercent } from 'app/components/tables/common-table/constant'
import _ from 'lodash'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseTooltip } from 'app/components/common/BaseTooltip'
import { SvgQuestionMarkCircleIcon } from 'app/assets/svg-assets'
import { BaseSpace } from 'app/components/common/BaseSpace'

export const useHookTable = ({ record, filter }: IProps) => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState(initialPagination)
  const [loading, setLoading] = useState(false)
  const [detail, setDetail] = useState<IOrganizationalChart | undefined>(
    undefined,
  )

  const getDetail = async () => {
    try {
      setLoading(true)

      // check if record is undefined
      if (!record?._id) return

      const res: ResponseOrganizationalChartType =
        await requestGetOrganizationalChart({
          unitId: record?._id,
        })

      res?.data && setDetail(res.data?.[0])
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDetail()
  }, [])

  const profiles: IProfileMonthlyRoster[] = useMemo(() => {
    let res: IProfileOrganization[] = []
    res = res.concat(detail?.managers ?? [])
    res = res.concat(detail?.vices ?? [])
    res = res.concat(detail?.profiles ?? [])
    return res?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
        employeeCode: getRandomUuid(),
        startDate: DateUtil.formatYYYYMMDD(new Date()),
        numberOfDaysInMonth: _.random(0, 10),
      } as IProfileMonthlyRoster
    })
  }, [detail, filter])

  const columnProfile = useMemo(() => {
    return [
      convertColumnTable<IProfileMonthlyRoster>({
        title: t(R.strings.employee),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'big-column',
        className: 'main-column',
        render: (_, record) => {
          return (
            <BaseAvatarProfile
              name={record?.name}
              src={record?.avatar}
              jobTitle={record?.workinfos?.jobTitle?.name ?? '-'}
            />
          )
        },
      }),
      convertColumnTable<IProfileMonthlyRoster>({
        title: t(R.strings.employee_id),
        key: 'employeeCode',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.employeeCode ?? '-'}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IProfileMonthlyRoster>({
        title: t(R.strings.start_date),
        key: 'startDate',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.startDate ?? '-'}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      {
        title: t(R.strings.leave_type),
        key: 'leaveType',
        children: [
          {
            title: t(R.strings.remaining_old_year),
            key: 'remainingOldYear',
            children: [
              {
                title: t(R.strings.annual_leave),
                key: 'annualLeave',
                children: [
                  convertColumnTable<IProfileMonthlyRoster>({
                    title: t(R.strings.available_in_quarter_2),
                    key: 'availableInQuarter2',
                    render: (_, record) => {
                      return (
                        <BaseText
                          children={
                            record?.availableInQuarter2 ?? randomPercent() ?? 0
                          }
                          fontWeight="medium"
                          opacity="0.7"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IProfileMonthlyRoster>({
                    title: t(R.strings.used_to_current_month),
                    key: 'usedUntilCurrentMonth',
                    render: (_, record) => {
                      return (
                        <BaseText
                          children={
                            record?.usedUntilCurrentMonth ??
                            randomPercent() ??
                            0
                          }
                          fontWeight="medium"
                          opacity="0.7"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IProfileMonthlyRoster>({
                    title: t(R.strings.remaining_in_quarter_2),
                    key: 'remainingInQuarter2',
                    render: (_, record) => {
                      return (
                        <BaseText
                          children={
                            record?.remainingInQuarter2 ?? randomPercent() ?? 0
                          }
                          fontWeight="semibold"
                          colorText="collapseBackgroundColor"
                        />
                      )
                    },
                  }),
                ],
              },
            ],
          },
          {
            title: t(R.strings.new_year),
            key: 'newYear',
            children: [
              {
                title: t(R.strings.annual_leave),
                key: 'annualLeave',
                children: [
                  convertColumnTable<IProfileMonthlyRoster>({
                    title: t(R.strings.available),
                    key: 'availableAnnualLeaveInYear',
                    render: (_, record) => {
                      return (
                        <BaseText
                          children={
                            record?.availableAnnualLeaveInYear ??
                            randomPercent() ??
                            0
                          }
                          fontWeight="medium"
                          opacity="0.7"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IProfileMonthlyRoster>({
                    title: t(R.strings.used),
                    key: 'usedAnnualLeaveInYear',
                    render: (_, record) => {
                      return (
                        <BaseText
                          children={
                            record?.usedAnnualLeaveInYear ??
                            randomPercent() ??
                            0
                          }
                          fontWeight="medium"
                          opacity="0.7"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IProfileMonthlyRoster>({
                    title: t(R.strings.remaining),
                    key: 'remainingAnnualLeaveInYear',
                    render: (_, record) => {
                      return (
                        <BaseText
                          children={
                            record?.remainingAnnualLeaveInYear ??
                            randomPercent() ??
                            0
                          }
                          fontWeight="semibold"
                          colorText="collapseBackgroundColor"
                        />
                      )
                    },
                  }),
                ],
              },
              {
                title: t(R.strings.sick_leave),
                key: 'sick_leave',
                children: [
                  convertColumnTable<IProfileMonthlyRoster>({
                    title: t(R.strings.available),
                    key: 'availableSickLeave',
                    render: (_, record) => {
                      return (
                        <BaseText
                          children={
                            record?.availableSickLeave ?? randomPercent() ?? 0
                          }
                          fontWeight="medium"
                          opacity="0.7"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IProfileMonthlyRoster>({
                    title: t(R.strings.used),
                    key: 'usedSickLeave',
                    render: (_, record) => {
                      return (
                        <BaseText
                          children={
                            record?.usedSickLeave ?? randomPercent() ?? 0
                          }
                          fontWeight="medium"
                          opacity="0.7"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IProfileMonthlyRoster>({
                    title: t(R.strings.remaining),
                    key: 'remainingSickLeave',
                    render: (_, record) => {
                      return (
                        <BaseText
                          children={
                            record?.remainingSickLeave ?? randomPercent() ?? 0
                          }
                          fontWeight="semibold"
                          colorText="collapseBackgroundColor"
                        />
                      )
                    },
                  }),
                ],
              },
            ],
          },
        ],
      },
      {
        title: (
          <BaseRow gutter={[8, 8]} justify={'center'} align={'middle'}>
            <BaseCol>
              <BaseText
                children={t(R.strings.follow_weekend_leave)}
                fontWeight="semibold"
              />
            </BaseCol>
            <BaseTooltip
              title={
                <BaseText
                  children={t(R.strings.maximum_leave_weekend)}
                  colorText="backgroundColor"
                  fontWeight="medium"
                />
              }
            >
              <SvgQuestionMarkCircleIcon />
            </BaseTooltip>
          </BaseRow>
        ),
        key: 'followWeekendLeave',
        className: 'big-column',
        children: [
          convertColumnTable<IProfileMonthlyRoster>({
            title: t(R.strings.number_of_days_in_month),
            key: 'numberOfDaysInMonth',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.numberOfDaysInMonth ?? 0}
                  fontWeight="semibold"
                  colorText={
                    record?.numberOfDaysInMonth &&
                    record?.numberOfDaysInMonth > 2
                      ? 'statesRedColor'
                      : 'primaryColor'
                  }
                />
              )
            },
          }),
        ],
      },
      {
        title: (
          <BaseRow gutter={[8, 8]} justify={'center'} align={'middle'}>
            <BaseCol>
              <BaseText
                children={t(R.strings.follow_leave_quota_in_month)}
                fontWeight="semibold"
              />
            </BaseCol>
            <BaseTooltip
              title={
                <BaseSpace size={4}>
                  <BaseText
                    children={'* Tối đa một tháng chỉ được nghỉ 8 ngày'}
                    colorText="backgroundColor"
                    fontWeight="medium"
                  />
                  <BaseText
                    children={'* BS nghỉ tối đa 2 ngày phép vào cuối tuần'}
                    colorText="backgroundColor"
                    fontWeight="medium"
                  />
                </BaseSpace>
              }
            >
              <SvgQuestionMarkCircleIcon />
            </BaseTooltip>
          </BaseRow>
        ),
        key: 'followLeaveQuotaInMonth',
        children: [
          convertColumnTable<IProfileMonthlyRoster>({
            title: t(R.strings.half_day),
            key: 'halfDay',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.halfDay ?? randomPercent() ?? 0}
                  fontWeight="medium"
                  opacity="0.7"
                />
              )
            },
          }),
          convertColumnTable<IProfileMonthlyRoster>({
            title: t(R.strings.full_day),
            key: 'fullDay',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.fullDay ?? randomPercent() ?? 0}
                  fontWeight="medium"
                  opacity="0.7"
                />
              )
            },
          }),
          convertColumnTable<IProfileMonthlyRoster>({
            title: t(R.strings.total),
            key: 'total',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.total ?? randomPercent() ?? 0}
                  fontWeight="medium"
                  opacity="0.7"
                />
              )
            },
          }),
        ],
      },
    ]
  }, [t, filter])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  return {
    loading,
    handleTableChange,
    pagination,
    profiles,
    columnProfile,
  }
}
