import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { ISupplies } from 'app/containers/Accounting/ItemManagement/model/supplies'
import { FONT_SIZE, PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import BaseImageWithCharacter from '../../../BaseImageWithCharacter'
import { addPrefixImage } from 'app/common/helpers'

const SuppliesOptionSelect = ({ item }: { item: ISupplies }) => {
  return (
    <RootWrapper gutter={[10, 10]} justify={'space-between'} align={'middle'}>
      <BaseRow gutter={10} align={'middle'}>
        <ImageOption
          src={addPrefixImage(item?.mainImagesUrl)}
          alt={item?.name}
        />
        <BaseCol>{item?.name}</BaseCol>
      </BaseRow>
    </RootWrapper>
  )
}

export default SuppliesOptionSelect

const RootWrapper = styled(BaseRow)`
  padding: ${PADDING.xxxxs} ${PADDING.xxxs};
`

const ImageOption = styled(BaseImageWithCharacter)`
  width: 24px !important;
  height: 24px !important;
  font-size: ${FONT_SIZE.xxs} !important;
  line-height: ${FONT_SIZE.xs};
  display: flex;
  align-items: center;
  justify-content: center;
`
