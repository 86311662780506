import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import HeaderPage from 'app/components/header/components/HeaderPage'
import * as S from 'app/components/tables/BaseTableReport/styles'
import 'dayjs/locale/vi'
import { useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { FilterLocationAndDoctorLayout } from './components'
import {
  OrthodonticWithoutPaidProvider
} from './context'
import { ListPatientWithoutPaidLayout } from './layouts'
import { RootWrapper } from './styles'

const OrthodonticWithoutPaidContainer = () => {

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.ACCOUNTING,
      FunctionPermissionEnum.READ_DAILY_INVOICE,
      R.strings.role,
    )
  }, [])

  return (
    <OrthodonticWithoutPaidProvider>
      <S.BaseReportWrapper>
        <HeaderPage titleI18nKey={R.strings.orthodontic_patient_scheduling_density} />
        <RootWrapper>
          <FilterLocationAndDoctorLayout />
          <ListPatientWithoutPaidLayout />
        </RootWrapper>
      </S.BaseReportWrapper>
    </OrthodonticWithoutPaidProvider>
  )
}

export default OrthodonticWithoutPaidContainer
