import styled from 'styled-components'

export const Box = styled.div`
  background: #fdfdfd;
  border: 1px solid var(--neutral-black-9-color);
  border-radius: 8px;
  padding: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
`
export const BoxTitle = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  opacity: 90%;
  color: #365b7e;
`
export const BoxAmount = styled.div`
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  color: var(--main-color-text);
`
