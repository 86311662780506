import { InfoCircleFilled } from '@ant-design/icons'
import { requestGetOrganizationalChart } from 'app/api/company/organizational-chart'
import {
  IOrganizationalChart,
  IProfileOrganization,
  ResponseOrganizationalChartType,
} from 'app/api/company/organizational-chart/model'
import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BaseTooltip } from 'app/components/common/BaseTooltip'
import {
  ExpandedTableValue,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import { getArray } from 'app/components/tables/common-table/constant'
import _, { isEqual } from 'lodash'
import { Pagination, initialPagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DefineDateType } from '../../constant'
import {
  DateProfileMonthlyRosterTypeEnum,
  IMonthlyRoster,
  IProfileMonthlyRoster,
  ListDateProfileMonthlyRosterTypeEnum,
} from '../../type'
import { IProps } from './type'

const HeadCustom = ({
  title,
  tooltipText,
}: {
  title: string
  tooltipText?: string
}) => {
  return (
    <BaseRow gutter={[8, 8]} justify={"start"} align={'middle'}>
      <BaseCol>
        <BaseText children={title} fontWeight="semibold" opacity="0.4" />
      </BaseCol>
      {tooltipText && (
        <BaseTooltip
          title={
            <BaseText
              children={tooltipText}
              colorText="backgroundColor"
              fontWeight="medium"
            />
          }
        >
          <InfoCircleFilled style={{ opacity: 0.4 }} rev={undefined} />
        </BaseTooltip>
      )}
    </BaseRow>
  )
}

export const useHookTable = ({ record, filter }: IProps) => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState(initialPagination)
  const [loading, setLoading] = useState(false)
  const [detail, setDetail] = useState<IOrganizationalChart | undefined>(
    undefined,
  )
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

  const handleExpand = (expanded: boolean, newKey?: number) => {
    if (!newKey) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const getDetail = async () => {
    try {
      setLoading(true)

      // check if record is undefined
      if (!record?._id) return

      const res: ResponseOrganizationalChartType =
        await requestGetOrganizationalChart({
          unitId: record?._id,
        })

      res?.data && setDetail(res.data?.[0])
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDetail()
  }, [])

  const data = useMemo(() => {
    return detail?.children?.map((item, index) => ({ ...item, key: index + 1 }))
  }, [detail])

  const profiles: IProfileMonthlyRoster[] = useMemo(() => {
    let res: IProfileOrganization[] = []
    res = res.concat(detail?.managers ?? [])
    res = res.concat(detail?.vices ?? [])
    res = res.concat(detail?.profiles ?? [])

    const numberDateOfMonth = new Date(
      filter?.year ? +filter?.year : new Date().getFullYear(),
      filter?.month ? +filter?.month : new Date().getMonth() + 1,
      0,
    ).getDate()

    return res?.map((item, index) => {
      getArray(numberDateOfMonth)?.forEach(index => {
        const type =
          ListDateProfileMonthlyRosterTypeEnum?.[
            _.random(0, ListDateProfileMonthlyRosterTypeEnum?.length - 1)
          ]
        item[`date-${index}-type`] = type
        item[`date-${index}-color`] = DefineDateType?.[type]?.color
      })
      return { ...item, key: index + 1 }
    })
  }, [detail, filter])

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IMonthlyRoster>({
        title: t(R.strings.department),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)
          const _handleExpand = () => {
            return handleExpand(!isExpanded, record?.key)
          }

          const style: React.CSSProperties | undefined = isExpanded
            ? { position: 'relative', marginTop: -16, paddingBottom: 15 }
            : undefined

          return (
            <ExpandedTableValue
              isExpanded={isExpanded}
              handleExpand={_handleExpand}
              isHaveAreaWhenNotHaveActionExpanded
              style={style}
              alignContainer="middle"
              value={record?.name}
            />
          )
        },
      }),
    ]
    return option
  }, [t, expandedRowKeys])

  const columnProfile = useMemo(() => {
    const numberDateOfMonth = new Date(
      filter?.year ? +filter?.year : new Date().getFullYear(),
      filter?.month ? +filter?.month : new Date().getMonth() + 1,
      0,
    ).getDate()

    const datesColumns = getArray(numberDateOfMonth).map(index => {
      return convertColumnTable({
        title: `${index > 9 ? index : `0${index}`}/${filter?.month}`,
        key: `date-${index}-value`,
        classNameWidthColumnOverwrite: 'medium-column',
        className: 'child-column',
        render: (_, record) => {
          const type = record?.[
            `date-${index}-type`
          ] as DateProfileMonthlyRosterTypeEnum
          return (
            <BaseRow gutter={[8, 8]}>
              <BaseCol>
                <BaseText
                  children={`${type ?? '0'}`}
                  colorText="statesGreenColor"
                  fontWeight="medium"
                  style={{
                    color: `${record?.[`date-${index}-color`]}`,
                  }}
                />
              </BaseCol>
              {isEqual(type, DateProfileMonthlyRosterTypeEnum.UL) ? (
                <BaseCol>
                  <BaseTooltip title={`Lý do nghỉ phép`}>
                    <InfoCircleFilled rev={undefined} />
                  </BaseTooltip>
                </BaseCol>
              ) : null}
            </BaseRow>
          )
        },
      })
    })

    return [
      convertColumnTable<IProfileMonthlyRoster>({
        title: t(R.strings.employee),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'big-column',
        className: 'main-column',
        render: (_, record) => {
          return (
            <BaseAvatarProfile
              name={record?.name}
              src={record?.avatar}
              jobTitle={record?.workinfos?.jobTitle?.name ?? '-'}
            />
          )
        },
      }),
      convertColumnTable<IProfileMonthlyRoster>({
        title: `${t(R.strings.start_date)} - ${t(R.strings.end_date)}`,
        key: 'startAndEndDate',
        className: 'main-column',
        render: (_, record) => {
          return <BaseText children={record?.startAndEndDate ?? '-'} />
        },
      }),
      {
        title: t(R.strings.total_working),
        key: 'date-all',
        children: datesColumns,
      },
      {
        title: `${t(R.strings.total_working_day)} + ${t(
          R.strings.total_leave_day,
        )}`,
        key: 'total_working_day',
        children: [
          convertColumnTable<IProfileMonthlyRoster>({
            title: <HeadCustom title="F" tooltipText="F" />,
            key: 'F',
            classNameWidthColumnOverwrite: 'medium-column',
            className: 'child-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.F ?? '0'}
                  fontWeight="medium"
                  opacity="0.5"
                />
              )
            },
          }),
          convertColumnTable<IProfileMonthlyRoster>({
            title: <HeadCustom title="E" tooltipText="E" />,
            key: 'E',
            classNameWidthColumnOverwrite: 'medium-column',
            className: 'child-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.E ?? '0'}
                  fontWeight="medium"
                  opacity="0.5"
                />
              )
            },
          }),
          convertColumnTable<IProfileMonthlyRoster>({
            title: <HeadCustom title="L" tooltipText="L" />,
            key: 'L',
            classNameWidthColumnOverwrite: 'medium-column',
            className: 'child-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.L ?? '0'}
                  fontWeight="medium"
                  opacity="0.5"
                />
              )
            },
          }),
          convertColumnTable<IProfileMonthlyRoster>({
            title: <HeadCustom title="DO" tooltipText="DO" />,
            key: 'DO',
            classNameWidthColumnOverwrite: 'medium-column',
            className: 'child-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.DO ?? '0'}
                  fontWeight="medium"
                  opacity="0.5"
                />
              )
            },
          }),
          convertColumnTable<IProfileMonthlyRoster>({
            title: <HeadCustom title="PH" tooltipText="PH" />,
            key: 'PH',
            classNameWidthColumnOverwrite: 'medium-column',
            className: 'child-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.F ?? '0'}
                  fontWeight="medium"
                  opacity="0.5"
                />
              )
            },
          }),
          convertColumnTable<IProfileMonthlyRoster>({
            title: (
              <HeadCustom
                title={`Alc ${
                  filter?.year
                    ? +filter?.year - 1
                    : new Date().getFullYear() - 1
                }`}
                tooltipText="ALc"
              />
            ),

            key: 'AlcYear',
            classNameWidthColumnOverwrite: 'medium-column',
            className: 'important-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.AlcYear ?? '0'}
                  fontWeight="medium"
                  opacity="0.5"
                />
              )
            },
          }),
          convertColumnTable<IProfileMonthlyRoster>({
            title: <HeadCustom title="AL" tooltipText="AL" />,
            key: 'AL',
            classNameWidthColumnOverwrite: 'medium-column',
            className: 'child-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.AL ?? '0'}
                  fontWeight="medium"
                  opacity="0.5"
                />
              )
            },
          }),
          convertColumnTable<IProfileMonthlyRoster>({
            title: <HeadCustom title="HL" tooltipText="HL" />,
            key: 'HL',
            classNameWidthColumnOverwrite: 'medium-column',
            className: 'child-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.HL ?? '0'}
                  fontWeight="medium"
                  opacity="0.5"
                />
              )
            },
          }),
          convertColumnTable<IProfileMonthlyRoster>({
            title: <HeadCustom title="SL" tooltipText="SL" />,
            key: 'SL',
            classNameWidthColumnOverwrite: 'medium-column',
            className: 'child-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.SL ?? '0'}
                  fontWeight="medium"
                  opacity="0.5"
                />
              )
            },
          }),
          convertColumnTable<IProfileMonthlyRoster>({
            title: <HeadCustom title="PL" tooltipText="PL" />,
            key: 'PL',
            classNameWidthColumnOverwrite: 'medium-column',
            className: 'child-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.PL ?? '0'}
                  fontWeight="medium"
                  opacity="0.5"
                />
              )
            },
          }),
          convertColumnTable<IProfileMonthlyRoster>({
            title: <HeadCustom title="UL" tooltipText="UL" />,
            key: 'UL',
            classNameWidthColumnOverwrite: 'medium-column',
            className: 'child-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.UL ?? '0'}
                  fontWeight="medium"
                  opacity="0.5"
                />
              )
            },
          }),
          convertColumnTable<IProfileMonthlyRoster>({
            title: t(R.strings.actual_day),
            key: 'actualDate',
            className: 'child-column',
            classNameWidthColumnOverwrite: 'medium-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.actualDate ?? '0'}
                  fontWeight="medium"
                />
              )
            },
          }),
        ],
      },
      convertColumnTable<IProfileMonthlyRoster>({
        title: t(R.strings.salary_day),
        key: 'dateCalculateSalary',
        className: 'child-column',
        classNameWidthColumnOverwrite: 'medium-column',
        fixed: 'right',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.dateCalculateSalary ?? '0'}
              fontWeight="medium"
              colorText="collapseBackgroundColor"
              textAlign="right"
            />
          )
        },
      }),
    ]
  }, [t, filter])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  return {
    tableData: {
      data,
    },
    columns,
    loading,
    handleTableChange,
    pagination,
    handleExpand,
    expandedRowKeys,
    profiles,
    columnProfile,
  }
}
