import R from 'app/assets/R'
import { BaseChip } from 'app/components/common/BaseChip'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BasePagination } from 'app/components/common/BasePagination'
import { BaseRow } from 'app/components/common/BaseRow'
import { useModalHook } from './hook'
import * as S from './styles'
import { IPropsUpdatePermission } from './type'

export const DetailPermission = (props: IPropsUpdatePermission) => {
  const {
    visible,
    t,
    showModal,
    handleCancel,
    permissionAfterSelected,
    permissionSelected,
    currentPage,
    pageSize,
    onChangePage,
    onChangePageSize,
  } = useModalHook(props)

  return (
    <BaseModalManagement
      titleKeyI18n={R.strings.role_content_info_permission}
      content={
        <S.ContentWrapper direction="vertical" size={16}>
          <S.DataWrapper direction={'vertical'} size={16}>
            <S.ItemHeader
              gutter={[10, 10]}
              align={'middle'}
              justify={'space-between'}
              wrap={false}
            >
              <BaseCol>
                <BaseRow gutter={[15, 15]} wrap={false} align={'middle'}>
                  <S.IDWrapper>
                    <S.TextHead>{t(R.strings.role_content_modules)}</S.TextHead>
                  </S.IDWrapper>
                  <BaseCol>
                    <S.TextHead>
                      {t(R.strings.role_content_name_permission)}
                    </S.TextHead>
                  </BaseCol>
                </BaseRow>
              </BaseCol>
              <BaseCol />
            </S.ItemHeader>
            <S.ListWrapper direction={'vertical'} size={16}>
              {permissionAfterSelected?.map((per, idx) => {
                return (
                  <S.ItemData
                    key={idx}
                    gutter={[10, 10]}
                    align={'middle'}
                    justify={'space-between'}
                    wrap={false}
                  >
                    <BaseCol>
                      <BaseRow gutter={[15, 15]} wrap={false} align={'middle'}>
                        <S.IDWrapper>
                          <S.TextData>
                            {t(`${per?.moduleName ?? ''}`.replace(/-/g, '_'))}
                          </S.TextData>
                        </S.IDWrapper>
                        <BaseCol>
                          <S.TextData>{per.name}</S.TextData>
                        </BaseCol>
                      </BaseRow>
                    </BaseCol>
                  </S.ItemData>
                )
              })}
            </S.ListWrapper>
          </S.DataWrapper>
          <S.FooterWrapper
            justify={'space-between'}
            align={'middle'}
            gutter={[10, 10]}
          >
            <BaseCol>
              <BaseRow gutter={[5, 5]} align={'middle'}>
                <BaseCol>
                  <S.TextData>
                    {`${t(R.strings.role_content_total_permission_selected)}: `}
                  </S.TextData>
                </BaseCol>
                <BaseCol>
                  <S.TextTotalPermissionSelected>
                    {permissionSelected?.length}
                  </S.TextTotalPermissionSelected>
                </BaseCol>
              </BaseRow>
            </BaseCol>
            <BaseCol>
              <BasePagination
                defaultCurrent={currentPage}
                pageSize={pageSize}
                total={permissionSelected?.length ?? 0}
                onChange={(page: number, pageSize?: number) => {
                  if (page && pageSize) {
                    onChangePageSize(pageSize)
                    onChangePage(page)
                  }
                }}
                pageSizeOptions={[10, 20, 50, 100]}
              />
            </BaseCol>
          </S.FooterWrapper>
        </S.ContentWrapper>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      confirmKeyI18n={R.strings.confirm}
      handleSubmit={() => undefined}
      visible={visible}
      widthModal={1000}
      typeButtonOpen="choose"
      openModalComponent={
        <BaseCol>
          <S.ButtonMorePermission onClick={showModal}>
            <BaseChip index={0} name={`(+${props.permissionCount - 4})`} />
          </S.ButtonMorePermission>
        </BaseCol>
      }
    />
  )
}
