import { BaseForm } from "app/components/common/forms/BaseForm"
import { IProps } from "./type"
import { IJobTitleLevel } from "app/api/jobtitlelevel/model/job-title-level-management"
import { useRef, useState } from "react"
import { IRefModal } from "app/components/common/ModalComponent"

export const useHook = (props: IProps) => {
    const [form] = BaseForm.useForm()
    const modalRef = useRef<IRefModal>({})
    const [search, setSearch] = useState('')
    const [doctors, setDoctors] = useState<IJobTitleLevel[]>([]);

    // open modal
    const openModal = () => {
        modalRef.current?.open?.()
    }

    // on change setDoctors
    const onChangeDoctor = (value: IJobTitleLevel) => {
        // check if exist in doctors, remove it
        const index = doctors.findIndex(item => item?._id === value?._id)
        if (index !== -1) {
            const newDoctors = [...doctors]
            newDoctors.splice(index, 1)
            setDoctors(newDoctors)
        } else {
            setDoctors([...doctors, value])
        }
    }

    return {
        form,
        modalRef,
        openModal,
        search,
        setSearch,
        doctors,
        onChangeDoctor
    }
}