import { IUnitManagement } from 'app/api/unit/model/unit-management'
import R from 'app/assets/R'
import { StatusTableEnum } from 'app/common/enum'
import { IBaseTableCommon } from 'app/components/tables/common-table/model'

export enum ClinicTypeEnum {
  Express = 'express',
  Standard = 'standard',
  Core = 'core',
  Supercenter = 'super_center',
  Flagship = 'flagship',
}

export const ClinicTypeEnumList = [
  ClinicTypeEnum.Express,
  ClinicTypeEnum.Standard,
  ClinicTypeEnum.Core,
  ClinicTypeEnum.Supercenter,
  ClinicTypeEnum.Flagship,
]

export const DefineClinicType = {
  [ClinicTypeEnum.Express]: {
    keyI18n: R.strings.express,
  },
  [ClinicTypeEnum.Standard]: {
    keyI18n: R.strings.standard,
  },
  [ClinicTypeEnum.Core]: {
    keyI18n: R.strings.core,
  },
  [ClinicTypeEnum.Supercenter]: {
    keyI18n: R.strings.super_center,
  },
  [ClinicTypeEnum.Flagship]: {
    keyI18n: R.strings.flagship,
  },
}

export interface IGeneralUnitManagementTable extends IBaseTableCommon {
  ordinalNumber?: number
  code?: string
  name?: string
  unitGroupName?: string
  unitTypeId?: string
  unitTypeName?: string
  status?: StatusTableEnum
  description?: string
  children?: Array<IUnitManagement>
  _id?: string
  clinicType?: ClinicTypeEnum
}
