import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BORDER_RADIUS } from 'parkway-web-common'
import styled from 'styled-components'

export const ImplantAreaTop = ({ end, start }: { start: number; end: number}) => {
  return (
    <RootWrapper $start={start}>
      <ContentWrapper>
        <BridgeWrapper $start={start} $end={end}>
          <BridgeContent $start={start} $end={end}>
            <ValueCRWrapper>
              <BaseText
                children={`CR( R${start}; R${end})`}
                fontWeight="medium"
                fontSize="xxxs"
                colorText="backgroundColor"
              />
            </ValueCRWrapper>
          </BridgeContent>
        </BridgeWrapper>
      </ContentWrapper>
    </RootWrapper>
  )
}

const RootWrapper = styled.div<{ $start: number }>`
  position: absolute;
  bottom: -5px;
  left: ${props => 25 + props?.$start * 65}px;
`
const ContentWrapper = styled.div`
  position: relative;
`
const BridgeWrapper = styled(BaseRow)<{ $start: number; $end: number }>`
  width: ${props => 65 * (props?.$end - props?.$start)}px;
  height: 22px;
  border: 1.5px solid #fa9f34;
  border-bottom-left-radius: ${BORDER_RADIUS};
  border-bottom-right-radius: ${BORDER_RADIUS};
  border-top: 0px;
`
const BridgeContent = styled.div<{ $start: number; $end: number }>`
  position: absolute;
  bottom: -10px;
  width: ${props => 65 * (props?.$end - props?.$start)}px;
  justify-content: center;
  display: flex;
`
const ValueCRWrapper = styled(BaseRow)`
  padding: 4px 8px;
  background-color: #fa9f34;
  width: fit-content;
  border-radius: ${BORDER_RADIUS};
`
