import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import dayjs from 'dayjs'
import {
    FONT_SIZE,
    FONT_WEIGHT,
    PADDING
} from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IFilter } from '../../type'

interface IProps {
  filter?: IFilter
  onChangeFilter?: (values: IFilter) => void
}

export const FilterLayout = ({ filter, onChangeFilter }: IProps) => {
  const { t } = useTranslation()

  const onChangeYear = (_, value: string) => {
    onChangeFilter?.({ ...filter, year: value })
  }

  return (
    <BaseForm>
      <RootWrapper align={'middle'} gutter={[16, 8]}>
        <BaseCol xl={5}>
          <BaseFormItem hiddenLabel>
            <Picker
              $prefix={t(R.strings.year)}
              format={'YYYY'}
              placeholder={t(R.strings.select)}
              picker="year"
              value={filter?.year ? dayjs(`${filter?.year}-01-01`) : undefined}
              onChange={onChangeYear}
            />
          </BaseFormItem>
        </BaseCol>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
`
const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0;
`

const Picker = styled(BaseDatePicker)<{
  $prefix: string
  $prefixWidth?: number
}>`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  &:hover {
    border: 1px solid ${convertedVariables.neutralBlack9Color};
  }
  .ant-picker-input::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$prefixWidth ?? 60}px;
  }
`
