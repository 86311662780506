import {
  IFilterReport14,
  TypeReport14Enum,
} from 'app/api/report/model/report-14'
import { isEqual, omit } from 'lodash'
import moment from 'moment'
import { initialPagination } from 'parkway-web-common'
import { useState } from 'react'
import { useTableCaseHook } from './custom-hook/hook'

export const useTableHook = () => {
  const [filterResponse, setFilterResponse] = useState<IFilterReport14>({
    year: `${moment().year()}`,
    type: TypeReport14Enum.CASE,
  })

  const {
    columns,
    expandedRowKeys,
    exportExcel,
    fetch,
    handleExpand,
    handleTableChange,
    tableData,
  } = useTableCaseHook(filterResponse)

  const handleChangeFilter = (filter: IFilterReport14) => {
    setFilterResponse(filter)
    if (!isEqual(omit(filter, 'type'), omit(filterResponse, 'type'))) {
      fetch(initialPagination, filter)
    }
  }

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleChangeFilter,
    filterResponse,
    exportExcel,
  }
}
