import { DiscountTypeEnum } from 'app/api/marketing/discount-campaign/model'
import { DefineDiscountTypeEnum } from 'app/api/marketing/discount-voucher/constant'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import { BORDER_RADIUS, formatMoney, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ProfileServiceComponent } from '../../../../../components'
import { DefineStatusEnum, DefineStatusSetEnum } from '../../constant'
import { IItemImportBuyOneGetOne, StatusSetEnum } from '../../type'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { Loading } from 'app/components/common/Loading'

export const ListBuyOneGetOneLayout = ({
  data,
  isLoading,
}: {
  data: IItemImportBuyOneGetOne[]
  isLoading?: boolean
}) => {
  const { t } = useTranslation()
  return (
    <ContentListWrapper size={8}>
      {data?.length ? (
        data?.map((item, index) => {
          return (
            <CollapseWrapper
              items={[
                {
                  key: index,
                  label: (
                    <BaseText
                      children={item?.setName}
                      fontWeight="semibold"
                      fontSize="xs"
                    />
                  ),
                  extra: (
                    <BaseText
                      children={t(DefineStatusSetEnum[item?.status]?.text)}
                      fontWeight="medium"
                      style={{
                        color: DefineStatusSetEnum[item?.status]?.color,
                      }}
                    />
                  ),
                  style: {
                    alignItems: 'center',
                  },
                  children: (
                    <BaseSpace size={0}>
                      <BaseRow
                        gutter={[16, 8]}
                        align={'middle'}
                        className="header-content"
                        justify={'space-between'}
                      >
                        <BaseCol>
                          <BaseText
                            children={t(R.strings.buy_service)}
                            fontWeight="semibold"
                          />
                        </BaseCol>
                      </BaseRow>
                      <BaseSpace className="list-item-content-wrapper">
                        {item?.buyTreatment?.length ? (
                          item?.buyTreatment?.map((itm, idx) => {
                            return (
                              <BaseRow
                                key={idx}
                                align={'middle'}
                                gutter={[16, 8]}
                                style={{
                                  marginLeft: 0,
                                  marginRight: 0,
                                }}
                              >
                                <BaseCol xl={1}>
                                  <BaseText
                                    children={itm?.rowNumber ?? '-'}
                                    fontWeight="medium"
                                    textAlign="center"
                                  />
                                </BaseCol>

                                <BaseCol xl={18}>
                                  <ProfileServiceComponent
                                    itm={{
                                      ...itm,
                                      discountType: itm.dType,
                                      quantity: itm.amount,
                                    }}
                                  />
                                </BaseCol>

                                <BaseCol xl={5}>
                                  <BaseText
                                    children={t(
                                      DefineStatusEnum?.[itm?.status]?.text,
                                    )}
                                    fontWeight="medium"
                                    style={{
                                      color:
                                        DefineStatusEnum?.[itm?.status]?.color,
                                    }}
                                  />
                                </BaseCol>
                              </BaseRow>
                            )
                          })
                        ) : (
                          <BaseText
                            children={t(R.strings.empty_list)}
                            fontWeight="medium"
                            opacity="0.5"
                          />
                        )}
                      </BaseSpace>
                      <BaseRow
                        gutter={[16, 8]}
                        align={'middle'}
                        className="header-content"
                        justify={'space-between'}
                      >
                        <BaseCol>
                          <BaseText
                            children={t(R.strings.discount_service)}
                            fontWeight="semibold"
                          />
                        </BaseCol>
                      </BaseRow>
                      <BaseSpace className="list-item-content-wrapper">
                        {item?.discountTreatment?.length ? (
                          item?.discountTreatment?.map((itm, idx) => {
                            return (
                              <BaseRow
                                key={idx}
                                align={'middle'}
                                gutter={[16, 8]}
                                justify={'space-between'}
                                style={{
                                  marginLeft: 0,
                                  marginRight: 0,
                                }}
                              >
                                <BaseCol xl={1}>
                                  <BaseText
                                    children={itm?.rowNumber ?? '-'}
                                    fontWeight="medium"
                                    textAlign="center"
                                  />
                                </BaseCol>

                                <BaseCol xl={12}>
                                  <ProfileServiceComponent
                                    itm={{
                                      ...itm,
                                      discountType: itm.dType,
                                      quantity: itm.amount,
                                    }}
                                  />
                                </BaseCol>
                                <BaseCol xl={3}>
                                  <BaseText
                                    children={formatMoney(
                                      itm?.discount ?? '0',
                                      isEqual(
                                        itm?.dType,
                                        DiscountTypeEnum.Percent,
                                      )
                                        ? '%'
                                        : 'đ',
                                      undefined,
                                      1,
                                    )}
                                    fontWeight="medium"
                                  />
                                </BaseCol>
                                <BaseCol xl={3}>
                                  <BaseText
                                    children={t(
                                      DefineDiscountTypeEnum?.[itm?.dType]
                                        ?.keyI18n ?? '-',
                                    )}
                                    fontWeight="medium"
                                  />
                                </BaseCol>
                                <BaseCol xl={5}>
                                  <BaseText
                                    children={t(
                                      DefineStatusEnum?.[itm?.status]?.text,
                                    )}
                                    fontWeight="medium"
                                    style={{
                                      color:
                                        DefineStatusEnum?.[itm?.status]?.color,
                                    }}
                                  />
                                </BaseCol>
                              </BaseRow>
                            )
                          })
                        ) : (
                          <BaseText
                            children={t(R.strings.empty_list)}
                            fontWeight="medium"
                            opacity="0.5"
                          />
                        )}
                      </BaseSpace>
                    </BaseSpace>
                  ),
                },
              ]}
              expandIconPosition="right"
              key={index}
              defaultActiveKey={
                !isEqual(item?.status, StatusSetEnum.SUCCESS) ? [index] : []
              }
            />
          )
        })
      ) : (
        <BaseEmpty />
      )}

      {isLoading ? <Loading isAbsolute size="small" /> : null}
    </ContentListWrapper>
  )
}

const CollapseWrapper = styled(BaseCollapse)`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};

  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-content {
    border-top: 1px solid ${convertedVariables.neutralBlack9Color};
    background-color: ${convertedVariables.neutralBlack9Color};
    .ant-collapse-content-box {
      padding: 0px !important;
    }
  }

  .ant-collapse-header {
    background-color: var(--background-color);
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background-color: #f9fafb;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .list-item-content-wrapper {
    padding: ${PADDING.md};
    background-color: ${convertedVariables.backgroundColor};
  }
`

const ContentListWrapper = styled(BaseSpace)`
  overflow-y: auto;
  max-height: 400px;
  background-color: ${convertedVariables.neutralBlack9Color};
  padding: ${PADDING.xxs};
  border-radius: ${BORDER_RADIUS};
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`
