import { requestPatientWithoutPaid } from 'app/api/accounting/api/orthodontic-without-paid'
import { AccountingMessageCode } from 'app/api/accounting/constant'
import {
  IParamsGetPatientWithoutPaid,
  IPatientWithoutPaid,
} from 'app/api/accounting/model/orthodontic-without-paid'
import R from 'app/assets/R'
import { getDetailPatientUrl } from 'app/common/helpers'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { _DEV_, initPagination } from 'app/constant'
import { isEqual } from 'lodash'
import {
  DateUtil,
  FORMAT_DD_MM_YYYY,
  Pagination,
  ResponseType,
  formatMoney,
  initialPagination,
} from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOrthodonticWithoutPaid } from '../../context'
import { IDataTable } from './type'
import { DifficultyPackage } from 'app/containers/Accounting/PatientScheduleWithoutPaid/components/DifficultyPackage'

export const useHook = () => {
  const { t } = useTranslation()

  const [pagination, setPagination] = useState<Pagination | undefined>(
    initPagination,
  )

  const [total, setTotal] = useState(0)
  const [data, setData] = useState<IDataTable[]>([])
  const [loading, setLoading] = useState(false)

  const { filterResponse } = useOrthodonticWithoutPaid()

  const getData = async () => {
    setLoading(true)
    try {
      const params: IParamsGetPatientWithoutPaid = {
        page: pagination?.current,
        pageSize: pagination?.pageSize,
        doctorId: filterResponse?.doctorIds ?? [],
        locationCode: filterResponse?.locationIds ?? [],
      }

      const res: ResponseType<IPatientWithoutPaid[]> =
        await requestPatientWithoutPaid(params)

      if (
        res?.msgcode &&
        isEqual(
          AccountingMessageCode?.OrthodonticWithoutPaid?.getPatientWithoutPaid,
          res?.msgcode,
        )
      ) {
        const data = res?.data ?? []
        const metadata = res?.metadata

        const newData: IDataTable[] = data?.map((item, index) => {
          const idNumber =
            ((params.page ?? 1) - 1) * (params?.pageSize ?? 10) + (index + 1)
          return {
            key: index + 1,
            idNumber,
            assignedPhysician: item.doctorName ?? '',
            patientName: item?.patientName ?? '-',
            patientId: item?.patientId ?? '-',
            patientCode: item?.patientCode ?? '-',
            agvAppointment: item?.averageOrthodonticByMonth ?? 0,
            totalOrthodontic: item?.totalOrthodontic ?? 0,
            month: item?.totalMonth ?? 0,
            clinic: item?.locationName ?? '-',
            difficulty: item?.difficulty,
            startDate: item?.recordStartDate
              ? DateUtil.formatDate(item?.recordStartDate, FORMAT_DD_MM_YYYY)
              : '-',
            endDate: item?.recordEndDate
              ? DateUtil.formatDate(item?.recordEndDate, FORMAT_DD_MM_YYYY)
              : t(R.strings.unfinished),
          }
        })

        setData(newData)
        setTotal(metadata?.totalItem ?? 0)
      }
    } catch (error) {
      _DEV_ && console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setPagination(prev => ({
      ...prev,
      current: initialPagination.current,
    }))
  }, [filterResponse?.doctorIds, filterResponse?.locationIds])

  useEffect(() => {
    const timer = setTimeout(() => {
      getData()
    }, 500)

    return () => clearTimeout(timer)
  }, [pagination, filterResponse?.doctorIds, filterResponse?.locationIds])

  const handleTableChange = (pagination?: Pagination) => {
    setPagination(pagination)
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<IDataTable>({
        title: t(R.strings.patient_name),
        key: 'patientName',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.patientName ?? '-'}
            type="OtherLink"
            hrefOtherLink={getDetailPatientUrl(record.patientId)}
          />
        ),
        fixed: 'left',
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.assigned_physician),
        key: 'assignedPhysician',
        classNameWidthColumnOverwrite: 'big-column',
        fixed: 'left',
        render: (_, record) => (
          <RenderValueTable
            value={record?.assignedPhysician ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.difficulty_level_of_the_shift),
        key: 'difficulty',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <DifficultyPackage level={record?.difficulty ?? 0} />
        ),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.treatment_month),
        key: 'month',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={formatMoney(record?.month ?? '0', '')}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.total_orthodontic),
        key: 'totalOrthodontic',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={formatMoney(record?.totalOrthodontic ?? '0', '')}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.agv_appointment_every_month),
        key: 'agvAppointment',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={formatMoney(record?.agvAppointment ?? '0', '', undefined, 2)}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.shift_start_time),
        key: 'startDate',
        render: (_, record) => (
          <RenderValueTable
            value={record?.startDate ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.shift_end_time),
        key: 'endDate',
        render: (_, record) => (
          <RenderValueTable
            value={record?.endDate ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.clinic),
        key: 'clinic',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.clinic ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
    ]
  }, [t])

  return {
    columns,
    tableData: {
      pagination: {
        ...pagination,
        total,
      },
      data,
      loading,
    },
    handleTableChange,
  }
}
