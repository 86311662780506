import styled from 'styled-components'

export const ToolbarCurrentTime = styled.div`
  width: 100% !important;
  text-align: left !important;
  padding-left: 80px !important;
`

export const ToolbarCurrentTimeDayName = styled.span`
  width: 50px;
  height: 22px;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  gap: 10px;
  opacity: 0.7px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: capitalize;
`

export const ToolbarCurrentTimeDayCurrent = styled.span`
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  width: 59px;
  height: 22px;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  gap: 10px;
  background: var(--states-green-light-color);
`

export const ToolbarCurrentTimeDay = styled.span`
  width: 44px;
  height: 4px;
  padding: 8px;
  gap: 10px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
`

export const ToolbarWrapper = styled.div`

`
