/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestExportPatientDebtContract } from 'app/api/accounting'
import R from 'app/assets/R'
import { ENV_CONFIG } from 'app/common/config'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IParamsHookReport } from 'app/components/tables/BaseTableReport/type'
import { notificationController } from 'app/controllers/notification-controller'
import { Pagination } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ALink } from './styles'
import { IGeneralDebtContractTable } from './common-model'
import { PaymentMethodTranslateEnum } from 'app/components/tables/BaseTableManagement/FilterDeptTable/type'
import { downloadNotificationController } from 'app/controllers/download-notification-controller'
import { NOTIFICATION_TYPE } from 'app/common/constants'
import { handlePermission } from '../common'

export const useCommonHook = ({
  expandedRowKeys,
  fetchLoading,
}: {
  expandedRowKeys: number[]
  fetchLoading: (value: boolean) => void
  fetch: (pagination: Pagination, params?: IParamsHookReport) => void
  handleExpand: (isExpanded: boolean, key?: number) => void
  currentFilter?: IParamsHookReport
}) => {
  const { t } = useTranslation()

  const onClickExport = async params => {
    fetchLoading(true)
    try {
      if (!params?.startDate && !params?.endDate) {
        notificationController.error({
          message: t(R.strings.select_range_date_required),
        })
      } else {
        await requestExportPatientDebtContract(
          {
            treatments: params?.treatments,
            params: {
              keyword: params?.search,
              startDate: params?.startDate,
              endDate: params?.endDate,
              paidTypes: params?.paidType?.join(','),
              location: params?.location,
              paymentMethod: params?.paymentMethod,
            },
          },
        )
        await handleNotification()
      }
    } catch (error) {
      // console.log({ error })
      notificationController.error({
        message: t(R.strings.error),
      })
    }
    fetchLoading(false)
  }

  const handleNotification = async () => {
    const permissionNotification = await handlePermission()
    if (
      permissionNotification?.state === 'granted' &&
      permissionNotification?.status === true
    ) {
      return downloadNotificationController.waiting({
        duration: 0,
        key: NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT_CONTRACT,
        message: t(R.strings.manage_customer_debt_contract),
        description: t(R.strings.download_waiting),
        cancel: t(R.strings.cancel),
      })
    }

    if (!permissionNotification?.status)
      notificationController.warning({
        message: t(R.strings.download_warning),
        description: t(R.strings.download_warning_description),
      })

    notificationController.success({
      message: t(R.strings.success),
      description: t(R.strings.export_excel_sendmail_message),
    })
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_treatment_date),
        key: 'treatments',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.treatments?.treatmentDate}
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_patient_code),
        key: 'patientCode',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record.patientCode} type="Main" />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_patient_name),
        key: 'patientName',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <ALink
            onClick={e => {
              e.preventDefault()
              window.open(
                ENV_CONFIG.OLD_WEBSITE_ENDPOINT +
                  '/patients/details/' +
                  record?.patientId,
              )
            }}
          >
            {record.patientName}
          </ALink>
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_clinic_name),
        key: 'location',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record.location} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_treatment),
        key: 'treatments',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          return (
            <RenderValueTable value={record?.treatments?.name} type="Base" />
          )
        },
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_treatment_total),
        key: 'treatments',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.treatments?.treatmentPrice}
            type="Money"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_payment_total),
        key: 'totalNetAmount',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record.totalNetAmount} type="Money" />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_deposit_total),
        key: 'deposit',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record.deposit} type="Money" />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_paid_total),
        key: 'totalPaid',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record.totalPaid} type="Money" />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_remain),
        key: 'manage_customer_debt_refund_total',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={
              record?.allTotal?.totalNetAmount
                ? Number(record?.allTotal?.totalNetAmount) -
                  Number(record?.allTotal?.totalPaid) -
                  Number(record?.allTotal?.totalRefund)
                : 0
            }
            type="Money"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_refund_total),
        key: 'totalRefund',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record.totalRefund} type="Money" />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_payment_type),
        key: 'paidType',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={t(String(record?.paidType))} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.payment_method),
        key: 'treatments',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={t(
              PaymentMethodTranslateEnum[
                String(record?.treatments?.paymentMethod)
              ],
            )}
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_tip_start_date),
        key: 'dateStartProcedure',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.dateStartProcedure} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_tip_end_name),
        key: 'endProcedureTreatmentName',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.endProcedureTreatmentName}
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_tip_end_date),
        key: 'dateEndProcedure',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          return (
            record?.endProcedureTreatmentName &&
            record.dateEndProcedure && (
              <RenderValueTable value={record.dateEndProcedure} type="Base" />
            )
          )
        },
      }),
    ]
  }, [t, expandedRowKeys])

  return {
    onClickExport,
    columns,
  }
}
