import { IWorkInfoUserInfo } from 'app/api/profile/model/profile'
import R from 'app/assets/R'
import { BaseChip } from 'app/components/common/BaseChip'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { notificationController } from 'app/controllers/notification-controller'
import { getDegreeClassification } from 'app/utils/degreeClassification.util'
import { DateUtil, normalizePhone } from 'parkway-web-common'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { useModalHook } from './hook'
import * as S from './styles'
import { convertStatusToFilterStatus } from 'app/common/helpers'


const ItemWorkInfo = ({ item }: { item: IWorkInfoUserInfo }) => {
  const { t } = useTranslation()
  return (
    <BaseSpace direction="vertical" size={15} style={{ width: '100%' }}>
      <BaseCol className="name-work-info">{item?.jobtitle?.name}</BaseCol>
      <BaseRow gutter={[20, 20]} align={'middle'} justify={'space-between'}>
        <BaseCol xl={6}>
          <BaseSpace direction="vertical" size={10}>
            <div className="label-text value-work-info">
              {t(R.strings.department)}
            </div>
            <BaseCol className="value-work-info">
              {item.unit?.name ?? '-'}
            </BaseCol>
          </BaseSpace>
        </BaseCol>

        <BaseCol xl={6}>
          <BaseSpace direction="vertical" size={10}>
            <div className="label-text value-work-info">
              {t(R.strings.start_date)}
            </div>
            <BaseCol className="value-work-info">
              {item.startdate ? DateUtil.formatDDMMYYY(item?.startdate) : '-'}
            </BaseCol>
          </BaseSpace>
        </BaseCol>

        <BaseCol xl={6}>
          <BaseSpace direction="vertical" size={10}>
            <div className="label-text value-work-info">
              {t(R.strings.end_date)}
            </div>
            <BaseCol className="value-work-info">
              {item.enddate ? DateUtil.formatDDMMYYY(item?.enddate) : '-'}
            </BaseCol>
          </BaseSpace>
        </BaseCol>

        <BaseCol xl={6}>
          <BaseSpace direction="vertical" size={10}>
            <div className="label-text value-work-info">
              {t(R.strings.status)}
            </div>
            <BaseCol className="value-work-info text-status">
              {item.status
                ? t(convertStatusToFilterStatus(item?.status))
                : '-'}
            </BaseCol>
          </BaseSpace>
        </BaseCol>
      </BaseRow>
    </BaseSpace>
  )
}

const ProfileContainer = () => {
  const { t, profile, skills, mainWorkInfos, secondsWorkInfos } = useModalHook()

  return (
    <S.BaseActionWrapper>
      <HeaderPage titleI18nKey={R.strings.profile} />
      <S.BaseFormWrapper layout="vertical" requiredMark="optional">
        <S.SectionBaseWrapper>
          <BaseSpace direction="vertical" size={10}>
            <BaseRow gutter={20} align={'top'} justify={'start'}>
              <BaseCol>
                <S.AvatarProfile
                  src={profile?.avatar}
                  alt={`${profile?.firstname ?? ''} ${
                    profile?.lastname ?? ''
                  } `}
                />
              </BaseCol>
              <BaseCol>
                <BaseSpace direction="vertical" size={5}>
                  <S.TextNameProfile>{`${profile?.firstname ?? ''} ${
                    profile?.lastname ?? ''
                  } `}</S.TextNameProfile>
                  <S.TextJobTitleProfile>
                    {profile?.jobTitleName ?? '-'}
                  </S.TextJobTitleProfile>
                  <S.StatusChip>
                    {t(convertStatusToFilterStatus(profile?.status))}
                  </S.StatusChip>
                </BaseSpace>
              </BaseCol>
            </BaseRow>
            <BaseCol />
            <BaseCol />
            <BaseRow justify={'space-between'} align={'middle'} gutter={10}>
              <BaseCol xl={12}>
                <S.FormItem label={t(R.strings.full_name)}>
                  <S.TextValue>{`${profile?.firstname ?? ''} ${
                    profile?.lastname ?? ''
                  } `}</S.TextValue>
                </S.FormItem>
              </BaseCol>
              <BaseCol xl={12}>
                <S.FormItem label={t(R.strings.profile_content_job_title)}>
                  <S.TextValue>{profile?.jobTitleName}</S.TextValue>
                </S.FormItem>
              </BaseCol>
            </BaseRow>
            <BaseRow justify={'space-between'} align={'middle'} gutter={10}>
              <BaseCol xl={12}>
                <S.FormItem label={t(R.strings.employee_id)}>
                  <BaseRow gutter={[10, 10]} align={'middle'}>
                    <BaseCol>
                      <S.TextValue>{profile?.employeeid}</S.TextValue>
                    </BaseCol>
                    <CopyToClipboard
                      text={profile?.employeeid}
                      onCopy={() => {
                        notificationController.success({
                          message: t(R.strings.copy_success),
                        })
                      }}
                    >
                      <S.IconCopy
                        src={R.images.ic_copy}
                        width={20}
                        height={20}
                        preview={false}
                      />
                    </CopyToClipboard>
                  </BaseRow>
                </S.FormItem>
              </BaseCol>
              <BaseCol xl={12}>
                <S.FormItem label={t(R.strings.phone)}>
                  <S.TextValue>
                    {profile?.mobile ? normalizePhone(profile?.mobile) : '-'}
                  </S.TextValue>
                </S.FormItem>
              </BaseCol>
            </BaseRow>

            <BaseCol xl={24}>
              <S.FormItem label={t(R.strings.email)}>
                <S.TextValue>{profile?.email}</S.TextValue>
              </S.FormItem>
            </BaseCol>
            <S.Line />

            <S.TitleText>{t(R.strings.skill_title)}</S.TitleText>

            {skills?.map((skill, index) => {
              return (
                <BaseSpace direction="vertical" size={15} key={index}>
                  <S.TitleTypeSkill>{skill?.nameType}</S.TitleTypeSkill>
                  <BaseRow gutter={[20, 20]}>
                    {skill?.children?.map((itm, idx) => {
                      return (
                        <BaseCol key={idx}>
                          <BaseChip
                            color={itm?.levelSkill?.color}
                            index={idx}
                            name={itm?.skill?.name ?? '-'}
                            prefix={itm?.levelSkill?.name}
                          />
                        </BaseCol>
                      )
                    })}
                  </BaseRow>
                </BaseSpace>
              )
            })}
          </BaseSpace>
        </S.SectionBaseWrapper>

        <S.SectionBaseWrapper title={t(R.strings.degree)}>
          <S.DegreeWrapper direction="vertical" size={10} split={<S.Line />}>
            {profile?.degrees?.map((item, index) => {
              return (
                <BaseRow key={index} align={'middle'} justify={'space-between'}>
                  <BaseSpace direction="vertical" size={10}>
                    <S.TextDegreeName>{item?.degree?.name}</S.TextDegreeName>
                    <BaseRow gutter={[20, 20]}>
                      <BaseCol>
                        <S.TextStartDateDegree>
                          {`${t(
                            R.strings.degree_content_start,
                          )}: ${DateUtil.formatDDMMYYY(item?.start ?? '')}`}
                        </S.TextStartDateDegree>
                      </BaseCol>
                      {item?.end ? (
                        <BaseCol>
                          <S.TextStartDateDegree>
                            {`${t(
                              R.strings.degree_content_end,
                            )}: ${DateUtil.formatDDMMYYY(item?.end ?? '')}`}
                          </S.TextStartDateDegree>
                        </BaseCol>
                      ) : null}
                      {item?.degreeClassification ? (
                        <BaseCol>
                          <BaseRow gutter={[10, 10]}>
                            <BaseCol>
                              <S.TextStartDateDegree>
                                {`${t(
                                  R.strings.degree_field_degree_classification,
                                )}:`}
                              </S.TextStartDateDegree>
                            </BaseCol>
                            <BaseCol>
                              <span
                                className={`tag ${item?.degreeClassification?.key}`}
                              >
                                {getDegreeClassification(
                                  item?.degreeClassification?.key,
                                )}
                              </span>
                            </BaseCol>
                          </BaseRow>
                        </BaseCol>
                      ) : null}
                    </BaseRow>
                  </BaseSpace>

                  <S.TextSeeClassification>
                    {t(R.strings.degree_content_see_degree)}
                  </S.TextSeeClassification>
                </BaseRow>
              )
            })}
          </S.DegreeWrapper>
        </S.SectionBaseWrapper>

        <S.SectionBaseWrapper
          title={t(R.strings.profile_content_job_title_main_column)}
        >
          <BaseSpace direction="vertical" size={20} style={{ width: '100%' }}>
            {mainWorkInfos?.listInActive?.length ? (
              <S.ItemWorkInfo
                gutter={[20, 20]}
                align={'middle'}
                $isDisable
                $count={mainWorkInfos?.listInActive?.length}
              >
                <BaseCol style={{ height: '100%' }}>
                  <BaseSpace
                    direction="vertical"
                    size={10}
                    style={{ alignItems: 'center', height: '100%' }}
                  >
                    <div className="dot" />
                    <div className="line-first" />
                  </BaseSpace>
                </BaseCol>
                <BaseCol xl={22}>
                  <BaseSpace
                    direction="vertical"
                    size={10}
                    style={{ width: '100%' }}
                  >
                    {mainWorkInfos?.listInActive?.map((item, index) => {
                      return <ItemWorkInfo item={item} key={index} />
                    })}
                  </BaseSpace>
                </BaseCol>
              </S.ItemWorkInfo>
            ) : null}
            {mainWorkInfos?.listActive?.length ? (
              <S.ItemWorkInfo
                gutter={[20, 20]}
                align={'middle'}
                $count={mainWorkInfos?.listActive?.length}
              >
                <BaseCol style={{ height: '100%' }}>
                  <BaseSpace
                    direction="vertical"
                    size={10}
                    style={{ alignItems: 'center', height: '100%' }}
                  >
                    <div className="dot" />
                    <div className="line-first" />
                  </BaseSpace>
                </BaseCol>
                <BaseCol xl={22}>
                  <BaseSpace
                    direction="vertical"
                    size={10}
                    style={{ width: '100%' }}
                  >
                    {mainWorkInfos?.listActive?.map((item, index) => {
                      return <ItemWorkInfo item={item} key={index} />
                    })}
                  </BaseSpace>
                </BaseCol>
              </S.ItemWorkInfo>
            ) : null}
          </BaseSpace>
        </S.SectionBaseWrapper>

        <S.SectionBaseWrapper
          title={t(R.strings.profile_content_job_title_secondary_column)}
        >
          <BaseSpace direction="vertical" size={20} style={{ width: '100%' }}>
            {secondsWorkInfos?.listInActive?.length ? (
              <S.ItemWorkInfo
                gutter={[20, 20]}
                align={'top'}
                $isDisable
                $count={secondsWorkInfos?.listInActive?.length}
              >
                <BaseCol style={{ height: '100%' }}>
                  <BaseSpace
                    direction="vertical"
                    size={20}
                    style={{ alignItems: 'center', height: '100%' }}
                  >
                    <div className="dot" />
                    <div className="line-first" />
                  </BaseSpace>
                </BaseCol>
                <BaseCol xl={22}>
                  <BaseSpace
                    direction="vertical"
                    size={10}
                    style={{ width: '100%' }}
                  >
                    {secondsWorkInfos?.listInActive?.map((item, index) => {
                      return <ItemWorkInfo item={item} key={index} />
                    })}
                  </BaseSpace>
                </BaseCol>
              </S.ItemWorkInfo>
            ) : null}
            {secondsWorkInfos?.listActive?.length ? (
              <S.ItemWorkInfo
                gutter={[20, 20]}
                align={'top'}
                $count={secondsWorkInfos?.listActive?.length}
              >
                <BaseCol>
                  <BaseSpace
                    direction="vertical"
                    size={20}
                    style={{ alignItems: 'center', height: '100%' }}
                  >
                    <div className="dot" />
                    <div className="line-first" />
                  </BaseSpace>
                </BaseCol>
                <BaseCol xl={22}>
                  <BaseSpace
                    direction="vertical"
                    size={10}
                    style={{ width: '100%' }}
                  >
                    {secondsWorkInfos?.listActive?.map((item, index) => {
                      return <ItemWorkInfo item={item} key={index} />
                    })}
                  </BaseSpace>
                </BaseCol>
              </S.ItemWorkInfo>
            ) : null}
          </BaseSpace>
        </S.SectionBaseWrapper>
      </S.BaseFormWrapper>
    </S.BaseActionWrapper>
  )
}

export default ProfileContainer
