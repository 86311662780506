// Message code define
export const MessageCode = {
  successGetData: '20000',
  successPutData: '20000',
  successDeleteData: '20000',
  successPostData: '20001',
}

// Endpoint
export const EndPoint = {
  ProviderManagement: {
    request: 'inventory/v1/provider',
  },
  MaterialManagement: {
    request: 'inventory/v1/material',
  },
  MaterialCategoryManagement: {
    request: 'inventory/v1/material-category',
  },
}
