import R from 'app/assets/R'
import {
  GenderEnumKeyI18n,
  PaymentMethodEnum,
  PaymentOptionEnum,
  PaymentOptionPurchaseProductServiceEnum,
  YesNoEnum,
} from 'app/common/enum'
import {
  getArray,
  randomMoney,
} from 'app/components/tables/common-table/constant'
import { getRandomUuid } from 'parkway-web-common'
import {
  StatusAppointmentSessionTreatmentPlanEnum,
  StatusServiceTreatmentScheduleEnum,
  StatusTreatmentPlanEnum,
} from './enum'
import { IProfilePatient } from './type'
import {
  ICompanyGetInvoice,
  IOrderDebtPayment,
  IPaymentMethodDebtPayment,
} from './type/debt-payment'
import {
  IFolderImageChild,
  IFolderImageParent,
  IImagePatient,
} from './type/image-patient'
import { IInfoPatient } from './type/info-patient'
import {
  IDiscountProfilePatient,
  IPurchaseProductService,
  ITotalInvoicePurchaseProductService,
} from './type/purchase-product-service'
import {
  IAppointmentSession,
  IInfoPatientTreatmentSchedule,
  ITreatmentInAppointmentSession,
} from './type/treatment-schedule'
import { ISupplies } from 'app/containers/Accounting/TreatmentManagement/model/supplies'

export const patientInfoExample: IInfoPatient = {
  msbn: '123456789',
  fullName: 'John Doe',
  gender: GenderEnumKeyI18n.MALE,
  birthday: '1990-05-15',
  phone: '+1234567890',
  email: 'john.doe@example.com',
  address: '228 Trần Hưng Đạo, Nguyễn Cư Trinh, Quận 1',
  sourceCustomer: 'Referral',
  noteImportant: 'Allergic to penicillin',
  updateBy: {
    username: 'doctor1',
    fullName: 'Lê Thị Kim Hoàn',
    role: 'Doctor',
  },
}

const discount: IDiscountProfilePatient = {
  code: 'DISCOUNT10',
  value: 10,
  name: 'Discount code',
}

const totalInvoice: ITotalInvoicePurchaseProductService = {
  discount: discount,
  alternativeApplicationProgram: [''],
  paymentOption: PaymentOptionPurchaseProductServiceEnum.OneTimePayment,
  paymentMethods: [
    { method: PaymentMethodEnum.Bank, amount: 30 },
    { method: PaymentMethodEnum.Cash, amount: 60 },
  ],
  company: {
    name: 'Công Ty Cổ Phần Y Tế Parkway',
    address: '228 Trần Hưng Đạo',
    email: 'phamvankhanh962001@gmail.com',
    mst: '123456789',
  },
  customerPayment: 90,
  issuesInvoice: YesNoEnum.No,
}

const purchaseProductService: IPurchaseProductService = {
  productServices: [
    {
      id: getRandomUuid(),
      discount: discount,
      totalPayment: randomMoney(), // Assuming total payment after discount
      quantity: 2,
      pricePerItem: 50,
      name: 'Product A',
      type: 'product', // or 'service'
      uom: 'unit', // unit of measurement
    },
    {
      id: getRandomUuid(),
      discount: discount,
      totalPayment: randomMoney(), // Assuming total payment after discount
      quantity: 1,
      pricePerItem: 50,
      name: 'Product B',
      type: 'service', // or 'service'
      uom: 'unit1', // unit of measurement
      isProductDoesNotSupportInstallmentPayment: true,
    },
    {
      id: getRandomUuid(),
      discount: discount,
      totalPayment: randomMoney(), // Assuming total payment after discount
      quantity: 4,
      pricePerItem: 50,
      name: 'Product C',
      type: 'service', // or 'service'
      uom: 'unit1', // unit of measurement
      isProductDoesNotSupportInstallmentPayment: true,
    },
  ],
  totalInvoice: totalInvoice,
}

const folderImageChild1: IFolderImageChild = {
  name: 'Subfolder 1',
  urls: [],
}

const folderImageChild2: IFolderImageChild = {
  name: 'Subfolder 2',
  urls: [],
}

// Sample data for IFolderImageParent interface
const folderImageParent: IFolderImageParent = {
  name: 'Main Folder',
  child: [folderImageChild1, folderImageChild2],
}

// Sample data for IImagePatient interface
const imagePatient: IImagePatient = {
  parentFolder: [folderImageParent],
}

const paymentMethod1: IPaymentMethodDebtPayment = {
  method: PaymentMethodEnum.Bank,
  amount: 100,
}

const paymentMethod2: IPaymentMethodDebtPayment = {
  method: PaymentMethodEnum.Cash,
  amount: 200,
}

const company: ICompanyGetInvoice = {
  name: 'ABC Company',
  address: '228 Trần Hưng Đạo, Nguyễn Cư Trinh, Quận 1, TP.HCM',
  mst: '123456789',
  email: 'info@abccompany.com',
}

// Sample data for IOrderDebtPayment interface
const orderDebtPayment: IOrderDebtPayment = {
  orderId: '123456',
  totalNeedPayment: 500,
  amountPayment: 300,
  paymentOption: PaymentOptionEnum.CompleteSettlement,
  paymentMethods: [paymentMethod1, paymentMethod2],
  issueInvoice: YesNoEnum.Yes,
  customerPayment: 300,
  company: company,
}

export const PaymentMethodEnumDefine = {
  [PaymentMethodEnum.Bank]: {
    i18nKey: R.strings.transfer,
  },
  [PaymentMethodEnum.Cash]: {
    i18nKey: R.strings.cash,
  },
  [PaymentMethodEnum.EWallet]: {
    i18nKey: R.strings.e_wallet,
  },
}

export const ListMaterials: ISupplies[] = [
  { _id: getRandomUuid(), quantity: 2, name: 'Kìm nhổ răng' },
  { _id: getRandomUuid(), quantity: 2, name: 'Khăn Lau' },
  { _id: getRandomUuid(), quantity: 2, name: 'Tăm nước' },
  { _id: getRandomUuid(), quantity: 2, name: 'Khay băng' },
  { _id: getRandomUuid(), quantity: 2, name: 'Khay A2' },
  { _id: getRandomUuid(), quantity: 2, name: 'Khay A3' },
  { _id: getRandomUuid(), quantity: 2, name: 'Khay A4' },
  { _id: getRandomUuid(), quantity: 2, name: 'Khay A6' },
  { _id: getRandomUuid(), quantity: 2, name: 'Kẹp nhỏ A1' },
  { _id: getRandomUuid(), quantity: 2, name: 'Kẹp nhỏ A2' },
  { _id: getRandomUuid(), quantity: 2, name: 'Kẹp nhỏ A3' },
]

const treatmentInAppointmentSession: ITreatmentInAppointmentSession[] = [
  {
    id: getRandomUuid(),
    name: 'Thủ thuật nhổ răng chuyên sâu',
    materials: ListMaterials?.slice(0, 3),
    paymentInfo: {
      unitPrice: 50,
      discountAmount: 10,
      totalAmount: 40,
      paid: 40,
    },
    doctor: {
      name: 'Hồ Thị Thanh Thuỷ',
      levelName: 'Bác sĩ',
      jobTitle: 'Bác sĩ',
    },
    nurses: [
      {
        name: 'Nurse 1',
        levelName: 'Nurse',
        jobTitle: 'Nurse',
      },
      {
        name: 'Nurse 2',
        levelName: 'Nurse',
        jobTitle: 'Nurse',
      },
    ],
  },
  {
    id: getRandomUuid(),
    name: 'Treatment 2',
    materials: ListMaterials?.slice(0, 3),
    paymentInfo: {
      unitPrice: 60,
      discountAmount: 15,
      totalAmount: 45,
      paid: 45,
    },
    doctor: {
      name: 'Hồ Thị Thanh Thuỷ',
      levelName: 'Bác sĩ',
      jobTitle: 'Bác sĩ',
    },
    nurses: [
      {
        name: 'Nurse 1',
        levelName: 'Nurse',
        jobTitle: 'Nurse',
      },
      {
        name: 'Nurse 2',
        levelName: 'Nurse',
        jobTitle: 'Nurse',
      },
    ],
  },
  // Thêm các phiên điều trị khác nếu cần
]

export const dataAppointmentSessions: IAppointmentSession[] = [
  {
    id: getRandomUuid(),
    name: 'Buổi hẹn 1',
    status: StatusAppointmentSessionTreatmentPlanEnum.Occurred,
    paymentInformation: {
      totalAmount: 300,
      paidAmount: 300,
      paymentMethod: PaymentMethodEnum.Bank,
    },
    timeStartTreatment: '2024-04-05T09:00:00',
    treatmentInformation: {
      symptomsDiagnosis:
        'CVR+ĐB. Gắn bộ MCKRot #22. Gắn 4M R7 #22. Thay dây Niti',
      treatmentDirection:
        'CVR+ĐB. Gắn bộ MCKRot #22. Gắn 4M R7 #22. Thay dây Niti',
      note: 'Khách hàng yêu cầu dời ngày khám sang ngày 08/08/2024. Khách hàng yêu cầu dời ngày khám sang ngày 08/08/2024. Khách hàng yêu cầu dời ngày khám ',
    },
    treatmentInAppointmentSession,
  },
  {
    id: getRandomUuid(),
    name: 'Buổi hẹn 2',
    status: StatusAppointmentSessionTreatmentPlanEnum.InTreatment,
    paymentInformation: {
      totalAmount: 500,
      paidAmount: 500,
      paymentMethod: PaymentMethodEnum.Cash,
    },
    timeStartTreatment: '2024-04-07T14:00:00',
    treatmentInformation: {
      symptomsDiagnosis:
        'CVR+ĐB. Gắn bộ MCKRot #22. Gắn 4M R7 #22. Thay dây Niti',
      treatmentDirection:
        'CVR+ĐB. Gắn bộ MCKRot #22. Gắn 4M R7 #22. Thay dây Niti',
      note: 'Khách hàng yêu cầu dời ngày khám sang ngày 08/08/2024. Khách hàng yêu cầu dời ngày khám sang ngày 08/08/2024. Khách hàng yêu cầu dời ngày khám ',
    },
    treatmentInAppointmentSession,
  },
]

const infoTreatmentSchedule: IInfoPatientTreatmentSchedule = {
  id: getRandomUuid(),
  totalInfoPayment: {
    totalAmountService: 1000,
    totalPayment: 1200,
    paidAmount: 800,
    refundAmount: 0,
    remainingAmount: 400,
  },
  treatmentSchedule: [
    {
      id: getRandomUuid(),
      treatmentName: 'Gói Niềng răng invisalign',
      startDateTreatment: '2024-04-05',
      endDateTreatment: '2024-04-10',
      clinicName: '228 Trần Hưng Đạo, Nguyễn Cư Trinh, Quận 1, TP.HCM',
      status: StatusServiceTreatmentScheduleEnum.InTreatment,
      infoPayment: {
        totalAmountService: 800,
        totalPayment: 800,
        paidAmount: 800,
        refundAmount: 0,
        remainingAmount: 0,
      },
      treatmentInformation: {
        treatingPhysician: {
          name: 'Hồ Thị Thanh Thuỷ',
          levelName: 'Bác sĩ',
          jobTitle: 'Bác sĩ',
        },
        assignedPhysician: {
          name: 'Lê Thị Kim Hoàn',
          levelName: 'Bác sĩ',
          jobTitle: 'Bác sĩ',
        },
        treatmentPlanEnum: StatusTreatmentPlanEnum.Approved,
      },
      treatmentPhase: [
        {
          name: 'Giai đoạn: Chốt ca và phác đồ chi tiết',
          startDate: '2024-04-05',
          endDate: '2024-04-07',
          status: StatusServiceTreatmentScheduleEnum.Completed,
          phaseApprover: {
            name: 'Lê Thị Kim Hoàn',
            levelName: 'Bác sĩ',
            jobTitle: 'Bác sĩ',
          },
          phaseAttendingPhysician: {
            name: 'Hồ Thị Thanh Thuỷ',
            levelName: 'Bác sĩ',
            jobTitle: 'Bác sĩ',
          },
          appointmentSession: dataAppointmentSessions,
          id: getRandomUuid(),
        },
        {
          name: 'Giai đoạn 1: Gắn mắc cài và aligning sơ khởi',
          startDate: '2024-04-05',
          endDate: '2024-04-07',
          status: StatusServiceTreatmentScheduleEnum.Completed,
          phaseApprover: {
            name: 'Lê Thị Kim Hoàn',
            levelName: 'Bác sĩ',
            jobTitle: 'Bác sĩ',
          },
          phaseAttendingPhysician: {
            name: 'Hồ Thị Thanh Thuỷ',
            levelName: 'Bác sĩ',
            jobTitle: 'Bác sĩ',
          },
          appointmentSession: dataAppointmentSessions,
          id: getRandomUuid(),
        },
        {
          name: 'Giai đoạn 2: Giai đoạn aligning và leveling tích cực',
          startDate: '2024-04-05',
          endDate: '2024-04-07',
          status: StatusServiceTreatmentScheduleEnum.Completed,
          phaseApprover: {
            name: 'Lê Thị Kim Hoàn',
            levelName: 'Bác sĩ',
            jobTitle: 'Bác sĩ',
          },
          phaseAttendingPhysician: {
            name: 'Hồ Thị Thanh Thuỷ',
            levelName: 'Bác sĩ',
            jobTitle: 'Bác sĩ',
          },
          appointmentSession: dataAppointmentSessions,
          id: getRandomUuid(),
        },
      ],
      professionalExchangeWitPhysicians: getArray(3).map(itm => {
        return {
          doctor: {
            name: 'Hồ Thị Thanh Thuỷ',
            levelName: 'Bác sĩ',
            jobTitle: 'Bác sĩ',
          },
          files: ['file.xlsx', 'file.docx', 'file.pxf'],
          images: [
            R.images.img_bg_auth,
            R.images.img_bg_auth,
            R.images.img_bg_auth,
          ],
          message: `Mô tả ${itm}`,
          time: new Date().toISOString(),
        }
      }),
    },
  ],
}

export const dataProfilePatient: IProfilePatient = {
  debtPayment: {
    orderDebtPayments: [orderDebtPayment],
  },
  imagePatient: imagePatient,
  infoPatient: patientInfoExample,
  purchaseProductService: purchaseProductService,
  infoTreatmentSchedule,
}

export const ListTreatmentExample = [
  'Kiểm tra răng',
  'Vệ sinh răng',
  'Cạo vôi răng cơ bản',
  'Khám răng hàm trên',
  'Khám răng hàm dưới',
  'Khám tổng quát',
]
