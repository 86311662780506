import styled from 'styled-components'

export const ModalTitle = styled.div`
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--main-color-text);
  margin: 20px 0px 10px 0px;
`

export const ModalMessage = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 20px;
`

export const ModalPatientName = styled.p`
  display: inline-block;
  color: var(--states-red-color);
  font-weight: bold;
`
