import {
  IDentalHealthHistory,
  OralHygieneToolsYouUsuallyUseEnum,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/dental-health-history'
import { useMemo, useRef, useState } from 'react'
import { IRefModal } from '../../../../components'
import { isEqual } from 'lodash'
import { ListKey } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/constant/dental-health-history'
import R from 'app/assets/R'

export const useHook = () => {
  const modalRef = useRef<IRefModal>({})
  const [data, setData] = useState<IDentalHealthHistory>({
    oral_hygiene_tools_you_usually_use: [],
  })

  const listItem = useMemo(() => {
    return ListKey?.map(key18n => {
      if (isEqual(key18n, R.strings.oral_hygiene_tools_you_usually_use)) {
        return {
          key18n,
          listEnumSelected: data?.[
            key18n
          ] as OralHygieneToolsYouUsuallyUseEnum[],
        }
      }
      return {
        key18n,
        isChecked: data?.[key18n] as boolean,
      }
    })
  }, [data])

  const onChangeBoolData = (key: string, newValue: boolean) => {
    setData(prev => ({
      ...prev,
      [key]: newValue,
    }))
  }

  const onChangeOralHygiene = (
    key: OralHygieneToolsYouUsuallyUseEnum,
    value: boolean,
  ) => {
    setData(prev => {
      const oldList = data?.oral_hygiene_tools_you_usually_use ?? []
      if (!value) {
        return {
          ...prev,
          oral_hygiene_tools_you_usually_use: oldList?.filter(
            itm => !isEqual(itm, key),
          ),
        }
      }

      return {
        ...prev,
        oral_hygiene_tools_you_usually_use: oldList?.concat([key]),
      }
    })
  }

  const handleReset = () => {
    setData({
        oral_hygiene_tools_you_usually_use: []
    })
  }

  const handleSubmit = () => {
    modalRef?.current?.hide?.()
    handleReset?.()
  }

  const handleCancel = () => {
    handleReset()
  }

  return {
    data,
    setData,
    modalRef,
    listItem,
    handleCancel,
    handleSubmit,
    onChangeBoolData,
    onChangeOralHygiene,
  }
}
