import { DatePicker } from 'antd'
import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { removeVietnameseTones } from 'app/helpers/string.helper'
import dayjs from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { SearchDropdown } from './search-dropdown'
import * as S from './styles'
import { UnitContext } from 'app/context/UnitContext'
import { IClinic } from 'app/model/unit.model'
import { ProfileContext } from 'app/context/ProfileContext'
import { IDoctor } from 'app/containers/CustomerCare/AppointmentPage/SidebarLeft/DoctorList/type'
import { PageSearchAppointmentContext } from 'app/context/PageSearchAppointmentContext'
import moment from 'moment'
import { DATE } from 'app/common/constants'
export enum PaidTypeTableEnum {
  deposit = 'deposit',
  installment = 'installment',
  one_time_payment = 'one_time_payment',
  no_paid = 'no_paid',
}
export type PaidTypeTableType = keyof typeof PaidTypeTableEnum

export interface IFilter {
  keyword: string | undefined
  clinic: string | unknown
  doctor: string | unknown
  startDate: string | undefined
  endDate: string | undefined
  search?: string | undefined
}

export const FilterSearchAppointment = ({
  onChangeFilter: handleOnchangeFilter,
  isSearch,
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { filter, setFilter } = useContext(PageSearchAppointmentContext)
  const { units } = useContext(UnitContext)
  const { fetchData: fetchDoctors, profiles: doctors } =
    useContext(ProfileContext)

  const [clinic, setClinic] = useState<IClinic[]>([])
  const [searchDoctor, setSearchDoctor] = useState('')

  const [isOverlayOpen, setOverlayOpen] = useState(false)

  useEffect(() => {
    let clinic: IClinic[] = []
    units.forEach(unit => {
      clinic = [...clinic, ...(unit['childs'] || [])]
    })
    setClinic(clinic)
  }, [units])

  useEffect(() => {
    fetchDoctors?.({
      page: 0,
      pageSize: 20,
      sort: '-1',
      group: 'doctor',
      unitId: filter?.clinicId,
    })
  }, [filter?.clinicId])

  useEffect(() => {
    setOverlayOpen(false)
  }, [pathname])

  const onChangeFilter = (key, value) => {
    setFilter(prev => {
      return {
        ...prev,
        [key]: value,
      }
    })
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleOnchangeFilter(filter)
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [filter])

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchDoctors?.({
        page: 0,
        pageSize: 20,
        sort: '-1',
        group: 'doctor',
        keyword: searchDoctor,
        unitId: filter?.clinicId,
      })
    }, 500)
    return () => clearTimeout(timer)
  }, [searchDoctor])

  return (
    <>
      <S.HeadWrapper gutter={20} align={'middle'}>
        {isSearch && (
          <BaseCol>
            <SearchDropdown
              query={filter?.keyword ?? ''}
              setQuery={e => onChangeFilter('keyword', e)}
              data={[]}
              isOverlayOpen={isOverlayOpen}
              setOverlayOpen={setOverlayOpen}
              placeholder={t(R.strings.search_appointment_s_label)}
            />
          </BaseCol>
        )}

        {!isSearch && (
          <>
            <BaseCol>
              <BaseForm.Item name="select" required={false}>
                <S.SelectBaseStyled
                  allowClear
                  placeholder={t(R.strings.search_appointment_choose_clinic)}
                  suffixIcon={!filter.clinicId && <SvgTableCloseIcon />}
                  onChange={value => onChangeFilter('clinicId', value)}
                  filterOption={(value, option) => {
                    return removeVietnameseTones(option?.label)
                      .toLowerCase()
                      .includes(removeVietnameseTones(value).toLowerCase())
                  }}
                  options={clinic?.map(item => {
                    return {
                      label: item?.name,
                      value: item?._id,
                    }
                  })}
                />
              </BaseForm.Item>
            </BaseCol>
            <BaseCol>
              <S.CustomSelectSearch
                allowClear
                placeholder={t(R.strings.doctor_label_placeholder)}
                suffixIcon={!filter.doctorId && <SvgTableCloseIcon />}
                onSearch={keyword => setSearchDoctor(keyword)}
                onChange={value => onChangeFilter('doctorId', value)}
                options={doctors.map((doctor: IDoctor) => ({
                  value: String(doctor?._id),
                  name: doctor?.name,
                  avatar: doctor?.avatar,
                  description:
                    doctor?.jobTitleName?.replaceAll(',', ' &') || '',
                }))}
              />
            </BaseCol>
            {'|'}
            <BaseCol>
              <DatePicker
                defaultValue={dayjs(filter?.startDate)}
                onChange={value =>
                  onChangeFilter(
                    'startDate',
                    moment(value?.format(DATE.YYYY_MM_DD)),
                  )
                }
                placeholder={t(R.strings.choose_date)}
              />
            </BaseCol>
            {'-'}
            <BaseCol>
              <DatePicker
                defaultValue={dayjs(filter?.endDate)}
                onChange={value =>
                  onChangeFilter(
                    'endDate',
                    moment(value?.format(DATE.YYYY_MM_DD)),
                  )
                }
                placeholder={t(R.strings.choose_date)}
              />
            </BaseCol>
          </>
        )}
      </S.HeadWrapper>
    </>
  )
}
