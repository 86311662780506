import { ApiClient } from 'app/service/api-service'
import { AppointmentEndPoint } from './constant'
import { IParams } from './model/appointment'
import { IAppointment } from 'app/containers/CustomerCare/AppointmentPage/type'

export const requestGetAppointmentManagement = (params: IParams) =>
  ApiClient.Get({
    url: AppointmentEndPoint.AppointmentManagement.request,
    params: { params },
  })

export const requestGetCallCenterScheduleTotal = (params: IParams) =>
  ApiClient.Get({
    url: AppointmentEndPoint.AppointmentBookingCallCenterManagement.total,
    params: { params },
  })

export const requestCreateAppointmentCallCenterManagement = (
  body: IAppointment,
) =>
  ApiClient.Post({
    url: AppointmentEndPoint.AppointmentBookingCallCenterManagement.request,
    body,
  })

export const requestUpdateAppointmentCallCenterManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IAppointment
}) =>
  ApiClient.Put({
    url: `${AppointmentEndPoint.AppointmentManagement.request}/call-center/schedule/${id}`,
    body,
  })

export const requestsendSmsCallCenter = (params: IParams) =>
  ApiClient.Get({
    url: AppointmentEndPoint.AppointmentBookingCallCenterManagement.sendSms,
    params: { params },
  })

export const requestExportCallCenter = (params: IParams) =>
  ApiClient.Get({
    url: AppointmentEndPoint.AppointmentBookingCallCenterManagement.export,
    params: { params },
  })

export const requestDetailAppointmentManagement = (id?: string | number) =>
  ApiClient.Get({
    url: `${AppointmentEndPoint.AppointmentManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteAppointmentManagement = (id?: string | number) =>
  ApiClient.Delete({
    url: `${AppointmentEndPoint.AppointmentManagement.request}/${id}`,
    body: {},
  })

export const requestGetAllAppointmentManagement = () =>
  ApiClient.Get({
    url: `${AppointmentEndPoint.AppointmentManagement.request}/get-all`,
    params: {},
  })
