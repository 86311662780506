/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestChangePasswordManagement } from 'app/api/human'
import { HumanMessageCode } from 'app/api/human/constant'
import { IPayloadChangePassword } from 'app/api/human/model/account-management'
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormData, IFormRule, IPropsChangePassword } from './type'

export const useModalHook = (props: IPropsChangePassword) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormData>()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)

    if (!props?.id) {
      return
    }
    try {
      const body: IPayloadChangePassword = {
        password: values?.password,
      }

      const response: ResponseType<string> =
        await requestChangePasswordManagement({ id: props.id, body })

      if (
        isEqual(
          response?.msgcode,
          HumanMessageCode.AccountManagement.successPutData,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        // props?.fetchTable?.()
        notificationController.success({
          message: t(R.strings.update_account),
          description: t(R.strings.change_password_success),
        })
      } else {
        notificationController.error({
          message: t(R.strings.update_account),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const validateRePassword = (_, value, callback) => {
    const { getFieldValue } = form

    // Check if password and re_password fields match
    if (value && value !== getFieldValue('password')) {
      callback(t(R.strings.re_password_not_match))
    } else {
      callback()
    }
  }

  const rules: IFormRule = useMemo(() => {
    return {
      password: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.password),
          }),
        },
      ],
      re_password: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.re_password),
          }),
        },
        {
          validator: validateRePassword,
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
  }
}
