import { IResponseFilterReport04 } from 'app/api/report/model/report-04'
import R from 'app/assets/R'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { usePagination } from 'app/hook'
import { useGetFilterReport04 } from 'app/react-query/hook/report'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { totalKeyReport04 } from '../constant'

const SelectServiceComponent = ({
  onChange,
}: {
  onChange?: (service?: string) => void
}) => {
  const { t } = useTranslation()

  const [service, setService] = useState<string | undefined>(totalKeyReport04)
  const [isEdit, setIsEdit] = useState(false)
  const { data: dataBefore } = useGetFilterReport04()
  const { flattenDataList } = usePagination()
  const data: string[] = useMemo(() => {
    const res: IResponseFilterReport04 = flattenDataList(dataBefore)
    return res?.services ?? []
  }, [dataBefore])

  const onChangeService = (type: string) => {
    setService(type)
    setIsEdit(true)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isEdit) {
        onChange?.(service)
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [service, isEdit])

  return (
    <SelectBaseStyled
      value={service}
      placeholder={t(R.strings.choose_service)}
      onChange={type => onChangeService(type as string)}
      allowClear
    >
      {data?.map((itm, idx) => {
        return (
          <Option key={idx} value={itm}>
            {itm}
          </Option>
        )
      })}
    </SelectBaseStyled>
  )
}
export default SelectServiceComponent

const SelectBaseStyled = styled(BaseSelect)`
  .ant-select-arrow {
    top: 50%;
  }
`
