import { Rule } from 'antd/es/form'
import { ClinicTypeEnum, IGeneralUnitManagementTable } from '../../type'

export interface IFormData {
  code?: string
  name?: string
  unitGroupId?: string
  unitTypeId?: string
  jobTitleIdManagement?: string
  description?: string
  _id?: string
  status?: string
  unitParentCode?: string
  unitParentName?: string
  parentid?: string
  jobTitleIdVice?: string
  clinicType?: ClinicTypeEnum
}

export interface IFormRule {
  code?: Rule[]
  unitGroupId?: Rule[]
  unitTypeId?: Rule[]
  jobTitleIdManagement?: Rule[]
  name?: Rule[]
  description?: Rule[]
  _id?: Rule[]
  status?: Rule[]
  parentid?: Rule[]
  clinicType?: Rule[]
}

export const initValues: IFormData = {
  code: '',
  name: '',
  _id: '',
  description: '',
  status: '',
}

export enum StatusUnitEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
  deleted = 'deleted',
}

export type StatusUnitType = keyof typeof StatusUnitEnum

export interface IPropsUpdateUnit {
  fetchTable: () => void
  id?: string | number
  listUnit?: IGeneralUnitManagementTable[]
}
