import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LeftSideEmployeeLayout, RightSideEmployeeLayout } from './layouts'
import * as S from './styles'

const DashboardContainer: React.FC = () => {
  const { t } = useTranslation()

  const desktopLayout = (
    <BaseRow gutter={[8, 8]} justify={'space-between'} wrap={false}>
      <LeftSideEmployeeLayout />
      <RightSideEmployeeLayout />
    </BaseRow>
  )
  return (
    <S.ScrollWrapper>
      <S.HeadingTitlePage>
        <span>{t(R.strings.dashboard)}</span>
      </S.HeadingTitlePage>
      {desktopLayout}
    </S.ScrollWrapper>
  )
}

export default DashboardContainer
