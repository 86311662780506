import { URL_DOWNLOAD_EXAMPLE } from 'app/constant'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchAppointmentHook } from './SearchAppointmentHook/hook'
import { initialPagination } from './common-model'

export const useTableHook = ({
  filter,
  setFilter
}) => {
  const { t } = useTranslation()

  const Hook = SearchAppointmentHook({
    filter
  })

  const tableData = useMemo(() => {
    return Hook.tableData
  }, [Hook])

  const columns = useMemo(() => {
    return Hook.columns
  }, [t, filter, tableData])

  const urlDownload = useMemo(() => {
    return {
      url: URL_DOWNLOAD_EXAMPLE.CostQuarterPlan,
      name: 'CostQuarterPlan.xlsx',
    }
  }, [t])

  const onChangeFilter = filter => {
    setFilter(filter)
    Hook?.fetch(initialPagination, filter)
  }

  const handleTableChange = pagination => {
    Hook?.fetch(pagination, filter)
  }

  return {
    columns,
    tableData,
    handleTableChange,
    urlDownload,
    onChangeFilter,
    filterResponse: filter,
  }
}
