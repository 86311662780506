// Message code define
export const MessageCode = {
  successGetData: '20000',
  successPutData: '20000',
  successDeleteData: '20000',
  successPostData: '20001',
}

// Endpoint
export const EndPoint = {
  SupplierManagement: {
    request: '/treatment/v1/supplier',
  },
  WarehouseManagement: {
    request: '/treatment/v1/warehouse',
  },
  WarehousePurchaseManagement: {
    request: 'treatment/v1/purchase-order',
  },
  WarehouseGoodsReceiptManagement: {
    request: 'treatment/v1/warehouse-goods-receipt',
  },
  WarehouseInventoryManagement: {
    request: 'treatment/v1/warehouse-inventory',
  },
  WarehouseGoodsIssueManagement: {
    request: 'treatment/v1/warehouse-goods-issue',
  }
}
