import { ApiClient, ApiClientHr } from 'app/service/api-service'
import { DiscountEndPoint } from './constant'
import {
  IParamGetUserDataByVoucher,
  IParamGetVoucherCodeList,
  IParamsGetDiscountVoucher,
  IParamsGetReferralInfo,
  IParamsReferralStatistics,
  IPayloadCreateVoucher,
  IPayloadUpdateWithInsertOnlyInVoucher,
} from './model'

export const requestGetDiscountVoucher = (params: IParamsGetDiscountVoucher) =>
  ApiClientHr.Get({
    url: DiscountEndPoint.DiscountVoucher.requestGetData,
    params: { params },
  })

export const requestCreateVoucher = (body: IPayloadCreateVoucher) =>
  ApiClientHr.Post({
    url: DiscountEndPoint.DiscountVoucher.requestGetData,
    body,
  })

export const requestUpdateVoucherWithInsertOnly = (
  body: IPayloadUpdateWithInsertOnlyInVoucher,
) =>
  ApiClientHr.Post({
    url: DiscountEndPoint.DiscountVoucher.updateDataWithInsert?.replace(
      ':id',
      body?.id ?? '',
    ),
    body,
  })

export const requestDeleteVoucher = (id: string) =>
  ApiClientHr.Delete({
    url: DiscountEndPoint.DiscountVoucher.deleteData.replace(':id', id),
    body: {},
  })

export const requestReTryVoucher = (id: string) =>
  ApiClientHr.Post({
    url: DiscountEndPoint.DiscountVoucher.retry.replace(':id', id),
    body: {},
  })

export const requestStopApplyVoucher = (id: string) =>
  ApiClientHr.Patch({
    url: DiscountEndPoint.DiscountVoucher.stopApply.replace(':id', id),
    body: {},
  })

export const requestGetUserDataByVoucherId = ({
  id,
  ...params
}: IParamGetUserDataByVoucher) =>
  ApiClientHr.Get({
    url: DiscountEndPoint.DiscountVoucher.userDataByVoucherId.replace(
      ':id',
      id,
    ),
    params: { params },
  })

export const requestGetReferralDataByVoucherId = ({
  id,
  ...params
}: IParamGetUserDataByVoucher) =>
  ApiClientHr.Get({
    url: DiscountEndPoint.DiscountVoucher.referralDataByVoucherId.replace(
      ':id',
      id,
    ),
    params: { params },
  })

export const requestGetDetailVoucher = (id: string) =>
  ApiClientHr.Get({
    url: DiscountEndPoint.DiscountVoucher.requestGetDetail.replace(':id', id),
    params: { id },
  })

export const requestRequestImportUserData = (body: FormData) =>
  ApiClient.Post({
    url: DiscountEndPoint.DiscountVoucher.importUserData,
    body,
  })

export const requestRequestImportReferralData = (body: FormData) =>
  ApiClient.Post({
    url: DiscountEndPoint.DiscountVoucher.importReferralData,
    body,
  })

export const requestGetVoucherCode = (params: IParamGetVoucherCodeList) =>
  ApiClientHr.Get({
    url: DiscountEndPoint.DiscountVoucher.getVoucherCode,
    params: { params },
  })

export const requestGetReferralDiscountVoucher = (
  params: IParamsGetReferralInfo,
) =>
  ApiClientHr.Get({
    url: DiscountEndPoint.DiscountVoucher.requestGetReferralDiscountVoucher,
    params: { params },
  })

export const requestExportVoucherCode = (params: IParamGetVoucherCodeList) =>
  ApiClientHr.Get({
    url: DiscountEndPoint.DiscountVoucher.exportVoucherCode,
    params: { params },
  })

export const requestExportListReferralVoucherCode = (
  params: IParamsGetReferralInfo,
) =>
  ApiClientHr.Get({
    url: DiscountEndPoint.DiscountVoucher.exportListReferralVoucherCode,
    params: { params },
  })

export const requestGetReferralStatistics = (
  params: IParamsReferralStatistics,
) =>
  ApiClientHr.Get({
    url: DiscountEndPoint.DiscountVoucher.requestGetReferralStatistics,
    params: { params },
  })

export const requestExportListReferralStatistics = (
  params: IParamsReferralStatistics,
) =>
  ApiClientHr.Get({
    url: DiscountEndPoint.DiscountVoucher.exportListReferralStatistics,
    params: { params },
  })

export const requestImportTreatmentInVoucherTemplate = (body: FormData) =>
  ApiClient.Post({
    url: DiscountEndPoint.DiscountVoucher.importTreatment,
    body,
  })

export const requestImportTreatmentInVoucherTemplateWithId = ({
  id,
  body,
}: {
  body: FormData
  id: string
}) =>
  ApiClient.Post({
    url: DiscountEndPoint.DiscountVoucher.importTreatmentWithVoucherTemplateId?.replace(
      ':id',
      id,
    ),
    body,
  })
