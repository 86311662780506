/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash'

export interface ITreeData {
  title: string
  value: string
  children: ITreeData[]
}

export type MapFields = {
  title?: string
  value?: string
  children?: string
}

const transformDataSelectTree = (
  data: any[],
  mapFields: MapFields,
): ITreeData[] | undefined => {
  if (!data || isEmpty(data)) {
    return undefined
  }

  const { title = 'title', value = 'value', children = 'children' } = mapFields

  return data.map((item: any) => ({
    title: item[title] ?? '',
    value: item[value] ?? '',
    children:
      transformDataSelectTree(item[children], { title, value, children }) ?? [],
  }))
}

export { transformDataSelectTree }
