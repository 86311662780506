import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { StatusEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { ALL_KEY } from 'app/constant'
import { usePagination } from 'app/hook'

import { IClinic, IUnit } from 'app/model/unit.model'
import { useGetAreaList } from 'app/react-query/hook/location'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  onChangeAreaId?: (id?: string) => void
  onChangeClinic?: (string?: string[]) => void
}

const AreaSelection = ({ onChangeAreaId, onChangeClinic }: IProps) => {
  const { t } = useTranslation()
  const { flattenDataList } = usePagination()
  const { data: areasBefore, isLoading: loadingArea } = useGetAreaList({
    status: StatusEnum.ACTIVE,
    typeid: 'area',
  })

  const [area, setArea] = useState<IUnit | undefined>(undefined)

  const [clinic, setClinic] = useState<IClinic | undefined>(undefined)

  const areas = useMemo(() => {
    const res: IUnit[] = flattenDataList(areasBefore)
    const allItem: IUnit = {
      _id: ALL_KEY,
      name: t(R.strings.report_14_content_all_area),
      childs: [],
    }

    return [allItem, ...res]
  }, [areasBefore, t])

  const clinics = useMemo(() => {
    if (!area?.childs?.length) return []

    const allItem: IClinic = {
      _id: ALL_KEY,
      name: t(R.strings.report_14_content_all_branch),
    }

    return [allItem, ...(area?.childs ?? [])]
  }, [area, t])

  const onChangeSelectArea = (_id?: string) => {
    const itemExist = areas?.find(itm => itm?._id === _id)
    if (!itemExist) return
    setArea(itemExist)
    onChangeAreaId?.(_id)
    if (!clinic || clinic?._id === ALL_KEY) {
      onChangeClinic?.(itemExist?.childs?.map(itm => itm?.oldid ?? ''))
    }
  }

  const onChangeSelectClinic = (_id: string) => {
    const itemExist = clinics?.find(itm => itm?._id === _id)
    if (!itemExist) return
    setClinic(itemExist)
    if (_id === ALL_KEY) {
      const itemAreaExist = areas?.find(itm => area?._id === itm?._id)
      onChangeClinic?.(itemAreaExist?.childs?.map(itm => itm?.oldid ?? ''))
    } else {
      onChangeClinic?.([itemExist?.oldid ?? ''])
    }
  }

  return (
    <RootWrapper gutter={[10, 10]}>
      <BaseCol>
        <BaseForm.Item name="select">
          <SelectBaseStyled
            defaultValue={ALL_KEY}
            value={area?._id}
            placeholder={t(R.strings.area)}
            suffixIcon={<SvgTableCloseIcon />}
            onChange={id => onChangeSelectArea(id as string)}
            allowClear={false}
            loading={loadingArea}
            showSearch
            options={
              areas?.map(item => ({
                value: item?._id,
                label: item?.name,
              })) ?? []
            }
          />
        </BaseForm.Item>
      </BaseCol>

      {area && clinics?.length ? (
        <BaseCol>
          <BaseForm.Item name="select">
            <SelectBaseStyled
              defaultValue={ALL_KEY}
              value={clinic?._id}
              placeholder={t(R.strings.report_14_content_all_branch)}
              suffixIcon={<SvgTableCloseIcon />}
              onChange={id => onChangeSelectClinic(id as string)}
              allowClear={false}
              loading={loadingArea}
              showSearch
              options={
                clinics?.map(item => ({
                  value: item?._id,
                  label: item?.name,
                })) ?? []
              }
            />
          </BaseForm.Item>
        </BaseCol>
      ) : null}
    </RootWrapper>
  )
}

export default AreaSelection

const RootWrapper = styled(BaseRow)``
const SelectBaseStyled = styled(BaseSelect)`
  min-width: 200px;
  .ant-select-arrow {
    top: 40%;
  }
`
