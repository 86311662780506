import {
  CheckCircleFilled,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import DividerLine from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/DividerLine'
import {
  IRefModal,
  ModalComponent,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/components'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEmpty, isEqual } from 'lodash'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { nurseData } from './constant'
import { IDoctorTreatmentSchedule } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { BaseRow } from 'app/components/common/BaseRow'

export const AddNurseModal = ({
  onSubmit,
}: {
  onSubmit?: (nurses: IDoctorTreatmentSchedule[]) => void
}) => {
  const { t } = useTranslation()
  const modalRef = useRef<IRefModal>({})

  const [search, setSearch] = useState('')
  const [nurses, setNurses] = useState<IDoctorTreatmentSchedule[]>([])

  const onChangeSearch = (value: string) => {
    setSearch(value)
  }

  const listNurse = useMemo(() => {
    if (isEmpty(search)) {
      return nurseData
    }

    return nurseData?.filter(
      item => item?.name?.includes(search) || item?.jobTitle?.includes(search),
    )
  }, [search])

  const onChangeNurse = (nurse: IDoctorTreatmentSchedule) => {
    const itemExist = nurses?.find(itm => isEqual(itm?.id, nurse?.id))
    if (itemExist) {
      setNurses(prev => prev?.filter(itm => !isEqual(itm?.id, nurse?.id)))
      return
    }
    setNurses(prev => prev?.concat([nurse]))
  }

  const _onSubmit = () => {
    onSubmit?.(nurses)
  }

  return (
    <ModalComponent
      iconOpenModal={
        <BaseCol>
          <PlusOutlinedIcon rev={undefined} />
        </BaseCol>
      }
      titleOpenModal={t(R.strings.add_nurse)}
      titleModal={t(R.strings.add_nurse)}
      textButtonOpenColor="otherBlueColor"
      isShowLineTop={false}
      ref={modalRef}
      handleSubmit={_onSubmit}
      renderContent={
        <BaseSpace>
          <BaseInput
            placeholder={t(R.strings.enter_name_doctor)}
            value={search}
            onChange={e => onChangeSearch(e?.target.value ?? '')}
            prefix={<SearchOutlined rev={undefined} />}
          />

          <DividerLine />

          {listNurse?.map((item, index) => {
            const isSelected = nurses?.find(itm => isEqual(itm?.id, item?.id))
            return (
              <ItemWrapper
                key={index}
                onClick={() => onChangeNurse(item)}
                justify={'space-between'}
              >
                <BaseAvatarProfile
                  jobTitle={`${item?.jobTitle}`}
                  name={`${item?.name}`}
                />
                {isSelected ? <CheckCircleFilledIcon rev={undefined} /> : null}
              </ItemWrapper>
            )
          })}
        </BaseSpace>
      }
    />
  )
}

const ItemWrapper = styled(BaseRow)<{ $isSelected?: boolean }>`
  cursor: pointer;
  padding: 5px 0px;
`

const PlusOutlinedIcon = styled(PlusOutlined)`
  path {
    fill: ${convertedVariables.otherBlueColor};
  }
`

const CheckCircleFilledIcon = styled(CheckCircleFilled)`
  svg {
    fill: ${convertedVariables.statesGreenColor};
  }
`
