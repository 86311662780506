import styled from 'styled-components'

export const ScheduleLabel = styled.div<{ background: string; border: string }>`
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  border: 1px solid ${props => props.background || '#BF4F74'};
  background: ${props => props.background || '#BF4F74'};
  &:before {
    content: '';
    width: 2px;
    height: 14px;
    border: 1px solid ${props => props.border || '#BF4F74'};
    background: ${props => props.border || '#BF4F74'};
    position: absolute;
    left: -8px;
    border-radius: 8px;
  }
`
