import React, { useState } from 'react'
import { SettingOutlined } from '@ant-design/icons'
import { BaseButton } from 'app/components/common/BaseButton'
import { HeaderActionWrapper } from 'app/components/header/styles'
import { SettingsOverlay } from './settings-overlay/SettingsOverlay'
import { BasePopover } from 'app/components/common/BasePopover'

export const SettingsDropdown: React.FC = () => {
  const [isOpened, setOpened] = useState(false)

  return (
    <BasePopover
      content={<SettingsOverlay />}
      trigger="click"
      onOpenChange={setOpened}
    >
      <HeaderActionWrapper>
        <BaseButton
          type={isOpened ? 'link' : 'text'}
          icon={<SettingOutlined rev={undefined} />}
        />
      </HeaderActionWrapper>
    </BasePopover>
  )
}
