import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { useCreateVoucherContext } from '../../context'
import { useTranslation } from 'react-i18next'

export const FooterLayout = () => {
  const { t } = useTranslation()
  const { handleGoBack, form, isLoading} = useCreateVoucherContext()
  return (
    <BaseRow align={'middle'} justify={'end'}>
      <BaseCol>
        <BaseRow gutter={16} align={'middle'}>
          <BaseCol>
            <BaseButton onClick={handleGoBack}>
              {t(R.strings.cancel)}
            </BaseButton>
          </BaseCol>
          <BaseCol>
            <BaseButton onClick={form?.submit} loading={isLoading}>
              {t(R.strings.confirm)}
            </BaseButton>
          </BaseCol>
        </BaseRow>
      </BaseCol>
    </BaseRow>
  )
}
