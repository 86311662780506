import {
  IDoctorTreatmentSchedule,
  INurseTreatmentSchedule,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'

const doctorData: IDoctorTreatmentSchedule[] = [
  {
    name: 'Phạm Văn Khánh',
    jobTitle: 'Bác sĩ',
    levelName: '-',
  },
  {
    name: 'Gia Quân',
    jobTitle: 'Bác sĩ',
    levelName: '-',
  },

  {
    name: 'Ngọc Long',
    jobTitle: 'Bác sĩ',
    levelName: '-',
  },
]

const nurseData: INurseTreatmentSchedule[] = [
  {
    name: 'Phạm Văn Khánh',
    jobTitle: 'Bác sĩ',
    levelName: '-',
  },
  {
    name: 'Gia Quân',
    jobTitle: 'Bác sĩ',
    levelName: '-',
  },

  {
    name: 'Ngọc Long',
    jobTitle: 'Bác sĩ',
    levelName: '-',
  },
]

export const ConstantAddDoctor = {
  nurseData,
  doctorData,
}
