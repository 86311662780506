import React from 'react'
import { PickerProps } from 'antd/lib/date-picker/generatePicker'
import { DayjsDatePicker } from './DayjsDatePicker'
import { AppDate, FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'
import { convertedVariables } from 'app/styles/themes/themeVariables'

interface IPropsStyle {
  $prefix?: string
  $prefixWidth?: number
}

export type BaseDatePickerProps = PickerProps<AppDate> & IPropsStyle

export const BaseDatePicker = React.forwardRef<
  React.Component<BaseDatePickerProps>,
  BaseDatePickerProps
>(({ className, ...props }, ref) => (
  <BaseDatePickerWrapper ref={ref} className={className} {...props} />
))

const BaseDatePickerWrapper = styled(DayjsDatePicker)<IPropsStyle>`
  ${({ $prefix, $prefixWidth }) =>
    $prefix &&
    `
    width: 100%;
    .ant-picker-input::before {
      content: '${$prefix ?? '-'}:';
      font-size: ${FONT_SIZE.xs};
      font-weight: ${FONT_WEIGHT.medium};
      color: ${convertedVariables.primaryColor};
      opacity: 0.4;
      width: ${$prefixWidth ?? 0}px;
    }`}
`
