import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import { useCreateVoucherContext } from './context'
import {
  FooterLayout,
  InfoVoucherLayout,
  SettingVoucherLayout,
} from './layouts'
import { initValues } from './type'
import { ImportFileModal, ImportReferralFileModal } from './modal'
import { isEqual } from 'lodash'
import { CampaignReferralEnum } from 'app/api/marketing/discount-campaign/model'
import { TargetUserEnum } from 'app/api/marketing/discount-voucher/enum'

export const ContentLayout = () => {
  const {
    form,
    handleSubmit,
    openImportArea,
    openImportAreaReferral,
    itemCampaign,
  } = useCreateVoucherContext()

  return (
    <BaseForm
      form={form}
      onFinish={handleSubmit}
      initialValues={{
        ...initValues,
        targetUser:
          isEqual(itemCampaign?.referralType, CampaignReferralEnum.Patient) ||
          isEqual(itemCampaign?.referralType, CampaignReferralEnum.Staff)
            ? TargetUserEnum?.Customer
            : undefined,
      }}
    >
      <RootWrapper>
        <InfoVoucherLayout />
        <SettingVoucherLayout />
        <FooterLayout />
      </RootWrapper>

      {openImportArea && <ImportFileModal />}

      {openImportAreaReferral && <ImportReferralFileModal />}
    </BaseForm>
  )
}

const RootWrapper = styled(BaseSpace)`
  padding-bottom: ${PADDING.xxl};
`
