import { Col, Dropdown, Row, Select } from 'antd'
import R from 'app/assets/R'
import { ListTotal } from 'app/constant'
import { AppointmentContext } from 'app/context/AppointmentContext'
import { PageAppointmentContext } from 'app/context/PageAppointmentContext'
import dayjs from 'dayjs'
import moment from 'moment'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AnnotateTitle, CenterLineSelection } from '../styles'
import { EScheduleStatus, IListTotal } from '../type'
import { ScheduleTotal } from '../../../../components/common/ScheduleTotal'
import { CalendarWrapper, ListBox, ListItem } from './styles'
import CalendarRight from './CalendarRight'
import { scheduleStatusAppointmentFilter } from 'app/api/appointment/constant'
import { ResultDateRangeNumber } from '../../SearchAppointment/styles'
import { DATE } from 'app/common/constants'

const SidebarRight = props => {
  const { t } = useTranslation()
  const { selectedDate, view, setView, setSelectedDate } = props
  const { doctorSelected, filter, setFilter } = useContext(
    PageAppointmentContext,
  )
  const { getCallCenterScheduleTotal, appointmentTotal } =
    useContext(AppointmentContext)

  useEffect(() => {
    if (
      filter?.date &&
      filter?.areaId &&
      filter?.clinicId &&
      filter?.doctorId
    ) {
      getCallCenterScheduleTotal?.({
        date: filter?.date?.format(DATE.YYYY_MM_DD),
        view,
        areaId: filter?.areaId,
        clinicId: filter?.clinicId,
        doctorId: filter?.doctorId,
        categoryServiceId: filter?.categoryServiceId,
        scheduleStatus: scheduleStatusAppointmentFilter,
      })
    }
  }, [view, filter, selectedDate])

  useEffect(() => {
    setFilter?.(prev => ({ ...prev, date: dayjs(selectedDate) }))
  }, [selectedDate])

  const scheduleTotal: IListTotal | undefined = appointmentTotal?.find(
    (total: IListTotal) => total?._id === EScheduleStatus.TOTAL,
  )

  return (
    <CalendarWrapper>
      <Row className="p-5 title-box">
        <Col span={15}>
          <h3 className="title">
            <p className="month">
              {t(R.strings.week)} {moment(selectedDate).week()} -{' '}
              {t(R.strings.month)} {moment(selectedDate).format(DATE.MM)},
              <p className="year">{moment(selectedDate).format(DATE.YYYY)}</p>|
            </p>
            <p className="doctor-name">
              {doctorSelected?.name &&
                ` ${t(R.strings.BS)} ${doctorSelected?.name}`}
            </p>
          </h3>
        </Col>
        <Col span={9}>
          <Row>
            <Col span={9}>
                {t(R.strings.total_schedule)}
                <ResultDateRangeNumber>
                  {(scheduleTotal && scheduleTotal['total']) || 0}
                </ResultDateRangeNumber>
            </Col>
            <Col span={8}>
              <Select
                defaultValue="week"
                options={[
                  { value: 'week', label: t(R.strings.week) },
                  { value: 'day', label: t(R.strings.day) },
                ]}
                className="select-calendar-type"
                value={view}
                onChange={value => setView(value)}
              />
            </Col>
            <Col span={1}>
              <CenterLineSelection />
            </Col>
            <Col span={6} className="annotate">
              <Dropdown
                menu={{
                  items: [
                    {
                      key: '1',
                      label: (
                        <>
                          <AnnotateTitle>
                            {t(R.strings.appointment_note)}:{' '}
                          </AnnotateTitle>
                          {ListTotal.map((total, idnex) => (
                            <ListBox
                              background={total?.background}
                              border={total?.border}
                              key={idnex}
                            >
                              <ListItem>{total?.label}</ListItem>
                            </ListBox>
                          ))}
                        </>
                      ),
                    },
                  ],
                }}
                placement="bottomRight"
                overlayClassName="create-appointment title-box annotate"
              >
                <AnnotateTitle>{t(R.strings.appointment_note)}</AnnotateTitle>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        className="p-5 app-row-margin"
      >
        {ListTotal.map(total => {
          const itemTotal: IListTotal | undefined = appointmentTotal?.find(
            (aTotal: IListTotal) => total?.status === aTotal?._id,
          )

          return (
            <ScheduleTotal
              background={total?.background}
              border={total?.border}
              label={total?.label}
              total={itemTotal ? itemTotal['total'] : total?.total}
            />
          )
        })}
      </Row>
      <CalendarRight
        view={view}
        setView={setView}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    </CalendarWrapper>
  )
}

export default SidebarRight
