import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import BaseText from 'app/components/common/BaseText'
import { useModalHook } from './hook'
import { IPropsFinishCampaign } from './type'
import { BaseRow } from 'app/components/common/BaseRow'

export const FinishCampaign = (props: IPropsFinishCampaign) => {
  const { visible, isLoading, showModal, handleCancel, handleSubmit, t } =
    useModalHook(props)

  return (
    <BaseModalManagement
      isLoading={isLoading}
      typeButtonOpen="delete"
      titleKeyI18n={R.strings.finish_campaign}
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.finish_campaign}
      confirmKeyI18n={R.strings.finish_campaign}
      handleSubmit={handleSubmit}
      visible={visible}
      widthModal={500}
      descriptionDelete={
        <BaseRow gutter={8} align={'middle'} justify={'center'}>
          <BaseCol>
            <BaseText
              children={t(R.strings.are_you_want_finish_campaign)}
              fontSize="xs"
            />
          </BaseCol>
          <BaseCol>
            <BaseText
              colorText="statesRedColor"
              fontWeight="medium"
              children={`"${props?.name}"`}
              fontSize="xs"
            />
          </BaseCol>
        </BaseRow>
      }
    />
  )
}
