/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteFilled, ImportOutlined, InboxOutlined } from '@ant-design/icons'
import { requestImportCampaignForUpdate } from 'app/api/marketing/discount-campaign'
import { DiscountCampaignMessageCode } from 'app/api/marketing/discount-campaign/constant'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseTabs } from 'app/components/common/BaseTabs'
import BaseText from 'app/components/common/BaseText'
import { BaseUpload } from 'app/components/common/BaseUpload'
import { BaseButtonsForm } from 'app/components/common/forms/BaseButtonsForm'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { _DEV_ } from 'app/constant'
import { useUpdateCampaignContext } from 'app/containers/Marketing/DiscountCampaignManagement/UpdateCampaign/context'
import { isEqual } from 'lodash'
import { FONT_SIZE, normFile, PADDING, ResponseType } from 'parkway-web-common'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { HeaderTabImportModal } from '../../../../components'
import { InitResponseImport, TabKey } from './constant'
import { ListTreatmentLayout } from './layouts'
import { IResponseImportFile, StatusEnum } from './type'

export const ImportFileModal = () => {
  const { itemCampaign, handleImportTreatment } = useUpdateCampaignContext()
  const refModal = useRef<IRefModal>({})
  const { t } = useTranslation()
  const [fileSelected, setFileSelected] = useState<File | null | undefined>(
    null,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [tabKey, setTabKey] = useState(TabKey.UPDATE)

  const [responseImport, setResponseImport] =
    useState<IResponseImportFile>(InitResponseImport)

  const _onConfirmModal = async () => {
    const listUpdateSuccess = responseImport?.modify?.filter(item =>
      isEqual(item?.status, StatusEnum.SUCCESS),
    )
    const listInsertSuccess = responseImport?.insert?.filter(item =>
      isEqual(item?.status, StatusEnum.SUCCESS),
    )
    const listRemoveSuccess = responseImport?.remove?.filter(item =>
      isEqual(item?.status, StatusEnum.SUCCESS),
    )
    
    handleImportTreatment?.({
      insert: listInsertSuccess,
      modify: listUpdateSuccess,
      remove: listRemoveSuccess,
    })

    refModal?.current?.hide?.()
    setFileSelected(null)
    setResponseImport(InitResponseImport)
  }

  const onRemoveFile = () => {
    setFileSelected(null)
    setResponseImport(InitResponseImport)
  }

  const onChangeFile = async (values: { file: any }) => {
    if (!itemCampaign?._id) {
      return
    }

    const { file } = values
    const { lastModified, name, type, originFileObj } = file
    const newFile = new File([originFileObj], name, { type, lastModified })
    if (!newFile) return

    setFileSelected(newFile)

    try {
      setIsLoading(true)
      const formData = new FormData()

      formData.append('file', newFile)

      const res: ResponseType<IResponseImportFile> =
        await requestImportCampaignForUpdate(itemCampaign?._id, formData)
      if (
        isEqual(
          res?.msgcode,
          DiscountCampaignMessageCode.DiscountCampaign.success,
        )
      ) {
        setResponseImport(res?.data)
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const onOpenModal = () => {
    refModal.current?.open?.()
  }

  const handleCancel = () => {
    setFileSelected(null)
    setResponseImport(InitResponseImport)
  }

  const isDisableSubmit = useMemo(() => {
    if (!fileSelected) return true

    if (
      !responseImport?.modify?.length &&
      !responseImport?.insert?.length &&
      !responseImport?.remove?.length
    ) {
      return true
    }

    return false
  }, [responseImport, fileSelected])

  return (
    <ModalComponent
      titleModal={R.strings.import_file}
      widthModal={1000}
      buttonOpenCustom={
        <BaseButton
          icon={<ImportOutlined rev={undefined} />}
          onClick={onOpenModal}
        >
          {t(R.strings.import)}
        </BaseButton>
      }
      ref={refModal}
      handleSubmit={_onConfirmModal}
      isShowLineBottom={false}
      handleCancel={handleCancel}
      isDisableSubmit={isDisableSubmit}
      renderContent={
        <BaseSpace size={8}>
          <DraggerInput
            name="dragger"
            valuePropName="file"
            getValueFromEvent={normFile}
            noStyle
          >
            <BaseUpload.Dragger
              name="files"
              onChange={onChangeFile}
              customRequest={() => undefined}
              showUploadList={false}
              accept=".xls, .xlsx"
            >
              <DraggerWrapper>
                <IconMailBox rev={undefined} size={32} />
              </DraggerWrapper>
            </BaseUpload.Dragger>
          </DraggerInput>
          {fileSelected && (
            <FileNameDisplayWrapper
              gutter={20}
              justify={'start'}
              align={'middle'}
            >
              <BaseCol>
                <BaseText
                  fontSize="xs"
                  fontWeight="medium"
                  colorText="statesGreenColor"
                >
                  {fileSelected?.name}
                </BaseText>
              </BaseCol>
              <BaseCol>
                <BaseButton
                  icon={<DeleteFilled rev={undefined} onClick={onRemoveFile} />}
                  size="small"
                />
              </BaseCol>
            </FileNameDisplayWrapper>
          )}

          {fileSelected ? (
            <BaseSpace size={8}>
              <BaseTabs
                onChange={key => setTabKey(key as TabKey)}
                items={[
                  {
                    key: TabKey.UPDATE,
                    label: (
                      <HeaderTabImportModal
                        tabKey={tabKey}
                        title={t(R.strings.update)}
                        number={responseImport?.modify?.length ?? 0}
                        correctKey={TabKey.UPDATE}
                      />
                    ),
                    children: (
                      <ListTreatmentLayout
                        data={responseImport?.modify}
                        isLoading={isLoading}
                      />
                    ),
                  },
                  {
                    key: TabKey.INSERT,
                    label: (
                      <HeaderTabImportModal
                        tabKey={tabKey}
                        title={t(R.strings.add)}
                        number={responseImport?.insert?.length ?? 0}
                        correctKey={TabKey.INSERT}
                      />
                    ),
                    children: (
                      <ListTreatmentLayout
                        data={responseImport?.insert}
                        isLoading={isLoading}
                      />
                    ),
                  },
                  {
                    key: TabKey.REMOVE,
                    label: (
                      <HeaderTabImportModal
                        tabKey={tabKey}
                        title={t(R.strings.delete)}
                        number={responseImport?.remove?.length ?? 0}
                        correctKey={TabKey.REMOVE}
                      />
                    ),
                    children: (
                      <ListTreatmentLayout
                        data={responseImport?.remove}
                        isLoading={isLoading}
                      />
                    ),
                  },
                ]}
              />
            </BaseSpace>
          ) : null}
        </BaseSpace>
      }
    />
  )
}

const DraggerInput = styled(BaseButtonsForm.Item)``

const DraggerWrapper = styled.div`
  padding: ${PADDING.xxl} 0px;
  & svg {
    fill: var(--gray);
  }
`

const IconMailBox = styled(InboxOutlined)`
  & svg {
    width: ${FONT_SIZE.xxxl};
    height: ${FONT_SIZE.xxxl};
  }
`

const FileNameDisplayWrapper = styled(BaseRow)`
  color: var(--primary-color);
  padding-top: ${PADDING.xxs};
  & svg {
    fill: red;
  }
`
