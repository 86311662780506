import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import ApproveDayOff from 'app/containers/HumanResources/ApproveDayOff'

const ApproveDayOffPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.approve_day_off)}</PageTitle>
      <ApproveDayOff />
    </>
  )
}
const ApproveDayOffPage = memo(ApproveDayOffPageCpn, isEqual)

export default ApproveDayOffPage
