/* eslint-disable @typescript-eslint/no-explicit-any */
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { IDateValueReportBooking } from 'app/api/customer-care/model/customer-care'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { Loading } from 'app/components/common/Loading'
import { SectionBase } from 'app/components/common/SectionBase'
import { BaseChart } from 'app/components/common/charts/BaseChart'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseManagementWrapper } from 'app/components/tables/BaseTableManagement/styles'
import { usePagination } from 'app/hook'
import { useGetReportBookingWebsite } from 'app/react-query/hook/report'
import { EChartsOption } from 'echarts-for-react'
import { isEqual } from 'lodash'
import {
  BORDER_RADIUS,
  DateUtil,
  FONT_SIZE,
  FONT_WEIGHT,
  FORMAT_YYYYMMDD,
  PADDING,
  colors,
  moment
} from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
interface DataItem {
  name: string
  value: number
}

const oneDay = 24 * 3600 * 1000

const getStartEndDate = (endDate: Date) => {
  const startDateMoment = moment(endDate).add(-30, 'days')
    const startDateFormat = startDateMoment.format(FORMAT_YYYYMMDD)
    const endDateFormat = moment(endDate).format(FORMAT_YYYYMMDD)

    return {
      startDate: DateUtil.formatDDMMYYY(startDateFormat),
      endDate: DateUtil.formatDDMMYYY(endDateFormat),
    }
}

const BookingWebsiteReport = () => {
  const { t } = useTranslation()
  const [days] = useState(30)
  const [endDate, setEndDate] = useState(new Date(+(new Date()) + oneDay))
  const getTimeText = useMemo(() => {
    const endDateCustom = new Date(+endDate - oneDay)
    return getStartEndDate(endDateCustom)
  }, [endDate])

  const timeParams = useMemo(() => {
    return getStartEndDate(endDate)
  }, [endDate])

  const { data: dataFormApi, isLoading } = useGetReportBookingWebsite({
    endDate: timeParams.endDate,
    startDate: timeParams.startDate,
  })

  const { flattenDataList } = usePagination()

  const dataFlatten: IDateValueReportBooking[] = useMemo(() => {
    return flattenDataList(dataFormApi) ?? []
  }, [dataFormApi])

  const data: DataItem[] = useMemo(() => {
    let now = endDate
    const newData: DataItem[] = []
    for (let i = days; i > 0; i--) {
      now = new Date(+now - oneDay)
      const date = moment(now).format(FORMAT_YYYYMMDD)
      const dateFormatDDMMYYY = moment(date).format('DD-MM-YYYY')

      const dataExist = dataFlatten?.find(itm =>
        isEqual(itm?._id, dateFormatDDMMYYY),
      )

      newData.push({
        name: dateFormatDDMMYYY,
        value: dataExist ? dataExist?.arrivals : 0,
      })
    }
    return newData?.reverse()
  }, [endDate, dataFlatten])

  const optionsData = useMemo(() => {
    const option: EChartsOption = {
      tooltip: {
        trigger: 'axis',
        formatter: function (params: any) {
          const paramsData = params?.[0]
          const date = paramsData?.name

          return `<div>
                    <span style="
                    font-size: ${FONT_SIZE.xs}; 
                    font-weight: ${FONT_WEIGHT.semibold};
                    color: var(--primary-color);
                    ">
                        ${date}
                    </span>
                    <span style="
                    margin-left: ${PADDING.md}; 
                    font-size: ${FONT_SIZE.xs}; 
                    font-weight: ${FONT_WEIGHT.semibold};
                    color: #216AE2;
                    ">
                        ${paramsData?.value} ${t(
            R.strings.arrivals,
          ).toLowerCase()}
                    </span>
                </div>`
        },
        axisPointer: {
          animation: false,
        },
      },
      grid: {
        top: 100,
        bottom: 60,
        left: 50,
        right: 30,
      },
      calculable: true,
      xAxis: [
        {
          splitLine: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            rotate: 45,
            textStyle: {
              baseline: 'top',
              color: colors.primary,
              fontSize: FONT_SIZE.xxxs,
              fontWeight: FONT_WEIGHT.regular,
            },
          },
          data: data?.map(itm => itm?.name ?? '-'),
          type: 'category',
          axisTick: { show: false },
          axisLine: {
            lineStyle: {
              color: '#F4F6F9',
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          boundaryGap: [0, '100%'],
          splitLine: {
            show: false,
          },
          name: t(R.strings.number_arrivals),
          axisLine: { show: false },
          axisTick: { show: false },
          nameLocation: 'end',
          nameTextStyle: {
            align: 'right',
            padding: [0, 0, 20, 0],
          },
        },
      ],
      series: [
        {
          type: 'line',
          showSymbol: false,
          data: data?.map(itm => itm?.value ?? 0),
          animationDelay: (idx: number) => idx * 10,
          lineStyle: {
            color: '#FA9F34',
          },
        },
      ],
      dataZoom: [
        {
          type: 'inside',
          show: false,
          zoomLock: true,
          endValue: data?.map((itm) => itm?.name)?.[data?.length - 1] ?? timeParams.endDate,
          startValue: data?.map((itm) => itm?.name)?.[data?.length - 18],
          rangeMode: ['value', 'value'],
        },
      ],
    }
    return option
  }, [data])

  const isDisableNextEndDate = useMemo(() => {
    const todayMoment = moment()
    const endDateCustom = new Date(+endDate - oneDay)

    return todayMoment.isSame(endDateCustom, 'day')
  }, [endDate])

  const onNextTime = () => {
    setEndDate(new Date(+endDate + oneDay * 30))
  }

  const onPreviousTime = () => {
    setEndDate(new Date(+endDate - oneDay * 30))
  }

  return (
    <BaseManagementWrapper>
      <HeaderPage titleI18nKey={R.strings.booking_website_report} />
      <SectionBase>
        <BaseRow gutter={[20, 20]} align={'middle'} justify={'space-between'}>
          <BaseCol>
            <TitlePageText>
              {t(R.strings.number_of_booking_in_many_days, { count: days })}
            </TitlePageText>
          </BaseCol>
          <BaseCol>
            <BaseRow gutter={[20, 20]} align={'middle'}>
              <BaseCol>
                <TimeWrapper>
                  {t(R.strings.time_from_to, {
                    from: getTimeText.startDate,
                    to: getTimeText.endDate,
                  })}
                </TimeWrapper>
              </BaseCol>
              <Line />
              <BaseCol>
                <BaseRow align={'middle'} gutter={[10, 10]}>
                  <BaseCol>
                    <BaseButton onClick={onPreviousTime}>
                      <LeftOutlined rev={undefined} />
                    </BaseButton>
                  </BaseCol>
                  <BaseCol onClick={onNextTime}>
                    <BaseButton disabled={isDisableNextEndDate}>
                      <RightOutlined rev={undefined} />
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>
        <BaseChart option={optionsData} height={600} />
      </SectionBase>
      {isLoading && <Loading />}
    </BaseManagementWrapper>
  )
}
export default BookingWebsiteReport

const TitlePageText = styled.div`
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.md};
`

const TimeWrapper = styled.div`
  color: var(--primary-color);
  padding: ${PADDING.xxs} ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  background-color: #f9fafb;
`

export const Line = styled.div`
  width: 1px;
  height: 20px;
  background-color: var(--primary-color);
`
