import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import { ReactNode } from 'react'
import styled from 'styled-components'

interface IProps {
  fontSize?: string
  fontWeight?: string 
  opacity?: string
  colorText?: string
  children: ReactNode
}

const TextCustomComponent = ({
  colorText,
  fontSize,
  fontWeight,
  opacity,
  children,
}: IProps) => {
  return (
    <TextCustom
      $colorText={colorText}
      $fontWeight={fontWeight}
      $opacity={opacity}
      $fontSize={fontSize}
    >
      {children}
    </TextCustom>
  )
}

export const TextCustom = styled.div<{
  $fontSize?: string
  $fontWeight?: string
  $opacity?: string
  $colorText?: string
}>`
  color: ${props => props?.$colorText ?? 'var(--primary-color)'};
  font-size: ${props => props?.$fontSize ?? FONT_SIZE.xs};
  font-weight: ${props => props?.$fontWeight ?? FONT_WEIGHT.regular};
  opacity: ${props => props?.$opacity ?? '1'};
`
export default TextCustomComponent
