import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTablePaymentTypeHook } from './PaymentType/hook'
import { initialPagination } from './common-model'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { FunctionPermissionEnum, ModulePermissionEnum } from 'app/common/permission-module'
import R from 'app/assets/R'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { NOTIFICATION_TYPE } from 'app/common/constants'
import { downloadNotificationController } from 'app/controllers/download-notification-controller'
import { handleFileDownload } from 'app/api/accounting'
import { changeMessage } from 'app/redux/slices/profileSlice'
import { isEqual } from 'lodash'

interface IMetadata {
  totalPaid?: number
  totalNetAmount?: number
  totalDeposit?: number
}
export const useTableHook = () => {
  const { t } = useTranslation()

  const [filterResponse, setFilterResponse] = useState<IResponseFilter>()
  const [exportFile, setExportFile] = useState<string[]>([])
  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()
  const profile = useAppSelector(state => state.profileSlice)
  const notificationMessage = profile?.message
  const dispatch = useAppDispatch()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.ACCOUNTING,
      FunctionPermissionEnum.READ_CUSTOMER_DEBT_MANAGEMENT,
      R.strings.role,
    )
  }, [])

  const PaymentTypeHook = useTablePaymentTypeHook(filterResponse)


  useEffect(() => {
    if (notificationMessage?.type !== NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT_CONTRACT) return;

    const payload = JSON.parse(notificationMessage?.payload || '')
    const successData = payload?.successData || []
    const errorData = payload?.errorData || []

    if (errorData?.length) {
      downloadNotificationController.downloadErrored({
        duration: 2,
        key: NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT_CONTRACT,
        message: t(R.strings.manage_customer_debt_contract),
        description: t(R.strings.download_errored),
        cancel: t(R.strings.cancel),
      })
    } else if(!isEqual(exportFile, successData)) {
      setExportFile(successData)
      downloadNotificationController.close(
        NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT_CONTRACT,
      )
    }
  }, [notificationMessage?.currentTime, notificationMessage?.type])

  const downloadFileForExport = () => {
    if (!exportFile?.length) return;

    exportFile.forEach(file => handleFileDownload(file, t, t(R.strings.manage_customer_debt_contract), NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT_CONTRACT))

    setExportFile([])
    dispatch(changeMessage({}))
  }

  useEffect(() => {
    downloadFileForExport();

    return downloadFileForExport;
  }, [exportFile])

  const expandedRowKeys = useMemo(() => {
    return PaymentTypeHook.expandedRowKeys
  }, [PaymentTypeHook])

  const tableData = useMemo(() => {
    return PaymentTypeHook.tableData
  }, [PaymentTypeHook])

  const metadata: IMetadata = useMemo(() => {
    return PaymentTypeHook.metadata
  }, [PaymentTypeHook])

  const handleExpand = useMemo(() => {
    return PaymentTypeHook.handleExpand
  }, [PaymentTypeHook])

  const onClickExport = useMemo(() => {
    return PaymentTypeHook.onClickExport
  }, [PaymentTypeHook])

  const handleChangeFilter = filter => {
    setFilterResponse(filter)
    PaymentTypeHook?.fetch(initialPagination, filter)
  }

  const columns = useMemo(() => {
    return PaymentTypeHook.columns
  }, [t, PaymentTypeHook])

  const handleTableChange = pagination => {
    PaymentTypeHook?.fetch(pagination, filterResponse)
  }

  return {
    columns,
    tableData,
    metadata,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleChangeFilter,
    filterResponse,
    onClickExport,
  }
}
