import { BaseSpace } from 'app/components/common/BaseSpace'
import { SearchInput } from 'app/components/common/inputs/SearchInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  media,
} from 'parkway-web-common'
import styled from 'styled-components'

export const HeadWrapper = styled(BaseSpace)`
  padding: ${PADDING.xs};
  width: 100%;
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-picker-focused.ant-picker {
    border: 1px solid ${convertedVariables.neutralBlack9Color};
  }
`
export const TitleTable = styled.span`
  font-size: ${FONT_SIZE.xs};
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.bold};
`
export const Picker = styled(BaseDatePicker)<{
  $prefix: string
  $width?: number
}>`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  &:hover {
    border: 1px solid ${convertedVariables.neutralBlack9Color};
  }
  .ant-picker-input::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$width ?? 120}px;
  }
`

export const TextFilter = styled.span`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  color: var(--primary-color);
`

export const SelectBaseStyled = styled(BaseSelect)<{
  $prefix: string
  $width?: number
}>`
  width: 100%;
  .ant-select-selector::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$width ?? 70}px;
    align-items: center;
    display: flex;
  }
`

export const InputSearch = styled(SearchInput)`
  .ant-input-group-addon {
    display: none;
  }

  @media only screen and ${media.md} {
    .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
      border-radius: ${BORDER_RADIUS};
      border-color: var(--neutral-black-color);
      padding: 0px ${PADDING.xxxxs};
    }
  }
`
export const BaseWrapper = styled.div`
  margin-bottom: 10px;
`
