import { BaseButton } from 'app/components/common/BaseButton'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { InputPassword } from 'app/components/common/inputs/InputPassword'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { BORDER_RADIUS, FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const ButtonCreateAction = styled(BaseButton)`
  background-color: var(--states-orange-color);
  color: var(--background-color);
  transition: all 0.5s ease;
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--background-color);
    color: var(--states-orange-color);
    border-color: var(--states-orange-color);
    transition: all 0.5s ease;
  }
`
export const LabelWrapper = styled(BaseRow)`
  color: var(--main-2-color);
`

export const RequireDot = styled.div`
  color: var(--states-red-color);
`
export const DashLine = styled(BaseDivider)``

export const TitleForm = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.bold};
`

export const FormItem = styled(BaseForm.Item)``

export const FormInput = styled(BaseInput)``

export const FormInputNumber = styled(InputNumber)`
    width: 100%;

  & .ant-input-group-addon {
    font-size: ${FONT_SIZE.xs} !important;
    font-weight: ${FONT_WEIGHT.regular} !important;
    color: #a5abb3 !important;
    width: 0 !important;
    min-width: unset !important;
  }
`

export const FormInputPassword = styled(InputPassword)``

export const SelectBaseStyled = styled(BaseSelect)``


export const PermissionContainer = styled(BaseSpace)`
  width: 100%;
  margin-top: ${PADDING.md};
  padding-bottom: ${PADDING.md};
`

export const PermissionHead = styled(BaseRow)``

export const PermissionHeadTitle = styled.div`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
  color: var(--main-2-color);
`
export const PermissionHeadButton = styled(BaseButton)``

export const PermissionHeadButtonText = styled(BaseButton)`
  color: var(--states-blue-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
`
export const AreaShowPermission = styled(BaseRow)`
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  border: 1px solid #f0f1f3; // need change
  margin: 0px !important;
`
