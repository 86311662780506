import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import { FilterReport } from 'app/components/tables/BaseTableReport/FilterReport'
import * as S from 'app/components/tables/BaseTableReport/styles'
import React from 'react'
import SelectServiceComponent from './components/SelectServiceComponent'
import { useTableHook } from './hook'
import { BaseCol } from 'app/components/common/BaseCol'

const Report004DoctorContainer: React.FC = () => {
  const {
    columns,
    tableData,
    expandedRowKeys,
    handleExpand,
    handleChangeFilter,
    exportExcel,
    handleChangeService,
  } = useTableHook()

  return (
    <S.BaseReportWrapper>
      <HeaderPage
        titleI18nKey={R.strings.report_004}
        isExport
        onPressExport={exportExcel}
      />
      <S.RootWrapper>
        <S.HeadWrapper align={'top'} gutter={15}>
          <FilterReport
            handleChangeFilter={handleChangeFilter}
            isSelectFilter
            selectFilterGroup="WEEK_MONTH_QUARTER_YEAR"
            isUsingWeekMonthCorrect
            isSelectQuarter
            otherFilterComponent={
              <BaseCol>
                <SelectServiceComponent onChange={handleChangeService} />
              </BaseCol>
            }
          />
        </S.HeadWrapper>
        <BaseTableReport
          columns={columns}
          dataSource={tableData.data}
          loading={tableData.loading}
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            defaultExpandAllRows: true,
            expandIcon: () => null,
            rowExpandable: record =>
              record?.children && record?.children?.length > 0,
          }}
          rowClassName="row-overwrite-style"
          pagination={false}
          scroll={{ x: true, y: tableData?.data?.length ? 480 : undefined }}
        />
      </S.RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default Report004DoctorContainer
