import R from 'app/assets/R'
import { ModulePermissionEnum } from 'app/common/permission-module'
import { BaseLayoutReport } from 'app/components/common/BaseLayoutReport'
import { data } from './data'
import { useTranslation } from 'react-i18next'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { useEffect } from 'react'

export const ReportContainer = () => {
  const { t } = useTranslation()
  const { isHavePermissionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionAndGoBack(ModulePermissionEnum.REPORT, R.strings.report)
  }, [t])

  return (
    <BaseLayoutReport
      data={data}
      titleI18nKey={R.strings.report}
      modulePermission={ModulePermissionEnum.REPORT}
    />
  )
}
