import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PropsOverlay } from '..'
import * as S from './styles'

export const ActionsOverlay: React.FC<PropsOverlay> = ({
  actionComponent,
  deleteComponent,
  updateComponent,
  onClickDelete,
  onClickUpdate,
  onHide,
  ...props
}) => {
  const { t } = useTranslation()
  const _onClickUpdate = () => {
    onClickUpdate?.()
    onHide?.()
  }

  const _onClickDelete = () => {
    onClickDelete?.()
    onHide?.()
  }

  return (
    <S.ActionsOverlayMenu {...props}>
      <BaseSpace direction="vertical" size={10} split={<S.SplitDivider />}>
        {actionComponent ?? (
          <>
            {updateComponent ?? (
              <S.ButtonAction onClick={_onClickUpdate}>
                {t(R.strings.update)}
              </S.ButtonAction>
            )}
            {deleteComponent ?? (
              <S.ButtonAction onClick={_onClickDelete}>
                {t(R.strings.delete)}
              </S.ButtonAction>
            )}
          </>
        )}
      </BaseSpace>
    </S.ActionsOverlayMenu>
  )
}
