/* eslint-disable @typescript-eslint/no-explicit-any */
import { AgeGroupEnum, GenderEnum } from 'app/containers/Report/Report014/enum'
import { ResponseType } from 'parkway-web-common'

export interface IDataReport14 {
  detail: Record<string, Record<string, ServiceDataReport14>>
  group: Record<string, ServiceDataReport14>
  total: ServiceDataReport14
}

export interface ServiceDataReport14 {
  months: Record<string, MonthData>
  quarters: Record<string, QuarterData>
  years: Record<string, YearData>
}

interface MonthData {
  case?: number
  revenue?: number
  weeks?: Record<
    string, // Key of type string (represents week number)
    WeekData
  >
  revenuePercent?: number
  casePercent?: number
  previousCase?: number
  previousRevenue?: number
}

interface QuarterData {
  case?: number
  revenue?: number
  previousCase?: number
  previousRevenue?: number
  revenuePercent?: number
  casePercent?: number
}

interface YearData {
  case?: number
  revenue?: number
  previousCase?: number
  previousRevenue?: number
  revenuePercent?: number
  casePercent?: number
}

interface WeekData {
  case?: number
  revenue?: number
  previousCase?: number
  previousRevenue?: number
  revenuePercent?: number
  casePercent?: number
}

export type ResponseReport14Type = ResponseType<IDataReport14>

export enum GenderReport14Enum {
  F = 'F',
  M = 'M',
  Other = 'Other',
}

// payload

export enum TypeReport14Enum {
  CASE = 'Case',
  REVENUE = 'Revenue',
}

export interface IParamsGetReport14 {
  year?: number
  location?: string[]
  patientAgeFrom?: number
  patientAgeTo?: number
  patientGender?: string
  exportType?: "CASE" | "REVENUE"
}

export interface IFilterReport14 {
  type: TypeReport14Enum
  year?: number | string
  area?: string
  clinic?: string[]
  age?: AgeGroupEnum
  gender?: GenderEnum
}
