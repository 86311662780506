/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance, UploadFile, UploadProps } from 'antd'
import {
  requestCreateItemManagement,
  requestDetailItemManagement,
  requestUpdateItemManagement,
} from 'app/api/item'
import { ItemMessageCode } from 'app/api/item/constant'
import { IItemManagement } from 'app/api/item/model/management'
import {
  IProductPropertiesCombinedData,
  IProductProperty,
} from 'app/api/product-property/model/management'
import R from 'app/assets/R'
import { IMAGE_ACTION } from 'app/common/config'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { usePagination } from 'app/hook'
import { useGetItemList } from 'app/react-query/hook/item'
import { IResponseQueryList } from 'app/react-query/model/common'
import { first, isEqual, unescape, uniqBy } from 'lodash'
import { ResponseType, getRandomUuid } from 'parkway-web-common'
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { ISupplies } from '../model/supplies'
import {
  IJobTitleOfTreatment,
  ITreatment,
  initTreatment,
} from '../model/treatment'
import { IFormData, IFormRule, ItemType } from './type'
import { useGetProductPropertyTreatmentList } from 'app/react-query/hook/product-property'
import { StatusEnum } from 'app/common/enum'
import { useGetJobTitleManagementList } from 'app/react-query/hook/job-title'
import { useGetJobTitleLevelManagementList } from 'app/react-query/hook/job-title-level'
import { IJobTitleManagement } from 'app/api/jobtitle/model/job-title-management'
import { IJobTitleLevelManagement } from 'app/api/jobtitlelevel/model/job-title-level-management'

interface ICreateUpdateServiceContext {
  form?: FormInstance<IFormData>
  handleSubmit?: (values: IFormData) => void
  rules?: IFormRule
  onChangeMainImage?: (e: { fileList: UploadFile[] }) => void
  handleChangeListImage?: UploadProps['onChange']
  mainImage?: UploadFile[]
  listImage?: UploadFile[]
  onChangeMainImageProperty?: (e: { fileList: UploadFile[] }) => void
  handleChangeListImageProperty?: UploadProps['onChange']
  mainImageProperty?: UploadFile[]
  listImageProperty?: UploadFile[]

  listTreatment?: ITreatment[]
  itemTreatments?: ITreatment[]
  productProperties?: IProductProperty[]
  jobTitles?: IJobTitleManagement[]
  jobTitleLevels?: IJobTitleLevelManagement[]

  isLoadingJobTitle?: boolean
  isLoadingJobTitleLevel?: boolean
  isLoadingTreatment?: boolean
  isLoadingProductProperty?: boolean
  onAddNewTreatment?: () => void
  onRemoveTreatment?: (index: number) => void
  onAddSuppliesOfTreatment?: (treatmentId: string, supplies: ISupplies) => void
  onRemoveSuppliesOfTreatment?: (
    treatmentId?: string,
    suppliesId?: string,
  ) => void
  onAddJobTitleOfTreatmentOfTreatment?: (treatmentId: string) => void
  onRemoveJobTitleOfTreatmentOfTreatment?: (
    treatmentId: string,
    jobTitleId: string,
  ) => void
  onChangeTimeOfTreatment?: (treatmentId: string, time: number) => void
  onChangeSuppliesOfTreatment?: (
    treatmentId: string,
    index: number,
    supplies: ISupplies,
  ) => void
  onChangeJobTitleOfTreatmentOfTreatment?: (
    treatmentId: string,
    index: number,
    jobTitle: IJobTitleOfTreatment,
  ) => void
  onChangeTreatmentInfo?: (index: number, newTreatment: ITreatment) => void
  editorRef?
  onAddNewProductProperties?: () => void
  onRemoveProductProperties?: (index: number) => void
  onChangeProductPropertiesInfo?: (
    index: number,
    newProductProperties: IProductProperty,
  ) => void
}

export const CreateUpdateContext = createContext<ICreateUpdateServiceContext>({
  form: undefined,
})

interface ICreateUpdateServiceProviderProps {
  children: any
}

export const CreateUpdateProvider: React.FC<
  ICreateUpdateServiceProviderProps
> = ({ children }) => {
  const { id, type } = useParams()

  const [form] = BaseForm.useForm<IFormData>()
  const [mainImage, setMainImage] = useState<UploadFile[]>([])
  const [listImage, setListImage] = useState<UploadFile[]>([])
  const editorRef = useRef<HTMLFormElement>(null)

  const getDetail = async () => {
    if (!id) {
      return
    }
    try {
      const res: ResponseType<IItemManagement[]> =
        await requestDetailItemManagement(id)

      if (
        isEqual(res?.msgcode, ItemMessageCode.ItemManagement.successGetData)
      ) {
        const data = res?.data?.[0]

        const addPrefixImage = (path): string => {
          return `${IMAGE_ACTION.IMAGE_SERVER}/${unescape(path)}`
        }

        const setImage = (string?: string, index?: string): UploadFile => {
          if (!string) {
            return {
              uid: '-1',
              name: '',
              fileName: '',
              thumbUrl: '',
              url: '',
            }
          }

          const image: UploadFile = {
            uid: index ?? '-1',
            name: index ?? '-1',
            fileName: string,
            thumbUrl: addPrefixImage(string),
            url: addPrefixImage(string),
          }

          return image
        }

        setMainImage([setImage(data?.mainImagesUrl)])

        const dataDetailImagesUrl = data.detailImagesUrl?.map((item, index) =>
          setImage(item, index.toString()),
        ) as UploadFile[]

        setListImage(dataDetailImagesUrl)
        const productPropertiesChildren = productProperties?.flatMap(
          prop => prop.productPropertiesChildren,
        )
        const productPropertiesCombinedData =
          data?.productPropertiesCombinedData?.map(
            productPropertiesCombinedData => ({
              ...productPropertiesCombinedData,
              nameCombined: productPropertiesCombinedData?.productPropertiesIds
                ?.map(
                  id =>
                    productPropertiesChildren.find(
                      property => id === property._id,
                    )?.name,
                )
                ?.join(' / '),
              mainImagesUrl: [
                setImage(productPropertiesCombinedData.mainImagesUrl),
              ],
              detailImagesUrl:
                productPropertiesCombinedData.detailImagesUrl?.map(
                  (detailImagesUrl, index) =>
                    setImage(detailImagesUrl, index.toString()),
                ),
            }),
          )

        form.setFieldsValue({
          _id: data._id,
          oldId: data?.oldId,
          name: data?.name,
          categoryid: data?.categoryid,
          type: data?.type,
          description: data?.description,
          detailImagesUrl: data?.detailImagesUrl ?? undefined,
          purchaseMeasurementUnitId:
            data?.purchaseMeasurementUnitId ?? undefined,
          usingMeasurementUnitId: data?.usingMeasurementUnitId ?? undefined,
          productPropertiesCombinedData:
            productPropertiesCombinedData ?? undefined,
          productPropertiesIds: data?.productPropertiesIds ?? undefined,
          isInventoryManagement: data?.isInventoryManagement ?? undefined,
          mainImagesUrl: data?.mainImagesUrl ?? undefined,
          itemBrandId: data?.itemBrandId,
          doctorJobTitleLevelIds: data?.doctorJobTitleLevelIds,
          code: data?.code,
          itemIds: data.itemIds,
          status: data?.status,
        })
      }
    } catch (error) {
      return null
    }
  }

  const { flattenDataList } = usePagination()

  const { data: productPropertyData, isLoading: isLoadingProductProperty } =
    useGetProductPropertyTreatmentList()

  const productProperties = useMemo(() => {
    const flattenData: IResponseQueryList<IProductProperty[]> =
      flattenDataList(productPropertyData)
    return flattenData?.data ?? []
  }, [productPropertyData])

  const { data: dataTreatment, isLoading: isLoadingTreatment } = useGetItemList(
    {
      type: 'treatment',
      pagesize: 1000,
    },
  )
  const itemTreatments = useMemo(() => {
    const flattenData: IResponseQueryList<ITreatment[]> =
      flattenDataList(dataTreatment)
    return flattenData?.data ?? []
  }, [dataTreatment])

  useEffect(() => {
    const delay = 500
    const handler = setTimeout(() => getDetail(), delay)
    return () => clearTimeout(handler)
  }, [id])

  const [listTreatment, setListTreatment] = useState<ITreatment[]>(
    // dataTreatment?.slice(0, 4),
    [],
  )
  const [listProductProperties, setListProductProperties] = useState<
    IProductProperty[]
  >([])

  const { data: jobTitleData, isLoading: isLoadingJobTitle } =
    useGetJobTitleManagementList()

  const { data: jobTitleLevelData, isLoading: isLoadingJobTitleLevel } =
    useGetJobTitleLevelManagementList()

  const jobTitles = useMemo(() => {
    const flattenData: IResponseQueryList<IJobTitleManagement[]> =
      flattenDataList(jobTitleData)
    return flattenData?.data ?? []
  }, [jobTitleData])

  const jobTitleLevels = useMemo(() => {
    const flattenData: IResponseQueryList<IJobTitleLevelManagement[]> =
      flattenDataList(jobTitleLevelData)

    return flattenData?.data ?? []
  }, [jobTitleLevelData])

  const { t } = useTranslation()

  const rules = useMemo(() => {
    return {
      categoryid: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.category),
          }),
        },
      ],
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.item_management_content_name),
          }),
        },
      ],
      code: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.item_management_content_code),
          }),
        },
      ],
      itemBrandId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.item_management_content_producer),
          }),
        },
      ],
      isInventoryManagement: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.inventory_management),
          }),
        },
      ],
      purchaseMeasurementUnitId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.item_management_content_purchase_unit),
          }),
        },
      ],
      usingMeasurementUnitId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.item_management_content_producer),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
    } as IFormRule
  }, [t])

  const handleSubmit = async (values: IFormData) => {
    const params = handleParamService(values)

    try {
      let response

      if (id) {
        response = await requestUpdateItemManagement({
          id,
          body: params,
        })
      } else {
        response = await requestCreateItemManagement(params)
      }

      if (
        isEqual(
          response?.msgcode,
          ItemMessageCode.ItemManagement.successPostData,
        )
      ) {
        notificationController.success({
          message: 'Success',
        })
      } else {
        notificationController.error({
          message: response?.message,
        })
      }
    } catch (error: any) {
      notificationController.error({
        message: error.message,
      })
    }
  }

  const onChangeMainImage = (e: { fileList: UploadFile[] }) => {
    if (Array.isArray(e)) {
      return e
    }

    setMainImage(e.fileList || mainImage)
    return e && e.fileList
  }

  const handleChangeListImage: UploadProps['onChange'] = ({
    fileList: newFileList,
  }) => setListImage(newFileList)

  // treatment

  const onAddNewTreatment = () => {
    setListTreatment([...listTreatment, initTreatment as ITreatment])
  }

  const onRemoveTreatment = (index: number) => {
    const newData = listTreatment.filter((_, i) => i !== index)
    setListTreatment(newData)
  }

  const onChangeTreatmentInfo = (index: number, newTreatment: ITreatment) => {
    const newData = listTreatment.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          ...newTreatment,
        }
      }
      return item
    })
    setListTreatment(newData)
  }

  // property product
  const onAddNewProductProperties = () => {
    setListProductProperties([...listProductProperties])
  }

  const onRemoveProductProperties = (index: number) => {
    const newData = listProductProperties.filter((_, i) => i !== index)
    setListProductProperties(newData)
  }

  const onChangeProductPropertiesInfo = (
    index: number,
    newProductProperties: IProductProperty,
  ) => {
    const newData = listProductProperties.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          ...newProductProperties,
        }
      }
      return item
    })
    setListProductProperties(newData)
  }

  const onAddSuppliesOfTreatment = (
    treatmentId: string,
    supplies: ISupplies,
  ) => {
    const newData = listTreatment.map(item => {
      if (item._id === treatmentId) {
        const suppliesExist = item?.suppliess?.find(
          i => i.itemId === supplies.itemId,
        )

        if (suppliesExist) {
          return item
        }

        return {
          ...item,
          suppliess: [...(item.suppliess ?? []), supplies],
        }
      }
      return item
    })
    setListTreatment(newData)
  }

  const onChangeSuppliesOfTreatment = (
    treatmentId: string,
    index: number,
    supplies: ISupplies,
  ) => {
    const newData = listTreatment.map(item => {
      if (item._id === treatmentId) {
        const suppliess = item?.suppliess?.map(
          (oldSupplies, indexOldSupplies) => {
            if (!oldSupplies.itemId) {
              return supplies
            }

            if (indexOldSupplies === index) {
              return supplies
            }
            return oldSupplies
          },
        )

        return {
          ...item,
          suppliess,
        }
      }
      return item
    })
    setListTreatment(newData)
  }

  const onRemoveSuppliesOfTreatment = (
    treatmentId?: string,
    suppliesId?: string,
  ) => {
    const newData = listTreatment.map(item => {
      if (item?._id === treatmentId) {
        const newSuppliess = item?.suppliess?.filter(
          itm => itm?.itemId !== suppliesId,
        )
        return {
          ...item,
          suppliess: newSuppliess,
        }
      }
      return item
    })
    setListTreatment(newData)
  }

  const onAddJobTitleOfTreatmentOfTreatment = (treatmentId: string) => {
    const newData = listTreatment.map(item => {
      if (item._id === treatmentId) {
        return {
          ...item,
          jobTitleOfTreatments: [
            ...(item.jobTitleOfTreatments ?? []),
            {
              _id: getRandomUuid(),
            },
          ],
        }
      }
      return item
    })

    setListTreatment(newData)
  }

  const onChangeJobTitleOfTreatmentOfTreatment = (
    treatmentId: string,
    index: number,
    jobTitle: IJobTitleOfTreatment,
  ) => {
    const newData = listTreatment.map(item => {
      if (item._id === treatmentId) {
        const jobTitles = item?.jobTitleOfTreatments?.map(
          (oldJobTitleOfTreatment, indexOld) => {
            if (!oldJobTitleOfTreatment._id) {
              return jobTitle
            }

            if (indexOld === index) {
              return {
                ...oldJobTitleOfTreatment,
                ...jobTitle,
              }
            }
            return oldJobTitleOfTreatment
          },
        )

        return {
          ...item,
          jobTitleOfTreatments: jobTitles,
        }
      }
      return item
    })
    setListTreatment(newData)
  }

  const onRemoveJobTitleOfTreatmentOfTreatment = (
    treatmentId: string,
    jobTitleId: string,
  ) => {
    const newData = listTreatment.map(item => {
      if (item._id === treatmentId) {
        const newJobTitleOfTreatments = item?.jobTitleOfTreatments?.filter(
          i => i._id !== jobTitleId,
        )
        return {
          ...item,
          jobTitleOfTreatments: newJobTitleOfTreatments,
        }
      }
      return item
    })
    setListTreatment(newData)
  }

  const onChangeTimeOfTreatment = (treatmentId: string, time: number) => {
    const newData = listTreatment.map(item => {
      if (item._id === treatmentId) {
        return {
          ...item,
          time,
        }
      }
      return item
    })
    setListTreatment(newData)
  }

  const handleParamService = (values: IFormData) => {
    const typeItem = values.type ?? type ?? undefined
    if (!values.type) {
      console.debug('Invalid type: ', values.type)
    }

    switch (typeItem) {
      case ItemType.service:
        return handleParamsTreatment(values)

      default:
        return handleParamsDefault(values)
    }
  }

  const handleParamsTreatment = (values: IFormData) => {
    const doctorJobTitleLevelIds = form?.getFieldValue('doctorJobTitleLevelIds')
    const uniqueItemIds = uniqBy(values?.itemIds, 'itemId')

    const firstMainImage = first(mainImage)

    return {
      name: values.name,
      code: values.code,
      oldId: values.oldId,
      type: ItemType.service,
      itemBrandId: values.itemBrandId,
      categoryid: values.categoryid,
      description: values.description?.level?.content ?? '-',
      status: StatusEnum.ACTIVE,
      skillsInfo: [],
      itemIds:
        uniqueItemIds?.map((itemId: any) => ({
          itemId: itemId.itemId,
          quantity: 1,
        })) ?? [],
      mainImagesUrl:
        firstMainImage?.response?.data?.path ??
        firstMainImage?.fileName ??
        undefined,
      detailImagesUrl: listImage
        ? listImage.map(i => i?.response?.data?.path ?? i.fileName)
        : undefined,
      doctorJobTitleLevelIds: doctorJobTitleLevelIds?.map(i => ({
        jobTitleLevelId: i.jobTitleLevelId,
        jobTitleId: i.jobTitleIdManagement || i.jobTitleId,
        jobTitleLevelName: i.jobTitleLevelName,
        jobTitleName: i.name || i.jobTitleName,
      })),
    }
  }

  const handleParamsDefault = (values: IFormData) => {
    const firstMainImage = first(mainImage)

    const productPropertiesCombinedData =
      values.productPropertiesCombinedData?.map(
        (productPropertiesCombined: IProductPropertiesCombinedData) => {
          return {
            ...productPropertiesCombined,
            mainImagesUrl:
              productPropertiesCombined?.detailImagesUrl?.[0]?.fileName ??
              productPropertiesCombined?.mainImagesUrl?.file?.response?.data
                ?.path ??
              undefined,
            detailImagesUrl:
              productPropertiesCombined?.detailImagesUrl?.fileList?.map(
                i => i?.response?.data?.path ?? i.fileName,
              ) ??
              productPropertiesCombined?.detailImagesUrl?.map(
                imageUrl => imageUrl.fileName,
              ) ??
              [],
          }
        },
      )

    return {
      name: values.name,
      code: values.code,
      oldId: values.oldId,
      type: values.type ?? type ?? undefined,
      isInventoryManagement: values.isInventoryManagement,
      itemBrandId: values.itemBrandId,
      categoryid: values.categoryid,
      description: values.description?.level?.content ?? '-',
      status: values.status ?? StatusEnum.ACTIVE,
      skillsInfo: [],
      mainImagesUrl:
        firstMainImage?.response?.data?.path ??
        firstMainImage?.fileName ??
        undefined,
      detailImagesUrl: listImage
        ? listImage.map(i => i?.response?.data?.path ?? i.fileName)
        : undefined,
      productPropertiesCombinedData,
      productPropertiesIds: values.productPropertiesIds,
      purchaseMeasurementUnitId: values.purchaseMeasurementUnitId,
      usingMeasurementUnitId: values.usingMeasurementUnitId,
    }
  }

  return (
    <CreateUpdateContext.Provider
      value={{
        form,
        handleSubmit,
        rules,
        onChangeMainImage,
        handleChangeListImage,
        mainImage,
        listImage,

        listTreatment,
        onAddNewTreatment,
        onRemoveTreatment,
        onAddSuppliesOfTreatment,
        onRemoveSuppliesOfTreatment,
        onAddJobTitleOfTreatmentOfTreatment,
        onRemoveJobTitleOfTreatmentOfTreatment,
        onChangeTimeOfTreatment,
        onChangeSuppliesOfTreatment,
        onChangeJobTitleOfTreatmentOfTreatment,
        onChangeTreatmentInfo,
        editorRef,
        onAddNewProductProperties,
        onRemoveProductProperties,
        onChangeProductPropertiesInfo,
        itemTreatments,
        isLoadingTreatment,
        productProperties,
        isLoadingProductProperty,
        jobTitles,
        jobTitleLevels,
        isLoadingJobTitle,
        isLoadingJobTitleLevel,
      }}
    >
      {children}
    </CreateUpdateContext.Provider>
  )
}

export function useCreateUpdateServiceContext() {
  const context = useContext(CreateUpdateContext)

  if (context === undefined) {
    throw new Error(
      'useCreateUpdateService must be used within a CreateUpdateServiceProvider',
    )
  }
  return context
}
