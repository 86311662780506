import { ICategoryTreatment } from 'app/api/category/model'
import { IJobTitleManagement } from 'app/api/jobtitle/model/job-title-management'
import { StatusEnum } from 'app/common/enum'
import { usePagination } from 'app/hook'
import { useGetCategoryTreatmentList } from 'app/react-query/hook/category'
import { useGetMeasurementUnitTreatmentList } from 'app/react-query/hook/measurement-units'
import { useGetItemBrandList } from 'app/react-query/hook/item-brand'
import { IResponseQueryList } from 'app/react-query/model/common'
import { useMemo, useState } from 'react'

export const useHook = () => {
  const { flattenDataList } = usePagination()

  const { data: categoryTreatmentData, isLoading: isLoadingCategoryTreatment } =
    useGetCategoryTreatmentList({ status: StatusEnum.ACTIVE, pagesize: 1000 })

  const { data: measurementUnitData, isLoading: isLoadingMeasurementUnit } =
    useGetMeasurementUnitTreatmentList({
      pagesize: 1000,
      status: StatusEnum.ACTIVE,
    })

  const { data: itemBrandData, isLoading: isLoadingItemBrand } =
    useGetItemBrandList()

  const categoryTreatments = useMemo(() => {
    const flattenData: IResponseQueryList<ICategoryTreatment[]> =
      flattenDataList(categoryTreatmentData)
    return flattenData?.data ?? []
  }, [categoryTreatmentData])

  const measurementUnits = useMemo(() => {
    const flattenData: IResponseQueryList<ICategoryTreatment[]> =
      flattenDataList(measurementUnitData)
    return flattenData?.data ?? []
  }, [measurementUnitData])

  const itemBrands = useMemo(() => {
    const flattenData: IResponseQueryList<ICategoryTreatment[]> =
      flattenDataList(itemBrandData)
    return flattenData?.data ?? []
  }, [itemBrandData])

  const [jobTitleSelected, setJobTitle] = useState<IJobTitleManagement[]>([])

  const onChangeJobTitle = (value: IJobTitleManagement) => {
    const itemExist = jobTitleSelected.find(item => item?._id === value?._id)

    // console.log({ value, itemExist })

    if (itemExist) {
      return
    }

    setJobTitle([...jobTitleSelected, value])
  }

  const onDeleteJobTitle = (index: number) => {
    const newJobTitleSelected = jobTitleSelected.filter((_, i) => i !== index)
    setJobTitle(newJobTitleSelected)
  }

  return {
    measurementUnits,
    itemBrands,
    categoryTreatments,
    isLoadingMeasurementUnit,
    isLoadingItemBrand,
    isLoadingCategoryTreatment,
    onChangeJobTitle,
    jobTitleSelected,
    onDeleteJobTitle,
  }
}
