import R from 'app/assets/R'
import { PageTitle } from 'app/components/common/PageTitle'
import ItemManagementContainer from 'app/containers/Accounting/ItemManagement'
import { ItemCategoryProvider } from 'app/context/ItemCategoryContext'
import { isEqual } from 'lodash'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

const AccountingItemManagementCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <ItemCategoryProvider>
        <PageTitle>{t(R.strings.item_management)}</PageTitle>
        <ItemManagementContainer />
      </ItemCategoryProvider>
    </>
  )
}
const AccountingItemManagementPage = memo(AccountingItemManagementCpn, isEqual)

export default AccountingItemManagementPage
