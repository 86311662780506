/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestGetDashboardDayOff,
  requestGetTotalDashboardDayOff,
} from 'app/api/hr'
import { HrMessageCode } from 'app/api/hr/constant'
import {
  IDataGetTotalDashboardDayOff,
  IParamsGetApproveDayOff,
  ResponseApproveDayOff,
} from 'app/api/hr/models/approve-day-off'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useInfiniteQuery } from 'react-query'
import { DayOffKeys } from '../query-key/day-off'

export function useGetDashboardDayOffList(
  params?: IParamsGetApproveDayOff,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<
    ResponseApproveDayOff | undefined,
    IParamsGetApproveDayOff
  >(
    DayOffKeys.listDashboardDayOff(params),
    async () => {
      try {
        const res: ResponseApproveDayOff = await requestGetDashboardDayOff(
          params,
        )

        if (isEqual(res?.msgcode, HrMessageCode.DayOff.success)) {
          return res
        }
      } catch (error: any) {
        console.log(error)
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export function useGetTotalDashboardDayOffList(
  params?: IParamsGetApproveDayOff,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<
    ResponseType<IDataGetTotalDashboardDayOff[]> | undefined,
    IParamsGetApproveDayOff
  >(
    DayOffKeys.listTotalDashboardDayOff(params),
    async () => {
      try {
        const res: ResponseType<IDataGetTotalDashboardDayOff[]> =
          await requestGetTotalDashboardDayOff(params)

        if (isEqual(res?.msgcode, HrMessageCode.DayOff.success)) {
          return res
        }
      } catch (error: any) {
        console.log(error)
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
