/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestExportDailyInvoice,
  requestSyncDailyInvoice,
} from 'app/api/accounting'
import R from 'app/assets/R'
import { ENV_CONFIG } from 'app/common/config'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { notificationController } from 'app/controllers/notification-controller'
import { Pagination, moment } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ALink } from './styles'
import { Collapse } from 'antd'
import { IGeneralInvoiceTable, initialPagination } from './common-model'
import { IParamsAccounting } from 'app/api/accounting/model'
import { get } from 'lodash'
import { PaymentMethodTranslateEnum } from 'app/components/tables/BaseTableManagement/FilterDeptTable/type'
import { downloadNotificationController } from 'app/controllers/download-notification-controller'
import { NOTIFICATION_TYPE } from 'app/common/constants'
import { handlePermission } from '../common'

export const useCommonHook = ({
  expandedRowKeys,
  fetchLoading,
  fetch,
}: {
  expandedRowKeys: number[]
  fetchLoading: (value: boolean) => void
  fetch: (pagination: Pagination, params?: IParamsAccounting) => void
  handleExpand: (isExpanded: boolean, key?: number) => void
  currentFilter?: IParamsAccounting
}) => {
  const { t } = useTranslation()

  const onClickExport = async params => {
    fetchLoading(true)
    try {
      if (!params?.startDate && !params?.endDate) {
        notificationController.error({
          message: t(R.strings.select_range_date_required),
        })
      } else {
        await requestExportDailyInvoice({
          treatments: params?.treatments,
          params: {
            keyword: params?.search,
            startDate: params?.startDate,
            endDate: params?.endDate,
            paidTypes: params?.paidType?.join(','),
            location: params?.location,
            paymentMethod: params?.paymentMethod,
          },
        })

        await handleNotification()
      }
    } catch (error) {
      // console.log({ error })
      notificationController.error({
        message: t(R.strings.error),
      })
    }
    fetchLoading(false)
  }

  const handleNotification = async () => {
    const permissionNotification = await handlePermission()
    if (
      permissionNotification?.state === 'granted' &&
      permissionNotification?.status === true
    ) {
      return downloadNotificationController.waiting({
        duration: 0,
        key: NOTIFICATION_TYPE.EXPORT_DAILY_INVOICE,
        message: t(R.strings.daily_invoice),
        description: t(R.strings.download_waiting),
        cancel: t(R.strings.cancel),
      })
    }

    if (!permissionNotification?.status)
      notificationController.warning({
        message: t(R.strings.download_warning),
        description: t(R.strings.download_warning_description),
      })

    notificationController.success({
      message: t(R.strings.success),
      description: t(R.strings.export_excel_sendmail_message),
    })
  }

  const onClickSync = async params => {
    try {
      if (!params?.startDate && !params?.endDate) {
        notificationController.error({
          message: t(R.strings.select_range_date_required),
        })
      } else if (
        moment(params?.endDate).diff(moment(params?.startDate), 'days') > 0
      ) {
        notificationController.error({
          message: t(R.strings.sync_for_up_to_one_day),
        })
      } else {
        await requestSyncDailyInvoice({
          startDate: params?.startDate,
          endDate: params?.endDate,
          syncType: 'date',
        })

        await fetch(initialPagination, params)
        notificationController.success({
          message: t(R.strings.success),
          description: t(R.strings.sync_successfully),
        })
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.manage_customer_debt_treatment_date),
        key: 'date',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.date} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.manage_customer_patient_name),
        key: 'patient',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <ALink
            onClick={e => {
              e.preventDefault()
              window.open(
                ENV_CONFIG.OLD_WEBSITE_ENDPOINT +
                  '/patients/details/' +
                  record?.patient?._id,
              )
            }}
          >
            {record?.patient?.name}
          </ALink>
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.manage_customer_patient_code),
        key: 'patient',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.patient?.id} type="Main" />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.manage_customer_clinic_name),
        key: 'location',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.location?.name} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.search_appointment_treatment),
        key: 'treatments',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          const treatments = record?.treatments?.sort(
            (a, b) => Number(b?.unitPrice) - Number(a?.unitPrice),
          )

          const treatmentNames = treatments?.map(treatment => treatment?.name)

          const items = [
            {
              key: '1',
              label: get(treatmentNames, '[0]', ''),
              children: treatmentNames
                ?.filter((_, index) => index !== 0)
                ?.map(treatment => (
                  <>
                    <RenderValueTable value={treatment} type="Base" />
                    <hr />
                  </>
                )),
            },
          ]

          return <Collapse items={items} className="Collapse-treatment" />
        },
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.treatment_fee),
        key: 'netAmount',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.netAmount} type="Money" />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.paid),
        key: 'payment',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.payment?.paid} type="Money" />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.refund),
        key: 'refund',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.refund?.amount} type="Money" />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.debt),
        key: 'patient',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.patient?.remain} type="Money" />
        ),
      }),

      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.daily_invoice_payment_for_treatment),
        key: 'paymentInfo',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.paymentInfo?.paymentFor?.name}
            type="Base"
          />
        ),
      }),

      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.daily_invoice_payment_for_treatment_amount),
        key: 'paymentInfo',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.paymentInfo?.paymentFor?.amount}
            type="Number"
          />
        ),
      }),

      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.daily_invoice_payment_for_receivable),
        key: 'paymentInfo',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.paymentInfo?.receivable?.name}
            type="Base"
          />
        ),
      }),

      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.daily_invoice_payment_for_receivable_amount),
        key: 'paymentInfo',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.paymentInfo?.receivable?.amount}
            type="Number"
          />
        ),
      }),

      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.cash),
        key: 'payment',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.payment?.cash} type="Money" />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.card),
        key: 'payment',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.payment?.card} type="Money" />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.card_type),
        key: 'payment',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.payment?.card ? record?.payment?.cardType : ''}
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.transfer),
        key: 'payment',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.payment?.transfer} type="Money" />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.transfer_type),
        key: 'payment',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={
              record?.payment?.transfer ? record?.payment?.transferType : ''
            }
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.app_momo),
        key: 'payment',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.payment?.app} type="Money" />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.payoo_link),
        key: 'payment',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.payment?.payooLink} type="Money" />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.fundiin),
        key: 'payment',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.payment?.fundiin} type="Money" />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.installment_direct_payment),
        key: 'payment',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          const paidType =
            record?.payment?.isInstallment === undefined
              ? ''
              : record?.payment?.isInstallment
              ? t(R.strings.installment)
              : t(R.strings.direct_payment)

          return <RenderValueTable value={paidType} type="Main" />
        },
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.payment_method),
        key: 'treatments',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          const treatmentMethod = record?.treatments?.find(
            treatment => treatment?.paymentMethod,
          )
          const paymentMethod = get(treatmentMethod, 'paymentMethod', '')

          return (
            <RenderValueTable
              value={t(PaymentMethodTranslateEnum[String(paymentMethod)])}
              type="Base"
            />
          )
        },
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.doctor),
        key: 'doctor',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.doctor?.name} type="Base" />
        ),
      }),
    ]
  }, [t, expandedRowKeys])

  return {
    onClickExport,
    columns,
    onClickSync,
  }
}
