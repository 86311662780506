import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import * as S from 'app/components/tables/BaseTableReport/styles'
import 'dayjs/locale/vi'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTableHook } from './hook'
import './styles.css'
import { BaseCol } from 'app/components/common/BaseCol'
import TotalBox from './components/TotalBox'
import { useTranslation } from 'react-i18next'
import Export from './components/Export'
import { Filter } from './components/Filter'

const DebtContainer: React.FC = () => {
  const {
    columns,
    tableData,
    handleTableChange,
    handleChangeFilter,
    filterResponse,
    onClickExport,
    metadata,
  } = useTableHook()

  const { t } = useTranslation()
  return (
    <S.BaseReportWrapper>
      <HeaderPage
        titleI18nKey={R.strings.manage_customer_debt}
        rightComponent={
          <Export
            onPressExport={() => onClickExport({ ...filterResponse })}
          />
        }
      />
      <S.RootWrapper>
        <Filter
          handleChangeFilter={handleChangeFilter}
          filterData={filterResponse}
        />
        <BaseRow>
          <BaseCol span={6}>
            <TotalBox
              title={t(R.strings.treatment_fee)}
              total={Number(metadata?.totalNetAmount)}
            />
          </BaseCol>

          <BaseCol span={6}>
            <TotalBox
              title={t(R.strings.paid)}
              total={Number(metadata?.totalPaid)}
            />
          </BaseCol>

          <BaseCol span={6}>
            <TotalBox
              title={t(R.strings.refund)}
              total={Number(metadata?.totalRefund)}
            />
          </BaseCol>

          <BaseCol span={6}>
            <TotalBox
              title={t(R.strings.debt)}
              total={Number(metadata?.totalRemain)}
            />
          </BaseCol>
        </BaseRow>
        <BaseTableReport
          columns={columns}
          dataSource={tableData.data}
          loading={tableData.loading}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
          pagination={tableData.pagination}
          scroll={{ x: true, y: tableData?.data?.length ? 480 : undefined }}
          // rowSelection={rowSelection}
        />
      </S.RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default DebtContainer
