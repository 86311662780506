import R from 'app/assets/R'
import {
  RenderValueTable,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { isEmpty, isEqual } from 'lodash'
import { Pagination, moment } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IGeneralMaterialManagementTable } from './type'

import {
  requestExportMaterialManagement,
  requestGetMaterialManagement,
} from 'app/api/inventory'
import { MessageCode } from 'app/api/inventory/constant'
import {
  IParamsGetMaterialManagement,
  IResponseGetMaterialManagement,
} from 'app/api/inventory/model/management'
import { IMAGE_ACTION } from 'app/common/config'
import { DATE } from 'app/common/constants'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { BaseCol } from 'app/components/common/BaseCol'
import { ActionsDropdown } from 'app/components/tables/BaseTableManagement/ActionDropdown'
import { ValueTableWrapper } from 'app/components/tables/BaseTableManagement/styles'
import { initPagination } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import dayjs, { Dayjs } from 'dayjs'
import { Delete } from './components/Delete'
import {
  IResponseFilter,
  initResponseFilterTableManagement,
} from './components/FilterManagementTable/type'
import { useModalHook } from './components/Update/hook'
import * as S from './components/Update/styles'

export const useHookTable = () => {
  const { t } = useTranslation()

  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const [update, setUpdate] = useState<{
    _id?: string
    invoiceSymbol?: string
    invoiceNumber?: string
    invoiceDate?: Dayjs
  }>({})

  const { handleSubmit } = useModalHook({})

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.INVENTORY,
      FunctionPermissionEnum.MATERIAL_READ,
      R.strings.role,
    )
  }, [])

  const onClickExport = async params => {
    try {
      const response = await requestExportMaterialManagement({
        keyword: params?.search ?? filter?.search,
        out: false,
        location: params?.location || filter?.location || "",
        startDate: params?.startDate
          ? dayjs(params?.startDate).format(DATE.YYYY_MM_DD)
          : dayjs(filter?.startDate).format(DATE.YYYY_MM_DD),
        endDate: params?.endDate
          ? dayjs(params?.endDate).format(DATE.YYYY_MM_DD)
          : dayjs(filter?.endDate).format(DATE.YYYY_MM_DD),
      })
      const url = `${IMAGE_ACTION.IMAGE_SERVER}/${response?.data?.Url}`
      window.open(url, '_blank', 'noopener,noreferrer')
    } catch (error) {
      // console.log({ error })
      notificationController.error({
        message: t(R.strings.error),
      })
    }
  }
  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IResponseFilter,
  ) => {
    const initData = {
      pagination: pagination ?? initPagination,
      data: [],
    } as IDataBaseTable<IGeneralMaterialManagementTable>
    try {
      let params: IParamsGetMaterialManagement = {
        page: pagination?.current,
        pagesize: pagination?.pageSize,
        keyword: newFilter?.search ?? filter?.search,
      }

      params = {
        ...params,
        ...newFilter,
        startDate: newFilter?.startDate
          ? dayjs(newFilter?.startDate).format(DATE.YYYY_MM_DD)
          : dayjs(filter?.startDate).format(DATE.YYYY_MM_DD),
        endDate: newFilter?.endDate
          ? dayjs(newFilter?.endDate).format(DATE.YYYY_MM_DD)
          : dayjs(filter?.endDate).format(DATE.YYYY_MM_DD),
        out: false,
      }

      const response: IResponseGetMaterialManagement =
        await requestGetMaterialManagement(params)

      if (isEqual(response?.msgcode, MessageCode.successGetData)) {
        const data = response?.data
        const metadata = response?.metadata

        const startIndex =
          ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

        const transformData = data => {
          if (isEmpty(data)) {
            return data
          }

          return data?.map((item, index) => {
            return {
              key: `${item?._id}-${index + 1}`,
              ordinalNumber: startIndex + index + 1,
              providerName: item?.provider?.name ?? '-',
              providerCode: item?.provider?.code ?? '-',
              providerId: item?.providerId,
              materialCategoryName: item?.materialCategory?.name ?? '-',
              materialCategoryVat: item?.materialCategory?.vat ?? '-',
              materialCategoryId: item?.materialCategoryId,
              unit: item?.unit ?? '-',
              useUnit: item?.useUnit ?? '-',
              unitPrice: item?.unitPrice ?? '-',
              quantity: item?.quantity,
              total: item?.total,
              paid: item?.paid,
              date: item?.date,
              note: item?.note,
              name: item?.name,
              multiplier: item?.multiplier,
              invoiceNumber: item?.invoiceNumber,
              invoiceSymbol: item?.invoiceSymbol,
              invoiceDate: item?.invoiceDate,
              invoicePriceTax: item?.invoicePriceTax,
              locationName: item?.location?.name,
              locationId: item?.locationId,
              _id: item?._id ?? '-',
            }
          })
        }

        const dataTables = transformData(data)

        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pageSize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IGeneralMaterialManagementTable>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, selectRowsTable, fetch, rowSelection } =
    useHookTableManagement({ getDataTable })

  const handleTableChange = pagination => {
    fetch(pagination, filter)
  }

  const handleChangeFilter = (newFilter: IResponseFilter) => {
    if (!isEqual(newFilter, filter)) {
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initPagination.current,
        },
        newFilter,
      )
    }
  }

  const fetchNewData = () => {
    fetch(initPagination)
    setFilter(initResponseFilterTableManagement)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (update?._id) handleSubmit(update)
    }, 500)
    return () => clearTimeout(timer)
  }, [update])

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralMaterialManagementTable>({
        title: t(R.strings.ordinal_number),
        key: 'ordinalNumber',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => (
          <RenderValueTable value={record.ordinalNumber} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralMaterialManagementTable>({
        title: t(R.strings.created_date),
        key: 'date',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={moment(record?.date).format(DATE.DD_MM_YYYY)}
            type="Base"
          />
        ),
      }),

      convertColumnTable<IGeneralMaterialManagementTable>({
        title: t(R.strings.material),
        key: 'materialCategoryName',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.materialCategoryName} type="Main" />
        ),
      }),

      convertColumnTable<IGeneralMaterialManagementTable>({
        title: t(R.strings.location),
        key: 'locationName',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <RenderValueTable value={record?.locationName} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralMaterialManagementTable>({
        title: t(R.strings.provider),
        key: 'providerName',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.providerName} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralMaterialManagementTable>({
        title: t(R.strings.provider_code),
        key: 'providerCode',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.providerCode} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralMaterialManagementTable>({
        title: t(R.strings.unit_price),
        key: 'unitPrice',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.unitPrice} type="Money" />
        ),
      }),

      convertColumnTable<IGeneralMaterialManagementTable>({
        title: t(R.strings.quantity),
        key: 'quantity',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.quantity} type="Main" />
        ),
      }),

      convertColumnTable<IGeneralMaterialManagementTable>({
        title: t(R.strings.unit_import),
        key: 'unit',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.unit} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralMaterialManagementTable>({
        title: t(R.strings.total_price),
        key: 'total',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.total} type="Money" />
        ),
      }),

      convertColumnTable<IGeneralMaterialManagementTable>({
        title: t(R.strings.invoice_number),
        key: 'invoiceNumber',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          return (
            <S.FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.invoice_number),
              })}
              defaultValue={record?.invoiceNumber}
              onChange={e =>
                setUpdate({ _id: record?._id, invoiceNumber: String(e?.target?.value) })
              }
            />
          )
        },
      }),

      convertColumnTable<IGeneralMaterialManagementTable>({
        title: t(R.strings.invoice_date),
        key: 'invoiceDate',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          return (
            <S.FormDatePicker
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.invoice_date),
              })}
              defaultValue={
                record?.invoiceDate ? dayjs(record?.invoiceDate) : undefined
              }
              onChange={value =>
                setUpdate({ _id: record?._id, invoiceDate: dayjs(value) })
              }
            />
          )
        },
      }),

      convertColumnTable<IGeneralMaterialManagementTable>({
        title: t(R.strings.invoice_symbol),
        key: 'invoiceSymbol',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          return (
            <S.FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.invoice_symbol),
              })}
              defaultValue={record?.invoiceSymbol}
              onChange={e =>
                setUpdate({ _id: record?._id, invoiceSymbol: String(e?.target?.value) })
              }
            />
          )
        },
      }),

      convertColumnTable<IGeneralMaterialManagementTable>({
        title: t(R.strings.material_vat),
        key: 'materialCategoryVat',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.materialCategoryVat} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralMaterialManagementTable>({
        title: t(R.strings.invoice_price_tax),
        key: 'invoicePriceTax',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.invoicePriceTax} type="Money" />
        ),
      }),

      convertColumnTable<IGeneralMaterialManagementTable>({
        title: '',
        key: 'action',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <ValueTableWrapper
            justify={'end'}
            align={'middle'}
            gutter={[10, 10]}
            wrap={false}
          >
            <BaseCol>
              <ActionsDropdown
                actionComponent={
                  <>
                    <Delete
                      id={record?._id}
                      fetchTable={fetchNewData}
                      materialName={record?.materialCategoryName}
                    />
                  </>
                }
              />
            </BaseCol>
          </ValueTableWrapper>
        ),
      }),
    ]
    return option
  }, [t, fetchNewData])

  return {
    tableData,
    selectRowsTable,
    handleTableChange,
    fetchNewData,
    rowSelection,
    columns,
    handleChangeFilter,
    filter,
    onClickExport,
  }
}
