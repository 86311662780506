import R from 'app/assets/R'
import {
  RenderValueTable,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { isEmpty, isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  EWarehouseInventoryColumnType,
  IGeneralWarehouseInventoryManagementTable,
  initResponseFilterTableManagement,
} from './type'

import { Flex } from 'antd'
import {
  requestExportWarehouseInventoryManagement,
  requestGetWarehouseInventoryManagement,
} from 'app/api/inventory-new/api/warehouse-inventory'
import { MessageCode } from 'app/api/inventory-new/constant'
import {
  IParamsGetWarehouseInventoryManagement,
  IResponseGetWarehouseInventoryManagement,
} from 'app/api/inventory-new/model/warehouse-inventory'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { initPagination } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { IResponseFilter } from './components/FilterManagementTable/type'
import { QuantityPopover } from './components/QuantityPopover'

export const useHookTable = () => {
  const { t } = useTranslation()

  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.INVENTORY,
      FunctionPermissionEnum.PROVIDER_READ,
      R.strings.role,
    )
  }, [])

  const onPressExport = async params => {
    try {
      const response = await requestExportWarehouseInventoryManagement({
        keyword: params?.search ?? filter?.search,
        warehouseId: filter.warehouseId,
        type: filter.type,
      })
      const url = `${response?.data?.url}`
      window.open(url, '_blank', 'noopener,noreferrer')
    } catch (error) {
      // console.log({ error })
      notificationController.error({
        message: t(R.strings.error),
      })
    }
  }
  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IResponseFilter,
  ) => {
    const initData = {
      pagination: pagination ?? initPagination,
      data: [],
    } as IDataBaseTable<IGeneralWarehouseInventoryManagementTable>
    try {
      let params: IParamsGetWarehouseInventoryManagement = {
        page: pagination?.current,
        pagesize: pagination?.pageSize,
        keyword: newFilter?.search ?? filter?.search,
      }

      params = { ...filter, ...params, ...newFilter }

      if (!params?.warehouseId) return initData

      const response: IResponseGetWarehouseInventoryManagement =
        await requestGetWarehouseInventoryManagement(params)

      if (isEqual(response?.msgcode, MessageCode.successGetData)) {
        const data = response?.data
        const metadata = response?.metadata

        const startIndex =
          ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

        const transformData = data => {
          if (isEmpty(data)) {
            return data
          }

          return data?.map((item, index) => {
            const {
              product,
              availableQuantity,
              total,
              roadWarehouseInventories,
              warehouseId,
            } = item
            return {
              key: `${item?._id}-${index + 1}`,
              _id: item?._id ?? '-',
              ordinalNumber: startIndex + index + 1,
              productId: product?._id,
              warehouseId,
              productCode: product?.code,
              productName: product?.name,
              productType: product?.type,
              productPurchaseMeasurementUnitName:
                product?.purchaseMeasurementUnit?.name,
              availableQuantity,
              total,
              roadWarehouseInventoriesAvailableQuantity:
                roadWarehouseInventories?.availableQuantity,
            }
          })
        }

        const dataTables = transformData(data)

        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pageSize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IGeneralWarehouseInventoryManagementTable>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, selectRowsTable, fetch, rowSelection } =
    useHookTableManagement({ getDataTable })

  const handleTableChange = pagination => {
    fetch(pagination, filter)
  }

  const handleChangeFilter = (newFilter: IResponseFilter) => {
    if (!isEqual(newFilter, filter)) {
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initPagination.current,
        },
        newFilter,
      )
    }
  }

  const fetchNewData = () => {
    fetch(initPagination)
    setFilter(initResponseFilterTableManagement)
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralWarehouseInventoryManagementTable>({
        title: '#',
        key: 'ordinalNumber',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <RenderValueTable value={record.ordinalNumber} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralWarehouseInventoryManagementTable>({
        title: t(R.strings.goods_code),
        key: 'productCode',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.productCode} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralWarehouseInventoryManagementTable>({
        title: t(R.strings.goods_name),
        key: 'productName',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <RenderValueTable value={record?.productName} type="Main" />
        ),
      }),

      convertColumnTable<IGeneralWarehouseInventoryManagementTable>({
        title: t(R.strings.item_management_content_type),
        key: 'productType',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={t(String(record?.productType))}
            type="Base"
          />
        ),
      }),

      convertColumnTable<IGeneralWarehouseInventoryManagementTable>({
        title: t(R.strings.unit_of_measure),
        key: 'productPurchaseMeasurementUnitName',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.productPurchaseMeasurementUnitName}
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralWarehouseInventoryManagementTable>({
        title: t(R.strings.total),
        key: 'total',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.total} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralWarehouseInventoryManagementTable>({
        title: t(R.strings.available_quantity),
        key: 'availableQuantity',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <>
            <Flex justify="center" align="center">
              {record?.availableQuantity}
              <QuantityPopover
                title={t(R.strings.available_quantity)}
                type={EWarehouseInventoryColumnType.AVAILABLE}
                warehouseId={record?.warehouseId}
                productId={record?.productId}
              />
            </Flex>
          </>
        ),
      }),

      convertColumnTable<IGeneralWarehouseInventoryManagementTable>({
        title: t(R.strings.road_available_quantity),
        key: 'roadWarehouseInventoriesAvailableQuantity',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <>
            <Flex justify="center" align="center">
              {record?.roadWarehouseInventoriesAvailableQuantity ?? 0}
              <QuantityPopover
                title={t(R.strings.road_available_quantity)}
                type={EWarehouseInventoryColumnType.OUTBOUND}
                warehouseId={record?.warehouseId}
                productId={record?.productId}
              />
            </Flex>
          </>
        ),
      }),
    ]
    return option
  }, [t, fetchNewData])

  return {
    tableData,
    selectRowsTable,
    handleTableChange,
    fetchNewData,
    rowSelection,
    columns,
    handleChangeFilter,
    filter,
    onPressExport: onPressExport,
  }
}
