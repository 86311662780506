import { SvgPurchaseOrderPrinterWhiteIcon } from 'app/assets/svg-assets'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { useHook } from '../BuyInfo/hook'
import { PrintButton } from './styles'

export const Print = () => {
  const { t } = useTranslation()
  const { isLoading } = useHook()

  return (
    <PrintButton
      icon={<SvgPurchaseOrderPrinterWhiteIcon />}
      loading={isLoading}
    >
      {t(R.strings.purchase_order_print)}
    </PrintButton>
  )
}
