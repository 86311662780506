import R from 'app/assets/R'
import { initResponseFilterTableManagement } from 'app/components/tables/BaseTableManagement/FilterManagementTable/type'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { cloneDeep, get } from 'lodash'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IGeneralGoodsManagementTable, IResponseFilter } from './type'

import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { ValueTableWrapper } from 'app/components/tables/BaseTableManagement/styles'
import { WarehouseGoodsIssueContext } from 'app/containers/InventoryManagement/WarehouseGoodsIssue/context'
import { InputText, QuantityError, QuantityErrorText } from './styles'
import { IItem } from 'app/api/item/model'
import { OrderNameBox } from '../CreateGoods/styles'
import { addPrefixImage } from 'app/common/helpers'
import {
  SvgInformationCircleRedIcon,
  SvgTrashWithoutColorIcon,
} from 'app/assets/svg-assets'
import { useModalHook } from '../CreateGoods/hook'
import { notificationController } from 'app/controllers/notification-controller'
import { requestWarehouseGoodsReceiptTemplate } from 'app/api/inventory-new'
import { GoodsIssueStatusEnum } from '../../../type'
import { GoodsIssueStatusColor, GoodsIssueStatusName } from '../../../constant'
import { StatusButton } from '../../../styles'
import { requestWarehouseGoodIssueImportProduct } from 'app/api/inventory-new/api/warehouse-goods-issue'

interface IItemEdit extends IItem {
  price?: number
}

export const useHook = () => {
  const { t } = useTranslation()

  const [filter] = useState<IResponseFilter>(initResponseFilterTableManagement)

  const { setGoods, goods } = useModalHook({})
  const { orders, setOrders, warehouseGoodsIssueDetail } = useContext(
    WarehouseGoodsIssueContext,
  )

  const [orderEdit, setOrderEdit] = useState<
    { [key: string]: IItemEdit | null } | null | undefined
  >(null)

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.INVENTORY,
      FunctionPermissionEnum.PROVIDER_READ,
      R.strings.role,
    )
  }, [])

  const handleFinishedEdit = (event, orderEdit) => {
    if (event?.key === 'Enter') {
      const currentOrderEditKey = `${orderEdit._id}_${event?.target?.name}`
      setOrderEdit(prev => ({
        ...prev,
        [currentOrderEditKey]: null,
      }))
    }
  }

  const handleEdit = (event, orderEdit) => {
    const orderIndex = orders?.findIndex(order => order?._id === orderEdit?._id)
    const newOrders = cloneDeep(orders || [])
    newOrders[Number(orderIndex)][String(event?.target?.name)] =
      event?.target?.value
    setOrders?.(newOrders)
  }

  const downloadTemplate = async id => {
    try {
      const response = await requestWarehouseGoodsReceiptTemplate({
        purchaseOrderId: id || '',
      })
      window.open(response?.data?.Url, '_blank', 'noopener,noreferrer')
    } catch (error) {
      notificationController.error({
        message: t(R.strings.warehouse_purchase_update),
        description: get(error, 'message'),
      })
    }
  }

  const handleRemove = record => {
    setOrders?.(prev => prev?.filter(item => item?._id !== record?._id))
    setGoods(goods?.filter(item => item?._id !== record?._id))
  }

  const upload = async (options, purchaseOrderId, callback) => {
    const { onSuccess, onError, file } = options

    const fmData = new FormData()
    fmData.append('file', file)
    try {
      const res = await requestWarehouseGoodIssueImportProduct(fmData)
      if (callback) callback(res)
      onSuccess('Ok')
    } catch (err) {
      onError({ err })
    }
  }

  const columns = useMemo(() => {
    const isDisabled = !!warehouseGoodsIssueDetail?._id

    const rowAction = !isDisabled
      ? [
          convertColumnTable<IGeneralGoodsManagementTable>({
            title: '',
            key: 'action',
            fixed: 'right',
            classNameWidthColumnOverwrite: 'small-column',
            render: (_, record) => (
              <ValueTableWrapper
                justify={'end'}
                align={'middle'}
                gutter={[10, 10]}
                wrap={false}
              >
                <SvgTrashWithoutColorIcon
                  onClick={() => handleRemove(record)}
                />
              </ValueTableWrapper>
            ),
          }),
        ]
      : []

    const rowQuantity = isDisabled
      ? [
          convertColumnTable<IGeneralGoodsManagementTable>({
            title: t(R.strings.warehouse_quantity_export),
            key: 'quantity',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record) => {
              return <RenderValueTable value={record?.quantity} type="Number" />
            },
          }),
          convertColumnTable<IGeneralGoodsManagementTable>({
            title: t(R.strings.warehouse_quantity_export),
            key: 'importQuantity',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record) => {
              return (
                <RenderValueTable
                  value={record?.importQuantity}
                  type="Number"
                />
              )
            },
          }),

          convertColumnTable<IGeneralGoodsManagementTable>({
            title: t(R.strings.status),
            key: 'status',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record) => {
              if ((record?.quantity || 0) > (record?.importQuantity || 0)) {
                const statusColor =
                  GoodsIssueStatusColor[GoodsIssueStatusEnum.SHORT_RECEIVED]
                return (
                  <StatusButton
                    $color={statusColor?.color}
                    $background={statusColor?.background}
                  >
                    {t(
                      GoodsIssueStatusName[GoodsIssueStatusEnum.SHORT_RECEIVED],
                    )}
                  </StatusButton>
                )
              }

              const statusColor =
                GoodsIssueStatusColor[GoodsIssueStatusEnum.CORRECT_RECEIVED]
              return (
                <StatusButton
                  $color={statusColor?.color}
                  $background={statusColor?.background}
                >
                  {t(
                    GoodsIssueStatusName[GoodsIssueStatusEnum.CORRECT_RECEIVED],
                  )}
                </StatusButton>
              )
            },
          }),
        ]
      : [
          convertColumnTable<IGeneralGoodsManagementTable>({
            title: t(R.strings.quantity),
            key: 'quantity',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record) => {
              const currentOrderEditKey = `${record._id}_quantity`
              return (
                <div
                  onClick={() =>
                    setOrderEdit(prev => ({
                      ...prev,
                      [currentOrderEditKey]: record,
                    }))
                  }
                >
                  {get(orderEdit, `${currentOrderEditKey}._id`, '') ===
                    record?._id && !isDisabled ? (
                    <InputText
                      defaultValue={record?.quantity}
                      name="quantity"
                      onKeyDown={event => handleFinishedEdit(event, record)}
                      onChange={e => handleEdit(e, record)}
                    />
                  ) : Number(record?.quantity || 0) > 999 ? (
                    <QuantityError>
                      <QuantityErrorText>{record?.quantity}</QuantityErrorText>
                      <SvgInformationCircleRedIcon />
                    </QuantityError>
                  ) : (
                    <RenderValueTable value={record?.quantity} type="Number" />
                  )}
                </div>
              )
            },
          }),
        ]
    const option = [
      convertColumnTable<IGeneralGoodsManagementTable>({
        title: '#',
        key: 'ordinalNumber',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => (
          <RenderValueTable value={record.ordinalNumber} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralGoodsManagementTable>({
        title: t(R.strings.goods),
        key: 'name',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <OrderNameBox>
            <img src={addPrefixImage(record?.mainImagesUrl)} alt="" />
            <p>{record?.name}</p>
          </OrderNameBox>
        ),
      }),

      convertColumnTable<IGeneralGoodsManagementTable>({
        title: t(R.strings.goods_code),
        key: 'code',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.code} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralGoodsManagementTable>({
        title: t(R.strings.unit_of_measure),
        key: 'purchaseMeasurementUnit',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          return (
            <RenderValueTable
              value={record?.purchaseMeasurementUnit}
              type="Base"
            />
          )
        },
      }),
      ...rowQuantity,
      ...rowAction,
    ]
    return option
  }, [t, orderEdit, orders, warehouseGoodsIssueDetail])

  return {
    columns,
    filter,
    downloadTemplate,
    upload,
  }
}
