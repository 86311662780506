/* eslint-disable @typescript-eslint/no-explicit-any */
import { IItemCategory } from 'app/api/item-category/model/management'
import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseTextEditor from 'app/components/common/BaseTextEditor'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { ItemCategoryContext } from 'app/context/ItemCategoryContext'
import { TreatmentContext } from 'app/context/TreatmentContext'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useCreateUpdateServiceContext } from '../../../../context'
import { StatusCreateEnum } from '../../../Service/layouts/InfoService/type'
import { useHook } from './hook'
import * as S from './styles'
import { YesNoEnum } from 'app/common/enum'
import { DefineYesNoData } from 'app/common/constants'

export const InfoComboLayout = () => {
  const { type: typeParam } = useParams()
  const { jobTitleSelected } = useHook()
  const { rules, form } = useCreateUpdateServiceContext()
  const [itemCateKeyword, setItemCateKeyword] = useState('')
  const { t } = useTranslation()
  const {
    fetchItemCategory,
    itemCategories,
    loading: isLoadingCategory,
  } = useContext(ItemCategoryContext)

  const { itemBrands } = useContext(TreatmentContext)

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchItemCategory?.({
        keyword: itemCateKeyword,
      })
    }, 500)
    return () => clearTimeout(timer)
  }, [itemCateKeyword])

  useEffect(() => {
    const doctorJobTitleLevelIds: string[] = jobTitleSelected?.map(({ _id }) =>
      String(_id),
    )
    form?.setFieldsValue({
      doctorJobTitleLevelIds,
    })
  }, [jobTitleSelected])

  useEffect(() => {
    form?.setFieldsValue({
      type: typeParam,
    })
  }, [])

  return (
    <S.RootWrapper direction="vertical" size={10}>
      <S.Title>
        {t(R.strings[`item_management_content_info_${typeParam}`])}
      </S.Title>
      <BaseForm.Item name="type" hidden={true} />
      <BaseForm.Item
        label={t(R.strings.category)}
        name="categoryid"
        rules={rules?.categoryid}
        required
      >
        <BaseSelect
          filterOption={false}
          placeholder={t(R.strings.select_category)}
          suffixIcon={<SvgTableCloseIcon />}
          loading={isLoadingCategory}
          onSearch={keyword => setItemCateKeyword(keyword)}
          onClear={() => setItemCateKeyword('')}
          allowClear
          showSearch
        >
          {itemCategories?.map((item: IItemCategory, index) => {
            return (
              <Option value={item._id} key={index}>
                {item?.name}
              </Option>
            )
          })}
        </BaseSelect>
      </BaseForm.Item>

      <BaseRow justify={'space-between'} align={'middle'} gutter={[10, 10]}>
        <BaseCol xl={24}>
          <BaseForm.Item
            label={t(R.strings?.[`item_management_content_${typeParam}_name`])}
            name="name"
            rules={rules?.name}
            required
          >
            <BaseInput
              placeholder={t(
                R.strings?.[`item_management_content_enter_${typeParam}_name`],
              )}
            />
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>

      <BaseRow justify={'space-between'} align={'middle'} gutter={[10, 10]}>
        <BaseCol xl={12}>
          <BaseRow gutter={10} align={'middle'}>
            <BaseCol xl={12}>
              <BaseForm.Item
                label={t(R.strings.minimum_one_time_payment)}
                name="minimumOneTimePayment"
                rules={rules?.code}
              >
                <S.InputNumberStyled
                  placeholder={t(R.strings.enter_amount_paid)}
                />
              </BaseForm.Item>
            </BaseCol>
            <BaseCol xl={12}>
              <BaseForm.Item
                label={t(R.strings.minimum_installment_payment_in_clinic)}
                name="minimumInstallmentPaymentInClinic"
                rules={rules?.categoryid}
              >
                <S.InputNumberStyled
                  placeholder={t(R.strings.enter_amount_paid)}
                />
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={`${t(R.strings.end_of_shift_payment)}`}
            name="endOfShiftPayment"
          >
            <S.InputNumberStyled placeholder={t(R.strings.enter_amount_paid)} />
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>

      <BaseRow justify={'space-between'} align={'middle'} gutter={[10, 10]}>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={t(R.strings.item_management_content_purchase_unit)}
            name="purchaseUnit"
            rules={rules?.itemBrandId}
            required
          >
            <BaseSelect
              placeholder={t(
                R.strings.item_management_content_select_purchase_unit,
              )}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {(itemBrands as any[]).map((item, index) => {
                return (
                  <Option value={item?._id} key={index}>
                    {item?.name}
                  </Option>
                )
              })}
            </BaseSelect>
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={t(R.strings.item_management_content_inventory_management)}
            name="itemBrandId"
            rules={rules?.itemBrandId}
            required
          >
            <BaseSelect
              placeholder={t(R.strings.select)}
              suffixIcon={<SvgTableCloseIcon />}
              defaultValue={YesNoEnum.Yes}
            >
              <Option value={YesNoEnum.No} key={YesNoEnum.No}>
                {t(DefineYesNoData[YesNoEnum.No].keyI18n)}
              </Option>
              <Option value={YesNoEnum.Yes} key={YesNoEnum.Yes}>
                {t(DefineYesNoData[YesNoEnum.Yes].keyI18n)}
              </Option>
            </BaseSelect>
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>

      <BaseRow justify={'space-between'} align={'middle'} gutter={[10, 10]}>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={t(R.strings.warranty_month)}
            rules={rules?.itemBrandId}
            className="warrantyMonth"
          >
            <S.InputNumberStyled
              placeholder={t(R.strings.enter_warranty_month)}
            />
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={t(R.strings.status)}
            name="status"
            rules={rules?.status}
            required
          >
            <BaseSelect
              placeholder={t(R.strings.please_select_status)}
              suffixIcon={<SvgTableCloseIcon />}
              defaultValue={StatusCreateEnum.active}
            >
              {Object.values(StatusCreateEnum).map((item, index) => {
                return (
                  <Option value={item} key={index}>
                    {t(item)}
                  </Option>
                )
              })}
            </BaseSelect>
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>
      <BaseForm.Item
        label={t(R.strings.description_service)}
        name="descriptionService"
        required
      >
        <BaseTextEditor placeholder={t(R.strings.description_service)} />
      </BaseForm.Item>

      <BaseForm.Item
        label={t(R.strings.description_warranty)}
        name="descriptionWarranty"
        required
      >
        <BaseTextEditor placeholder={t(R.strings.description_warranty)} />
      </BaseForm.Item>
    </S.RootWrapper>
  )
}
