import { BaseButton } from 'app/components/common/BaseButton'
import styled from 'styled-components'

export const PageFooter = styled.div`
  background: white;
  padding: 16px 20px 16px 20px;
  gap: 20px;
  opacity: 0px;
  border-left: 1px solid var(--revenue-dashboard-border-color);
  margin-top: 16px;
`

export const CustomButton = styled(BaseButton)`
  margin-left: 10px;
  background: var(--other-blue-color);
  color: white;
  &:hover {
    background: initial !important;
  }
`

export const HeadingTitleCode = styled.p`
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: var(--states-orange-color);
  margin-left: 5px;
`
