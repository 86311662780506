/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Cancel, Confirm, RootWrapper } from './styles'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { ItemContext } from 'app/context/ItemContext'
import { useContext } from 'react'

const ActionForm = () => {
  const { t } = useTranslation()
  const { loading } = useContext(ItemContext)
  return (
    <RootWrapper>
      <Cancel>{t(R.strings.cancel)}</Cancel>
      <Confirm type="primary" htmlType="submit" loading={loading}>
        {t(R.strings.confirm)}
      </Confirm>
    </RootWrapper>
  )
}

export default ActionForm
