import R from 'app/assets/R'
import { PageTitle } from 'app/components/common/PageTitle'
import TreatmentManagementContainer from 'app/containers/Accounting/TreatmentManagement'
import { TreatmentProvider } from 'app/context/TreatmentContext'
import { isEqual } from 'lodash'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

const AccountingTreatmentManagementCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <TreatmentProvider>
        <PageTitle>{t(R.strings.treatment_management)}</PageTitle>
        <TreatmentManagementContainer />
      </TreatmentProvider>
    </>
  )
}
const AccountingTreatmentManagementPage = memo(
  AccountingTreatmentManagementCpn,
  isEqual,
)

export default AccountingTreatmentManagementPage
