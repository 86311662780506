export const dataBranch = [
  'Nam Kỳ',
  'Phố Huế',
  'Trần Hưng Đạo',
  'Lỹ Bán Bích',
  '3/2 (Quận 10)',
  'Vinh',
  'Bắc Ninh',
  '3/2 (Quận 10)',
  'Vinh',
  'Bắc Ninh',
  '3/2 (Quận 10)',
  'Vinh',
  'Bắc Ninh',
]

export const dataPendingRevenue = [
  150000, 2000000, 1200000, 18000, 15000, 45000, 120000, 150000, 45000, 120000,
  15000, 45000, 120000,
]

export const dataActualRevenue = [
  10000, 180000, 1500000, 210000, 560000, 450000, 1900, 15000, 45000, 120000,
  15000, 45000, 120000,
]

export const formatNumberMoney = (num: number): string => {
  const units = ['', 'k', 'tr', 'T']
  const divisor = 1000
  let index = 0

  while (num >= divisor && index < units.length - 1) {
    num /= divisor
    index++
  }

  const formattedNumber = num.toLocaleString('vi-VN', {
    maximumFractionDigits: 2,
  })
  return `${formattedNumber} ${units[index]}`
}
