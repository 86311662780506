import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import Appointment from 'app/containers/CustomerCare/AppointmentPage'

const AppointmentPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.customer_care_plan)}</PageTitle>
      <Appointment />
    </>
  )
}
const AppointmentPage = memo(AppointmentPageCpn, isEqual)

export default AppointmentPage
