/* eslint-disable @typescript-eslint/no-explicit-any */
import { IJobTitleManagement } from 'app/api/jobtitle/model/job-title-management'
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { usePagination } from 'app/hook'
import { useGetJobTitleManagementList } from 'app/react-query/hook/job-title'
import { IResponseQueryList } from 'app/react-query/model/common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormData, IFormRule, IPropsAddJobTitleDoctor } from './type'
import { useGetJobTitleLevelManagementList } from 'app/react-query/hook/job-title-level'
import { IJobTitleLevelManagement } from 'app/api/jobtitlelevel/model/job-title-level-management'

export const useModalHook = ({
  jobTitleSelected,
  jobTitleLevelSelected,
  onSubmit,
}: IPropsAddJobTitleDoctor) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const [visible, setVisible] = useState(false)

  const { flattenDataList } = usePagination()

  const { data: jobTitleData, isLoading: loadingJobTitle } =
    useGetJobTitleManagementList()

  const { data: jobTitleLevelData, isLoading: loadingJobTitleLevel } =
    useGetJobTitleLevelManagementList()

  const jobTitles = useMemo(() => {
    const flattenData: IResponseQueryList<IJobTitleManagement[]> =
      flattenDataList(jobTitleData)

    const jobTitlesBefore = flattenData?.data ?? []

    const jobTitlesAfter = jobTitlesBefore.filter(item => {
      return !jobTitleSelected?.find(itm => itm._id === item._id)
    })

    return jobTitlesAfter ?? []
  }, [jobTitleData, jobTitleSelected])

  const jobTitleLevels = useMemo(() => {
    const flattenData: IResponseQueryList<IJobTitleLevelManagement[]> =
      flattenDataList(jobTitleLevelData)

    return flattenData?.data ?? []
  }, [jobTitleLevelData, jobTitleLevelSelected])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    const jobTitle = jobTitles.find(item => item._id === values.jobTitleId)
    const jobTitleLevel = jobTitleLevels.find(
      item => item._id === values.jobTitleLevelId,
    )
    if (!jobTitle || !jobTitleLevel) return

    const params = {
      _id: jobTitle?._id,
      code: jobTitle?.code,
      name: jobTitle?.name,
      jobTitleIdManagement: jobTitle?._id,
      jobTitleLevelId: jobTitleLevel?._id,
      jobTitleLevelName: jobTitleLevel?.name,
    }

    onSubmit?.(params)
    handleCancel()
  }

  const rules: IFormRule = useMemo(() => {
    return {
      jobTitleId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.item_management_content_job_title_level_doctor),
          }),
        },
      ],
      jobTitleLevelId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.item_management_content_job_title_level_doctor),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    isLoading: loadingJobTitle,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    jobTitles,
    jobTitleLevels,
    loadingJobTitleLevel,
  }
}
