import { MoreOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { SvgMail1Icon, SvgPhoneCall1Icon } from 'app/assets/svg-assets'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { useTranslation } from 'react-i18next'
import { DividerLine } from '../../../../components'
import BaseText from 'app/components/common/BaseText'
import styled from 'styled-components'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { UpdateRelativesModal } from '../UpdateRelativesModal'
import { IRelatives } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/relatives'

export const ItemRelatives = ({
  index,
  item,
}: {
  item: IRelatives
  index: number
}) => {
  const { t } = useTranslation()
  return (
    <RootItemWrapper key={index}>
      <BaseSpace>
        <HeadItemWrapper
          justify={'space-between'}
          align={'middle'}
          gutter={[15, 15]}
        >
          <BaseCol>
            <BaseAvatarProfile
              name={item?.name}
              jobTitle={item?.jobTitle}
              src={item?.avatar}
            />
          </BaseCol>

          <BaseCol>
            <BaseRow align={'middle'} gutter={15}>
              <BaseCol>
                <AreaIcon>
                  <SvgPhoneCall1Icon />
                </AreaIcon>
              </BaseCol>
              <BaseCol>
                <AreaIcon>
                  <SvgMail1Icon />
                </AreaIcon>
              </BaseCol>
              <BaseCol>
                <AreaIcon>
                  <BasePopover
                    content={
                      <BaseSpace>
                        <UpdateRelativesModal />
                        <BaseButton type="text">
                          {t(R.strings.delete)}
                        </BaseButton>
                      </BaseSpace>
                    }
                    trigger="click"
                  >
                    <BaseButton
                      type={'text'}
                      icon={<MoreOutlined rev={undefined} />}
                      onClick={event => {
                        event?.stopPropagation()
                      }}
                    />
                  </BasePopover>
                </AreaIcon>
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </HeadItemWrapper>

        <DividerLine />

        <ContentItemWrapper>
          <BaseRow gutter={[15, 10]} align={'middle'}>
            <BaseCol xl={6}>
              <BaseText opacity="0.7" children={t(R.strings.relationship)} />
            </BaseCol>
            <BaseCol>
              <BaseText children={item?.relation ?? '-'} fontWeight="medium" />
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[15, 10]} align={'middle'}>
            <BaseCol xl={6}>
              <BaseText opacity="0.7" children={t(R.strings.phone)} />
            </BaseCol>
            <BaseCol>
              <BaseText children={item?.phone ?? '-'} fontWeight="medium" />
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[15, 10]} align={'middle'}>
            <BaseCol xl={6}>
              <BaseText opacity="0.7" children={t(R.strings.email)} />
            </BaseCol>
            <BaseCol>
              <BaseText children={item?.email ?? '-'} fontWeight="medium" />
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[15, 10]} align={'middle'}>
            <BaseCol xl={6}>
              <BaseText opacity="0.7" children={t(R.strings.address)} />
            </BaseCol>
            <BaseCol>
              <BaseText children={item?.address ?? '-'} fontWeight="medium" />
            </BaseCol>
          </BaseRow>
        </ContentItemWrapper>
      </BaseSpace>
    </RootItemWrapper>
  )
}

const RootItemWrapper = styled.div`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
`

const HeadItemWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
`

const ContentItemWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
  background-color: #fcfcfd; //need change
`

const AreaIcon = styled.div`
  border-radius: ${PADDING.xxl};
  background-color: ${convertedVariables.neutralBlack9Color};
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`
