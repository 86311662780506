import { requestGetReport09 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import {
  IParamsGetReport09,
  IResponseGetReport09,
} from 'app/api/report/model/report-09'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import {
  IDataBaseTable,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { initialPagination } from 'parkway-web-common'
import { getGender } from 'parkway-web-common'
import { isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import {
  useCommonHook,
  convertDataToTable
} from '../common-hook'
import { IDataReport09Year } from './data'

export const useTableYearHook = (props: IParamsHookReport) => {
  const { t } = useTranslation()
  const getDataYear = async (
    pagination: Pagination,
    params: IParamsGetReport09,
  ) => {
    try {
      const response: IResponseGetReport09 = await requestGetReport09({
        year: props?.year,
        ...params,
      })
      if (
        isEqual(response?.msgcode, ReportMessageCode.Report09.successGetData)
      ) {
        const data = response?.data?.rows ?? []
        
        let dataTable: IDataReport09Year[] = []
        data?.forEach((item, index) => {
          const [firstTypeAge, ...otherTypeAge] = item?.typeAges ?? []
          const [firstAgeGroupOfTypeAge, ...otherAgeGroupOfTypeAge] =
            firstTypeAge?.ageGroups ?? []

          let newResponse: IDataReport09Year = {
            key: index + 1,
            gender: t(getGender(item?.gender)),
            age: firstAgeGroupOfTypeAge?.ageGroup,
            typeAge: firstTypeAge?.typeAge,
            index: 2,
            numOfChild: otherAgeGroupOfTypeAge?.length + 1,
            ...convertDataToTable(firstAgeGroupOfTypeAge?.products ?? []),
          }

          let children: IDataReport09Year[] = []
          children = children.concat(
            otherAgeGroupOfTypeAge?.map((item, idx) => {

              return {
                key: Number(`${index + 1}${idx + 1}`),
                age: item?.ageGroup,
                ...convertDataToTable(item?.products ?? []),
              }
            }),
          )

          otherTypeAge?.forEach(item => {
            const [firstItem, ...otherItem] = item?.ageGroups ?? []

            children = children?.concat([
              {
                key: Number(`${index + 1}${children?.length + 1}`),
                age: firstItem?.ageGroup,
                typeAge: item?.typeAge,
                numOfChild: otherItem?.length + 1,
                ...convertDataToTable(firstItem?.products ?? []),
              },
            ])
            children = children.concat(
              otherItem?.map((item, idx) => {

                return {
                  key: Number(`${index + 1}${idx + children?.length + 1}`),
                  age: item?.ageGroup,
                  ...convertDataToTable(item?.products ?? []),
                }
              }),
            )
          })

          newResponse = {
            ...newResponse,
            children: children?.map((item, idx) => ({
              ...item,
              index: idx + 1,
            })),
          }

          const totalItem: IDataReport09Year = {
            key: Number(`${index + 1}${children?.length + 2}`),
            gender: 'Tổng',
            isTotalGroupTable: true,
            ...convertDataToTable(item?.products ?? []),
          }

          dataTable = dataTable.concat([newResponse, totalItem])
        })

        const metadata = response?.data?.metadata

        return {
          pagination: pagination ?? initialPagination,
          data: [
            ...dataTable,
            {
              key: Number(`${dataTable.length + 1}`),
              gender: 'Tổng',
              isTotalTable: true,
              ...convertDataToTable(metadata?.products ?? []),
            },
          ],
        } as IDataBaseTable<IDataReport09Year>
      }
      return {
        pagination: pagination ?? initialPagination,
        data: [],
      } as IDataBaseTable<IDataReport09Year>
    } catch (error) {
      return {
        pagination: pagination ?? initialPagination,
        data: [],
      } as IDataBaseTable<IDataReport09Year>
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,
    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({
    getTreeTableData: getDataYear,
    isExpandedWhenStart: true,
  })

  const { columns, exportExcel } = useCommonHook({
    expandedRowKeys,
    typeHook: 'month',
    currentFilter: props,
    fetchLoading
  })

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleResetExpand,
    fetch,
    fetchLoading,
    exportExcel
  }
}
