export const validateResponseHtml = (html?: string) => {
  html = html?.replace(/script/g, '').replace(/\/script/g, '')

  html = html
    ?.replace(/&lt;/g, '')
    .replace(/\/&gt;/g, '')
    .replace(/&gt;/g, '')

  return html
}
