/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { dataSupplies } from 'app/containers/Accounting/ItemManagement/model/supplies'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormData, IFormRule, IPropsCreateSupplies } from './type'
import { requestUpdateItemManagement } from 'app/api/item'
import { ItemMessageCode } from 'app/api/item/constant'
import { isEqual } from 'lodash'
import { notificationController } from 'app/controllers/notification-controller'

export const useModalHook = (props: IPropsCreateSupplies) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const suppliess = useMemo(() => {
    return dataSupplies
  }, [dataSupplies])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)

    try {
      const { id, itemIds } = props
      const transformItemIds =
        itemIds
          ?.filter(itemId => itemId._id !== values.supplies)
          ?.map(itemId => ({
            itemId: itemId._id,
            quantity: itemId.quantity,
          })) ?? []

      const addNewItemId = [
        ...transformItemIds,
        { itemId: values.supplies, quantity: values.quantity },
      ]

      const payload = {
        itemIds: addNewItemId,
      }

      const response = await requestUpdateItemManagement({
        id,
        body: payload,
      })

      if (
        isEqual(
          response?.msgcode,
          ItemMessageCode.ItemManagement.successPutData,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        props?.fetchTable?.()
      } else {
        notificationController.error({
          message: t(R.strings.add_account),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const rules: IFormRule = useMemo(() => {
    return {
      supplies: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.item_management_content_supplies),
          }),
        },
      ],
      quantity: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.quantity),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    suppliess,
  }
}
