import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { SearchDropdown } from '../SearchDropdown'
import { BaseButton } from 'app/components/common/BaseButton'
import * as S from './styles'
import {
  CategoryType,
  Component,
  categoriesList,
  configComponents,
} from 'app/constant'
import { useResponsive } from 'parkway-web-common'

export interface CategoryComponents {
  category: CategoryType
  components: Component[]
}

export const HeaderSearch: React.FC = () => {
  const { mobileOnly, isTablet } = useResponsive()

  const { pathname } = useLocation()

  const [query, setQuery] = useState('')
  const [components] = useState<Component[]>(configComponents)

  const [isModalOpen, setModalOpen] = useState(false)
  const [isOverlayOpen, setOverlayOpen] = useState(false)

  const sortedResults = query
    ? categoriesList.reduce((acc, current) => {
        const searchResults = components.filter(
          component =>
            component.categories.includes(current.name) &&
            component.keywords.some(keyword => keyword.includes(query)),
        )

        return searchResults.length > 0
          ? acc.concat({ category: current.name, components: searchResults })
          : acc
      }, [] as CategoryComponents[])
    : null

  useEffect(() => {
    setModalOpen(false)
    setOverlayOpen(false)
  }, [pathname])

  return (
    <>
      {mobileOnly && (
        <>
          <BaseButton
            type={isModalOpen ? 'link' : 'text'}
            icon={
              <S.SearchIcon
                rev={undefined}
                onClick={() => setModalOpen(true)}
              />
            }
          />
          <S.SearchModal
            open={isModalOpen}
            closable={false}
            footer={null}
            onCancel={() => setModalOpen(false)}
            destroyOnClose
          >
            <SearchDropdown
              query={query}
              setQuery={setQuery}
              data={sortedResults}
              isOverlayOpen={isOverlayOpen}
              setOverlayOpen={setOverlayOpen}
            />
          </S.SearchModal>
        </>
      )}

      {isTablet && (
        <SearchDropdown
          query={query}
          setQuery={setQuery}
          data={sortedResults}
          isOverlayOpen={isOverlayOpen}
          setOverlayOpen={setOverlayOpen}
        />
      )}
    </>
  )
}
