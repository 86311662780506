/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestDetailJobTitleLevelManagement,
  requestUpdateJobTitleLevelManagement,
} from 'app/api/jobtitlelevel'
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IFormData,
  IFormRule,
  IPropsUpdateJobTitleLevel,
  StatusJobTitleLevelEnum,
} from './type'
import {
  IPayloadUpdateJobTitleLevelManagement,
  IJobTitleLevelManagement,
} from 'app/api/jobtitlelevel/model/job-title-level-management'
import { JobTitleLevelMessageCode } from 'app/api/jobtitlelevel/constant'
import {
  convertArrayStatusFilterToArrayStatusNumber,
  convertStatusToFilterStatus,
} from 'app/common/helpers'

export const useModalHook = (props: IPropsUpdateJobTitleLevel) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormData>()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getDetail = async () => {
    if (!props?.id) {
      return
    }
    try {
      const res: ResponseType<IJobTitleLevelManagement> =
        await requestDetailJobTitleLevelManagement(props?.id)

      if (
        isEqual(
          res?.msgcode,
          JobTitleLevelMessageCode.JobTitleLevelManagement.successGetData,
        )
      ) {
        const data = res?.data
        form.setFieldsValue({
          name: data?.name ?? '',
          code: data?.code ?? '',
          description: data?.description ?? '',
          _id: data._id,
          status: convertStatusToFilterStatus(data?.status),
          level: data?.level,
        })
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  useEffect(() => {
    visible && getDetail()
  }, [props?.id, visible])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)
    try {
      const body: IPayloadUpdateJobTitleLevelManagement = {
        name: values?.name,
        description: values?.description,
        code: values?.code,
        _id: values?._id,
        level: values?.level,
        status:
          convertArrayStatusFilterToArrayStatusNumber([
            values?.status,
          ] as any)?.[0] ?? StatusJobTitleLevelEnum.active,
      }

      const response: ResponseType<string> =
        await requestUpdateJobTitleLevelManagement({
          id: props?.id,
          body,
        })

      if (
        isEqual(
          response?.msgcode,
          JobTitleLevelMessageCode.JobTitleLevelManagement.successPutData,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        props?.fetchTable?.()
        notificationController.success({
          message: t(R.strings.update),
          description: response?.message,
        })
      } else {
        notificationController.error({
          message: t(R.strings.update),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const rules: IFormRule = useMemo(() => {
    return {
      code: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.job_title_level_field_job_title_level_string_id),
          }),
        },
      ],
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.job_title_level_field_name),
          }),
        },
      ],
      description: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.job_title_level_field_description),
          }),
        },
      ],
      level: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.level),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
  }
}
