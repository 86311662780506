import { PlusOutlined } from '@ant-design/icons'
import { BaseSpace } from 'app/components/common/BaseSpace'
import CollapseComponent from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/CollapseComponent'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const ImageWrapper = styled(BaseSpace)`
  padding: ${PADDING.xxs};
  border-radius: 0px 0px ${BORDER_RADIUS} ${BORDER_RADIUS};
  background-color: #f3f4f6;
  .ant-upload-select {
    background-color: ${convertedVariables.backgroundColor} !important;
  }
  // .ant-upload-list-item-image {
  //   object-fit: cover !important;
  // }
  .ant-image-img {
    object-fit: contain !important;
  }
`

export const CollapseWrapper = styled(CollapseComponent)`
  border: 0px !important;

  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-header {
    padding-right: 0px !important;
  }

  .ant-collapse-content {
    border-top: 0px;
    .ant-collapse-content-box {
      padding-right: 0px !important;
    }
  }
`

export const PlusIcon = styled(PlusOutlined)<{ $size?: string }>`
  svg {
    fill: var(--collapse-background-color);
    width: ${props => props?.$size ?? '20px'} !important;
    height: ${props => props?.$size ?? '20px'} !important;
  }
`

export const UploadWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
  width: 100%;
`
