import { BaseRow } from 'app/components/common/BaseRow'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'
import { StatusTaskEnum } from './data'

export const RootWrapper = styled.div`
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  background-color: var(--white);
`
export const HeadWrapper = styled(BaseRow)`
  padding-bottom: ${PADDING.md};
`

export const TitleText = styled.span`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
`
export const CountWrapper = styled.span`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
`
export const Line = styled.div`
  background-color: #ebeef2; // need change
  width: 100%;
  height: 1px;
`

export const ItemTaskWrapper = styled(BaseRow)`
  padding: ${PADDING.xxxs} 0;
`

export const TitleItemText = styled.span`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.semibold};
`

export const StatusItemText = styled.span<{ status: StatusTaskEnum }>`
  color: ${props => {
    switch (props.status) {
      case StatusTaskEnum.NOT_DONE:
        return `var(--primary-color)`
      case StatusTaskEnum.IN_PROGRESS:
        return `#216AE2` // state blue
      case StatusTaskEnum.DONE:
        return `var(--states-green-color)`
      default:
        return `var(--primary-color)`
    }
  }};
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.medium};
`
