import { DiscountVoucherMessageCode } from 'app/api/marketing/discount-voucher/constant'
import R from 'app/assets/R'
import { _DEV_ } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { useStopApplyDiscountVoucher } from 'app/react-query/hook/voucher'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPropsStopApplyVoucher } from './type'

export const useModalHook = (props: IPropsStopApplyVoucher) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const { mutateAsync: mutateAsyncStopApply, isLoading } = useStopApplyDiscountVoucher()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleSubmit = async () => {
    if (!props?.id) return
    try {
      const response: ResponseType<unknown> = await mutateAsyncStopApply?.({
        id: props?.id,
      })

      if (
        isEqual(
          response?.msgcode,
          DiscountVoucherMessageCode.DiscountVoucher.success,
        )
      ) {
        handleCancel()
        notificationController.success({
          message: t(R.strings.cancel_apply_voucher),
          description: t(R.strings.success),
        })
      } else {
        notificationController.error({
          message: t(R.strings.cancel_apply_voucher),
          description: response?.message,
        })
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    }
  }

  return {
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
  }
}
