import { isEqual } from 'lodash'
import { getRandomUuid } from 'parkway-web-common'
import { useState } from 'react'
import { DiscountTypeEnum } from 'app/api/marketing/discount-campaign/model'
import { IBundleServiceItem } from '../type'

export const useBundleBillDiscountHook = () => {
  const [listBundleBillDiscountService, setListBundleBillDiscountService] =
    useState<IBundleServiceItem[]>([])

  const onAddNewBundleBillDiscountService = () => {
    setListBundleBillDiscountService([
      ...listBundleBillDiscountService,
      {
        id: getRandomUuid(),
        name: undefined,
        selectedTreatment: [],
      },
    ])
  }

  const onChangeNameBundleBillDiscountByIndex = (
    index: number,
    name: string,
  ) => {
    const newList = listBundleBillDiscountService.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          name,
        }
      }
      return item
    })
    setListBundleBillDiscountService(newList)
  }

  const onRemoveBundleBillDiscountServiceByIndex = (index: number) => {
    const newList = listBundleBillDiscountService.filter((_, i) => i !== index)
    setListBundleBillDiscountService(newList)
  }

  const onChangeItemBundleBillDiscountServiceByIndex = (
    index: number,
    item: IBundleServiceItem,
  ) => {
    const newList = listBundleBillDiscountService.map((itm, i) => {
      if (isEqual(i, index)) {
        return {
          ...itm,
          ...item,
        }
      }
      return itm
    })
    setListBundleBillDiscountService(newList)
  }

  const onChangeDiscountValueItemBundleBillDiscountService = (
    index: number,
    treatmentIndex: number,
    discountValue: number,
  ) => {
    const newList = listBundleBillDiscountService.map((item, i) => {
      if (isEqual(i, index)) {
        return {
          ...item,
          selectedTreatment: item?.selectedTreatment?.map((itm, idx) => {
            if (isEqual(idx, treatmentIndex)) {
              return {
                ...itm,
                discountValue,
              }
            }
            return itm
          }),
        }
      }
      return item
    })
    setListBundleBillDiscountService(newList)
  }

  const onChangeDiscountTypeItemBundleBillDiscountService = (
    index: number,
    treatmentIndex: number,
    discountType: DiscountTypeEnum,
  ) => {
    const newList = listBundleBillDiscountService.map((item, i) => {
      if (isEqual(i, index)) {
        return {
          ...item,
          selectedTreatment: item?.selectedTreatment?.map((itm, idx) => {
            if (isEqual(idx, treatmentIndex)) {
              return {
                ...itm,
                discountType,
              }
            }
            return itm
          }),
        }
      }
      return item
    })
    setListBundleBillDiscountService(newList)
  }

  const onChangeQuantityItemBundleBillDiscountService = (
    index: number,
    treatmentIndex: number,
    quantity: number,
  ) => {
    const newList = listBundleBillDiscountService.map((item, i) => {
      if (isEqual(i, index)) {
        return {
          ...item,
          selectedTreatment: item?.selectedTreatment?.map((itm, idx) => {
            if (isEqual(idx, treatmentIndex)) {
              return {
                ...itm,
                quantity,
              }
            }
            return itm
          }),
        }
      }
      return item
    })
    setListBundleBillDiscountService(newList)
  }

  const onDeleteItemInSelectedTreatmentBundleBillDiscount = (
    index: number,
    treatmentIndex: number,
  ) => {
    const newList = listBundleBillDiscountService.map((item, i) => {
      if (isEqual(i, index)) {
        return {
          ...item,
          selectedTreatment: item?.selectedTreatment?.filter(
            (_, idx) => idx !== treatmentIndex,
          ),
        }
      }
      return item
    })
    setListBundleBillDiscountService(newList)
  }

  const onChangeBundleBillDiscountList = (list: IBundleServiceItem[]) => {
    setListBundleBillDiscountService(list)
  }

  return {
    onAddNewBundleBillDiscountService,
    listBundleBillDiscountService,
    onRemoveBundleBillDiscountServiceByIndex,
    onChangeNameBundleBillDiscountByIndex,
    onChangeDiscountTypeItemBundleBillDiscountService,
    onChangeDiscountValueItemBundleBillDiscountService,
    onChangeItemBundleBillDiscountServiceByIndex,
    onChangeQuantityItemBundleBillDiscountService,
    onDeleteItemInSelectedTreatmentBundleBillDiscount,
    onChangeBundleBillDiscountList,
  }
}
