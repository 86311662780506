import { requestGetProviderManagement } from 'app/api/inventory'
import { IProvider } from 'app/api/inventory/model/management'
import { notificationController } from 'app/controllers/notification-controller'
import { get } from 'lodash'
import { createContext, useState } from 'react'

interface IProviderContext {
  loading: boolean
  data: IProvider[]
  setLoading?: (loading) => void
  setData?: (params) => void
  fetchProvider?: (params) => void
}
export const ProviderContext = createContext<IProviderContext>({
  loading: false,
  data: [],
})

export const InventoryProvider = ({ children }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchProvider = async params => {
    setLoading(true)
    try {
      const response = await requestGetProviderManagement(params)
      setData(response?.data)
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }
    setLoading(false)
  }

  return (
    <ProviderContext.Provider
      value={{ data, loading, setLoading, fetchProvider, setData }}
    >
      {children}
    </ProviderContext.Provider>
  )
}
