/* eslint-disable @typescript-eslint/no-explicit-any */
import { JobTitleMessageCode } from 'app/api/jobtitle/constant'
import { requestGetAllJobTitleGroup } from 'app/api/jobtitlegroup'
import { IJobTitleGroup } from 'app/api/jobtitlegroup/model/job-title-management'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useInfiniteQuery } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { JobTitleGroupKeys } from '../query-key/job-title-group'

export function useGetJobTitleGroup(enabled = true) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IJobTitleGroup[]>>(
    // <IResponseQueryList<IJobTitleGroupManagement[]>>
    JobTitleGroupKeys.listJobTitleGroup(),
    async () => {
      try {
        const res: ResponseType<IJobTitleGroup[]> =
          await requestGetAllJobTitleGroup({})

        if (
          isEqual(
            res?.msgcode,
            JobTitleMessageCode.JobTitleManagement.successGetData,
          )
        ) {
          const data: IJobTitleGroup[] = res?.data || []
          return {
            data,
            total: res?.data.length ?? 0,
          }
        }
        return {
          data: [],
          total: res?.data.length,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
