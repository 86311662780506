import { FONT_SIZE, FONT_WEIGHT, LAYOUT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 250px;
  min-width: 1000px;
  padding: ${LAYOUT.desktop.paddingVertical} ${PADDING.md};
  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
`

export const HeadingTitlePage = styled.p`
  font-size: ${FONT_SIZE.xxl};
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.bold};
  padding-bottom: ${LAYOUT.desktop.headerPadding};
`
