/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetMarketingPlan } from 'app/api/marketing'
import { MarketingMessageCode } from 'app/api/marketing/constant'
import {
  IParamsGetMarketingPlan,
  IResponseGetMarketingPlan,
} from 'app/api/marketing/model'
import R from 'app/assets/R'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import {
  IDataBaseTable,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import {
  convertChildCostPlanValue,
  convertTotalCostPlanValue,
  useCommonHook,
} from '../common-hook'
import { initialPagination } from '../common-model'
import { IDataMarketingPlanWeekly } from './data'

export const useTableWeeklyHook = (props: IParamsHookReport) => {
  const { t } = useTranslation()

  const getDataWeek = async (
    pagination: Pagination,
    params: IParamsGetMarketingPlan,
  ) => {
    const response: IResponseGetMarketingPlan = await requestGetMarketingPlan({
      periodtype: 'week',
      month: props?.month,
      year: props?.year,
      ...params,
    })
    if (
      isEqual(
        response?.msgcode,
        MarketingMessageCode.MarketingPlan.successGetData,
      )
    ) {
      const data = response?.data?.rows ?? []
      const dataTable: IDataMarketingPlanWeekly[] =
        data?.map((item, index) => {
          const [firstClinic, ...otherClinic] = item?.area ?? []
          const [firstWeekOfFirstClinic, ...otherWeekOfFirstClinic] =
            firstClinic?.weeks ?? []
          const keyOfFirstClinic = Number(`${(index + 1) * 10}1`)

          const childClinicData = convertChildCostPlanValue(
            firstWeekOfFirstClinic,
          )
          const totalClinicData = convertTotalCostPlanValue(firstClinic)
          const newResponse: IDataMarketingPlanWeekly = {
            key: index + 1,
            service: item?.serviceName,
            isArea: true,
            childrenKey: keyOfFirstClinic,
            week: `${t(R.strings.week)} ${firstWeekOfFirstClinic?.week}`,
            ...childClinicData,
            ...totalClinicData,
            childOfFirstChild: otherWeekOfFirstClinic?.map((item, idx) => {
              const keyChild = Number(`${keyOfFirstClinic}0${idx}`)
              const childClinicData = convertChildCostPlanValue(item)
              return {
                key: keyChild,
                week: `${t(R.strings.week)} ${item?.week}`,
                index: idx + 1,
                ...childClinicData,
                parentKey: keyOfFirstClinic,
              }
            }),
            children: otherClinic?.map((clinic, idx: number) => {
              const [firstWeekOfClinic, ...otherWeekOfClinic] =
                clinic?.weeks ?? []
              const keyOfClinic = Number(`${(index + 1) * 10}000${idx + 2}`)

              const childClinicData =
                convertChildCostPlanValue(firstWeekOfClinic)
              const totalClinicData = convertTotalCostPlanValue(clinic)
              return {
                key: keyOfClinic,
                week: `${t(R.strings.week)} ${firstWeekOfClinic?.week}`,
                ...childClinicData,
                ...totalClinicData,
                children: otherWeekOfClinic?.map((item, idxChild) => {
                  const keyChild = Number(`${keyOfClinic}00${idxChild}`)
                  const childClinicData = convertChildCostPlanValue(item)
                  return {
                    key: keyChild,
                    index: idxChild + 1,
                    week: `${t(R.strings.week)} ${item?.week}`,
                    ...childClinicData,
                  }
                }),
              }
            }),
          }
          return newResponse
        }) ?? []
      return {
        pagination: initialPagination,
        data: dataTable,
      } as IDataBaseTable<IDataMarketingPlanWeekly>
    }
    return {
      pagination: initialPagination,
      data: [],
    } as IDataBaseTable<IDataMarketingPlanWeekly>
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,
    expandedAreaKeys,
    handleExpandArea,
    fetch,
    handleResetExpand,
    fetchLoading,
  } = useBaseTableReportHook({
    getTreeTableData: getDataWeek,
  })

  const { columns, onClickImport, data } = useCommonHook({
    expandedRowKeys,
    fetch,
    fetchLoading,
    handleExpand,
    typeHook: 'week',
    handleExpandArea,
    expandedAreaKeys,
    dataTable: tableData.data,
    currentFilter: props,
  })

  const _onClickImport = async (file: any) => {
    onClickImport(file, 'week')
  }

  return {
    columns,
    tableData,
    data,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleResetExpand,
    fetch,
    onClickImport: _onClickImport,
  }
}
