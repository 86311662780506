import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormData, IFormRule } from './type'
import { IPropsCreateItemCategory, StatusItemCategoryType } from '.'
import { StatusTableType } from 'app/components/tables/BaseTableManagement/hook'
import { first, get, isEqual } from 'lodash'
import { IPayloadCreateItemCategoryManagement } from 'app/api/item-category/model/management'
import { ResponseType } from 'parkway-web-common'
import { requestCreateItemCategoryManagement } from 'app/api/item-category'
import { notificationController } from 'app/controllers/notification-controller'
import { ItemCategoryMessageCode } from 'app/api/item-category/constant'
import { convertArrayStatusFilterToArrayStatusNumber } from 'app/common/helpers'

export const useModalHook = ({
  fetchTable,
  parentId,
}: IPropsCreateItemCategory) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState<StatusItemCategoryType | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)

    try {
      const payload: IPayloadCreateItemCategoryManagement = {
        name: values.name,
        parentId: parentId ?? undefined,
        status: first(
          convertArrayStatusFilterToArrayStatusNumber([
            values.status,
          ] as StatusTableType[]),
        ),
      }
      const response: ResponseType<string> =
        await requestCreateItemCategoryManagement(payload)

      if (
        isEqual(
          response?.msgcode,
          ItemCategoryMessageCode.ItemCategoryManagement.successPostData,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        fetchTable?.()
      } else {
        notificationController.error({
          message: t(R.strings.item_category_content_add),
          description: response?.message,
        })
      }
    } catch (error) {
      notificationController.error({
        message: t(R.strings.item_category_content_add),
        description: get(error, 'message', ''),
      })
      setIsLoading(false)
    }
  }

  const rules: IFormRule = useMemo(() => {
    return {
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.skill_field_name),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    setStatus,
    status,
    isLoading,
  }
}
