import * as S from './styles'
import CollapseProperty from './CollapseProperty'
import ImageProperty from './ImageProperty'
import { DashedLine } from 'app/components/common/BaseLayoutReport/styles'
import R from 'app/assets/R'
import { t } from 'i18next'

const Properties = () => {
  return (
    <S.RootWrapper direction="vertical" size={10}>
      <S.Title>
        {t(R.strings.item_management_content_add_product_property)}
      </S.Title>
      <CollapseProperty />
      <DashedLine />
      <ImageProperty />
    </S.RootWrapper>
  )
}

export default Properties
