import { BaseRow } from 'app/components/common/BaseRow'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const CompanyWrapper = styled(BaseRow)`
  padding: ${PADDING.xs};
  border-radius: ${BORDER_RADIUS};
  background-color: #f6f6f6;
  margin: 0px !important;
  border: 1px solid ${convertedVariables.neutralBlack2Color};
`

export const InputWrapper = styled(BaseInput)`
  background-color: ${convertedVariables.backgroundColor};
`
