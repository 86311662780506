import { ApiClient } from 'app/service/api-service'
import { DashboardEndPoint } from './constant'
import {
  IParamsGeneralInfo,
  IParamsGetGroupByClinic,
  IParamsRevenueAndPercentage,
} from './model'

export const requestGetRevenueToday = (params: IParamsRevenueAndPercentage) =>
  ApiClient.Get({
    url: DashboardEndPoint.RevenueToday.requestGetData,
    params: { params },
  })

export const requestGetRevenueClinicChart = (params: IParamsGetGroupByClinic) =>
  ApiClient.Get({
    url: DashboardEndPoint.RevenueClinicChart.requestGetData,
    params: { params },
  })

export const requestGetGeneralInfo = (params?: IParamsGeneralInfo) =>
  ApiClient.Get({
    url: DashboardEndPoint.GeneralInfoDashboard.requestGetData,
    params: { params },
  })
