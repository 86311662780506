import { getToken } from 'app/service/storage/storage-service'
import { useInfiniteQuery } from 'react-query'
import { VoucherKeys } from '../query-key/voucher'
import { isEqual } from 'lodash'
import { LimitDiscountMessageCode } from 'app/api/marketing/limit-discount/constant'
import { IParamsGetLimitDiscount } from 'app/api/marketing/limit-discount/model'
import { IResponseImportFile } from 'app/containers/Marketing/LimitDiscountManagement/components/ImportModal/type'
import { ResponseType } from 'parkway-web-common'
import { getLimitDiscountData } from 'app/api/marketing/limit-discount'
import { LimitDiscountKeys } from '../query-key/limit-discount'

export function useGetLimitDiscountList(
  params?: IParamsGetLimitDiscount,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<ResponseType<IResponseImportFile[]>, undefined>(
    LimitDiscountKeys.listLimitDiscounts(params),
    async () => {
      const res: ResponseType<IResponseImportFile[]> =
        await getLimitDiscountData({
          ...params,
        })

      if (
        isEqual(
          res?.msgcode,
          LimitDiscountMessageCode.LimitDiscount.successGetData,
        )
      ) {
        return res
      }

      return Promise.reject(res)
    },
    {
      enabled: !!token && enabled,
    },
  )
}
