import { TypeItemEnum } from '../../common-model'

export enum StatusItemManagementEnum {
  all = 'all',
  active = 'active',
  // deleted = 'deleted',
  // error = 'error',
  inactive = 'inactive',
}

export const DefineStatusItemManagement = {
  [StatusItemManagementEnum.all]: 'Tất cả các trạng thái',
  [StatusItemManagementEnum.active]: 'Đang hoạt động',
  // [StatusItemManagementEnum.deleted]: 'Đã xóa',
  // [StatusItemManagementEnum.error]: 'Lỗi',
  [StatusItemManagementEnum.inactive]: 'Không hoạt động',
}

export interface IFilterResponseItemManagement {
  status?: StatusItemManagementEnum
  keyword?: string
  type?: TypeItemEnum
  categoryid?: string
}

export interface IFilterProps {
  handleChangeFilter: (value: IFilterResponseItemManagement) => void
}
