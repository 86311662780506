import { FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'
import { BaseAvatar } from '../BaseAvatar'
import { BaseCol } from '../BaseCol'
import { BaseRow } from '../BaseRow'
import BaseText from '../BaseText'
import { BaseTooltip } from '../BaseTooltip'
interface IProps {
  name?: string
  src?: string
  jobTitle?: string
  isShowToolTip?: boolean
}
const BaseAvatarProfile: React.FC<IProps> = ({
  jobTitle,
  name,
  src,
  isShowToolTip = false,
}) => {
  return (
    <BaseRow gutter={[10, 10]} align={'middle'} wrap={false}>
      <BaseCol>
        <BaseAvatar alt={name} src={src} />
      </BaseCol>

      <BaseCol>
        <BaseText
          children={name}
          fontWeight="semibold"
          colorText="primaryColor"
          fontSize="xs"
          lineHeight='1.45'
        />
        {jobTitle ? (
          isShowToolTip ? (
            <BaseTooltip title={jobTitle}>
              <TextJobTitleInProfileName>{jobTitle}</TextJobTitleInProfileName>
            </BaseTooltip>
          ) : (
            <TextJobTitleInProfileName>{jobTitle}</TextJobTitleInProfileName>
          )
        ) : null}
      </BaseCol>
    </BaseRow>
  )
}

export default BaseAvatarProfile

const TextJobTitleInProfileName = styled.div<{ $maxWidthJobTitle?: string }>`
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.medium};
  font-size: 10px;
  line-height: 1.25;
  max-width: ${props => props?.$maxWidthJobTitle ?? '200px'} !important;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`
