export enum StatusTaskEnum {
  NOT_DONE = 1,
  IN_PROGRESS,
  DONE,
}

export interface ITaskDashboard {
  id: number
  title: string
  status: StatusTaskEnum
}

export const dataTasksDashboard: ITaskDashboard[] = [
  { id: 1, title: 'Tham gia tập huấn', status: StatusTaskEnum.IN_PROGRESS },
  { id: 2, title: 'Đánh giá bác sĩ mới', status: StatusTaskEnum.NOT_DONE },
  { id: 3, title: 'Thực tập', status: StatusTaskEnum.NOT_DONE },
  { id: 4, title: 'Review liệu trình', status: StatusTaskEnum.NOT_DONE },
  { id: 5, title: 'Tập huấn', status: StatusTaskEnum.NOT_DONE },
  { id: 6, title: 'Review liệu trình', status: StatusTaskEnum.NOT_DONE },
]
