import * as S from 'app/components/tables/BaseTableManagement/styles'
import HeaderPage from 'app/components/header/components/HeaderPage'
import R from 'app/assets/R'
import CompanyInfo from '../components/CompanyInfo'
import BuyInfo from '../components/BuyInfo'
import OrderInfo from '../components/OrderInfo'
import { WarehousePurchaseContext } from '../../context'
import Footer from '../components/Footer'
import { useParams } from 'react-router'
import { requestDetailWarehousePurchaseManagement } from 'app/api/inventory-new'
import { useContext, useEffect } from 'react'
import dayjs from 'dayjs'
import { cloneDeep, get } from 'lodash'
import { SaveDraft } from '../components/SaveDraft'
import { notificationController } from 'app/controllers/notification-controller'

const reorder = () => {
  const params = useParams()
  const { setOrders, form, setWarehousePurchaseDetail, setDiscount } =
    useContext(WarehousePurchaseContext)
  const getWarehousePurchaseDetail = async () => {
    try {
      const { data } = await requestDetailWarehousePurchaseManagement(
        params?.id,
      )

      const modifyData = cloneDeep(data)
      delete modifyData._id
      delete modifyData.status

      const orders = modifyData?.orders?.map(order => ({
        ...order,
        name: order?.product?.name,
        code: order?.product?.code,
        purchaseMeasurementUnit: order?.measurementUnit?.name,
        measurementUnitId: order?.measurementUnitId,
      }))

      setOrders?.(orders)
      await form?.setFieldsValue({
        ...modifyData,
        paymentDueDate: dayjs(modifyData?.paymentDueDate),
        estimateDeliveryDate: dayjs(modifyData?.estimateDeliveryDate),
      })

      setWarehousePurchaseDetail?.({
        ...modifyData,
        paymentDueDate: dayjs(modifyData?.paymentDueDate),
        estimateDeliveryDate: dayjs(modifyData?.estimateDeliveryDate),
      })

      setDiscount?.(modifyData?.discount)
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }
  }

  useEffect(() => {
    getWarehousePurchaseDetail()
  }, [])

  return (
    <>
      <S.BaseManagementWrapper>
        <HeaderPage
          titleI18nKey={R.strings.purchase_order_re_order}
          rightComponent={<SaveDraft />}
        />
        <div>
          <div>
            <CompanyInfo />
            <BuyInfo />
          </div>
        </div>

        <div>
          <div>
            <OrderInfo />
          </div>
        </div>
      </S.BaseManagementWrapper>

      <Footer />
    </>
  )
}

export default reorder
