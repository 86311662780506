import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import moment from 'moment'
import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'

const currentMonth = moment().month() + 1

const ItemInfo = ({
  label,
  type,
  value,
}: {
  label: string
  value?: string
  type: 'normal' | 'success' | 'warning'
}) => {
  return (
    <BaseRow justify={'space-between'} align={'middle'}>
      <BaseCol xl={20}>
        <BaseText children={label} opacity="0.7" fontWeight="medium" />
      </BaseCol>

      <BaseCol xl={4}>
        <NumberWrapper $type={type}>
          <BaseText
            children={value ?? '-'}
            fontWeight="semibold"
            colorText={
              isEqual(type, 'success') ? 'primaryColor' : 'backgroundColor'
            }
          />
        </NumberWrapper>
      </BaseCol>
    </BaseRow>
  )
}

export const StatisticsDoctorLayout = () => {
  return (
    <SectionBase>
      <BaseSpace>
        <BaseRow justify={'space-between'} align={'middle'} gutter={[15, 10]}>
          <BaseCol>
            <BaseText
              children={t(R.strings.statistic_month_number, {
                month: currentMonth,
              })}
              fontSize="md"
              fontWeight="semibold"
            />
          </BaseCol>
          <BaseCol>
            <BaseText
              children={t(R.strings.see_detail)}
              fontWeight="medium"
              fontSize="xs"
              colorText="collapseBackgroundColor"
            />
          </BaseCol>
        </BaseRow>

        <BaseRow justify={'space-between'} align={'middle'}>
          <BaseCol xl={20}>
            <BaseText
              children={t(R.strings.closing_rate)}
              opacity="0.7"
              fontWeight="medium"
            />
          </BaseCol>

          <BaseCol xl={4}>
            <BaseText
              children={`${50}%`}
              fontWeight="semibold"
              colorText="statesGreenColor"
            />
          </BaseCol>
        </BaseRow>

        <ItemInfo label={'Niềng răng mắc cài'} type="normal" value="50" />

        <ItemInfo label={t(R.strings.invisalign)} type="normal" value="50" />

        <ItemInfo label={'Tư vấn chỉnh nha'} type="normal" value="50" />
        <ItemInfo label={'Tư vấn tổng quát'} type="normal" value="50" />
        <ItemInfo label={'Số ca quá hạn'} type="warning" value="50" />
        <ItemInfo
          label={'Số bệnh nhân đã điều trị'}
          type="success"
          value="50"
        />
      </BaseSpace>
    </SectionBase>
  )
}

const NumberWrapper = styled.div<{ $type: 'normal' | 'success' | 'warning' }>`
  background-image: ${props => {
    switch (props?.$type) {
      case 'normal':
        return 'linear-gradient(90deg, #629DAA, #6DAEBD)'
      case 'success':
        return 'linear-gradient(90deg, #F1F1F1, #F1F1F1)'
      case 'warning':
        return 'linear-gradient(90deg, #FD7B59, #F75428)'
    }
  }};
  padding: 5px;
  border-radius: ${PADDING.md};
  width: fit-content;
`
