import { Form } from 'antd'
import { AppointmentContext } from 'app/context/AppointmentContext'
import { PageAppointmentContext } from 'app/context/PageAppointmentContext'
import { ProfileContext } from 'app/context/ProfileContext'
import { UnitContext } from 'app/context/UnitContext'
import { IUnit } from 'app/model/unit.model'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IClinic } from '../../../SidebarLeft/type'
import { IAppointment } from '../../../type'
import './index.css'
import {
  CALLCENTER,
  EScheduleStatus,
  SCHEDULE_CALLCENTER,
} from 'app/api/appointment/model'
import { PatientContext } from 'app/context/PatientContext'
import { IPatient } from 'app/api/patient/model/patient'
import { DATE } from 'app/common/constants'
import { IProps, IRefWithBoundingRect, PageType } from './type'
import { PATIENT_TYPE } from './FormModal'
import { ItemCategoryContext } from 'app/context/ItemCategoryContext'
import { ItemContext } from 'app/context/ItemContext'
import { IItem } from 'app/api/item/model'
import { ITreatment } from 'app/containers/Accounting/ItemManagement/model/treatment'
import { disabledDate, getStatus, getTitle, timeslots } from './helper'
import { get, unionBy } from 'lodash'
import { REQUEST_APPOINTMENT_TYPE } from 'app/common/enum'

export const useCommonHook = (props: IProps) => {
  const {
    open,
    setOpen,
    appointment,
    setAppointment,
    view,
    required: PropRequired,
  } = props
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [callCenter, setCallCenter] = useState({})
  const submitButton = useRef<IRefWithBoundingRect>(null)
  const [clinic, setClinic] = useState<IClinic[]>([])
  const [treatments, setTreatments] = useState<ITreatment[]>([])
  const { units } = useContext(UnitContext)
  const {
    itemCategories: categoryServices,
    fetchItemCategory: fetchCategoryServices,
  } = useContext(ItemCategoryContext)

  const { items, fetchItemData } = useContext(ItemContext)
  const { profiles: doctors, fetchData: fetchDoctors } =
    useContext(ProfileContext)
  const { postAppointment, updateAppointment } = useContext(AppointmentContext)
  const { filter } = useContext(PageAppointmentContext)
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { getCallCenterScheduleTotal } = useContext(AppointmentContext)
  const {
    fetchDataAppointmentPatient: fetchPatient,
    patients,
    patientProfiles,
  } = useContext(PatientContext)

  const [note, setNote] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [searchPatient, setSearchPatient] = useState<string>('')
  const [searchDoctor, setSearchDoctor] = useState<string>('')
  const [required, setRequired] = useState(PropRequired)

  // Remove the field if the value doesn't exist.
  const newAppointment: IAppointment = useMemo(() => {
    return Object.keys(appointment).reduce((accumulator, key) => {
      if (appointment[key]) accumulator[key] = appointment[key]
      return accumulator
    }, {})
  }, [appointment])

  const handleOk = async body => {
    setConfirmLoading(true)

    if (body?.patientId === PATIENT_TYPE.NEW) {
      delete body?.patientId
      delete appointment?.patientId
    }

    const status = getStatus(props?.page)
    const isCallCenter = props?.page === PageType?.CALLCENTER
    if (newAppointment?._id) {
      await updateAppointment?.(
        {
          ...appointment,
          ...callCenter,
          ...body,
          date: body?.date?.format(DATE.YYYY_MM_DD),
          services: [body?.services].flat(Infinity),
          treatments: [body?.treatments].flat(Infinity),
        },
        newAppointment?._id,
      )
      if (appointment && appointment.fetch) {
        await appointment?.fetch()
      }
      setAppointment({})
    } else {
      await postAppointment?.({
        ...body,
        scheduleStatus: !isCallCenter ? EScheduleStatus.SCHEDULE : 0,
        status,
        date: body?.date?.format(DATE.YYYY_MM_DD),
        services: [body?.services].flat(Infinity),
        treatments: [body?.treatments].flat(Infinity),
        type: !isCallCenter ? SCHEDULE_CALLCENTER : CALLCENTER,
      })
      if (appointment && appointment.fetch) {
        await appointment?.fetch()
      }
      form.resetFields()
    }

    if (!appointment?.fetch) {
      await getCallCenterScheduleTotal?.({
        date: filter?.date?.format(DATE.YYYY_MM_DD),
        view,
        areaId: filter?.areaId,
        clinicId: filter?.clinicId,
        doctorId: filter?.doctorId,
      })
    }

    setSearchDoctor('')
    setConfirmLoading(false)
    setOpen(false)
    setRequired(true)
  }

  const handleCancel = async () => {
    await setAppointment({})
    await form.resetFields()
    await setOpen(false)
    setRequired(true)
  }

  const handleArea = value => {
    const areaSelected: IUnit | undefined = units.find(
      (area: IUnit) => area?._id === value,
    )
    setClinic(get(areaSelected, 'childs', []))
  }

  const handleCategoryService = async value => {
    if (value)
      await fetchItemData?.({
        type: 'service',
        categoryid: value,
      })
  }

  const handleService = value => {
    const serviceSelected: IItem | undefined = items?.find(
      (item: IItem) => item?._id === value,
    )

    setTreatments(get(serviceSelected, 'itemIds', []))
  }

  useEffect(() => {
    form.setFieldsValue(newAppointment)
    handleArea(newAppointment?.areaId)
    setNote(newAppointment?.note || '')
    handleCategoryService(newAppointment?.categoryServiceId)
    setPhone(String(newAppointment?.phone || ''))
  }, [open])

  useEffect(() => {
    ///Set initial value if a filter is present.
    if (!newAppointment?._id && !newAppointment?.fetch) {
      const fieldAppointment = {
        areaId: filter?.areaId || '',
        clinicId: filter?.clinicId || '',
        categoryServiceId: filter?.categoryServiceId || '',
        doctorId: filter?.doctorId || '',
      }
      form.setFieldsValue(fieldAppointment)
      handleArea(fieldAppointment?.areaId)
      handleCategoryService(fieldAppointment?.categoryServiceId)
      setAppointment(fieldAppointment)
    }
  }, [open])

  useEffect(() => {
    if (!categoryServices.length)
      fetchCategoryServices?.({
        keyword: '',
        page: '0',
        pageSize: '200',
        deactivated: false,
      })
  }, [])

  useEffect(() => {
    handleService(appointment?.services)
  }, [items])

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchPatient?.({
        page: 0,
        pageSize: 20,
        keyword: searchPatient,
      })
    }, 500)
    return () => clearTimeout(timer)
  }, [searchPatient])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (phone || newAppointment?.patient?.phone) {
        fetchPatient?.(
          {
            page: 0,
            pageSize: 20,
            keyword: phone || newAppointment?.patient?.phone,
          },
          REQUEST_APPOINTMENT_TYPE.PROFILE,
        )
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [phone, newAppointment?.patient?.phone])

  const onSelectPatient = async value => {
    const patientNameOrId = value[value.length - 1]

    const patient = patients?.find(
      (patient: IPatient) => patient?._id === patientNameOrId,
    )

    form.setFieldValue('phone', (patient && patient['phone']) || '')
    form.setFieldValue(
      'fullName',
      (patient && patient['name']) || patientNameOrId,
    )

    await fetchPatient?.(
      {
        page: 0,
        pageSize: 20,
        keyword: patient && patient['phone'],
      },
      REQUEST_APPOINTMENT_TYPE.PROFILE,
    )
    form.setFieldValue('patientId', patient ? patientNameOrId : undefined)
  }

  const onSelectPatientProfile = async value => {
    const patientSelectedId = value[value.length - 1]
    if (patientSelectedId && patientSelectedId != PATIENT_TYPE.NEW) {
      const patient = patientProfiles?.find(
        (patient: IPatient) => patient?._id === patientSelectedId,
      )
      form.setFieldValue('phone', (patient && patient['phone']) || '')
      form.setFieldValue(
        'fullName',
        (patient && patient['name']) || patientSelectedId,
      )
    }
  }

  const onChangeClinic = value => {
    fetchDoctors?.({
      page: 0,
      pageSize: 20,
      sort: '-1',
      group: 'doctor',
      keyword: searchDoctor,
      unitId: value || appointment?.clinicId,
    })
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      onChangeClinic(appointment?.clinicId)
    }, 500)
    return () => clearTimeout(timer)
  }, [searchDoctor])

  const title = getTitle(newAppointment?._id, props?.page, props?.title, t)

  return {
    title,
    open,
    handleOk,
    confirmLoading,
    handleCancel,
    newAppointment,
    setCallCenter,
    submitButton,
    t,
    setRequired,
    form,
    onSelectPatient,
    onSelectPatientProfile,
    setSearchPatient,
    patients: unionBy(patients, patientProfiles, '_id'),
    handleArea,
    units,
    clinic,
    handleCategoryService,
    categoryServices,
    setSearchDoctor,
    doctors,
    items,
    disabledDate,
    timeslots,
    setNote,
    note,
    page: props?.page,
    handleService,
    treatments,
    onChangeClinic,
    patientProfiles,
    preserve: false,
    required,
    setPhone,
    phone,
  }
}
