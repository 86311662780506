import R from 'app/assets/R'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import {
  RenderValueTableReport,
  convertColumnTable,
  useBaseTableReportHook,
} from 'app/components/tables/BaseTableReport/hook'
import {
  IBaseColumnReport,
  IDataBaseTable,
} from 'app/components/tables/BaseTableReport/type'
import { Pagination, initialPagination } from 'parkway-web-common'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateSupplies } from '../modal/supplies/CreateSupplies'
import { DeleteSupplies } from '../modal/supplies/DeleteSupplies'
import { UpdateSupplies } from '../modal/supplies/UpdateSupplies'
import { IDataTableSupplies } from './model'
import { IItemManagement } from 'app/api/item/model/management'
import { IMeasurementUnit } from 'app/api/treatment/model'
import { useCreateUpdateServiceContext } from '../../CreateUpdateProductServiceManagement/context'

export const useTableHook = ({ record, fetchNewData }) => {
  const { t } = useTranslation()

  const id = record?._id
  const { measurementUnits } = useCreateUpdateServiceContext()

  const itemIds: IDataTableSupplies[] = useMemo(() => {
    const childrenUsingMeasurementUnitInfo: IMeasurementUnit[] =
      record?.childrenUsingMeasurementUnitInfo ?? measurementUnits ?? []

    return record?.itemIds?.map((item: IItemManagement, index) => ({
      key: index,
      _id: item._id?.toString(),
      name: item.name,
      quantity: item.quantity,
      unitOfMeasure:
        childrenUsingMeasurementUnitInfo?.find(
          usingMeasurementUnit =>
            usingMeasurementUnit._id === item.usingMeasurementUnitId,
        )?.name ?? '-',
    }))
  }, [record])

  useEffect(() => {
    const timer = setTimeout(() => {
      fetch(initialPagination, itemIds)
    }, 500)
    return () => clearTimeout(timer)
  }, [itemIds])

  const getDataSupply = async (
    pagi?: Pagination,
    items?: IDataTableSupplies[],
  ) => {
    const initData = {
      pagination: initialPagination,
      data: items ?? itemIds,
    } as IDataBaseTable<IDataTableSupplies>
    return initData
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,
    handleResetExpand,
    fetchLoading,
    fetch,
  } = useBaseTableReportHook({ getTreeTableData: getDataSupply })

  const _onFetch = () => {
    fetchNewData && fetchNewData()
  }

  type TypeColumn = IDataTableSupplies

  const columns = useMemo(() => {
    const options: IBaseColumnReport<TypeColumn> = [
      convertColumnTable<TypeColumn>({
        title: t(R.strings.item_management_content_supplies),
        align: 'start',
        className: 'left',
        classNameWidthColumnOverwrite: 'normal-column',
        key: 'name',
        render: (_, record) => record?.name,
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.quantity),
        key: 'quantity',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.quantity,
          }),
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.unit_of_measure),
        key: 'unitOfMeasure',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            valueString: record?.unitOfMeasure,
          }),
      }),
      convertColumnTable<TypeColumn>({
        title: '',
        key: 'action',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => {
          return (
            <RenderValueTable
              value={record.action}
              type="ThreeDot"
              actionComponent={
                <>
                  <CreateSupplies
                    id={id}
                    itemIds={itemIds}
                    fetchTable={_onFetch}
                  />
                  <UpdateSupplies
                    id={id}
                    itemId={record._id}
                    itemIds={itemIds}
                    fetchTable={_onFetch}
                  />
                  <DeleteSupplies
                    id={id}
                    itemId={record._id}
                    itemIds={itemIds}
                    fetchTable={_onFetch}
                    name={record?.name}
                  />
                </>
              }
            />
          )
        },
      }),
    ]

    return options
  }, [t, expandedRowKeys, itemIds])

  return {
    columns,
    tableData: tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleResetExpand,
    fetch,
    fetchLoading,
  }
}
