/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetReport11 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { IResponeGetReport11 } from 'app/api/report/model/report-11'
import { randomPercent } from 'app/components/tables/common-table/constant'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import {
  IDataBaseTable,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { initialPagination } from 'parkway-web-common'
import { isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import { getValueByTreatment, useCommonHook } from '../common-hook'
import { IDataReport11Area } from './data'

export const useTableAreaHook = (props: IParamsHookReport) => {
  const getDataArea = async (
    pagination: Pagination,
    params: IParamsHookReport,
  ) => {
    try {
      const response: IResponeGetReport11 = await requestGetReport11({
        year: props?.year,
        type: 'area',
        ...params,
      })
      if (
        isEqual(response?.msgcode, ReportMessageCode.Report11.successGetData)
      ) {
        const data = response?.data ?? []
        const dataTable: IDataReport11Area[] = data?.map((item, index) => {
          const [firstCustomer, ...otherCustomer] = item?.customers ?? []
          const treatmentData = getValueByTreatment({
            treatments: firstCustomer?.treatments,
            type: "normal"
          })
          const treatmentDataOfArea = getValueByTreatment({
            treatments: item?.treatments,
            type: "total"
          })
          return {
            key: index + 1,
            area: `${item?._id}`,
            fullName: `${firstCustomer?.name}`,
            annualExpenditureAmount: firstCustomer?.payments,
            numberOfPurchase: firstCustomer?.quatity,
            averageOrderValue: firstCustomer?.avgPayments,
            ...treatmentData,
            annualExpenditureAmountTotal: item?.payments,
            numberOfPurchaseTotal: item?.quatity,
            averageOrderValueTotal: item?.avgPayments,
            ...treatmentDataOfArea,
            children: otherCustomer?.map((itm, idx) => {
              const treatmentData = getValueByTreatment({
                treatments: itm?.treatments,
                type: "normal"
              })
              return {
                key: Number(`${index + 1}${idx + 2}`) * randomPercent(),
                fullName: `${itm?.name}`,
                annualExpenditureAmount: itm?.payments,
                numberOfPurchase: itm?.quatity,
                averageOrderValue: itm?.avgPayments,
                ...treatmentData,
                index: idx + 1,
                parentKey: index + 1,
              } as IDataReport11Area
            }),
          }
        })

        return {
          pagination: pagination ?? initialPagination,
          data: dataTable,
        } as IDataBaseTable<IDataReport11Area>
      }
      return {
        pagination: pagination ?? initialPagination,
        data: [],
      } as IDataBaseTable<IDataReport11Area>
    } catch (error) {
      return {
        pagination: pagination ?? initialPagination,
        data: [],
      } as IDataBaseTable<IDataReport11Area>
    }
  }


  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,
    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({ getTreeTableData: getDataArea })

  const { columns, exportExcel } = useCommonHook({
    expandedRowKeys,
    typeHook: 'AREA',
    handleExpand,
    currentFilter: props,
    fetchLoading
  })

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleResetExpand,
    fetch,
    fetchLoading,
    exportExcel
  }
}
