import { WalletOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { PaymentMethodEnumDefine } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/constant'
import { IAppointmentSession } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { formatMoney } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'

export const PaymentInformationLayout = ({
  item,
}: {
  item: IAppointmentSession
}) => {
  const { t } = useTranslation()
  return (
    <BaseSpace>
      <BaseRow gutter={15} align={'middle'} justify={'start'}>
        <BaseCol>
          <WalletOutlined rev={undefined} />
        </BaseCol>
        <BaseCol>
          <BaseText
            children={t(R.strings.payment_information)}
            fontSize="xs"
            fontWeight="semibold"
          />
        </BaseCol>
      </BaseRow>

      <BaseRow gutter={[15, 10]}>
        <BaseCol xl={5}>
          <BaseText children={t(R.strings.total_price)} opacity="0.5" />
        </BaseCol>
        <BaseCol xl={19}>
          <BaseText
            children={formatMoney(item?.paymentInformation?.totalAmount)}
            fontWeight="semibold"
          />
        </BaseCol>
      </BaseRow>

      <BaseRow gutter={[15, 10]}>
        <BaseCol xl={5}>
          <BaseText children={t(R.strings.paid_amount)} opacity="0.5" />
        </BaseCol>
        <BaseCol xl={19}>
          <BaseText
            children={formatMoney(item?.paymentInformation?.paidAmount)}
            fontWeight="semibold"
            colorText="statesGreenColor"
          />
        </BaseCol>
      </BaseRow>

      <BaseRow gutter={[15, 10]}>
        <BaseCol xl={5}>
          <BaseText children={t(R.strings.payment_method)} opacity="0.5" />
        </BaseCol>
        <BaseCol xl={19}>
          <BaseText
            children={
              t(PaymentMethodEnumDefine?.[
                item?.paymentInformation?.paymentMethod
              ]?.i18nKey ?? '-')
            }
            fontWeight="semibold"
          />
        </BaseCol>
      </BaseRow>
    </BaseSpace>
  )
}
