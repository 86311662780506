import R from 'app/assets/R'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { useMemo } from 'react'
import { DescriptionModal } from '../../../DescriptionModal'
import { useModalHook } from './hook'
import { IPropsDeleteItem } from './type'

export const DeleteItem = (props: IPropsDeleteItem) => {
  const { visible, isLoading, showModal, handleCancel, handleSubmit } =
    useModalHook(props)

  const titleKeyI18n = useMemo(() => {
    switch (props.type) {
      case 'service':
        return R.strings.item_management_content_delete_service
      case 'supplies':
        return R.strings.item_management_content_delete_supplies
      case 'product':
        return R.strings.item_management_content_delete_product
      default:
        return ''
    }
  }, [props.type])

  const descriptionTitleKeyI18n = useMemo(() => {
    switch (props.type) {
      case 'service':
        return R.strings.item_management_content_are_you_want_delete_service
      case 'supplies':
        return R.strings.item_management_content_are_you_want_delete_supplies
      case 'product':
        return R.strings.item_management_content_are_you_want_delete_product
      default:
        return ''
    }
  }, [props.type])

  const descriptionContentKeyI18n = useMemo(() => {
    switch (props.type) {
      case 'service':
        return R.strings
          .item_management_content_once_the_service_is_locked_it_cannot_be_recovered
      case 'supplies':
        return R.strings
          .item_management_content_once_the_supplies_is_locked_it_cannot_be_recovered
      case 'product':
        return R.strings
          .item_management_content_once_the_product_is_locked_it_cannot_be_recovered
      default:
        return ''
    }
  }, [props.type])

  return (
    <BaseModalManagement
      isLoading={isLoading}
      typeButtonOpen="delete"
      titleKeyI18n={titleKeyI18n}
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.delete}
      confirmKeyI18n={R.strings.confirm}
      handleSubmit={handleSubmit}
      visible={visible}
      widthModal={500}
      descriptionDelete={
        <DescriptionModal
          contentKey={descriptionContentKeyI18n}
          titleKey={descriptionTitleKeyI18n}
          name={props.name}
        />
      }
    />
  )
}
