import { LANGUAGE_SUPPORT, LanguageSupportType } from 'app/i18n/constant'
import { KEY_STORAGE } from '../key'
import { IUser } from 'app/model/user.model'
import { isEmpty } from 'lodash'

export const getToken = () => {
  return localStorage.getItem(KEY_STORAGE.TOKEN)
}

export const refetchToken = () => {
  return localStorage.setItem(KEY_STORAGE.TOKEN, '')
}

export const setToken = (token: string) => {
  return localStorage.setItem(KEY_STORAGE.TOKEN, token)
}

export const getUserStorage = () => {
  const item = localStorage.getItem(KEY_STORAGE.USER)
  return item && !isEmpty(item) ? (JSON.parse(item) as IUser) : null
}

export const refetchUserStorage = () => {
  return localStorage.setItem(KEY_STORAGE.USER, '')
}

export const setUserStorage = (user: string) => {
  return localStorage.setItem(KEY_STORAGE.USER, user)
}

// config
export const getCurrentLanguage = () => {
  // const currentLanguage = localStorage.getItem(KEY_STORAGE.LANGUAGE)
  // if (!currentLanguage) {
  //   return LANGUAGE_SUPPORT.vi
  // }
  // return currentLanguage
  return LANGUAGE_SUPPORT.vi
}

export const setCurrentLanguage = (language: LanguageSupportType | string) => {
  return localStorage.setItem(KEY_STORAGE.LANGUAGE, language)
}

// report
export const setReportDisplayType = (type: number) => {
  localStorage.setItem(KEY_STORAGE.DISPLAY_REPORT_LIST, type.toString())
}

export const getReportDisplayType = () => {
  return localStorage.getItem(KEY_STORAGE.DISPLAY_REPORT_LIST)
}
