import { HistoryOutlined } from '@ant-design/icons'
import { SvgCopyIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  colors,
} from 'parkway-web-common'
import styled from 'styled-components'

export const SectionBaseStyled = styled.div`
  padding-left: 0px !important;
  padding-right: 0px !important;
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
  padding-bottom: ${PADDING.md};
`

export const TitleInfo = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.semibold};
`
export const BaseSpaceWrapper = styled(BaseSpace)`
  width: 100%;
`

export const ContentWrapper = styled(BaseSpaceWrapper)`
  padding: 0px ${PADDING.xl};
`

export const ValueInfo = styled(BaseCol)<{ $color?: string }>`
  color: ${props => props.$color ?? 'var(--primary-color)'};
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.semibold};
`

export const TitleItemInfo = styled.div`
  color: #545d69; // need change
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.medium};
  opacity: 0.7;
`

export const ButtonCreate = styled(BaseButton)<{
  $backgroundColor?: string
  $color?: string
}>`
  width: 100%;
  background-color: ${props =>
    props?.$backgroundColor ?? 'var(--primary-color)'};
  color: ${props => props?.$color ?? colors.white};
  border-color: ${props => props?.$backgroundColor ?? 'var(--primary-color)'};
  &:hover {
    color: ${props => props?.$color ?? colors.white} !important;
  }
`

export const ButtonPurchase = styled(BaseButton)`
  width: 100%;
  padding: ${PADDING.xxxl} ${PADDING.md};
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  height: 100%;
`

export const ButtonCreatePrice = styled(BaseButton)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
  justify-content: center;
  align-items: center;
  background-color: ${convertedVariables.backgroundColor};
  height: 48px;
  width: 100%;
  border-color: ${convertedVariables.backgroundColor};
  color: ${convertedVariables.primaryColor} !important;
  &:hover {
    border: 1px solid ${convertedVariables.backgroundColor} !important;
    color: ${convertedVariables.primaryColor} !important;;
  }
`

export const CopyIcon = styled(SvgCopyIcon)`
  cursor: pointer;
`

export const HistoryIcon = styled(HistoryOutlined)`
  svg {
    fill: ${convertedVariables.otherBlueColor};
    opacity: 0.7;
    width: 16px;
    height: 16px;
  }
`
export const HeaderWrapper = styled(BaseRow)`
  padding: ${PADDING.xxs} ${PADDING.xl};
  border-bottom: 1px solid ${convertedVariables.neutralBlack9Color};
  height: 60px;
`

export const DotStatusDay = styled.div`
  width: 4px;
  height: 4px;
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};
`
