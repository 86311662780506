import {
    ITargetUserFile,
    IUserDataVoucher,
} from 'app/api/marketing/discount-voucher/model'
import { useState } from 'react'

export const useUserTargetHook = () => {
  const [targetUserFile, setTargetUserFile] = useState<
    ITargetUserFile | undefined
  >(undefined)

  const [lisCustomer, setListCustomer] = useState<IUserDataVoucher[]>([])

  const onChangeTargetUserFile = (file?: ITargetUserFile) => {
    setTargetUserFile(file)
  }

  const onChangeListCustomer = (users: IUserDataVoucher[]) => {
    setListCustomer(users)
  }

  return {
    targetUserFile,
    onChangeTargetUserFile,
    onChangeListCustomer,
    lisCustomer,
  }
}
