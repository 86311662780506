import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IProps } from './type'

export const ModalShowSenderJobTitle = ({
  listSenderJobTitle,
  numberSenderJobTitle,
}: IProps) => {
  const { t } = useTranslation()
  const modalRef = useRef<IRefModal>({})

  return (
    <>
      <ModalComponent
        ref={modalRef}
        renderContent={
          <BaseSpace>
            <HeaderListSenderWrapper>
              <BaseText
                children={t(R.strings.profile_content_job_title)}
                fontWeight="semibold"
                opacity="0.7"
                fontSize="xs"
              />
            </HeaderListSenderWrapper>

            <ListSenderWrapper>
              {listSenderJobTitle?.map((item, index) => {
                return (
                  <ItemSenderWrapper key={index}>
                    <BaseText
                      children={item}
                      fontWeight="semibold"
                      fontSize="xs"
                    />
                  </ItemSenderWrapper>
                )
              })}
            </ListSenderWrapper>
          </BaseSpace>
        }
        buttonOpenCustom={
          <SenderWrapper
            $isButton
            onClick={() => {
              modalRef?.current?.open?.()
            }}
          >
            <BaseText
              children={`+${numberSenderJobTitle}`}
              fontWeight="semibold"
              colorText="collapseBackgroundColor"
            />
          </SenderWrapper>
        }
        titleModal={t(R.strings.sender_job_title)}
        isShowCancel={false}
        handleSubmit={() => modalRef?.current?.hide?.()}
      />
    </>
  )
}

const SenderWrapper = styled.div<{ $isButton?: boolean }>`
  padding: 6px ${PADDING.xxxs};
  border-radius: ${PADDING.xl};
  background-color: #f8f8f8;

  ${({ $isButton }) =>
    $isButton &&
    `
        cursor: pointer;
    `}
`

const ListSenderWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
  background-color: ${convertedVariables.neutralBlack9Color};
  border-radius: ${BORDER_RADIUS};
`

const ItemSenderWrapper = styled.div`
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};
`

const HeaderListSenderWrapper = styled(BaseRow)`
  padding-left: 32px;
`
