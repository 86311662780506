import { useMemo } from 'react'
import { IProfilePatient } from '../type'
import {
  IAppointmentSession,
  IDoctorTreatmentSchedule,
  IInfoPatientTreatmentSchedule,
  ITreatmentPhaseTreatmentSchedule,
} from '../type/treatment-schedule'
import { isEqual } from 'lodash'

export const useTreatmentScheduleHook = ({
  profilePatient,
  onChangeInfoTreatment,
}: {
  profilePatient: IProfilePatient
  onChangeInfoTreatment?: (
    infoTreatmentSchedule: IInfoPatientTreatmentSchedule,
  ) => void
}) => {
  const treatmentSchedules = useMemo(() => {
    return profilePatient?.infoTreatmentSchedule?.treatmentSchedule ?? []
  }, [profilePatient?.infoTreatmentSchedule?.treatmentSchedule])

  const onAddTreatmentPhaseInTreatmentSchedule = ({
    id,
    treatmentPhase,
  }: {
    id: string
    treatmentPhase?: ITreatmentPhaseTreatmentSchedule
  }) => {
    if (!treatmentPhase) return

    const treatmentSchedule = treatmentSchedules?.map(item => {
      if (isEqual(id, item?.id)) {
        return {
          ...item,
          treatmentPhase: item?.treatmentPhase?.concat([treatmentPhase]),
        }
      }
      return item
    })

    onChangeInfoTreatment?.({
      ...profilePatient?.infoTreatmentSchedule,
      treatmentSchedule,
    })
  }

  const onUpdateTreatmentPhaseInTreatmentSchedule = ({
    idSchedule,
    treatmentPhase,
  }: {
    idSchedule: string
    treatmentPhase?: ITreatmentPhaseTreatmentSchedule
  }) => {
    if (!treatmentPhase) return

    const treatmentSchedule = treatmentSchedules?.map(item => {
      if (isEqual(idSchedule, item?.id)) {
        return {
          ...item,
          treatmentPhase: item?.treatmentPhase?.map((itm) => {
            if (isEqual(treatmentPhase?.id, itm?.id)) {
              return treatmentPhase
            }
            return itm
          }),
        }
      }
      return item
    })

    onChangeInfoTreatment?.({
      ...profilePatient?.infoTreatmentSchedule,
      treatmentSchedule,
    })
  }

  const onDeleteTreatmentPhaseInTreatmentSchedule = ({
    id,
  }: {
    id: string
  }) => {
    const treatmentSchedule = treatmentSchedules?.filter(
      item => !isEqual(item?.id, id),
    )

    onChangeInfoTreatment?.({
      ...profilePatient?.infoTreatmentSchedule,
      treatmentSchedule,
    })
  }

  const onChangePhaseAttendingPhysician = ({
    idSchedule,
    phaseAttendingPhysician,
    idTreatmentPhase,
  }: {
    idSchedule: string
    idTreatmentPhase: string
    phaseAttendingPhysician?: IDoctorTreatmentSchedule
  }) => {
    const treatmentSchedule = treatmentSchedules?.map(item => {
      if (isEqual(idSchedule, item?.id)) {
        return {
          ...item,
          treatmentPhase: item?.treatmentPhase.map(itm => {
            if (isEqual(itm?.id, idTreatmentPhase)) {
              return {
                ...itm,
                phaseAttendingPhysician,
              }
            }
            return itm
          }),
        }
      }
      return item
    })

    onChangeInfoTreatment?.({
      ...profilePatient?.infoTreatmentSchedule,
      treatmentSchedule,
    })
  }

  const onChangePhaseApprover = ({
    idSchedule,
    phaseApprover,
    idTreatmentPhase,
  }: {
    idSchedule: string
    idTreatmentPhase: string
    phaseApprover?: IDoctorTreatmentSchedule
  }) => {
    const treatmentSchedule = treatmentSchedules?.map(item => {
      if (isEqual(idSchedule, item?.id)) {
        return {
          ...item,
          treatmentPhase: item?.treatmentPhase.map(itm => {
            if (isEqual(itm?.id, idTreatmentPhase)) {
              return {
                ...itm,
                phaseApprover,
              }
            }
            return itm
          }),
        }
      }
      return item
    })

    onChangeInfoTreatment?.({
      ...profilePatient?.infoTreatmentSchedule,
      treatmentSchedule,
    })
  }

  const onAddAppointmentSession = ({
    idSchedule,
    appointmentSession,
    idTreatmentPhase,
  }: {
    idSchedule: string
    idTreatmentPhase: string
    appointmentSession?: IAppointmentSession
  }) => {
    const treatmentSchedule = treatmentSchedules?.map(item => {
      if (isEqual(idSchedule, item?.id)) {
        return {
          ...item,
          treatmentPhase: item?.treatmentPhase.map(itm => {
            if (isEqual(itm?.id, idTreatmentPhase) && appointmentSession) {
              return {
                ...itm,
                appointmentSession: itm?.appointmentSession?.concat([
                  appointmentSession,
                ]),
              }
            }
            return itm
          }),
        }
      }
      return item
    })

    onChangeInfoTreatment?.({
      ...profilePatient?.infoTreatmentSchedule,
      treatmentSchedule,
    })
  }

  const onChangeAppointmentSession = ({
    idSchedule,
    appointmentSession,
    idTreatmentPhase,
  }: {
    idSchedule: string
    idTreatmentPhase: string
    appointmentSession?: IAppointmentSession[]
  }) => {
    const treatmentSchedule = treatmentSchedules?.map(item => {
      if (isEqual(idSchedule, item?.id)) {
        return {
          ...item,
          treatmentPhase: item?.treatmentPhase.map(itm => {
            if (isEqual(itm?.id, idTreatmentPhase) && appointmentSession) {
              return {
                ...itm,
                appointmentSession
              }
            }
            return itm
          }),
        }
      }
      return item
    })

    onChangeInfoTreatment?.({
      ...profilePatient?.infoTreatmentSchedule,
      treatmentSchedule,
    })
  }

  return {
    treatmentSchedules,
    onAddTreatmentPhaseInTreatmentSchedule,
    onDeleteTreatmentPhaseInTreatmentSchedule,
    onChangePhaseApprover,
    onChangePhaseAttendingPhysician,
    onAddAppointmentSession,
    onChangeAppointmentSession,
    onUpdateTreatmentPhaseInTreatmentSchedule
  }
}
