import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { OrganizationalChartContainer } from 'app/containers/Company'

const OrganizationalChartPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.customer_care_plan)}</PageTitle>
      <OrganizationalChartContainer />
    </>
  )
}
const OrganizationalChartPage = memo(OrganizationalChartPageCpn, isEqual)

export default OrganizationalChartPage
