import { withLoading } from 'app/hocs/with-loading.hoc'
import AccountingPage from 'app/page/menu/accounting'
import AccountingItemManagementPage from 'app/page/menu/accounting/accounting-item-management'
import AccountingItemManagementAddProductServicePage, {
  ActionProductServiceEnum,
} from 'app/page/menu/accounting/accounting-item-management-add-product-service'
import AccountingSoldSellingExpensesPage from 'app/page/menu/accounting/accounting-cost-plan'
import AccountingDebtContract from 'app/page/menu/accounting/accounting-debt-contract'
import { Route } from 'react-router-dom'
import {
  ACCOUNTING_ITEM_MANAGEMENT_PATH,
  ACCOUNTING_ITEM_MANAGEMENT_ADD_PRODUCT_SERVICE_PATH,
  ACCOUNTING_PATH,
  ACCOUNTING_SOLD_SELLING_EXPENSES,
  MANAGE_CUSTOMER_DEBT_PATH,
  ACCOUNTING_BRACES_TRAY,
  ACCOUNTING_ITEM_MANAGEMENT_UPDATE_PRODUCT_SERVICE_PATH,
  ACCOUNTING_TREATMENT_MANAGEMENT_PATH,
  ACCOUNTING_TREATMENT_MANAGEMENT_ADD_PRODUCT_SERVICE_PATH,
  ACCOUNTING_TREATMENT_MANAGEMENT_UPDATE_PRODUCT_SERVICE_PATH,
  ACCOUNTING_DAILY_INVOICE_PATH,
  ACCOUNTING_SCHEDULE_WITHOUT_PAID,
  MANAGE_CUSTOMER_DEBT_CONTRACT_PATH,
  ACCOUNTING_ORTHODONTIC_WITHOUT_PAID_PATH,
  ACCOUNTING_PATIENT_OUTDATED,
} from './route-path'
import AccountingBracesTray from 'app/page/menu/accounting/accounting-braces-tray'
import AccountingTreatmentManagementPage from 'app/page/menu/accounting/accounting-treatment-management'
import AccountingTreatmentManagementAddProductServicePage from 'app/page/menu/accounting/accounting-treatment-management-add-product-service'
import AccountingTreatmentManagementUpdateProductServicePage from 'app/page/menu/accounting/accounting-treatment-management-update-product-service'
import AccountingDailyInvoice from 'app/page/menu/accounting/accounting-daily-invoice'
import AccountingPatientScheduleWithoutPaid from 'app/page/menu/accounting/accounting-patient-schedule-without-paid'
import AccountingDebt from 'app/page/menu/accounting/accounting-debt'
import AccountingOrthodonticWithoutPaid from 'app/page/menu/accounting/accounting-orthodontic-without-paid'
import AccountingPatientOutdated from 'app/page/menu/accounting/accounting-patient-outdated'

const Accounting = withLoading(AccountingPage)
const AccountingSoldSellingExpenses = withLoading(
  AccountingSoldSellingExpensesPage,
)
const AccountingDebtContractElement = withLoading(AccountingDebtContract)
const AccountingDebtElement = withLoading(AccountingDebt)
const AccountingPatientScheduleWithoutPaidElement = withLoading(
  AccountingPatientScheduleWithoutPaid,
)

const AccountingOrthodonticWithoutPaidElement = withLoading(
  AccountingOrthodonticWithoutPaid,
)

const AccountingDailyInvoiceElement = withLoading(AccountingDailyInvoice)

const AccountingBracesTrayElement = withLoading(AccountingBracesTray)
const AccountingPatientOutdatedElement = withLoading(AccountingPatientOutdated)

const AccountingItemManagement = withLoading(AccountingItemManagementPage)
const AccountingItemManagementAddProductService = withLoading(
  AccountingItemManagementAddProductServicePage,
)

const AccountingItemManagementUpdateProductService = withLoading(
  AccountingItemManagementAddProductServicePage,
)

const AccountingTreatmentManagement = withLoading(
  AccountingTreatmentManagementPage,
)

const AccountingTreatmentManagementAddProductService = withLoading(
  AccountingTreatmentManagementAddProductServicePage,
)

const AccountingTreatmentManagementUpdateProductService = withLoading(
  AccountingTreatmentManagementUpdateProductServicePage,
)

export const AccountingRouter = () => {
  return (
    <>
      <Route path={ACCOUNTING_PATH} element={<Accounting />} />
      <Route
        path={ACCOUNTING_SOLD_SELLING_EXPENSES}
        element={<AccountingSoldSellingExpenses />}
      />
      <Route
        path={MANAGE_CUSTOMER_DEBT_CONTRACT_PATH}
        element={<AccountingDebtContractElement />}
      />

      <Route
        path={MANAGE_CUSTOMER_DEBT_PATH}
        element={<AccountingDebtElement />}
      />

      <Route
        path={ACCOUNTING_DAILY_INVOICE_PATH}
        element={<AccountingDailyInvoiceElement />}
      />

      <Route
        path={ACCOUNTING_ITEM_MANAGEMENT_PATH}
        element={<AccountingItemManagement />}
      />
      <Route
        path={ACCOUNTING_ITEM_MANAGEMENT_ADD_PRODUCT_SERVICE_PATH}
        element={<AccountingItemManagementAddProductService />}
      />
      <Route
        path={ACCOUNTING_TREATMENT_MANAGEMENT_PATH}
        element={<AccountingTreatmentManagement />}
      />
      <Route
        path={ACCOUNTING_TREATMENT_MANAGEMENT_ADD_PRODUCT_SERVICE_PATH}
        element={<AccountingTreatmentManagementAddProductService />}
      />
      <Route
        path={ACCOUNTING_TREATMENT_MANAGEMENT_UPDATE_PRODUCT_SERVICE_PATH}
        element={<AccountingTreatmentManagementUpdateProductService />}
      />
      <Route
        path={ACCOUNTING_ITEM_MANAGEMENT_UPDATE_PRODUCT_SERVICE_PATH}
        element={
          <AccountingItemManagementUpdateProductService
            type={ActionProductServiceEnum.update}
          />
        }
      />

      <Route
        path={ACCOUNTING_BRACES_TRAY}
        element={<AccountingBracesTrayElement />}
      />

      <Route
        path={ACCOUNTING_PATIENT_OUTDATED}
        element={<AccountingPatientOutdatedElement />}
      />

      <Route
        path={ACCOUNTING_SCHEDULE_WITHOUT_PAID}
        element={<AccountingPatientScheduleWithoutPaidElement />}
      />

      <Route
        path={ACCOUNTING_ORTHODONTIC_WITHOUT_PAID_PATH}
        element={<AccountingOrthodonticWithoutPaidElement />}
      />
    </>
  )
}
