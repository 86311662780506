import R from 'app/assets/R'
import {
  IResponseFilter,
  initResponseFilterTableManagement,
} from 'app/components/tables/BaseTableManagement/FilterManagementTable/type'
import {
  RenderValueTable,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { isEmpty, isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IGeneralMaterialCategoryManagementTable } from './type'

import {
  requestExportMaterialCategoryManagement,
  requestGetMaterialCategoryManagement,
} from 'app/api/inventory'
import { MessageCode } from 'app/api/inventory/constant'
import {
  IParamsGetMaterialCategoryManagement,
  IResponseGetMaterialCategoryManagement,
} from 'app/api/inventory/model/management'
import { IMAGE_ACTION } from 'app/common/config'
import { convertArrayStatusFilterToArrayStatusNumber } from 'app/common/helpers'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { BaseCol } from 'app/components/common/BaseCol'
import { ActionsDropdown } from 'app/components/tables/BaseTableManagement/ActionDropdown'
import { ValueTableWrapper } from 'app/components/tables/BaseTableManagement/styles'
import { initPagination } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { Delete } from './components/Delete'
import { Update } from './components/Update'

export const useHookTable = () => {
  const { t } = useTranslation()

  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.INVENTORY,
      FunctionPermissionEnum.MATERIAL_CATEGORY_READ,
      R.strings.role,
    )
  }, [])

  const onClickExport = async params => {
    try {
      const response = await requestExportMaterialCategoryManagement({
        keyword: params?.search ?? filter?.search,
      })
      const url = `${IMAGE_ACTION.IMAGE_SERVER}/${response?.data?.Url}`
      window.open(url, '_blank', 'noopener,noreferrer')
    } catch (error) {
      // console.log({ error })
      notificationController.error({
        message: t(R.strings.error),
      })
    }
  }
  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IResponseFilter,
  ) => {
    const initData = {
      pagination: pagination ?? initPagination,
      data: [],
    } as IDataBaseTable<IGeneralMaterialCategoryManagementTable>
    try {
      const status = convertArrayStatusFilterToArrayStatusNumber(
        newFilter?.status ?? filter?.status,
      )
      let params: IParamsGetMaterialCategoryManagement = {
        page: pagination?.current,
        pagesize: pagination?.pageSize,
        keyword: newFilter?.search ?? filter?.search,
      }

      params = { ...params, ...newFilter, status }

      const response: IResponseGetMaterialCategoryManagement =
        await requestGetMaterialCategoryManagement(params)

      if (isEqual(response?.msgcode, MessageCode.successGetData)) {
        const data = response?.data
        const metadata = response?.metadata

        const startIndex =
          ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

        const transformData = data => {
          if (isEmpty(data)) {
            return data
          }

          return data?.map((item, index) => {
            return {
              key: `${item?._id}-${index + 1}`,
              ordinalNumber: startIndex + index + 1,
              ...item,
              providerContact: item?.provider?.contact,
            }
          })
        }

        const dataTables = transformData(data)

        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pageSize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IGeneralMaterialCategoryManagementTable>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, selectRowsTable, fetch, rowSelection } =
    useHookTableManagement({ getDataTable })

  const handleTableChange = pagination => {
    fetch(pagination, filter)
  }

  const handleChangeFilter = (newFilter: IResponseFilter) => {
    if (!isEqual(newFilter, filter)) {
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initPagination.current,
        },
        newFilter,
      )
    }
  }

  const fetchNewData = () => {
    fetch(initPagination)
    setFilter(initResponseFilterTableManagement)
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralMaterialCategoryManagementTable>({
        title: t(R.strings.ordinal_number),
        key: 'ordinalNumber',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => (
          <RenderValueTable value={record.ordinalNumber} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralMaterialCategoryManagementTable>({
        title: t(R.strings.code),
        key: 'id',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => (
          <RenderValueTable value={record?.id} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralMaterialCategoryManagementTable>({
        title: t(R.strings.name),
        key: 'name',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable value={record?.name} type="Main" />
        ),
      }),

      convertColumnTable<IGeneralMaterialCategoryManagementTable>({
        title: t(R.strings.unit_import),
        key: 'unit',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.unit} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralMaterialCategoryManagementTable>({
        title: t(R.strings.unit_use),
        key: 'unitUse',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.unitUse} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralMaterialCategoryManagementTable>({
        title: t(R.strings.material_purpose),
        key: 'purpose',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.purpose} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralMaterialCategoryManagementTable>({
        title: t(R.strings.detail),
        key: 'details',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => (
          <RenderValueTable value={record?.details} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralMaterialCategoryManagementTable>({
        title: t(R.strings.material_group),
        key: 'type',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => (
          <RenderValueTable value={record?.type} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralMaterialCategoryManagementTable>({
        title: t(R.strings.provider_contact),
        key: 'providerContact',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => (
          <RenderValueTable value={record?.providerContact} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralMaterialCategoryManagementTable>({
        title: t(R.strings.TIN),
        key: 'TIN',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => (
          <RenderValueTable value={record?.TIN} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralMaterialCategoryManagementTable>({
        title: t(R.strings.material_vat),
        key: 'vat',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => (
          <RenderValueTable value={record?.vat} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralMaterialCategoryManagementTable>({
        title: '',
        key: 'action',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <ValueTableWrapper
            justify={'end'}
            align={'middle'}
            gutter={[10, 10]}
            wrap={false}
          >
            <BaseCol>
              <ActionsDropdown
                actionComponent={
                  <>
                    <Update materialCategory={record} fetchTable={fetchNewData} />
                    <Delete
                      id={record?._id}
                      fetchTable={fetchNewData}
                      name={record?.name}
                    />
                  </>
                }
              />
            </BaseCol>
          </ValueTableWrapper>
        ),
      }),
    ]
    return option
  }, [t, fetchNewData])

  return {
    tableData,
    selectRowsTable,
    handleTableChange,
    fetchNewData,
    rowSelection,
    columns,
    handleChangeFilter,
    filter,
    onClickExport,
  }
}
