import { SearchOutlined } from '@ant-design/icons'
import { requestGetUserDataByVoucherId } from 'app/api/marketing/discount-voucher'
import {
  DefineGender,
  DefineUserDataByVoucherEnum,
  DiscountVoucherMessageCode,
} from 'app/api/marketing/discount-voucher/constant'
import { IUserDataVoucher } from 'app/api/marketing/discount-voucher/model'
import R from 'app/assets/R'
import { getDetailPatientUrl } from 'app/common/helpers'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { _DEV_ } from 'app/constant'
import { isEqual } from 'lodash'
import {
  FONT_SIZE,
  FORMAT_ONLY_YEAR,
  formatMoney,
  initialPagination,
  moment,
  ResponseType,
} from 'parkway-web-common'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const ModalUserDisplayLayout = ({
  fileName,
  id,
}: {
  users?: IUserDataVoucher[]
  fileName: string
  id?: string
}) => {
  const { t } = useTranslation()
  const modalRef = useRef<IRefModal>({})
  const [usersSelected, setServicesSelected] = useState<IUserDataVoucher[]>([])
  const [loading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState(initialPagination)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')

  const data = useMemo(() => {
    return usersSelected?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
        voucherCode: item?.discountVoucher?.code,
        usingTime: item?.discountVoucher?.used,
        totalUsingTime: item?.discountVoucher?.maxUsage,
      }
    })
  }, [usersSelected])

  const handleOpenModal = () => {
    modalRef.current?.open?.()
  }

  const getData = async () => {
    if (!id) return
    try {
      setIsLoading(true)

      const res: ResponseType<IUserDataVoucher[]> =
        await requestGetUserDataByVoucherId({
          id,
          keyword: search,
          page: pagination.current,
          pageSize: pagination.pageSize,
          pagesize: pagination.pageSize,
        })

      if (
        isEqual(
          res?.msgcode,
          DiscountVoucherMessageCode.DiscountVoucher.success,
        )
      ) {
        setServicesSelected(res?.data)
        setTotal(res?.metadata?.totalItem ?? 0)
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [search, pagination])

  const columns = useMemo(() => {
    return [
      convertColumnTable<{ key?: number; _id?: string }>({
        title: '#',
        key: 'key',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.key}
              fontWeight="medium"
              opacity="0.7"
              colorText={record?._id ? 'primaryColor' : 'statesRedColor'}
            />
          )
        },
      }),
      convertColumnTable<IUserDataVoucher>({
        title: t(R.strings.patient_code),
        key: 'id',
        render: (_, record) => {
          return (
            <RenderValueTable
              type="OtherLink"
              value={record?.id}
              hrefOtherLink={getDetailPatientUrl(record?._id)}
              styleText={{
                fontSize: FONT_SIZE.xxs,
              }}
            />
          )
        },
      }),
      convertColumnTable<IUserDataVoucher>({
        title: t(R.strings.name),
        key: 'name',
        render: (_, record) => {
          return <BaseText children={record?.name} fontWeight="medium" />
        },
      }),
      convertColumnTable<IUserDataVoucher>({
        title: t(R.strings.gender),
        key: 'gender',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.gender ? t(DefineGender[record?.gender]?.keyI18n) : '-'
              }
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IUserDataVoucher>({
        title: t(R.strings.year_of_birth),
        key: 'dob',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.dob ? moment(record?.dob).format(FORMAT_ONLY_YEAR) : '-'
              }
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IUserDataVoucher>({
        title: t(R.strings.voucher_code),
        key: 'voucherCode',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.voucherCode ?? '-'}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IUserDataVoucher>({
        title: t(R.strings.number_of_use),
        key: 'usingTime',
        render: (_, record) => {
          return (
            <BaseText
              children={`${formatMoney(
                record?.usingTime ?? '0',
                '',
              )}/${formatMoney(record?.totalUsingTime ?? '0', '')}`}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IUserDataVoucher>({
        title: t(R.strings.status),
        key: 'status',
        render: (_, record) => {
          if (record?.discountVoucher?.statusUsed === undefined) return
          const define =
            DefineUserDataByVoucherEnum[record?.discountVoucher?.statusUsed]
          return (
            <BaseText
              children={t(define?.keyI18n)}
              fontWeight="medium"
              opacity="0.7"
              colorText={define?.textColor}
            />
          )
        },
      }),
    ]
  }, [t, usersSelected])

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const handleConfirm = () => {
    modalRef?.current?.hide?.()
  }

  return (
    <ModalComponent
      buttonOpenCustom={
        <BaseButton onClick={handleOpenModal} type="link">
          {fileName}
        </BaseButton>
      }
      titleModal={R.strings.customer_list}
      ref={modalRef}
      widthModal={1000}
      isShowCancel={false}
      handleSubmit={handleConfirm}
      renderContent={
        <RootWrapper>
          <BaseRow gutter={[16, 8]} align={'middle'}>
            <BaseCol xl={12}>
              <BaseFormItem hiddenLabel>
                <BaseInput
                  prefix={<SearchOutlined rev={undefined} />}
                  placeholder={t(R.strings.enter_service_name)}
                  value={search}
                  onChange={onChangeSearch}
                />
              </BaseFormItem>
            </BaseCol>
          </BaseRow>

          <BaseTableManagement
            columns={columns}
            dataSource={data}
            pagination={{
              ...pagination,
              total,
            }}
            rowClassName="row-overwrite-style"
            scroll={{ x: true }}
            onChange={setPagination}
            loading={loading}
          />
        </RootWrapper>
      }
    />
  )
}

const RootWrapper = styled(BaseSpace)`
  .ant-table-wrapper .ant-table .ant-table-header {
    border-radius: 0px !important;
  }

  .ant-table-thead > tr > th {
    border: 0px !important;
  }

  .row-overwrite-style .ant-table-cell {
    border-left: 0px !important;
    border-right: 0px !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0px;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0px !important;
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
