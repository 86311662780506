import { Select } from 'antd'
import {
  SelectSearch,
  UserAvatar,
  UserInfo,
  UserItem,
  UserLabel,
  UserList,
  UserName,
} from './styles'
import { BaseAvatar } from '../BaseAvatar'
import { ReactNode } from 'react'
export const { Option } = Select
interface IOption {
  avatar?: string
  name?: string
  description?: string
  value?: string
}
interface ISectionBaseProp{
  options?: IOption[]
  onChange?
  defaultValue?
  placeholder?: string
  onSearch?
  mode?
  filterOption?: boolean
  showSearch?: boolean
  suffixIcon?: ReactNode
  className?: string
  allowClear?: boolean
  disabled?: boolean
  labelInValue?: boolean
  labelRender?: (label: ReactNode) => ReactNode,
  optionLabelProp?: string
}

export const BaseSelectSearch: React.FC<ISectionBaseProp> = ({
  onChange,
  options,
  placeholder,
  onSearch,
  filterOption = false,
  showSearch = true,
  suffixIcon = null,
  className,
  allowClear = false,
  disabled = false,
  optionLabelProp = "label",
  ...props
}) => {
  return (
    <SelectSearch
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      onSearch={onSearch}
      optionLabelProp={optionLabelProp}
      defaultActiveFirstOption={false}
      suffixIcon={suffixIcon}
      filterOption={filterOption}
      notFoundContent={null}
      showSearch={showSearch}
      className={className}
      allowClear={allowClear}
      {...props}
    >
      {options?.map((option, index) => (
        <Option value={option.value} label={option?.name} key={index}>
          <UserList>
            <UserItem>
              <UserAvatar>
                <BaseAvatar src={option?.avatar} alt={option?.name} />
              </UserAvatar>
              <UserInfo>
                <UserName>{option?.name}</UserName>
                <UserLabel>{option?.description}</UserLabel>
              </UserInfo>
            </UserItem>
          </UserList>
        </Option>
      ))}
    </SelectSearch>
  )
}
