/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableProps } from 'antd'
import * as S from './styles'
export type BaseTableProps<T> = TableProps<T>

export const BaseTableReport: React.FC<BaseTableProps<any>> = props => {
  return (
    <S.BaseTableWrapper
      scroll={{
        x: true,
        y:
          props.dataSource?.length && props.dataSource?.length > 5
            ? 480
            : undefined,
      }}
      {...props}
    />
  )
}
