/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd'
import { KeyImport } from 'app/api/import/constant'
import {
  CampaignTypeEnum,
  IDiscountCampaign,
} from 'app/api/marketing/discount-campaign/model'
import { requestRequestImportUserData } from 'app/api/marketing/discount-voucher'
import { DiscountVoucherMessageCode } from 'app/api/marketing/discount-voucher/constant'
import {
  EVerifyRuleKey,
  MethodIssueEnum,
} from 'app/api/marketing/discount-voucher/enum'
import {
  IDiscountVoucher,
  IPayloadUpdateWithInsertOnlyInVoucher,
  ITargetUserFile,
  IUserDataVoucher,
} from 'app/api/marketing/discount-voucher/model'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { IRefModal } from 'app/components/common/ModalComponent'
import { _DEV_ } from 'app/constant'
import {
  useUpdateDiscountVoucherWithInsertOnly
} from 'app/react-query/hook/voucher'
import dayjs from 'dayjs'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import {
  createContext,
  MutableRefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router'
import { IFormData, ITreatmentInTable } from './type'

interface IAdditionalServiceVoucherContext {
  form?: FormInstance<IFormData>
  listService?: ITreatmentInTable[]

  handleSubmit?: (values: IFormData) => void
  handleGoBack?: () => void
  onChangeService?: (service: ITreatmentInTable[]) => void
  onDeleteService?: (id?: string) => void
  onChangeMethodIssueVoucher?: (method: MethodIssueEnum) => void
  methodIssueVoucher?: MethodIssueEnum
  isLoading?: boolean
  campaignType?: CampaignTypeEnum
  itemCampaign?: IDiscountCampaign
  openImportArea?: boolean
  onCloseModal?: () => void
  onOpenModal?: () => void
  file?: File | null
  onRemoveFile?: () => void
  onConfirmModal?: (file?: File) => void
  onChangeFile?: (values: { file: any }) => void
  listUserData?: IUserDataVoucher[]
  listUserDataError?: string[]
  modalDisplayUserRef?: MutableRefObject<IRefModal>
  onChangeRuleKey?: (key: EVerifyRuleKey) => void
  verifyRuleKey?: EVerifyRuleKey

  isEditPrefix?: boolean
  setIsEditPrefix?: (value: boolean) => void
  prefixLength?: number
  setPrefixLength?: (value: number) => void
  prefix?: string
  setPrefix?: (value: string) => void
  targetUserFile?: ITargetUserFile
  lisCustomer?: IUserDataVoucher[]
  itemVoucher?: IDiscountVoucher
}
export const AdditionalServiceVoucherContext = createContext<IAdditionalServiceVoucherContext>({})

export const AdditionalServiceVoucherProvider = ({ children }) => {
  const navigate = useNavigate()
  const locationData = useLocation()
  const [form] = BaseForm.useForm<IFormData>()
  const [listService, setListService] = useState<ITreatmentInTable[]>([])
  const [methodIssueVoucher, setMethodIssueVoucher] = useState<
    MethodIssueEnum | undefined
  >(MethodIssueEnum.Auto)
  const [isEditPrefix, setIsEditPrefix] = useState(false)
  const [prefixLength, setPrefixLength] = useState(6)
  const [prefix, setPrefix] = useState('')
  const [targetUserFile, setTargetUserFile] = useState<
    ITargetUserFile | undefined
  >(undefined)

  const [lisCustomer, setListCustomer] = useState<IUserDataVoucher[]>([])

  const [listUserData, setListUserData] = useState<IUserDataVoucher[]>([])
  const [listUserDataError, setListUserDataError] = useState<string[]>([])
  const [openImportArea, setOpenImportArea] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const modalDisplayUserRef = useRef<IRefModal>({})
  const [verifyRuleKey, setVerifyRuleKey] = useState<
    EVerifyRuleKey | undefined
  >(undefined)

  const itemVoucher: IDiscountVoucher = useMemo(() => {
    return locationData?.state?.itemVoucher
  }, [locationData])

  useEffect(() => {
    if (itemVoucher) {
      form.setFieldsValue({
        startDate: dayjs(itemVoucher?.startDate),
        endDate: itemVoucher?.endDate ? dayjs(itemVoucher?.endDate) : undefined,
        code: itemVoucher?.code,
        prefix: itemVoucher?.prefixCode,
        discountType: itemVoucher?.discountType,
        totalVoucher: itemVoucher?.quantity,
        numberOfUses: itemVoucher?.maxUsage,
        discountValue: itemVoucher?.amount,
        targetUser: itemVoucher?.targetUser,
        methodIssue: itemVoucher?.methodIssue,
        prefixLength: itemVoucher?.maxLengthCode,
        isCustomPrefix: !!itemVoucher?.prefixCode?.length,
        name: itemVoucher?.name ?? '',
        verifyRuleKey: itemVoucher?.verifyRuleKey,
      })

      setMethodIssueVoucher(itemVoucher?.methodIssue)
      setListService(
        itemVoucher?.treatmentInfos?.map(item => {
          return {
            ...item,
            isInit: true,
          }
        }) ?? [],
      )
      setIsEditPrefix(!!itemVoucher?.prefixCode?.length)
      setPrefixLength(itemVoucher?.maxLengthCode ?? 6)
      setPrefix(itemVoucher?.prefixCode ?? '')
      setTargetUserFile(itemVoucher?.targetUserFile)
      setListCustomer(itemVoucher?.targetUserInfos ?? [])
      setVerifyRuleKey(itemVoucher?.verifyRuleKey)
    }
  }, [itemVoucher])

  const onCloseModal = () => {
    setOpenImportArea(false)
  }

  const onOpenModal = () => {
    setOpenImportArea(true)
  }

  const onRemoveFile = () => {
    setFile(null)
    setListUserData([])
    setListUserDataError([])
  }

  const onConfirmModal = async (file?: File) => {
    if (!file) return
    try {
      const formData = new FormData()
      formData.append(KeyImport.All.file, file)

      const res: ResponseType<{
        userData: IUserDataVoucher[]
        notFoundIds: string[]
      }> = await requestRequestImportUserData(formData)

      if (
        isEqual(
          res?.msgcode,
          DiscountVoucherMessageCode.DiscountVoucher.success,
        )
      ) {
        const userData = res?.data?.userData ?? []

        setListUserData(userData)
        setListUserDataError(res?.data?.notFoundIds ?? [])
        form.validateFields(['totalVoucher'])
        setFile(file)
        form.setFieldsValue({
          file,
        })
        modalDisplayUserRef?.current?.open?.()
        onCloseModal()
      }
    } catch (error) {
      _DEV_ && console.error(error)
    }
  }

  const itemCampaign: IDiscountCampaign = useMemo(() => {
    return locationData?.state?.itemCampaign
  }, [locationData])

  const onChangeService = (service: ITreatmentInTable[]) => {
    setListService(service)
  }

  const onDeleteService = (id?: string) => {
    const newListService = listService.filter(item => !isEqual(item?._id, id))
    setListService(newListService)
  }

  const onChangeMethodIssueVoucher = (method: MethodIssueEnum) => {
    setMethodIssueVoucher(method)
  }

  const handleGoBack = () => {
    navigate?.(-1)
  }

  const { mutateAsync: mutateAsyncUpdate, isLoading } =
    useUpdateDiscountVoucherWithInsertOnly()

  const handleSubmit = async () => {
    if (!itemCampaign || !itemVoucher) return

    const payload: IPayloadUpdateWithInsertOnlyInVoucher = {
      id: itemVoucher?._id,
      treatments: isEqual(itemCampaign?.campaignType, CampaignTypeEnum.Voucher)
        ? listService
            ?.filter(item => !item?.isInit)
            ?.map(item => item?._id ?? '') ?? []
        : undefined,
    }

    await mutateAsyncUpdate(payload)
  }

  const onChangeRuleKey = (key: EVerifyRuleKey) => {
    setVerifyRuleKey(key)
  }

  return (
    <AdditionalServiceVoucherContext.Provider
      value={{
        form,
        handleSubmit,
        handleGoBack,
        onChangeService,
        onDeleteService,
        listService,
        methodIssueVoucher,
        onChangeMethodIssueVoucher,
        isLoading,
        campaignType: itemCampaign?.campaignType,
        itemCampaign,
        openImportArea,
        onCloseModal,
        onOpenModal,
        file,
        onRemoveFile,
        onConfirmModal,
        listUserData,
        listUserDataError,
        modalDisplayUserRef,
        onChangeRuleKey,
        verifyRuleKey,
        isEditPrefix,
        prefixLength,
        prefix,
        targetUserFile,
        lisCustomer,
        itemVoucher
      }}
    >
      {children}
    </AdditionalServiceVoucherContext.Provider>
  )
}

export function useAdditionalServiceVoucherContext() {
  const context = useContext(AdditionalServiceVoucherContext)

  if (context === undefined) {
    throw new Error(
      'useAdditionalServiceVoucher must be used within a AdditionalServiceVoucherProvider',
    )
  }
  return context
}
