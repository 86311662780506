import TextArea from 'antd/es/input/TextArea'
import R from 'app/assets/R'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import DividerLine from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/DividerLine'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TableWrapper } from '../../styles'
import { BaseButton } from 'app/components/common/BaseButton'
import { PlusOutlined } from '@ant-design/icons'
import { convertedVariables } from 'app/styles/themes/themeVariables'

const ItemEmergingIssuesSolutionsApproach = () => {
  const { t } = useTranslation()
  return (
    <TableWrapper>
      <BaseRow
        className="child-wrapper"
        justify={'space-between'}
        align={'middle'}
      >
        <BaseCol>
          <BaseText children={'Vấn đề 1'} fontWeight="medium" fontSize="xs" />
        </BaseCol>
        <SvgDeleteDesignIcon />
      </BaseRow>
      <DividerLine />
      <ContentWrapper className="child-wrapper">
        <BaseRow gutter={[15, 15]} align={'middle'}>
          <BaseCol xl={12}>
            <BaseSpace>
              <BaseText
                children={t(R.strings.date_of_issue_occurrence)}
                fontWeight="semibold"
                opacity="0.7"
              />
              <BaseDatePickerStyled />
            </BaseSpace>
          </BaseCol>
          <BaseCol xl={12}>
            <BaseSpace>
              <BaseText
                children={t(R.strings.time_elapsed_since_the_beginning)}
                fontWeight="semibold"
                opacity="0.7"
              />
              <BaseInput placeholder={'n/a'} disabled />
            </BaseSpace>
          </BaseCol>
        </BaseRow>
        <BaseRow gutter={[15, 15]} align={'middle'}>
          <BaseCol xl={12}>
            <BaseSpace>
              <BaseText
                children={t(R.strings.emerging_issue)}
                fontWeight="semibold"
                opacity="0.7"
              />
              <TextArea placeholder={t(R.strings.enter_content)} rows={3} />
            </BaseSpace>
          </BaseCol>
          <BaseCol xl={12}>
            <BaseSpace>
              <BaseText
                children={t(R.strings.resolution_direction)}
                fontWeight="semibold"
                opacity="0.7"
              />
              <TextArea placeholder={t(R.strings.enter_content)} rows={3} />
            </BaseSpace>
          </BaseCol>
        </BaseRow>
        <BaseRow>
          <BaseCol xl={24}>
            <BaseSpace>
              <BaseText
                children={t(R.strings.professional_medical_opinion)}
                fontWeight="semibold"
                opacity="0.7"
              />
              <TextArea placeholder={t(R.strings.enter_content)} rows={1} />
            </BaseSpace>
          </BaseCol>
        </BaseRow>
      </ContentWrapper>
    </TableWrapper>
  )
}

export const EmergingIssuesSolutionsApproachLayout = () => {
  const { t } = useTranslation()
  return (
    <BaseSpace>
      <BaseText
        children={`${t(R.strings.part_number, { part: 5 })}. ${t(
          R.strings.emerging_issues_solutions_approach,
        )}`}
        fontWeight="semibold"
        fontSize="xs"
      />
      <ItemEmergingIssuesSolutionsApproach />
      <BaseButton
        icon={
          <PlusOutlined
            rev={undefined}
            color={convertedVariables.otherBlueColor}
          />
        }
        children={t(R.strings.additional_troubleshooting)}
        type="link"
      />
    </BaseSpace>
  )
}

const ContentWrapper = styled(BaseSpace)`
  background-color: #fbfbfb; // need change
`
const BaseDatePickerStyled = styled(BaseDatePicker)`
  width: 100%;
`
