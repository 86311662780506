import { ITreatmentPhaseTreatmentSchedule } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { ContentTreatmentPhaseModal } from './ContentTreatmentPhaseModal'
import { ProfilePatientProvider } from './context'

export const UpdateTreatmentPhaseModal = (props: {
  idSchedule?: string
  treatmentPhase?: ITreatmentPhaseTreatmentSchedule
}) => {
  return (
    <ProfilePatientProvider {...props}>
      <ContentTreatmentPhaseModal />
    </ProfilePatientProvider>
  )
}
