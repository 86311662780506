import { BaseForm } from "app/components/common/forms/BaseForm"
import { IProps } from "./type"
import { IJobTitleLevel } from "app/api/jobtitlelevel/model/job-title-level-management"
import { useState } from "react"

export const useHook = (props: IProps) => {
    const [form] = BaseForm.useForm()
    const [doctors, setDoctors] = useState<IJobTitleLevel[]>([]);

    return {
        form
    }
}