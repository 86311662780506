// Message code define
export const ItemBrandMessageCode = {
  ItemBrandManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20001',
  },
}

// Endpoint
export const ItemBrandEndPoint = {
  ItemBrandManagement: {
    request: 'treatment/v1/item-brand',
  },
}
