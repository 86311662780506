/* eslint-disable @typescript-eslint/no-explicit-any */
import { IDataReport16 } from 'app/api/report/model/report-16'
import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import BaseText from 'app/components/common/BaseText'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { getArray } from 'app/components/tables/common-table/constant'
import { initPagination } from 'app/constant'
import { usePagination } from 'app/hook'
import { useGetFilterReport16 } from 'app/react-query/hook/report'
import { IResponseQueryList } from 'app/react-query/model/common'
import {
  formatMoney,
  moment,
  Pagination
} from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable, IFilter } from './type'

export const useHook = () => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState<IFilter>({
    year: moment().format('YYYY'),
  })
  const [pagination, setPagination] = useState<Pagination>(initPagination)

  const { data: dataInfinity, isLoading } = useGetFilterReport16({
    year: filter.year,
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
  })

  const { flattenDataList } = usePagination()

  const { isHavePermissionFunctionAndGoBack, user } =
    useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.REPORT,
      FunctionPermissionEnum.REPORT_READ_REPORT_16,
      R.strings.report_16,
    )
  }, [user])

  const onChangeFilter = (values: IFilter) => {
    setFilter(values)
    setPagination({
      ...pagination,
      current: 1,
    })
  }

  const onChangeTable = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const dataFlatten: IResponseQueryList<IDataReport16[]> = useMemo(() => {
    return flattenDataList(dataInfinity)
  }, [dataInfinity])

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => {
      let newResponse: IDataTable = {
        ...item,
        index: index + 1,
      }

      item?.months?.forEach(itemMonth => {
        newResponse = {
          ...newResponse,
          [`number-check-in-month-${itemMonth?.month}`]: itemMonth?.checkIn,
          [`number-close-month-${itemMonth.month}`]: itemMonth?.checkInPaid,
          [`close-rate-month-${itemMonth.month}`]: itemMonth?.percent,
        }
      })

      return newResponse
    })
  }, [dataInfinity])

  const paginationData = useMemo(() => {
    return {
      ...pagination,
      total: dataFlatten?.total ?? 0,
    }
  }, [dataInfinity, pagination])

  const columns = useMemo(() => {
    const listMonthOfYear = getArray(12)?.map(month => {
      return {
        key: `month-${month}`,
        title: t(R.strings.month_no, { month }),
        children: [
          convertColumnTable<any>({
            title: t(R.strings.number_check_in),
            key: `number-check-in-month-${month}`,
            className: 'medium-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={formatMoney(
                    record?.[`number-check-in-month-${month}`] ?? '0',
                    '',
                  )}
                  fontWeight="medium"
                  opacity="0.5"
                />
              )
            },
          }),
          convertColumnTable<any>({
            title: t(R.strings.number_close),
            key: `number-close-month-${month}`,
            className: 'medium-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={formatMoney(
                    record?.[`number-close-month-${month}`] ?? '0',
                    '',
                  )}
                  fontWeight="medium"
                  opacity="0.5"
                />
              )
            },
          }),
          convertColumnTable<any>({
            title: t(R.strings.close_rate),
            key: `close-rate-month-${month}`,
            className: 'medium-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={formatMoney(
                    record?.[`close-rate-month-${month}`] ?? '0',
                    '%',
                  )}
                  fontWeight="medium"
                  opacity="0.5"
                />
              )
            },
          }),
        ],
      }
    })

    return [
      convertColumnTable<{ index: number }>({
        title: '#',
        key: 'index',
        classNameWidthColumnOverwrite: 'number-column',
        fixed: 'left',
        render: (_, record) => {
          return <BaseText children={record?.index} fontWeight="medium" />
        },
      }),
      convertColumnTable<IDataReport16>({
        key: 'clinicName',
        title: t(R.strings.clinic),
        classNameWidthColumnOverwrite: 'big-column',
        fixed: 'left',
        render: (_, record) => {
          return <BaseText children={record?.clinicName} fontWeight="medium" />
        },
      }),
      ...listMonthOfYear,
      convertColumnTable<IDataReport16>({
        key: 'total',
        title: t(R.strings.total_check_in),
        classNameWidthColumnOverwrite: 'medium-column',
        fixed: 'right',

        render: (_, record) => {
          return (
            <BaseText
              children={formatMoney(record?.total?.checkIn ?? 0, '')}
              fontWeight="medium"
              colorText="statesGreenColor"
            />
          )
        },
      }),
      convertColumnTable<IDataReport16>({
        key: 'total',
        title: t(R.strings.total_close),
        classNameWidthColumnOverwrite: 'medium-column',
        fixed: 'right',
        render: (_, record) => {
          return (
            <BaseText
              children={formatMoney(record?.total?.checkInPaid ?? 0, '')}
              fontWeight="medium"
              colorText="statesGreenColor"
            />
          )
        },
      }),
      convertColumnTable<IDataReport16>({
        key: 'total',
        title: t(R.strings.percent_achievement),
        fixed: 'right',

        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => {
          return (
            <BaseText
              children={formatMoney(record?.total?.percent ?? 0, '%')}
              fontWeight="medium"
              colorText="statesGreenColor"
            />
          )
        },
      }),
    ]
  }, [t])

  return {
    filter,
    pagination: paginationData,
    onChangeTable,
    onChangeFilter,
    data,
    columns,
    isLoading,
  }
}
