import { DatePicker } from 'antd'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputPassword } from 'app/components/common/inputs/InputPassword'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const ButtonCreateAction = styled(BaseButton)`
  background-color: var(--states-orange-color);
  color: var(--background-color);
  transition: all 0.5s ease;
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--background-color);
    color: var(--states-orange-color);
    border-color: var(--states-orange-color);
    transition: all 0.5s ease;
  }
`
export const LabelWrapper = styled(BaseRow)`
  color: var(--main-2-color);
`

export const RequireDot = styled.div`
  color: var(--states-red-color);
`
export const DashLine = styled(BaseDivider)``

export const TitleForm = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.bold};
`

export const FormItem = styled(BaseForm.Item)``

export const FormInput = styled(BaseInput)``

export const FormInputPassword = styled(InputPassword)``

export const SelectBaseStyled = styled(BaseSelect)``

export const FormDatePicker = styled(DatePicker)`
  width: 100%;
`

export const Header = styled.div`
  margin-top: -10px;
  margin-bottom: -5px;
`
export const Content = styled.div`
  .ant-table-thead > tr > th,
  .ant-table-thead > tr > td {
    border: initial !important;
    border-radius: initial !important;
    border-bottom: 1px solid var(--revenue-dashboard-border-color) !important;
    background: white;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: 1px solid var(--inventory-border-bg);
  }
  margin-bottom: 5px;
`

export const Total = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`

export const TotalNumber = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: var(--states-green-color);
  margin-left: 5px;
`

export const UnSelectedButton = styled(BaseButton)`
  background: var(--inventory-bg2);
  color: white;
  margin-left: 10px;
`

export const OrderNameBox = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  img {
    width: 32px;
    height: 32px;
    gap: 0px;
    border-radius: 4px;
    opacity: 0px;
  }
  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin-left: 5px;
  }
`
