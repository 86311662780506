import {
  PaymentOptionEnum,
  PaymentOptionPurchaseProductServiceEnum,
  YesNoEnum,
} from 'app/common/enum'
import { createContext, useContext, useState } from 'react'
import { dataProfilePatient } from './constant'
import { useCalculateData } from './hook/use-calculate-data'
import { useDebtPayment } from './hook/use-debt-payment'
import { usePurchaseProductService } from './hook/use-purchase-product-service'
import { IProfilePatient } from './type'
import {
  ICompanyGetInvoice,
  IDebtPaymentProfilePatient,
  IOrderDebtPayment,
  IPaymentMethodDebtPayment,
} from './type/debt-payment'
import { IFolderImageParent, IImagePatient } from './type/image-patient'
import { IInfoPatient } from './type/info-patient'
import {
  IDiscountProfilePatient,
  IProductServicePurchaseItem,
  IPurchaseProductService,
  ITotalInvoicePurchaseProductService,
} from './type/purchase-product-service'
import { useImagePatient } from './hook/use-image-patient'
import { UploadFile } from 'antd'
import {
  IAppointmentSession,
  IDoctorTreatmentSchedule,
  IInfoPatientTreatmentSchedule,
  ITreatmentPhaseTreatmentSchedule,
  ITreatmentSchedule,
} from './type/treatment-schedule'
import { useTreatmentScheduleHook } from './hook/use-treatment-schedule'

interface IContextProfilePatientContext {
  // data
  profilePatient?: IProfilePatient
  infoPatient?: IInfoPatient
  debtPayment?: IDebtPaymentProfilePatient
  imagePatient?: IImagePatient
  purchaseProductService?: IPurchaseProductService
  alternativeApplicationPrograms?: string[]

  totalQuantity: number
  totalPayment: number
  totalAmount: number
  totalDiscount: number
  totalPaymentMade: number
  excessMoney: number

  totalInvoice?: ITotalInvoicePurchaseProductService
  // action
  // debt payment
  onChangePaymentOptionDebtPayment?: (
    orderId: string,
    paymentOption: PaymentOptionEnum,
  ) => void
  onChangeIssueInvoiceDebtPayment?: (orderId: string, value: YesNoEnum) => void
  onChangeCustomerPaymentDebtPayment?: (orderId: string, value: number) => void
  onChangePaymentMethodDebtPayment?: (
    orderId: string,
    paymentMethod: IPaymentMethodDebtPayment,
  ) => void
  onPaymentOrderDebtPayment?: (orderId: string) => void
  onDeletePaymentOptionDebtPayment?: (
    orderId: string,
    paymentMethod: IPaymentMethodDebtPayment,
  ) => void

  // purchase product service
  isOpenPurchaseProductService?: boolean
  handleOpenPurchase?: () => void
  handleHidePurchase?: () => void

  onChangeCustomerOrderTotalInvoice?: (customerPayment: number) => void
  onChangeCompanyInfoTotalInvoice?: (
    key: keyof ICompanyGetInvoice,
    value: string,
  ) => void
  onChangeIssueInvoiceTotalInvoice?: (issuesInvoice: YesNoEnum) => void

  onChangePaymentMethodTotalInvoice?: (
    index: number,
    paymentMethod: IPaymentMethodDebtPayment,
  ) => void
  onAddPaymentMethodTotalInvoice?: (
    paymentMethod: IPaymentMethodDebtPayment,
  ) => void
  onDeletePaymentMethodTotalInvoice?: (index: number) => void
  onChangePaymentOptionTotalInvoice?: (
    paymentOption: PaymentOptionPurchaseProductServiceEnum,
  ) => void
  onDeleteAlternativeApplicationProgram?: (index: number) => void
  onAddAlternativeApplicationProgram?: (newValue: string) => void
  onChangeDiscountTotalInvoice?: (discount: IDiscountProfilePatient) => void
  onChangeTotalInvoice?: (
    totalInvoice: ITotalInvoicePurchaseProductService,
  ) => void
  onDeleteProductServiceItem?: (productServiceId: string) => void
  onChangeDiscountProductServiceItem?: ({
    newDiscount,
    productServiceId,
  }: {
    productServiceId: string
    newDiscount: IDiscountProfilePatient
  }) => void
  onChangeQuantityProductServiceItem?: ({
    newQuantity,
    productServiceId,
  }: {
    productServiceId: string
    newQuantity: number
  }) => void

  onAddProductServiceItem?: (
    newItemProductService: Omit<IProductServicePurchaseItem, 'id'>,
  ) => void
  onChangeProductServices?: (
    productServices: IProductServicePurchaseItem[],
  ) => void
  onChangeAlternativeApplicationProgram?: (
    index: number,
    newValue: string,
  ) => void

  // image patient
  addImageParent?: (newData: IFolderImageParent) => void
  onChangeImageList?: ({
    childName,
    parentName,
    listImage,
  }: {
    parentName: string
    childName: string
    listImage: UploadFile[]
  }) => void

  onDeleteChildImagePatient?: ({
    parentName,
    childName,
  }: {
    parentName?: string
    childName?: string
  }) => void

  onDeleteParentImagePatient?: (parentName?: string) => void

  onChangeCompanyInfoOrderDebtPayment?: (
    orderId: string,
    key: keyof ICompanyGetInvoice,
    value: string,
  ) => void

  // treatmentSchedules

  treatmentSchedules?: ITreatmentSchedule[]
  onAddTreatmentPhaseInTreatmentSchedule?: ({
    id,
    treatmentPhase,
  }: {
    id: string
    treatmentPhase?: ITreatmentPhaseTreatmentSchedule
  }) => void
  onDeleteTreatmentPhaseInTreatmentSchedule?: ({ id }: { id: string }) => void
  onChangePhaseAttendingPhysician?: ({
    idSchedule,
    phaseAttendingPhysician,
    idTreatmentPhase,
  }: {
    idSchedule: string
    idTreatmentPhase: string
    phaseAttendingPhysician?: IDoctorTreatmentSchedule
  }) => void
  onChangePhaseApprover?: ({
    idSchedule,
    phaseApprover,
    idTreatmentPhase,
  }: {
    idSchedule: string
    idTreatmentPhase: string
    phaseApprover?: IDoctorTreatmentSchedule
  }) => void
  onAddAppointmentSession?: ({
    idSchedule,
    appointmentSession,
    idTreatmentPhase,
  }: {
    idSchedule: string
    idTreatmentPhase: string
    appointmentSession?: IAppointmentSession
  }) => void
  onChangeAppointmentSession?: ({
    idSchedule,
    appointmentSession,
    idTreatmentPhase,
  }: {
    idSchedule: string
    idTreatmentPhase: string
    appointmentSession?: IAppointmentSession[]
  }) => void
  onUpdateTreatmentPhaseInTreatmentSchedule?: ({
    idSchedule,
    treatmentPhase,
  }: {
    idSchedule: string
    treatmentPhase?: ITreatmentPhaseTreatmentSchedule
  }) => void
}

export const ProfilePatientContext =
  createContext<IContextProfilePatientContext>({
    excessMoney: 0,
    totalAmount: 0,
    totalDiscount: 0,
    totalPayment: 0,
    totalPaymentMade: 0,
    totalQuantity: 0,
  })

export const ProfilePatientProvider = ({ children }) => {
  const [profilePatient, setProfilePatient] = useState(dataProfilePatient)

  const onChangeDebtPayment = (orderDebtPayments: IOrderDebtPayment[]) => {
    setProfilePatient(prev => ({
      ...prev,
      debtPayment: { orderDebtPayments },
    }))
  }

  const hookDebtPayment = useDebtPayment({
    orderDebtPayments: profilePatient?.debtPayment?.orderDebtPayments ?? [],
    onChangeDebtPayment,
  })

  const onChangePurchaseProductService = (
    purchaseProductService: IPurchaseProductService,
  ) => {
    setProfilePatient(prev => ({
      ...prev,
      purchaseProductService,
    }))
  }

  const hookPurchaseProductService = usePurchaseProductService({
    onChangePurchaseProductService,
    purchaseProductService: profilePatient?.purchaseProductService,
  })

  const hookCalculateData = useCalculateData(profilePatient)

  const onChangeImagePatient = (imagePatient: IImagePatient) => {
    setProfilePatient(prev => ({
      ...prev,
      imagePatient,
    }))
  }

  const hookImagePatient = useImagePatient({
    imagePatient: profilePatient?.imagePatient,
    onChangeImagePatient,
  })

  const onChangeInfoTreatment = (
    infoTreatmentSchedule: IInfoPatientTreatmentSchedule,
  ) => {
    setProfilePatient(prev => ({
      ...prev,
      infoTreatmentSchedule,
    }))
  }

  const hookSchedule = useTreatmentScheduleHook({
    profilePatient,
    onChangeInfoTreatment,
  })

  return (
    <ProfilePatientContext.Provider
      value={{
        profilePatient,
        ...hookCalculateData,
        // debt payment
        onChangeCustomerPaymentDebtPayment:
          hookDebtPayment.onChangeCustomerPayment,
        onChangePaymentOptionDebtPayment: hookDebtPayment.onChangePaymentOption,
        onChangeIssueInvoiceDebtPayment: hookDebtPayment.onChangeIssueInvoice,
        onChangePaymentMethodDebtPayment: hookDebtPayment.onChangePaymentMethod,
        onPaymentOrderDebtPayment: hookDebtPayment?.onPaymentOrder,
        onDeletePaymentOptionDebtPayment: hookDebtPayment.onDeletePaymentMethod,
        // product service
        ...hookPurchaseProductService,
        // image patient
        ...hookImagePatient,
        // hookSchedule
        ...hookSchedule,
      }}
    >
      {children}
    </ProfilePatientContext.Provider>
  )
}

export function useProfilePatientContext() {
  const context = useContext(ProfilePatientContext)

  if (context === undefined) {
    throw new Error(
      'useProfilePatientContext must be used within a ProfilePatientContext',
    )
  }
  return context
}
