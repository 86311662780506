/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetRevenueToday } from 'app/api/dashboard'
import { DashboardMessageCode } from 'app/api/dashboard/constant'
import { ResponseRevenueAndPercentage } from 'app/api/dashboard/model'
import R from 'app/assets/R'
import {
  SvgArrowTrendingDownIcon,
  SvgArrowTrendingUpIcon,
} from 'app/assets/svg-assets'
import {
  ExpandedTableValue,
  RenderValueTableReport,
  convertColumnTable,
  useBaseTableReportHook,
} from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { isEqual } from 'lodash'
import {
  DateUtil,
  FORMAT_CENTER_YYYY_MM_DD,
  defaultParamList,
  formatMoney,
} from 'parkway-web-common'
import { useEffect, useMemo } from 'react'
import { IDataRevenue, initialPagination } from './data'
import * as S from './styles'
import { useTranslation } from 'react-i18next'

export const useTableHook = () => {
  const { t } = useTranslation()
  const getRevenueToday = async () => {
    const response: ResponseRevenueAndPercentage = await requestGetRevenueToday(
      {
        ...defaultParamList,
        date: DateUtil.formatDate(new Date(), FORMAT_CENTER_YYYY_MM_DD),
      },
    )
    if (
      isEqual(
        response?.msgcode,
        DashboardMessageCode.RevenueToday.successGetData,
      )
    ) {
      const data = response?.data ?? []
      const dataTable: IDataRevenue[] =
        data?.map((item, index) => {
          const [firstClinic, ...otherClinic] = item?.clinic ?? []
          const newResponse: IDataRevenue = {
            key: index + 1,
            area: item?.areaname,

            totalRevenue: item?.total?? 0,
            totalActual: item?.totalActual?? 0,
            totalProjected: item?.projected?? 0,
            totalValueGrow: item?.percentageIncrease?? 0,
            isTotalUpValue: item?.isGrowValue?? 0,

            totalValueGrowActual: item?.percentageIncreaseActual?? 0,
            isTotalUpValueActual: item?.isGrowValueActual?? 0,

            totalValueGrowProjected: item?.percentageIncreaseProjected?? 0,
            isTotalUpValueProjected: item?.isGrowValueProjected?? 0,

            // child
            actual: firstClinic?.totalActual?? 0,
            revenue: firstClinic?.todayTotal?? 0,
            projected: firstClinic?.totalProjected?? 0,
            isUpValue: firstClinic?.isGrowValue?? 0,
            valueGrow: firstClinic?.percentageIncrease?? 0,

            isUpValueActual: firstClinic?.isGrowValueActual?? 0,
            valueGrowActual: firstClinic?.percentageIncreaseActual?? 0,

            isUpValueProjected: firstClinic?.isGrowValueProjected?? 0,
            valueGrowProjected: firstClinic?.percentageIncreaseProjected?? 0,

            clinic: firstClinic?.clinicName,
            children: otherClinic.map((clinic, idx: number) => {
              return {
                key: Number(`${index + 1}${idx + 1}`),
                actual: clinic?.totalActual ?? 0,
                revenue: clinic?.todayTotal ?? 0,
                projected: clinic?.totalProjected ?? 0,
                isUpValue: clinic?.isGrowValue ?? 0,
                valueGrow: clinic?.percentageIncrease ?? 0,

                isUpValueActual: clinic?.isGrowValueActual ?? 0,
                valueGrowActual: clinic?.percentageIncreaseActual ?? 0,

                isUpValueProjected: clinic?.isGrowValueProjected ?? 0,
                valueGrowProjected: clinic?.percentageIncreaseProjected ?? 0,

                clinic: clinic?.clinicName,
                index: idx + 1,
              }
            }),
          }
          return newResponse
        }) ?? []

      return {
        pagination: initialPagination,
        data: dataTable ?? [],
      } as IDataBaseTable<IDataRevenue>
    }

    return {
      pagination: initialPagination,
      data: [],
    } as IDataBaseTable<any>
  }

  useEffect(() => {
    getRevenueToday()
  }, [])

  const { tableData, expandedRowKeys, handleExpand, handleTableChange } =
    useBaseTableReportHook({ getTreeTableData: getRevenueToday })

  const columns = useMemo(() => {
    let options: any[] = [
      convertColumnTable<IDataRevenue>({
        title: t(R.strings.area),
        key: 'area',
        onCell: record => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)
          if (!isExpanded && record?.children) {
            if (expandedRowKeys?.length) {
              return { rowSpan: 1, colSpan: 2 }
            }
            return { rowSpan: 1 }
          }

          if (isExpanded && !record?.children?.length) {
            return { rowSpan: 1 }
          }

          return {
            rowSpan: record.children?.length ? record.children?.length + 1 : 0,
          }
        },
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)
          if (!record?.children) {
            return null
          }
          const _handleExpand = () => {
            return handleExpand(!isExpanded, record?.key)
          }
          return (
            <ExpandedTableValue
              handleExpand={_handleExpand}
              value={record?.area}
              isExpanded={isExpanded}
            />
          )
        },
      }),
    ]

    if (expandedRowKeys?.length) {
      options = options?.concat([
        convertColumnTable<IDataRevenue>({
          title: t(R.strings.clinic),
          key: 'clinic',
          onCell: record => {
            const isExpanded = expandedRowKeys.includes(record.key ?? 0)
            if (!isExpanded && record?.children && !!expandedRowKeys?.length) {
              return { colSpan: 0 }
            }
            return {}
          },
          render: (_, record) => {
            return (
              <RenderValueTableReport
                record={record}
                expandedRowKeys={expandedRowKeys}
                valueString={record?.clinic}
                justify="start"
              />
            )
          },
        }),
      ])
    }

    return [
      ...options,
      convertColumnTable<IDataRevenue>({
        title: t(R.strings.revenue),
        key: 'revenue',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)

          let dataUsing = {
            revenueData: record?.revenue,
            isUpValue: record?.isUpValue,
            growValue: record?.valueGrow,
          }

          if (record?.children && !isExpanded) {
            dataUsing = {
              revenueData: record?.totalRevenue,
              isUpValue: record?.isTotalUpValue,
              growValue: record?.totalValueGrow,
            }
          }

          let className = ''

          if ((record?.index && record?.index % 2 === 0) || isExpanded) {
            className = 'is-expanded'
          }

          return (
            <S.RevenueTableWrapper className={className}>
              {formatMoney(dataUsing.revenueData ?? '0')}
              <S.GrowthWrapper $upValue={dataUsing.isUpValue}>
                {dataUsing.isUpValue ? (
                  <SvgArrowTrendingUpIcon />
                ) : (
                  <SvgArrowTrendingDownIcon />
                )}
                <span className="value">
                  {dataUsing?.growValue && Number(dataUsing.growValue) !== 0 ? (
                    <>{dataUsing.isUpValue ? '+' : '-'}</>
                  ) : ''}
                  {formatMoney(dataUsing.growValue ?? '0', '%')}
                </span>
              </S.GrowthWrapper>
            </S.RevenueTableWrapper>
          )
        },
      }),
      convertColumnTable<IDataRevenue>({
        title: t(R.strings.actual_revenue),
        key: 'actual',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)

          let dataUsing = {
            revenueData: record?.actual,
            isUpValue: record?.isUpValueActual,
            growValue: record?.valueGrowActual,
          }

          if (record?.children && !isExpanded) {
            dataUsing = {
              revenueData: record?.totalActual,
              isUpValue: record?.isTotalUpValueActual,
              growValue: record?.totalValueGrowActual,
            }
          }

          let className = ''

          if ((record?.index && record?.index % 2 === 0) || isExpanded) {
            className = 'is-expanded'
          }

          return (
            <S.RevenueTableWrapper className={className}>
              {formatMoney(dataUsing.revenueData ?? '0')}
              <S.GrowthWrapper $upValue={dataUsing.isUpValue}>
                {dataUsing.isUpValue ? (
                  <SvgArrowTrendingUpIcon />
                ) : (
                  <SvgArrowTrendingDownIcon />
                )}
                <span className="value">
                  {dataUsing?.growValue && Number(dataUsing.growValue) !== 0 ? (
                    <>{dataUsing.isUpValue ? '+' : '-'}</>
                  ): ''}
                  {formatMoney(dataUsing.growValue ?? '0', '%')}
                </span>
              </S.GrowthWrapper>
            </S.RevenueTableWrapper>
          )
        },
      }),
      convertColumnTable<IDataRevenue>({
        title: t(R.strings.projected_revenue),
        key: 'projected',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)

          let dataUsing = {
            revenueData: record?.projected,
            isUpValue: record?.isUpValueProjected,
            growValue: record?.valueGrowProjected,
          }

          if (record?.children && !isExpanded) {
            dataUsing = {
              revenueData: record?.totalProjected,
              isUpValue: record?.isTotalUpValueProjected,
              growValue: record?.totalValueGrowProjected,
            }
          }

          let className = ''

          if ((record?.index && record?.index % 2 === 0) || isExpanded) {
            className = 'is-expanded'
          }

          return (
            <S.RevenueTableWrapper className={className}>
              {formatMoney(dataUsing.revenueData ?? '0')}
              <S.GrowthWrapper $upValue={dataUsing.isUpValue}>
                {dataUsing.isUpValue ? (
                  <SvgArrowTrendingUpIcon />
                ) : (
                  <SvgArrowTrendingDownIcon />
                )}
                <span className="value">
                  {dataUsing?.growValue && Number(dataUsing.growValue) !== 0 ? (
                    <>{dataUsing.isUpValue ? '+' : '-'}</>
                  ) : ''}
                  {formatMoney(dataUsing.growValue ?? '0', '%')}
                </span>
              </S.GrowthWrapper>
            </S.RevenueTableWrapper>
          )
        },
      }),
    ]
  }, [t, expandedRowKeys, convertColumnTable])

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
  }
}
