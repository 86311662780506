import styled from 'styled-components'

export const WeekDayName = styled.p`
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  color: var(--main-color-text);
  text-align: left;
  text-transform: capitalize;
`
export const WeekDay = styled.p`
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--main-color-text);
  text-align: left;
`
export const WeekCurrentDayName = styled.div`
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--states-green-color);
  width: 59px;
  height: 22px;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  gap: 10px;
  background: var(--states-green-light-color);
`
