/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestImportMarketingPlan } from 'app/api/marketing'
import { MarketingMessageCode } from 'app/api/marketing/constant'
import R from 'app/assets/R'
import { PeriodType } from 'parkway-web-common'
import {
  ExpandedTableValue,
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import {
  IBaseColumnReport,
  IOnCell,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { notificationController } from 'app/controllers/notification-controller'
import { convertToFormDateImport } from 'app/service/general/service'
import { isEqual } from 'lodash'
import { Pagination, ResponseType } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataMarketingPlanMonthly } from './Monthly/data'
import { IDataMarketingPlanWeekly } from './Weekly/data'
import { initialPagination } from './common-model'
import { IAreaOfService, IMonthOrWeekOfArea } from 'app/api/marketing/model'

export const useCommonHook = ({
  expandedRowKeys,
  fetchLoading,
  typeHook,
  handleExpand,
  fetch,
  expandedAreaKeys,
  handleExpandArea,
  dataTable,
  currentFilter,
}: {
  expandedRowKeys: number[]
  fetchLoading: (value: boolean) => void
  typeHook: PeriodType
  fetch: (pagination: Pagination, params?: IParamsHookReport) => void
  handleExpand: (isExpanded: boolean, key?: number) => void
  expandedAreaKeys: number[]
  handleExpandArea: (isExpanded: boolean, key?: number) => void
  dataTable: any[]
  currentFilter?: IParamsHookReport
}) => {
  const { t } = useTranslation()

  const onClickImport = async (file: any, periodType: PeriodType) => {
    fetchLoading(true)
    try {
      const response: ResponseType<{ countSuccess?: number }> =
        await requestImportMarketingPlan(
          convertToFormDateImport({
            file,
            periodtype: periodType,
            importtype: 'full',
          }),
        )
      if (
        isEqual(
          response.msgcode,
          MarketingMessageCode.MarketingPlan.successImport,
        )
      ) {
        fetch(initialPagination, currentFilter)
        notificationController.success({
          message: t(R.strings.import_file_successful),
        })
        fetchLoading(false)
        return
      }
      fetchLoading(false)
      notificationController.error({ message: t(R.strings.import_file_error) })
    } catch (error) {
      fetchLoading(false)
    }
  }

  const columns = useMemo(() => {
    type TypeColumn = IDataMarketingPlanWeekly | IDataMarketingPlanMonthly

    const onCellService = (record: TypeColumn): IOnCell => {
      if (record.isArea && record?.children) {
        const isExpandedArea = expandedRowKeys.includes(record.key ?? 0)
        if (!isExpandedArea && expandedAreaKeys?.length) {
          if (expandedRowKeys.length > expandedAreaKeys.length) {
            return {
              rowSpan: 1,
              colSpan: 3,
            }
          }

          return {
            rowSpan: 1,
            colSpan: 2,
          }
        }

        if (!expandedAreaKeys?.length) {
          return { rowSpan: 1 }
        }

        let count = record?.children?.length + 1 ?? 0
        record?.children?.forEach(item => {
          const isExpanded = expandedRowKeys.includes(item.key ?? 0)
          if (isExpanded && item?.children) {
            count += item?.children?.length
          }
        })

        return {
          rowSpan: count,
        }
      }

      return { rowSpan: 0 }
    }

    const onCellArea = (record: TypeColumn): IOnCell => {
      if (record?.isArea) {
        const isExpandedArea = expandedRowKeys.includes(record.key ?? 0)
        if (!isExpandedArea && expandedAreaKeys?.length) {
          return { colSpan: 0 }
        }

        const isExpanded = expandedRowKeys.includes(record.childrenKey ?? 0)
        if (!isExpanded && record?.children) {
          if (expandedRowKeys.length > expandedAreaKeys.length) {
            return { rowSpan: 1, colSpan: 2 }
          }
          return { rowSpan: 1 }
        }

        if (isExpanded && !record?.childOfFirstChild?.length) {
          return { rowSpan: 1 }
        }

        return {
          rowSpan: record.childOfFirstChild?.length
            ? record.childOfFirstChild?.length + 1
            : 0,
        }
      }

      const isExpanded = expandedRowKeys.includes(record.key ?? 0)
      if (!isExpanded && record?.children) {
        if (expandedRowKeys.length > expandedAreaKeys.length) {
          return { rowSpan: 1, colSpan: 2 }
        }
        return { rowSpan: 1 }
      }

      if (isExpanded && !record?.children?.length) {
        return { rowSpan: 1 }
      }

      return {
        rowSpan: record.children?.length ? record.children?.length + 1 : 0,
      }
    }

    const onCellEmployeeName = (record: TypeColumn): IOnCell => {
      if (record?.isArea) {
        const isExpanded = expandedRowKeys.includes(record.childrenKey ?? 0)

        if (
          !isExpanded &&
          record?.children &&
          expandedRowKeys.length > expandedAreaKeys.length
        ) {
          return { colSpan: 0 }
        }
      }

      const isExpanded = expandedRowKeys.includes(record.key ?? 0)
      if (
        !isExpanded &&
        record?.children &&
        expandedRowKeys.length > expandedAreaKeys.length
      ) {
        return { colSpan: 0 }
      }

      return {}
    }

    // render column

    const renderServiceColumn = (_, record: TypeColumn) => {
      const isExpandedChild = expandedRowKeys.includes(record.key ?? 0)
      const _handleExpand = () => {
        if (record?.isArea) {
          handleExpandArea(!isExpandedChild, record.key ?? 0)
        }
      }
      return (
        <ExpandedTableValue
          isExpanded={isExpandedChild}
          handleExpand={_handleExpand}
          value={record?.service}
          className={isExpandedChild ? 'is-expanded-1' : ''}
        />
      )
    }

    const renderAreaColumn = (_, record: TypeColumn) => {
      if (!record?.children) return null
      let key = record?.key
      if (record?.isArea) key = record?.childrenKey
      const isExpandedChild = expandedRowKeys.includes(key ?? 0)
      const _handleExpand = () => {
        return handleExpand(!isExpandedChild, key)
      }
      return (
        <ExpandedTableValue
          isExpanded={isExpandedChild}
          handleExpand={_handleExpand}
          value={record?.area}
        />
      )
    }

    const renderEmployeeNameColumn = (_, record: TypeColumn) => {
      switch (typeHook) {
        case 'week':
          return (
            <RenderValueTableReport
              record={record}
              valueString={record?.week}
              justify="start"
              expandedRowKeys={expandedRowKeys}
            />
          )
        case 'month':
          return (
            <RenderValueTableReport
              record={record}
              valueString={record?.month}
              justify="start"
              expandedRowKeys={expandedRowKeys}
            />
          )
      }
    }

    let options: IBaseColumnReport<TypeColumn> = [
      convertColumnTable({
        title: t(R.strings.service),
        key: 'service',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'big-column',
        onCell: onCellService,
        render: renderServiceColumn,
      }),
    ]

    if (expandedAreaKeys.length) {
      options = options.concat([
        convertColumnTable({
          title: t(R.strings.area),
          key: 'area',
          fixed: 'left',
          onCell: onCellArea,
          render: renderAreaColumn,
        }),
      ])
    }

    if (expandedRowKeys.length > expandedAreaKeys.length) {
      switch (typeHook) {
        case 'week':
          options = options?.concat([
            convertColumnTable<IDataMarketingPlanWeekly>({
              title: t(R.strings.week),
              fixed: 'left',
              key: 'week',
              onCell: onCellEmployeeName,
              render: renderEmployeeNameColumn,
            }),
          ])
          break
        case 'month':
          options = options?.concat([
            convertColumnTable<IDataMarketingPlanMonthly>({
              title: t(R.strings.month),
              key: 'month',
              fixed: 'left',
              onCell: onCellEmployeeName,
              render: renderEmployeeNameColumn,
            }),
          ])
          break
      }
    }

    return [
      ...options,
      convertColumnTable<TypeColumn>({
        title: t(R.strings.budget),
        key: 'budget',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.budget ?? 0,
            secondLevelValue: record?.budgetTotal ?? 0,
            isFillBlueInTotal: true,
            expandedRowKeys,
          }),
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.spent_actual),
        key: 'spentActual',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.spentActual ?? 0,
            secondLevelValue: record?.spentActualTotal ?? 0,
            isFillBlueInTotal: true,
            expandedRowKeys,
          }),
      }),
      {
        title: t(R.strings.number_q_lead),
        children: [
          convertColumnTable<TypeColumn>({
            title: t(R.strings.planing),
            key: 'qTechPlaning',
            render: (_, record) =>
              RenderValueTableReport({
                record,
                firstLevelValue: record?.qTechPlaning ?? 0,
                secondLevelValue: record?.qTechPlaningTotal ?? 0,
                expandedRowKeys,
                prefix: '',
              }),
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.actuals),
            key: 'qTechActual',
            render: (_, record) =>
              RenderValueTableReport({
                record,
                firstLevelValue: record?.qTechActual ?? 0,
                secondLevelValue: record?.qTechActualTotal ?? 0,
                expandedRowKeys,
                prefix: '',
              }),
          }),
        ],
      },
      {
        title: t(R.strings.cpql),
        children: [
          convertColumnTable<TypeColumn>({
            title: t(R.strings.planing),
            key: 'cpqlPlaning',
            render: (_, record) =>
              RenderValueTableReport({
                record,
                firstLevelValue: record?.cpqlPlaning ?? 0,
                secondLevelValue: record?.cpqlPlaningTotal ?? 0,
                expandedRowKeys,
              }),
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.actuals),
            key: 'cpqlActual',
            render: (_, record) =>
              RenderValueTableReport({
                record,
                firstLevelValue: record?.cpqlActual ?? 0,
                secondLevelValue: record?.cpqlActualTotal ?? 0,
                expandedRowKeys,
              }),
          }),
        ],
      },
    ]
  }, [t, expandedRowKeys, typeHook])

  const data = (() => {
    return dataTable.map(item => {
      const isExpanded = expandedRowKeys.includes(item.childrenKey)
      const oldChild =
        item?.children?.filter(
          (itm: { parentKey?: number }) => !itm?.parentKey,
        ) ?? []
      if (isExpanded) {
        const newChild = item?.childOfFirstChild ?? []
        return {
          ...item,
          children: [...newChild, ...oldChild],
        }
      }
      return {
        ...item,
        children: oldChild,
      }
    })
  })()

  return {
    onClickImport,
    columns,
    data,
  }
}

export const convertChildCostPlanValue = (item: IMonthOrWeekOfArea) => {
  return {
    budget: item?.budgetPlan,
    appointmentSchedule: item?.bookingPlan,
    checkInSchedule: item?.checkInPlan,
    qTechPlaning: item?.qLeadPlan,
    qTechActual: item?.qLeadActual,
    spentActual: item?.budgetActual,
    cpqlPlaning: item?.cpqlPlan,
    cpqlActual: item?.cpqlActual,
  }
}

export const convertTotalCostPlanValue = (item: IAreaOfService) => {
  return {
    area: item?.areaName,
    budgetTotal: item?.budgetPlanTotal,
    appointmentScheduleTotal: item?.bookingPlanTotal,
    checkInScheduleTotal: item?.checkInPlanTotal,
    qTechPlaningTotal: item?.qLeadPlanTotal,
    qTechActualTotal: item?.qLeadActualTotal,
    spentActualTotal: item?.budgetActualTotal,
    cpqlPlaningTotal: item?.cpqlPlanTotal,
    cpqlActualTotal: item?.cpqlActualTotal,
  }
}
