import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import CreateDiscountCampaignContainer from 'app/containers/Marketing/DiscountCampaignManagement/CreateCampaign'

const CreateDiscountCampaignPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.create_campaign)}</PageTitle>
      <CreateDiscountCampaignContainer />
    </>
  )
}
const CreateDiscountCampaignPage = memo(CreateDiscountCampaignPageCpn, isEqual)

export default CreateDiscountCampaignPage
