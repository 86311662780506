/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetReport11 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { IResponseReportFromThenUtilNow } from 'app/api/report/model/report-11'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { isEqual } from 'lodash'
import { Pagination, initialPagination } from 'parkway-web-common'
import { getValueByTreatment, useCommonHook } from '../common-hook'
import { IDataReport11FromThenUtilNow } from './data'

export const useTableFromThenUtilNowHook = () => {
  const getDataFromThenUtilNow = async (pagination: Pagination) => {
    try {
      const response: IResponseReportFromThenUtilNow = await requestGetReport11(
        {
          type: 'full',
        },
      )

      if (
        isEqual(response?.msgcode, ReportMessageCode.Report11.successGetData)
      ) {
        const data = response?.data ?? []

        const dataTable: IDataReport11FromThenUtilNow[] = data?.map(
          (item, index) => {
            const treatmentData = getValueByTreatment({
              treatments: item?.treatments,
              type: 'normal',
            })

            return {
              key: index + 1,
              fullName: `${item?._id}`,
              annualExpenditureAmount: item?.payments,
              numberOfPurchase: item?.treatments?.length ?? 0,
              averageOrderValue: item?.avgPayments,
              ...treatmentData,
              index: index + 1,
              parentKey: index + 1,
            } as IDataReport11FromThenUtilNow
          },
        )

        return {
          pagination: pagination ?? initialPagination,
          data: dataTable,
        } as IDataBaseTable<IDataReport11FromThenUtilNow>
      }
      return {
        pagination: pagination ?? initialPagination,
        data: [],
      } as IDataBaseTable<IDataReport11FromThenUtilNow>
    } catch (error) {
      // console.log({error});
      
      return {
        pagination: pagination ?? initialPagination,
        data: [],
      } as IDataBaseTable<IDataReport11FromThenUtilNow>
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,
    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({ getTreeTableData: getDataFromThenUtilNow })

  const { columns, exportExcel } = useCommonHook({
    expandedRowKeys,
    typeHook: 'FROM_THEN_UTIL_NOW',
    handleExpand,
    fetchLoading
  })

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleResetExpand,
    fetch,
    fetchLoading,
    exportExcel
  }
}
