import { DualAxes } from '@ant-design/plots'
import { useEffect, useState } from 'react'
import { requestGetNPS } from 'app/api/survey'
import { ResponseType } from 'parkway-web-common'
import { isEqual } from 'lodash'
import { SurveyMessageCode } from 'app/api/survey/constant'
import { IResponseFilter } from '../FilterSurveyReport/type'
import * as S from './styles'
import { t } from 'i18next'
import R from 'app/assets/R'

const lineWidth = 2
const lineColor = {
  current: '#FA9F34',
  average: '#9747FF',
}

enum ValueReportEnum {
  average = 'Đánh giá trung bình',
  current = 'Khu vực đã chọn',
}

enum TypeReportKeyEnum {
  average = 'average',
  current = 'current',
}

const convertToLineColor = type => {
  switch (type) {
    case ValueReportEnum.average:
      return TypeReportKeyEnum.average
    case ValueReportEnum.current:
      return TypeReportKeyEnum.current

    default:
      return ''
  }
}

interface IResponseNPS {
  npsCurrent?: Array<IDataNPS>
  total?: Array<IDataNPS>
}

interface IDataNPS {
  date: string
  value: number
  type: string
}

export function ChartNetPromoterScore({
  location,
  clinic,
  area,
}: IResponseFilter) {
  const [nps, setNPS] = useState<IResponseNPS>({})

  const fetchNPS = async () => {
    try {
      const param = {
        location: location ?? null,
      }

      const res: ResponseType<IResponseNPS> = await requestGetNPS(param)
      if (
        isEqual(res?.msgcode, SurveyMessageCode.SurveyReport.successGetData)
      ) {
        const data: IResponseNPS = res?.data || {}
        setNPS(data)
      }
    } catch (error) {
      console.error({ error })
    }
    return []
  }

  useEffect(() => {
    fetchNPS()
  }, [clinic, area])
  const currentData = nps.npsCurrent ?? []
  const averageData = nps.total ?? []

  const currentFollowByAverage = averageData.map(item => {
    const current = currentData.find(current => current.date === item.date)

    return {
      date: item.date,
      value: current?.value ?? null,
      type: current?.type ?? ValueReportEnum.current,
    }
  })

  const lineData = [...averageData, ...currentFollowByAverage]

  const config = {
    scrollbar: {
      x: {
        ratio: 0.8,
        scrollable: true,
        slidable: true,
      },
    },
    legend: {
      color: {
        layout: {
          justifyContent: 'center',
          alignItems: 'flex-end',
          flexDirection: 'column',
        },
      },
    },
    xField: 'date',
    children: [
      {
        data: lineData,
        type: 'line',
        yField: 'value',
        colorField: 'type',
        style: {
          lineWidth,
          stroke: d => {
            return lineColor[convertToLineColor(d[0].type)]
          },
        },
        axis: {
          x: {
            title: 'Ngày',
            titlePosition: 'lb',
          },
          y: {
            labelDirection: 'negative',
            title: 'Số điểm NPS',
            titlePosition: 't',
          },
        },
        shapeField: 'smooth',
        scale: {
          y: {
            domainMin: -100,
            domainMax: 100,
            domain: [-100, 100],
            tickMethod: () => [-100, 0, 30, 70, 100],
          },
        },
        interaction: {
          tooltip: {
            crosshairs: false,
          },
        },
      },
    ],
    theme: {
      category10: ['#9747FF', '#FA9F34'],
    },
  }
  return (
    <S.NPSChart>
      <S.Label className="excellent">
        {t(R.strings.survey_report_content_excellent)}
      </S.Label>
      <S.Label className="good">
        {t(R.strings.survey_report_content_good)}
      </S.Label>
      <S.Label className="middle">
        {t(R.strings.survey_report_content_middle)}
      </S.Label>
      <S.Label className="bad">
        {t(R.strings.survey_report_content_bad)}
      </S.Label>
      <DualAxes className="nps-chart" {...config} />
    </S.NPSChart>
  )
}

export default ChartNetPromoterScore
