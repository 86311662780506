/* eslint-disable @typescript-eslint/no-explicit-any */
import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { isEqual } from 'lodash'
import moment from 'moment'
import { SelectFilterEnum, initialPagination } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTableMonthHook } from './Month/hook'
import { useTableYearHook } from './Year/hook'

export const useTableHook = () => {
  const { t } = useTranslation()

  const [filterResponse, setFilterResponse] = useState<IResponseFilter>({
    year: `${moment().year()}`,
    month: `${moment().month() + 1}`,
  })

  const hookMonth = useTableMonthHook({
    year: filterResponse.year,
    month: filterResponse?.month,
  })

  const hookYear = useTableYearHook({ year: filterResponse.year })

  const expandedRowKeys = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.MONTH:
        return hookMonth.expandedRowKeys
      case SelectFilterEnum.YEAR:
        return hookYear.expandedRowKeys
      default:
        return hookMonth.expandedRowKeys
    }
  }, [filterResponse?.selectFilterType, hookMonth, hookYear])

  const tableData = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.MONTH:
        return hookMonth.tableData
      case SelectFilterEnum.YEAR:
        return hookYear.tableData
      default:
        return hookMonth.tableData
    }
  }, [filterResponse?.selectFilterType, hookMonth, hookYear])

  const handleTableChange = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.MONTH:
        return hookMonth.handleTableChange
      case SelectFilterEnum.YEAR:
        return hookYear.handleTableChange
      default:
        return hookMonth.handleTableChange
    }
  }, [filterResponse?.selectFilterType, hookMonth, hookYear])

  const handleExpand = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.MONTH:
        return hookMonth.handleExpand
      case SelectFilterEnum.YEAR:
        return hookYear.handleExpand
      default:
        return hookMonth.handleExpand
    }
  }, [filterResponse?.selectFilterType, hookMonth, hookYear])

  const handleChangeFilter = (filter: IResponseFilter) => {
    const { year, month } = filter
    setFilterResponse(filter)
    if (year && month) {

      if (
        !isEqual(
          { year, month },
          { year: filterResponse?.year, month: filterResponse?.month },
        )
      ) {
        hookMonth.fetch(initialPagination, { year, month })
      }
    }
    if (year) {

      if (!isEqual(year, filterResponse?.year)) {
        hookYear.fetch(initialPagination, { year })
      }
    }
  }

  const columns = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.MONTH:
        return hookMonth.columns
      case SelectFilterEnum.YEAR:
        return hookYear.columns
      default:
        return hookMonth.columns
    }
  }, [t, expandedRowKeys, filterResponse?.selectFilterType])

  const exportExcel = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.MONTH:
        return hookMonth.exportExcel
      case SelectFilterEnum.YEAR:
        return hookYear.exportExcel
      default:
        return hookMonth.exportExcel
    }
  }, [filterResponse])

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleChangeFilter,
    filterResponse,
    exportExcel,
  }
}
