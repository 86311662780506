import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useModalHook } from './hook'
import * as S from './styles'
import { IPropsChangePassword, initValues } from './type'

export const ChangePassword = (props: IPropsChangePassword) => {
  const {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
  } = useModalHook(props)

  return (
    <BaseModalManagement
      isLoading={isLoading}
      typeButtonOpen="update"
      titleKeyI18n={R.strings.change_password}
      content={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <BaseRow gutter={20} align={'top'} justify={'space-between'}>
            <BaseCol xl={12}>
              <S.FormItem
                label={t(R.strings.password)}
                name="password"
                rules={rules.password}
                required
              >
                <S.FormInputPassword
                  placeholder={t(R.strings.enter_field, {
                    field: t(R.strings.password),
                  })}
                />
              </S.FormItem>
            </BaseCol>
            <BaseCol xl={12}>
              <S.FormItem
                label={t(R.strings.re_password)}
                name="re_password"
                rules={rules.re_password}
                required
              >
                <S.FormInputPassword placeholder={t(R.strings.re_password)} />
              </S.FormItem>
            </BaseCol>
          </BaseRow>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.change_password}
      confirmKeyI18n={R.strings.change_password}
      handleSubmit={form.submit}
      visible={visible}
    />
  )
}
