import React, { useEffect, useRef, useState } from 'react'
import { SearchOverlay } from './search-overlay/SearchOverlay/SearchOverlay'
import { HeaderActionWrapper } from 'app/components/header/styles'
import { CategoryComponents } from 'app/components/header/components/HeaderSearch'
import { Btn, InputSearch } from '../HeaderSearch/styles'
import { useTranslation } from 'react-i18next'
import { BasePopover } from 'app/components/common/BasePopover'
import { DashOutlined } from '@ant-design/icons'

interface SearchOverlayProps {
  query: string
  setQuery: (query: string) => void
  data: CategoryComponents[] | null
  isOverlayOpen: boolean
  setOverlayOpen: (state: boolean) => void
}

export const SearchDropdown: React.FC<SearchOverlayProps> = ({
  query,
  setQuery,
  data,
  isOverlayOpen,
  setOverlayOpen,
}) => {
  const [isFilterOpen, setFilterOpen] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    setOverlayOpen(!!query || isFilterOpen)
  }, [query, isFilterOpen, setOverlayOpen])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null)

  return (
    <>
      <BasePopover
        {...((!!data || isFilterOpen) && {
          trigger: 'click',
          onOpenChange: setOverlayOpen,
        })}
        overlayClassName="search-overlay"
        content={<SearchOverlay data={data} isFilterOpen={isFilterOpen} />}
        open={isOverlayOpen}
        getPopupContainer={() => ref.current}
      >
        <HeaderActionWrapper>
          <InputSearch
            width="100%"
            value={query}
            placeholder={t('header.search')}
            filter={
              <Btn
                size="small"
                type={isFilterOpen ? 'primary' : 'text'}
                aria-label="Filter"
                icon={<DashOutlined rev={undefined} />}
                onClick={() => setFilterOpen(!isFilterOpen)}
              />
            }
            onChange={event => setQuery(event.target.value)}
            enterButton={null}
            addonAfter={null}
          />
          <div ref={ref} />
        </HeaderActionWrapper>
      </BasePopover>
    </>
  )
}
