import { ApiClient } from 'app/service/api-service'
import { AccountingEndPoint } from '../constant'
import { IParamsGetPatientOutdated } from '../model/patient-outdated'

export const requestPatientOutdated = (
  params: IParamsGetPatientOutdated,
) =>
  ApiClient.Get({
    url: AccountingEndPoint.PatientOutdated.requestGetData,
    params: { params },
  })