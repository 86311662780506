import { ApiClientHr } from 'app/service/api-service'
import {
  IParamsGetUnitManagement,
  IPayloadCreateUnitManagement,
  IPayloadUpdateUnitManagement,
} from './model/unit-management'
import { UnitEndPoint } from './constant'

export const requestGetUnitManagement = (params: IParamsGetUnitManagement) =>
  ApiClientHr.Get({
    url: UnitEndPoint.UnitManagement.request,
    params: { params },
  })

export const requestCreateUnitManagement = (
  body: IPayloadCreateUnitManagement,
) =>
  ApiClientHr.Post({
    url: UnitEndPoint.UnitManagement.request,
    body,
  })

export const requestUpdateUnitManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateUnitManagement
}) =>
  ApiClientHr.Put({
    url: `${UnitEndPoint.UnitManagement.request}/${id}`,
    body,
  })

export const requestDetailUnitManagement = (id?: string | number) =>
  ApiClientHr.Get({
    url: `${UnitEndPoint.UnitManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteUnitManagement = (id?: string | number) =>
  ApiClientHr.Delete({
    url: `${UnitEndPoint.UnitManagement.request}/${id}`,
    body: {},
  })

export const requestGetTypeIdManagement = () =>
  ApiClientHr.Get({
    url: `${UnitEndPoint.UnitManagement.request}/get-type-id`,
    params: {},
  })

export const requestGetAllUnitManagement = () =>
  ApiClientHr.Get({
    url: `${UnitEndPoint.UnitManagement.request}/get-all`,
    params: {},
  })

  export const requestFindUnitManagement = (params: IParamsGetUnitManagement) =>
  ApiClientHr.Get({
    url: `${UnitEndPoint.UnitManagement.request}/find`,
    params: { params },
  })
