import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import PatientRecordManagementContainer from 'app/containers/DentalTreatmentManagement/PatientRecordManagement'

const PatientRecordManagementPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.dental_treatment_management)}</PageTitle>
      <PatientRecordManagementContainer />
    </>
  )
}
const PatientRecordManagementPage = memo(PatientRecordManagementPageCpn, isEqual)

export default PatientRecordManagementPage
