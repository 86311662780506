/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetReport10 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { IResponseGetReport10 } from 'app/api/report/model/report-10'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import {
  IDataBaseTable,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { initialPagination } from 'parkway-web-common'
import { isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import {
  convertDataToDataTable,
  getValueByLocation,
  initValueByLocation,
  sumDataWithArea,
  useCommonHook,
} from '../common-hook'
import { IDataReport10Year } from './data'
import { getGender } from 'parkway-web-common'

export const useTableYearHook = (props: IParamsHookReport) => {
  const { t } = useTranslation()

  const getDataYear = async (
    pagination: Pagination,
    params: IParamsHookReport,
  ) => {
    try {
      const response: IResponseGetReport10 = await requestGetReport10({
        year: props?.year,
        ...params,
      })
      if (
        isEqual(response?.msgcode, ReportMessageCode.Report10.successGetData)
      ) {
        const data = response?.data ?? []
        let totalTable = initValueByLocation

        let dataTable: IDataReport10Year[] = []
        data?.forEach((item, index) => {
          let total = initValueByLocation

          const [firstTypeAge, ...otherTypeAge] = item?.typeAges ?? []
          const [firstAgeGroupOfTypeAge, ...otherAgeGroupOfTypeAge] =
            firstTypeAge?.ageGroups ?? []
          const dataOfLocationFirstAgeGroup = getValueByLocation({
            listLocation: firstAgeGroupOfTypeAge,
          })
          total = sumDataWithArea(total, dataOfLocationFirstAgeGroup)
          let newResponse: IDataReport10Year = {
            key: index + 1,
            gender: t(getGender(item?.gender)),
            age: firstAgeGroupOfTypeAge?.ageGroup,
            typeAge: firstTypeAge?.typeAge,
            numOfChild: otherAgeGroupOfTypeAge?.length + 1,
            ...convertDataToDataTable(dataOfLocationFirstAgeGroup),
          }

          let children: IDataReport10Year[] = []
          children = children.concat(
            otherAgeGroupOfTypeAge?.map((item, idx) => {
              const dataOfLocation = getValueByLocation({
                listLocation: item,
              })

              total = sumDataWithArea(total, dataOfLocation)
              return {
                key: Number(`${index + 1}${idx + 1}`),
                age: item?.ageGroup,
                ...convertDataToDataTable(dataOfLocation),
              }
            }),
          )

          otherTypeAge?.forEach(item => {
            const [firstItem, ...otherItem] = item?.ageGroups ?? []
            const dataOfLocationFirstItem = getValueByLocation({
              listLocation: firstItem,
            })
            total = sumDataWithArea(total, dataOfLocationFirstItem)

            children = children?.concat([
              {
                key: Number(`${index + 1}${children?.length + 1}`),
                age: firstItem?.ageGroup,
                typeAge: item?.typeAge,
                numOfChild: otherItem?.length + 1,
                ...convertDataToDataTable(dataOfLocationFirstItem),
              },
            ])
            children = children.concat(
              otherItem?.map((item, idx) => {
                const dataOfLocation = getValueByLocation({
                  listLocation: item,
                })
                total = sumDataWithArea(total, dataOfLocation)

                return {
                  key: Number(`${index + 1}${idx + children?.length + 1}`),
                  age: item?.ageGroup,
                  ...convertDataToDataTable(dataOfLocation),
                }
              }),
            )
          })

          newResponse = {
            ...newResponse,
            children: children?.map((item, idx) => ({
              ...item,
              index: idx + 1,
            })),
          }

          const totalItem: IDataReport10Year = {
            key: Number(`${index + 1}${children?.length + 2}`),
            gender: 'Tổng',
            isTotalGroupTable: true,
            ...convertDataToDataTable(total),
          }

          totalTable = sumDataWithArea(totalTable, total)
          dataTable = dataTable.concat([newResponse, totalItem])
        })

        return {
          pagination: pagination ?? initialPagination,
          data: [
            ...dataTable,
            {
              key: Number(`${dataTable.length + 1}`),
              gender: 'Tổng',
              isTotalTable: true,
              ...convertDataToDataTable(totalTable),
            },
          ],
        } as IDataBaseTable<IDataReport10Year>
      }
      return {
        pagination: pagination ?? initialPagination,
        data: [],
      } as IDataBaseTable<IDataReport10Year>
    } catch (error) {
      return {
        pagination: pagination ?? initialPagination,
        data: [],
      } as IDataBaseTable<IDataReport10Year>
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,
    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({
    getTreeTableData: getDataYear,
    isExpandedWhenStart: true,
  })

  const { columns, exportExcel } = useCommonHook({
    expandedRowKeys,
    typeHook: 'month',
    currentFilter: props,
    fetchLoading
  })

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleResetExpand,
    fetch,
    fetchLoading,
    exportExcel
  }
}
