import { MoreOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseButton } from 'app/components/common/BaseButton'
import { BasePopover } from 'app/components/common/BasePopover'
import BaseText from 'app/components/common/BaseText'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable } from './type'
import styled from 'styled-components'
import { PADDING } from 'parkway-web-common'

export const useHookCommon = () => {
  const { t } = useTranslation()
  const column = useMemo(() => {
    return [
      convertColumnTable<IDataTable>({
        key: 'stt',
        title: '#',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.stt}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        key: 'treatmentPlanType',
        title: t(R.strings.treatment_plan_type),
        render: (_, record) => {
          return (
            <BaseText
              children={record?.treatmentPlanType}
              fontWeight="semibold"
              fontSize="xs"
            />
          )
        },
      }),

      convertColumnTable<IDataTable>({
        key: 'level',
        title: t(R.strings.level),
        render: (_, record) => {
          return (
            <StatusWrapper
              $backgroundColor="linear-gradient(90deg, #629DAA, #6DAEBD)"
            >
              <BaseText
                children={record?.level}
                fontWeight="medium"
                fontSize="xs"
                colorText='backgroundColor'
              />
            </StatusWrapper>
          )
        },
      }),

      convertColumnTable<IDataTable>({
        key: 'treatingPhysician',
        title: t(R.strings.treating_physician),
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          return (
            <BaseAvatarProfile
              name={record?.treatingPhysician ?? '-'}
              jobTitle="Nhân viên"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        key: 'service',
        title: t(R.strings.service),
        render: (_, record) => {
          return (
            <BaseText
              children={record?.service}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        key: 'patient',
        title: t(R.strings.patient),
        render: (_, record) => {
          return (
            <BaseText
              children={record?.patient}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        key: 'date',
        title: t(R.strings.date),
        render: (_, record) => {
          return (
            <BaseText
              children={record?.date}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        key: 'service',
        title: '',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'number-column',
        render: () => {
          return (
            <BasePopover>
              <BaseButton type="text">
                <MoreOutlined rev={undefined} />
              </BaseButton>
            </BasePopover>
          )
        },
      }),
    ]
  }, [t])

  return {
    column,
  }
}

const StatusWrapper = styled.div<{ $backgroundColor?: string }>`
  background-image: ${({ $backgroundColor }) => $backgroundColor};
  padding: 6px ${PADDING.xxxs};
  width: fit-content;
  border-radius: 20px;
`
