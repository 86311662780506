import R from 'app/assets/R'
import { BasePopover } from 'app/components/common/BasePopover'
import { CategoryComponents } from 'app/components/header/components/HeaderSearch'
import { HeaderActionWrapper } from 'app/components/header/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InputSearch } from '../styles'

interface SearchOverlayProps {
  query: string
  setQuery: (query: string) => void
  data: CategoryComponents[] | null
  isOverlayOpen: boolean
  setOverlayOpen: (state: boolean) => void
}

export const SearchDropdown: React.FC<SearchOverlayProps> = ({
  query,
  setQuery,
  isOverlayOpen,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <BasePopover
        overlayClassName="search-overlay"
        open={isOverlayOpen}
      >
        <HeaderActionWrapper>
          <InputSearch
            width="100%"
            value={query}
            placeholder={t(R.strings.search)}
            onChange={event => setQuery(event.target.value)}
            enterButton={null}
            addonAfter={null}
          />
        </HeaderActionWrapper>
      </BasePopover>
    </>
  )
}
