import { BaseCol } from 'app/components/common/BaseCol'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  LAYOUT,
  PADDING,
  media,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RightSideCol = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: ${BORDER_RADIUS};
  margin-left: ${PADDING.md};
  min-width: 260px;
  @media only screen and ${media.xl} {
    height: calc(100vh - ${LAYOUT.desktop.headerHeight} - 10vh);
    overflow: auto;
  }
`

export const LeftSideCol = styled(BaseCol)`
  background-color: var(--white);
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.md} ${PADDING.xs} !important;
  min-width: 740px;
  @media only screen and ${media.xl} {
    height: calc(100vh - ${LAYOUT.desktop.headerHeight} - 10vh);
    overflow: auto;
  }
`

export const Space = styled.div`
  margin-top: ${PADDING.md};
`

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 250px;
  min-width: 1000px;
  padding: ${LAYOUT.desktop.paddingVertical} ${PADDING.md};
  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 15px;

  background: black;

  min-height: 300px;
  overflow-y: auto;
`

export const Item = styled.div`
  background: red;
  height: 150px;
  flex-shrink: 0;
`

export const HeadingTitlePage = styled.p`
  font-size: ${FONT_SIZE.xxl};
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.bold};
  padding-bottom: ${LAYOUT.desktop.headerPadding};
`

export const TitleContent = styled.div<{ $isUsingPadding?: boolean }>`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  padding-top: ${props => (props.$isUsingPadding ? PADDING.md : 0)};
`
export const BaseColRevenue = styled(BaseCol)`
  width: 10%;
`

export const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
`

export const TextUpdateTime = styled.div`
  color: rgba(0, 34, 68, 0.6);
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.medium};
`
