import { requestOrthodonticPatientWithoutPaid } from 'app/api/accounting/api/orthodontic-patient-without-paid'
import { AccountingMessageCode } from 'app/api/accounting/constant'
import {
  GroupTypeEnum,
  IParamsGetOrthodonticWithoutPaid,
  IResponseOrthodonticWithoutPaid,
  ReportTypeEnum,
} from 'app/api/accounting/model/orthodontic-patient-without-paid'
import { FORMAT_DD_MM_YYYY_HH_MM, _DEV_ } from 'app/constant'
import { isEqual } from 'lodash'
import { DateUtil, Pagination, initialPagination } from 'parkway-web-common'
import { useEffect, useState } from 'react'
import { useCommonColumn } from '../../../common-column'
import { useOrthodonticWithoutPaid } from '../../../context'
import { IDataTablePatient } from '../../../type'
interface IProps {
  id: string
}

export const useHook = (props: IProps) => {
  const { params: paramsFilter } = useOrthodonticWithoutPaid()

  const [pagination, setPagination] = useState<Pagination | undefined>(
    initialPagination,
  )

  const [total, setTotal] = useState(0)

  const [data, setData] = useState<IDataTablePatient[]>([])
  const [loading, setLoading] = useState(false)

  const getData = async () => {
    setLoading(true)
    try {
      const params: IParamsGetOrthodonticWithoutPaid = {
        page: pagination?.current,
        pageSize: pagination?.pageSize,
        id: props?.id,
        groupType: GroupTypeEnum.clinic,
        reportType: ReportTypeEnum.warning,
        ...paramsFilter,
      }

      const res: IResponseOrthodonticWithoutPaid =
        await requestOrthodonticPatientWithoutPaid(params)

      if (
        res?.msgcode &&
        isEqual(
          AccountingMessageCode?.OrthodonticWithoutPaid?.getLocationSuccess,
          res?.msgcode,
        )
      ) {
        const data = res?.data ?? []
        const metadata = res?.metadata

        const newData = data?.map((item, index) => {
          const idNumber =
            ((params.page ?? 1) - 1) * (params?.pageSize ?? 10) + (index + 1)
          return {
            key: index + 1,
            idNumber,
            appointmentDate: DateUtil.formatDate(
              item.appointmentDate ?? '',
              FORMAT_DD_MM_YYYY_HH_MM,
            ),
            assignedPhysician: item.doctorName ?? '-',
            packageLevel: item?.difficulty ?? 0,
            patientName: item?.patientName ?? '-',
            patientId: item?.patientId ?? '-',
            patientCode: item?.patientCode ?? '-',
            totalMonth: item?.totalMonth ?? 0,
          } as IDataTablePatient
        })

        setData(newData)
        setTotal(metadata?.totalItem ?? 0)
      }
    } catch (error) {
      _DEV_ && console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getData()
    }, 500)

    return () => clearTimeout(timer)
  }, [pagination, paramsFilter])

  const handleTableChange = (pagination?: Pagination) => {
    setPagination(pagination)
  }

  const { columnsPatientsOfThreeTabFirst } = useCommonColumn()

  return {
    columns: columnsPatientsOfThreeTabFirst,
    tableData: {
      pagination: {
        ...pagination,
        total,
      },
      data,
      loading,
    },
    handleTableChange,
  }
}
