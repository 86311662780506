import { Timeline } from 'antd'
import { SvgCalendarDesignIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { getArray } from 'app/components/tables/common-table/constant'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  BORDER_RADIUS,
  DateUtil,
  FORMAT_DD_MM_YYYY,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'
import { TransactionHistoryItem } from './components'

export const TransactionHistoryLayout = () => {
  return (
    <RootWrapper>
      <Timeline>
        {getArray(4).map((_, index) => {
          return (
            <Timeline.Item
              key={index}
              children={
                <BaseSpace>
                  <BaseRow gutter={[10, 10]} align={'middle'}>
                    <SvgCalendarDesignIconStyled opacity={0.7} />
                    <BaseCol>
                      <BaseText
                        children={DateUtil.formatDate(
                          new Date(),
                          FORMAT_DD_MM_YYYY,
                        )}
                        opacity="0.7"
                        fontWeight="semibold"
                      />
                    </BaseCol>
                  </BaseRow>

                  <TransactionHistoryItem />
                </BaseSpace>
              }
            />
          )
        })}
      </Timeline>
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseSpace)`
  padding: ${PADDING.xxs};
  background-color: #f9fafb; // need change
  border-radius: ${BORDER_RADIUS};
`

const SvgCalendarDesignIconStyled = styled(SvgCalendarDesignIcon)`
  path {
    fill: ${convertedVariables.primaryColor};
  }
`
