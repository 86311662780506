import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { ExamplePatient } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/constant/emergency-contact'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useState } from 'react'
import styled from 'styled-components'
import { AddContactModal, ItemEmergencyContact } from './components'

export const EmergencyContactLayout = () => {
  const [patient] = useState([ExamplePatient, ExamplePatient])
  return (
    <RootWrapper>
      <BaseRow align={'middle'} justify={'end'}>
        <AddContactModal />
      </BaseRow>

      {patient?.map((item, index) => {
        return <ItemEmergencyContact item={item} index={index} key={index} />
      })}
    </RootWrapper>
  )
}
const RootWrapper = styled(BaseSpace)`
  padding: ${PADDING.xxs};
  background-color: #f9fafb; // need change
  border-radius: ${BORDER_RADIUS};
`
