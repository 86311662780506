import { IRefModal } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useMemo, useRef, useState } from 'react'
import { IFormData, IRuleFormData } from './type'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'

export const useHook = () => {
  const { t } = useTranslation()
  const modalRef = useRef<IRefModal>({})
  const [form] = BaseForm.useForm<IFormData>()

  // list sender job title
  const [listSenderJobTitle, setListSenderJobTitle] = useState<string[]>([
    'Doctor',
    'Nurse',
  ])

  // replace sender job title by new value[]
  const replaceSenderJobTitle = (newList: string[]) => {
    setListSenderJobTitle(newList)
  }

  // delete sender job title by index
  const deleteSenderJobTitle = (index: number) => {
    const newList = listSenderJobTitle.filter((_, i) => i !== index)
    setListSenderJobTitle(newList)
  }

  // handle submit form
  const handleSubmit = (values: IFormData) => {
    // logic here
    console.log({ values })
    
    // close modal
    modalRef?.current?.hide?.()
  }

  // rule of hook
  const rules: IRuleFormData = useMemo(() => {
    return {
      department: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.department),
          }),
        },
      ],
      approveType: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.approve_type),
          }),
        },
      ],
      approverJobTitle: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.approver_job_title),
          }),
        },
      ],
    }
  }, [t])

  return {
    modalRef,
    form,
    listSenderJobTitle,
    deleteSenderJobTitle,
    replaceSenderJobTitle,
    handleSubmit,
    rules,
  }
}
