/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAppointment } from 'app/api/appointment/model/appointment'
import { ISkills } from 'app/api/profile/model/management'
import { ResponseTypeV2 } from 'app/api/report/model/common'
import { StatusTableEnum } from 'app/common/enum'
import { IDoctor } from 'app/containers/CustomerCare/AppointmentPage/SidebarLeft/DoctorList/type'
import { IService } from 'app/containers/CustomerCare/AppointmentPage/type'
import { IClinic } from 'app/model/unit.model'
export interface IIndustry {
  _id?: string
  name?: string
  skills?: ISkills[]
}

export interface ILevelBooking {
  _id?: string
  name?: string
}

export interface IRangeExperience {
  _id?: string
  name?: string
}

export interface IBookingManagement {
  _id?: string
  fullName?: string
  status?: StatusTableEnum
  phone: string
  treatments: Array<string>
  clinicId: string
  doctorId: string
  callCenterId: string
  note?: string
  doctor?: IDoctor
  clinic?: IClinic
  createdBy: string
  updatedBy: string
  createdAt: Date
  updatedAt: Date
  services?: IService[]
  recallAt?: string
  utm_source?: string
  histories?: IAppointment[]
}

export type IResponseGetBookingManagement = ResponseTypeV2<IBookingManagement[]>

export enum BookingStatusEnum {
  CREATED = 1,
  REQUEST = 2,
  CONFIRM = 3,
  DELETED = 4,
  CANCEL = 5,
  RECALL = 6,
  ASSIGNED = 7,
}

export type StatusBookingType = keyof typeof BookingStatusEnum

export interface IParamsGetBookingManagement {
  keyword?: string
  status?: BookingStatusEnum
  pageSize?: number
  page?: number
  callCenterId?: string
  isAssigned?: boolean
}

export interface IPayloadCreateBookingManagement {
  fullName?: string
  status?: BookingStatusEnum
  phone?: string
  treatments?: string
  areaId?: string
  clinicId?: string
  doctorId?: string
  date?: string
  time?: string
  callCenterId?: string
  note: string
  createdBy?: string
  updatedBy?: string
  createdAt?: Date
  updatedAt?: Date
}

export interface IPayloadUpdateBookingManagement {
  _id?: string
  fullName?: string
  status?: BookingStatusEnum
  phone?: string
  treatments?: string
  areaId?: string
  clinicId?: string
  doctorId?: string
  date?: string
  time?: string
  callCenterId?: string
  note?: string
  recallAt?: string
  createdBy?: string
  updatedBy?: string
  createdAt?: Date
  updatedAt?: Date
}

export interface IBooking {
  _id: string
  fullName?: string
  status?: BookingStatusEnum
  phone?: string
  treatments?: Array<string>
  areaId?: string
  clinicId?: string
  doctorId?: string
  date?: string
  time?: string
  note?: string
  callCenterId?: string
  createdBy?: string
  updatedBy?: string
  createdAt?: Date
  updatedAt?: Date
}

export interface IPayloadAssignMultiple {
  ids?: string[]
  callCenterId?: string
  isAssigned?: boolean
}
