import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { isEqual } from 'lodash'
import moment from 'moment'
import { SelectFilterEnum } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTableMonthlyHook } from './Monthly/hook'
import { useTableWeeklyHook } from './Weekly/hook'
import { initialPagination } from './common-model'

export const useTableHook = () => {
  const { t } = useTranslation()

  const [filterResponse, setFilterResponse] = useState<IResponseFilter>({
    year: `${moment().year()}`,
    month: `${moment().month() + 1}`,
  })

  const hookWeekly = useTableWeeklyHook({
    year: filterResponse.year,
    month: filterResponse?.month,
  })
  const hookMonthly = useTableMonthlyHook({ year: filterResponse.year })

  const expandedRowKeys = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.expandedRowKeys
      case SelectFilterEnum.MONTH:
        return hookMonthly.expandedRowKeys
      default:
        return hookWeekly.expandedRowKeys
    }
  }, [filterResponse?.selectFilterType, hookWeekly, hookMonthly])

  const tableData = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.tableData
      case SelectFilterEnum.MONTH:
        return hookMonthly.tableData
      default:
        return hookWeekly.tableData
    }
  }, [filterResponse?.selectFilterType, hookWeekly, hookMonthly])

  const handleTableChange = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.handleTableChange
      case SelectFilterEnum.MONTH:
        return hookMonthly.handleTableChange
      default:
        return hookWeekly.handleTableChange
    }
  }, [filterResponse?.selectFilterType, hookWeekly, hookMonthly])

  const handleExpand = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.handleExpand
      case SelectFilterEnum.MONTH:
        return hookMonthly.handleExpand
      default:
        return hookWeekly.handleExpand
    }
  }, [filterResponse?.selectFilterType, hookWeekly, hookMonthly])

  const handleChangeFilter = (filter: IResponseFilter) => {
    const { year, month } = filter
    setFilterResponse(filter)
    if (year && month) {
      if (
        !isEqual(
          { year, month },
          { year: filterResponse?.year, month: filterResponse?.month },
        )
      )
        hookWeekly?.fetch(initialPagination, { year, month })
    }

    if (year) {
      if (!isEqual(year, filterResponse?.year)) {
        hookMonthly?.fetch(initialPagination, { year })
      }
    }
  }

  const columns = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.columns
      case SelectFilterEnum.MONTH:
        return hookMonthly.columns
      default:
        return hookWeekly.columns
    }
  }, [t, expandedRowKeys, filterResponse])

  const exportExcel = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.exportExcel
      case SelectFilterEnum.MONTH:
        return hookMonthly.exportExcel
      default:
        return hookWeekly.exportExcel
    }
  }, [filterResponse])

  

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleChangeFilter,
    filterResponse,
    exportExcel,
  }
}
