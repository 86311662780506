interface IconProps {
  className?: string
  width?: string
  height?: string
  strokeColor?: string
  onClick?: (event: unknown) => void
}

export const StartIcon = ({ width = '20px', height = '20px' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.0006 16.5339L5.31279 18.9978C4.30269 19.5291 3.12269 18.6715 3.31519 17.5465L4.21029 12.3261L0.41759 8.62835C-0.400109 7.83205 0.0511906 6.44445 1.17999 6.27945L6.42159 5.51815L8.76549 0.76785C9.27049 -0.25595 10.7294 -0.25595 11.2344 0.76785L13.5783 5.51815L18.82 6.27945C19.9488 6.44325 20.4001 7.83075 19.5826 8.62835L15.7898 12.3261L16.6849 17.5465C16.8774 18.6716 15.6973 19.5291 14.6873 18.9978L10.0006 16.5339Z"
      fill="url(#paint0_linear_3437_42384)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3437_42384"
        x1="9.99999"
        y1="-0.00045"
        x2="9.99999"
        y2="19.1596"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE61C" />
        <stop offset={1} stopColor="#FFA929" />
      </linearGradient>
    </defs>
  </svg>
)

export const TableCloseIcon: React.FC<IconProps> = ({
  width = '20px',
  height = '20px',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <g clip-path="url(#clip0_1011_12404)">
        <rect width="20" height="20" rx="10" fill="#F3F4F5" />
        <path d="M10 13.3333L5 8.33325H15L10 13.3333Z" fill="#002244" />
      </g>
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#EBEEF2" />
    </g>
    <defs>
      <clipPath id="clip0_1011_12404">
        <rect width="20" height="20" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const TableOpenIcon: React.FC<IconProps> = ({
  width = '20px',
  height = '20px',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <g clip-path="url(#clip0_937_103045)">
        <rect width="20" height="20" rx="10" fill="white" />
        <path d="M10 6.66675L15 11.6667H5L10 6.66675Z" fill="#002244" />
      </g>
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#DADEE3" />
    </g>
    <defs>
      <clipPath id="clip0_937_103045">
        <rect width="20" height="20" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const DeleteIcon: React.FC<IconProps> = ({
  width = '20px',
  height = '20px',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g opacity="0.7">
      <path
        d="M12.2837 7.5L11.9952 15M8.00481 15L7.71635 7.5M16.023 4.82547C16.308 4.86851 16.592 4.91456 16.875 4.96358M16.023 4.82547L15.1332 16.3938C15.058 17.3707 14.2434 18.125 13.2637 18.125H6.73631C5.75655 18.125 4.94198 17.3707 4.86683 16.3938L3.97696 4.82547M16.023 4.82547C15.0677 4.6812 14.1013 4.57071 13.125 4.49527M3.125 4.96358C3.40798 4.91456 3.69198 4.86851 3.97696 4.82547M3.97696 4.82547C4.93231 4.6812 5.89874 4.57071 6.875 4.49527M13.125 4.49527V3.73182C13.125 2.74902 12.3661 1.92853 11.3838 1.8971C10.9244 1.8824 10.463 1.875 10 1.875C9.53696 1.875 9.07565 1.8824 8.61618 1.8971C7.63388 1.92853 6.875 2.74902 6.875 3.73182V4.49527M13.125 4.49527C12.0938 4.41558 11.0516 4.375 10 4.375C8.94836 4.375 7.9062 4.41558 6.875 4.49527"
        stroke="#002244"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const ResetIcon: React.FC<IconProps> = ({
  width = '20px',
  height = '20px',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_3803_202827)">
      <path
        d="M16.6663 9.16658C16.4625 7.7001 15.7822 6.3413 14.7302 5.2995C13.6782 4.25769 12.3128 3.59068 10.8444 3.40121C9.376 3.21174 7.88603 3.51032 6.60401 4.25096C5.32199 4.99159 4.31905 6.1332 3.74967 7.49992M3.33301 4.16658V7.49992H6.66634"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33301 10.8335C3.53681 12.3 4.21712 13.6588 5.26914 14.7006C6.32117 15.7424 7.68654 16.4094 9.15495 16.5989C10.6234 16.7883 12.1133 16.4898 13.3953 15.7491C14.6774 15.0085 15.6803 13.8669 16.2497 12.5002M16.6663 15.8335V12.5002H13.333"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3803_202827">
        <rect width={20} height={20} fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const UploadIcon: React.FC<IconProps> = ({
  width = '20px',
  height = '20px',
  ...props
}) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_3846_186545)">
      <path
        d="M2.66699 11.3335V12.6668C2.66699 13.0205 2.80747 13.3596 3.05752 13.6096C3.30756 13.8597 3.6467 14.0002 4.00033 14.0002H12.0003C12.3539 14.0002 12.6931 13.8597 12.9431 13.6096C13.1932 13.3596 13.3337 13.0205 13.3337 12.6668V11.3335"
        stroke="#216AE2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66699 5.99984L8.00033 2.6665L11.3337 5.99984"
        stroke="#216AE2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 2.6665V10.6665"
        stroke="#216AE2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3846_186545">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const RemoveCircleIcon: React.FC<IconProps> = ({
  width = '20px',
  height = '20px',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3337 9.99984C18.3337 14.6022 14.6027 18.3332 10.0003 18.3332C5.39795 18.3332 1.66699 14.6022 1.66699 9.99984C1.66699 5.39747 5.39795 1.6665 10.0003 1.6665C14.6027 1.6665 18.3337 5.39747 18.3337 9.99984ZM12.7993 12.7988C12.5552 13.0428 12.1595 13.0428 11.9154 12.7988L10.0003 10.8837L8.08524 12.7988C7.84116 13.0428 7.44544 13.0428 7.20136 12.7988C6.95728 12.5547 6.95728 12.159 7.20136 11.9149L9.11645 9.99979L7.20137 8.08471C6.95729 7.84063 6.95729 7.4449 7.20137 7.20082C7.44545 6.95675 7.84118 6.95675 8.08525 7.20082L10.0003 9.1159L11.9154 7.20083C12.1595 6.95675 12.5552 6.95675 12.7993 7.20083C13.0434 7.44491 13.0434 7.84064 12.7993 8.08472L10.8842 9.99979L12.7993 11.9149C13.0434 12.1589 13.0434 12.5547 12.7993 12.7988Z"
      fill="#D9DEE3"
    />
  </svg>
)

export const RemoveCircleGreyIcon: React.FC<IconProps> = ({
  width = '20px',
  height = '20px',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
    viewBox="0 0 16 16"
    fill="none"
  >
    <g opacity="0.7">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9742 11.824C11.2089 12.0586 11.5893 12.0586 11.824 11.824C12.0587 11.5893 12.0587 11.2089 11.824 10.9742L8.8498 8L11.824 5.0258C12.0587 4.79115 12.0587 4.41069 11.824 4.17603C11.5893 3.94137 11.2089 3.94137 10.9742 4.17603L8.00002 7.15022L5.02578 4.17599C4.79112 3.94134 4.41066 3.94134 4.176 4.17599C3.94134 4.41065 3.94134 4.79111 4.176 5.02577L7.15024 8L4.17599 10.9742C3.94133 11.2089 3.94134 11.5893 4.17599 11.824C4.41065 12.0587 4.79111 12.0587 5.02577 11.824L8.00002 8.84977L10.9742 11.824Z"
        fill="#858C94"
      />
    </g>
  </svg>
)

export const ThumbIcon: React.FC<IconProps> = ({
  width = '124px',
  height = '124px',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
    viewBox="0 0 125 124"
    fill="none"
  >
    <g clipPath="url(#clip0_3871_177130)">
      <path
        d="M62.5 124C96.7417 124 124.5 96.2417 124.5 62C124.5 27.7583 96.7417 0 62.5 0C28.2583 0 0.5 27.7583 0.5 62C0.5 96.2417 28.2583 124 62.5 124Z"
        fill="#ECEFF1"
      />
      <path
        d="M107.613 98.2545C104.916 96.1233 101.63 94.5888 98.0649 93.8447L81.0692 90.4347C79.2867 90.0938 78.0002 88.4972 78.0002 86.6373V82.739C79.0929 81.2045 80.1237 79.1662 81.2009 77.035C82.0379 75.3842 83.3012 72.8965 83.9289 72.2533C87.3699 68.7967 90.6947 64.914 91.7254 59.9075C92.6864 55.211 91.7409 52.7465 90.6327 50.7625C90.6327 45.8103 90.4777 39.6102 89.3074 35.0997C89.1679 28.9927 88.0597 25.5595 85.2697 22.5525C83.3012 20.4213 80.4027 19.9253 78.0699 19.53C77.1554 19.375 75.8922 19.158 75.4272 18.91C71.2964 16.678 67.2122 15.5852 62.3374 15.5C52.1307 15.9185 39.5834 22.413 35.3829 33.9915C34.0809 37.5178 34.2127 43.307 34.3212 47.957L34.2204 50.7547C33.2207 52.7077 32.2364 55.1878 33.2052 59.8997C34.2282 64.914 37.5529 68.8045 41.0559 72.3075C41.6294 72.8965 42.9237 75.4075 43.7762 77.066C44.8689 79.1895 45.9074 81.22 47.0002 82.7467V86.645C47.0002 88.4973 45.7059 90.0938 43.9157 90.4425L26.9044 93.8525C23.3627 94.6042 20.0767 96.1233 17.3874 98.2545C16.5504 98.9288 16.0234 99.913 15.9382 100.982C15.8529 102.052 16.2094 103.098 16.9302 103.896C28.5087 116.669 45.1169 124 62.5002 124C79.8834 124 96.4994 116.676 108.07 103.896C108.791 103.098 109.155 102.044 109.062 100.975C108.977 99.9053 108.45 98.921 107.613 98.2545Z"
        fill="#D9DEE3"
      />
    </g>
    <defs>
      <clipPath id="clip0_3871_177130">
        <rect
          width={124}
          height={124}
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)

export const ImageIcon: React.FC<IconProps> = ({
  width = '17',
  height = '16',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      d="M3.16602 11.3333V12.6666C3.16602 13.0202 3.30649 13.3593 3.55654 13.6094C3.80659 13.8594 4.14573 13.9999 4.49935 13.9999L12.4993 13.9999C12.853 13.9999 13.1921 13.8594 13.4422 13.6094C13.6922 13.3593 13.8327 13.0202 13.8327 12.6666V11.3333"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.16602 6.00008L8.49935 2.66675L11.8327 6.00008"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 2.66675L8.5 10.6667"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const PhoneIcon: React.FC<IconProps> = ({
  width = '20',
  height = '20',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="phone-call" clipPath="url(#clip0_4344_26735)">
      <path
        id="Vector"
        d="M4.16667 3.33203H7.5L9.16667 7.4987L7.08333 8.7487C7.9758 10.5583 9.44039 12.0229 11.25 12.9154L12.5 10.832L16.6667 12.4987V15.832C16.6667 16.2741 16.4911 16.698 16.1785 17.0105C15.866 17.3231 15.442 17.4987 15 17.4987C11.7494 17.3012 8.68346 15.9208 6.38069 13.618C4.07792 11.3152 2.69754 8.24931 2.5 4.9987C2.5 4.55667 2.67559 4.13275 2.98816 3.82019C3.30072 3.50763 3.72464 3.33203 4.16667 3.33203Z"
        stroke="#216AE2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M12.5 5.83203C12.942 5.83203 13.366 6.00763 13.6785 6.32019C13.9911 6.63275 14.1667 7.05667 14.1667 7.4987"
        stroke="#216AE2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M12.5 2.5C13.8261 2.5 15.0979 3.02678 16.0355 3.96447C16.9732 4.90215 17.5 6.17392 17.5 7.5"
        stroke="#216AE2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4344_26735">
        <rect width={20} height={20} fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const CallingIcon: React.FC<IconProps> = ({
  width = '24',
  height = '24',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_1726_77986)">
      <path
        d="M5 4H9L11 9L8.5 10.5C9.57096 12.6715 11.3285 14.429 13.5 15.5L15 13L20 15V19C20 19.5304 19.7893 20.0391 19.4142 20.4142C19.0391 20.7893 18.5304 21 18 21C14.0993 20.763 10.4202 19.1065 7.65683 16.3432C4.8935 13.5798 3.23705 9.90074 3 6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4Z"
        stroke="#28BA59"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 7V7.01"
        stroke="#28BA59"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 7V7.01"
        stroke="#28BA59"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 7V7.01"
        stroke="#28BA59"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1726_77986">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const EditIcon: React.FC<IconProps> = ({
  width = '24',
  height = '24',
  strokeColor = '#216AE2',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <g clipPath="url(#clip0_4405_146366)">
      <path
        d="M4.66699 4.66797H4.00033C3.6467 4.66797 3.30756 4.80844 3.05752 5.05849C2.80747 5.30854 2.66699 5.64768 2.66699 6.0013V12.0013C2.66699 12.3549 2.80747 12.6941 3.05752 12.9441C3.30756 13.1942 3.6467 13.3346 4.00033 13.3346H10.0003C10.3539 13.3346 10.6931 13.1942 10.9431 12.9441C11.1932 12.6941 11.3337 12.3549 11.3337 12.0013V11.3346"
        stroke={strokeColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.59 4.39007C13.8526 4.12751 14.0001 3.77139 14.0001 3.40007C14.0001 3.02875 13.8526 2.67264 13.59 2.41007C13.3274 2.14751 12.9713 2 12.6 2C12.2287 2 11.8726 2.14751 11.61 2.41007L6 8.00007V10.0001H8L13.59 4.39007Z"
        stroke={strokeColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.667 3.33203L12.667 5.33203"
        stroke={strokeColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4405_146366">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
)
