import { Pagination } from 'parkway-web-common'
import { IGeneralReport05Table } from '../common-model'

export const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
}

export interface IDataReport05Weekly
  extends IGeneralReport05Table<IDataReport05Weekly> {
  week1?: number
  week2?: number
  week3?: number
  week4?: number
  week5?: number
  week6?: number
  totalRevenueMonth?: number
  week1Total?: number
  week2Total?: number
  week3Total?: number
  week4Total?: number
  week5Total?: number
  week6Total?: number
  totalRevenueMonthTotal?: number
}

export interface IDataReportTable05 {
  data: IDataReport05Weekly[]
  pagination: Pagination
}

export const getTreeTableData = (
  pagination: Pagination,
): Promise<IDataReportTable05> => {
  return new Promise(res => {
    setTimeout(() => {
      res({
        data: [],
        pagination: { ...pagination, total: 8 },
      })
    }, 1000)
  })
}
