import R from 'app/assets/R'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { useModalHook } from './hook'
import { IPropsDeleteWarehouse } from './type'
import { BaseCol } from 'app/components/common/BaseCol'
import * as S from './styles'
import { DeleteOutlined } from '@ant-design/icons'

export const Delete = (props: IPropsDeleteWarehouse) => {
  const { visible, isLoading, showModal, handleCancel, handleSubmit, t } =
    useModalHook(props)

  return (
    <BaseModalManagement
      isLoading={isLoading}
      typeButtonOpen="delete"
      titleKeyI18n={R.strings.delete}
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.delete}
      confirmKeyI18n={R.strings.delete}
      handleSubmit={handleSubmit}
      visible={visible}
      widthModal={500}
      iconLeft={<DeleteOutlined rev={true} />}
      descriptionDelete={
        <>
          <BaseCol>
            <span>
              {t(R.strings.provider_delete)}{' '}
              <S.TextProvider>{props?.name ?? ''}</S.TextProvider>
            </span>
          </BaseCol>
        </>
      }
    />
  )
}
