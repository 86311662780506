import { IOrganizationalChart, IProfileOrganization } from 'app/api/company/organizational-chart/model'

export interface IMonthlyRoster extends IOrganizationalChart {
  key: number
}

export interface IFilterMonthlyRosterReport {
  year?: string
  month?: string
  jobTitleId?: string
}

export enum DateProfileMonthlyRosterTypeEnum {
  F = 'F',
  E = 'E',
  L = 'L',
  DO = 'DO',
  PH = 'PH',
  Alc = 'Alc',
  AL = 'AL',
  HL = 'HL',
  SL = 'SL',
  PL = 'PL',
  UL = 'UL',
}

export const ListDateProfileMonthlyRosterTypeEnum = Object.values(DateProfileMonthlyRosterTypeEnum);

export interface IDateProfileMonthlyRoster {
  date?: number
  type?: DateProfileMonthlyRosterTypeEnum
}


export interface IProfileMonthlyRoster extends IProfileOrganization {
  key: number

  startAndEndDate?: string
  F?: number
  E?: number
  L?: number
  DO?: number
  PH?: number
  AlcYear?: number
  AL?: number
  HL?: number
  SL?: number
  PL?: number
  UL?: number
  actualDate?: number;
  dateCalculateSalary?: number

  dates?: IDateProfileMonthlyRoster[]
}