import { requestGetTotalWithoutPaid } from 'app/api/accounting/api/orthodontic-without-paid'
import { AccountingMessageCode } from 'app/api/accounting/constant'
import { IParamsGetLocationsWithoutPaid } from 'app/api/accounting/model/orthodontic-without-paid'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useQuery } from 'react-query'
import { WithoutPaidKeys } from '../query-key/without-paid'
import { requestGetTotalPatientWithoutPaid } from 'app/api/accounting/api/orthodontic-patient-without-paid'
import { IParamsGetTotalWithoutPaid } from 'app/api/accounting/model/orthodontic-patient-without-paid'

export interface IResponseGetTotalWithoutPaid {
  totalDoctor: number
  totalLocation: number
  totalPatient: number
}

export function useGetTotalWithoutPaid(
  params: IParamsGetLocationsWithoutPaid,
  enabled = true,
) {
  const token = getToken()
  return useQuery<IResponseGetTotalWithoutPaid, IParamsGetLocationsWithoutPaid>(
    WithoutPaidKeys.generalInfo(params),
    async () => {
      try {
        const res: ResponseType<IResponseGetTotalWithoutPaid> =
          await requestGetTotalWithoutPaid(params)

        if (
          isEqual(
            res?.msgcode,
            AccountingMessageCode.OrthodonticWithoutPaid.getSuccess,
          )
        ) {
          const data: IResponseGetTotalWithoutPaid = res?.data
          return data
        }
        return { totalDoctor: 0, totalLocation: 0, totalPatient: 0 }
      } catch (error) {
        return { totalDoctor: 0, totalLocation: 0, totalPatient: 0 }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export interface IResponseGetTotalPatientWithoutPaid {
  totalWithoutPaid?: number
  totalNeedReview?: number
  totalWarning?: number
  totalDoctorNeedWarning?: number
  totalClinicNeedWarning?: number
}

export function useGetTotalPatientWithoutPaid(
  params: IParamsGetTotalWithoutPaid,
  enabled = true,
) {
  const token = getToken()
  return useQuery<
    IResponseGetTotalPatientWithoutPaid,
    IParamsGetTotalWithoutPaid
  >(
    WithoutPaidKeys.patientInfo(params),
    async () => {
      try {
        const res: ResponseType<IResponseGetTotalPatientWithoutPaid> =
          await requestGetTotalPatientWithoutPaid(params)

        if (
          isEqual(
            res?.msgcode,
            AccountingMessageCode.OrthodonticWithoutPaid.getSuccess,
          )
        ) {
          const data: IResponseGetTotalPatientWithoutPaid = res?.data
          return data
        }
        return {
          totalClinicNeedWarning: 0,
          totalDoctorNeedWarning: 0,
          totalNeedReview: 0,
          totalWarning: 0,
          totalWithoutPaid: 0,
        }
      } catch (error) {
        return {
          totalClinicNeedWarning: 0,
          totalDoctorNeedWarning: 0,
          totalNeedReview: 0,
          totalWarning: 0,
          totalWithoutPaid: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
      cacheTime: 10000
    },
  )
}
