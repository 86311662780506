import R from 'app/assets/R'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useModalHook } from './hook'
import * as S from './styles'
import { StatusTableEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'

export const initValues = {
  name: '',
}

export type StatusSupplierType = keyof typeof StatusTableEnum

export interface IPropsCreateSupplier {
  fetchTable: () => void
  parentId?: string
}

export const Create = (props: IPropsCreateSupplier) => {
  const {
    form,
    visible,
    t,
    showModal,
    handleSubmit,
    handleCancel,
    isLoading,
    rules,
  } = useModalHook(props)

  return (
    <BaseModalManagement
      typeButtonOpen={props.parentId ? 'update' : 'add'}
      titleKeyI18n={R.strings.provider_add}
      isLoading={isLoading}
      content={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <BaseRow gutter={20} align={'top'} justify={'space-between'}>
            <BaseCol xl={12}>
              <S.FormItem
                name="code"
                label={t(R.strings.supplier_code)}
                rules={rules.code}
                required
              >
                <S.FormInput
                  placeholder={t(R.strings.enter_field, {
                    field: t(R.strings.supplier_code),
                  })}
                />
              </S.FormItem>
            </BaseCol>
            <BaseCol xl={12}>
              <S.FormItem
                name="oldId"
                label={t(R.strings.old_supplier_code)}
              >
                <S.FormInput
                  placeholder={t(R.strings.enter_field, {
                    field: t(R.strings.old_supplier_code),
                  })}
                />
              </S.FormItem>
            </BaseCol>
          </BaseRow>
    

          <S.FormItem
            name="name"
            label={t(R.strings.supplier_name)}
            rules={rules.name}
            required
          >
            <S.FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.supplier_name),
              })}
            />
          </S.FormItem>


          <S.FormItem
            name="address"
            label={t(R.strings.address)}
            rules={rules.address}
            required
          >
            <S.FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.address),
              })}
            />
          </S.FormItem>


          <BaseRow gutter={20} align={'top'} justify={'space-between'}>
            <BaseCol xl={12}>
              <S.FormItem
                name="bankAccount"
                required
                label={t(R.strings.bank_account)}
                rules={rules.bankAccount}
              >
                <S.FormInput
                  placeholder={t(R.strings.enter_field, {
                    field: t(R.strings.bank_account),
                  })}
                />
              </S.FormItem>
            </BaseCol>
            <BaseCol xl={12}>
              <S.FormItem
                name="bankName"
                label={t(R.strings.bank_name)}
                rules={rules.bankName}
                required
              >
                <S.FormInput
                  placeholder={t(R.strings.enter_field, {
                    field: t(R.strings.bank_name),
                  })}
                />
              </S.FormItem>
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={20} align={'top'} justify={'space-between'}>
            <BaseCol xl={12}>
              <S.FormItem
                name="taxIdNumber"
                required
                label={t(R.strings.TIN)}
                rules={rules.taxIdNumber}
              >
                <S.FormInput
                  placeholder={t(R.strings.enter_field, {
                    field: t(R.strings.TIN),
                  })}
                />
              </S.FormItem>
            </BaseCol>
            <BaseCol xl={12}>
              <S.FormItem
                name="phone"
                label={t(R.strings.phone)}
                rules={rules.phone}
                required
              >
                <S.FormInput
                  placeholder={t(R.strings.enter_field, {
                    field: t(R.strings.phone),
                  })}
                />
              </S.FormItem>
            </BaseCol>
          </BaseRow>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.provider_add}
      confirmKeyI18n={R.strings.provider_add}
      handleSubmit={form.submit}
      visible={visible}
    />
  )
}
