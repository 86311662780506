// Message code define
export const UnitTypeMessageCode = {
  UnitTypeManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20001',
  },
}

// Endpoint
export const UnitTypeEndPoint = {
  UnitTypeManagement: {
    request: 'hr/v1/unittype',
  },
}
