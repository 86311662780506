/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetPNL } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { IParamsGetPNL, IResponseGetPNL } from 'app/api/report/model/report-PNL'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import {
  IDataBaseTable,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import { convertGeneralPNLValue, useCommonHook } from '../common-hook'
import { initialPagination } from '../common-model'
import { IDataPNLMonthly } from './data'

export const useTableMonthlyHook = (props: IParamsHookReport) => {
  const getDataMonthly = async (
    pagination: Pagination,
    params: IParamsGetPNL,
  ) => {
    try {
      const response: IResponseGetPNL = await requestGetPNL({
        periodtype: 'month',
        year: props?.year,
        month: props?.month,
        ...params,
      })
      if (isEqual(response?.msgcode, ReportMessageCode.PNL.successGetData)) {
        const data = response?.data?.rows ?? []
        const dataTable: IDataPNLMonthly[] =
          data?.map((item, index) => {
            const newResponse: IDataPNLMonthly = {
              key: index + 1,
              firstNumber: index,
              isTotalGroupTable: !index,
              clinic: item?.clinicName,
              index: index + 1,
              ...convertGeneralPNLValue(item).childs,
            }
            return newResponse
          }) ?? []

        return {
          pagination: initialPagination,
          data: dataTable,
        } as IDataBaseTable<IDataPNLMonthly>
      }
      return {
        pagination: initialPagination,
        data: [],
      } as IDataBaseTable<IDataPNLMonthly>
    } catch (error) {
      return {
        pagination: initialPagination,
        data: [],
      } as IDataBaseTable<IDataPNLMonthly>
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,
    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({ getTreeTableData: getDataMonthly })

  const { columns, exportExcel } = useCommonHook({
    expandedRowKeys,
    typeHook: 'month',
    fetchLoading,
    currentFilter: props,
  })

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleResetExpand,
    fetch,
    fetchLoading,
    exportExcel
  }
}
