/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetReport09 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import {
  IDataBaseTable,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { initialPagination } from 'parkway-web-common'
import { getGender, getQuarterOfYear } from 'parkway-web-common'
import { isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import {
  useCommonHook,
  convertDataToTable
} from '../common-hook'
import { IDataReport09Quarter } from './data'
import {
  IParamsGetReport09,
  IResponseGetReport09,
} from 'app/api/report/model/report-09'
import { SelectQuarterEnum } from 'parkway-web-common'

export const useTableQuarterHook = (props: IParamsHookReport) => {
  const { t } = useTranslation()
  const getDataWeek = async (
    pagination: Pagination,
    params: IParamsGetReport09,
  ) => {
    try {
      const quarterParams = getQuarterOfYear({ year: props.year })[
        props?.quarter ?? SelectQuarterEnum?.QUARTER_1
      ]
      const response: IResponseGetReport09 = await requestGetReport09({
        year: props?.year,
        startDate: quarterParams?.start,
        endDate: quarterParams?.end,
        ...params,
      })

      if (
        isEqual(response?.msgcode, ReportMessageCode.Report09.successGetData)
      ) {
        const data = response?.data?.rows ?? []
        let dataTable: IDataReport09Quarter[] = []
        data?.forEach((gender, index) => {
          const [firstTypeAge, ...otherTypeAge] = gender?.typeAges ?? []
          const [firstAgeGroupOfTypeAge, ...otherAgeGroupOfTypeAge] =
            firstTypeAge?.ageGroups ?? []

          const dataOfFirstAgeGroup = convertDataToTable(firstAgeGroupOfTypeAge?.products ?? [])

          let newResponse: IDataReport09Quarter = {
            key: index + 1,
            gender: t(getGender(gender?.gender)),
            age: firstAgeGroupOfTypeAge?.ageGroup,
            typeAge: firstTypeAge?.typeAge,
            numOfChild: otherAgeGroupOfTypeAge?.length + 1,
            ...dataOfFirstAgeGroup
          }

          let children: IDataReport09Quarter[] = []
          children = children.concat(
            otherAgeGroupOfTypeAge?.map((item, idx) => {
              return {
                key: Number(`${index + 1}${idx + 1}`),
                age: item?.ageGroup,
                ...convertDataToTable(item?.products ?? []),
              }
            }),
          )

          otherTypeAge?.forEach(item => {
            const [firstItem, ...otherItem] = item?.ageGroups ?? []

            children = children?.concat([
              {
                key: Number(`${index + 1}${children?.length + 1}`),
                age: firstItem?.ageGroup,
                typeAge: item?.typeAge,
                numOfChild: otherItem?.length + 1,
                ...convertDataToTable(firstItem?.products ?? []),
              },
            ])
            children = children.concat(
              otherItem?.map((item, idx) => {
                return {
                  key: Number(`${index + 1}${idx + children?.length + 1}`),
                  age: item?.ageGroup,
                  ...convertDataToTable(item?.products ?? []),
                }
              }),
            )
          })

          newResponse = {
            ...newResponse,
            children: children?.map((item, idx) => ({
              ...item,
              index: idx + 1,
            })),
          }

          const totalItem: IDataReport09Quarter = {
            key: Number(`${index + 1}${children?.length + 2}`),
            gender: 'Tổng',
            isTotalGroupTable: true,
            ...convertDataToTable(gender?.products ?? []),
          }

          dataTable = dataTable.concat([newResponse, totalItem])
        })

        const metadata = response?.data?.metadata
        return {
          pagination: pagination ?? initialPagination,
          data: [
            ...dataTable,
            {
              key: Number(`${dataTable.length + 1}`),
              gender: 'Tổng',
              isTotalTable: true,
              ...convertDataToTable(metadata?.products ?? []),
            },
          ],
        } as IDataBaseTable<IDataReport09Quarter>
      }
      return {
        pagination: pagination ?? initialPagination,
        data: [],
      } as IDataBaseTable<IDataReport09Quarter>
    } catch (error) {
      return {
        pagination: pagination ?? initialPagination,
        data: [],
      } as IDataBaseTable<IDataReport09Quarter>
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,
    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({
    getTreeTableData: getDataWeek,
    isExpandedWhenStart: true,
  })

  const { columns, exportExcel } = useCommonHook({
    expandedRowKeys,
    typeHook: 'week',
    currentFilter: props,
    fetchLoading
  })

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleResetExpand,
    fetch,
    fetchLoading,
    exportExcel
  }
}
