/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetCostPlan } from 'app/api/accounting'
import { AccountingMessageCode } from 'app/api/accounting/constant'
import {
  IParamsGetCostPlan,
  IResponseGetCostPlan,
} from 'app/api/accounting/model'
import R from 'app/assets/R'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import {
  IDataBaseTable,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { initPagination } from 'app/constant'
import { isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import { convertGeneralCostPlanValue, useCommonHook } from '../common-hook'
import { IDataCostPlanMonthly } from './data'

export const useTableMonthlyHook = (props: IParamsHookReport) => {
  const { t } = useTranslation()

  const getDataMonthly = async (
    pagination: Pagination,
    params: IParamsGetCostPlan,
  ) => {
    const response: IResponseGetCostPlan = await requestGetCostPlan({
      periodtype: 'month',
      year: props?.year,
      ...params,
    })
    if (
      isEqual(response?.msgcode, AccountingMessageCode.CostPlan.successGetData)
    ) {
      const data = response?.data?.rows ?? []
      const dataTable: IDataCostPlanMonthly[] =
        data?.map((item, index) => {
          const [firstMonth, ...otherMonths] = item?.months ?? []

          const totalDataClinic = convertGeneralCostPlanValue(item).total
          const dataFirstWeek = convertGeneralCostPlanValue(firstMonth).childs

          const newResponse: IDataCostPlanMonthly = {
            key: index + 1,
            clinic: item?.clinicName,
            month: `${t(R.strings.month)} ${firstMonth.month}`,
            ...totalDataClinic,
            ...dataFirstWeek,

            children: otherMonths?.map((itm, idx: number) => {
              const dataOfMonth = convertGeneralCostPlanValue(itm)?.childs

              return {
                key: Number(`${(index + 1) * 10}${idx + 1}`),
                month: `${t(R.strings.month)} ${itm?.month}`,
                index: idx + 1,
                ...dataOfMonth,
              }
            }),
          }
          return newResponse
        }) ?? []

      return {
        pagination: initPagination,
        data: dataTable,
      } as IDataBaseTable<IDataCostPlanMonthly>
    }
    return {
      pagination: initPagination,
      data: [],
    } as IDataBaseTable<IDataCostPlanMonthly>
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,

    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({ getTreeTableData: getDataMonthly })

  const { columns, onClickImport } = useCommonHook({
    expandedRowKeys,
    fetch,
    fetchLoading,
    handleExpand,
    typeHook: 'month',
    currentFilter: props,
  })

  const _onClickImport = async (file: any) => {
    onClickImport(file, 'month')
  }

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleResetExpand,
    fetch,
    fetchLoading,
    onClickImport: _onClickImport,
  }
}
