import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

import {
  CustomTableManagement,
  InputText,
  OrderInfoContent,
  OrderInfoFooter,
  OrderInfoHeader,
  OrderInfoTitle,
  TotalNetAmount,
  TotalPage,
} from './styles'
import { PurchaseOrderStatusEnum, StatusTableEnum } from 'app/common/enum'
import { useHook } from './hook'
import { BaseRow } from 'app/components/common/BaseRow'
import { SvgDownloadIcon, SvgFileImportIcon } from 'app/assets/svg-assets'
import { useContext, useState } from 'react'
import { Flex, Upload, UploadProps } from 'antd'
import { BaseButton } from 'app/components/common/BaseButton'
import { CreateGoods } from '../CreateGoods'
import { WarehousePurchaseContext } from 'app/containers/InventoryManagement/WarehousePurchase/context'
import { formatMoney } from 'parkway-web-common'
import { reduce } from 'lodash'
import { notificationController } from 'app/controllers/notification-controller'

export type StatusWarehousePurchaseType = keyof typeof StatusTableEnum

const OrderInfo = () => {
  const [importLoading, setImportLoading] = useState(false)

  const { columns, downloadTemplate, upload } = useHook()
  const [isDownloading, setIsDownloading] = useState(false)

  const { orders, discount, setDiscount, warehousePurchaseDetail, setOrders } =
    useContext(WarehousePurchaseContext)
  const { t } = useTranslation()
  const tableData = orders?.map((order, index) => ({
    ...order,
    ordinalNumber: index + 1,
  }))

  const orderTotalAmount = reduce(
    orders,
    (prev, curr) =>
      (prev += Number(curr?.quantity || 0) * Number(curr?.price || 0)),
    0,
  )
  const totalNetAmount = formatMoney(
    orderTotalAmount - (orderTotalAmount * Number(discount)) / 100,
  )

  const handleDownloadTemplate = async () => {
    setIsDownloading(true)
    await downloadTemplate(warehousePurchaseDetail?._id)
    setIsDownloading(false)
  }

  const handleUpload = res => {
    const importOrders = (res?.data || [])?.map(order => ({
      ...order,
      name: order?.product?.name,
      code: order?.product?.code,
      purchaseMeasurementUnit: order?.measurementUnit?.name,
    }))
    const importOrderIds = importOrders?.map(order => order?._id) || []
    const orderIds = orders?.map(order => order?._id) || []
    if (
      orderIds?.length &&
      orderIds.every(orderId => importOrderIds?.includes(orderId))
    ) {
      notificationController.error({
        message: t(R.strings.import_error),
        description: t(R.strings.goods_existed),
      })
    } else {
      setOrders?.(prev => [...prev, ...importOrders])
    }
  }

  const uploadProps: UploadProps = {
    showUploadList: false,
    customRequest: options =>
      upload(options, warehousePurchaseDetail?._id, handleUpload),
    onChange(info) {
      if (info.file.status === 'uploading') {
        setImportLoading(true)
        return
      }

      if (info.file.status === 'done') {
        console.log('info', info)
        setImportLoading(false)
      } else if (info.file.status === 'error') {
        setImportLoading(false)
        notificationController.error({
          message: t(R.strings.import_error),
        })
      }
    },
  }

  const isDisabled = !!(
    warehousePurchaseDetail?._id &&
    PurchaseOrderStatusEnum.DRAFT !== Number(warehousePurchaseDetail?.status)
  )

  return (
    <>
      <OrderInfoHeader>
        <Flex justify="space-between" align="center">
          <OrderInfoTitle>{t(R.strings.order_info)}</OrderInfoTitle>

          <Flex justify="space-between" align="center" gap={10}>
            {!!warehousePurchaseDetail?._id && (
              <div onClick={() => handleDownloadTemplate()}>
                <BaseButton icon={<SvgDownloadIcon />} loading={isDownloading}>
                  {t(R.strings.sample_file)}
                </BaseButton>
              </div>
            )}

            {!isDisabled && (
              <div>
                <Upload {...uploadProps}>
                  <BaseButton
                    icon={<SvgFileImportIcon />}
                    loading={importLoading}
                  >
                    {t(R.strings.import)}
                  </BaseButton>
                </Upload>
              </div>
            )}

            {!isDisabled && (
              <div>
                <CreateGoods />
              </div>
            )}
          </Flex>
        </Flex>
      </OrderInfoHeader>

      <OrderInfoContent>
        <CustomTableManagement
          columns={columns}
          dataSource={tableData}
          pagination={{
            pageSize: 100,
            total: tableData?.length,
            showTotal: total => {
              return (
                <TotalPage>
                  {t(R.strings.total_page, {
                    total,
                  })}
                </TotalPage>
              )
            },
          }}
          expandable={{
            expandIcon: () => null,
          }}
        />
      </OrderInfoContent>

      <OrderInfoFooter>
        <div>
          <BaseRow justify="space-between" align="middle">
            <div>{t(R.strings.order_total)}</div>
            <div>{formatMoney(orderTotalAmount)}</div>
          </BaseRow>

          <BaseRow justify="space-between" align="middle">
            <div>{t(R.strings.order_discount)}</div>
            <InputText
              addonAfter="(%)"
              name="discount"
              onChange={e => setDiscount?.(Number(e?.target?.value))}
              value={discount}
              disabled={isDisabled}
            />
          </BaseRow>

          <BaseRow justify="space-between" align="middle">
            <div>
              <b>{t(R.strings.total_amount)}</b>
            </div>
            <TotalNetAmount>
              <div>
                <b>{totalNetAmount}</b>
              </div>
            </TotalNetAmount>
          </BaseRow>
        </div>
      </OrderInfoFooter>
    </>
  )
}

export default OrderInfo
