import { DiscountTypeEnum } from 'app/api/marketing/discount-campaign/model'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { IBillDiscountInTable } from '../type'

export const useTotalBillTypeHook = () => {
  const [listTotalBill, setListTotalBill] = useState<IBillDiscountInTable[]>([])

  const onChangeDiscountValueTotalBill = (value: number, id?: string) => {
    const newListTotalBill = listTotalBill.map(item => {
      if (isEqual(item?._id, id)) {
        return {
          ...item,
          discount: value,
        }
      }
      return item
    })
    setListTotalBill(newListTotalBill)
  }

  const onChangeDiscountTypeTotalBill = (
    value: DiscountTypeEnum,
    id?: string,
  ) => {
    const newListTotalBill = listTotalBill.map(item => {
      if (isEqual(item?._id, id)) {
        return {
          ...item,
          dType: value,
        }
      }
      return item
    })
    setListTotalBill(newListTotalBill)
  }

  const onChangeTotalBill = (service: IBillDiscountInTable[]) => {
    setListTotalBill(service)
  }

  const onDeleteTotalBill = (id?: string) => {
    const newListTotalBill = listTotalBill.filter(
      item => !isEqual(item?._id, id),
    )
    setListTotalBill(newListTotalBill)
  }

  const onAddTotalBill = (bill: IBillDiscountInTable) => {
    setListTotalBill([...listTotalBill, bill])
  }

  const onInitTotalBill = (data: IBillDiscountInTable[]) => {
    setListTotalBill(data)
  }

  return {
    onChangeTotalBill,
    listTotalBill,
    onChangeDiscountTypeTotalBill,
    onChangeDiscountValueTotalBill,
    onDeleteTotalBill,
    onAddTotalBill,
    onInitTotalBill,
  }
}
