import { requestGetRevenueClinicChart } from 'app/api/dashboard'
import { DashboardMessageCode } from 'app/api/dashboard/constant'
import { ResponseClinicRevenue } from 'app/api/dashboard/model'
import R from 'app/assets/R'
import { Loading } from 'app/components/common/Loading'
import { BaseChart } from 'app/components/common/charts/BaseChart'
import { lightColorsTheme } from 'app/styles/themes/light/lightTheme'
import { EChartsOption } from 'echarts-for-react'
import { isEqual } from 'lodash'
import {
  DateUtil,
  FONT_SIZE,
  FONT_WEIGHT,
  FORMAT_CENTER_YYYY_MM_DD,
  colors,
  defaultParamList,
  formatMoney,
} from 'parkway-web-common'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatNumberMoney } from './data'

export interface ISeriesData {
  color?: string
  seriesName?: string
  value?: string
}

export interface IDataChart {
  branchNames?: string[]
  revenuePending?: number[]
  revenueActual?: number[]
  totalRevenue?: number[]
}

export const StackedBarChart: React.FC = () => {
  const { t } = useTranslation()

  const [isLoadingData, setIsLoadingData] = useState(false)

  const [dataChart, setDataChart] = useState<IDataChart>({
    branchNames: [],
    revenueActual: [],
    revenuePending: [],
    totalRevenue: [],
  })

  const getData = async () => {
    try {
      setIsLoadingData(true)
      const response: ResponseClinicRevenue =
        await requestGetRevenueClinicChart({
          ...defaultParamList,
          date: DateUtil.formatDate(new Date(), FORMAT_CENTER_YYYY_MM_DD),
        })
      if (
        isEqual(
          response.msgcode,
          DashboardMessageCode.RevenueToday.successGetData,
        )
      ) {
        const data = response.data.rows

        const branchNames =
          data?.map(item => item.clinicName?.replace('Parkway', '') ?? '') ?? []
        const revenuePending =
          data?.map(item => item?.totalProjected ?? 0) ?? []
        const revenueActual = data?.map(item => item?.totalActual ?? 0) ?? []
        const totalRevenue = data?.map(item => item?.totalAmount ?? 0) ?? []

        setDataChart({
          branchNames,
          revenuePending,
          revenueActual,
          totalRevenue,
        })
      }
      setIsLoadingData(false)
    } catch (error) {
      setIsLoadingData(false)
      // console.log({ error }, '----------------')
    }
  }

  useEffect(() => {
    getData()

    return () => {
      setDataChart({})
    };
  }, [])

  const data = useMemo(() => {
    return {
      categories: dataChart.branchNames,
      series: [
        {
          name: t(R.strings.today_revenue),
          data: dataChart.totalRevenue,
          color: lightColorsTheme.statesRedColor,
        },
        {
          name: t(R.strings.actual_revenue),
          data: dataChart.revenueActual,
          color: lightColorsTheme.statesOrange,
        },
        {
          name: t(R.strings.pending_revenue),
          data: dataChart.revenuePending,
          color: lightColorsTheme.otherBlue,
        },
      ],
    }
  }, [dataChart])


  const itemToolTip = (item: ISeriesData) => {
    const containerStyle =
      'display:flex; flex-direction: row !important; align-items: center !important; justify-content: space-between !important;'
    const pointStyle = `border-radius:10px;width:10px;height:10px;background-color: ${item?.color};`
    const nameStyle = `margin-left: 10px; color: ${colors.primary}`
    const valueStyle = `margin-left: 10px; color: ${item?.color}; font-weight: ${FONT_WEIGHT.semibold}`
    const point = `<span style="${pointStyle}"></span>`
    const name = `<span style="${nameStyle}">${item?.seriesName ?? '-'}</span>`
    const value = `<span style="${valueStyle}">${formatMoney(
      item?.value ?? 0,
    )}</span>`
    return `<div style="${containerStyle}">
      <div style="${containerStyle}">
      ${point}
      ${name}
      </div>
      ${value}
    </div>`
  }

  const formatToolTip = (items: ISeriesData[]) => {
    const root = 'width: fit-content'
    return `<div style="${root}">
      ${itemToolTip(items?.[0])}
      ${itemToolTip(items?.[1])}
      ${itemToolTip(items?.[2])}
    </div>`
  }

  const getOption: EChartsOption = useMemo(() => {
    return {
      baseOption: {
        title: {
          textAlign: 'left',
          left: '5%',
        },
        tooltip: {
          formatter: formatToolTip,
        },
        legend: {
          data: data.series.map(item => item.name),
          itemGap: 35,
          itemHeight: 5,
          itemWidth: 5,
          right: '0%',
          top: 30,
          borderRadius: 10,
        },
        calculable: true,
        grid: {
          top: 100,
          bottom: 30,
          left: 50,
          right: 30,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'none',
              snap: true,
              label: {
                show: true,
                formatter: params => {
                  const xLabel = params?.value
                  return `${xLabel}`
                },
                color: colors.secondaryText,
                backgroundColor: colors.white,
                fontSize: FONT_SIZE.xxxs,
              },
            },
          },
        },
        xAxis: [
          {
            axisLabel: {
              interval: 0,
              rotate: 0,
              textStyle: {
                baseline: 'top',
                color: colors.primary,
                fontSize: FONT_SIZE.xxxs,
                fontWeight: FONT_WEIGHT.regular,
              },
            },
            data: data.categories,
            type: 'category',
            axisLine: { show: false },
            axisTick: { show: false },
          },
        ],
        yAxis: [
          {
            axisLabel: {
              textStyle: { fontSize: 10 },
              formatter: (value: number) => {
                return formatNumberMoney(value)
              },
            },
            axisLine: { show: false },
            axisTick: { show: false },
            name: 'Số tiền',
            type: 'value',
          },
        ],
        series: data.series.map(item => ({
          name: item.name,
          type: 'bar',
          data: item.data,
          barWidth: 28,
          color: item?.color,
          animationDelay: (idx: number) => idx * 10,
          barGap: '10%',
          barCategoryGap: '20%',
        })),
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 35,
            show: false,
            zoomLock: true,
          },
        ],
      },
    }
  }, [data, FONT_SIZE, FONT_WEIGHT, colors])

  return (
    <div>
      <BaseStackedChartDashboard option={getOption} />
      {isLoadingData && <Loading size="large" isAbsolute />}
    </div>
  )
}

const BaseStackedChartDashboard = styled(BaseChart)`
  width: 100%;
`
