export enum OralHygieneToolsYouUsuallyUseEnum {
  RegularToothbrush = '1',
  ElectricToothbrush = '2',
  DentalFloss = '3',
  Toothpick = '4',
  InterdentalBrush = '5',
  TongueScraper = '6',
  Mouthwash = '7',
  WaterFlosser = '8',
}

export interface IDentalHealthHistory {
  createAt?: string
  createBy?: {
    name?: string
    jobTitle?: string
  }
  address?: string
  do_you_have_any_areas_where_food_tends_to_get_stuck_or_impacted?: boolean
  do_you_have_any_areas_that_need_to_be_re_filled_or_do_you_have_any_old_fillings_that_need_to_be_checked?: boolean
  do_your_gums_bleed_when_you_brush_or_floss?: boolean
  have_you_ever_had_gingivitis_or_periodontitis?: boolean
  when_was_your_last_dental_cleaning?: boolean
  do_you_experience_any_unpleasant_taste_in_your_mouth_or_unpleasant_bad_breath?: boolean
  how_many_times_a_day_do_you_usually_brush_your_teeth?: boolean
  oral_hygiene_tools_you_usually_use?: OralHygieneToolsYouUsuallyUseEnum[]
  are_you_currently_using_any_prescription_toothpaste_or_mouthwash?: boolean
  would_you_like_a_consultation_for_teeth_whitening?: boolean
  are_there_any_spots_or_stains_on_your_teeth_that_you_are_not_happy_with?: boolean
  have_you_ever_had_orthodontic_treatment_braces?: boolean
  would_you_like_to_have_straighter_teeth?: boolean
  do_you_often_bite_your_cheek_when_chewing?: boolean
  do_you_often_get_mouth_ulcers?: boolean
  are_you_currently_using_any_special_dental_appliances?: boolean
  do_you_snore_or_have_jaw_pain_especially_in_the_morning?: boolean
  do_you_wear_dentures_or_partial_dentures_at_home?: boolean
}
