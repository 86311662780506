import { ApiClient, ApiClientHr } from 'app/service/api-service'
import { DiscountEndPoint } from './constant'
import {
  IParamsGetDiscountCampaign,
  IParamsGetImportTemplate,
  IPayloadCreateCampaign,
  IPayloadGetCampaignAvailable,
  IPayloadUpdateCampaign,
  IPayloadUpdateEndDateInsertOnly,
  IPayloadUpdateWithInsertOnly,
} from './model'

export const requestGetDiscountCampaign = (
  params: IParamsGetDiscountCampaign,
) =>
  ApiClientHr.Get({
    url: DiscountEndPoint.DiscountCampaign.requestGetData,
    params: { params },
  })

export const requestCreateCampaign = (body: IPayloadCreateCampaign) =>
  ApiClientHr.Post({
    url: DiscountEndPoint.DiscountCampaign.requestGetData,
    body,
  })

export const requestUpdateCampaign = ({
  id,
  ...body
}: IPayloadUpdateCampaign) =>
  ApiClientHr.Put({
    url: DiscountEndPoint.DiscountCampaign.updateData.replace(':id', id),
    body,
  })

export const requestUpdateWithInsertOnlyCampaign = ({
  id,
  ...body
}: IPayloadUpdateWithInsertOnly) =>
  ApiClientHr.Put({
    url: DiscountEndPoint.DiscountCampaign.updateWithInsertOnlyData.replace(
      ':id',
      id,
    ),
    body,
  })

export const requestUpdateStackedWithInsertOnlyCampaign = ({
  id,
  ...body
}: IPayloadUpdateWithInsertOnly) =>
  ApiClientHr.Put({
    url: DiscountEndPoint.DiscountCampaign.requestInsertStackedCampaign.replace(
      ':id',
      id,
    ),
    body,
  })

export const requestUpdateEndDateWithInsertOnlyCampaign = ({
  id,
  ...body
}: IPayloadUpdateEndDateInsertOnly) =>
  ApiClientHr.Put({
    url: DiscountEndPoint.DiscountCampaign.requestInsertEndDateCampaign.replace(
      ':id',
      id,
    ),
    body,
  })

export const requestDeActivatedCampaign = (id: string) =>
  ApiClientHr.Put({
    url: DiscountEndPoint.DiscountCampaign.deActivated.replace(':id', id),
    body: {},
  })

export const requestGetDetailCampaign = (id: string) =>
  ApiClientHr.Get({
    url: DiscountEndPoint.DiscountCampaign.requestGetDetail.replace(':id', id),
    params: { id },
  })

export const requestGetCampaignAvailable = (
  body?: IPayloadGetCampaignAvailable,
) =>
  ApiClientHr.Post({
    url: DiscountEndPoint.DiscountCampaign.requestCampaignAvailable,
    body,
  })

export const requestRequestImportTreatment = (body: FormData) =>
  ApiClient.Post({
    url: DiscountEndPoint.DiscountCampaign.requestImportTreatment,
    body,
  })

export const requestImportCampaignForUpdate = (id: string, body: FormData) =>
  ApiClient.Put({
    url: DiscountEndPoint.DiscountCampaign.requestImportDiscountCampaignForUpdate?.replace(
      ':id',
      id,
    ),
    body,
  })

export const requestGetImportTemplate = (params: IParamsGetImportTemplate) =>
  ApiClient.Get({
    url: DiscountEndPoint.DiscountCampaign.requestGetImportTemplate,
    params: { params },
  })
export const requestGetImportTemplateForUpdate = (id: string) =>
  ApiClient.Get({
    url: DiscountEndPoint.DiscountCampaign.requestGetTemplateTreatmentForUpdate?.replace(
      ':id',
      id,
    ),
    params: { id },
  })
