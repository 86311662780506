export interface IResponseFilter {
  area?: string | null
  clinic?: string | null
  location?: string[]
}

export interface IFilterProps {
  handleChangeFilter?: (filter: IResponseFilter) => void
  filterData?: IResponseFilter
}

export const initResponseFilterTableManagement = {
  clinic: null,
  area: null,
  location: [],
}
