import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import { useTableHook } from './hook'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { PlusOutlined } from '@ant-design/icons'

export const CallScheduleLayout = () => {
  const { t } = useTranslation()
  const { columns, handleTableChange, tableData } = useTableHook()
  return (
    <RootWrapper>
      <BaseRow align={'middle'} justify={'end'}>
        <BaseButton icon={<PlusOutlined rev={undefined} />}>
          {t(R.strings.add_call_schedule)}
        </BaseButton>
      </BaseRow>

      <BaseTableManagement
        dataSource={tableData?.data}
        pagination={tableData?.pagination}
        loading={tableData?.loading}
        onChange={handleTableChange}
        columns={columns}
      />
    </RootWrapper>
  )
}
const RootWrapper = styled(BaseSpace)`
  padding: ${PADDING.xxs};
  background-color: #f9fafb; // need change
  border-radius: ${BORDER_RADIUS};
`
