import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { SvgCheckIcon, SvgCloseIcon } from 'app/assets/svg-assets'

import { BaseRow } from 'app/components/common/BaseRow'
import { WarehouseGoodsReceiptContext } from 'app/containers/InventoryManagement/WarehousePurchaseGoodsReceipt/context'
import { useHook } from '../BuyInfo/hook'
import { CustomButton, PageFooter } from '../../styles'

const Footer = () => {
  const { t } = useTranslation()
  const { handleCreate, isLoading, handleCancel } = useHook()
  const { orders, warehouseGoodsReceiptDetail } = useContext(
    WarehouseGoodsReceiptContext,
  )

  return (
    <PageFooter>
      <BaseRow justify="end" align={'middle'}>
        <BaseButton
          icon={<SvgCloseIcon />}
          onClick={async () => await handleCancel()}
        >
          {t(R.strings.cancel)}
        </BaseButton>
        <CustomButton
          icon={<SvgCheckIcon />}
          onClick={async () =>
            await handleCreate(orders, warehouseGoodsReceiptDetail)
          }
          loading={isLoading}
        >
          {t(R.strings.confirm)}
        </CustomButton>
      </BaseRow>
    </PageFooter>
  )
}

export default Footer
