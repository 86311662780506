import { TablePatients } from './TablePatients'
import { IDataTable } from './common-hook'
import { useTableHook } from './hook'
import { TableWrapper } from './styles'

export const CureIsNeededForThePatient = () => {
  const { columns, expandedRowKeys, handleExpand, tableData, handleTableChange } = useTableHook()
  return (
    <TableWrapper
      columns={columns}
      dataSource={tableData.data}
      loading={tableData.loading}
      pagination={tableData.pagination}
      expandable={{
        expandedRowKeys,
        onExpand: handleExpand,
        expandIcon: () => null,
        showExpandColumn: false,
        expandedRowRender: (record: IDataTable) => (
          <TablePatients id={`${record.id}`} />
        ),
      }}
      scroll={{
        x: true,
        y: undefined,
      }}
      onChange={handleTableChange}
    />
  )
}
