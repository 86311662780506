import R from 'app/assets/R'
import { YesNoEnum } from 'app/common/enum'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { IOrderDebtPayment } from '../../../type/debt-payment'
import { CompanyInfoLayout } from './components/CompanyInfoLayout'
import DetailPayment from './components/DetailPaymentLayout'
import HeadLayout from './components/HeadLayout'
import InfoPaymentLayout from './components/InfoPaymentLayout'
import IssueInvoiceLayout from './components/IssueInvoiceLayout'
import PaymentMethodLayout from './components/PaymentMethodLayout'
import PaymentOptionLayout from './components/PaymentOptionLayout'
import * as S from './styles'

const OrderItem = ({ orderItem }: { orderItem: IOrderDebtPayment }) => {
  const { t } = useTranslation()
  return (
    <S.SpaceWrapper>
      <BaseSpace>
        <HeadLayout />
      </BaseSpace>
      <S.ContentWrapper>
        <InfoPaymentLayout />
        <PaymentOptionLayout orderItem={orderItem} />
        <PaymentMethodLayout orderItem={orderItem} />
        <IssueInvoiceLayout orderItem={orderItem} />
        {isEqual(orderItem?.issueInvoice, YesNoEnum?.Yes) ? (
          <CompanyInfoLayout orderItem={orderItem} />
        ) : null}
        <DetailPayment />
      </S.ContentWrapper>
      <S.BottomWrapper justify={'end'} align={'middle'}>
        <BaseButton>{t(R.strings.payment)}</BaseButton>
      </S.BottomWrapper>
    </S.SpaceWrapper>
  )
}

export default OrderItem
