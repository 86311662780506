import { DeleteIcon } from 'app/common/icons'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  width: 100%;
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  background-color: white; // need change
`

export const TitleTreatmentService = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.regular};
  color: var(--primary-color);
`

export const TreatmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const TreatmentItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f4f6f9;
  padding: 12px 16px;
  border-radius: 8px;
  gap: 16px;

  .treatment-item {
    &__input {
      margin-bottom: 0px;
      flex: 1;
    }
  }
`

export const TitleTreatment = styled.span`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
`
export const SelectStyled = styled(BaseSelect)``

export const ButtonAdd = styled(BaseButton)`
  justify-content: flex-start;
`

export const ButtonDeleteStyled = styled(DeleteIcon)`
  cursor: pointer;
`
