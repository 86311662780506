import { BaseRadio } from 'app/components/common/BaseRadio'
import styled from 'styled-components'

export const SettingsOverlayMenu = styled.div`
  width: 13rem;
`

export const RadioBtn = styled(BaseRadio)`
  font-size: 0.875rem;
`

export const PwaInstallWrapper = styled.div`
  padding: 0 1rem 0.75rem;
`
