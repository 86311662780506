import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { getArray } from 'app/components/tables/common-table/constant'
import DividerLine from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/DividerLine'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DefineBracesTagEnum, ListBracesTagKey } from '../../constant'
import { BracesTagEnum } from '../../type'
import { CloseCircleFilled } from '@ant-design/icons'

export const TreatmentInformation = () => {
  const { t } = useTranslation()
  const [tags, setTags] = useState<BracesTagEnum[]>([])

  const onChangeTags = (tags: BracesTagEnum[]) => {
    setTags(tags)
  }

  const onRemove = (idx: number) => {
    setTags(prev => prev?.filter((_, indx) => idx !== indx))
  }
  return (
    <SectionWrapper>
      <div className="child-wrapper">
        <BaseText
          children={t(R.strings.treatment_information)}
          fontWeight="semibold"
          fontSize="md"
        />
      </div>
      <DividerLine />
      <BaseSpace className="child-wrapper">
        <BaseRow gutter={15}>
          <BaseCol xl={12}>
            <BaseSpace>
              <BaseText
                children={t(R.strings.difficulty_level_of_the_shift)}
                fontWeight="semibold"
                colorText="main2Color"
              />
              <BaseSelectWrapper defaultValue={1}>
                {getArray(5).map(i => {
                  return (
                    <Option value={i} key={i}>
                      {`Mức ${i}`}
                    </Option>
                  )
                })}
              </BaseSelectWrapper>
            </BaseSpace>
          </BaseCol>
          <BaseCol xl={12}>
            <BaseSpace>
              <BaseText
                children={t(R.strings.type_treatment_plan)}
                fontWeight="semibold"
                colorText="main2Color"
              />
              <BaseSelectWrapper defaultValue={1}>
                {getArray(5).map(i => {
                  return (
                    <Option value={i} key={i}>
                      {`Loại ${i}`}
                    </Option>
                  )
                })}
              </BaseSelectWrapper>
            </BaseSpace>
          </BaseCol>
        </BaseRow>
        <BaseRow>
          <BaseCol xl={24}>
            <BaseSpace>
              <BaseText
                children={t(R.strings.braces_tag)}
                fontWeight="semibold"
                colorText="main2Color"
              />
              <BaseSelectWrapper
                placeholder={t(R.strings.select_braces_tag)}
                maxTagCount={1}
                allowClear
                mode="multiple"
                onChange={value => onChangeTags(value as BracesTagEnum[])}
                value={tags}
              >
                {ListBracesTagKey.map((itm, i) => {
                  const bracesTag = DefineBracesTagEnum?.[itm]
                  return (
                    <Option value={BracesTagEnum[itm]} key={i}>
                      {bracesTag?.text ?? BracesTagEnum[itm]}
                    </Option>
                  )
                })}
              </BaseSelectWrapper>
            </BaseSpace>
          </BaseCol>
        </BaseRow>
        {tags?.length ? (
          <ListTagWrapper gutter={[15, 10]} align={'middle'}>
            {tags?.map((itm, idx) => {
              const bracesTag = DefineBracesTagEnum?.[itm]
              return (
                <BaseCol key={idx}>
                  <TagWrapper
                    align={'middle'}
                    gutter={8}
                    $backgroundColor={bracesTag?.backgroundColor ?? 'red'}
                  >
                    <BaseCol>
                      <BaseText
                        children={bracesTag?.text ?? '-'}
                        colorText="backgroundColor"
                        fontWeight="medium"
                      />
                    </BaseCol>
                    <BaseCol>
                      <CloseCircleOutlinedIcon
                        rev={undefined}
                        onClick={() => onRemove(idx)}
                      />
                    </BaseCol>
                  </TagWrapper>
                </BaseCol>
              )
            })}
          </ListTagWrapper>
        ) : null}
      </BaseSpace>
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div`
  width: 100%;
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
  .child-wrapper {
    padding: ${PADDING.md};
  }
`

const BaseSelectWrapper = styled(BaseSelect)`
  width: 100%;
  .ant-select-arrow {
    top: 50%;
  }
`

const ListTagWrapper = styled(BaseRow)`
  background-color: #f8f8f8;
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.md};
  margin-inline: 0px !important;
`

const TagWrapper = styled(BaseRow)<{ $backgroundColor: string }>`
  background-image: ${props => props?.$backgroundColor};
  border-radius: ${PADDING.xl};
  padding: 8px 10px;
`
const CloseCircleOutlinedIcon = styled(CloseCircleFilled)`
  svg {
    fill: ${convertedVariables.backgroundColor};
  }
  cursor: pointer;
`
