import { BaseSpace } from 'app/components/common/BaseSpace'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  width: 100%;
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  background-color: white; // need change
`

export const Title = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
`


export const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`
