import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import CreateWarehouseGoodsIssueContainer from 'app/containers/InventoryManagement/WarehouseGoodsIssue/pages/create'

const CreateWarehouseGoodsIssueContainerPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.inventory_management)}</PageTitle>
      <CreateWarehouseGoodsIssueContainer />
    </>
  )
}
const CreateWarehouseGoodsIssueContainerPage = memo(
  CreateWarehouseGoodsIssueContainerPageCpn,
  isEqual,
)

export default CreateWarehouseGoodsIssueContainerPage
