import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IRuleEmergencyContact } from './type'
import R from 'app/assets/R'

export const useCommonHook = () => {
  const { t } = useTranslation()
  const rules: IRuleEmergencyContact = useMemo(() => {
    return {
      address: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.address),
          }),
        },
      ],
      email: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.email),
          }),
        },
      ],
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.name),
          }),
        },
      ],
      patientCode: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.patient_code),
          }),
        },
      ],
      phone: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.phone),
          }),
        },
      ],
      profilePatientType: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.patient_record),
          }),
        },
      ],
      relationship: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.relationship),
          }),
        },
      ],
    }
  }, [t])

  return { rules }
}
