import { ItemCategoryProvider } from 'app/context/ItemCategoryContext'
import { WarehousePurchaseProvider } from '../../context'
import Update from './update'

const UpdateWarehousePurchaseContainer = () => {
  return (
    <WarehousePurchaseProvider>
      <ItemCategoryProvider>
        <Update />
      </ItemCategoryProvider>
    </WarehousePurchaseProvider>
  )
}

export default UpdateWarehousePurchaseContainer
