/* eslint-disable */
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { BaseSpace } from '../BaseSpace'
import './styles.css'
import { CONFIG_EDITOR } from './contants'
import { validateResponseHtml } from './hook'
import BaseText from '../BaseText'

interface IProps {
  onChange?: (content?: string) => void
  disabled?: boolean
  value?: string
  placeholder?: string
}

const BaseTextEditor: React.FC<IProps> = ({
  value,
  onChange,
  placeholder,
  disabled,
}) => {
  const _onChange = (content?: string) => {
    const newContent = validateResponseHtml(content)
    onChange?.(newContent)
  }

  return (
    <BaseSpace>
      <ReactQuill
        theme="snow"
        modules={CONFIG_EDITOR.modules}
        formats={CONFIG_EDITOR.formats}
        value={value}
        onChange={_onChange}
        defaultValue={value}
        placeholder={placeholder}
        readOnly={disabled}
      />
    </BaseSpace>
  )
}

export default BaseTextEditor
