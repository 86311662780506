import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useEffect, useRef, useState } from 'react'
import { ProfilePatientTypeEnum } from '../../enum'
import { IFormRelatives } from '../../type'
import { _DEV_ } from 'app/constant'
import { IRefModal } from '../../../../components'

export const useHook = () => {
  const [form] = BaseForm.useForm<IFormRelatives>()

  const ref = useRef<IRefModal>({})

  const [profilePatientType, setProfilePatientType] =
    useState<ProfilePatientTypeEnum>(ProfilePatientTypeEnum.AlreadyAPatient)

  const handleSubmit = async (values: IFormRelatives) => {
    _DEV_ && console.log({ values })
    ref?.current?.hide?.()
  }

  useEffect(() => {
    form.setFieldsValue({
      profilePatientType: ProfilePatientTypeEnum.AlreadyAPatient,
      name: 'some_name',
      patientCode: 'some_code',
      relationship: 'some_relationship',
      phone: '0965259441',
      email: 'some_email@example.com',
      address: 'some_address',
    })
  }, [])

  return {
    ref,
    form,
    profilePatientType,
    setProfilePatientType,
    handleSubmit,
  }
}
