import { t } from 'i18next'
import { useTableHook } from './hook'
import * as S from './styles'
import R from 'app/assets/R'

const SuppliesOfGroupTable = ({ record, fetchNewData }) => {
  const { columns, tableData } = useTableHook({ record, fetchNewData })

  return (
    <p>
      <S.Title>{t(R.strings.item_management_content_used_supplies)}</S.Title>
      <S.BaseTableWrapper
        columns={columns}
        dataSource={tableData.data}
        loading={tableData.loading}
        pagination={false}
        rowClassName={'row-overwrite-style'}
      />
    </p>
  )
}

export default SuppliesOfGroupTable
