import R from 'app/assets/R'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { useHookTable } from './hook'
import { BaseRow } from 'app/components/common/BaseRow'
import { FilterManagement } from './components/FilterManagementTable'
import { useTranslation } from 'react-i18next'
import { CustomSelectInputSearch, TotalPage } from './styles'
import Export from './components/Export'
import {
  DropdownRender,
  SearchOutlinedStyled,
  SearchWrapper,
} from 'app/components/common/selects/SelectSearch/styles'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { useEffect, useState } from 'react'
import { IWarehouse } from 'app/api/inventory-new/model/management'
import { requestGetWarehouseManagement } from 'app/api/inventory-new'
import { get } from 'lodash'
import { Select } from 'antd'
import { HeadingTitlePage } from 'app/components/tables/BaseTableReport/styles'
import { StatusEnum } from 'app/common/enum'

const { Option } = Select

const WarehouseInventoryContainer = () => {
  const {
    tableData,
    columns,
    handleTableChange,
    filter,
    handleChangeFilter,
    onPressExport,
  } = useHookTable()

  const { t } = useTranslation()

  const [warehouses, setWarehouses] = useState<IWarehouse[]>([])
  const [warehouseKeyword, setWarehouseKeyword] = useState('')

  useEffect(() => {
    const timer = setTimeout(async () => {
      const response = await requestGetWarehouseManagement({
        keyword: warehouseKeyword,
        status: StatusEnum.ACTIVE,
        pagesize: 1000,
      })
      const data: IWarehouse[] = response?.data
      const warehouseId = get(data, '[0]._id', '')
      if (!filter?.warehouseId) handleChangeFilter({ ...filter, warehouseId })
      setWarehouses(data)
    }, 500)
    return () => clearTimeout(timer)
  }, [warehouseKeyword])

  return (
    <S.BaseManagementWrapper>
      <BaseRow align={'middle'} justify={'space-between'}>
        <BaseRow align={'middle'} justify={'start'}>
          <HeadingTitlePage>
            {t(R.strings.warehouse_inventory)}
          </HeadingTitlePage>
          <CustomSelectInputSearch
            placeholder={t(R.strings.select)}
            onChange={value =>
              handleChangeFilter({ ...filter, warehouseId: String(value) })
            }
            value={filter?.warehouseId}
            filterOption={false}
            dropdownRender={menu => (
              <DropdownRender>
                <SearchWrapper>
                  <SearchOutlinedStyled
                    className="search__icon"
                    rev=""
                    size={20}
                  />
                  <BaseInput
                    className="search__input"
                    placeholder={t(R.strings.search)}
                    onChange={e => setWarehouseKeyword(e?.target?.value)}
                  />
                </SearchWrapper>
                {menu}
              </DropdownRender>
            )}
          >
            {warehouses?.map((warehouse, index) => {
              return (
                <Option value={warehouse?._id} key={index}>
                  {warehouse?.name}
                </Option>
              )
            })}
          </CustomSelectInputSearch>
        </BaseRow>
        <Export onPressExport={onPressExport} />
      </BaseRow>

      <S.RootWrapper>
        <BaseRow>
          <FilterManagement
            handleChangeFilter={handleChangeFilter}
            filterData={filter}
          />
        </BaseRow>
        <BaseTableManagement
          columns={columns}
          dataSource={tableData.data}
          pagination={{
            ...tableData.pagination,
            showTotal: total => {
              return (
                <TotalPage>
                  {t(R.strings.total_page, {
                    total,
                  })}
                </TotalPage>
              )
            },
          }}
          loading={tableData.loading}
          onChange={handleTableChange}
          expandable={{
            expandIcon: () => null,
          }}
        />
      </S.RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default WarehouseInventoryContainer
