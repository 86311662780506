import R from 'app/assets/R'
import { YesNoEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import { useMemo } from 'react'
import {
  AlternativeApplicationProgramLayout,
  CompanyInfoLayout,
  DetailPaymentLayout,
  IssueInvoiceLayout,
  PaymentLayout,
  PaymentMethodLayout,
  PaymentOptionLayout,
} from './layouts'
import * as S from './styles'
import { formatMoney } from 'parkway-web-common'
import { BaseSpace } from 'app/components/common/BaseSpace'
import DividerLine from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/DividerLine'

const TotalInvoiceLayout = () => {
  const { purchaseProductService, totalPaymentMade } =
    useProfilePatientContext()
  const isShowCompanyInfo = useMemo(() => {
    return isEqual(
      purchaseProductService?.totalInvoice?.issuesInvoice,
      YesNoEnum.Yes,
    )
  }, [purchaseProductService?.totalInvoice?.issuesInvoice])

  return (
    <BaseSpace>
      <S.RootWrapper>
        <PaymentLayout />
        <AlternativeApplicationProgramLayout />
        <PaymentOptionLayout />
        <PaymentMethodLayout />
        <IssueInvoiceLayout />
        {isShowCompanyInfo ? <CompanyInfoLayout /> : null}
        <DetailPaymentLayout />
      </S.RootWrapper>
      <DividerLine />
      <BaseRow justify={'end'}>
        <BaseRow gutter={15} align={'middle'}>
          <BaseCol>
            <BaseRow gutter={10} align={'middle'}>
              <BaseCol>
                <BaseText
                  fontWeight="semibold"
                  children={`${t(R.strings.total_amount_payment)}:`}
                  fontSize='xs'
                />
              </BaseCol>
              <BaseCol>
                <BaseText
                  fontWeight="extraBold"
                  colorText="statesGreenColor"
                  children={formatMoney(totalPaymentMade)}
                  fontSize='xs'
                />
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol>
            <S.ButtonPayment>
              <BaseText
                children={t(R.strings.payment)}
                colorText="backgroundColor"
                fontWeight="semibold"
                fontSize='xs'
              />
            </S.ButtonPayment>
          </BaseCol>
        </BaseRow>
      </BaseRow>
    </BaseSpace>
  )
}

export default TotalInvoiceLayout
