import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { ModalComponent } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ModalChooseSenderLayout } from '../ModalChooseSender'
import { useHook } from './hook'

export const ModalCreateApproveFlowLayout = () => {
  const { t } = useTranslation()
  const {
    deleteSenderJobTitle,
    form,
    listSenderJobTitle,
    modalRef,
    handleSubmit,
    rules,
    replaceSenderJobTitle,
  } = useHook()

  return (
    <ModalComponent
      ref={modalRef}
      titleModal={t(R.strings.add_approve_flow)}
      titleOpenModal={t(R.strings.add_approve_flow)}
      textButtonOpenColor={'backgroundColor'}
      borderRadiusButtonOpen={BORDER_RADIUS}
      borderButtonOpen={`1px solid ${convertedVariables.neutralBlack9Color}`}
      styleButtonOpen={{
        backgroundColor: convertedVariables?.statesOrangeColor,
      }}
      iconOpenModal={<PlusOutlinedIcon rev={undefined} />}
      handleSubmit={form?.submit}
      renderContent={
        <BaseForm form={form} onFinish={handleSubmit}>
          <BaseSpace>
            <BaseSpace size={2}>
              <BaseForm.Item
                label={t(R.strings.approve_type)}
                name={'approveType'}
                required
                rules={rules.approveType}
              >
                <BaseSelect
                  options={[
                    { label: 'Approve', value: 'approve' },
                    { label: 'Reject', value: 'reject' },
                  ]}
                  placeholder={t(R.strings.select_approve_type)}
                  showSearch
                />
              </BaseForm.Item>
              <BaseForm.Item
                label={t(R.strings.department)}
                name={'department'}
                required
                rules={rules.department}
              >
                <BaseSelect
                  options={[
                    { label: 'Phòng A', value: 'A' },
                    { label: 'Phòng B', value: 'B' },
                  ]}
                  placeholder={t(R.strings.select_department)}
                  showSearch
                />
              </BaseForm.Item>

              <BaseForm.Item
                label={t(R.strings.approver_job_title)}
                name={'approverJobTitle'}
                required
                rules={rules.approverJobTitle}
              >
                <BaseSelect
                  options={[
                    { label: 'A', value: 'A' },
                    { label: 'B', value: 'B' },
                  ]}
                  placeholder={t(R.strings.select_approver_job_title)}
                  showSearch
                />
              </BaseForm.Item>
            </BaseSpace>
            <BaseRow align={'middle'} justify={'space-between'}>
              <BaseCol>
                <BaseText
                  children={t(R.strings.sender_job_title)}
                  fontWeight="semibold"
                  opacity="0.7"
                />
              </BaseCol>
              <BaseCol>
                <ModalChooseSenderLayout
                  currentSelectSender={listSenderJobTitle}
                  onChangeListSenderJobTitle={replaceSenderJobTitle}
                />
              </BaseCol>
            </BaseRow>

            <ListSenderWrapper gutter={[8, 8]} align={'middle'}>
              {listSenderJobTitle?.map((item, index) => {
                return (
                  <BaseCol key={index}>
                    <ItemSenderWrapper align={'middle'} gutter={8}>
                      <BaseCol>
                        <BaseText children={item} fontWeight="medium" />
                      </BaseCol>
                      <BaseCol>
                        <CloseCircleOutlined
                          rev={undefined}
                          onClick={() => deleteSenderJobTitle(index)}
                        />
                      </BaseCol>
                    </ItemSenderWrapper>
                  </BaseCol>
                )
              })}
            </ListSenderWrapper>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}

const PlusOutlinedIcon = styled(PlusOutlined)`
  svg {
    fill: ${convertedVariables.backgroundColor};
  }
`

const ItemSenderWrapper = styled(BaseRow)`
  padding: 6px ${PADDING.xxs};
  border-radius: ${PADDING.md};
  background-color: ${convertedVariables.backgroundColor};
  margin-left: 0px !important;
  margin-right: 0px !important;

  svg {
    cursor: pointer;
    opacity: 0.7;
  }
`

const ListSenderWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
  background-color: ${convertedVariables.neutralBlack9Color};
  border-radius: ${BORDER_RADIUS};
  margin-left: 0px !important;
  margin-right: 0px !important;
`
