// Message code define
export const DegreeClassificationMessageCode = {
  DegreeClassificationManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20001',
  },
}

// Endpoint
export const DegreeClassificationEndPoint = {
  DegreeClassificationManagement: {
    request: 'hr/v1/degreeclassification',
  },
}
