import { IJobTitle } from 'app/api/jobtitle/model/job-title-management'
import R from 'app/assets/R'
import { StatusOptionsCreateEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { Option } from 'app/components/common/selects/BaseSelect'
import { IUnit } from 'app/model/unit.model'
import { t } from 'i18next'
import React from 'react'
import { IFormRule } from '../../CreateProfileContainer/type'
import * as S from './styles'

interface WorkInfoFormProps {
  rules: IFormRule
  key: number
  name: number
  jobTitles: IJobTitle[]
  units: IUnit[]
}

const WorkInfoForm: React.FC<WorkInfoFormProps> = ({
  key,
  rules,
  name,
  jobTitles,
  units,
  ...restField
}) => {
  return (
    <div key={key}>
      <BaseRow gutter={20} align={'top'} justify={'space-between'}>
        <BaseCol xl={12}>
          <S.FormItem
            {...restField}
            name={[name, 'unitid']}
            label={t(R.strings.department)}
            rules={rules.workinfos?.unitid}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(
                R.strings.profile_content_please_select_department,
              )}
              showSearch
              options={units?.map(unit => ({
                value: unit._id,
                label: unit.name,
              }))}
            />
          </S.FormItem>
        </BaseCol>
        <BaseCol xl={12}>
          <S.FormItem
            label={t(R.strings.profile_content_job_title)}
            name={[name, 'jobtitleid']}
            rules={rules.workinfos?.jobtitleid}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.profile_content_please_select_job_title)}
              options={jobTitles?.map(jobTitles => ({
                value: jobTitles._id,
                label: jobTitles.name,
              }))}
              showSearch
            />
          </S.FormItem>
        </BaseCol>
      </BaseRow>
      <BaseRow gutter={20} align={'top'} justify={'space-between'}>
        <BaseCol xl={6}>
          <S.FormItem
            name={[name, 'startdate']}
            label={t(R.strings.start_date)}
            rules={rules.workinfos?.startdate}
            required
          >
            <S.DatePicker placeholder={t(R.strings.choose_date)}></S.DatePicker>
          </S.FormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <S.FormItem
            label={t(R.strings.end_date)}
            name={[name, 'enddate']}
            rules={rules.workinfos?.enddate}
          >
            <S.DatePicker placeholder={t(R.strings.choose_date)}></S.DatePicker>
          </S.FormItem>
        </BaseCol>
        <BaseCol xl={12}>
          <S.FormItem
            label={t(R.strings.status)}
            name={[name, 'status']}
            rules={rules.workinfos?.status}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.please_select_status)}
            >
              {Object.values(StatusOptionsCreateEnum)?.map((item, index) => {
                return (
                  <Option value={item} key={index}>
                    {t(item)}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>
        </BaseCol>
      </BaseRow>
    </div>
  )
}

export default WorkInfoForm
