/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { RequireDot } from '../../BaseModalManagement/styles'
import styled from 'styled-components'

export type BaseLabelProps = {
  label?: React.ReactNode
  required?: boolean
}

export const LabelWrapper = styled.span`
  color: var(--main-2-color);
  display: flex;
  gap: 0.1rem;
`

export const BaseLabel: React.FC<BaseLabelProps> = ({ label, required }) => {
  return (
    <LabelWrapper>
      <span>{label}</span> {!!required && <RequireDot>*</RequireDot>}
    </LabelWrapper>
  )
}
