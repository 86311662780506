// Message code define
export const TreatmentMessageCode = {
  Management: {
    successPut: '20000',
  },
  CostPlan: {
    successImport: '20003',
    successGetData: '20001',
  },
  PatientSearch: {
    successExport: '20000',
    successGetData: '20000',
  },
}

// Endpoint
export const TreatmentEndPoint = {
  PatientSearch: {
    requestGetData: 'report/v1/searching-patients',
    exportExcel: 'report/v1/searching-patients/export',
  },
  CostPlan: {
    requestImportFile: 'report/v1/costplan/import',
    requestGetData: 'report/v1/costplan/getGroupByClinic',
  },
  recordTreatment: {
    requestGetData: 'treatment/v1/record-treatment/treatment',
  },
  treatment: {
    requestGetItemBrandData: '/treatment/v1/item-brand',
    requestGetMeasurementUnitData: '/treatment/v1/measurement-units',
  },
}
// const Key import
export const KeyTreatmentImport = {
  All: {
    periodType: 'periodtype',
    importType: 'importtype',
    file: 'file',
  },
}
