import { requestGetTemplateLimitDiscount } from 'app/api/marketing/limit-discount'
import { LimitDiscountMessageCode } from 'app/api/marketing/limit-discount/constant'
import {
  IParamsGetRefererReport,
  IRefererReport,
} from 'app/api/marketing/referer-report/model'
import R from 'app/assets/R'
import { convertToRegionName } from 'app/common/helpers'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { initPagination } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { useDebounce, usePagination } from 'app/hook'
import { useGetLimitDiscountList } from 'app/react-query/hook/limit-discount'
import { isEqual } from 'lodash'
import { Pagination, ResponseType, formatMoney } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ILimitDiscountData,
  IRegionalPrice,
} from './components/ImportModal/type'
import { BaseTextRegionName, BaseTextRegionPrice } from './styles'
import { IFilterDataTableReport } from './type'

export const useHookTable = () => {
  const { t } = useTranslation()

  const [pagination, setPagination] = useState(initPagination)

  const [filter, setFilter] = useState<IFilterDataTableReport>({})

  const { flattenDataList } = usePagination()

  const filterDebounce = useDebounce<IFilterDataTableReport>(filter, 500)

  const params = useMemo(() => {
    let newParams: IParamsGetRefererReport = {
      page: pagination.current,
      pageSize: pagination.pageSize,
      pagesize: pagination.pageSize,
    }

    if (filterDebounce?.keyword) {
      newParams = { ...newParams, keyword: filterDebounce.keyword }
    }

    return newParams
  }, [filterDebounce, pagination])

  const { data: dataApi, isLoading } = useGetLimitDiscountList(params)

  const dataFlatten = useMemo(() => {
    const res: ResponseType<IRefererReport[]> = flattenDataList(dataApi)
    return res
  }, [dataApi])

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [dataFlatten])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const onClickDownloadFileExample = async () => {
    try {
      const response = await requestGetTemplateLimitDiscount()
      if (
        isEqual(
          response?.msgcode,
          LimitDiscountMessageCode.LimitDiscount.successGetTemplate,
        )
      ) {
        window.open(response?.data?.url, '_blank', 'noopener,noreferrer')
      }
    } catch (error) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.with_error_please_contact_with_admin),
      })
    }
  }

  const handleChangeFilter = (newFilter: IFilterDataTableReport) => {
    setFilter(newFilter)
    setPagination({ ...pagination, current: 1 })
  }

  const renderRegionPrice = (array: IRegionalPrice[]) => {
    const renderItem = (item: IRegionalPrice) => {
      return (
        <BaseCol>
          <BaseRow>
            <BaseCol>
              <BaseTextRegionName>
                {convertToRegionName(item?.region)}
              </BaseTextRegionName>
            </BaseCol>
            <BaseCol>
              <BaseTextRegionPrice>
                {item?.price ? `: ${formatMoney(item?.price, 'đ')}` : '-'}
              </BaseTextRegionPrice>
            </BaseCol>
          </BaseRow>
        </BaseCol>
      )
    }

    return <>{array?.map(renderItem)}</>
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<ILimitDiscountData>({
        title: t(R.strings.service),
        key: 'name',
        classNameWidthColumnOverwrite: 'very-big-column',
        align: 'left',
        render: (_, record) => {
          return (
            <BaseSpace size={4}>
              <BaseText
                children={record?.name}
                fontWeight="semibold"
                opacity="0.9"
                textAlign="left"
              />
              <BaseRow>
                {renderRegionPrice(record?.regionalPrice || [])}
              </BaseRow>
            </BaseSpace>
          )
        },
      }),
      convertColumnTable<ILimitDiscountData>({
        title: t(R.strings.limit_discount),
        key: 'maximum',
        className: 'column-limit-voucher',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.maximum ? `${record?.maximum}%` : '-'}
              fontWeight="medium"
              opacity="0.5"
              textAlign="right"
            />
          )
        },
      }),
    ]
    return option
  }, [t])

  return {
    data,
    columns,
    isLoading,
    filter,
    handleTableChange,
    pagination: {
      ...pagination,
      total: dataFlatten?.metadata?.totalItem ?? 0,
    },
    handleChangeFilter,
    onClickDownloadFileExample,
  }
}
