import { isEqual } from 'lodash'
import { getRandomUuid } from 'parkway-web-common'
import { useState } from 'react'
import { DiscountTypeEnum } from 'app/api/marketing/discount-campaign/model'
import { IBundleServiceItem } from '../type'

export const useBundleHook = () => {
  const [listBundleService, setListBundleService] = useState<
    IBundleServiceItem[]
  >([])

  const onAddNewBundleService = () => {
    setListBundleService([
      ...listBundleService,
      {
        id: getRandomUuid(),
        name: undefined,
        selectedTreatment: [],
      },
    ])
  }

  const onChangeNameBundleByIndex = (index: number, name: string) => {
    const newList = listBundleService.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          name,
        }
      }
      return item
    })
    setListBundleService(newList)
  }

  const onRemoveBundleServiceByIndex = (index: number) => {
    const newList = listBundleService.filter((_, i) => i !== index)
    setListBundleService(newList)
  }

  const onChangeItemBundleServiceByIndex = (
    index: number,
    item: IBundleServiceItem,
  ) => {
    const newList = listBundleService.map((itm, i) => {
      if (isEqual(i, index)) {
        return {
          ...itm,
          ...item,
        }
      }
      return itm
    })
    setListBundleService(newList)
  }

  const onChangeDiscountValueItemBundleService = (
    index: number,
    treatmentIndex: number,
    discountValue: number,
  ) => {
    const newList = listBundleService.map((item, i) => {
      if (isEqual(i, index)) {
        return {
          ...item,
          selectedTreatment: item?.selectedTreatment?.map((itm, idx) => {
            if (isEqual(idx, treatmentIndex)) {
              return {
                ...itm,
                discountValue,
              }
            }
            return itm
          }),
        }
      }
      return item
    })
    setListBundleService(newList)
  }

  const onChangeDiscountTypeItemBundleService = (
    index: number,
    treatmentIndex: number,
    discountType: DiscountTypeEnum,
  ) => {
    const newList = listBundleService.map((item, i) => {
      if (isEqual(i, index)) {
        return {
          ...item,
          selectedTreatment: item?.selectedTreatment?.map((itm, idx) => {
            if (isEqual(idx, treatmentIndex)) {
              return {
                ...itm,
                discountType,
              }
            }
            return itm
          }),
        }
      }
      return item
    })
    setListBundleService(newList)
  }

  const onChangeQuantityItemBundleService = (
    index: number,
    treatmentIndex: number,
    quantity: number,
  ) => {
    const newList = listBundleService.map((item, i) => {
      if (isEqual(i, index)) {
        return {
          ...item,
          selectedTreatment: item?.selectedTreatment?.map((itm, idx) => {
            if (isEqual(idx, treatmentIndex)) {
              return {
                ...itm,
                quantity,
              }
            }
            return itm
          }),
        }
      }
      return item
    })
    setListBundleService(newList)
  }

  const onDeleteItemInSelectedTreatment = (
    index: number,
    treatmentIndex: number,
  ) => {
    const newList = listBundleService.map((item, i) => {
      if (isEqual(i, index)) {
        return {
          ...item,
          selectedTreatment: item?.selectedTreatment?.filter(
            (_, idx) => idx !== treatmentIndex,
          ),
        }
      }
      return item
    })
    setListBundleService(newList)
  }

  const onChangeBundleList = (list: IBundleServiceItem[]) => {
    setListBundleService(list)
  }

  return {
    onAddNewBundleService,
    listBundleService,
    onRemoveBundleServiceByIndex,
    onChangeNameBundleByIndex,
    onChangeDiscountTypeItemBundleService,
    onChangeDiscountValueItemBundleService,
    onChangeItemBundleServiceByIndex,
    onChangeQuantityItemBundleService,
    onDeleteItemInSelectedTreatment,
    onChangeBundleList
  }
}
