import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { FilterDept } from 'app/components/tables/BaseTableManagement/FilterDeptTable'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import * as S from 'app/components/tables/BaseTableReport/styles'
import 'dayjs/locale/vi'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTableHook } from './hook'
import './styles.css'
import { BaseCol } from 'app/components/common/BaseCol'
import TotalBox from './components/TotalBox'
import { useTranslation } from 'react-i18next'
import Export from './components/Export'
import { PaidTypeTableEnum } from '../Debt/components/Filter/type'

const DebtContractContainer: React.FC = () => {
  const {
    columns,
    tableData,
    handleTableChange,
    handleChangeFilter,
    filterResponse,
    onClickExport,
    metadata,
  } = useTableHook()

  const { t } = useTranslation()
  return (
    <S.BaseReportWrapper>
      <HeaderPage
        titleI18nKey={R.strings.manage_customer_debt_contract}
        rightComponent={
          <Export onPressExport={() => onClickExport({ ...filterResponse })} />
        }
      />
      <S.RootWrapper>
        <FilterDept
          handleChangeFilter={handleChangeFilter}
          filterData={filterResponse}
          listPaidType = {Object.values(PaidTypeTableEnum)}
        />
        <BaseRow>
          <BaseCol span={8}>
            <TotalBox
              title={t(R.strings.manage_customer_debt_payment_total)}
              total={Number(metadata?.totalNetAmount)}
            />
          </BaseCol>
          <BaseCol span={8}>
            <TotalBox
              title={t(R.strings.manage_customer_debt_deposit_total)}
              total={Number(metadata?.totalDeposit)}
            />
          </BaseCol>

          <BaseCol span={8}>
            <TotalBox
              title={t(R.strings.manage_customer_debt_paid_total)}
              total={Number(metadata?.totalPaid)}
            />
          </BaseCol>
        </BaseRow>
        <BaseTableReport
          columns={columns}
          dataSource={tableData.data}
          loading={tableData.loading}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
          pagination={tableData.pagination}
          scroll={{ x: true, y: tableData?.data?.length ? 480 : undefined }}
          // rowSelection={rowSelection}
        />
      </S.RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default DebtContractContainer
