import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { ITreatmentImport } from '../../type'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'
import { formatMoney } from 'parkway-web-common'
import { isEqual } from 'lodash'
import { DiscountTypeEnum } from 'app/api/marketing/discount-campaign/model'
import { DefineDiscountTypeEnum } from 'app/api/marketing/discount-voucher/constant'
import { DefineStatusEnum } from '../../constant'
import { useMemo } from 'react'

export const ListTreatmentLayout = ({
  data,
  isLoading,
}: {
  data?: ITreatmentImport[]
  isLoading?: boolean
}) => {
  const { t } = useTranslation()

  const dataSource = useMemo(() => {
    return data?.map((item, index) => ({ ...item, key: index + 1 }))
  }, [data])

  return (
    <BaseTableManagement
      pagination={{
        pageSize: 5,
      }}
      columns={[
        convertColumnTable<ITreatmentImport>({
          key: 'rowNumber',
          title: t(R.strings.row),
          classNameWidthColumnOverwrite: 'small-column',
          render: (_, record) => {
            return (
              <BaseText
                children={record?.rowNumber ?? '-'}
                fontWeight="medium"
                opacity="0.7"
              />
            )
          },
        }),
        convertColumnTable<ITreatmentImport>({
          key: 'name',
          title: t(R.strings.name),
          render: (_, record) => {
            return (
              <BaseText children={record?.name ?? '-'} fontWeight="medium" />
            )
          },
        }),
        convertColumnTable<ITreatmentImport>({
          key: 'discount',
          title: t(R.strings.discount_value),
          render: (_, record) => {
            return (
              <BaseText
                children={formatMoney(
                  record?.discount ?? '',
                  isEqual(record?.dType, DiscountTypeEnum.Percent) ? '%' : 'đ',
                  undefined,
                  1,
                )}
                fontWeight="medium"
                opacity="0.7"
              />
            )
          },
        }),
        convertColumnTable<ITreatmentImport>({
          key: 'dType',
          title: t(R.strings.discount_type),
          render: (_, record) => {
            return (
              <BaseText
                children={
                  record?.dType
                    ? t(DefineDiscountTypeEnum[record?.dType]?.keyI18n)
                    : '-'
                }
                fontWeight="medium"
                opacity="0.7"
              />
            )
          },
        }),
        convertColumnTable<ITreatmentImport>({
          key: 'status',
          title: t(R.strings.status),
          render: (_, record) => {
            return (
              <BaseText
                children={
                  record?.status
                    ? t(DefineStatusEnum[record?.status]?.text)
                    : '-'
                }
                fontWeight="medium"
                style={
                  record?.status
                    ? {
                        color: DefineStatusEnum[record?.status]?.color,
                      }
                    : undefined
                }
              />
            )
          },
        }),
      ]}
      dataSource={dataSource}
      loading={isLoading}
      scroll={{ x: true }}
    />
  )
}
