import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import {
  ContentModalAppointmentSession,
  ModalComponent,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/components'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import { useHook } from './hook'
import { IAppointmentSession } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'

export const AddAppointmentSession = ({
  handleSubmit,
}: {
  handleSubmit?: (item: IAppointmentSession) => void
}) => {
  const { t } = useTranslation()

  const {
    form,
    refModal,
    onAddTreatment,
    listTreatment,
    onDeleteTreatment,
    isDisableSubmit,
    onSubmit,
    onChangeTreatment,
  } = useHook({ handleSubmit })

  return (
    <ModalComponent
      iconOpenModal={
        <BaseCol>
          <PlusOutlined rev={undefined} />
        </BaseCol>
      }
      ref={refModal}
      titleOpenModal={t(R.strings.add_appointment_session)}
      titleModal={t(R.strings.add_appointment_session)}
      borderRadiusButtonOpen={BORDER_RADIUS}
      widthModal={1200}
      borderButtonOpen={`1px solid ${convertedVariables.neutralBlack9Color}`}
      styleButtonOpen={{
        backgroundColor: convertedVariables?.backgroundColor,
        marginLeft: 0,
        marginRight: 0,
      }}
      isShowLineBottom={false}
      isShowLineTop={false}
      paddingContent={`0px`}
      isDisableSubmit={isDisableSubmit}
      handleSubmit={form?.submit}
      renderContent={
        <ContentModalAppointmentSession
          form={form}
          onAddTreatmentInAppointmentSession={onAddTreatment}
          listTreatment={listTreatment}
          onDelete={onDeleteTreatment}
          handleSubmit={onSubmit}
          onChangeTreatmentInAppointmentSession={onChangeTreatment}
        />
      }
    />
  )
}
