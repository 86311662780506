import { BaseButton } from 'app/components/common/BaseButton'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseRow)`
  padding: ${PADDING.xxxs};
  background-color: white;
  border-radius: ${BORDER_RADIUS};
`

export const InfoWrapper = styled(BaseRow)``

export const ImageSupplies = styled(BaseImage)`
  border-radius: ${BORDER_RADIUS};
  width: 32px !important;
  height: 32px !important;
`
export const InfoSupplies = styled(BaseSpace)`
  width: 100%;
  .name {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.semibold};
  }
  .uom {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.regular};
  }
`
export const QuantitySupplies = styled(BaseRow)`
  gap: ${PADDING.xxxs};
  .quantity {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.semibold};
  }
`
export const ButtonActionQuantity = styled(BaseButton)`
  border-radius: 100%;
  padding: ${PADDING.xxxxs} !important;
  height: 24px !important;
  width: 24px !important;
  font-weight: ${FONT_WEIGHT.bold};
`

export const LineVertical = styled.div`
  width: 1px;
  height: 24px;
  background-color: var(--neutral-black-2-color);
`
