import R from 'app/assets/R'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useModalHook } from './hook'
import * as S from './styles'
import { IPropsChangePassword } from './type'

export const ModalChangePassword = (props: IPropsChangePassword) => {
  const { form, t, isLoading, handleSubmit, handleCancel, rules } =
    useModalHook(props)

  return (
    <S.BaseModalCustom
      open={props.isVisible}
      onCancel={handleCancel}
      closeIcon={null}
      confirmLoading={isLoading}
      onOk={form?.submit}
      title={t(R.strings.change_password)}
      width={700}
      okText={t(R.strings.confirm)}
    >
      <div>
        <BaseDivider/>
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={{}}
          form={form}
        >
          <S.FormItem
            name={'oldPassword'}
            label={t(R.strings.old_password)}
            rules={rules.oldPassword}
            required
          >
            <S.FormInputPassword
              placeholder={t(R.strings.enter_old_password)}
            />
          </S.FormItem>
          <S.FormItem
            name={'newPassword'}
            label={t(R.strings.new_password)}
            rules={rules.newPassword}
            required
          >
            <S.FormInputPassword
              placeholder={t(R.strings.enter_new_password)}
            />
          </S.FormItem>

          <S.FormItem
            name={'confirmNewPassword'}
            label={t(R.strings.confirm_new_password)}
            rules={rules.confirmNewPassword}
            required
          >
            <S.FormInputPassword
              placeholder={t(R.strings.enter_confirm_new_password)}
            />
          </S.FormItem>
        </BaseForm>
        <BaseDivider/>
      </div>
    </S.BaseModalCustom>
  )
}
