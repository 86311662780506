import styled from 'styled-components'

export const CompanyBox = styled.div`
  gap: 10px;

  padding: 20px;
  gap: 20px;
  border-radius: 8px 8px 0px 0px;
  border: 0px 0px 1px 0px;
  opacity: 0px;
  border-bottom: 1px solid var(--neutral-black-2-color);
  background: white;
`

export const CompanyLogo = styled.div``

export const CompanyName = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--inventory-text-color);
`

export const CompanyAddress = styled.div`
  margin-top: 2px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: var(--main-color-text);
`
