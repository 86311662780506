import { Dropdown } from 'antd'
import R from 'app/assets/R'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { IAppointment } from '../../../type'
import './index.css'
import {
  InfoHoverBoxAuthor,
  InfoHoverBoxAuthorContent,
  InfoHoverBoxAuthorDate,
  InfoHoverBoxAuthorInfo,
  InfoHoverBoxAuthorName,
  InfoHoverBoxAuthorTilte,
  InfoHoverBoxAuthorTilteUpdate,
  InfoHoverBoxContent,
  InfoHoverBoxContentInfoList,
  InfoHoverBoxContentInfoListItem,
  InfoHoverBoxContentInfoListItemLabel,
  InfoHoverBoxContentInfoListItemName,
  InfoHoverBoxContentInfoListItemNote,
  InfoHoverBoxContentTitle,
  InfoHoverbox,
} from './styles'
import { BaseAvatar } from 'app/components/common/BaseAvatar'

export function InfoHover(props) {
  const { isOpenAction, event } = props
  const { t } = useTranslation()
  const resource = event?.resource

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: '1',
            label: (
              <InfoHoverbox>
                <InfoHoverBoxAuthor>
                  <InfoHoverBoxAuthorTilte>
                    {t(R.strings.created_by)}{' '}
                  </InfoHoverBoxAuthorTilte>
                  <InfoHoverBoxAuthorContent>
                    <InfoHoverBoxAuthorInfo>
                      <BaseAvatar
                        src={resource.createdBy?.avatar}
                        alt={resource.createdBy?.name}
                      />
                      <InfoHoverBoxAuthorName>
                        <div>
                          <b>{resource.createdBy?.name}</b>
                        </div>
                        <div>{resource.createdBy?.jobTitle}</div>
                      </InfoHoverBoxAuthorName>
                    </InfoHoverBoxAuthorInfo>
                    <InfoHoverBoxAuthorDate>
                      {moment(resource.createdAt).format('YYYY/MM/DD hh:mm')}
                    </InfoHoverBoxAuthorDate>
                  </InfoHoverBoxAuthorContent>
                </InfoHoverBoxAuthor>
                <InfoHoverBoxContent>
                  <InfoHoverBoxContentTitle>
                    {t(R.strings.schedule_info)}
                  </InfoHoverBoxContentTitle>
                  <div className="box-content-info">
                    <InfoHoverBoxContentInfoList>
                      <InfoHoverBoxContentInfoListItem className="box-content-info-item">
                        <InfoHoverBoxContentInfoListItemLabel>
                          {t(R.strings.patient_name)}
                        </InfoHoverBoxContentInfoListItemLabel>
                        <InfoHoverBoxContentInfoListItemName>
                          {resource.fullName}
                        </InfoHoverBoxContentInfoListItemName>
                      </InfoHoverBoxContentInfoListItem>
                      <InfoHoverBoxContentInfoListItem className="box-content-info-item">
                        <InfoHoverBoxContentInfoListItemLabel>
                          {t(R.strings.phone_label)}
                        </InfoHoverBoxContentInfoListItemLabel>
                        <InfoHoverBoxContentInfoListItemName>
                          {resource.phone}
                        </InfoHoverBoxContentInfoListItemName>
                      </InfoHoverBoxContentInfoListItem>
                    </InfoHoverBoxContentInfoList>

                    <InfoHoverBoxContentInfoList>
                      <InfoHoverBoxContentInfoListItem className="box-content-info-item">
                        <InfoHoverBoxContentInfoListItemLabel>
                          {t(R.strings.area_label)}
                        </InfoHoverBoxContentInfoListItemLabel>
                        <InfoHoverBoxContentInfoListItemName>
                          {resource.area?.name}
                        </InfoHoverBoxContentInfoListItemName>
                      </InfoHoverBoxContentInfoListItem>
                      <InfoHoverBoxContentInfoListItem className="box-content-info-item">
                        <InfoHoverBoxContentInfoListItemLabel>
                          {t(R.strings.clinic_label)}
                        </InfoHoverBoxContentInfoListItemLabel>
                        <InfoHoverBoxContentInfoListItemName>
                          {resource.clinic?.name}
                        </InfoHoverBoxContentInfoListItemName>
                      </InfoHoverBoxContentInfoListItem>
                    </InfoHoverBoxContentInfoList>

                    <InfoHoverBoxContentInfoList>
                      <InfoHoverBoxContentInfoListItem className="box-content-info-item">
                        <InfoHoverBoxContentInfoListItemLabel>
                          {t(R.strings.category_service_label)}
                        </InfoHoverBoxContentInfoListItemLabel>
                        <InfoHoverBoxContentInfoListItemName>
                          {resource.categoryService?.name}
                        </InfoHoverBoxContentInfoListItemName>
                      </InfoHoverBoxContentInfoListItem>
                      <InfoHoverBoxContentInfoListItem className="box-content-info-item">
                        <InfoHoverBoxContentInfoListItemLabel>
                          {t(R.strings.doctor_label)}
                        </InfoHoverBoxContentInfoListItemLabel>
                        <InfoHoverBoxContentInfoListItemName>
                          {resource.doctor?.name}
                        </InfoHoverBoxContentInfoListItemName>
                      </InfoHoverBoxContentInfoListItem>
                    </InfoHoverBoxContentInfoList>

                    <InfoHoverBoxContentInfoList>
                      <InfoHoverBoxContentInfoListItem className="box-content-info-item">
                        <InfoHoverBoxContentInfoListItemLabel>
                          {t(R.strings.day_label)}
                        </InfoHoverBoxContentInfoListItemLabel>
                        <InfoHoverBoxContentInfoListItemName>
                          {resource.date}
                        </InfoHoverBoxContentInfoListItemName>
                      </InfoHoverBoxContentInfoListItem>
                      <InfoHoverBoxContentInfoListItem className="box-content-info-item">
                        <InfoHoverBoxContentInfoListItemLabel>
                          {t(R.strings.time_label)}
                        </InfoHoverBoxContentInfoListItemLabel>
                        <InfoHoverBoxContentInfoListItemName>
                          {resource.time}
                        </InfoHoverBoxContentInfoListItemName>
                      </InfoHoverBoxContentInfoListItem>
                    </InfoHoverBoxContentInfoList>

                    <InfoHoverBoxContentInfoList>
                      <InfoHoverBoxContentInfoListItemNote>
                        <InfoHoverBoxContentInfoListItemLabel>
                          {t(R.strings.note_label)}
                        </InfoHoverBoxContentInfoListItemLabel>
                        <InfoHoverBoxContentInfoListItemName>
                          {resource.note}
                        </InfoHoverBoxContentInfoListItemName>
                      </InfoHoverBoxContentInfoListItemNote>
                    </InfoHoverBoxContentInfoList>
                  </div>
                </InfoHoverBoxContent>
                <div>
                  <InfoHoverBoxAuthor>
                    <InfoHoverBoxAuthorTilteUpdate className="update">
                      {t(R.strings.update_info)}{' '}
                    </InfoHoverBoxAuthorTilteUpdate>
                    {resource?.histories?.map(
                      (appointment: IAppointment, index) =>
                        index < 3 && (
                          <InfoHoverBoxAuthorContent key={index}>
                            <InfoHoverBoxAuthorInfo>
                              <BaseAvatar
                                src={appointment?.createdBy?.avatar}
                                alt={appointment?.createdBy?.name}
                              />
                              <InfoHoverBoxAuthorName>
                                <div>
                                  <b>{appointment?.createdBy?.name}</b>
                                </div>
                                <div>{appointment?.createdBy?.jobTitle}</div>
                              </InfoHoverBoxAuthorName>
                            </InfoHoverBoxAuthorInfo>
                            <InfoHoverBoxAuthorDate>
                              {moment(appointment?.createdAt).format(
                                'YYYY/MM/DD hh:mm',
                              )}
                            </InfoHoverBoxAuthorDate>
                          </InfoHoverBoxAuthorContent>
                        ),
                    )}
                  </InfoHoverBoxAuthor>
                </div>
              </InfoHoverbox>
            ),
          },
        ],
      }}
      placement="bottomRight"
      overlayClassName={`create-appointment infohover ${
        isOpenAction && 'hidden'
      }`}
      // open={true}
    >
      {props.children}
    </Dropdown>
  )
}
