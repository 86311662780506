import {
  IFilterReport14,
  TypeReport14Enum,
} from 'app/api/report/model/report-14'
import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { ALL_KEY } from 'app/constant'
import { t } from 'i18next'
import moment from 'moment'
import { Dates, FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import { useState } from 'react'
import styled from 'styled-components'
import { AgeGroupEnum, GenderEnum } from '../enum'
import AreaSelection from './AreaSelection'

interface IProps {
  onChangeFilterReport14: (filter: IFilterReport14) => void
}

export const FilterReport14 = ({ onChangeFilterReport14 }: IProps) => {
  const [filter, setFilter] = useState<IFilterReport14>({
    year: moment().year(),
    type: TypeReport14Enum.CASE,
    clinic: [],
  })

  const onChangeFilter = ({
    key,
    value,
  }: {
    key: keyof IFilterReport14
    value?: string | TypeReport14Enum | number | unknown
  }) => {
    const newFilter = {
      ...filter,
      [key]: value,
    }
    setFilter(newFilter)
    onChangeFilterReport14(newFilter)
  }

  const onChangeYear = (_, year: string) => {
    onChangeFilter({ key: 'year', value: year })
  }

  const onChangeClinic = (clinics?: string[]) => {
    onChangeFilter({ key: 'clinic', value: clinics })
  }

  return (
    <RootWrapper gutter={[10, 10]} align={'middle'}>
      <BaseCol>
        <TextSeeWith>{`${t(R.strings.see_with)}:`}</TextSeeWith>
      </BaseCol>
      <BaseCol>
        <BaseForm.Item name="select">
          <SelectBaseStyled
            defaultValue={TypeReport14Enum.CASE}
            value={filter?.type}
            placeholder={t(R.strings.report_14_content_all_branch)}
            suffixIcon={<SvgTableCloseIcon />}
            onChange={type =>
              onChangeFilter({ key: 'type', value: type as TypeReport14Enum })
            }
            allowClear={false}
          >
            <Option key={TypeReport14Enum.CASE}>{t(R.strings.case)}</Option>
            <Option key={TypeReport14Enum.REVENUE}>
              {t(R.strings.revenue)}
            </Option>
          </SelectBaseStyled>
        </BaseForm.Item>
      </BaseCol>
      <BaseCol>
        <BaseForm.Item>
          <Picker
            picker={'year'}
            onChange={onChangeYear}
            value={Dates.getDate(`${filter?.year}-01-01`)}
            allowClear={false}
          />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol>
        <AreaSelection onChangeClinic={onChangeClinic} />
      </BaseCol>
      <BaseCol>
        <BaseForm.Item name="select">
          <SelectBaseStyled
            defaultValue={ALL_KEY}
            value={filter?.age}
            placeholder={t(R.strings.report_14_content_all_branch)}
            suffixIcon={<SvgTableCloseIcon />}
            onChange={age =>
              onChangeFilter({ key: 'age', value: age as AgeGroupEnum })
            }
            allowClear={false}
          >
            <Option key={ALL_KEY}>
              {t(R.strings.report_14_content_all_age_group)}
            </Option>
            {Object.values(AgeGroupEnum)?.map(age => {
              return <Option key={age}>{age}</Option>
            })}
          </SelectBaseStyled>
        </BaseForm.Item>
      </BaseCol>
      <BaseCol>
        <BaseForm.Item name="select">
          <SelectBaseStyled
            defaultValue={ALL_KEY}
            value={filter?.gender}
            placeholder={t(R.strings.report_14_content_all_branch)}
            suffixIcon={<SvgTableCloseIcon />}
            onChange={gender =>
              onChangeFilter({ key: 'gender', value: gender as GenderEnum })
            }
            allowClear={false}
          >
            <Option key={ALL_KEY}>
              {t(R.strings.report_14_content_all_gender)}
            </Option>
            {Object.values(GenderEnum)?.map(gender => {
              return <Option key={gender}>{t(gender)}</Option>
            })}
          </SelectBaseStyled>
        </BaseForm.Item>
      </BaseCol>
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseRow)`
  padding-bottom: ${PADDING.xxs};
  .ant-form-item {
    margin-bottom: 0px;
  }
`

const SelectBaseStyled = styled(BaseSelect)`
  min-width: 160px;
  .ant-select-arrow {
    top: 40%;
  }
`
const Picker = styled(BaseDatePicker)`
  min-width: 160px;
`
const TextSeeWith = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`
