import { Divider } from 'antd'
import { requestGetOrganizationalChartByJobTitle } from 'app/api/company/organizational-chart'
import {
  IProfileOrganization,
  ResponseOrganizationByJobTitleType,
} from 'app/api/company/organizational-chart/model'
import { SvgDownArrowIcon, SvgHumanResourcesIcon } from 'app/assets/svg-assets'
import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { PADDING } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { TreeNode } from 'react-organizational-chart'
import styled from 'styled-components'

export const ItemProfileComponent = ({
  profile,
  isShowLevel,
  parentChildLength,
  haveMoreOneChild,
  isWarning,
}: {
  profile: IProfileOrganization
  isShowLevel?: boolean
  parentChildLength?: number
  haveMoreOneChild?: boolean
  isWarning?: boolean
}) => {
  const [isCollapse, setIsCollapse] = useState(false)

  const handleCollapse = () => {
    setIsCollapse(!isCollapse)
  }

  const [data, setData] = useState<IProfileOrganization[]>([])
  const [loading, setLoading] = useState(false)

  const getData = async () => {
    try {
      setLoading(true)

      const res: ResponseOrganizationByJobTitleType =
        await requestGetOrganizationalChartByJobTitle({
          jobTitleId: profile?.workinfos?.jobtitleid,
          unitId: profile?.workinfos?.unitid,
          profileId: profile?._id,
        })

      res?.data && setData(res.data)
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isCollapse) {
      getData()
    }
  }, [profile, isCollapse])

  const level = useMemo(() => {
    if (!isShowLevel) return 0
    return profile?.levelDisplay ? profile?.levelDisplay - 1 : 0
  }, [profile?.levelDisplay, isShowLevel])

  const height = useMemo(() => {
    let bonus = 72

    if (haveMoreOneChild) {
      bonus = 75
    }

    if (level > 1) {
      bonus = bonus + 72 * (level - 1)
    }

    return !level ? `${0}px` : `${bonus + level * 220}px`
  }, [parentChildLength, level])

  const marginTop = useMemo(() => {
    return !level ? `${0}px` : `30px`
  }, [level, parentChildLength])

  const isHaveMoreOneChild = useMemo(() => {
    return !!data?.find(child => child?.sizeChildren && child?.sizeChildren > 1)
  }, [data])

  return (
    <TreeNode
      label={
        <BaseSpace style={{ marginTop: '3px' }} size={0}>
          <Divider
            dashed
            type="vertical"
            style={{
              height,
              borderColor: '#365B7E',
              marginLeft: 8,
            }}
          />
          <RootWrapper $marginTop={marginTop} $isWarning={isWarning}>
            <BaseCol />
            <ImageLogoWrapper justify={'center'}>
              <ImageLogo>
                <BaseAvatar
                  src={profile?.avatar}
                  alt={profile?.name}
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}
                />
              </ImageLogo>
            </ImageLogoWrapper>
            <div
              style={{
                height: 45,
              }}
            >
              <BaseText
                children={`${profile?.name ?? '-'} (Level: ${
                  profile?.workinfos?.jobTitle?.jobTitleLevel?.level ?? 0
                })`}
                fontWeight="semibold"
                fontSize="xs"
                colorText="primary1Color"
              />
            </div>
            <JobTitleWrapper>
              <BaseText
                children={profile?.workinfos?.jobTitle?.name ?? '-'}
                fontWeight="medium"
                fontSize="xxxs"
              />
            </JobTitleWrapper>

            <BaseRow align={'middle'} gutter={16}>
              <BaseCol>
                <BaseRow align={'middle'} gutter={8}>
                  <SvgHumanResourcesIcon />
                  <BaseCol>
                    <BaseText
                      children={profile?.sizeChildren ?? 0}
                      fontWeight="semibold"
                      opacity="0.7"
                      fontSize="xs"
                    />
                  </BaseCol>
                </BaseRow>
              </BaseCol>
            </BaseRow>

            <CollapseWrapper
              onClick={profile?.sizeChildren ? handleCollapse : undefined}
              $isWarning={isWarning}
            >
              <SvgDownArrowIconWrapper $isActive={isCollapse} />
            </CollapseWrapper>
          </RootWrapper>
        </BaseSpace>
      }
    >
      {isCollapse ? (
        <>
          {data?.length
            ? data?.map((child, indexChild) => {
                const levelDisplayCalculated =
                  (child?.workinfos?.jobTitle?.jobTitleLevel?.level ?? 0) -
                  (profile?.workinfos?.jobTitle?.jobTitleLevel?.level ?? 0)

                return (
                  <TreeNode
                    key={indexChild}
                    label={
                      <ItemProfileComponent
                        profile={{
                          ...child,
                          levelDisplay:
                            levelDisplayCalculated <= 0
                              ? 0
                              : levelDisplayCalculated,
                        }}
                        parentChildLength={profile?.sizeChildren}
                        haveMoreOneChild={isHaveMoreOneChild}
                        isShowLevel
                        isWarning={levelDisplayCalculated <= 0}
                      />
                    }
                  />
                )
              })
            : null}
          {loading ? <Loading /> : null}
        </>
      ) : null}
    </TreeNode>
  )
}

const RootWrapper = styled(BaseSpace)<{
  $borderTopColor?: string
  $marginTop?: string
  $isWarning?: boolean
}>`
  position: relative;
  border-radius: ${PADDING.xs};
  align-items: center;
  width: 250px;
  background-color: ${convertedVariables.backgroundColor};
  border-top: 2px solid ${({ $borderTopColor }) => $borderTopColor ?? '#365B7E'} !important;
  margin-top: ${props => props.$marginTop ?? '10px'};
  padding: 0px ${PADDING.xs};
  ${({ $isWarning }) => {
    if ($isWarning) {
      return `
        border: 1px solid ${convertedVariables.statesRedColor} !important;
        border-top: 2px solid ${convertedVariables.statesRedColor} !important;
      `
    }
  }}
`

const ImageLogoWrapper = styled(BaseRow)`
  position: absolute;
  top: -25px;
  width: 100%;
  left: 0px;
`

const ImageLogo = styled.div<{ $borderColor?: string }>`
  border-radius: 50%;
  width: 50px !important;
  height: 50px !important;
  border: 2px solid
    ${props => props.$borderColor ?? convertedVariables.backgroundColor};
`

const JobTitleWrapper = styled(BaseRow)`
  background-color: ${convertedVariables.neutralBlack9Color};
  border-radius: ${PADDING.xs};
  padding: ${PADDING.xxxs};
  width: fit-content;
`
const CollapseWrapper = styled.div<{ $isWarning?: boolean }>`
  background-color: #f8f8f8 !important;
  padding: ${PADDING.xxs} ${PADDING.md};
  width: ${props => (props?.$isWarning ? '248px' : '250px')} !important;
  cursor: pointer;
  border-bottom-left-radius: ${PADDING.xs};
  border-bottom-right-radius: ${PADDING.xs};
  position: relative;
`

const SvgDownArrowIconWrapper = styled(SvgDownArrowIcon)<{
  $isActive?: boolean
}>`
  transform: rotate(${props => (props?.$isActive ? 0 : -180)}deg);
  transition: 0.5s ease;
`
