// Message code define
export const SkillMessageCode = {
  SkillManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20001',
  },
}

// Endpoint
export const SkillEndPoint = {
  SkillManagement: {
    request: 'hr/v1/skill',
  },
}
