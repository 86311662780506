import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { ITreatmentGroupOldDb } from 'app/api/old-db-user/model'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { ProfileServiceComponent } from 'app/containers/Marketing/DiscountCampaignManagement/CreateCampaign/RootLayout/Contents/components'
import { usePagination } from 'app/hook'
import { useGetTreatmentGroupList } from 'app/react-query/hook/old-db'
import { isEqual } from 'lodash'
import { initialPagination, Pagination, ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
export const ModalAddTreatmentLayout = ({
  services,
  onChangeService,
}: {
  services?: ITreatmentGroupOldDb[]
  onChangeService?: (services: ITreatmentGroupOldDb[]) => void
}) => {
  const { t } = useTranslation()
  const modalRef = useRef<IRefModal>({})
  const { flattenDataList } = usePagination()
  const [servicesSelected, setServicesSelected] = useState<
    ITreatmentGroupOldDb[]
  >([])

  const [pagination, setPagination] = useState<Pagination>({
    ...initialPagination,
    pageSize: 5,
  })

  const [search, setSearch] = useState<string | undefined>(undefined)

  const { data: treatmentGroupApi, isLoading } = useGetTreatmentGroupList({
    keyword: search,
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
  })

  const dataTreatmentGroup = useMemo(() => {
    const res: ResponseType<ITreatmentGroupOldDb[]> =
      flattenDataList(treatmentGroupApi)
    return res
  }, [treatmentGroupApi])

  const data = useMemo(() => {
    return dataTreatmentGroup?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [dataTreatmentGroup?.data])

  const handleOpenModal = () => {
    modalRef.current?.open?.()
  }

  const onChangePagination = (pagination: Pagination) => {
    setPagination(pagination)
  }

  // select service
  const handleSelectService = (record: ITreatmentGroupOldDb) => {
    const isSelected = !!servicesSelected?.find(item =>
      isEqual(item._id, record?._id),
    )

    if (isSelected) {
      setServicesSelected(
        servicesSelected.filter(item => !isEqual(item._id, record?._id)),
      )
    } else {
      setServicesSelected([...servicesSelected, record])
    }
  }

  // handle save service
  const handleSaveService = () => {
    onChangeService?.(servicesSelected)
    modalRef.current?.hide?.()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setServicesSelected(services ?? [])
    }, 500)
    return () => clearTimeout(timer)
  }, [services])

  const columns = useMemo(() => {
    return [
      convertColumnTable<ITreatmentGroupOldDb>({
        title: t(R.strings.service),
        key: 'name',
        fixed: 'left',
        className: 'name-service-column',
        render: (_, record) => {
          return (
            <ItemWrapper onClick={() => handleSelectService(record)}>
              <ProfileServiceComponent itm={record} />
            </ItemWrapper>
          )
        },
      }),
      convertColumnTable<ITreatmentGroupOldDb>({
        title: '',
        key: '_id',
        width: "5%",
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          const isSelected = !!servicesSelected?.find(item =>
            isEqual(item._id, record?._id),
          )

          return (
            <BaseCheckbox
              checked={isSelected}
              onChange={() => handleSelectService(record)}
            />
          )
        },
      }),
    ]
  }, [t, servicesSelected])

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setPagination({ ...pagination, current: 1 })
  }

  return (
    <ModalComponent
      buttonOpenCustom={
        <BaseButton
          icon={<PlusOutlined rev={undefined} />}
          onClick={handleOpenModal}
        >
          {t(R.strings.add_service)}
        </BaseButton>
      }
      titleModal={R.strings.add_service}
      ref={modalRef}
      widthModal={1000}
      handleSubmit={handleSaveService}
      renderContent={
        <BaseForm>
          <RootWrapper>
            <BaseRow gutter={[16, 8]} align={'middle'}>
              <BaseCol xl={12}>
                <BaseFormItem hiddenLabel>
                  <BaseInput
                    prefix={<SearchOutlined rev={undefined} />}
                    placeholder={t(R.strings.enter_service_name)}
                    value={search}
                    onChange={onChangeSearch}
                  />
                </BaseFormItem>
              </BaseCol>
            </BaseRow>

            <BaseTableManagement
              columns={columns}
              dataSource={data}
              loading={isLoading}
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: dataTreatmentGroup?.metadata?.totalItem ?? 0,
              }}
              onChange={onChangePagination}
              rowClassName="row-overwrite-style"
              scroll={{ x: true }}
            />
          </RootWrapper>
        </BaseForm>
      }
    />
  )
}

const RootWrapper = styled(BaseSpace)`
  .ant-table-wrapper .ant-table .ant-table-header {
    border-radius: 0px !important;
  }

  .ant-table-thead > tr > th {
    border: 0px !important;
  }

  .row-overwrite-style .ant-table-cell {
    border-left: 0px !important;
    border-right: 0px !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0px;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0px !important;
  }
`
const ItemWrapper = styled(BaseRow)`
  cursor: pointer;
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
