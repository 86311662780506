import R from 'app/assets/R'
import { GoodsIssueActionEnum, GoodsIssueStatusEnum } from './type'
import {
  SvgFileIcon,
  SvgReOrderIcon,
  SvgReceiveIcon,
  SvgTrashGreyIcon,
} from 'app/assets/svg-assets'
import {
  INVENTORY_WAREHOUSE_GOODS_ISSUE_RECEIPT_PATH,
  INVENTORY_WAREHOUSE_GOODS_ISSUE_RETURN_PATH,
  INVENTORY_WAREHOUSE_GOODS_ISSUE_VIEW_PATH
} from 'app/components/router/route-path'

const GoodsIssueStatusName = {
  [GoodsIssueStatusEnum.CORRECT_RECEIVED]:
    R.strings.warehouse_goods_issue_correct_received,
  [GoodsIssueStatusEnum.DELIVERING]: R.strings.warehouse_goods_issue_delivering,
  // [GoodsIssueStatusEnum.OVER_RECEIVED]:
  //   R.strings.warehouse_goods_issue_over_received,
  [GoodsIssueStatusEnum.SHORT_RECEIVED]:
    R.strings.warehouse_goods_issue_short_received,
}

const GoodsIssueStatusColor = {
  [GoodsIssueStatusEnum.CORRECT_RECEIVED]: {
    background: 'linear-gradient(316.55deg, #629DAA 0%, #6DAEBD 100%)',
    color: 'white',
  },
  [GoodsIssueStatusEnum.DELIVERING]: {
    background: 'linear-gradient(136.55deg, #FFAE49 0%, #FF7617 100%)',
    color: 'white',
  },
  [GoodsIssueStatusEnum.SHORT_RECEIVED]: {
    background: 'linear-gradient(94.8deg, #FF6B6B 0%, #F44949 99.59%)',
    color: 'white',
  },
  [GoodsIssueStatusEnum.CANCEL]: {
    background: 'linear-gradient(94.8deg, #FF6B6B 0%, #F44949 99.59%)',
    color: 'white',
  },
}

const GoodsIssueActionFromStatus = {
  [GoodsIssueStatusEnum.DELIVERING]: [
    GoodsIssueActionEnum.VIEW_DETAIL,
    GoodsIssueActionEnum.RECEIVE,
    GoodsIssueActionEnum.CANCEL,
    GoodsIssueActionEnum.RETURN,
  ],
  [GoodsIssueStatusEnum.CORRECT_RECEIVED]: [GoodsIssueActionEnum.VIEW_DETAIL],
  [GoodsIssueStatusEnum.SHORT_RECEIVED]: [
    GoodsIssueActionEnum.VIEW_DETAIL,
    GoodsIssueActionEnum.RETURN,
    GoodsIssueActionEnum.CANCEL,
  ],
}

const GoodsIssueActionName = {
  [GoodsIssueActionEnum.VIEW_DETAIL]: R.strings.view_detail,
  [GoodsIssueActionEnum.RECEIVE]: R.strings.purchase_order_receive,
  [GoodsIssueActionEnum.RETURN]: R.strings.warehouse_goods_issue_add_return,
  [GoodsIssueActionEnum.CANCEL]: R.strings.cancel,
}

const GoodsIssueActionIcon = {
  [GoodsIssueActionEnum.VIEW_DETAIL]: SvgFileIcon,
  [GoodsIssueActionEnum.RECEIVE]: SvgReceiveIcon,
  [GoodsIssueActionEnum.RETURN]: SvgReOrderIcon,
  [GoodsIssueActionEnum.CANCEL]: SvgTrashGreyIcon,
}

const GoodsIssueActionPath = {
  [GoodsIssueActionEnum.VIEW_DETAIL]: INVENTORY_WAREHOUSE_GOODS_ISSUE_VIEW_PATH,
  [GoodsIssueActionEnum.RECEIVE]: INVENTORY_WAREHOUSE_GOODS_ISSUE_RECEIPT_PATH,
  [GoodsIssueActionEnum.RETURN]: INVENTORY_WAREHOUSE_GOODS_ISSUE_RETURN_PATH,
}

export {
  GoodsIssueStatusColor,
  GoodsIssueStatusName,
  GoodsIssueActionName,
  GoodsIssueActionIcon,
  GoodsIssueActionFromStatus,
  GoodsIssueActionPath,
}
