import { Upload } from 'antd'
import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'
const { Dragger } = Upload

export const TitleUpload = styled.span`
  color: var(--collapse-background-color);
  font-size: ${PADDING.lg};
`
export const UploadWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

// export const GrowthWrapper = styled(BaseRow)<{ $upValue?: boolean }>`
//   background-color: ${props =>
//     props?.$upValue
//       ? 'var(--states-green-light-color)'
//       : ' var(--states-red-light-color)'};

export const DraggerStyled = styled(Dragger)`
  transition: unset !important;

  &.ant-upload-wrapper:has(.ant-upload-list-item-container) .ant-upload-drag {
    display: none;
  }

  & .ant-upload.ant-upload-btn {
    padding: 26px 0;
  }

  & .ant-upload-list-item {
    border-color: unset !important;
  }

  &
    .ant-upload-list-item-container
    .ant-upload-list-item.ant-upload-list-item-done {
    color: #d9d9d9 !important;

    & .ant-upload-list-item-name {
      color: var(--main-2-color) !important;
    }

    & .ant-btn-icon {
      background-color: #f4f6f9 !important;
      border-radius: 50%;
      padding: 2px;
    }
  }
`
