import * as S from './styles'
import { IFormDegreeModalData } from './DegreeModal/type'
import { IDegree } from 'app/api/degree/model/management'
import { IDegreeClassification } from 'app/api/degreeclassification/model/management'
import DegreeItem from '../DegreeItem'

export interface IPropsUpdateSkill {
  degreeClassificationOptions: IDegreeClassification[]
  degreeOptions: IDegree[]
  degrees?: IFormDegreeModalData[]
  onRemove?: (key: number) => void
  onUpdate?: (key: number) => void
}

export const DegreeList = ({
  degrees,
  degreeOptions,
  degreeClassificationOptions,
  onRemove,
  onUpdate,
}: IPropsUpdateSkill) => {
  return (
    <S.DegreeWrapper>
      {degrees?.map((degree, index) => {
        const fondDegree = degreeOptions?.find(
          degreeOption => degreeOption._id === degree.degree,
        )

        const fondDegreeClassification = degreeClassificationOptions?.find(
          degreeOption => degreeOption._id === degree.degreeClassification,
        )

        let documentImageUrl =
          degree?.document &&
          degree?.document[0] &&
          degree?.document[0].response?.data?.imageUrl

        if (!documentImageUrl) {
          documentImageUrl = degree?.document?.url
        }

        return (
          <DegreeItem
            degree={fondDegree}
            degreeClassification={fondDegreeClassification}
            start={degree?.start}
            end={degree?.end}
            key={index}
            index={index}
            documentImageUrl={documentImageUrl}
            onRemove={onRemove}
            onUpdate={onUpdate}
          />
        )
      })}
    </S.DegreeWrapper>
  )
}
