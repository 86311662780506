import R from 'app/assets/R'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { WeekCurrentDayName, WeekDay, WeekDayName } from './styles'
import { DATE } from 'app/common/constants'

moment.locale('vi')

const WeekHeader = props => {
  const { t } = useTranslation()

  const isToday =
    moment().format(DATE.YYYYMMDD) === moment(props?.date).format(DATE.YYYYMMDD)
  const isSelected =
    moment(props?.selectedDate).format(DATE.YYYYMMDD) ===
    moment(props?.date).format(DATE.YYYYMMDD)
  return (
    <div
      className={`weekday ${isSelected && 'selected'}`}
      ref={props?.headerRef}
    >
      <div>
        <span
          className={`span ${
            (props?.label?.indexOf('CN') > -1 ||
              props?.label.indexOf('T7') > -1) &&
            'off'
          }`}
        >
          <WeekDayName>{moment(props?.date).format(DATE.DDDD)}</WeekDayName>
        </span>
        <WeekDay>{moment(props?.date).format(DATE.DD)}</WeekDay>
      </div>
      <div className="week-current-day">
        {isToday && (
          <WeekCurrentDayName> {t(R.strings.today)}</WeekCurrentDayName>
        )}
      </div>
    </div>
  )
}

export default WeekHeader
