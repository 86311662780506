import { useTranslation } from 'react-i18next'
import * as S from './styles'
import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import { dataMailDashboard } from './data'

export const MailBoxComponent = () => {
  const { t } = useTranslation()
  return (
    <S.RootWrapper>
      <S.HeadWrapper justify={'space-between'} align={'middle'}>
        <S.TitleText>{t(R.strings.mail)}</S.TitleText>
        <S.DetailWrapper>{t(R.strings.see_detail)}</S.DetailWrapper>
      </S.HeadWrapper>
      <BaseRow justify={'space-between'} align={'middle'}>
        {dataMailDashboard.map((item, index) => {
          return (
            <S.ItemWrapper key={`${index} ${item?.id}`} type={item?.type}>
              <S.HeadWrapper align={'middle'}>
                <S.HeadLeftItem type={item?.type} />
                <S.HeadTextItem type={item?.type}>
                  {t(item?.titleI18nKey)}
                </S.HeadTextItem>
              </S.HeadWrapper>
              <S.CountTextItem>{item?.count}</S.CountTextItem>
            </S.ItemWrapper>
          )
        })}
      </BaseRow>
    </S.RootWrapper>
  )
}
