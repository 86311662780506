import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import ApproveTreatmentPlanContainer from 'app/containers/DentalTreatmentManagement/ApproveTreatmentPlan'

const ApproveTreatmentPlanPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.approve_treatment_plan)}</PageTitle>
      <ApproveTreatmentPlanContainer />
    </>
  )
}
const ApproveTreatmentPlanPage = memo(ApproveTreatmentPlanPageCpn, isEqual)

export default ApproveTreatmentPlanPage
