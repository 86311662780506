import { ApiClientHr } from 'app/service/api-service'
import { HrEndPoint } from './constant'
import { IParamsGetUnit } from './model'
import { IParamsGetApproveDayOff } from './models/approve-day-off'

export const requestGetUnit = (params: IParamsGetUnit) =>
  ApiClientHr.Get({
    url: HrEndPoint.Unit.requestGetData,
    params: { params },
  })

// day off
export const requestGetTotalDashboardDayOff = (
  params?: IParamsGetApproveDayOff,
) =>
  ApiClientHr.Get({
    url: HrEndPoint.DayOff.requestTotal,
    params: { params },
  })

export const requestGetDashboardDayOff = (params?: IParamsGetApproveDayOff) =>
  ApiClientHr.Get({
    url: HrEndPoint.DayOff.requestList,
    params: { params },
  })
