import RequestLevelSkillTable from '../RequestLevelSkillTable'
import SuppliesOfGroupTable from '../SuppliesOfGroupTable'
import * as S from './styles'

export interface IListSkill {
  id: string
  industryId?: string
  industryName?: string
  skillId?: string
  skillName?: string
  levelSkillName?: string
  levelSkillId?: string
  levelSkillColor?: string
}

export function ExpandedRowRender<T>({
  record,
  listSkill,
  fetchNewData,
}: {
  record: T
  listSkill?: IListSkill[]
  fetchNewData?: () => void
}) {
  return (
    <S.RootWrapper>
      <S.FirstColWrapper />
      <S.ExpandedRowRenderWrapper direction="vertical" size={20}>
        <RequestLevelSkillTable record={record} listSkill={listSkill} />
        <SuppliesOfGroupTable fetchNewData={fetchNewData} record={record} />
      </S.ExpandedRowRenderWrapper>
    </S.RootWrapper>
  )
}
