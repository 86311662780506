import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { ListStatusTable } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { StatusTableType } from '../hook'
import { SearchDropdown } from './search-dropdown'
import * as S from './styles'
import {
  IFilterProps,
  IResponseFilter,
  initResponseFilterTableManagement,
} from './type'

export const FilterManagement = ({
  handleChangeFilter,
  filterData,
  placeholderSearch,
  placeholderFilterStatus,
  otherFilterComponent,
  xlSize,
}: IFilterProps) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const [isOverlayOpen, setOverlayOpen] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      handleChangeFilter?.(filter)
    }, 500)
    return () => clearTimeout(timer)
  }, [filter])

  useEffect(() => {
    if (filterData && !isEqual(filterData, filter)) {
      // console.log({ filterData })

      setFilter(filterData)
    }
  }, [filterData])

  useEffect(() => {
    setOverlayOpen(false)
  }, [pathname])

  const onChangeFilter = (status: StatusTableType[]) => {
    if (!status?.length) {
      status = initResponseFilterTableManagement?.status
    }
    setFilter(prev => {
      return {
        ...prev,
        status,
      }
    })
  }

  const onChangeSearch = (search: string) => {
    setFilter(prev => {
      return {
        ...prev,
        search,
      }
    })
  }

  return (
    <S.HeadWrapper gutter={20} align={'middle'}>
      <BaseCol xl={xlSize}>
        <BaseRow gutter={20} align={'middle'}>
          <BaseCol xl={xlSize}>
            <SearchDropdown
              query={filter?.search ?? ''}
              setQuery={onChangeSearch}
              data={[]}
              isOverlayOpen={isOverlayOpen}
              setOverlayOpen={setOverlayOpen}
              placeholder={placeholderSearch}
            />
          </BaseCol>
          <BaseCol xl={xlSize}>
            <BaseForm.Item name="select" required={false}>
              <S.SelectBaseStyled
                defaultValue={filter.status}
                value={filter.status}
                mode="multiple"
                allowClear
                placeholder={placeholderFilterStatus ?? t(R.strings.all_status)}
                suffixIcon={!filter.status?.length && <SvgTableCloseIcon />}
                onChange={type => onChangeFilter(type as StatusTableType[])}
              >
                {ListStatusTable?.map((item, index) => {
                  return (
                    <Option value={item} key={index}>
                      {t(item)}
                    </Option>
                  )
                })}
              </S.SelectBaseStyled>
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>
      </BaseCol>
      {otherFilterComponent ? (
        <BaseCol xl={xlSize}>{otherFilterComponent}</BaseCol>
      ) : null}
    </S.HeadWrapper>
  )
}
