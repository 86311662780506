import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import MaterialExportContainer from 'app/containers/InventoryManagement/OldInventory/MaterialExport'

const MaterialExportContainerPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.inventory_management)}</PageTitle>
      <MaterialExportContainer />
    </>
  )
}
const MaterialExportContainerPage = memo(MaterialExportContainerPageCpn, isEqual)

export default MaterialExportContainerPage
