import { BaseButton } from 'app/components/common/BaseButton'
import { DropdownCollapse } from 'app/components/header/styles'
import { useAppSelector } from 'app/redux/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import { LanguagePicker } from '../LanguagePicker'
import { ThemePicker } from '../ThemePicker'

export const SettingsOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation()

  const { isPWASupported, event } = useAppSelector(state => state.pwa)

  return (
    <S.SettingsOverlayMenu {...props}>
      <DropdownCollapse
        bordered={false}
        expandIconPosition="end"
        ghost
        defaultActiveKey="themePicker"
      >
        <DropdownCollapse.Panel
          header={t('header.changeLanguage')}
          key="languagePicker"
        >
          <LanguagePicker />
        </DropdownCollapse.Panel>
        <DropdownCollapse.Panel
          header={t('header.changeTheme')}
          key="themePicker"
        >
          <ThemePicker />
        </DropdownCollapse.Panel>
      </DropdownCollapse>
      {isPWASupported && (
        <S.PwaInstallWrapper>
          <BaseButton
            block
            type="primary"
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              return event && (event as any).prompt()
            }}
          >
            {t('pwa')}
          </BaseButton>
        </S.PwaInstallWrapper>
      )}
    </S.SettingsOverlayMenu>
  )
}
