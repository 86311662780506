/* eslint-disable @typescript-eslint/no-explicit-any */
import { CollapseProps, ExpandIconPosition } from 'antd/lib/collapse/Collapse'
import BaseText from 'app/components/common/BaseText'
import { FONT_WEIGHT, getRandomUuid } from 'parkway-web-common'
import React, { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

type BaseCollapseProps = CollapseProps

interface IProps extends BaseCollapseProps {
  titleKeyI18n?: string
  styleItem?: React.CSSProperties
  children?: ReactNode
  extra?: ReactNode
  expandIconPosition?: ExpandIconPosition
  expandIcon?: (panelProps: any) => React.ReactNode // panelProps can't define
  style?: React.CSSProperties
  header?: React.ReactNode
  label?: React.ReactNode
}

const CollapseComponent: React.FC<IProps> = ({
  titleKeyI18n,
  styleItem,
  extra,
  children,
  expandIconPosition,
  expandIcon,
  label,
  ...props
}) => {
  const { t } = useTranslation()

  const style: React.CSSProperties = useMemo(() => {
    return {
      fontWeight: FONT_WEIGHT.semibold,
      ...styleItem,
    }
  }, [styleItem])

  return (
    <S.RootWrapper
      items={[
        {
          key: titleKeyI18n ?? getRandomUuid(),
          label: label ? (
            label
          ) : titleKeyI18n ? (
            <BaseText
              children={t(titleKeyI18n ?? '')}
              fontSize="md"
              fontWeight="semibold"
            />
          ) : undefined,
          style,
          children,
          extra: <div onClick={even => even?.stopPropagation()}>{extra}</div>,
        },
      ]}
      expandIconPosition={expandIconPosition}
      expandIcon={expandIcon}
      style={style}
      onChange={() => undefined}
      {...props}
    />
  )
}

export default CollapseComponent
