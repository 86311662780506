import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import { useModalHook } from './hook'
import * as S from './styles'
import { IFormData } from './type'

export const initValues: IFormData = {
  code: '',
  name: '',
  description: '',
  _id: '',
}

enum StatusJobTitleLevelOptionsEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
}

export type StatusJobTitleLevelType =
  keyof typeof StatusJobTitleLevelOptionsEnum

export interface IPropsCreateJobTitleLevel {
  fetchTable: () => void
}

export const Create = (props: IPropsCreateJobTitleLevel) => {
  const {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
  } = useModalHook(props)

  return (
    <BaseModalManagement
      titleKeyI18n={R.strings.job_title_level_content_add}
      isLoading={isLoading}
      content={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <S.FormItem
            name="code"
            label={t(R.strings.job_title_level_content_id)}
            rules={rules.code}
            required
          >
            <S.FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.job_title_level_content_id),
              })}
            />
          </S.FormItem>
          <S.FormItem
            name="name"
            label={t(R.strings.job_title_level_content_name_create)}
            rules={rules.name}
            required
          >
            <S.FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.job_title_level_content_name_create),
              })}
            />
          </S.FormItem>
          <S.FormItem
            name="description"
            label={t(R.strings.job_title_level_content_description)}
            rules={rules.description}
            required
          >
            <S.FormInput.TextArea
              showCount
              maxLength={300}
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.job_title_level_content_description),
              })}
            />
          </S.FormItem>
          <S.FormItem
            name="level"
            label={t(R.strings.value_level)}
            rules={rules.level}
            required
          >
            <S.FormInputNumber
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.enter_value_level),
              })}
              min={0}
            />
          </S.FormItem>
          <S.FormItem
            label={t(R.strings.status)}
            name="status"
            rules={rules.status}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.please_select_status)}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {Object.values(StatusJobTitleLevelOptionsEnum)?.map(
                (item, index) => {
                  return (
                    <Option value={item} key={index}>
                      {t(item)}
                    </Option>
                  )
                },
              )}
            </S.SelectBaseStyled>
          </S.FormItem>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.job_title_level_content_add}
      confirmKeyI18n={R.strings.job_title_level_content_add}
      handleSubmit={form.submit}
      visible={visible}
    />
  )
}
