import { CheckCircleFilled } from "@ant-design/icons"
import { BaseButton } from "app/components/common/BaseButton"
import { BaseCol } from "app/components/common/BaseCol"
import { BaseRow } from "app/components/common/BaseRow"
import BaseText from "app/components/common/BaseText"
import { convertedVariables } from "app/styles/themes/themeVariables"
import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

export const ButtonToothAction = ({
    onClick,
    key18nKey,
    icon,
    isSelected,
  }: {
    onClick?: () => void
    key18nKey: string
    icon: ReactNode
    isSelected?: boolean
  }) => {
    const { t } = useTranslation()
    return (
      <BaseButtonAction type="text" icon={icon} onClick={onClick}>
        <BaseRow
          align={'middle'}
          gutter={25}
          justify={'space-between'}
          style={{ width: '100%' }}
        >
          <BaseCol xl={20}>
            <TextStatus
              $isSelected={isSelected}
              children={t(key18nKey)}
              fontSize="xs"
              fontWeight={isSelected ? 'semibold' : 'regular'}
            />
          </BaseCol>
          {isSelected ? (
            <CheckOutlinedWrapper rev={undefined} />
          ) : (
            <div
              style={{
                width: 20,
                height: 20,
              }}
            />
          )}
        </BaseRow>
      </BaseButtonAction>
    )
  }


const BaseButtonAction = styled(BaseButton)`
width: 100%;
justify-content: flex-start !important;
`

const TextStatus = styled(BaseText)<{ $isSelected?: boolean }>`
color: ${props =>
  props?.$isSelected ? '#629daa' : convertedVariables.primaryColor};
text-align: left;
`

const CheckOutlinedWrapper = styled(CheckCircleFilled)`
svg {
  fill: #629daa; // need change
  width: 20px;
  height: 20px;
}
`