import { IBaseTableReport } from 'app/components/tables/BaseTableReport/type'
import { Pagination } from 'parkway-web-common'

export const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
}

export interface IGeneralCheckInPlanTable<T> extends IBaseTableReport<T> {
  service?: string
  month?: string
  week?: string
  checkInSchedule?: number

  checkInScheduleTotal?: number
}
