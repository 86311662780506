import React from 'react'
import moment from 'moment'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import {
  Author,
  AuthorBox,
  AuthorInfo,
  AuthorJobName,
  AuthorLabel,
  AuthorName,
  AuthorUser,
  TableAvatar,
  UpdatedByAuthor,
  UpdatedByBox,
  UpdatedByLabel,
  UpdatedByList,
  UpdatedInfo,
} from '../styles'
import { Popover } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'
import { DATE } from 'app/common/constants'

const CreatedBy: React.FC<{
  record
}> = props => {
  const { record } = props
  const { t } = useTranslation()
  const content = (
    <UpdatedByBox>
      <AuthorLabel>{t(R.strings.search_appointment_updated_by)}</AuthorLabel>
      {record?.histories?.map((appointment, index) => {
        return (
          <UpdatedByList key={index}>
            <UpdatedByAuthor>
              <TableAvatar alt={appointment?.createdBy?.name} src={appointment?.createdBy?.avatar} />
              <UpdatedInfo>
                <AuthorInfo>
                  <AuthorName>{appointment?.createdBy?.name}</AuthorName>
                  <AuthorJobName>{appointment?.createdBy?.jobTitle}</AuthorJobName>
                </AuthorInfo>
              </UpdatedInfo>
            </UpdatedByAuthor>
            <UpdatedByLabel>
              {moment(appointment?.createdAt).format(DATE.DD_MM_YYYY_HH_MM)}
            </UpdatedByLabel>
          </UpdatedByList>
        )
      })}
    </UpdatedByBox>
  )
  return record.createdBy ? (
    <Author>
      <AuthorBox>
        <AuthorUser>
          <TableAvatar
            alt={record?.createdBy?.name}
            src={record?.createdBy?.avatar}
          />
          <AuthorInfo>
            <AuthorName>{record?.createdBy?.name}</AuthorName>
            <AuthorJobName>{record?.createdBy?.jobTitle}</AuthorJobName>
          </AuthorInfo>
        </AuthorUser>
        <AuthorLabel>
          <Popover content={content} title="" placement="topRight">
            <InfoCircleFilled rev={undefined} style={{ fontSize: 16 }} />
          </Popover>
        </AuthorLabel>
      </AuthorBox>
    </Author>
  ) : (
    <></>
  )
}

export default CreatedBy
