import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { CreateCampaignProvider } from './context'
import { RootLayout } from './RootLayout'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { useEffect } from 'react'
import { FunctionPermissionEnum, ModulePermissionEnum } from 'app/common/permission-module'

const CreateCampaignLayout = () => {
  const { user, isHavePermissionFunctionAndGoBack } =
    useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.CREATE_DISCOUNT_CAMPAIGN,
      R.strings.create_campaign,
    )
  }, [user])


  return (
    <CreateCampaignProvider>
      <S.BaseManagementWrapper>
        <HeaderPage titleI18nKey={R.strings.create_campaign} />

        <RootLayout />
      </S.BaseManagementWrapper>
    </CreateCampaignProvider>
  )
}

export default CreateCampaignLayout
