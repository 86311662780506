import { IItemManagement } from 'app/api/item/model/management'
import { DiscountTypeEnum } from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useUpdateCampaignContext } from '../../../../context'
import { ITreatmentInTable } from '../../../../type'
import { ProfileServiceComponent } from '../../components'
import { isEqual } from 'lodash'

export const useHook = () => {
  const { t } = useTranslation()
  const {
    listService,
    onDeleteService,
    onChangeService,
    onChangeDiscountType,
    onChangeDiscountValue,
    initListService,
    deleteList,
    addList,
    updateList,
    handleDownloadTemplateTreatment,
    isLoadingDownload,
  } = useUpdateCampaignContext()

  const [search, setSearch] = useState('')

  const data = useMemo(() => {
    return listService
      ?.filter(item => {
        return item?.name?.toLowerCase().includes(search.toLowerCase())
      })
      ?.map((item, index) => {
        return {
          ...item,
          index: index + 1,
          key: index,
        } as ITreatmentInTable
      })
  }, [listService, search])

  const columns = useMemo(() => {
    return [
      convertColumnTable<ITreatmentInTable>({
        title: t(R.strings.service),
        key: 'name',
        fixed: 'left',
        className: 'name-service-column',
        render: (_, record) => {
          return <ProfileServiceComponent itm={record} />
        },
      }),
      convertColumnTable<ITreatmentInTable>({
        title: t(R.strings.discount_value),
        key: 'discountValue',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => {
          return (
            <BaseFormItem hiddenLabel>
              <InputNumberWrapper
                value={record?.discountValue ?? 0}
                onChange={e => onChangeDiscountValue?.(e ? +e : 0, record?._id)}
                min={0}
                max={
                  isEqual(record?.discountType, DiscountTypeEnum.Percent)
                    ? 100
                    : undefined
                }
                precision={
                  isEqual(record?.discountType, DiscountTypeEnum.Percent)
                    ? 1
                    : 0
                }
                disabled={record?.discountType === undefined}
              />
            </BaseFormItem>
          )
        },
      }),
      convertColumnTable<ITreatmentInTable>({
        title: t(R.strings.discount_type),
        key: 'discountType',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => {
          return (
            <BaseFormItem hiddenLabel>
              <BaseSelect
                value={record?.discountType}
                placeholder={t(R.strings.select)}
                onChange={value =>
                  onChangeDiscountType?.(value as DiscountTypeEnum, record?._id)
                }
                options={[
                  {
                    label: t(R.strings.cash),
                    value: DiscountTypeEnum.Amount,
                  },
                  {
                    label: t(R.strings.discount_percent),
                    value: DiscountTypeEnum.Percent,
                  },
                ]}
              />
            </BaseFormItem>
          )
        },
      }),
      convertColumnTable<IItemManagement>({
        title: '',
        key: '_id',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          return (
            <BaseButton
              onClick={() => onDeleteService?.(record?._id)}
              icon={<SvgDeleteDesignIcon />}
              type="link"
            />
          )
        },
      }),
    ]
  }, [t, listService, initListService, deleteList, addList, updateList])

  const onChangeSearch = (value: string) => {
    setSearch(value)
  }

  return {
    columns,
    listService,
    data,
    onChangeSearch,
    search,
    onChangeService,
    handleDownloadTemplateTreatment,
    isLoadingDownload,
  }
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`
const InputNumberWrapper = styled(InputNumber)`
  width: 100%;
`
