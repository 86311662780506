import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormSkillModalData, IFormRule } from './type'
import { StatusSkillType } from '.'

interface IPropsUseModalHook {
  handleSubmit: (values) => boolean
}

export const useModalHook = ({ handleSubmit }: IPropsUseModalHook) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState<StatusSkillType | undefined>()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinish = async (values: IFormSkillModalData) => {
    if (!handleSubmit(values)) {
      return
    }

    setVisible(false)
    form.resetFields()
  }

  const rules: IFormRule = useMemo(() => {
    return {
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.skill_field_name),
          }),
        },
      ],
      industry: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.skill_field_industry),
          }),
        },
      ],
      skill: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.skill_field_industry),
          }),
        },
      ],
      levelSkill: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.skill_field_industry),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    showModal,
    onFinish,
    handleCancel,
    rules,
    setStatus,
    status,
  }
}
