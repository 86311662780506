import { CheckOutlined, EditOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BuyOneGetOneItem } from 'app/containers/Marketing/DiscountCampaignManagement/CreateCampaign/type'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const LabelCollapseComponent = ({
  item,
  onChange,
  isNotAllowEdit,
}: {
  item: BuyOneGetOneItem
  onChange?: (value: string) => void
  isNotAllowEdit?: boolean
}) => {
  const { t } = useTranslation()
  const [isEdit, setIsEdit] = useState(false)

  const onEnableEdit = () => {
    setIsEdit(!isEdit)
  }

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e.target.value)
  }

  return (
    <div
      onClick={e => {
        if (isEdit) {
          e.stopPropagation()
          setIsEdit(false)
        }
      }}
    >
      <BaseRow gutter={[16, 8]} align={'middle'}>
        <BaseCol>
          <div
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <BaseFormItem hiddenLabel>
              {isEdit ? (
                <BaseInput
                  placeholder={t(R.strings.enter_name)}
                  value={item?.name}
                  onChange={_onChange}
                />
              ) : (
                <BaseText
                  children={item?.name ?? 'Chưa nhập tên'}
                  fontWeight={item?.name ? 'semibold' : 'medium'}
                  opacity={item?.name ? '1' : '0.5'}
                  fontSize={item?.name ? 'xs' : 'xxs'}
                />
              )}
            </BaseFormItem>
          </div>
        </BaseCol>
        {!isNotAllowEdit ? (
          <BaseCol>
            <div onClick={e => e?.stopPropagation()}>
              <BaseButton
                onClick={onEnableEdit}
                icon={
                  isEdit ? (
                    <CheckOutlined rev={undefined} />
                  ) : (
                    <EditOutlined rev={undefined} />
                  )
                }
                type="text"
              />
            </div>
          </BaseCol>
        ) : null}
      </BaseRow>
    </div>
  )
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
