import { Timeline } from 'antd'
import { SvgCalendarDesignIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { ListDentalHealthHistory } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/constant/dental-health-history'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import { ActionAddDentalHealthHistory, DentalHealthHistoryItem } from './components'

export const DentalHealthHistoryLayout = () => {
  return (
    <RootWrapper>
      <BaseRow align={'middle'} justify={'end'}>
        <ActionAddDentalHealthHistory />
      </BaseRow>

      <Timeline>
        {ListDentalHealthHistory.map((item, index) => {
          return (
            <Timeline.Item
              key={index}
              children={
                <BaseSpace>
                  <BaseRow gutter={[10, 10]} align={'middle'}>
                    <SvgCalendarDesignIconStyled opacity={0.7} />
                    <BaseCol>
                      <BaseText
                        children={item?.createAt}
                        opacity="0.7"
                        fontWeight="semibold"
                      />
                    </BaseCol>
                  </BaseRow>

                  <DentalHealthHistoryItem item={item} />
                </BaseSpace>
              }
            />
          )
        })}
      </Timeline>
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseSpace)`
  padding: ${PADDING.xxs};
  background-color: #f9fafb; // need change
  border-radius: ${BORDER_RADIUS};
`

const SvgCalendarDesignIconStyled = styled(SvgCalendarDesignIcon)`
  path {
    fill: ${convertedVariables.primaryColor};
  }
`
