import { Popover } from 'antd'
import { HistoryOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import {
  ButtonHistory,
  ButtonHistoryStatus,
  HistoryText,
  HistoryTitle,
  HistoryUser,
  HistoryTable,
} from './styles'
import { IProps } from './type'
import moment from 'moment'
import { DATE } from 'app/common/constants'
import {
  AuthorInfo,
  AuthorJobName,
  AuthorName,
  AuthorUserLeft,
  TableAvatar,
} from 'app/containers/CustomerCare/SearchAppointment/styles'
import { ListBookingStatus } from 'app/constant'

const BookingHistoryContent = (data, t) => {
  const columns = [
    {
      title:  t(R.strings.employee),
      key: 'createdBy.name',
      render: (_, record) => (
        <HistoryUser>
          <AuthorUserLeft>
            <TableAvatar
              alt={record?.createdBy?.name}
              src={record?.createdBy?.avatar}
            />
            <AuthorInfo>
              <AuthorName>{record?.createdBy?.name}</AuthorName>
              <AuthorJobName>{record?.createdBy?.jobTitle}</AuthorJobName>
            </AuthorInfo>
          </AuthorUserLeft>
        </HistoryUser>
      ),
    },
    {
      title: t(R.strings.status),
      key: 'record.status',
      render: (_, record) => {
        const status = ListBookingStatus.find(
          item => item?.status === record?.status,
        )

        return (
          <ButtonHistoryStatus
            $background={status?.background}
            $border={status?.border}
          >
            {t(status?.label)}
          </ButtonHistoryStatus>
        )
      },
    },
    {
      title: t(R.strings.date_time),
      key: 'record.createdAt',
      render: (_, record) => (
        <HistoryText>
          {moment(record?.createdAt).format(DATE.YYYY_MM_DD_HH_MM)}
        </HistoryText>
      ),
    },
  ]

  return (
    <>
      <HistoryTable
        dataSource={data}
        columns={columns}
        pagination={false}
      ></HistoryTable>
    </>
  )
}

export const BookingHistory = ({ data }: IProps) => {
  const { t } = useTranslation()
  return (
    <Popover
      content={() => BookingHistoryContent(data?.histories, t)}
      title={<HistoryTitle>{t(R.strings.updated_history)}</HistoryTitle>}
      placement="topRight"
    >
      <ButtonHistory>
        <HistoryOutlined rev={false} />
      </ButtonHistory>
    </Popover>
  )
}
