import R from 'app/assets/R'
import { SvgRequireDoctorLevelIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { getArray } from 'app/components/tables/common-table/constant'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ItemTagDoctor = () => {
  return (
    <ItemWrapper align={'middle'} gutter={[16, 10]}>
      <BaseCol>
        <TagWrapper
          $borderColor={convertedVariables.statesOrangeColor}
          $backgroundColor={convertedVariables.statesOrangeLightColor}
        >
          <BaseText
            children={'Senior'}
            fontWeight="medium"
            colorText="statesOrangeColor"
          />
        </TagWrapper>
      </BaseCol>
      <BaseCol>
        <BaseText children={'Bác sĩ chỉnh nha 3A'} fontWeight="medium" />
      </BaseCol>
    </ItemWrapper>
  )
}

const ItemWrapper = styled(BaseRow)`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${PADDING.xxl};
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  padding: ${PADDING.xxxs};
  margin-left: 0px !important;
`

const TagWrapper = styled(BaseRow)<{
  $borderColor: string
  $backgroundColor: string
}>`
  border-radius: ${PADDING.xl};
  border: 1px solid ${props => props?.$borderColor};
  background-color: ${props => props?.$backgroundColor};
  padding: 4px 8px;
`

export const RequireDoctorLevelLayout = () => {
  const { t } = useTranslation()
  return (
    <RootWrapper>
      <HeaderWrapper>
        <BaseRow gutter={[16, 10]} align={'middle'}>
          <SvgRequireDoctorLevelIcon />
          <BaseCol>
            <BaseText
              children={t(R.strings.required_doctor_level)}
              fontWeight="semibold"
              fontSize="xs"
            />
          </BaseCol>
        </BaseRow>
      </HeaderWrapper>
      <ContentWrapper>
        <BaseRow gutter={[16, 16]} align={"middle"}>
          {getArray(3).map((_, index) => {
            return (
              <BaseCol key={index}>
                <ItemTagDoctor />
              </BaseCol>
            )
          })}
        </BaseRow>
      </ContentWrapper>
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  width: 100%;
`

const HeaderWrapper = styled.div`
  width: 100%;
  padding: ${PADDING.xl};
  padding-left: 60px;
  align-items: center;
  display: flex;
`

const ContentWrapper = styled.div`
  width: 100%;
  background-color: ${convertedVariables.backgroundColor};
  padding: ${PADDING.md};
  padding-left: 60px;
`
