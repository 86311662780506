import { DeleteIcon } from 'app/common/icons'
import { BaseRow } from 'app/components/common/BaseRow'
import { FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import styled from 'styled-components'

export const SkillWrapper = styled(BaseRow)`
  width: 100%;
  justify-content: space-between;
  display: flex;
  padding: 12px;
  border-radius: 1px;
  background-color: ${colors.white};
`

export const LevelTag = styled.div<{ color?: string }>`
  padding: 4px ${PADDING.xxxxs};
  border-radius: 20px;
  color: ${colors.white};
  background-color: ${props => (props.color ? props.color : colors.grayC4)};
  font-size: ${PADDING.xxxs};
  font-weight: ${FONT_WEIGHT.bold};

  &::before {
    content: '•';
    font-size: 100%;
    margin-right: 4px;
    font-weight: ${FONT_WEIGHT.black};
  }
`

export const SkillTitle = styled.div``

export const RemoveCircleIconStyled = styled(DeleteIcon)`
  cursor: pointer;
`

export const LevelTagGroup = styled.div`
  display: flex;
  min-width: 300px;
  justify-content: space-between;
`

export const ActionGroup = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
  border-left: 1px solid #ebeef2;
  margin-left: 12px;
`
