import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import styled from 'styled-components'
import { useHookTable } from './hook'
import { FilterLayout } from './layouts'
const ReportLeaveContainer = () => {
  const {
    tableData,
    columns,
    handleTableChange,
    filter,
    handleChangeFilter,
    loading,
    pagination,
  } = useHookTable()
  return (
    <S.BaseManagementWrapper>
      <HeaderPage titleI18nKey={R.strings.report_doctor_work_time} />
      <RootWrapper>
        <FilterLayout formData={filter} onChange={handleChangeFilter} />
        <BaseTableWrapper
          columns={columns}
          dataSource={tableData.data}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
        />
      </RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default ReportLeaveContainer

const RootWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
`

const BaseTableWrapper = styled(BaseTableManagement)`
  .ant-table-tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed
    > .ant-table-wrapper
    .ant-table {
    margin-block: -16px;
    margin-inline: 32px -16px;
  }

  .child-column {
    color: rgba(0, 34, 68, 0.4) !important;
  }

  .important-column {
    color: ${convertedVariables.statesRedColor} !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-radius: 0px !important;
  }

  .ant-table-thead > tr > th {
    font-weight: 600 !important;
    text-align: center !important;
  }
`
