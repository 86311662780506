/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetAllLevelSkillManagement } from 'app/api/level-skill'
import { LevelSkillMessageCode } from 'app/api/level-skill/constant'
import { ILevelSkillManagement } from 'app/api/level-skill/model/management'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useInfiniteQuery } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { LevelSkillKeys } from '../query-key/level-skill'
import { IPayLoadGetList } from 'app/common/interface'

export function useGetLevelSkillManagementList(
  params?: IPayLoadGetList,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<
    IResponseQueryList<ILevelSkillManagement[]>,
    undefined
  >(
    LevelSkillKeys.listLevelSkill(),
    async () => {
      try {
        const res: ResponseType<ILevelSkillManagement[]> =
          await requestGetAllLevelSkillManagement()

        if (
          isEqual(
            res?.msgcode,
            LevelSkillMessageCode.LevelSkillManagement.successGetData,
          )
        ) {
          const data: ILevelSkillManagement[] = res?.data || []
          return {
            data,
            total: data.length ?? 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
