/* eslint-disable @typescript-eslint/no-explicit-any */
import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { isEqual } from 'lodash'
import moment from 'moment'
import { SelectFilterEnum, initialPagination } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTableAreaHook } from './Area/hook'
import { useTableFromThenUtilNowHook } from './FromThenUtilNow/hook'

export const useTableHook = () => {
  const { t } = useTranslation()

  const [filterResponse, setFilterResponse] = useState<IResponseFilter>({
    year: `${moment().year()}`,
    month: `${moment().month() + 1}`,
  })

  const hookArea = useTableAreaHook({
    year: filterResponse.year,
    month: filterResponse?.month,
  })
  const hookFromThenUtilNowHook = useTableFromThenUtilNowHook()

  const expandedRowKeys = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.AREA:
        return hookArea.expandedRowKeys
      case SelectFilterEnum.FROM_THEN_UTIL_NOW:
        return hookFromThenUtilNowHook.expandedRowKeys
      default:
        return hookArea.expandedRowKeys
    }
  }, [filterResponse?.selectFilterType, hookArea, hookFromThenUtilNowHook])

  const tableData = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.AREA:
        return hookArea.tableData
      case SelectFilterEnum.FROM_THEN_UTIL_NOW:
        return hookFromThenUtilNowHook.tableData
      default:
        return hookArea.tableData
    }
  }, [filterResponse, hookArea, hookFromThenUtilNowHook])

  const handleTableChange = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.AREA:
        return hookArea.handleTableChange
      case SelectFilterEnum.FROM_THEN_UTIL_NOW:
        return hookFromThenUtilNowHook.handleTableChange
      default:
        return hookArea.handleTableChange
    }
  }, [filterResponse?.selectFilterType, hookArea, hookFromThenUtilNowHook])

  const handleExpand = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.AREA:
        return hookArea.handleExpand
      case SelectFilterEnum.FROM_THEN_UTIL_NOW:
        return hookFromThenUtilNowHook.handleExpand
      default:
        return hookArea.handleExpand
    }
  }, [filterResponse?.selectFilterType, hookArea, hookFromThenUtilNowHook])

  const handleChangeFilter = (filter: IResponseFilter) => {
    const { year } = filter
    setFilterResponse(filter)
    if (!isEqual(year, filterResponse?.year)) {
      hookArea.fetch(initialPagination, { year })
    }
    hookFromThenUtilNowHook.fetch(initialPagination)
  }

  const columns = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.AREA:
        return hookArea.columns
      case SelectFilterEnum.FROM_THEN_UTIL_NOW:
        return hookFromThenUtilNowHook.columns
      default:
        return hookArea.columns
    }
  }, [t, expandedRowKeys, filterResponse?.selectFilterType])

  const exportExcel = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.AREA:
        return hookArea.exportExcel
      case SelectFilterEnum.FROM_THEN_UTIL_NOW:
        return hookFromThenUtilNowHook.exportExcel
      default:
        return hookArea.exportExcel
    }
  }, [filterResponse])

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleChangeFilter,
    filterResponse,
    exportExcel,
  }
}
