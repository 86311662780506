import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import * as S from './styles'
import 'dayjs/locale/vi'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { BaseManagementWrapper } from 'app/components/tables/BaseTableManagement/styles'
import { FilterSurveyReport } from './components/FilterSurveyReport'
import { t } from 'i18next'
import { ChartByDay } from './components/ChartByDay'
import ChartNeedToImprove from './components/ChartNeedToImprove'
import ChartNetPromoterScore from './components/ChartNetPromoterScore'
import { useReportHook } from './hook'

const SurveyReportContainer: React.FC = () => {
  const { handleChangeFilter, filter } = useReportHook()

  return (
    <BaseManagementWrapper>
      <HeaderPage titleI18nKey={R.strings.survey_report} />
      <S.RootWrapper>
        <S.WrapperSectionFilter>
          <S.Title>{t(R.strings.overview)}</S.Title>
          <FilterSurveyReport handleChangeFilter={handleChangeFilter} />
        </S.WrapperSectionFilter>
        <S.WrapperSection>
          <S.Title>{t(R.strings.survey_report_content_by_day)}</S.Title>
          <ChartByDay
            location={filter.location}
            area={filter.area}
            clinic={filter.clinic}
          />
        </S.WrapperSection>
        <S.WrapperSection>
          <S.Title>{t(R.strings.survey_report_content_by_day)}</S.Title>
          <ChartNeedToImprove
            location={filter.location}
            area={filter.area}
            clinic={filter.clinic}
          />
        </S.WrapperSection>
        <S.WrapperSection>
          <S.Title>
            {t(R.strings.survey_report_content_next_promotor_score)}
          </S.Title>
          <ChartNetPromoterScore
            location={filter.location}
            area={filter.area}
            clinic={filter.clinic}
          />
        </S.WrapperSection>
      </S.RootWrapper>
    </BaseManagementWrapper>
  )
}

export default SurveyReportContainer
