import R from 'app/assets/R'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { OralHygieneToolsYouUsuallyUseEnum } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/dental-health-history'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const CheckBoxItem: React.FC<{
  keyEnum: OralHygieneToolsYouUsuallyUseEnum
  isDisable?: boolean
  onChange?: (key: OralHygieneToolsYouUsuallyUseEnum, value: boolean) => void
  listSelected?: OralHygieneToolsYouUsuallyUseEnum[]
}> = ({ keyEnum, isDisable, onChange, listSelected, ...props }) => {
  const { t } = useTranslation()

  const keyI18n = useMemo(() => {
    switch (keyEnum) {
      case OralHygieneToolsYouUsuallyUseEnum.RegularToothbrush:
        return R.strings.regular_toothbrush
      case OralHygieneToolsYouUsuallyUseEnum.ElectricToothbrush:
        return R.strings.electric_toothbrush
      case OralHygieneToolsYouUsuallyUseEnum.DentalFloss:
        return R.strings.dental_floss
      case OralHygieneToolsYouUsuallyUseEnum.Toothpick:
        return R.strings.toothpick
      case OralHygieneToolsYouUsuallyUseEnum.InterdentalBrush:
        return R.strings.interdental_brush
      case OralHygieneToolsYouUsuallyUseEnum.TongueScraper:
        return R.strings.tongue_scraper
      case OralHygieneToolsYouUsuallyUseEnum.Mouthwash:
        return R.strings.mouthwash
      case OralHygieneToolsYouUsuallyUseEnum.WaterFlosser:
        return R.strings.water_flosser
    }
  }, [keyEnum])

  const _onChange = (value: boolean) => {
    onChange?.(keyEnum, value)
  }

  const isSelected = useMemo(() => {
    return !!listSelected?.find(itm => isEqual(itm, keyEnum))
  }, [listSelected, keyEnum])

  return (
    <BaseCol xl={6} {...props}>
      <BaseRow gutter={10} wrap={false} align={'middle'}>
        <BaseCol>
          <CheckBoxWrapper
            disabled={isDisable}
            checked={isSelected}
            onChange={e => _onChange(e?.target?.checked)}
          />
        </BaseCol>
        <BaseCol>
          <BaseText
            children={`${t(keyI18n)}`}
            opacity={isDisable ? '0.7' : '1'}
            fontWeight="medium"
          />
        </BaseCol>
      </BaseRow>
    </BaseCol>
  )
}

export const ContentItem: React.FC<{
  isChecked?: boolean
  key18n: string
  oriNumber: number
  isDisable?: boolean
  onChange?: (key: string, value: boolean) => void
}> = ({ key18n, isChecked, oriNumber, isDisable, onChange, ...props }) => {
  const { t } = useTranslation()

  const _onChange = (value: boolean) => {
    onChange?.(key18n, value)
  }
  return (
    <BaseRow
      gutter={[10, 10]}
      align={'top'}
      justify={'space-between'}
      {...props}
    >
      <BaseCol xl={23}>
        <BaseText
          children={`${oriNumber}. ${t(key18n)}`}
          opacity={isDisable ? '0.7' : '1'}
          fontWeight="medium"
        />
      </BaseCol>
      <BaseCol xl={1}>
        <CheckBoxWrapper
          checked={!!isChecked}
          disabled={isDisable}
          onChange={e => _onChange(e.target.checked)}
        />
      </BaseCol>
    </BaseRow>
  )
}

const CheckBoxWrapper = styled(BaseCheckbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${convertedVariables.main2Color};
    opacity: 0.5;
    border: 1px solid rgba(${convertedVariables.main2Color}, 0.5);
    &::after {
      border-color: ${convertedVariables.backgroundColor} !important;
      inset-inline-start: 30.5%;
    }
  }
`
