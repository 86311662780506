import { BaseButton } from 'app/components/common/BaseButton'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  width: 100%;
  padding: 16px;
  background-color: var(--white);

  .ant-space-item {
    width: 100%;
  }
`

export const TitleSupplies = styled.div`
  color: var(--text-main-color);
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.xxs};
`

export const AreaAddNewSupplies = styled(BaseRow)`
  width: 100%;
`

export const FormInputNumber = styled(InputNumber)`
  width: 100%;
`
export const SelectBaseStyled = styled(BaseSelect)``

export const FormItem = styled(BaseForm.Item)``

export const ButtonAddNewSupplies = styled(BaseButton)``

export const ListSuppliesWrapper = styled(BaseSpace)`
  width: 100%;
  padding: ${PADDING.xxxxs} ${PADDING.xxs};
  border-radius: ${BORDER_RADIUS};
  background-color: #f8f8f8; // need change
`
