import { CalendarOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import {
  ContentModalAppointmentSession,
  ModalComponent,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/components'
import { useTranslation } from 'react-i18next'
import { useHook } from './hook'
import { IAppointmentSession } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'

interface IProps {
  handleSubmit?: (item: IAppointmentSession) => void
}

export const AddAppointmentSessionModal = ({ handleSubmit }: IProps) => {
  const { t } = useTranslation()

  const {
    form,
    isDisableSubmit,
    listTreatment,
    onAddTreatment,
    onChangeTreatment,
    onDeleteTreatment,
    onSubmit,
    refModal,
  } = useHook({ handleSubmit })

  return (
    <ModalComponent
      iconOpenModal={
        <BaseCol>
          <CalendarOutlined rev={undefined} />
        </BaseCol>
      }
      titleOpenModal={t(R.strings.add_appointment_session)}
      titleModal={t(R.strings.add_appointment_session)}
      isShowLineBottom={false}
      isShowLineTop={false}
      paddingContent={`0px`}
      widthModal={1300}
      ref={refModal}
      isDisableSubmit={isDisableSubmit}
      handleSubmit={form?.submit}
      renderContent={
        <ContentModalAppointmentSession
          form={form}
          onAddTreatmentInAppointmentSession={onAddTreatment}
          listTreatment={listTreatment}
          onDelete={onDeleteTreatment}
          handleSubmit={onSubmit}
          onChangeTreatmentInAppointmentSession={onChangeTreatment}
        />
      }
    />
  )
}
