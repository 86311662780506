import R from 'app/assets/R'
import {
  IResponseFilter,
  initResponseFilterTableManagement,
} from 'app/components/tables/BaseTableManagement/FilterManagementTable/type'
import {
  RenderValueTable,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { isEmpty, isEqual } from 'lodash'
import { Pagination, moment } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IGeneralPatientOudatedManagementTable } from './type'

import { requestPatientOutdated } from 'app/api/accounting/api/patient-outdated'
import {
  IParamsGetPatientOutdated,
  IResponsePatientOutdated,
} from 'app/api/accounting/model/patient-outdated'
import { MessageCode } from 'app/api/inventory/constant'
import { ENV_CONFIG } from 'app/common/config'
import { DATE } from 'app/common/constants'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { initPagination } from 'app/constant'
import { ALink } from '../DailyInvoice/styles'

export const useHookTable = () => {
  const { t } = useTranslation()

  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.ACCOUNTING,
      FunctionPermissionEnum.READ_PATIENT_OUTDATED,
      R.strings.role,
    )
  }, [])

  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IResponseFilter,
  ) => {
    const initData = {
      pagination: pagination ?? initPagination,
      data: [],
    } as IDataBaseTable<IGeneralPatientOudatedManagementTable>
    try {
      let params: IParamsGetPatientOutdated = {
        page: pagination?.current,
        pagesize: pagination?.pageSize,
        keyword: newFilter?.search ?? filter?.search,
      }

      params = { ...params, ...newFilter }

      const response: IResponsePatientOutdated = await requestPatientOutdated(
        params,
      )

      if (isEqual(response?.msgcode, MessageCode.successGetData)) {
        const data = response?.data
        const metadata = response?.metadata

        const startIndex =
          ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

        const transformData = data => {
          if (isEmpty(data)) {
            return data
          }

          return data?.map((item, index) => {
            return {
              key: `${item?._id}-${index + 1}`,
              ordinalNumber: startIndex + index + 1,
              date: item?.date ?? '-',
              netAmount: item?.netAmount ?? '-',
              patient: item?.patient ?? '-',
              treatmentTotal: item?.treatmentTotal ?? '-',
              updatedAt: item?.updatedAt ?? '-',
              updatedBy: item?.updatedBy,
              _id: item?._id ?? '-',
            }
          })
        }

        const dataTables = transformData(data)

        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pageSize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IGeneralPatientOudatedManagementTable>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, selectRowsTable, fetch, rowSelection } =
    useHookTableManagement({ getDataTable })

  const handleTableChange = pagination => {
    fetch(pagination, filter)
  }

  const handleChangeFilter = (newFilter: IResponseFilter) => {
    if (!isEqual(newFilter, filter)) {
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initPagination.current,
        },
        newFilter,
      )
    }
  }

  const fetchNewData = () => {
    fetch(initPagination)
    setFilter(initResponseFilterTableManagement)
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralPatientOudatedManagementTable>({
        title: t(R.strings.ordinal_number),
        key: 'ordinalNumber',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => (
          <RenderValueTable value={record.ordinalNumber} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralPatientOudatedManagementTable>({
        title: t(R.strings.updated_at),
        key: 'updatedAt',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={moment(record?.updatedAt).format(DATE.DD_MM_YYYY_HH_MM)}
            type="Base"
          />
        ),
      }),

      convertColumnTable<IGeneralPatientOudatedManagementTable>({
        title: t(R.strings.date_incorrect),
        key: 'date',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={moment(record?.date).format(DATE.DD_MM_YYYY_HH_MM)}
            type="Base"
          />
        ),
      }),

      convertColumnTable<IGeneralPatientOudatedManagementTable>({
        title: t(R.strings.updated_by),
        key: 'updatedBy',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.updatedBy?.name} type="Main" />
        ),
      }),

      convertColumnTable<IGeneralPatientOudatedManagementTable>({
        title: t(R.strings.patient_name),
        key: 'patient',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <ALink
            onClick={e => {
              e.preventDefault()
              window.open(
                ENV_CONFIG.OLD_WEBSITE_ENDPOINT +
                  '/patients/details/' +
                  record?.patient?._id,
              )
            }}
          >
            {record?.patient?.name}
          </ALink>
        ),
      }),
      convertColumnTable<IGeneralPatientOudatedManagementTable>({
        title: t(R.strings.net_amount),
        key: 'netAmount',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.netAmount} type="Number" />
        ),
      }),

      convertColumnTable<IGeneralPatientOudatedManagementTable>({
        title: t(R.strings.net_amount_edited),
        key: 'treatmentTotal',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.treatmentTotal} type="Number" />
        ),
      }),
    ]
    return option
  }, [t, fetchNewData])

  return {
    tableData,
    selectRowsTable,
    handleTableChange,
    fetchNewData,
    rowSelection,
    columns,
    handleChangeFilter,
    filter,
  }
}
