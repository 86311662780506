import R from 'app/assets/R'
import { BasicRecord } from '../BasicRecord'

export const PatientLeave = ({ timeString }: { timeString?: string }) => {
  return (
    <BasicRecord
      labelKeyI18n={R.strings.the_patient_leaves}
      timeString={timeString}
    />
  )
}
