import { StatusTableType } from 'app/components/tables/BaseTableManagement/hook'

export interface IResponseFilter {
  status?: StatusTableType[]
  search?: string
}

export interface IFilterProps {
  handleChangeFilter?: (data: IResponseFilter) => void
  filterData?: IResponseFilter
}

export const initResponseFilterTableManagement = {
  search: '',
}
