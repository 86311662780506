// Message code define
export const HumanMessageCode = {
  AccountManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20001',
  },
}

// Endpoint
export const HumanEndPoint = {
  AccountManagement: {
    request: 'account/v1/user',
    requestChangePassword: 'account/v1/user/change-password/:id',
  },
}
