import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import AdditionalServiceDiscountCampaignContainer from 'app/containers/Marketing/DiscountCampaignManagement/AdditionalServiceCampaign'

const AdditionalBillDiscountCampaignPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.additional_bill_discount)}</PageTitle>
      <AdditionalServiceDiscountCampaignContainer />
    </>
  )
}
const AdditionalBillDiscountCampaignPage = memo(
  AdditionalBillDiscountCampaignPageCpn,
  isEqual,
)

export default AdditionalBillDiscountCampaignPage
