import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { LeftSideValueTable, RightSideValueTable } from '../../styles'
import { ItemDiagnosis } from '../../components'

export const BoneLayout = () => {
  return (
    <BaseRow>
      <LeftSideValueTable xl={3} className="child-wrapper">
        <BaseText children={'Xương (I)'} fontWeight="medium" fontSize="xs" />
      </LeftSideValueTable>
      <RightSideValueTable xl={21} className="child-wrapper">
        <BaseSpace size={16}>
          <BaseRow gutter={10} align="top">
            <BaseCol xl={8}>
              <ItemDiagnosis
                label="Hạng mặt:"
                stt={2}
                placeholder={R.strings.enter_content}
              />
            </BaseCol>
            <BaseCol xl={8}>
              <ItemDiagnosis
                label="Xu hướng phát triển:"
                stt={1}
                placeholder={R.strings.enter_content}
              />
            </BaseCol>
            <BaseCol xl={8}>
              <ItemDiagnosis
                label="Xương hàm trên:"
                stt={1}
                placeholder={R.strings.enter_content}
              />
            </BaseCol>
          </BaseRow>

          <BaseRow gutter={10} align="top">
            <BaseCol xl={8}>
              <ItemDiagnosis
                label={'Kích thước xương Hàm trên:'}
                stt={2}
                placeholder={R.strings.enter_content}
              />
            </BaseCol>
            <BaseCol xl={8}>
              <ItemDiagnosis
                label="Cant mặt phẳng hàm trên:"
                stt={2}
                placeholder={R.strings.enter_content}
              />
            </BaseCol>
            <BaseCol xl={8}>
              <ItemDiagnosis
                label="Xương Hàm dưới:"
                stt={2}
                placeholder={R.strings.enter_content}
              />
            </BaseCol>
          </BaseRow>

          <BaseRow gutter={10} align="top">
            <BaseCol xl={8}>
              <ItemDiagnosis
                label={'Kích cỡ xương hàm dưới:'}
                stt={3}
                placeholder={R.strings.enter_content}
              />
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      </RightSideValueTable>
    </BaseRow>
  )
}
