import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const TooltipWrapper = styled.div`
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: ${PADDING.xxxxs};
`

export const TooltipHeader = styled.div`
  display: flex;
  font-size: ${FONT_SIZE.xxs};
  font-style: normal;
  font-weight: ${FONT_WEIGHT.medium};
  align-content: center;
  align-items: center;
  gap: 6px;
`

export const TooltipStar = styled.span`
  font-size: ${FONT_SIZE.xl};
  font-style: normal;
  font-weight: ${FONT_WEIGHT.semibold};
`
export const TooltipTitleStar = styled.span``

export const TooltipNumberStar = styled.span`
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.semibold};
`
export const TooltipFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${FONT_SIZE.xxs};
`
export const TooltipTotal = styled.span`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.bold};
  color: #216ae2;
`
