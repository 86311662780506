// Message code define
export const OldDBMessageCode = {
  Doctors: {
    successGetData: '20000',
  },
  TreatmentGroup: {
    successGetData: '20000',
  }
}

// Endpoint
export const OldDBEndPoint = {
  Doctors: {
    requestGetData: 'report/v1/old-db-users/doctors',
  },
  TreatmentGroup: {
    requestGetData: 'olddb/v1/treatment-group/search',
  }
}
