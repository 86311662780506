import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useModalHook } from './hook'
import * as S from './styles'
import { IPropsRecallBooking, initValues } from './type'

export const Recall = (props: IPropsRecallBooking) => {
  const { form, visible, t, showModal, handleCancel, rules } =
    useModalHook(props)

  const { handleSubmit, loading } = props

  const renderOpenModal = (() => {
    return (
      <S.ButtonAction className="recall" onClick={showModal}>
        {t(R.strings.recall)}
      </S.ButtonAction>
    )
  })()

  return (
    <S.ModalStyled
      isLoading={loading}
      typeButtonOpen="update"
      titleButton={t(R.strings.recall)}
      titleModal={<S.ModalTitle>{t(R.strings.recall)}</S.ModalTitle>}
      widthModal={300}
      renderOpenModal={renderOpenModal}
      content={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <S.FormItem
            name="recallAt"
            label={t(R.strings.booking_field_date)}
            rules={rules.date}
            required
          >
            <S.BaseDatePickerStyled
              showTime={{ format: 'HH:mm' }}
              placeholder={t(R.strings.degree_content_start)}
            />
          </S.FormItem>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.update}
      confirmKeyI18n={R.strings.update}
      handleSubmit={form.submit}
      visible={visible}
    />
  )
}
