/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DeleteOutlined } from '@ant-design/icons'
import type { UploadFile } from 'antd'
import { Modal } from 'antd'
import R from 'app/assets/R'
import { SvgImageExampleIcon } from 'app/assets/svg-assets'
import { DRIVE_ACTION } from 'app/common/config'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { FONT_SIZE } from 'parkway-web-common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCreateUpdateServiceContext } from '../../context'
import * as S from './styles'
import { useParams } from 'react-router'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { get } from 'lodash'
import BaseText from 'app/components/common/BaseText'

const getBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file as Blob)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })

const Images = () => {
  const { t } = useTranslation()
  const { type: typeParam } = useParams()

  const {
    handleChangeListImage,
    onChangeMainImage,
    listImage,
    mainImage,
    form,
  } = useCreateUpdateServiceContext()

  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as Blob)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
    setPreviewTitle(
      file.name || file?.url!.substring(file.url!.lastIndexOf('/') + 1),
    )
  }

  const handleCancel = () => setPreviewOpen(false)

  useEffect(() => {
    form?.setFieldsValue({
      mainImagesUrl: get(mainImage, '[0].response.data.path'),
      detailImagesUrl: listImage?.map(image => image?.response?.data?.path),
    })
  }, [listImage, mainImage])

  return (
    <S.RootWrapper size={16}>
      <S.TitleImageService>
        {t(R.strings.item_management_content_image_type)?.replace(
          '{{type}}',
          t(R.strings[String(typeParam)])?.toLowerCase(),
        )}
      </S.TitleImageService>
      <S.SplitLine />
      <S.ChooseImageWrapper gutter={[20, 10]}>
        <BaseForm.Item name="mainImagesUrl">
          <S.MainImageWrapper
            action={DRIVE_ACTION.UPLOAD_IMAGE}
            fileList={mainImage}
            maxCount={1}
            progress={{ strokeWidth: 6, showInfo: true }}
            onPreview={handlePreview}
            onChange={onChangeMainImage}
            listType="picture-card"
            showUploadList={{ removeIcon: <DeleteOutlined rev={undefined} /> }}
          >
            <S.UploadWrapper direction="vertical" size={10}>
              <SvgImageExampleIcon />
              <BaseRow gutter={[10, 10]} align={'middle'} justify={'center'}>
                <BaseCol>
                  <S.PlusIcon rev={undefined} />
                </BaseCol>
                <BaseCol>
                  <BaseText
                    fontWeight="medium"
                    fontSize="xs"
                    colorText="collapseBackgroundColor"
                  >
                    {t(R.strings.add_main_image)}
                  </BaseText>
                </BaseCol>
              </BaseRow>
            </S.UploadWrapper>
          </S.MainImageWrapper>
        </BaseForm.Item>
        <BaseCol xl={17}>
          <BaseRow gutter={[20, 10]} align={'middle'}>
            <BaseForm.Item name="detailImagesUrl">
              <S.ImageWrapper
                action={DRIVE_ACTION.UPLOAD_IMAGE}
                listType="picture-card"
                fileList={listImage}
                onPreview={handlePreview}
                onChange={handleChangeListImage}
              >
                <S.UploadWrapper direction="vertical" size={5}>
                  <SvgImageExampleIcon width={36} height={36} />
                  <BaseRow
                    gutter={[10, 10]}
                    align={'middle'}
                    justify={'center'}
                  >
                    <BaseCol>
                      <S.PlusIcon $size={FONT_SIZE.xxs} rev={undefined} />
                    </BaseCol>
                    <BaseCol>
                      <BaseText
                        fontWeight="medium"
                        fontSize="xs"
                        colorText="collapseBackgroundColor"
                      >
                        {t(R.strings.add_image)}
                      </BaseText>
                    </BaseCol>
                  </BaseRow>
                </S.UploadWrapper>
              </S.ImageWrapper>
            </BaseForm.Item>
          </BaseRow>
        </BaseCol>
      </S.ChooseImageWrapper>
      <BaseText
        children={t(R.strings.note_size_image, { size: 5 })}
        fontWeight="medium"
        opacity="0.7"
      />
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </S.RootWrapper>
  )
}

export default Images
