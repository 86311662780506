import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import Report011Container from 'app/containers/Report/Report011'

const Report011PageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.report_011)}</PageTitle>
      <Report011Container />
    </>
  )
}
const Report011Page = memo(Report011PageCpn, isEqual)

export default Report011Page
