import { Rule } from 'antd/es/form'
import { IWarehouse } from 'app/api/inventory-new/model/management'



export interface IFormRule {
  code?: Rule[]
  roadWarehouseCode?: Rule[]
  name?: Rule[]
  unitId?: Rule[]
  status?: Rule[]
}

export const initValues: IWarehouse = {
  _id: '',
  name: '',
}

export enum StatusWarehouseEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
  deleted = 'deleted',
}

export type StatusWarehouseType = keyof typeof StatusWarehouseEnum

export interface IPropsUpdateWarehouse {
  fetchTable: () => void
  id?: string | number
  warehouse: IWarehouse
}
