import { ApiClientHr } from 'app/service/api-service'
import { SkillEndPoint } from './constant'
import {
  IParamsGetSkillManagement,
  IPayloadCreateSkillManagement,
  IPayloadUpdateSkillManagement,
} from './model/management'

export const requestGetSkillManagement = (params: IParamsGetSkillManagement) =>
  ApiClientHr.Get({
    url: SkillEndPoint.SkillManagement.request,
    params: { params },
  })

export const requestCreateSkillManagement = (
  body: IPayloadCreateSkillManagement,
) =>
  ApiClientHr.Post({
    url: SkillEndPoint.SkillManagement.request,
    body,
  })

export const requestUpdateSkillManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateSkillManagement
}) =>
  ApiClientHr.Put({
    url: `${SkillEndPoint.SkillManagement.request}/${id}`,
    body,
  })

export const requestDetailSkillManagement = (id?: string | number) =>
  ApiClientHr.Get({
    url: `${SkillEndPoint.SkillManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteSkillManagement = (id?: string | number) =>
  ApiClientHr.Delete({
    url: `${SkillEndPoint.SkillManagement.request}/${id}`,
    body: {},
  })

export const requestGetAllSkillManagement = () =>
  ApiClientHr.Get({
    url: `${SkillEndPoint.SkillManagement.request}/get-all`,
    params: {},
  })
