import { DownloadOutlined, PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useCreateCampaignContext } from 'app/containers/Marketing/DiscountCampaignManagement/CloneCampaign/context'
import { ITreatmentInTable } from 'app/containers/Marketing/DiscountCampaignManagement/CloneCampaign/type'
import {
  ModalAddAndUpdateSelectedTreatment,
  SplitDivider,
} from 'app/containers/Marketing/DiscountCampaignManagement/components'
import { IBundleServiceItem } from 'app/containers/Marketing/VoucherManagement/CreateVoucher/type'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  LabelCollapseComponent,
  ProfileServiceComponent,
} from '../../../../components'
import { ImportBundle } from './ImportModal'

export const BundleBillDiscountTypeLayout = () => {
  const { t } = useTranslation()
  const {
    onAddNewBundleBillDiscountService,
    listBundleBillDiscountService,
    onRemoveBundleBillDiscountServiceByIndex,
    onChangeNameBundleBillDiscountByIndex,
    onChangeItemBundleBillDiscountServiceByIndex,
    onDeleteItemInSelectedTreatmentBundleBillDiscount,
    isLoading,
    handleGetImportTemplate,
  } = useCreateCampaignContext()

  const onChangeSelectedTreatment = ({
    index,
    service,
    item,
  }: {
    service?: ITreatmentInTable[]
    item?: IBundleServiceItem
    index?: number
  }) => {
    if (index !== undefined && item) {
      onChangeItemBundleBillDiscountServiceByIndex?.(index, {
        ...item,
        selectedTreatment: service,
      })
    }
  }

  return (
    <RootWrapper>
      <HeaderWrapper
        gutter={[16, 8]}
        align={'middle'}
        justify={'space-between'}
      >
        <BaseCol>
          <BaseText
            children={t(R.strings.service_apply)}
            fontWeight="semibold"
            fontSize="md"
          />
        </BaseCol>

        <BaseCol>
          <BaseRow gutter={[16, 8]} align={'middle'}>
            <BaseCol>
              <BaseButton
                icon={<DownloadOutlined rev={undefined} />}
                onClick={handleGetImportTemplate}
                loading={isLoading}
              >
                {t(R.strings.download_example_import_file)}
              </BaseButton>
            </BaseCol>
            <BaseCol>
              <ImportBundle />
            </BaseCol>
            <BaseCol>
              <BaseButton
                icon={<PlusOutlined rev={undefined} />}
                onClick={onAddNewBundleBillDiscountService}
              >
                {t(R.strings.add_group)}
              </BaseButton>
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </HeaderWrapper>
      <ContentWrapper split={<SplitDivider text="or" />}>
        {listBundleBillDiscountService?.length ? (
          listBundleBillDiscountService?.map((item, index) => {
            return (
              <CollapseWrapper
                items={[
                  {
                    key: index,
                    label: (
                      <LabelCollapseComponent
                        item={item}
                        key={index}
                        onChange={value =>
                          onChangeNameBundleBillDiscountByIndex?.(index, value)
                        }
                      />
                    ),
                    extra: (
                      <div onClick={e => e.stopPropagation()}>
                        <BaseRow gutter={[16, 8]} align={'middle'} key={index}>
                          <BaseCol>
                            <ModalAddAndUpdateSelectedTreatment
                              services={item?.selectedTreatment}
                              onChangeService={service =>
                                onChangeSelectedTreatment({
                                  index,
                                  service,
                                  item,
                                })
                              }
                            />
                          </BaseCol>
                          <BaseCol>
                            <BaseButton
                              onClick={() =>
                                onRemoveBundleBillDiscountServiceByIndex?.(
                                  index,
                                )
                              }
                              icon={<SvgDeleteDesignIcon />}
                            />
                          </BaseCol>
                        </BaseRow>
                      </div>
                    ),
                    style: {
                      alignItems: 'center',
                    },
                    children: (
                      <BaseSpace size={0}>
                        <BaseRow
                          gutter={[16, 8]}
                          align={'middle'}
                          className="header-content"
                          justify={'space-between'}
                        >
                          <BaseCol xl={22}>
                            <BaseText
                              children={t(R.strings.buy_service)}
                              fontWeight="semibold"
                            />
                          </BaseCol>

                          <BaseCol xl={2} />
                        </BaseRow>

                        <BaseSpace
                          className="list-item-content-wrapper"
                          split={<SplitDivider text="and" />}
                        >
                          {item?.selectedTreatment?.length ? (
                            item?.selectedTreatment?.map((itm, idx) => {
                              return (
                                <BaseRow
                                  key={idx}
                                  align={'middle'}
                                  gutter={[16, 8]}
                                  justify={'space-between'}
                                >
                                  <BaseCol xl={22}>
                                    <ProfileServiceComponent itm={itm} />
                                  </BaseCol>

                                  <BaseCol xl={2}>
                                    <BaseRow justify={'center'}>
                                      <BaseButton
                                        icon={<SvgDeleteDesignIcon />}
                                        type="text"
                                        onClick={() =>
                                          onDeleteItemInSelectedTreatmentBundleBillDiscount?.(
                                            index,
                                            idx,
                                          )
                                        }
                                      />
                                    </BaseRow>
                                  </BaseCol>
                                </BaseRow>
                              )
                            })
                          ) : (
                            <BaseText
                              children={t(R.strings.empty_list)}
                              fontWeight="medium"
                              opacity="0.5"
                            />
                          )}
                        </BaseSpace>
                      </BaseSpace>
                    ),
                  },
                ]}
                expandIconPosition="right"
                key={index}
              />
            )
          })
        ) : (
          <BaseEmpty />
        )}
      </ContentWrapper>
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
`

const HeaderWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
  border-start-start-radius: ${BORDER_RADIUS};
`

const ContentWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
  background-color: #f9fafb;
  border-start-end-radius: ${BORDER_RADIUS};
  .dash-line {
    border-top: 1px dashed ${convertedVariables.neutralBlack2Color};
  }
`
const CollapseWrapper = styled(BaseCollapse)`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};

  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-content {
    border-top: 1px solid ${convertedVariables.neutralBlack9Color};
    background-color: ${convertedVariables.neutralBlack9Color};
    .ant-collapse-content-box {
      padding: 0px !important;
    }
  }

  .ant-collapse-header {
    background-color: var(--background-color);
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background-color: #f9fafb;
  }

  .list-item-content-wrapper {
    padding: ${PADDING.md};
    background-color: ${convertedVariables.backgroundColor};
  }
`
