import { BaseSpace } from 'app/components/common/BaseSpace'
import {
  MailBoxComponent,
  NewsComponent,
  NotificationComponent,
  TaskComponent,
} from 'app/containers/Dashboard/DashboardBod/components'
import { BORDER_RADIUS, LAYOUT, PADDING, media } from 'parkway-web-common'
import styled from 'styled-components'

export const RightSideBodLayout = () => {
  return (
    <RightSideCol>
      <BaseSpace>
        <NotificationComponent />
        <TaskComponent />
        <MailBoxComponent />
        <NewsComponent />
      </BaseSpace>
    </RightSideCol>
  )
}

const RightSideCol = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: ${BORDER_RADIUS};
  margin-left: ${PADDING.md};
  min-width: 260px;
  @media only screen and ${media.xl} {
    height: calc(100vh - ${LAYOUT.desktop.headerHeight} - 10vh);
    overflow: auto;
  }
`
