import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import Report010Container from 'app/containers/Report/Report010'

const Report010PageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.report_010)}</PageTitle>
      <Report010Container />
    </>
  )
}
const Report010Page = memo(Report010PageCpn, isEqual)

export default Report010Page
