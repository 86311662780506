import R from 'app/assets/R'
import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { ITreatmentInTable } from 'app/containers/Marketing/DiscountCampaignManagement/CreateCampaign/type'
import { BORDER_RADIUS, formatMoney } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'

export const ProfileServiceComponent = ({
  itm,
}: {
  itm?: ITreatmentInTable
}) => {
  const { t } = useTranslation()
  return (
    <BaseRow gutter={16} wrap={false} align={'middle'}>
      <BaseCol>
        <BaseAvatar
          alt={itm?.name}
          style={{
            borderRadius: BORDER_RADIUS,
          }}
          size={42}
        />
      </BaseCol>
      <BaseCol>
        <BaseSpace size={4}>
          <BaseText
            children={itm?.name ?? '-'}
            fontWeight="semibold"
            fontSize="xs"
          />
          <BaseRow gutter={[16, 8]}>
            {itm?.regionalPrice?.map((region, indxRegion) => {
              return (
                <BaseCol key={indxRegion}>
                  <BaseRow align={'middle'} gutter={8}>
                    <BaseCol>
                      <BaseText
                        children={`${t(R.strings.region)} ${region?.region}:`}
                        fontWeight="medium"
                      />
                    </BaseCol>
                    <BaseCol>
                      <BaseText
                        children={formatMoney(region?.price ?? '')}
                        fontWeight="medium"
                        colorText="statesGreenColor"
                      />
                    </BaseCol>
                  </BaseRow>
                </BaseCol>
              )
            })}
          </BaseRow>
        </BaseSpace>
      </BaseCol>
    </BaseRow>
  )
}
