import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 0px ${PADDING.md};
`

export const BaseTableManagementWrapper = styled(BaseTableManagement)`
  .day-off-type-column {
    width: 100px !important;
    min-width: 100px !important;
  }

  .level-column {
    min-width: 100px !important;
    width: 120px !important;
  }
`
