import { IRefModal } from 'app/components/common/ModalComponent'
import { useEffect, useRef, useState } from 'react'
import { IProps } from './type'

export const useHook = ({
  currentSelectSender = ['Accountant', 'Manager'],
  onChangeListSenderJobTitle,
}: IProps) => {
  const modalRef = useRef<IRefModal>({})
  const [listSenderJobTitle, setListSenderJobTitle] =
    useState<string[]>(currentSelectSender)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [total, setTotal] = useState<number>(0)

  // onChange list sender job title and check is exist in list sender job title => delete item
  const _onChangeListSenderJobTitle = (item: string) => {
    if (listSenderJobTitle.includes(item)) {
      const newList = listSenderJobTitle.filter(i => i !== item)
      setListSenderJobTitle(newList)
    } else {
      setListSenderJobTitle([...listSenderJobTitle, item])
    }
  }

  // handle submit form
  const handleSubmit = () => {
    onChangeListSenderJobTitle?.(listSenderJobTitle)
    modalRef?.current?.hide?.()
  }

  useEffect(() => {
    setListSenderJobTitle(currentSelectSender)
  }, [currentSelectSender])

  return {
    modalRef,
    listSenderJobTitle,
    _onChangeListSenderJobTitle,
    handleSubmit,
    page,
    setPage,
    pageSize,
    setPageSize,
    total,
    setTotal,
  }
}
