export const colorAvatarMap = {
  A: '#6B7280',
  B: '#C2410C',
  C: '#4D7C0F',
  D: '#047857',
  E: '#0E7490',
  F: '#0369A1',
  G: '#4338CA',
  H: '#7E22CE',
  I: '#BE185D',

  J: '#6B7280',
  K: '#C2410C',
  L: '#4D7C0F',
  M: '#047857',
  N: '#0E7490',
  O: '#0369A1',
  P: '#4338CA',
  Q: '#7E22CE',
  R: '#BE185D',

  S: '#6B7280',
  T: '#C2410C',
  U: '#4D7C0F',
  V: '#047857',
  W: '#0E7490',
  X: '#0369A1',
  Y: '#4338CA',
  Z: '#7E22CE',
}

export const backgroundColorAvatarMap = {
  A: '#F3F4F6',
  B: '#FFEDD5',
  C: '#ECFCCB',
  D: '#D1FAE5',
  E: '#CFFAFE',
  F: '#E0F2FE',
  G: '#E0E7FF',
  H: '#F3E8FF',
  I: '#FCE7F3',

  J: '#F3F4F6',
  K: '#FFEDD5',
  L: '#ECFCCB',
  M: '#D1FAE5',
  N: '#CFFAFE',
  O: '#E0F2FE',
  P: '#E0E7FF',
  Q: '#F3E8FF',
  R: '#FCE7F3',

  S: '#F3F4F6',
  T: '#FFEDD5',
  U: '#ECFCCB',
  V: '#D1FAE5',
  W: '#CFFAFE',
  X: '#E0F2FE',
  Y: '#E0E7FF',
  Z: '#F3E8FF',
}
