export enum EScheduleStatus {
  DEFAULT = 0,
  TOTAL = 1,
  SCHEDULE = 2,
  NOT_YET_ARRIVED = 3,
  ARRIVED = 4,
  TREATING = 5,
  LONG_WAITING = 6,
  CANCEL = 7,
  DONE = 8,
}

export enum EBookingStatus {
  CREATED = 1,
  REQUEST = 2,
  CONFIRM = 3,
  DELETED = 4,
  CANCEL = 5,
}

export const SCHEDULE_CALLCENTER = "SCHEDULE_CALLCENTER"
export const CALLCENTER = "CALLCENTER"