/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormRoleData, IFormRoleRule, IPermission } from '../../type'
import { IPropsCreateRole } from './type'
import { IPayloadCreateRole } from 'app/api/roles/models/role'
import { usePagination } from 'app/hook'
import { useGetRoleRights } from 'app/react-query/hook/role'
import { IResponseQueryList } from 'app/react-query/model/common'
import { IRoleRight } from 'app/api/roles/models/rights'
import { requestPostRolesManagement } from 'app/api/roles'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { RoleMessageCode } from 'app/api/roles/constant'
import { notificationController } from 'app/controllers/notification-controller'

export const useModalHook = (props: IPropsCreateRole) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormRoleData>()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [module, setModule] = useState<string | undefined>()

  const [permissions, setPermissions] = useState<IPermission[]>([])

  const { flattenDataList } = usePagination()

  const { data: dataRightsBefore, isLoading: isLoadingRights } =
    useGetRoleRights({pageSize: 100, pagesize: 100})

  const modules = useMemo(() => {
    const flattenDta: IResponseQueryList<IRoleRight[]> =
      flattenDataList(dataRightsBefore)
    return flattenDta?.data ?? []
  }, [dataRightsBefore])

  const onChangePermission = (newPermission: IPermission[]) => {
    setPermissions(newPermission)
  }

  const onRemovePermission = (index: number) => {
    setPermissions(prev => prev?.filter((_, idx) => idx !== index))
  }

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormRoleData) => {
    setIsLoading(true)
    try {
      const payload: IPayloadCreateRole = {
        description: values?.description ?? '',
        name: values?.name,
        permissions: permissions?.map(item => item?._id ?? ''),
        moduleName: values?.module,
      }

      const response: ResponseType<string> = await requestPostRolesManagement(
        payload,
      )

      if (
        isEqual(
          response?.msgcode,
          RoleMessageCode.RoleManagement.successGetData,
        )
      ) {
        notificationController.success({
          message: t(R.strings.role_content_add_role),
          description: response?.message,
        })
        setPermissions([])
        handleCancel()
        props?.fetchTable?.()
      } else {
        notificationController.error({
          message: t(R.strings.role_content_add_role),
          description: response?.message,
        })
      }
    } catch (error) {
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeModule = (value: string) => {
    setModule(value)
  }

  const rules: IFormRoleRule = useMemo(() => {
    return {
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.role_content_name_role),
          }),
        },
      ],
      module: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.role_content_group_permission),
          }),
        },
      ],
      description: [],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    onChangePermission,
    permissions,
    onRemovePermission,
    modules,
    isLoadingRights,
    onChangeModule,
    module,
  }
}
