/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { formatMoney } from 'parkway-web-common'
import { Box, BoxAmount, BoxTitle } from './styles'

const TotalBox: React.FC<{
  title: string
  total: number
}> = ({ title, total }) => {
  return (
    <Box>
      <BoxTitle>{title}</BoxTitle>
      <BoxAmount>{formatMoney(total, '') || 0}</BoxAmount>
    </Box>
  )
}

export default TotalBox
