/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import {
  ExpandedTableValue,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import { IBaseTableReport } from 'app/components/tables/BaseTableReport/type'
import { FONT_WEIGHT } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export interface IDataTable extends IBaseTableReport<IDataTable> {
  doctor?: string
  total?: number
  id?: string
  totalAll?: number
}

export const useCommonHook = ({
  expandedRowKeys,
  handleExpand,
}: {
  expandedRowKeys: number[]
  handleExpand: (isExpanded: boolean, key?: number) => void
}) => {
  const { t } = useTranslation()

  const columns = useMemo(() => {
    return [
      convertColumnTable<IDataTable>({
        title: t(R.strings.doctor),
        key: 'doctor',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)
          const _handleExpand = () => {
            handleExpand(!isExpanded, record?.key)
          }

          return (
            <ExpandedTableValue
              isExpanded={isExpanded}
              handleExpand={_handleExpand}
              value={record?.doctor}
              isHaveAreaWhenNotHaveActionExpanded
            />
          )
        },
        className: 'class-left',
        width: '80%',
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.total_patient),
        key: 'total',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          // const percent = ((record?.total ?? 0) / (record?.totalAll ?? 0)) * 100
          return (
            // <RenderValueTable
            //   value={`${record?.total ?? 0}/${
            //     record?.totalAll ?? 0
            //   } (${lodash.round(percent, 2)})%`}
            //   type="Main"
            //   style={{
            //     opacity: 0.7,
            //     fontWeight: FONT_WEIGHT.semibold,
            //   }}
            // />
            <RenderValueTable
              value={record?.total}
              type="Main"
              style={{
                opacity: 0.7,
                fontWeight: FONT_WEIGHT.semibold,
              }}
            />
          )
        },
        className: 'class-right',
      }),
    ]
  }, [t, expandedRowKeys])

  return {
    columns,
  }
}
