import R from 'app/assets/R'
import { IGroupReport } from 'app/components/common/BaseLayoutReport/type'
import {
  CUSTOMER_CARE_CHECK_IN_SERVICE_PLAN_PATH,
  CUSTOMER_CARE_CREATE_APPOINTMENT_PATH,
  CUSTOMER_BOOKING_PATH,
  CUSTOMER_CARE_PLAN_PATH,
  CUSTOMER_CARE_SEARCH_APPOINTMENT_PATH,
  CUSTOMER_CARE_SURVEY_REPORT_PATH,
  CUSTOMER_BOOKING_FOR_EMPLOYEE_PATH,
  CUSTOMER_CARE_BOOKING_WEBSITE_REPORT_PATH,
} from 'app/components/router/route-path'
import { DateUtil } from 'parkway-web-common'
import { CUSTOMER_CARE_CHECK_IN_PLAN_PATH } from './../../components/router/route-path'
import { FunctionPermissionEnum } from 'app/common/permission-module'

export const groupData: IGroupReport[] = [
  {
    id: 1,
    titleI18nKey: R.strings.manage_appointment,
    reports: [
      {
        id: 10,
        label: '',
        descriptionI18nKey: R.strings.schedule_appointment,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: CUSTOMER_CARE_CREATE_APPOINTMENT_PATH,
        isHideLabel: true,
        permission: FunctionPermissionEnum.CREATE_APPOINTMENT
      },
      {
        id: 11,
        label: '',
        descriptionI18nKey: R.strings.booking,
        updateAt: '25/01/2024',
        path: CUSTOMER_BOOKING_PATH,
        isHideLabel: true,
        permission: FunctionPermissionEnum.BOOKING_APPOINTMENT
      },
      {
        id: 12,
        label: '',
        descriptionI18nKey: R.strings.booking_employee,
        updateAt: '25/01/2024',
        path: CUSTOMER_BOOKING_FOR_EMPLOYEE_PATH,
        isHideLabel: true,
        permission: FunctionPermissionEnum.BOOKING_EMPLOYEE_APPOINTMENT
      },
      {
        id: 13,
        label: '',
        descriptionI18nKey: R.strings.search_appointment,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: CUSTOMER_CARE_SEARCH_APPOINTMENT_PATH,
        isHideLabel: true,
        permission: FunctionPermissionEnum.SEARCH_APPOINTMENT
      },
    ],
  },
  {
    id: 2,
    titleI18nKey: R.strings.report_group_import_data,
    reports: [
      {
        id: 21,
        label: '001',
        descriptionI18nKey: R.strings.customer_care_plan,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: CUSTOMER_CARE_PLAN_PATH,
        permission: FunctionPermissionEnum.IMPORT_PLAN_CUSTOMER_CARE
      },
      {
        id: 22,
        label: '002',
        descriptionI18nKey: R.strings.check_in_customer_plan,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: CUSTOMER_CARE_CHECK_IN_PLAN_PATH,
        permission: FunctionPermissionEnum.IMPORT_PLAN_CHECK_IN_CUSTOMER_CARE
      },
      {
        id: 23,
        label: '003',
        descriptionI18nKey: R.strings.check_in_service_plan,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: CUSTOMER_CARE_CHECK_IN_SERVICE_PLAN_PATH,
        permission: FunctionPermissionEnum.IMPORT_PLAN_CHECK_IN_BY_SERVICE_CUSTOMER_CARE
      },
    ],
  },
  {
    id: 3,
    titleI18nKey: R.strings.report_group_report,
    reports: [
      {
        id: 31,
        label: '013',
        descriptionI18nKey: R.strings.survey_report,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: CUSTOMER_CARE_SURVEY_REPORT_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_13
      },
      {
        id: 32,
        label: '014',
        descriptionI18nKey: R.strings.booking_website_report,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: CUSTOMER_CARE_BOOKING_WEBSITE_REPORT_PATH,
      },
    ],
  },
]
