import { isEqual } from 'lodash'
import { getRandomUuid } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import {
  DiscountTypeEnum,
  IDataUpdateCampaign,
} from 'app/api/marketing/discount-campaign/model'
import { IBundleServiceItem, ITreatmentInTable } from '../type'
import { IResponseImportBundle } from '../RootLayout/Contents/layouts/BundleTypeLayout/ImportModal/type'

export const useBundleHook = () => {
  const [initBundleService, setInitListBundleService] = useState<
    IBundleServiceItem[]
  >([])
  const [listBundleService, setListBundleService] = useState<
    IBundleServiceItem[]
  >([])

  const [updateListBundle, setUpdateListBundle] = useState<
    IBundleServiceItem[]
  >([])
  const [deleteListBundle, setDeleteListBundle] = useState<
    IBundleServiceItem[]
  >([])
  const [addListBundle, setAddListBundle] = useState<IBundleServiceItem[]>([])

  const onAddNewBundleService = () => {
    const newItem: IBundleServiceItem = {
      id: getRandomUuid(),
      name: undefined,
      selectedTreatment: [],
    }

    setListBundleService([...listBundleService, newItem])
    setAddListBundle([...addListBundle, newItem])
  }

  const onInitBundleItem = (items: IBundleServiceItem[]) => {
    setListBundleService(items)
    setInitListBundleService(items)
    setAddListBundle([])
    setUpdateListBundle([])
    setDeleteListBundle([])
  }

  const onChangeUpdateListBundle = (data: IBundleServiceItem) => {
    if (initBundleService.find(item => isEqual(item?.id, data?.id))) {
      if (updateListBundle.find(item => isEqual(item?.id, data?.id))) {
        const newUpdateList = updateListBundle.map(item => {
          if (isEqual(item?.id, data?.id)) {
            return data
          }
          return item
        })
        setUpdateListBundle(newUpdateList)
      } else {
        setUpdateListBundle([...updateListBundle, data])
      }
    }
  }

  const onChangeAddListBundle = (data: IBundleServiceItem) => {
    if (initBundleService.find(item => isEqual(item?.id, data?.id))) {
      return
    }

    const newAddList = addListBundle.map(item => {
      if (isEqual(item?.id, data?.id)) {
        return data
      }
      return item
    })
    setAddListBundle(newAddList)
  }

  const onChangeListBundle = (data: IBundleServiceItem) => {
    const newList = listBundleService.map(item => {
      if (isEqual(item?.id, data?.id)) {
        return data
      }
      return item
    })
    setListBundleService(newList)
  }

  const onChangeNameBundleByIndex = (index: number, name: string) => {
    const itemExist = listBundleService.find((_, i) => isEqual(i, index))

    if (!itemExist) {
      return
    }

    onChangeUpdateListBundle({
      ...itemExist,
      name,
    })

    onChangeAddListBundle({
      ...itemExist,
      name,
    })

    onChangeListBundle({
      ...itemExist,
      name,
    })
  }

  const onRemoveBundleServiceByIndex = (index: number) => {
    const itemExist = listBundleService.find((_, i) => isEqual(i, index))

    if (!itemExist) return

    const itemInit = initBundleService.find(itm =>
      isEqual(itemExist.id, itm?.id),
    )

    if (itemInit) {
      setDeleteListBundle([...deleteListBundle, itemInit])
    }

    const newAddListBundle = addListBundle.filter(
      itm => !isEqual(itemExist.id, itm?.id),
    )
    setAddListBundle(newAddListBundle)

    const newUpdateListBundle = updateListBundle.filter(
      itm => !isEqual(itemExist.id, itm?.id),
    )
    setUpdateListBundle(newUpdateListBundle)

    const newList = listBundleService.filter(
      itm => !isEqual(itm.id, itemExist.id),
    )
    setListBundleService(newList)
  }

  const onChangeItemBundleServiceByIndex = (
    index: number,
    item: IBundleServiceItem,
  ) => {
    const itemExist = listBundleService.find((_, i) => isEqual(i, index))

    if (!itemExist) {
      return
    }

    onChangeUpdateListBundle({
      ...itemExist,
      ...item,
    })

    onChangeAddListBundle({
      ...itemExist,
      ...item,
    })

    onChangeListBundle({
      ...itemExist,
      ...item,
    })
  }

  const onChangeDiscountValueItemBundleService = (
    index: number,
    treatmentIndex: number,
    discountValue: number,
  ) => {
    const itemExist = listBundleService.find((_, i) => isEqual(i, index))

    if (!itemExist) return

    const treatmentExist = itemExist?.selectedTreatment?.find((_, idx) =>
      isEqual(idx, treatmentIndex),
    )

    if (!treatmentExist) return

    const newTreatment = itemExist?.selectedTreatment?.map((itm, idx) => {
      if (isEqual(idx, treatmentIndex)) {
        return {
          ...itm,
          discountValue,
        }
      }
      return itm
    })

    const newItemBundle = {
      ...itemExist,
      selectedTreatment: newTreatment,
    }

    onChangeUpdateListBundle(newItemBundle)

    onChangeAddListBundle(newItemBundle)

    onChangeListBundle(newItemBundle)
  }

  const onChangeDiscountTypeItemBundleService = (
    index: number,
    treatmentIndex: number,
    discountType: DiscountTypeEnum,
  ) => {
    const itemExist = listBundleService.find((_, i) => isEqual(i, index))

    if (!itemExist) return

    const treatmentExist = itemExist?.selectedTreatment?.find((_, idx) =>
      isEqual(idx, treatmentIndex),
    )

    if (!treatmentExist) return

    const newTreatment = itemExist?.selectedTreatment?.map((itm, idx) => {
      if (isEqual(idx, treatmentIndex)) {
        return {
          ...itm,
          discountType,
        }
      }
      return itm
    })

    const newItemBundle = {
      ...itemExist,
      selectedTreatment: newTreatment,
    }

    onChangeUpdateListBundle(newItemBundle)

    onChangeAddListBundle(newItemBundle)

    onChangeListBundle(newItemBundle)
  }

  const onChangeQuantityItemBundleService = (
    index: number,
    treatmentIndex: number,
    quantity: number,
  ) => {
    const itemExist = listBundleService.find((_, i) => isEqual(i, index))

    if (!itemExist) return

    const treatmentExist = itemExist?.selectedTreatment?.find((_, idx) =>
      isEqual(idx, treatmentIndex),
    )

    if (!treatmentExist) return

    const newTreatment = itemExist?.selectedTreatment?.map((itm, idx) => {
      if (isEqual(idx, treatmentIndex)) {
        return {
          ...itm,
          quantity,
        }
      }
      return itm
    })

    const newItemBundle = {
      ...itemExist,
      selectedTreatment: newTreatment,
    }

    onChangeUpdateListBundle(newItemBundle)

    onChangeAddListBundle(newItemBundle)

    onChangeListBundle(newItemBundle)
  }

  const onDeleteItemInSelectedTreatment = (
    index: number,
    treatmentIndex: number,
  ) => {
    const itemExist = listBundleService.find((_, i) => isEqual(i, index))

    if (!itemExist) return

    const treatmentAfterDeleted = itemExist?.selectedTreatment?.filter(
      (_, idx) => idx !== treatmentIndex,
    )

    const newItemBundle = {
      ...itemExist,
      selectedTreatment: treatmentAfterDeleted,
    }

    onChangeUpdateListBundle(newItemBundle)

    onChangeAddListBundle(newItemBundle)

    onChangeListBundle(newItemBundle)
  }

  const dataUpdateBundle: IDataUpdateCampaign = useMemo(() => {
    const payload: IDataUpdateCampaign = {
      push: addListBundle.map(item => {
        return {
          name: item.name,
          buyTreatment: item?.selectedTreatment?.map(itm => {
            return {
              treatment: itm?._id,
              amount: itm?.quantity,
              dType: itm?.discountType,
              discount: itm?.discountValue,
            }
          }),
          discountTreatment: [],
        }
      }),
      removes: deleteListBundle.map(item => item?.id ?? ''),
      updates: updateListBundle.map(item => {
        return {
          _id: item.id,
          name: item.name,
          buyTreatment: item?.selectedTreatment?.map(itm => {
            return {
              treatment: itm?._id,
              amount: itm?.quantity,
              dType: itm?.discountType,
              discount: itm?.discountValue,
            }
          }),
          discountTreatment: [],
        }
      }),
    }
    return payload
  }, [updateListBundle, deleteListBundle, addListBundle])

  const handleImportBundle = (data: IResponseImportBundle) => {
    const listDelete = data?.remove?.map(item => {
      return {
        id: item?._id,
        name: item?.setName,
        selectedTreatment: item?.buyTreatment?.map(itm => ({
          ...itm,
          _id: itm?._id,
          name: itm?.name,
          discountValue: itm?.discount,
          discountType: itm?.dType,
          quantity: itm?.amount,
          regionalPrice: itm?.regionalPrice,
        })) as ITreatmentInTable[],
      }
    })

    const listUpdate = data?.modify?.map(item => {
      return {
        id: item?._id,
        name: item?.setName,
        selectedTreatment: item?.buyTreatment?.map(itm => ({
          ...itm,
          _id: itm?._id,
          name: itm?.name,
          discountValue: itm?.discount,
          discountType: itm?.dType,
          quantity: itm?.amount,
          regionalPrice: itm?.regionalPrice,
        })) as ITreatmentInTable[],
      }
    })

    const listAdd = data?.insert?.map(item => {
      return {
        id: item?._id ?? getRandomUuid(),
        name: item?.setName,
        selectedTreatment: item?.buyTreatment?.map(itm => ({
          ...itm,
          _id: itm?._id,
          name: itm?.name,
          discountValue: itm?.discount,
          discountType: itm?.dType,
          quantity: itm?.amount,
          regionalPrice: itm?.regionalPrice,
        })) as ITreatmentInTable[],
      }
    })

    const newListService = initBundleService
      // xoá item đã bị xoá khỏi danh sách
      .filter(
        item =>
          !listDelete.some(deleteItem => isEqual(deleteItem?.id, item?.id)),
      )
      // cập nhật lại item đã bị sửa
      .map(item => {
        const itemUpdate = listUpdate.find(itm => isEqual(itm?.id, item?.id))
        if (itemUpdate) {
          return itemUpdate
        }
        return item
      })
      .concat(listAdd)

    setListBundleService(newListService)
    setUpdateListBundle(listUpdate)
    setDeleteListBundle(listDelete)
    setAddListBundle(listAdd)
  }

  return {
    onAddNewBundleService,
    listBundleService,
    onRemoveBundleServiceByIndex,
    onChangeNameBundleByIndex,
    onChangeDiscountTypeItemBundleService,
    onChangeDiscountValueItemBundleService,
    onChangeItemBundleServiceByIndex,
    onChangeQuantityItemBundleService,
    onDeleteItemInSelectedTreatment,
    onInitBundleItem,
    handleImportBundle,
    dataUpdateBundle,

    updateListBundle,
    addListBundle,
    deleteListBundle,
    initBundleService,
  }
}
