import { getArray } from 'app/components/tables/common-table/constant'
import { useEffect, useState } from 'react'
import { IDataTable } from '../type'

export const useHook = () => {
  const [data, setData] = useState<IDataTable[]>([])

  const getData = () => {
    setData(
      getArray(20).map((item, index) => ({
        stt: index + 1,
        treatmentPlanType: 'Chẩn đoán',
        treatingPhysician: 'Nguyễn Văn A',
        service: 'Chẩn đoán',
        patient: 'Nguyễn Văn B',
        date: '10/10/2021 10:10:10',
      })),
    )
  }

  useEffect(() => {
    getData()
  }, [])


  return {
    data,
  }
}