import * as S from './styles'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { t } from 'i18next'
import R from 'app/assets/R'
import { isEmpty, isEqual } from 'lodash'
import { getAllCombinations } from 'app/common/helpers'
import { useHook } from '../hook'
import {
  IProductPropertiesCombinedData,
  IProductProperty,
} from 'app/api/product-property/model/management'
import Images from './Images'
import { BaseInput } from 'app/components/common/inputs/BaseInput'

const ImageProperty = () => {
  const {
    productPropertiesChildren: mapProductPropertiesChildren,
    isLoadingProductProperty,
    form,
  } = useHook()

  const onClickBarCode = e => {
    e.preventDefault()
  }

  return (
    <S.PropertyContainer>
      <S.ImagePropertyWrapper>
        <S.ImagePropertyBody className="image-property__tbody">
          <S.ImagePropertyTR>
            <S.ImagePropertyTD flex="1">
              {t(R.strings.id_property)}
            </S.ImagePropertyTD>
            <S.ImagePropertyTD width="192px">
              {t(R.strings.barcode)}
            </S.ImagePropertyTD>
          </S.ImagePropertyTR>
        </S.ImagePropertyBody>
        <BaseForm.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            const checkUpdate = !isEqual(
              prevValues.productPropertiesIds,
              currentValues.productPropertiesIds,
            )

            if (
              checkUpdate &&
              mapProductPropertiesChildren &&
              mapProductPropertiesChildren?.length > 0
            ) {
              const currentProductPropertiesCombinedData: IProductPropertiesCombinedData[] =
                currentValues.productPropertiesCombinedData

              const productPropertiesChildren: Array<IProductProperty[]> = []
              currentValues?.productPropertiesIds?.forEach(
                (item: IProductProperty) => {
                  if (item?.productPropertiesChildren) {
                    productPropertiesChildren.push(
                      item?.productPropertiesChildren,
                    )
                  }
                },
              )

              if (
                isEmpty(productPropertiesChildren) ||
                productPropertiesChildren?.length <= 0
              ) {
                return false
              }
              const arraysToCombine = getAllCombinations(
                productPropertiesChildren ?? [],
              )
              // setCollapseData(arraysToCombine)

              const productPropertiesCombinedData = arraysToCombine?.map(
                (item: string[]) => {
                  const productPropertiesDetail = item?.map(itemProperties => {
                    return mapProductPropertiesChildren?.find(
                      property => property._id === itemProperties,
                    )
                  })

                  const nameCombined = productPropertiesDetail
                    ?.map(productProperty => productProperty?.name ?? undefined)
                    ?.join(' / ')

                  const prevImageMain =
                    currentProductPropertiesCombinedData?.find(
                      productProperty =>
                        isEqual(productProperty.productPropertiesIds, item),
                    )

                  return {
                    productPropertiesIds: item,
                    productPropertiesDetail,
                    nameCombined,
                    mainImagesUrl: prevImageMain?.mainImagesUrl ?? undefined,
                    detailImagesUrl: prevImageMain?.detailImagesUrl ?? [],
                    barCode: prevImageMain?.barCode ?? undefined,
                  }
                },
              ) as IProductPropertiesCombinedData[]

              form?.setFieldValue(
                'productPropertiesCombinedData',
                productPropertiesCombinedData,
              )
            }

            return !isLoadingProductProperty && checkUpdate
          }}
        >
          {() => {
            return (
              <>
                <BaseForm.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    !isEqual(
                      prevValues.productPropertiesCombinedData,
                      currentValues.productPropertiesCombinedData,
                    )
                  }
                >
                  {({ getFieldValue }) => {
                    const productPropertiesCombinedDataField = getFieldValue(
                      'productPropertiesCombinedData',
                    )

                    return (
                      <>
                        <BaseForm.List name="productPropertiesCombinedData">
                          {productPropertiesCombinedData => {
                            return (
                              <>
                                {productPropertiesCombinedData?.map(
                                  productPropertiesCombinedItem => {
                                    let productPropertiesCombinedDataByName
                                    try {
                                      productPropertiesCombinedDataByName =
                                        productPropertiesCombinedDataField[
                                          productPropertiesCombinedItem.name
                                        ]
                                    } catch (error) {
                                      productPropertiesCombinedDataByName =
                                        undefined
                                    }

                                    return (
                                      <S.ImagePropertyCollapse
                                        className="my-collapse"
                                        key={`collapse-property-combine-${productPropertiesCombinedItem.key}`}
                                        items={[
                                          {
                                            key: `image-property-collapse-${productPropertiesCombinedItem.key}`,
                                            label: (
                                              <S.ImagePropertyHeaderCollapse>
                                                <span>
                                                  {
                                                    productPropertiesCombinedDataByName?.nameCombined
                                                  }
                                                </span>
                                                <BaseForm.Item
                                                  name={[
                                                    productPropertiesCombinedItem.name,
                                                    'barCode',
                                                  ]}
                                                  hiddenLabel
                                                >
                                                  <BaseInput
                                                    placeholder={t(
                                                      R.strings.fill_barcode,
                                                    )}
                                                    onClick={onClickBarCode}
                                                  />
                                                </BaseForm.Item>
                                              </S.ImagePropertyHeaderCollapse>
                                            ),
                                            children: (
                                              <div>
                                                <S.Title>
                                                  {t(
                                                    R.strings
                                                      .item_management_content_image,
                                                  )}
                                                </S.Title>
                                                <Images
                                                  nameMain={[
                                                    productPropertiesCombinedItem.name,
                                                    'mainImagesUrl',
                                                  ]}
                                                  nameDetail={[
                                                    productPropertiesCombinedItem.name,
                                                    'detailImagesUrl',
                                                  ]}
                                                />
                                              </div>
                                            ),
                                          },
                                        ]}
                                        defaultActiveKey={['1']}
                                      />
                                    )
                                  },
                                )}
                              </>
                            )
                          }}
                        </BaseForm.List>
                      </>
                    )
                  }}
                </BaseForm.Item>
              </>
            )
          }}
        </BaseForm.Item>
      </S.ImagePropertyWrapper>
    </S.PropertyContainer>
  )
}

export default ImageProperty
