import R from 'app/assets/R'
import { PageTitle } from 'app/components/common/PageTitle'
import CreateUpdateProductServiceManagement from 'app/containers/Accounting/TreatmentManagement/CreateUpdateProductServiceManagement'
import { TreatmentProvider } from 'app/context/TreatmentContext'
import { isEqual } from 'lodash'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

export enum ActionProductServiceEnum {
  create = 'create',
  update = 'update',
}

const AccountingTreatmentManagementAddProductServiceCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <TreatmentProvider>
        <PageTitle>{t(R.strings.item_management)}</PageTitle>
        <CreateUpdateProductServiceManagement />
      </TreatmentProvider>
    </>
  )
}
const AccountingTreatmentManagementAddProductServicePage = memo(
  AccountingTreatmentManagementAddProductServiceCpn,
  isEqual,
)

export default AccountingTreatmentManagementAddProductServicePage
