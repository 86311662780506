import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import ApproveFlowContainer from 'app/containers/DentalTreatmentManagement/ApproveTreatmentFlow'

const ApproveFlowPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.approve_flow)}</PageTitle>
      <ApproveFlowContainer />
    </>
  )
}
const ApproveFlowPage = memo(ApproveFlowPageCpn, isEqual)

export default ApproveFlowPage
