import * as S from 'app/components/tables/BaseTableManagement/styles'
import HeaderPage from 'app/components/header/components/HeaderPage'
import R from 'app/assets/R'
import CompanyInfo from '../components/CompanyInfo'
import BuyInfo from '../components/BuyInfo'
import OrderInfo from '../components/OrderInfo'
import { WarehousePurchaseContext } from '../../context'
import Footer from '../components/Footer'
import { useParams } from 'react-router'
import { requestDetailWarehousePurchaseManagement } from 'app/api/inventory-new'
import { useContext, useEffect } from 'react'
import dayjs from 'dayjs'
import { PurchaseOrderStatusEnum } from 'app/common/enum'
import { HeadingTitlePage } from 'app/components/tables/BaseTableReport/styles'
import { useTranslation } from 'react-i18next'
import { HeadingTitleCode } from './styles'
import { BaseRow } from 'app/components/common/BaseRow'
import { Print } from '../components/Print'
import { notificationController } from 'app/controllers/notification-controller'
import { get } from 'lodash'

const Update = () => {
  const params = useParams()
  const { t } = useTranslation()
  const {
    setOrders,
    form,
    setWarehousePurchaseDetail,
    setDiscount,
    warehousePurchaseDetail,
  } = useContext(WarehousePurchaseContext)
  const getWarehousePurchaseDetail = async () => {
    try {
      const { data } = await requestDetailWarehousePurchaseManagement(
        params?.id,
      )

      const orders = data?.orders?.map(order => ({
        ...order,
        name: order?.product?.name,
        code: order?.product?.code,
        purchaseMeasurementUnit: order?.measurementUnit?.name,
        measurementUnitId: order?.measurementUnitId,
      }))

      setOrders?.(orders)
      await form?.setFieldsValue({
        ...data,
        paymentDueDate: dayjs(data?.paymentDueDate),
        estimateDeliveryDate: dayjs(data?.estimateDeliveryDate),
      })

      setWarehousePurchaseDetail?.({
        ...data,
        paymentDueDate: dayjs(data?.paymentDueDate),
        estimateDeliveryDate: dayjs(data?.estimateDeliveryDate),
      })

      setDiscount?.(data?.discount)
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }
  }

  useEffect(() => {
    getWarehousePurchaseDetail()
  }, [])

  return (
    <>
      <S.BaseManagementWrapper>
        {PurchaseOrderStatusEnum.DRAFT ===
        Number(warehousePurchaseDetail?.status) ? (
          <HeaderPage
            titleI18nKey={R.strings.warehouse_purchase_update}
            rightComponent={<></>}
          />
        ) : (
          <BaseRow justify={'space-between'} align={'middle'}>
            <BaseRow justify={'start'} align={'middle'}>
              <HeadingTitlePage>{t(R.strings.purchase_order)}</HeadingTitlePage>
              <HeadingTitleCode>
                {warehousePurchaseDetail?.code}
              </HeadingTitleCode>
            </BaseRow>
            <Print />
          </BaseRow>
        )}
        <div>
          <div>
            <CompanyInfo />
            <BuyInfo />
          </div>
        </div>

        <div>
          <div>
            <OrderInfo />
          </div>
        </div>
      </S.BaseManagementWrapper>

      <Footer />
    </>
  )
}

export default Update
