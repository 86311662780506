import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { useTranslation } from 'react-i18next'

export const ItemDiagnosis = ({
  placeholder,
  label,
  stt,
  placeholderSecond,
  isTwoInput,
  suffix,
  suffixSecond,
  note,
  disableInput,
}: {
  label: string
  stt?: number
  placeholder?: string
  placeholderSecond?: string
  isTwoInput?: boolean
  suffix?: string
  suffixSecond?: string
  note?: string
  disableInput?: boolean
}) => {
  const { t } = useTranslation()
  return (
    <BaseSpace size={8}>
      <BaseRow gutter={5} wrap={false}>
        <BaseCol>
          <BaseText children={t(label)} opacity="0.7" />
        </BaseCol>
        {stt ? (
          <BaseCol>
            <BaseText
              children={`(${stt})`}
              opacity="0.7"
              fontSize="xxxs"
              fontWeight="medium"
            />
          </BaseCol>
        ) : null}
      </BaseRow>
      <BaseInput
        placeholder={placeholder ? t(placeholder) : undefined}
        suffix={suffix ? <BaseText children={suffix} opacity="0.5" /> : null}
        disabled={disableInput}
      />

      {isTwoInput ? (
        <BaseInput
          placeholder={placeholderSecond ? t(placeholderSecond) : undefined}
          suffix={
            suffixSecond ? (
              <BaseText children={suffixSecond} opacity="0.5" />
            ) : null
          }
        />
      ) : null}

      {note ? <BaseText children={note} opacity="0.7" /> : null}
    </BaseSpace>
  )
}
