import { BaseCollapse } from "app/components/common/BaseCollapse";
import { convertedVariables } from "app/styles/themes/themeVariables";
import { BORDER_RADIUS } from "parkway-web-common";
import styled from "styled-components";

export const RootWrapper = styled(BaseCollapse)`
  width: 100%;

  border: 1px solid ${convertedVariables.neutralBlack9Color};
  
  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-content {
    border-top: 1px solid ${convertedVariables.neutralBlack9Color};
  }

  .ant-collapse-header {
    background-color: var(--background-color);
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  } 

  .ant-collapse-header[aria-expanded="true"] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  } 
`