/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetItemBrandManagement } from 'app/api/item-brand'
import { ItemBrandMessageCode } from 'app/api/item-brand/constant'
import {
  IItemBrandManagement,
  IResponseGetItemBrandManagement,
} from 'app/api/item-brand/model/management'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { useInfiniteQuery } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { ItemBrandKeys } from '../query-key/item-brand'

export function useGetItemBrandList(enabled = true) {
  const token = getToken()
  return useInfiniteQuery<
    IResponseQueryList<IItemBrandManagement[]>,
    undefined
  >(
    ItemBrandKeys.listItemBrand(),
    async () => {
      try {
        const res: IResponseGetItemBrandManagement =
          await requestGetItemBrandManagement({})

        if (
          isEqual(
            res?.msgcode,
            ItemBrandMessageCode.ItemBrandManagement.successGetData,
          )
        ) {
          const data: IItemBrandManagement[] = res?.data || []

          return {
            data,
            total: res.metadata.totalItem || 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
