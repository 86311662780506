import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { t } from 'i18next'
import { InfoItem } from '../../components'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import styled from 'styled-components'
import { FONT_WEIGHT, PADDING } from 'parkway-web-common'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { DefineBracesTagEnum } from 'app/containers/DentalTreatmentManagement/TreatmentPlan/constant'
import { BracesTagEnum } from 'app/containers/DentalTreatmentManagement/TreatmentPlan/type'

export const GeneralInformationLayout = () => {
  return (
    <BaseSpace>
      <BaseText
        children={t(R.strings.general_info)}
        fontWeight="semibold"
        fontSize="md"
      />
      <BaseRow gutter={[8, 8]} align={'top'}>
        <BaseCol xl={12}>
          <InfoItem
            label={t(R.strings.medical_record)}
            value={t(R.strings.invisalign)}
          />
        </BaseCol>
        <BaseCol xl={12}>
          <InfoItem
            label={t(R.strings.status)}
            customValue={
              <TagStatus
                $backgroundColor={
                  DefineBracesTagEnum?.[BracesTagEnum.DeepBite].backgroundColor
                }
                children={t(R.strings.pending)}
              />
            }
          />
        </BaseCol>
      </BaseRow>

      <BaseText
        children={t(R.strings.patient_info)}
        fontWeight="semibold"
        fontSize="md"
        colorText="collapseBackgroundColor"
      />
      <BaseRow gutter={[8, 8]} align={'top'}>
        <BaseCol xl={12}>
          <InfoItem label={t(R.strings.full_name)} value={'Phạm Văn Khánh'} />
        </BaseCol>
        <BaseCol xl={12}>
          <InfoItem label={t(R.strings.phone)} value={'0965 259 441'} />
        </BaseCol>
      </BaseRow>
      <BaseRow>
        <BaseCol xl={24}>
          <InfoItem
            label={t(R.strings.address)}
            value={'128 Trần Hưng Đạo, Quận 1, Tp.Hồ Chí Minh'}
          />
        </BaseCol>
      </BaseRow>

      <BaseText
        children={t(R.strings.doctor_info)}
        fontWeight="semibold"
        fontSize="md"
        colorText="collapseBackgroundColor"
      />

      <BaseRow gutter={[8, 8]} align={'top'}>
        <BaseCol xl={12}>
          <InfoItem
            label={t(R.strings.assigned_physician)}
            customValue={
              <BaseAvatarProfile jobTitle={'Bác sĩ'} name="Phạm Văn Khánh" />
            }
          />
        </BaseCol>
        <BaseCol xl={12}>
          <InfoItem
            label={t(R.strings.treating_physician)}
            customValue={
              <BaseAvatarProfile jobTitle={'Bác sĩ'} name="Phạm Văn Khánh" />
            }
          />
        </BaseCol>
      </BaseRow>
    </BaseSpace>
  )
}

const TagStatus = styled.div<{ $backgroundColor: string }>`
  padding: 8px 12px;
  border-radius: ${PADDING.xl};
  background-image: ${props => props?.$backgroundColor};
  width: fit-content;
  color: ${convertedVariables.backgroundColor};
  font-weight: ${FONT_WEIGHT.semibold};
`
