import { BaseSpace } from 'app/components/common/BaseSpace'
import { BundleBillDiscountTypeLayout, ListDiscountBillLayout } from './layouts'

export const TotalBillTypeLayout = () => {
  return (
    <BaseSpace>
      <ListDiscountBillLayout />
      <BundleBillDiscountTypeLayout />
    </BaseSpace>
  )
}
