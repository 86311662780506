import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { Content } from 'antd/lib/layout/layout'
import styled from 'styled-components'
import { Select } from 'antd'
import { SelectProps } from 'antd/lib'

export const BaseSection = styled(Content)`
  background-color: var(--background-color);
  color: var(--primary-color);
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
`
export const Title = styled.h2`
  padding-bottom: ${PADDING.md};
  border-bottom: 1px solid var(--revenue-dashboard-border-color);
  margin-bottom: ${PADDING.md};
`
interface ISelectSearch extends SelectProps {
  optionRender
}
export const SelectSearch = styled(Select)<ISelectSearch>`
  && {
    .ant-select-selector {
      padding: 0px 5px !important;
    }
  }
`

export const UserList = styled.div``

export const UserItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const UserAvatar = styled.div``

export const UserInfo = styled.div`
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const UserName = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #002244;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const UserLabel = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #002244;
  opacity: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
