import { Button } from 'antd'
import styled from 'styled-components'

export const ButtonPrint = styled(Button)`
padding: 0px 20px 0px 10px;
border-radius: 4px;
gap: 8px;
margin-right: 10px;
border: initial;
`

export const ButtonAddNew = styled(Button)`
padding: 0px 20px 0px 10px;
border-radius: 4px;
gap: 8px;
border: initial;
background: #FF7854;
color: white;
`