import { convertedVariables } from 'app/styles/themes/themeVariables'
import styled from 'styled-components'

const DividerLine: React.FC<{ color?: string }> = ({ color }) => {
  return <DividerLineStyle $color={color} />
}

export default DividerLine

const DividerLineStyle = styled.div<{ $color?: string }>`
  margin: 0px !important;
  background-color: ${props =>
    props?.$color ?? convertedVariables.neutralBlack9Color};
  width: 100%;
  height: 1px;
`
