import { requestGetAppointmentPatientManagement } from 'app/api/patient'
import { REQUEST_APPOINTMENT_TYPE } from 'app/common/enum'
import { IPatient } from 'app/containers/CustomerCare/AppointmentPage/type'
import { notificationController } from 'app/controllers/notification-controller'
import { get } from 'lodash'
import { createContext, useState } from 'react'

interface IPatientContext {
  loading: boolean
  patients: IPatient[]
  patientProfiles: IPatient[]
  setLoading?: (loading) => void
  fetchDataAppointmentPatient?: (params, type?: number) => void
  setPatients?: (patients) => void
}
export const PatientContext = createContext<IPatientContext>({
  loading: false,
  patients: [],
  patientProfiles: [],
})

export const PatientProvider = ({ children }) => {
  const [patients, setPatients] = useState([])
  const [patientProfiles, setPatientProfiles] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchDataAppointmentPatient = async (params, type = 0) => {
    setLoading(true)
    try {
      const patients = await requestGetAppointmentPatientManagement(params)
      if (type === REQUEST_APPOINTMENT_TYPE.PROFILE) {
        setPatientProfiles(patients?.data?.rows)
      } else {
        setPatients(patients?.data?.rows)
      }
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }

    setLoading(false)
  }

  return (
    <PatientContext.Provider
      value={{
        patients,
        loading,
        setLoading,
        fetchDataAppointmentPatient,
        patientProfiles,
        setPatients,
      }}
    >
      {children}
    </PatientContext.Provider>
  )
}
