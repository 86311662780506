import { BaseRow } from 'app/components/common/BaseRow'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RevenueItemWrapper = styled.div<{ color: string }>`
  padding: ${PADDING.xxs} !important;
  border-radius: ${BORDER_RADIUS};
  width: 100% !important;
  background-color: #f9fafb; // need change
  border-bottom: 2.5px solid ${props => props.color};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const LeftPointTitle = styled.div`
  border-radius: ${BORDER_RADIUS};
  width: 4px;
  height: 14px;
  background-color: var(--secondary-background-color);
`

export const Title = styled.p`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.medium};
`
export const RevenueValue = styled.div<{ $isAll?: boolean }>`
  color: ${props =>
    props.$isAll ? 'var(--secondary-background-color)' : 'var(--primary-color)'};
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.semibold};
  padding-top: ${PADDING.xxs};
`
export const CompareTime = styled.div`
  color: var(--text-primary2-color);
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
  padding-top: ${PADDING.xxs};
`

export const GrowthWrapper = styled(BaseRow)<{ $upValue?: boolean }>`
  background-color: ${props =>
    props?.$upValue
      ? 'var(--states-green-light-color)'
      : ' var(--states-red-light-color)'};
  padding: 1px 2px;
  border-radius: 4px;
  width: fit-content;
  margin-top: ${PADDING.md};

  .value {
    font-size: ${FONT_SIZE.xxs};
    font-weight: ${FONT_WEIGHT.semibold};
    color: ${props =>
      props.$upValue ? 'var(--states-green-color)' : 'var(--states-red-color)'};
    margin-left: ${PADDING.xxxxs};
  }
`
