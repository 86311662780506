import R from 'app/assets/R'
import {
  SvgArrowTrendingDownIcon,
  SvgArrowTrendingUpIcon,
} from 'app/assets/svg-assets'
import { BaseRow } from 'app/components/common/BaseRow'
import { DEFAULT_CURRENCY_UNIT, formatMoney } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

export interface IRevenueDashboard {
  $isAll?: boolean
  value?: string | number
  labelI18n?: string
  isNotMoney?: boolean
  isUpValue?: boolean
  valueGrow?: string | number
  color: string
  id?: number
}

const RevenueItem = ({
  $isAll = false,
  labelI18n = '',
  value = 0,
  isNotMoney,
  isUpValue,
  valueGrow,
  color,
}: IRevenueDashboard) => {
  const { t } = useTranslation()
  return (
    <S.RevenueItemWrapper color={color}>
      <BaseRow align={'top'} gutter={5} wrap={false}>
        <S.Title>{t(labelI18n)}</S.Title>
      </BaseRow>

      <S.RevenueValue $isAll={$isAll}>
        <span>
          {formatMoney(value, isNotMoney ? '' : DEFAULT_CURRENCY_UNIT.short)}
        </span>
      </S.RevenueValue>

      <S.GrowthWrapper
        $upValue={Number(valueGrow) !== 0 ? isUpValue : true}
        align={'middle'}
      >
        {valueGrow && Number(valueGrow) !== 0 ? (
          <>
            {isUpValue ? (
              <SvgArrowTrendingUpIcon />
            ) : (
              <SvgArrowTrendingDownIcon />
            )}
          </>
        ) : (
          <SvgArrowTrendingUpIcon />
        )}
        <span className="value">
          {valueGrow && Number(valueGrow) !== 0 ? <>{isUpValue ? '+' : '-'}</> : ''}
          {valueGrow}%
        </span>
      </S.GrowthWrapper>

      <S.CompareTime>
        <span>{t(R.strings.compared_to_last_day)}</span>
      </S.CompareTime>
    </S.RevenueItemWrapper>
  )
}

export default RevenueItem
