import R from 'app/assets/R'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BasePagination } from 'app/components/common/BasePagination'
import { BaseRow } from 'app/components/common/BaseRow'
import { Option } from 'app/components/common/selects/BaseSelect'
import { useModalHook } from './hook'
import * as S from './styles'
import { IFormData, IPropsUpdatePermission } from './type'

export const initValues: IFormData = {
  name: '',
}

export const UpdatePermissionOfRole = (props: IPropsUpdatePermission) => {
  const {
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    search,
    onChangeSearch,
    module,
    modules,
    onChangeModules,
    permissionAfterSelected,
    isSelectAll,
    onClickSelectAll,
    onChangePermissions,
    permissionSelected,
    currentPage,
    pageSize,
    onChangePage,
    onChangePageSize,
    listPermission,
  } = useModalHook(props)

  return (
    <BaseModalManagement
      titleKeyI18n={R.strings.role_content_update_permission}
      isLoading={isLoading}
      content={
        <S.ContentWrapper direction="vertical" size={16}>
          <S.FilterWrapper justify={'start'} gutter={[16, 8]} align={'middle'}>
            <BaseCol xl={12}>
              <S.FormItem>
                <S.SelectBaseStyled
                  placeholder={t(
                    R.strings.role_content_choose_group_permission,
                  )}
                  value={module}
                  onChange={value => onChangeModules(value as string)}
                  allowClear
                >
                  {modules?.map((item, index) => {
                    return (
                      <Option value={item._id} key={index}>
                        {t(`${item?._id ?? ''}`.replace(/-/g, '_'))}
                      </Option>
                    )
                  })}
                </S.SelectBaseStyled>
              </S.FormItem>
            </BaseCol>
            <BaseCol xl={12}>
              <S.FormItem validateDebounce={500} label={false}>
                <S.FormInput
                  value={search}
                  onChange={e => onChangeSearch(e.target?.value ?? '')}
                  placeholder={t(R.strings.role_content_enter_name_permission)}
                  allowClear
                />
              </S.FormItem>
            </BaseCol>
          </S.FilterWrapper>

          <S.DataWrapper direction={'vertical'} size={16}>
            <S.ItemHeader
              gutter={[10, 10]}
              align={'middle'}
              justify={'space-between'}
              wrap={false}
            >
              <BaseCol>
                <BaseRow gutter={[15, 15]} wrap={false} align={'middle'}>
                  <S.IDWrapper>
                    <S.TextHead>{t(R.strings.role_content_modules)}</S.TextHead>
                  </S.IDWrapper>
                  <BaseCol>
                    <S.TextHead>
                      {t(R.strings.role_content_name_permission)}
                    </S.TextHead>
                  </BaseCol>
                </BaseRow>
              </BaseCol>
              <BaseCol>
                <BaseRow align={'middle'} gutter={[10, 10]}>
                  <BaseCol>
                    <S.TextHead>{t(R.strings.all)}</S.TextHead>
                  </BaseCol>
                  <BaseCol>
                    <BaseCheckbox
                      checked={isSelectAll}
                      onClick={onClickSelectAll}
                    />
                  </BaseCol>
                </BaseRow>
              </BaseCol>
            </S.ItemHeader>
            <S.ListWrapper direction={'vertical'} size={16}>
              {permissionAfterSelected?.map((per, idx) => {
                const _onClick = () => {
                  onChangePermissions(per)
                }
                return (
                  <S.ItemData
                    key={idx}
                    gutter={[10, 10]}
                    align={'middle'}
                    justify={'space-between'}
                    wrap={false}
                    onClick={_onClick}
                  >
                    <BaseCol>
                      <BaseRow gutter={[15, 15]} wrap={false} align={'middle'}>
                        <S.IDWrapper>
                          <S.TextData>
                            {t(`${per?.moduleName ?? ''}`.replace(/-/g, '_'))}
                          </S.TextData>
                        </S.IDWrapper>
                        <BaseCol>
                          <S.TextData>{per.name}</S.TextData>
                        </BaseCol>
                      </BaseRow>
                    </BaseCol>
                    <BaseCol>
                      <BaseCheckbox checked={per?.isSelected} />
                    </BaseCol>
                  </S.ItemData>
                )
              })}
            </S.ListWrapper>
          </S.DataWrapper>
          <S.FooterWrapper
            justify={'space-between'}
            align={'middle'}
            gutter={[10, 10]}
          >
            <BaseCol>
              <BaseRow gutter={[5, 5]} align={'middle'}>
                <BaseCol>
                  <S.TextData>
                    {`${t(R.strings.role_content_total_permission_selected)}: `}
                  </S.TextData>
                </BaseCol>
                <BaseCol>
                  <S.TextTotalPermissionSelected>
                    {permissionSelected?.length}
                  </S.TextTotalPermissionSelected>
                </BaseCol>
              </BaseRow>
            </BaseCol>
            <BaseCol>
              <BasePagination
                defaultCurrent={currentPage}
                pageSize={pageSize}
                total={listPermission?.length ?? 0}
                onChange={(page: number, pageSize?: number) => {
                  if (page && pageSize) {
                    onChangePageSize(pageSize)
                    onChangePage(page)
                  }
                }}
                pageSizeOptions={[10, 20, 50, 100]}
              />
            </BaseCol>
          </S.FooterWrapper>
        </S.ContentWrapper>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      confirmKeyI18n={R.strings.confirm}
      handleSubmit={handleSubmit}
      visible={visible}
      widthModal={1000}
      openModalComponent={
        <S.PermissionHeadButton
          icon={<S.PlusIcon rev={false} />}
          onClick={showModal}
        >
          <S.PermissionHeadButtonText>
            {t(R.strings.role_content_add_permission)}
          </S.PermissionHeadButtonText>
        </S.PermissionHeadButton>
      }
    />
  )
}
