import { ApiClientHr } from 'app/service/api-service'
import {
  IParamsGetDegreeClassificationManagement,
  IPayloadCreateDegreeClassificationManagement,
  IPayloadUpdateDegreeClassificationManagement,
} from './model/management'
import { DegreeClassificationEndPoint } from './constant'

export const requestGetDegreeClassificationManagement = (params: IParamsGetDegreeClassificationManagement) =>
  ApiClientHr.Get({
    url: DegreeClassificationEndPoint.DegreeClassificationManagement.request,
    params: { params },
  })

export const requestCreateDegreeClassificationManagement = (
  body: IPayloadCreateDegreeClassificationManagement,
) =>
  ApiClientHr.Post({
    url: DegreeClassificationEndPoint.DegreeClassificationManagement.request,
    body,
  })

export const requestUpdateDegreeClassificationManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateDegreeClassificationManagement
}) =>
  ApiClientHr.Put({
    url: `${DegreeClassificationEndPoint.DegreeClassificationManagement.request}/${id}`,
    body,
  })

export const requestDetailDegreeClassificationManagement = (id?: string | number) =>
  ApiClientHr.Get({
    url: `${DegreeClassificationEndPoint.DegreeClassificationManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteDegreeClassificationManagement = (id?: string | number) =>
  ApiClientHr.Delete({
    url: `${DegreeClassificationEndPoint.DegreeClassificationManagement.request}/${id}`,
    body: {},
  })

export const requestGetAllDegreeClassificationManagement = () =>
  ApiClientHr.Get({
    url: `${DegreeClassificationEndPoint.DegreeClassificationManagement.request}/get-all`,
    params: {},
  })
