import {
  CampaignReferralEnum,
  CampaignTypeEnum,
} from 'app/api/marketing/discount-campaign/model'
import {
  DefineApplyRuleKey,
  DefineTargetUserEnum,
} from 'app/api/marketing/discount-voucher/constant'
import {
  DiscountTypeEnum,
  EVerifyRuleKey,
  MethodIssueEnum,
  TargetUserEnum,
} from 'app/api/marketing/discount-voucher/enum'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDetailVoucherContext } from '../../../../context'
import { ModalUserDisplayLayout } from '../../../../modal'
import { BaseButton } from 'app/components/common/BaseButton'
import { DownloadOutlined } from '@ant-design/icons'
import { DefineCampaignReferralEnum } from 'app/api/marketing/discount-campaign/constant'
import { ModalReferralDisplayLayout } from '../../../../modal/ModalReferralDisplay'

export const InfoCustomerLayout = () => {
  const { t } = useTranslation()
  const {
    methodIssueVoucher,
    campaignType,
    targetUserFile,
    lisCustomer,
    itemVoucher,
    verifyRuleKey,
    targetReferralFile,
    itemCampaign,
  } = useDetailVoucherContext()

  return (
    <BaseSpace size={4}>
      {isEqual(itemCampaign?.referralType, CampaignReferralEnum.Normal) ||
      isEqual(itemCampaign?.referralType, CampaignReferralEnum.OnlyUser) ? (
        <BaseSpace size={4}>
          <FormItem
            label={t(R.strings.apply_rule)}
            required
            name={'verifyRuleKey'}
          >
            <BaseSelect
              placeholder={t(R.strings.select_apply_rule)}
              options={[
                {
                  label: t(DefineApplyRuleKey?.[EVerifyRuleKey.All]?.keyI18n),
                  value: EVerifyRuleKey.All,
                },
                {
                  label: t(
                    DefineApplyRuleKey?.[EVerifyRuleKey.NewPatient]?.keyI18n,
                  ),
                  value: EVerifyRuleKey.NewPatient,
                },
                {
                  label: t(
                    DefineApplyRuleKey?.[EVerifyRuleKey.PatientId]?.keyI18n,
                  ),
                  value: EVerifyRuleKey.PatientId,
                },
              ]}
              disabled
            />
          </FormItem>

          {isEqual(methodIssueVoucher, MethodIssueEnum.Auto) &&
          !isEqual(verifyRuleKey, EVerifyRuleKey.NewPatient) ? (
            <FormItem
              label={t(R.strings.target_user)}
              required
              name={'targetUser'}
            >
              <BaseSelect
                placeholder={t(R.strings.select_target_user)}
                options={[
                  {
                    label: t(
                      DefineTargetUserEnum?.[TargetUserEnum.Customer]?.keyI18n,
                    ),
                    value: TargetUserEnum.Customer,
                  },
                  {
                    label: t(
                      DefineTargetUserEnum?.[TargetUserEnum.VipCustomer]
                        ?.keyI18n,
                    ),
                    value: TargetUserEnum.VipCustomer,
                  },
                  {
                    label: t(
                      DefineTargetUserEnum?.[TargetUserEnum.CompanyEmployee]
                        ?.keyI18n,
                    ),
                    value: TargetUserEnum.CompanyEmployee,
                  },
                ]}
                disabled
              />
            </FormItem>
          ) : null}

          {targetUserFile &&
          isEqual(methodIssueVoucher, MethodIssueEnum.Auto) &&
          isEqual(verifyRuleKey, EVerifyRuleKey.PatientId) ? (
            <FormItem hiddenLabel>
              <BaseRow
                className="custom-characters-wrapper"
                gutter={[8, 8]}
                align={'middle'}
                style={{
                  marginLeft: 0,
                  marginRight: 0,
                }}
              >
                <BaseCol>
                  <BaseText
                    children={`${t(R.strings.customer_list)}:`}
                    fontWeight="medium"
                    fontSize="xs"
                    opacity="0.7"
                  />
                </BaseCol>
                <BaseCol>
                  <ModalUserDisplayLayout
                    fileName={targetUserFile?.name ?? ''}
                    users={lisCustomer}
                    id={itemVoucher?._id}
                  />
                </BaseCol>
                <BaseCol>
                  <a href={targetUserFile?.path}>
                    <BaseButton icon={<DownloadOutlined rev={undefined} />} />
                  </a>
                </BaseCol>
              </BaseRow>
            </FormItem>
          ) : null}
        </BaseSpace>
      ) : null}

      <FormItem
        label={t(R.strings.referral_target)}
        required
        rules={[
          {
            required: true,
            message: t(R.strings.require_field, {
              field: t(R.strings.referral_target),
            }),
          },
        ]}
        name={'referralTarget'}
        initialValue={itemCampaign?.referralType ?? CampaignReferralEnum.Normal}
      >
        <BaseSelect
          placeholder={t(R.strings.select_referral_target)}
          options={[
            {
              label: t(
                DefineCampaignReferralEnum?.[CampaignReferralEnum.Normal]
                  ?.keyI18n,
              ),
              value: CampaignReferralEnum.Normal,
            },
            {
              label: t(
                DefineCampaignReferralEnum?.[CampaignReferralEnum.Patient]
                  ?.keyI18n,
              ),
              value: CampaignReferralEnum.Patient,
            },
            {
              label: t(
                DefineCampaignReferralEnum?.[CampaignReferralEnum.Staff]
                  ?.keyI18n,
              ),
              value: CampaignReferralEnum.Staff,
            },
            {
              label: t(
                DefineCampaignReferralEnum[CampaignReferralEnum.OnlyUser]
                  ?.keyI18n,
              ),
              value: CampaignReferralEnum.OnlyUser,
            },
          ]}
          filterSort={() => 0}
          value={itemCampaign?.referralType ?? CampaignReferralEnum.Normal}
          disabled
        />
      </FormItem>

      {isEqual(itemCampaign?.referralType, CampaignReferralEnum.Patient) ||
      isEqual(itemCampaign?.referralType, CampaignReferralEnum.Staff) ? (
        <FormItem hiddenLabel>
          <BaseRow
            className="custom-characters-wrapper"
            gutter={[8, 8]}
            align={'middle'}
            style={{
              marginLeft: 0,
              marginRight: 0,
            }}
          >
            <BaseCol>
              <BaseText
                children={`${t(R.strings.customer_list)}:`}
                fontWeight="medium"
                fontSize="xs"
                opacity="0.7"
              />
            </BaseCol>
            {targetReferralFile?.name ? (
              <BaseCol>
                <ModalReferralDisplayLayout
                  fileName={targetReferralFile?.name ?? ''}
                  id={itemVoucher?._id}
                />
              </BaseCol>
            ) : (
              <BaseCol>
                <BaseText
                  children={t(R.strings.empty_list)}
                  opacity="0.7"
                  fontSize="xxs"
                  fontWeight="medium"
                />
              </BaseCol>
            )}
            {targetReferralFile?.path ? (
              <BaseCol>
                <a href={targetReferralFile?.path}>
                  <BaseButton icon={<DownloadOutlined rev={undefined} />} />
                </a>
              </BaseCol>
            ) : null}
          </BaseRow>
        </FormItem>
      ) : null}

      {!isEqual(itemCampaign?.campaignType, CampaignTypeEnum.Voucher) ? (
        <BaseRow gutter={[16, 16]}>
          <BaseCol xl={12}>
            <FormItem label={t(R.strings.number_of_uses)} name={'numberOfUses'}>
              <InputNumberWrapper
                placeholder={t(R.strings.enter_number_of_uses)}
                min={1}
                step={1}
                precision={0}
                disabled
              />
            </FormItem>
          </BaseCol>

          <BaseCol xl={12}>
            <FormItem
              label={t(R.strings.number_of_uses_per_customer)}
              name={'numberOfUsesPerCustomer'}
            >
              <InputNumberWrapper
                placeholder={t(R.strings.enter_number_of_uses_per_customer)}
                step={1}
                precision={0}
                disabled
              />
            </FormItem>
          </BaseCol>
        </BaseRow>
      ) : null}

      {isEqual(campaignType, CampaignTypeEnum.Voucher) ? (
        <BaseRow gutter={[16, 8]} align={'top'}>
          <BaseCol xl={12}>
            <FormItem
              label={t(R.strings.discount_type)}
              required
              name={'discountType'}
            >
              <BaseSelect
                placeholder={t(R.strings.select_discount_type)}
                options={[
                  {
                    label: t(R.strings.cash_discount),
                    value: DiscountTypeEnum.Amount,
                  },
                  {
                    label: t(R.strings.discount_percent),
                    value: DiscountTypeEnum.Percent,
                  },
                ]}
                disabled
              />
            </FormItem>
          </BaseCol>
          <BaseCol xl={12}>
            <FormItem
              label={t(R.strings.discount_value)}
              required
              name={'discountValue'}
              dependencies={['discountType']}
            >
              <InputNumberWrapper
                placeholder={t(R.strings.enter_discount_value)}
                min={0}
                disabled
              />
            </FormItem>
          </BaseCol>
        </BaseRow>
      ) : null}

      <BaseRow gutter={[16, 8]} align={'top'}>
        <BaseCol xl={12}>
          <BaseForm.Item
            name={'startDate'}
            required
            label={t(R.strings.start_date)}
          >
            <BaseDatePickerStyled
              format={'DD-MM-YYYY HH:mm'}
              placeholder={t(R.strings.start_date)}
              disabled
            />
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseForm.Item name={'endDate'} label={t(R.strings.end_date)}>
            <BaseDatePickerStyled
              format={'DD-MM-YYYY HH:mm'}
              placeholder={t(R.strings.no_end_date)}
              disabled
            />
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>
    </BaseSpace>
  )
}

const FormItem = styled(BaseForm.Item)`
  width: 100%;
`

const InputNumberWrapper = styled(InputNumber)`
  width: 100%;
`

const BaseDatePickerStyled = styled(BaseDatePicker)`
  width: 100%;
`
