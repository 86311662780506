import { StatusTableType } from 'app/components/tables/BaseTableManagement/hook'

export interface IResponseFilter {
  status?: StatusTableType[]
  search?: string
  industry?: string | null
  levelItemCategory?: string | null
  rangeExperience?: string | null
}

export interface IFilterProps {
  handleChangeFilter?: (data: IResponseFilter) => void
  filterData?: IResponseFilter
}

export const initResponseFilterTableManagement = {
  search: '',
  status: [],
  industry: null,
  levelItemCategory: null,
  rangeExperience: null,
}
