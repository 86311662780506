import { ApiClient } from 'app/service/api-service'
import { MarketingEndPoint } from './constant'
import { IParamsGetMarketingPlan } from './model'

export const requestGetMarketingPlan = (params: IParamsGetMarketingPlan) =>
  ApiClient.Get({
    url: MarketingEndPoint.MarketingPlan.requestGetData,
    params: { params },
  })

export const requestImportMarketingPlan = (body: FormData) =>
  ApiClient.Post({
    url: MarketingEndPoint.MarketingPlan.requestImportFile,
    body,
  })
