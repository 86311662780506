import { ITreatmentList } from 'app/api/treatment/model'
import BaseText from 'app/components/common/BaseText'
import { SeeMoreButton, ServiceText } from '../styles'
import { useState } from 'react'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { flatMap } from 'lodash'

interface IProps {
  data: ITreatmentList[]
}
export const TreatmentList = (props: IProps) => {
  const { data } = props
  const { t } = useTranslation()

  const [isCollapse, setIsCollapse] = useState(true)

  const onCollapseClick = () => {
    setIsCollapse(true)
  }
  const onSeeMoreClick = () => {
    setIsCollapse(false)
  }

  return isCollapse ? (
    <>
      <ServiceText
        children={data[0]?.data && `1. ${data[0]?.data[0]?.name}`}
        textAlign="left"
      />
      {flatMap(data, 'data')?.length > 1 && (
        <SeeMoreButton
          children={t(R.strings.see_more)}
          onClick={onSeeMoreClick}
        />
      )}
    </>
  ) : (
    <>
      {data?.map((item, index) => (
        <>
          {item?.data?.map(i => (
            <>
              <ServiceText
                children={`${index + 1}. ${i?.name}`}
                textAlign="left"
              />
            </>
          ))}
        </>
      ))}
      {flatMap(data, 'data')?.length > 1 && (
        <SeeMoreButton
          children={t(R.strings.collapse)}
          onClick={onCollapseClick}
        />
      )}
    </>
  )
}
