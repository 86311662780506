import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable, StatusDataTable } from './type'
import { _DEV_ } from 'app/constant'
import { getArray } from 'app/components/tables/common-table/constant'
import { BORDER_RADIUS, DateUtil, PADDING } from 'parkway-web-common'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import R from 'app/assets/R'
import styled from 'styled-components'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import BaseText from 'app/components/common/BaseText'
import _ from 'lodash'

export const useHook = () => {
  const [data, setData] = useState<IDataTable[]>([])
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()
  const getData = async () => {
    setLoading(true)
    try {
      const data = getArray(100).map(i => {
        return {
          key: i.toString(),
          stt: i,
          date: DateUtil.formatDate(new Date(), 'HH:mm DD/MM/YYYY'),
          name: 'Chốt phương án xây dựng sản phẩm',
          status: _.random(1, 2),
        } as IDataTable
      })
      setData(data)
    } catch (error) {
      _DEV_ && console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getData()
    }, 500)

    return () => clearTimeout(timer)
  }, [])

  const columns = useMemo(() => {
    return [
      convertColumnTable<IDataTable>({
        title: t(R.strings.ordinal_number),
        key: 'stt',
        render: (_, record) => (
          <RenderValueTable
            value={record?.stt ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
        classNameWidthColumnOverwrite: 'number-column',
        fixed: 'left',
        className: 'class-left',
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.name_work),
        key: 'name',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable value={record?.name ?? '-'} type="Main" />
        ),
        fixed: 'left',
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.appointment_date),
        key: 'date',
        render: (_, record) => (
          <RenderValueTable
            value={record?.date ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.status),
        key: 'status',
        render: (_, record) => (
          <StatusWrapper $type={record?.status}>
            <BaseText
              children={(()=> {
                switch (record?.status) {
                  case StatusDataTable.Doing: {
                    return t(R.strings.doing)
                  }
                  case StatusDataTable.Pending: {
                    return  t(R.strings.pending)
                  }
                }
              })()}
              fontWeight="semibold"
              fontSize='xs'
              colorText={(() => {
                switch (record?.status) {
                  case StatusDataTable.Doing: {
                    return 'otherBlueColor'
                  }
                  case StatusDataTable.Pending: {
                    return 'statesOrangeColor'
                  }
                }
              })()}
            />
          </StatusWrapper>
        ),
      }),
    ]
  }, [t])

  return {
    columns,
    tableData: {
      data,
      loading,
    },
  }
}

const StatusWrapper = styled.div<{ $type?: StatusDataTable }>`
  background-color: ${props => {
    switch (props?.$type) {
      case StatusDataTable.Doing:
        return convertedVariables.statesBlueLightColor
      case StatusDataTable.Pending:
        return convertedVariables.statesOrangeLightColor
    }
  }};
  padding: ${PADDING.xxs};
  border-radius: ${BORDER_RADIUS};
  width: fit-content;
`
