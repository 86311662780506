import { IBaseTableReport } from 'app/components/tables/BaseTableReport/type'
import { Pagination } from 'parkway-web-common'

export const initialPagination: Pagination = {
  current: 1,
  pageSize: 30,
}

export interface IDataRevenue extends IBaseTableReport<IDataRevenue> {
  area?: string
  ordinal_number?: number
  clinic?: string
  totalActual?: number
  totalRevenue?: number
  revenue?: number
  actual?: number
  totalProjected?: number
  projected?: number
  key?: number
  children?: IDataRevenue[]

  parentKey?: number
  isUpValue?: boolean
  valueGrow?: number
  isTotalUpValue?: boolean
  totalValueGrow?: number

  isUpValueActual?: boolean
  valueGrowActual?: number
  isTotalUpValueActual?: boolean
  totalValueGrowActual?: number

  isUpValueProjected?: boolean
  valueGrowProjected?: number
  isTotalUpValueProjected?: boolean
  totalValueGrowProjected?: number
}

export interface IDataTableDashboard {
  data: IDataRevenue[]
  pagination: Pagination
}

export const getTreeTableData = (
  pagination: Pagination,
): Promise<IDataTableDashboard> => {
  return new Promise(res => {
    setTimeout(() => {
      res({
        data: [],
        pagination: { ...pagination, total: 8 },
      })
    }, 1000)
  })
}
