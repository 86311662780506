/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetAllJobTitleManagement } from 'app/api/jobtitle'
import { JobTitleMessageCode } from 'app/api/jobtitle/constant'
import { IJobTitle } from 'app/api/jobtitle/model/job-title-management'
import {
  requestDetailUnitManagement,
  requestGetUnitManagement,
  requestUpdateUnitManagement,
} from 'app/api/unit'
import { requestGetUnitGroupAllManagement } from 'app/api/unit-group'
import { UnitGroupMessageCode } from 'app/api/unit-group/constant'
import { IUnitGroup } from 'app/api/unit-group/model/management'
import { UnitMessageCode } from 'app/api/unit/constant'
import {
  IPayloadUpdateUnitManagement,
  IResponseGetUnitManagement,
  IUnitManagement,
} from 'app/api/unit/model/unit-management'
import { requestGetUnitTypeAllManagement } from 'app/api/unittype'
import { UnitTypeMessageCode } from 'app/api/unittype/constant'
import { IUnitType } from 'app/api/unittype/model/unit-type-management'
import R from 'app/assets/R'
import {
  convertArrayStatusFilterToArrayStatusNumber,
  convertStatusToFilterStatus,
} from 'app/common/helpers'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { _DEV_ } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { isEmpty, isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormData, IFormRule, IPropsUpdateUnit, StatusUnitEnum } from './type'

function replaceChildCode(
  originalString: string,
  oldSubstring: string,
  newSubstring: string,
): string {
  return originalString.replace(`${oldSubstring}-`, newSubstring)
}

export const useModalHook = (props: IPropsUpdateUnit) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormData>()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [unitGroups, setUnitGroups] = useState<IUnitGroup[]>([])
  const [unitTypes, setUnitTypes] = useState<IUnitType[]>([])
  const [jobTitles, setJobTitles] = useState<IJobTitle[]>([])
  const [parentId, setParentId] = useState<string | undefined>(undefined)
  const [parentCodeSelected, setParentCodeSelected] = useState<
    string | undefined
  >(undefined)

  const [typeId, setTypeId] = useState<string | undefined>(undefined)

  const [unitsFormApi, setUnitsFromApi] = useState<IUnitManagement[]>([])

  const getDetail = async () => {
    if (!props?.id) {
      return
    }
    try {
      const res: ResponseType<IUnitManagement> =
        await requestDetailUnitManagement(props?.id)

      if (
        isEqual(res?.msgcode, UnitMessageCode.UnitManagement.successGetData)
      ) {
        const data = res?.data

        const { code = '', unitParentCode = '' } = data

        const formattedCode = replaceChildCode(code, unitParentCode, '')
        const newParentId = isEmpty(data?.parentid) ? undefined : data?.parentid

        form.setFieldsValue({
          name: data?.name ?? '',
          code: formattedCode,
          unitParentName: data.unitParentName,
          description: data?.description ?? '',
          jobTitleIdManagement: isEmpty(data?.jobTitleIdManagement)
            ? undefined
            : data?.jobTitleIdManagement,
          unitGroupId: data?.unitGroupId,
          unitTypeId: data?.unitTypeId,
          unitParentCode: data?.unitParentCode,
          parentid: newParentId,
          jobTitleIdVice: isEmpty(data?.jobTitleIdVice)
            ? undefined
            : data?.jobTitleIdVice,
          _id: data._id,
          status: convertStatusToFilterStatus(data?.status),
          clinicType: data?.clinicType,
        })
        onChangeClinicType(data?.unitTypeId)
        setParentId(newParentId)
        setParentCodeSelected(data?.unitParentCode)
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  const getUnitGroup = async () => {
    try {
      const res: ResponseType<IUnitGroup[]> =
        await requestGetUnitGroupAllManagement()
      if (
        isEqual(
          res?.msgcode,
          UnitGroupMessageCode.UnitGroupManagement.successGetData,
        )
      ) {
        const data: IUnitGroup[] = res?.data || []
        setUnitGroups(data)
      }
    } catch (error) {
      // console.log({ error })
    }
    return []
  }

  const getUnitTypeAll = async () => {
    try {
      const res: ResponseType<IUnitType[]> =
        await requestGetUnitTypeAllManagement()
      if (
        isEqual(
          res?.msgcode,
          UnitTypeMessageCode.UnitTypeManagement.successGetData,
        )
      ) {
        const data: IUnitType[] = res?.data || []
        setUnitTypes(data)
      }
    } catch (error) {
      // console.log({ error })
    }
    return []
  }

  const getAllJobTitle = async () => {
    try {
      const res: ResponseType<IJobTitle[]> =
        await requestGetAllJobTitleManagement()
      if (
        isEqual(
          res?.msgcode,
          JobTitleMessageCode.JobTitleManagement.successGetData,
        )
      ) {
        const data: IJobTitle[] = res?.data || []
        setJobTitles(data)
      }
    } catch (error) {
      // console.log({ error })
    }
    return []
  }

  const getDataUnits = async () => {
    try {
      const response: IResponseGetUnitManagement =
        await requestGetUnitManagement({ pagesize: 100 })

      if (
        isEqual(
          response?.msgcode,
          UnitMessageCode.UnitManagement.successGetData,
        )
      ) {
        const data = response?.data?.rows
        setUnitsFromApi(data ?? [])
      }
    } catch (error) {
      _DEV_ && console.log({ error })
    }
  }

  useEffect(() => {
    visible && getUnitGroup()
    visible && getUnitTypeAll()
    visible && getDetail()
    visible && getAllJobTitle()
    visible && getDataUnits()
  }, [props?.id, visible])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)
    try {
      const unitParentCode = parentCodeSelected ?? values?.unitParentCode
      const code = unitParentCode
        ? `${unitParentCode}-${values.code}`
        : values.code
      const body: IPayloadUpdateUnitManagement = {
        name: values?.name,
        description: values?.description,
        code: code,
        unitGroupId: values?.unitGroupId,
        unitTypeId: values?.unitTypeId,
        jobTitleIdManagement: values?.jobTitleIdManagement,
        parentid: values?.parentid ?? parentId,
        jobTitleIdVice: values?.jobTitleIdVice,
        clinicType: values?.clinicType,
        status:
          convertArrayStatusFilterToArrayStatusNumber([
            values?.status,
          ] as any)?.[0] ?? StatusUnitEnum.active,
      }

      const response: ResponseType<string> = await requestUpdateUnitManagement({
        id: props?.id,
        body,
      })

      if (
        isEqual(
          response?.msgcode,
          UnitMessageCode.UnitManagement.successPutData,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        props?.fetchTable?.()
        notificationController.success({
          message: t(R.strings.update),
          description: response?.message,
        })
      } else {
        notificationController.error({
          message: t(R.strings.update),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const rules: IFormRule = useMemo(() => {
    return {
      parentid: [
        {
          required: true,
          message: t(R.strings.required),
        },
      ],
      code: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.unit_field_unit_string_id),
          }),
        },
      ],
      unitGroupId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.unit_field_unit_department_string_id),
          }),
        },
      ],
      unitTypeId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.unit_field_unitTypeId),
          }),
        },
      ],
      jobTitleIdManagement: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.unit_field_job_title_management),
          }),
        },
      ],
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.unit_field_name),
          }),
        },
      ],
      description: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.unit_field_description),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
      clinicType: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.clinic_type),
          }),
        },
      ],
    }
  }, [t])

  const isHaveParent = useMemo(() => {
    return !isEmpty(parentId)
  }, [parentId, props?.id])

  const units = useMemo(() => {
    if (isHaveParent) return unitsFormApi
    return unitsFormApi?.filter(itm => !isEqual(itm?._id, props?.id))
  }, [unitsFormApi, isHaveParent])

  const onChangeParentCodeSelected = (parentId?: string) => {
    const parentExist = unitsFormApi?.find(itm => isEqual(itm?._id, parentId))
    if (parentExist) {
      setParentCodeSelected(parentExist?.code)
    }
  }

  const onChangeClinicType = (id?: string) => {
    setTypeId(id)
  }

  const type = useMemo(() => {
    return unitTypes?.find(itm => isEqual(itm?._id, typeId))?.name
  }, [unitTypes, typeId])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    unitGroups,
    unitTypes,
    jobTitles,
    parentId,
    units,
    isHaveParent,
    onChangeParentCodeSelected,
    parentCodeSelected,
    type,
    onChangeClinicType,
  }
}
