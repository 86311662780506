export enum ToothStatusEnum {
  teethWithPathology = 1,
  toothLoss = 2,
  implantPlacement = 3,
  toothWithIndicationForExtraction = 4,
  remainingToothRoots = 5,
  toothBridge = 6,
}

export enum PositionToothEnum {
  Top = 'Top',
  Bottom = 'Bottom',
}

export enum ToothNumberEnum {
  Tooth18 = 18,
  Tooth17 = 17,
  Tooth16 = 16,
  Tooth15 = 15,
  Tooth14 = 14,
  Tooth13 = 13,
  Tooth12 = 12,
  Tooth11 = 11,

  Tooth28 = 28,
  Tooth27 = 27,
  Tooth26 = 26,
  Tooth25 = 25,
  Tooth24 = 24,
  Tooth23 = 23,
  Tooth22 = 22,
  Tooth21 = 21,

  Tooth48 = 48,
  Tooth47 = 47,
  Tooth46 = 46,
  Tooth45 = 45,
  Tooth44 = 44,
  Tooth43 = 43,
  Tooth42 = 42,
  Tooth41 = 41,

  Tooth38 = 38,
  Tooth37 = 37,
  Tooth36 = 36,
  Tooth35 = 35,
  Tooth34 = 34,
  Tooth33 = 33,
  Tooth32 = 32,
  Tooth31 = 31,
}

export interface IToothData {
  key: ToothNumberEnum
  position: PositionToothEnum
  currentStatus?: ToothStatusEnum
  imageTooth?: string
  imageToothImplant: string
  value?: string
}

export interface IToothSizeAndMorphology {
  [PositionToothEnum.Top]: {
    [ToothNumberEnum.Tooth18]: IToothData
    [ToothNumberEnum.Tooth17]: IToothData
    [ToothNumberEnum.Tooth16]: IToothData
    [ToothNumberEnum.Tooth15]: IToothData
    [ToothNumberEnum.Tooth14]: IToothData
    [ToothNumberEnum.Tooth13]: IToothData
    [ToothNumberEnum.Tooth12]: IToothData
    [ToothNumberEnum.Tooth11]: IToothData

    [ToothNumberEnum.Tooth21]: IToothData
    [ToothNumberEnum.Tooth22]: IToothData
    [ToothNumberEnum.Tooth23]: IToothData
    [ToothNumberEnum.Tooth24]: IToothData
    [ToothNumberEnum.Tooth25]: IToothData
    [ToothNumberEnum.Tooth26]: IToothData
    [ToothNumberEnum.Tooth27]: IToothData
    [ToothNumberEnum.Tooth28]: IToothData
  }
  [PositionToothEnum.Bottom]: {
    [ToothNumberEnum.Tooth48]: IToothData
    [ToothNumberEnum.Tooth47]: IToothData
    [ToothNumberEnum.Tooth46]: IToothData
    [ToothNumberEnum.Tooth45]: IToothData
    [ToothNumberEnum.Tooth44]: IToothData
    [ToothNumberEnum.Tooth43]: IToothData
    [ToothNumberEnum.Tooth42]: IToothData
    [ToothNumberEnum.Tooth41]: IToothData

    [ToothNumberEnum.Tooth31]: IToothData
    [ToothNumberEnum.Tooth32]: IToothData
    [ToothNumberEnum.Tooth33]: IToothData
    [ToothNumberEnum.Tooth34]: IToothData
    [ToothNumberEnum.Tooth35]: IToothData
    [ToothNumberEnum.Tooth36]: IToothData
    [ToothNumberEnum.Tooth37]: IToothData
    [ToothNumberEnum.Tooth38]: IToothData
  }
}

export interface IBridgeTooth {
  id?: string
  start?: ToothNumberEnum
  indexStart?: number
  end?: ToothNumberEnum
  indexEnd?: number
}

export enum BracesTagEnum {
  Adult = "Adult",
  Child = "Child",
  Teenager = "Teenager",

  ConvexProfile = "Convex profile",
  ConcaveProfile = "Concave profile",
  StraightProfile = "Straight profile",
  ProtrudedChin = "Protruded chin",
  RetrudedChin = "Retruded chin",
  Dolichofacial = "Dolichofacial",
  Mesofacial = "Mesofacial",
  Brachyfacial = "Brachyfacial",
  DentalClassI = "Dental class I",
  DentalClassIIDivI = "Dental class II div I",
  DentalClassIIDiv2 = "Dental class II div 2",
  DentalClassIII = "Dental class III",
  SkeletalClassI = "Skeletal class I",
  SkeletalClassII = "Skeletal class II",
  SkeletalClassIII = "Skeletal class III",
  PosteriorCrossbite = "Posterior crossbite",
  AnteriorCrossbite = "Anterior crossbite",
  EdgeToEdgeBite = "Edge to edge bite",
  IncreaseedOverjet = "Increaseed overjet",
  OpenBite = "Open bite",
  DeepBite = "Deep bite",
  NarrowArches = "Narrow arches",
  AsymmetricArches = "Asymmetric arches",
  MLD = "MLD",
  MidlineShift = "Midline shift",
  Crowding = "Crowding",
  Rotations = "Rotations",
  ImpactedCanine = "Impacted canine",
  Spacing = "Spacing",

  Distalization = "Distalization",
  Mesialization = "Mesialization",
  LelelingCurveOfSpee = "Leleling curve of spee",
  Extrusion = "Extrusion",
  Expansion = "Expansion",
  Proclination = "Proclination",
  FiestPremolarExtraction = "Fiest premolar extraction",
  SecondPremolarExtraction = "Second premolar extraction",
  LowerIncisorExtraction = "Lower incisor extraction",
  OtherExtraction = "Other extraction",
  IPR = "IPR",
  TADs = "TADs",
  SpaceClosure = "Space closure",
  FunctinalAppliances = "Functinal appliances",
  PreRestorative = "Pre-restorative",
  PreSurgical = "Pre-surgical",
  Retroclination = "Retroclination",

  Implants = "Implants",
  Restoration = "Restoration",
}