import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useTranslation } from 'react-i18next'
import SuppliesItem from '../SuppliesItem'
import * as S from './styles'
import AddSuppliesModal from './AddSuppliesModal'
import { isEqual } from 'lodash'
import { useCreateUpdateServiceContext } from 'app/containers/Accounting/TreatmentManagement/CreateUpdateProductServiceManagement/context'
import { addPrefixImage } from 'app/common/helpers'

const SuppliesManagement = () => {
  const { t } = useTranslation()
  const { itemSupplies, measurementUnits } = useCreateUpdateServiceContext()

  return (
    <S.RootWrapper direction="vertical">
      <S.TitleSupplies>
        {t(R.strings.item_management_content_supplies)}
      </S.TitleSupplies>
      <BaseForm.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
          return !isEqual(prevValues.itemIds, currentValues.itemIds)
        }}
      >
        {({ getFieldValue, setFieldValue }) => {
          const itemIds = getFieldValue('itemIds')
          const isNoItem = !itemIds || itemIds?.length === 0

          return (
            <S.ListSuppliesWrapper direction="vertical" size={10}>
              {itemIds?.map((item, index) => {
                const foundSupply = itemSupplies?.find(
                  itemSupply => itemSupply._id === item.itemId,
                )
                const foundMeasurementUnits = measurementUnits?.find(
                  measurementUnit =>
                    measurementUnit._id === foundSupply?.usingMeasurementUnitId,
                )

                const supplies = {
                  ...item,
                  name: foundSupply?.name,
                  mainImagesUrl: addPrefixImage(foundSupply?.mainImagesUrl),
                  usingMeasurementUnitId:
                    foundMeasurementUnits?.name ??
                    foundSupply?.usingMeasurementUnitId ??
                    undefined,
                }
                const itemIds = getFieldValue('itemIds')

                const onDeleteSupplies = itemId => {
                  setFieldValue(
                    'itemIds',
                    itemIds.filter(i => i.itemId !== itemId),
                  )
                }

                const onChangeQuantity = (itemId, quantity) => {
                  const updatedItemIds = itemIds.map(item => {
                    if (item.itemId === itemId) {
                      return { ...item, quantity: quantity }
                    }
                    return item
                  })

                  setFieldValue('itemIds', updatedItemIds)
                }

                return (
                  <SuppliesItem
                    supplies={supplies}
                    key={index}
                    onChangeQuantity={onChangeQuantity}
                    onDeleteSupplies={() => onDeleteSupplies(item.itemId)}
                  />
                )
              })}

              {isNoItem && (
                <BaseCol>
                  {t(R.strings.item_management_content_not_select_supplies)}
                </BaseCol>
              )}
            </S.ListSuppliesWrapper>
          )
        }}
      </BaseForm.Item>

      <AddSuppliesModal />
    </S.RootWrapper>
  )
}

export default SuppliesManagement
