import { isEqual } from 'lodash'
import { getRandomUuid } from 'parkway-web-common'
import { useState } from 'react'
import { BuyOneGetOneItem } from '../type'
import { DiscountTypeEnum } from 'app/api/marketing/discount-campaign/model'

export const useBuyOneGetOneHook = () => {
  const [listBuyOneGetOne, setListBuyOneGetOne] = useState<BuyOneGetOneItem[]>(
    [],
  )

  const onAddNewBuyOneGetOne = () => {
    setListBuyOneGetOne([
      ...listBuyOneGetOne,
      {
        id: getRandomUuid(),
        name: undefined,
        selectedTreatment: [],
        discountTreatment: [],
      },
    ])
  }

  const onChangeNameByIndex = (index: number, name: string) => {
    const newList = listBuyOneGetOne.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          name,
        }
      }
      return item
    })
    setListBuyOneGetOne(newList)
  }

  const onRemoveBuyOneGetOneByIndex = (index: number) => {
    const newList = listBuyOneGetOne.filter((_, i) => i !== index)
    setListBuyOneGetOne(newList)
  }

  const onChangeItemByIndex = (index: number, item: BuyOneGetOneItem) => {
    const newList = listBuyOneGetOne.map((itm, i) => {
      if (isEqual(i, index)) {
        return {
          ...itm,
          ...item,
        }
      }
      return itm
    })
    setListBuyOneGetOne(newList)
  }

  
  const onChangeDiscountValueInDiscountTreatment = (
    index: number,
    treatmentIndex: number,
    discountValue: number,
  ) => {
    const newList = listBuyOneGetOne.map((item, i) => {
      if (isEqual(i, index)) {
        return {
          ...item,
          discountTreatment: item?.discountTreatment?.map((itm, idx) => {
            if (isEqual(idx, treatmentIndex)) {
              return {
                ...itm,
                discountValue,
              }
            }
            return itm
          }),
        }
      }
      return item
    })
    setListBuyOneGetOne(newList)
  }


  const onChangeDiscountTypeInDiscountTreatment = (
    index: number,
    treatmentIndex: number,
    discountType: DiscountTypeEnum,
  ) => {
    const newList = listBuyOneGetOne.map((item, i) => {
      if (isEqual(i, index)) {
        return {
          ...item,
          discountTreatment: item?.discountTreatment?.map((itm, idx) => {
            if (isEqual(idx, treatmentIndex)) {
              return {
                ...itm,
                discountType,
              }
            }
            return itm
          }),
        }
      }
      return item
    })
    setListBuyOneGetOne(newList)
  }

  const onChangeBuyOneGetOneList = (list: BuyOneGetOneItem[]) => {
    setListBuyOneGetOne(list)
  }

  return {
    onAddNewBuyOneGetOne,
    listBuyOneGetOne,
    onRemoveBuyOneGetOneByIndex,
    onChangeNameByIndex,
    onChangeDiscountTypeInDiscountTreatment,
    onChangeDiscountValueInDiscountTreatment,
    onChangeItemByIndex,
    onChangeBuyOneGetOneList
  }
}
