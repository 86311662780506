import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { ITreatmentInAppointmentSession } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { formatMoney } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'

export const PaymentInformationLayout = ({
  treatment,
}: {
  treatment: ITreatmentInAppointmentSession
}) => {
  const { t } = useTranslation()
  return (
    <BaseSpace>
      <BaseText
        children={t(R.strings.payment_information)}
        fontWeight="semibold"
      />

      <BaseRow gutter={[15, 10]} align={'middle'} justify={'space-between'}>
        <BaseCol>
          <BaseText children={t(R.strings.unit_price)} opacity="0.5" />
        </BaseCol>
        <BaseCol>
          <BaseText
            children={formatMoney(treatment?.paymentInfo?.unitPrice)}
            fontWeight="semibold"
          />
        </BaseCol>
      </BaseRow>

      <BaseRow gutter={[15, 10]} align={'middle'} justify={'space-between'}>
        <BaseCol>
          <BaseText children={t(R.strings.discount)} opacity="0.5" />
        </BaseCol>
        <BaseCol>
          <BaseText
            children={formatMoney(treatment?.paymentInfo?.discountAmount)}
            fontWeight="semibold"
          />
        </BaseCol>
      </BaseRow>

      <BaseRow gutter={[15, 10]} align={'middle'} justify={'space-between'}>
        <BaseCol>
          <BaseText children={t(R.strings.total_payment)} opacity="0.5" />
        </BaseCol>
        <BaseCol>
          <BaseText
            children={formatMoney(treatment?.paymentInfo?.totalAmount)}
            fontWeight="semibold"
          />
        </BaseCol>
      </BaseRow>

      <BaseRow gutter={[15, 10]} align={'middle'} justify={'space-between'}>
        <BaseCol>
          <BaseText children={t(R.strings.paid)} opacity="0.5" />
        </BaseCol>
        <BaseCol>
          <BaseText
            children={formatMoney(treatment?.paymentInfo?.paid)}
            fontWeight="semibold"
            colorText="statesGreenColor"
          />
        </BaseCol>
      </BaseRow>
    </BaseSpace>
  )
}
