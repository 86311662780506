import R from 'app/assets/R'
import { GenderEnumKeyI18n } from 'app/common/enum'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { DENTAL_TREATMENT_PATIENT_RECORD_PROFILE_PATH } from 'app/components/router/route-path'
import {
  IResponseFilter,
  initResponseFilterTableManagement,
} from 'app/components/tables/BaseTableManagement/FilterManagementTable/type'
import {
  RenderValueTable,
  StatusTableEnum,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { getArray } from 'app/components/tables/common-table/constant'
import { _DEV_ } from 'app/constant'
import _, { isEqual, random } from 'lodash'
import { Pagination, initialPagination, normalizePhone } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Delete } from './components/Delete'
import { IGeneralRecordPatient } from './type'

export const useHookTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.DASHBOARD,
      FunctionPermissionEnum.DASHBOARD_READ_DOCTOR,
      R.strings.dental_treatment_management,
    )
  }, [])

  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IResponseFilter,
  ) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IGeneralRecordPatient>
    try {
      _DEV_ && console.log({ newFilter })
      // const status = convertArrayStatusFilterToArrayStatusNumber(
      //   newFilter?.status ?? filter?.status,
      // )
      // let params: IParamsGetItemCategoryManagement = {
      //   page: pagination?.current,
      //   pagesize: pagination?.pageSize,
      //   keyword: newFilter?.search ?? filter?.search,
      // }

      // const status = convertStatusFilterToStatus(
      //   newFilter?.status ?? filter?.status,
      // )
      // let params: IParamsGetItemCategoryManagement = {
      //   page: pagination?.current,
      //   pagesize: pagination?.pageSize,
      //   keyword: newFilter?.search ?? filter?.search,
      // }

      // params = { ...params, ...newFilter, status }

      // const response: IResponseGetItemCategoryManagement =
      //   await requestGetItemCategoryManagement(params)

      // if (
      //   isEqual(
      //     response?.msgcode,
      //     ItemCategoryMessageCode.ItemCategoryManagement.successGetData,
      //   )
      // ) {

      const startIndex =
        ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

      const dataTables = getArray(50)?.map((item, index) => {
        return {
          key: +`${item}-${index + 1}`,
          ordinalNumber: startIndex + index + 1,
          address: `Address ${index}`,
          name: `Full name ${index}`,
          phone: `0965259441`,
          email: `phamvankhanh962001@gmail.com`,
          gender: Object.values(GenderEnumKeyI18n)?.[_.random(2)],
          status: Object.values(StatusTableEnum)?.[random(3)],
          _id: `${item}`
        } as IGeneralRecordPatient
      })

      return {
        pagination: {
          current: pagination?.current ?? 1,
          pageSize: pagination?.pageSize,
          total: 50,
        },
        data: dataTables,
      } as IDataBaseTable<IGeneralRecordPatient>
      // }

      // return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, selectRowsTable, handleTableChange, fetch, rowSelection } =
    useHookTableManagement({ getDataTable })

  const handleChangeFilter = (newFilter: IResponseFilter) => {
    if (!isEqual(newFilter, filter)) {
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initialPagination.current,
        },
        newFilter,
      )
    }
  }

  const fetchNewData = () => {
    fetch(initialPagination)
    setFilter(initResponseFilterTableManagement)
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralRecordPatient>({
        title: t(R.strings.ordinal_number),
        fixed: 'left',
        key: 'ordinalNumber',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => (
          <RenderValueTable value={record.ordinalNumber} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralRecordPatient>({
        title: t(R.strings.full_name),
        fixed: 'left',
        key: 'name',
        className: 'column-children',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return (
            <RenderValueTable
              value={record?.name}
              type="Link"
              onClick={() => navigate(DENTAL_TREATMENT_PATIENT_RECORD_PROFILE_PATH, {
                state: {
                  id: record?._id
                }
              })}
            />
          )
        },
      }),
      convertColumnTable<IGeneralRecordPatient>({
        title: t(R.strings.gender),
        key: 'gender',
        className: 'column-children',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return (
            <RenderValueTable
              value={record?.gender ? t(record?.gender) : '-'}
              type="Main"
            />
          )
        },
      }),
      convertColumnTable<IGeneralRecordPatient>({
        title: t(R.strings.phone),
        key: 'phone',
        className: 'column-children',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return <RenderValueTable value={normalizePhone(record?.phone ?? '-') ?? '-'} type="Main" />
        },
      }),
      convertColumnTable<IGeneralRecordPatient>({
        title: t(R.strings.email),
        key: 'email',
        className: 'column-children',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return <RenderValueTable value={record?.email} type="Main" />
        },
      }),
      convertColumnTable<IGeneralRecordPatient>({
        title: t(R.strings.address),
        key: 'address',
        className: 'column-children',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return <RenderValueTable value={record?.address} type="Main" />
        },
      }),
      convertColumnTable<IGeneralRecordPatient>({
        title: t(R.strings.status),
        key: 'status',
        classNameWidthColumnOverwrite: 'small-column',
        fixed: 'right',
        render: (_, record) => (
          <RenderValueTable
            value={record.status}
            type="Status"
            actionComponent={
              <>
                {!isEqual(record?.status, StatusTableEnum.deleted) && (
                  <Delete
                    id={record?._id}
                    fetchTable={fetchNewData}
                    name={record?.name}
                  />
                )}
              </>
            }
          />
        ),
      }),
    ]
    return option
  }, [t, fetchNewData])

  return {
    tableData,
    selectRowsTable,
    handleTableChange,
    fetchNewData,
    rowSelection,
    columns,
    handleChangeFilter,
    filter,
    t,
  }
}
