import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { ModalComponent } from 'app/components/common/ModalComponent'
import { useTranslation } from 'react-i18next'
import { useHook } from './hook'
import { IProps } from './type'

export const AddJobTitleDoctorModalLayout = (props: IProps) => {
  const { t } = useTranslation()
  const { form, modalRef, openModal } = useHook(props)
  return (
    <ModalComponent
      titleModal={t(R.strings.add_job_title_doctor)}
      ref={modalRef}
      buttonOpenCustom={
        <BaseButton
          icon={<PlusOutlined rev={undefined} />}
          onClick={openModal}
          children={
            <BaseText
              children={t(R.strings.add_job_title_doctor)}
              fontWeight="medium"
            />
          }
        />
      }
      renderContent={
        <BaseForm form={form}>
          {/* <BaseSpace>
            <BaseRow gutter={[16, 8]} align={'middle'}>
              <BaseCol xl={12}>
                <BaseFormItem label={t(R.strings.area)} required>
                  <BaseInput
                    disabled
                    placeholder={t(R.strings.area)}
                    value={'Khu vực Hồ Chí Minh'}
                  />
                </BaseFormItem>
              </BaseCol>
              <BaseCol xl={12}>
                <BaseFormItem label={t(R.strings.clinic)} required>
                  <BaseInput
                    disabled
                    placeholder={t(R.strings.clinic)}
                    value={'Phòng khám Đa khoa'}
                  />
                </BaseFormItem>
              </BaseCol>
            </BaseRow>

            <BaseRow>
              <BaseCol>
                <BaseText
                  children={t(R.strings.doctor_nurse_info)}
                  fontWeight="medium"
                  opacity="0.8"
                />
              </BaseCol>
            </BaseRow>
          </BaseSpace> */}
        </BaseForm>
      }
    />
  )
}
