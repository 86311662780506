import { Rule } from 'antd/es/form'
import { ISupplier } from 'app/api/inventory-new/model/management'



export interface IFormRule {
  code?: Rule[]
  name?: Rule[]
  address?: Rule[]
  bankAccount?: Rule[]
  bankName?: Rule[]
  taxIdNumber?: Rule[]
  phone?: Rule[]
}

export const initValues: ISupplier = {
  _id: '',
  name: '',
}

export enum StatusSupplierEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
  deleted = 'deleted',
}

export type StatusSupplierType = keyof typeof StatusSupplierEnum

export interface IPropsUpdateSupplier {
  fetchTable: () => void
  id?: string | number
  supplier: ISupplier
}
