import { Route } from 'react-router-dom'
import {
  UTILITIES_GENERATE_QR_PATH,
  UTILITIES_PATH
} from './route-path'

import { withLoading } from 'app/hocs/with-loading.hoc'
import UtilitiesManagementPage from 'app/page/menu/utilities'
import QRGeneratePage from 'app/page/menu/utilities/patient-record-management'

const UtilitiesManagement = withLoading(UtilitiesManagementPage)
const QRGenerate = withLoading(QRGeneratePage)

export const UtilitiesManagementRouter = () => {
  return (
    <>
      <Route
        path={UTILITIES_PATH}
        element={<UtilitiesManagement />}
      />
      <Route
        path={UTILITIES_GENERATE_QR_PATH}
        element={<QRGenerate />}
      />
    </>
  )
}
