import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { useTranslation } from 'react-i18next'
import { ProfilePatientTypeEnum } from '../../enum'
import { FormInstance } from 'antd'
import { isEqual } from 'lodash'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { IFormRelatives } from '../../type'
import { useCommonHook } from '../../common-hook'

export const FormContentModal = ({
  form,
  onChangeProfilePatientType,
  profilePatientType,
  handleSubmit,
}: {
  form?: FormInstance<unknown>
  profilePatientType?: ProfilePatientTypeEnum
  onChangeProfilePatientType?: (profileType: ProfilePatientTypeEnum) => void
  handleSubmit?: (values: IFormRelatives) => void
}) => {
  const { t } = useTranslation()
  const { rules } = useCommonHook()
  return (
    <BaseForm form={form} onFinish={handleSubmit}>
      <BaseSpace size={0}>
        <BaseForm.Item
          label={t(R.strings.patient_record)}
          required
          name={'profilePatientType'}
          rules={rules.profilePatientType}
        >
          <BaseSelect
            placeholder={t(R.strings.select_patient_record)}
            value={ProfilePatientTypeEnum}
            onChange={value =>
              onChangeProfilePatientType?.(value as ProfilePatientTypeEnum)
            }
          >
            <Option value={ProfilePatientTypeEnum.AlreadyAPatient}>
              {t(R.strings.already_a_patient)}
            </Option>
            <Option value={ProfilePatientTypeEnum.NotAPatientYet}>
              {t(R.strings.not_a_patient_yet)}
            </Option>
          </BaseSelect>
        </BaseForm.Item>

        {isEqual(profilePatientType, ProfilePatientTypeEnum.AlreadyAPatient) ? (
          <BaseSpace>
            <BaseRow align={'middle'} gutter={[15, 10]}>
              <BaseCol xl={12}>
                <BaseForm.Item
                  label={t(R.strings.name)}
                  required
                  name="name"
                  rules={rules.name}
                >
                  <BaseSelect placeholder={t(R.strings.select)}>
                    <Option value={ProfilePatientTypeEnum.AlreadyAPatient}>
                      {t(R.strings.already_a_patient)}
                    </Option>
                    <Option value={ProfilePatientTypeEnum.NotAPatientYet}>
                      {t(R.strings.not_a_patient_yet)}
                    </Option>
                  </BaseSelect>
                </BaseForm.Item>
              </BaseCol>

              <BaseCol xl={12}>
                <BaseForm.Item
                  required
                  label={t(R.strings.msbn)}
                  name="patientCode"
                  rules={rules.patientCode}
                >
                  <BaseInput placeholder={t(R.strings.enter_patient_code)} />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>
          </BaseSpace>
        ) : (
          <BaseSpace>
            <BaseForm.Item
              required
              label={t(R.strings.name)}
              name={'name'}
              rules={rules.name}
            >
              <BaseInput placeholder={t(R.strings.name)} />
            </BaseForm.Item>
          </BaseSpace>
        )}

        <BaseRow align={'middle'} gutter={[15, 10]}>
          <BaseCol xl={12}>
            <BaseForm.Item
              label={t(R.strings.relationship)}
              required
              name={'relationship'}
              rules={rules.relationship}
            >
              <BaseSelect placeholder={t(R.strings.select)}>
                <Option value={ProfilePatientTypeEnum.AlreadyAPatient}>
                  {t(R.strings.already_a_patient)}
                </Option>
                <Option value={ProfilePatientTypeEnum.NotAPatientYet}>
                  {t(R.strings.not_a_patient_yet)}
                </Option>
              </BaseSelect>
            </BaseForm.Item>
          </BaseCol>

          <BaseCol xl={12}>
            <BaseForm.Item
              required
              label={t(R.strings.phone)}
              name={'phone'}
              rules={rules.phone}
            >
              <BaseInput placeholder={t(R.strings.enter_phone)} />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>

        <BaseForm.Item
          required
          label={t(R.strings.email)}
          name={'email'}
          rules={rules.email}
        >
          <BaseInput placeholder={t(R.strings.email)} />
        </BaseForm.Item>

        <BaseForm.Item
          required
          label={t(R.strings.address)}
          name={'address'}
          rules={rules.address}
        >
          <BaseInput placeholder={t(R.strings.address)} />
        </BaseForm.Item>
      </BaseSpace>
    </BaseForm>
  )
}
