import R from 'app/assets/R'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import * as S from './styles'
import SuppliesOptionSelect from 'app/containers/Accounting/ItemManagement/components/modal/supplies/components/SuppliesOptionSelect'
import { Option } from 'app/components/common/selects/BaseSelect'
import { useCreateUpdateServiceContext } from 'app/containers/Accounting/TreatmentManagement/CreateUpdateProductServiceManagement/context'

interface IFormData {
  quantity?: number
  supplies?: string
}

const AddSuppliesModal = () => {
  const {
    itemSupplies,
    isLoadingSupply,
    form: formMain,
  } = useCreateUpdateServiceContext()

  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormData>()
  const [visible, setVisible] = useState(false)
  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleSubmit = values => {
    const itemIds = formMain?.getFieldValue('itemIds') ?? []
    const foundItemId = itemIds.find(
      itemId => itemId.itemId === values.supplies,
    )
    const newItemIds = foundItemId
      ? itemIds.map(itemId =>
          itemId === foundItemId
            ? { itemId: foundItemId.itemId, quantity: values.quantity }
            : itemId,
        )
      : [...itemIds, { itemId: values.supplies, quantity: values.quantity }]

    formMain?.setFieldValue('itemIds', newItemIds)
    setVisible(false)
  }

  return (
    <BaseModalManagement
      typeButtonOpen="hyperlink"
      titleKeyI18n={R.strings.item_management_content_add_supplies}
      isLoading={false}
      content={
        <BaseForm form={form} onFinish={handleSubmit} layout="vertical">
          <S.FormItem name="supplies" hiddenLabel>
            <S.SelectBaseStyled
              loading={isLoadingSupply}
              placeholder={t(R.strings.item_management_content_select_supplies)}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {itemSupplies?.map(supply => {
                return (
                  <Option value={supply._id} key={`supply-${supply._id}`}>
                    <SuppliesOptionSelect item={supply} />
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>

          <S.FormItem
            name="quantity"
            hiddenLabel
            rules={[
              {
                min: 1,
                type: 'number',
                message: t(R.strings.quantity_must_be_greater_than_0),
              },
            ]}
          >
            <S.FormInputNumber
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.quantity),
              })}
            />
          </S.FormItem>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      handleSubmit={form.submit}
      confirmKeyI18n={R.strings.confirm}
      visible={visible}
      textOpenModalI18n={R.strings.item_management_content_add_supplies}
    />
  )
}

export default AddSuppliesModal
