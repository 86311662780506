/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormData, IFormRule } from './type'
import { IIndustry } from 'app/api/skill/model/management'
import { useCreateUpdateServiceContext } from 'app/containers/Accounting/TreatmentManagement/CreateUpdateProductServiceManagement/context'

export const initValues: IFormData = {
  skillsInfo: [],
}

export const useModalHook = () => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const {
    form: formCreateTreatment,
    industries,
    listSkill,
  } = useCreateUpdateServiceContext()
  const [visible, setVisible] = useState(false)
  const [industrySelected, setIndustrySelected] = useState<IIndustry>()

  const selectedSkills =
    formCreateTreatment?.getFieldValue('skillsInfo')?.map(skill => skill?.id) ??
    []
  form.setFieldValue('skillsInfo', selectedSkills)

  const onChangeIndustry = (value: unknown) => {
    const industry = industries?.find(item => item._id === value)
    setIndustrySelected(industry)
    form?.resetFields(['skill_id'])
  }
  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form?.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    const skillId = values.skillsInfo
    const skills = listSkill?.filter(skill => skillId?.includes(skill.id))
    formCreateTreatment?.setFieldValue('skillsInfo', skills)
    setVisible(false)
  }

  const skillIds = listSkill?.map(skill => skill.id) ?? []

  const handleSelectAllChange = e => {
    const { checked } = e.target
    form?.setFieldValue('skillsInfo', checked ? skillIds : [])
  }

  const handleCheckboxChange = checkedValues => {
    form?.setFieldValue('skillsInfo', checkedValues)
  }

  const rules: IFormRule = useMemo(() => {
    return {
      industry_id: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.item_management_content_job_title_level_doctor),
          }),
        },
      ],
      skill_id: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.item_management_content_job_title_level_doctor),
          }),
        },
      ],
      level_id: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.item_management_content_job_title_level_doctor),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    industrySelected,
    onChangeIndustry,
    industries,
    handleSelectAllChange,
    handleCheckboxChange,
    listSkill,
  }
}
