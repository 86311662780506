import { CompanyInfoComponent } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/CompanyComponent'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { IOrderDebtPayment } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/debt-payment'

export const CompanyInfoLayout = ({
  orderItem,
}: {
  orderItem: IOrderDebtPayment
}) => {
  const { onChangeCompanyInfoOrderDebtPayment } = useProfilePatientContext()

  const _onChangeCompanyName = (value: string) => {
    onChangeCompanyInfoOrderDebtPayment?.(orderItem?.orderId, 'name', value)
  }

  const _onChangeCompanyTaxId = (value: string) => {
    onChangeCompanyInfoOrderDebtPayment?.(orderItem?.orderId, 'mst', value)
  }

  const _onChangeCompanyAddress = (value: string) => {
    onChangeCompanyInfoOrderDebtPayment?.(orderItem?.orderId, 'address', value)
  }

  const _onChangeCompanyEmail = (value: string) => {
    onChangeCompanyInfoOrderDebtPayment?.(orderItem?.orderId, 'email', value)
  }

  return (
    <CompanyInfoComponent
      company={orderItem?.company}
      onChangeCompanyAddress={_onChangeCompanyAddress}
      onChangeCompanyEmail={_onChangeCompanyEmail}
      onChangeCompanyName={_onChangeCompanyName}
      onChangeCompanyTaxId={_onChangeCompanyTaxId}
    />
  )
}
