/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetCategoryTreatment } from 'app/api/category'
import { CategoryMessageCode } from 'app/api/category/constant'
import {
  ICategoryTreatment,
  IParamsGetCategoryTreatment,
  IResponseGetCategoryTreatment,
} from 'app/api/category/model'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { useInfiniteQuery } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { CategoryKeys } from '../query-key/category'

export function useGetCategoryTreatmentList(
  params: IParamsGetCategoryTreatment,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<ICategoryTreatment[]>, undefined>(
    CategoryKeys.listCategory(),
    async () => {
      try {
        const res: IResponseGetCategoryTreatment =
          await requestGetCategoryTreatment(params)

        if (
          isEqual(
            res?.msgcode,
            CategoryMessageCode.CategoryTreatment.successGetData,
          )
        ) {
          const data: ICategoryTreatment[] = res?.data?.rows || []

          return {
            data,
            total: res?.data?.total ?? 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
