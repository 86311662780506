import { Rule } from 'antd/es/form'

export interface IFormData {
  name?: string
  status?: string
  parentId?: string
}

export interface IFormRule {
  name?: Rule[]
  status?: Rule[]
}

export enum StatusTableEnumCreate {
  active = 'active',
  inactive = 'inactive',
  // error = 'error',
}
