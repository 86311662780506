import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StatusTaskEnum, dataTasksDashboard } from './data'
import * as S from './styles'
import styled from 'styled-components'

export const TaskComponent = () => {
  const { t } = useTranslation()

  // state see more
  const [seeMore, setSeeMore] = useState(false)

  const defineStatusText = (props: { status: StatusTaskEnum }) => {
    switch (props.status) {
      case StatusTaskEnum.NOT_DONE:
        return t(R.strings.not_done)
      case StatusTaskEnum.IN_PROGRESS:
        return t(R.strings.in_progress)
      case StatusTaskEnum.DONE:
        return t(R.strings.done)
      default:
        return t(R.strings.not_done)
    }
  }

  const onClickSeeMore = () => {
    setSeeMore(!seeMore)
  }

  return (
    <S.RootWrapper>
      <S.HeadWrapper justify={'space-between'} align={'middle'}>
        <S.TitleText>{t(R.strings.my_tasks)}</S.TitleText>
        <S.CountWrapper>{dataTasksDashboard.length}</S.CountWrapper>
      </S.HeadWrapper>
      <S.Line />
      <BaseSpace>
        {dataTasksDashboard
          ?.slice(0, seeMore ? dataTasksDashboard?.length : 3)
          ?.map((item, index) => {
            return (
              <S.ItemTaskWrapper
                key={`${item.id} ${index}`}
                justify={'space-between'}
                align={'middle'}
              >
                <S.TitleItemText>{item?.title}</S.TitleItemText>
                <S.StatusItemText status={item?.status}>
                  {defineStatusText({ status: item?.status })}
                </S.StatusItemText>
              </S.ItemTaskWrapper>
            )
          })}
        <BaseRow justify={'end'}>
          <ButtonSeeMore onClick={onClickSeeMore}>
            <BaseText
              children={seeMore ? t(R.strings.collapse) : t(R.strings.see_more)}
              colorText="collapseBackgroundColor"
              fontWeight="medium"
            />
          </ButtonSeeMore>
        </BaseRow>
      </BaseSpace>
    </S.RootWrapper>
  )
}

const ButtonSeeMore = styled.div`
  cursor: pointer;
`
