import { convertedVariables } from "app/styles/themes/themeVariables"
import styled from "styled-components"

export const VerticalLine = () => {
    return <VerticalLineStyled />
}


const VerticalLineStyled = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${convertedVariables.neutralBlack9Color};
`