import styled from 'styled-components'
import { FONT_SIZE, FONT_WEIGHT, media } from 'parkway-web-common'
import { BaseTypography } from 'app/components/common/BaseTypography'

export const BaseFormTitle = styled(BaseTypography.Text)`
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: 1rem;
  display: block;

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xxs};
  }
`
