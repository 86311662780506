import { INurseTreatmentSchedule } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { getRandomUuid } from 'parkway-web-common'

export const nurseData: INurseTreatmentSchedule[] = [
  {
    name: 'Nguyễn Minh Ngọc',
    jobTitle: 'Y Tá',
    levelName: '-',
    id: getRandomUuid(),
  },
  {
    name: 'Chu Đào Giáng My',
    jobTitle: 'Y Tá',
    levelName: '-',
    id: getRandomUuid(),
  },

  {
    name: 'Nguyễn Thị Hoa Mai',
    jobTitle: 'Y Tá',
    levelName: '-',
    id: getRandomUuid(),
  },
  {
    name: 'Nguyễn Linh Chi',
    jobTitle: 'Y Tá',
    levelName: '-',
    id: getRandomUuid(),
  },
  {
    name: 'Phạm Thị Bích Ngọc',
    jobTitle: 'Y Tá',
    levelName: '-',
    id: getRandomUuid(),
  },
]
