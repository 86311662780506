import React, { ComponentProps, useEffect, useState } from 'react'
import { Select as AntSelect } from 'antd'
import { RefSelectProps } from 'antd/lib/select'
import * as S from './styles'
import { Dimension } from 'parkway-web-common'
import { BaseInput } from '../../inputs/BaseInput'
import { convertVietnameseString } from 'app/common/helpers'
import { t } from 'i18next'
import R from 'app/assets/R'

export const { Option } = AntSelect

export interface BaseSelectSearchProps
  extends ComponentProps<typeof AntSelect> {
  width?: Dimension
  shadow?: boolean
  className?: string
  placeholderSearch?: string
}

export const BaseSelectSearch = React.forwardRef<
  RefSelectProps,
  BaseSelectSearchProps
>(
  (
    {
      className,
      width,
      shadow,
      children,
      placeholder,
      placeholderSearch,
      options,
      ...props
    },
    ref,
  ) => {
    const [searchQuery, setSearchQuery] = useState('')
    const [filteredOptions, setFilteredOptions] = useState(options ?? null)

    useEffect(() => {
      setFilteredOptions(options ?? [])
    }, [options?.length])

    const handleSearch = value => {
      const valueText = value.target.value

      setSearchQuery(valueText)
      filterOption(valueText)
    }

    const filterOption = keyword => {
      const filtered =
        options?.filter(option =>
          convertVietnameseString(option.label).includes(
            convertVietnameseString(keyword),
          ),
        ) ?? []

      setFilteredOptions(filtered)
    }

    return (
      <S.Select
        options={filteredOptions ?? []}
        showSearch={false}
        getPopupContainer={triggerNode => triggerNode}
        ref={ref}
        className={className}
        $width={width}
        $shadow={shadow}
        placeholder={<S.Placeholder>{placeholder}</S.Placeholder>}
        dropdownRender={menu => (
          <S.DropdownRender>
            <S.SearchWrapper>
              <S.SearchOutlinedStyled
                className="search__icon"
                rev=""
                size={20}
              />
              <BaseInput
                className="search__input"
                placeholder={placeholderSearch ?? t(R.strings.search)}
                value={searchQuery}
                onChange={handleSearch}
                allowClear
              />
            </S.SearchWrapper>
            {menu}
          </S.DropdownRender>
        )}
        {...props}
      >
        {children}
      </S.Select>
    )
  },
)
