import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { FilterManagement } from 'app/components/tables/BaseTableManagement/FilterManagementTable'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { CreateAccount } from './components/CreateAccount'
import { useHookTable } from './hook'
import { useTranslation } from 'react-i18next'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { useEffect } from 'react'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
const AccountManagementContainer = () => {
  const {
    tableData,
    columns,
    handleTableChange,
    handleChangeFilter,
    fetchNewData,
    filter,
    profile,
  } = useHookTable()

  const { t } = useTranslation()

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.HR,
      FunctionPermissionEnum.ACCOUNT_READ,
      R.strings.account_management,
    )
  }, [t])

  return (
    <S.BaseManagementWrapper>
      <HeaderPage
        titleI18nKey={R.strings.account_management}
        isExport
        rightComponent={
          <CreateAccount fetchTable={fetchNewData} profile={profile} />
        }
      />
      <S.RootWrapper>
        <BaseRow>
          <FilterManagement
            handleChangeFilter={handleChangeFilter}
            filterData={filter}
          />
        </BaseRow>
        <BaseTableManagement
          columns={columns}
          dataSource={tableData.data}
          pagination={tableData.pagination}
          loading={tableData.loading}
          onChange={handleTableChange}
        />
      </S.RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default AccountManagementContainer
