import R from 'app/assets/R'
import { SvgClinicIcon } from 'app/assets/svg-assets'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import DividerLine from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/DividerLine'
import {
  IDentalHealthHistory,
  OralHygieneToolsYouUsuallyUseEnum,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/dental-health-history'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CheckBoxItem, ContentItem } from '../OtherComponents'
import { useHook } from './hook'
import { isEqual } from 'lodash'

const ListOralHygieneToolsYouUsuallyUseEnum = Object.keys(
  OralHygieneToolsYouUsuallyUseEnum,
)

export const DentalHealthHistoryItem = ({
  item,
}: {
  item?: IDentalHealthHistory
}) => {
  const { t } = useTranslation()
  const { isCollapse, listItem, onChangeCollapse } = useHook(item)

  return (
    <RootWrapper>
      <HeadItemWrapper onClick={onChangeCollapse}>
        <BaseCol>
          <BaseSpace>
            <BaseText
              children={t(R.strings.dental_health_survey)}
              fontSize="xs"
              fontWeight="semibold"
            />
            <BaseRow
              align={'middle'}
              gutter={[15, 10]}
              style={{ marginLeft: 0, marginRight: 0 }}
            >
              <SvgClinicIcon />
              <BaseCol>
                <BaseText children={item?.address ?? '-'} fontWeight="medium" />
              </BaseCol>
            </BaseRow>
          </BaseSpace>
        </BaseCol>
      </HeadItemWrapper>
      <DividerLine />
      {isCollapse ? (
        <ContentItemWrapper>
          {listItem?.map((itm, index) => {
            if (
              isEqual(itm?.key18n, R.strings.oral_hygiene_tools_you_usually_use)
            ) {
              return (
                <BaseSpace>
                  <BaseText
                    children={`${8}. ${t(
                      R.strings.oral_hygiene_tools_you_usually_use,
                    )}`}
                    opacity="0.7"
                    fontWeight="medium"
                  />
                  <BaseRow align={'middle'} gutter={[15, 10]}>
                    {ListOralHygieneToolsYouUsuallyUseEnum?.slice(0, 4)?.map(
                      (itmEnum, idxEnum) => {
                        return (
                          <CheckBoxItem
                            keyEnum={
                              OralHygieneToolsYouUsuallyUseEnum?.[itmEnum]
                            }
                            isDisable
                            listSelected={itm?.listEnumSelected ?? []}
                            key={idxEnum}
                          />
                        )
                      },
                    )}
                  </BaseRow>
                  <BaseRow align={'middle'} gutter={[15, 10]}>
                    {ListOralHygieneToolsYouUsuallyUseEnum?.slice(4, 8)?.map(
                      (itmEnum, idxEnum) => {
                        return (
                          <CheckBoxItem
                            keyEnum={
                              OralHygieneToolsYouUsuallyUseEnum?.[itmEnum]
                            }
                            isDisable
                            listSelected={itm?.listEnumSelected ?? []}
                            key={idxEnum}
                          />
                        )
                      },
                    )}
                  </BaseRow>
                </BaseSpace>
              )
            }
            return (
              <ContentItem
                key18n={itm.key18n}
                oriNumber={index + 1}
                isDisable
                isChecked={itm?.isChecked}
              />
            )
          })}
        </ContentItemWrapper>
      ) : null}

      <HeadItemWrapper
        align={'middle'}
        gutter={[15, 10]}
        justify={'space-between'}
      >
        <BaseCol>
          <BaseAvatarProfile
            jobTitle={item?.createBy?.jobTitle}
            name={item?.createBy?.name}
          />
        </BaseCol>
        <BaseCol>
          <BaseButton type="link" onClick={onChangeCollapse}>
            <BaseText
              children={
                isCollapse ? t(R.strings.collapse) : t(R.strings.see_detail)
              }
              colorText="collapseBackgroundColor"
              fontSize="xs"
              fontWeight="medium"
            />
          </BaseButton>
        </BaseCol>
      </HeadItemWrapper>
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};
`

const HeadItemWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
  cursor: pointer;
`

const ContentItemWrapper = styled(BaseSpace)`
  background-color: #fcfcfc;
  padding: ${PADDING.md};
`
