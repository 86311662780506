import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 0px ${PADDING.md};
`

export const TotalPage = styled.div`
  color: var(--main-color-text);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  opacity: 0.7;
`
