import { getArray } from 'app/components/tables/common-table/constant'
import _ from 'lodash'

export enum UnitOfMeasureSuppliesEnum {
  piece = 'piece',
  box = 'box',
  kilogram = 'kilogram',
  liter = 'liter',
  meter = 'meter',
  pair = 'pair',
  crate = 'crate',
  milliliter = 'milliliter',
  set = 'set',
}

export const DefineUnitOfMeasureSupplies = {
  [UnitOfMeasureSuppliesEnum.piece]: 'Cái',
  [UnitOfMeasureSuppliesEnum.box]: 'Hộp',
  [UnitOfMeasureSuppliesEnum.kilogram]: 'Kg',
  [UnitOfMeasureSuppliesEnum.liter]: 'Lít',
  [UnitOfMeasureSuppliesEnum.meter]: 'Mét',
  [UnitOfMeasureSuppliesEnum.pair]: 'Cặp',
  [UnitOfMeasureSuppliesEnum.crate]: 'Thùng',
  [UnitOfMeasureSuppliesEnum.milliliter]: 'ml',
  [UnitOfMeasureSuppliesEnum.set]: 'Bộ',
}

export const listUnitOfMeasure = Object.values(UnitOfMeasureSuppliesEnum)

export interface ISupplies {
  _id?: string
  itemId?: string
  name?: string
  quantity?: number
  usingMeasurementUnitId?: string
  mainImagesUrl?: string
}

export const dataSupplies: ISupplies[] = getArray(30).map(item => {
  return {
    key: item,
    _id: item.toString(),
    itemId: item.toString(),
    name: `Vật tư ${item}`,
    quantity: _.random(100),
    usingMeasurementUnitId:
      listUnitOfMeasure[_.random(listUnitOfMeasure.length - 1)],
  }
})
