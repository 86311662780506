import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormRule } from './type'
import { IPropsCreateSupplier, StatusSupplierType } from '.'
import { notificationController } from 'app/controllers/notification-controller'
import { ISupplier } from 'app/api/inventory-new/model/management'
import { requestCreateSupplierManagement } from 'app/api/inventory-new'
import { StatusEnum } from 'app/common/enum'
import { get } from 'lodash'

export const useModalHook = ({ fetchTable }: IPropsCreateSupplier) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState<StatusSupplierType | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: ISupplier) => {
    setIsLoading(true)

    try {
      await requestCreateSupplierManagement({
        ...values,
        status: StatusEnum.ACTIVE,
      })

      setIsLoading(false)
      handleCancel()
      fetchTable?.()

      notificationController.success({
        message: t(R.strings.provider_add),
        description: t(R.strings.success),
      })
    } catch (error) {
      notificationController.error({
        message: t(R.strings.provider_add),
        description: get(error, 'message', ''),
      })
      setIsLoading(false)
    }
  }

  const rules: IFormRule = useMemo(() => {
    return {
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.supplier_name),
          }),
        },
      ],
      code: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.supplier_code),
          }),
        },
      ],
      address: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.address),
          }),
        },
      ],
      bankAccount: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.bank_account),
          }),
        },
      ],
      bankName: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.bank_name),
          }),
        },
      ],
      taxIdNumber: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.TIN),
          }),
        },
      ],
      phone: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.phone),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    setStatus,
    status,
    isLoading,
  }
}
