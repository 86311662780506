import { useMemo } from 'react'
import { dataNotificationDashboard } from './data'
import * as S from './styles'
export const NotificationDoctorComponent = () => {
  const notificationList = useMemo(() => {
    return {
      tablet: dataNotificationDashboard.map((item, index) => (
        <S.ItemWrapper key={index}>
          <S.TitleItemText>{item?.title}</S.TitleItemText>
          <S.DescriptionItemText>{item?.description}</S.DescriptionItemText>
        </S.ItemWrapper>
      )),
    }
  }, [dataNotificationDashboard])


  return (
    <S.RootWrapper>
      <S.CarouselWrapper
        dotPosition={'bottom'}
        draggable
        autoplay
      >
        {notificationList.tablet}
      </S.CarouselWrapper>
    </S.RootWrapper>
  )
}
