import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { SvgCopyIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const HeaderWrapperLayout = () => {
  const { t } = useTranslation()
  return (
    <BaseRow align={'top'} justify={'space-between'} gutter={[15, 10]}>
      <BaseCol>
        <BaseSpace size={8}>
          <BaseText
            children={t(R.strings.patient_being_examined)}
            fontWeight="semibold"
            fontSize="md"
          />
          <BaseRow gutter={[15, 10]} align={'middle'}>
            <BaseCol>
              <BaseText children={'ID: 8898988'} fontSize="md" />
            </BaseCol>
            <SvgCopyIcon />
          </BaseRow>
        </BaseSpace>
      </BaseCol>
      <BaseCol>
        <BaseRow gutter={[10, 10]} align={'middle'}>
          <BaseCol>
            <BaseButton
              children={
                <BaseText
                  children={t(R.strings.view_full_profile)}
                  fontWeight="medium"
                  fontSize="xs"
                />
              }
            />
          </BaseCol>
          <BaseCol>
            <Line />
          </BaseCol>
          <BaseCol>
            <BaseButton icon={<LeftOutlined rev={undefined} />} />
          </BaseCol>
          <BaseCol>
            <BaseButton icon={<RightOutlined rev={undefined} />} />
          </BaseCol>
        </BaseRow>
      </BaseCol>
    </BaseRow>
  )
}

const Line = styled.div`
  width: 1.5px;
  height: 25px;
  background-color: #f0f1f3;
`
