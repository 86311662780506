import { Col } from 'antd'
import './index.css'
import { ScheduleLabel } from './styles'
export function ScheduleTotal(props) {
  return (
    <Col span={4}>
      <div
        style={{
          background: props?.background, //'#ECFDF2',
        }}
        className="schedule-box"
      >
        <div className="schedule-box-list">
          <ScheduleLabel border={props?.border} background={props?.background}>
            {props?.label}
          </ScheduleLabel>
          <span>{props?.total}</span>
        </div>
      </div>
    </Col>
  )
}
