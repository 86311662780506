import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import { useModalHook } from './hook'
import * as S from './styles'
import { IPropsUpdateAccount, StatusEnum, initValues } from './type'

export const UpdateAccount = (props: IPropsUpdateAccount) => {
  const {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
  } = useModalHook(props)

  return (
    <BaseModalManagement
      isLoading={isLoading}
      typeButtonOpen="update"
      titleKeyI18n={R.strings.update_account}
      content={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <BaseRow gutter={20} align={'top'} justify={'space-between'}>
            <BaseCol xl={12}>
              <S.FormItem
                name="firstName"
                label={t(R.strings.first_name)}
                rules={rules.firstName}
                required
              >
                <S.FormInput
                  placeholder={t(R.strings.enter_field, {
                    field: t(R.strings.first_name),
                  })}
                />
              </S.FormItem>
            </BaseCol>
            <BaseCol xl={12}>
              <S.FormItem
                name="lastName"
                required
                label={t(R.strings.last_name)}
                rules={rules.lastName}
              >
                <S.FormInput
                  placeholder={t(R.strings.enter_field, {
                    field: t(R.strings.last_name),
                  })}
                />
              </S.FormItem>
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={20} align={'top'} justify={'space-between'}>
            <BaseCol xl={12}>
              <S.FormItem
                name="code"
                required
                label={t(R.strings.employee_id)}
                rules={rules.code}
              >
                <S.FormInput
                  placeholder={t(R.strings.enter_field, {
                    field: t(R.strings.employee_id),
                  })}
                />
              </S.FormItem>
            </BaseCol>
            <BaseCol xl={12}>
              <S.FormItem
                name="phone"
                label={t(R.strings.phone)}
                rules={rules.phone}
                required
              >
                <S.FormInput
                  placeholder={t(R.strings.enter_field, {
                    field: t(R.strings.phone),
                  })}
                />
              </S.FormItem>
            </BaseCol>
          </BaseRow>
          <S.FormItem
            name="email"
            label={t(R.strings.email)}
            rules={rules.email}
            required
          >
            <S.FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.email),
              })}
            />
          </S.FormItem>
          <S.FormItem
            label={t(R.strings.status)}
            name="status"
            rules={rules.status}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.please_select_status)}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {Object.values(StatusEnum)?.map((item, index) => {
                return (
                  <Option value={item} key={index}>
                    {t(item)}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.update_account}
      confirmKeyI18n={R.strings.update_account}
      handleSubmit={form.submit}
      visible={visible}
    />
  )
}
