import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { Report021Container } from 'app/containers/Report/Report021'

const Report021PageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.report_21)}</PageTitle>
      <Report021Container />
    </>
  )
}
const Report021Page = memo(Report021PageCpn, isEqual)

export default Report021Page
