import { IBaseTableReport } from 'app/components/tables/BaseTableReport/type'
import { Pagination } from 'parkway-web-common'

export const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
}

export interface IGeneralMarketingPlanTable<T> extends IBaseTableReport<T> {
  service?: string
  month?: string
  week?: string
  checkInSchedule?: number
  kpiPlan?: number
  kpiActual?: number

  checkInScheduleTotal?: number
  kpiPlanTotal?: number
  kpiActualTotal?: number
}
