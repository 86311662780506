import { requestGetMaterialCategoryManagement } from 'app/api/inventory'
import { IMaterialCategory } from 'app/api/inventory/model/management'
import { notificationController } from 'app/controllers/notification-controller'
import { get } from 'lodash'
import { createContext, useState } from 'react'

interface IMaterialCategoryContext {
  loading: boolean,
  data: IMaterialCategory[],
  setLoading?: (loading) => void,
  setData?: (data) => void,
  fetchMaterialCategory?: (params) => void,
}
export const MaterialCategoryContext = createContext<IMaterialCategoryContext>({
  loading: false,
  data: [],
})

export const MaterialCategoryProvider = ({ children }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchMaterialCategory = async params => {
    setLoading(true)
    try {
      const response = await requestGetMaterialCategoryManagement(params)
      setData(response?.data)
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }
    setLoading(false)
  }

  return (
    <MaterialCategoryContext.Provider
      value={{ data, loading, setLoading, fetchMaterialCategory, setData }}
    >
      {children}
    </MaterialCategoryContext.Provider>
  )
}
