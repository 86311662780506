import { Rule } from 'antd/es/form'

export interface IFormData {
  code?: string
  name?: string
  description?: string
  _id?: string
  status?: string
  level?: number 
}

export interface IFormRule {
  name?: Rule[]
  code?: Rule[]
  description?: Rule[]
  status?: Rule[]
  level?: Rule[]
}

export const initValues: IFormData = {
  code: '',
  name: '',
  _id: '',
  description: '',
  status: '',
}

export enum StatusJobTitleLevelEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
  deleted = 'deleted',
}

export type StatusJobTitleLevelType = keyof typeof StatusJobTitleLevelEnum

export interface IPropsUpdateJobTitleLevel {
  fetchTable: () => void
  id?: string | number
}
