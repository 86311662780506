import { Rule } from 'antd/es/form'
import { BookingStatusEnum } from 'app/api/booking/model/management'
import { IProfile } from 'app/api/profile/model/management'
import { ITreatmentGroup } from 'app/api/treatment-group/model/management'
import { IUnit } from 'app/api/unit/model/unit-management'
import dayjs from 'dayjs';

export interface IFormData {
  _id?: string
  fullName?: string
  status?: BookingStatusEnum
  phone?: string
  treatments?: string
  areaId?: string
  clinicId?: string
  doctorId?: string
  callCenterId?: string
  date?: dayjs.Dayjs
  time?: dayjs.Dayjs
  note?: string
  recallAt?: dayjs.Dayjs
  createdBy?: string
  updatedBy?: string
  createdAt?: Date
  updatedAt?: Date
}

export interface IFormRule {
  fullName?: Rule[]
  phone?: Rule[]
  treatments?: Rule[]
  clinicId?: Rule[]
  doctorId?: Rule[]
  callCenterId?: Rule[]
  status?: Rule[]
  areaId?: Rule[]
  date?: Rule[]
  time?: Rule[]
  note?: Rule[]
}

export const initValues: IFormData = {
  _id: '',
  fullName: '',
}

export enum StatusBookingEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
  deleted = 'deleted',
}

export type StatusBookingType = keyof typeof StatusBookingEnum

export interface IPropsRecallBooking {
  id: string | number | undefined
  titleButton?: string
  nameCalling?: string
  profile?: IProfile[]
  unit?: IUnit[]
  treatmentGroup?: ITreatmentGroup[]
  date?: string
  time?: string
  fetchTable?
  handleSubmit?
  loading?: boolean
}
