import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TagStatusComponent } from '../../../../components'
import { BaseButton } from 'app/components/common/BaseButton'
import BaseText from 'app/components/common/BaseText'
import { useNavigate } from 'react-router'
import { DENTAL_TREATMENT_PATIENT_PROFILE_TREATMENT_PLAN } from 'app/components/router/route-path'

export const TreatmentPlanAction: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <div onClick={event => event?.stopPropagation()}>
      <BasePopover
        content={
          <BaseSpace>
            <BaseButton
              onClick={() => {
                navigate(DENTAL_TREATMENT_PATIENT_PROFILE_TREATMENT_PLAN)
              }}
              type="text"
              icon={<PlusOutlined rev={undefined} />}
            >
              <BaseText
                children={t(R.strings.create_treatment_plan)}
                fontSize="xs"
              />
            </BaseButton>
          </BaseSpace>
        }
        trigger="click"
      >
        <ButtonWrapper type="text">
          <TagStatusComponent
            backgroundColor={convertedVariables.backgroundColor}
            color={convertedVariables?.primaryColor}
            textKeyI18n={t(R.strings.not_have)}
            border={`1px solid ${convertedVariables.neutralBlack2Color}`}
            expandIcon={
              <CaretDownIcon
                rev={undefined}
                $fill={convertedVariables.primaryColor}
                $opacity={'0.7'}
              />
            }
          />
        </ButtonWrapper>
      </BasePopover>
    </div>
  )
}

const CaretDownIcon = styled(CaretDownOutlined)<{
  $fill?: string
  $opacity?: string
}>`
  svg {
    width: 16px !important;
    height: 16px !important;
    fill: ${props => props?.$fill};
    opacity: ${props => props?.$opacity ?? '1'};
  }
`
const ButtonWrapper = styled(BaseButton)`
  width: fit-content;
  padding: 0px;
  &:hover {
    background-color: unset !important;
  }
`
