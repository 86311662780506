import { ApiClientTreatment } from 'app/service/api-service'
import { ItemCategoryEndPoint } from './constant'
import {
  IParamsGetItemCategoryManagement,
  IPayloadCreateItemCategoryManagement,
  IPayloadUpdateItemCategoryManagement,
} from './model/management'

export const requestGetItemCategoryManagement = (params: IParamsGetItemCategoryManagement) =>
  ApiClientTreatment.Get({
    url: ItemCategoryEndPoint.ItemCategoryManagement.request,
    params: { params },
  })

export const requestCreateItemCategoryManagement = (
  body: IPayloadCreateItemCategoryManagement,
) =>
  ApiClientTreatment.Post({
    url: ItemCategoryEndPoint.ItemCategoryManagement.request,
    body,
  })

export const requestUpdateItemCategoryManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateItemCategoryManagement
}) =>
  ApiClientTreatment.Put({
    url: `${ItemCategoryEndPoint.ItemCategoryManagement.request}/${id}`,
    body,
  })

export const requestDetailItemCategoryManagement = (id?: string | number) =>
  ApiClientTreatment.Get({
    url: `${ItemCategoryEndPoint.ItemCategoryManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteItemCategoryManagement = (id?: string | number) =>
  ApiClientTreatment.Delete({
    url: `${ItemCategoryEndPoint.ItemCategoryManagement.request}/${id}`,
    body: {},
  })

export const requestGetAllItemCategoryManagement = () =>
  ApiClientTreatment.Get({
    url: `${ItemCategoryEndPoint.ItemCategoryManagement.request}/get-all`,
    params: {},
  })
