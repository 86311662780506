import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseSelectSearch } from 'app/components/common/BaseSelectSearch'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import styled from 'styled-components'

export const BuyBox = styled.div`
  padding: 20px;
  gap: 20px;
  border: 0px 1px 1px 1px;
  opacity: 0px;
  border-width: 0px 1px 1px 1px;

  border-style: solid;

  border-color: var(--neutral-black-2-color);
  background: var(--inventory-bg);

  border-radius: 0px 0px 8px 8px;
`
export const BuyTitle = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  padding: 0px 0px 10px 0px;
`
export const CustomSelectSearch = styled(BaseSelectSearch)`
  .ant-select-selector {
    height: 50px !important;
    border: 1px solid var(--inventory-border-color) !important;
  }
`
export const RowLine = styled.div`
  margin-top: 20px;
`
export const CustomDatePicker = styled(BaseDatePicker)`
  &.ant-picker {
    border: 1px solid var(--inventory-border-color) !important;
    width: 100% !important;
  }
`

export const CustomCheckbox = styled(BaseCheckbox)`
  span.ant-checkbox-inner {
    width: 16px;
    height: 16px;
    padding: 1px;
    gap: 0px;
    border-radius: 4px;
    opacity: 0px;
    border: 1px solid var(--inventory-border-color);
  }
  &span:last-child {
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    text-align: left !important;
    color: var(--main-color-text) !important;
  }
`

export const FormItem = styled(BaseForm.Item)`
  .ant-input-disabled {
    border: 1px solid var(--inventory-border-color);
    background: var(--neutral-black-16-color);
  }
`

export const FormItemCheckbox = styled(BaseForm.Item)`
  margin: 0px;
  .ant-form-item-control-input {
    min-height: initial;
  }
  .ant-form-item-label {
    height: 0;
  }
`

export const FormItemUser = styled.div`
  .ant-form-item-explain-error {
    margin-top: 20px;
  }
`

export const InputBox = styled.div`
  .ant-input-group-wrapper {
    border: initial;
  }
`

export const ButtonExcel = styled(BaseButton)`
  background: initial;
  border: initial;
  justify-content: start;
  gap: initial;
  span:last-child {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--inventory-text-color3);
    margin-left: 5px;
  }
`
