import { convertedVariables } from 'app/styles/themes/themeVariables'
import { PADDING } from 'parkway-web-common'
import { ReactNode } from 'react'
import styled from 'styled-components'

const BasePageWrapper: React.FC<{ children: ReactNode }> = ({
  children,
  ...props
}) => {
  return <BaseManagement {...props}>{children}</BaseManagement>
}

export default BasePageWrapper

const BaseManagement = styled.div`
  padding: ${PADDING.md};
  background-color: ${convertedVariables.neutralBlack9Color};
`
