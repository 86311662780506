/* eslint-disable @typescript-eslint/no-explicit-any */
import type { UploadFile, UploadProps } from 'antd'
import {
  SvgDefaultPrefixIcon,
  SvgDocPrefixIcon,
  SvgExcelPrefixIcon,
  SvgPdfPrefixIcon,
} from 'app/assets/svg-assets'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useState } from 'react'

export const useHook = () => {
  const [form] = BaseForm.useForm()

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const onRemove = (file: UploadFile) => {
    const index = fileList.indexOf(file)
    const newFileList = fileList.slice()
    newFileList.splice(index, 1)
    setFileList(newFileList)
  }

  const props: UploadProps = {
    beforeUpload: (_, files) => {
      setFileList([...fileList, ...files])
      return false
    },
  }

  return {
    form,
    props,
    fileList,
    onRemove,
  }
}

export const getDataByFileType = (file: UploadFile): { icon: JSX.Element } => {
  const fileType = file?.name?.split('.')?.pop()?.toLowerCase()

  switch (fileType) {
    case 'doc':
    case 'docx':
      return {
        icon: <SvgDocPrefixIcon />,
      }

    case 'xls':
    case 'xlsx':
      return {
        icon: <SvgExcelPrefixIcon />,
      }

    case 'pdf':
      return {
        icon: <SvgPdfPrefixIcon />,
      }
    default:
      return {
        icon: <SvgDefaultPrefixIcon />,
      }
  }
}

export const fileToDataUri = (file: any) =>
  new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = event => {
      resolve(event?.target?.result)
    }
    reader.readAsDataURL(file)
  })
