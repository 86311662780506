import { getRandomUuid, moment } from 'parkway-web-common'
import { IPatientJourneyItem } from './components'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'

export const PatientJourneyData: IPatientJourneyItem[] = [
  {
    id: getRandomUuid(),
    name: 'Đặt lịch tư vấn: Gói Niềng răng invisalign, Từ Website',
    note: 'Bệnh nhân mua dịch vụ',
    treatment: 'Niềng răng Invisalign',
    location: 'Parkway Trần Hưng Đạo',
    startDate: moment().format(FORMAT_DD_MM_YYYY_HH_MM),
    endTime: moment().add(3, 'hours').format(FORMAT_DD_MM_YYYY_HH_MM),
    patientCounseledByACounselor: {
      counselor: {
        name: 'Lê Thị Ngọc Thảo',
        jobTitle: 'Tư vấn viên',
      },
      lastedUpdate: moment().add(0.6, 'hours').format(FORMAT_DD_MM_YYYY_HH_MM),
      time: moment().add(0.5, 'hours').format(FORMAT_DD_MM_YYYY_HH_MM),
    },
    patientAction: [
      {
        name: 'Kiểm tra hiện trạng răng miệng',
        time: moment().add(1, 'hours').format(FORMAT_DD_MM_YYYY_HH_MM),
        listTreatment: [
          {
            materials: [],
            name: 'Chụp X-Quang',
            paymentInfo: {
              discountAmount: 0,
              paid: 0,
              totalAmount: 0,
              unitPrice: 0,
            },
            doctor: {
              name: 'Phạm Văn Khánh',
              jobTitle: 'Bác Sĩ',
              levelName: '',
            },
            nurses: [
              {
                name: 'Lê Văn Lương',
                jobTitle: 'Y Tá',
                levelName: '',
              },
              {
                name: 'Trần Hồng hạnh',
                jobTitle: 'Y Tá',
                levelName: '',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: getRandomUuid(),
    name: 'Đặt lịch tư vấn: Gói Niềng răng invisalign, Từ Website',
    note: 'Bệnh nhân mua dịch vụ',
    treatment: 'Niềng răng Invisalign',
    location: 'Parkway Trần Hưng Đạo',
    startDate: moment()
      .add(1, 'days')
      .add(2, 'hours')
      .format(FORMAT_DD_MM_YYYY_HH_MM),
    endTime: moment()
      .add(1, 'days')
      .add(5, 'hours')
      .format(FORMAT_DD_MM_YYYY_HH_MM),
    patientCounseledByACounselor: {
      counselor: {
        name: 'Lê Thị Ngọc Thảo',
        jobTitle: 'Tư vấn viên',
      },
      lastedUpdate: moment()
        .add(1, 'days')
        .add(0.6, 'hours')
        .format(FORMAT_DD_MM_YYYY_HH_MM),
      time: moment()
        .add(1, 'days')
        .add(0.5, 'hours')
        .format(FORMAT_DD_MM_YYYY_HH_MM),
    },
    patientAction: [
      {
        name: 'Bệnh nhân thực hiện điều trị',
        time: moment()
          .add(1, 'days')
          .add(3, 'hours')
          .format(FORMAT_DD_MM_YYYY_HH_MM),
        listTreatment: [
          {
            materials: [],
            name: 'Lấy cao răng cơ bản',
            paymentInfo: {
              discountAmount: 0,
              paid: 0,
              totalAmount: 0,
              unitPrice: 0,
            },
            doctor: {
              name: 'Lê Thị Mỹ Linh',
              jobTitle: 'Bác Sĩ',
              levelName: '',
            },
            nurses: [
              {
                name: 'Trần Hồng hạnh',
                jobTitle: 'Y Tá',
                levelName: '',
              },
            ],
          },
          {
            materials: [],
            name: 'Nhổ răng',
            paymentInfo: {
              discountAmount: 0,
              paid: 0,
              totalAmount: 0,
              unitPrice: 0,
            },
            doctor: {
              name: 'Lê Thị Mỹ Linh',
              jobTitle: 'Bác Sĩ',
              levelName: '',
            },
            nurses: [
              {
                name: 'Trần Hồng hạnh',
                jobTitle: 'Y Tá',
                levelName: '',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: getRandomUuid(),
    name: 'Đặt lịch tư vấn: Gói Niềng răng invisalign, Từ Website',
    note: 'Bệnh nhân mua dịch vụ',
    treatment: 'Niềng răng Invisalign',
    location: 'Parkway Trần Hưng Đạo',
    startDate: moment()
      .add(3, 'days')
      .add(3, 'hours')
      .format(FORMAT_DD_MM_YYYY_HH_MM),
    endTime: moment()
      .add(3, 'days')
      .add(6, 'hours')
      .format(FORMAT_DD_MM_YYYY_HH_MM),
    patientCounseledByACounselor: {
      counselor: {
        name: 'Lê Thị Minh',
        jobTitle: 'Tư vấn viên & Call Center',
      },
      lastedUpdate: moment()
        .add(3, 'days')
        .add(1.6, 'hours')
        .format(FORMAT_DD_MM_YYYY_HH_MM),
      time: moment()
        .add(3, 'days')
        .add(1.2, 'hours')
        .format(FORMAT_DD_MM_YYYY_HH_MM),
    },
    patientAction: [
      {
        name: 'Bệnh nhân thực hiện điều trị',
        time: moment()
          .add(3, 'days')
          .add(4, 'hours')
          .format(FORMAT_DD_MM_YYYY_HH_MM),
        listTreatment: [
          {
            materials: [],
            name: 'Kiểm tra răng sau cao răng',
            paymentInfo: {
              discountAmount: 0,
              paid: 0,
              totalAmount: 0,
              unitPrice: 0,
            },
            doctor: {
              name: 'Lê Thị Mỹ Linh',
              jobTitle: 'Bác Sĩ',
              levelName: '',
            },
            nurses: [
              {
                name: 'Trần Hồng hạnh',
                jobTitle: 'Y Tá',
                levelName: '',
              },
            ],
          },
          {
            materials: [],
            name: 'Gắn hàm giả tiền invisalign',
            paymentInfo: {
              discountAmount: 0,
              paid: 0,
              totalAmount: 0,
              unitPrice: 0,
            },
            doctor: {
              name: 'Phạm Văn Khánh',
              jobTitle: 'Bác Sĩ',
              levelName: '',
            },
            nurses: [
              {
                name: 'Nguyễn Hoa Mai',
                jobTitle: 'Y Tá',
                levelName: '',
              },
              {
                name: 'Chu Đào Giáng My',
                jobTitle: 'Y Tá',
                levelName: '',
              },
            ],
          },
        ],
      },
      
    ],
  },
]
