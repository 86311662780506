import { requestGetAppointmentManagement } from 'app/api/appointment'
import { requestUpdateCallCenterBookingManagement } from 'app/api/booking'
import { BookingMessageCode } from 'app/api/booking/constant'
import { ProfileMessageCode } from 'app/api/profile/constant'
import { IAllProfile, IProfile } from 'app/api/profile/model/management'
import { ResponseTypeV2 } from 'app/api/report/model/common'
import R from 'app/assets/R'
import { DATE } from 'app/common/constants'
import { JobTitleGroup } from 'app/common/enum'
import { MinuteToMillisecond } from 'app/common/helpers'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { Option } from 'app/components/common/selects/BaseSelect'
import {
  DropdownRender,
  SearchOutlinedStyled,
  SearchWrapper,
} from 'app/components/common/selects/SelectSearch/styles'
import { CUSTOMER_BOOKING_FOR_EMPLOYEE_PATH } from 'app/components/router/route-path'
import {
  RenderValueTable,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { APPOINTMENT, initPagination } from 'app/constant'
import { ProfileContext } from 'app/context/ProfileContext'
import { IUser } from 'app/model/user.model'
import { useAppSelector } from 'app/redux/hooks'
import { get, isEmpty, isEqual, map } from 'lodash'
import { Pagination, moment } from 'parkway-web-common'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import {
  BookingStatusEnum,
  IParamsGetBookingManagement,
  IResponseGetBookingManagement,
} from '../../../api/booking/model/management'
import { StatusTableEnum } from '../SurveyReport/hook'
import { AssignItemSelect } from './components/Assign/styles'
import { BookingHistory } from './components/BookingHistory'
import { Delete } from './components/Delete'
import { Update } from './components/Update'
import { FlexRow } from './styles'
import { IGeneralBookingManagementTable } from './type'

export interface IResponseFilter {
  status?: BookingStatusEnum
  search?: string
  isAssigned?: boolean
}

export interface ISelectedRows {
  [key: string]: {
    selectedRowKeys?: number[]
    selectedRows?: object[]
  }
}

export const useHookTable = () => {
  const [selectedRows, setSelectedRows] = useState<ISelectedRows>({})
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isEmployee = pathname.includes(CUSTOMER_BOOKING_FOR_EMPLOYEE_PATH)
  const showByManager = !isEmployee

  const { profiles: callCenters, fetchData: fetchCallCenters } =
    useContext(ProfileContext)
  const [searchCallCenter, setSearchCallCenter] = useState('')
  const [callCenterIds, setCallCenterIds] = useState({})
  const [filter, setFilter] = useState<IResponseFilter>()
  const user: IUser | null = useAppSelector(state => state.user.user)
  const profile = useAppSelector(state => state.profileSlice)

  useEffect(() => {
    const timer = setInterval(() => {
      fetch(initPagination, filter)
    }, MinuteToMillisecond(5))

    return () => clearTimeout(timer)
  }, [filter])

  useEffect(() => {
    if (profile?.message?.type === APPOINTMENT) {
      fetchNewData()
    }
  }, [profile?.message?.currentTime, profile?.message?.type])

  const userId = useMemo(() => {
    return user?._id || user?.id
  }, [user])

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchCallCenters?.({
        page: 0,
        pagesize: 50,
        sort: '-1',
        group: JobTitleGroup.callCenter,
        keyword: searchCallCenter,
      })
    }, 500)
    return () => clearTimeout(timer)
  }, [searchCallCenter])

  const updateCallCenter = async (
    id,
    callCenterId,
  ): Promise<IProfile[] | undefined | null> => {
    const body = { callCenterId, isAssigned: !isEmpty(callCenterId) }

    try {
      const res: ResponseTypeV2<IAllProfile> =
        await requestUpdateCallCenterBookingManagement({ id: id, body })

      if (
        isEqual(
          res?.msgcode,
          ProfileMessageCode.ProfileManagement.successGetData,
        )
      ) {
        const data = res?.data.rows || []
        return data
      }
    } catch (error) {
      // console.log({ error })
      return null
    }
  }

  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IResponseFilter,
  ) => {
    const initData = {
      pagination: pagination ?? initPagination,
      data: [],
    } as IDataBaseTable<IGeneralBookingManagementTable>
    try {
      let params: IParamsGetBookingManagement = {
        page: Number(pagination?.current),
        pageSize: pagination?.pageSize,
      }

      const paramEmployee = isEmployee ? { callCenterId: String(userId) } : {}
      const status =
        newFilter?.status ?? filter?.status ?? BookingStatusEnum.REQUEST

      const assigned =
        showByManager && Number(status) === BookingStatusEnum.REQUEST
          ? { isAssigned: false }
          : showByManager && Number(status) === BookingStatusEnum.ASSIGNED
          ? {
              status: BookingStatusEnum.REQUEST,
            }
          : {}
      params = {
        ...params,
        ...newFilter,
        status,
        ...paramEmployee,
        ...assigned,
      }

      const response: IResponseGetBookingManagement =
        await requestGetAppointmentManagement(params)

      if (
        isEqual(
          response?.msgcode,
          BookingMessageCode.BookingManagement.successGetData,
        )
      ) {
        const data = response?.data
        const metadata = response?.metadata
        const dataTables = data?.map((item, index) => {
          const serviceName: string = map(item?.services, 'name').join(' & ')

          const PageIndex =
            Number(pagination?.pageSize) * (Number(pagination?.current) - 1)
          return {
            key: index + PageIndex + 1,
            _id: item?._id ?? '-',
            ordinalNumber: index + PageIndex + 1,
            name: item?.fullName ?? '',
            fullName: item.fullName ?? '-',
            status: item.status,
            phone: item.phone ?? '-',
            serviceName: serviceName ?? '-',
            clinicName: item?.clinic?.name ?? '-',
            doctorName: item?.doctor?.name ?? '-',
            callCenterId: !isEmpty(item.callCenterId)
              ? item.callCenterId
              : undefined,
            note: item.note ?? '',
            source: item,
            recallAt: item?.recallAt,
            createdAt: item?.createdAt,
            histories: item?.histories,
            utmSource: item?.utm_source
          }
        })

        return {
          pagination: {
            ...pagination,
            total: metadata?.total ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IGeneralBookingManagementTable>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, selectRowsTable, handleTableChange, fetch, rowSelection } =
    useHookTableManagement({ getDataTable })

  useEffect(() => {
    setSelectedRows(prev => ({
      ...prev,
      [Number(tableData.pagination.current)]: selectRowsTable,
    }))
  }, [selectRowsTable])

  const handleTableChangeTable = (pagination?: Pagination) => {
    handleTableChange(pagination ? pagination : {}, filter)
  }

  const handleChangeFilter = (newFilter: IResponseFilter) => {
    if (!isEqual(newFilter, filter)) {
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initPagination.current,
        },
        newFilter,
      )
    }
  }

  const fetchNewData = () => {
    fetch(initPagination, filter)
    setFilter(filter)
  }

  const selectedRow = get(
    selectedRows,
    `[${Number(tableData.pagination.current)}]`,
    { selectedRowKeys: [] },
  )

  const columns = useMemo(() => {
    const optionOfManagement = showByManager
      ? [
          convertColumnTable<IGeneralBookingManagementTable>({
            title: t(R.strings.call_center),
            key: 'callCenterId',
            classNameWidthColumnOverwrite: 'big-column',
            render: (_, record) => {
              return (
                <>
                  <AssignItemSelect
                    placeholder={t(
                      R.strings.booking_content_select_profile_call_center,
                    )}
                    onChange={value => {
                      updateCallCenter(record._id, value)
                      setCallCenterIds(prev => ({
                        ...prev,
                        [String(record._id)]: value,
                      }))
                    }}
                    filterOption={false}
                    dropdownRender={menu => (
                      <DropdownRender>
                        <SearchWrapper>
                          <SearchOutlinedStyled
                            className="search__icon"
                            rev=""
                            size={20}
                          />
                          <BaseInput
                            className="search__input"
                            placeholder={t(R.strings.search)}
                            onChange={value =>
                              setSearchCallCenter(value?.target?.value)
                            }
                            allowClear
                          />
                        </SearchWrapper>
                        {menu}
                      </DropdownRender>
                    )}
                    value={
                      callCenterIds[String(record._id)] ??
                      record.callCenterId ??
                      undefined
                    }
                  >
                    {(callCenters || [])?.map(
                      (callCenter: { _id: string; name: string }, index) => {
                        return (
                          <Option value={callCenter?._id} key={index}>
                            {callCenter?.name}
                          </Option>
                        )
                      },
                    )}
                  </AssignItemSelect>
                </>
              )
            },
          }),
        ]
      : []

    const recall =
      Number(filter?.status) === BookingStatusEnum.RECALL
        ? [
            convertColumnTable<IGeneralBookingManagementTable>({
              title: t(R.strings.note),
              key: 'note',
              classNameWidthColumnOverwrite: 'big-column',
              render: (_, record) => {
                return <RenderValueTable value={record?.note} type="Base" />
              },
            }),
            convertColumnTable<IGeneralBookingManagementTable>({
              title: t(R.strings.date_recall),
              key: 'recallAt',
              classNameWidthColumnOverwrite: 'big-column',
              render: (_, record) => {
                return (
                  <RenderValueTable
                    value={
                      record?.recallAt
                        ? moment(record?.recallAt).format(DATE.DD_MM_YYYY_HH_MM)
                        : ''
                    }
                    type="Base"
                  />
                )
              },
            }),
          ]
        : []

    const option = [
      convertColumnTable<IGeneralBookingManagementTable>({
        title: t(R.strings.ordinal_number),
        fixed: 'left',
        key: 'ordinalNumber',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => (
          <RenderValueTable value={record.ordinalNumber} type="Number" />
        ),
      }),
      convertColumnTable<IGeneralBookingManagementTable>({
        title: t(R.strings.booking_content_name),
        key: 'fullName',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return <RenderValueTable value={record.fullName} type="Main" />
        },
      }),
      convertColumnTable<IGeneralBookingManagementTable>({
        title: t(R.strings.phone),
        key: 'phone',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <Update
            showIcon
            fetchTable={fetchNewData}
            titleButton={record.phone}
            nameCalling={record.fullName}
            id={record?._id}
            appointment={record?.source}
          />
        ),
      }),
      convertColumnTable<IGeneralBookingManagementTable>({
        title: t(R.strings.treatments),
        key: 'serviceName',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable value={record.serviceName} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralBookingManagementTable>({
        title: t(R.strings.clinic),
        key: 'clinicName',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable value={record.clinicName} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralBookingManagementTable>({
        title: t(R.strings.doctor),
        key: 'doctorName',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return <RenderValueTable value={record.doctorName} type="Base" />
        },
      }),
      ...recall,
      convertColumnTable<IGeneralBookingManagementTable>({
        title: t(R.strings.created_at),
        key: 'createdAt',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return (
            <RenderValueTable
              value={moment(record.createdAt).format(DATE.DD_MM_YYYY_HH_MM)}
              type="Base"
            />
          )
        },
      }),
      ...optionOfManagement,
      convertColumnTable<IGeneralBookingManagementTable>({
        title: t(R.strings.customer_source),
        key: 'utmSource',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          return (
            <RenderValueTable
              value={record?.utmSource}
              type="Base"
            />
          )
        },
      }),
      convertColumnTable<IGeneralBookingManagementTable>({
        title: '',
        key: 'status',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => {
          return (
            <div>
              {record.status !== BookingStatusEnum.CANCEL ? (
                <FlexRow>
                  <BookingHistory data={record}/>
                  <Delete
                    id={record?._id}
                    fetchTable={fetchNewData}
                    name={record?.fullName}
                  />
                </FlexRow>
              ) : (
                <RenderValueTable
                  type="Status"
                  showStatusWrapper={false}
                  actionComponent={
                    <>
                      <Update
                        fetchTable={fetchNewData}
                        titleButton={t(R.strings.update)}
                        nameCalling={record.fullName}
                        id={record?._id}
                        isCalling={false}
                        isUpdate={true}
                        appointment={record?.source}
                      />
                      {!isEqual(record?.status, StatusTableEnum.deleted) && (
                        <Delete
                          id={record?._id}
                          fetchTable={fetchNewData}
                          name={record?.fullName}
                          textOpenModalI18n={R.strings.delete}
                        />
                      )}
                    </>
                  }
                />
              )}
            </div>
          )
        },
      }),
    ]
    return option
  }, [t, fetchNewData, showByManager, tableData.data, filter?.status])

  return {
    tableData,
    selectRowsTable: selectedRow,
    handleTableChangeTable,
    fetchNewData,
    rowSelection,
    columns,
    handleChangeFilter,
    filter,
    showByManager,
    selectedRowKeys: selectedRow?.selectedRowKeys,
    selectedRows,
    setSelectedRows,
    updateCallCenter,
  }
}
