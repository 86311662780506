import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import CollapseComponent from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/CollapseComponent'
import { VerticalLine } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/VerticalLine'
import { StatusServiceTreatmentScheduleEnumDefine } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/constant/treatment-phase'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { StatusServiceTreatmentScheduleEnum } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/enum'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import { TagStatusComponent } from '../../components'
import { TreatmentScheduleAction } from './components/TreatmentScheduleAction'
import {
  HeaderLayout,
  PaymentLayout,
  TreatmentInformationLayout,
  TreatmentPhaseLayout,
} from './layouts'

export const TreatmentScheduleLayout = () => {
  const { treatmentSchedules } = useProfilePatientContext()

  return (
    <RootWrapper>
      {treatmentSchedules?.map((item, index) => {
        const statusData =
          StatusServiceTreatmentScheduleEnumDefine?.[item?.status]
        const keyActive = isEqual(
          item?.status,
          StatusServiceTreatmentScheduleEnum.InTreatment,
        )
          ? index + 1
          : undefined

        return (
          <CollapseWrapper
            key={index + 1}
            titleKeyI18n={`${index + 1}`}
            extra={
              <BaseRow gutter={[15, 10]} align={'middle'}>
                <BaseCol>
                  <TagStatusComponent
                    color={statusData?.color}
                    textKeyI18n={t(statusData?.i18nKey)}
                    backgroundColor={statusData.backgroundColor}
                  />
                </BaseCol>
                <BaseCol>
                  <VerticalLine />
                </BaseCol>
                <BaseCol>
                  <TreatmentScheduleAction />
                </BaseCol>
              </BaseRow>
            }
            defaultActiveKey={`${keyActive}`}
            label={<HeaderLayout item={item} />}
            children={
              <BaseSpace>
                <PaymentLayout infoPayment={item?.infoPayment} />
                <TreatmentInformationLayout
                  treatmentInformation={item?.treatmentInformation}
                />
                <TreatmentPhaseLayout
                  treatmentPhases={item?.treatmentPhase}
                  professionalExchangeWitPhysicians={
                    item?.professionalExchangeWitPhysicians ?? []
                  }
                  idSchedule={item?.id}
                />
              </BaseSpace>
            }
            expandIcon={() => null}
          />
        )
      })}
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseSpace)`
  padding: ${PADDING.xxs};
  background-color: #f9fafb; // need change
  border-radius: ${BORDER_RADIUS};
`

const CollapseWrapper = styled(CollapseComponent)`
  .ant-collapse-header {
    align-items: flex-start !important;
  }
`
