import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPropsDeleteSupplies } from './type'
import { requestUpdateItemManagement } from 'app/api/item'
import { isEqual } from 'lodash'
import { ItemMessageCode } from 'app/api/item/constant'
import { notificationController } from 'app/controllers/notification-controller'
import R from 'app/assets/R'

export const useModalHook = (props: IPropsDeleteSupplies) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const { id, itemIds } = props
      const transformItemIds =
        itemIds
          ?.filter(itemId => itemId._id !== props.itemId)
          ?.map(itemId => ({
            itemId: itemId._id,
            quantity: itemId.quantity,
          })) ?? []

      const payload = {
        itemIds: transformItemIds,
      }
      console.log('payload: ', payload)

      const response = await requestUpdateItemManagement({
        id,
        body: payload,
      })

      if (
        isEqual(
          response?.msgcode,
          ItemMessageCode.ItemManagement.successPutData,
        )
      ) {
        handleCancel()
        props?.fetchTable?.()
      } else {
        notificationController.error({
          message: t(R.strings.add_account),
          description: response?.message,
        })
      }
      setIsLoading(false)
    } catch (error) {
      console.log({ error })
    }
  }

  return {
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
  }
}
