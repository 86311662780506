// Message code define
export const JobTitleLevelMessageCode = {
  JobTitleLevelManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20001',
  },
}

// Endpoint
export const JobTitleLevelEndPoint = {
  JobTitleLevelManagement: {
    request: 'hr/v1/jobtitlelevel',
  },
}
