import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { ModalComponent } from 'app/components/common/ModalComponent'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AddJobTitleDoctorModalLayout } from '../AddJobTitleDoctorNurseModal'
import { useHook } from './hook'
import { IProps } from './type'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { convertedVariables } from 'app/styles/themes/themeVariables'

export const UpdateModalLayout = (props: IProps) => {
  const { t } = useTranslation()
  const { form } = useHook(props)
  return (
    <ModalComponent
      titleModal={t(R.strings.strategic_doctor_update)}
      titleOpenModal={t(R.strings.update)}
      renderContent={
        <BaseForm form={form}>
          <BaseSpace>
            <BaseRow gutter={[16, 8]} align={'middle'}>
              <BaseCol xl={12}>
                <BaseFormItem label={t(R.strings.area)} required>
                  <BaseInput
                    disabled
                    placeholder={t(R.strings.area)}
                    value={'Khu vực Hồ Chí Minh'}
                  />
                </BaseFormItem>
              </BaseCol>
              <BaseCol xl={12}>
                <BaseFormItem label={t(R.strings.clinic)} required>
                  <BaseInput
                    disabled
                    placeholder={t(R.strings.clinic)}
                    value={'Phòng khám Đa khoa'}
                  />
                </BaseFormItem>
              </BaseCol>
            </BaseRow>

            <BaseRow justify={'space-between'} gutter={16} align={'middle'}>
              <BaseCol>
                <BaseText
                  children={t(R.strings.doctor_info)}
                  fontWeight="medium"
                  opacity="0.8"
                />
              </BaseCol>
              <BaseCol>
                <AddJobTitleDoctorModalLayout />
              </BaseCol>
            </BaseRow>
            <ListWrapper>
                <ItemWrapper>
                    
                </ItemWrapper>
            </ListWrapper>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`

const ListWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
  background-color: ${convertedVariables.neutralBlack9Color};
  border: 1px solid ${convertedVariables.neutralBlack2Color};
  border-radius: ${BORDER_RADIUS};
`

const ItemWrapper = styled(BaseRow)`
border-radius: ${PADDING.xl};
background-color: ${convertedVariables.backgroundColor};
padding: 5px ${PADDING.xxs};
`