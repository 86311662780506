import { WithChildrenProps } from 'parkway-web-common'
import { getToken } from 'app/service/storage/storage-service'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { AUTH_LOGIN_PATH, DASHBOARD_PATH } from './route-path'
import { useAppSelector } from 'app/redux/hooks'
import LoadingPage from 'app/page/other/loading'
import { useVerifyPermission } from 'app/common/use-verify-permission'
import { isEqual } from 'lodash'

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token = getToken()
  const profile = useAppSelector(state => state.profileSlice)?.profile
  const currentPath = useLocation().pathname
  const { listChildDashboard } = useVerifyPermission()

  return token ? (
    profile ? (
      isEqual(currentPath, DASHBOARD_PATH) &&
      listChildDashboard?.length &&
      listChildDashboard?.[0]?.url ? (
        <Navigate to={listChildDashboard?.[0]?.url} replace />
      ) : (
        <>{children}</>
      )
    ) : (
      <LoadingPage />
    )
  ) : (
    <Navigate to={AUTH_LOGIN_PATH} replace />
  )
}

export default RequireAuth
