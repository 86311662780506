import R from 'app/assets/R'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useModalHook } from './hook'
import * as S from './styles'
import { IPropsUpdateProvider, initValues } from './type'

export const Update = (props: IPropsUpdateProvider) => {
  const {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
  } = useModalHook(props)

  return (
    <BaseModalManagement
      isLoading={isLoading}
      typeButtonOpen="update"
      titleKeyI18n={R.strings.provider_update}
      content={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <S.FormItem
            name="code"
            label={t(R.strings.code)}
            rules={rules?.code}
            required
          >
            <S.FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.code),
              })}
            />
          </S.FormItem>
          <S.FormItem
            name="TIN"
            label={t(R.strings.TIN)}
            rules={rules?.TIN}
            required
          >
            <S.FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.TIN),
              })}
            />
          </S.FormItem>

          <S.FormItem name="date" label={t(R.strings.payment_date)}>
            <S.FormDatePicker
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.code),
              })}
              className="w-100"
            />
          </S.FormItem>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.provider_update}
      confirmKeyI18n={R.strings.provider_update}
      handleSubmit={form.submit}
      visible={visible}
    />
  )
}
