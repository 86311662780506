import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import DividerLine from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/DividerLine'
import {
  IRefModal,
  ModalComponent,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/components'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEmpty } from 'lodash'
import { ReactNode, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ConstantAddDoctor } from './constant'
import { IDoctorTreatmentSchedule } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'

export const AddDoctorModal = ({
  onPress,
  iconOpen,
  isShowTextOpenModal = true,
  titleModal
}: {
  onPress?: (doctor: IDoctorTreatmentSchedule) => void
  iconOpen?: ReactNode
  isShowTextOpenModal?: boolean
  titleModal?: string
}) => {
  const { t } = useTranslation()

  const modalRef = useRef<IRefModal>({})

  const [search, setSearch] = useState('')

  const onChangeSearch = (value: string) => {
    setSearch(value)
  }

  const listDoctor = useMemo(() => {
    if (isEmpty(search)) {
      return ConstantAddDoctor.doctorData
    }

    return ConstantAddDoctor.doctorData?.filter(
      item => item?.name?.includes(search) || item?.jobTitle?.includes(search),
    )
  }, [search])

  const _onClick = (item: IDoctorTreatmentSchedule) => {
    onPress?.(item)
    modalRef?.current?.hide?.()
  }

  return (
    <ModalComponent
      iconOpenModal={
        iconOpen ?? (
          <BaseCol>
            <PlusOutlinedIcon rev={undefined} />
          </BaseCol>
        )
      }
      titleOpenModal={isShowTextOpenModal ? t(R.strings.add_doctor) : undefined}
      titleModal={titleModal ?? t(R.strings.add_doctor)}
      textButtonOpenColor="otherBlueColor"
      isShowLineTop={false}
      ref={modalRef}
      footer={<div />}
      isShowLineBottom={false}
      renderContent={
        <BaseSpace>
          <BaseInput
            placeholder={t(R.strings.enter_name_doctor)}
            value={search}
            onChange={e => onChangeSearch(e?.target.value ?? '')}
            prefix={<SearchOutlined rev={undefined} />}
          />

          <DividerLine />

          {listDoctor?.map((item, index) => {
            return (
              <ItemWrapper key={index} onClick={() => _onClick(item)}>
                <BaseAvatarProfile
                  jobTitle={`${item?.jobTitle}`}
                  name={`${item?.name}`}
                />
              </ItemWrapper>
            )
          })}
        </BaseSpace>
      }
    />
  )
}

const ItemWrapper = styled.div`
  cursor: pointer;
  padding: 5px 0px;
`

const PlusOutlinedIcon = styled(PlusOutlined)`
  path {
    fill: ${convertedVariables.otherBlueColor};
  }
`
