import { requestGetOldDoctor } from 'app/api/old-db-user'
import { OldDBMessageCode } from 'app/api/old-db-user/constant'
import { IOldDoctor, IResponseGetOldDoctor } from 'app/api/old-db-user/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { _DEV_ } from 'app/constant'
import { UnitContext } from 'app/context/UnitContext'
import { IClinic } from 'app/model/unit.model'
import { get, isEqual, reduce } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOrthodonticWithoutPaid } from '../../context'
import * as S from './styles'

export const FilterLocationAndDoctorLayout: React.FC = () => {
  const { filterResponse, setFilterResponse } = useOrthodonticWithoutPaid()
  const { t } = useTranslation()

  const [oldDoctors, setOldDoctors] = useState<IOldDoctor[]>([])
  const [loadingGetDoctor, setLoadingGetDoctor] = useState(false)

  const onChangeLocationsFrom = (locations: string[]) => {
    setFilterResponse?.(prev => ({
      ...prev,
      locationIds: locations,
    }))
  }

  const onChangeDoctorFrom = (doctors: string[]) => {
    setFilterResponse?.(prev => ({
      ...prev,
      doctorIds: doctors,
    }))
  }

  const { units } = useContext(UnitContext)

  const clinics = reduce(
    units,
    (results: IClinic[], item) => {
      return [...results, ...get(item, 'childs', [])]
    },
    [],
  )

  const getDoctors = async () => {
    try {
      setLoadingGetDoctor(true)
      const responseDoctors: IResponseGetOldDoctor = await requestGetOldDoctor(
        {},
      )

      if (
        isEqual(
          responseDoctors?.msgcode,
          OldDBMessageCode.Doctors.successGetData,
        )
      ) {
        const data = responseDoctors?.data ?? []
        setOldDoctors(data ?? [])
      }
    } catch (error) {
      _DEV_ && console.log({ error })
    } finally {
      setLoadingGetDoctor(false)
    }
  }

  useEffect(() => {
    getDoctors()
  }, [])

  return (
    <S.HeadWrapper direction="vertical" size={0}>
      <S.BaseWrapper>
        <BaseRow gutter={16} align={'middle'} justify={'start'}>
          <BaseCol xl={10}>
            <BaseForm.Item name="select">
              <BaseSelect
                defaultValue={filterResponse?.locationIds}
                value={filterResponse?.locationIds}
                placeholder={t(R.strings.select_clinic)}
                onChange={type => onChangeLocationsFrom(type as string[])}
                allowClear
                mode="multiple"
                maxTagCount={2}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={clinics?.map(item => {
                  return {
                    label: item?.name,
                    value: item?.oldid,
                  }
                })}
              />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xl={10}>
            <BaseForm.Item name="select">
              <BaseSelect
                defaultValue={filterResponse?.doctorIds}
                value={filterResponse?.doctorIds}
                placeholder={t(R.strings.select_doctor)}
                onChange={type => onChangeDoctorFrom(type as string[])}
                allowClear
                mode="multiple"
                maxTagCount={2}
                loading={loadingGetDoctor}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                showSearch
                options={oldDoctors?.map(item => {
                  return {
                    label: item?.name,
                    value: item?._id,
                  }
                })}
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>
      </S.BaseWrapper>
    </S.HeadWrapper>
  )
}
