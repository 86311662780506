import { ApiClient } from 'app/service/api-service'
import { FileEndPoint } from './constant'

export const requestUploadFile = (
  body?: FormData,
) =>
  ApiClient.Post({
    url: `${FileEndPoint.upload}`,
    body,
  })
