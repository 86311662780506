import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
`

export const HeadWrapper = styled.div`
  padding: ${PADDING.md};
  border-bottom: 0.5px solid ${convertedVariables.neutralBlack9Color};
`

export const ContentWrapper = styled.div`
  padding: ${PADDING.md};
  border-top: 0.5px solid ${convertedVariables.neutralBlack9Color};
`

export const BaseCollapseWrapper = styled(BaseCollapse)`
  border: 1px solid ${convertedVariables.neutralBlack9Color};

  .ant-collapse-content {
    border-top: 1px solid ${convertedVariables.neutralBlack9Color};
  }

  .ant-collapse-item {
    border-bottom: 0px !important;
  }
  .ant-collapse-header {
    align-items: center !important;
  }
`

export const BaseCollapseChild = styled(BaseCollapse)`
  border: 1px solid ${convertedVariables.neutralBlack9Color};

  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-content {
    border-top: 1px solid ${convertedVariables.neutralBlack9Color};
    border-bottom: 0px;
  }

  .ant-collapse-header {
    background-color: var(--background-color);
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
`

export const InputNumberWrapper = styled(InputNumber)`
  width: 150px;
`

export const ButtonAddTreatment = styled(BaseRow)`
  cursor: pointer;
  svg {
    fill: ${convertedVariables.collapseBackgroundColor};
  }
`
