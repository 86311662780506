import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { CardPaymentItem } from '../../../../components'
import R from 'app/assets/R'
import { IInfoPayment } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'

interface IProps {
  infoPayment: IInfoPayment
}

export const PaymentLayout = ({ infoPayment }: IProps) => {
  return (
    <BaseRow gutter={[10, 10]} align={'middle'}>
      <BaseCol style={{display: 'flex', flex: 1}}>
        <CardPaymentItem
          titleKeyI18n={R.strings.total_amount_service}
          value={infoPayment?.totalAmountService}
        />
      </BaseCol>
      <BaseCol style={{display: 'flex', flex: 1}}>
        <CardPaymentItem
          titleKeyI18n={R.strings.total_payment}
          value={infoPayment?.totalPayment}
        />
      </BaseCol>

      <BaseCol style={{display: 'flex', flex: 1}}>
        <CardPaymentItem
          titleKeyI18n={R.strings.paid_amount}
          value={infoPayment?.paidAmount}
        />
      </BaseCol>

      <BaseCol style={{display: 'flex', flex: 1}}>
        <CardPaymentItem
          titleKeyI18n={R.strings.refund_amount}
          value={infoPayment?.totalAmountService}
        />
      </BaseCol>
      <BaseCol style={{display: 'flex', flex: 1}}>
        <CardPaymentItem
          titleKeyI18n={R.strings.remaining_amount}
          value={infoPayment?.remainingAmount}
          isDebtPayment
        />
      </BaseCol>
    </BaseRow>
  )
}
