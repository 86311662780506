import R from 'app/assets/R'
import { SvgArrowBackUpIcon } from 'app/assets/svg-assets'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useTranslation } from 'react-i18next'
import { ModalComponent } from '../../components'

export const ServiceRefundModal = () => {
  const { t } = useTranslation()

  return (
    <ModalComponent
      iconOpenModal={<SvgArrowBackUpIcon />}
      titleOpenModal={t(R.strings.service_refund)}
      titleModal={t(R.strings.service_refund)}
      renderContent={
        <BaseSpace>
          <BaseText children={'Khánh'} />
        </BaseSpace>
      }
    />
  )
}
