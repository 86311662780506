/* eslint-disable @typescript-eslint/no-explicit-any */
import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { isEqual } from 'lodash'
import moment from 'moment'
import {
  SelectFilterEnum,
  SelectQuarterEnum,
  getQuarterOfYear,
  initialPagination,
} from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTableQuarterHook } from './Quarter/hook'
import { useTableYearHook } from './Year/hook'

export const useTableHook = () => {
  const { t } = useTranslation()

  const [filterResponse, setFilterResponse] = useState<IResponseFilter>({
    year: `${moment().year()}`,
  })

  const hookQuarter = useTableQuarterHook({
    year: filterResponse.year,
    quarter: SelectQuarterEnum.QUARTER_1,
  })
  const hookYear = useTableYearHook({ year: filterResponse.year })

  const expandedRowKeys = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.expandedRowKeys
      case SelectFilterEnum.YEAR:
        return hookYear.expandedRowKeys
      default:
        return hookQuarter.expandedRowKeys
    }
  }, [filterResponse?.selectFilterType, hookQuarter, hookYear])

  const tableData = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.tableData
      case SelectFilterEnum.YEAR:
        return hookYear.tableData
      default:
        return hookQuarter.tableData
    }
  }, [filterResponse?.selectFilterType, hookQuarter, hookYear])

  const handleTableChange = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.handleTableChange
      case SelectFilterEnum.YEAR:
        return hookYear.handleTableChange
      default:
        return hookQuarter.handleTableChange
    }
  }, [filterResponse?.selectFilterType, hookQuarter, hookYear])

  const handleExpand = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.handleExpand
      case SelectFilterEnum.YEAR:
        return hookYear.handleExpand
      default:
        return hookQuarter.handleExpand
    }
  }, [filterResponse?.selectFilterType, hookQuarter, hookYear])

  const handleChangeFilter = (filter: IResponseFilter) => {
    const { year, quarter } = filter
    setFilterResponse(filter)
    if (year) {
      const quarterParams = getQuarterOfYear({ year })[
        quarter ?? SelectQuarterEnum?.QUARTER_1
      ]
      if (
        !isEqual(
          {
            year: filterResponse.year,
            quarter: filterResponse?.quarter,
          },
          {
            year,
            quarter,
          },
        )
      ) {
        hookQuarter.fetch(initialPagination, {
          year,
          startDate: quarterParams?.start,
          endDate: quarterParams?.end,
        })
      }

      if (!isEqual(filterResponse?.year, year)) {
        hookYear.fetch(initialPagination, { year })
      }
    }
  }

  const columns = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.columns
      case SelectFilterEnum.YEAR:
        return hookYear.columns
      default:
        return hookQuarter.columns
    }
  }, [t, expandedRowKeys, filterResponse?.selectFilterType])

  const exportExcel = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.exportExcel
      case SelectFilterEnum.YEAR:
        return hookYear.exportExcel
      default:
        return hookQuarter.exportExcel
    }
  }, [filterResponse])

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleChangeFilter,
    filterResponse,
    exportExcel,
  }
}
