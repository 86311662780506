import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { ITreatmentInAppointmentSession } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { useTranslation } from 'react-i18next'

export const DoctorAndNurseLayout = ({
  treatment,
}: {
  treatment: ITreatmentInAppointmentSession
}) => {
  const { t } = useTranslation()
  return (
    <BaseSpace>
      <BaseText
        children={t(R.strings.doctors_and_nurses_perform)}
        fontWeight="semibold"
      />

      <BaseText
        children={t(R.strings.doctors_perform)}
        fontWeight="medium"
        fontSize="xxs"
      />

      <BaseRow>
        <BaseCol xl={6}>
          <BaseAvatarProfile
            name={treatment?.doctor?.name}
            jobTitle={treatment?.doctor?.jobTitle}
          />
        </BaseCol>
      </BaseRow>

      <BaseText
        children={t(R.strings.nurses_perform)}
        fontWeight="medium"
        fontSize="xxs"
      />

      <BaseRow gutter={[15, 15]} align={'middle'}>
        {treatment?.nurses?.map((item, index) => {
          return (
            <BaseCol key={index} xl={6}>
              <BaseAvatarProfile name={item?.name} jobTitle={item?.jobTitle} />
            </BaseCol>
          )
        })}
      </BaseRow>
    </BaseSpace>
  )
}
