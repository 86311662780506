import styled from 'styled-components'

export const InfoHoverbox = styled.div`
  width: 487px;
  padding: 0px;
  margin: 0;
  border-radius: 8px;
  gap: 16px;
`

export const InfoHoverBoxAuthor = styled.div``
export const InfoHoverBoxAuthorTilte = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
`
export const InfoHoverBoxAuthorTilteUpdate = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: var(--main-color-text);
  margin-bottom: 2px;
`

export const InfoHoverBoxAuthorContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0px;
`

export const InfoHoverBoxAuthorInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const InfoHoverBoxAuthorName = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--main-color-text);
  margin-left: 5px;
`

export const InfoHoverBoxAuthorDate = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--main-color-text);
  opacity: 50%;
`

export const InfoHoverBoxContent = styled.div`
  padding: 10px 0px;
  margin: 5px 0px;
  border-top: 1px dashed var(--neutral-black-color);
  border-bottom: 1px dashed var(--neutral-black-color);
`

export const InfoHoverBoxContentTitle = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: var(--main-color-text);
  margin-bottom: 2px;
`

export const InfoHoverBoxContentInfoList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const InfoHoverBoxContentInfoListItem = styled.div`
  padding: 3px 0px;
`

export const InfoHoverBoxContentInfoListItemNote = styled.div`
  padding: 3px 0px;
  width: 100%;
`
export const InfoHoverBoxContentInfoListItemLabel = styled.div`
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--main-color-text);
`
export const InfoHoverBoxContentInfoListItemName = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--main-color-text);
`
