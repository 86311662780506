import R from 'app/assets/R'
import { IGroupReport } from 'app/components/common/BaseLayoutReport/type'
import { UTILITIES_GENERATE_QR_PATH } from 'app/components/router/route-path'
import { DateUtil } from 'parkway-web-common'



export const data: IGroupReport[] = [
  {
    id: 1,
    titleI18nKey: R.strings.utilities,
    reports: [
      {
        id: 1,
        label: '001',
        descriptionI18nKey: R.strings.qr_generate,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: UTILITIES_GENERATE_QR_PATH,
      },
    ],
  },
]
