import R from 'app/assets/R'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BasePagination } from 'app/components/common/BasePagination'
import { BaseRow } from 'app/components/common/BaseRow'
import { useModalHook } from './hook'
import * as S from './styles'
import { IFormData, IPropsUpdateRole } from './type'
import { Loading } from 'app/components/common/Loading'
import { BaseEmpty } from 'app/components/common/BaseEmpty'

export const initValues: IFormData = {
  name: '',
}

export const SelectRoleOfJobTitle = (props: IPropsUpdateRole) => {
  const {
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    search,
    onChangeSearch,
    rolesAfterSelected,
    isSelectAll,
    onClickSelectAll,
    rolesSelected,
    currentPage,
    pageSize,
    onChangePage,
    onChangePageSize,
    dataRoles,
    onChangeRoles,
  } = useModalHook(props)

  return (
    <BaseModalManagement
      titleKeyI18n={R.strings.role}
      isLoading={isLoading}
      content={
        <S.ContentWrapper direction="vertical" size={16}>
          <S.FormItem validateDebounce={500} label={false}>
            <S.FormInput
              value={search}
              onChange={e => onChangeSearch(e.target?.value ?? '')}
              placeholder={t(R.strings.search)}
              allowClear
            />
          </S.FormItem>

          <S.DataWrapper direction={'vertical'} size={16}>
            <S.ItemHeader
              gutter={[10, 10]}
              align={'middle'}
              justify={'space-between'}
              wrap={false}
            >
              <BaseCol>
                <S.TextHead>{t(R.strings.role)}</S.TextHead>
              </BaseCol>
              <BaseCol>
                <BaseRow align={'middle'} gutter={[10, 10]}>
                  <BaseCol>
                    <S.TextHead>{t(R.strings.all)}</S.TextHead>
                  </BaseCol>
                  <BaseCol>
                    <BaseCheckbox
                      checked={isSelectAll}
                      onClick={onClickSelectAll}
                    />
                  </BaseCol>
                </BaseRow>
              </BaseCol>
            </S.ItemHeader>
            <S.ListWrapper direction={'vertical'} size={16}>
              {rolesAfterSelected?.length ? rolesAfterSelected?.map((role, idx) => {
                const _onClick = () => {
                  onChangeRoles(role)
                }
                return (
                  <S.ItemData
                    key={idx}
                    gutter={[10, 10]}
                    align={'middle'}
                    justify={'space-between'}
                    wrap={false}
                    onClick={_onClick}
                  >
                    <BaseCol>
                      <S.TextData>{role.name}</S.TextData>
                    </BaseCol>
                    <BaseCol>
                      <BaseCheckbox checked={role?.isSelected} />
                    </BaseCol>
                  </S.ItemData>
                )
              }): <BaseEmpty />}
              {isLoading ? <Loading /> : null}
            </S.ListWrapper>
          </S.DataWrapper>
          <S.FooterWrapper
            justify={'space-between'}
            align={'middle'}
            gutter={[10, 10]}
          >
            <BaseCol>
              <BaseRow gutter={[5, 5]} align={'middle'}>
                <BaseCol>
                  <S.TextData>
                    {`${t(R.strings.role_content_role_selected_count)}: `}
                  </S.TextData>
                </BaseCol>
                <BaseCol>
                  <S.TextTotalPermissionSelected>
                    {rolesSelected?.length}
                  </S.TextTotalPermissionSelected>
                </BaseCol>
              </BaseRow>
            </BaseCol>
            <BaseCol>
              <BasePagination
                defaultCurrent={currentPage}
                pageSize={pageSize}
                total={dataRoles?.length ?? 0}
                onChange={(page: number, pageSize?: number) => {
                  if (page && pageSize) {
                    onChangePageSize(pageSize)
                    onChangePage(page)
                  }
                }}
                pageSizeOptions={[10, 20, 50, 100]}
              />
            </BaseCol>
          </S.FooterWrapper>
        </S.ContentWrapper>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      confirmKeyI18n={R.strings.confirm}
      handleSubmit={handleSubmit}
      visible={visible}
      widthModal={1000}
      openModalComponent={
        <S.PermissionHeadButton
          icon={<S.PlusIcon rev={false} />}
          onClick={showModal}
        >
          <S.PermissionHeadButtonText>
            {t(R.strings.role_content_add_role)}
          </S.PermissionHeadButtonText>
        </S.PermissionHeadButton>
      }
    />
  )
}
