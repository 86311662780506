import { BaseCol } from 'app/components/common/BaseCol'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  background-color: #F3F4F5; // need change
`
export const FirstColWrapper = styled(BaseCol)`
  width: 60px;
  background-color: white;
  border: 0.5px solid #e8e8e8;
  border-top: none;
  border-bottom: none;
  margin: none !important;
`
export const TitleRequestJobTitleLevelDoctor = styled.div`
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.xs};
  color: #000000;
`
