import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { Box, Name } from './styles'

const Info = () => {
  const { t } = useTranslation()
  return (
    <Box>
      <div>
        <Name>{t(R.strings.warehouse_goods_issue_info)}</Name>
      </div>
    </Box>
  )
}

export default Info
