import { ParamsList, ResponseType } from 'parkway-web-common'

export enum ReportTypeEnum {
  withoutPaid = 'withoutPaid',
  needReview = 'needReview',
  warning = 'warning',
}

export enum GroupTypeEnum {
  doctor = 'doctor',
  clinic = 'clinic',
}

export interface IParamsGetTotalWithoutPaid {
  startDate?: string
  endDate?: string
}

export interface IParamsGetLocationsWithoutPaid extends ParamsList {
  startDate?: string
  endDate?: string
  reportType: ReportTypeEnum
  groupType: GroupTypeEnum
}

export interface ILocationsWithoutPaid {
  _id: string
  name?: string
  total?: number
}

export type IParamsGetDoctorsWithoutPaid = IParamsGetLocationsWithoutPaid

export type IResponseLocationsWithoutPaid = ResponseType<
  ILocationsWithoutPaid[]
> & { metadata: { total: number } }

export interface IParamsGetOrthodonticWithoutPaid
  extends IParamsGetLocationsWithoutPaid {
  id?: string
}

export interface IOrthodonticWithoutPaid {
  appointmentDate?: string
  recordId?: string
  doctorId?: string
  patientId?: string
  recordDate?: string
  totalMonth?: number
  difficulty?: number
  patientName?: string
  patientCode?: string
  doctorName?: string
  locationCode?: string
}

export type IResponseOrthodonticWithoutPaid = ResponseType<
  IOrthodonticWithoutPaid[]
> & { metadata: { total: number } }

export interface IDoctorsWithoutPaid {
  _id: string
  name?: string
  total?: number
  totalAll?: number
}

export type IResponseDoctorsWithoutPaid = ResponseType<
  IDoctorsWithoutPaid[]
> & { metadata: { total: number } }
