import { SvgSaveIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { useHook } from '../BuyInfo/hook'
import { useContext } from 'react'
import { WarehousePurchaseContext } from 'app/containers/InventoryManagement/WarehousePurchase/context'
import { StorePOStatusEnum } from 'app/common/enum'

export const SaveDraft = () => {
  const { t } = useTranslation()
  const { handleCreate, isLoading } = useHook()
  const { form, discount, orders } = useContext(WarehousePurchaseContext)

  return (
    <BaseButton
      icon={<SvgSaveIcon />}
      onClick={async () => {
        const values = await form?.getFieldsValue()
        await handleCreate(values, orders, discount, StorePOStatusEnum.DRAFT)
      }}
      loading={isLoading}
    >
      {t(R.strings.save_daft)}
    </BaseButton>
  )
}
