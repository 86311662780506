import { DatePicker } from 'antd'
import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import { removeVietnameseTones } from 'app/helpers/string.helper'
import dayjs from 'dayjs'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import { SearchDropdown } from './search-dropdown'
import { DATE } from 'app/common/constants'
export enum PaidTypeTableEnum {
  deposit = 'deposit',
  installment = 'installment',
  one_time_payment = 'one_time_payment',
  no_paid = 'no_paid',
}
export type PaidTypeTableType = keyof typeof PaidTypeTableEnum
const { RangePicker } = DatePicker
declare type EventValue<DateType> = DateType | null
declare type RangeValue<DateType> =
  | [EventValue<DateType>, EventValue<DateType>]
  | null

export interface IFilter {
  keyword: string | undefined
  treatments: string[] | undefined
  paidType: string[] | unknown
  startDate: string | undefined
  endDate: string | undefined
  search?: string | undefined
  paymentMethod?: string[] | unknown
}

export const FilterBracesTray = ({ handleChangeFilter, filterData }) => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState<IFilter>({
    keyword: '',
    treatments: [],
    paidType: [],
    paymentMethod: [],
    startDate: dayjs().subtract(4, 'months').format(DATE.YYYY_MM_DD),
    endDate: dayjs().format(DATE.YYYY_MM_DD),
  })
  const [isOverlayOpen, setOverlayOpen] = useState(false)

  useEffect(() => {
    handleChangeFilter?.(filter)
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      handleChangeFilter?.(filter)
    }, 500)
    return () => clearTimeout(timer)
  }, [filter])

  useEffect(() => {
    if (filterData && !isEqual(filterData, filter)) {
      setFilter(filterData)
    }
  }, [filterData])

  const onChangeSearch = (search: string) => {
    setFilter(prev => {
      return {
        ...prev,
        search,
      }
    })
  }

  return (
    <S.HeadWrapper gutter={20} align={'middle'}>
      <BaseCol>
        <SearchDropdown
          query={filter?.search ?? ''}
          setQuery={onChangeSearch}
          data={[]}
          isOverlayOpen={isOverlayOpen}
          setOverlayOpen={setOverlayOpen}
        />
      </BaseCol>
      <BaseCol>
        <BaseForm.Item name="select" required={false}>
          <S.SelectBaseStyled
            defaultValue={filter.paidType}
            mode="multiple"
            allowClear
            placeholder={t(R.strings.manage_customer_choose_paid_type)}
            suffixIcon={!filter?.paidType && <SvgTableCloseIcon />}
            onChange={type =>
              setFilter((prev: IFilter) => ({
                ...prev,
                paidType: type,
              }))
            }
            filterOption={(value, option) => {
              return removeVietnameseTones(option?.children)
                .toLowerCase()
                .includes(removeVietnameseTones(value).toLowerCase())
            }}
          >
            {Object.values(PaidTypeTableEnum)?.map((item, index) => {
              return (
                <Option value={item} key={index}>
                  {t(item)}
                </Option>
              )
            })}
          </S.SelectBaseStyled>
        </BaseForm.Item>
      </BaseCol>
      <BaseCol>
        <RangePicker
          defaultValue={[dayjs(filter?.startDate), dayjs(filter?.endDate)]}
          value={[dayjs(filter?.startDate), dayjs(filter?.endDate)]}
          onCalendarChange={(date: RangeValue<dayjs.Dayjs>) => {
            if (date && date.length >= 2) {
              setFilter({
                ...filter,
                startDate: date[0] ? date[0].format(DATE.YYYY_MM_DD) : '',
                endDate: date[1] ? date[1].format(DATE.YYYY_MM_DD) : '',
              })
            }
          }}
        />
      </BaseCol>
    </S.HeadWrapper>
  )
}
