/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiClientTreatment } from 'app/service/api-service'
import { ItemEndPoint } from './constant'
import {
  IParamsGetItemManagement,
  IPayloadCreateItemManagement,
} from './model/management'

export const requestGetItem = (params?: IParamsGetItemManagement) =>
  ApiClientTreatment.Get({
    url: `${ItemEndPoint.ItemManagement.request}`,
    params: { params },
  })

export const requestPostItem = (body: any) =>
  ApiClientTreatment.Post({
    url: `${ItemEndPoint.ItemManagement.request}`,
    body,
  })

export const requestGetItemManagement = params =>
  ApiClientTreatment.Get({
    url: ItemEndPoint.ItemManagement.request,
    params: { params },
  })

export const requestCreateItemManagement = (
  body: IPayloadCreateItemManagement,
) =>
  ApiClientTreatment.Post({
    url: ItemEndPoint.ItemManagement.request,
    body,
  })

export const requestUpdateItemManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: any
}) =>
  ApiClientTreatment.Put({
    url: `${ItemEndPoint.ItemManagement.request}/${id}`,
    body,
  })

export const requestDetailItemManagement = (id?: string | number) =>
  ApiClientTreatment.Get({
    url: `${ItemEndPoint.ItemManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteItemManagement = (id?: string | number) =>
  ApiClientTreatment.Delete({
    url: `${ItemEndPoint.ItemManagement.request}/${id}`,
    body: {},
  })

export const requestGetAllItemManagement = () =>
  ApiClientTreatment.Get({
    url: `${ItemEndPoint.ItemManagement.request}/get-all`,
    params: {},
  })
