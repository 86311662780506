import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import ViewWarehouseGoodsIssueContainer from 'app/containers/InventoryManagement/WarehouseGoodsIssue/pages/view'

const ViewWarehouseGoodsIssueContainerPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.inventory_management)}</PageTitle>
      <ViewWarehouseGoodsIssueContainer />
    </>
  )
}
const ViewWarehouseGoodsIssueContainerPage = memo(
  ViewWarehouseGoodsIssueContainerPageCpn,
  isEqual,
)

export default ViewWarehouseGoodsIssueContainerPage
