import { Pagination } from 'parkway-web-common'

export const initialPagination: Pagination = {
  current: 1,
  pageSize: 20,
}

export interface IInvoiceTreatment {
  quantity?: number;
  unitPrice?: number;
  treatmentFee?: number;
  vatCode?: string;
  vat?: number;
  revenueTax?: number;
  name?: string;
  paymentMethod?: string;
}

export interface IGeneralInvoiceTable {
  _id?: string;
  date?: string;
  deactivated?: boolean;
  doctor?: {
    _id?: string;
    name?: string;
  };
  location?: {
    _id?: string;
    name?: string;
    address?: string;
    oldid?: string;
  };
  netAmount?: number;
  patient?: {
    _id?: string;
    name?: string;
    id?: string;
    email?: string;
    address?: string;
    remain?: number;
  };
  payment?: {
    _id?: string;
    paid?: number;
    cash?: number;
    card?: number;
    payooLink?: number;
    installment?: number;
    fundiin?: number;
    transfer?: number;
    app?: number;
    cardType?: string;
    transferType?: string;
    isInstallment?: boolean;
  };
  paymentInfo?: {
    paymentFor?: {
      record?: string;
      treatment?: string;
      amount?: number;
      name?: string;
    };
    receivable?: {
      id?: string;
      amount?: number;
      name?: string;
      treatment?: string;
    };
  };
  refund?: {
    amount?: number;
  };
  treatments: IInvoiceTreatment[];
  paidType?: string;
}
