import 'dayjs/locale/vi'
import React, { useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { PageSearchAppointmentProvider } from 'app/context/PageSearchAppointmentContext'
import { AppointmentProvider } from 'app/context/AppointmentContext'
import './styles.css'
import SearchAppointmentPage from './SearchAppointmentPage'
import { PatientProvider } from 'app/context/PatientContext'
import { ItemCategoryProvider } from 'app/context/ItemCategoryContext'
import { ItemProvider } from 'app/context/ItemContext'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'

const SearchAppointment: React.FC = () => {
  const { t } = useTranslation()
  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.CUSTOMER_CARE,
      FunctionPermissionEnum.SEARCH_APPOINTMENT,
      R.strings.survey_report,
    )
  }, [t])
  
  return (
    <PageSearchAppointmentProvider>
      <AppointmentProvider>
        <PatientProvider>
          <ItemCategoryProvider>
            <ItemProvider>
              <SearchAppointmentPage />
            </ItemProvider>
          </ItemCategoryProvider>
        </PatientProvider>
      </AppointmentProvider>
    </PageSearchAppointmentProvider>
  )
}

export default SearchAppointment
