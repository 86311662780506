import R from 'app/assets/R'
import { ListKey } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/constant/dental-health-history'
import { IDentalHealthHistory, OralHygieneToolsYouUsuallyUseEnum } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/dental-health-history'
import { isEqual } from 'lodash'
import { useMemo, useState } from 'react'

export const useHook = (item?: IDentalHealthHistory) => {
  const [isCollapse, setIsCollapse] = useState(false)

  const onChangeCollapse = () => {
    setIsCollapse(!isCollapse)
  }

  const listItem = useMemo(() => {
    return ListKey?.map(key18n => {
      if (isEqual(key18n, R.strings.oral_hygiene_tools_you_usually_use)) {
        return {
          key18n,
          listEnumSelected: item?.[key18n] as OralHygieneToolsYouUsuallyUseEnum[],
        }
      }
      return {
        key18n,
        isChecked: item?.[key18n] as boolean,
      }
    })
  }, [item])

  return {
    isCollapse,
    listItem,
    onChangeCollapse,
  }
}
