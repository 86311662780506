import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import { PlusOutlined } from '@ant-design/icons'
import { BaseLabel } from 'app/components/common/forms/BaseLabel'
import { isEqual } from 'lodash'
import { EditIcon } from 'app/common/icons'
import { useHook } from '../hook'

const CollapseProperty = () => {
  const { t } = useTranslation()
  const {
    rules,
    productProperties,
    isLoadingProductProperty,
    productPropertiesChildren,
    mapProductProperties,
    onCloseCollapse,
  } = useHook()

  const childrenItem = ({
    productProperty,
    remove,
    idCollapseProductProperty,
  }) => (
    <S.PropertySelect key={`property-product-${productProperty.key}`}>
      <div className="property-product-select">
        <BaseForm.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            return !isEqual(
              prevValues.productPropertiesIds[productProperty.name],
              currentValues.productPropertiesIds[productProperty.name],
            )
          }}
        >
          {({ getFieldValue }) => {
            return (
              <BaseForm.Item
                name={[productProperty.name, 'parentId']}
                label={t(R.strings.category)}
                rules={rules?.categoryid}
                required
              >
                <BaseSelect
                  placeholder={t(R.strings.select_category)}
                  suffixIcon={<SvgTableCloseIcon />}
                  loading={isLoadingProductProperty}
                >
                  {productProperties?.map((item, index) => {
                    const productPropertyField = getFieldValue(
                      'productPropertiesIds',
                    )

                    const isDisabled = productPropertyField?.filter(
                      itemSelected => itemSelected?.parentId === item._id,
                    )?.length
                    return (
                      <Option
                        value={item._id}
                        disabled={!!isDisabled}
                        key={`property-product-item-${index}`}
                      >
                        {item?.name}
                      </Option>
                    )
                  })}
                </BaseSelect>
              </BaseForm.Item>
            )
          }}
        </BaseForm.Item>
        <BaseForm.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            return !isEqual(
              prevValues.productPropertiesIds[productProperty.name],
              currentValues.productPropertiesIds[productProperty.name],
            )
          }}
        >
          {({ getFieldValue }) => {
            const productPropertyField = getFieldValue('productPropertiesIds')
            const productPropertyByName =
              productPropertyField[productProperty.name]
            const parentId = productPropertyByName?.parentId
            const productPropertiesChildrenField =
              productPropertyByName?.productPropertiesChildren

            const foundProductPropertiesChildren =
              productProperties?.find(p => p._id === parentId)
                ?.productPropertiesChildren ?? []

            const productPropertiesChildrenSelect =
              foundProductPropertiesChildren
            return (
              <BaseForm.List
                name={[productProperty.name, 'productPropertiesChildren']}
              >
                {(
                  productPropertiesChildrens,
                  { add: addPropertyValue, remove: removePropertyValue },
                ) => {
                  return (
                    <>
                      <BaseLabel label="Giá trị" required />
                      {productPropertiesChildrens.map(
                        productPropertiesChildren => {
                          return (
                            <S.ProductPropertyValue
                              key={productPropertiesChildren.key}
                            >
                              <BaseForm.Item
                                {...productPropertiesChildren}
                                hiddenLabel={true}
                                name={[productPropertiesChildren.name]}
                                className={`property-product-value property-product-value-${productPropertiesChildren.name}`}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing value for property',
                                  },
                                ]}
                              >
                                <BaseSelect
                                  className="property-product-values"
                                  placeholder={t(R.strings.select_category)}
                                  suffixIcon={<SvgTableCloseIcon />}
                                  loading={isLoadingProductProperty}
                                  allowClear={true}
                                >
                                  {productPropertiesChildrenSelect?.map(
                                    (item, index) => {
                                      const isDisabled =
                                        productPropertiesChildrenField?.includes(
                                          item?._id,
                                        )

                                      return (
                                        <Option
                                          value={item?._id}
                                          key={`property-product-item-${index}`}
                                          disabled={isDisabled}
                                        >
                                          {item?.name}
                                        </Option>
                                      )
                                    },
                                  )}
                                </BaseSelect>
                              </BaseForm.Item>
                              <S.ButtonDelete
                                onClick={() => {
                                  removePropertyValue(
                                    productPropertiesChildren.name,
                                  )
                                }}
                              />
                            </S.ProductPropertyValue>
                          )
                        },
                      )}
                      <S.ButtonAdd
                        type="dashed"
                        onClick={() => addPropertyValue()}
                        block
                        icon={<PlusOutlined rev="" />}
                      >
                        {t(
                          R.strings.item_management_content_add_property_value,
                        )}
                      </S.ButtonAdd>
                    </>
                  )
                }}
              </BaseForm.List>
            )
          }}
        </BaseForm.Item>
      </div>

      <div className="property-product__button-group">
        <S.ButtonStyled onClick={() => remove(productProperty.name)}>
          {t(R.strings.delete)}
        </S.ButtonStyled>
        <S.ButtonStyled
          onClick={() => onCloseCollapse(idCollapseProductProperty)}
        >
          {t(R.strings.done_button)}
        </S.ButtonStyled>
      </div>
    </S.PropertySelect>
  )

  return (
    <S.PropertyContainer>
      <BaseForm.List name="productPropertiesIds">
        {(productProperties, { add, remove }) => {
          return (
            <>
              {productProperties.map(productProperty => {
                const idCollapseProductProperty = `collapse-property-product-${productProperty.key}`

                return (
                  <S.CollapseStyled
                    defaultActiveKey={[productProperty.key]}
                    className={idCollapseProductProperty}
                    key={productProperty.key}
                    collapsible="header"
                    expandIconPosition="end"
                    items={[
                      {
                        key: productProperty.key,
                        label: (
                          <S.Property>
                            <BaseForm.Item
                              noStyle
                              shouldUpdate={(prevValues, currentValues) => {
                                return !isEqual(
                                  prevValues.productPropertiesIds[
                                    productProperty.name
                                  ],
                                  currentValues.productPropertiesIds[
                                    productProperty.name
                                  ],
                                )
                              }}
                            >
                              {({ getFieldValue }) => {
                                const productPropertyFiled = getFieldValue(
                                  'productPropertiesIds',
                                )[productProperty.name]

                                const parentId = productPropertyFiled?.parentId

                                const productPropertiesChildrens =
                                  productPropertyFiled?.productPropertiesChildren

                                return (
                                  <>
                                    <div className="property">
                                      <label>
                                        {mapProductProperties[parentId ?? '']}
                                      </label>
                                      <ul>
                                        {productPropertiesChildrens?.map(
                                          item => {
                                            const foundProductPropertiesChildren =
                                              productPropertiesChildren?.find(
                                                children =>
                                                  children._id === item,
                                              )

                                            if (!foundProductPropertiesChildren)
                                              return

                                            return (
                                              <li
                                                key={`property-product-value${item}`}
                                              >
                                                {
                                                  foundProductPropertiesChildren.name
                                                }
                                              </li>
                                            )
                                          },
                                        )}
                                      </ul>
                                    </div>
                                  </>
                                )
                              }}
                            </BaseForm.Item>
                            <S.CollapseActionGroup>
                              <div className="action-item">
                                <S.ButtonEdit
                                  onClick={() =>
                                    onCloseCollapse(idCollapseProductProperty)
                                  }
                                >
                                  <EditIcon strokeColor={'#8090a1'} />
                                  <span>{t(R.strings.edit)}</span>
                                </S.ButtonEdit>
                              </div>
                              <div className="action-item">
                                <S.ButtonDelete
                                  onClick={() => remove(productProperty.name)}
                                />
                              </div>
                            </S.CollapseActionGroup>
                          </S.Property>
                        ),
                        children: childrenItem({
                          productProperty,
                          remove,
                          idCollapseProductProperty,
                        }),
                      },
                    ]}
                  />
                )
              })}
              <S.ButtonAdd
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined rev="" />}
              >
                {t(R.strings.item_management_content_add_property)}
              </S.ButtonAdd>
            </>
          )
        }}
      </BaseForm.List>
    </S.PropertyContainer>
  )
}

export default CollapseProperty
