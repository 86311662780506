/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd'
import {
  CampaignTypeEnum,
  IDiscountCampaign,
} from 'app/api/marketing/discount-campaign/model'
import {
  EVerifyRuleKey,
  MethodIssueEnum,
} from 'app/api/marketing/discount-voucher/enum'
import {
  IDiscountVoucher,
  ITargetUserFile,
  IUserDataVoucher,
} from 'app/api/marketing/discount-voucher/model'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import dayjs from 'dayjs'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { IFormData, ITreatmentInTable } from './type'
import { useUserTargetHook } from './hook/user-target'
import { useReferralTargetHook } from './hook/referral-target'
import { isEqual } from 'lodash'

interface IDetailVoucherContext {
  form?: FormInstance<IFormData>
  listService?: ITreatmentInTable[]

  handleSubmit?: (values: IFormData) => void
  handleGoBack?: () => void
  onChangeService?: (service: ITreatmentInTable[]) => void
  onDeleteService?: (id?: string) => void
  onChangeMethodIssueVoucher?: (method: MethodIssueEnum) => void
  methodIssueVoucher?: MethodIssueEnum
  isLoading?: boolean
  campaignType?: CampaignTypeEnum
  itemCampaign?: IDiscountCampaign
  itemVoucher?: IDiscountVoucher
  isEditPrefix?: boolean
  setIsEditPrefix?: (value: boolean) => void
  prefixLength?: number
  setPrefixLength?: (value: number) => void
  prefix?: string
  setPrefix?: (value: string) => void
  targetUserFile?: ITargetUserFile
  lisCustomer?: IUserDataVoucher[]
  verifyRuleKey?: EVerifyRuleKey

  targetReferralFile?: ITargetUserFile
  lisCustomerReferral?: IUserDataVoucher[]
}
export const DetailVoucherContext = createContext<IDetailVoucherContext>({})

export const DetailVoucherProvider = ({ children }) => {
  const navigate = useNavigate()
  const locationData = useLocation()
  const [form] = BaseForm.useForm<IFormData>()
  const [listService, setListService] = useState<ITreatmentInTable[]>([])
  const [methodIssueVoucher, setMethodIssueVoucher] = useState<
    MethodIssueEnum | undefined
  >(undefined)
  const [isEditPrefix, setIsEditPrefix] = useState(false)
  const [prefixLength, setPrefixLength] = useState(6)
  const [prefix, setPrefix] = useState('')
  const [verifyRuleKey, setVerifyRuleKey] = useState<
    EVerifyRuleKey | undefined
  >(undefined)

  const itemVoucher: IDiscountVoucher = useMemo(() => {
    return locationData?.state?.itemVoucher
  }, [locationData])

  const itemCampaign: IDiscountCampaign = useMemo(() => {
    return locationData?.state?.itemCampaign
  }, [locationData])

  const handleGoBack = () => {
    navigate?.(-1)
  }

  const {
    lisCustomer,
    targetUserFile,
    onChangeListCustomer,
    onChangeTargetUserFile,
  } = useUserTargetHook()

  const {
    lisCustomerReferral,
    onChangeListCustomerReferral,
    onChangeTargetReferralFile,
    targetReferralFile,
  } = useReferralTargetHook()

  useEffect(() => {
    if (itemVoucher) {
      const numberOfUsesPerCustomer =
        itemVoucher?.verifyRules?.find(item =>
          isEqual(item?.key, EVerifyRuleKey.MaxUsage),
        )?.value ?? 0

      form.setFieldsValue({
        startDate: dayjs(itemVoucher?.startDate),
        endDate: itemVoucher?.endDate ? dayjs(itemVoucher?.endDate) : undefined,
        code: itemVoucher?.code,
        prefix: itemVoucher?.prefixCode,
        discountType: itemVoucher?.discountType,
        totalVoucher: itemVoucher?.quantity,
        numberOfUses: itemVoucher?.maxUsage,
        discountValue: itemVoucher?.amount,
        targetUser: itemVoucher?.targetUser,
        methodIssue: itemVoucher?.methodIssue,
        prefixLength: itemVoucher?.maxLengthCode,
        isCustomPrefix: !!itemVoucher?.prefixCode?.length,
        name: itemVoucher?.name ?? '',
        verifyRuleKey: itemVoucher?.verifyRuleKey,
        numberOfUsesPerCustomer,
      })

      setMethodIssueVoucher(itemVoucher?.methodIssue)
      setListService(itemVoucher?.treatmentInfos ?? [])
      setIsEditPrefix(!!itemVoucher?.prefixCode?.length)
      setPrefixLength(itemVoucher?.maxLengthCode ?? 6)
      setPrefix(itemVoucher?.prefixCode ?? '')
      onChangeTargetUserFile(itemVoucher?.targetUserFile)
      onChangeListCustomer(itemVoucher?.targetUserInfos ?? [])
      setVerifyRuleKey(itemVoucher?.verifyRuleKey)

      onChangeListCustomerReferral(itemVoucher?.listReferralInfos ?? [])
      onChangeTargetReferralFile(itemVoucher?.referralFile)
    }
  }, [itemVoucher])

  return (
    <DetailVoucherContext.Provider
      value={{
        form,
        handleGoBack,
        listService,
        methodIssueVoucher,
        campaignType: itemCampaign?.campaignType,
        itemCampaign,
        itemVoucher,
        isEditPrefix,
        prefixLength,
        prefix,
        targetUserFile,
        lisCustomer,
        verifyRuleKey,
        targetReferralFile,
        lisCustomerReferral,
      }}
    >
      {children}
    </DetailVoucherContext.Provider>
  )
}

export function useDetailVoucherContext() {
  const context = useContext(DetailVoucherContext)

  if (context === undefined) {
    throw new Error(
      'useDetailVoucher must be used within a DetailVoucherProvider',
    )
  }
  return context
}
