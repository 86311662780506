import { PageType } from './type'
import R from 'app/assets/R'
import { Button } from 'antd'

const FormFooter = ({
  newAppointment,
  props,
  setCallCenter,
  submitButton,
  confirmLoading,
  handleCancel,
  t,
  setRequired,
}) => {
  return (
    <>
      {newAppointment?._id &&
      props?.page === PageType.CALLCENTER &&
      props?.isCalling ? (
        props?.footer({
          setCallCenter,
          submitButton,
          confirmLoading,
          setRequired,
        })
      ) : (
        <div className="appointment">
          <Button key="back" onClick={handleCancel}>
            {t(R.strings.cancel)}
          </Button>
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={confirmLoading}
            onClick={() => submitButton?.current?.click()}
          >
            {t(R.strings.confirm)}
          </Button>
        </div>
      )}
    </>
  )
}

export default FormFooter
