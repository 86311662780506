import React from 'react'
import * as S from './styles'
import { WithChildrenProps } from 'parkway-web-common'
import { colors } from 'parkway-web-common'

interface MainHeaderProps extends WithChildrenProps {
  isTwoColumnsLayout: boolean
}

export const MainHeader: React.FC<MainHeaderProps> = ({
  isTwoColumnsLayout,
  children,
}) => {
  return (
    <S.Header
      $isTwoColumnsLayoutHeader={isTwoColumnsLayout}
      style={{ backgroundColor: colors.white }}
    >
      {children}
    </S.Header>
  )
}
