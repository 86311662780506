import { BaseRow } from 'app/components/common/BaseRow'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const AreaRowWrapper = styled.div`
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  position: absolute !important;
  top: ${PADDING.md};
`

export const AreaText = styled.div`
  margin-left: ${PADDING.xxs};
  font-weight: ${FONT_WEIGHT.semibold};
`

export const RevenueTableWrapper = styled(BaseRow)`
  align-item: center;
  justify-content: flex-end;
`

export const GrowthWrapper = styled(BaseRow)<{ $upValue?: boolean }>`
  background-color: ${props =>
    props?.$upValue
      ? 'var(--states-green-light-color)'
      : ' var(--states-red-light-color)'};
  padding: 1px 2px;
  border-radius: 4px;
  width: fit-content;
  margin-left: ${PADDING.xxxs};

  .value {
    font-size: ${FONT_SIZE.xxs};
    font-weight: ${FONT_WEIGHT.semibold};
    color: ${props =>
      props.$upValue ? 'var(--states-green-color)' : 'var(--states-red-color)'};
    margin-left: ${PADDING.xxxxs};
  }
`

export const NoWrapper = styled.div`
  position: absolute !important;
  top: ${PADDING.md};
`
