import R from 'app/assets/R'
import { DegreeClassification } from 'app/common/enum'
import { t } from 'i18next'

const getDegreeClassification = key => {
  switch (key) {
    case DegreeClassification.excellent:
      return t(R.strings.degree_classification_excellent)
    case DegreeClassification.good:
      return t(R.strings.degree_classification_good)
    case DegreeClassification.average_good:
      return t(R.strings.degree_classification_average_good)
    case DegreeClassification.average:
      return t(R.strings.degree_classification_average)
    case DegreeClassification.below_average:
      return t(R.strings.degree_classification_below_average)
    case DegreeClassification.weak:
      return t(R.strings.degree_classification_weak)
    case DegreeClassification.unknown:
      return t(R.strings.degree_classification_unknown)
    default:
      return key
  }
}

export { getDegreeClassification }
