import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import ProfileManagementContainer from 'app/containers/HumanResources/ProfileManagement'

const ProfileManagementPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.profile_content_management)}</PageTitle>
      <ProfileManagementContainer />
    </>
  )
}
const ProfileManagementPage = memo(ProfileManagementPageCpn, isEqual)

export default ProfileManagementPage