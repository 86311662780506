import { BaseButton } from 'app/components/common/BaseButton'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  width: 100%;
  padding: ${PADDING.md};
`
export const ButtonCreateAction = styled(BaseButton)`
  background-color: var(--states-orange-color);
  color: var(--background-color);
  transition: all 0.5s ease;
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--background-color);
    color: var(--states-orange-color);
    border-color: var(--states-orange-color);
    transition: all 0.5s ease;
  }
`

export const ButtonActionGroup = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`
export const ButtonCancel = styled(BaseButton)`
  min-width: 120px;
`
export const FormItem = styled(BaseForm.Item)``

export const ButtonSubmit = styled(BaseButton)`
  min-width: 120px;
  background-color: var(--other-blue-color);
  color: var(--background-color);
  border-color: var(--other-blue-color);
  &:hover {
    color: var(--background-color) !important;
    border-color: var(--other-blue-color);
  }
`
