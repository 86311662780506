import { AppointmentContext } from 'app/context/AppointmentContext'
import { PageAppointmentContext } from 'app/context/PageAppointmentContext'
import moment from 'moment'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { IAppointment } from '../../type'
import ConfirmModal from './ConfirmModal'
import CreateAndUpdate from './CreateAndUpdate'
import Event from './Event'
import TimeSlotWrapper from './TimeSlotWrapper'
import Toolbar from './ToolBar'
import WeekHeader from './WeekHeader'
import './index.css'
import { TIMESLOT_STEP } from 'app/common/config'
import { scheduleStatusAppointment } from 'app/api/appointment/constant'
import { DATE } from 'app/common/constants'
import { PageType } from './CreateAndUpdate/type'

const localizer = momentLocalizer(moment)
moment.locale('vi')
export const navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
}

export const views = {
  MONTH: 'month',
  WEEK: 'week',
  WORK_WEEK: 'work_week',
  DAY: 'day',
  AGENDA: 'agenda',
}

interface IRefWithBoundingRect extends HTMLElement {
  getBoundingClientRect: () => DOMRect
}

const CalendarRight = props => {
  const { view, setView, selectedDate, setSelectedDate } = props
  const [calendarDate, setCalendarDate] = useState(new Date())
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const headerRef = useRef<IRefWithBoundingRect>(null)
  const currentTimeRef = useRef<IRefWithBoundingRect>(null)
  const [openCreateUpdate, setOpenCreateUpdate] = useState(false)
  const [isOpenTime, setIsOpenTime] = useState(false)
  const [cancelAppointmentItem, setCancelAppointmentItem] = useState({})
  const { fetchData, appointments } = useContext(AppointmentContext)
  const { filter } = useContext(PageAppointmentContext)

  const [appointment, setAppointment] = useState({})
  useEffect(() => {
    if (
      filter?.date &&
      filter?.areaId &&
      filter?.clinicId &&
      filter?.doctorId
    ) {
      fetchData?.({
        page: 0,
        pageSize: 10,
        ...filter,
        date: filter?.date?.format(DATE.YYYY_MM_DD),
        view,
        scheduleStatus: scheduleStatusAppointment,
      })
    }
  }, [filter, view, selectedDate, calendarDate])

  useEffect(() => {
    if (view === 'week' && isOpenTime) {
      const element: Element | null =
        document.querySelector('.rbc-time-content')
      const rbcTimeView: Element | null = document.querySelector(
        '.calendar.week .rbc-time-view',
      )
      if (rbcTimeView) rbcTimeView.scrollTop = 0
      const header = headerRef?.current?.getBoundingClientRect()
      const currentTime = currentTimeRef?.current?.getBoundingClientRect()

      if (element) {
        const childrenToRemove = element.querySelectorAll('.custom-timeline')
        childrenToRemove.forEach(child => {
          child.remove()
        })
      }

      element?.insertAdjacentHTML(
        'beforeend',
        `
      <div class="custom-timeline" style="
      top:${
        Number(
          (
            (currentTime ? currentTime?.top : 0) - (header ? header?.top : 0)
          ).toFixed(0),
        ) - 78
      }px;
      width:${element?.scrollWidth}px;
      "> 
      <div class="line"></div> 
      </div>
    `,
      )
    }
  }, [view, isOpenTime, new Date()])

  useEffect(() => {
    setCalendarDate(selectedDate)
  }, [selectedDate])

  const appointmentEvents = appointments
    ?.sort((a: IAppointment, b: IAppointment) => {
      return (
        Number(moment(`${a?.date} ${a?.time}`).toDate()) -
        Number(moment(`${b?.date} ${b?.time}`).toDate())
      )
    })
    ?.map((appointment: IAppointment, index) => {
      return {
        tilte: appointment?.categoryService?.name,
        start: new Date(
          moment(`${appointment?.date} ${appointment?.time}`).format(),
        ),
        end: new Date(
          moment(`${appointment?.date} ${appointment?.time}`)
            .add('minutes', appointment?.duration || TIMESLOT_STEP)
            .format(),
        ),
        resource: appointment,
        prev: index - 1 >= 0 ? appointments[index - 1] : {},
        next: index + 1 <= appointments?.length ? appointments[index + 1] : {},
      }
    })

  const CalendarProps = {
    defaultView: view,
    view: view,
    defaultDate: new Date(),
    date: calendarDate,
    views: [views.WEEK, views.DAY],
    events: appointmentEvents,
    step: TIMESLOT_STEP,
    timeslots: 1,
    isNow: false,
  }

  const components = useMemo(
    () => ({
      eventWrapper: props =>
        props
          ? Event({
              ...props,
              setOpenConfirmModal,
              setCancelAppointmentItem,
              setOpenCreateUpdate,
              setAppointment,
            })
          : null,
      timeSlotWrapper: props => {
        return TimeSlotWrapper({
          ...props,
          currentTimeRef,
          view,
          setOpenCreateUpdate,
          setIsOpenTime,
          setAppointment,
        })
      },
      toolbar: props => (props ? Toolbar({ ...props, selectedDate }) : null),
      week: {
        header: props =>
          props ? WeekHeader({ ...props, selectedDate, headerRef }) : null,
      },
    }),
    [selectedDate, view, appointments],
  )

  const formats = useMemo(
    () => ({
      dayHeaderFormat: (date: Date) => {
        return moment(date).format(DATE.DDDD_D)
      },

      timeGutterFormat: (date: Date) => {
        return moment(date)
          .format(DATE.LT_A)
          .replace(DATE.SA, DATE.AM)
          .replace(DATE.CH, DATE.PM)
      },
    }),
    [],
  )

  const onNavigate = date => {
    setCalendarDate(date)
    setSelectedDate(moment(date).format(DATE.YYYY_MM_DD))
  }

  return (
    <div className={`calendar ${view}`}>
      <ConfirmModal
        openConfirmModal={openConfirmModal}
        setOpenConfirmModal={setOpenConfirmModal}
        cancelAppointmentItem={cancelAppointmentItem}
        setCancelAppointmentItem={setCancelAppointmentItem}
      />
      <CreateAndUpdate
        open={openCreateUpdate}
        setOpen={setOpenCreateUpdate}
        appointment={appointment}
        setAppointment={setAppointment}
        view={view}
        page={PageType.APPOINTMENT}
      />

      <Calendar
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        onView={view => setView(view)}
        style={{ height: 820 }}
        onNavigate={onNavigate}
        components={components}
        formats={formats}
        selectable
        min={new Date(2023, 11, 1, 8, 0, 0)}
        max={new Date(2023, 11, 1, 18, 30, 0)}
        {...CalendarProps}
      />
    </div>
  )
}

export default CalendarRight
