import { InfoCircleOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { SvgInformationCircleOrangeIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BaseTooltip } from 'app/components/common/BaseTooltip'
import { randomMoney } from 'app/components/tables/common-table/constant'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'
import { IProductServicePurchaseItem } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/purchase-product-service'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  BORDER_RADIUS,
  DateUtil,
  PADDING,
  formatMoney,
} from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  item?: IProductServicePurchaseItem
  index: number
}

export const ProductServiceItem = ({ index }: IProps) => {
  const {t} = useTranslation()
  return (
    <HeadItemWrapper align={'middle'} gutter={15} wrap={false}>
      <BaseCol xl={1}>
        <BaseText
          children={index}
          opacity="0.7"
          fontSize="xs"
          fontWeight="medium"
        />
      </BaseCol>
      <BaseCol xl={3}>
        <BaseText
          children={DateUtil.formatDate(new Date(), FORMAT_DD_MM_YYYY_HH_MM)}
          opacity="0.7"
          fontSize="xs"
          fontWeight="medium"
        />
      </BaseCol>
      <BaseCol xl={7}>
        <BaseRow align={'middle'} gutter={10} wrap={false}>
          <BaseCol>
            <BaseText
              children={'PW565LTGR'}
              fontWeight="semibold"
              fontSize="xs"
            />
          </BaseCol>
          <BaseTooltip title={'PW565LTGR'}>
            <SvgInformationCircleOrangeIcon />
          </BaseTooltip>
        </BaseRow>
      </BaseCol>
      <BaseCol xl={3}>
        <BaseText
          children={formatMoney(randomMoney())}
          opacity="0.7"
          fontSize="xs"
          fontWeight="medium"
        />
      </BaseCol>
      <BaseCol xl={3}>
        <BaseText
          children={formatMoney(randomMoney())}
          fontSize="xs"
          fontWeight="bold"
          colorText="statesGreenColor"
        />
      </BaseCol>
      <BaseCol xl={4}>
        <BaseText
          children={'Chuyển khoản ngân hàng'}
          opacity="0.7"
          fontSize="xs"
          fontWeight="medium"
        />
      </BaseCol>
      <BaseCol xl={3}>
        <BaseRow align={'middle'} gutter={[15, 10]} justify={'center'}>
          <BaseCol>
            <BaseText
              children={'Có'}
              fontSize="xs"
              textAlign="center"
              fontWeight="medium"
            />
          </BaseCol>
          <BaseTooltip title={t(R.strings.issue_invoice)}>
            <InfoCircleOutlined rev={undefined} />
          </BaseTooltip>
        </BaseRow>
      </BaseCol>
    </HeadItemWrapper>
  )
}

const HeadItemWrapper = styled(BaseRow)`
  padding: ${PADDING.xxs};
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};
`
