export const handlePermission = async () => {
  try {
    const permissionNotifications = await navigator.permissions.query({
      name: 'notifications',
    })
    const permission = await permissionQuery(permissionNotifications)
    return permission
  } catch (error) {
    return {
      status: false,
      state: 'error',
    }
  }
}

const permissionQuery = async result => {
  switch (result?.state) {
    case 'granted':
      return {
        status: true,
        state: 'granted',
      }
    case 'denied':
      return {
        status: false,
        state: 'denied',
      }
    case 'prompt': {
      const newPrompt = await Notification.requestPermission()
      return {
        status: newPrompt === 'granted',
        state: 'prompt',
      }
    }
    default:
      return {
        status: false,
        state: 'default',
      }
  }
}
