import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  LabelCollapseComponent,
  ProfileServiceComponent,
} from '../../../../components'
import { useDetailCampaignContext } from 'app/containers/Marketing/DiscountCampaignManagement/DetailCampaign/context'
import { BaseDivider } from 'app/components/common/BaseDivider'

export const BundleBillDiscountTypeLayout = () => {
  const { t } = useTranslation()
  const { listBundleBillDiscountService } = useDetailCampaignContext()

  return (
    <RootWrapper>
      <HeaderWrapper
        gutter={[16, 8]}
        align={'middle'}
        justify={'space-between'}
      >
        <BaseCol>
          <BaseText
            children={t(R.strings.service_apply)}
            fontWeight="semibold"
            fontSize="md"
          />
        </BaseCol>
      </HeaderWrapper>
      <ContentWrapper
        split={
          <BaseDivider
            style={{
              margin: 0,
            }}
            dashed
          >
            <BaseText
              children={'or'}
              style={{
                color: '#858C94',
              }}
            />
          </BaseDivider>
        }
      >
        {listBundleBillDiscountService?.length ? (
          listBundleBillDiscountService?.map((item, index) => {
            return (
              <CollapseWrapper
                items={[
                  {
                    key: index,
                    label: <LabelCollapseComponent item={item} key={index} />,

                    style: {
                      alignItems: 'center',
                    },
                    children: (
                      <BaseSpace size={0}>
                        <BaseRow
                          gutter={[16, 8]}
                          align={'middle'}
                          className="header-content"
                          justify={'space-between'}
                        >
                          <BaseCol xl={24}>
                            <BaseText
                              children={t(R.strings.buy_service)}
                              fontWeight="semibold"
                            />
                          </BaseCol>
                        </BaseRow>

                        <BaseSpace
                          className="list-item-content-wrapper"
                          split={
                            <BaseDivider
                              style={{
                                margin: 0,
                              }}
                              dashed
                            >
                              <BaseText
                                children={'and'}
                                style={{
                                  color: '#858C94',
                                }}
                              />
                            </BaseDivider>
                          }
                        >
                          {item?.selectedTreatment?.length ? (
                            item?.selectedTreatment?.map((itm, idx) => {
                              return (
                                <BaseRow
                                  key={idx}
                                  align={'middle'}
                                  gutter={[16, 8]}
                                  justify={'space-between'}
                                >
                                  <BaseCol xl={24}>
                                    <ProfileServiceComponent itm={itm} />
                                  </BaseCol>
                                </BaseRow>
                              )
                            })
                          ) : (
                            <BaseText
                              children={t(R.strings.empty_list)}
                              fontWeight="medium"
                              opacity="0.5"
                            />
                          )}
                        </BaseSpace>
                      </BaseSpace>
                    ),
                  },
                ]}
                expandIconPosition="right"
                key={index}
              />
            )
          })
        ) : (
          <BaseEmpty />
        )}
      </ContentWrapper>
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
`

const HeaderWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
  border-start-start-radius: ${BORDER_RADIUS};
`

const ContentWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
  background-color: #f9fafb;
  border-start-end-radius: ${BORDER_RADIUS};
  .dash-line {
    border-top: 1px dashed ${convertedVariables.neutralBlack2Color};
  }
`
const CollapseWrapper = styled(BaseCollapse)`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};

  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-content {
    border-top: 1px solid ${convertedVariables.neutralBlack9Color};
    background-color: ${convertedVariables.neutralBlack9Color};
    .ant-collapse-content-box {
      padding: 0px !important;
    }
  }

  .ant-collapse-header {
    background-color: var(--background-color);
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background-color: #f9fafb;
  }

  .list-item-content-wrapper {
    padding: ${PADDING.md};
    background-color: ${convertedVariables.backgroundColor};
  }
`
