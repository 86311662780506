// Message code define
export const ProductPropertyMessageCode = {
  ProductPropertyManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20001',
  },
}

// Endpoint
export const ProductPropertyEndPoint = {
  ProductPropertyManagement: {
    request: 'treatment/v1/product-properties',
  },
}
