import { BaseCol } from '../BaseCol'
import { BaseRow } from '../BaseRow'
import * as S from './styles'
import { To, useNavigate } from 'react-router'

interface ButtonUpdateProps {
  title?: string
  path?: To
  onClick?: () => void
}

export const ButtonUpdate: React.FC<ButtonUpdateProps> = ({
  title,
  path,
  onClick,
}) => {
  const navigate = useNavigate()
  const onNavigate = (path?: To) => {
    if (path) {
      navigate(path)
    }
  }
  return (
    <BaseCol onClick={() => onClick || onNavigate(path)}>
      <S.ButtonUpdateAction>
        <BaseRow gutter={8}>
          <BaseCol>{title}</BaseCol>
        </BaseRow>
      </S.ButtonUpdateAction>
    </BaseCol>
  )
}
