import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import styled from 'styled-components'
import { useHook } from './hook'
import { FilterLayout } from './layouts'
import { BORDER_RADIUS } from 'parkway-web-common'

const ApproveDayOff = () => {
  const {
    columns,
    filter,
    onChangeFilter,
    isLoadingDayOff,
    handleTableChange,
    pagination,
    data,
  } = useHook()
  return (
    <S.BaseManagementWrapper>
      <HeaderPage titleI18nKey={R.strings.strategic_doctor} />
      <RootWrapper>
        <FilterLayout filter={filter} onChange={onChangeFilter} />
        <BaseTableWrapper
          columns={columns}
          dataSource={data}
          rowClassName="row-overwrite-style"
          pagination={pagination}
          loading={isLoadingDayOff}
          onChange={handleTableChange}
        />
      </RootWrapper>
    </S.BaseManagementWrapper>
  )
}

export default ApproveDayOff

const RootWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
`

const BaseTableWrapper = styled(BaseTableManagement)`
  tr.ant-table-expanded-row > td {
    background: ${convertedVariables.backgroundColor};
  }

  tr.ant-table-expanded-row:hover > td {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell {
    border-bottom: 0.5px solid ${convertedVariables.neutralBlack2Color} !important;
  }

  .all-column:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell-row-hover:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor} !important;
  }

  .child-column {
    color: rgba(0, 34, 68, 0.4) !important;
  }

  .important-column {
    color: ${convertedVariables.statesRedColor} !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-radius: 0px !important;
  }

  .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
    margin-inline: 0px !important;
  }

  .ant-table-expanded-row-fixed {
    padding: 0px !important;
  }

  .ant-table-thead > tr > th {
    color: rgba(0, 34, 68, 0.7) !important;
    font-weight: 600 !important;
    text-align: center !important;
  }

  .date-column {
    min-width: 300px !important;
  }

  .ant-table-tbody {
    .date-column {
      padding: 0px !important;
    }
  }
`
