/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestDetailDegreeManagement,
  requestUpdateDegreeManagement,
} from 'app/api/degree'
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IFormData,
  IFormRule,
  IPropsUpdateDegree,
  StatusDegreeEnum,
} from './type'
import {
  IIndustry,
  IPayloadUpdateDegreeManagement,
  IDegree,
} from 'app/api/degree/model/management'
import { DegreeMessageCode } from 'app/api/degree/constant'
import { requestGetAllIndustryManagement } from 'app/api/industry'
import { IndustryMessageCode } from 'app/api/industry/constant'
import {
  convertArrayStatusFilterToArrayStatusNumber,
  convertStatusToFilterStatus,
} from 'app/common/helpers'

export const useModalHook = (props: IPropsUpdateDegree) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormData>()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [industries, setIndustry] = useState<IIndustry[]>()

  const getDetail = async () => {
    if (!props?.id) {
      return
    }
    try {
      const res: ResponseType<IDegree> = await requestDetailDegreeManagement(
        props?.id,
      )

      if (
        isEqual(res?.msgcode, DegreeMessageCode.DegreeManagement.successGetData)
      ) {
        const data = res?.data

        form.setFieldsValue({
          _id: data._id,
          name: data?.name,
          industry: data?.industry,
          status: convertStatusToFilterStatus(data?.status),
        })
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  useEffect(() => {
    visible && getDetail()
  }, [props?.id, visible])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)
    try {
      const body: IPayloadUpdateDegreeManagement = {
        _id: values?._id,
        name: values?.name,
        industry: values?.industry,
        status:
          convertArrayStatusFilterToArrayStatusNumber([
            values?.status,
          ] as any)?.[0] ?? StatusDegreeEnum.active,
      }

      const response: ResponseType<string> =
        await requestUpdateDegreeManagement({
          id: props?.id,
          body,
        })

      if (
        isEqual(
          response?.msgcode,
          DegreeMessageCode.DegreeManagement.successPutData,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        props?.fetchTable?.()
        notificationController.success({
          message: t(R.strings.update),
          description: response?.message,
        })
      } else {
        notificationController.error({
          message: t(R.strings.update),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const getAllIndustry = async () => {
    try {
      const res: ResponseType<IIndustry[]> =
        await requestGetAllIndustryManagement()

      if (
        isEqual(
          res?.msgcode,
          IndustryMessageCode.IndustryManagement.successGetData,
        )
      ) {
        const data: IIndustry[] = res?.data || []
        setIndustry(data)
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  useEffect(() => {
    visible && getAllIndustry()
  }, [visible])

  const rules: IFormRule = useMemo(() => {
    return {
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.degree_field_name),
          }),
        },
      ],
      industry: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.degree_field_industry),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    industries,
  }
}
