/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetReport06 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { IResponseGetReport06 } from 'app/api/report/model/report-06'
import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { initFilterTable } from 'app/hook'
import { isEqual } from 'lodash'
import {
  DateUtil,
  FORMAT_CENTER_YYYY_MM_DD,
  convertPercent,
  initialPagination,
  moment
} from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { convertDataToTable, useCommonHook } from './common-hook'
import { IDataReport006 } from './data'

export const useTableHook = () => {
  const [filterResponse, setFilterResponse] = useState<IResponseFilter>(
    initFilterTable(),
  )

  const [listServiceName, setListServiceName] = useState<string[]>([])

  const params = useMemo(() => {
    const { year, month, week, selectFilterType } = filterResponse

    if (selectFilterType === 'WEEK') {
      return {
        startDate: DateUtil.formatDate(
          week?.startDate ?? '',
          FORMAT_CENTER_YYYY_MM_DD,
        ),
        endDate: DateUtil.formatDate(
          week?.endDate ?? '',
          FORMAT_CENTER_YYYY_MM_DD,
        ),
      }
    }

    const momentOfMonth = moment(`${year}-${month}-01`)

    return {
      startDate: DateUtil.formatDate(
        momentOfMonth?.startOf('month').toDate(),
        FORMAT_CENTER_YYYY_MM_DD,
      ),
      endDate: DateUtil.formatDate(
        momentOfMonth?.endOf('month').toDate(),
        FORMAT_CENTER_YYYY_MM_DD,
      ),
    }
  }, [filterResponse])

  const handleChangeFilter = (filter: IResponseFilter) => {
    setFilterResponse(filter)
  }

  const [tableData, setTableData] = useState<{
    data: any[]
    loading: boolean
  }>({
    data: [],
    loading: false,
  })

  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

  const handleExpand = (expanded: boolean, newKey?: number) => {
    if (!newKey) return

    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const getDataWeek = async () => {
    const initData = {
      pagination: initialPagination,
      data: [],
    } as IDataBaseTable<IDataReport006>
    try {
      const response: IResponseGetReport06 = await requestGetReport06(params)
      if (
        isEqual(response?.msgcode, ReportMessageCode.Report06.successGetData)
      ) {
        const data = response?.data?.rows ?? []
        let dataTable: IDataReport006[] = []

        data.forEach((area, index) => {
          const [firstClinicOfArea, ...otherClinicOfArea] = area?.children ?? []

          const dataOfFirstClinic = convertDataToTable(
            firstClinicOfArea?.products ?? [],
            'normal',
          )

          const dataOfArea = convertDataToTable(area?.products ?? [], 'total')

          const saveData: IDataReport006 = {
            key: index + 1,
            area: area?.areName,
            clinic: firstClinicOfArea?.name,
            ...dataOfFirstClinic,
            ...dataOfArea,
            totalRevenueActual: firstClinicOfArea?.totalActual,
            totalRevenuePlan: firstClinicOfArea?.budgetPlan,
            percentTargetComplete: firstClinicOfArea?.targetPercent,
            totalRevenueActualTotal: area?.totalActual,
            totalRevenuePlanTotal: area?.budgetPlan,
            percentTargetCompleteTotal: area.targetPercent,
            children: otherClinicOfArea?.map((clinic, idx) => {
              const dataOfClinic = convertDataToTable(
                clinic?.products ?? [],
                'normal',
              )
              return {
                key: Number((index + 1) * 100 + idx),
                clinic: clinic?.name,
                parentKey: index + 1,
                index: idx + 1,
                ...dataOfClinic,
                totalRevenueActual: clinic?.totalActual,
                totalRevenuePlan: clinic?.budgetPlan,
                percentTargetComplete: clinic?.targetPercent,
              }
            }),
          }
          dataTable = dataTable.concat([saveData])
        })

        const metadata = response?.data?.metadata?.total

        const dataTableOfMetadata = convertDataToTable(
          metadata?.products ?? [],
          'normal',
        )

        setListServiceName(
          metadata?.products?.map(item => item?.treatmentgroupName ?? '') ?? [],
        )

        const dataOfTable: IDataReport006 = {
          area: 'Tổng',
          key: dataTable?.length + 1,
          isTotalTable: true,
          totalRevenueActual: metadata?.totalActual,
          totalRevenuePlan: metadata?.budgetPlan,
          percentTargetComplete: convertPercent(
            metadata?.totalActual,
            metadata?.budgetPlan,
          ),
          ...dataTableOfMetadata,
        }

        return {
          ...initData,
          data: [...dataTable, dataOfTable],
        }
      }
      return initData
    } catch (error) {
      return initData
    }
  }

  const fetchLoading = (isLoading: boolean) => {
    setTableData(prev => ({
      ...prev,
      loading: isLoading,
    }))
  }

  const fetchData = async () => {
    setTableData(tableData => {
      return { ...tableData, loading: true }
    })
    const res = await getDataWeek()

    setTableData({
      data: res?.data,
      loading: false,
    })
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchData()
    }, 650)

    return () => clearTimeout(timer)
  }, [params])

  const { columns, exportExcel } = useCommonHook({
    expandedRowKeys,
    typeHook: isEqual(filterResponse?.selectFilterType, 'MONTH')
      ? 'month'
      : 'week',
    handleExpand,
    tableData,
    fetchLoading,
    currentFilter: params,
    listServiceName,
  })

  return {
    columns,
    tableData,
    handleExpand,
    expandedRowKeys,
    handleChangeFilter,
    filterResponse,
    exportExcel,
  }
}
