import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { DetailVoucherProvider } from './context'
import { ContentLayout } from './content'

const DetailVoucherLayout = () => {
  return (
    <DetailVoucherProvider>
      <S.BaseManagementWrapper>
        <HeaderPage titleI18nKey={R.strings.detail_voucher} />
        <ContentLayout />
      </S.BaseManagementWrapper>
    </DetailVoucherProvider>
  )
}

export default DetailVoucherLayout
