import {
  DiscountTypeEnum,
  IDataUpdateCampaign,
} from 'app/api/marketing/discount-campaign/model'
import { isEqual } from 'lodash'
import { useMemo, useState } from 'react'
import { IResponseImportFile } from '../RootLayout/Contents/layouts/TreatmentTypeLayout/layouts/ImportModal/type'
import { ITreatmentInTable } from '../type'

export const useTreatmentTypeHook = () => {
  const [initService, setInitService] = useState<ITreatmentInTable[]>([])
  const [listService, setListService] = useState<ITreatmentInTable[]>([])

  const [updateList, setUpdateList] = useState<ITreatmentInTable[]>([])
  const [deleteList, setDeleteList] = useState<ITreatmentInTable[]>([])
  const [addList, setAddList] = useState<ITreatmentInTable[]>([])

  const onChangeUpdateList = (data: ITreatmentInTable) => {
    if (initService.find(item => isEqual(item?._id, data?._id))) {
      if (updateList.find(item => isEqual(item?._id, data?._id))) {
        const newUpdateList = updateList.map(item => {
          if (isEqual(item?._id, data?._id)) {
            return data
          }
          return item
        })
        setUpdateList(newUpdateList)
      } else {
        setUpdateList([...updateList, data])
      }
    }
  }

  const onChangeAddList = (data: ITreatmentInTable) => {
    if (initService.find(item => isEqual(item?._id, data?._id))) {
      return
    }

    if (addList.find(item => isEqual(item?._id, data?._id))) {
      const newAddList = addList.map(item => {
        if (isEqual(item?._id, data?._id)) {
          return data
        }
        return item
      })
      setAddList(newAddList)
    } else {
      setAddList([...addList, data])
    }
  }

  const onChangeDiscountValue = (value: number, id?: string) => {
    const itemUpdate = listService.find(item => isEqual(item?._id, id))

    if (!itemUpdate) return

    const newListService = listService.map(item => {
      if (isEqual(item?._id, id)) {
        return {
          ...item,
          discountValue: value,
        }
      }
      return item
    })

    onChangeUpdateList({
      ...itemUpdate,
      discountValue: value,
    })
    onChangeAddList({
      ...itemUpdate,
      discountValue: value,
    })
    setListService(newListService)
  }

  const onInitListService = (data: ITreatmentInTable[]) => {
    setListService(data)
    setInitService(data)
    setAddList([])
    setDeleteList([])
    setUpdateList([])
  }

  const onChangeDiscountType = (value: DiscountTypeEnum, id?: string) => {
    const itemUpdate = listService.find(item => isEqual(item?._id, id))

    if (!itemUpdate) return
    const newListService = listService.map(item => {
      if (isEqual(item?._id, id)) {
        return {
          ...item,
          discountType: value,
        }
      }
      return item
    })
    onChangeUpdateList({
      ...itemUpdate,
      discountType: value,
    })
    onChangeAddList({
      ...itemUpdate,
      discountType: value,
    })
    setListService(newListService)
  }

  const onChangeService = (services: ITreatmentInTable[]) => {
    // lấy item đã bị xoá nhưng được thêm mới vào
    const newUpdateList = deleteList.filter(item =>
      services.some(initItem => isEqual(initItem?._id, item?._id)),
    )

    // lấy danh sách được thêm mới vào
    const newAddList = services.filter(
      item => !initService.some(initItem => isEqual(initItem?._id, item?._id)),
    )

    // lấy danh sách bị xóa đi khỏi danh sách ban đầu
    const newDeleteList = initService.filter(
      item => !services.some(newItem => isEqual(newItem?._id, item?._id)),
    )

    setListService(
      () => {
        const newService = services?.filter((item) => !newAddList.some(initItem => isEqual(initItem?._id, item?._id)))?.map(item => {
          const itemUpdate = newUpdateList.find(updateItem =>
            isEqual(updateItem?._id, item?._id),
          )
  
          return itemUpdate ?? item
        })

        return newAddList.concat(newService)
      }
    )

    setAddList(newAddList)
    setDeleteList(newDeleteList)
  }

  const onDeleteService = (id?: string) => {
    // check xem item có trong danh sách ban đầu không, nếu có thì thêm nó vào danh sách xóa
    const itemInit = initService.find(item => isEqual(item?._id, id))

    if (itemInit) {
      setDeleteList([...deleteList, itemInit])
    }

    // check xem item có trong danh sách thêm mới không, nếu có thì xóa nó đi
    const newAddList = addList.filter(item => !isEqual(item?._id, id))
    setAddList(newAddList)

    // check xem item có trong danh sách cập nhật không, nếu có thì xóa nó đi
    const newUpdateList = updateList.filter(item => !isEqual(item?._id, id))
    setUpdateList(newUpdateList)

    const newListService = listService.filter(item => !isEqual(item?._id, id))
    setListService(newListService)
  }

  const dataUpdate: IDataUpdateCampaign = useMemo(() => {
    const payload: IDataUpdateCampaign = {
      push: addList.map(item => {
        return {
          treatment: item?._id,
          amount: 1,
          dType: item?.discountType,
          discount: item?.discountValue,
        }
      }),
      removes: deleteList.map(item => item?.parentId ?? ''),
      updates: updateList.map(item => {
        return {
          _id: item?.parentId,
          treatment: item?._id,
          amount: 1,
          dType: item?.discountType,
          discount: item?.discountValue,
        }
      }),
    }
    return payload
  }, [updateList, deleteList, addList])

  const handleImportTreatment = (data: IResponseImportFile) => {
    const listDelete: ITreatmentInTable[] = data?.remove?.map(item => ({
      ...item,
      discountType: item?.dType,
      discountValue: +(item?.discount ?? '0'),
      _id: item?.treatmentId,
      parentId: item?._id,
    }))

    const listUpdate: ITreatmentInTable[] = data?.modify?.map(item => ({
      ...item,
      discountType: item?.dType,
      discountValue: +(item?.discount ?? '0'),
      _id: item?.treatmentId,
      parentId: item?._id,
    }))

    const listAdd: ITreatmentInTable[] = data?.insert?.map(item => ({
      ...item,
      discountType: item?.dType,
      discountValue: +(item?.discount ?? '0'),
      _id: item?.treatmentId,
    }))

    let newListService = initService
      // xoá item đã bị xoá khỏi danh sách
      .filter(
        item =>
          !listDelete.some(deleteItem => isEqual(deleteItem?._id, item?._id)),
      )
      // thay thế item cũ bằng item mới
      .map(item => {
        const updateItem = listUpdate.find(update =>
          isEqual(update?._id, item?._id),
        )
        return updateItem ?? item
      })
    // thêm list item mới
    newListService = addList.concat(newListService)

    setListService(newListService)
    setUpdateList(listUpdate)
    setDeleteList(listDelete)
    setAddList(listAdd)
  }

  return {
    onChangeService,
    listService,
    onChangeDiscountType,
    onChangeDiscountValue,
    onDeleteService,
    onInitListService,
    dataUpdate,
    initService,
    deleteList,
    updateList,
    addList,
    handleImportTreatment,
  }
}
