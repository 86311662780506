import { FormInstance } from 'antd/lib'
import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseTextEditor from 'app/components/common/BaseTextEditor'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { BaseTreeSelect } from 'app/components/common/selects/BaseTreeSelect'
import { transformDataSelectTree } from 'app/components/common/selects/BaseTreeSelect/helper'
import { LevelSkillItem } from 'app/containers/Accounting/ItemManagement/components/LevelSkillItem'
import { mapColorWithFirstCharacter } from 'app/hook'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useCreateUpdateServiceContext } from '../../../../context'
import { IFormData } from '../../../../type'
import { AddJobTitleDoctor } from '../../../../components/AddJobTitleDoctor'
import { useHook } from './hook'
import * as S from './styles'
import { StatusCreateEnum } from './type'

export const InfoServiceLayout = ({ form }: { form?: FormInstance<IFormData> }) => {
  const {
    categoryTreatments,
    itemBrands,
    isLoadingCategoryTreatment,
    isLoadingItemBrand,
    jobTitleSelected,
    jobTitleLevelSelected,
    onChangeJobTitle,
    onDeleteJobTitle,
  } = useHook({ form })

  const { rules } = useCreateUpdateServiceContext()

  const { t } = useTranslation()
  const treeData = transformDataSelectTree(categoryTreatments, {
    title: 'name',
    value: '_id',
  })

  return (
    <S.RootWrapper direction="vertical" size={10}>
      <S.TitleInfoService>
        {t(R.strings.item_management_content_info_service)}
      </S.TitleInfoService>

      <BaseRow justify={'space-between'} align={'middle'} gutter={[10, 10]}>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={t(R.strings.item_management_content_category)}
            name="categoryid"
            rules={rules?.categoryid}
            required
          >
            <BaseTreeSelect
              placeholder={t(R.strings.item_management_content_select_category)}
              suffixIcon={<SvgTableCloseIcon />}
              loading={isLoadingCategoryTreatment}
              treeData={treeData}
              treeDefaultExpandAll
            />
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={t(R.strings.item_management_content_name_service)}
            name="name"
            rules={rules?.name}
            required
          >
            <BaseInput
              placeholder={t(
                R.strings.item_management_content_enter_name_service,
              )}
            />
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>

      <BaseRow justify={'space-between'} align={'middle'} gutter={[10, 10]}>
        <BaseCol xl={12}>
          <BaseRow gutter={10} align={'middle'}>
            <BaseCol xl={12}>
              <BaseForm.Item
                label={t(R.strings.item_management_content_service_code)}
                name="code"
                rules={rules?.code}
                required
              >
                <BaseInput
                  placeholder={t(
                    R.strings.item_management_content_enter_service_code,
                  )}
                />
              </BaseForm.Item>
            </BaseCol>
            <BaseCol xl={12}>
              <BaseForm.Item
                label={t(R.strings.service_type)}
                name="serviceType"
                rules={rules?.categoryid}
                required
              >
                <BaseTreeSelect
                  placeholder={t(R.strings.choose_service_type)}
                  suffixIcon={<SvgTableCloseIcon />}
                  loading={isLoadingCategoryTreatment}
                  treeData={treeData}
                  treeDefaultExpandAll
                />
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseRow gutter={10} align={'middle'}>
            <BaseCol xl={12}>
              <BaseForm.Item
                label={`${t(
                  R.strings.item_management_content_product_code,
                )} (SKU)`}
                required
                name="productCode"
              >
                <BaseInput
                  placeholder={t(
                    R.strings.item_management_content_enter_product_code,
                  )}
                />
              </BaseForm.Item>
            </BaseCol>
            <BaseCol xl={12}>
              <BaseForm.Item label={t(R.strings.barcode)} name="barcode">
                <BaseInput placeholder={t(R.strings.enter_barcode)} />
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>

      <BaseRow justify={'space-between'} align={'middle'} gutter={[10, 10]}>
        <BaseCol xl={12}>
          <BaseRow gutter={10} align={'middle'}>
            <BaseCol xl={12}>
              <BaseForm.Item
                label={t(R.strings.minimum_one_time_payment)}
                name="minimumOneTimePayment"
                rules={rules?.code}
              >
                <S.InputNumberStyled
                  placeholder={t(R.strings.enter_amount_paid)}
                />
              </BaseForm.Item>
            </BaseCol>
            <BaseCol xl={12}>
              <BaseForm.Item
                label={t(R.strings.minimum_installment_payment_in_clinic)}
                name="minimumInstallmentPaymentInClinic"
                rules={rules?.categoryid}
              >
                <S.InputNumberStyled
                  placeholder={t(R.strings.enter_amount_paid)}
                />
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={`${t(R.strings.end_of_shift_payment)}`}
            name="endOfShiftPayment"
          >
            <S.InputNumberStyled placeholder={t(R.strings.enter_amount_paid)} />
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>

      <BaseRow justify={'space-between'} align={'middle'} gutter={[10, 10]}>
        <BaseCol xl={12}>
          <BaseRow gutter={10} align={'middle'}>
            <BaseCol xl={12}>
              <BaseForm.Item
                label={t(R.strings.item_management_content_purchase_unit)}
                name="purchaseUnit"
                rules={rules?.itemBrandId}
                required
              >
                <BaseSelect
                  placeholder={t(
                    R.strings.item_management_content_select_purchase_unit,
                  )}
                  suffixIcon={<SvgTableCloseIcon />}
                  loading={isLoadingItemBrand}
                >
                  {itemBrands.map((item, index) => {
                    return (
                      <Option value={item._id} key={index}>
                        {item?.name}
                      </Option>
                    )
                  })}
                </BaseSelect>
              </BaseForm.Item>
            </BaseCol>
            <BaseCol xl={12}>
              <BaseForm.Item
                label={t(R.strings.item_management_content_using_unit)}
                name="usingUnit"
                rules={rules?.itemBrandId}
                required
              >
                <BaseSelect
                  placeholder={t(
                    R.strings.item_management_content_select_using_unit,
                  )}
                  suffixIcon={<SvgTableCloseIcon />}
                  loading={isLoadingItemBrand}
                >
                  {itemBrands.map((item, index) => {
                    return (
                      <Option value={item._id} key={index}>
                        {item?.name}
                      </Option>
                    )
                  })}
                </BaseSelect>
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={t(R.strings.item_management_content_producer)}
            name="itemBrandId"
            rules={rules?.itemBrandId}
            required
          >
            <BaseSelect
              placeholder={t(R.strings.item_management_content_select_producer)}
              suffixIcon={<SvgTableCloseIcon />}
              loading={isLoadingItemBrand}
            >
              {itemBrands.map((item, index) => {
                return (
                  <Option value={item._id} key={index}>
                    {item?.name}
                  </Option>
                )
              })}
            </BaseSelect>
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>

      <BaseRow justify={'space-between'} align={'middle'} gutter={[10, 10]}>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={t(R.strings.warranty_month)}
            rules={rules?.itemBrandId}
            className="warrantyMonth"
          >
            <S.InputNumberStyled
              placeholder={t(R.strings.enter_warranty_month)}
            />
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={t(R.strings.status)}
            name="status"
            rules={rules?.status}
            required
          >
            <BaseSelect
              placeholder={t(R.strings.please_select_status)}
              suffixIcon={<SvgTableCloseIcon />}
              loading={isLoadingCategoryTreatment}
              defaultValue={StatusCreateEnum.active}
            >
              {Object.values(StatusCreateEnum).map((item, index) => {
                return (
                  <Option value={item} key={index}>
                    {t(item)}
                  </Option>
                )
              })}
            </BaseSelect>
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>
      <BaseForm.Item
        label={t(R.strings.description_service)}
        name="descriptionService"
        required
      >
        <BaseTextEditor placeholder={t(R.strings.description_service)} />
      </BaseForm.Item>

      <BaseForm.Item
        label={t(R.strings.description_warranty)}
        name="descriptionWarranty"
        required
      >
        <BaseTextEditor placeholder={t(R.strings.description_warranty)} />
      </BaseForm.Item>

      <BaseForm.Item
        label={t(
          R.strings.item_management_content_request_job_title_level_doctor,
        )}
        name=" "
      >
        <BaseRow
          gutter={[20, 10]}
          align={'middle'}
        >
          <BaseForm.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return !isEqual(
                prevValues.doctorJobTitleLevelIds,
                currentValues.doctorJobTitleLevelIds,
              )
            }}
          >
            {({ getFieldValue }) => {
              const doctorJobTitleLevelIds =
                getFieldValue('doctorJobTitleLevelIds') ?? []

              return (
                <BaseCol>
                  <BaseRow gutter={[20, 10]} className="flex-test">
                    {doctorJobTitleLevelIds?.map((item, index) => {
                      const color = mapColorWithFirstCharacter(
                        item?.jobTitleLevelName?.[0] ?? 'P',
                      )?.color
                      return (
                        <BaseCol key={index}>
                          <LevelSkillItem
                            index={index}
                            color={color}
                            levelSkill={item?.jobTitleLevelName}
                            skill={item?.name ?? item.jobTitleName}
                            onRemove={onDeleteJobTitle}
                            key={index}
                          />
                        </BaseCol>
                      )
                    })}
                  </BaseRow>
                </BaseCol>
              )
            }}
          </BaseForm.Item>
          <AddJobTitleDoctor
            onSubmit={onChangeJobTitle}
            jobTitleSelected={jobTitleSelected}
            jobTitleLevelSelected={jobTitleLevelSelected}
          />
        </BaseRow>
      </BaseForm.Item>
    </S.RootWrapper>
  )
}