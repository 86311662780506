import { Dispatch, SetStateAction } from "react"
import { IAppointment, IPatient } from "../../../type"
import { Moment } from "moment"
import { FormInstance } from "antd"
import { IClinic, IUnit } from "app/model/unit.model"
import { ITreatment } from "app/containers/Accounting/ItemManagement/model/treatment"
import { RangePickerProps } from "antd/es/date-picker"
import { IItemCategory } from "app/api/item-category/model/management"
import { IItem } from "app/api/item/model"
import { IProfile } from "app/api/profile/model/management"

export enum PageType {
  SEARCH_APPOINTMENT = 0,
  APPOINTMENT = 1,
  CALLCENTER = 2,
}

export interface IFormModal {
  handleOk?: ((values) => void) | undefined
  form?: FormInstance
  newAppointment?: IAppointment
  onSelectPatient: React.Dispatch<React.SetStateAction<string[]>>
  onSelectPatientProfile: React.Dispatch<React.SetStateAction<string[]>>
  setSearchPatient: React.Dispatch<React.SetStateAction<string>>
  setPhone: React.Dispatch<React.SetStateAction<string>>
  patients?: IPatient[]
  t?
  handleArea?
  units?: IUnit[]
  clinic?: IClinic[]
  handleCategoryService
  categoryServices?: IItemCategory[]
  setSearchDoctor: React.Dispatch<React.SetStateAction<string>>
  doctors?: IProfile[]
  services?: IItem[]
  disabledDate?: RangePickerProps['disabledDate'] 
  timeslots?: Moment[]
  setNote: React.Dispatch<React.SetStateAction<string>>
  note?: string
  submitButton?
  page?: PageType
  handleService?
  treatments?: ITreatment[]
  onChangeClinic?: Dispatch<SetStateAction<string>>
  preserve?: boolean
  required?: boolean
  patientProfiles?: IPatient[],
  phone?: string
}

export interface IProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  appointment: IAppointment
  setAppointment: Dispatch<SetStateAction<IAppointment>>
  view: string
  page?: number
  footer?
  title?
  isCalling?: boolean
  required?: boolean
}

export interface IRefWithBoundingRect extends HTMLElement {
  getBoundingClientRect: () => DOMRect
}