import { TreeSelect as AntTreeSelect } from 'antd'
import { normalizeProp } from 'app/hook'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { Dimension, FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export interface InternalSelectProps {
  $width?: Dimension
  $shadow?: boolean
}

export const TreeSelect = styled(AntTreeSelect)<InternalSelectProps>`
  width: ${props => props.$width && normalizeProp(props.$width)};

  font-weight: ${FONT_WEIGHT.medium};

  box-shadow: ${props => props.$shadow && 'var(--box-shadow)'};

  .ant-select-tree-treenode {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
    margin-bottom: 0 !important;
  }

  .ant-select-arrow {
    color: var(--text-main-color);
    top: calc(50% - 4px);
    right: 8px;
  }

  .ant-select-selector {
    border: 1px solid ${convertedVariables.neutralBlack2Color} !important;
  }
`
export const Placeholder = styled.div`
  color: var(--main-2-color);
  font-weight: ${FONT_WEIGHT.regular};
  font-size: ${FONT_SIZE.xs};
`
