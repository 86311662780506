import { BaseAvatar } from 'app/components/common/BaseAvatar'
import styled from 'styled-components'

export const ActionHeaderMonth = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--main-color-text);
`

export const ActionHeaderYear = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--main-color-text);
  opacity: 70%;
`

export const ActionHeaderButton = styled.div`
  display: flex;
`

export const ActionHeaderPrevNext = styled.div<{ className?: string }>`
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid var(--revenue-dashboard-border-color);
  gap: 10px;
  ${props => props.className === 'prev' && 'margin-right: 5px;'}
`

export const ActionHeaderPrevNextImg = styled.div`
  text-align: center;
  margin: auto;
  // margin-top: calc(50% - 7px);
`

export const SelectWrapper = styled.div`
  padding: 0px 10px 10px 10px;
`
export const SelectTopWrapper = styled.div`
  padding: 10px;
  border-top: 2px solid var(--revenue-dashboard-border-color);
`

export const DoctorAvatar = styled(BaseAvatar)`
  width: 38px;
  height: 38px;
  flex: none;
  && {
    .ant-avatar-string {
      transform: scale(1) translateX(-50%) translateY(10%) !important;
    }
  }
`