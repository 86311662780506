import R from 'app/assets/R'
import { FunctionPermissionEnum, ModulePermissionEnum } from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { initPagination, URL_DOWNLOAD_EXAMPLE } from 'app/constant'
import { isEqual } from 'lodash'
import moment from 'moment'
import { SelectFilterEnum } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTableMonthlyHook } from './Monthly/hook'
import { useTableWeeklyHook } from './Quarter/hook'

export const useTableHook = () => {
  const { t } = useTranslation()

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.ACCOUNTING,
      FunctionPermissionEnum.REPORT_READ_REPORT_12,
      R.strings.role,
    )
  }, [])

  const [filterResponse, setFilterResponse] = useState<IResponseFilter>({
    year: `${moment().year()}`,
    month: `${moment().month() + 1}`,
  })

  const hookQuarter = useTableWeeklyHook({
    year: filterResponse.year,
  })
  const hookMonthly = useTableMonthlyHook({ year: filterResponse.year })

  const expandedRowKeys = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.expandedRowKeys
      case SelectFilterEnum.MONTH:
        return hookMonthly.expandedRowKeys
      default:
        return hookMonthly.expandedRowKeys
    }
  }, [filterResponse?.selectFilterType, hookQuarter, hookMonthly])

  const tableData = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.tableData
      case SelectFilterEnum.MONTH:
        return hookMonthly.tableData
      default:
        return hookMonthly.tableData
    }
  }, [filterResponse?.selectFilterType, hookQuarter, hookMonthly])

  const handleTableChange = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.handleTableChange
      case SelectFilterEnum.MONTH:
        return hookMonthly.handleTableChange
      default:
        return hookMonthly.handleTableChange
    }
  }, [filterResponse?.selectFilterType, hookQuarter, hookMonthly])

  const handleExpand = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.handleExpand
      case SelectFilterEnum.MONTH:
        return hookMonthly.handleExpand
      default:
        return hookMonthly.handleExpand
    }
  }, [filterResponse?.selectFilterType, hookQuarter, hookMonthly])

  const onClickImport = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.onClickImport
      case SelectFilterEnum.MONTH:
        return hookMonthly.onClickImport
      default:
        return hookMonthly.onClickImport
    }
  }, [filterResponse?.selectFilterType, hookQuarter, hookMonthly])

  const handleChangeFilter = (filter: IResponseFilter) => {
    const { year } = filter
    setFilterResponse(filter)

    if (!isEqual(year, filterResponse?.year)) {
      hookMonthly?.fetch(initPagination, { year })
      hookQuarter?.fetch(initPagination, { year })
    }
  }

  const columns = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.columns
      case SelectFilterEnum.MONTH:
        return hookMonthly.columns
      default:
        return hookMonthly.columns
    }
  }, [t, expandedRowKeys, filterResponse?.selectFilterType])

  const urlDownload = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return {
          url: URL_DOWNLOAD_EXAMPLE.CostQuarterPlan,
          name: 'CostQuarterPlan.xlsx',
        }
      case SelectFilterEnum.MONTH:
        return {
          url: URL_DOWNLOAD_EXAMPLE.CostMonthPlan,
          name: 'CostMonthPlan.xlsx',
        }
      default:
        return {
          url: URL_DOWNLOAD_EXAMPLE.CostQuarterPlan,
          name: 'CostQuarterPlan.xlsx',
        }
    }
  }, [filterResponse?.selectFilterType])

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleChangeFilter,
    filterResponse,
    urlDownload,
    onClickImport,
  }
}
