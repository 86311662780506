import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import moment from 'moment'
import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'

const currentMonth = moment().month() + 1

const ItemInfo = ({
  label,
  type,
  value,
}: {
  label: string
  value?: string
  type: 'normal' | 'success' | 'warning'
}) => {
  return (
    <ItemWrapper justify={'space-between'} align={'middle'}>
      <BaseCol xl={16}>
        <BaseText children={label} opacity="0.7" fontWeight="medium" />
      </BaseCol>

      <BaseCol>
        <BaseRow gutter={0} wrap={false} align={'middle'}>
          <BaseCol className="see-more">
            <BaseRow align={'middle'}>
              <BaseCol>
                <BaseText
                  children={t(R.strings.see)}
                  fontWeight="medium"
                  colorText="collapseBackgroundColor"
                  style={{ cursor: 'pointer' }}
                />
              </BaseCol>
              <BaseCol>
                <BaseDivider type="vertical" />
              </BaseCol>
            </BaseRow>
          </BaseCol>
          <BaseCol>
            <NumberWrapper $type={type}>
              <BaseText
                children={value ?? '-'}
                fontWeight="semibold"
                colorText={
                  isEqual(type, 'success') ? 'primaryColor' : 'backgroundColor'
                }
              />
            </NumberWrapper>
          </BaseCol>
        </BaseRow>
      </BaseCol>
    </ItemWrapper>
  )
}

export const StatisticsDoctorLayout = () => {
  return (
    <SectionBase>
      <BaseSpace>
        <BaseRow justify={'space-between'} align={'middle'} gutter={[15, 10]}>
          <BaseCol>
            <BaseText
              children={t(R.strings.statistic_month_number, {
                month: currentMonth,
              })}
              fontSize="md"
              fontWeight="semibold"
            />
          </BaseCol>
        </BaseRow>

        <BaseRow justify={'space-between'} align={'middle'}>
          <BaseCol xl={20}>
            <BaseText
              children={t(R.strings.closing_rate)}
              opacity="0.7"
              fontWeight="medium"
            />
          </BaseCol>

          <BaseCol>
            <BaseText
              children={`${50}%`}
              fontWeight="semibold"
              colorText="statesGreenColor"
            />
          </BaseCol>
        </BaseRow>

        <BaseRow justify={'space-between'} align={'middle'}>
          <BaseCol />
          <BaseCol xl={20}>
            <BaseText
              children={t(R.strings.rate_close_after_general_examination)}
              opacity="0.5"
            />
          </BaseCol>

          <BaseCol>
            <BaseText children={`${50}%`} opacity="0.5" />
          </BaseCol>
        </BaseRow>

        <BaseRow justify={'space-between'} align={'middle'}>
          <BaseCol />
          <BaseCol xl={20}>
            <BaseText
              children={t(R.strings.close_after_general_examination)}
              opacity="0.5"
            />
          </BaseCol>

          <BaseCol>
            <BaseText children={`${50}%`} opacity="0.5" />
          </BaseCol>
        </BaseRow>

        <BaseRow justify={'space-between'} align={'middle'}>
          <BaseCol />
          <BaseCol xl={20}>
            <BaseText
              children={t(R.strings.close_after_IP_examination)}
              opacity="0.5"
            />
          </BaseCol>

          <BaseCol>
            <BaseText children={`${50}%`} opacity="0.5" />
          </BaseCol>
        </BaseRow>

        <BaseRow justify={'space-between'} align={'middle'}>
          <BaseCol />
          <BaseCol xl={20}>
            <BaseText
              children={t(R.strings.close_after_orthodontic_examination)}
              opacity="0.5"
            />
          </BaseCol>

          <BaseCol>
            <BaseText children={`${50}%`} opacity="0.5" />
          </BaseCol>
        </BaseRow>

        <ItemInfo label={'Niềng răng mắc cài'} type="normal" value="50" />

        <ItemInfo label={t(R.strings.invisalign)} type="normal" value="50" />

        <ItemInfo label={'Tư vấn chỉnh nha'} type="normal" value="50" />
        <ItemInfo label={'Tư vấn tổng quát'} type="normal" value="50" />
        <ItemInfo label={'Số ca quá hạn'} type="warning" value="50" />
        <ItemInfo
          label={'Số bệnh nhân đã điều trị'}
          type="success"
          value="50"
        />
        <BaseRow justify={'end'}>
          <div style={{ cursor: 'pointer' }}>
            <BaseText
              children={t(R.strings.see_all)}
              fontWeight="medium"
              fontSize="xs"
              colorText="collapseBackgroundColor"
            />
          </div>
        </BaseRow>
      </BaseSpace>
    </SectionBase>
  )
}

const NumberWrapper = styled.div<{ $type: 'normal' | 'success' | 'warning' }>`
  background-image: ${props => {
    switch (props?.$type) {
      case 'normal':
        return 'linear-gradient(90deg, #629DAA, #6DAEBD)'
      case 'success':
        return 'linear-gradient(90deg, #F1F1F1, #F1F1F1)'
      case 'warning':
        return 'linear-gradient(90deg, #FD7B59, #F75428)'
    }
  }};
  padding: 5px;
  border-radius: ${PADDING.md};
  width: fit-content;
`

const ItemWrapper = styled(BaseRow)`
  .see-more {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  &:hover .see-more {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
`
