import { ApiClient } from 'app/service/api-service'
import { SurveyEndPoint } from './constant'
import { IParamsGetReportAverageByDay } from './model/management'

export const requestGetReportAverageByDay = (
  params: IParamsGetReportAverageByDay,
) =>
  ApiClient.Get({
    url: `${SurveyEndPoint.SurveyReport.request}/average-by-day`,
    params: { params },
  })

export const requestGetReviewListImprove = (
  params: IParamsGetReportAverageByDay,
) =>
  ApiClient.Get({
    url: `${SurveyEndPoint.SurveyReport.request}/review-list-improve`,
    params: { params },
  })

export const requestGetNPS = (params: IParamsGetReportAverageByDay) =>
  ApiClient.Get({
    url: `${SurveyEndPoint.SurveyReport.request}/nps`,
    params: { params },
  })
