// Message code define
export const UnitGroupMessageCode = {
  UnitGroupManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20001',
  },
}

// Endpoint
export const UnitGroupEndPoint = {
  UnitGroupManagement: {
    request: 'hr/v1/unit-group',
  },
}
