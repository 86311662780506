import { ISkillProfileUser } from 'app/api/profile/model/profile'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { getUserInfo } from 'app/redux/slices/profileSlice'
import 'dayjs/locale/vi'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useModalHook = () => {
  const profile = useAppSelector(state => state.profileSlice.profile)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  const skills = useMemo(() => {
    return groupByIndustry(profile?.skills ?? [])
  }, [profile])

  const mainWorkInfos = useMemo(() => {
    const listMain = profile?.workinfos?.filter(item => item?.isMain) ?? []
    const listActive = listMain.filter(item => item?.status === 1) ?? []

    const listInActive = listMain.filter(item => item?.status !== 1) ?? []

    return {
      listActive,
      listInActive,
    }
  }, [profile])

  const secondsWorkInfos = useMemo(() => {
    const listSecond = profile?.workinfos?.filter(item => !item?.isMain) ?? []
    const listActive = listSecond.filter(item => item?.status === 1) ?? []

    const listInActive = listSecond.filter(item => item?.status !== 1) ?? []

    return {
      listActive,
      listInActive,
    }
  }, [profile])

  return {
    form,
    t,
    skills,
    profile,
    mainWorkInfos,
    secondsWorkInfos,
  }
}

function groupByIndustry(
  data: Array<ISkillProfileUser>,
): Array<{ nameType: string; children: Array<ISkillProfileUser> }> {
  const groups = data.reduce((acc, item) => {
    const group = acc.find(group => group.nameType === item.industry?.name)
    if (group) {
      group.children.push(item)
    } else {
      acc.push({
        nameType: item.industry?.name ?? '-',
        children: [item],
      })
    }
    return acc
  }, [] as Array<{ nameType: string; children: Array<ISkillProfileUser> }>)
  return groups
}
