import React, { useEffect, useState } from 'react'
import type { UploadProps } from 'antd'
import { DRIVE_ACTION, IMAGE_ACTION } from 'app/common/config'
import * as S from './styles'
import { t } from 'i18next'
import R from 'app/assets/R'
import { notificationController } from 'app/controllers/notification-controller'
import { ImageIcon } from 'app/common/icons'

interface IPropsUploadAvatar {
  image?: string // Replace with the actual type of image
  onSetAvatar?: (avatar: string) => void // Replace with the actual type of onSetAvatar
}

const UploadAvatar = ({ image, onSetAvatar }: IPropsUploadAvatar) => {
  const [imageUrl, setImageUrl] = useState<string>(image || '')

  const defaultFileList = [
    {
      uid: '-1',
      name: '',
      thumbUrl: image || imageUrl || IMAGE_ACTION.THUMBNAIL_AVATAR,
      url: image || imageUrl || IMAGE_ACTION.THUMBNAIL_AVATAR,
    },
  ]

  useEffect(() => {
    setImageUrl(image ?? '')
  }, [image?.length])

  const handleChange: UploadProps['onChange'] = info => {
    const status = info.file.status
    if (status === 'error') {
      notificationController.error({ message: 'Uploading image failed' })

      return false
    }

    if (status === 'done') {
      const image = info.file?.response?.data?.imageUrl
      onSetAvatar && onSetAvatar(image)
      setImageUrl(image)
      return true
    }

    return
  }

  const handleRemoveImage = () => {
    onSetAvatar && onSetAvatar('')
    setImageUrl('')
  }

  return (
    <S.UploadAvatar
      name="file"
      listType="picture-circle"
      maxCount={1}
      defaultFileList={defaultFileList}
      action={DRIVE_ACTION.UPLOAD_IMAGE}
      onChange={handleChange}
      multiple={false}
      itemRender={() => {
        return (
          <div className="avatar-container">
            <img src={imageUrl || IMAGE_ACTION.THUMBNAIL_AVATAR} alt="thumb" />

            <S.ButtonRemove
              onClick={() => {
                handleRemoveImage()
              }}
            >
              {t(R.strings.delete)}
            </S.ButtonRemove>
          </div>
        )
      }}
    >
      <S.ButtonUpload icon={<ImageIcon />}>
        {t(R.strings.upload)}
      </S.ButtonUpload>
    </S.UploadAvatar>
  )
}

export default UploadAvatar
