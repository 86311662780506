import { Button } from 'antd'
import { Collapse } from 'antd/lib'
import { DeleteIcon } from 'app/common/icons'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const Title = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
`

export const CollapseStyled = styled(Collapse)`
  background-color: var(--white);
  border: 1px solid #f4f6f9;

  .ant-collapse-item {
    border: 1px solid #f4f6f9;
  }

  .ant-collapse-header {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;

    .ant-collapse-header-text {
      width: calc(100% - 2rem);
    }
  }

  .ant-collapse-content {
    border: 1px solid #f4f6f9;
  }
`

export const CollapseActionGroup = styled.div`
  display: flex;
  align-items: center;

  .action-item {
    padding-left: 16px;
    padding-right: 16px;

    border-left: 1px solid var(--neutral-black-2-color);

    span {
      font-size: ${FONT_SIZE.xs};
      font-weight: ${FONT_WEIGHT.medium};
    }
  }

  .action-item:first-child {
    padding-left: 0;
    border-left: unset;
  }

  .action-item:last-child {
    padding-right: 0;
  }
`

export const Property = styled.div`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.semibold};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .property {
    display: flex;
    flex-direction: column;
    gap: 8px;

    ul {
      display: flex;
      gap: 10px;

      li {
        font-size: ${FONT_SIZE.xxs};
        list-style: none;
        padding: 4px 12px;
        border-radius: 20px;
        background-color: var(--neutral-black-16-color);
      }
    }
  }
`

export const PropertySelect = styled.div`
  display: flex;
  flex-direction: column;

  .property-product-select {
    border: 1px solid #f4f6f9;
    width: 100%;
    margin-bottom: 0;
    padding: 12px 16px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .icon-remove {
    cursor: pointer;
    margin-top: 8px;
  }
`

export const PropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .property-product {
    &__item {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__button-group {
      display: flex;
      gap: 8px;
      padding: 12px 16px;
      justify-content: flex-end;

      background-color: #f9fafb;
      border: 1px solid #f4f6f9;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
`

export const ProductPropertyValue = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .property-product-value {
    margin-bottom: 0;
    flex: 1;
  }
`

export const ButtonStyled = styled(Button)``

export const ButtonAdd = styled(Button)`
  display: flex;
  align-items: center;
  border: unset;
  box-shadow: unset;
  color: #216ae2;
  border-color: #216ae2;

  &:hover {
    color: #216ae2 !important;
    border-color: #216ae2 !important;
  }
`

export const ButtonDelete = styled(DeleteIcon)`
  cursor: pointer;
`

export const ButtonEdit = styled(Button)`
  cursor: pointer;
  display: flex;
  padding-top: 17px;
  padding-bottom: 17px;
  align-items: center;

  svg {
    color: var(--white);
  }

  &:hover {
    color: var(--main-2-color) !important;
    border-color: var(--neutral-black-2-color) !important;
  }
`

export const PropertySelectStyled = styled.div``
