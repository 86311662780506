import TextArea from 'antd/es/input/TextArea'
import R from 'app/assets/R'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import DividerLine from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/DividerLine'
import { TagStatusComponent } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/components'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TableWrapper } from '../../styles'
import { BaseButton } from 'app/components/common/BaseButton'
import { PlusOutlined } from '@ant-design/icons'

const ItemTherapeutic = () => {
  const { t } = useTranslation()
  return (
    <TableWrapper>
      <BaseRow
        className="child-wrapper"
        justify={'space-between'}
        align={'middle'}
      >
        <BaseCol>
          <BaseText children={'Vấn đề 1'} fontWeight="medium" fontSize="xs" />
        </BaseCol>
        <BaseCol>
          <BaseRow gutter={[20, 10]}>
            <BaseCol>
              <TagStatusComponent
                textKeyI18n={R.strings.under_treatment}
                color={convertedVariables.statesGreenColor}
                backgroundColor={convertedVariables.statesGreenLightColor}
              />
            </BaseCol>
            <SvgDeleteDesignIcon />
          </BaseRow>
        </BaseCol>
      </BaseRow>
      <DividerLine />
      <ContentWrapper className="child-wrapper">
        <BaseText
          children={t(R.strings.content)}
          fontWeight="semibold"
          opacity="0.7"
        />
        <TextArea placeholder={t(R.strings.enter_content)} rows={3} />
        <BaseText
          children={t(R.strings.note)}
          fontWeight="semibold"
          opacity="0.7"
        />
        <TextArea placeholder={t(R.strings.enter_note)} rows={1} />
      </ContentWrapper>
    </TableWrapper>
  )
}

export const TherapeuticLayout = () => {
  const { t } = useTranslation()
  return (
    <BaseSpace>
      <BaseText
        children={`${t(R.strings.part_number, { part: 2 })}. ${t(
          R.strings.therapeutic,
        )}`}
        fontWeight="semibold"
        fontSize="xs"
      />
      <ItemTherapeutic />

      <BaseButton
        icon={
          <PlusOutlined
            rev={undefined}
            color={convertedVariables.otherBlueColor}
          />
        }
        children={t(R.strings.additional_troubleshooting)}
        type="link"
      />
    </BaseSpace>
  )
}

const ContentWrapper = styled(BaseSpace)`
  background-color: #fbfbfb; // need change
`
