import { BaseCol } from 'app/components/common/BaseCol'
import { get, isEqual } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { SearchDropdown } from './search-dropdown'
import * as S from './styles'
import {
  IFilterProps,
  IResponseFilter,
  initResponseFilterTableManagement,
} from './type'
import { useTranslation } from 'react-i18next'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { UnitContext } from 'app/context/UnitContext'
import R from 'app/assets/R'
import { Option } from 'app/components/common/selects/BaseSelect'
import { DefineStatusWithoutDeleted } from 'app/common/constants'

export const FilterManagement = ({
  handleChangeFilter,
  filterData,
}: IFilterProps) => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const { units } = useContext(UnitContext)

  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const [isOverlayOpen, setOverlayOpen] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      handleChangeFilter?.(filter)
    }, 500)
    return () => clearTimeout(timer)
  }, [filter])

  useEffect(() => {
    if (filterData && !isEqual(filterData, filter)) {
      setFilter(filterData)
    }
  }, [filterData])

  useEffect(() => {
    setOverlayOpen(false)
  }, [pathname])

  const onChangeSearch = (search: string) => {
    setFilter(prev => {
      return {
        ...prev,
        search,
      }
    })
  }

  const clinics = units?.map(unit => get(unit, 'childs', [])).flat(Infinity)
  return (
    <S.HeadWrapper gutter={20} align={'middle'}>
      <BaseCol>
        <SearchDropdown
          query={filter?.search ?? ''}
          setQuery={onChangeSearch}
          data={[]}
          isOverlayOpen={isOverlayOpen}
          setOverlayOpen={setOverlayOpen}
        />
      </BaseCol>
      <BaseCol>
        <S.SelectBaseStyled
          placeholder={t(R.strings.report_14_content_all_branch)}
          suffixIcon={<SvgTableCloseIcon />}
          allowClear
          onChange={value => setFilter(prev => ({ ...prev, unitId: value }))}
          showSearch
          options={
            clinics?.map(item => ({
              value: get(item, '_id'),
              label: get(item, 'name'),
            })) || []
          }
        />
      </BaseCol>
      <BaseCol>
        <S.SelectBaseStyled
          placeholder={t(R.strings.all_status)}
          suffixIcon={<SvgTableCloseIcon />}
          allowClear
          onChange={value =>
            setFilter(prev => ({ ...prev, status: Number(value) || undefined }))
          }
        >
          {Object.entries(DefineStatusWithoutDeleted).map(([key, value]) => {
            return (
              <Option value={Number(key)} key={key}>
                {t(value)}
              </Option>
            )
          })}
        </S.SelectBaseStyled>
      </BaseCol>
    </S.HeadWrapper>
  )
}
