import { ApiClientAccount } from 'app/service/api-service'
import {
  IParamsGetAccountManagement,
  IPayloadChangePassword,
  IPayloadCreateAccountManagement,
  IPayloadUpdateAccountManagement,
} from './model/account-management'
import { HumanEndPoint } from './constant'

export const requestGetAccountManagement = (
  params: IParamsGetAccountManagement,
) =>
  ApiClientAccount.Get({
    url: HumanEndPoint.AccountManagement.request,
    params: { params },
  })

export const requestChangePasswordManagement = ({
  id,
  body,
}: {
  id: string
  body?: IPayloadChangePassword
}) =>
  ApiClientAccount.Patch({
    url: `${HumanEndPoint.AccountManagement.requestChangePassword.replace(
      ':id',
      id,
    )}`,
    body: body,
  })

export const requestCreateAccountManagement = (
  body: IPayloadCreateAccountManagement,
) =>
  ApiClientAccount.Post({
    url: HumanEndPoint.AccountManagement.request,
    body,
  })

export const requestUpdateAccountManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateAccountManagement
}) =>
  ApiClientAccount.Put({
    url: `${HumanEndPoint.AccountManagement.request}/${id}`,
    body,
  })

export const requestDetailAccountManagement = (id?: string | number) =>
  ApiClientAccount.Get({
    url: `${HumanEndPoint.AccountManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteAccountManagement = (id?: string | number) =>
  ApiClientAccount.Delete({
    url: `${HumanEndPoint.AccountManagement.request}/${id}`,
    body: {},
  })

export const requestGetAllAccountManagement = () =>
ApiClientAccount.Get({
    url: `${HumanEndPoint.AccountManagement.request}/get-all`,
    params: {},
  })
