import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { DateUtil } from 'parkway-web-common'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  colorDot?: string
  timeString?: string
  labelKeyI18n: string
  content?: ReactNode
  actionInHead?: ReactNode
}

export const BasicRecord: React.FC<IProps> = ({
  colorDot,
  timeString = DateUtil?.formatDate(new Date(), 'HH:ss'),
  labelKeyI18n,
  content,
  actionInHead,
}) => {
  const { t } = useTranslation()
  return (
    <BaseSpace size={10}>
      <BaseRow gutter={12} align={'middle'}>
        <BaseCol>
          <DotWrapper $color={colorDot} />
        </BaseCol>
        <BaseCol xl={1}>
          <BaseText children={timeString} opacity="0.5" fontWeight="medium" />
        </BaseCol>
        <BaseCol xl={22}>
          <BaseRow
            align={'middle'}
            gutter={[8, 8]}
            justify={'space-between'}
            wrap={false}
          >
            <BaseCol>
              <BaseText
                children={t(labelKeyI18n)}
                fontWeight="semibold"
                opacity="0.7"
              />
            </BaseCol>
            <BaseCol>{actionInHead}</BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>

      {content ? <BaseRow gutter={12} align={'middle'}>
        <BaseCol>
          <div style={{ width: 6, height: 6}} />
        </BaseCol>
        <BaseCol xl={1} />
        <BaseCol xl={22}>{content}</BaseCol>
      </BaseRow> : null}
    </BaseSpace>
  )
}

const DotWrapper = styled.div<{ $color?: string }>`
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: ${props =>
    props?.$color ?? convertedVariables?.statesGreenColor};
`
