// Message code define
export const DegreeMessageCode = {
  DegreeManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20001',
  },
}

// Endpoint
export const DegreeEndPoint = {
  DegreeManagement: {
    request: 'hr/v1/degree',
  },
}
