import { Rule } from 'antd/es/form'

export interface IFormData {
  password?: string
  re_password?: string
}

export interface IFormRule {
  password?: Rule[]
  re_password?: Rule[]
}

export const initValues: IFormData = {
  password: '',
  re_password: '',
}

export enum StatusEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
  deleted = 'deleted',
}

export type StatusAccountType = keyof typeof StatusEnum

export interface IPropsChangePassword {
  id?: string
}
