import { useParams } from 'react-router'
import { CreateUpdateProvider } from './context'

import { TypeItemEnum } from '../common-model'
import { ComboLayout, DefaultLayout, ServiceLayout } from './layouts'

interface IProps {
  type: 'create' | 'update'
}

const CreateUpdateProductServiceManagement = ({ type }: IProps) => {
  const { type: typeParam } = useParams()

  const Children = props => {
    switch (typeParam) {
      case TypeItemEnum.supplies:
      case TypeItemEnum.asset:
      case TypeItemEnum.tools:
      case TypeItemEnum.product:
        return <DefaultLayout typeAction={type} {...props} />

      case TypeItemEnum.combo:
        return <ComboLayout typeAction={type} {...props} />

      case TypeItemEnum.service:
        return <ServiceLayout typeAction={type} {...props} />

      default:
        return <ServiceLayout typeAction={type} {...props} />
    }
  }
  return (
    <CreateUpdateProvider>
      <Children />
    </CreateUpdateProvider>
  )
}

export default CreateUpdateProductServiceManagement
