export interface INewDashboard {
  id: number
  title: string
  date: Date | string
}

export const dataNewDashboard: INewDashboard[] = [
  {
    id: 1,
    title:
      'Nha khoa Parkway tưng bừng khai trương chi nhánh 3/2 Nha khoa Parkway tưng bừng khai trương chi nhánh 3/2 ',
    date: new Date(),
  },
  {
    id: 2,
    title:
      'Nha khoa Parkway tưng bừng khai trương chi nhánh 3/2 Nha khoa Parkway tưng bừng khai trương chi nhánh 3/2 ',
    date: new Date(),
  },
]
