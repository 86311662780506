import React from 'react'
import { TableProps } from 'antd'
import * as S from './styles'

export type BaseTableProps<T> = TableProps<T>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BaseTable: React.FC<BaseTableProps<any>> = props => {
  return <S.Table {...props} />
}
