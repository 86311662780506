export interface IDataTable {
  stt: number
  patientName?: string
  service?: string
  date?: string
  key: string
  status?: StatusTableEnum
}

export enum StatusTableEnum {
  InExamination = 1,
  HasArrived = 2,
  HasNotArrived = 3,
  Canceled = 4,
}
