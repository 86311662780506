import * as S from 'app/components/tables/BaseTableManagement/styles'
import HeaderPage from 'app/components/header/components/HeaderPage'
import R from 'app/assets/R'
import Info from '../components/Info'
import BuyInfo from '../components/BuyInfo'
import OrderInfo from '../components/OrderInfo'
import { WarehouseGoodsIssueProvider } from '../../context'
import Footer from '../components/Footer'

const CreateWarehouseGoodsIssueContainer = () => {
  return (
    <WarehouseGoodsIssueProvider>
      <S.BaseManagementWrapper>
        <HeaderPage
          titleI18nKey={R.strings.warehouse_purchase_add}
          rightComponent={<></>}
        />
        <div>
          <div>
            <Info />
            <BuyInfo />
          </div>
        </div>

        <div>
          <div>
            <OrderInfo />
          </div>
        </div>
      </S.BaseManagementWrapper>

      <Footer />
    </WarehouseGoodsIssueProvider>
  )
}

export default CreateWarehouseGoodsIssueContainer
