/* eslint-disable @typescript-eslint/no-explicit-any */
import { DownloadOutlined, ImportOutlined } from '@ant-design/icons'
import {
  CampaignReferralEnum,
  CampaignTypeEnum,
} from 'app/api/marketing/discount-campaign/model'
import {
  DefineApplyRuleKey,
  DefineTargetUserEnum,
  URL_FILE_TEMPLATE_PATIENT_IN_VOUCHER,
} from 'app/api/marketing/discount-voucher/constant'
import {
  EVerifyRuleKey,
  MethodIssueEnum,
  TargetUserEnum,
} from 'app/api/marketing/discount-voucher/enum'
import R from 'app/assets/R'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'
import dayjs, { Dayjs } from 'dayjs'
import { isEqual } from 'lodash'
import { DateUtil } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useCreateVoucherContext } from '../../../../context'
import {
  ModalReferralDisplayLayout,
  ModalUserDisplayLayout,
} from '../../../../modal'
import { DefineCampaignReferralEnum } from 'app/api/marketing/discount-campaign/constant'

export const InfoCustomerLayout = () => {
  const { t } = useTranslation()
  const {
    campaignType,
    onOpenModal,
    file,
    onRemoveFile,
    methodIssueVoucher,
    itemCampaign,
    onChangeRuleKey,
    verifyRuleKey,
    fileReferralTargetData,
    onOpenModalReferral,
    onRemoveFileReferral,
  } = useCreateVoucherContext()

  const renderFileName = useMemo(() => {
    if (file) {
      return file.name
    }

    return ''
  }, [file])

  const renderReferralFileName = useMemo(() => {
    if (fileReferralTargetData) {
      return fileReferralTargetData.name
    }

    return ''
  }, [fileReferralTargetData])

  return (
    <BaseSpace size={4}>
      {isEqual(itemCampaign?.referralType, CampaignReferralEnum.Normal) ||
      isEqual(itemCampaign?.referralType, CampaignReferralEnum.OnlyUser) ? (
        <BaseSpace size={4}>
          <FormItem
            label={t(R.strings.apply_rule)}
            required
            rules={[
              {
                required: true,
                message: t(R.strings.require_field, {
                  field: t(R.strings.apply_rule),
                }),
              },
            ]}
            name={'verifyRule'}
          >
            <BaseSelect
              placeholder={t(R.strings.select_apply_rule)}
              onChange={key => onChangeRuleKey?.(key as EVerifyRuleKey)}
              options={[
                {
                  label: t(DefineApplyRuleKey?.[EVerifyRuleKey.All]?.keyI18n),
                  value: EVerifyRuleKey.All,
                },
                {
                  label: t(
                    DefineApplyRuleKey?.[EVerifyRuleKey.NewPatient]?.keyI18n,
                  ),
                  value: EVerifyRuleKey.NewPatient,
                },
                {
                  label: t(
                    DefineApplyRuleKey?.[EVerifyRuleKey.PatientId]?.keyI18n,
                  ),
                  value: EVerifyRuleKey.PatientId,
                },
              ]}
            />
          </FormItem>

          {isEqual(methodIssueVoucher, MethodIssueEnum.Auto) &&
          !isEqual(verifyRuleKey, EVerifyRuleKey.NewPatient) ? (
            <FormItem
              label={t(R.strings.target_user)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.target_user),
                  }),
                },
              ]}
              name={'targetUser'}
            >
              <BaseSelect
                placeholder={t(R.strings.select_target_user)}
                options={[
                  {
                    label: t(
                      DefineTargetUserEnum?.[TargetUserEnum.Customer]?.keyI18n,
                    ),
                    value: TargetUserEnum.Customer,
                  },
                  {
                    label: t(
                      DefineTargetUserEnum?.[TargetUserEnum.VipCustomer]
                        ?.keyI18n,
                    ),
                    value: TargetUserEnum.VipCustomer,
                  },
                  {
                    label: t(
                      DefineTargetUserEnum?.[TargetUserEnum.CompanyEmployee]
                        ?.keyI18n,
                    ),
                    value: TargetUserEnum.CompanyEmployee,
                  },
                ]}
                disabled={
                  isEqual(
                    itemCampaign?.referralType,
                    CampaignReferralEnum.Patient,
                  ) ||
                  isEqual(
                    itemCampaign?.referralType,
                    CampaignReferralEnum.Staff,
                  )
                }
              />
            </FormItem>
          ) : null}

          {isEqual(methodIssueVoucher, MethodIssueEnum.Auto) &&
          isEqual(verifyRuleKey, EVerifyRuleKey.PatientId) ? (
            <FormItem hiddenLabel>
              <BaseRow
                className="custom-characters-wrapper"
                gutter={[16, 8]}
                align={'middle'}
                justify={'space-between'}
                style={{
                  marginLeft: 0,
                  marginRight: 0,
                }}
              >
                <BaseCol>
                  <BaseRow gutter={8} align={'middle'}>
                    <BaseCol>
                      <BaseText
                        children={`${t(R.strings.customer_list)}:`}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                    </BaseCol>
                    {file ? (
                      <>
                        <BaseCol>
                          <ModalUserDisplayLayout fileName={renderFileName} />
                        </BaseCol>
                        <BaseCol>
                          <BaseButton
                            icon={<SvgDeleteDesignIcon />}
                            onClick={onRemoveFile}
                          />
                        </BaseCol>
                      </>
                    ) : (
                      <BaseCol>
                        <BaseText
                          children={t(R.strings.not_select_file)}
                          opacity="0.2"
                          fontSize="xs"
                          fontWeight="medium"
                        />
                      </BaseCol>
                    )}
                  </BaseRow>
                </BaseCol>

                <BaseCol>
                  <BaseRow gutter={16} align={'middle'}>
                    <a href={URL_FILE_TEMPLATE_PATIENT_IN_VOUCHER}>
                      <BaseCol>
                        <BaseButton icon={<DownloadOutlined rev={undefined} />}>
                          {t(R.strings.file_template)}
                        </BaseButton>
                      </BaseCol>
                    </a>
                    <BaseCol>
                      <BaseButton
                        icon={<ImportOutlined rev={undefined} />}
                        onClick={onOpenModal}
                      >
                        {t(R.strings.import)}
                      </BaseButton>
                    </BaseCol>
                  </BaseRow>
                </BaseCol>
              </BaseRow>
            </FormItem>
          ) : null}
        </BaseSpace>
      ) : null}

      <FormItem
        label={t(R.strings.referral_target)}
        required
        rules={[
          {
            required: true,
            message: t(R.strings.require_field, {
              field: t(R.strings.referral_target),
            }),
          },
        ]}
        name={'referralTarget'}
        initialValue={itemCampaign?.referralType ?? CampaignReferralEnum.Normal}
      >
        <BaseSelect
          placeholder={t(R.strings.select_referral_target)}
          options={[
            {
              label: t(
                DefineCampaignReferralEnum?.[CampaignReferralEnum.Normal]
                  ?.keyI18n,
              ),
              value: CampaignReferralEnum.Normal,
            },
            {
              label: t(
                DefineCampaignReferralEnum?.[CampaignReferralEnum.Patient]
                  ?.keyI18n,
              ),
              value: CampaignReferralEnum.Patient,
            },
            {
              label: t(
                DefineCampaignReferralEnum?.[CampaignReferralEnum.Staff]
                  ?.keyI18n,
              ),
              value: CampaignReferralEnum.Staff,
            },
            {
              label: t(
                DefineCampaignReferralEnum[CampaignReferralEnum.OnlyUser]
                  ?.keyI18n,
              ),
              value: CampaignReferralEnum.OnlyUser,
            },
          ]}
          filterSort={() => 0}
          value={itemCampaign?.referralType ?? CampaignReferralEnum.Normal}
          disabled
        />
      </FormItem>

      {isEqual(itemCampaign?.referralType, CampaignReferralEnum.Patient) ||
      isEqual(itemCampaign?.referralType, CampaignReferralEnum.Staff) ? (
        <FormItem
          hiddenLabel
          required
          rules={[
            {
              required: true,
              message: t(R.strings.require_import_field, {
                field: t(
                  isEqual(
                    itemCampaign?.referralType,
                    CampaignReferralEnum.Patient,
                  )
                    ? R.strings.customer_list
                    : R.strings.employee_list,
                ),
              }),
            },
          ]}
          name={'referralList'}
        >
          <BaseSpace size={8}>
            <BaseRow
              className="custom-characters-wrapper"
              gutter={[16, 8]}
              align={'middle'}
              justify={'space-between'}
              style={{
                marginLeft: 0,
                marginRight: 0,
              }}
            >
              <BaseCol>
                <BaseRow gutter={8} align={'middle'}>
                  <BaseCol>
                    <BaseText
                      children={`${t(
                        isEqual(
                          itemCampaign?.referralType,
                          CampaignReferralEnum.Patient,
                        )
                          ? R.strings.customer_list
                          : R.strings.employee_list,
                      )}:`}
                      fontWeight="medium"
                      fontSize="xs"
                      opacity="0.7"
                    />
                  </BaseCol>
                  {fileReferralTargetData ? (
                    <>
                      <BaseCol>
                        <ModalReferralDisplayLayout
                          fileName={renderReferralFileName}
                        />
                      </BaseCol>
                      <BaseCol>
                        <BaseButton
                          icon={<SvgDeleteDesignIcon />}
                          onClick={onRemoveFileReferral}
                        />
                      </BaseCol>
                    </>
                  ) : (
                    <BaseCol>
                      <BaseText
                        children={t(R.strings.not_select_file)}
                        opacity="0.2"
                        fontSize="xs"
                        fontWeight="medium"
                      />
                    </BaseCol>
                  )}
                </BaseRow>
              </BaseCol>

              <BaseCol>
                <BaseRow gutter={16} align={'middle'}>
                  <a href={URL_FILE_TEMPLATE_PATIENT_IN_VOUCHER}>
                    <BaseCol>
                      <BaseButton icon={<DownloadOutlined rev={undefined} />}>
                        {t(R.strings.file_template)}
                      </BaseButton>
                    </BaseCol>
                  </a>
                  <BaseCol>
                    <BaseButton
                      icon={<ImportOutlined rev={undefined} />}
                      onClick={onOpenModalReferral}
                    >
                      {t(R.strings.import)}
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
              </BaseCol>
            </BaseRow>
            <BaseCol />
          </BaseSpace>
        </FormItem>
      ) : null}

      {!isEqual(itemCampaign?.campaignType, CampaignTypeEnum.Voucher) ? (
        <BaseRow gutter={[16, 16]}>
          <BaseCol xl={12}>
            <FormItem
              label={t(R.strings.number_of_uses)}
              required
              name={'numberOfUses'}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.number_of_uses),
                  }),
                },
              ]}
            >
              <InputNumberWrapper
                placeholder={t(R.strings.enter_number_of_uses)}
                min={1}
                step={1}
                precision={0}
              />
            </FormItem>
          </BaseCol>

          <BaseCol xl={12}>
            <FormItem
              label={t(R.strings.number_of_uses_per_customer)}
              name={'numberOfUsesPerCustomer'}
              dependencies={['numberOfUses', 'totalVoucher']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const numberOfUsesPerCustomer = value as number

                    const numberOfUses = getFieldValue('numberOfUses') as number

                    const totalVoucher = getFieldValue('totalVoucher') as number

                    if (numberOfUsesPerCustomer > totalVoucher) {
                      return Promise.reject(
                        t(
                          R.strings
                            .the_number_of_uses_per_customer_cannot_exceed_the_total_number_of_vouchers,
                        ),
                      )
                    }

                    if (numberOfUsesPerCustomer > numberOfUses) {
                      return Promise.reject(
                        t(
                          R.strings
                            .the_number_of_uses_per_customer_cannot_exceed_the_number_of_uses,
                        ),
                      )
                    }

                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <InputNumberWrapper
                placeholder={t(R.strings.enter_number_of_uses_per_customer)}
                step={1}
                precision={0}
              />
            </FormItem>
          </BaseCol>
        </BaseRow>
      ) : null}

      {isEqual(campaignType, CampaignTypeEnum.Voucher) ? (
        <BaseRow gutter={[16, 8]} align={'top'}>
          <BaseCol xl={24}>
            <FormItem
              label={t(R.strings.discount_value)}
              required
              name={'discountValue'}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.discount_value),
                  }),
                },
              ]}
              dependencies={['discountType']}
            >
              <InputNumberWrapper
                placeholder={t(R.strings.enter_discount_value)}
                min={0}
                step={1}
                precision={0}
              />
            </FormItem>
          </BaseCol>
        </BaseRow>
      ) : null}

      <BaseRow gutter={[16, 8]} align={'top'}>
        <BaseCol xl={12}>
          <BaseForm.Item
            name={'startDate'}
            required
            label={t(R.strings.start_date)}
            dependencies={['endDate']}
            rules={[
              {
                required: true,
                message: t(R.strings.require_field, {
                  field: t(R.strings.start_date),
                }),
              },
              () => ({
                validator(_, value) {
                  const day = value as Dayjs

                  if (!day || !itemCampaign?.start) {
                    return Promise.resolve()
                  }
                  if (
                    day.isSame(itemCampaign?.start) ||
                    day.isAfter(itemCampaign?.start)
                  ) {
                    return Promise.resolve()
                  }

                  return Promise.reject(
                    t(R.strings.start_date_must_be_after_campaign_start_date, {
                      date: DateUtil.formatDate(
                        itemCampaign?.start,
                        FORMAT_DD_MM_YYYY_HH_MM,
                      ),
                    }),
                  )
                },
              }),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const day = value as Dayjs

                  if (!day || !getFieldValue('endDate')) {
                    return Promise.resolve()
                  }
                  if (
                    day.isSame(getFieldValue('endDate')) ||
                    day.isBefore(getFieldValue('endDate'))
                  ) {
                    return Promise.resolve()
                  }

                  return Promise.reject(
                    t(R.strings.start_date_must_be_before_end_date),
                  )
                },
              }),
            ]}
          >
            <BaseDatePickerStyled
              format={'DD-MM-YYYY HH:mm'}
              placeholder={t(R.strings.start_date)}
              showTime={{ defaultValue: dayjs('00:00', 'HH:mm') }}
            />
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseForm.Item
            name={'endDate'}
            label={t(R.strings.end_date)}
            required
            rules={[
              {
                required: true,
                message: t(R.strings.require_field, {
                  field: t(R.strings.end_date),
                }),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const day = value as Dayjs

                  if (!day || !getFieldValue('startDate')) {
                    return Promise.resolve()
                  }
                  if (
                    day.isSame(getFieldValue('startDate')) ||
                    day.isAfter(getFieldValue('startDate'))
                  ) {
                    return Promise.resolve()
                  }

                  return Promise.reject(
                    t(R.strings.end_date_must_be_after_start_date),
                  )
                },
              }),
            ]}
          >
            <BaseDatePickerStyled
              format={'DD-MM-YYYY HH:mm'}
              placeholder={t(R.strings.end_date)}
              allowClear
              showTime={{ defaultValue: dayjs('23:59', 'HH:mm') }}
            />
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>
    </BaseSpace>
  )
}

const FormItem = styled(BaseForm.Item)`
  width: 100%;
`

const InputNumberWrapper = styled(InputNumber)`
  width: 100%;
`

const BaseDatePickerStyled = styled(BaseDatePicker)`
  width: 100%;
`
