// Message code define
export const OrganizationalChartMessageCode = {
  OrganizationalChart: {
    successGetData: '80000',
  },
}

// Endpoint
export const OrganizationalChartEndPoint = {
  OrganizationalChart: {
    request: 'hr/v1/organizational-chart',
  },
  OrganizationalChartByJobTitle: {
    request: 'hr/v1/organizational-chart/by-job-title',
  },
}
