import { CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { ReactNode, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

enum ButtonOpenModalTypeEnum {
  add = 'add',
  hyperlink = 'hyperlink',
  update = 'update',
  delete = 'delete',
  deleteIcon = 'deleteIcon',
  choose = 'choose',
  custom = 'custom',
}

type getContainerFunc = () => HTMLElement

interface Props {
  handleCancel: () => void
  showModal: () => void
  handleSubmit: () => void
  isLoading?: boolean
  visible?: boolean
  titleKeyI18n?: string
  otherAction?: ReactNode
  widthModal?: number
  confirmKeyI18n?: string
  cancelKeyI18n?: string
  content?: ReactNode
  textOpenModalI18n?: string
  openModalComponent?: ReactNode
  typeButtonOpen?: keyof typeof ButtonOpenModalTypeEnum
  descriptionDelete?: string | ReactNode
  getContainer?: string | HTMLElement | getContainerFunc | false
  iconLeft?: ReactNode
  titleButton?: string
  titleModal?: string | ReactNode
  footer?: ReactNode | false
  renderOpenModal?: ReactNode
  closeIcon?: ReactNode
  isShowLineBetweenArea?: boolean
  isDisableSubmit?: boolean
  buttonComponent?: ReactNode
}

export const BaseModalManagement: React.FC<Props> = ({
  showModal,
  handleCancel,
  handleSubmit,
  isLoading,
  visible,
  titleKeyI18n,
  otherAction,
  widthModal = 750,
  confirmKeyI18n,
  cancelKeyI18n = R.strings.cancel,
  content,
  textOpenModalI18n,
  openModalComponent,
  typeButtonOpen = 'add',
  descriptionDelete,
  getContainer,
  iconLeft,
  titleButton,
  titleModal,
  footer,
  renderOpenModal,
  closeIcon,
  isShowLineBetweenArea = true,
  isDisableSubmit,
  buttonComponent,
}) => {
  const { t } = useTranslation()

  const titleHeaderModal = useMemo(() => {
    return titleModal ?? (titleKeyI18n && t(titleKeyI18n)) ?? ''
  }, [titleModal, t, titleKeyI18n])
  const titleButtonModal = useMemo(() => {
    return titleButton ?? t(textOpenModalI18n ?? R.strings.action)
  }, [titleButton, textOpenModalI18n])

  const headModal = useCallback(() => {
    return (
      <BaseRow
        justify={'space-between'}
        align={'middle'}
        gutter={[10, 10]}
        style={{ width: '100%' }}
      >
        <BaseCol>
          <S.TitleForm>{titleHeaderModal}</S.TitleForm>
        </BaseCol>
        <BaseCol>
          {closeIcon ?? (
            <CloseOutlined rev={undefined} onClick={handleCancel} />
          )}
        </BaseCol>
      </BaseRow>
    )
  }, [titleHeaderModal, !!handleCancel, closeIcon])()

  const renderOpenModalDefault = useMemo(() => {
    if (openModalComponent) return openModalComponent
    switch (typeButtonOpen) {
      case 'add': {
        return (
          <BaseCol>
            <S.ButtonCreateAction onClick={showModal}>
              <BaseRow gutter={8}>
                <BaseCol>
                  <PlusOutlined rev={undefined} />
                </BaseCol>
                <BaseCol>{t(textOpenModalI18n ?? R.strings.action)}</BaseCol>
              </BaseRow>
            </S.ButtonCreateAction>
          </BaseCol>
        )
      }
      case 'update': {
        return (
          <BaseCol>
            <S.ButtonUpdateAction onClick={showModal}>
              <BaseRow
                style={{ width: '100%' }}
                gutter={8}
                justify={'start'}
                align={'middle'}
              >
                {iconLeft ? iconLeft : null}
                <BaseCol>{titleButtonModal}</BaseCol>
              </BaseRow>
            </S.ButtonUpdateAction>
          </BaseCol>
        )
      }
      case 'delete': {
        return (
          <BaseCol>
            <S.ButtonUpdateAction onClick={showModal} icon={iconLeft}>
              {t(textOpenModalI18n ?? R.strings.action)}
            </S.ButtonUpdateAction>
          </BaseCol>
        )
      }
      case 'deleteIcon': {
        return (
          <BaseCol>
            <S.ButtonUpdateAction onClick={showModal}>
              <BaseRow
                style={{ width: '100%' }}
                gutter={8}
                justify={'start'}
                align={'middle'}
              >
                {iconLeft ? (
                  iconLeft
                ) : (
                  <BaseCol>
                    <DeleteOutlined rev={undefined} />
                  </BaseCol>
                )}
                {textOpenModalI18n && (
                  <BaseCol>{t(textOpenModalI18n ?? R.strings.action)}</BaseCol>
                )}
              </BaseRow>
            </S.ButtonUpdateAction>
          </BaseCol>
        )
      }
      case 'hyperlink': {
        return (
          <BaseCol>
            <S.ButtonCreateHyperlinkAction onClick={showModal}>
              <BaseRow gutter={8}>
                <BaseCol>
                  <PlusOutlined rev={undefined} />
                </BaseCol>
                <S.TitleButton>
                  {t(textOpenModalI18n ?? R.strings.action)}
                </S.TitleButton>
              </BaseRow>
            </S.ButtonCreateHyperlinkAction>
          </BaseCol>
        )
      }
      case 'custom': {
        return <BaseCol>{buttonComponent && buttonComponent}</BaseCol>
      }
      default: {
        return (
          <BaseCol>
            <S.ButtonUpdateAction onClick={showModal}>
              <BaseRow
                style={{ width: '100%' }}
                gutter={8}
                justify={'start'}
                align={'middle'}
              >
                <BaseCol>{t(textOpenModalI18n ?? R.strings.action)}</BaseCol>
              </BaseRow>
            </S.ButtonUpdateAction>
          </BaseCol>
        )
      }
    }
  }, [textOpenModalI18n, t, openModalComponent, typeButtonOpen, showModal])

  const renderContent = (() => {
    switch (typeButtonOpen) {
      case 'deleteIcon':
      case 'delete': {
        return (
          <S.DeleteModalWrapper>
            {headModal}
            <S.DeleteDescriptionModalWrapper>
              {descriptionDelete ?? ''}
            </S.DeleteDescriptionModalWrapper>
            <S.FooterModalWrapper
              justify={'center'}
              align={'middle'}
              gutter={[16, 16]}
            >
              <BaseCol>
                <S.ButtonFooterModal onClick={handleCancel}>
                  {t(cancelKeyI18n)}
                </S.ButtonFooterModal>
              </BaseCol>
              <BaseCol>
                <S.ButtonConfirmModal
                  onClick={handleSubmit}
                  loading={isLoading}
                  disabled={isDisableSubmit}
                >
                  {t(confirmKeyI18n ?? '')}
                </S.ButtonConfirmModal>
              </BaseCol>
            </S.FooterModalWrapper>
          </S.DeleteModalWrapper>
        )
      }
      case 'choose': {
        return (
          <>
            {headModal}
            {isShowLineBetweenArea && <S.DashLine />}
            {content}
          </>
        )
      }
      default:
        return (
          <>
            {headModal}
            {isShowLineBetweenArea && <S.DashLine />}
            {content}
            {isShowLineBetweenArea && <S.DashLine />}
          </>
        )
    }
  })()

  const footerDefault = (() => {
    switch (typeButtonOpen) {
      case 'deleteIcon':
      case 'delete': {
        return <></>
      }
      case 'choose': {
        return <></>
      }
      default: {
        return (
          <>
            <S.FooterModalWrapper justify={'end'} align={'middle'}>
              <S.ButtonFooterModal onClick={handleCancel}>
                {t(cancelKeyI18n)}
              </S.ButtonFooterModal>
              <S.ButtonConfirmModal
                onClick={handleSubmit}
                loading={isLoading}
                disabled={isDisableSubmit}
              >
                {t(confirmKeyI18n ?? '')}
              </S.ButtonConfirmModal>
              {otherAction && otherAction}
            </S.FooterModalWrapper>
          </>
        )
      }
    }
  })()

  return (
    <>
      {renderOpenModal ?? renderOpenModalDefault}
      <S.BaseModalCustom
        open={visible}
        width={widthModal}
        footer={footer ? footer : footerDefault}
        getContainer={getContainer ?? false}
        onCancel={handleCancel}
        closeIcon={null}
      >
        {renderContent}
      </S.BaseModalCustom>
    </>
  )
}
