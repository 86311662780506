/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd'
import { KeyImport } from 'app/api/import/constant'
import { requestRequestImportReferralData } from 'app/api/marketing/discount-voucher'
import { DiscountVoucherMessageCode } from 'app/api/marketing/discount-voucher/constant'
import { IUserDataVoucher } from 'app/api/marketing/discount-voucher/model'
import { IRefModal } from 'app/components/common/ModalComponent'
import { _DEV_ } from 'app/constant'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useRef, useState } from 'react'
import { IFormData } from '../type'
import {
  CampaignReferralEnum,
  IDiscountCampaign,
} from 'app/api/marketing/discount-campaign/model'

export const useReferralTargetHook = ({
  form,
  itemCampaign,
}: {
  form: FormInstance<IFormData>
  itemCampaign?: IDiscountCampaign
}) => {
  const [listReferralTargetData, setListReferralTargetData] = useState<
    IUserDataVoucher[]
  >([])
  const [listReferralTargetDataError, setListReferralTargetDataError] =
    useState<string[]>([])
  const [openImportAreaReferral, setOpenImportAreaReferral] = useState(false)
  const [fileReferralTargetData, setFileReferralTargetData] =
    useState<File | null>(null)
  const modalDisplayReferralRef = useRef<IRefModal>({})

  const onCloseModalReferral = () => {
    setOpenImportAreaReferral(false)
  }

  const onOpenModalReferral = () => {
    setOpenImportAreaReferral(true)
  }

  const onRemoveFileReferral = () => {
    setFileReferralTargetData(null)
    setListReferralTargetData([])
    setListReferralTargetDataError([])
    form.setFieldsValue({
      referralList: undefined,
    })
  }

  const onConfirmModalReferral = async (fileReferralTargetData?: File) => {
    if (!fileReferralTargetData) return
    try {
      const formData = new FormData()
      formData.append(KeyImport.All.file, fileReferralTargetData)
      formData.append(
        'referralType',
        itemCampaign?.referralType ?? CampaignReferralEnum.Patient,
      )

      const res: ResponseType<{
        userData: IUserDataVoucher[]
        notFoundIds: string[]
      }> = await requestRequestImportReferralData(formData)

      if (
        isEqual(
          res?.msgcode,
          DiscountVoucherMessageCode.DiscountVoucher.success,
        )
      ) {
        const userData = res?.data?.userData ?? []

        setListReferralTargetData(userData)
        setListReferralTargetDataError(res?.data?.notFoundIds ?? [])
        setFileReferralTargetData(fileReferralTargetData)
        form?.setFieldsValue({
          referralList: userData,
        })
        form.setFieldsValue({
          fileReferralTargetData,
        })
        modalDisplayReferralRef?.current?.open?.()
        onCloseModalReferral()
      }
    } catch (error) {
      _DEV_ && console.error(error)
    }
  }

  return {
    setListReferralTargetData,
    setListReferralTargetDataError,
    setOpenImportAreaReferral,
    onRemoveFileReferral,
    onConfirmModalReferral,
    modalDisplayReferralRef,
    listReferralTargetData,
    listReferralTargetDataError,
    openImportAreaReferral,
    fileReferralTargetData,
    onOpenModalReferral,
    onCloseModalReferral,
  }
}
