/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetReport05 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import {
  IParamsGetReport05,
  IResponseGetReport05,
} from 'app/api/report/model/report-05'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import {
  IDataBaseTable,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import { convertGeneralReport05Value, useCommonHook } from '../common-hook'
import { initialPagination } from '../common-model'
import { IDataReport05Monthly } from './data'

export const useTableMonthlyHook = (props: IParamsHookReport) => {
  const getDataMonthly = async (
    pagination: Pagination,
    params: IParamsGetReport05,
  ) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IDataReport05Monthly>
    try {
      const response: IResponseGetReport05 = await requestGetReport05({
        year: props?.year,
        ...params,
        option: 'month',
      })
      if (
        isEqual(response?.msgcode, ReportMessageCode.Report05.successGetData)
      ) {
        const data = response?.data?.rows ?? []
        const dataTable: IDataReport05Monthly[] =
          data?.map((area, index) => {
            const [firstClinic, ...otherClinics] = area?.children ?? []

            const dataOfFirstClinic = convertGeneralReport05Value({
              clinic: firstClinic,
              type: 'clinic',
              typeHook: 'month',
            })

            const dataOfArea = convertGeneralReport05Value({
              area,
              type: 'area',
              typeHook: 'month',
            })

            const newResponse: IDataReport05Monthly = {
              key: index + 1,
              ...dataOfFirstClinic,
              ...dataOfArea,
              children: otherClinics?.map((clinic, idx) => {
                const dataOfClinic = convertGeneralReport05Value({
                  clinic,
                  type: 'clinic',
                  typeHook: 'month',
                })

                return {
                  key: (index + 1) * 100 + idx + 1,
                  index: idx + 1,
                  ...dataOfClinic,
                }
              }),
            }
            return newResponse
          }) ?? []

        const metadata = response?.data?.metadata?.total
        const dataOfMetadata = convertGeneralReport05Value({
          clinic: metadata,
          type: 'clinic',
          typeHook: 'month',
        })

        const totalTable: IDataReport05Monthly = {
          key: dataTable?.length + 1,
          area: 'Tổng',
          isTotalTable: true,
          ...dataOfMetadata,
          totalRevenueYear: metadata?.totalActual ?? 0,
        }

        return {
          pagination: pagination ?? initialPagination,
          data: [...dataTable, totalTable],
        } as IDataBaseTable<IDataReport05Monthly>
      }
      return initData
    } catch (error) {
      return initData
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,

    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({ getTreeTableData: getDataMonthly })

  const { columns, exportExcel } = useCommonHook({
    expandedRowKeys,
    fetch,
    fetchLoading,
    handleExpand,
    typeHook: 'month',
    currentFilter: props,
  })

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleResetExpand,
    fetch,
    fetchLoading,
    exportExcel
  }
}
