import { BaseButton } from 'app/components/common/BaseButton'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  width: 100%;
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  background-color: white; // need change

  .ant-space-item {
    width: 100%;
  }
`
export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ActionGroup = styled.div``

export const TitleSupplies = styled.div`
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.md};
  color: var(--primary-color);
`

export const AreaAddNewSupplies = styled(BaseRow)`
  width: 100%;
`

export const FormInputNumber = styled(InputNumber)`
  width: 100%;
`
export const SelectBaseStyled = styled(BaseSelect)`
  min-width: 150px !important;
`

export const FormItem = styled(BaseForm.Item)``

export const ButtonAddNewSupplies = styled(BaseButton)``

export const ListSuppliesWrapper = styled(BaseRow)`
  display: flex;
  align-items: flex-start;
  border: 1px solid var(--neutral-black-2-color);
  margin: unset !important;
  padding: 12px;
  background-color: var(--neutral-black-16-color);
  max-height: 355px;
  overflow-y: scroll;
  gap: 8px;
`

export const SectionSkill = styled(BaseRow)`
  display: flex;
  flex-direction: column;

  .section-skill {
    &__header {
      display: flex;
      justify-content: space-between;
      padding: 12px;
    }

    &__title {
      min-width: 323px;
    }
  }
`

export const SkillWrapper = styled(BaseRow)`
  width: 101%;
  margin-right: -16px;
`
export const SelectedSkillText = styled.span`
  margin-top: 26px;
  font-size: 14px;

  .number {
    color: #28ba59;
    font-weight: ${FONT_WEIGHT.semibold};
  }
`
