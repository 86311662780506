import * as S from 'app/components/tables/BaseTableManagement/styles'
import HeaderPage from 'app/components/header/components/HeaderPage'
import R from 'app/assets/R'
import CompanyInfo from '../components/CompanyInfo'
import BuyInfo from '../components/BuyInfo'
import OrderInfo from '../components/OrderInfo'
import { ItemCategoryProvider } from 'app/context/ItemCategoryContext'
import { WarehousePurchaseProvider } from '../../context'
import Footer from '../components/Footer'
import { SaveDraft } from '../components/SaveDraft'

const CreateWarehousePurchaseContainer = () => {
  return (
    <WarehousePurchaseProvider>
      <ItemCategoryProvider>
        <S.BaseManagementWrapper>
          <HeaderPage
            titleI18nKey={R.strings.warehouse_purchase_add}
            rightComponent={<SaveDraft />}
          />
          <div>
            <div>
              <CompanyInfo />
              <BuyInfo />
            </div>
          </div>

          <div>
            <div>
              <OrderInfo />
            </div>
          </div>
        </S.BaseManagementWrapper>

        <Footer />
      </ItemCategoryProvider>
    </WarehousePurchaseProvider>
  )
}

export default CreateWarehousePurchaseContainer
