import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { initFilterTable } from 'app/hook'
import { isEqual } from 'lodash'
import moment from 'moment'
import {
  FORMAT_YYYYMMDD,
  SelectFilterEnum,
  initialPagination
} from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTableMonthlyHook } from './Monthly/hook'
import { useTableWeeklyHook } from './Weekly/hook'

export const useTableHook = () => {
  const { t } = useTranslation()

  const [filterResponse, setFilterResponse] =
    useState<IResponseFilter>(initFilterTable)

  const hookWeekly = useTableWeeklyHook({
    year: filterResponse.year,
    month: filterResponse?.month,
    startDate: moment(filterResponse?.week?.startDate).format(FORMAT_YYYYMMDD),
    endDate: moment(filterResponse?.week?.endDate).format(FORMAT_YYYYMMDD),
  })

  const hookMonthly = useTableMonthlyHook({
    year: filterResponse.year,
    month: filterResponse?.month,
  })

  const tableData = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.tableData
      case SelectFilterEnum.MONTH:
        return hookMonthly.tableData
      default:
        return hookWeekly.tableData
    }
  }, [filterResponse?.selectFilterType, hookWeekly, hookMonthly])

  const handleTableChange = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.handleTableChange
      case SelectFilterEnum.MONTH:
        return hookMonthly.handleTableChange
      default:
        return hookWeekly.handleTableChange
    }
  }, [filterResponse, hookWeekly, hookMonthly])

  const onClickImport = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.onClickImport
      case SelectFilterEnum.MONTH:
        return hookMonthly.onClickImport
      default:
        return hookWeekly.onClickImport
    }
  }, [filterResponse?.selectFilterType, hookWeekly, hookMonthly])

  const handleChangeFilter = (filter: IResponseFilter) => {
    const { year, month, week } = filter
    setFilterResponse(prev => {
      return ({ ...prev, ...filter })
    })

    if (
      !isEqual(
        { week, year, month },
        {
          week: filterResponse?.week,
          year: filterResponse?.year,
          month: filterResponse?.month,
        },
      )
    ) {
      
      hookWeekly.fetch(initialPagination, {
        year,
        month,
        startDate: moment(week?.startDate).format(FORMAT_YYYYMMDD),
        endDate: moment(week?.endDate).format(FORMAT_YYYYMMDD),
      })
    }

    if (
      !isEqual(
        { year, month },
        {
          year: filterResponse?.year,
          month: filterResponse?.month,
        },
      )
    ) {
      hookMonthly.fetch(initialPagination, { year, month })
    }
  }

  const columns = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.columns
      case SelectFilterEnum.MONTH:
        return hookMonthly.columns
      default:
        return hookWeekly.columns
    }
  }, [t, filterResponse?.selectFilterType])


  return {
    columns,
    tableData,
    handleTableChange,
    handleChangeFilter,
    filterResponse,
    onClickImport,
  }
}
