import { ItemCategoryProvider } from 'app/context/ItemCategoryContext'
import { WarehouseGoodsIssueProvider } from '../../context'
import View from './view'

const ViewWarehouseGoodsIssueContainer = () => {
  return (
    <WarehouseGoodsIssueProvider>
      <ItemCategoryProvider>
        <View />
      </ItemCategoryProvider>
    </WarehouseGoodsIssueProvider>
  )
}

export default ViewWarehouseGoodsIssueContainer
