import { requestGetItem, requestPostItem } from 'app/api/item'
import { notificationController } from 'app/controllers/notification-controller'
import { createContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { ACCOUNTING_ITEM_MANAGEMENT_PATH } from 'app/components/router/route-path'
import { useNavigate } from 'react-router'
import { get } from 'lodash'
import { IItem } from 'app/api/item/model'

interface ItemContext {
  loading: boolean
  items: IItem[]
  createItem?: (payload) => Promise<void>
  fetchItemData?: (params) => void
}
export const ItemContext = createContext<ItemContext>({
  loading: false,
  items: [],
})

export const ItemProvider = ({ children }) => {
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const fetchItemData = async params => {
    setLoading(true)
    try {
      const items = await requestGetItem(params)
      setItems(items?.data)
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }
    setLoading(false)
  }
  const createItem = async payload => {
    setLoading(true)
    try {
      await requestPostItem(payload)
      notificationController.success({
        message: t(R.strings.created_successfully),
      })
      navigate(ACCOUNTING_ITEM_MANAGEMENT_PATH)
    } catch (error) {
      notificationController.error({ message: get(error, 'message', '') })
    }
    setLoading(false)
  }

  return (
    <ItemContext.Provider value={{ items, loading, createItem, fetchItemData }}>
      {children}
    </ItemContext.Provider>
  )
}
