/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteFilled, ExportOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { SvgDownloadIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModal } from 'app/components/common/BaseModal'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseTooltip } from 'app/components/common/BaseTooltip'
import { BaseUpload } from 'app/components/common/BaseUpload'
import { isNull } from 'lodash'
import { normFile } from 'parkway-web-common'
import { ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImportFileModal } from '../ImportModal'
import * as S from './styles'

interface Props {
  title?: string
  titleI18nKey?: string
  isExport?: boolean
  isImport?: boolean
  onPressExport?: () => void
  onPressImport?: (file?: any) => void
  rightComponent?: ReactNode
  urlExample?: string
  nameFileExample?: string
  isShowTooltip?: boolean
  onClickDownloadFileExample?: () => void
}

export const HeaderPage = ({
  title,
  isExport,
  isImport,
  onPressExport,
  onPressImport,
  rightComponent,
  titleI18nKey,
  urlExample,
  isShowTooltip,
  onClickDownloadFileExample,
}: Props) => {
  const { t } = useTranslation()
  const [openImportArea, setOpenImportArea] = useState(false)
  const [file, setFile] = useState<File | null>(null)

  const onCloseModal = () => {
    setOpenImportArea(false)
  }

  const onOpenModal = () => {
    setOpenImportArea(true)
  }

  const onRemoveFile = () => {
    setFile(null)
  }

  const onConfirmModal = () => {
    if (file) {
      onPressImport?.(file)
      onRemoveFile()
      onCloseModal()
    }
  }

  const onChangeFile = (values: { file: any }) => {
    setTimeout(() => {
      const { file } = values
      const { lastModified, name, type, originFileObj } = file
      const newFile = new File([originFileObj], name, { type, lastModified })
      setFile(newFile)
    }, 500)
  }

  const actionComponent = useCallback(() => {
    return (
      <BaseRow gutter={10} style={{ marginRight: 0 }}>
        {isExport && (
          <BaseCol>
            <S.ButtonAction onClick={onPressExport}>
              <BaseRow gutter={8}>
                <BaseCol>
                  <ExportOutlined rev={undefined} />
                </BaseCol>
                <BaseCol>{t(R.strings.export_excel)}</BaseCol>
              </BaseRow>
            </S.ButtonAction>
          </BaseCol>
        )}
        {isImport && (
          <>
            <BaseCol>
              {/* <a href={urlExample ?? EXAMPLE_URL_EXCEL_FILE}> */}
              <S.ButtonAction onClick={onClickDownloadFileExample}>
                <BaseRow gutter={8} align={'middle'}>
                  <S.SvgCol>
                    <SvgDownloadIcon />
                  </S.SvgCol>
                  <S.BaseTextButton $opacity={0.7}>
                    {t(R.strings.sample_file)}
                  </S.BaseTextButton>
                </BaseRow>
              </S.ButtonAction>
              {/* </a> */}
            </BaseCol>
            <ImportFileModal />
          </>
        )}
        {rightComponent && rightComponent}
      </BaseRow>
    )
  }, [isExport, isImport, onPressImport, onPressExport, onOpenModal])()

  return (
    <>
      <S.RootWrapper
        align={'middle'}
        justify={'space-between'}
        wrap={false}
        gutter={16}
      >
        <BaseCol xl={16}>
          {isShowTooltip ? (
            <BaseTooltip
              title={`${titleI18nKey ? t(titleI18nKey) : title ?? ''}`}
            >
              <S.HeadingTitlePage>
                {titleI18nKey ? t(titleI18nKey) : title ?? ''}
              </S.HeadingTitlePage>
            </BaseTooltip>
          ) : (
            <S.HeadingTitlePage>
              {titleI18nKey ? t(titleI18nKey) : title ?? ''}
            </S.HeadingTitlePage>
          )}
        </BaseCol>
        <BaseCol>{actionComponent}</BaseCol>
      </S.RootWrapper>
      {openImportArea && (
        <BaseModal
          title={t(R.strings.import_file)}
          open={openImportArea}
          onOk={onConfirmModal}
          okButtonProps={{ disabled: isNull(file) }}
          onCancel={onCloseModal}
          okText={t(R.strings.import)}
          cancelText={t(R.strings.cancel)}
        >
          <S.DraggerInput
            name="dragger"
            valuePropName="file"
            getValueFromEvent={normFile}
            noStyle
          >
            <BaseUpload.Dragger
              name="files"
              onChange={onChangeFile}
              customRequest={() => undefined}
              showUploadList={false}
            >
              <S.DraggerWrapper>
                <S.IconMailBox rev={undefined} size={24} />
              </S.DraggerWrapper>
            </BaseUpload.Dragger>
          </S.DraggerInput>
          {file && (
            <S.FileNameDisplayWrapper
              gutter={20}
              justify={'start'}
              align={'middle'}
            >
              <BaseCol>
                <span>{file?.name}</span>
              </BaseCol>
              <BaseCol>
                <BaseButton
                  icon={<DeleteFilled rev={undefined} onClick={onRemoveFile} />}
                />
              </BaseCol>
            </S.FileNameDisplayWrapper>
          )}
        </BaseModal>
      )}
    </>
  )
}
