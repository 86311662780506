// Message code define
export const LimitDiscountMessageCode = {
  LimitDiscount: {
    successGetData: '20000',
    successGetTemplate: '20000',
    successImportFile: '20000',
    successUpdate: '20000',
  },
}

// Endpoint
export const LimitDiscountEndPoint = {
  LimitDiscount: {
    template: '/olddb/v1/discounts-maximum/template',
    import: '/olddb/v1/discounts-maximum/import',
    update: '/olddb/v1/discounts-maximum',
    getData: '/olddb/v1/discounts-maximum',
  },
}
