/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetReport08 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { IResponseGetReport08 } from 'app/api/report/model/report-08'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import {
  IDataBaseTable,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { getArray } from 'app/components/tables/common-table/constant'
import { isEqual } from 'lodash'
import { Pagination, PeriodEnum, initialPagination } from 'parkway-web-common'
import { getLocationByTypeFiler, useCommonHook } from '../common-hook'
import { IDataReport08Monthly } from './data'

export const useTableMonthlyHook = (props: IParamsHookReport) => {
  const getDataMonthly = async (
    pagination: Pagination,
    params: IParamsHookReport,
  ) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IDataReport08Monthly>
    try {
      const response: IResponseGetReport08 = await requestGetReport08({
        year: params?.year ?? props.year,
        location: getLocationByTypeFiler(params?.location ?? props?.location),
        type: PeriodEnum.month,
      })
      if (
        isEqual(response?.msgcode, ReportMessageCode.Report08.successGetData)
      ) {
        const data = response?.data?.rows ?? []

        const dataTable: IDataReport08Monthly[] =
          data?.map((item, index) => {
            let monthData: any = {}
            getArray(12).map((month, idx) => {
              const currentAmount =
                item?.months?.find(w => w?.month === month)?.amount ?? 0
              let percentDevelop = 0
              if (idx && currentAmount) {
                const previousAmount = monthData?.[`month${idx}`]

                percentDevelop =
                  ((currentAmount - previousAmount) / previousAmount) * 100
              } else if (idx === 0 && item?.previousTime && currentAmount) {
                const previousAmount = item?.previousTime?.amount ?? 0
                percentDevelop =
                  ((currentAmount - previousAmount) / previousAmount) * 100
              }

              monthData = {
                ...monthData,
                [`month${month}`]: currentAmount,
                [`percentDevelopMonth${month}`]: percentDevelop,
              }
            })
            return {
              firstNumber: index + 1,
              product: `${item?.name}`,
              index: index + 1,
              totalRevenue: item?.amount,
              ...monthData,
            }
          }) ?? []

        return {
          ...initData,
          data: dataTable,
        }
      }
      return initData
    } catch (error) {
      return initData
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,
    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({
    getTreeTableData: getDataMonthly,
  })

  const { columns, exportExcel } = useCommonHook({
    typeHook: 'month',
    currentFilter: props,
    fetchLoading
  })

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleResetExpand,
    fetch,
    fetchLoading,
    exportExcel
  }
}
