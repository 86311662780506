import { DiscountTypeEnum } from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDetailCampaignContext } from '../../../../context'
import {
  LabelCollapseComponent,
  ProfileServiceComponent,
} from '../../components'

export const BundleTypeLayout = () => {
  const { t } = useTranslation()
  const { listBundleService } =
    useDetailCampaignContext()

  return (
    <RootWrapper>
      <HeaderWrapper
        gutter={[16, 8]}
        align={'middle'}
        justify={'space-between'}
      >
        <BaseCol>
          <BaseText
            children={t(R.strings.service_apply)}
            fontWeight="semibold"
            fontSize="md"
          />
        </BaseCol>
      </HeaderWrapper>
      <ContentWrapper>
        {listBundleService?.map((item, index) => {
          return (
            <CollapseWrapper
              items={[
                {
                  key: index,
                  label: (
                    <LabelCollapseComponent
                      item={item}
                      key={index}
                      // onChange={value =>
                      //   onChangeNameBundleByIndex?.(index, value)
                      // }
                    />
                  ),
                  style: {
                    alignItems: 'center',
                  },
                  children: (
                    <BaseSpace size={0}>
                      <BaseRow
                        gutter={[16, 8]}
                        align={'middle'}
                        className="header-content"
                        justify={'space-between'}
                      >
                        <BaseCol xl={10}>
                          <BaseText
                            children={t(R.strings.buy_service)}
                            fontWeight="semibold"
                          />
                        </BaseCol>
                        <BaseCol xl={4}>
                          <BaseText
                            children={t(R.strings.quantity)}
                            fontWeight="semibold"
                          />
                        </BaseCol>
                        <BaseCol xl={4}>
                          <BaseText
                            children={t(R.strings.discount_value)}
                            fontWeight="semibold"
                          />
                        </BaseCol>
                        <BaseCol xl={4}>
                          <BaseText
                            children={t(R.strings.discount_type)}
                            fontWeight="semibold"
                          />
                        </BaseCol>
                        <BaseCol xl={2} />
                      </BaseRow>

                      <BaseSpace className="list-item-content-wrapper">
                        {item?.selectedTreatment?.length ? (
                          item?.selectedTreatment?.map((itm, idx) => {
                            return (
                              <BaseRow
                                key={idx}
                                align={'middle'}
                                gutter={[16, 8]}
                                justify={'space-between'}
                              >
                                <BaseCol xl={10}>
                                  <ProfileServiceComponent itm={itm} />
                                </BaseCol>
                                <BaseCol xl={4}>
                                  <BaseFormItem hiddenLabel>
                                    <InputNumberWrapper
                                      value={itm?.quantity}
                                      placeholder={t(R.strings.enter_quantity)}
                                      disabled
                                    />
                                  </BaseFormItem>
                                </BaseCol>
                                <BaseCol xl={4}>
                                  <BaseFormItem hiddenLabel>
                                    <InputNumberWrapper
                                      value={itm?.discountValue}
                                      placeholder={t(
                                        R.strings.enter_discount_value,
                                      )}
                                      disabled
                                    />
                                  </BaseFormItem>
                                </BaseCol>
                                <BaseCol xl={4}>
                                  <BaseFormItem hiddenLabel>
                                    <BaseSelect
                                      value={itm?.discountType}
                                      placeholder={t(
                                        R.strings.select_discount_type,
                                      )}
                                      disabled
                                      options={[
                                        {
                                          label: t(R.strings.discount_percent),
                                          value: DiscountTypeEnum.Percent,
                                        },
                                        {
                                          label: t(R.strings.cash),
                                          value: DiscountTypeEnum.Amount,
                                        },
                                      ]}
                                    />
                                  </BaseFormItem>
                                </BaseCol>
                                <BaseCol xl={2} />
                              </BaseRow>
                            )
                          })
                        ) : (
                          <BaseText
                            children={t(R.strings.empty_list)}
                            fontWeight="medium"
                            opacity="0.5"
                          />
                        )}
                      </BaseSpace>
                    </BaseSpace>
                  ),
                },
              ]}
              expandIconPosition="right"
              key={index}
            />
          )
        })}
      </ContentWrapper>
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
`

const HeaderWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
  border-start-start-radius: ${BORDER_RADIUS};
`

const ContentWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
  background-color: #f9fafb;
  border-start-end-radius: ${BORDER_RADIUS};
  .dash-line {
    border-top: 1px dashed ${convertedVariables.neutralBlack2Color};
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`

const CollapseWrapper = styled(BaseCollapse)`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};

  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-content {
    border-top: 1px solid ${convertedVariables.neutralBlack9Color};
    background-color: ${convertedVariables.neutralBlack9Color};
    .ant-collapse-content-box {
      padding: 0px !important;
    }
  }

  .ant-collapse-header {
    background-color: var(--background-color);
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background-color: #f9fafb;
  }

  .list-item-content-wrapper {
    padding: ${PADDING.md};
    background-color: ${convertedVariables.backgroundColor};
  }
`

const InputNumberWrapper = styled(InputNumber)`
  width: 100%;
`
