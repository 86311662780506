import { ILevelSkill, ISkill } from 'app/api/skill/model/management'
import { IFormSkillModalData } from './SkillModal/type'
import { SkillItem } from './SkillItem'
import * as S from './styles'

export interface IPropsUpdateSkill {
  levelSkillOptions: ILevelSkill[]
  skillOptions: ISkill[]
  skills?: IFormSkillModalData[]
  onRemove?: (key: number) => void
}

export const Skill = ({
  levelSkillOptions,
  skillOptions,
  skills,
  onRemove,
}: IPropsUpdateSkill) => {
  return (
    <S.SkillList>
      {skills?.map((skill, index) => {
        const foundSkill = skillOptions?.find(
          skillOption => skillOption._id === skill.skill,
        )

        const foundLevelSkill = levelSkillOptions?.find(
          levelSkillOption => levelSkillOption._id === skill.levelSkill,
        )

        return (
          <SkillItem
            key={index}
            index={index}
            levelSkill={foundLevelSkill}
            skill={foundSkill}
            onRemove={onRemove}
          />
        )
      })}
    </S.SkillList>
  )
}
