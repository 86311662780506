import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const SkillList = styled.div`
  gap: ${PADDING.xxxxs};
  display: flex;
  flex-wrap: wrap;
  transition: all 0.5s ease;
  margin-bottom: ${PADDING.xxxxs};
`
