import { BaseDivider } from 'app/components/common/BaseDivider'
import BaseText from 'app/components/common/BaseText'
import React from 'react'
import styled from 'styled-components'

interface IProps {
  style?: React.CSSProperties
  text?: string
  children?: React.ReactNode
  colorText?: string
}

export const SplitDivider: React.FC<IProps> = ({
  text,
  colorText = '#858C94',
  children = (
    <BaseText
      children={text}
      style={{
        color: colorText,
      }}
    />
  ),
  style,
}) => {
  return (
    <Divider dashed style={style}>
      {children}
    </Divider>
  )
}

const Divider = styled(BaseDivider)`
  margin: 0px !important;
`
