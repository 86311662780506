import {
  SvgCalendarAppointmentSessionIcon,
  SvgTableCloseIcon,
  SvgTableOpenIcon,
} from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import styled from 'styled-components'
import { useHook } from './hook'

export const AppointmentSessionItem = () => {
  const { columns, tableData } = useHook()
  return (
    <BaseCollapseWrapper
      items={[
        {
          key: 11,
          className: 'child-collapse',
          label: (
            <BaseRow gutter={[8, 8]} align={'middle'}>
              <SvgCalendarAppointmentSessionIcon />
              <BaseCol>
                <BaseText
                  children={'Buổi 1'}
                  fontWeight="medium"
                  fontSize="xs"
                />
              </BaseCol>
            </BaseRow>
          ),
          children: (
            <BaseTableManagementWrapper
              dataSource={tableData?.data}
              loading={tableData?.loading}
              columns={columns}
              pagination={false}
              scroll={{ x: true }}
            />
          ),
        },
      ]}
      expandIcon={({ isActive }) =>
        isActive ? <SvgTableOpenIcon /> : <SvgTableCloseIcon />
      }
    />
  )
}

const BaseCollapseWrapper = styled(BaseCollapse)`
  .ant-collapse-content-box {
    padding: 0px !important;
    padding-bottom: 5px !important;
  }

  border: 1px solid #EBEEF2;
  border-bottom: 0px;
`

const BaseTableManagementWrapper = styled(BaseTableManagement)`
  .name-column {
    min-width: 500px !important;
    padding-left: 5rem !important;
  }

  .all-column {
    border: 0px !important;
    border-top: 0.5px solid #ebeef2 !important;
  }

  .ant-table-thead .ant-table-cell {
    border: 0px !important;
    border-top: 0.5px solid #ebeef2 !important;
    border-bottom: 0.5px solid #ebeef2 !important;
    text-align: left !important;
    opacity: 0.5;
  }
`
