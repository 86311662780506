import { Dropdown } from 'antd'
import R from 'app/assets/R'
import { ListTotal } from 'app/constant'
import { PageAppointmentContext } from 'app/context/PageAppointmentContext'
import moment from 'moment'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InfoHover } from '../InfoHover'
import EditIcon from './../../../../../../assets/svg/edit.svg'
import TrashIcon from './../../../../../../assets/svg/trash.svg'
import addNew from './../../../../../../assets/images/addNew.png'
import {
  AddNew,
  AddNewHoverBox,
  DropdownActionDeleteTitle,
  DropdownActionUpdateTitle,
  DropdownEdit,
  DropdownTrash,
  EventBlock,
  EventBox,
  EventBoxItem,
  EventBoxItemBottom,
  EventBoxItemBottomBox,
  EventBoxItemCategoryService,
  EventBoxItemTime,
  EventBoxItemTitle,
  EventBoxItemTitleWrapper,
  EventContainer,
  EventWrapper,
  WaitingBox,
  WaitingText,
  WaitingTime,
} from './styles'
import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { DATE } from 'app/common/constants'
import { checkAppointmentTimeConflict, secondsToTime } from 'app/common/helpers'
import { AddNewImg, AddNewLabel } from '../TimeSlotWrapper/styles'
import { EScheduleStatus } from '../../../type'
import dayjs from 'dayjs'

moment.locale('vi')

const Event = props => {
  const {
    setCancelAppointmentItem,
    setOpenConfirmModal,
    event,
    style,
    setOpenCreateUpdate,
    setAppointment,
  } = props
  const { t } = useTranslation()
  const { filter } = useContext(PageAppointmentContext)
  const nextAppointment = event?.next
  const prevAppointment = event?.prev
  const currentAppointment = event?.resource

  const services = currentAppointment?.services?.map(service => service?._id)

  const treatments = currentAppointment?.treatments?.map(
    treatment => treatment?._id,
  )
  const appointment = {
    ...currentAppointment,
    areaId: currentAppointment?.area?._id,
    clinicId: currentAppointment?.clinic?._id,
    categoryServiceId: currentAppointment?.categoryService?._id,
    services: services?.length ? services[0]?._id || services[0] : '',
    treatments: treatments?.length ? treatments[0]?._id || treatments[0] : '',
    doctorId: currentAppointment?.doctor?._id,
    date: moment(currentAppointment?.date),
  }

  const handleCancelAppointment = () => {
    setCancelAppointmentItem(appointment)
    setOpenConfirmModal(true)
  }

  const handleUpdateAppointment = () => {
    setAppointment(appointment)
    setOpenCreateUpdate(true)
  }

  const [isOpenAction, setIsOpenAction] = useState(false)

  const itemStyle = [
    ...ListTotal,
    {
      background: '#F3F4F6',
      border: 'var(--neutral-black-color)',
      status: 6,
    },
  ].find(total => currentAppointment?.scheduleStatus === total?.status)

  const styleEvent = {
    ...style,
    background: itemStyle?.background,
    border: itemStyle?.border,
  }

  const { isConflictNext, isConflictPrev } = checkAppointmentTimeConflict(
    prevAppointment,
    currentAppointment,
    nextAppointment,
  )

  const time = currentAppointment?.time
  const date = dayjs(currentAppointment?.date)
  const dateTime = `${currentAppointment?.date} ${currentAppointment?.time}`
  return (
    <>
      <div>
        {currentAppointment?.clinic?._id === filter?.clinicId && (
          <>
            {(isConflictNext || isConflictPrev) && (
              <EventBlock styleEvent={styleEvent} />
            )}

            <EventContainer
              isConflictNext={isConflictNext}
              isConflictPrev={isConflictPrev}
            >
              <AddNewHoverBox
                isConflictNext={isConflictNext}
                isConflictPrev={isConflictPrev}
              >
                <AddNew
                  styleEvent={styleEvent}
                  className="add-new"
                  onClick={() => {
                    props?.setOpenCreateUpdate(true)
                    props?.setAppointment({
                      time,
                      date,
                    })
                  }}
                >
                  <span>
                    <AddNewImg src={addNew} alt="" />
                  </span>
                  <AddNewLabel>{t(R.strings.schedule_booking)}</AddNewLabel>
                </AddNew>
                <InfoHover isOpenAction={isOpenAction} event={event}>
                  <EventWrapper
                    title=""
                    className="rbc-event"
                    styleEvent={styleEvent}
                  >
                    <EventBox>
                      <EventBoxItem>
                        <EventBoxItemTitleWrapper>
                          <EventBoxItemTitle
                            isConflictNext={isConflictNext}
                            isConflictPrev={isConflictPrev}
                          >
                            {currentAppointment?.clinic?.name}
                          </EventBoxItemTitle>
                          <EventBoxItemCategoryService
                            isConflictNext={isConflictNext}
                            isConflictPrev={isConflictPrev}
                          >
                            {event?.tilte}
                          </EventBoxItemCategoryService>
                        </EventBoxItemTitleWrapper>
                        <div>
                          <Dropdown
                            menu={{
                              items: [
                                {
                                  label: (
                                    <DropdownEdit
                                      onClick={handleUpdateAppointment}
                                    >
                                      {' '}
                                      <img src={EditIcon} />{' '}
                                      <DropdownActionUpdateTitle>
                                        {t(R.strings.update)}
                                      </DropdownActionUpdateTitle>
                                    </DropdownEdit>
                                  ),
                                  key: '1',
                                },
                                {
                                  label: (
                                    <DropdownTrash
                                      onClick={handleCancelAppointment}
                                    >
                                      <img src={TrashIcon} />{' '}
                                      <DropdownActionDeleteTitle>
                                        {t(R.strings.customer_cancel)}
                                      </DropdownActionDeleteTitle>
                                    </DropdownTrash>
                                  ),
                                  key: '2',
                                },
                              ],
                            }}
                            trigger={['click']}
                            onOpenChange={e => setIsOpenAction(e)}
                          >
                            <a
                              onClick={e => e.preventDefault()}
                              className="event-action"
                            >
                              ...
                            </a>
                          </Dropdown>
                        </div>
                      </EventBoxItem>
                      <EventBoxItemBottomBox>
                        {currentAppointment?.scheduleStatus ===
                          EScheduleStatus.LONG_WAITING && (
                          <WaitingBox>
                            <WaitingText>
                              {t(R.strings.waited)}:&nbsp;
                            </WaitingText>
                            <WaitingTime>
                              {secondsToTime(
                                moment().diff(moment(dateTime), 'minutes') * 60,
                              )}
                            </WaitingTime>
                          </WaitingBox>
                        )}

                        <EventBoxItemBottom>
                          <div>
                            <BaseAvatar
                              src={currentAppointment?.createdBy?.avatar}
                              alt={currentAppointment?.createdBy?.name}
                            />
                          </div>
                          <EventBoxItemTime>
                            {moment(event?.start)
                              .format(DATE.LT_A)
                              .replace(DATE.SA, t(R.strings.SA))
                              .replace(DATE.CH, t(R.strings.CH))}
                          </EventBoxItemTime>
                        </EventBoxItemBottom>
                      </EventBoxItemBottomBox>
                    </EventBox>
                  </EventWrapper>
                </InfoHover>
              </AddNewHoverBox>
            </EventContainer>
          </>
        )}
      </div>
    </>
  )
}
export default Event
