import R from 'app/assets/R'
import { PaymentOptionPurchaseProductServiceEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRadio } from 'app/components/common/BaseRadio'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const PaymentOptionLayout = () => {
  const { t } = useTranslation()

  const { purchaseProductService, onChangePaymentOptionTotalInvoice } =
    useProfilePatientContext()

  const _onChangePaymentOptionTotalInvoice = (
    newValue: PaymentOptionPurchaseProductServiceEnum,
  ) => {
    onChangePaymentOptionTotalInvoice?.(newValue)
  }

  return (
    <BaseSpace >
      <BaseText fontSize='xs' fontWeight={'semibold'}>
        {t(R.strings.payment_option)}
      </BaseText>

      <BaseRadioGroup
        value={purchaseProductService?.totalInvoice?.paymentOption}
        defaultValue={PaymentOptionPurchaseProductServiceEnum.OneTimePayment}
        onChange={e =>
          _onChangePaymentOptionTotalInvoice(
            e.target?.value as PaymentOptionPurchaseProductServiceEnum,
          )
        }
      >
        <BaseRow gutter={[15, 10]} align={'middle'}>
          <BaseCol xl={6}>
            <RadioBtn
              value={PaymentOptionPurchaseProductServiceEnum.OneTimePayment}
            >
              <BaseText fontSize='xs'>
                {t(R.strings.one_time_payment)}
              </BaseText>
            </RadioBtn>
          </BaseCol>

          <BaseCol xl={6}>
            <RadioBtn
              value={PaymentOptionPurchaseProductServiceEnum.InstallmentPayment}
            >
              <BaseText fontSize='xs'>
                {t(R.strings.installment_payment)}
              </BaseText>
            </RadioBtn>
          </BaseCol>

          <BaseCol xl={6}>
            <RadioBtn
              value={PaymentOptionPurchaseProductServiceEnum.PaymentInStages}
            >
              <BaseText fontSize='xs'>
                {t(R.strings.payment_in_stages)}
              </BaseText>
            </RadioBtn>
          </BaseCol>
        </BaseRow>
      </BaseRadioGroup>
    </BaseSpace>
  )
}

const RadioBtn = styled(BaseRadio)`
  font-size: 0.875rem;
`

const BaseRadioGroup = styled(BaseRadio.Group)`
width: 100%;
`