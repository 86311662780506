import { DatePicker } from 'antd'
import { requestGetServices } from 'app/api/treatment-group'
import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { DATE } from 'app/common/constants'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import { removeVietnameseTones } from 'app/helpers/string.helper'
import dayjs, { Dayjs } from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { get, isEqual } from 'lodash'
import { getQuarterOfYear, moment } from 'parkway-web-common'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import {
  IFilter,
  PaidTypeTableEnum,
} from '../FilterBracesTrayTable'
import { DebtTreeSelect } from './debt-treeselect'
import { SearchDropdown } from './search-dropdown'
import * as S from './styles'
import { IProps, PaymentMethodEnum, RangeValue } from './type'
import { UnitContext } from 'app/context/UnitContext'
dayjs.extend(quarterOfYear)

const { RangePicker } = DatePicker

export const FilterDept: React.FC<IProps> = ({
  handleChangeFilter,
  filterData,
  listPaidType = Object.values(PaidTypeTableEnum),
  listPaymentMethod = Object.keys(PaymentMethodEnum),
  isTreatment = true,
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [filter, setFilter] = useState<IFilter>({
    keyword: '',
    treatments: [],
    paidType: [],
    paymentMethod: [],
    startDate: moment().format(DATE.YYYY_MM_DD),
    endDate: moment().format(DATE.YYYY_MM_DD),
  })
  const [dayFilter, setDayFilter] = useState<{
    year?: Dayjs
    quarter?: number | null
  }>({})

  const { units } = useContext(UnitContext)
  const [serviceValues, setServiceValues] = useState<string[]>([])
  const [treatmentValues, setTreatmentValues] = useState<string[]>([])

  const [isTreatmentLoading, setIsTreatmentLoading] = useState(false)
  const [treatments, setTreatments] = useState<
    {
      name: string
    }[]
  >([])

  const [isOverlayOpen, setOverlayOpen] = useState(false)
  const [keyword] = useState('')

  const getData = async (keyword: string) => {
    setIsTreatmentLoading(true)
    try {
      const response = await requestGetServices({ keyword, pageSize: 100 })
      setTreatments(response?.data)
    } catch (error) {
      return {
        data: [],
      }
    }
    setIsTreatmentLoading(false)
  }

  useEffect(() => {
    getData(keyword)
  }, [])

  useEffect(() => {
    handleChangeFilter?.(filter)
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      handleChangeFilter?.(filter)
    }, 500)
    return () => clearTimeout(timer)
  }, [filter])

  useEffect(() => {
    if (filterData && !isEqual(filterData, filter)) {
      setFilter(filterData)
    }
  }, [filterData])

  useEffect(() => {
    setOverlayOpen(false)
  }, [pathname])

  const onChangeSearch = (search: string) => {
    setFilter(prev => {
      return {
        ...prev,
        search,
      }
    })
  }

  const onSelectYear = value => {
    setDayFilter(prev => ({
      ...prev,
      year: dayjs(value),
      quarter: 0,
    }))
    if (value) {
      setFilter(prev => ({
        ...prev,
        startDate: dayjs(value).startOf('year').format(DATE.YYYY_MM_DD),
        endDate: dayjs(value).endOf('year').format(DATE.YYYY_MM_DD),
      }))
    } else {
      setFilter(prev => ({
        ...prev,
        startDate: dayjs().format(DATE.YYYY_MM_DD),
        endDate: dayjs().format(DATE.YYYY_MM_DD),
      }))
    }
  }

  const onSelectQuarter = value => {
    setDayFilter(prev => ({
      ...prev,
      quarter: Number(value),
    }))
    const quarter = getQuarterOfYear({
      year: dayFilter?.year?.format(DATE.YYYY),
    })
    if (value) {
      setFilter(prev => ({
        ...prev,
        startDate: quarter[Number(value)]?.start,
        endDate: quarter[Number(value)]?.end,
      }))
    } else {
      const currentYear = dayFilter?.year || dayjs()
      setFilter(prev => ({
        ...prev,
        startDate: currentYear.startOf('year').format(DATE.YYYY_MM_DD),
        endDate: currentYear.endOf('year').format(DATE.YYYY_MM_DD),
      }))
    }
  }
  useEffect(() => {
    setFilter(prev => {
      return {
        ...prev,
        treatments: [...serviceValues, ...treatmentValues],
      }
    })
  }, [serviceValues, treatmentValues])

  const clinics = units?.map(unit => get(unit, 'childs', [])).flat(Infinity)
  return (
    <S.HeadWrapper direction="vertical" size={0}>
      <BaseRow gutter={20} align={'middle'} justify={'space-between'}>
        <BaseCol xl={8}>
          <SearchDropdown
            query={filter?.search ?? ''}
            setQuery={onChangeSearch}
            data={[]}
            isOverlayOpen={isOverlayOpen}
            setOverlayOpen={setOverlayOpen}
          />
        </BaseCol>
        <BaseCol xl={8}>
          <BaseForm.Item name="select" required={false}>
            <S.SelectBaseStyled
              defaultValue={filter.paidType}
              mode="multiple"
              allowClear
              placeholder={t(R.strings.manage_customer_choose_paid_type)}
              suffixIcon={!filter?.paidType && <SvgTableCloseIcon />}
              maxTagCount={1}
              onChange={type =>
                setFilter((prev: IFilter) => ({
                  ...prev,
                  paidType: type,
                }))
              }
              filterOption={(value, option) => {
                return removeVietnameseTones(option?.children)
                  .toLowerCase()
                  .includes(removeVietnameseTones(value).toLowerCase())
              }}
            >
              {listPaidType?.map((item, index) => {
                return (
                  <Option value={item} key={index}>
                    {t(item)}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={8}>
          <BaseForm.Item name="select" required={false}>
            <S.SelectBaseStyled
              defaultValue={filter.paymentMethod}
              mode="multiple"
              allowClear
              placeholder={t(R.strings.payment_method)}
              suffixIcon={!filter?.paymentMethod && <SvgTableCloseIcon />}
              maxTagCount={1}
              onChange={type =>
                setFilter((prev: IFilter) => ({
                  ...prev,
                  paymentMethod: type,
                }))
              }
            >
              {listPaymentMethod?.map((item, index) => {
                const value = PaymentMethodEnum[item];
                return (
                  <Option value={value} key={index}>
                    {t(item)}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>
      <BaseRow gutter={20} align={'middle'} justify={'space-between'}>
        <BaseCol xl={8}>
          <S.SelectBaseStyled
            placeholder={t(R.strings.location)}
            suffixIcon={<SvgTableCloseIcon />}
            onChange={value =>
              setFilter(prev => ({
                ...prev,
                location: value,
              }))
            }
            allowClear
            style={{ width: '100%' }}
          >
            {clinics?.map((item, index) => {
              return (
                <Option value={get(item, 'oldid', '')} key={String(index)}>
                  {get(item, 'name', '')}
                </Option>
              )
            })}
          </S.SelectBaseStyled>
        </BaseCol>
        <BaseCol xl={8}>
          <DebtTreeSelect
            isTreatmentLoading={isTreatmentLoading}
            treatments={treatments}
            selectedValues={serviceValues}
            setSelectedValues={setServiceValues}
            type={['service']}
            placeholder={t(R.strings.manage_customer_debt_choose_treatment)}
          />
        </BaseCol>
        {isTreatment && (
          <BaseCol xl={8}>
            <DebtTreeSelect
              isTreatmentLoading={isTreatmentLoading}
              treatments={treatments}
              selectedValues={treatmentValues}
              setSelectedValues={setTreatmentValues}
              type={['treatment', 'product']}
              placeholder={t(R.strings.manage_customer_debt_choose_tip)}
            />
          </BaseCol>
        )}
      </BaseRow>
      <BaseRow
        gutter={20}
        align={'middle'}
        justify={'space-between'}
        className="mt-5"
      >
        <BaseCol xl={8}>
          <DatePicker
            onChange={date => onSelectYear(date)}
            picker="year"
            style={{ width: '100%' }}
          />
        </BaseCol>
        <BaseCol xl={8}>
          <S.SelectBaseStyled
            allowClear
            placeholder={t(R.strings.quarter_in_year)}
            suffixIcon={<SvgTableCloseIcon />}
            onChange={value => onSelectQuarter(value || 0)}
            value={dayFilter?.quarter}
            style={{ width: '100%' }}
          >
            <Option value={0} key={0}>
              {t(R.strings.all)}
            </Option>
            {[1, 2, 3, 4]?.map((item, index) => {
              return (
                <Option value={item} key={index}>
                  {t(R.strings.quarter)} {item}
                </Option>
              )
            })}
          </S.SelectBaseStyled>
        </BaseCol>
        <BaseCol xl={8}>
          <RangePicker
            defaultValue={[dayjs(filter?.startDate), dayjs(filter?.endDate)]}
            value={[dayjs(filter?.startDate), dayjs(filter?.endDate)]}
            onCalendarChange={(date: RangeValue<dayjs.Dayjs>) => {
              if (date && date.length >= 2) {
                setFilter(prev => ({
                  ...prev,
                  startDate: date[0] ? date[0].format(DATE.YYYY_MM_DD) : '',
                  endDate: date[1] ? date[1].format(DATE.YYYY_MM_DD) : '',
                }))
              } else {
                setFilter(prev => ({
                  ...prev,
                  startDate: undefined,
                  endDate: undefined,
                }))
              }
            }}
            style={{ width: '100%' }}
          />
        </BaseCol>
      </BaseRow>
    </S.HeadWrapper>
  )
}
