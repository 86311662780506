/* eslint-disable @typescript-eslint/no-explicit-any */
import { CalendarOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { isEmpty, isEqual } from 'lodash'
import moment from 'moment'
import {
  DateUtil,
  FORMAT_CENTER_YYYY_MM_DD,
  FORMAT_CENTER_YYYY_MM_DD_HH_mm_A,
  FORMAT_DD_MM_YYYY,
  FORMAT_dd_MM_yyyy,
} from 'parkway-web-common'
import { forwardRef, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BaseCol } from '../BaseCol'
import { BaseRow } from '../BaseRow'
import * as S from './styles'

export function getWeekOfMonth(dateString: string): number {
  const [day, month, year] = dateString.split('/').map(Number)
  const currentDate = moment(
    `${year}-${month}-${day}`,
    FORMAT_CENTER_YYYY_MM_DD,
  )
  const startOfMonth = currentDate.clone().startOf('month').add(1, 'day')
  const diffWeeks = Math.floor(currentDate.diff(startOfMonth, 'days') / 7)
  return diffWeeks + 2
}

function formatDateStringRange(startDate: string, endDate: string): string {
  const formattedStartDate = moment(startDate, FORMAT_DD_MM_YYYY).format('DD')
  const formattedEndDate = moment(endDate, FORMAT_DD_MM_YYYY).format(
    FORMAT_DD_MM_YYYY,
  )
  const formattedDateRange = `${formattedStartDate}-${formattedEndDate}`
  return formattedDateRange
}

export const CustomBaseWeekPicker = forwardRef<any, any>(
  ({ value, onClick, endDate }, ref) => {
    const endDateFormat = DateUtil.formatDDMMYYY(endDate)
    const { t } = useTranslation()
    return (
      <S.BaseWeekInput onClick={onClick} ref={ref}>
        <BaseRow align={'middle'} gutter={24}>
          <BaseCol>
            <S.WeekPickerText $isEmptyText={!!isEmpty(value)}>
              {!isEmpty(value)
                ? `${t(R.strings.week)}: ${getWeekOfMonth(value)} - `
                : ''}
              {!isEmpty(value)
                ? formatDateStringRange(value, endDateFormat)
                : t(R.strings.choose_week)}
            </S.WeekPickerText>
          </BaseCol>
          <BaseCol>
            <CalendarOutlined rev={undefined} />
          </BaseCol>
        </BaseRow>
      </S.BaseWeekInput>
    )
  },
)

interface Props {
  handleWeekChange: (data: { startDate: Date; endDate: Date }) => void
  month?: string | number
  year?: string | number
}

export const getStartEndDateOfMonth = ({
  year,
  month,
}: {
  year?: string | number
  month?: string | number
}) => {
  const selectedDate = moment(`${year}-${month}-1`).add(1, 'day')
  const startOfMonth = selectedDate.clone().startOf('month')
  const endOfMonth = selectedDate.clone().endOf('month')
  return {
    minDayOfMonth: moment(
      startOfMonth,
      FORMAT_CENTER_YYYY_MM_DD_HH_mm_A,
    ).toDate(),
    maxDayOfMonth: moment(
      endOfMonth,
      FORMAT_CENTER_YYYY_MM_DD_HH_mm_A,
    ).toDate(),
  }
}

export const getGetStartEndDateOfWeek = (
  date: string | Date,
  minDayOfMonth?: string | Date,
  maxDayOfMonth?: string | Date,
) => {
  const selectedDate = moment(date, FORMAT_CENTER_YYYY_MM_DD)
  let startOfWeek = selectedDate.clone().startOf('isoWeek')
  let endOfWeek = selectedDate.clone().endOf('isoWeek')
  const firstDayOfMonth = moment(minDayOfMonth, FORMAT_CENTER_YYYY_MM_DD)
  const endDayOfMonth = moment(maxDayOfMonth, FORMAT_CENTER_YYYY_MM_DD)

  if (startOfWeek.isBefore(firstDayOfMonth)) {
    startOfWeek = firstDayOfMonth
  }

  if (endOfWeek.isAfter(endDayOfMonth)) {
    endOfWeek = endDayOfMonth
  }

  return {
    endOfWeek: moment(endOfWeek, FORMAT_CENTER_YYYY_MM_DD_HH_mm_A).toDate(),
    startOfWeek: moment(startOfWeek, FORMAT_CENTER_YYYY_MM_DD_HH_mm_A).toDate(),
  }
}

export const BaseWeekPicker = ({ handleWeekChange, month, year }: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  const minMaxWeedSelect = useMemo(() => {
    return getStartEndDateOfMonth({ month, year })
  }, [month, year])

  const handleMonthChange = (date: Date | string) => {
    const result = getGetStartEndDateOfWeek(
      date,
      minMaxWeedSelect.minDayOfMonth,
      minMaxWeedSelect.maxDayOfMonth,
    )
    setStartDate(result.startOfWeek)
    setEndDate(result.endOfWeek)
    handleWeekChange({
      startDate: result.startOfWeek,
      endDate: result.endOfWeek,
    })
  }

  useEffect(() => {
    if (year && month) {
      const currentMonth = moment().month() + 1
      const currentYear = moment().year()
      const minMaxWeed = getStartEndDateOfMonth({ month, year })
      if (isEqual(+year, +currentYear) && isEqual(+month, +currentMonth)) {
        const result = getGetStartEndDateOfWeek(
          moment().format(FORMAT_CENTER_YYYY_MM_DD),
          minMaxWeed.minDayOfMonth,
          minMaxWeed.maxDayOfMonth,
        )
        setStartDate(result.startOfWeek)
        setEndDate(result.endOfWeek)
        handleWeekChange({
          startDate: result.startOfWeek,
          endDate: result.endOfWeek,
        })
      } else {
        const result = getGetStartEndDateOfWeek(
          moment(minMaxWeed.minDayOfMonth).format(FORMAT_CENTER_YYYY_MM_DD),
          minMaxWeed.minDayOfMonth,
          minMaxWeed.maxDayOfMonth,
        )
        setStartDate(result.startOfWeek)
        setEndDate(result.endOfWeek)
        handleWeekChange({
          startDate: result.startOfWeek,
          endDate: result.endOfWeek,
        })
      }
    }
  }, [year, month])

  return (
    <S.BaseWeekPicker
      selected={startDate}
      onChange={handleMonthChange}
      startDate={startDate}
      endDate={endDate}
      minDate={minMaxWeedSelect.minDayOfMonth}
      maxDate={minMaxWeedSelect.maxDayOfMonth}
      dateFormat={FORMAT_dd_MM_yyyy}
      customInput={<CustomBaseWeekPicker endDate={endDate} />}
    />
  )
}
