import { TimePicker } from 'antd'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputPassword } from 'app/components/common/inputs/InputPassword'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const FooterModal = styled.div`
  display: flex;
  gap: 20px;
`

export const ButtonAction = styled(BaseButton)`
  width: 100%;
  padding: 12px 16px;
  height: 44px;
  font-weight: ${FONT_WEIGHT.medium};
  border: none;

  &.confirm {
    background: linear-gradient(247deg, #362e7b 0%, #365b7e 87.34%);
    color: var(--additional-background-color);

    &:hover {
      color: var(--additional-background-color) !important;
    }
  }

  &.recall {
    background-color: var(--states-blue-light-color);
    color: #216ae2;

    &:hover {
      color: #216ae2 !important;
    }
  }

  &.cancel {
    background-color: var(--states-red-light-color);
    color: var(--states-red-color);

    &:hover {
      color: var(--states-red-color) !important;
    }
  }
`

export const LabelWrapper = styled(BaseRow)`
  color: var(--main-2-color);
`

export const RequireDot = styled.div`
  color: var(--states-red-color);
`
export const DashLine = styled(BaseDivider)``

export const TitleForm = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.bold};
`

export const ModalStyled = styled(BaseModalManagement)``

export const ModalTitle = styled.div`
  display: flex;
  gap: 4px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f1f3;
`

export const FormItem = styled(BaseForm.Item)``

export const FormInput = styled(BaseInput)``

export const FormInputPassword = styled(InputPassword)``

export const SelectBaseStyled = styled(BaseSelect)``

export const SelectCallCenter = styled(BaseSelect)`
  width: 20px;
  background-color: transparent !important;

  & .ant-select-selector {
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
    width: 150px !important;
  }

  & .ant-select-selection-placeholder {
    div {
      color: #216ae2 !important;
    }
  }

  & .ant-select-arrow {
    display: none !important;
  }
`

export const DatePickerStyled = styled(BaseDatePicker)`
  width: 100%;
`

export const TimePickerStyled = styled(TimePicker)`
  width: 100%;
`
