import { Select } from 'antd'
import { SelectProps } from 'antd/lib'
import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseRow } from 'app/components/common/BaseRow'
import styled from 'styled-components'

export const ResultDateRange = styled.div`
  width: 100%;
  padding: 10px 0px;
  border-top: 1px dashed var(--neutral-black-color);
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--main-color-text);
`

export const ResultDateRangeNumber = styled.span`
  color: var(--states-green-color);
`

export const AuthorBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
export const Author = styled.div``
export const AuthorUser = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const AuthorUserLeft = styled.div`
  display: flex;
`
export const AuthorInfo = styled.div``
export const AuthorJobName = styled.div`
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--main-color-text);
  margin-left: 5px;
`
export const AuthorName = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 70%;
  margin-left: 5px;
`
export const AuthorLabel = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 70%;
  margin-bottom: 5px;
`
export const UpdatedByBox = styled.div`
width: 280px;
`
export const UpdatedByList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 3px 0px;
`
export const UpdatedByAuthor = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const UpdatedInfo = styled.div``

export const UpdatedByName = styled.div`
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--main-color-text);
  margin-left: 5px;
  margin-right: 2px;
`
export const UpdatedByJobName = styled.div`
  font-family: Inter;
  font-size: 8px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--main-color-text);
  text-align: left;
  margin-left: 5px;
`

export const UpdatedByLabel = styled.span`
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 80%;
`

export const TableAvatar = styled(BaseAvatar)`
  width: 38px;
  height: 38px;
  flex: none;
  && {
    .ant-avatar-string {
      transform: scale(1) translateX(-50%) translateY(10%) !important;
    }
  }
`

interface ISelectStatus extends SelectProps {
  background?: string
  border?: string
}
export const SelectStatus = styled(Select)<ISelectStatus>`
  && {
    .ant-select-selector,
    .ant-select-selection-placeholder {
      ${props =>
        props?.background
          ? `
      background: ${props?.background};
      border: 1px solid ${props?.border};
      color: white;
      `
          : `
      color: var(--main-color-text);
      `}
      text-align: center;
    }

    .ant-select-arrow {
      ${props => !props?.background && `color: var(--main-color-text);`}
    }
  }
`

export const FilterRow = styled(BaseRow)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`

export const FilterRowBottom = styled(BaseRow)`
  margin-top: -15px;
`

export const SendSMSBox = styled.div``

export const SMSColumn = styled.div`
  text-align: center;
`

export const FilterSearchAppointmentBox = styled.div`
  width: 70%;
`
