import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { Box, Name } from './styles'
import { BaseRow } from 'app/components/common/BaseRow'
import { Flex } from 'antd'

const Info = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <BaseRow justify={'space-between'} align={'middle'}>
        <Flex justify={'start'} align={'middle'} gap={10}>
          <div>
            <Name>{t(R.strings.warehouse_goods_issue_info)}</Name>
          </div>
        </Flex>
      </BaseRow>
    </Box>
  )
}

export default Info
