// Message code define
export const RoleMessageCode = {
  RoleManagement: {
    successGetData: '20000',
  },
}

// Endpoint
export const RoleEndPoint = {
  RoleManagement: {
    request: 'auth/v1/roles',
    requestRight: 'auth/v1/rights',
    jobTitleRoles: "auth/v1/job-title-roles",
    jobTitleRoleByJobTitle: "auth/v1/job-title-roles/job-title"
  },
  
}
