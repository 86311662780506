import { requestGetWarehouseUnit } from 'app/api/inventory-new'
import { notificationController } from 'app/controllers/notification-controller'
import { IUnit } from 'app/model/unit.model'
import { get } from 'lodash'
import { createContext, useEffect, useState } from 'react'
/* eslint-disable @typescript-eslint/no-explicit-any */
interface IWarehouseUnitContext {
  loading: boolean
  units: IUnit[] | any
  setLoading?: (loading) => void
}
export const WarehouseUnitContext = createContext<IWarehouseUnitContext>({
  loading: false,
  units: [],
})

export const WarehouseUnitProvider = ({ children }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const units = await requestGetWarehouseUnit()
        setData(units?.data)
      } catch (error) {
        notificationController.error({ message: get(error, 'message', '') })
      }

      setLoading(false)
    }

    if (!data.length) {
      fetchData()
    }
  }, [])

  return (
    <WarehouseUnitContext.Provider value={{ units: data, loading, setLoading }}>
      {children}
    </WarehouseUnitContext.Provider>
  )
}
