import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { SvgCheckIcon, SvgCloseIcon } from 'app/assets/svg-assets'

import { BaseRow } from 'app/components/common/BaseRow'
import { WarehouseGoodsIssueContext } from 'app/containers/InventoryManagement/WarehouseGoodsIssue/context'
import { useHook } from '../BuyInfo/hook'
import { CustomButton, PageFooter } from '../../create/styles'
import { useParams } from 'react-router'
import { GoodsIssueStatusEnum } from '../../../type'

const Footer = () => {
  const { t } = useTranslation()
  const params = useParams()
  const { handleCreate, isLoading, handleCancel } = useHook(params)
  const { form, discount, orders, warehouseGoodsIssueDetail } = useContext(
    WarehouseGoodsIssueContext,
  )

  const handleCreateGoodsIssue = async () => {
    const values = await form?.getFieldsValue()
    await handleCreate(values, orders, discount, GoodsIssueStatusEnum.DELIVERING)
  }

  const isDisabled = !!warehouseGoodsIssueDetail?._id
  return (
    <>
      {!isDisabled && (
        <PageFooter>
          <BaseRow justify="end" align={'middle'}>
            <BaseButton
              icon={<SvgCloseIcon />}
              onClick={async () => await handleCancel()}
            >
              {t(R.strings.cancel)}
            </BaseButton>
            <CustomButton
              icon={<SvgCheckIcon />}
              onClick={async () => await handleCreateGoodsIssue()}
              loading={isLoading}
            >
              {t(R.strings.confirm)}
            </CustomButton>
          </BaseRow>
        </PageFooter>
      )}
    </>
  )
}

export default Footer
