import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseTextEditor from 'app/components/common/BaseTextEditor'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { StatusEnumNumber } from 'app/components/tables/BaseTableManagement/hook'
import { useTranslation } from 'react-i18next'
import { useCreateUpdateServiceContext } from '../../context'
import * as S from './styles'
import { useParams } from 'react-router'
import { useEffect } from 'react'
import { generateExecuteTimeArray, showExecuteTime } from 'app/common/helpers'

export enum StatusTableEnumCreate {
  active = 'active',
  inactive = 'inactive',
}

const Info = () => {
  const { type: typeParam } = useParams()
  const { rules, form } = useCreateUpdateServiceContext()
  const { t } = useTranslation()

  useEffect(() => {
    form?.setFieldsValue({
      type: typeParam,
    })
  }, [])

  return (
    <S.RootWrapper direction="vertical" size={10}>
      <S.Title>Thêm thủ thuật</S.Title>
      <BaseRow justify={'space-between'} align={'middle'} gutter={[10, 10]}>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={t(R.strings.treatment_management_content_treatment_name)}
            name="name"
            rules={rules?.name}
            required
          >
            <BaseInput
              placeholder={t(
                R.strings.treatment_management_content_enter_treatment_name,
              )}
            />
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseForm.Item
            label={t(R.strings.treatment_management_content_treatment_code)}
            name="code"
            rules={rules?.code}
            required
          >
            <BaseInput
              placeholder={t(
                R.strings.treatment_management_content_enter_treatment_code,
              )}
            />
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseForm.Item
            label={t(R.strings.treatment_management_content_treatment_old_id)}
            name="oldId"
          >
            <BaseInput
              placeholder={t(
                R.strings.treatment_management_content_enter_treatment_old_id,
              )}
            />
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>
      <BaseRow justify={'space-between'} align={'middle'} gutter={[10, 10]}>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={t(
              R.strings.treatment_management_content_treatment_execute_time,
            )}
            name="doctorTime"
            rules={rules?.doctorTime}
            required
          >
            <BaseSelect
              placeholder={t(
                R.strings
                  .treatment_management_content_enter_treatment_execute_time,
              )}
            >
              {generateExecuteTimeArray().map((time: number) => (
                <Option value={time} key={time}>
                  {showExecuteTime(time)}
                </Option>
              ))}
            </BaseSelect>
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseForm.Item
            label={t(
              R.strings.treatment_management_content_treatment_execute_time,
            )}
            name="executeTime"
            rules={rules?.executeTime}
            required
          >
            <BaseSelect
              placeholder={t(
                R.strings
                  .treatment_management_content_enter_treatment_execute_time,
              )}
            >
              {generateExecuteTimeArray().map((time: number) => (
                <Option value={time} key={time}>
                  {showExecuteTime(time)}
                </Option>
              ))}
            </BaseSelect>
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseForm.Item
            label={t(
              R.strings.treatment_management_content_treatment_chair_time,
            )}
            name="chairTime"
            rules={rules?.chairTime}
            required
          >
            <BaseSelect
              placeholder={t(
                R.strings
                  .treatment_management_content_enter_treatment_execute_time,
              )}
            >
              {generateExecuteTimeArray().map((time: number) => (
                <Option value={time} key={time}>
                  {showExecuteTime(time)}
                </Option>
              ))}
            </BaseSelect>
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>
      <BaseCol>
        <BaseForm.Item
          label={t(R.strings.status)}
          name="status"
          rules={rules?.status}
          required
        >
          <BaseSelect
            placeholder={t(R.strings.please_select_status)}
            suffixIcon={<SvgTableCloseIcon />}
          >
            {Object.values(StatusTableEnumCreate).map((item, index) => {
              return (
                <Option value={StatusEnumNumber[item]} key={index}>
                  {t(R.strings[item])}
                </Option>
              )
            })}
          </BaseSelect>
        </BaseForm.Item>
      </BaseCol>
      <BaseForm.Item name="description" label={t(R.strings.description)}>
        <BaseTextEditor placeholder={t(R.strings.description)} />
      </BaseForm.Item>
    </S.RootWrapper>
  )
}

export default Info
