export interface INotificationDashboard {
  id: number
  title: string
  description?: string
}

export const dataNotificationDashboard: INotificationDashboard[] = [
  {
    id: 1,
    title: 'Lịch tập huấn 1',
    description: 'Hôm nay bạn có một lịch tập huấn vào lúc 11:11 20/20/2023',
  },
  {
    id: 2,
    title: 'Lịch tập huấn 2',
    description: 'Hôm nay bạn có một lịch tập huấn vào lúc 11:11 20/20/2023',
  },
  {
    id: 3,
    title: 'Lịch tập huấn 3',
    description: 'Hôm nay bạn có một lịch tập huấn vào lúc 11:11 20/20/2023',
  },
]
