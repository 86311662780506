import {
  SvgClinicIcon,
  SvgClockCircleDesignIcon,
  SvgPackageIcon,
} from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { ITreatmentSchedule } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'

interface IProps {
  item: ITreatmentSchedule
}

export const HeaderLayout = ({ item }: IProps) => {
  return (
    <BaseSpace>
      <BaseRow align={'top'} justify={'space-between'} gutter={[15, 10]}>
        <BaseCol>
          <BaseRow gutter={[10, 10]} align={'top'}>
            <BaseCol>
              <SvgClockCircleDesignIcon />
            </BaseCol>
            <BaseCol>
              <BaseSpace size={10}>
                <BaseText
                  children={item?.startDateTreatment}
                  fontSize="xs"
                  fontWeight="medium"
                  opacity="0.7"
                />
                <BaseCol>
                  <BaseRow gutter={20} align={'middle'}>
                    <SvgPackageIcon />
                    <BaseCol>
                      <BaseText
                        children={item?.treatmentName}
                        fontWeight="semibold"
                        fontSize="md"
                      />
                    </BaseCol>
                  </BaseRow>
                </BaseCol>

                <BaseCol>
                  <BaseRow gutter={20} align={'middle'}>
                    <SvgClockCircleDesignIcon />
                    <BaseCol>
                      <BaseText
                        children={'Dự kiến 3 năm 6 tháng'}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                    </BaseCol>
                  </BaseRow>
                </BaseCol>

                <BaseCol>
                  <BaseRow gutter={20} align={'middle'}>
                    <BaseCol xl={1} style={{ padding: 0 }}>
                      <SvgClinicIcon
                        style={{ width: 18, height: 18, opacity: 0.6 }}
                      />
                    </BaseCol>
                    <BaseCol>
                      <BaseText
                        children={item?.clinicName}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                    </BaseCol>
                  </BaseRow>
                </BaseCol>
              </BaseSpace>
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>
    </BaseSpace>
  )
}
