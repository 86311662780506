import { DeleteOutlined } from '@ant-design/icons'
import type { UploadFile } from 'antd'
import { Image, Modal } from 'antd'
import R from 'app/assets/R'
import { SvgImageExampleIcon } from 'app/assets/svg-assets'
import { DRIVE_ACTION } from 'app/common/config'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { FONT_SIZE } from 'parkway-web-common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { get, isEqual } from 'lodash'
import { useCreateUpdateServiceContext } from 'app/containers/Accounting/ItemManagement/CreateUpdateProductServiceManagement/context'
import { NamePath } from 'antd/es/form/interface'

interface IPropImages {
  nameMain?: string | NamePath
  nameDetail?: string | NamePath
}

const getBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file as Blob)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })

const Images = ({
  nameMain = 'mainImagesUrl',
  nameDetail = 'detailImagesUrl',
}: IPropImages) => {
  const { t } = useTranslation()

  const {
    handleChangeListImageProperty,
    onChangeMainImageProperty,
    listImageProperty,
    mainImageProperty,
    form,
  } = useCreateUpdateServiceContext()

  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as Blob)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
    setPreviewTitle(
      file.name || file?.url?.substring(file.url?.lastIndexOf('/') + 1) || '',
    )
  }

  const handleCancel = () => setPreviewOpen(false)

  useEffect(() => {
    form?.setFieldsValue({
      [nameMain]: get(mainImageProperty, '[0].response.data.path'),
      [nameDetail]: listImageProperty?.map(
        image => image?.response?.data?.path,
      ),
    })
  }, [listImageProperty, mainImageProperty])
  const [nameOfMain] = nameMain

  const productPropertiesCombinedData =
    form?.getFieldValue('productPropertiesCombinedData')[nameOfMain] ??
    undefined
  const mainImagesUrl = productPropertiesCombinedData.mainImagesUrl ?? []
  const detailImagesUrl = productPropertiesCombinedData.detailImagesUrl ?? []

  return (
    <S.RootWrapper direction="vertical" size={15}>
      <BaseForm.Item
        shouldUpdate={(prevValues, currentValues) => {
          return !isEqual(prevValues, currentValues)
        }}
      >
        {({ getFieldValue }) => {
          const [nameOfMain, fieldOfMain] = nameMain
          const [nameOfDetail, fieldOfDetail] = nameDetail

          const productPropertiesCombinedData = getFieldValue(
            'productPropertiesCombinedData',
          )

          const nameMainImage =
            productPropertiesCombinedData?.[nameOfMain]?.[fieldOfMain]

          const nameDetailImage =
            productPropertiesCombinedData?.[nameOfDetail]?.[fieldOfDetail]

          return (
            <S.ChooseImageWrapper gutter={[20, 10]}>
              <BaseForm.Item name={nameMain}>
                <S.MainImageWrapper
                  action={DRIVE_ACTION.UPLOAD_IMAGE}
                  fileList={nameMainImage?.fileList ?? mainImagesUrl ?? []}
                  maxCount={1}
                  progress={{ strokeWidth: 6, showInfo: true }}
                  onPreview={handlePreview}
                  onChange={onChangeMainImageProperty}
                  listType="picture-card"
                  showUploadList={{
                    removeIcon: <DeleteOutlined rev={undefined} />,
                  }}
                >
                  <S.UploadWrapper direction="vertical" size={10}>
                    <SvgImageExampleIcon />
                    <BaseRow gutter={[10, 10]} align={'middle'}>
                      <BaseCol>
                        <S.PlusIcon rev={undefined} />
                      </BaseCol>
                      <S.TitleUpload>
                        {t(R.strings.add_main_image)}
                      </S.TitleUpload>
                    </BaseRow>
                  </S.UploadWrapper>
                </S.MainImageWrapper>
              </BaseForm.Item>
              <BaseCol xl={17}>
                <BaseRow gutter={[20, 10]} align={'middle'}>
                  <BaseForm.Item name={nameDetail}>
                    <S.ImageWrapper
                      action={DRIVE_ACTION.UPLOAD_IMAGE}
                      listType="picture-card"
                      fileList={
                        nameDetailImage?.fileList ?? detailImagesUrl ?? []
                      }
                      onPreview={handlePreview}
                      onChange={handleChangeListImageProperty}
                    >
                      <S.UploadWrapper direction="vertical" size={5}>
                        <SvgImageExampleIcon width={36} height={36} />
                        <BaseRow gutter={[10, 10]} align={'middle'}>
                          <BaseCol>
                            <S.PlusIcon $size={FONT_SIZE.xxs} rev={undefined} />
                          </BaseCol>
                          <S.TitleUpload $fontSize={FONT_SIZE.xxs}>
                            {t(R.strings.add_image)}
                          </S.TitleUpload>
                        </BaseRow>
                      </S.UploadWrapper>
                    </S.ImageWrapper>
                  </BaseForm.Item>
                </BaseRow>
              </BaseCol>
            </S.ChooseImageWrapper>
          )
        }}
      </BaseForm.Item>

      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <Image alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </S.RootWrapper>
  )
}

export default Images
