import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import { PADDING } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const HeadTabComponent = ({
  keyTitle,
  total,
  currentTab,
}: {
  keyTitle: string
  total: number
  currentTab: string
}) => {
  const { t } = useTranslation()

  const isSelected = useMemo(() => {
    return isEqual(currentTab, keyTitle)
  }, [keyTitle, currentTab])
  
  return (
    <BaseRow gutter={[15, 10]} align={'middle'} wrap={false}>
      <BaseCol>
        <BaseTextStyled
          children={t(keyTitle)}
          fontWeight="semibold"
          fontSize="xs"
          opacity={`${isSelected ? 1 : 0.7}`}
          $color={`${isSelected ? convertedVariables.primaryColor : '#798193'}`}
        />
      </BaseCol>
      <BaseCol>
        <TotalCountWrapper>
          <BaseText
            children={total}
            fontWeight="semibold"
            colorText="collapseBackgroundColor"
            fontSize="xs"
          />
        </TotalCountWrapper>
      </BaseCol>
    </BaseRow>
  )
}

const TotalCountWrapper = styled.div`
  padding: 3px 6px;
  border-radius: ${PADDING.md};
  background-color: ${convertedVariables.statesBlueLightColor};
`

const BaseTextStyled = styled(BaseText)<{ $color?: string }>`
  color: ${props => props?.$color ?? convertedVariables.primaryColor};
`
