// Message code define
export const CustomerCareMessageCode = {
  CustomerCarePlan: {
    successImport: '20003',
    successGetData: '20001',
  },
  CheckInPlan: {
    successImport: '20001',
    successGetData: '20001',
  },
  ReportBooking: {
    successGetData: '80000',
  },
}

// Endpoint
export const CustomerCareEndPoint = {
  CustomerCarePlan: {
    requestImportFile: 'report/v1/measure-customer/import',
    requestGetData: 'report/v1/measure-customer',
  },
  CheckInPlan: {
    requestImportFile: 'report/v1/check-in-customer-care-plan/import',
    requestGetData: 'report/v1/check-in-customer-care-plan/by-period-type',
  },
  CheckInPlanByService: {
    requestImportFile: 'report/v1/check-in-service-plan/import',
    requestGetData: 'report/v1/check-in-service-plan/',
  },
  ReportBooking: {
    getReportBookingWebsite: 'appointment/v1/booking/report/from-website',
  },
}

// const Key import
export const KeyCustomerCareImport = {
  All: {
    periodType: 'periodType',
    importType: 'importType',
    file: 'file',
  },
}
