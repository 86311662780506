import {
  requestExportListReferralStatistics,
  requestGetReferralStatistics,
} from 'app/api/marketing/discount-voucher'
import { DiscountVoucherMessageCode } from 'app/api/marketing/discount-voucher/constant'
import { IParamsGetReferralInfo } from 'app/api/marketing/discount-voucher/model'
import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import {
  convertColumnTable,
  ExpandedTableValue,
} from 'app/components/tables/BaseTableReport/hook'
import { _DEV_, initPagination } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { useDebounce } from 'app/hook'
import { isEmpty, isEqual } from 'lodash'
import { Pagination, ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable, IFilterDataTableReport } from './type'

export const useHookTable = () => {
  const { t } = useTranslation()

  const [usersSelected, setServicesSelected] = useState<IDataTable[]>([])
  const [loading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState(initPagination)
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState<IFilterDataTableReport>({})
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

  const handleExpand = (expanded: boolean, newKey?: number) => {
    if (!newKey) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const data = useMemo(() => {
    return usersSelected?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [usersSelected])

  const params: IParamsGetReferralInfo = useMemo(() => {
    return {
      keyword: isEmpty(filter?.keyword) ? undefined : filter?.keyword,
      page: pagination.current,
      pageSize: pagination.pageSize,
      pagesize: pagination.pageSize,
      referralTypes: filter?.referralTypes ?? [],
      year: isEmpty(filter?.year) ? undefined : filter?.year,
      month: isEmpty(filter?.month) ? undefined : filter?.month,
    }
  }, [pagination, filter])

  const paramsDebounce = useDebounce(params, 500)

  const getData = async () => {
    try {
      setIsLoading(true)

      const res: ResponseType<IDataTable[]> =
        await requestGetReferralStatistics(paramsDebounce)

      if (
        isEqual(
          res?.msgcode,
          DiscountVoucherMessageCode.DiscountVoucher.success,
        )
      ) {
        setServicesSelected(res?.data)
        setTotal(res?.metadata?.totalItem ?? 0)
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [paramsDebounce])

  const exportVoucherCode = async () => {
    setIsLoading(true)
    try {
      const res: ResponseType<{ url?: string; Url?: string }> =
        await requestExportListReferralStatistics(paramsDebounce)
        
      if (res?.msgcode === DiscountVoucherMessageCode.DiscountVoucher.success) {
        window.open(res?.data?.url ?? res?.data?.Url, '_blank')
        notificationController.success({
          message: t(R.strings.download_excel_success),
        })
      } else {
        notificationController.error({
          message: t(R.strings.export_excel),
          description: t(R.strings.error),
        })
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeFilter = (filter?: IFilterDataTableReport) => {
    setFilter(prev => ({
      ...prev,
      ...filter,
    }))

    setPagination(prev => ({ ...prev, current: 1 }))
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<IDataTable>({
        title: t(R.strings.voucher_code),
        key: 'id',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'medium-column',
        width: '20%',
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)
          const _handleExpand = () => {
            handleExpand(!isExpanded, record?.key)
          }

          return (
            <ExpandedTableValue
              isExpanded={isExpanded}
              handleExpand={_handleExpand}
              value={record?.id}
              isHaveAreaWhenNotHaveActionExpanded
            />
          )
        },
      }),

      convertColumnTable<IDataTable>({
        title: t(R.strings.name),
        key: 'name',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (value, record) => {
          return (
            <RenderValueTable
              type="OtherLink"
              hrefOtherLink={record?.link ?? ''}
              value={value}
            />
          )
        },
      }),

      convertColumnTable<IDataTable>({
        title: t(R.strings.number_of_voucher),
        key: 'vouchersSize',
        classNameWidthColumnOverwrite: 'medium-column',
        width: '15%',
        render: value => {
          return <BaseText children={value} opacity="0.7" fontWeight="medium" />
        },
      }),
    ]
  }, [t, usersSelected, expandedRowKeys])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  return {
    data,
    columns,
    loading,
    pagination,
    setPagination,
    total,
    handleTableChange,
    exportVoucherCode,
    onChangeFilter,
    filter,
    expandedRowKeys,
    handleExpand,
  }
}
