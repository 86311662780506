// Message code define
export const UnitMessageCode = {
  UnitManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20001',
  },
}

// Endpoint
export const UnitEndPoint = {
  UnitManagement: {
    request: 'hr/v1/unit',
  },
}
