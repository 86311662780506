/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetPNL } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { IParamsGetPNL, IResponseGetPNL } from 'app/api/report/model/report-PNL'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import {
  IDataBaseTable,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import { convertGeneralPNLValue, useCommonHook } from '../common-hook'
import { IDataPNLQuarter } from './data'
import { initialPagination } from '../common-model'

export const useTableQuarterHook = (props: IParamsHookReport) => {
  const getDataWeek = async (pagination: Pagination, params: IParamsGetPNL) => {
    try {
      const response: IResponseGetPNL = await requestGetPNL({
        periodtype: 'quarter',
        year: props?.year,
        quarter: props?.quarter,
        ...params,
      })
      if (isEqual(response?.msgcode, ReportMessageCode.PNL.successGetData)) {
        const data = response?.data?.rows ?? []
        const dataTable: IDataPNLQuarter[] =
          data?.map((item, index) => {
            const newResponse: IDataPNLQuarter = {
              key: index + 1,
              firstNumber: index,
              isTotalGroupTable: !index,
              clinic: item?.clinicName,
              index: index + 1,
              ...convertGeneralPNLValue(item).childs,
            }
            return newResponse
          }) ?? []

        return {
          pagination: pagination ?? initialPagination,
          data: dataTable,
        } as IDataBaseTable<IDataPNLQuarter>
      }
      return {
        pagination: pagination ?? initialPagination,
        data: [],
      } as IDataBaseTable<IDataPNLQuarter>
    } catch (error) {
      return {
        pagination: pagination ?? initialPagination,
        data: [],
      } as IDataBaseTable<IDataPNLQuarter>
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,
    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({ getTreeTableData: getDataWeek })

  const { columns, exportExcel } = useCommonHook({
    expandedRowKeys,
    typeHook: 'quarter',
    fetchLoading,
    currentFilter: props,
  })

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleResetExpand,
    fetch,
    fetchLoading,
    exportExcel
  }
}
