import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

import {
  CustomTableManagement,
  OrderInfoContent,
  OrderInfoFooter,
  OrderInfoHeader,
  OrderInfoTitle,
  TotalQuantity,
  TotalPage,
} from './styles'
import { StatusTableEnum } from 'app/common/enum'
import { useHook } from './hook'
import { BaseRow } from 'app/components/common/BaseRow'
import { SvgDownloadIcon, SvgFileImportIcon } from 'app/assets/svg-assets'
import { useContext, useState } from 'react'
import { Flex, Upload, UploadProps } from 'antd'
import { BaseButton } from 'app/components/common/BaseButton'
import { CreateGoods } from '../CreateGoods'
import { WarehouseGoodsIssueContext } from 'app/containers/InventoryManagement/WarehouseGoodsIssue/context'
import { formatMoney } from 'parkway-web-common'
import { sumBy } from 'lodash'
import { notificationController } from 'app/controllers/notification-controller'
import { GoodsIssueActionEnum } from '../../../type'

export type StatusWarehouseGoodsIssueType = keyof typeof StatusTableEnum

const OrderInfo = () => {
  const [importLoading, setImportLoading] = useState(false)

  const { columns, downloadTemplate, upload } = useHook()
  const [isDownloading, setIsDownloading] = useState(false)

  const { orders, warehouseGoodsIssueDetail, setOrders } = useContext(
    WarehouseGoodsIssueContext,
  )
  const { t } = useTranslation()
  const tableData = orders?.map((order, index) => ({
    ...order,
    ordinalNumber: index + 1,
  }))

  const totalQuantity = formatMoney(sumBy(orders, 'quantity'), '')

  const handleDownloadTemplate = async () => {
    setIsDownloading(true)
    await downloadTemplate(warehouseGoodsIssueDetail?._id)
    setIsDownloading(false)
  }

  const handleUpload = res => {
    const importOrders = (res?.data || [])?.map(order => ({
      ...order,
      name: order?.product?.name,
      code: order?.product?.code,
      purchaseMeasurementUnit: order?.measurementUnit?.name,
    }))
    const importOrderIds = importOrders?.map(order => order?._id) || []
    const orderIds = orders?.map(order => order?._id) || []
    if (
      orderIds?.length &&
      orderIds.every(orderId => importOrderIds?.includes(orderId))
    ) {
      notificationController.error({
        message: t(R.strings.import_error),
        description: t(R.strings.goods_existed),
      })
    } else {
      setOrders?.(prev => [...prev, ...importOrders])
    }
  }

  const uploadProps: UploadProps = {
    showUploadList: false,
    customRequest: options =>
      upload(options, warehouseGoodsIssueDetail?._id, handleUpload),
    onChange(info) {
      if (info.file.status === 'uploading') {
        setImportLoading(true)
        return
      }

      if (info.file.status === 'done') {
        console.log('info', info)
        setImportLoading(false)
      } else if (info.file.status === 'error') {
        setImportLoading(false)
        notificationController.error({
          message: t(R.strings.import_error),
        })
      }
    },
  }

  const isDisabled = !!warehouseGoodsIssueDetail?._id

  return (
    <>
      <OrderInfoHeader>
        <Flex justify="space-between" align="center">
          <OrderInfoTitle>
            {t(R.strings.warehouse_goods_issue_delivering_info)}
          </OrderInfoTitle>

          <Flex justify="space-between" align="center" gap={10}>
            <div>
              <CreateGoods type={GoodsIssueActionEnum.VIEW_DETAIL} />
            </div>
            {!!warehouseGoodsIssueDetail?._id && (
              <div onClick={() => handleDownloadTemplate()}>
                <BaseButton icon={<SvgDownloadIcon />} loading={isDownloading}>
                  {t(R.strings.sample_file)}
                </BaseButton>
              </div>
            )}

            {!isDisabled && (
              <div>
                <Upload {...uploadProps}>
                  <BaseButton
                    icon={<SvgFileImportIcon />}
                    loading={importLoading}
                  >
                    {t(R.strings.import)}
                  </BaseButton>
                </Upload>
              </div>
            )}

            {!isDisabled && (
              <div>
                <CreateGoods />
              </div>
            )}
          </Flex>
        </Flex>
      </OrderInfoHeader>

      <OrderInfoContent>
        <CustomTableManagement
          columns={columns}
          dataSource={tableData}
          pagination={{
            pageSize: 100,
            total: tableData?.length,
            showTotal: total => {
              return (
                <TotalPage>
                  {t(R.strings.total_page, {
                    total,
                  })}
                </TotalPage>
              )
            },
          }}
          expandable={{
            expandIcon: () => null,
          }}
        />
      </OrderInfoContent>

      {!isDisabled && (
        <OrderInfoFooter>
          <div>
            <BaseRow justify="space-between" align="middle">
              <div>
                <b>{t(R.strings.quantity)}</b>
              </div>
              <TotalQuantity>
                <div>
                  <b>{totalQuantity}</b>
                </div>
              </TotalQuantity>
            </BaseRow>
          </div>
        </OrderInfoFooter>
      )}
    </>
  )
}

export default OrderInfo
