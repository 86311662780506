import { ApiClient } from 'app/service/api-service'
import { RefererReportEndPoint } from './constant'
import { IParamsGetRefererReport } from './model'

export const requestGetRefererReport = (
  params?: IParamsGetRefererReport,
) =>
  ApiClient.Get({
    url: RefererReportEndPoint.RefererReport.request,
    params: { params },
  })
