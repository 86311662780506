import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { FilterManagement } from 'app/components/tables/BaseTableManagement/FilterManagementTable'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { CreateRole } from './components/CreateRole'
import { useHookTable } from './hook'
import { useTranslation } from 'react-i18next'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
const RoleManagementContainer = () => {
  const { t } = useTranslation()
  const {
    tableData,
    columns,
    handleTableChange,
    handleChangeFilter,
    fetchNewData,
    filter,
    isHavePermissionFunction,
  } = useHookTable()

  return (
    <S.BaseManagementWrapper>
      <HeaderPage
        titleI18nKey={R.strings.role_management}
        rightComponent={
          isHavePermissionFunction(
            ModulePermissionEnum.HR,
            FunctionPermissionEnum.ROLES_CREATE,
          ) ? (
            <CreateRole fetchTable={fetchNewData} />
          ) : undefined
        }
      />
      <S.RootWrapper>
        <BaseRow>
          <FilterManagement
            handleChangeFilter={handleChangeFilter}
            filterData={filter}
            placeholderSearch={t(R.strings.role_content_enter_role)}
          />
        </BaseRow>
        <BaseTableManagement
          columns={columns}
          dataSource={tableData.data}
          pagination={tableData.pagination}
          loading={tableData.loading}
          onChange={handleTableChange}
        />
      </S.RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default RoleManagementContainer
