import * as S from 'app/components/tables/BaseTableManagement/styles'
import HeaderPage from 'app/components/header/components/HeaderPage'
import R from 'app/assets/R'
import Info from './components/Info'
import BuyInfo from './components/BuyInfo'
import OrderInfo from './components/OrderInfo'
import { ItemCategoryProvider } from 'app/context/ItemCategoryContext'
import { WarehouseGoodsReceiptProvider } from './context'
import Footer from './components/Footer'

const CreateWarehouseGoodsReceiptContainer = () => {
  return (
    <WarehouseGoodsReceiptProvider>
      <ItemCategoryProvider>
        <S.BaseManagementWrapper>
          <HeaderPage
            titleI18nKey={R.strings.goods_receipt_ticket}
            rightComponent={<></>}
          />
          <div>
            <div>
              <Info />
              <BuyInfo />
            </div>
          </div>

          <div>
            <div>
              <OrderInfo />
            </div>
          </div>
        </S.BaseManagementWrapper>

        <Footer />
      </ItemCategoryProvider>
    </WarehouseGoodsReceiptProvider>
  )
}

export default CreateWarehouseGoodsReceiptContainer
