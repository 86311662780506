import { ApiClient } from 'app/service/api-service'
import {
  IParamsGetDoctorsWithoutPaid,
  IParamsGetLocationsWithoutPaid,
  IParamsGetOrthodonticWithoutPaid,
  IParamsGetTotalWithoutPaid,
} from '../model/orthodontic-patient-without-paid'
import { AccountingEndPoint } from '../constant'

export const requestOrthodonticPatientWithoutPaid = (
  params: IParamsGetOrthodonticWithoutPaid,
) =>
  ApiClient.Get({
    url: AccountingEndPoint.PatientScheduleWithoutPaid.requestGetData,
    params: { params },
  })

export const requestGetDoctorsPatientWithoutPaid = (
  params: IParamsGetDoctorsWithoutPaid,
) =>
  ApiClient.Get({
    url: AccountingEndPoint.PatientScheduleWithoutPaid.doctors,
    params: { params },
  })

export const requestGetLocationsPatientWithoutPaid = (
  params: IParamsGetLocationsWithoutPaid,
) =>
  ApiClient.Get({
    url: AccountingEndPoint.PatientScheduleWithoutPaid.locations,
    params: { params },
  })

export const requestGetTotalPatientWithoutPaid = (
  params: IParamsGetTotalWithoutPaid,
) =>
  ApiClient.Get({
    url: AccountingEndPoint.PatientScheduleWithoutPaid.total,
    params: { params },
  })
