/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetMeasurementUnitManagement } from 'app/api/measurement-unit'
import { MeasurementUnitMessageCode } from 'app/api/measurement-unit/constant'
import {
  IMeasurementUnitManagement,
  IResponseGetMeasurementUnitManagement,
} from 'app/api/measurement-unit/model/management'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { useInfiniteQuery } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { MeasurementUnitKeys } from '../query-key/measurement-unit'

export function useGetMeasurementUnitTreatmentList(params, enabled = true) {
  const token = getToken()
  return useInfiniteQuery<
    IResponseQueryList<IMeasurementUnitManagement[]>,
    undefined
  >(
    MeasurementUnitKeys.listMeasurementUnit(),
    async () => {
      try {
        const res: IResponseGetMeasurementUnitManagement =
          await requestGetMeasurementUnitManagement(params)

        if (
          isEqual(
            res?.msgcode,
            MeasurementUnitMessageCode.MeasurementUnitManagement.successGetData,
          )
        ) {
          const data: IMeasurementUnitManagement[] = res?.data || []

          return {
            data,
            total: res.metadata.totalItem || 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
