import { requestGetJobTitleManagement } from 'app/api/jobtitle'
import { createContext, useState } from 'react'
import { IJobTitle } from './type'

interface IJobTitleContext {
  loading: boolean
  jobTitles: IJobTitle[]
  setLoading?: (loading) => void
  fetchData?: (params) => void
  total: number
}
export const JobTitleContext = createContext<IJobTitleContext>({
  loading: false,
  jobTitles: [],
  total: 0,
})

export const JobTitleProvider = ({ children }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const fetchData = async params => {
    setLoading(true)
    const jobTitles = await requestGetJobTitleManagement(params)
    setData(jobTitles?.data?.rows)
    setTotal(jobTitles?.data?.metadata?.totalItem)
    setLoading(false)
  }

  return (
    <JobTitleContext.Provider
      value={{ jobTitles: data, loading, setLoading, fetchData, total }}
    >
      {children}
    </JobTitleContext.Provider>
  )
}
