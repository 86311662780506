import { DiscountTypeEnum } from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDetailCampaignContext } from '../../../../context'
import { ITreatmentInTable } from '../../../../type'
import { ProfileServiceComponent } from '../../components'

export const useHook = () => {
  const { t } = useTranslation()
  const {
    listService,
    onChangeService,
  } = useDetailCampaignContext()

  const [search, setSearch] = useState('')

  const data = useMemo(() => {
    return listService
      ?.filter(item => {
        return item?.name?.toLowerCase().includes(search.toLowerCase())
      })
      ?.map((item, index) => {
        return {
          ...item,
          index: index + 1,
          key: index,
        } as ITreatmentInTable
      })
  }, [listService, search])

  const columns = useMemo(() => {
    return [
      convertColumnTable<ITreatmentInTable>({
        title: t(R.strings.service),
        key: 'name',
        fixed: 'left',
        className: 'name-service-column',
        render: (_, record) => {
          return (
            <ProfileServiceComponent itm={record} />
          )
        },
      }),
      convertColumnTable<ITreatmentInTable>({
        title: t(R.strings.discount_value),
        key: 'discountValue',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => {
          return (
            <BaseFormItem hiddenLabel>
              <InputNumberWrapper
                value={record?.discountValue ?? 0}
                disabled
                min={0}
              />
            </BaseFormItem>
          )
        },
      }),
      convertColumnTable<ITreatmentInTable>({
        title: t(R.strings.discount_type),
        key: 'discountType',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => {
          return (
            <BaseFormItem hiddenLabel>
              <BaseSelect
                value={record?.discountType}
                placeholder={t(R.strings.select)}
                disabled
                options={[
                  {
                    label: t(R.strings.cash),
                    value: DiscountTypeEnum.Amount,
                  },
                  {
                    label: t(R.strings.discount_percent),
                    value: DiscountTypeEnum.Percent,
                  },
                ]}
              />
            </BaseFormItem>
          )
        },
      }),
    ]
  }, [t, listService])

  const onChangeSearch = (value: string) => {
    setSearch(value)
  }

  return {
    columns,
    listService,
    data,
    onChangeSearch,
    search,
    onChangeService,
  }
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`
const InputNumberWrapper = styled(InputNumber)`
  width: 100%;
`
