/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetCostPlan } from 'app/api/accounting'
import { AccountingMessageCode } from 'app/api/accounting/constant'
import {
  IParamsGetCostPlan,
  IResponseGetCostPlan,
} from 'app/api/accounting/model'
import R from 'app/assets/R'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import {
  IDataBaseTable,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import { convertGeneralCostPlanValue, useCommonHook } from '../common-hook'
import { IDataCostPlanWeekly } from './data'
import { initPagination } from 'app/constant'

export const useTableWeeklyHook = (props: IParamsHookReport) => {
  const { t } = useTranslation()

  const getDataWeek = async (
    pagination: Pagination,
    params: IParamsGetCostPlan,
  ) => {
    const response: IResponseGetCostPlan = await requestGetCostPlan({
      periodtype: 'quarter',
      year: props?.year,
      ...params,
    })
    if (
      isEqual(response?.msgcode, AccountingMessageCode.CostPlan.successGetData)
    ) {
      const data = response?.data?.rows ?? []
      const dataTable: IDataCostPlanWeekly[] =
        data?.map((item, index) => {
          const [firstWeek, ...otherWeeks] = item?.quarters ?? []
          const totalDataClinic = convertGeneralCostPlanValue(item).total
          const dataFirstWeek = convertGeneralCostPlanValue(firstWeek).childs

          const newResponse: IDataCostPlanWeekly = {
            key: index + 1,
            clinic: item?.clinicName,
            quarter: `${t(R.strings.quarter)} ${firstWeek?.quarter}`,
            ...totalDataClinic,
            ...dataFirstWeek,
            children: otherWeeks?.map((quarter, idx: number) => {
              const convertWeekClinic = convertGeneralCostPlanValue(quarter)

              return {
                key: Number(`${(index + 1) * 10}${idx + 1}`),
                quarter: `${t(R.strings.quarter)} ${quarter?.quarter}`,
                index: idx + 1,
                ...convertWeekClinic.childs,
              }
            }),
          }
          return newResponse
        }) ?? []

      return {
        pagination: pagination ?? initPagination,
        data: dataTable,
      } as IDataBaseTable<IDataCostPlanWeekly>
    }
    return {
      pagination: pagination ?? initPagination,
      data: [],
    } as IDataBaseTable<IDataCostPlanWeekly>
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,

    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({ getTreeTableData: getDataWeek })

  const { columns, onClickImport } = useCommonHook({
    expandedRowKeys,
    fetch,
    fetchLoading,
    handleExpand,
    typeHook: 'quarter',
    currentFilter: props,
  })

  const _onClickImport = async (file: any) => {
    onClickImport(file, 'quarter')
  }

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,

    handleResetExpand,
    fetch,
    fetchLoading,
    onClickImport: _onClickImport,
  }
}
