import React from 'react'
import { Modal } from 'antd'
import Appointment from '../../AppointmentPage'

const AppointmentModal: React.FC<{
  open
  setOpen
}> = ({ open, setOpen }) => {
  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <>
      <Modal
        open={open}
        title=""
        onCancel={handleCancel}
        footer={[]}
        style={{ top: 20 }}
        width="90%"
        wrapClassName="appointment-modal"
      >
        <Appointment />
      </Modal>
    </>
  )
}

export default AppointmentModal
