/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestPutRolesManagement } from 'app/api/roles'
import { RoleMessageCode } from 'app/api/roles/constant'
import { IRoleRight } from 'app/api/roles/models/rights'
import { IPayloadUpdateRole } from 'app/api/roles/models/role'
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { usePagination } from 'app/hook'
import { useGetRoleRights } from 'app/react-query/hook/role'
import { IResponseQueryList } from 'app/react-query/model/common'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormRoleData, IFormRoleRule, IPermission } from '../../type'
import { IPropsUpdateRole } from './type'

export const useModalHook = ({ role, fetchTable }: IPropsUpdateRole) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormRoleData>()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [permissions, setPermissions] = useState<IPermission[]>([])

  const { flattenDataList } = usePagination()

  const { data: dataRightsBefore, isLoading: isLoadingRights } =
    useGetRoleRights({})

  const modules = useMemo(() => {
    const flattenDta: IResponseQueryList<IRoleRight[]> =
      flattenDataList(dataRightsBefore)
    return flattenDta?.data ?? []
  }, [dataRightsBefore])

  const onChangePermission = (newPermission: IPermission[]) => {
    setPermissions(newPermission)
  }

  const onRemovePermission = (index: number) => {
    setPermissions(prev => prev?.filter((_, idx) => idx !== index))
  }

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  useEffect(() => {
    setPermissions(role?.permissions ?? [])
    form.setFieldValue('name', role?.name)
    form.setFieldValue('description', role?.description)
    form.setFieldValue('module', role?.moduleName)
  }, [role, visible])

  const handleSubmit = async (values: IFormRoleData) => {
    setIsLoading(true)
    try {
      if (!role?._id) return

      const payload: IPayloadUpdateRole = {
        description: values?.description ?? '',
        name: values?.name,
        permissions: permissions?.map(item => item?._id ?? ''),
        moduleName: values?.module,
        id: role?._id,
      }

      const response: ResponseType<string> = await requestPutRolesManagement(
        payload,
      )

      if (
        isEqual(
          response?.msgcode,
          RoleMessageCode.RoleManagement.successGetData,
        )
      ) {
        notificationController.success({
          message: t(R.strings.role_content_update_role),
          description: response?.message,
        })
        setPermissions([])
        handleCancel()
        fetchTable?.()
      } else {
        notificationController.error({
          message: t(R.strings.role_content_update_role),
          description: response?.message,
        })
      }
    } catch (error) {
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const rules: IFormRoleRule = useMemo(() => {
    return {
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.role_content_name_role),
          }),
        },
      ],
      description: [],
      module: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.role_content_group_permission),
          }),
        },
      ],
    }
  }, [t])

  const isDisableSubmit = useMemo(() => {
    if (!permissions?.length) return true

    return false
  }, [permissions, form.getFieldsError()])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    onChangePermission,
    permissions,
    onRemovePermission,
    modules,
    isLoadingRights,
    isDisableSubmit,
  }
}
