import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import * as SManagement from 'app/components/tables/BaseTableManagement/styles'
import InfoPatientComponent from './layouts/InfoPatient'
import * as S from './styles'
import PaymentDebt from './layouts/PaymentDebt'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import PurchaseProductService from './layouts/PurchaseProductService'
import ImagePatient from './layouts/ImagePatient'
import { ProfilePatientProvider } from './context'
import TreatmentSchedule from './layouts/TreatmentSchedule'
import { useEffect } from 'react'
import { FunctionPermissionEnum, ModulePermissionEnum } from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'

export const ProfilePatientRecordContainer = () => {
  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.DASHBOARD,
      FunctionPermissionEnum.DASHBOARD_READ_DOCTOR,
      R.strings.profile_patient_record,
    )
  }, [])

  return (
    <ProfilePatientProvider>
      <SManagement.BaseManagementWrapper>
        <HeaderPage titleI18nKey={R.strings.profile_patient_record} />
        <BaseForm>
          <S.BaseSpaceWrapper direction="vertical" size={15}>
            <InfoPatientComponent />
            <PaymentDebt />
            <PurchaseProductService />
            <ImagePatient />
            <TreatmentSchedule />
          </S.BaseSpaceWrapper>
        </BaseForm>
      </SManagement.BaseManagementWrapper>
    </ProfilePatientProvider>
  )
}
