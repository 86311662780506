import R from 'app/assets/R'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useModalHook } from './hook'
import * as S from './styles'
import { StatusTableEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { Option } from 'app/components/common/BaseSelectSearch'
import { BaseTableManagementWrapper } from 'app/components/tables/BaseTableManagement/styles'
import {
  ButtonConfirmModal,
  ButtonFooterModal,
  FooterModalWrapper,
} from 'app/components/common/BaseModalManagement/styles'
import { useContext, useEffect, useState } from 'react'
import { ItemCategoryContext } from 'app/context/ItemCategoryContext'
import { InputSearch } from 'app/components/tables/BaseTableManagement/FilterBracesTrayTable/styles'
import { IItem } from 'app/api/item/model'
import { WarehousePurchaseContext } from 'app/containers/InventoryManagement/WarehousePurchase/context'

export const initValues = {
  name: '',
}

export type StatusGoodsType = keyof typeof StatusTableEnum

export interface IPropsCreateGoods {
  categoryid?: string
  setOrders?: (data) => void
  orders?: IItem[]
}

export const CreateGoods = () => {
  const { setOrders } = useContext(WarehousePurchaseContext)
  const {
    form,
    visible,
    t,
    showModal,
    handleCancel,
    isLoading,
    columns,
    tableData,
    handleTableChange,
    handleChangeFilter,
    filter,
    goods,
    hideModal,
  } = useModalHook()

  const { fetchItemCategory, itemCategories } = useContext(ItemCategoryContext)
  const [search, setSearch] = useState('')
  useEffect(() => {
    const timer = setTimeout(() => {
      handleChangeFilter({
        ...filter,
        search,
      })
    }, 500)

    return () => clearTimeout(timer)
  }, [search])

  useEffect(() => {
    if (!itemCategories?.length) {
      fetchItemCategory?.({})
    }
  }, [])

  return (
    <BaseModalManagement
      typeButtonOpen={'add'}
      titleKeyI18n={R.strings.goods_add}
      isLoading={isLoading}
      isShowLineBetweenArea={false}
      footer={
        <>
          <FooterModalWrapper justify={'space-between'} align={'middle'}>
            <S.Total>
              {t(R.strings.total_selected)}{' '}
              <S.TotalNumber>{tableData.pagination.total}</S.TotalNumber>
            </S.Total>
            <FooterModalWrapper justify={'end'} align={'middle'}>
              <ButtonFooterModal onClick={handleCancel}>
                {t(R.strings.cancel)}
              </ButtonFooterModal>
              <ButtonConfirmModal
                loading={isLoading}
                onClick={() => {
                  setOrders?.(goods)
                  hideModal()
                }}
              >
                {t(R.strings.confirm)}
              </ButtonConfirmModal>
            </FooterModalWrapper>
          </FooterModalWrapper>
        </>
      }
      content={
        <BaseForm
          layout="vertical"
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <S.Header>
            <BaseRow gutter={20} align={'top'} justify={'space-between'}>
              <BaseCol xl={8}>
                <S.FormItem name="categoryid">
                  <S.SelectBaseStyled
                    placeholder={t(R.strings.select_goods_category)}
                    suffixIcon={<SvgTableCloseIcon />}
                    onChange={value =>
                      handleChangeFilter({
                        ...filter,
                        categoryid: String(value || ""),
                      })
                    }
                    allowClear
                  >
                    {itemCategories?.map((category, index) => {
                      return (
                        <Option value={category?._id} key={index}>
                          {category?.name}
                        </Option>
                      )
                    })}
                  </S.SelectBaseStyled>
                </S.FormItem>
              </BaseCol>
              <BaseCol xl={8}>
                <S.FormItem name="search">
                  <InputSearch
                    width="100%"
                    placeholder={t(R.strings.enter_field, {
                      field: t(R.strings.goods_name),
                    })}
                    onChange={event => setSearch(event?.target?.value)}
                  />
                </S.FormItem>
              </BaseCol>
            </BaseRow>
          </S.Header>

          <S.Content>
            <BaseTableManagementWrapper
              columns={columns}
              dataSource={tableData.data}
              loading={tableData.loading}
              pagination={{ hideOnSinglePage: true }}
              onChange={handleTableChange}
              expandable={{
                expandIcon: () => null,
              }}
            />
          </S.Content>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.goods_add}
      confirmKeyI18n={R.strings.confirm}
      handleSubmit={form.submit}
      visible={visible}
    />
  )
}
