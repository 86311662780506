import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { CreateUpdateContext } from '../../context'
import Images from '../../components/Images'
import * as S from '../../styles'
import Info from '../../components/Info'
import { useParams } from 'react-router'
import ActionForm from '../../components/Acction'
import Properties from '../../components/Properties'

export const DefaultLayout = () => {
  const { type: typeParam } = useParams()
  return (
    <CreateUpdateContext.Consumer>
      {({ form, handleSubmit }) => {
        return (
          <S.RootWrapper direction="vertical" size={10}>
            <HeaderPage titleI18nKey={R.strings[`add_${typeParam}`]} />
            <BaseForm
              layout="vertical"
              onFinish={handleSubmit}
              requiredMark="optional"
              form={form}
              initialValues={{
                isInventoryManagement: true,
              }}
            >
              <S.RootWrapper direction="vertical" size={10}>
                <Info />
                <Properties />
                <Images />
                <ActionForm />
              </S.RootWrapper>
            </BaseForm>
          </S.RootWrapper>
        )
      }}
    </CreateUpdateContext.Consumer>
  )
}