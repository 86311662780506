import { MoreOutlined } from '@ant-design/icons'
import { BaseButton } from 'app/components/common/BaseButton'
import { BasePopover } from 'app/components/common/BasePopover'
import { ReactNode, useState } from 'react'

interface IProps {
  content?: ReactNode
}

export const ActionComponent: React.FC<IProps> = ({ content }) => {
  const [isOpened, setOpened] = useState(false)

  return (
    <div onClick={(event) => event?.stopPropagation()}>
      <BasePopover content={content} trigger="click" onOpenChange={setOpened}>
        <BaseButton
          type={isOpened ? 'link' : 'default'}
          icon={<MoreOutlined rev={undefined} />}
          onClick={event => {
            event?.stopPropagation()
          }}
        />
      </BasePopover>
    </div>
  )
}
