import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING, formatMoney } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  titleKeyI18n: string
  value?: number
  isDebtPayment?: boolean
}

export const CardPaymentItem = ({
  titleKeyI18n,
  value,
  isDebtPayment,
}: IProps) => {
  const { t } = useTranslation()
  return (
    <RootWrapper>
      <Title
        children={t(titleKeyI18n)}
        opacity="0.9"
        colorText="primaryColor"
        fontWeight="semibold"
      />
      <BaseText
        children={formatMoney(value ?? 0)}
        colorText={isDebtPayment ? 'statesRedColor' : 'primaryColor'}
        fontWeight="semibold"
        fontSize="lg"
      />
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseSpace)`
  padding: ${PADDING.xs};
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  background-color: #fdfdfd;
  min-width: 150px;
`

const Title = styled(BaseText)`
color: #365B7E; // need change
`