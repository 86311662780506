import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { SearchInput } from 'app/components/common/inputs/SearchInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  media,
} from 'parkway-web-common'
import styled from 'styled-components'

export const HeadWrapper = styled(BaseRow)`
  padding-bottom: ${PADDING.xs};
  .ant-form-item {
    margin-bottom: 0px;
  }
`
export const TitleTable = styled.span`
  font-size: ${FONT_SIZE.xs};
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.bold};
`
export const Picker = styled(BaseDatePicker)`
  min-width: 160px;
`

export const TextFilter = styled.span`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  color: var(--primary-color);
`

export const SelectBaseStyled = styled(BaseSelect)``

export const InputSearch = styled(SearchInput)`
  .ant-input-group-addon {
    display: none;
  }

  @media only screen and (${media.md}) {
    .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
      border-radius: ${BORDER_RADIUS};
      border-color: var(--neutral-black-color);
      padding: 0px ${PADDING.xxxxs};
    }
  }
`

export const SelectLabel = styled(BaseInput)`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  &.ant-input[disabled] {
    color: var(--text-main-color) !important;
    background: ${convertedVariables.backgroundColor} !important;
    border: 1px solid var(--inventory-border-color) !important;
    color: var(--inventory-text-color2) !important;
    width: 110px;
    max-width: 100%;
    text-align: left;
  }
`

export const SelectInputSearch = styled(BaseSelect)`
  .ant-select-arrow {
    top: 50% !important;
  }

  &.require {
    border: 1px solid var(--inventory-border-color2);
    border-radius: 7px;
  }
`

export const CustomDatePicker = styled(BaseDatePicker)`
  border: 1px solid var(--inventory-border-color) !important;

  input::placeholder,
  input::-ms-input-placeholder {
    color: var(--main-2-color);
    font-weight: 400;
    font-size: 0.875rem;
  }
`

export const CustomBaseCol = styled(BaseCol)`
  margin-top: 5px;
`

export const ColumnLine = styled.div`
  border-left: 1px solid var(--inventory-border-color) !important;
  margin-top: 5px;
  height: 20px;
`
