import { UploadFile } from 'antd'
import R from 'app/assets/R'
import { notificationController } from 'app/controllers/notification-controller'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IFolderImageParent, IImagePatient } from '../type/image-patient'

interface IProps {
  imagePatient: IImagePatient
  onChangeImagePatient: (newImagePatient: IImagePatient) => void
}

export const useImagePatient = ({
  imagePatient,
  onChangeImagePatient,
}: IProps) => {
  const parentFolders = useMemo(() => {
    return imagePatient?.parentFolder ?? []
  }, [imagePatient?.parentFolder])

  const onChangeParentData = (parentFolder: IFolderImageParent[]) => {
    onChangeImagePatient({
      parentFolder,
    })
  }

  const addImageParent = (newData: IFolderImageParent) => {
    const { t } = useTranslation()
    const itemExist = parentFolders?.find(item => item?.name === newData?.name)

    if (itemExist) {
      notificationController?.error({
        message: t(R.strings.exist_folder_name, { name: newData?.name }),
      })
      return
    }

    onChangeParentData(parentFolders?.concat([newData]))
  }

  const onChangeImageList = ({
    childName,
    parentName,
    listImage,
  }: {
    parentName: string
    childName: string
    listImage: UploadFile[]
  }) => {
    const newData = parentFolders?.map(parent => {
      if (parent?.name === parentName) {
        const child = parent?.child?.map(itm => {
          if (itm?.name === childName) {
            return {
              ...itm,
              urls: listImage,
            }
          }
          return itm
        })

        return {
          ...parent,
          child,
        }
      }
      return parent
    })

    onChangeParentData(newData)
  }

  const onDeleteChildImagePatient = ({
    parentName,
    childName,
  }: {
    parentName?: string
    childName?: string
  }) => {
    const newData = parentFolders?.map(parent => {
      if (parent?.name === parentName) {
        const child = parent?.child?.filter(itm => itm?.name !== childName)
        return {
          ...parent,
          child,
        }
      }
      return parent
    })

    onChangeParentData(newData)
  }

  const onDeleteParentImagePatient = ( parentName?: string) => {
    const newData = parentFolders?.filter(parent => parent?.name !== parentName)
    onChangeParentData(newData)
  }

  return {
    parentFolders,
    addImageParent,
    onChangeImageList,
    onDeleteChildImagePatient,
    onDeleteParentImagePatient,
  }
}
