import R from 'app/assets/R'
import { BaseLayoutReport } from 'app/components/common/BaseLayoutReport'
import { data } from './data'

const UtilitiesContainer = () => {
  return (
    <BaseLayoutReport
      data={data}
      titleI18nKey={R.strings.utilities}
    />
  )
}

export default UtilitiesContainer
