import {
  IParamsGetRefererReport,
  IRefererReport,
} from 'app/api/marketing/referer-report/model'
import R from 'app/assets/R'
import { getDetailPatientUrl } from 'app/common/helpers'
import BaseText from 'app/components/common/BaseText'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { initPagination } from 'app/constant'
import { useDebounce, usePagination } from 'app/hook'
import { useGetRefererReportList } from 'app/react-query/hook/referer-report'
import {
  Pagination,
  ResponseType,
  formatMoney,
  moment
} from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable, IFilterDataTableReport } from './type'

export const useHookTable = () => {
  const { t } = useTranslation()

  const [pagination, setPagination] = useState(initPagination)

  const [filter, setFilter] = useState<IFilterDataTableReport>({})

  const { flattenDataList } = usePagination()

  const filterDebounce = useDebounce<IFilterDataTableReport>(filter, 500)

  const params = useMemo(() => {
    let newParams: IParamsGetRefererReport = {
      page: pagination.current,
      pageSize: pagination.pageSize,
      pagesize: pagination.pageSize,
    }

    if (filterDebounce?.keyword) {
      newParams = { ...newParams, keyword: filterDebounce.keyword }
    }

    if (filterDebounce?.year && filterDebounce?.month) {
      const startDate = `${filterDebounce.year}-${filterDebounce.month}-01`
      const endDay = moment(startDate, 'YYYY-MM-DD').endOf('month').format('DD')
      const endDate = `${filterDebounce.year}-${filterDebounce.month}-${endDay}`
      newParams = { ...newParams, startDate, endDate }

      return newParams
    }

    if (filterDebounce?.year) {
      const startDate = `${filterDebounce.year}-01-01`
      const endDate = `${filterDebounce.year}-12-31`

      newParams = { ...newParams, startDate, endDate }

      return newParams
    }

    return newParams
  }, [filterDebounce, pagination])

  const { data: dataApi, isLoading } = useGetRefererReportList(params)

  const dataFlatten = useMemo(() => {
    const res: ResponseType<IRefererReport[]> = flattenDataList(dataApi)
    return res
  }, [dataApi])

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [dataFlatten])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (newFilter: IFilterDataTableReport) => {
    setFilter(newFilter)
    setPagination({ ...pagination, current: 1 })
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IDataTable>({
        title: '#',
        key: 'key',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.key}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.full_name_customer_introduce),
        key: 'refererPersonName',
        render: (_, record) => {
          return (
            <RenderValueTable
              value={record?.refererPersonName}
              type="OtherLink"
              hrefOtherLink={getDetailPatientUrl(record?._id ?? '')}
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.patient_code),
        key: 'refererPersonCode',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.refererPersonCode ?? '-'}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.number_of_customers_introduce),
        key: 'referredPersonTotal',
        render: (_, record) => {
          return (
            <BaseText
              children={formatMoney(record?.referredPersonTotal ?? 0, '')}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.value_of_customers_introduce),
        key: 'referredPaidTotal',
        render: (_, record) => {
          return (
            <BaseText
              children={formatMoney(record?.referredPaidTotal ?? 0)}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.number_of_commission_received),
        key: 'commissionTotal',
        render: (_, record) => {
          return (
            <BaseText
              children={formatMoney(record?.commissionTotal ?? 0)}
              fontWeight="semibold"
            />
          )
        },
      }),
    ]
    return option
  }, [t])

  return {
    data,
    columns,
    isLoading,
    filter,
    handleTableChange,
    pagination: {
      ...pagination,
      total: dataFlatten?.metadata?.totalItem ?? 0,
    },
    handleChangeFilter,
  }
}
