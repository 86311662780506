import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import DividerLine from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/DividerLine'
import { useTranslation } from 'react-i18next'
import {
  BoneLayout,
  EmergingIssuesSolutionsApproachLayout,
  FunctionLayout,
  ProceduresEquipment,
  TherapeuticLayout,
  ToothLayout,
  ToothSizeAndMorphology,
  TreatmentStagesLayout,
} from './layouts'
import { SectionWrapper, TableWrapper } from './styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'

export const DiagnosisLayout = () => {
  const { t } = useTranslation()
  return (
    <SectionWrapper>
      <div className="child-wrapper">
        <BaseText
          children={t(R.strings.treatment_plan)}
          fontWeight="semibold"
          fontSize="md"
        />
      </div>
      <DividerLine />
      <BaseSpace className="child-wrapper">
        <BaseText
          children={`1. ${t(R.strings.diagnosis)}`}
          fontWeight="semibold"
          fontSize="xs"
        />
        <TableWrapper>
          <BaseRow className="child-wrapper" gutter={10}>
            <BaseCol xl={3} />
            <BaseCol xl={7}>
              <BaseText
                children={t(R.strings.anteroposterior_direction_a)}
                fontWeight="semibold"
              />
            </BaseCol>
            <BaseCol xl={7}>
              <BaseText
                children={t(R.strings.vertical_direction_b)}
                fontWeight="semibold"
              />
            </BaseCol>
            <BaseCol xl={7}>
              <BaseText
                children={t(R.strings.vertical_direction_a)}
                fontWeight="semibold"
              />
            </BaseCol>
          </BaseRow>
          <DividerLine color={convertedVariables.neutralBlack2Color} />
          <BoneLayout />
          <DividerLine color={convertedVariables.neutralBlack2Color} />
          <ToothLayout />
          <DividerLine color={convertedVariables.neutralBlack2Color} />
          <FunctionLayout />
        </TableWrapper>
        <ToothSizeAndMorphology />
        <TherapeuticLayout />
        <ProceduresEquipment />
        <TreatmentStagesLayout />
        <EmergingIssuesSolutionsApproachLayout />
      </BaseSpace>
    </SectionWrapper>
  )
}
