import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import {
  ListBottomToothKey,
  ListTopToothKey,
} from 'app/containers/DentalTreatmentManagement/TreatmentPlan/constant'
import { useTreatmentPlanContext } from 'app/containers/DentalTreatmentManagement/TreatmentPlan/context'
import { PositionToothEnum } from 'app/containers/DentalTreatmentManagement/TreatmentPlan/type'
import { BORDER_RADIUS } from 'parkway-web-common'
import styled from 'styled-components'
import { ImplantAreaBottom, ImplantAreaTop } from '../../../../components'
import { ItemToothResultTabComponent } from '../../components'

export const ResultTab = () => {
  const {
    bridgeToothBottomTabResult,
    toothSizeAndMorphologyTabResult,
    bridgeToothTopTabResult,
  } = useTreatmentPlanContext()

  return (
    <ListWrapper size={30}>
      <ListToothWrapper
        gutter={10}
        wrap={false}
        align={'middle'}
        justify={'center'}
      >
        {ListTopToothKey?.map((key, index) => {
          const item =
            toothSizeAndMorphologyTabResult?.[PositionToothEnum.Top]?.[key]
          return (
            <BaseCol key={index}>
              <ItemToothResultTabComponent item={item} index={index} />
            </BaseCol>
          )
        })}
        {bridgeToothTopTabResult?.map(itm => {
          if (itm?.indexStart !== undefined && itm?.indexEnd !== undefined) {
            return (
              <ImplantAreaTop start={itm?.indexStart} end={itm?.indexEnd} />
            )
          }
          return null
        })}
      </ListToothWrapper>

      <ListToothWrapper
        gutter={10}
        wrap={false}
        align={'middle'}
        justify={'center'}
      >
        {ListBottomToothKey?.map((key, index) => {
          const item =
            toothSizeAndMorphologyTabResult?.[PositionToothEnum.Bottom]?.[key]
          return (
            <BaseCol key={index}>
              <ItemToothResultTabComponent item={item} index={index} />
            </BaseCol>
          )
        })}
        {bridgeToothBottomTabResult?.map(itm => {
          if (itm?.indexStart !== undefined && itm?.indexEnd !== undefined) {
            return (
              <ImplantAreaBottom start={itm?.indexStart} end={itm?.indexEnd} />
            )
          }
          return null
        })}
      </ListToothWrapper>
    </ListWrapper>
  )
}

const ListWrapper = styled(BaseSpace)`
  background-color: #f8f8f8;
  border-radius: ${BORDER_RADIUS};
  align-items: left;
  scroll-behavior: auto;
  overflow-x: auto;

  @media only screen and ${'(min-width: 1380px)'} {
    align-items: center;
  }
`

const ListToothWrapper = styled(BaseRow)`
  position: relative;
  width: 1027px;
`
