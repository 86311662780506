import { BaseButton } from 'app/components/common/BaseButton'
import styled from 'styled-components'

export const PrintButton = styled(BaseButton)`
  padding: 8px 12px 8px 12px;
  gap: 6px;
  border-radius: 8px;
  opacity: 0px;
  background: linear-gradient(316.55deg, #2f7eff 0%, #659fff 100%);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: white;
`
