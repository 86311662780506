import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import { useTable } from './hook'
import { FilterLayout } from './layouts'
import { BaseTableReport, HeadPageWrapper, RootWrapper } from './styles'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { Loading } from 'app/components/common/Loading'

export const PatientSearchContainer = () => {
  const {
    filter,
    handleChangeFilter,
    data,
    columns,
    isLoading,
    handleTableChange,
    pagination,
    isLoadingReport,
    handleExportExcel,
  } = useTable()
  const { isHavePermissionFunction } = useVerifyPermissionAndRedirect()
  return (
    <RootWrapper>
      <HeadPageWrapper
        titleI18nKey={R.strings.patient_search}
        isExport={isHavePermissionFunction(
          ModulePermissionEnum.DENTAL_TREATMENT_MANAGEMENT,
          FunctionPermissionEnum.EXPORT_PATIENT_SEARCH,
        )}
        onPressExport={handleExportExcel}
      />

      <SectionBase style={{ position: 'relative' }}>
        <BaseSpace>
          <FilterLayout formData={filter} onChange={handleChangeFilter} />
          {!isLoadingReport ? (
            <BaseTableReport
              columns={columns}
              dataSource={data}
              pagination={pagination}
              loading={isLoading}
              onChange={handleTableChange}
              rowClassName="row-overwrite-style"
              scroll={{
                x: true,
              }}
            />
          ) : (
            <Loading isAbsolute />
          )}
        </BaseSpace>
      </SectionBase>
    </RootWrapper>
  )
}
