/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import React from 'react'
import { useTableHook } from './hook'

export const RevenueTable: React.FC = () => {
  const {
    columns,
    tableData,
    handleTableChange,
    expandedRowKeys,
    handleExpand,
  } = useTableHook()

  return (
    <>
      <BaseTableReport
        columns={columns}
        dataSource={tableData.data}
        loading={tableData.loading}
        onChange={handleTableChange}
        expandable={{
          expandedRowKeys,
          onExpand: handleExpand,
          defaultExpandAllRows: true,
          expandIcon: () => null,
          rowExpandable: record =>
            record.children && record.children.length > 0,
        }}
        rowClassName="row-overwrite-style"
        pagination={false}
        scroll={{ x: true }}
      />
    </>
  )
}
