import { StatusTableType } from 'app/components/tables/BaseTableManagement/hook'
import dayjs, { Dayjs } from 'dayjs'

export interface IResponseFilter {
  status?: StatusTableType[]
  search?: string
  startDate: Dayjs,
  endDate: Dayjs,
  location?: string
}

export interface IFilterProps {
  handleChangeFilter?: (data: IResponseFilter) => void
  filterData?: IResponseFilter
}

export const initResponseFilterTableManagement = {
  search: '',
  startDate: dayjs(),
  endDate: dayjs()
}
