import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import CreateVoucherContainer from 'app/containers/Marketing/VoucherManagement/CreateVoucher'

const CreateVoucherPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.create_voucher)}</PageTitle>
      <CreateVoucherContainer />
    </>
  )
}
const CreateVoucherPage = memo(CreateVoucherPageCpn, isEqual)

export default CreateVoucherPage
