import {
  SvgCalendarDesignIcon,
  SvgTableCloseIcon,
  SvgTableOpenIcon,
} from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { StatusAppointmentSessionTreatmentPlanEnumDefine } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/constant/treatment-phase'
import {
  IAppointmentSession,
  ITreatmentInAppointmentSession,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import styled from 'styled-components'
import {
  PaymentInformationLayout,
  TreatmentInformationLayout,
  TreatmentsLayout,
} from './layouts'
import { isEqual } from 'lodash'

export const AppointmentSession = ({
  appointmentSessions,
  onChangeAppointmentSession,
}: {
  appointmentSessions: IAppointmentSession[]
  onChangeAppointmentSession?: ({
    newAppointmentSession,
  }: {
    newAppointmentSession: IAppointmentSession[]
  }) => void
}) => {
  const _onChangeAppointmentSession = ({
    appointmentSessionId,
    newTreatments,
  }: {
    appointmentSessionId?: string
    newTreatments?: ITreatmentInAppointmentSession[]
  }) => {
    const newAppointmentSession = appointmentSessions?.map(item => {
      if (isEqual(item?.id, appointmentSessionId)) {
        return {
          ...item,
          treatmentInAppointmentSession: newTreatments,
        }
      }
      return item
    })
    onChangeAppointmentSession?.({
      newAppointmentSession,
    })
  }

  return (
    <BaseSpace>
      {appointmentSessions?.map((item, index) => {
        const dataAppointment =
          StatusAppointmentSessionTreatmentPlanEnumDefine?.[item?.status]

        return (
          <CollapseWrapper
            key={index}
            $backgroundColor={dataAppointment?.backgroundColor}
            $color={dataAppointment?.color}
            items={[
              {
                label: (
                  <BaseRow justify={'space-between'} align={'middle'}>
                    <BaseRow align={'middle'} gutter={15}>
                      <CalendarOutlinedIcon $color={dataAppointment?.color} />
                      <BaseCol>
                        <TitleText
                          children={item?.name}
                          fontWeight="semibold"
                          fontSize="xs"
                          $color={dataAppointment?.color}
                        />
                      </BaseCol>
                    </BaseRow>
                  </BaseRow>
                ),
                key: index,
                extra: (
                  <BaseText
                    children={item?.timeStartTreatment}
                    opacity="0.5"
                    fontWeight="medium"
                    fontSize="xxs"
                  />
                ),
                children: (
                  <BaseSpace size={20}>
                    <TreatmentInformationLayout item={item} />
                    <PaymentInformationLayout item={item} />
                    <TreatmentsLayout
                      treatments={item?.treatmentInAppointmentSession ?? []}
                      onAddTreatmentInAppointmentSession={({ newTreatments }) =>
                        _onChangeAppointmentSession?.({
                          appointmentSessionId: item?.id,
                          newTreatments,
                        })
                      }
                    />
                  </BaseSpace>
                ),
              },
            ]}
            expandIcon={({ isActive }) =>
              !isActive ? (
                <SvgTableCloseIcon />
              ) : (
                <SvgTableOpenIcon rotate={isActive ? 90 : 0} />
              )
            }
            expandIconPosition="end"
          />
        )
      })}
    </BaseSpace>
  )
}

const CollapseWrapper = styled(BaseCollapse)<{
  $backgroundColor: string
  $color: string
}>`
  border: 0px;

  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-content {
    border-top: 0px;
  }

  .ant-collapse-header {
    background-color: ${props => props?.$backgroundColor};
  }
`
const TitleText = styled(BaseText)<{
  $color: string
}>`
  color: ${props => props?.$color};
`
const CalendarOutlinedIcon = styled(SvgCalendarDesignIcon)<{
  $color: string
}>`
  path {
    color: ${props => props?.$color};
    fill: ${props => props?.$color} !important;
  }
`
