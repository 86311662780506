import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { initFilterTable } from 'app/hook'
import { isEqual } from 'lodash'
import { SelectFilterEnum, initialPagination } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTableMonthlyHook } from './Monthly/hook'
import { useTableWeeklyHook } from './Weekly/hook'

export const useTableHook = () => {
  const { t } = useTranslation()

  const [filterResponse, setFilterResponse] = useState<IResponseFilter>(
    initFilterTable(),
  )

  const hookWeekly = useTableWeeklyHook({
    year: filterResponse?.year,
    location: filterResponse?.selectFilterType,
    month: filterResponse?.month,
  })

  const hookMonthly = useTableMonthlyHook({
    year: filterResponse.year,
    location: filterResponse?.selectFilterType,
  })

  const expandedRowKeys = useMemo(() => {
    switch (filterResponse?.selectFilterTypeSecond) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.expandedRowKeys
      case SelectFilterEnum.MONTH:
        return hookMonthly.expandedRowKeys
      default:
        return hookWeekly.expandedRowKeys
    }
  }, [filterResponse?.selectFilterTypeSecond, hookWeekly, hookMonthly])

  const tableData = useMemo(() => {
    switch (filterResponse?.selectFilterTypeSecond) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.tableData
      case SelectFilterEnum.MONTH:
        return hookMonthly.tableData
      default:
        return hookWeekly.tableData
    }
  }, [filterResponse?.selectFilterTypeSecond, hookWeekly, hookMonthly])

  const handleTableChange = useMemo(() => {
    switch (filterResponse?.selectFilterTypeSecond) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.handleTableChange
      case SelectFilterEnum.MONTH:
        return hookMonthly.handleTableChange
      default:
        return hookWeekly.handleTableChange
    }
  }, [filterResponse?.selectFilterTypeSecond, hookWeekly, hookMonthly])

  const handleExpand = useMemo(() => {
    switch (filterResponse?.selectFilterTypeSecond) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.handleExpand
      case SelectFilterEnum.MONTH:
        return hookMonthly.handleExpand
      default:
        return hookWeekly.handleExpand
    }
  }, [filterResponse?.selectFilterTypeSecond, hookWeekly, hookMonthly])

  const handleChangeFilter = (filter: IResponseFilter) => {
    const { year, selectFilterType, month } = filter
    setFilterResponse(filter)
    if (year && selectFilterType && month) {
      if (
        !isEqual(
          { year, selectFilterType, month },
          {
            year: filterResponse?.year,
            selectFilterType: filterResponse?.selectFilterType,
            month: filterResponse?.month,
          },
        )
      ) {
        hookWeekly.fetch(initialPagination, {
          year,
          location: selectFilterType,
          month,
        })
      }
    }

    if (year && selectFilterType) {
      if (
        !isEqual(
          { year, selectFilterType },
          {
            year: filterResponse?.year,
            selectFilterType: filterResponse?.selectFilterType,
          },
        )
      ) {
        hookMonthly.fetch(initialPagination, {
          year,
          location: selectFilterType,
        })
      }
    }
  }

  const columns = useMemo(() => {
    switch (filterResponse?.selectFilterTypeSecond) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.columns
      case SelectFilterEnum.MONTH:
        return hookMonthly.columns
      default:
        return hookWeekly.columns
    }
  }, [
    t,
    expandedRowKeys,
    filterResponse?.selectFilterTypeSecond,
    filterResponse?.month,
  ])

  const exportExcel = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.WEEK:
        return hookWeekly.exportExcel
      case SelectFilterEnum.MONTH:
        return hookMonthly.exportExcel
      default:
        return hookWeekly.exportExcel
    }
  }, [filterResponse])

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleChangeFilter,
    filterResponse,
    exportExcel
  }
}
