/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetUnitManagement } from 'app/api/unit'
import { UnitMessageCode } from 'app/api/unit/constant'
import {
  IParamsGetUnitManagement,
  IUnit,
} from 'app/api/unit/model/unit-management'
import { ResponseTypeV2 } from 'app/common/model'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { useInfiniteQuery } from 'react-query'
import { IResponseQueryListNoTotal } from '../model/common'
import { UnitKeys } from '../query-key/unit'
import { IMetadata } from 'parkway-web-common'

export function useGetUnitList(
  params?: IParamsGetUnitManagement,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<
    IResponseQueryListNoTotal<{
      rows: IUnit[]
      metadata?: IMetadata
      total?: number
    }>,
    undefined
  >(
    UnitKeys.listUnit(params),
    async () => {
      try {
        const res: ResponseTypeV2<{
          rows: IUnit[]
          metadata: IMetadata
          total: number
        }> = await requestGetUnitManagement({
          ...params,
        })

        if (
          isEqual(res?.msgcode, UnitMessageCode.UnitManagement.successGetData)
        ) {
          const data: {
            rows: IUnit[]
            metadata: IMetadata
            total: number
          } = res?.data

          return {
            data,
            total: res.data?.total || 0,
          }
        }
        return {
          data: {
            rows: [],
          },
          total: 0,
        }
      } catch (error: any) {
        return {
          data: {
            rows: [],
          },
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
