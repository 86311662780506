import { withLoading } from 'app/hocs/with-loading.hoc'
import { Route } from 'react-router-dom'
import {
  INVENTORY_CATEGORY_PATH,
  INVENTORY_MANAGEMENT_PATH,
  INVENTORY_MATERIAL_CATEGORY_PATH,
  INVENTORY_MATERIAL_EXPORT_PATH,
  INVENTORY_MATERIAL_IMPORT_PATH,
  INVENTORY_PROVIDER_PATH,
  INVENTORY_SUPPLIER_PATH,
  INVENTORY_WAREHOUSE_CATEGORY_PATH,
  INVENTORY_WAREHOUSE_GOODS_ISSUE_CREATE_PATH,
  INVENTORY_WAREHOUSE_GOODS_ISSUE_FOR_UNIT_CREATE_PATH,
  INVENTORY_WAREHOUSE_GOODS_ISSUE_FOR_UNIT_PATH,
  INVENTORY_WAREHOUSE_GOODS_ISSUE_PATH,
  INVENTORY_WAREHOUSE_GOODS_ISSUE_RECEIPT_PATH,
  INVENTORY_WAREHOUSE_GOODS_ISSUE_RETURN_PATH,
  INVENTORY_WAREHOUSE_GOODS_ISSUE_VIEW_PATH,
  INVENTORY_WAREHOUSE_INVENTORY_PATH,
  INVENTORY_WAREHOUSE_PURCHASE_CREATE_PATH,
  INVENTORY_WAREHOUSE_PURCHASE_GOODS_RECEIPT_PATH,
  INVENTORY_WAREHOUSE_PURCHASE_PATH,
  INVENTORY_WAREHOUSE_PURCHASE_RE_ORDER_PATH,
  INVENTORY_WAREHOUSE_PURCHASE_UPDATE_PATH,
  INVENTORY_WAREHOUSE_PURCHASE_VIEW_PATH,
} from './route-path'

import InventoryManagementPage from 'app/page/menu/inventory-management'
import TreatmentCategoryContainer from 'app/containers/InventoryManagement/ItemCategory'
import ProviderContainerPage from 'app/page/menu/inventory-management/provider'
import MaterialImportContainerPage from 'app/page/menu/inventory-management/material-import'
import MaterialExportContainerPage from 'app/page/menu/inventory-management/material-export'
import MaterialCategoryContainerPage from 'app/page/menu/inventory-management/material-category'
import SupplierContainerPage from 'app/page/menu/inventory-management/supplier'
import WarehouseContainerPage from 'app/page/menu/inventory-management/warehouse'
import WarehousePurchaseContainerPage from 'app/page/menu/inventory-management/warehouse-purchase'
import CreateWarehousePurchaseContainerPage from 'app/page/menu/inventory-management/create-warehouse-purchase'
import UpdateWarehousePurchaseContainerPage from 'app/page/menu/inventory-management/upload-warehouse-purchase'
import CreateWarehouseGoodsReceiptContainerPage from 'app/page/menu/inventory-management/create-warehouse-goods-receipt'
import ReorderWarehousePurchaseContainerPage from 'app/page/menu/inventory-management/reorder-warehouse-purchase'
import WarehouseInventoryContainerPage from 'app/page/menu/inventory-management/warehouse-inventory'
import CreateWarehouseGoodsIssueContainerPage from 'app/page/menu/inventory-management/ create-warehouse-goods-issue'
import ViewWarehouseGoodsIssueContainerPage from 'app/page/menu/inventory-management/ view-warehouse-goods-issue'
import ReturnWarehouseGoodsIssueContainerPage from 'app/page/menu/inventory-management/ return-warehouse-goods-issue'
import CreateGoodsIssueReceiptContainerPage from 'app/page/menu/inventory-management/create-goods-issue-receipt'
import WarehouseGoodsIssueContainerPage from 'app/page/menu/inventory-management/warehouse-goods-issue'

const InventoryManagement = withLoading(InventoryManagementPage)
const ProviderContainer = withLoading(ProviderContainerPage)
const MaterialImportContainer = withLoading(MaterialImportContainerPage)
const MaterialExportContainer = withLoading(MaterialExportContainerPage)
const MaterialCategoryContainer = withLoading(MaterialCategoryContainerPage)
const SupplierContainer = withLoading(SupplierContainerPage)
const WarehouseContainer = withLoading(WarehouseContainerPage)
const WarehousePurchaseContainer = withLoading(WarehousePurchaseContainerPage)
const CreateWarehousePurchaseContainer = withLoading(
  CreateWarehousePurchaseContainerPage,
)
const UpdateWarehousePurchaseContainer = withLoading(
  UpdateWarehousePurchaseContainerPage,
)
const CreateWarehouseGoodsReceiptContainer = withLoading(
  CreateWarehouseGoodsReceiptContainerPage,
)
const ReorderWarehousePurchaseContainer = withLoading(
  ReorderWarehousePurchaseContainerPage,
)
const WarehouseInventoryContainer = withLoading(WarehouseInventoryContainerPage)
const WarehouseGoodsIssueContainer = withLoading(
  WarehouseGoodsIssueContainerPage,
)
const CreateWarehouseGoodsIssueContainer = withLoading(
  CreateWarehouseGoodsIssueContainerPage,
)
const ViewWarehouseGoodsIssueContainer = withLoading(
  ViewWarehouseGoodsIssueContainerPage,
)
const ReturnWarehouseGoodsIssueContainer = withLoading(
  ReturnWarehouseGoodsIssueContainerPage,
)
const CreateGoodsIssueReceiptContainer = withLoading(
  CreateGoodsIssueReceiptContainerPage,
)

export const InventoryManagementRouter = () => {
  return (
    <>
      <Route
        path={INVENTORY_MANAGEMENT_PATH}
        element={<InventoryManagement />}
      />
      <Route
        path={INVENTORY_CATEGORY_PATH}
        element={<TreatmentCategoryContainer />}
      />

      <Route path={INVENTORY_PROVIDER_PATH} element={<ProviderContainer />} />

      <Route
        path={INVENTORY_MATERIAL_IMPORT_PATH}
        element={<MaterialImportContainer />}
      />
      <Route
        path={INVENTORY_MATERIAL_EXPORT_PATH}
        element={<MaterialExportContainer />}
      />
      <Route
        path={INVENTORY_MATERIAL_CATEGORY_PATH}
        element={<MaterialCategoryContainer />}
      />
      <Route path={INVENTORY_SUPPLIER_PATH} element={<SupplierContainer />} />
      <Route
        path={INVENTORY_WAREHOUSE_CATEGORY_PATH}
        element={<WarehouseContainer />}
      />
      <Route
        path={INVENTORY_WAREHOUSE_PURCHASE_PATH}
        element={<WarehousePurchaseContainer />}
      />
      <Route
        path={INVENTORY_WAREHOUSE_PURCHASE_CREATE_PATH}
        element={<CreateWarehousePurchaseContainer />}
      />
      <Route
        path={INVENTORY_WAREHOUSE_PURCHASE_UPDATE_PATH}
        element={<UpdateWarehousePurchaseContainer />}
      />
      <Route
        path={INVENTORY_WAREHOUSE_PURCHASE_VIEW_PATH}
        element={<UpdateWarehousePurchaseContainer />}
      />

      <Route
        path={INVENTORY_WAREHOUSE_PURCHASE_GOODS_RECEIPT_PATH}
        element={<CreateWarehouseGoodsReceiptContainer />}
      />

      <Route
        path={INVENTORY_WAREHOUSE_PURCHASE_RE_ORDER_PATH}
        element={<ReorderWarehousePurchaseContainer />}
      />

      <Route
        path={INVENTORY_WAREHOUSE_INVENTORY_PATH}
        element={<WarehouseInventoryContainer />}
      />

      <Route
        path={INVENTORY_WAREHOUSE_GOODS_ISSUE_PATH}
        element={<WarehouseGoodsIssueContainer />}
      />

      <Route
        path={INVENTORY_WAREHOUSE_GOODS_ISSUE_FOR_UNIT_PATH}
        element={<WarehouseGoodsIssueContainer />}
      />

      <Route
        path={INVENTORY_WAREHOUSE_GOODS_ISSUE_CREATE_PATH}
        element={<CreateWarehouseGoodsIssueContainer />}
      />
      <Route
        path={INVENTORY_WAREHOUSE_GOODS_ISSUE_FOR_UNIT_CREATE_PATH}
        element={<CreateWarehouseGoodsIssueContainer />}
      />
      <Route
        path={INVENTORY_WAREHOUSE_GOODS_ISSUE_VIEW_PATH}
        element={<ViewWarehouseGoodsIssueContainer />}
      />
      <Route
        path={INVENTORY_WAREHOUSE_GOODS_ISSUE_RECEIPT_PATH}
        element={<CreateGoodsIssueReceiptContainer />}
      />

      <Route
        path={INVENTORY_WAREHOUSE_GOODS_ISSUE_RETURN_PATH}
        element={<ReturnWarehouseGoodsIssueContainer />}
      />
    </>
  )
}
