/* eslint-disable @typescript-eslint/no-explicit-any */
import { MoreOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import { scheduleStatusSearchAppointmentFilter } from 'app/api/appointment/constant'
import { SCHEDULE_CALLCENTER } from 'app/api/appointment/model'
import R from 'app/assets/R'
import { DATE, SearchAppointmentMenuAction } from 'app/common/constants'
import {
  APPOINTMENT_VIEW_TYPE,
  SEARCH_APPOINTMENT_ACTION,
} from 'app/common/enum'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IParamsHookReport } from 'app/components/tables/BaseTableReport/type'
import {
  AppointmentStatusDone,
  ListAppointmentStatus,
  ListTotal,
  initPagination,
} from 'app/constant'
import { AppointmentContext } from 'app/context/AppointmentContext'
import { PageSearchAppointmentContext } from 'app/context/PageSearchAppointmentContext'
import dayjs from 'dayjs'
import { Pagination, moment } from 'parkway-web-common'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EScheduleStatus, IAppointment } from '../AppointmentPage/type'
import CreatedBy from './CreatedBy'
import {
  AuthorInfo,
  AuthorJobName,
  AuthorName,
  AuthorUserLeft,
  SMSColumn,
  SelectStatus,
  TableAvatar,
} from './styles'

export const useCommonHook = ({
  currentFilter,
  fetch,
}: {
  fetch: (pagination: Pagination, params?: IParamsHookReport) => void
  currentFilter?: IParamsHookReport
}) => {
  const { updateAppointment } = useContext(AppointmentContext)
  const {
    setOpenCreateUpdate,
    setAppointment,
    setOpenConfirmModal,
    setCancelAppointmentItem,
  } = useContext(PageSearchAppointmentContext)
  const { getCallCenterScheduleTotal, sendSmsAppointment } =
    useContext(AppointmentContext)

  const getScheduleTotal = currentFilter => {
    getCallCenterScheduleTotal?.({
      ...currentFilter,
      startDate: moment(currentFilter?.startDate)?.format(
        DATE.YYYY_MM_DD_HH_MM,
      ),
      endDate: moment(currentFilter?.endDate).format(DATE.YYYY_MM_DD_HH_MM),
      view: APPOINTMENT_VIEW_TYPE.LIST,
      scheduleStatus: scheduleStatusSearchAppointmentFilter,
    })
  }

  const onChangeStatus = async (
    value,
    appointment: IAppointment,
    currentFilter,
  ) => {
    const result = await updateAppointment?.(
      {
        ...appointment,
        scheduleStatus: value,
      },
      appointment?._id,
    )

    if (Object.keys(result?.data).length) {
      fetch(initPagination, currentFilter)
      getScheduleTotal(currentFilter)
    }
  }

  const { t } = useTranslation()

  const fetchCallback = useCallback(() => {
    if (Object.keys(currentFilter || {}).length) {
      getScheduleTotal(currentFilter)
    }
    return fetch(initPagination, currentFilter)
  }, [currentFilter])

  const handleSelectMenu = (type, record) => {
    switch (type) {
      case SEARCH_APPOINTMENT_ACTION.ADD: {
        setOpenCreateUpdate(true)
        const newRecord = { ...record }
        delete newRecord._id
        setAppointment({
          ...newRecord,
          areaId: newRecord?.area?._id,
          clinicId: newRecord?.clinic?._id,
          services: newRecord?.services?.map(service => service?._id),
          treatments: newRecord?.treatments?.map(treatment => treatment?._id),
          date: moment(newRecord?.date),
          fetch: fetchCallback,
          milliseconds: moment().valueOf(),
          type: SCHEDULE_CALLCENTER,
        })
        break
      }
      case SEARCH_APPOINTMENT_ACTION.UPDATE: {
        setOpenCreateUpdate(true)
        setAppointment({
          ...record,
          areaId: record?.area?._id,
          clinicId: record?.clinic?._id,
          services: record?.services?.map(service => service?._id),
          treatments: record?.treatments?.map(treatment => treatment?._id),
          date: dayjs(record?.date),
          fetch: fetchCallback,
        })
        break
      }
      case SEARCH_APPOINTMENT_ACTION.SEND: {
        sendSmsAppointment?.({ _id: record?._id })
        break
      }
      case SEARCH_APPOINTMENT_ACTION.CANCEL: {
        setOpenConfirmModal(true)
        setCancelAppointmentItem({
          _id: record?._id,
          date: moment(record?.date),
          fullName: record?.fullName,
          scheduleStatus: EScheduleStatus.CANCEL,
          fetch: fetchCallback,
        })
        break
      }
      default: {
        break
      }
    }
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<any>({
        title: t(R.strings.ordinal_number),
        key: 'ordinal_number',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => (
          <RenderValueTable value={record.index} type="Main" />
        ),
      }),
      convertColumnTable({
        title: t(R.strings.search_appointment_date_time),
        key: 'date',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IAppointment) => (
          <RenderValueTable
            value={` ${moment(record.date).format(DATE.DD_MM_YYYY)} ${
              record.time
            }`}
            type="Base"
          />
        ),
      }),
      convertColumnTable({
        title: t(R.strings.search_appointment_patient),
        key: 'fullName',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IAppointment) => (
          <RenderValueTable value={record.fullName} type="Main" />
        ),
      }),
      convertColumnTable({
        title: t(R.strings.phone),
        key: 'phone',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IAppointment) => (
          <RenderValueTable value={record.phone} type="Base" />
        ),
      }),
      convertColumnTable({
        title: t(R.strings.search_appointment_dob),
        key: 'patient',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IAppointment) => (
          <RenderValueTable
            value={
              record?.patient?.dob
                ? moment(record?.patient?.dob).format(DATE.DD_MM_YYYY)
                : ''
            }
            type="Base"
          />
        ),
      }),
      convertColumnTable({
        title: t(R.strings.search_appointment_doctor),
        key: 'doctor',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IAppointment) => (
          <AuthorUserLeft>
            <TableAvatar
              alt={record?.doctor?.name}
              src={record?.doctor?.avatar}
            />
            <AuthorInfo>
              <AuthorName>{record?.doctor?.name}</AuthorName>
              <AuthorJobName>{record?.doctor?.jobTitle}</AuthorJobName>
            </AuthorInfo>
          </AuthorUserLeft>
        ),
      }),
      convertColumnTable<any>({
        title: t(R.strings.search_appointment_doctor_shift),
        key: 'doctor',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IAppointment) => (
          <AuthorUserLeft>
            <TableAvatar
              alt={record?.doctor?.name}
              src={record?.doctor?.avatar}
            />
            <AuthorInfo>
              <AuthorName>{record?.doctor?.name}</AuthorName>
              <AuthorJobName>{record?.doctor?.jobTitle}</AuthorJobName>
            </AuthorInfo>
          </AuthorUserLeft>
        ),
      }),
      convertColumnTable({
        title: t(R.strings.search_appointment_location),
        key: 'clinic',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IAppointment) => (
          <RenderValueTable value={record?.clinic?.name} type="Base" />
        ),
      }),
      convertColumnTable({
        title: t(R.strings.search_appointment_treatment),
        key: 'reason',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <RenderValueTable value={record?.reason} type="Base" />
        ),
      }),
      convertColumnTable({
        title: t(R.strings.note_label),
        key: 'note',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.note} type="Base" />
        ),
      }),

      convertColumnTable({
        title: t(R.strings.search_appointment_send_sms),
        key: 'sms',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => <SMSColumn>{record?.sms}</SMSColumn>,
      }),
      convertColumnTable({
        title: t(R.strings.created_by),
        key: 'created_by',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => <CreatedBy record={record} />,
      }),
      convertColumnTable({
        title: t(R.strings.created_at),
        key: 'createdAt',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={moment(record?.createdAt).format(DATE.DD_MM_YYYY)}
            type="Base"
          />
        ),
      }),
      convertColumnTable({
        title: t(R.strings.customer_source),
        key: 'utmSource',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          return (
            <RenderValueTable
              value={record?.utmSource}
              type="Base"
            />
          )
        },
      }),
      convertColumnTable({
        title: t(R.strings.status),
        key: 'status',
        classNameWidthColumnOverwrite: 'normal-column',
        className: 'status-column',
        fixed: 'right',
        render: (_, record: IAppointment) => {
          const status = [...ListTotal, AppointmentStatusDone].find(
            item => item?.status === record?.scheduleStatus,
          )
          return (
            <SelectStatus
              placeholder={status?.label}
              style={{ width: 155 }}
              onChange={e => onChangeStatus(e, record, currentFilter)}
              background={
                record?.scheduleStatus === EScheduleStatus.SCHEDULE
                  ? ''
                  : status?.border
              }
              border={
                record?.scheduleStatus === EScheduleStatus.SCHEDULE
                  ? ''
                  : status?.border
              }
              defaultValue={null}
              value={null}
              className="selected-status"
              options={ListAppointmentStatus.map(status => ({
                value: status?.status,
                label: status?.label,
              }))}
            />
          )
        },
      }),
      convertColumnTable<{
        action: string
      }>({
        title: '',
        key: 'action',
        classNameWidthColumnOverwrite: 'small-column',
        className: 'status-column',
        fixed: 'right',
        render: (_, record) => {
          const items = SearchAppointmentMenuAction.map((action, index) => {
            return {
              key: index,
              label: (
                <div onClick={() => handleSelectMenu(action?.type, record)}>
                  {t(action?.label)}
                </div>
              ),
            }
          })

          return (
            <Dropdown menu={{ items }} placement="bottomLeft">
              <MoreOutlined rev="true" style={{ fontSize: 20 }} />
            </Dropdown>
          )
        },
      }),
    ]
  }, [t, currentFilter])

  return {
    columns,
  }
}
