import React from 'react'
import { BaseButton, BaseButtonProps } from 'app/components/common/BaseButton'
import { useTranslation } from 'react-i18next'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'

interface BaseButtonsGroupProps extends BaseButtonProps {
  className?: string
  onCancel: () => void
  loading?: boolean
}

export const BaseButtonsGroup: React.FC<BaseButtonsGroupProps> = ({
  className,
  onCancel,
  loading,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <BaseRow className={className} gutter={[10, 10]} wrap={false}>
      <BaseCol span={12}>
        <BaseButton block type="primary" onClick={onCancel} {...props}>
          {t('cancel')}
        </BaseButton>
      </BaseCol>
      <BaseCol span={12}>
        <BaseButton
          block
          type="primary"
          loading={loading}
          htmlType="submit"
          {...props}
        >
          {t('save')}
        </BaseButton>
      </BaseCol>
    </BaseRow>
  )
}
