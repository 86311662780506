import { requestGetOrganizationalChart } from 'app/api/company/organizational-chart'
import { ResponseOrganizationalChartType } from 'app/api/company/organizational-chart/model'
import R from 'app/assets/R'
import {
  ExpandedTableValue,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import { initPagination } from 'app/constant'
import { Pagination, moment } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFilterMonthlyRosterReport, IMonthlyRoster } from './type'

export const useHookTable = () => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState(initPagination)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IMonthlyRoster[]>([])
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

  const handleExpand = (expanded: boolean, newKey?: number) => {
    if (!newKey) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const [filter, setFilter] = useState<IFilterMonthlyRosterReport>({
    year: moment().year().toString(),
    month:
      moment().month() + 1 > 9
        ? `${moment().month() + 1}`
        : `0${moment().month() + 1}`,
    jobTitleId: undefined,
  })

  const getData = async () => {
    try {
      setLoading(true)

      const res: ResponseOrganizationalChartType =
        await requestGetOrganizationalChart({})

      res?.data &&
        setData(
          res.data?.map((item, index) => ({
            ...item,
            key: index + 1,
            children: undefined,
          })),
        )
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IMonthlyRoster>({
        title: t(R.strings.department),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)
          const _handleExpand = () => {
            return handleExpand(!isExpanded, record?.key)
          }

          const style: React.CSSProperties | undefined = isExpanded
            ? { position: 'relative', marginTop: -16, paddingBottom: 15 }
            : undefined

          return (
            <ExpandedTableValue
              isExpanded={isExpanded}
              handleExpand={_handleExpand}
              isHaveAreaWhenNotHaveActionExpanded
              style={style}
              alignContainer="middle"
              value={record?.name}
            />
          )
        },
      }),
    ]
    return option
  }, [t, expandedRowKeys])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (newFilter: IFilterMonthlyRosterReport) => {
    setFilter(newFilter)
  }

  return {
    tableData: {
      data,
    },
    columns,
    loading,
    filter,
    handleTableChange,
    pagination,
    handleChangeFilter,
    handleExpand,
    expandedRowKeys,
  }
}
