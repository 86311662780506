/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestImportCustomerCarePlan } from 'app/api/customer-care'
import { CustomerCareMessageCode } from 'app/api/customer-care/constant'
import { KeyImport } from 'app/api/import/constant'
import R from 'app/assets/R'
import {
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import {
  IBaseColumnReport,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { initPagination } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import {
  Pagination,
  PeriodType,
  ResponseType
} from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataCustomerCarePlanMonthly } from './Monthly/data'
import { IDataCustomerCarePlanWeekly } from './Weekly/data'

export const useCommonHook = ({
  fetchLoading,
  typeHook,
  fetch,
  currentFilter,
}: {
  fetchLoading: (value: boolean) => void
  typeHook: PeriodType
  fetch: (pagination: Pagination, params?: IParamsHookReport) => void
  currentFilter?: IParamsHookReport
}) => {
  const { t } = useTranslation()

  const onClickImport = async (file: any, periodType: PeriodType) => {
    fetchLoading(true)
    try {
      const formData = new FormData()
      formData.append(KeyImport.All.file, file)
      formData.append('periodType', periodType)
      const response: ResponseType<{ countSuccess?: number }> =
        await requestImportCustomerCarePlan(formData)

      if (
        isEqual(
          response.msgcode,
          CustomerCareMessageCode.CustomerCarePlan.successImport,
        )
      ) {
        fetch(initPagination, currentFilter)
        notificationController.success({
          message: t(R.strings.import_file_successful),
        })
        fetchLoading(false)
        return
      }
      fetchLoading(false)
      notificationController.error({ message: t(R.strings.import_file_error) })
    } catch (error) {
      fetchLoading(false)
    }
  }

  const columns = useMemo(() => {
    type TypeColumn = IDataCustomerCarePlanWeekly | IDataCustomerCarePlanMonthly
    const options: IBaseColumnReport<TypeColumn> = [
      convertColumnTable<TypeColumn>({
        title: t(R.strings.ordinal_number),
        key: 'firstNumber',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => {
          return RenderValueTableReport({
            record,
            valueString: record?.firstNumber ?? 0,
          })
        },
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.name_employee),
        key: 'employeeName',
        render: (_, record) => {
          return RenderValueTableReport({
            record,
            valueString: record?.employeeName ?? 0,
          })
        },
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.number_qlead_actual),
        key: 'qLeadActual',
        render: (_, record) => {
          return RenderValueTableReport({
            record,
            firstLevelValue: record?.qLeadActual ?? 0,
            prefix: '',
          })
        },
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.number_customer_re_care),
        key: 'numCustomerReCare',
        render: (_, record) => {
          return RenderValueTableReport({
            record,
            firstLevelValue: record?.numCustomerReCare ?? 0,
            prefix: '',
          })
        },
      }),
    ]

    return options
  }, [t, typeHook])

  return {
    onClickImport,
    columns,
  }
}
