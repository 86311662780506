import R from 'app/assets/R'
import { StackedBarChart } from 'app/components/charts'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { Loading } from 'app/components/common/Loading'
import { FORMAT_DD_MM_YYYY_HH_MM_SS } from 'app/constant'
import { useGetGeneralInfo } from 'app/react-query/hook/dashboard'
import { DateUtil, FORMAT_CENTER_YYYY_MM_DD, PADDING } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import RevenueItem from '../../components/left-side-bod/RevenueItem'
import { RevenueTable } from '../../components/left-side-bod/RevenueTable'
import * as S from './styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
interface IValueGeneralInfo {
  isGrow?: boolean
  percent?: string | number
  value?: string | number
}
interface IGeneralInfo {
  secondLevelValue: IValueGeneralInfo
  projectedValue: IValueGeneralInfo
  actualValue: IValueGeneralInfo
  newLeadValue: IValueGeneralInfo
  customerValue: IValueGeneralInfo
  lastedUpdateTime?: string
}
export const LeftSideBodLayout = () => {
  const { data, isLoading } = useGetGeneralInfo({
    date: DateUtil.formatDate(new Date(), FORMAT_CENTER_YYYY_MM_DD),
  })

  const { t } = useTranslation()

  const generalInfo: IGeneralInfo = useMemo(() => {
    return {
      secondLevelValue: {
        isGrow: !!data?.isGrowValueAmount,
        percent: data?.percentageIncreaseAmount ?? `0`,
        value: data?.totalAmount ?? `0`,
      },
      projectedValue: {
        isGrow: !!data?.isGrowValueProjected,
        percent: data?.percentageIncreaseProjected ?? `0`,
        value: data?.totalProjected ?? `0`,
      },
      actualValue: {
        isGrow: !!data?.isGrowValueActual,
        percent: data?.percentageIncreaseActual ?? `0`,
        value: data?.totalActual ?? `0`,
      },
      newLeadValue: {
        isGrow: false,
        percent: `0`,
        value: `0`,
      },
      customerValue: {
        isGrow: !!data?.isGrowValueNewPatients,
        percent: data?.percentageIncreaseNewPatients ?? `0`,
        value: data?.newPatients ?? `0`,
      },
      lastedUpdateTime: data?.lastedUpdateTime
        ? DateUtil.formatDate(
            data?.lastedUpdateTime,
            FORMAT_DD_MM_YYYY_HH_MM_SS,
          )
        : undefined,
    }
  }, [data])

  return (
    <S.LeftSideCol xl={18}>
      <BaseRow
        justify={'space-between'}
        align={'middle'}
        style={{ paddingBottom: PADDING.md }}
      >
        <BaseCol>
          <S.TitleContent>
            <span>{t(R.strings.overall_today_summary)}</span>
          </S.TitleContent>
        </BaseCol>
        {generalInfo?.lastedUpdateTime && (
          <S.TextUpdateTime>{`${t(R.strings.update_time)}: ${
            generalInfo?.lastedUpdateTime
          }`}</S.TextUpdateTime>
        )}
      </BaseRow>
      <BaseRow justify={'space-between'} gutter={8} wrap={false}>
        <S.BaseColRevenue flex={1}>
          <RevenueItem
            $isAll
            labelI18n={R.strings.total_revenue}
            value={generalInfo.secondLevelValue.value}
            valueGrow={generalInfo.secondLevelValue.percent}
            isUpValue={generalInfo.secondLevelValue.isGrow}
            color={convertedVariables.otherBlueColor}
          />
        </S.BaseColRevenue>
        <S.BaseColRevenue flex={1}>
          <RevenueItem
            labelI18n={R.strings.pending_revenue}
            value={generalInfo.projectedValue.value}
            valueGrow={generalInfo.projectedValue.percent}
            isUpValue={generalInfo.projectedValue.isGrow}
            color={convertedVariables.statesOrangeColor}
          />
        </S.BaseColRevenue>
        <S.BaseColRevenue flex={1}>
          <RevenueItem
            labelI18n={R.strings.actual_revenue}
            value={generalInfo.actualValue.value}
            valueGrow={generalInfo.actualValue.percent}
            isUpValue={generalInfo.actualValue.isGrow}
            color={'#629DAA'} // need change
          />
        </S.BaseColRevenue>
        <S.BaseColRevenue flex={1}>
          <RevenueItem
            labelI18n={R.strings.number_new_lead}
            isNotMoney
            value={generalInfo.newLeadValue.value}
            valueGrow={generalInfo.newLeadValue.percent}
            isUpValue={generalInfo.newLeadValue.isGrow}
            color={'#9747FF'} // need change
          />
        </S.BaseColRevenue>
        <S.BaseColRevenue flex={1}>
          <RevenueItem
            labelI18n={R.strings.customers}
            isNotMoney
            value={generalInfo.customerValue.value}
            valueGrow={generalInfo.customerValue.percent}
            isUpValue={generalInfo.customerValue.isGrow}
            color={convertedVariables.statesGreenColor}
          />
        </S.BaseColRevenue>

        {isLoading && (
          <S.LoadingContainer>
            <Loading size="large" />
          </S.LoadingContainer>
        )}
      </BaseRow>

      <BaseCol id="line-race" xs={24}>
        <StackedBarChart />
      </BaseCol>

      <BaseCol>
        <S.TitleContent $isUsingPadding>
          {t(R.strings.today_revenue)}
        </S.TitleContent>
        <S.Space />

        <RevenueTable />
      </BaseCol>
    </S.LeftSideCol>
  )
}
