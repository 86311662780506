
/* eslint-disable @typescript-eslint/no-explicit-any */

import { requestGetRights, requestGetRolesManagement } from 'app/api/roles'
import { RoleMessageCode } from 'app/api/roles/constant'
import {
  IParamsGetRoleRights,
  IResponseGetRoleRights,
  IRoleRight,
} from 'app/api/roles/models/rights'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { useInfiniteQuery } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { RoleKeys } from '../query-key/role'
import { IParamsGetRoleManagement, IRoleManagement } from 'app/api/roles/models/role'

export function useGetRoleRights(params: IParamsGetRoleRights, enabled = true) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IRoleRight[]>, undefined>(
    RoleKeys.listRoleRight(params),
    async () => {
      try {
        const res: IResponseGetRoleRights = await requestGetRights(params)

        if (
          isEqual(res?.msgcode, RoleMessageCode.RoleManagement.successGetData)
        ) {
          const data: IRoleRight[] = res?.data || []

          return {
            data,
            total: res?.metadata?.totalItem ?? 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}



export function useGetRoleManagement(params: IParamsGetRoleManagement, enabled = true) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IRoleManagement[]>, undefined>(
    RoleKeys.listRoleManagement(params),
    async () => {
      try {
        const res: IResponseGetRoleRights = await requestGetRolesManagement(params)

        if (
          isEqual(res?.msgcode, RoleMessageCode.RoleManagement.successGetData)
        ) {
          const data: IRoleManagement[] = res?.data || []
          return {
            data,
            total: res?.metadata?.totalItem ?? 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
