import React, { useState } from 'react'
import { BellOutlined } from '@ant-design/icons'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseBadge } from 'app/components/common/BaseBadge'
import { NotificationsOverlay } from 'app/components/header/components/NotificationsDropdown/NotificationsOverlay/NotificationsOverlay'
import { notifications as fetchedNotifications } from 'app/api/notification'
import { HeaderActionWrapper } from 'app/components/header/styles'
import { BasePopover } from 'app/components/common/BasePopover'
import { Notification } from 'parkway-web-common'

export const NotificationsDropdown: React.FC = () => {
  const [notifications, setNotifications] =
    useState<Notification[]>(fetchedNotifications)
  const [isOpened, setOpened] = useState(false)

  return (
    <BasePopover
      trigger="click"
      content={
        <NotificationsOverlay
          notifications={notifications}
          setNotifications={setNotifications}
        />
      }
      onOpenChange={setOpened}
    >
      <HeaderActionWrapper>
        <BaseButton
          type={isOpened ? 'primary' : 'text'}
          icon={
            <BaseBadge>
              <BellOutlined rev={undefined} />
            </BaseBadge>
          }
        />
      </HeaderActionWrapper>
    </BasePopover>
  )
}
