import styled from 'styled-components'

export const CalendarWrapper = styled.div`
background: var(--revenue-dashboard-border-color),
borderRadius: 1px soild var(--revenue-dashboard-border-color),
padding: 15,
`

export const ListBox = styled.div<{ border: string; background: string }>`
  width: 187px;
  height: 30px;
  padding: 8px;
  border-left: 2px solid ${props => props.border ?? 'white'};
  gap: 8px;
  background: ${props => props.background ?? 'white'};
  margin-top: 10px;
`

export const ListItem = styled.div`
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--main-color-text);
`

export const ActionHeader = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
