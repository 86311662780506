import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import { SelectInputSearch } from '../WarehousePurchase/components/FilterManagementTable/styles'

export const RootWrapper = styled.div`
  padding: 0px ${PADDING.md};
`

export const TotalPage = styled.div`
  color: var(--main-color-text);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  opacity: 0.7;
`

export const CustomSelectInputSearch = styled(SelectInputSearch)`
  min-width: initial;
  .ant-select-selector {
    min-width: initial !important;
    max-width: initial !important;
    background: transparent !important;
    border: initial !important;
  }
  .ant-select-selection-search {
    min-width: initial !important;
    max-width: initial !important;
  }

  .ant-select-selection-item {
    font-size: 1.5rem;
    color: var(--primary-color);
    font-weight: 700;
  }
  &.ant-select-open .ant-select-selector {
    min-width: 200px !important;
  }
`
