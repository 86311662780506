import { IParamsGetApproveDayOff } from 'app/api/hr/models/approve-day-off'

export const DayOffKeys = {
  allDashboardDayOffs: ['DashboardDayOffs'] as const,
  listDashboardDayOffs: () =>
    [...DayOffKeys.allDashboardDayOffs, 'listDashboardDayOffs'] as const,
  listDashboardDayOff: (filter?: IParamsGetApproveDayOff) =>
    [...DayOffKeys.listDashboardDayOffs(), { filter }] as const,

  listTotalDashboardDayOffs: () =>
    [...DayOffKeys.allDashboardDayOffs, 'listTotalDashboardDayOffs'] as const,
  listTotalDashboardDayOff: (filter?: IParamsGetApproveDayOff) =>
    [...DayOffKeys.listTotalDashboardDayOffs(), { filter }] as const,
}
