import { ApiClientHr } from 'app/service/api-service'
import { IndustryEndPoint } from './constant'
import { IParamsGetAllIndustry } from './model/management'

export const requestGetAllIndustryManagement = (
  params?: IParamsGetAllIndustry,
) =>
  ApiClientHr.Get({
    url: `${IndustryEndPoint.IndustryManagement.request}/get-all`,
    params: { params },
  })
