import R from 'app/assets/R'
import { StatusEnum } from './type'
import { convertedVariables } from 'app/styles/themes/themeVariables'

export const DefineStatusEnum = {
  [StatusEnum.DUPLICATED]: {
    text: R.strings.duplicated_treatment,
    color: convertedVariables.statesRedColor,
  },
  [StatusEnum.NOT_FOUND_TREATMENT]: {
    text: R.strings.not_found_treatment,
    color: convertedVariables.statesRedColor,
  },
  [StatusEnum.SUCCESS]: {
    text: R.strings.success,
    color: convertedVariables.statesGreenColor,
  },
}
