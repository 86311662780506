import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StatusGoodsType } from '.'
import {
  IGeneralGoodsManagementTable,
  IResponseFilter,
} from '../OrderInfo/type'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { Pagination, initialPagination } from 'parkway-web-common'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import {
  IParamsGetItemManagement,
  IResponseGetItemManagement,
} from 'app/api/item/model/management'
import { ItemType } from 'app/containers/Accounting/TreatmentManagement/CreateUpdateProductServiceManagement/type'
import { requestGetItem } from 'app/api/item'
import { isEmpty, isEqual } from 'lodash'
import { MessageCode } from 'app/api/inventory/constant'
import {
  RenderValueTable,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { BaseButton } from 'app/components/common/BaseButton'
import { initResponseFilterTableManagement } from 'app/components/tables/BaseTableManagement/FilterManagementTable/type'
import { OrderNameBox, UnSelectedButton } from './styles'
import { BaseRow } from 'app/components/common/BaseRow'
import { IItem } from 'app/api/item/model'
import { WarehousePurchaseContext } from 'app/containers/InventoryManagement/WarehousePurchase/context'
import { addPrefixImage } from 'app/common/helpers'

export const useModalHook = () => {
  const { orders } = useContext(WarehousePurchaseContext)
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState<StatusGoodsType | undefined>()
  const [isLoading] = useState<boolean>(false)
  const [goods, setGoods] = useState<IItem[]>(orders || [])

  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.INVENTORY,
      FunctionPermissionEnum.PROVIDER_READ,
      R.strings.role,
    )
  }, [])

  const showModal = () => {
    setVisible(true)
  }

  const hideModal = () => {
    setVisible(false)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IResponseFilter,
  ) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IGeneralGoodsManagementTable>
    try {
      let params: IParamsGetItemManagement = {
        page: pagination?.current,
        pagesize: pagination?.pageSize,
        keyword: newFilter?.search ?? filter?.search,
      }

      params = { ...params, ...newFilter, type: ItemType.supplies }

      const response: IResponseGetItemManagement = await requestGetItem(params)

      if (isEqual(response?.msgcode, MessageCode.successGetData)) {
        const data = response?.data
        const metadata = response?.metadata

        const startIndex =
          ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

        const transformData = data => {
          if (isEmpty(data)) {
            return data
          }

          return data?.map((item, index) => {
            const purchaseMeasurementUnit = item?.purchaseMeasurementUnitInfo
              ?.map(item => item?.name)
              .join(',')
            return {
              key: `${item?._id}-${index + 1}`,
              ordinalNumber: startIndex + index + 1,
              code: item?.code ?? '-',
              name: item?.name ?? '-',
              purchaseMeasurementUnit,
              measurementUnitId: item?.purchaseMeasurementUnitId,
              productId: item?._id,
              mainImagesUrl: item?.mainImagesUrl,
              vat: item?.vat ?? '-',
              _id: item?._id ?? '-',
            }
          })
        }

        const dataTables = transformData(data)

        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pageSize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IGeneralGoodsManagementTable>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, selectRowsTable, fetch, rowSelection } =
    useHookTableManagement({ getDataTable })

  const handleTableChange = pagination => {
    fetch(pagination, filter)
  }

  const handleChangeFilter = (newFilter: IResponseFilter) => {
    if (!isEqual(newFilter, filter)) {
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initialPagination.current,
        },
        newFilter,
      )
    }
  }

  const fetchNewData = () => {
    fetch(initialPagination)
    setFilter(initResponseFilterTableManagement)
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralGoodsManagementTable>({
        title: t(R.strings.goods_code),
        key: 'code',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <RenderValueTable value={record?.code} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralGoodsManagementTable>({
        title: t(R.strings.goods),
        key: 'name',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <OrderNameBox>
            <img src={addPrefixImage(record?.mainImagesUrl)} alt="" />
            <p>{record?.name}</p>
          </OrderNameBox>
        ),
      }),

      convertColumnTable<IGeneralGoodsManagementTable>({
        title: t(R.strings.unit_of_measure),
        key: 'purchaseMeasurementUnit',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.purchaseMeasurementUnit}
            type="Base"
          />
        ),
      }),

      convertColumnTable<IGeneralGoodsManagementTable>({
        title: '',
        key: 'action',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => {
          const newRecord = { ...record, productId: record?._id }
          const isSelected = goods?.findIndex(
            item => item?._id === newRecord?._id,
          )
          return (
            <BaseRow justify={'end'} align={'middle'}>
              {isSelected === -1 ? (
                <BaseButton
                  onClick={() =>
                    setGoods(prev => prev.concat(newRecord as IItem))
                  }
                >
                  {t(R.strings.select)}
                </BaseButton>
              ) : (
                <UnSelectedButton
                  onClick={() => {
                    return setGoods(prev => {
                      const newGoods = prev?.filter(
                        item => item?._id != newRecord?._id,
                      )
                      return newGoods
                    })
                  }}
                >
                  {t(R.strings.unselect)}
                </UnSelectedButton>
              )}
            </BaseRow>
          )
        },
      }),
    ]
    return option
  }, [t, fetchNewData, orders, goods])

  return {
    form,
    visible,
    t,
    showModal,
    setStatus,
    status,
    isLoading,
    tableData,
    selectRowsTable,
    handleTableChange,
    fetchNewData,
    rowSelection,
    columns,
    handleChangeFilter,
    filter,
    goods,
    hideModal,
    setGoods,
    handleCancel,
  }
}
