import R from 'app/assets/R'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { useModalHook } from './hook'
import { IPropsDeleteRole } from './type'
import { BaseCol } from 'app/components/common/BaseCol'
import * as S from './styles'

export const DeleteRole = (props: IPropsDeleteRole) => {
  const { visible, isLoading, showModal, handleCancel, handleSubmit, t } =
    useModalHook(props)

  return (
    <BaseModalManagement
      isLoading={isLoading}
      typeButtonOpen="delete"
      titleKeyI18n={R.strings.role_content_delete_role}
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.role_content_delete_role}
      confirmKeyI18n={R.strings.role_content_delete_role}
      handleSubmit={handleSubmit}
      visible={visible}
      widthModal={500}
      descriptionDelete={
        <>
          <BaseCol>
            <span>
              {t(R.strings.are_you_want_delete_name, {
                name: R.strings.role,
              })}{' '}
              <S.TextAccount>{props?.role?.name ?? ''}</S.TextAccount>
            </span>
          </BaseCol>
          <BaseCol>
            {t(R.strings.once_the_name_is_locked_it_cannot_be_recovered, {
              name: R.strings.role,
            })}
          </BaseCol>
        </>
      }
    />
  )
}
