import { SearchOutlined } from '@ant-design/icons'
import { Calendar, ConfigProvider, Input, Select } from 'antd'
import locale from 'antd/es/locale/vi_VN'
import R from 'app/assets/R'
import { PageAppointmentContext } from 'app/context/PageAppointmentContext'
import { UnitContext } from 'app/context/UnitContext'
import dayjs from 'dayjs'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DoctorList from './DoctorList'
import { SelectTopWrapper, SelectWrapper } from './styles'
import { IArea, IClinic, ILabelValue } from './type'
import { useLocation } from 'react-router'
import { CUSTOMER_CARE_SEARCH_APPOINTMENT_PATH } from 'app/components/router/route-path'
import { CalendarHeader } from './CalendarHeader'

const SidebarLeft = props => {
  const { t } = useTranslation()
  const { onSelect, selectedDate } = props
  const { units } = useContext(UnitContext)
  const { setFilter, filter } = useContext(PageAppointmentContext)
  const [clinic, setClinic] = useState<ILabelValue[]>([])
  const [searchDoctor, setSearchDoctor] = useState('')
  const [total, setTotal] = useState(0)
  const location = useLocation()

  const area = units.map((unit: IArea) => ({
    value: unit?._id,
    label: unit?.name,
  }))

  const handleArea = e => {
    const areaSelected: IArea | undefined = units.find(
      (a: IArea) => a._id === e?.value,
    )
    if (!areaSelected) {
      return
    }
    const area = areaSelected as IArea
    const clinic = area.childs.map((c: IClinic) => ({
      value: c?._id,
      label: c?.name,
    }))
    setClinic(clinic || [])
    setFilter?.({ ...filter, areaId: e?.value })
  }

  const selectClass =
    location?.pathname === CUSTOMER_CARE_SEARCH_APPOINTMENT_PATH
      ? 'appointment-resize'
      : ''
  return (
    <div className="calendar-left app-bg-white">
      <ConfigProvider locale={locale}>
        <Calendar
          fullscreen={false}
          className="app-width-full"
          onChange={value => setFilter?.({ ...filter, date: value })}
          value={dayjs(selectedDate)}
          headerRender={CalendarHeader}
          onSelect={onSelect}
        />
      </ConfigProvider>
      <SelectTopWrapper>
        <Select
          labelInValue
          placeholder={t(R.strings.area_label_placeholder)}
          className="app-width-full"
          options={area}
          onChange={e => handleArea(e)}
          allowClear
          popupClassName={selectClass}
        />
      </SelectTopWrapper>

      <SelectWrapper>
        <Select
          labelInValue
          placeholder={t(R.strings.clinic_label_placeholder)}
          className="app-width-full"
          options={clinic}
          onChange={e => setFilter?.({ ...filter, clinicId: e?.value })}
          allowClear
          popupClassName={selectClass}
        />
      </SelectWrapper>
      <SelectWrapper>
        <Input
          placeholder={t(R.strings.search_doctor)}
          prefix={<SearchOutlined rev className="app-color-close" />}
          onChange={e => setSearchDoctor(e?.target?.value)}
          allowClear
        />
      </SelectWrapper>
      <div className="doctor-total">
        {t(R.strings.all_doctor)} ({total}){' '}
      </div>
      <DoctorList searchDoctor={searchDoctor} setTotal={setTotal} />
    </div>
  )
}

export default SidebarLeft
