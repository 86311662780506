import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { randomMoney } from 'app/components/tables/common-table/constant'
import { formatMoney } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const InfoPaymentLayout = () => {
  const { t } = useTranslation()
  return (
    <SpaceWrapper direction="vertical" size={15}>
      <BaseText fontWeight={'semibold'} fontSize="xs">
        {t(R.strings.info_payment)}
      </BaseText>

      <BaseRow align={'middle'} justify={'space-between'} gutter={10}>
        <BaseCol>
          <BaseText opacity="0.7" fontSize="xs">
            {t(R.strings.late_payment_period)}
          </BaseText>
        </BaseCol>
        <BaseCol>
          <BaseText fontSize="xs" fontWeight="medium">
            {'11+12'}
          </BaseText>
        </BaseCol>
      </BaseRow>

      <BaseRow align={'middle'} justify={'space-between'} gutter={10}>
        <BaseCol>
          <BaseText opacity="0.7" fontSize="xs">
            {t(R.strings.total_amount_due)}
          </BaseText>
        </BaseCol>
        <BaseCol>
          <BaseText fontSize="xs" fontWeight="medium">
            {formatMoney(`${randomMoney()}`)}
          </BaseText>
        </BaseCol>
      </BaseRow>
    </SpaceWrapper>
  )
}

export default InfoPaymentLayout

export const SpaceWrapper = styled(BaseSpace)`
  width: 100%;
`
