import { useState } from 'react'
import R from 'app/assets/R'
import { IPropsCreateBooking } from './type'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import CreateAndUpdate from 'app/containers/CustomerCare/AppointmentPage/SideBarRight/CalendarRight/CreateAndUpdate'
import { PageType } from 'app/containers/CustomerCare/AppointmentPage/SideBarRight/CalendarRight/CreateAndUpdate/type'
import { ButtonCreateAction } from 'app/components/common/BaseModalManagement/styles'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { APPOINTMENT_VIEW_TYPE } from 'app/common/enum'

export const Create = (props: IPropsCreateBooking) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      <CreateAndUpdate
        open={open}
        setOpen={setOpen}
        appointment={{ fetch: props?.fetchTable }}
        setAppointment={(e) => console.log(e)}
        page={PageType.CALLCENTER}
        view={APPOINTMENT_VIEW_TYPE.LIST}
      />
      <BaseCol>
        <ButtonCreateAction onClick={() => setOpen(true)}>
          <BaseRow gutter={8}>
            <BaseCol>
              <PlusOutlined rev={undefined} />
            </BaseCol>
            <BaseCol>{t(R.strings.add_schedule)}</BaseCol>
          </BaseRow>
        </ButtonCreateAction>
      </BaseCol>
    </>
  )
}
