/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAgeGroupReport10, IParamsGetReport10 } from 'app/api/report/model/report-10'
import R from 'app/assets/R'
import { LocationEnum, PeriodType, ResponseType } from 'parkway-web-common'
import { BaseRow } from 'app/components/common/BaseRow'
import {
  ExpandedTableValue,
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import { AreaRowWrapper } from 'app/components/tables/BaseTableReport/styles'
import {
  IBaseColumnReport,
  IOnCell,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { isEqual } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataReport10Year } from './Year/data'
import { IDataReport10Month } from './Month/data'
import { IResponseValueByLocation } from './common-model'
import { IResponseExportExcel } from 'app/api/report/model/common'
import { requestExportExcelPatient } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { notificationController } from 'app/controllers/notification-controller'

export const useCommonHook = ({
  expandedRowKeys,
  typeHook,
  currentFilter,
  fetchLoading
}: {
  expandedRowKeys: number[]
  typeHook: PeriodType,
  currentFilter?: IParamsHookReport
  fetchLoading?: (isLoading: boolean) => void
}) => {
  const { t } = useTranslation()
  const exportExcel = async () => {
    try {
      if (!currentFilter?.year) {
        return
      }

      let params: IParamsGetReport10 = {
        year: currentFilter?.year,
      }

      switch (typeHook) {
        case "week": {
          params = {
            ...params,
            month: currentFilter?.month,
          }
        }
      }

      fetchLoading?.(true)
      const res: ResponseType<IResponseExportExcel> =
        await requestExportExcelPatient(params)

      if (res?.msgcode === ReportMessageCode.Report10.successGetData &&
        res?.data?.success) {
        window.open(res?.data?.data ?? '')
        notificationController.success({
          message: t(R.strings.download_excel_success),
        })
      }
    } catch (error: any) {
      // console.log(error)
      notificationController.error(error)
    } finally {
      fetchLoading?.(false)
    }
  }

  const columns = useMemo(() => {
    type TypeColumn = IDataReport10Month | IDataReport10Year
    const onCellGender = (record: TypeColumn): IOnCell => {
      if (
        (record?.isTotalTable || record?.isTotalGroupTable) &&
        expandedRowKeys.length
      )
        return { colSpan: 3 }

      return {
        rowSpan: record.children?.length ? record.children?.length + 1 : 0,
      }
    }

    const onTypeAge = (record: TypeColumn): IOnCell => {
      if (record?.typeAge && record?.numOfChild)
        return { rowSpan: record?.numOfChild }
      return { rowSpan: 0 }
    }

    const onCellAge = (record: TypeColumn): IOnCell => {
      if (record?.isTotalTable || record?.isTotalGroupTable) {
        return { colSpan: 0 }
      }

      return {}
    }

    const options: IBaseColumnReport<TypeColumn> = [
      convertColumnTable<TypeColumn>({
        title: t(R.strings.gender),
        key: 'gender',
        onCell: onCellGender,
        fixed: 'left',
        render: (_, record) => {
          if (record?.isTotalGroupTable) {
            return (
              <ExpandedTableValue
                value={record?.gender}
                isTotalTable
                className={'is-total-group-table'}
              />
            )
          }
          return (
            <BaseRow>
              <ExpandedTableValue
                value={record?.gender}
                isNotHaveActionExpanded
                className={`${record?.isTotalTable ? 'is-total-table' : ''}`}
              />
            </BaseRow>
          )
        },
      }),
      convertColumnTable<TypeColumn>({
        title: '',
        key: 'typeAge',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'medium-column',
        onCell: onTypeAge,
        render: (_, record) => {
          let className = ''

          if (record?.index && record?.index % 2 === 0) {
            className = 'is-expanded'
          }
          return (
            <AreaRowWrapper className={className}>
              {record?.typeAge}
            </AreaRowWrapper>
          )
        },
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.age),
        key: 'age',
        fixed: 'left',
        onCell: onCellAge,
        render: (_, record) => {
          return (
            <RenderValueTableReport
              record={record}
              valueString={record?.age}
              expandedRowKeys={expandedRowKeys}
              justify="start"
            />
          )
        },
      }),
      {
        title: t(R.strings.number_of_cases_in_the_region),
        children: [
          convertColumnTable<TypeColumn>({
            title: t(R.strings.hochiminh),
            key: 'numberOfCaseHoChiMinh',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.numberOfCaseHoChiMinh ?? 0,
                expandedRowKeys,
                prefix: '',
                isFillBlueInTotal: record?.isTotalTable,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.hanoi),
            key: 'numberOfCaseHanoi',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.numberOfCaseHanoi ?? 0,
                expandedRowKeys,
                prefix: '',
                isFillBlueInTotal: record?.isTotalTable,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.province),
            key: 'numberOfCaseProvince',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.numberOfCaseProvince ?? 0,
                expandedRowKeys,
                prefix: '',
                isFillBlueInTotal: record?.isTotalTable,
              })
            },
          }),
        ],
      },
      {
        title: t(R.strings.revenue_in_the_region),
        children: [
          convertColumnTable<TypeColumn>({
            title: t(R.strings.hochiminh),
            key: 'revenueInHoChiMinh',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.revenueInHoChiMinh ?? 0,
                expandedRowKeys,
                isFillBlueInTotal: record?.isTotalTable,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.hanoi),
            key: 'revenueInHanoi',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.revenueInHanoi ?? 0,
                expandedRowKeys,
                isFillBlueInTotal: record?.isTotalTable,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.province),
            key: 'revenueInProvince',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.revenueInProvince ?? 0,
                expandedRowKeys,
                isFillBlueInTotal: record?.isTotalTable,
              })
            },
          }),
        ],
      },
    ]
    return options
  }, [t, expandedRowKeys, typeHook])

  return {
    columns,
    exportExcel
  }
}

export const getValueByLocation = ({
  listLocation,
}: {
  listLocation?: IAgeGroupReport10
}): IResponseValueByLocation => {
  const itemExistHN = listLocation?.locations?.find(item =>
    isEqual(item?.areaName, LocationEnum.HANOI),
  )
  const itemExistHCM = listLocation?.locations?.find(item =>
    isEqual(item?.areaName, LocationEnum.HOCHIMINH),
  )

  const itemExistInProvince = listLocation?.locations?.find(item =>
    isEqual(item?.areaName, LocationEnum.PROVINCE),
  )

  return {
    hanoi: {
      numberOfCase: itemExistHN?.count ?? 0,
      revenue: itemExistHN?.payments ?? 0,
    },
    hcm: {
      numberOfCase: itemExistHCM?.count ?? 0,
      revenue: itemExistHCM?.payments ?? 0,
    },
    province: {
      numberOfCase: itemExistInProvince?.count ?? 0,
      revenue: itemExistInProvince?.payments ?? 0,
    },
  }
}

export const sumDataWithArea = (
  total: IResponseValueByLocation,
  newData: IResponseValueByLocation,
) => {
  return {
    hanoi: {
      revenue: total.hanoi.revenue + newData.hanoi.revenue,
      numberOfCase: total.hanoi.numberOfCase + newData.hanoi.numberOfCase,
    },
    hcm: {
      revenue: total.hcm.revenue + newData.hcm.revenue,
      numberOfCase: total.hcm.numberOfCase + newData.hcm.numberOfCase,
    },
    province: {
      revenue: total.province.revenue + newData.province.revenue,
      numberOfCase: total.province.numberOfCase + newData.province.numberOfCase,
    },
  }
}

export const convertDataToDataTable = (
  dataLocation: IResponseValueByLocation,
): IDataReport10Month => {
  return {
    numberOfCaseHoChiMinh: dataLocation?.hcm?.numberOfCase,
    numberOfCaseHanoi: dataLocation?.hanoi?.numberOfCase,
    numberOfCaseProvince: dataLocation?.province?.numberOfCase,
    revenueInHoChiMinh: dataLocation?.hcm?.revenue,
    revenueInHanoi: dataLocation?.hanoi.revenue,
    revenueInProvince: dataLocation?.province?.revenue,
  }
}

export const initValueByLocation: IResponseValueByLocation = {
  hanoi: {
    revenue: 0,
    numberOfCase: 0,
  },
  hcm: {
    revenue: 0,
    numberOfCase: 0,
  },
  province: {
    revenue: 0,
    numberOfCase: 0,
  },
}
