import { ApiClient } from 'app/service/api-service'
import { LimitDiscountEndPoint } from './constant'
import { IBodyUpdateLimitDiscount, IParamsGetLimitDiscount } from './model'

export const requestGetTemplateLimitDiscount = (
  params?: IParamsGetLimitDiscount,
) =>
  ApiClient.Get({
    url: LimitDiscountEndPoint.LimitDiscount.template,
    params: { params },
  })
export const importLimitDiscount = (body: FormData) =>
  ApiClient.Post({
    url: LimitDiscountEndPoint.LimitDiscount.import,
    body,
  })
export const updateLimitDiscount = (body: IBodyUpdateLimitDiscount) =>
  ApiClient.Put({
    url: LimitDiscountEndPoint.LimitDiscount.update,
    body,
  })
export const getLimitDiscountData = (params: IParamsGetLimitDiscount) =>
  ApiClient.Get({
    url: LimitDiscountEndPoint.LimitDiscount.getData,
    params: { params },
  })
