import { NOTIFICATION_TYPE } from "./constants"

export enum DegreeClassification {
  excellent = 'excellent',
  good = 'good',
  average_good = 'average_good',
  average = 'average',
  below_average = 'below_average',
  weak = 'weak',
  unknown = 'unknown',
}

export enum JobTitleGroup {
  doctor = 'doctor',
  nurse = 'nurse',
  callCenter = 'callcenter',
  other = 'other',
}

export enum EYesNo {
  no = 0,
  yes = 1,
}

export enum StatusEnum {
  ACTIVE = 1,
  INACTIVE = 2,
  BLOCK = 3,
  DELETED = 4,
}

export type StatusType = keyof typeof StatusEnum

export enum StatusOptionsCreateEnum {
  active = 'active',
  inactive = 'inactive',
}

export enum StatusOptionsUpdateEnum {
  active = 'active',
  inactive = 'inactive',
  deleted = 'deleted',
}

export enum GenderEnumKeyI18n {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export enum StatusTableEnum {
  active = 'active',
  deleted = 'deleted',
  inactive = 'inactive',
}

export enum PaymentOptionEnum {
  LatePaymentProcessing = 'LatePaymentProcessing',
  CompleteSettlement = 'CompleteSettlement',
}

export enum YesNoEnum {
  Yes = 'Yes',
  No = 'No',
}

export enum PaymentMethodEnum {
  Cash = 'Cash',
  Bank = 'Bank',
  EWallet = 'EWallet',
}

export enum PaymentOptionPurchaseProductServiceEnum {
  OneTimePayment = 'OneTimePayment',
  InstallmentPayment = 'InstallmentPayment',
  PaymentInStages = 'PaymentInStages',
}

export const ListStatusTable = Object.values(StatusTableEnum)

export type StatusTableType = keyof typeof StatusTableEnum

export enum REQUEST_APPOINTMENT_TYPE {
  PROFILE = 1,
}
export enum SEARCH_APPOINTMENT_ACTION {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  SEND = 'SEND',
  CANCEL = 'CANCEL',
}

export enum APPOINTMENT_VIEW_TYPE {
  LIST = 'list',
}

export const enum PurchaseOrderStatusEnum {
  DRAFT = 1,
  PENDING = 2,
  CONFIRMED = 3,
  CANCEL = 4,
  SENT_TO_SUPPLIER = 5,
  DONE = 6,
  RECEIVED = 7,
  RETURNED = 8,
}

export enum StorePOStatusEnum {
  DRAFT = PurchaseOrderStatusEnum.DRAFT,
  PENDING = PurchaseOrderStatusEnum.PENDING,
}

export enum UpdatePOAtDraftStatusEnum {
  DRAFT = PurchaseOrderStatusEnum.DRAFT,
  PENDING = PurchaseOrderStatusEnum.PENDING,
}

export enum UpdatePOAtPendingStatusEnum {
  CONFIRMED = PurchaseOrderStatusEnum.CONFIRMED,
}

export enum UpdatePOAtConfirmedStatusEnum {
  CANCEL = PurchaseOrderStatusEnum.CANCEL,
  SENT_TO_SUPPLIER = PurchaseOrderStatusEnum.SENT_TO_SUPPLIER,
}

export enum UpdatePOAtSentToSupplierStatusEnum {
  RECEIVED = PurchaseOrderStatusEnum.RECEIVED,
}

export enum UpdatePOAtReceivedStatusEnum {
  DONE = PurchaseOrderStatusEnum.DONE,
}


export enum PurchaseOrderActionEnum {
  UPDATE = 1,
  RECEIVE = 2,
  RE_ORDER = 3,
  PRINT = 4,
  VIEW_DETAIL = 5,
}

export enum PurchaseOrderActionFormEnum {
  UPDATE = 1,
  CREATE = 2,
  VIEW = 3
}

export type NotificationType  = keyof typeof NOTIFICATION_TYPE;

