import { CampaignTypeEnum } from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDetailCampaignContext } from '../context'
import { ContentLayout } from './Contents'
import { InfoCampaignLayout } from './InfoCampaign'

export const RootLayout = () => {
  const { t } = useTranslation()
  const { form, handleSubmit, handleGoBack } = useDetailCampaignContext()
  return (
    <BaseForm
      form={form}
      onFinish={handleSubmit}
      initialValues={{
        campaignType: CampaignTypeEnum.Treatments,
      }}
    >
      <RootContentWrapper>
        <InfoCampaignLayout />
        <ContentLayout />
        <BaseRow justify={'end'} align={'middle'}>
          <BaseCol>
            <BaseRow gutter={[16, 8]} align={'middle'}>
              <BaseCol>
                <BaseButton onClick={handleGoBack}>
                  {t(R.strings.back)}
                </BaseButton>
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>
      </RootContentWrapper>
    </BaseForm>
  )
}

const RootContentWrapper = styled(BaseSpace)`
  padding-bottom: ${PADDING.md};
  position: relative;
`
