import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import Report005Container from 'app/containers/Report/Report005'

const Report005PageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.report_005)}</PageTitle>
      <Report005Container />
    </>
  )
}
const Report005Page = memo(Report005PageCpn, isEqual)

export default Report005Page
