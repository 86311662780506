import { DownloadOutlined, PlusOutlined } from '@ant-design/icons'
import { DiscountTypeEnum } from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useUpdateCampaignContext } from '../../../../context'
import { IBundleServiceItem, ITreatmentInTable } from '../../../../type'
import {
  LabelCollapseComponent,
  ProfileServiceComponent,
} from '../../components'
import { ImportBundle } from './ImportModal'
import { ModalAddAndUpdateSelectedTreatment } from './ModalAddAndUpdateSelectedTreatment'
import { BaseEmpty } from 'app/components/common/BaseEmpty'

export const BundleTypeLayout = () => {
  const { t } = useTranslation()
  const {
    onAddNewBundleService,
    listBundleService,
    onRemoveBundleServiceByIndex,
    onChangeNameBundleByIndex,
    onChangeDiscountTypeItemBundleService,
    onChangeDiscountValueItemBundleService,
    onChangeItemBundleServiceByIndex,
    onChangeQuantityItemBundleService,
    onDeleteItemInSelectedTreatment,
    handleDownloadTemplateTreatment,
  } = useUpdateCampaignContext()

  const onChangeSelectedTreatment = ({
    index,
    service,
    item,
  }: {
    service?: ITreatmentInTable[]
    item?: IBundleServiceItem
    index?: number
  }) => {
    if (index !== undefined && item) {
      onChangeItemBundleServiceByIndex?.(index, {
        ...item,
        selectedTreatment: service,
      })
    }
  }

  return (
    <RootWrapper>
      <HeaderWrapper
        gutter={[16, 8]}
        align={'middle'}
        justify={'space-between'}
      >
        <BaseCol>
          <BaseText
            children={t(R.strings.service_apply)}
            fontWeight="semibold"
            fontSize="md"
          />
        </BaseCol>

        <BaseCol>
          <BaseRow gutter={[16, 8]} align={'middle'}>
            <BaseCol>
              <BaseButton
                icon={<DownloadOutlined rev={undefined} />}
                onClick={handleDownloadTemplateTreatment}
              >
                {t(R.strings.download_exist_data)}
              </BaseButton>
            </BaseCol>
            <BaseCol>
              <ImportBundle />
            </BaseCol>
            <BaseCol>
              <BaseButton
                icon={<PlusOutlined rev={undefined} />}
                onClick={onAddNewBundleService}
              >
                {t(R.strings.add_bundle)}
              </BaseButton>
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </HeaderWrapper>
      <ContentWrapper>
        {listBundleService?.length ? (
          listBundleService?.map((item, index) => {
            return (
              <CollapseWrapper
                items={[
                  {
                    key: index,
                    label: (
                      <LabelCollapseComponent
                        item={item}
                        key={index}
                        onChange={value =>
                          onChangeNameBundleByIndex?.(index, value)
                        }
                      />
                    ),
                    extra: (
                      <div onClick={e => e.stopPropagation()}>
                        <BaseRow gutter={[16, 8]} align={'middle'} key={index}>
                          <BaseCol>
                            <ModalAddAndUpdateSelectedTreatment
                              services={item?.selectedTreatment}
                              onChangeService={service =>
                                onChangeSelectedTreatment({
                                  index,
                                  service,
                                  item,
                                })
                              }
                            />
                          </BaseCol>
                          <BaseCol>
                            <BaseButton
                              onClick={() =>
                                onRemoveBundleServiceByIndex?.(index)
                              }
                              icon={<SvgDeleteDesignIcon />}
                            />
                          </BaseCol>
                        </BaseRow>
                      </div>
                    ),
                    style: {
                      alignItems: 'center',
                    },
                    children: (
                      <BaseSpace size={0}>
                        <BaseRow
                          gutter={[16, 8]}
                          align={'middle'}
                          className="header-content"
                          justify={'space-between'}
                        >
                          <BaseCol xl={10}>
                            <BaseText
                              children={t(R.strings.buy_service)}
                              fontWeight="semibold"
                            />
                          </BaseCol>
                          <BaseCol xl={4}>
                            <BaseText
                              children={t(R.strings.quantity)}
                              fontWeight="semibold"
                            />
                          </BaseCol>
                          <BaseCol xl={4}>
                            <BaseText
                              children={t(R.strings.discount_value)}
                              fontWeight="semibold"
                            />
                          </BaseCol>
                          <BaseCol xl={4}>
                            <BaseText
                              children={t(R.strings.discount_type)}
                              fontWeight="semibold"
                            />
                          </BaseCol>
                          <BaseCol xl={2} />
                        </BaseRow>

                        <BaseSpace className="list-item-content-wrapper">
                          {item?.selectedTreatment?.length ? (
                            item?.selectedTreatment?.map((itm, idx) => {
                              return (
                                <BaseRow
                                  key={idx}
                                  align={'middle'}
                                  gutter={[16, 8]}
                                  justify={'space-between'}
                                >
                                  <BaseCol xl={10}>
                                    <ProfileServiceComponent itm={itm} />
                                  </BaseCol>
                                  <BaseCol xl={4}>
                                    <BaseFormItem hiddenLabel>
                                      <InputNumberWrapper
                                        value={itm?.quantity}
                                        placeholder={t(
                                          R.strings.enter_quantity,
                                        )}
                                        onChange={value =>
                                          onChangeQuantityItemBundleService?.(
                                            index,
                                            idx,
                                            value as number,
                                          )
                                        }
                                        min={1}
                                      />
                                    </BaseFormItem>
                                  </BaseCol>
                                  <BaseCol xl={4}>
                                    <BaseFormItem hiddenLabel>
                                      <InputNumberWrapper
                                        value={itm?.discountValue}
                                        placeholder={t(
                                          R.strings.enter_discount_value,
                                        )}
                                        onChange={value =>
                                          onChangeDiscountValueItemBundleService?.(
                                            index,
                                            idx,
                                            value as number,
                                          )
                                        }
                                        min={0}
                                        max={
                                          isEqual(
                                            itm?.discountType,
                                            DiscountTypeEnum.Percent,
                                          )
                                            ? 100
                                            : undefined
                                        }
                                        precision={
                                          isEqual(
                                            itm?.discountType,
                                            DiscountTypeEnum.Percent,
                                          )
                                            ? 1
                                            : 0
                                        }
                                        disabled={
                                          itm?.discountType === undefined
                                        }
                                      />
                                    </BaseFormItem>
                                  </BaseCol>
                                  <BaseCol xl={4}>
                                    <BaseFormItem hiddenLabel>
                                      <BaseSelect
                                        value={itm?.discountType}
                                        placeholder={t(
                                          R.strings.select_discount_type,
                                        )}
                                        onChange={value =>
                                          onChangeDiscountTypeItemBundleService?.(
                                            index,
                                            idx,
                                            value as DiscountTypeEnum,
                                          )
                                        }
                                        options={[
                                          {
                                            label: t(
                                              R.strings.discount_percent,
                                            ),
                                            value: DiscountTypeEnum.Percent,
                                          },
                                          {
                                            label: t(R.strings.cash),
                                            value: DiscountTypeEnum.Amount,
                                          },
                                        ]}
                                      />
                                    </BaseFormItem>
                                  </BaseCol>
                                  <BaseCol xl={2}>
                                    <BaseRow justify={'center'}>
                                      <BaseButton
                                        icon={<SvgDeleteDesignIcon />}
                                        type="text"
                                        onClick={() =>
                                          onDeleteItemInSelectedTreatment?.(
                                            index,
                                            idx,
                                          )
                                        }
                                      />
                                    </BaseRow>
                                  </BaseCol>
                                </BaseRow>
                              )
                            })
                          ) : (
                            <BaseText
                              children={t(R.strings.empty_list)}
                              fontWeight="medium"
                              opacity="0.5"
                            />
                          )}
                        </BaseSpace>
                      </BaseSpace>
                    ),
                  },
                ]}
                expandIconPosition="right"
                key={index}
              />
            )
          })
        ) : (
          <BaseEmpty />
        )}
      </ContentWrapper>
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
`

const HeaderWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
  border-start-start-radius: ${BORDER_RADIUS};
`

const ContentWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
  background-color: #f9fafb;
  border-start-end-radius: ${BORDER_RADIUS};
  .dash-line {
    border-top: 1px dashed ${convertedVariables.neutralBlack2Color};
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`

const CollapseWrapper = styled(BaseCollapse)`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};

  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-content {
    border-top: 1px solid ${convertedVariables.neutralBlack9Color};
    background-color: ${convertedVariables.neutralBlack9Color};
    .ant-collapse-content-box {
      padding: 0px !important;
    }
  }

  .ant-collapse-header {
    background-color: var(--background-color);
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background-color: #f9fafb;
  }

  .list-item-content-wrapper {
    padding: ${PADDING.md};
    background-color: ${convertedVariables.backgroundColor};
  }
`

const InputNumberWrapper = styled(InputNumber)`
  width: 100%;
`
