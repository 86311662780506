import { PlusOutlined } from '@ant-design/icons'
import { Upload } from 'antd'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { DraggerStyled } from 'app/components/common/forms/BaseUploadDragger/styles'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  width: 100%;
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  background-color: white; // need change
`

export const TitleImageService = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
`

export const SplitLine = styled(BaseDivider)`
  margin: 0px;
`

export const ChooseImageWrapper = styled(BaseRow)`
  margin-left: 0px !important;
  margin-right: 0px !important;
  .ant-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
`

export const MainImageWrapper = styled(DraggerStyled)`
  width: 265px !important;
  height: 265px !important;
  border-radius: ${BORDER_RADIUS};
  .ant-upload-list-item-container
    .ant-upload-list-item.ant-upload-list-item-done {
    width: 265px !important;
    height: 265px !important;
    border-style: dashed;
  }
  .ant-upload-list-item-container {
    width: 265px !important;
    height: 265px !important;
  }
  .ant-upload-list-item-container
    .ant-upload-list-item.ant-upload-list-item-done
    .ant-btn-icon {
    background-color: transparent !important;
    padding: 0px;
  }
`

export const ImageWrapper = styled(Upload)`
  .ant-upload-list-item-container
    .ant-upload-list-item.ant-upload-list-item-done {
    border-style: dashed;
  }
  .ant-upload-list-item-container {
    width: 128px !important;
    height: 128px !important;
  }
  .ant-upload-select {
    width: 128px !important;
    height: 128px !important;
  }
  .ant-upload-list-item-container
    .ant-upload-list-item.ant-upload-list-item-done
    .ant-btn-icon {
    background-color: transparent !important;
    padding: 0px;
  }
`

export const TitleUpload = styled.span<{ $fontSize?: string }>`
  color: var(--collapse-background-color);
  font-size: ${props => props.$fontSize ?? FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
`

export const PlusIcon = styled(PlusOutlined)<{ $size?: string }>`
  svg {
    fill: var(--collapse-background-color);
    width: ${props => props?.$size ?? '16px'} !important;
    height: ${props => props?.$size ?? '16px'} !important;
  }
`

export const UploadWrapper = styled(BaseSpace)``
