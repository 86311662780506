import { CreateUpdateContext, CreateUpdateProvider } from './context'

import HeaderPage from 'app/components/header/components/HeaderPage'
import * as S from './styles'
import { useParams } from 'react-router'
import { t } from 'i18next'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import Info from './components/Info'
import R from 'app/assets/R'
import SelectionSkillLevel from '../components/modal/treatment/components/SelectionSkillLevel'
import { StatusEnum } from 'app/common/enum'
import SuppliesManagement from './components/SuppliesManagement'

const DEFAULT_TIME = 30

const initialValues = {
  isInventoryManagement: true,
  executeTime: DEFAULT_TIME,
  doctorTime: DEFAULT_TIME,
  chairTime: DEFAULT_TIME,
  status: StatusEnum.ACTIVE,
}

const CreateUpdateProductServiceManagement = () => {
  const { type: typeParam } = useParams()
  return (
    <CreateUpdateProvider>
      <CreateUpdateContext.Consumer>
        {({ form, handleSubmit }) => {
          return (
            <S.RootWrapper direction="vertical" size={10}>
              <HeaderPage titleI18nKey={R.strings[`add_${typeParam}`]} />
              <BaseForm
                layout="vertical"
                onFinish={handleSubmit}
                requiredMark="optional"
                form={form}
                initialValues={initialValues}
              >
                <S.RootWrapper direction="vertical" size={10}>
                  <Info />
                  <SuppliesManagement />
                  <SelectionSkillLevel form={form} />
                </S.RootWrapper>
                <S.FormItem>
                  <S.ButtonActionGroup>
                    <S.ButtonActionGroup>
                      <S.ButtonCancel
                        onClick={() => {
                          form?.resetFields()
                        }}
                      >
                        {t(R.strings.cancel)}
                      </S.ButtonCancel>
                      <S.ButtonSubmit htmlType="submit">
                        {t(R.strings.confirm)}
                      </S.ButtonSubmit>
                    </S.ButtonActionGroup>
                  </S.ButtonActionGroup>
                </S.FormItem>
              </BaseForm>
            </S.RootWrapper>
          )
        }}
      </CreateUpdateContext.Consumer>
    </CreateUpdateProvider>
  )
}

export default CreateUpdateProductServiceManagement
