/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetReport07 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { IResponseGetReport07 } from 'app/api/report/model/report-07'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import {
  IDataBaseTable,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { initialPagination } from 'parkway-web-common'
import { isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import {
  convertApiDataToTableData,
  convertPercent,
  sumTableData,
  useCommonHook,
} from '../common-hook'
import { IGeneralReport07Table } from '../common-model'
import { IDataReport07Monthly } from './data'

export const useTableYearHook = (props: IParamsHookReport) => {
  const getDataYear = async (
    pagination: Pagination,
    params: IParamsHookReport,
  ) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IDataReport07Monthly>
    let totalTableItem: IGeneralReport07Table<IDataReport07Monthly> = {
      isTotalTable: true,
      area: 'Tổng',
    }

    try {
      const response: IResponseGetReport07 = await requestGetReport07({
        year: props.year,
        ...params,
      })
      if (
        isEqual(response?.msgcode, ReportMessageCode.Report07.successGetData)
      ) {
        const areas = response?.data ?? []
        const dataTable: IDataReport07Monthly[] =
          areas?.map((itemArea, index) => {
            const [firstClinic, ...otherClinic] = itemArea?.clinics ?? []
            const refererData = convertApiDataToTableData({
              referers: firstClinic.referrers,
              total: firstClinic?.projected,
            })

            const refererDataTotal = convertApiDataToTableData({
              referers: itemArea.referrers,
              total: itemArea?.projected,
              type: 'total',
            })

            const newArea = {
              key: index + 1,
              area: itemArea.area,
              clinic: firstClinic?.clinic,

              totalRevenueActual: firstClinic?.projected,
              totalRevenuePlan: firstClinic?.total ?? 0,
              percentTargetComplete: convertPercent(
                firstClinic?.projected,
                firstClinic?.total ?? 0,
              ),
              ...refererData,

              totalRevenueActualTotal: itemArea?.projected,
              totalRevenuePlanTotal: itemArea?.total ?? 0,
              percentTargetCompleteTotal: convertPercent(
                itemArea?.projected,
                itemArea?.total ?? 0,
              ),
              ...refererDataTotal,

              children: otherClinic.map((otherItem, idx) => {
                const refererOtherData = convertApiDataToTableData({
                  referers: otherItem.referrers,
                  total: otherItem?.projected,
                })
                return {
                  key: Number(`${index + 1}${idx + 1}`),
                  clinic: otherItem.clinic,
                  parentKey: index + 1,
                  totalRevenueActual: otherItem?.projected,
                  totalRevenuePlan: otherItem?.total ?? 0,
                  index: idx + 1,
                  percentTargetComplete: convertPercent(
                    otherItem?.projected,
                    otherItem?.total ?? 0,
                  ),
                  ...refererOtherData,
                }
              }),
            }

            totalTableItem = sumTableData({
              newData: newArea,
              total: totalTableItem,
            })

            return newArea
          }) ?? []

        return {
          ...initData,
          data: [
            ...dataTable,
            {
              key: dataTable?.length + 1,
              ...totalTableItem,
            },
          ],
        }
      }
      return initData
    } catch (error) {
      return initData
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,
    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({
    getTreeTableData: getDataYear,
  })

  const { columns, exportExcel } = useCommonHook({
    expandedRowKeys,
    typeHook: 'YEAR',
    handleExpand,
    fetchLoading,
    currentFilter: props,
  })

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleResetExpand,
    fetch,
    fetchLoading,
    exportExcel
  }
}
