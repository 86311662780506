import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import Update from 'app/containers/HumanResources/ProfileManagement/UpdateProfileContainer'

const ProfileUpdatePageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.update)}</PageTitle>
      <Update />
    </>
  )
}
const ProfileUpdatePage = memo(ProfileUpdatePageCpn, isEqual)

export default ProfileUpdatePage
