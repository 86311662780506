import { BaseCol } from 'app/components/common/BaseCol'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BORDER_RADIUS, LAYOUT, media } from 'parkway-web-common'
import styled from 'styled-components'
import { ListWorkThisWeekLayout, ListWorkTodayLayout } from './layouts'

export const LeftSideEmployeeLayout = () => {
  return (
    <LeftSideCol xl={18}>
      <BaseSpace>
        <ListWorkTodayLayout />
        <ListWorkThisWeekLayout />
      </BaseSpace>
    </LeftSideCol>
  )
}

const LeftSideCol = styled(BaseCol)`
  border-radius: ${BORDER_RADIUS};
  min-width: 740px;
  @media only screen and ${media.xl} {
    height: calc(100vh - ${LAYOUT.desktop.headerHeight} - 10vh);
    overflow: auto;
  }
`
