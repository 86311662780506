export enum AgeGroupEnum {
    AGE_0_8 = '0-8',
    AGE_9_14 = '9-14',
    AGE_15_17 = '15-17',
    AGE_18_23 = "18-23",
    AGE_24_27 = "24-27",
    AGE_28_34 = "28-34",
    AGE_35_44 = "35-44",
    AGE_45_54 = "45-54",
    AGE_MORE_54 = ">54"
}

export enum GenderEnum {
    MALE = 'male',
    FEMALE = 'female',
    OTHER = "other"
}

