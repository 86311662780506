/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import { IBaseTableReport } from 'app/components/tables/BaseTableReport/type'

export enum TypeItemEnum {
  service = 'service',
  product = 'product',
  combo = 'combo',
  supplies = 'supplies',
  asset = 'asset',
  tools = 'tools',
}

export const DefineValueTypeItem = {
  [TypeItemEnum.service]: 'Dịch vụ',
  [TypeItemEnum.product]: 'Sản phẩm',
  [TypeItemEnum.combo]: 'Combo',
  [TypeItemEnum.asset]: 'Tài sản',
  [TypeItemEnum.tools]: 'Dụng cụ',
  [TypeItemEnum.supplies]: 'Vật tư',
}

export enum StatusProductServiceEnum {
  IsUsing = 1,
  NotUsing = 2,
}

export const DefineStatusProductService = {
  [StatusProductServiceEnum.IsUsing]: {
    i18nKey: R.strings.is_using,
    backgroundImage: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [StatusProductServiceEnum.NotUsing]: {
    i18nKey: R.strings.not_using,
    backgroundImage: 'linear-gradient(90deg, #FF6B6B, #F44949)',
  },
}

export interface IDataTableItemManagement
  extends IBaseTableReport<IDataTableItemManagement> {
  _id: string
  status: StatusProductServiceEnum
  type: TypeItemEnum
  name?: string
  categoryId?: string
  ordinalNumber?: number
  doctorJobTitleLevelIds?: any[]

  categoryName?: string
  purchaseUnit?: string
  usingUnit?: string
  minimumOneTimePayment?: number
  minimumInstallmentPaymentInClinic?: number
  endOfShiftPayment?: number
}
