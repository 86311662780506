import R from 'app/assets/R'
import {
  RenderValueTable,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { isEmpty, isEqual } from 'lodash'
import { Pagination, initialPagination, moment } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IGeneralWarehousePurchaseManagementTable } from './type'

import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { ValueTableWrapper } from 'app/components/tables/BaseTableManagement/styles'
import { BaseCol } from 'app/components/common/BaseCol'

import {
  requestExportWarehousePurchaseManagement,
  requestGetWarehousePurchaseManagement,
} from 'app/api/inventory-new'
import { MessageCode } from 'app/api/inventory-new/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { IMAGE_ACTION } from 'app/common/config'
import { ActionButton, ActionDropdown, StatusButton } from './styles'
import {
  DATE,
  PurchaseOrderActionFromStatus,
  PurchaseOrderActionIcon,
  PurchaseOrderActionName,
  PurchaseOrderActionPath,
  PurchaseOrderStatusColor,
  PurchaseOrderStatusIcon,
  PurchaseOrderStatusName,
} from 'app/common/constants'
import {
  IParamsGetWarehousePurchaseManagement,
  IResponseGetWarehousePurchaseManagement,
} from 'app/api/inventory-new/model/management'
import { useNavigate } from 'react-router'
import {
  AuthorInfo,
  AuthorJobName,
  AuthorName,
  AuthorUserLeft,
  TableAvatar,
} from 'app/containers/CustomerCare/SearchAppointment/styles'
interface IResponseFilter {
  search?: string
  status?: number
}

const initResponseFilterTableManagement = {}
export const useHookTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.INVENTORY,
      FunctionPermissionEnum.WAREHOUSE_READ,
      R.strings.role,
    )
  }, [])

  const onClickExport = async params => {
    try {
      const response = await requestExportWarehousePurchaseManagement({
        keyword: params?.search ?? filter?.search,
      })
      const url = `${IMAGE_ACTION.IMAGE_SERVER}/${response?.data?.Url}`
      window.open(url, '_blank', 'noopener,noreferrer')
    } catch (error) {
      // console.log({ error })
      notificationController.error({
        message: t(R.strings.error),
      })
    }
  }
  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IResponseFilter,
  ) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IGeneralWarehousePurchaseManagementTable>
    try {
      const status = newFilter?.status ?? filter?.status
      let params: IParamsGetWarehousePurchaseManagement = {
        page: pagination?.current,
        pagesize: pagination?.pageSize,
        keyword: newFilter?.search ?? filter?.search,
      }

      params = { ...params, ...newFilter, status }

      const response: IResponseGetWarehousePurchaseManagement =
        await requestGetWarehousePurchaseManagement(params)

      if (isEqual(response?.msgcode, MessageCode.successGetData)) {
        const data = response?.data
        const metadata = response?.metadata

        const startIndex =
          ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

        const transformData = data => {
          if (isEmpty(data)) {
            return data
          }

          return data?.map((item, index) => {
            return {
              key: `${item?._id}-${index + 1}`,
              ordinalNumber: startIndex + index + 1,
              purchaserId: item?.purchaserId ?? '-',
              purchaser: item?.purchaser,
              code: item?.code ?? '-',
              receiverId: item?.receiverId ?? '-',
              phone: item?.phone ?? '-',
              warehouseReceiveId: item?.warehouseReceiveId ?? '-',
              warehouseReceive: item?.warehouseReceive,
              address: item?.address ?? '-',
              paymentDueDate: item?.paymentDueDate,
              estimateDeliveryDate: item?.estimateDeliveryDate,
              supplierId: item?.supplierId,
              supplier: item?.supplier,
              responsiblePersonName: item?.responsiblePersonName,
              isSentToSupplier: item?.isSentToSupplier,
              goodsReceipts: item?.goodsReceipts,
              status: item?.status,
              discount: item?.discount,
              _id: item?._id ?? '-',
            }
          })
        }

        const dataTables = transformData(data)

        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pageSize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IGeneralWarehousePurchaseManagementTable>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, selectRowsTable, fetch, rowSelection } =
    useHookTableManagement({ getDataTable })

  const handleTableChange = pagination => {
    fetch(pagination, filter)
  }

  const handleChangeFilter = (newFilter: IResponseFilter) => {
    if (!isEqual(newFilter, filter)) {
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initialPagination.current,
        },
        newFilter,
      )
    }
  }

  const fetchNewData = () => {
    fetch(initialPagination)
    setFilter(initResponseFilterTableManagement)
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralWarehousePurchaseManagementTable>({
        title: '#',
        key: 'ordinalNumber',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => (
          <RenderValueTable value={record.ordinalNumber} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralWarehousePurchaseManagementTable>({
        title: t(R.strings.order_id),
        key: 'code',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.code} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralWarehousePurchaseManagementTable>({
        title: t(R.strings.warehouse_import),
        key: 'warehouseReceive',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.warehouseReceive?.name}
            type="Base"
          />
        ),
      }),

      convertColumnTable<IGeneralWarehousePurchaseManagementTable>({
        title: t(R.strings.provider),
        key: 'supplier',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <RenderValueTable value={record?.supplier?.name} type="Main" />
        ),
      }),

      convertColumnTable<IGeneralWarehousePurchaseManagementTable>({
        title: t(R.strings.person_request),
        key: 'purchaser',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <>
            {record?.purchaser && (
              <AuthorUserLeft>
                <TableAvatar
                  alt={record?.purchaser?.name}
                  src={record?.purchaser?.avatar}
                />
                <AuthorInfo>
                  <AuthorName>{record?.purchaser?.name}</AuthorName>
                  <AuthorJobName>{record?.purchaser?.jobTitle}</AuthorJobName>
                </AuthorInfo>
              </AuthorUserLeft>
            )}
          </>
        ),
      }),

      convertColumnTable<IGeneralWarehousePurchaseManagementTable>({
        title: t(R.strings.created_at),
        key: 'createdAt',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable
            value={moment(record?.createdAt).format(DATE.YYYY_MM_DD_HH_MM)}
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralWarehousePurchaseManagementTable>({
        title: t(R.strings.status),
        key: 'status',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          const statusColor = PurchaseOrderStatusColor[Number(record?.status)]
          const IconComponent = PurchaseOrderStatusIcon[Number(record?.status)]

          return (
            <StatusButton
              $color={statusColor?.color}
              $background={statusColor?.background}
            >
              {IconComponent && <IconComponent />}
              {t(PurchaseOrderStatusName[Number(record?.status)])}
            </StatusButton>
          )
        },
      }),

      convertColumnTable<IGeneralWarehousePurchaseManagementTable>({
        title: '',
        key: 'action',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => {
          return (
            <ValueTableWrapper
              justify={'end'}
              align={'middle'}
              gutter={[10, 10]}
              wrap={false}
            >
              <BaseCol>
                <ActionDropdown
                  actionComponent={
                    <>
                      {PurchaseOrderActionFromStatus[
                        Number(record?.status)
                      ].map(key => {
                        const IconComponent = PurchaseOrderActionIcon[key]
                        const ActionName = PurchaseOrderActionName[key]
                        return (
                          <ActionButton
                            icon={<IconComponent />}
                            onClick={() =>
                              navigate(
                                `${PurchaseOrderActionPath[key].replace(
                                  ':id',
                                  record._id ?? '',
                                )}`,
                              )
                            }
                          >
                            {t(ActionName)}
                          </ActionButton>
                        )
                      })}
                    </>
                  }
                />
              </BaseCol>
            </ValueTableWrapper>
          )
        },
      }),
    ]
    return option
  }, [t, fetchNewData])

  return {
    tableData,
    selectRowsTable,
    handleTableChange,
    fetchNewData,
    rowSelection,
    columns,
    handleChangeFilter,
    filter,
    onClickExport,
  }
}
