import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import ListReferralVoucherCodeContainer from 'app/containers/Marketing/VoucherManagement/ListReferralVoucherCode'

const ListReferralVoucherCodePageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.referral_voucher_code_list)}</PageTitle>
      <ListReferralVoucherCodeContainer />
    </>
  )
}
const ListReferralVoucherCodePage = memo(ListReferralVoucherCodePageCpn, isEqual)

export default ListReferralVoucherCodePage
