import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import DetailContainer from 'app/containers/Marketing/VoucherManagement/DetailVoucher'

const DetailVoucherPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.detail_voucher)}</PageTitle>
      <DetailContainer />
    </>
  )
}
const DetailVoucherPage = memo(DetailVoucherPageCpn, isEqual)

export default DetailVoucherPage
