import R from 'app/assets/R'
import {
  SvgDeleteDesignIcon,
  SvgImplantPlacementIcon,
  SvgRemainingToothRootsIcon,
  SvgTeethWithPathologyIcon,
  SvgToothBridgeIcon,
  SvgToothLossIcon,
  SvgToothWithIndicationForExtractionIcon,
} from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseImage } from 'app/components/common/BaseImage'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import DividerLine from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/DividerLine'
import {
  ToothStatusEnum
} from 'app/containers/DentalTreatmentManagement/TreatmentPlan/type'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ButtonToothAction } from '../ButtonToothAction'
import { useHook } from './hook'
import { IPropsToothAction } from './type'


export const ToothActionCurrentStatusTabComponent: React.FC<IPropsToothAction> = props => {
  const { heightImage = 100, widthImage = 50, item } = props
  const { t } = useTranslation()

  const {
    _onChangeImplantPlacement,
    _onChangeRemainingToothRoots,
    _onChangeTeethWithPathology,
    _onChangeToothLoss,
    _onChangeToothWithIndicationForExtraction,
    _onDeleteStatusTooth,
    iconDefine,
    opacityImage,
    urlImage,
    isDisableAction,
    _onAddBridge,
    bridgeBottomTabCurrentStatusSelecting,
    bridgeTopTabCurrentStatusSelecting,
    _onChangeBridge,
    currentBridgeTooth
  } = useHook(props)

  return (
    <div onClick={event => event?.stopPropagation()}>
      {bridgeBottomTabCurrentStatusSelecting || bridgeTopTabCurrentStatusSelecting ? (
        <BaseButtonWrapper
          type={'text'}
          icon={
            <div>
              <BaseImage
                preview={false}
                src={urlImage}
                width={widthImage}
                height={heightImage}
                style={{ opacity: opacityImage }}
              />
              {iconDefine ? (
                <div
                  style={{
                    position: 'absolute',
                    bottom: 8,
                    right: -5,
                  }}
                >
                  {iconDefine}
                </div>
              ) : null}
            </div>
          }
          onClick={event => {
            event?.stopPropagation()
            _onChangeBridge?.()
          }}
          disabled={isDisableAction}
        />
      ) : (
        <BasePopover
          content={
            <BaseSpace>
              <ButtonToothAction
                icon={<SvgTeethWithPathologyIcon />}
                onClick={_onChangeTeethWithPathology}
                key18nKey={R.strings.teeth_with_pathology}
                isSelected={isEqual(
                  item?.currentStatus,
                  ToothStatusEnum.teethWithPathology,
                )}
              />

              <ButtonToothAction
                icon={<SvgToothLossIcon />}
                onClick={_onChangeToothLoss}
                key18nKey={R.strings.tooth_loss}
                isSelected={isEqual(
                  item?.currentStatus,
                  ToothStatusEnum.toothLoss,
                )}
              />

              <ButtonToothAction
                icon={<SvgImplantPlacementIcon />}
                onClick={_onChangeImplantPlacement}
                key18nKey={R.strings.implant_placement}
                isSelected={isEqual(
                  item?.currentStatus,
                  ToothStatusEnum.implantPlacement,
                )}
              />

              <ButtonToothAction
                icon={<SvgToothWithIndicationForExtractionIcon />}
                onClick={_onChangeToothWithIndicationForExtraction}
                key18nKey={R.strings.tooth_with_indication_for_extraction}
                isSelected={isEqual(
                  item?.currentStatus,
                  ToothStatusEnum.toothWithIndicationForExtraction,
                )}
              />

              <ButtonToothAction
                icon={<SvgRemainingToothRootsIcon />}
                onClick={_onChangeRemainingToothRoots}
                key18nKey={R.strings.remaining_tooth_roots}
                isSelected={isEqual(
                  item?.currentStatus,
                  ToothStatusEnum.remainingToothRoots,
                )}
              />

              <ButtonToothAction
                icon={<SvgToothBridgeIcon />}
                onClick={_onAddBridge}
                key18nKey={R.strings.tooth_bridge}
                isSelected={!!currentBridgeTooth}
              />

              <DividerLine />

              <BaseButtonAction
                onClick={_onDeleteStatusTooth}
                type="text"
                icon={<SvgDeleteDesignIcon />}
              >
                {t(R.strings.delete_tooth_condition)}
              </BaseButtonAction>
            </BaseSpace>
          }
          trigger="click"
        >
          <BaseButtonWrapper
            type={'text'}
            icon={
              <div>
                <BaseImage
                  preview={false}
                  src={urlImage}
                  width={widthImage}
                  height={heightImage}
                  style={{ opacity: opacityImage }}
                />
                {iconDefine ? (
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 8,
                      right: -5,
                    }}
                  >
                    {iconDefine}
                  </div>
                ) : null}
              </div>
            }
            onClick={event => {
              event?.stopPropagation()
            }}
            disabled={isDisableAction}
          />
        </BasePopover>
      )}
    </div>
  )
}

const BaseButtonWrapper = styled(BaseButton)`
  height: fit-content !important;
  width: fit-content !important;
  border: 2px solid transparent;
  &:focus {
    border: 2px solid #9747ff;
    background-image: linear-gradient(180deg, #ffffff, #e9ebf8);
  }
`

const BaseButtonAction = styled(BaseButton)`
  width: 100%;
  justify-content: flex-start !important;
`

