import React from 'react'
import {
  BaseForm,
  BaseFormInterface,
  BaseFormProps,
} from 'app/components/common/forms/BaseForm'
import { BaseButtonsGroup } from 'app/components/common/forms/components/BaseButtonsGroup'
import { BaseFormTitle } from 'app/components/common/forms/components/BaseFormTitle'
import { BaseFormItem } from 'app/components/common/forms/components/BaseFormItem'
import { BaseFormList } from 'app/components/common/forms/components/BaseFormList'

export interface BaseButtonsFormProps extends BaseFormProps {
  isFieldsChanged: boolean
  setFieldsChanged?: (state: boolean) => void
  footer?: React.ReactElement
  loading?: boolean
}

export const BaseButtonsForm: BaseFormInterface<BaseButtonsFormProps> = ({
  form,
  isFieldsChanged,
  setFieldsChanged,
  footer,
  loading = false,
  children,
  ...props
}) => {
  const [formDefault] = BaseForm.useForm()
  const currentForm = form || formDefault

  const onCancel = () => {
    currentForm?.resetFields()
    setFieldsChanged && setFieldsChanged(false)
  }

  return (
    <BaseForm form={currentForm} {...props}>
      {children}
      {isFieldsChanged &&
        (footer || <BaseButtonsGroup loading={loading} onCancel={onCancel} />)}
    </BaseForm>
  )
}

BaseButtonsForm.Title = BaseFormTitle
BaseButtonsForm.Item = BaseFormItem
BaseButtonsForm.List = BaseFormList
BaseButtonsForm.useForm = BaseForm.useForm
BaseButtonsForm.Provider = BaseForm.Provider
