/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import deVi from 'antd/lib/locale/vi_VN'
import { AppRouter } from 'app/components/router/app-router'
import { JobTitleProvider } from 'app/context/JobTitleContext'
import { ProfileProvider } from 'app/context/ProfileContext'
import { TreatmentGroupProvider } from 'app/context/TreatmentGroupContext'
import { UnitProvider } from 'app/context/UnitContext'
import { LanguageSupportType } from 'app/i18n/constant'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import {
  getCurrentLanguage,
  getToken,
} from 'app/service/storage/storage-service'
import GlobalStyle from 'app/styles/GlobalStyle'
import { themeObject } from 'app/styles/themes/themeVariables'
import { useEffect, useRef } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import './app/i18n'
import {
  INotificationResponse,
  getMessagingToken,
  onMessageListener,
} from 'app/utils/firebase'
import { notificationController } from 'app/controllers/notification-controller'
import { requestUpdateMessageToken } from 'app/api/profile'
import { changeMessage, getUserInfo } from 'app/redux/slices/profileSlice'
import NotificationSound from './app/assets/sound/notification-sound.mp3'
import { NOTIFICATION_TYPE } from 'app/common/constants'
import { isEqual } from 'lodash'

const queryClient = new QueryClient()

const App: React.FC = () => {
  const language = getCurrentLanguage() as LanguageSupportType
  const theme = useAppSelector(state => state.theme.theme)
  const profile = useAppSelector(state => state.profileSlice)?.profile
  const notificationMessage = useAppSelector(state => state.profileSlice)?.message
  const user = useAppSelector(state => state.user)?.user
  const { i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const token = getToken()
  const audioPlayer = useRef<any>(null)

  function playAudio() {
    audioPlayer?.current?.play()
  }

  const getLanguage = () => {
    if (language) {
      i18n.changeLanguage(language)
      return
    }
  }

  const onChangeMessageToken = async () => {
    try {
      const profileId = profile?._id || user?._id || user?.id
      const token = await getMessagingToken()

      if (!token || !profileId) return

      await requestUpdateMessageToken({
        id: String(profileId),
        messagingToken: token,
      })
    } catch (error) {
      console.log('error')
    }
  }

  const getData = async () => {
    if (token) {
      await dispatch(getUserInfo())
      onChangeMessageToken()
    }
  }

  useEffect(() => {
    getData()
    getLanguage()
  }, [token])

  useEffect(() => {
    const channel = new BroadcastChannel('notifications')
    channel.addEventListener('message', event => {
      const res: INotificationResponse = event.data as INotificationResponse
      playAudio()
      notificationHandler(res)
    })
  }, [])

  useEffect(() => {
    onMessageListener().then(data => {
      const res: INotificationResponse = data as INotificationResponse
      playAudio()
      notificationHandler(res)
    })
  })

  const notificationHandler = res => {
    const newPayload = JSON.parse(res.data?.payload || '{}')
    const profilePayload = JSON.parse(notificationMessage?.payload || '{}')

    switch (res.data?.type) {
      case NOTIFICATION_TYPE.APPOINTMENT:
        // do some things
        // console.log(NOTIFICATION_TYPE.APPOINTMENT)

        notificationController.success({
          message: res?.notification?.title,
          description: `${res?.notification?.body}`,
        })
        dispatch(
          changeMessage({
            type: NOTIFICATION_TYPE.APPOINTMENT,
            currentTime: new Date(),
          }),
        )
        break

      case NOTIFICATION_TYPE.EXPORT_DAILY_INVOICE:
      case NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT:
      case NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT_CONTRACT:
        if (!isEqual(profilePayload, newPayload)) {
          dispatch(
            changeMessage({
              type: res.data?.type,
              currentTime: new Date(),
              payload: res.data?.payload,
            }),
          )
        }

        break

      case 'OTHER':
        // do somethings
        // console.log('OTHER')

        break

      default:
        // console.log('default')
        break
    }
  }

  return (
    <QueryClientProvider client={queryClient}>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <audio ref={audioPlayer} src={NotificationSound} />
      <GlobalStyle />
      <HelmetProvider>
        <ConfigProvider locale={language === 'en' ? enUS : deVi}>
          <ProfileProvider>
            <JobTitleProvider>
              <UnitProvider>
                <TreatmentGroupProvider>
                  <AppRouter />
                </TreatmentGroupProvider>
              </UnitProvider>
            </JobTitleProvider>
          </ProfileProvider>
        </ConfigProvider>
      </HelmetProvider>
    </QueryClientProvider>
  )
}
export default App
