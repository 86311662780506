import { convertedVariables } from "app/styles/themes/themeVariables"
import { UnWorkingDayTypeEnum } from "./models/approve-day-off"

// Message code define
export const HrMessageCode = {
  Unit: {
    successGetData: '20001',
  },
  DayOff: {
    success: '20000',
  },
}

// Endpoint
export const HrEndPoint = {
  Unit: {
    requestGetData: 'hr/v1/unit/find',
  },
  DayOff: {
    requestTotal: 'hr/v1/un-working-day/approver/dashboard/type-each-days',
    requestList: 'hr/v1/un-working-day/approver/dashboard',
  },
}



export const DefineUnWorkingDayTypeEnum = {
  [UnWorkingDayTypeEnum.AL]: {
    backgroundSecond: 'rgba(165, 132, 255, 1)',
    backgroundLight: 'rgba(165, 132, 255, 0.5)',
    color: 'rgba(165, 132, 255, 1)',
    text: 'AL',
  },
  [UnWorkingDayTypeEnum.SL]: {
    backgroundSecond: 'rgba(165, 132, 255, 1)',
    backgroundLight: 'rgba(165, 132, 255, 0.5)',
    color: 'rgba(165, 132, 255, 1)',
    text: 'SL',
  },
  [UnWorkingDayTypeEnum.HL]: {
    backgroundSecond: 'rgba(165, 132, 255, 1)',
    backgroundLight: 'rgba(165, 132, 255, 0.5)',
    color: 'rgba(165, 132, 255, 1)',
    text: 'HL',
  },
  [UnWorkingDayTypeEnum.PL]: {
    backgroundSecond: 'rgba(165, 132, 255, 1)',
    backgroundLight: 'rgba(165, 132, 255, 0.5)',
    color: 'rgba(165, 132, 255, 1)',
    text: 'PL',
  },
  [UnWorkingDayTypeEnum.DO]: {
    backgroundSecond: 'rgba(98, 157, 170, 1)',
    backgroundLight: 'rgba(232, 246, 249, 1)',
    color: 'rgba(98, 157, 170, 1)',
    text: 'DO',
  },
  [UnWorkingDayTypeEnum.UL]: {
    backgroundSecond: convertedVariables.statesRedColor,
    backgroundLight: convertedVariables.statesRedLightColor,
    color: convertedVariables.statesRedColor,
    text: 'UL',
  },
  [UnWorkingDayTypeEnum.ALC]: {
    backgroundSecond: 'rgba(165, 132, 255, 1)',
    backgroundLight: 'rgba(165, 132, 255, 0.5)',
    color: 'rgba(165, 132, 255, 1)',
    text: 'UL',
  },
}