import { MoreOutlined } from '@ant-design/icons'
import { BaseButton } from 'app/components/common/BaseButton'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { useState } from 'react'
import {
  PrintContractModal,
  RefundAmountModal,
  ServiceRefundModal,
} from '../../../../modals'

export const TreatmentScheduleAction: React.FC = () => {
  const [isOpened, setOpened] = useState(false)

  return (
    <BasePopover
      content={
        <BaseSpace size={10}>
          <RefundAmountModal />
          <PrintContractModal />
          <ServiceRefundModal />
        </BaseSpace>
      }
      trigger="click"
      onOpenChange={setOpened}
    >
      <BaseButton
        type={isOpened ? 'link' : 'default'}
        icon={<MoreOutlined rev={undefined} />}
      />
    </BasePopover>
  )
}
