/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collapse } from 'antd'
import { requestExportBracesTray } from 'app/api/accounting'
import R from 'app/assets/R'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IParamsHookReport } from 'app/components/tables/BaseTableReport/type'
import { notificationController } from 'app/controllers/notification-controller'
import { Pagination, moment } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ALink } from '../DebtContract/styles'
import { ENV_CONFIG } from 'app/common/config'

export const useCommonHook = ({
  expandedRowKeys,
  fetchLoading,
}: {
  expandedRowKeys: number[]
  fetchLoading: (value: boolean) => void
  fetch: (pagination: Pagination, params?: IParamsHookReport) => void
  handleExpand: (isExpanded: boolean, key?: number) => void
  currentFilter?: IParamsHookReport
}) => {
  const { t } = useTranslation()

  const onClickExport = async params => {
    fetchLoading(true)
    try {
      if (!params?.startDate && !params?.endDate) {
        notificationController.error({
          message: t(R.strings.select_range_date_required),
        })
      } else {
        const response = await requestExportBracesTray({
          keyword: params?.search,
          startDate: params?.startDate,
          endDate: params?.endDate,
          paidTypes: params?.paidType?.join(','),
        })
        window.open(response?.data, '_blank', 'noopener,noreferrer')
      }
    } catch (error) {
     // console.log({ error })
    }
    fetchLoading(false)
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<{
        patient: { id: string }
      }>({
        title: t(R.strings.manage_customer_patient_code),
        key: 'patient',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.patient?.id} type="Main" />
        ),
      }),
      convertColumnTable<{
        patient: { name: string, _id: string }
        patient_name
      }>({
        title: t(R.strings.manage_customer_patient_name),
        key: 'patient_name',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <ALink
            onClick={e => {
              e.preventDefault()
              window.open(
                ENV_CONFIG.OLD_WEBSITE_ENDPOINT +
                  '/patients/details/' +
                  record?.patient?._id,
              )
            }}
          >
            {record?.patient?.name}
          </ALink>
        ),
      }),
      convertColumnTable<{
        locationName: string
      }>({
        title: t(R.strings.location),
        key: 'locationName',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.locationName} type="Base" />
        ),
      }),
      convertColumnTable<{
        treatmentStart
      }>({
        title: t(R.strings.item_management_content_treatment_name),
        key: 'treatmentStart',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.treatmentStart?.name} type="Base" />
        ),
      }),
      convertColumnTable<{
        treatmentStart
        tip_price: string
      }>({
        title: t(R.strings.tip_price),
        key: 'tip_price',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.treatmentStart?.total}
            type="Money"
          />
        ),
      }),
      convertColumnTable<{
        treatments
      }>({
        title: t(R.strings.package_name),
        key: 'treatments',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          const treatments = record?.treatments?.map(
            treatment => treatment?.name,
          )
          const items: any = [
            {
              key: '1',
              label: treatments.length ? treatments[0] : '',
              children: treatments
                ?.filter((treatment, index) => index !== 0)
                ?.map(treatment => (
                  <>
                    <RenderValueTable value={treatment} type="Base" />
                    <hr />
                  </>
                )),
            },
          ]

          return <Collapse items={items} className="Collapse-treatment" />
        },
      }),
      convertColumnTable<{
        treatmentTotal
      }>({
        title: t(R.strings.package_price),
        key: 'treatmentTotal',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.treatmentTotal} type="Money" />
        ),
      }),
      convertColumnTable<{
        payment
      }>({
        title: t(R.strings.net_amount),
        key: 'payment',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.payment?.total} type="Money" />
        ),
      }),
      convertColumnTable<{
        payment
      }>({
        title: t(R.strings.paid),
        key: 'payment',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={t(record?.payment?.paid)} type="Money" />
        ),
      }),
      convertColumnTable<{
        paidType: string
      }>({
        title: t(R.strings.manage_customer_payment_type),
        key: 'paidType',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={t(record?.paidType)} type="Base" />
        ),
      }),
      convertColumnTable<{
        date: string
      }>({
        title: t(R.strings.manage_customer_tip_start_date),
        key: 'date',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={moment(record?.date).format('DD/MM/YYYY')}
            type="Base"
          />
        ),
      }),
      convertColumnTable<{
        contractCompanyValue: number
      }>({
        title: t(R.strings.contract_company_value),
        key: 'contractCompanyValue',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.contractCompanyValue} type="Money" />
        ),
      }),
      convertColumnTable<{
        contractClinicValue: number
      }>({
        title: t(R.strings.contract_clinic_value),
        key: 'contractClinicValue',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.contractClinicValue} type="Money" />
        ),
      }),
      convertColumnTable<{
        contract: { SHD: string }
      }>({
        title: t(R.strings.contract_code),
        key: 'contract',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.contract?.SHD} type="Base" />
        ),
      }),
      convertColumnTable<{
        isInvoicing: boolean
        patient
      }>({
        title: t(R.strings.note_print),
        key: 'isInvoicing',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={
              record?.patient?.isInvoicing ? t(R.strings.yes) : t(R.strings.no)
            }
            type="Base"
          />
        ),
      }),
      convertColumnTable<{
        treatmentCount: number
      }>({
        title: t(R.strings.service_amount),
        key: 'treatmentCount',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.treatmentCount} type="Number" />
        ),
      }),
    ]
  }, [t, expandedRowKeys])

  return {
    onClickExport,
    columns,
  }
}
