import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import {
  IProfessionalExchangeWitPhysician,
  ITreatmentPhaseTreatmentSchedule,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { useTranslation } from 'react-i18next'
import { ProfessionalExchangeWithPhysicianItem } from './ProfessionalExchangeWithPhysician'
import { TreatmentPhaseItem } from './TreatmentPhaseItem'
import { AddTreatmentPhase } from './TreatmentPhaseItem/modal'
import * as S from './styles'
import styled from 'styled-components'

export const TreatmentPhaseLayout = ({
  treatmentPhases,
  professionalExchangeWitPhysicians,
  idSchedule,
}: {
  treatmentPhases: ITreatmentPhaseTreatmentSchedule[]
  professionalExchangeWitPhysicians: IProfessionalExchangeWitPhysician[]
  idSchedule?: string
}) => {
  const { t } = useTranslation()
  return (
    <BaseSpace size={20}>
      <BaseText
        children={t(R.strings.treatment_phase)}
        fontWeight="semibold"
        fontSize="xs"
      />
      <S.ListWrapper>
        <ButtonAddPhaseWrapper justify={'end'}>
          <AddTreatmentPhase idSchedule={idSchedule} />
        </ButtonAddPhaseWrapper>
        {treatmentPhases?.map((item, index) => {
          return (
            <BaseSpace key={index}>
              <TreatmentPhaseItem item={item} idSchedule={idSchedule}/>
            </BaseSpace>
          )
        })}
        <ProfessionalExchangeWithPhysicianItem
          professionalExchangeWitPhysicians={professionalExchangeWitPhysicians}
        />
      </S.ListWrapper>
    </BaseSpace>
  )
}

const ButtonAddPhaseWrapper = styled(BaseRow)``
