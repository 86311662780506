import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  CharacterLayout,
  InfoCustomerLayout,
  ServiceApplyLayout,
} from './layouts'
import { useCreateVoucherContext } from '../../context'
import { isEqual } from 'lodash'
import { CampaignTypeEnum } from 'app/api/marketing/discount-campaign/model'

export const SettingVoucherLayout = () => {
  const { t } = useTranslation()
  const { itemCampaign } = useCreateVoucherContext()

  return (
    <RootWrapper>
      <BaseRow className="head">
        <BaseText
          children={t(R.strings.voucher_setting)}
          fontWeight="semibold"
          fontSize="md"
        />
      </BaseRow>
      <BaseSpace className="body-content">
        <CharacterLayout />

        <LineHorizontal />

        <InfoCustomerLayout />

        <LineHorizontal />

        {isEqual(itemCampaign?.campaignType, CampaignTypeEnum.Voucher) ? (
          <ServiceApplyLayout />
        ) : null}
      </BaseSpace>
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  border-radius: ${BORDER_RADIUS};
  .head {
    padding: ${PADDING.md};
    border-start-start-radius: ${BORDER_RADIUS};
    border-start-end-radius: ${BORDER_RADIUS};
    background-color: ${convertedVariables.backgroundColor};
    border-bottom: 1px solid ${convertedVariables.neutralBlack2Color};
  }
  .body-content {
    padding: ${PADDING.md};
    border-end-end-radius: ${BORDER_RADIUS};
    border-end-start-radius: ${BORDER_RADIUS};
    background-color: #fcfcfc;
  }
  .custom-characters-wrapper {
    padding: ${PADDING.md};
    border-radius: ${BORDER_RADIUS};
    background-color: ${convertedVariables.neutralBlack9Color};
    border: 1px solid ${convertedVariables.neutralBlack2Color};
  }
`

const LineHorizontal = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${convertedVariables.neutralBlack2Color};
`
