import { BaseRow } from 'app/components/common/BaseRow'
import { SearchInput } from 'app/components/common/inputs/SearchInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  media,
} from 'parkway-web-common'
import styled from 'styled-components'

export const HeadWrapper = styled(BaseRow)`
  padding-bottom: ${PADDING.xs};
  .ant-form-item {
    margin-bottom: 0px;
  }
`
export const TitleTable = styled.span`
  font-size: ${FONT_SIZE.xs};
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.bold};
`
export const Picker = styled(BaseDatePicker)`
  min-width: 160px;
`

export const TextFilter = styled.span`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  color: var(--primary-color);
`

export const SelectBaseStyled = styled(BaseSelect)``

export const InputSearch = styled(SearchInput)`
  .ant-input-group-addon {
    display: none;
  }

  @media only screen and (${media.md}) {
    .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
      border-radius: ${BORDER_RADIUS};
      border-color: var(--neutral-black-color);
      padding: 0px ${PADDING.xxxxs};
    }
  }
`
