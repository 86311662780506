import { PlusOutlined } from '@ant-design/icons'
import { BaseCol } from '../BaseCol'
import { BaseRow } from '../BaseRow'
import * as S from './styles'
import { To, useNavigate } from 'react-router'

interface ButtonCreateProps {
  title: string
  path: To
}

export const ButtonCreate: React.FC<ButtonCreateProps> = ({ title, path }) => {
  const navigate = useNavigate()
  const onNavigate = (path?: To) => {
    if (path) {
      navigate(path)
    }
  }
  return (
    <BaseCol onClick={() => onNavigate(path)}>
      <S.ButtonCreateAction>
        <BaseRow gutter={8}>
          <BaseCol>
            <PlusOutlined rev={undefined} />
          </BaseCol>
          <BaseCol>{title}</BaseCol>
        </BaseRow>
      </S.ButtonCreateAction>
    </BaseCol>
  )
}
