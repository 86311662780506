/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { IRefModal } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/components'
import {
  IAppointmentSession,
  ITreatmentInAppointmentSession,
  ITreatmentPhaseTreatmentSchedule,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { isEqual } from 'lodash'
import { FORMAT_CENTER_YYYY_MM_DD, moment } from 'parkway-web-common'
import {
  MutableRefObject,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { IFormTreatmentPhase } from './type'

interface IDataAddTreatmentPhaseContext {
  listAppointmentSession?: IAppointmentSession[]
  modalRef?: MutableRefObject<IRefModal>
  form?: FormInstance<IFormTreatmentPhase>
  handleSubmit?: (values?: IFormTreatmentPhase) => void
  onAddListAppointmentSession?: (
    appointmentSession: IAppointmentSession,
  ) => void
  onDeleteAppointmentSession?: (index: number) => void
  onAddTreatmentInAppointmentSession?: ({
    newTreatment,
    appointmentSessionId,
  }: {
    appointmentSessionId?: string
    newTreatment: ITreatmentInAppointmentSession
  }) => void

  onChangeTreatmentInAppointmentSession?: ({
    newTreatment,
    appointmentSessionId,
  }: {
    appointmentSessionId?: string
    newTreatment: ITreatmentInAppointmentSession
  }) => void

  onDeleteTreatmentInAppointmentSession?: ({
    indexTreatment,
    appointmentSessionId,
  }: {
    appointmentSessionId?: string
    indexTreatment: number
  }) => void

  startDate?: string
  endDate?: string
  onChangeEndDate?: (date?: string) => void
  onChangeStartDate?: (date?: string) => void
}

export const AddTreatmentPhaseContext =
  createContext<IDataAddTreatmentPhaseContext>({})

export const ProfilePatientProvider = ({
  children,
  idSchedule,
  treatmentPhase,
}: {
  idSchedule?: string
  treatmentPhase?: ITreatmentPhaseTreatmentSchedule
  children: any
}) => {
  const { onUpdateTreatmentPhaseInTreatmentSchedule } =
    useProfilePatientContext()
  const modalRef = useRef<IRefModal>({})
  const [form] = BaseForm.useForm<IFormTreatmentPhase>()
  const [isChangeValue, setIsChangeValue] = useState(false)

  const [startDate, setStartDate]= useState<string | undefined>('')
  const [endDate, setEndDate]= useState<string | undefined>('')

  const [listAppointmentSession, setListAppointmentSession] = useState<
    IAppointmentSession[]
  >([])

  useEffect(() => {
    if (treatmentPhase && !isChangeValue) {
      setListAppointmentSession(treatmentPhase?.appointmentSession ?? [])
      form?.setFieldsValue({
        phaseName: treatmentPhase?.name,
      })
      setStartDate(treatmentPhase.startDate)
      setEndDate(treatmentPhase.endDate)
    }
  }, [treatmentPhase, isChangeValue])

  const handleSubmit = (values?: IFormTreatmentPhase) => {
    if (!idSchedule || !treatmentPhase) return

    const newTreatment: ITreatmentPhaseTreatmentSchedule = {
      ...treatmentPhase,
      appointmentSession: listAppointmentSession,
      name: values?.phaseName ?? '',
      endDate: moment(endDate).format(FORMAT_CENTER_YYYY_MM_DD),
      startDate: moment(startDate).format(FORMAT_CENTER_YYYY_MM_DD),
    }


    onUpdateTreatmentPhaseInTreatmentSchedule?.({
      idSchedule,
      treatmentPhase: newTreatment,
    })
    form?.resetFields()
    setListAppointmentSession([])
    modalRef?.current?.hide?.()
    setIsChangeValue(false)
  }

  const onAddListAppointmentSession = (
    appointmentSession: IAppointmentSession,
  ) => {
    setListAppointmentSession(prev => prev?.concat([appointmentSession]))
    !isChangeValue && setIsChangeValue(true)
  }

  const onDeleteAppointmentSession = (index: number) => {
    setListAppointmentSession(prev => prev?.filter((_, idx) => idx !== index))
    !isChangeValue && setIsChangeValue(true)
  }

  const onAddTreatmentInAppointmentSession = ({
    newTreatment,
    appointmentSessionId,
  }: {
    appointmentSessionId?: string
    newTreatment: ITreatmentInAppointmentSession
  }) => {
    setListAppointmentSession(prev => {
      return prev?.map(item => {
        if (item?.id === appointmentSessionId) {
          return {
            ...item,
            treatmentInAppointmentSession:
              item?.treatmentInAppointmentSession?.concat([newTreatment]),
          }
        }
        return item
      })
    })

    !isChangeValue && setIsChangeValue(true)
  }

  const onChangeTreatmentInAppointmentSession = ({
    newTreatment,
    appointmentSessionId,
  }: {
    appointmentSessionId?: string
    newTreatment: ITreatmentInAppointmentSession
  }) => {
    setListAppointmentSession(prev => {
      return prev?.map(item => {
        if (item?.id === appointmentSessionId) {
          return {
            ...item,
            treatmentInAppointmentSession:
              item?.treatmentInAppointmentSession?.map(itm => {
                if (isEqual(itm?.id, newTreatment?.id)) {
                  return newTreatment
                }
                return itm
              }),
          }
        }
        return item
      })
    })
    !isChangeValue && setIsChangeValue(true)
  }

  const onDeleteTreatmentInAppointmentSession = ({
    indexTreatment,
    appointmentSessionId,
  }: {
    appointmentSessionId?: string
    indexTreatment: number
  }) => {
    setListAppointmentSession(prev => {
      return prev?.map(item => {
        if (item?.id === appointmentSessionId) {
          return {
            ...item,
            treatmentInAppointmentSession:
              item?.treatmentInAppointmentSession?.filter(
                (_, idx) => idx !== indexTreatment,
              ),
          }
        }
        return item
      })
    })

    !isChangeValue && setIsChangeValue(true)
  }

  const onChangeStartDate = (date?: string) => {
    setStartDate(date)
  }

  const onChangeEndDate = (date?: string) => {
    setEndDate(date)
  }

  return (
    <AddTreatmentPhaseContext.Provider
      value={{
        form,
        modalRef,
        handleSubmit,
        listAppointmentSession,
        onAddListAppointmentSession,
        onAddTreatmentInAppointmentSession,
        onDeleteAppointmentSession,
        onDeleteTreatmentInAppointmentSession,
        onChangeTreatmentInAppointmentSession,
        endDate,
        startDate,
        onChangeStartDate,
        onChangeEndDate
      }}
    >
      {children}
    </AddTreatmentPhaseContext.Provider>
  )
}

export function useAddTreatmentPhaseContext() {
  const context = useContext(AddTreatmentPhaseContext)

  if (context === undefined) {
    throw new Error(
      'useAddTreatmentPhaseContext must be used within a AddTreatmentPhaseContext',
    )
  }
  return context
}
