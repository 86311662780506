import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseModal } from 'app/components/common/BaseModal'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText, { ColorTextType } from 'app/components/common/BaseText'
import { PADDING } from 'parkway-web-common'
import { ReactNode, forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  iconOpenModal?: ReactNode
  titleOpenModal?: string
  widthModal?: number
  cancelKeyI18n?: string
  isDisableSubmit?: boolean
  confirmKeyI18n?: string
  isLoadingConfirm?: boolean
  handleSubmit?: () => void
  renderContent: ReactNode
  titleModal: string
  styleButtonOpen?: React.CSSProperties
  borderRadiusButtonOpen?: string
  borderButtonOpen?: string
  textButtonOpenColor?: ColorTextType
  isShowLineTop?: boolean
  isShowLineBottom?: boolean
  paddingContent?: string
  handleCancel?: () => void
  footer?: ReactNode
}

export interface IRefModal {
  hide?: () => void
  open?: () => void
}

export const ModalComponent = forwardRef<IRefModal, IProps>(
  (
    {
      iconOpenModal,
      titleOpenModal,
      widthModal = 750,
      cancelKeyI18n = R.strings.cancel,
      confirmKeyI18n = R.strings.confirm,
      isLoadingConfirm,
      isDisableSubmit,
      handleSubmit,
      handleCancel,
      renderContent,
      titleModal,
      styleButtonOpen,
      borderRadiusButtonOpen,
      borderButtonOpen,
      textButtonOpenColor,
      isShowLineBottom = true,
      isShowLineTop = true,
      paddingContent,
      footer,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const [visible, setVisible] = useState(false)

    const onHide = () => {
      setVisible(false)
      handleCancel?.()
    }

    const onOpen = () => {
      setVisible(true)
    }

    useImperativeHandle(
      ref,
      () => {
        return {
          hide: onHide,
          open: onOpen,
        }
      },
      [],
    )

    const _handleSubmit = () => {
      handleSubmit?.()
    }

    return (
      <>
        <ButtonOpenModal
          style={styleButtonOpen}
          onClick={onOpen}
          gutter={[10, 10]}
          align={'middle'}
          $borderRadius={borderRadiusButtonOpen}
          $border={borderButtonOpen}
        >
          {iconOpenModal}
          {titleOpenModal ? (
            <BaseCol>
              <BaseText
                fontSize="xs"
                fontWeight="medium"
                children={t(titleOpenModal)}
                colorText={textButtonOpenColor}
              />
            </BaseCol>
          ) : null}
        </ButtonOpenModal>

        <BaseModalCustom
          open={visible}
          width={widthModal}
          onCancel={onHide}
          closeIcon={null}
          footer={
            footer ? (
              footer
            ) : (
              <div onClick={event => event?.stopPropagation()}>
                <FooterWrapper justify={'end'} align={'middle'}>
                  <ButtonFooterModal onClick={onHide}>
                    {t(cancelKeyI18n)}
                  </ButtonFooterModal>
                  <ButtonConfirmModal
                    onClick={_handleSubmit}
                    loading={isLoadingConfirm}
                    disabled={isDisableSubmit}
                  >
                    {t(confirmKeyI18n)}
                  </ButtonConfirmModal>
                </FooterWrapper>
              </div>
            )
          }
        >
          <BaseSpace>
            <HeaderWrapper>
              <BaseText
                children={t(titleModal)}
                fontWeight="semibold"
                fontSize="lg"
              />
            </HeaderWrapper>
            {isShowLineTop ? <DividerLine /> : null}
            <ContentWrapper $paddingContent={paddingContent}>
              {renderContent}
            </ContentWrapper>
            {isShowLineBottom ? <DividerLine /> : null}
          </BaseSpace>
        </BaseModalCustom>
      </>
    )
  },
)

const FooterWrapper = styled(BaseRow)`
  padding: 0px ${PADDING.xl};
`

const BaseModalCustom = styled(BaseModal)`
  .ant-modal-close {
    position: unset;
  }
  .ant-modal-content {
    padding: ${PADDING.xl} 0px;
  }
`

const HeaderWrapper = styled(BaseRow)`
  padding: 0px ${PADDING.xl};
`

const ContentWrapper = styled.div<{ $paddingContent?: string }>`
  padding: ${props => props?.$paddingContent ?? `0px ${PADDING.xl}`};
`

const ButtonOpenModal = styled(BaseRow)<{
  $border?: string
  $borderRadius?: string
}>`
  padding: ${PADDING.xxxxs} ${PADDING.xxs};
  cursor: pointer;
  border: ${props => props?.$border ?? 'unset'};
  border-radius: ${props => props?.$borderRadius ?? 'unset'};
`

const ButtonFooterModal = styled(BaseButton)`
  min-width: 120px;
`

const ButtonConfirmModal = styled(ButtonFooterModal)`
  min-width: 120px;
  background-color: var(--other-blue-color);
  color: var(--background-color);
  border-color: var(--other-blue-color);
  &:hover {
    color: var(--background-color) !important;
    border-color: var(--other-blue-color);
  }
`

export const DividerLine = styled(BaseDivider)`
  margin: 0px !important;
`
