/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IJobTitleManagement,
  IParamsGetJobTitleManagement,
} from 'app/api/jobtitle/model/job-title-management'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useInfiniteQuery } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { requestGetAllJobTitleLevelManagement } from 'app/api/jobtitlelevel'
import { JobTitleLevelMessageCode } from 'app/api/jobtitlelevel/constant'
import { IJobTitleLevelManagement } from 'app/api/jobtitlelevel/model/job-title-level-management'
import { JobTitleLevelKeys } from '../query-key/job-title-level'

export function useGetJobTitleLevelManagementList(enabled = true) {
  const token = getToken()
  return useInfiniteQuery<
    IResponseQueryList<IJobTitleManagement[]>,
    IParamsGetJobTitleManagement
  >(
    JobTitleLevelKeys.listJobTitleLevel(),
    async () => {
      try {
        const res: ResponseType<{
          rows: IJobTitleLevelManagement[]
          total: number
        }> = await requestGetAllJobTitleLevelManagement()

        if (
          isEqual(
            res?.msgcode,
            JobTitleLevelMessageCode.JobTitleLevelManagement.successGetData,
          )
        ) {
          const data: IJobTitleLevelManagement[] = res?.data?.rows || []
          return {
            data,
            total: res?.data?.total ?? 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
