import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseTextEditor from 'app/components/common/BaseTextEditor'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { StatusEnumNumber } from 'app/components/tables/BaseTableManagement/hook'
import { useTranslation } from 'react-i18next'
import { useCreateUpdateServiceContext } from '../../context'
import { useHook } from './hook'
import * as S from './styles'
import { useParams } from 'react-router'
import { useContext, useEffect, useState } from 'react'
import { ItemCategoryContext } from 'app/context/ItemCategoryContext'
import { IItemCategory } from 'app/api/item-category/model/management'
import { TreatmentContext } from 'app/context/TreatmentContext'
import { StatusOptionsCreateEnum } from 'app/common/enum'
import { IItemBrand } from 'app/api/item-brand/model/management'

enum EInventory {
  YES = 'YES',
  NO = 'NO',
}

const Info = () => {
  const { type: typeParam } = useParams()
  const { jobTitleSelected } = useHook()
  const { rules, form } = useCreateUpdateServiceContext()
  const [itemCateKeyword, setItemCateKeyword] = useState('')
  const [itemBrandKeyword, setItemBrandKeyword] = useState('')
  const [measurementUnitKeyword, setMeasurementUnitKeyword] = useState('')

  const { t } = useTranslation()
  const {
    fetchItemCategory,
    itemCategories,
    loading: isLoadingCategory,
  } = useContext(ItemCategoryContext)

  const {
    fetchItemBrands,
    itemBrands,
    loading: isItemBrand,
    fetchMeasurementUnits,
    isMeasurementUnitLoading,
    measurementUnits,
  } = useContext(TreatmentContext)

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchItemCategory?.({
        keyword: itemCateKeyword,
      })
    }, 500)
    return () => clearTimeout(timer)
  }, [itemCateKeyword])

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchItemBrands?.({
        keyword: itemBrandKeyword,
      })
    }, 500)
    return () => clearTimeout(timer)
  }, [itemBrandKeyword])

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchMeasurementUnits?.({
        keyword: measurementUnitKeyword,
      })
    }, 500)
    return () => clearTimeout(timer)
  }, [measurementUnitKeyword])

  useEffect(() => {
    const doctorJobTitleLevelIds: string[] = jobTitleSelected?.map(({ _id }) =>
      String(_id),
    )
    form?.setFieldsValue({
      doctorJobTitleLevelIds,
    })
  }, [jobTitleSelected])

  useEffect(() => {
    form?.setFieldsValue({
      type: typeParam,
    })
  }, [])

  return (
    <S.RootWrapper direction="vertical" size={10}>
      <S.Title>
        {t(R.strings[`item_management_content_info_${typeParam}`])}
      </S.Title>
      <BaseForm.Item name="type" hidden={true} />
      <BaseForm.Item
        label={t(R.strings.category)}
        name="categoryid"
        rules={rules?.categoryid}
        required
      >
        <BaseSelect
          filterOption={false}
          placeholder={t(R.strings.select_category)}
          suffixIcon={<SvgTableCloseIcon />}
          loading={isLoadingCategory}
          onSearch={keyword => setItemCateKeyword(keyword)}
          onClear={() => setItemCateKeyword('')}
          allowClear
          showSearch
        >
          {itemCategories?.map((item: IItemCategory, index) => {
            return (
              <Option value={item._id} key={index}>
                {item?.name}
              </Option>
            )
          })}
        </BaseSelect>
      </BaseForm.Item>

      <BaseRow justify={'space-between'} align={'middle'} gutter={[10, 10]}>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={t(R.strings[`item_management_content_${typeParam}_name`])}
            name="name"
            rules={rules?.name}
            required
          >
            <BaseInput
              placeholder={t(
                R.strings[`item_management_content_enter_${typeParam}_name`],
              )}
            />
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={t(R.strings[`item_management_content_${typeParam}_code`])}
            name="code"
            rules={rules?.code}
            required
          >
            <BaseInput
              placeholder={t(
                R.strings[`item_management_content_enter_${typeParam}_code`],
              )}
            />
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>

      <BaseRow justify={'space-between'} align={'middle'} gutter={[10, 10]}>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={t(R.strings.item_management_content_producer)}
            name="itemBrandId"
            rules={rules?.itemBrandId}
            required
          >
            <BaseSelect
              placeholder={t(R.strings.item_management_content_select_producer)}
              suffixIcon={<SvgTableCloseIcon />}
              loading={isItemBrand}
              onSearch={keyword => setItemBrandKeyword(keyword)}
              onClear={() => setItemBrandKeyword('')}
              allowClear
              showSearch
              filterOption={false}
            >
              {itemBrands?.map((item: IItemBrand, index) => {
                return (
                  <Option value={item._id} key={index}>
                    {item?.name}
                  </Option>
                )
              })}
            </BaseSelect>
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={t(R.strings.inventory_management)}
            name="isInventoryManagement"
            rules={rules?.isInventoryManagement}
            required
          >
            <BaseSelect
              suffixIcon={<SvgTableCloseIcon />}
              loading={isLoadingCategory}
            >
              {Object.values(EInventory).map((item, index) => {
                return (
                  <Option value={item === EInventory?.YES} key={index}>
                    {t(item)}
                  </Option>
                )
              })}
            </BaseSelect>
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>

      <BaseRow justify={'space-between'} align={'middle'} gutter={[10, 10]}>
        <BaseCol xl={6}>
          <BaseForm.Item
            label={t(R.strings.item_management_content_purchase_unit)}
            name="purchaseMeasurementUnitId"
            rules={rules?.purchaseMeasurementUnitId}
            required
          >
            <BaseSelect
              placeholder={t(
                R.strings.item_management_content_select_purchase_unit,
              )}
              suffixIcon={<SvgTableCloseIcon />}
              onSearch={keyword => setMeasurementUnitKeyword(keyword)}
              onClear={() => setMeasurementUnitKeyword('')}
              onSelect={() => setMeasurementUnitKeyword('')}
              loading={isMeasurementUnitLoading}
              allowClear
              showSearch
              filterOption={false}
            >
              {measurementUnits?.map(
                (item: { _id: string; name: string }, index) => {
                  return (
                    <Option value={item._id} key={index}>
                      {item?.name}
                    </Option>
                  )
                },
              )}
            </BaseSelect>
          </BaseForm.Item>
        </BaseCol>

        <BaseCol xl={6}>
          <BaseForm.Item
            label={t(R.strings.item_management_content_using_unit)}
            name="usingMeasurementUnitId"
            rules={rules?.usingMeasurementUnitId}
            required
          >
            <BaseSelect
              placeholder={t(R.strings.item_management_content_using_unit)}
              suffixIcon={<SvgTableCloseIcon />}
              onSearch={keyword => setMeasurementUnitKeyword(keyword)}
              onClear={() => setMeasurementUnitKeyword('')}
              onSelect={() => setMeasurementUnitKeyword('')}
              loading={isMeasurementUnitLoading}
              allowClear
              showSearch
              filterOption={false}
            >
              {measurementUnits?.map(
                (item: { _id: string; name: string }, index) => {
                  return (
                    <Option value={item._id} key={index}>
                      {item?.name}
                    </Option>
                  )
                },
              )}
            </BaseSelect>
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseForm.Item
            label={t(R.strings.status)}
            name="status"
            rules={rules?.status}
            required
          >
            <BaseSelect
              placeholder={t(R.strings.please_select_status)}
              suffixIcon={<SvgTableCloseIcon />}
              loading={isLoadingCategory}
            >
              {Object.values(StatusOptionsCreateEnum).map((item, index) => {
                return (
                  <Option value={StatusEnumNumber[item]} key={index}>
                    {t(item)}
                  </Option>
                )
              })}
            </BaseSelect>
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>

      <BaseForm.Item
        label={t(R.strings.description)}
        name="description"
        rules={rules?.description}
        required
      >
        <BaseTextEditor placeholder={t(R.strings.description)} />
      </BaseForm.Item>
    </S.RootWrapper>
  )
}

export default Info
