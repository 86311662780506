/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetMarketingPlan } from 'app/api/marketing'
import { MarketingMessageCode } from 'app/api/marketing/constant'
import {
  IParamsGetMarketingPlan,
  IResponseGetMarketingPlan,
} from 'app/api/marketing/model'
import R from 'app/assets/R'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import {
  IDataBaseTable,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import {
  convertChildCostPlanValue,
  convertTotalCostPlanValue,
  useCommonHook,
} from '../common-hook'
import { initialPagination } from '../common-model'
import { IDataMarketingPlanMonthly } from './data'

export const useTableMonthlyHook = (props: IParamsHookReport) => {
  const { t } = useTranslation()

  const getDataMonth = async (
    pagination: Pagination,
    params: IParamsGetMarketingPlan,
  ) => {
    const response: IResponseGetMarketingPlan = await requestGetMarketingPlan({
      periodtype: 'month',
      year: props?.year,
      ...params,
    })
    if (
      isEqual(
        response?.msgcode,
        MarketingMessageCode.MarketingPlan.successGetData,
      )
    ) {
      const data = response?.data?.rows ?? []

      const dataTable: IDataMarketingPlanMonthly[] =
        data?.map((item, index) => {
          const [firstClinic, ...otherClinic] = item?.area ?? []
          const [firstMonthOfFirstClinic, ...otherMonthOfFirstClinic] =
            firstClinic?.months ?? []
          const keyOfFirstClinic = Number(`${(index + 1) * 10}1`)

          const newResponse: IDataMarketingPlanMonthly = {
            key: index + 1,
            service: item?.serviceName,
            isArea: true,
            childrenKey: keyOfFirstClinic,
            month: `${t(R.strings.month)} ${firstMonthOfFirstClinic?.month}`,
            ...convertChildCostPlanValue(firstMonthOfFirstClinic),
            ...convertTotalCostPlanValue(firstClinic),
            childOfFirstChild: otherMonthOfFirstClinic?.map((item, idx) => {
              const keyChild = Number(`${keyOfFirstClinic}0${idx}`)

              return {
                key: keyChild,
                month: `${t(R.strings.month)} ${item?.month}`,
                index: idx + 1,
                ...convertChildCostPlanValue(item),
                parentKey: keyOfFirstClinic,
              }
            }),
            children: otherClinic?.map((clinic, idx: number) => {
              const [firstMonthOfClinic, ...otherMonthOfClinic] =
                clinic?.months ?? []
              const keyOfClinic = Number(`${(index + 1) * 10}000${idx + 2}`)
              return {
                key: keyOfClinic,
                month: `${t(R.strings.month)} ${firstMonthOfClinic?.month}`,
                ...convertChildCostPlanValue(firstMonthOfClinic),
                ...convertTotalCostPlanValue(clinic),

                children: otherMonthOfClinic?.map((item, idxChild) => {
                  const keyChild = Number(`${keyOfClinic}00${idxChild}`)
                  return {
                    key: keyChild,
                    month: `${t(R.strings.month)} ${item?.month}`,
                    index: idxChild + 1,
                    ...convertChildCostPlanValue(item),
                  }
                }),
              }
            }),
          }
          return newResponse
        }) ?? []
      return {
        pagination: initialPagination,
        data: dataTable,
      } as IDataBaseTable<IDataMarketingPlanMonthly>
    }
    return {
      pagination: initialPagination,
      data: [],
    } as IDataBaseTable<IDataMarketingPlanMonthly>
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,

    expandedAreaKeys,
    handleExpandArea,
    fetch,
    handleResetExpand,
    fetchLoading,
  } = useBaseTableReportHook({
    getTreeTableData: getDataMonth,
  })

  const { columns, onClickImport, data } = useCommonHook({
    expandedRowKeys,
    fetch,
    fetchLoading,
    handleExpand,
    typeHook: 'month',
    handleExpandArea,
    expandedAreaKeys,
    dataTable: tableData.data,
    currentFilter: props,
  })

  const _onClickImport = async (file: any) => {
    onClickImport(file, 'month')
  }

  return {
    columns,
    tableData,
    data,
    handleTableChange,
    handleExpand,
    expandedRowKeys,

    handleResetExpand,
    onClickImport: _onClickImport,
    fetch,
  }
}
