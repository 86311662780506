import { ApiClientHr } from 'app/service/api-service'
import { JobTitleEndPoint } from './constant'
import {
  IParamsGetJobTitleManagement,
  IPayloadCreateJobTitleManagement,
  IPayloadUpdateJobTitleManagement,
} from './model/job-title-management'

export const requestGetJobTitleManagement = (
  params: IParamsGetJobTitleManagement,
) =>
  ApiClientHr.Get({
    url: JobTitleEndPoint.JobTitleManagement.request,
    params: { params },
  })

export const requestCreateJobTitleManagement = (
  body: IPayloadCreateJobTitleManagement,
) =>
  ApiClientHr.Post({
    url: JobTitleEndPoint.JobTitleManagement.request,
    body,
  })

export const requestUpdateJobTitleManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateJobTitleManagement
}) =>
  ApiClientHr.Put({
    url: `${JobTitleEndPoint.JobTitleManagement.request}/${id}`,
    body,
  })

export const requestDetailJobTitleManagement = (id?: string | number) =>
  ApiClientHr.Get({
    url: `${JobTitleEndPoint.JobTitleManagement.request}/${id}`,
    params: {},
  })

export const requestDeleteJobTitleManagement = (id?: string | number) =>
  ApiClientHr.Delete({
    url: `${JobTitleEndPoint.JobTitleManagement.request}/${id}`,
    body: {},
  })

export const requestGetAllJobTitleManagement = () =>
  ApiClientHr.Get({
    url: `${JobTitleEndPoint.JobTitleManagement.request}/get-all`,
    params: {},
  })
