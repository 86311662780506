import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { ListStatusTable, StatusTableEnum } from 'app/common/enum'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { ISupplies } from 'app/containers/Accounting/ItemManagement/model/supplies'
import CollapseComponent from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/CollapseComponent'
import {
  ListMaterials,
  ListTreatmentExample,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/constant'
import {
  IDoctorTreatmentSchedule,
  ITreatmentInAppointmentSession,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEmpty, isEqual } from 'lodash'
import { BORDER_RADIUS } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AddDoctorModal } from '../../modal'
import { AddNurseModal } from '../../modal/AddNurseModal'

const getMaterials = ({
  currentId,
  currentList,
}: {
  currentId?: string
  currentList?: ISupplies[]
}) => {
  return ListMaterials?.filter(item => {
    if (isEqual(item?._id, currentId)) return true

    return !currentList?.find(itm => isEqual(item?._id, itm?._id))
  })
}

export const TreatmentInPhaseModal = ({
  item,
  index,
  onDelete,
  onChangeTreatment,
}: {
  item?: ITreatmentInAppointmentSession
  index?: number
  onDelete?: () => void
  onChangeTreatment?: (newTreatment: ITreatmentInAppointmentSession) => void
}) => {
  const { t } = useTranslation()

  const onDeleteDoctor = () => {
    if (!item) return
    const newTreatment: ITreatmentInAppointmentSession = {
      ...item,
      doctor: undefined,
    }

    onChangeTreatment?.(newTreatment)
  }

  const onDeleteNurses = () => {
    if (!item) return
    const newTreatment: ITreatmentInAppointmentSession = {
      ...item,
      nurses: [],
    }

    onChangeTreatment?.(newTreatment)
  }

  const onChangeTreatmentName = (treatmentName?: string) => {
    if (!item) return
    const newTreatment: ITreatmentInAppointmentSession = {
      ...item,
      name: treatmentName,
    }

    onChangeTreatment?.(newTreatment)
  }

  const onChangeDoctor = (doctor?: IDoctorTreatmentSchedule) => {
    if (!item) return
    const newTreatment: ITreatmentInAppointmentSession = {
      ...item,
      doctor,
    }

    onChangeTreatment?.(newTreatment)
  }

  const onChangeNurses = (nurses?: IDoctorTreatmentSchedule[]) => {
    if (!item) return
    const newTreatment: ITreatmentInAppointmentSession = {
      ...item,
      nurses,
    }

    onChangeTreatment?.(newTreatment)
  }

  const onChangeMaterials = (materials: ISupplies[]) => {
    if (!item) return
    const newTreatment: ITreatmentInAppointmentSession = {
      ...item,
      materials,
    }

    onChangeTreatment?.(newTreatment)
  }

  const onAddMaterial = () => {
    const newMaterial: ISupplies = {
      _id: '',
      quantity: 0,
    }

    onChangeMaterials(item?.materials?.concat([newMaterial]) ?? [])
  }

  const onChangeQuantityMaterial = ({
    id,
    quantity,
  }: {
    id?: string
    quantity?: number
  }) => {
    const newMaterials = item?.materials?.map(itm => {
      if (isEqual(itm?._id, id)) {
        return {
          ...itm,
          quantity,
        }
      }

      return itm
    })
    onChangeMaterials(newMaterials ?? [])
  }

  const onDeleteQuantityMaterial = (id?: string) => {
    const newMaterials = item?.materials?.filter(itm => !isEqual(itm?._id, id))
    onChangeMaterials(newMaterials ?? [])
  }

  const onChangeNameMaterial = ({
    id,
    material,
  }: {
    id?: string
    material?: string
  }) => {
    const itemMaterial = ListMaterials?.find(itm =>
      isEqual(itm?.name, material),
    )
    if (!itemMaterial) return
    const newMaterials = item?.materials?.map(itm => {
      if (isEqual(itm?._id, id)) {
        return {
          ...itm,
          name: material,
          _id: itemMaterial?._id,
        }
      }

      return itm
    })
    onChangeMaterials(newMaterials ?? [])
  }

  const isDisableAddButtonMaterial = useMemo(() => {
    const itemExist = item?.materials?.find(
      itm => isEmpty(itm?._id) || !itm?.quantity,
    )
    return !!itemExist
  }, [item?.materials])

  return (
    <CollapseWrapper
      label={
        <BaseRow align={'middle'} gutter={[15, 15]} justify={'space-between'}>
          <BaseCol xl={7}>
            <BaseText
              children={`${index ?? '1'}. ${item?.name ?? '-'}`}
              fontSize="xs"
              fontWeight="medium"
            />
          </BaseCol>

          <div onClick={event => event?.stopPropagation()}>
            <BaseButton
              onClick={onDelete}
              type="text"
              icon={<SvgDeleteDesignIcon />}
            />
          </div>
        </BaseRow>
      }
      expandIconPosition="end"
      style={{ alignItems: 'center' }}
      titleKeyI18n={item?.id}
      children={
        <BaseForm>
          <BaseSpace>
            <BaseText
              children={t(R.strings.treatment_information)}
              fontWeight="semibold"
              fontSize="xs"
            />
            <BaseSpace size={8}>
              <BaseFormItem
                label={t(R.strings.treatment_management_content_treatment_name)}
                required
              >
                <BaseSelectWrapper
                  placeholder={t(
                    R.strings.item_management_content_select_treatment,
                  )}
                  value={item?.name}
                  onChange={value => onChangeTreatmentName(value as string)}
                >
                  {ListTreatmentExample?.map((item, index) => {
                    return <Option key={index} value={item} children={item} />
                  })}
                </BaseSelectWrapper>
              </BaseFormItem>
              <BaseFormItem label={t(R.strings.status)} required>
                <BaseSelectWrapper
                  placeholder={t(
                    R.strings.item_management_content_select_treatment,
                  )}
                  defaultValue={StatusTableEnum.active}
                >
                  {ListStatusTable?.map((key, index) => {
                    return <Option key={index} value={key} children={t(key)} />
                  })}
                </BaseSelectWrapper>
              </BaseFormItem>
            </BaseSpace>

            <BaseSpace size={8}>
              <BaseText
                children={t(R.strings.equipment_information)}
                fontWeight="semibold"
                fontSize="xs"
              />
              {item?.materials?.map((material, indexMaterial) => {
                return (
                  <BaseRow
                    key={indexMaterial}
                    gutter={[8, 8]}
                    justify={'space-between'}
                    align={'middle'}
                  >
                    <BaseCol xl={11}>
                      <BaseFormItem hiddenLabel>
                        <BaseSelectWrapper
                          placeholder={t(
                            R.strings.item_management_content_select_treatment,
                          )}
                          value={material?.name}
                          onChange={value =>
                            onChangeNameMaterial({
                              id: material?._id,
                              material: value as string,
                            })
                          }
                        >
                          {getMaterials({
                            currentId: material?._id,
                            currentList: item?.materials,
                          })?.map((ma, index) => {
                            return (
                              <Option
                                key={index}
                                value={ma?.name}
                                children={ma?.name}
                              />
                            )
                          })}
                        </BaseSelectWrapper>
                      </BaseFormItem>
                    </BaseCol>
                    <BaseCol xl={11}>
                      <BaseFormItem hiddenLabel>
                        <InputNumberStyled
                          placeholder={t(R.strings.enter_quantity)}
                          value={material?.quantity ?? 0}
                          min={0}
                          onChange={value =>
                            onChangeQuantityMaterial({
                              id: material?._id,
                              quantity: value as number,
                            })
                          }
                        />
                      </BaseFormItem>
                    </BaseCol>
                    <BaseCol>
                      <BaseButton
                        onClick={() => onDeleteQuantityMaterial(material?._id)}
                        icon={<SvgDeleteDesignIcon />}
                        type="text"
                      />
                    </BaseCol>
                  </BaseRow>
                )
              })}
              <BaseButton
                type="link"
                icon={<PlusOutlined rev={undefined} />}
                onClick={onAddMaterial}
                disabled={isDisableAddButtonMaterial}
              >
                <BaseText
                  children={t(R.strings.add_supplies)}
                  colorText={
                    isDisableAddButtonMaterial
                      ? 'disabledBgColor'
                      : 'collapseBackgroundColor'
                  }
                  fontSize="xs"
                />
              </BaseButton>
            </BaseSpace>

            <BaseText
              children={t(R.strings.doctors_and_nurses_perform)}
              fontWeight="semibold"
              fontSize="xs"
            />
            <BaseText
              children={t(R.strings.doctors_perform)}
              opacity="0.5"
              fontWeight="medium"
            />
            <BaseRow justify={'space-between'} align={'middle'}>
              <BaseCol xl={22}>
                <BaseRow>
                  {item?.doctor ? (
                    <BaseCol xl={6}>
                      <BaseAvatarProfile
                        name={item?.doctor?.name}
                        jobTitle={item?.doctor?.jobTitle}
                        src={item?.doctor?.avatar}
                      />
                    </BaseCol>
                  ) : (
                    <BaseCol xl={7}>
                      <AddDoctorModal onPress={onChangeDoctor} />
                    </BaseCol>
                  )}
                </BaseRow>
              </BaseCol>
              <BaseCol xl={1}>
                <BaseButton
                  onClick={onDeleteDoctor}
                  type="text"
                  icon={<SvgDeleteDesignIcon />}
                />
              </BaseCol>
            </BaseRow>
            <BaseText
              children={t(R.strings.nurses_perform)}
              opacity="0.5"
              fontWeight="medium"
            />
            <BaseRow justify={'space-between'} align={'middle'}>
              <BaseCol xl={23}>
                {item?.nurses?.length ? (
                  <BaseRow align={'middle'} gutter={[15, 10]}>
                    {item?.nurses?.map((itm, idx) => {
                      return (
                        <BaseCol key={idx} xl={8}>
                          <BaseAvatarProfile
                            name={itm?.name}
                            jobTitle={itm?.jobTitle}
                            src={itm?.avatar}
                            key={idx}
                          />
                        </BaseCol>
                      )
                    })}
                  </BaseRow>
                ) : (
                  <BaseCol>
                    <AddNurseModal onSubmit={onChangeNurses} />
                  </BaseCol>
                )}
              </BaseCol>
              <BaseCol xl={1}>
                <BaseButton
                  onClick={onDeleteNurses}
                  type="text"
                  icon={<SvgDeleteDesignIcon />}
                />
              </BaseCol>
            </BaseRow>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}

const CollapseWrapper = styled(CollapseComponent)`
  border: 1px solid ${convertedVariables.neutralBlack9Color};

  .ant-collapse-content {
    border-top: 1px solid ${convertedVariables.neutralBlack9Color};
  }

  .ant-collapse-header {
    border: 0px !important;
    background-color: var(--background-color) !important;
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
`
const BaseSelectWrapper = styled(BaseSelect)`
  width: 100%;
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`
