import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { initialPagination } from './common-model'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import R from 'app/assets/R'
import { useTableDataHook } from './data/hook'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { NOTIFICATION_TYPE } from 'app/common/constants'
import { downloadNotificationController } from 'app/controllers/download-notification-controller'
import { handleFileDownload } from 'app/api/accounting'
import { changeMessage } from 'app/redux/slices/profileSlice'
import { isEqual } from 'lodash'

interface IMetadata {
  totalNetAmount?: number
  totalPaid?: number
  totalCash?: number
  totalCard?: number
  totalTransfer?: number
  totalApp?: number
  totalRemain?: number
  totalRefund?: number
}

export const useTableHook = () => {
  const { t } = useTranslation()

  const [filterResponse, setFilterResponse] = useState<IResponseFilter>()
  const [exportFile, setExportFile] = useState<string[]>([])
  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()
  const profile = useAppSelector(state => state.profileSlice)
  const notificationMessage = profile?.message
  const dispatch = useAppDispatch()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.ACCOUNTING,
      FunctionPermissionEnum.READ_DAILY_INVOICE,
      R.strings.role,
    )
    setExportFile([])
  }, [])

  const DataHook = useTableDataHook(filterResponse)

  useEffect(() => {
    if (notificationMessage?.type !== NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT) return;

    const payload = JSON.parse(notificationMessage?.payload || '')
    const successData = payload?.successData || []
    const errorData = payload?.errorData || []

    if (errorData?.length) {
      downloadNotificationController.downloadErrored({
        duration: 2,
        key: NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT,
        message: t(R.strings.manage_customer_debt),
        description: t(R.strings.download_errored),
        cancel: t(R.strings.cancel),
      })
    } else if(!isEqual(exportFile, successData)) {
      setExportFile(successData)
      downloadNotificationController.close(
        NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT,
      )
    }
  }, [notificationMessage?.currentTime, notificationMessage?.type])

  const downloadFileForExport = () => {
    if (!exportFile?.length) return;


    exportFile.forEach(file => handleFileDownload(file, t, t(R.strings.manage_customer_debt), NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT))

    setExportFile([])
    dispatch(changeMessage({}))
  }

  useEffect(() => {
    downloadFileForExport()

    return downloadFileForExport
  }, [exportFile])

  const expandedRowKeys = useMemo(() => {
    return DataHook.expandedRowKeys
  }, [DataHook])

  const tableData = useMemo(() => {
    return DataHook.tableData
  }, [DataHook])

  const metadata: IMetadata = useMemo(() => {
    return DataHook.metadata
  }, [DataHook])

  const handleExpand = useMemo(() => {
    return DataHook.handleExpand
  }, [DataHook])

  const onClickExport = useMemo(() => {
    return DataHook.onClickExport
  }, [DataHook])

  const handleChangeFilter = filter => {
    setFilterResponse(filter)
    DataHook?.fetch(initialPagination, filter)
  }

  const columns = useMemo(() => {
    return DataHook.columns
  }, [t, DataHook])

  const handleTableChange = pagination => {
    DataHook?.fetch(pagination, filterResponse)
  }

  return {
    columns,
    tableData,
    metadata,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleChangeFilter,
    filterResponse,
    onClickExport,
  }
}
