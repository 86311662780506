import { IBaseTableReport } from 'app/components/tables/BaseTableReport/type'
import { Pagination } from 'parkway-web-common'

export const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
}

export interface IGeneralReport05Table<T> extends IBaseTableReport<T> {
  totalRevenueTarget?: number
  percentTargetComplete?: number

  totalRevenueTargetTotal?: number
  percentTargetCompleteTotal?: number
}
