import { DownloadOutlined, SearchOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { ProfileServiceComponent } from 'app/containers/Marketing/DiscountCampaignManagement/CreateCampaign/RootLayout/Contents/components'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAdditionalServiceVoucherContext } from '../../../../context'
import { ModalAddTreatmentLayout } from '../../../../modal'
import { ITreatmentInTable } from '../../../../type'
import { ImportFileModal } from './layouts'
import { URL_FILE_TEMPLATE_TREATMENT_IN_VOUCHER } from 'app/api/marketing/discount-voucher/constant'

export const ServiceApplyLayout = () => {
  const { t } = useTranslation()
  const [searchTable, setSearchTable] = useState('')
  const { listService, onDeleteService, onChangeService } =
    useAdditionalServiceVoucherContext()

  const data = useMemo(() => {
    return listService
      ?.filter(item => {
        return item?.name?.toLowerCase().includes(searchTable.toLowerCase())
      })

      ?.map((item, index) => {
        return {
          ...item,
          key: index + 1,
        }
      })
  }, [searchTable, listService])

  const columns = useMemo(() => {
    return [
      convertColumnTable<ITreatmentInTable>({
        title: '#',
        key: 'key',
        width: '5%',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.key ?? 1}
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<ITreatmentInTable>({
        title: t(R.strings.service),
        key: 'name',
        className: 'name-service-column',
        render: (_, record) => {
          return <ProfileServiceComponent itm={record} />
        },
      }),
      convertColumnTable<ITreatmentInTable>({
        title: '',
        key: '_id',
        classNameWidthColumnOverwrite: 'number-column',
        width: '5%',
        render: (_, record) => {
          if (record?.isInit) return null
          return (
            <BaseButton
              onClick={() => onDeleteService?.(record?._id)}
              icon={<SvgDeleteDesignIcon />}
              type="link"
              disabled={record?.isInit}
            />
          )
        },
      }),
    ]
  }, [t, listService])

  const handleOpenFileTemplate = () => {
    window.open(URL_FILE_TEMPLATE_TREATMENT_IN_VOUCHER, '_blank', 'noopener')
  }

  return (
    <BaseSpace size={8}>
      <BaseRow justify={'space-between'} align={'middle'} gutter={16}>
        <BaseCol>
          <BaseText
            children={t(R.strings.service_apply)}
            fontWeight="semibold"
            fontSize="md"
          />
        </BaseCol>
        <BaseCol>
          <BaseRow gutter={[16, 8]} align={'middle'}>
            <BaseCol>
              <BaseButton
                icon={<DownloadOutlined rev={undefined} />}
                onClick={handleOpenFileTemplate}
              >
                {t(R.strings.download_example_import_file)}
              </BaseButton>
            </BaseCol>
            <BaseCol>
              <ImportFileModal />
            </BaseCol>
            <BaseCol>
              <ModalAddTreatmentLayout
                onChangeService={onChangeService}
                services={listService}
              />
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>

      <BaseRow>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel style={{ marginBottom: 0 }}>
            <BaseInput
              placeholder={t(R.strings.enter_service_name)}
              value={searchTable}
              onChange={e => setSearchTable(e.target.value)}
              allowClear
              prefix={<SearchOutlined rev={undefined} />}
            />
          </BaseFormItem>
        </BaseCol>
      </BaseRow>

      <BaseTableManagement
        columns={columns}
        dataSource={data}
        scroll={{ x: true }}
        pagination={{
          pageSize: 5,
        }}
      />
    </BaseSpace>
  )
}

const BaseFormItem = styled(BaseForm.Item)`
  width: 100%;
`
