import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import Report015Container from 'app/containers/Report/Report015'

const Report015PageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.report_15)}</PageTitle>
      <Report015Container />
    </>
  )
}
const Report015Page = memo(Report015PageCpn, isEqual)

export default Report015Page
