/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestImportCheckInServicePlan } from 'app/api/customer-care'
import {
  IAreaOfServiceCheckInServicePlan,
  IMonthOrWeekOfAreaCheckInServicePlan,
} from 'app/api/customer-care/model/check-in-service-plan'
import { MarketingMessageCode } from 'app/api/marketing/constant'
import R from 'app/assets/R'
import {
  ExpandedTableValue,
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import {
  IBaseColumnReport,
  IOnCell,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { notificationController } from 'app/controllers/notification-controller'
import { convertToFormDateImport } from 'app/service/general/service'
import { isEqual } from 'lodash'
import { Pagination, PeriodType, ResponseType } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataMarketingPlanMonthly } from './Monthly/data'
import { IDataMarketingPlanWeekly } from './Weekly/data'
import { initialPagination } from './common-model'

export const useCommonHook = ({
  expandedRowKeys,
  fetchLoading,
  typeHook,
  handleExpand,
  fetch,
  expandedAreaKeys,
  handleExpandArea,
  dataTable,
  currentFilter,
}: {
  expandedRowKeys: number[]
  fetchLoading: (value: boolean) => void
  typeHook: PeriodType
  fetch: (pagination: Pagination, params?: IParamsHookReport) => void
  handleExpand: (isExpanded: boolean, key?: number) => void
  expandedAreaKeys: number[]
  handleExpandArea: (isExpanded: boolean, key?: number) => void
  dataTable: any[]
  currentFilter?: IParamsHookReport
}) => {
  const { t } = useTranslation()

  const onClickImport = async (file: any, periodType: PeriodType) => {
    fetchLoading(true)
    try {
      const response: ResponseType<{ countSuccess?: number }> =
        await requestImportCheckInServicePlan(
          convertToFormDateImport({
            file,
            periodtype: periodType,
            importtype: 'full',
          }),
        )
      console.log('response.msgcode', response.msgcode)

      if (
        isEqual(
          response.msgcode,
          MarketingMessageCode.MarketingPlan.successImport,
        )
      ) {
        fetch(initialPagination, currentFilter)
        notificationController.success({
          message: t(R.strings.import_file_successful),
        })
        fetchLoading(false)
        return
      }
      fetchLoading(false)
      notificationController.error({ message: t(R.strings.import_file_error) })
    } catch (error) {
      fetchLoading(false)
    }
  }

  const columns = useMemo(() => {
    type TypeColumn = IDataMarketingPlanWeekly | IDataMarketingPlanMonthly

    const onCellService = (record: TypeColumn): IOnCell => {
      if (record.isArea && record?.children) {
        const isExpandedArea = expandedRowKeys.includes(record.key ?? 0)
        if (!isExpandedArea && expandedAreaKeys?.length) {
          if (expandedRowKeys.length > expandedAreaKeys.length) {
            return {
              rowSpan: 1,
              colSpan: 3,
            }
          }

          return {
            rowSpan: 1,
            colSpan: 2,
          }
        }

        if (!expandedAreaKeys?.length) {
          return { rowSpan: 1 }
        }

        let count = record?.children?.length + 1 ?? 0
        record?.children?.forEach(item => {
          const isExpanded = expandedRowKeys.includes(item.key ?? 0)
          if (isExpanded && item?.children) {
            count += item?.children?.length
          }
        })

        return {
          rowSpan: count,
        }
      }

      return { rowSpan: 0 }
    }

    const onCellClinic = (record: TypeColumn): IOnCell => {
      if (record?.isArea) {
        const isExpandedArea = expandedRowKeys.includes(record.key ?? 0)
        if (!isExpandedArea && expandedAreaKeys?.length) {
          return { colSpan: 0 }
        }

        const isExpanded = expandedRowKeys.includes(record.childrenKey ?? 0)
        if (!isExpanded && record?.children) {
          if (expandedRowKeys.length > expandedAreaKeys.length) {
            return { rowSpan: 1, colSpan: 2 }
          }
          return { rowSpan: 1 }
        }

        if (isExpanded && !record?.childOfFirstChild?.length) {
          return { rowSpan: 1 }
        }

        return {
          rowSpan: record.childOfFirstChild?.length
            ? record.childOfFirstChild?.length + 1
            : 0,
        }
      }

      const isExpanded = expandedRowKeys.includes(record.key ?? 0)
      if (!isExpanded && record?.children) {
        if (expandedRowKeys.length > expandedAreaKeys.length) {
          return { rowSpan: 1, colSpan: 2 }
        }
        return { rowSpan: 1 }
      }

      if (isExpanded && !record?.children?.length) {
        return { rowSpan: 1 }
      }

      return {
        rowSpan: record.children?.length ? record.children?.length + 1 : 0,
      }
    }

    const onCellEmployeeName = (record: TypeColumn): IOnCell => {
      if (record?.isArea) {
        const isExpanded = expandedRowKeys.includes(record.childrenKey ?? 0)

        if (
          !isExpanded &&
          record?.children &&
          expandedRowKeys.length > expandedAreaKeys.length
        ) {
          return { colSpan: 0 }
        }
      }

      const isExpanded = expandedRowKeys.includes(record.key ?? 0)
      if (
        !isExpanded &&
        record?.children &&
        expandedRowKeys.length > expandedAreaKeys.length
      ) {
        return { colSpan: 0 }
      }

      return {}
    }

    // render column

    const renderServiceColumn = (_, record: TypeColumn) => {
      const isExpandedChild = expandedRowKeys.includes(record.key ?? 0)
      const _handleExpand = () => {
        if (record?.isArea) {
          handleExpandArea(!isExpandedChild, record.key ?? 0)
        }
      }
      return (
        <ExpandedTableValue
          isExpanded={isExpandedChild}
          handleExpand={_handleExpand}
          value={record?.service}
          className={isExpandedChild ? 'is-expanded-1' : ''}
        />
      )
    }

    const renderClinicColumn = (_, record: TypeColumn) => {
      if (!record?.children) return null
      let key = record?.key
      if (record?.isArea) key = record?.childrenKey
      const isExpandedChild = expandedRowKeys.includes(key ?? 0)
      const _handleExpand = () => {
        return handleExpand(!isExpandedChild, key)
      }
      return (
        <ExpandedTableValue
          isExpanded={isExpandedChild}
          handleExpand={_handleExpand}
          value={record?.clinic}
        />
      )
    }

    const renderEmployeeNameColumn = (_, record: TypeColumn) => {
      switch (typeHook) {
        case 'week':
          return (
            <RenderValueTableReport
              record={record}
              valueString={record?.week}
              justify="start"
              expandedRowKeys={expandedRowKeys}
            />
          )
        case 'month':
          return (
            <RenderValueTableReport
              record={record}
              valueString={record?.month}
              justify="start"
              expandedRowKeys={expandedRowKeys}
            />
          )
      }
    }

    let options: IBaseColumnReport<TypeColumn> = [
      convertColumnTable({
        title: t(R.strings.service),
        key: 'service',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'big-column',
        onCell: onCellService,
        render: renderServiceColumn,
      }),
    ]

    if (expandedAreaKeys.length) {
      options = options.concat([
        convertColumnTable({
          title: t(R.strings.clinic),
          key: 'clinic',
          fixed: 'left',
          classNameWidthColumnOverwrite: 'very-big-column',
          onCell: onCellClinic,
          render: renderClinicColumn,
        }),
      ])
    }

    if (expandedRowKeys.length > expandedAreaKeys.length) {
      switch (typeHook) {
        case 'week':
          options = options?.concat([
            convertColumnTable<IDataMarketingPlanWeekly>({
              title: t(R.strings.week),
              fixed: 'left',
              key: 'week',
              onCell: onCellEmployeeName,
              render: renderEmployeeNameColumn,
            }),
          ])
          break
        case 'month':
          options = options?.concat([
            convertColumnTable<IDataMarketingPlanMonthly>({
              title: t(R.strings.month),
              key: 'month',
              fixed: 'left',
              onCell: onCellEmployeeName,
              render: renderEmployeeNameColumn,
            }),
          ])
          break
      }
    }

    return [
      ...options,
      convertColumnTable<TypeColumn>({
        title: t(R.strings.check_in_planing),
        key: 'checkInSchedule',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.checkInSchedule ?? 0,
            secondLevelValue: record?.checkInScheduleTotal ?? 0,
            isFillBlueInTotal: true,
            expandedRowKeys,
            prefix: '',
          }),
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.kpi_plan),
        key: 'kpiPlan',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.kpiPlan ?? 0,
            secondLevelValue: record?.kpiPlanTotal ?? 0,
            isFillBlueInTotal: true,
            expandedRowKeys,
            prefix: '',
          }),
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.kpi_actual),
        key: 'kpiActual',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.kpiActual ?? 0,
            secondLevelValue: record?.kpiActualTotal ?? 0,
            isFillBlueInTotal: true,
            expandedRowKeys,
            prefix: '',
          }),
      }),
    ]
  }, [t, expandedRowKeys, typeHook])

  const data = (() => {
    return dataTable.map(item => {
      const isExpanded = expandedRowKeys.includes(item.childrenKey)
      const oldChild =
        item?.children?.filter(
          (itm: { parentKey?: number }) => !itm?.parentKey,
        ) ?? []
      if (isExpanded) {
        const newChild = item?.childOfFirstChild ?? []
        return {
          ...item,
          children: [...newChild, ...oldChild],
        }
      }
      return {
        ...item,
        children: oldChild,
      }
    })
  })()

  return {
    onClickImport,
    columns,
    data,
  }
}

export const convertChildCostPlanValue = (
  item: IMonthOrWeekOfAreaCheckInServicePlan,
) => {
  return {
    checkInSchedule: item?.checkInPlan,
    kpiPlan: item?.kpiPlan,
    kpiActual: item?.kpiActual,
  }
}

export const convertTotalCostPlanValue = (
  item: IAreaOfServiceCheckInServicePlan,
) => {
  return {
    clinic: item?.clinicName,
    checkInScheduleTotal: item?.checkInPlanTotal,
    kpiPlanTotal: item?.kpiPlanTotal,
    kpiActualTotal: item?.kpiActualTotal,
  }
}
