export const ProductPropertyKeys = {
  allProductProperties: ['ProductProperties'] as const,
  listProductProperties: () =>
    [
      ...ProductPropertyKeys.allProductProperties,
      'listProductProperties',
    ] as const,
  listProductProperty: () =>
    [...ProductPropertyKeys.listProductProperties(), {}] as const,
}
