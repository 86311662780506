/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import {
  ExpandedTableValue,
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import {
  IBaseColumnReport,
  IOnCell,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { getArray } from 'app/components/tables/common-table/constant'
import {
  PERCENT_PREFIX,
  Pagination,
  PeriodType,
  ResponseType,
  moment,
} from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataReport05Monthly } from './Monthly/data'
import { IDataReport05Weekly } from './Weekly/data'
import {
  AreaDataReport05,
  BrandDataReport05,
  IParamsGetReport05,
} from 'app/api/report/model/report-05'
import { requestExportExcelRevenueOfPaid } from 'app/api/report'
import { IResponseExportExcel } from 'app/api/report/model/common'
import { ReportMessageCode } from 'app/api/report/constant'
import { notificationController } from 'app/controllers/notification-controller'

export const useCommonHook = ({
  expandedRowKeys,
  typeHook,
  handleExpand,
  currentFilter,
  fetchLoading,
}: {
  expandedRowKeys: number[]
  fetchLoading: (value: boolean) => void
  typeHook: PeriodType
  fetch: (pagination: Pagination, params?: IParamsHookReport) => void
  handleExpand: (isExpanded: boolean, key?: number) => void
  currentFilter?: IParamsHookReport
}) => {
  const { t } = useTranslation()

  const exportExcel = async () => {
    try {
      if (!currentFilter?.year) return

      let params: IParamsGetReport05 = {
        year: currentFilter?.year,
      }

      switch (typeHook) {
        case 'week':
          if (!currentFilter?.month) return
          params = {
            ...params,
            month: currentFilter?.month,
            option: 'week',
          }
          break
        case 'month':
          params = {
            ...params,
            option: 'month',
          }
          break
      }

      fetchLoading(true)
      const res: ResponseType<IResponseExportExcel> =
        await requestExportExcelRevenueOfPaid(params)

      if (
        res?.msgcode === ReportMessageCode.Report05.successGetData &&
        res?.data?.success
      ) {
        window.open(res?.data?.data ?? '')
        notificationController.success({
          message: t(R.strings.download_excel_success),
        })
      }
    } catch (error: any) {
      // console.log(error)
      notificationController.error(error)
    } finally {
      fetchLoading(false)
    }
  }

  const columns = useMemo(() => {
    type TypeColumn = IDataReport05Weekly & IDataReport05Monthly

    const onCellArea = (record: TypeColumn): IOnCell => {
      if (record?.isTotalTable && expandedRowKeys.length) return { colSpan: 2 }

      if (record?.isTotalTable && !expandedRowKeys.length) return { colSpan: 1 }

      const isExpanded = expandedRowKeys.includes(record?.key ?? 0)
      if (!isExpanded && record?.children && expandedRowKeys?.length) {
        return { rowSpan: 1, colSpan: 2 }
      }

      if (!isExpanded && record?.children) {
        return { rowSpan: 1 }
      }

      if (isExpanded && !record?.children?.length) {
        return { rowSpan: 1 }
      }

      return {
        rowSpan: record.children?.length ? record.children?.length + 1 : 0,
      }
    }

    const onCellClinic = (record: TypeColumn): IOnCell => {
      if (record?.isTotalTable) {
        return { colSpan: 0 }
      }
      const isExpanded = expandedRowKeys.includes(record.key ?? 0)
      if (!isExpanded && record?.children && !!expandedRowKeys?.length) {
        return { colSpan: 0 }
      }
      return {}
    }

    let options: IBaseColumnReport<TypeColumn> = [
      convertColumnTable<TypeColumn>({
        title: t(R.strings.area),
        key: 'area',
        onCell: onCellArea,
        fixed: 'left',
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)
          if (record?.isTotalTable) {
            return <ExpandedTableValue value={record?.area} isTotalTable />
          }

          if (!record?.children) return null

          const _handleExpand = () => {
            return handleExpand(!isExpanded, record?.key)
          }

          return (
            <ExpandedTableValue
              isExpanded={isExpanded}
              handleExpand={_handleExpand}
              value={record?.area}
            />
          )
        },
      }),
    ]

    if (expandedRowKeys?.length) {
      options = options?.concat([
        convertColumnTable<TypeColumn>({
          title: t(R.strings.clinic),
          key: 'clinic',
          onCell: onCellClinic,
          fixed: 'left',
          render: (_, record) => {
            return (
              <RenderValueTableReport
                record={record}
                valueString={record?.clinic}
                expandedRowKeys={expandedRowKeys}
                justify="start"
              />
            )
          },
        }),
      ])
    }

    if (typeHook === 'week') {
      const weeks = getArray(6)?.map(item => {
        return convertColumnTable<any>({
          title: t(R.strings.week_no, { week: item }),
          key: `week${item}`,
          render: (_, record) => {
            return RenderValueTableReport({
              record,
              firstLevelValue: record?.[`week${item}`] ?? 0,
              secondLevelValue: record?.[`week${item}Total`] ?? 0,
              isFillBlueInTotal: true,
              expandedRowKeys,
            })
          },
        })
      })
      return [
        ...options,
        ...weeks,
        convertColumnTable<TypeColumn>({
          title: t(R.strings.total_revenue_month, {
            month: currentFilter?.month ?? moment().month() + 1,
          }),
          key: 'totalRevenueMonth',
          render: (_, record) =>
            RenderValueTableReport({
              record,
              firstLevelValue: record?.totalRevenueMonth ?? 0,
              secondLevelValue: record?.totalRevenueMonthTotal ?? 0,
              isFillBlueInTotal: true,
              expandedRowKeys,
            }),
        }),
        convertColumnTable<TypeColumn>({
          title: t(R.strings.total_revenue_target),
          key: 'totalRevenueTarget',
          render: (_, record) =>
            RenderValueTableReport({
              record,
              firstLevelValue: record?.totalRevenueTarget ?? 0,
              secondLevelValue: record?.totalRevenueTargetTotal ?? 0,
              isFillBlueInTotal: true,
              expandedRowKeys,
            }),
        }),
        convertColumnTable<TypeColumn>({
          title: t(R.strings.percent_complete_target_api),
          key: 'percentTargetComplete',
          render: (_, record) =>
            RenderValueTableReport({
              record,
              firstLevelValue: record?.percentTargetComplete ?? 0,
              secondLevelValue: record?.percentTargetCompleteTotal ?? 0,
              prefix: PERCENT_PREFIX,
              isFillBlueInTotal: true,
              expandedRowKeys,
            }),
        }),
      ]
    } else {
      const months = getArray(12)?.map(item => {
        return convertColumnTable<any>({
          title: t(R.strings.month_no, { month: item }),
          key: `month${item}`,
          render: (_, record) => {
            return RenderValueTableReport({
              record,
              firstLevelValue: record?.[`month${item}`] ?? 0,
              secondLevelValue: record?.[`month${item}Total`] ?? 0,
              isFillBlueInTotal: true,
              expandedRowKeys,
            })
          },
        })
      })
      return [
        ...options,
        ...months,
        convertColumnTable<TypeColumn>({
          title: t(R.strings.total_revenue_year, {
            year: currentFilter?.year ?? moment().year(),
          }),
          key: 'totalRevenueYear',
          render: (_, record) =>
            RenderValueTableReport({
              record,
              firstLevelValue: record?.totalRevenueYear ?? 0,
              secondLevelValue: record?.totalRevenueYearTotal ?? 0,
              isFillBlueInTotal: true,
              expandedRowKeys,
            }),
        }),
        convertColumnTable<TypeColumn>({
          title: t(R.strings.total_revenue_target),
          key: 'totalRevenueTarget',
          render: (_, record) =>
            RenderValueTableReport({
              record,
              firstLevelValue: record?.totalRevenueTarget ?? 0,
              secondLevelValue: record?.totalRevenueTargetTotal ?? 0,
              isFillBlueInTotal: true,
              expandedRowKeys,
            }),
        }),
        convertColumnTable<TypeColumn>({
          title: t(R.strings.percent_complete_target_api),
          key: 'percentTargetComplete',
          render: (_, record) =>
            RenderValueTableReport({
              record,
              firstLevelValue: record?.percentTargetComplete ?? 0,
              secondLevelValue: record?.percentTargetCompleteTotal ?? 0,
              prefix: PERCENT_PREFIX,
              isFillBlueInTotal: true,
              expandedRowKeys,
            }),
        }),
      ]
    }
  }, [t, expandedRowKeys, typeHook, currentFilter?.month, currentFilter?.year])

  return {
    columns,
    exportExcel,
  }
}

export const convertGeneralReport05Value = ({
  area,
  type,
  clinic,
  typeHook,
}: {
  area?: AreaDataReport05
  clinic?: BrandDataReport05
  type: 'clinic' | 'area'
  typeHook: 'month' | 'week'
}) => {
  if (typeHook === 'week') {
    let weekData: IDataReport05Weekly = {}
    switch (type) {
      case 'clinic':
        clinic?.totalPaidOfBrand?.forEach(item => {
          weekData = {
            ...weekData,
            [`week${item.week}`]: item?.totalActual,
          }
        })
        return {
          ...weekData,
          clinic: clinic?.name,
          totalRevenueMonth: clinic?.totalActual ?? 0,
          totalRevenueTarget: clinic?.budgetPlan ?? 0,
          percentTargetComplete: clinic?.targetPercent ?? 0,
        }
      case 'area':
        area?.totalPaidOfBrand?.forEach(item => {
          weekData = {
            ...weekData,
            [`week${item.week}Total`]: item?.totalActual,
          }
        })
        return {
          ...weekData,
          area: area?.areName,
          totalRevenueMonthTotal: area?.totalActual ?? 0,
          totalRevenueTargetTotal: area?.budgetPlan ?? 0,
          percentTargetCompleteTotal: area?.targetPercent,
        }
    }
  } else if (typeHook === 'month') {
    let monthData: IDataReport05Weekly = {}
    switch (type) {
      case 'clinic':
        clinic?.totalPaidOfBrand?.forEach(item => {
          monthData = {
            ...monthData,
            [`month${item.month}`]: item?.totalActual,
          }
        })
        return {
          ...monthData,
          clinic: clinic?.name,
          totalRevenueYear: clinic?.totalActual ?? 0,
          totalRevenueTarget: clinic?.budgetPlan ?? 0,
          percentTargetComplete: clinic?.targetPercent ?? 0,
        }
      case 'area':
        area?.totalPaidOfBrand?.forEach(item => {
          monthData = {
            ...monthData,
            [`month${item.month}Total`]: item?.totalActual,
          }
        })
        return {
          ...monthData,
          area: area?.areName,
          totalRevenueYearTotal: area?.totalActual ?? 0,
          totalRevenueTargetTotal: area?.budgetPlan ?? 0,
          percentTargetCompleteTotal: area?.targetPercent,
        }
    }
  }
}
