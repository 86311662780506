import { requestOrthodonticPatientWithoutPaid } from 'app/api/accounting/api/orthodontic-patient-without-paid'
import { AccountingMessageCode } from 'app/api/accounting/constant'
import {
  GroupTypeEnum,
  IParamsGetOrthodonticWithoutPaid,
  IResponseOrthodonticWithoutPaid,
  ReportTypeEnum,
} from 'app/api/accounting/model/orthodontic-patient-without-paid'
import R from 'app/assets/R'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { FORMAT_DD_MM_YYYY_HH_MM, _DEV_ } from 'app/constant'
import { isEqual } from 'lodash'
import { DateUtil, Pagination, initialPagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DifficultyPackage } from '../../../components/DifficultyPackage'
import { useOrthodonticWithoutPaid } from '../../../context'
import { IDataTablePatient } from '../../../type'
import { getDetailPatientUrl } from 'app/common/helpers'
interface IProps {
  id: string
}

export const useHook = (props: IProps) => {
  const { params: paramsFilter } = useOrthodonticWithoutPaid()
  const [pagination, setPagination] = useState<Pagination | undefined>(
    initialPagination,
  )

  const [total, setTotal] = useState(0)

  const [data, setData] = useState<IDataTablePatient[]>([])
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()
  const getData = async () => {
    setLoading(true)
    try {
      const params: IParamsGetOrthodonticWithoutPaid = {
        page: pagination?.current,
        pageSize: pagination?.pageSize,
        id: props?.id,
        groupType: GroupTypeEnum.doctor,
        reportType: ReportTypeEnum.withoutPaid,
        ...paramsFilter,
      }

      const res: IResponseOrthodonticWithoutPaid =
        await requestOrthodonticPatientWithoutPaid(params)

      if (
        res?.msgcode &&
        isEqual(
          AccountingMessageCode?.OrthodonticWithoutPaid?.getLocationSuccess,
          res?.msgcode,
        )
      ) {
        const data = res?.data ?? []
        const metadata = res?.metadata

        const newData = data?.map((item, index) => {
          const idNumber =
            ((params.page ?? 1) - 1) * (params?.pageSize ?? 10) + (index + 1)
          return {
            key: index + 1,
            idNumber,
            appointmentDate: DateUtil.formatDate(
              item.appointmentDate ?? '',
              FORMAT_DD_MM_YYYY_HH_MM,
            ),
            assignedPhysician: item.doctorName ?? '-',
            packageLevel: item?.difficulty ?? 0,
            patientName: item?.patientName ?? '-',
            patientId: item?.patientId ?? '-',
            patientCode: item?.patientCode ?? '-',
            totalMonth: item?.totalMonth ?? 0,
          } as IDataTablePatient
        })

        setData(newData)
        setTotal(metadata?.totalItem ?? 0)
      }
    } catch (error) {
      _DEV_ && console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getData()
    }, 500)

    return () => clearTimeout(timer)
  }, [pagination])

  const handleTableChange = (pagination?: Pagination) => {
    setPagination(pagination)
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<IDataTablePatient>({
        title: '#',
        key: 'idNumber',
        render: (_, record) => (
          <RenderValueTable
            value={record?.idNumber ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
        classNameWidthColumnOverwrite: 'small-column',
        fixed: 'left',
        className: 'class-left',
      }),
      convertColumnTable<IDataTablePatient>({
        title: t(R.strings.patient_name),
        key: 'patientName',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.patientName ?? '-'}
            type="OtherLink"
            hrefOtherLink={getDetailPatientUrl(record.patientId)}
          />
        ),
        fixed: 'left',
      }),
      convertColumnTable<IDataTablePatient>({
        title: t(R.strings.patient_id),
        key: 'patientCode',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.patientCode ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTablePatient>({
        title: t(R.strings.appointment_date),
        key: 'appointmentDate',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.appointmentDate ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTablePatient>({
        title: t(R.strings.total_month),
        key: 'totalMonth',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.totalMonth ?? '0'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTablePatient>({
        title: t(R.strings.package_level),
        key: 'packageLevel',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <DifficultyPackage level={record?.packageLevel ?? 0} />
        ),
      }),
    ]
  }, [t])

  return {
    columns,
    tableData: {
      pagination: {
        ...pagination,
        total,
      },
      data,
      loading,
    },
    handleTableChange,
  }
}
