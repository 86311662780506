import { ApiClientHr } from 'app/service/api-service'
import { ProfileEndPoint } from './constant'
import {
  IParamsGetProfileManagement,
  IPayloadCreateProfileManagement,
  IPayloadUpdateProfileManagement,
} from './model/management'

export const requestGetProfileManagement = (
  params: IParamsGetProfileManagement,
) =>
  ApiClientHr.Get({
    url: ProfileEndPoint.ProfileManagement.request,
    params: { params },
  })

export const requestGetDoctorManagement = (
  params: IParamsGetProfileManagement,
) =>
  ApiClientHr.Get({
    url: ProfileEndPoint.DoctorManagement.request,
    params: { params },
  })
export const requestGetDoctors = () =>
  ApiClientHr.Get({
    url: ProfileEndPoint.Doctor.request,
    params: {},
  })

export const requestCreateProfileManagement = (
  body: IPayloadCreateProfileManagement,
) =>
  ApiClientHr.Post({
    url: ProfileEndPoint.ProfileManagement.request,
    body,
  })

export const requestUpdateProfileManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateProfileManagement
}) =>
  ApiClientHr.Put({
    url: `${ProfileEndPoint.ProfileManagement.request}/${id}`,
    body,
  })

export const requestDetailProfileManagement = (id?: string | number) =>
  ApiClientHr.Get({
    url: `${ProfileEndPoint.ProfileManagement.request}/${id}`,
    params: {},
  })

export const requestGetUserInfo = () =>
  ApiClientHr.Get({
    url: `${ProfileEndPoint.UserInfo.request}`,
    params: {},
  })

export const requestDeleteProfileManagement = (id?: string | number) =>
  ApiClientHr.Delete({
    url: `${ProfileEndPoint.ProfileManagement.request}/${id}`,
    body: {},
  })

export const requestAllProfileManagement = () =>
  ApiClientHr.Get({
    url: `${ProfileEndPoint.ProfileManagement.request}/all`,
    params: {},
  })

export const requestUpdateMessageToken = ({
  id,
  messagingToken,
}: {
  messagingToken: string
  id: string
}) =>
  ApiClientHr.Put({
    url: ProfileEndPoint.ProfileManagement.updateMessageToken.replace(
      ':id',
      id,
    ),
    body: { messagingToken },
  })

export const requestExportProfile = () =>
  ApiClientHr.Get({
    url: `${ProfileEndPoint.export.profile}`,
    params: {},
  })
