/* eslint-disable @typescript-eslint/no-explicit-any */
import { IRoleRight } from 'app/api/roles/models/rights'
import { IRolePermission } from 'app/api/roles/models/role'
import { compareArrays, usePagination } from 'app/hook'
import { useGetRoleRights } from 'app/react-query/hook/role'
import { IResponseQueryList } from 'app/react-query/model/common'
import { isEmpty, isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPermission } from '../../type'
import { IPropsUpdatePermission } from './type'

export const useModalHook = ({
  permissions,
  onSubmit,
}: IPropsUpdatePermission) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [module, setModule] = useState<string | undefined>()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [permissionSelected, setPermissionSelected] = useState<IPermission[]>(
    [],
  )

  const [keyword, setKeyword] = useState('')

  const { flattenDataList } = usePagination()

  const { data: dataRightsBefore, isLoading: isLoadingRights } =
    useGetRoleRights({ keyword, pagesize: 100 })

  const dataRights = useMemo(() => {
    const flattenDta: IResponseQueryList<IRoleRight[]> =
      flattenDataList(dataRightsBefore)
    return flattenDta?.data ?? []
  }, [dataRightsBefore])

  const listPermission = useMemo(() => {
    let data = dataRights
    if (!isEmpty(module)) {
      data = data?.filter(item => item?._id === module) ?? []
    }
    return data.reduce((acc: IRolePermission[], cur) => {
      return [...acc, ...(cur?.rights ?? [])]
    }, [])
  }, [dataRights, module])

  useEffect(() => {
    const timer = setTimeout(() => {
      setKeyword(search)
      setCurrentPage(1)
    }, 500)
    return () => clearTimeout(timer)
  }, [search])

  const onChangePermission = (newPermission: IPermission[]) => {
    setPermissionSelected(newPermission)
  }

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const permissionAfterSelected = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize
    const endIndex = startIndex + pageSize
    const listPermissionAfterPagination = listPermission?.slice(
      startIndex,
      endIndex,
    )
    return listPermissionAfterPagination?.map(item => {
      const isSelected = !!permissionSelected?.find(itm =>
        isEqual(itm?._id, item?._id),
      )
      return {
        ...item,
        isSelected,
      }
    })
  }, [permissionSelected, currentPage, pageSize, listPermission])

  useEffect(() => {
    if (visible) {
      setPermissionSelected(permissions ?? [])
    }
  }, [permissions, visible])

 // console.log({ permissionSelected })

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      onSubmit?.(permissionSelected)
      setPermissionSelected([])
      setModule(undefined)
    } catch (error) {
     // console.log({ error })
    } finally {
      setIsLoading(false)
      handleCancel()
    }
  }

  const onChangeModules = (value: string) => {
    setModule(value)
  }

  const onChangeSearch = (value: string) => {
    setSearch(value)
  }

  const isSelectAll = useMemo(() => {
    const listSelectedKey =
      permissionSelected?.map(item => item?._id ?? '') ?? []
    const listPermissionKey = listPermission?.map(item => item?._id ?? '') ?? []

    return compareArrays(listSelectedKey, listPermissionKey)
  }, [permissionSelected, listPermission])

  const onClickSelectAll = () => {
    if (isSelectAll) {
      setPermissionSelected([])
    } else {
      setPermissionSelected(listPermission)
    }
  }

  const onChangePermissions = (per: IPermission) => {
    const itemExist = permissionSelected?.find(itm => itm?._id === per?._id)

    if (itemExist) {
      setPermissionSelected(prev => prev?.filter(itm => itm?._id !== per?._id))
      return
    }

    setPermissionSelected(prev => prev?.concat([per]))
  }

  const onChangePageSize = (newPageSize: number) => {
    setPageSize(newPageSize)
  }

  const onChangePage = (page: number) => {
    setCurrentPage(page)
  }

  return {
    visible,
    t,
    isLoading: isLoading || isLoadingRights,
    showModal,
    handleSubmit,
    handleCancel,
    onChangePermission,
    permissionSelected,
    onChangeSearch,
    search,
    modules: dataRights,
    module,
    onChangeModules,
    permissionAfterSelected,
    isSelectAll,
    onClickSelectAll,
    onChangePermissions,
    pageSize,
    currentPage,
    onChangePageSize,
    onChangePage,
    dataRights,
    listPermission,
  }
}
