import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import Report014Container from 'app/containers/Report/Report014'

const Report014PageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.report_14)}</PageTitle>
      <Report014Container />
    </>
  )
}
const Report014Page = memo(Report014PageCpn, isEqual)

export default Report014Page
