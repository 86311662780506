import React from 'react'
import { MoonSunSwitch } from 'app/components/common/MoonSunSwitch'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { ThemeType } from 'parkway-web-common'
import { setNightMode } from 'app/redux/slices/nightModeSlice'
import { setTheme } from 'app/redux/slices/themeSlice'

export const ThemePicker: React.FC = () => {
  const dispatch = useAppDispatch()
  const theme = useAppSelector(state => state.theme.theme)

  const handleClickButton = (theme: ThemeType) => {
    dispatch(setTheme(theme))
    dispatch(setNightMode(false))
  }

  return (
    <MoonSunSwitch
      isMoonActive={theme === 'dark'}
      onClickMoon={() => handleClickButton('dark')}
      onClickSun={() => handleClickButton('light')}
    />
  )
}
