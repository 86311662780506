import { ImportOutlined, PlusOutlined } from '@ant-design/icons'
import { DiscountTypeEnum } from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useCreateCampaignContext } from '../../../../context'
import { BuyOneGetOneItem, ITreatmentInTable } from '../../../../type'
import { ModalAddAndUpdateSelectedTreatment } from './ModalAddAndUpdateSelectedTreatment'
import { ModalAddAndUpdateDiscountTreatment } from './ModalAddAndUpdateDiscountTreatment'
import {
  LabelCollapseComponent,
  ProfileServiceComponent,
} from '../../components'

export const BuyOneGetOneTypeLayout = () => {
  const { t } = useTranslation()
  const {
    listBuyOneGetOne,
    onAddNewBuyOneGetOne,
    onChangeNameByIndex,
    onRemoveBuyOneGetOneByIndex,
    onChangeItemByIndex,
    onChangeDiscountValueInDiscountTreatment,
    onChangeDiscountTypeInDiscountTreatment,
  } = useCreateCampaignContext()

  const onChangeSelectedTreatment = ({
    index,
    service,
    item,
  }: {
    service?: ITreatmentInTable[]
    item?: BuyOneGetOneItem
    index?: number
  }) => {
    if (index !== undefined && item) {
      onChangeItemByIndex?.(index, {
        ...item,
        selectedTreatment: service,
      })
    }
  }

  const onChangeDiscountTreatment = ({
    index,
    service,
    item,
  }: {
    service?: ITreatmentInTable[]
    item?: BuyOneGetOneItem
    index?: number
  }) => {
    if (index !== undefined && item && service) {
      onChangeItemByIndex?.(index, {
        ...item,
        discountTreatment: service,
      })
    }
  }

  return (
    <RootWrapper>
      <HeaderWrapper
        gutter={[16, 8]}
        align={'middle'}
        justify={'space-between'}
      >
        <BaseCol>
          <BaseText
            children={t(R.strings.service_apply)}
            fontWeight="semibold"
            fontSize="md"
          />
        </BaseCol>

        <BaseCol>
          <BaseRow gutter={[16, 8]} align={'middle'}>
            <BaseCol>
              <BaseButton icon={<ImportOutlined rev={undefined} />}>
                {t(R.strings.import)}
              </BaseButton>
            </BaseCol>
            <BaseCol>
              <BaseButton
                icon={<PlusOutlined rev={undefined} />}
                onClick={onAddNewBuyOneGetOne}
              >
                {t(R.strings.add_service_buy_discount)}
              </BaseButton>
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </HeaderWrapper>
      <ContentWrapper>
        {listBuyOneGetOne?.map((item, index) => {
          return (
            <CollapseWrapper
              items={[
                {
                  key: index,
                  label: (
                    <LabelCollapseComponent
                      item={item}
                      key={index}
                      onChange={value => onChangeNameByIndex?.(index, value)}
                    />
                  ),
                  extra: (
                    <div onClick={e => e.stopPropagation()}>
                      <BaseRow gutter={[16, 8]} align={'middle'} key={index}>
                        <BaseCol>
                          <BaseButton
                            onClick={() => onRemoveBuyOneGetOneByIndex?.(index)}
                            icon={<SvgDeleteDesignIcon />}
                          />
                        </BaseCol>
                      </BaseRow>
                    </div>
                  ),
                  style: {
                    alignItems: 'center',
                  },
                  children: (
                    <BaseSpace size={0}>
                      <BaseRow
                        gutter={[16, 8]}
                        align={'middle'}
                        className="header-content"
                        justify={'space-between'}
                      >
                        <BaseCol>
                          <BaseText
                            children={t(R.strings.buy_service)}
                            fontWeight="semibold"
                          />
                        </BaseCol>
                        <BaseCol>
                          <ModalAddAndUpdateSelectedTreatment
                            services={item?.selectedTreatment}
                            onChangeService={service =>
                              onChangeSelectedTreatment({
                                index,
                                service,
                                item,
                              })
                            }
                          />
                        </BaseCol>
                      </BaseRow>
                      <BaseSpace className="list-item-content-wrapper">
                        {item?.selectedTreatment?.length ? (
                          item?.selectedTreatment?.map((itm, idx) => {
                            return (
                              <BaseRow
                                key={idx}
                                align={'middle'}
                                gutter={[16, 8]}
                              >
                                <BaseCol xl={24}>
                                  <ProfileServiceComponent itm={itm} />
                                </BaseCol>
                              </BaseRow>
                            )
                          })
                        ) : (
                          <BaseText
                            children={t(R.strings.empty_list)}
                            fontWeight="medium"
                            opacity="0.5"
                          />
                        )}
                      </BaseSpace>
                      <BaseRow
                        gutter={[16, 8]}
                        align={'middle'}
                        className="header-content"
                        justify={'space-between'}
                      >
                        <BaseCol>
                          <BaseText
                            children={t(R.strings.discount_service)}
                            fontWeight="semibold"
                          />
                        </BaseCol>
                        <BaseCol>
                          <ModalAddAndUpdateDiscountTreatment
                            services={item?.discountTreatment}
                            onChangeService={service =>
                              onChangeDiscountTreatment({
                                index,
                                service,
                                item,
                              })
                            }
                          />
                        </BaseCol>
                      </BaseRow>
                      <BaseSpace className="list-item-content-wrapper">
                        {item?.discountTreatment?.length ? (
                          item?.discountTreatment?.map((itm, idx) => {
                            return (
                              <BaseRow
                                key={idx}
                                align={'middle'}
                                gutter={[16, 8]}
                                justify={'space-between'}
                              >
                                <BaseCol xl={14}>
                                  <ProfileServiceComponent itm={itm} />
                                </BaseCol>
                                <BaseCol xl={5}>
                                  <BaseFormItem hiddenLabel>
                                    <InputNumberWrapper
                                      value={itm?.discountValue}
                                      placeholder={t(
                                        R.strings.enter_discount_value,
                                      )}
                                      onChange={value =>
                                        onChangeDiscountValueInDiscountTreatment?.(
                                          index,
                                          idx,
                                          value as number,
                                        )
                                      }
                                      min={0}
                                      max={
                                        isEqual(
                                          itm?.discountType,
                                          DiscountTypeEnum.Percent,
                                        )
                                          ? 100
                                          : undefined
                                      }
                                      step={1}
                                      precision={
                                        isEqual(
                                          itm?.discountType,
                                          DiscountTypeEnum.Percent,
                                        )
                                          ? 1
                                          : 0
                                      }
                                      disabled={itm?.discountType === undefined}
                                    />
                                  </BaseFormItem>
                                </BaseCol>
                                <BaseCol xl={5}>
                                  <BaseFormItem hiddenLabel>
                                    <BaseSelect
                                      value={itm?.discountType}
                                      placeholder={t(
                                        R.strings.select_discount_type,
                                      )}
                                      onChange={value =>
                                        onChangeDiscountTypeInDiscountTreatment?.(
                                          index,
                                          idx,
                                          value as DiscountTypeEnum,
                                        )
                                      }
                                      options={[
                                        {
                                          label: t(R.strings.discount_percent),
                                          value: DiscountTypeEnum.Percent,
                                        },
                                        {
                                          label: t(R.strings.cash),
                                          value: DiscountTypeEnum.Amount,
                                        },
                                      ]}
                                    />
                                  </BaseFormItem>
                                </BaseCol>
                              </BaseRow>
                            )
                          })
                        ) : (
                          <BaseText
                            children={t(R.strings.empty_list)}
                            fontWeight="medium"
                            opacity="0.5"
                          />
                        )}
                      </BaseSpace>
                    </BaseSpace>
                  ),
                },
              ]}
              expandIconPosition="right"
              key={index}
            />
          )
        })}
      </ContentWrapper>
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
`

const HeaderWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
  border-start-start-radius: ${BORDER_RADIUS};
`

const ContentWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
  background-color: #f9fafb;
  border-start-end-radius: ${BORDER_RADIUS};
  .dash-line {
    border-top: 1px dashed ${convertedVariables.neutralBlack2Color};
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`

const CollapseWrapper = styled(BaseCollapse)`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};

  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-content {
    border-top: 1px solid ${convertedVariables.neutralBlack9Color};
    background-color: ${convertedVariables.neutralBlack9Color};
    .ant-collapse-content-box {
      padding: 0px !important;
    }
  }

  .ant-collapse-header {
    background-color: var(--background-color);
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background-color: #f9fafb;
  }

  .list-item-content-wrapper {
    padding: ${PADDING.md};
    background-color: ${convertedVariables.backgroundColor};
  }
`

const InputNumberWrapper = styled(InputNumber)`
  width: 100%;
`
