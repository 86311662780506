import { BaseSpace } from 'app/components/common/BaseSpace'
import { ActionComponent } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/ActionComponent'
import { DeleteAppointmentSessionModal, PrintTreatmentReceiptModal, SendTreatmentReceiptEmailModal, UpdateAppointmentSessionModal } from '../../modal'
import DividerLine from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/DividerLine'

export const AppointmentSessionAction: React.FC = () => {
  return (
    <ActionComponent
      content={
        <BaseSpace size={7}>
          <UpdateAppointmentSessionModal />
          <DividerLine />
          <PrintTreatmentReceiptModal />
          <SendTreatmentReceiptEmailModal />
          <DividerLine />
          <DeleteAppointmentSessionModal />
        </BaseSpace>
      }
    />
  )
}
