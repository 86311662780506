/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pagination, moment } from 'parkway-web-common'
import { useCommonHook } from '../common-hook'
import { initialPagination } from './data'
import { requestGetAppointmentManagement } from 'app/api/appointment'
import {
  IAppointmentPayload,
  IParams,
} from 'app/api/appointment/model/appointment'
import { useContext, useState } from 'react'
import { AppointmentContext } from 'app/context/AppointmentContext'
import { scheduleStatusSearchAppointment } from 'app/api/appointment/constant'
import { IAppointment } from '../../AppointmentPage/type'
import { DATE } from 'app/common/constants'
import { APPOINTMENT_VIEW_TYPE } from 'app/common/enum'

export const SearchAppointmentHook = ({ filter }) => {
  const { appointments, setData } = useContext(AppointmentContext)

  const [tableData, setTableData] = useState<{
    data: IAppointment[]
    pagination: Pagination
    loading: boolean
  }>({
    data: appointments,
    pagination: initialPagination,
    loading: false,
  })

  const getData: any = async (pagination: Pagination, params: IParams) => {
    setTableData(prev => ({
      ...prev,
      loading: true,
    }))
    try {
      const pageSize =
        (pagination['pageSize'] || 0) > 10
          ? pagination?.pageSize
          : initialPagination.pageSize

      const response: IAppointmentPayload =
        await requestGetAppointmentManagement({
          ...params,
          page: pagination?.current || 1,
          pageSize,
          startDate: moment(
            params?.startDate || moment().startOf('day'),
          )?.format(DATE.YYYY_MM_DD_HH_MM),
          endDate: moment(params?.endDate || moment().endOf('day')).format(
            DATE.YYYY_MM_DD_HH_MM,
          ),
          view: APPOINTMENT_VIEW_TYPE.LIST,
          scheduleStatus: scheduleStatusSearchAppointment,
        })
      const PageIndex =
        Number(pagination?.pageSize) * (Number(pagination?.current) - 1)
      const data = response?.data?.map((patient, index) => ({
        ...patient,
        key: patient?._id,
        index: index + PageIndex + 1,
        utmSource: patient?.utm_source,
      }))

      setData?.(data)
      setTableData(prev => ({
        ...prev,
        pagination: {
          ...pagination,
          total: response?.metadata?.total,
          pageSize,
        },
        data,
      }))
    } catch (error) {
      setTableData(prev => ({
        ...prev,
        pagination: pagination ?? initialPagination,
        data: [],
      }))
    }
    setTableData(prev => ({
      ...prev,
      loading: false,
    }))
  }

  const { columns } = useCommonHook({
    currentFilter: filter,
    fetch: getData,
  })

  return {
    columns,
    tableData,
    fetch: getData,
    fetchLoading: tableData.loading,
  }
}
