import { EDayOffType } from 'app/api/jobtitle/model/job-title-management'
import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { YesNoArray } from 'app/common/constants'
import { EYesNo } from 'app/common/enum'
import {
  convertToDayOfWeek,
  convertYesNoToString,
  days,
} from 'app/common/helpers'
import { BaseChip } from 'app/components/common/BaseChip'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseTextEditor from 'app/components/common/BaseTextEditor'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import { convertDayOffType } from '../../constant'
import { SelectRoleOfJobTitle } from '../SelectRoleOfJobTitle'
import { useModalHook } from './hook'
import * as S from './styles'
import { IPropsUpdateJobTitle, StatusJobTitleEnum, initValues } from './type'

export const UpdateJobTitle = (props: IPropsUpdateJobTitle) => {
  const {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    jobTitleLevels,
    jobTitles,
    jobTitleGroups,
    roles,
    onChangeRole,
    onRemoveRole,
  } = useModalHook(props)

  return (
    <BaseModalManagement
      isLoading={isLoading}
      typeButtonOpen="update"
      titleKeyI18n={R.strings.update}
      content={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <BaseRow align={'middle'} justify={'space-between'} gutter={[15, 15]}>
            <BaseCol xl={12}>
              <S.FormItem
                name="code"
                label={t(R.strings.job_title_content_id)}
                rules={rules.code}
                required
              >
                <S.FormInput
                  placeholder={t(R.strings.enter_field, {
                    field: t(R.strings.job_title_content_id),
                  })}
                />
              </S.FormItem>
            </BaseCol>
            <BaseCol xl={12}>
              <S.FormItem
                name="name"
                label={t(R.strings.job_title_content_name_create)}
                rules={rules.name}
                required
              >
                <S.FormInput
                  placeholder={t(R.strings.enter_field, {
                    field: t(R.strings.job_title_content_name_create),
                  })}
                />
              </S.FormItem>
            </BaseCol>
          </BaseRow>
          <BaseRow justify={'space-between'} align={'middle'} gutter={[15, 15]}>
            <BaseCol xl={12}>
              <S.FormItem
                label={t(R.strings.unit_content_job_title_management)}
                name="jobTitleIdManagement"
              >
                <S.SelectBaseStyled
                  placeholder={t(
                    R.strings
                      .unit_content_please_select_unit_job_title_management,
                  )}
                  suffixIcon={<SvgTableCloseIcon />}
                  allowClear
                  options={jobTitles?.map(jobTitle => ({
                    label: jobTitle.name,
                    value: jobTitle._id,
                  }))}
                  showSearch
                />
              </S.FormItem>
            </BaseCol>
            <BaseCol xl={12}>
              <S.FormItem
                label={t(R.strings.job_title_level_content_title)}
                name="jobTitleLevelId"
                rules={rules.role}
                required
              >
                <S.SelectBaseStyled
                  placeholder={t(R.strings.please_select_role)}
                  suffixIcon={<SvgTableCloseIcon />}
                  options={jobTitleLevels?.map(jobTitleLevel => ({
                    label: jobTitleLevel.name,
                    value: jobTitleLevel._id,
                  }))}
                />
              </S.FormItem>
            </BaseCol>
          </BaseRow>
          <BaseRow justify={'space-between'} align={'middle'} gutter={[15, 15]}>
            <BaseCol xl={12}>
              <S.FormItem
                label={t(R.strings.job_title_content_group)}
                name="jobTitleGroupId"
                rules={rules.role}
                required
              >
                <S.SelectBaseStyled
                  placeholder={t(
                    R.strings.job_title_content_please_select_job_title_group,
                  )}
                  suffixIcon={<SvgTableCloseIcon />}
                >
                  {jobTitleGroups?.map((item, index) => {
                    return (
                      <Option value={item._id} key={index}>
                        {item.name}
                      </Option>
                    )
                  })}
                </S.SelectBaseStyled>
              </S.FormItem>
            </BaseCol>
            <BaseCol xl={12}>
              <S.FormItem
                label={t(R.strings.job_title_content_hasTimekeeping)}
                name="hasTimekeeping"
                rules={rules.hasTimekeeping}
                required
              >
                <S.SelectBaseStyled
                  placeholder={t(
                    R.strings
                      .unit_content_please_select_unit_job_title_management,
                  )}
                  suffixIcon={<SvgTableCloseIcon />}
                >
                  {YesNoArray?.map((item, index) => {
                    return (
                      <Option value={item} key={index}>
                        {convertYesNoToString(item)}
                      </Option>
                    )
                  })}
                </S.SelectBaseStyled>
              </S.FormItem>
            </BaseCol>
          </BaseRow>
          <S.FormItem
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.hasTimekeeping !== currentValues.hasTimekeeping
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('hasTimekeeping') === EYesNo.yes && (
                <BaseRow gutter={20} align={'top'} justify={'space-between'}>
                  <BaseCol xl={8}>
                    <S.FormItem
                      label={t(R.strings.job_title_content_dayOffType)}
                      name="dayOffType"
                      rules={rules.dayOffType}
                      required
                    >
                      <S.SelectBaseStyled
                        placeholder={t(R.strings.job_title_content_dayOffType)}
                        suffixIcon={<SvgTableCloseIcon />}
                        defaultValue={EDayOffType.Static}
                      >
                        {Object.values(EDayOffType)?.map((type, index) => {
                          return (
                            <Option value={type} key={index}>
                              {convertDayOffType(type as EDayOffType)}
                            </Option>
                          )
                        })}
                      </S.SelectBaseStyled>
                    </S.FormItem>
                  </BaseCol>
                  <BaseCol xl={16}>
                    <S.FormItem
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.dayOffType !== currentValues.dayOffType
                      }
                    >
                      {({ getFieldValue }) =>
                        getFieldValue('dayOffType') === EDayOffType.Static ? (
                          <S.FormItem
                            label=""
                            name="staticDayOffInWeek"
                            rules={rules.dayOffType}
                          >
                            <S.SelectBaseStyled
                              mode="multiple"
                              placeholder={t(
                                R.strings
                                  .job_title_content_please_select_dayOffFixed,
                              )}
                              suffixIcon={<SvgTableCloseIcon />}
                            >
                              {days?.map((day, index) => {
                                return (
                                  <Option value={day} key={index}>
                                    {convertToDayOfWeek(day)}
                                  </Option>
                                )
                              })}
                            </S.SelectBaseStyled>
                          </S.FormItem>
                        ) : (
                          <S.FormItem
                            label=""
                            name="totalDynamicDayOffInWeek"
                            className="his"
                            rules={rules.dayOffType}
                          >
                            <S.FormInputNumber
                              className="his2"
                              min={1}
                              max={7}
                              defaultValue={2}
                              addonAfter={t(R.strings.day)}
                              placeholder={t(
                                R.strings
                                  .job_title_content_please_select_dayOffDynamic,
                              )}
                            />
                          </S.FormItem>
                        )
                      }
                    </S.FormItem>
                  </BaseCol>
                </BaseRow>
              )
            }
          </S.FormItem>
          <S.FormItem
            name={'description'}
            label={t(R.strings.job_title_content_description)}
          >
            <BaseTextEditor placeholder={t(R.strings.description)} />
          </S.FormItem>

          <S.PermissionContainer direction="vertical" size={10}>
            <S.PermissionHead justify={'space-between'} align={'bottom'}>
              <BaseCol>
                <S.PermissionHeadTitle>
                  {t(R.strings.role)}
                </S.PermissionHeadTitle>
              </BaseCol>
              <BaseCol>
                <SelectRoleOfJobTitle onSubmit={onChangeRole} roles={roles} />
              </BaseCol>
            </S.PermissionHead>
            <S.AreaShowPermission gutter={[16, 16]} align={'middle'}>
              {roles?.length ? (
                roles.map((per, index) => {
                  return (
                    <BaseCol key={index}>
                      <BaseChip
                        index={index}
                        name={per?.name}
                        onRemove={onRemoveRole}
                      />
                    </BaseCol>
                  )
                })
              ) : (
                <BaseChip index={0} name={t(R.strings.empty_list)} />
              )}
            </S.AreaShowPermission>
          </S.PermissionContainer>
          <S.FormItem
            label={t(R.strings.status)}
            name="status"
            rules={rules.status}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.please_select_status)}
              suffixIcon={<SvgTableCloseIcon />}
              defaultValue={StatusJobTitleEnum.active}
            >
              {Object.values(StatusJobTitleEnum)?.map((item, index) => {
                return (
                  <Option value={item} key={index}>
                    {t(item)}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.update}
      confirmKeyI18n={R.strings.update}
      handleSubmit={form.submit}
      visible={visible}
      widthModal={900}
    />
  )
}
