import TextArea from 'antd/es/input/TextArea'
import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useTranslation } from 'react-i18next'

export const ProceduresEquipment = () => {
  const { t } = useTranslation()
  return (
    <BaseSpace>
      <BaseText
        children={`${t(R.strings.part_number, { part: 3 })}. ${t(
          R.strings.procedures_equipment,
        )}`}
        fontWeight="semibold"
        fontSize="xs"
      />
      <TextArea placeholder={t(R.strings.enter_content)} rows={3} />
    </BaseSpace>
  )
}
