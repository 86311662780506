import { BaseRow } from 'app/components/common/BaseRow'
import { LeftSideValueTable, RightSideValueTable } from '../../styles'
import BaseText from 'app/components/common/BaseText'
import { BaseSpace } from 'app/components/common/BaseSpace'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { ItemDiagnosis } from '../../components'

export const FunctionLayout = () => {
  return (
    <BaseRow>
      <LeftSideValueTable xl={3} className="child-wrapper">
        <BaseText
          children={'Chức năng (III)'}
          fontWeight="medium"
          fontSize="xs"
        />
      </LeftSideValueTable>
      <RightSideValueTable xl={21} className="child-wrapper">
        <BaseSpace size={16}>
          <BaseText children={'Khớp thái dương hàm'} fontWeight="medium" />
          <BaseRow gutter={10} align="top">
            <BaseCol xl={24}>
              <ItemDiagnosis
                label="Tiếng kêu bất thường"
                stt={1}
                placeholder={R.strings.enter_content}
              />
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={10} align="top">
            <BaseCol xl={24}>
              <ItemDiagnosis
                label="Há / ngậm tối đa"
                stt={2}
                placeholder={R.strings.enter_content}
              />
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={10} align="top">
            <BaseCol xl={24}>
              <ItemDiagnosis
                label="X-ray tiêu lồi"
                stt={3}
                placeholder={R.strings.enter_content}
              />
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      </RightSideValueTable>
    </BaseRow>
  )
}
