import { useTableHook } from './hook'
import { TableWrapper } from './styles'

export const LocationWithOrthodonticAppointment = () => {
  const { columns, tableData, handleTableChange} = useTableHook()
  return (
    <TableWrapper
      columns={columns}
      dataSource={tableData.data}
      loading={tableData.loading}
      pagination={tableData.pagination}
      onChange={handleTableChange}
      scroll={{
        x: true,
      }}
    />
  )
}
