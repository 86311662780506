import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import DebtContractContainer from 'app/containers/Accounting/DebtContract'

const AccountingDebtContractCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>
        {t(R.strings.manage_customer_debt)}
      </PageTitle>
      <DebtContractContainer />
    </>
  )
}
const AccountingDebtContract = memo(
  AccountingDebtContractCpn,
  isEqual,
)

export default AccountingDebtContract
