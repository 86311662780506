import { PaymentOptionEnum, YesNoEnum } from 'app/common/enum'
import {
  ICompanyGetInvoice,
  IOrderDebtPayment,
  IPaymentMethodDebtPayment,
} from '../type/debt-payment'
import { _DEV_ } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'

interface IProps {
  orderDebtPayments: IOrderDebtPayment[]
  onChangeDebtPayment: (newData: IOrderDebtPayment[]) => void
}

export const useDebtPayment = ({
  onChangeDebtPayment,
  orderDebtPayments,
}: IProps) => {
  const { t } = useTranslation()

  const onChangePaymentOption = (
    orderId: string,
    paymentOption: PaymentOptionEnum,
  ) => {
    const newOrders = orderDebtPayments?.map(item => {
      if (item?.orderId === orderId) {
        return {
          ...item,
          paymentOption,
        }
      }
      return item
    })

    onChangeDebtPayment(newOrders)
  }

  const onChangeIssueInvoice = (orderId: string, value: YesNoEnum) => {
    const newOrders = orderDebtPayments?.map(item => {
      if (item?.orderId === orderId) {
        item.issueInvoice = value
      }
      return item
    })

    onChangeDebtPayment(newOrders)
  }

  const onChangeCustomerPayment = (orderId: string, value: number) => {
    const newOrders = orderDebtPayments?.map(item => {
      if (item?.orderId === orderId) {
        item.customerPayment = value
      }
      return item
    })

    onChangeDebtPayment(newOrders)
  }

  const onChangePaymentMethod = (
    orderId: string,
    paymentMethod: IPaymentMethodDebtPayment,
  ) => {
    const newOrders = orderDebtPayments?.map(item => {
      if (item?.orderId === orderId) {
        const oldPaymentMethods = item?.paymentMethods ?? []

        const itemExist = oldPaymentMethods?.find(
          itm => itm?.method === paymentMethod?.method,
        )

        if (!itemExist) {
          item.paymentMethods = oldPaymentMethods?.concat([paymentMethod])
        }

        const newPaymentMethods = oldPaymentMethods?.map(itm => {
          if (itm?.method === paymentMethod.method) {
            itm.amount = paymentMethod.amount
          }
          return itm
        })

        item.paymentMethods = newPaymentMethods
      }
      return item
    })

    onChangeDebtPayment(newOrders)
  }

  const onPaymentOrder = (orderId: string) => {
    const itemOrder = orderDebtPayments?.find(itm => itm?.orderId === orderId)

    if (itemOrder) {
      _DEV_ && console.log({ itemOrder })
      const newOrders = orderDebtPayments?.map(item => {
        if (item?.orderId === orderId) {
          item.isPayment = true
        }
        return item
      })
      onChangeDebtPayment(newOrders)
      notificationController.success({
        message: t(R.strings.success),
      })
      return
    }

    notificationController.error({
      message: 'Payment error',
    })
  }

  const onDeletePaymentMethod = (
    orderId: string,
    paymentMethod: IPaymentMethodDebtPayment,
  ) => {
    const newOrders = orderDebtPayments?.map(item => {
      if (item?.orderId === orderId) {
        const newPaymentMethod = item?.paymentMethods?.filter(
          itm => itm?.method !== paymentMethod.method,
        )
        item.paymentMethods = newPaymentMethod
      }
      return item
    })
    onChangeDebtPayment(newOrders)
  }

  const onChangeCompanyInfoOrderDebtPayment = (
    orderId: string,
    key: keyof ICompanyGetInvoice,
    value: string,
  ) => {
    const newOrders = orderDebtPayments?.map(item => {
      if (item?.orderId === orderId) {
        const company: ICompanyGetInvoice = {
          ...item.company,
          [key]: value,
        }
        item.company = company
      }
      return item
    })
    onChangeDebtPayment(newOrders)
  }

  return {
    onChangePaymentOption,
    onChangePaymentMethod,
    onChangeIssueInvoice,
    onChangeCustomerPayment,
    onPaymentOrder,
    onDeletePaymentMethod,
    onChangeCompanyInfoOrderDebtPayment
  }
}
