import { DefineUnWorkingDayTypeEnum } from 'app/api/hr/constant'
import {
  IDataGetTotalDashboardDayOff,
  IParamsGetApproveDayOff,
  IUnWorkingDays,
  LeaveShiftDayOffEnum,
  ResponseApproveDayOff,
  StatusDayOffEnum,
} from 'app/api/hr/models/approve-day-off'
import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IColumnTable } from 'app/components/tables/BaseTableReport/type'
import { getArray } from 'app/components/tables/common-table/constant'
import { usePagination } from 'app/hook'
import {
  useGetDashboardDayOffList,
  useGetTotalDashboardDayOffList,
} from 'app/react-query/hook/day-off'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import {
  DateUtil,
  FORMAT_DD_MM_YYYY,
  initialPagination,
  moment,
  Pagination,
  ResponseType,
} from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { HeadDayOffTableComponent } from './components'
import { DayOffItem } from './components/DayOffItem'
import { IDataTable, IFilter } from './type'

const getColorUnWorkingDay = ({
  previousUnWorkingDays,
  itemUnWorkingDay,
  unWorkingDaysNotInPrevious,
}: {
  previousUnWorkingDays?: IUnWorkingDays[]
  itemUnWorkingDay?: IUnWorkingDays
  unWorkingDaysNotInPrevious?: IUnWorkingDays[]
}) => {
  const previousUnWorkingDay = previousUnWorkingDays?.find(item => {
    return isEqual(
      DateUtil.formatDDMMYYY(item?.unWorkingDateEnd ?? ''),
      DateUtil.formatDDMMYYY(itemUnWorkingDay?.unWorkingDateEnd ?? ''),
    )
  })

  const itemNotInPrevious = unWorkingDaysNotInPrevious?.find(item => {
    return isEqual(
      DateUtil.formatDDMMYYY(item?.unWorkingDateEnd ?? ''),
      DateUtil.formatDDMMYYY(itemUnWorkingDay?.unWorkingDateEnd ?? ''),
    )
  })

  if (previousUnWorkingDay) {
    console.log({ previousUnWorkingDay })
  }

  switch (itemUnWorkingDay?.status) {
    case StatusDayOffEnum.APPROVED:
      return {
        borderColor: convertedVariables.statesGreenColor,
        backgroundColor: convertedVariables.statesGreenLightColor,
      }
    case StatusDayOffEnum.REJECTED:
      return {
        borderColor: convertedVariables.statesRedColor,
        backgroundColor: convertedVariables.statesRedLightColor,
      }
    case StatusDayOffEnum.WAITING_CANCEL:
    case StatusDayOffEnum.WAITING_BOM_APPROVE:
    case StatusDayOffEnum.WAITING_FOR_BOM_APPROVE_CANCEL:
    case StatusDayOffEnum.WAITING_FOR_BOM_APPROVE_REJECT:
    case StatusDayOffEnum.CREATED:
      if (previousUnWorkingDays?.length) {
        if (!previousUnWorkingDay) {
          return {
            borderColor: '#D45900',
            backgroundColor: '#FFE2CE',
          }
        }
      }
      return {
        borderColor: convertedVariables.statesOrangeColor,
        backgroundColor: convertedVariables.statesOrangeLightColor,
      }
    default:
      if (itemNotInPrevious) {
        return {
          borderColor: '#F2E914',
          backgroundColor: '#FEFFD7',
        }
      }
      return {
        borderColor: undefined,
        backgroundColor: undefined,
      }
  }
}

const DayOffItemOverwrite = ({
  item,
  previousUnWorkingDays,
  unWorkingDaysNotInPrevious,
  typeLayout,
  tag,
}: {
  item: IUnWorkingDays
  previousUnWorkingDays?: IUnWorkingDays[]
  unWorkingDaysNotInPrevious?: IUnWorkingDays[]
  typeLayout: 'full' | 'half'
  tag?: string
}) => {
  const dataColor = useMemo(() => {
    return getColorUnWorkingDay({
      itemUnWorkingDay: item,
      previousUnWorkingDays,
    })
  }, [item, previousUnWorkingDays, unWorkingDaysNotInPrevious])

  return (
    <DayOffItem
      typeLayout={typeLayout}
      tag={tag}
      value={item?.unWorkingDayType ?? ''}
      colorValue={
        DefineUnWorkingDayTypeEnum[item?.unWorkingDayType ?? ''].color
      }
      borderColor={dataColor.borderColor}
      backgroundColor={dataColor.backgroundColor}
    />
  )
}

export const useHook = () => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState<IFilter>({
    year: new Date().getFullYear().toString(),
    month: (new Date().getMonth() + 1).toString(),
  })

  const [pagination, setPagination] = useState<Pagination>(initialPagination)

  const params = useMemo(() => {
    const res: IParamsGetApproveDayOff = {
      startDate: moment(`${filter?.year}-${filter?.month}-01`)
        .locale('vi')
        .startOf('month')
        .toISOString(),
      endDate: moment(`${filter?.year}-${filter?.month}-01`)
        .locale('vi')
        .endOf('month')
        .toISOString(),
      status: [],
    }

    return res
  }, [filter])

  const { flattenDataList } = usePagination()

  const { data: dataTotal, isLoading: isLoadingTotal } =
    useGetTotalDashboardDayOffList(params)

  const { data: dataDayOff, isLoading: isLoadingDayOff } =
    useGetDashboardDayOffList(params)

  const totalStatusList = useMemo(() => {
    const res: ResponseType<IDataGetTotalDashboardDayOff[]> =
      flattenDataList(dataTotal)
    return res?.data
  }, [dataTotal])

  const dataApi: ResponseApproveDayOff = useMemo(() => {
    return flattenDataList(dataDayOff)
  }, [dataDayOff])

  const data = useMemo(() => {
    return dataApi?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [dataApi])

  const columns: IColumnTable<IDataTable>[] = useMemo(() => {
    let options: IColumnTable<IDataTable>[] = []

    const countDayOfMonth = new Date(
      Number(filter.year),
      Number(filter.month),
      0,
    ).getDate()

    const dates = getArray(countDayOfMonth).map(day => {
      const totalData = totalStatusList?.find(itm => isEqual(day, itm?._id))
      const dateString = `${filter.year}-${filter.month}-${day}`
      const dateMoment = moment(dateString).locale('vi')

      return {
        title: (
          <HeadDayOffTableComponent
            dateMoment={dateMoment}
            totalData={totalData}
            isLoadingTotal={isLoadingTotal}
          />
        ),
        dataIndex: `day${day}`,
        key: `day-${day}`,
        className: 'date-column',
        render: (_, record: IDataTable) => {
          const unWorkingDays = record?.unWorkingDays?.filter(item => {
            return isEqual(
              DateUtil.formatDDMMYYY(item?.unWorkingDateEnd ?? ''),
              dateMoment.format(FORMAT_DD_MM_YYYY),
            )
          })

          const previousUnWorkingDays = record?.previousUnWorkingDays

          const unWorkingDaysNotInPrevious = previousUnWorkingDays?.filter(
            item => {
              return !unWorkingDays?.find(prevItem => {
                return isEqual(
                  DateUtil.formatDDMMYYY(prevItem?.unWorkingDateEnd ?? ''),
                  DateUtil.formatDDMMYYY(item?.unWorkingDateEnd ?? ''),
                )
              })
            },
          )

          if (!unWorkingDays?.length) {
            return (
              <ItemWrapper>
                <DayOffItem typeLayout={'full'} value={'W'} />
              </ItemWrapper>
            )
          }

          const itemF = unWorkingDays.find(
            item => item?.unWorkingShift === LeaveShiftDayOffEnum.F,
          )

          if (itemF) {
            return (
              <ItemWrapper>
                <DayOffItemOverwrite
                  typeLayout={'full'}
                  item={itemF}
                  previousUnWorkingDays={previousUnWorkingDays}
                  unWorkingDaysNotInPrevious={unWorkingDaysNotInPrevious}
                />
              </ItemWrapper>
            )
          }

          const itemL = unWorkingDays.find(
            item => item?.unWorkingShift === LeaveShiftDayOffEnum.L,
          )

          const itemE = unWorkingDays.find(
            item => item?.unWorkingShift === LeaveShiftDayOffEnum.E,
          )

          if (itemL && itemE) {
            return (
              <ItemWrapper>
                <DayOffItemOverwrite
                  typeLayout={'half'}
                  tag={'Sáng'}
                  item={itemE}
                  previousUnWorkingDays={previousUnWorkingDays}
                  unWorkingDaysNotInPrevious={unWorkingDaysNotInPrevious}
                />
                <DayOffItemOverwrite
                  typeLayout={'half'}
                  tag={'Chiều'}
                  item={itemL}
                  previousUnWorkingDays={previousUnWorkingDays}
                  unWorkingDaysNotInPrevious={unWorkingDaysNotInPrevious}
                />
              </ItemWrapper>
            )
          }

          if (itemE) {
            return (
              <ItemWrapper>
                <DayOffItemOverwrite
                  typeLayout={'half'}
                  tag={'Sáng'}
                  item={itemE}
                  previousUnWorkingDays={previousUnWorkingDays}
                  unWorkingDaysNotInPrevious={unWorkingDaysNotInPrevious}
                />
                <DayOffItem typeLayout={'half'} value={'W'} />
              </ItemWrapper>
            )
          }

          if (itemL) {
            return (
              <ItemWrapper>
                <DayOffItem typeLayout={'half'} value={'W'} />
                <DayOffItemOverwrite
                  typeLayout={'half'}
                  tag={'Chiều'}
                  item={itemL}
                  previousUnWorkingDays={previousUnWorkingDays}
                  unWorkingDaysNotInPrevious={unWorkingDaysNotInPrevious}
                />
              </ItemWrapper>
            )
          }

          return (
            <ItemWrapper>
              <DayOffItem typeLayout={'full'} value={'W'} />
            </ItemWrapper>
          )
        },
      } as IColumnTable<IDataTable>
    })

    options = [
      convertColumnTable({
        title: t(R.strings.employee),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record: IDataTable) => {
          return (
            <BaseSpace>
              <BaseText
                children={record?.name}
                fontWeight="semibold"
                textAlign="center"
              />
            </BaseSpace>
          )
        },
      }),
      convertColumnTable({
        title: t(R.strings.unit_field_name),
        key: 'workinfos',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record: IDataTable) => {
          return (
            <BaseSpace>
              {record?.workinfos?.map((wif, idx) => {
                return (
                  <BaseText
                    children={wif?.unit?.name}
                    fontWeight="medium"
                    opacity="0.7"
                    textAlign="center"
                    key={idx}
                  />
                )
              })}
            </BaseSpace>
          )
        },
      }),
      {
        title: 'Ngày nghỉ',
        key: 'key',
        children: dates,
      },
    ]

    return options
  }, [filter, totalStatusList, isLoadingTotal])

  const onChangeFilter = (value: IFilter) => {
    setFilter(value)

    setPagination({
      ...pagination,
      current: 1,
    })
  }

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  return {
    columns,
    filter,
    onChangeFilter,
    isLoadingTotal,
    isLoadingDayOff,
    pagination: {
      ...pagination,
      total: dataApi?.metadata?.totalItem ?? 0,
    },
    data: data ?? [],
    handleTableChange,
  }
}

const ItemWrapper = styled.div`
  height: 90px;
  display: flex;
  flex: 1;
  width: 100%;
`
