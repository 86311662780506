import { DiscountTypeEnum } from 'app/api/marketing/discount-campaign/model'

export enum StatusEnum {
  DUPLICATED = 'duplicated',
  WRONG_DISCOUNT_TYPE = 'wrong_discount_type',
  WRONG_DISCOUNT_INPUT = 'wrong_discount_input',
  NOT_FOUND_TREATMENT = 'not_found_treatment',
  SUCCESS = 'success',
}

export interface ITreatmentImport {
  _id?: string
  name?: string
  dType?: DiscountTypeEnum
  discount?: string
  status?: StatusEnum
  rowNumber?: string
  key?: number
  treatmentId?: string
}

export interface IResponseImportFile {
  modify: ITreatmentImport[]
  remove: ITreatmentImport[]
  insert: ITreatmentImport[]
}
