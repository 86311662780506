import { memo } from 'react'
import { isEqual } from 'lodash'
import DashboardBodContainer from 'app/containers/Dashboard/DashboardBod'
import { PageTitle } from 'app/components/common/PageTitle'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

const DashboardBodCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.dashboard_bod)}</PageTitle>
      <DashboardBodContainer />
    </>
  )
}
export const DashboardBodPage = memo(DashboardBodCpn, isEqual)
