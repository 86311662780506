import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import { BORDER_RADIUS, LAYOUT, media } from 'parkway-web-common'
import styled from 'styled-components'
import {
  GeneralInformationLayout,
  HeaderWrapperLayout,
  ListPatientTodayLayout,
  TreatmentInformationLayout,
} from './layouts'

export const LeftSideDoctorLayout = () => {
  return (
    <LeftSideCol xl={18}>
      <BaseSpace>
        <SectionBase>
          <BaseSpace>
            <HeaderWrapperLayout />
            <BaseDividerLine type="horizontal" dashed />

            <BaseRow align={'top'} justify={'space-between'}>
              <BaseCol xl={12}>
                <GeneralInformationLayout />
              </BaseCol>

              <BaseCol xl={12}>
                <TreatmentInformationLayout />
              </BaseCol>
            </BaseRow>
          </BaseSpace>
        </SectionBase>
        <ListPatientTodayLayout />
      </BaseSpace>
    </LeftSideCol>
  )
}

const BaseDividerLine = styled(BaseDivider)`
  margin: 0px;
`
export const LeftSideCol = styled(BaseCol)`
  border-radius: ${BORDER_RADIUS};
  min-width: 740px;
  @media only screen and ${media.xl} {
    height: calc(100vh - ${LAYOUT.desktop.headerHeight} - 10vh);
    overflow: auto;
  }
`
