import { Rule } from 'antd/es/form'
import { IProfile } from 'app/api/profile/model/management'

export interface IFormData {
  firstName?: string
  lastName?: string
  employeeId?: string
  phone?: string
  email?: string
  status?: string
  code?: string
}

export interface IFormRule {
  firstName?: Rule[]
  lastName?: Rule[]
  employeeId?: Rule[]
  phone?: Rule[]
  email?: Rule[]
  status?: Rule[]
  code?: Rule[]
}

export const initValues: IFormData = {
  code: '',
  firstName: '',
  lastName: '',
  employeeId: '',
  phone: '',
  email: '',
}

export enum StatusEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
  deleted = 'deleted',
}

export type StatusAccountType = keyof typeof StatusEnum

export interface IPropsUpdateAccount {
  fetchTable: () => void
  id?: string | number
  profile?: IProfile[]
}
