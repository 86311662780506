import * as S from './styles'

interface IPropsUpdateSkill {
  index: number
  levelSkill?: string
  skill?: string
  color?: string
  showDelete?: boolean
  onRemove?: (key: number) => void
}

export const LevelSkillItem = ({
  index,
  levelSkill,
  skill,
  showDelete = true,
  onRemove,
  color,
}: IPropsUpdateSkill) => {
  return (
    <S.SkillWrapper key={index} align={'middle'} gutter={[10, 10]}>
      <S.LevelTag color={color}>{levelSkill ?? 'unknown'}</S.LevelTag>
      <S.SkillTitle>{skill ?? 'unknown'}</S.SkillTitle>
      {showDelete && (
        <S.RemoveCircleIconStyled onClick={() => onRemove && onRemove(index)} />
      )}
    </S.SkillWrapper>
  )
}
