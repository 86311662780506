import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import SelectProgramsComponent from './SelectPaymentMethod'

export const PaymentMethodLayout = () => {
  const { t } = useTranslation()

  const { purchaseProductService, onAddPaymentMethodTotalInvoice } =
    useProfilePatientContext()

  const paymentMethods = useMemo(() => {
    return purchaseProductService?.totalInvoice?.paymentMethods
  }, [purchaseProductService?.totalInvoice?.paymentMethods])

  const isDisableAddButton = useMemo(() => {
    const itemEmpty = paymentMethods?.findIndex(
      itm => isEmpty(itm?.method) || !itm?.amount,
    )
    if (itemEmpty !== -1) return true

    return false
  }, [purchaseProductService])

  const _onAddPaymentMethod = () => {
    onAddPaymentMethodTotalInvoice?.({
      amount: 0,
      method: undefined,
    })
  }

  return (
    <BaseSpace>
      <BaseText fontSize='xs' fontWeight="semibold" children={t(R.strings.payment_method)} />
      {paymentMethods?.map((item, index) => {
        return <SelectProgramsComponent item={item} index={index} />
      })}
      <BaseButton
        type="link"
        icon={<PlusOutlined rev={undefined} />}
        disabled={isDisableAddButton}
        onClick={_onAddPaymentMethod}
      >
        {t(R.strings.add_payment_method)}
      </BaseButton>
    </BaseSpace>
  )
}
