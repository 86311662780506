import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import styled from 'styled-components'
import { useModalHook } from './hook'
import * as S from './styles'
import { IFormData, IPropsAddJobTitleDoctor } from './type'
import { convertedVariables } from 'app/styles/themes/themeVariables'

export const initValues: IFormData = {}

export const AddJobTitleDoctor = (props: IPropsAddJobTitleDoctor) => {
  const {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    jobTitles,
    jobTitleLevels,
  } = useModalHook(props)

  return (
    <BaseModalManagement
      titleKeyI18n={R.strings.item_management_content_job_title_level_doctor}
      isLoading={isLoading}
      content={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <S.FormItem
            label={t(R.strings.item_management_content_job_title_level_doctor)}
            name="jobTitleId"
            rules={rules.jobTitleId}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(
                R.strings.item_management_content_select_job_title_level,
              )}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {jobTitles.map((item, index) => {
                return (
                  <Option value={item._id} key={index}>
                    {item?.name}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>
          <S.FormItem
            label={t(R.strings.job_title_level_content_title)}
            name="jobTitleLevelId"
            rules={rules.jobTitleLevelId}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(
                R.strings.item_management_content_select_job_title_level,
              )}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {jobTitleLevels.map((item, index) => {
                return (
                  <Option value={item._id} key={index}>
                    {item?.name}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={
        R.strings.item_management_content_job_title_level_doctor
      }
      confirmKeyI18n={R.strings.confirm}
      handleSubmit={form.submit}
      visible={visible}
      typeButtonOpen="update"
      openModalComponent={
        <ButtonModalStyled onClick={showModal} gutter={[8, 8]} align={'middle'}>
          <BaseCol>
            <PlusOutlined rev={undefined} />
          </BaseCol>
          <BaseCol>
            <BaseText
              children={t(
                R.strings.item_management_content_add_job_title_level_doctor,
              )}
              colorText="collapseBackgroundColor"
              fontWeight="medium"
            />
          </BaseCol>
        </ButtonModalStyled>
      }
    />
  )
}

const ButtonModalStyled = styled(BaseRow)`
  cursor: pointer;
  svg {
    fill: ${convertedVariables.collapseBackgroundColor};
  }
`
