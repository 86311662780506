const icons = {
    
    addNew: require("./images/addNew.png"),
    avatar: require("./images/avatar.png"),
    ic_avatar: require("./images/ic_avatar.png"),
    ic_bell: require("./images/ic_bell.png"),
    ic_chevron_down: require("./images/ic_chevron_down.png"),
    ic_connecttion: require("./images/ic_connecttion.png"),
    ic_contract: require("./images/ic_contract.png"),
    ic_copy: require("./images/ic_copy.png"),
    ic_customer: require("./images/ic_customer.png"),
    ic_leading: require("./images/ic_leading.png"),
    ic_logo_parkway: require("./images/ic_logo_parkway.png"),
    ic_logo_parkway_collapsed: require("./images/ic_logo_parkway_collapsed.png"),
    ic_logout: require("./images/ic_logout.png"),
    ic_parkway: require("./images/ic_parkway.png"),
    ic_password: require("./images/ic_password.png"),
    ic_teeth_11: require("./images/ic_teeth_11.png"),
    ic_teeth_11_implant: require("./images/ic_teeth_11_implant.png"),
    ic_teeth_12: require("./images/ic_teeth_12.png"),
    ic_teeth_12_implant: require("./images/ic_teeth_12_implant.png"),
    ic_teeth_13: require("./images/ic_teeth_13.png"),
    ic_teeth_13_implant: require("./images/ic_teeth_13_implant.png"),
    ic_teeth_14: require("./images/ic_teeth_14.png"),
    ic_teeth_14_implant: require("./images/ic_teeth_14_implant.png"),
    ic_teeth_15: require("./images/ic_teeth_15.png"),
    ic_teeth_15_implant: require("./images/ic_teeth_15_implant.png"),
    ic_teeth_16: require("./images/ic_teeth_16.png"),
    ic_teeth_16_implant: require("./images/ic_teeth_16_implant.png"),
    ic_teeth_17: require("./images/ic_teeth_17.png"),
    ic_teeth_17_implant: require("./images/ic_teeth_17_implant.png"),
    ic_teeth_18: require("./images/ic_teeth_18.png"),
    ic_teeth_18_implant: require("./images/ic_teeth_18_implant.png"),
    ic_teeth_21: require("./images/ic_teeth_21.png"),
    ic_teeth_21_implant: require("./images/ic_teeth_21_implant.png"),
    ic_teeth_22: require("./images/ic_teeth_22.png"),
    ic_teeth_22_implant: require("./images/ic_teeth_22_implant.png"),
    ic_teeth_23: require("./images/ic_teeth_23.png"),
    ic_teeth_23_implant: require("./images/ic_teeth_23_implant.png"),
    ic_teeth_24: require("./images/ic_teeth_24.png"),
    ic_teeth_24_implant: require("./images/ic_teeth_24_implant.png"),
    ic_teeth_25: require("./images/ic_teeth_25.png"),
    ic_teeth_25_implant: require("./images/ic_teeth_25_implant.png"),
    ic_teeth_26: require("./images/ic_teeth_26.png"),
    ic_teeth_26_implant: require("./images/ic_teeth_26_implant.png"),
    ic_teeth_27: require("./images/ic_teeth_27.png"),
    ic_teeth_27_implant: require("./images/ic_teeth_27_implant.png"),
    ic_teeth_28: require("./images/ic_teeth_28.png"),
    ic_teeth_28_implant: require("./images/ic_teeth_28_implant.png"),
    ic_teeth_31: require("./images/ic_teeth_31.png"),
    ic_teeth_31_implant: require("./images/ic_teeth_31_implant.png"),
    ic_teeth_32: require("./images/ic_teeth_32.png"),
    ic_teeth_32_implant: require("./images/ic_teeth_32_implant.png"),
    ic_teeth_33: require("./images/ic_teeth_33.png"),
    ic_teeth_33_implant: require("./images/ic_teeth_33_implant.png"),
    ic_teeth_34: require("./images/ic_teeth_34.png"),
    ic_teeth_34_implant: require("./images/ic_teeth_34_implant.png"),
    ic_teeth_35: require("./images/ic_teeth_35.png"),
    ic_teeth_35_implant: require("./images/ic_teeth_35_implant.png"),
    ic_teeth_36: require("./images/ic_teeth_36.png"),
    ic_teeth_36_implant: require("./images/ic_teeth_36_implant.png"),
    ic_teeth_37: require("./images/ic_teeth_37.png"),
    ic_teeth_37_implant: require("./images/ic_teeth_37_implant.png"),
    ic_teeth_38: require("./images/ic_teeth_38.png"),
    ic_teeth_38_implant: require("./images/ic_teeth_38_implant.png"),
    ic_teeth_41: require("./images/ic_teeth_41.png"),
    ic_teeth_41_implant: require("./images/ic_teeth_41_implant.png"),
    ic_teeth_42: require("./images/ic_teeth_42.png"),
    ic_teeth_42_implant: require("./images/ic_teeth_42_implant.png"),
    ic_teeth_43: require("./images/ic_teeth_43.png"),
    ic_teeth_43_implant: require("./images/ic_teeth_43_implant.png"),
    ic_teeth_44: require("./images/ic_teeth_44.png"),
    ic_teeth_44_implant: require("./images/ic_teeth_44_implant.png"),
    ic_teeth_45: require("./images/ic_teeth_45.png"),
    ic_teeth_45_implant: require("./images/ic_teeth_45_implant.png"),
    ic_teeth_46: require("./images/ic_teeth_46.png"),
    ic_teeth_46_implant: require("./images/ic_teeth_46_implant.png"),
    ic_teeth_47: require("./images/ic_teeth_47.png"),
    ic_teeth_47_implant: require("./images/ic_teeth_47_implant.png"),
    ic_teeth_48: require("./images/ic_teeth_48.png"),
    ic_teeth_48_implant: require("./images/ic_teeth_48_implant.png"),
    ic_united_kingdom_flag: require("./images/ic_united_kingdom_flag.png"),
    ic_user: require("./images/ic_user.png"),
    ic_verified: require("./images/ic_verified.png"),
    ic_vietnam_flag: require("./images/ic_vietnam_flag.png"),
    img_bg_auth: require("./images/img_bg_auth.jpg"),
    next: require("./images/next.png"),
    prev: require("./images/prev.png")
    }
export default icons