import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import PromotionOnGoingContainer from 'app/containers/MyDesk/PromotionOnGoing'

const PromotionOnGoingPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.promotion_ongoing)}</PageTitle>
      <PromotionOnGoingContainer />
    </>
  )
}
const PromotionOnGoingPage = memo(PromotionOnGoingPageCpn, isEqual)

export default PromotionOnGoingPage
