import moment from 'moment'
import { FORMAT_CENTER_YYYY_MM_DD } from 'parkway-web-common'
import {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useMemo,
    useState,
} from 'react'
import { IFilterData } from './type'

interface IContextOrthodonticWithoutPaid {
  filterResponse?: IFilterData
  setFilterResponse?: Dispatch<SetStateAction<IFilterData>>
  params?: {
    startDate: string
    endDate: string
  }
}

export const OrthodonticWithoutPaid =
  createContext<IContextOrthodonticWithoutPaid>({})

export const OrthodonticWithoutPaidProvider = ({ children }) => {
  const [filterResponse, setFilterResponse] = useState<IFilterData>({
    from: {
      year: moment().year(),
      quarter: moment().quarter(),
    },
    to: {
      year: moment().year(),
      quarter: moment().quarter(),
    },
  })

  const params = useMemo(() => {
    const startDate = moment()
      .year(filterResponse?.from?.year)
      .quarter(filterResponse?.from?.quarter)
      .startOf('quarter')
      .format(FORMAT_CENTER_YYYY_MM_DD)

    const endDate = moment()
      .year(filterResponse?.to?.year)
      .quarter(filterResponse?.to?.quarter)
      .endOf('quarter')
      .format(FORMAT_CENTER_YYYY_MM_DD)
    return { startDate, endDate }
  }, [filterResponse])

  return (
    <OrthodonticWithoutPaid.Provider
      value={{
        filterResponse,
        setFilterResponse,
        params,
      }}
    >
      {children}
    </OrthodonticWithoutPaid.Provider>
  )
}

export function useOrthodonticWithoutPaid() {
  const context = useContext(OrthodonticWithoutPaid)

  if (context === undefined) {
    throw new Error(
      'useOrthodonticWithoutPaid must be used within a OrthodonticWithoutPaid',
    )
  }
  return context
}
