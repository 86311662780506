import { BaseSpace } from 'app/components/common/BaseSpace'
import { BasicRecord } from '../BasicRecord'
import {
  IItemTreatmentCollapse,
  ItemTreatmentCollapse,
} from '../ItemTreatmentCollapse'
import { DateUtil } from 'parkway-web-common'

export interface IPatientAction {
  name?: string
  listTreatment?: IItemTreatmentCollapse[]
  time?: string
}

export const PatientAction = ({ item }: { item: IPatientAction }) => {
  return (
    <BasicRecord
      labelKeyI18n={item?.name ?? '-'}
      timeString={
        item?.time ? DateUtil.formatDate(item?.time ?? '', 'HH:mm') : undefined
      }
      content={
        <BaseSpace>
          {item?.listTreatment?.map((item, idx) => {
            return (
              <ItemTreatmentCollapse item={item} index={idx + 1} key={idx} />
            )
          })}
        </BaseSpace>
      }
    />
  )
}
