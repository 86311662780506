import { useAppSelector } from 'app/redux/hooks'
import { isEqual } from 'lodash'
import { useMemo } from 'react'
import {
  ModulePermissionEnum,
  FunctionPermissionEnum,
} from './permission-module'
import R from 'app/assets/R'
import {
  DASHBOARD_BOD_PATH,
  DASHBOARD_CONSULTATION_PATH,
  DASHBOARD_DOCTOR_PATH,
  DASHBOARD_NURSE_PATH,
} from 'app/components/router/route-path'
import { SidebarNavigationItem } from 'app/components/layouts/main/sider/sidebar-navigation'

export const useVerifyPermission = () => {
  const userData = useAppSelector(state => state.profileSlice).profile

  const modules = useMemo(() => {
    return userData?.roles ?? []
  }, [userData])

  const isHavePermissionModule = (
    moduleName: ModulePermissionEnum,
  ): boolean => {
    if (!modules?.length) return false

    const modulesExistIndex = modules?.findIndex(itm =>
      isEqual(itm?.moduleName, moduleName),
    )
    if (modulesExistIndex === -1) return false
    return true
  }

  const isHavePermissionFunction = (
    moduleName: ModulePermissionEnum,
    permission: FunctionPermissionEnum,
  ): boolean => {
    if (!modules?.length) return false
    const modulesExist = modules?.find(
      itm =>
        isEqual(itm?.moduleName, moduleName) &&
        !!itm?.permissions?.find(itmPer => isEqual(itmPer, permission)),
    )

    if (!modulesExist) return false

    return true
  }

  const listChildDashboard = useMemo(() => {
    const permissionModules = userData?.roles?.filter(item =>
      isEqual(item?.moduleName, ModulePermissionEnum.DASHBOARD),
    )

    let listPermissionFunc: FunctionPermissionEnum[] = []

    permissionModules?.forEach(item => {
      listPermissionFunc = listPermissionFunc?.concat(item?.permissions ?? [])
    })

    let listChildDashboard: SidebarNavigationItem[] = []

    if (
      listPermissionFunc?.find(itm =>
        isEqual(itm, FunctionPermissionEnum.DASHBOARD_READ_BOD),
      )
    ) {
      listChildDashboard = listChildDashboard?.concat([
        {
          title: R.strings.dashboard_bod,
          key: R.strings.dashboard_bod,
          url: DASHBOARD_BOD_PATH,
          modulePermission: ModulePermissionEnum.DASHBOARD,
          moduleFunctionPermission: FunctionPermissionEnum.DASHBOARD_READ_BOD,
        },
      ])
    }

    if (
      listPermissionFunc?.find(itm =>
        isEqual(itm, FunctionPermissionEnum.DASHBOARD_READ_DOCTOR),
      )
    ) {
      listChildDashboard = listChildDashboard?.concat([
        {
          title: R.strings.dashboard_doctor,
          key: R.strings.dashboard_doctor,
          url: DASHBOARD_DOCTOR_PATH,
          modulePermission: ModulePermissionEnum.DASHBOARD,
          moduleFunctionPermission:
            FunctionPermissionEnum.DASHBOARD_READ_DOCTOR,
        },
      ])
    }

    if (
      listPermissionFunc?.find(itm =>
        isEqual(itm, FunctionPermissionEnum.DASHBOARD_READ_NURSE),
      )
    ) {
      listChildDashboard = listChildDashboard?.concat([
        {
          title: R.strings.dashboard_nurse,
          key: R.strings.dashboard_nurse,
          url: DASHBOARD_NURSE_PATH,
          modulePermission: ModulePermissionEnum.DASHBOARD,
          moduleFunctionPermission: FunctionPermissionEnum.DASHBOARD_READ_NURSE,
        },
      ])
    }

    if (
      listPermissionFunc?.find(itm =>
        isEqual(itm, FunctionPermissionEnum.DASHBOARD_READ_CONSULTATION),
      )
    ) {
      listChildDashboard = listChildDashboard?.concat([
        {
          title: R.strings.dashboard_consultation,
          key: R.strings.dashboard_consultation,
          url: DASHBOARD_CONSULTATION_PATH,
          modulePermission: ModulePermissionEnum.DASHBOARD,
          moduleFunctionPermission:
            FunctionPermissionEnum.DASHBOARD_READ_CONSULTATION,
        },
      ])
    }

    return listChildDashboard
  }, [userData])

  return {
    isHavePermissionFunction,
    isHavePermissionModule,
    userData,
    listChildDashboard,
  }
}
