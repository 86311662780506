import { List, Skeleton } from 'antd'
import { JobTitleContext } from 'app/context/JobTitleContext'
import { PageAppointmentContext } from 'app/context/PageAppointmentContext'
import { ProfileContext } from 'app/context/ProfileContext'
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import seletedIcon from './../../../../../assets/svg/seletecdIcon.svg'
import { IDoctor } from './type'
import { DoctorAvatar } from '../styles'
interface IDoctorList {
  searchDoctor: string
  setTotal: Dispatch<SetStateAction<number>>
}
const DoctorList: React.FC<IDoctorList> = props => {
  const [doctorPage, setDoctorPage] = useState(0)
  const [selected, setSelected] = useState({ email: '' })

  const { fetchData: fetchJobTitle, jobTitles } = useContext(JobTitleContext)
  
  const {
    fetchMoreData,
    fetchData: fetchDoctors,
    profiles,
    total,
  } = useContext(ProfileContext)
  const { searchDoctor, setTotal } = props

  const { setDoctorSelected, filter, setFilter } = useContext(
    PageAppointmentContext,
  )

  useEffect(() => {
    if (!jobTitles.length) fetchJobTitle?.({ keyword: 'doctor' })
  }, [])

  useEffect(() => {
    setTotal(total)
  }, [total])

  useEffect(() => {
    if (jobTitles.length)
      fetchMoreData?.({
        page: doctorPage,
        pagesize: 20,
        sort: '-1',
        group: 'doctor',
      })
  }, [jobTitles])

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchDoctors?.({
        page: 0,
        pageSize: 20,
        sort: '-1',
        group: 'doctor',
        keyword: searchDoctor,
        unitId: filter?.clinicId,
      })
    }, 500)
    setDoctorPage(0)
    return () => clearTimeout(timer)
  }, [searchDoctor, filter?.clinicId])

  const loadMoreData = () => {
    fetchMoreData?.({
      page: doctorPage + 1,
      pagesize: 20,
      sort: '-1',
      group: 'doctor',
    })
    setDoctorPage(doctorPage + 1)
  }

  const handleSelected = doctor => {
    setSelected(doctor)
    setDoctorSelected?.(doctor)
    setFilter?.({ ...filter, doctorId: doctor?._id })
  }

  return (
    <div className="scrollabelDiv" id="scrollabelDiv">
      <InfiniteScroll
        dataLength={doctorPage * 20}
        next={loadMoreData}
        hasMore={profiles.length < total}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        scrollableTarget="scrollabelDiv"
      >
        <List
          dataSource={profiles}
          renderItem={(item: IDoctor) => {
            return (
              <div
                onClick={() => handleSelected(item)}
                className={`list-item ${
                  item?.email === selected?.email && 'selected'
                }`}
              >
                <List.Item key={item.email}>
                  <List.Item.Meta
                    avatar={<DoctorAvatar src={item?.avatar} alt={item?.name} />}
                    title={item.name}
                    description={item?.jobTitleName?.replaceAll(',', ' &')}
                  />
                </List.Item>
                <img src={seletedIcon} className="selected" />
              </div>
            )
          }}
        />
      </InfiniteScroll>
    </div>
  )
}

export default DoctorList
