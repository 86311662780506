import { isEqual } from 'lodash'
import { getRandomUuid } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { BuyOneGetOneItem, ITreatmentInTable } from '../type'
import {
  DiscountTypeEnum,
  IDataUpdateCampaign,
} from 'app/api/marketing/discount-campaign/model'
import { IResponseImportBuyOneGetOne } from '../RootLayout/Contents/layouts/BuyOneGetOneTypeLayout/ImportModal/type'

export const useBuyOneGetOneHook = () => {
  const [initListBuyOneGetOne, setInitListBuyOneGetOne] = useState<
    BuyOneGetOneItem[]
  >([])
  const [listBuyOneGetOne, setListBuyOneGetOne] = useState<BuyOneGetOneItem[]>(
    [],
  )

  const [updateListBuyOneGetOne, setUpdateListBuyOneGetOne] = useState<
    BuyOneGetOneItem[]
  >([])
  const [deleteListBuyOneGetOne, setDeleteListBuyOneGetOne] = useState<
    BuyOneGetOneItem[]
  >([])
  const [addListBuyOneGetOne, setAddListBuyOneGetOne] = useState<
    BuyOneGetOneItem[]
  >([])

  const onAddNewBuyOneGetOne = () => {
    const newItem: BuyOneGetOneItem = {
      id: getRandomUuid(),
      name: undefined,
      selectedTreatment: [],
      discountTreatment: [],
    }

    setListBuyOneGetOne([...listBuyOneGetOne, newItem])
    setAddListBuyOneGetOne([...addListBuyOneGetOne, newItem])
  }

  const initBuyOneGetOne = (data: BuyOneGetOneItem[]) => {
    setListBuyOneGetOne(data)
    setInitListBuyOneGetOne(data)
    setAddListBuyOneGetOne([])
    setUpdateListBuyOneGetOne([])
    setDeleteListBuyOneGetOne([])
  }

  const onChangeUpdateListBuyOneGetOne = (data: BuyOneGetOneItem) => {
    if (initListBuyOneGetOne.find(item => isEqual(item?.id, data?.id))) {
      if (updateListBuyOneGetOne.find(item => isEqual(item?.id, data?.id))) {
        const newUpdateList = updateListBuyOneGetOne.map(item => {
          if (isEqual(item?.id, data?.id)) {
            return data
          }
          return item
        })
        setUpdateListBuyOneGetOne(newUpdateList)
      } else {
        setUpdateListBuyOneGetOne([...updateListBuyOneGetOne, data])
      }
    }
  }

  const onChangeAddListBuyOneGetOne = (data: BuyOneGetOneItem) => {
    if (initListBuyOneGetOne.find(item => isEqual(item?.id, data?.id))) {
      return
    }

    const newAddList = addListBuyOneGetOne.map(item => {
      if (isEqual(item?.id, data?.id)) {
        return data
      }
      return item
    })

    setAddListBuyOneGetOne(newAddList)
  }

  const onChangeListBuyOneGetOne = (data: BuyOneGetOneItem) => {
    const newList = listBuyOneGetOne.map(item => {
      if (isEqual(item?.id, data?.id)) {
        return data
      }
      return item
    })
    setListBuyOneGetOne(newList)
  }

  const onChangeNameByIndex = (index: number, name: string) => {
    const itemExist = listBuyOneGetOne.find((_, i) => isEqual(i, index))

    if (!itemExist) return

    const newItem = {
      ...itemExist,
      name,
    }

    onChangeUpdateListBuyOneGetOne(newItem)

    onChangeAddListBuyOneGetOne(newItem)

    onChangeListBuyOneGetOne(newItem)
  }

  const onRemoveBuyOneGetOneByIndex = (index: number) => {
    const itemExist = listBuyOneGetOne.find((_, i) => isEqual(i, index))

    if (!itemExist) return

    const item = initListBuyOneGetOne.find(itm =>
      isEqual(itemExist.id, itm?.id),
    )

    if (item) {
      setDeleteListBuyOneGetOne([...deleteListBuyOneGetOne, item])
    }

    const newAddList = addListBuyOneGetOne.filter(
      itm => !isEqual(itemExist.id, itm?.id),
    )
    setAddListBuyOneGetOne(newAddList)

    const newUpdateList = updateListBuyOneGetOne.filter(
      itm => !isEqual(itemExist.id, itm?.id),
    )

    setUpdateListBuyOneGetOne(newUpdateList)

    const newList = listBuyOneGetOne.filter(
      itm => !isEqual(itm.id, itemExist.id),
    )
    setListBuyOneGetOne(newList)
  }

  const onChangeItemByIndex = (index: number, item: BuyOneGetOneItem) => {
    const itemExist = listBuyOneGetOne.find((_, i) => isEqual(i, index))

    if (!itemExist) return

    const newItem = {
      ...itemExist,
      ...item,
    }

    onChangeUpdateListBuyOneGetOne(newItem)

    onChangeAddListBuyOneGetOne(newItem)

    onChangeListBuyOneGetOne(newItem)
  }

  const onChangeDiscountValueInDiscountTreatment = (
    index: number,
    treatmentIndex: number,
    discountValue: number,
  ) => {
    const itemExist = listBuyOneGetOne.find((_, i) => isEqual(i, index))

    if (!itemExist) return

    const newItem = {
      ...itemExist,
      discountTreatment: itemExist?.discountTreatment?.map((itm, idx) => {
        if (isEqual(idx, treatmentIndex)) {
          return {
            ...itm,
            discountValue,
          }
        }
        return itm
      }),
    }

    onChangeUpdateListBuyOneGetOne(newItem)

    onChangeAddListBuyOneGetOne(newItem)

    onChangeListBuyOneGetOne(newItem)
  }

  const onChangeDiscountTypeInDiscountTreatment = (
    index: number,
    treatmentIndex: number,
    discountType: DiscountTypeEnum,
  ) => {
    const itemExist = listBuyOneGetOne.find((_, i) => isEqual(i, index))

    if (!itemExist) return

    const newItem = {
      ...itemExist,
      discountTreatment: itemExist?.discountTreatment?.map((itm, idx) => {
        if (isEqual(idx, treatmentIndex)) {
          return {
            ...itm,
            discountType,
          }
        }
        return itm
      }),
    }

    onChangeUpdateListBuyOneGetOne(newItem)
    onChangeAddListBuyOneGetOne(newItem)
    onChangeListBuyOneGetOne(newItem)
  }

  const dataUpdateBuyOneGetOne: IDataUpdateCampaign = useMemo(() => {
    const payload: IDataUpdateCampaign = {
      push: addListBuyOneGetOne.map(item => {
        return {
          name: item.name,
          buyTreatment: item?.selectedTreatment?.map(itm => {
            return {
              treatment: itm?._id,
              amount: itm?.quantity,
              dType: itm?.discountType,
              discount: itm?.discountValue,
            }
          }),
          discountTreatment: item?.discountTreatment?.map(itm => {
            return {
              treatment: itm?._id,
              amount: itm?.quantity,
              dType: itm?.discountType,
              discount: itm?.discountValue,
            }
          }),
        }
      }),
    }
    return payload
  }, [updateListBuyOneGetOne, deleteListBuyOneGetOne, addListBuyOneGetOne])

  const handleImportBuyOneGetOne = (data: IResponseImportBuyOneGetOne) => {
    const listAdd = data?.insert?.map(item => {
      return {
        id: item?._id ?? getRandomUuid(),
        name: item?.setName,
        selectedTreatment: item?.buyTreatment?.map(itm => ({
          ...itm,
          _id: itm?._id,
          name: itm?.name,
          discountValue: itm?.discount,
          discountType: itm?.dType,
          quantity: itm?.amount,
          regionalPrice: itm?.regionalPrice,
        })) as ITreatmentInTable[],
        discountTreatment: item?.discountTreatment?.map(itm => ({
          ...itm,
          _id: itm?._id,
          name: itm?.name,
          discountValue: itm?.discount,
          discountType: itm?.dType,
          quantity: itm?.amount,
          regionalPrice: itm?.regionalPrice,
        })) as ITreatmentInTable[],
      }
    })

    const newListService = initListBuyOneGetOne.concat(listAdd)
    setListBuyOneGetOne(newListService)
    setAddListBuyOneGetOne(listAdd)
  }

  return {
    onAddNewBuyOneGetOne,
    listBuyOneGetOne,
    onRemoveBuyOneGetOneByIndex,
    onChangeNameByIndex,
    onChangeDiscountTypeInDiscountTreatment,
    onChangeDiscountValueInDiscountTreatment,
    onChangeItemByIndex,
    initBuyOneGetOne,
    handleImportBuyOneGetOne,
    initListBuyOneGetOne,
    updateListBuyOneGetOne,
    deleteListBuyOneGetOne,
    addListBuyOneGetOne,
    dataUpdateBuyOneGetOne,
  }
}
