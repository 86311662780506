import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseManagementWrapper } from 'app/components/tables/BaseTableManagement/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { HeaderPage } from './components'
import { useHookTable } from './hook'
import { FilterLayout } from './layouts'
import { BaseTable } from './styles'

export const LimitDiscountManagementContainer = () => {
  const { t } = useTranslation()
  const {
    data,
    columns,
    handleTableChange,
    filter,
    handleChangeFilter,
    isLoading,
    pagination,
    onClickDownloadFileExample,
  } = useHookTable()

  return (
    <BaseManagementWrapper>
      <HeaderPage
        title={t(R.strings.limit_discount_management)}
        isImport
        onClickDownloadFileExample={onClickDownloadFileExample}
      />
      <RootWrapper size={8}>
        <FilterLayout formData={filter} onChange={handleChangeFilter} />
        <BaseTable
          columns={columns}
          dataSource={data}
          pagination={pagination}
          loading={isLoading}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
          scroll={{ x: true }}
        />
      </RootWrapper>
    </BaseManagementWrapper>
  )
}

const RootWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
`
