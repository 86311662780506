import styled from 'styled-components'

export const EventWrapper = styled.div<{
  styleEvent: {
    border: string
    background: string
    top: number
    width: number
    height: number
    left: number
  }
}>`
  padding: 8px;
  border: initial !important;
  border-left: 2px solid ${props => props.styleEvent.border} !important;
  color: var(--main-color-text);
  background: ${props => props.styleEvent.background};
  position: absolute;
  top: ${props => props.styleEvent.top}%;
  width: ${props => props.styleEvent.width}%;
  height: ${props => (props.styleEvent.height || 0) - 0.02}%;
  left: ${props => props.styleEvent.left}%;
  border-radius: initial;
  z-index: 3;
`

export const EventBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

export const EventBoxItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
`

export const EventBoxItemBottomBox = styled.div`
  width: 100%;
  /* padding-bottom: 5px;
  position: relative;
  bottom: 5px; */
`

export const EventBoxItemBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;
`

export const EventBoxItemTitleWrapper = styled.div`
`
export const EventBoxItemTitle = styled.div<{
  isConflictNext: boolean
  isConflictPrev: boolean
}>`
  color: var(--main-color-text);
  font-size: 10px;
  line-height: 14px;
  opacity: 70%;
  ${props =>
    props?.isConflictNext || props?.isConflictPrev
      ? `
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100px;
      white-space: nowrap;
      `
      : ''};
`
export const EventBoxItemCategoryService = styled.div<{
  isConflictNext: boolean
  isConflictPrev: boolean
}>`
  color: var(--main-color-text);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 2px;
  ${props =>
    props?.isConflictNext || props?.isConflictPrev
      ? `
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100px;
      white-space: nowrap;
      `
      : ''};
`

export const EventBoxItemTime = styled.div`
  font-size: 10px;
  opacity: 70%;
`

export const DropdownEdit = styled.div`
  display: flex;
  justify-content: left;
`

export const DropdownTrash = styled.div`
  display: flex;
  justify-content: left;
`
export const DropdownActionUpdateTitle = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--collapse-background-color);
  padding-left: 2px;
`

export const DropdownActionDeleteTitle = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--states-red-color);
  padding-left: 2px;
`
export const EventContainer = styled.div<{
  isConflictNext: boolean
  isConflictPrev: boolean
}>`
  .rbc-event {
    width: ${props =>
      props?.isConflictNext || props?.isConflictPrev ? 50 : 100}%;
    margin-left: ${props => (props?.isConflictPrev ? 50 : 0)}% !important;
  }
`

export const EventBlock = styled.div<{
  styleEvent: {
    border: string
    background: string
    top: number
    width: number
    height: number
    left: number
  }
}>`
  width: 100%;
  background: rgb(244, 246, 249);
  z-index: 1;
  border: initial !important;
  color: var(--main-color-text);
  position: absolute;
  top: ${props => props.styleEvent.top}%;
  height: ${props => (props.styleEvent.height || 0) - 0.02}%;
  left: ${props => props.styleEvent.left}%;
  border-radius: initial;
  z-index: 2;
`
export const AddNewHoverBox = styled.div<{
  isConflictNext: boolean
  isConflictPrev: boolean
}>`
  &:hover .rbc-event {
    ${props =>
      !props?.isConflictNext && !props?.isConflictPrev
        ? 'width: 80%; transition: width 0.5s;'
        : ''}
  }
  &:hover .add-new {
    ${props =>
      !props?.isConflictNext && !props?.isConflictPrev ? 'display: flex;' : ''}
  }
`
export const AddNew = styled.div<{
  styleEvent: {
    border: string
    background: string
    top: number
    width: number
    height: number
    left: number
  }
}>`
  display: none;
  background: rgb(244, 246, 249);
  z-index: 1;
  color: var(--main-color-text);
  position: absolute;
  top: ${props => props.styleEvent.top}%;
  height: ${props => (props.styleEvent.height || 0) - 0.02}%;
  border-radius: initial;
  z-index: 2;
  width: 20%;
  left: 80%;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  border: 1px dotted #5585f4;
  cursor: pointer;
`
export const WaitingBox = styled.div`
  display: flex;
  margin-bottom: 2px;
  margin-bottom: 1px;
`

export const WaitingText = styled.div`
font-family: Inter;
font-size: 10px;
font-weight: 400;
line-height: 14px;
letter-spacing: 0em;
text-align: left;
color: var(--main-color-text);
`

export const WaitingTime = styled.div`
font-family: Inter;
font-size: 10px;
font-weight: 500;
line-height: 14px;
letter-spacing: 0em;
text-align: left;
color: var(--states-red-color);
`
