import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

import {
  CustomTableManagement,
  OrderInfoContent,
  OrderInfoHeader,
  OrderInfoTitle,
  TotalPage,
} from './styles'
import { StatusTableEnum } from 'app/common/enum'
import { useHook } from './hook'
import {
  SvgDownloadIcon,
  SvgFileIcon,
  SvgFileImportWhiteIcon,
} from 'app/assets/svg-assets'
import { useContext, useMemo, useState } from 'react'
import { Flex, Upload } from 'antd'
import { BaseButton } from 'app/components/common/BaseButton'
import { ButtonCreateAction } from 'app/components/tables/BaseTableManagement/styles'
import { SearchDropdown } from '../search-dropdown'
import { notificationController } from 'app/controllers/notification-controller'
import { UploadProps } from 'antd/lib'
import { GoodsIssueReceiptContext } from '../../context'

export type StatusGoodsIssueReceiptType = keyof typeof StatusTableEnum

const OrderInfo = () => {
  const [importLoading, setImportLoading] = useState(false)
  const { columns, downloadTemplate, upload } = useHook()
  const [isDownloading, setIsDownloading] = useState(false)
  const [isOverlayOpen, setOverlayOpen] = useState(false)
  const [search, setSearch] = useState('')

  const { orders, warehouseGoodsIssueReceiptDetail, goodsIssues, setOrders } =
    useContext(GoodsIssueReceiptContext)
  const { t } = useTranslation()
  const tableData = orders?.map((order, index) => ({
    ...order,
    ordinalNumber: index + 1,
  }))

  const handleDownloadTemplate = async () => {
    setIsDownloading(true)
    await downloadTemplate(warehouseGoodsIssueReceiptDetail?._id)
    setIsDownloading(false)
  }

  const handleUpload = res => {
    const importOrders = (res?.data || [])?.map(order => ({
      ...order,
      name: order?.product?.name,
      code: order?.product?.code,
      purchaseMeasurementUnit: order?.measurementUnit?.name,
    }))
    const importOrderIds = importOrders?.map(order => order?._id) || []
    const orderIds = orders?.map(order => order?._id) || []
    if (
      orderIds?.length &&
      orderIds.every(orderId => importOrderIds?.includes(orderId))
    ) {
      notificationController.error({
        message: t(R.strings.import_error),
        description: t(R.strings.goods_existed),
      })
    } else {
      setOrders?.(prev => [...prev, ...importOrders])
    }
  }

  const uploadProps: UploadProps = {
    showUploadList: false,
    customRequest: options =>
      upload(options, warehouseGoodsIssueReceiptDetail?._id, handleUpload),
    onChange(info) {
      if (info.file.status === 'uploading') {
        setImportLoading(true)
        return
      }

      if (info.file.status === 'done') {
        console.log('info', info)
        setImportLoading(false)
      } else if (info.file.status === 'error') {
        setImportLoading(false)
        notificationController.error({
          message: t(R.strings.import_error),
        })
      }
    },
  }

  const newOrders = useMemo(() => {
    return tableData?.filter(
      order => order?.code?.includes(search) || order?.name?.includes(search),
    )
  }, [search, tableData])

  return (
    <>
      <OrderInfoHeader>
        <Flex justify="space-between" align="center">
          <OrderInfoTitle>{t(R.strings.warehouse_goods_issue_delivering_info)}</OrderInfoTitle>

          <Flex justify="space-between" align="center" gap={10}>
            <div>
              <SearchDropdown
                query={search ?? ''}
                setQuery={value => setSearch(value)}
                data={[]}
                isOverlayOpen={isOverlayOpen}
                setOverlayOpen={setOverlayOpen}
              />
            </div>
            <div>
              <BaseButton
                icon={<SvgFileIcon />}
                onClick={() => setOrders?.(goodsIssues || [])}
              >
                {t(R.strings.import_from_purchase_order)}
              </BaseButton>
            </div>
            {!!warehouseGoodsIssueReceiptDetail?._id && (
              <div onClick={() => handleDownloadTemplate()}>
                <BaseButton icon={<SvgDownloadIcon />} loading={isDownloading}>
                  {t(R.strings.sample_file)}
                </BaseButton>
              </div>
            )}

            <div>
              <Upload {...uploadProps}>
                <ButtonCreateAction
                  icon={<SvgFileImportWhiteIcon />}
                  loading={importLoading}
                >
                  {t(R.strings.import)}
                </ButtonCreateAction>
              </Upload>
            </div>
          </Flex>
        </Flex>
      </OrderInfoHeader>

      <OrderInfoContent>
        <CustomTableManagement
          columns={columns}
          dataSource={newOrders}
          pagination={{
            pageSize: 100,
            total: newOrders?.length,
            showTotal: total => {
              return (
                <TotalPage>
                  {t(R.strings.total_page, {
                    total,
                  })}
                </TotalPage>
              )
            },
          }}
          expandable={{
            expandIcon: () => null,
          }}
        />
      </OrderInfoContent>
    </>
  )
}

export default OrderInfo
