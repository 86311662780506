/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestExportExcelReport04, requestGetReport04 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import {
  IInfoService,
  IParamsExportReport04,
  IParamsGetReport04,
  IResponseGetReport04,
} from 'app/api/report/model/report-04'
import R from 'app/assets/R'
import { getStartEndDateOfWeek } from 'app/common/helpers'
import {
  ExpandedTableValue,
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import {
  IBaseColumnReport,
  IOnCell,
} from 'app/components/tables/BaseTableReport/type'
import { randomMoney } from 'app/components/tables/common-table/constant'
import { _DEV_ } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { PERCENT_PREFIX, Pagination, ResponseType } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataReport004, IFilterReport04 } from './common-model'
import { totalKeyReport04 } from './constant'

export const useCommonHook = ({
  expandedRowKeys,
  handleExpand,
  currentFilter,
  dataTable,
  expandedAreaKeys,
  handleExpandArea,
  fetchLoading,
}: {
  expandedRowKeys: number[]
  fetchLoading: (value: boolean) => void
  fetch: (pagination: Pagination, params?: IFilterReport04) => void
  handleExpand: (isExpanded: boolean, key?: number) => void
  currentFilter?: IFilterReport04
  dataTable: IDataReport004[]
  expandedAreaKeys: number[]
  handleExpandArea?: (isExpanded: boolean, key?: number) => void
}) => {
  const { t } = useTranslation()

  const exportExcel = async () => {
    try {
      let currentParams: IParamsExportReport04 = {
        year: currentFilter?.year,
        serviceName: currentFilter?.service ?? totalKeyReport04,
      }

      const type = currentFilter?.selectFilterType

      switch (type) {
        case 'WEEK': {
          const weekOfMonth = getStartEndDateOfWeek({
            date: currentFilter?.week?.startDate ?? '01',
            month: currentFilter?.month ?? '',
            year: currentFilter?.year ?? '',
          })

          currentParams = {
            ...currentParams,
            week: weekOfMonth?.noCurrentWeekOfMonth.toString(),
            month: currentFilter?.month,
          }

          break
        }
        case 'MONTH': {
          currentParams = {
            ...currentParams,
            month: currentFilter?.month,
          }

          break
        }
        case 'QUARTER': {
          currentParams = {
            ...currentParams,
            quarter: currentFilter?.quarter?.toString(),
          }

          break
        }
      }

      fetchLoading(true)
      const res: ResponseType<{ url: string }> =
        await requestExportExcelReport04(currentParams)

      if (res?.msgcode === ReportMessageCode.Report04.successGetData) {
        window.open(res?.data?.url ?? '')
        notificationController.success({
          message: t(R.strings.download_excel_success),
        })
      }
    } catch (error: any) {
      console.log(error)
      notificationController.error(error)
    } finally {
      fetchLoading(false)
    }
  }

  const columns = useMemo(() => {
    type TypeColumn = IDataReport004

    const onCellArea = (record: IDataReport004): IOnCell => {
      if (record?.isTotalTable && expandedRowKeys.length) {
        if (expandedRowKeys?.length > expandedAreaKeys?.length) {
          return {
            colSpan: 3,
          }
        }
        return {
          colSpan: 2,
        }
      }

      if (record?.isTotalTable && !expandedRowKeys.length) return { colSpan: 1 }

      if (record.isArea && record?.children) {
        const isExpandedArea = expandedRowKeys.includes(record.key ?? 0)
        if (!isExpandedArea && expandedAreaKeys?.length) {
          if (expandedRowKeys.length > expandedAreaKeys.length) {
            return {
              rowSpan: 1,
              colSpan: 3,
            }
          }

          return {
            rowSpan: 1,
            colSpan: 2,
          }
        }

        if (!expandedAreaKeys?.length) {
          return { rowSpan: 1 }
        }

        let count = (record?.children?.length ?? 0) + 1
        record?.children?.forEach(item => {
          const isExpanded = expandedRowKeys.includes(item.key ?? 0)
          if (isExpanded && item?.children) {
            count += item?.children?.length
          }
        })

        return {
          rowSpan: count,
        }
      }

      return { rowSpan: 0 }
    }

    const onCellClinic = (record: IDataReport004): IOnCell => {
      if (record?.isTotalTable) {
        return { colSpan: 0 }
      }

      if (record?.isArea) {
        const isExpandedArea = expandedRowKeys.includes(record.key ?? 0)
        if (!isExpandedArea && expandedAreaKeys?.length) {
          return { colSpan: 0 }
        }

        const isExpanded = expandedRowKeys.includes(record.childrenKey ?? 0)
        if (!isExpanded && record?.children) {
          if (expandedRowKeys.length > expandedAreaKeys.length) {
            return { rowSpan: 1, colSpan: 2 }
          }
          return { rowSpan: 1 }
        }

        if (isExpanded && !record?.childOfFirstChild?.length) {
          return { rowSpan: 1 }
        }

        return {
          rowSpan: record.childOfFirstChild?.length
            ? record.childOfFirstChild?.length + 1
            : 0,
        }
      }

      const isExpanded = expandedRowKeys.includes(record.key ?? 0)
      if (!isExpanded && record?.children) {
        if (expandedRowKeys.length > expandedAreaKeys.length) {
          return { rowSpan: 1, colSpan: 2 }
        }
        return { rowSpan: 1 }
      }

      if (isExpanded && !record?.children?.length) {
        return { rowSpan: 1 }
      }

      return {
        rowSpan: record.children?.length ? record.children?.length + 1 : 0,
      }
    }

    const onCellDoctor = (record: IDataReport004): IOnCell => {
      if (record?.isTotalTable) {
        return { colSpan: 0 }
      }

      if (record?.isArea) {
        const isExpanded = expandedRowKeys.includes(record.childrenKey ?? 0)

        if (
          !isExpanded &&
          record?.children &&
          expandedRowKeys.length > expandedAreaKeys.length
        ) {
          return { colSpan: 0 }
        }
      }

      const isExpanded = expandedRowKeys.includes(record.key ?? 0)
      if (
        !isExpanded &&
        record?.children &&
        expandedRowKeys.length > expandedAreaKeys.length
      ) {
        return { colSpan: 0 }
      }

      return {}
    }

    // render column

    const renderAreaColumn = (_, record: IDataReport004) => {
      const isExpandedChild = expandedRowKeys.includes(record.key ?? 0)
      const _handleExpand = () => {
        if (record?.isArea) {
          handleExpandArea?.(!isExpandedChild, record.key ?? 0)
        }
      }
      return (
        <ExpandedTableValue
          isExpanded={isExpandedChild}
          handleExpand={_handleExpand}
          value={record?.area}
          isNotHaveActionExpanded={!record?.children?.length}
          isHaveAreaWhenNotHaveActionExpanded
        />
      )
    }

    const renderClinicColumn = (_, record: IDataReport004) => {
      if (!record?.children) return null
      let key = record?.key
      if (record?.isArea) key = record?.childrenKey
      const isExpandedChild = expandedRowKeys.includes(key ?? 0)

      const _handleExpand = () => {
        return handleExpand(!isExpandedChild, key)
      }

      let isNotHaveActionExpanded = !record?.children?.length

      if (record?.numberOfChild) {
        isNotHaveActionExpanded = !record?.numberOfChild
      }

      if (record?.isArea) {
        isNotHaveActionExpanded = !record?.childOfFirstChild?.length
      }

      console.log({ record, isNotHaveActionExpanded })

      return (
        <ExpandedTableValue
          isExpanded={isExpandedChild}
          handleExpand={_handleExpand}
          value={record?.clinic}
          isNotHaveActionExpanded={isNotHaveActionExpanded}
          isHaveAreaWhenNotHaveActionExpanded
        />
      )
    }

    let options: IBaseColumnReport<TypeColumn> = [
      convertColumnTable<TypeColumn>({
        title: t(R.strings.area),
        key: 'area',
        onCell: onCellArea,
        render: renderAreaColumn,
      }),
    ]

    if (expandedRowKeys?.length) {
      options = options?.concat([
        convertColumnTable<TypeColumn>({
          title: t(R.strings.clinic),
          key: 'clinic',
          classNameWidthColumnOverwrite: 'very-big-column',
          onCell: onCellClinic,
          render: renderClinicColumn,
        }),
      ])
    }

    if (expandedRowKeys?.length > expandedAreaKeys?.length) {
      options = options?.concat([
        convertColumnTable<TypeColumn>({
          title: t(R.strings.doctor),
          key: 'doctor',
          classNameWidthColumnOverwrite: 'very-big-column',
          onCell: onCellDoctor,
          render: (_, record) => (
            <RenderValueTableReport
              record={record}
              valueString={record?.doctor}
              justify="start"
              expandedRowKeys={expandedRowKeys}
            />
          ),
        }),
      ])
    }

    return [
      ...options,
      convertColumnTable<TypeColumn>({
        title: t(R.strings.number_checkin),
        key: 'checkInActual',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.checkInActual ?? 0,
            secondLevelValue: record?.checkInActualTotal ?? 0,
            thirdLevelValue: record.checkInActualTotalOfArea ?? 0,
            isFillBlueInTotal: true,
            expandedRowKeys,
            expandedAreaKeys,
            prefix: '',
          }),
      }),

      convertColumnTable<TypeColumn>({
        title: t(R.strings.number_of_cases_closed),
        key: 'numberOfCasesClosed',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.numberOfCasesClosed ?? 0,
            secondLevelValue: record?.numberOfCasesClosedTotal ?? 0,
            thirdLevelValue: record.numberOfCasesClosedTotalOfArea ?? 0,
            expandedRowKeys,
            expandedAreaKeys,
            prefix: '',
            isFillBlueInTotal: true,
          }),
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.crv3),
        key: 'crv3',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.crv3 ?? 0,
            secondLevelValue: record?.crv3Total ?? 0,
            thirdLevelValue: record.crv3TotalOfArea ?? 0,
            prefix: '',
            expandedRowKeys,
            expandedAreaKeys,
            isFillBlueInTotal: true,
          }),
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.crv3_with_period),
        key: 'crv3WithPeriod',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.crv3WithPeriod ?? 0,
            secondLevelValue: record?.crv3WithPeriodTotal ?? 0,
            thirdLevelValue: record.crv3WithPeriodTotalOfArea ?? 0,
            expandedRowKeys,
            expandedAreaKeys,
            prefix: '',
            isFillBlueInTotal: true,
          }),
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.percent_crv3_growth_in_period),
        key: 'percentCrv3WithPeriod',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.percentCrv3WithPeriod ?? 0,
            secondLevelValue: record?.percentCrv3WithPeriodTotal ?? 0,
            thirdLevelValue: record.percentCrv3WithPeriodTotalOfArea ?? 0,
            prefix: PERCENT_PREFIX,
            expandedRowKeys,
            expandedAreaKeys,
            isShowValueUpAndDown: true,
            percent: record?.percentCrv3WithPeriod ?? 0,
            percentTotal: record?.percentCrv3WithPeriodTotal ?? 0,
            percentTotalOfTotal: record?.percentCrv3WithPeriodTotalOfArea ?? 0,
            isFillBlueInTotal: true,
          }),
      }),
    ]
  }, [t, expandedRowKeys, currentFilter, expandedAreaKeys])

  const data = (() => {
    return dataTable.map(item => {
      const isExpanded = expandedRowKeys.includes(item?.childrenKey ?? 0)
      const oldChild =
        item?.children?.filter(
          (itm: { parentKey?: number }) => !itm?.parentKey,
        ) ?? []
      if (isExpanded) {
        const newChild = item?.childOfFirstChild ?? []
        return {
          ...item,
          children: [...newChild, ...oldChild],
        }
      }
      return {
        ...item,
        children: oldChild,
      }
    })
  })()

  return {
    columns,
    exportExcel,
    data,
  }
}

export const convertValueResponseToTableData = ({
  item,
  type,
}: {
  item: IInfoService
  type: 'doctor' | 'area' | 'clinic'
}) => {
  if (!item) {
    return {}
  }

  switch (type) {
    case 'doctor':
      return {
        checkInActual: item?.serviceCheckIn ?? 0,
        numberOfCasesClosed: item?.serviceCheckInPaid,
        crv3: item?.serviceCVR3,
        crv3WithPeriod: item?.previousServiceCVR3,
        percentCrv3WithPeriod: item?.cvr3Percent,
      }
    case 'clinic':
      return {
        checkInActualTotal: item?.serviceCheckIn ?? 0,
        numberOfCasesClosedTotal: item?.serviceCheckInPaid,
        crv3Total: item?.serviceCVR3,
        crv3WithPeriodTotal: item?.previousServiceCVR3,
        percentCrv3WithPeriodTotal: item?.cvr3Percent,
      }
    case 'area':
      return {
        checkInActualTotalOfArea: item?.serviceCheckIn ?? 0,
        numberOfCasesClosedTotalOfArea: item?.serviceCheckInPaid,
        crv3TotalOfArea: item?.serviceCVR3,
        crv3WithPeriodTotalOfArea: item?.previousServiceCVR3,
        percentCrv3WithPeriodTotalOfArea: item?.cvr3Percent,
      }
  }
}

export const getDataReport04FromApi = async (
  params: IParamsGetReport04,
  key: string = totalKeyReport04,
) => {
  const response: IResponseGetReport04 = await requestGetReport04(params)

  if (isEqual(response?.msgcode, ReportMessageCode.Report04.successGetData)) {
    const data = response?.data?.areas ?? []

    const newData: IDataReport004[] =
      data?.map((area, index) => {
        const [firstClinic, ...otherClinic] = area?.clinics ?? []

        const [firstDoctorOfFirstClinic, ...otherDoctorOfFirstClinic] =
          firstClinic?.doctors ?? []
        const keyOfFirstClinic = Number(`${(index + 1) * 10}001`)

        const totalDoctorData = convertValueResponseToTableData({
          item: firstDoctorOfFirstClinic?.info?.[key],
          type: 'doctor',
        })

        const totalClinicData = convertValueResponseToTableData({
          item: firstClinic?.info?.[key],
          type: 'clinic',
        })

        const totalAreaData = convertValueResponseToTableData({
          item: area?.info?.[key],
          type: 'area',
        })

        const newResponse: IDataReport004 = {
          key: index + 1,
          area: area?.name,
          isArea: true,
          childrenKey: keyOfFirstClinic,
          doctor: firstDoctorOfFirstClinic?.name,
          clinic: firstClinic?.name,
          ...totalDoctorData,
          ...totalClinicData,
          ...totalAreaData,
          childOfFirstChild: otherDoctorOfFirstClinic?.map((item, idx) => {
            const keyChildOtherDoctorOfFirstClinic = Number(
              `${keyOfFirstClinic}${idx + 1}0`,
            )

            const firstDoctorData = convertValueResponseToTableData({
              item: item?.info?.[key],
              type: 'doctor',
            })

            return {
              key: keyChildOtherDoctorOfFirstClinic,
              doctor: item?.name,
              parentKey: keyOfFirstClinic,
              ...firstDoctorData,
            }
          }),
          children: otherClinic?.map((itmOtherClinic, idxOtherClinic) => {
            const keyChildOtherClinic = Number(
              `${randomMoney()}${idxOtherClinic}111`,
            )

            const [firstDoctorOfClinic, ...otherDoctorOfClinic] =
              itmOtherClinic?.doctors ?? []

            const childServiceData = convertValueResponseToTableData({
              item: firstDoctorOfClinic?.info?.[key],
              type: 'doctor',
            })

            const totalClinicDataOfOtherClinic =
              convertValueResponseToTableData({
                item: itmOtherClinic?.info?.[key],
                type: 'clinic',
              })

            return {
              key: keyChildOtherClinic,
              clinic: itmOtherClinic?.name,
              doctor: firstDoctorOfClinic?.name,
              ...childServiceData,
              ...totalClinicDataOfOtherClinic,
              numberOfChild: itmOtherClinic?.doctors?.length,
              children: otherDoctorOfClinic?.map((item, idx) => {
                const keyChild = Number(
                  `${idxOtherClinic}${idx}${randomMoney()}2220`,
                )

                const childDoctorData = convertValueResponseToTableData({
                  item: item?.info?.[key],
                  type: 'doctor',
                })

                return {
                  key: keyChild,
                  doctor: item?.name,
                  ...childDoctorData,
                  parentKey: keyChildOtherClinic,
                }
              }),
            }
          }),
        }
        return newResponse
      }) ?? []

    const totalOfTable = response?.data?.total

    const totalOfTableData = convertValueResponseToTableData({
      item: totalOfTable?.[key],
      type: 'doctor',
    })

    const totalItem: IDataReport004 = {
      key: randomMoney(),
      isTotalTable: true,
      area: totalKeyReport04,
      ...totalOfTableData,
    }

    _DEV_ && console.log({ newData, params })

    return [...newData, totalItem]
  }
}
