import {
  EDayOffType,
} from 'app/api/jobtitle/model/job-title-management'
import R from 'app/assets/R'
import { t } from 'i18next'

export function convertDayOffType(type?: EDayOffType): string | undefined {
  switch (type) {
    case EDayOffType.Dynamic:
      return t(R.strings.job_title_content_dayOfDynamic)
    case EDayOffType.Static:
      return t(R.strings.job_title_content_dayOfFixed)
    default:
      return type
  }
}
