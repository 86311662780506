import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import { useModalHook } from './hook'
import * as S from './styles'
import { IFormData } from './type'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { SectionBase } from 'app/components/common/SectionBase'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { Button } from 'app/components/common/BaseButton/styles'
import WorkInfoForm from '../components/WorkInfoForm'
import { PlusCircleOutlined } from '@ant-design/icons'
import { ActionAccountEnum } from '../type'
import { useEffect, useState } from 'react'
import { convertToOptionActionAccount } from '../constant'
import { Skill } from '../components/Skill'
import { SkillModal } from '../components/Skill/SkillModal'
import { IFormSkillModalData } from '../components/Skill/SkillModal/type'
import { notificationController } from 'app/controllers/notification-controller'
import { DegreeList } from '../components/Degree'
import { DegreeModal } from '../components/Degree/DegreeModal'
import { IFormDegreeModalData } from '../components/Degree/DegreeModal/type'
import UploadAvatar from '../components/UploadAvatar'
import { isEqual } from 'lodash'
import { StatusOptionsUpdateEnum } from 'app/common/enum'

export const initValues: IFormData = {
  name: '',
  _id: '',
  workinfos: [{}],
}

enum StatusUnitOptionsEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
}

export type StatusUnitType = keyof typeof StatusUnitOptionsEnum

const Update = () => {
  const {
    form,
    t,
    handleSubmit,
    handleCancel,
    rules,
    jobTitles,
    units,
    accounts,
    skills: skillOptions,
    industries: industryOptions,
    levelSkills: levelSkillOptions,
    degrees: degreeOptions,
    degreeClassifications: degreeClassificationOptions,
  } = useModalHook()

  const avatar = form.getFieldValue('avatar') ?? ''

  const handleAddSkill = (values: IFormSkillModalData) => {
    const skillField = form.getFieldValue('skills') ?? []
    const foundSkill = skillField?.find(
      skill =>
        skill.industry === values.industry && skill.skill === values.skill,
    )

    if (foundSkill) {
      notificationController.error({
        message: t(R.strings.skill_content_add),
        description: t(R.strings.skill_content_exists_skill),
      })

      return false
    }

    const skillPushed = [...skillField, values]

    form.setFieldValue('skills', skillPushed)

    return true
  }

  const handleRemoveSkill = key => {
    const skillField = form.getFieldValue('skills') ?? []
    const skillFiltered = skillField.filter((skill, index) => index !== key)

    form.setFieldValue('skills', skillFiltered)
  }

  const accountAction =
    form.getFieldValue('action_account') === ActionAccountEnum.select_id
  const [isShowSelectAccount, setIsShowSelectAccount] =
    useState<boolean>(accountAction)

  useEffect(() => {
    setIsShowSelectAccount(accountAction)
  }, [accountAction])

  const handleActionAccountOnChange = key => {
    switch (key) {
      case ActionAccountEnum.select_id:
        setIsShowSelectAccount(true)
        break
      default:
        setIsShowSelectAccount(false)
        break
    }
  }

  const handleAddDegree = (values: IFormDegreeModalData) => {
    const degreeField = form.getFieldValue('degrees') ?? []
    const degreePushed = [...degreeField, values]
    form.setFieldValue('degrees', degreePushed)

    return true
  }

  const handleRemoveDegree = key => {
    const degreeField = form.getFieldValue('degrees') ?? []
    const degreeFiltered = degreeField.filter((degree, index) => index !== key)

    form.setFieldValue('degrees', degreeFiltered)
  }

  const handleUpdate = key => {
    const degreeField = form.getFieldValue('degrees') ?? []
    const foundDegree = degreeField.find((degree, index) => index === key)

    return foundDegree
  }

  const handleAvatar = (image: string) => {
    form.setFieldValue('avatar', image)
  }

  return (
    <S.BaseActionWrapper>
      <HeaderPage
        titleI18nKey={R.strings.profile_content_management}
        isExport
      />
      <S.BaseFormWrapper
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark="optional"
        initialValues={initValues}
        form={form}
      >
        <SectionBase title={t(R.strings.profile_content_info_section)}>
          <BaseRow gutter={20} align={'top'} justify={'space-between'}>
            <BaseCol xl={6}>
              <S.FormItem hiddenLabel name="avatar">
                {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment //
                @ts-ignore */}
                <UploadAvatar image={avatar ?? ''} onSetAvatar={handleAvatar} />
              </S.FormItem>
            </BaseCol>
            <BaseCol xl={18}>
              <BaseRow gutter={20} align={'top'} justify={'space-between'}>
                <BaseCol xl={12}>
                  <S.FormItem
                    name="lastname"
                    label={t(R.strings.profile_content_lastname)}
                    rules={rules.lastname}
                    required
                  >
                    <S.FormInput
                      placeholder={t(R.strings.enter_field, {
                        field: t(R.strings.profile_content_lastname),
                      })}
                    />
                  </S.FormItem>
                </BaseCol>
                <BaseCol xl={12}>
                  <S.FormItem
                    name="firstname"
                    label={t(R.strings.profile_content_firstname)}
                    rules={rules.firstname}
                    required
                  >
                    <S.FormInput
                      placeholder={t(R.strings.enter_field, {
                        field: t(R.strings.profile_content_firstname),
                      })}
                    />
                  </S.FormItem>
                </BaseCol>
              </BaseRow>
              <BaseRow gutter={20} align={'top'} justify={'space-between'}>
                <BaseCol xl={12}>
                  <S.FormItem
                    label={t(R.strings.account)}
                    name="action_account"
                    rules={rules.action_account}
                  >
                    <S.SelectBaseStyled
                      placeholder={t(
                        R.strings.profile_content_please_select_account,
                      )}
                      suffixIcon={<SvgTableCloseIcon />}
                      onChange={handleActionAccountOnChange}
                    >
                      {Object.values(ActionAccountEnum)?.map((item, index) => {
                        return (
                          <Option value={item} key={index}>
                            {convertToOptionActionAccount(item)}
                          </Option>
                        )
                      })}
                    </S.SelectBaseStyled>
                  </S.FormItem>
                </BaseCol>
                {isShowSelectAccount && (
                  <BaseCol xl={12}>
                    <S.FormItem name="accountid">
                      <S.SelectBaseStyled
                        showSearch
                        placeholder={t(R.strings.choose)}
                        suffixIcon={<SvgTableCloseIcon />}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.children.toLowerCase() ?? '').includes(
                            input.toLowerCase(),
                          )
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.children ?? '')
                            .toLowerCase()
                            .localeCompare(
                              (optionB?.children ?? '').toLowerCase(),
                            )
                        }
                      >
                        {accounts?.map((account, index) => {
                          return (
                            <Option value={account._id} key={index}>
                              {`${account.code ? `[${account.code}]` : ''}${
                                account.name
                              }`}
                            </Option>
                          )
                        })}
                      </S.SelectBaseStyled>
                    </S.FormItem>
                  </BaseCol>
                )}
                {!isShowSelectAccount && (
                  <BaseCol xl={12}>
                    <S.FormItem>
                      <S.FormInput disabled={true} />
                    </S.FormItem>
                  </BaseCol>
                )}
              </BaseRow>
              <BaseRow gutter={20} align={'top'} justify={'space-between'}>
                <BaseCol xl={6}>
                  <S.FormItem
                    name="employeeid"
                    label={t(R.strings.profile_content_employeeid)}
                    rules={rules.employeeid}
                    required
                  >
                    <S.FormInput
                      placeholder={t(R.strings.enter_field, {
                        field: t(R.strings.profile_content_employeeid),
                      })}
                    />
                  </S.FormItem>
                </BaseCol>

                <BaseCol xl={6}>
                  <S.FormItem
                    name="employeeOldId"
                    label={t(R.strings.profile_content_employee_old_id)}
                  >
                    <S.FormInput
                      placeholder={t(R.strings.enter_field, {
                        field: t(R.strings.profile_content_employee_old_id),
                      })}
                    />
                  </S.FormItem>
                </BaseCol>
                <BaseCol xl={12}>
                  <S.FormItem
                    name="mobile"
                    label={t(R.strings.profile_content_mobile)}
                    rules={rules.mobile}
                    required
                  >
                    <S.FormInput
                      placeholder={t(R.strings.enter_field, {
                        field: t(R.strings.profile_content_mobile),
                      })}
                    />
                  </S.FormItem>
                </BaseCol>
              </BaseRow>
              <BaseRow gutter={20} align={'top'} justify={'space-between'}>
                <BaseCol xl={12}>
                  <S.FormItem
                    name="email"
                    label={t(R.strings.profile_content_email)}
                    rules={rules.email}
                    required
                  >
                    <S.FormInput
                      placeholder={t(R.strings.enter_field, {
                        field: t(R.strings.profile_content_email),
                      })}
                    />
                  </S.FormItem>
                </BaseCol>
                <BaseCol xl={12}>
                  <S.FormItem
                    label={t(R.strings.status)}
                    name="status"
                    rules={rules.status}
                    required
                  >
                    <S.SelectBaseStyled
                      placeholder={t(R.strings.please_select_status)}
                      suffixIcon={<SvgTableCloseIcon />}
                    >
                      {Object.values(StatusOptionsUpdateEnum)?.map(
                        (item, index) => {
                          return (
                            <Option value={item} key={index}>
                              {t(item)}
                            </Option>
                          )
                        },
                      )}
                    </S.SelectBaseStyled>
                  </S.FormItem>
                </BaseCol>
              </BaseRow>
            </BaseCol>
          </BaseRow>
        </SectionBase>
        <SectionBase title={t(R.strings.profile_content_skill)}>
          <S.FormItem
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              !isEqual(prevValues.skills, currentValues.skills)
            }
          >
            {({ getFieldValue }) => (
              <S.FormItem hiddenLabel name="skills">
                <Skill
                  skillOptions={skillOptions}
                  levelSkillOptions={levelSkillOptions}
                  skills={getFieldValue('skills') ?? []}
                  onRemove={handleRemoveSkill}
                />
                <SkillModal
                  skillOptions={skillOptions}
                  industryOptions={industryOptions}
                  levelSkillOptions={levelSkillOptions}
                  handleSubmit={handleAddSkill}
                />
              </S.FormItem>
            )}
          </S.FormItem>
        </SectionBase>
        <SectionBase title={t(R.strings.profile_content_degree)}>
          <S.FormItem
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              !isEqual(prevValues.degrees, currentValues.degrees)
            }
          >
            {({ getFieldValue }) => (
              <S.FormItem hiddenLabel name="degrees">
                <DegreeList
                  degrees={getFieldValue('degrees') ?? []}
                  degreeOptions={degreeOptions}
                  degreeClassificationOptions={degreeClassificationOptions}
                  onRemove={handleRemoveDegree}
                  onUpdate={handleUpdate}
                />
                <DegreeModal
                  degreeOptions={degreeOptions}
                  degreeClassificationOptions={degreeClassificationOptions}
                  handleSubmit={handleAddDegree}
                />
              </S.FormItem>
            )}
          </S.FormItem>
        </SectionBase>
        <SectionBase title={t(R.strings.profile_content_job_title_section)}>
          <BaseForm.List name="workinfos">
            {(fields, { add, remove }) => {
              const fieldLength = fields.length
              const isShowKey = fieldLength > 1
              return (
                <div>
                  <S.TitleFormList>
                    {t(R.strings.profile_content_job_title_main)}
                  </S.TitleFormList>
                  <S.ListWorkInfo>
                    {fields.map(({ key, name, ...restField }) => {
                      const keyNumber = name + 1
                      const isShowDelete = keyNumber !== 1

                      return (
                        <S.WrapperWorkInfo>
                          <S.HeaderWorkInfo>
                            <h4>
                              {t(
                                R.strings.profile_content_job_title_main_column,
                              )}{' '}
                              {isShowKey && keyNumber}
                            </h4>
                            <S.ButtonActionGroup>
                              <S.RefreshButton
                                onClick={() => {
                                  const itemWorkInfo =
                                    form.getFieldValue('workinfos')
                                  itemWorkInfo[name] = {}

                                  form.setFieldValue('workinfos', itemWorkInfo)
                                }}
                                rev={undefined}
                              />
                              {isShowDelete && (
                                <S.DeleteButton
                                  onClick={() => remove(name)}
                                  rev={undefined}
                                />
                              )}
                            </S.ButtonActionGroup>
                          </S.HeaderWorkInfo>
                          <WorkInfoForm
                            key={key}
                            name={name}
                            rules={rules}
                            {...restField}
                            jobTitles={jobTitles}
                            units={units}
                          />
                        </S.WrapperWorkInfo>
                      )
                    })}
                  </S.ListWorkInfo>
                  <S.WrapperButton type="link" onClick={() => add()}>
                    <PlusCircleOutlined rev={undefined} />
                    {t(R.strings.profile_content_add_work_info_main)}
                  </S.WrapperButton>
                </div>
              )
            }}
          </BaseForm.List>
          <BaseForm.List name="workinfoakas">
            {(fields, { add, remove }) => (
              <>
                <S.TitleFormList>
                  {t(R.strings.profile_content_job_title_secondary)}
                </S.TitleFormList>
                <S.ListWorkInfo>
                  {fields.map(({ key, name, ...restField }) => {
                    const keyNumber = name + 1
                    return (
                      <S.WrapperWorkInfo>
                        <S.HeaderWorkInfo>
                          <h4>
                            {t(
                              R.strings
                                .profile_content_job_title_secondary_child,
                            )}{' '}
                            {keyNumber}
                          </h4>
                          <S.ButtonActionGroup>
                            <S.RefreshButton
                              onClick={() => {
                                const itemWorkInfo =
                                  form.getFieldValue('workinfoakas')
                                itemWorkInfo[name] = {}

                                form.setFieldValue('workinfoakas', itemWorkInfo)
                              }}
                              rev={undefined}
                            />
                            <S.DeleteButton
                              onClick={() => remove(name)}
                              rev={undefined}
                            />
                          </S.ButtonActionGroup>
                        </S.HeaderWorkInfo>
                        <WorkInfoForm
                          key={key}
                          name={name}
                          rules={rules}
                          {...restField}
                          jobTitles={jobTitles}
                          units={units}
                        />
                      </S.WrapperWorkInfo>
                    )
                  })}
                </S.ListWorkInfo>
                <Button type="link" onClick={() => add()}>
                  <PlusCircleOutlined rev={undefined} />
                  {t(R.strings.profile_content_add_work_info_aka)}
                </Button>
              </>
            )}
          </BaseForm.List>
        </SectionBase>
      </S.BaseFormWrapper>
      <S.FormItem>
        <S.ButtonActionGroup>
          <S.ButtonCancel onClick={handleCancel}>
            {t(R.strings.cancel)}
          </S.ButtonCancel>
          <S.ButtonSubmit onClick={form.submit}>
            {t(R.strings.update)}
          </S.ButtonSubmit>
        </S.ButtonActionGroup>
      </S.FormItem>
    </S.BaseActionWrapper>
  )
}

export default Update
