import { IBaseTableReport } from 'app/components/tables/BaseTableReport/type'
import { Pagination } from 'parkway-web-common'

export const initialPagination: Pagination = {
  current: 1,
  pageSize: 20,
}

export interface IGeneralCostPlanTable<T> extends IBaseTableReport<T> {
  cpNvlLive?: number
  doctorDepartment?: number
  nursingDepartment?: number
  advisoryDepartment?: number
  xRayTechnicalDepartment?: number
  receptionDepartment?: number
  securityAndHousekeepingDepartment?: number
  initialConstruction?: number
  machineryAndEquipment?: number
  rentalExpenses?: number
  locationRentalTaxExpenses?: number
  otherOperatingExpenses?: number
  cardSwipeInstallmentFees?: number
  othersCosmetics?: number
  planNumber?: number
  performance?: number
  branch?: number
  callCenter?: number
  ccdc?: number

  // add new 2024-10-01
  marketingBP?: number
  operationBP?: number
  trainingBP?: number
  hrBP?: number
  accountingBP?: number
  purchasingBP?: number
  otherExpenses?: number
  otherIncome?: number
  interestOnDeposits?: number
  otherProfits?: number

  cpNvlLiveTotal?: number
  doctorDepartmentTotal?: number
  nursingDepartmentTotal?: number
  advisoryDepartmentTotal?: number
  xRayTechnicalDepartmentTotal?: number
  receptionDepartmentTotal?: number
  securityAndHousekeepingDepartmentTotal?: number
  initialConstructionTotal?: number
  machineryAndEquipmentTotal?: number
  rentalExpensesTotal?: number
  locationRentalTaxExpensesTotal?: number
  otherOperatingExpensesTotal?: number
  cardSwipeInstallmentFeesTotal?: number
  othersCosmeticsTotal?: number
  planNumberTotal?: number
  performanceTotal?: number
  callCenterTotal?: number
  branchTotal?: number
  ccdcTotal?: number
  // add new 2024-10-01
  marketingBPTotal?: number
  operationBPTotal?: number
  trainingBPTotal?: number
  hrBPTotal?: number
  accountingBPTotal?: number
  purchasingBPTotal?: number
  otherExpensesTotal?: number
  otherIncomeTotal?: number
  interestOnDepositsTotal?: number
  otherProfitsTotal?: number
}
