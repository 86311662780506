export const MeasurementUnitKeys = {
  allMeasurementUnits: ['MeasurementUnits'] as const,
  listMeasurementUnits: () =>
    [
      ...MeasurementUnitKeys.allMeasurementUnits,
      'listMeasurementUnits',
    ] as const,
  listMeasurementUnit: () =>
    [...MeasurementUnitKeys.listMeasurementUnits(), {}] as const,
}
