import R from 'app/assets/R'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { BaseListProgram } from './constant'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'

interface IProp {
  item: string
  index: number
}

const SelectProgramsComponent = ({ item, index }: IProp) => {
  const { t } = useTranslation()

  const {
    onChangeAlternativeApplicationProgram,
    onDeleteAlternativeApplicationProgram,
    alternativeApplicationPrograms,
  } = useProfilePatientContext()

  const listProgramsCanSelect = useMemo(() => {
    return BaseListProgram?.filter(itemSelect => {
      if (itemSelect === item) return true

      return !alternativeApplicationPrograms?.find(itm => itm === itemSelect)
    })
  }, [alternativeApplicationPrograms])

  const _onChange = (newValue: string) => {
    onChangeAlternativeApplicationProgram?.(index, newValue)
  }

  const _onDelete = () => {
    onDeleteAlternativeApplicationProgram?.(index)
  }

  const isDisableDeleteButton = useMemo(() => {
    return alternativeApplicationPrograms?.length === 1
  }, [alternativeApplicationPrograms])

  const value = useMemo(() => {
    return isEmpty(item) ? undefined : item
  }, [item])

  return (
    <RootWrapper gutter={15} wrap={false} align={'middle'}>
      <BaseCol>
        <BaseText
          fontSize="xs"
          fontWeight="medium"
          children={t(R.strings.program)}
        />
      </BaseCol>
      <BaseCol>
        <SelectWrapper
          onChange={value => _onChange(value as string)}
          placeholder={t(R.strings.select_program)}
          value={value}
          allowClear
        >
          {listProgramsCanSelect?.map((item, index) => {
            return (
              <Option value={item} key={index}>
                {item}
              </Option>
            )
          })}
        </SelectWrapper>
      </BaseCol>
      <BaseCol>
        <BaseButton
          onClick={_onDelete}
          disabled={isDisableDeleteButton}
          type="text"
          icon={<SvgDeleteDesignIcon />}
        />
      </BaseCol>
    </RootWrapper>
  )
}

export default SelectProgramsComponent

const RootWrapper = styled(BaseRow)`
  border: 1px solid ${convertedVariables.neutralBlack2Color};
  background-color: #f6f6f6; // need change
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  margin-left: 0px !important;
  margin-right: 0px !important;
`

const SelectWrapper = styled(BaseSelect)`
  min-width: 350px !important;
  .ant-select-arrow {
    top: 50%;
  }
`
