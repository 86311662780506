// Message code define
export const PatientMessageCode = {
  PatientManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20001',
  },
}

// Endpoint
export const PatientEndPoint = {
  PatientManagement: {
    request: 'hr/v1/profile/patient',
    appointmentPatient: 'appointment/v1/booking/patient/search',
  },
}