export enum StatusEnum {
  Duplicated = 'duplicated',
  WrongMaximumDiscount = 'wrong_maximum_discount',
  NotFoundTreatment = 'not_found_treatment',
  Success = 'success',
}

export interface IRegionalPrice {
  _id?: string
  price?: number
  region?: number
}
export interface IResponseImportFile {
  _id?: string
  name?: string
  maximum?: string
  status?: StatusEnum
  rowNumber?: string
  regionalPrice?: IRegionalPrice[]
  key?: string
}
export interface ILimitDiscountData {
  _id?: string
  treatmentId?: string
  name?: string
  maximum?: string
  regionalPrice?: IRegionalPrice[]
  key?: string
}
