import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import { FilterReport } from 'app/components/tables/BaseTableReport/FilterReport'
import * as S from 'app/components/tables/BaseTableReport/styles'
import 'dayjs/locale/vi'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTableHook } from './hook'

const Report001Container: React.FC = () => {
  const {
    columns,
    tableData,
    handleTableChange,
    expandedRowKeys,
    handleExpand,
    handleChangeFilter,
    exportExcel,
  } = useTableHook()

  return (
    <S.BaseReportWrapper>
      <HeaderPage
        titleI18nKey={R.strings.report_001}
        isExport
        onPressExport={exportExcel}
      />
      <S.RootWrapper>
        <S.HeadWrapper>
          <FilterReport
            isSelectFilter
            handleChangeFilter={handleChangeFilter}
            selectFilterGroup="WEEK_MONTH"
            isUsingWeekMonthCorrect
          />
        </S.HeadWrapper>
        <BaseTableReport
          columns={columns}
          dataSource={tableData.data}
          loading={tableData.loading}
          onChange={handleTableChange}
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            defaultExpandAllRows: true,
            expandIcon: () => null,
            rowExpandable: record =>
              record?.children && record?.children?.length > 0,
          }}
          rowClassName="row-overwrite-style"
          pagination={false}
          scroll={{ x: true, y: tableData?.data?.length ? 480 : undefined }}
        />
      </S.RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default Report001Container
