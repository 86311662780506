import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { ActionsDropdown } from 'app/components/tables/BaseTableManagement/ActionDropdown'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import * as SParent from 'app/containers/Accounting/ItemManagement/styles'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import { IDataTableItemManagement } from '../../common-model'
import { mapColorWithFirstCharacter } from 'app/hook'
import { isEmpty } from 'lodash'
import { appendIds } from 'app/common/helpers'
import { IListSkill } from '../ExpandedRowRender'
import { LevelSkillItemTable } from '../LevelSkillItemTable'
import { requestUpdateItemManagement } from 'app/api/item'
import { TreatmentMessageCode } from 'app/api/treatment/constant'

interface IDataTable {
  index: number
  _id: string
  skillsInfo?: IListSkill[]
  action?: string
}

const RequestLevelSkillTable = ({ record, listSkill }) => {
  if (isEmpty(record.skillsInfo)) {
    return <></>
  }
  record.skillsInfo = record.skillsInfo.map(item => {
    const id = appendIds([item.skillId, item.industryId, item.levelSkillId])
    const foundLevelSkill = listSkill?.find(
      (skill: IListSkill) => skill.id === id,
    )
    return foundLevelSkill || {}
  })

  const { t } = useTranslation()

  const [dataLevelSkill, setDataLevelSkill] = useState<IDataTable[]>([])

  useEffect(() => {
    setDataLevelSkill([record])
  }, [record])

  const [isShowDelete, setIsShowDelete] = useState<boolean>(false)

  const onShowDelete = () => {
    setIsShowDelete(prev => !prev)
  }

  const onDelete = async (id: string) => {
    const itemData = dataLevelSkill?.[0]
    const newSkillsInfo = itemData?.skillsInfo?.filter(
      (item: IListSkill) => item.id !== id,
    )

    const response = await requestUpdateItemManagement({
      id: itemData._id,
      body: {
        skillsInfo: newSkillsInfo,
      },
    })

    if (response.msgcode === TreatmentMessageCode.Management.successPut) {
      setDataLevelSkill(prev => {
        const newData = {
          ...prev?.[0],
          skillsInfo: newSkillsInfo ?? undefined,
        }

        return [newData]
      })
    }
  }

  const column = useMemo(() => {
    return [
      convertColumnTable<IDataTableItemManagement>({
        title: t(R.strings.skill_content_title),
        key: 'key',
        render: (_, record) => {
          return (
            <BaseRow gutter={[20, 10]} align={'middle'}>
              {record?.skillsInfo?.map((item, index) => {
                const color = mapColorWithFirstCharacter(
                  item?.levelSkillName?.[0] ?? 'P',
                )?.color

                if (isEmpty(item)) {
                  return <></>
                }

                return (
                  <BaseCol key={index}>
                    <LevelSkillItemTable
                      index={item.index ?? ''}
                      id={item.id}
                      color={color}
                      levelSkill={item?.levelSkillName}
                      skill={item?.skillName}
                      onRemove={onDelete}
                      key={item.id}
                      showDelete={isShowDelete}
                    />
                  </BaseCol>
                )
              })}
            </BaseRow>
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: ' ',
        key: 'action',
        classNameWidthColumnOverwrite: 'small-column',
        render: () => {
          return (
            <ActionsDropdown
              actionComponent={
                <>
                  {/* <CreateLevelSkill /> */}
                  <BaseButton onClick={onShowDelete}>
                    {t(R.strings.update)}
                  </BaseButton>
                </>
              }
            />
          )
        },
      }),
    ]
  }, [t, isShowDelete, dataLevelSkill?.[0]?.skillsInfo?.length])

  return (
    <S.RootWrapper direction="vertical" size={20}>
      <S.TitleRequestJobTitleLevelDoctor>
        {t(R.strings.treatment_management_content_request_level_skill)}
      </S.TitleRequestJobTitleLevelDoctor>
      <SParent.BaseTableWrapper
        columns={column}
        dataSource={dataLevelSkill}
        pagination={false}
        rowClassName="row-overwrite-style"
      />
    </S.RootWrapper>
  )
}

export default RequestLevelSkillTable
