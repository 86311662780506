/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetLocationsPatientWithoutPaid } from 'app/api/accounting/api/orthodontic-patient-without-paid'
import { AccountingMessageCode } from 'app/api/accounting/constant'
import {
  GroupTypeEnum,
  IParamsGetLocationsWithoutPaid,
  IResponseLocationsWithoutPaid,
  ReportTypeEnum,
} from 'app/api/accounting/model/orthodontic-patient-without-paid'
import R from 'app/assets/R'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { _DEV_, initPagination } from 'app/constant'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import {
  FONT_SIZE,
  FONT_WEIGHT,
  Pagination
} from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useOrthodonticWithoutPaid } from '../../context'

export const useTableHook = () => {
  const { params } = useOrthodonticWithoutPaid()

  const [pagination, setPagination] = useState<Pagination | undefined>(
    initPagination,
  )

  const [total, setTotal] = useState(0)

  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const getData = async () => {
    setLoading(true)

    try {
      const paramsApi: IParamsGetLocationsWithoutPaid = {
        page: pagination?.current,
        pageSize: pagination?.pageSize ?? 10,
        ...params,
        groupType: GroupTypeEnum.clinic,
        reportType: ReportTypeEnum.withoutPaid,
      }

      const res: IResponseLocationsWithoutPaid =
        await requestGetLocationsPatientWithoutPaid(paramsApi)

      if (
        res?.msgcode &&
        isEqual(
          AccountingMessageCode?.OrthodonticWithoutPaid?.getLocationSuccess,
          res?.msgcode,
        )
      ) {
        const newData = res?.data?.map((item, index) => {
          const idNumber =
            ((paramsApi?.page ?? 1) - 1) * (paramsApi?.pageSize ?? 10) +
            (index + 1)
          return {
            key: index + 1,
            clinic: item?.name,
            total: item?.total ?? 0,
            idNumber,
          }
        })

        setData(newData)

        setTotal(res?.metadata?.totalItem ?? 0)
      }
    } catch (error) {
      _DEV_ && console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getData()
    }, 500)

    return () => clearTimeout(timer)
  }, [pagination, params])

  const handleTableChange = (pagination?: Pagination) => {
    setPagination(pagination)
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<{ idNumber: number }>({
        title: '#',
        key: 'idNumber',
        render: (_, record) => (
          <RenderValueTable
            value={record?.idNumber ?? '-'}
            type="Main"
            styleText={{
              fontWeight: FONT_WEIGHT.semibold,
              fontSize: FONT_SIZE.xs,
              opacity: 0.7,
            }}
          />
        ),
        classNameWidthColumnOverwrite: 'small-column',
        fixed: 'left',
        className: 'class-left',
        width: '5%',
      }),
      convertColumnTable<{ clinic: string }>({
        title: t(R.strings.clinic),
        key: 'clinic',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.clinic ?? '-'}
            type="Main"
            styleText={{
              fontWeight: FONT_WEIGHT.semibold,
              fontSize: FONT_SIZE.xs,
            }}
          />
        ),
        className: 'class-left',
        width: '80%',
      }),
      convertColumnTable<{ total: number }>({
        title: t(R.strings.total_patient),
        key: 'total',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.total ?? '-'}
            type="Main"
            styleText={{
              fontWeight: FONT_WEIGHT.semibold,
              fontSize: FONT_SIZE.xs,
              opacity: 0.7,
            }}
          />
        ),
        className: 'class-right',
      }),
    ]
  }, [t])

  return {
    columns,
    tableData: {
      pagination: {
        ...pagination,
        total,
      },
      data,
      loading,
    },
    handleTableChange,
  }
}
