import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { ActionHeader } from '../../SideBarRight/styles'
import {
  ActionHeaderButton,
  ActionHeaderMonth,
  ActionHeaderPrevNext,
  ActionHeaderPrevNextImg,
  ActionHeaderYear,
} from '../styles'
import { useTranslation } from 'react-i18next'

export const CalendarHeader = ({ value, onChange }) => {
  const year = value.year()
  const month = value.month()
  const { t } = useTranslation()
  return (
    <ActionHeader>
      <div>
        <ActionHeaderMonth>
          {t(R.strings.month)} {month + 1}
        </ActionHeaderMonth>
        <ActionHeaderYear>{year}</ActionHeaderYear>
      </div>
      <ActionHeaderButton>
        <ActionHeaderPrevNext
          onClick={() => {
            const now = value.clone().month(month - 1)
            onChange(now)
          }}
          className="prev"
        >
          <ActionHeaderPrevNextImg>
            <LeftOutlined rev={undefined} className="appointment-fontsize" />
          </ActionHeaderPrevNextImg>{' '}
        </ActionHeaderPrevNext>
        <ActionHeaderPrevNext
          onClick={() => {
            const now = value.clone().month(month + 1)
            onChange(now)
          }}
        >
          <ActionHeaderPrevNextImg>
            <RightOutlined rev className="appointment-fontsize" />{' '}
          </ActionHeaderPrevNextImg>
        </ActionHeaderPrevNext>
      </ActionHeaderButton>
    </ActionHeader>
  )
}
