import { ISkills } from 'app/api/profile/model/management'
import { IIndustry, ILevelSkill, ISkill } from 'app/api/skill/model/management'
import { StatusTableEnum } from 'app/common/enum'
import { getArray } from 'app/components/tables/common-table/constant'
import { ISupplies, dataSupplies } from './supplies'

export interface IJobTitleOfTreatment {
  _id?: string
  industry?: IIndustry
  skills?: ISkills
  levelSkill?: ILevelSkill
}

export interface ITreatment {
  _id?: string
  name?: string
  totalMinutes?: number
  suppliess?: ISupplies[]
  skill?: ISkill[]
  status?: StatusTableEnum
  jobTitleOfTreatments?: IJobTitleOfTreatment[]
}

export const dataTreatment: ITreatment[] = getArray(10).map(index => {
  return {
    _id: index.toString(),
    name: `Thủ thuật ${index}`,
    totalMinutes: 60,
    suppliess: dataSupplies?.slice(0, 3),
    jobTitleOfTreatments: [],
  }
})

export const initTreatment: ITreatment = {
  name: '',
  _id: '',
  totalMinutes: 0,
  suppliess: [],
  skill: [],
  status: StatusTableEnum.active,
  jobTitleOfTreatments: [],
}

export const listTimeData = [
  {
    label: '15 phút',
    value: 15,
  },
  {
    label: '30 phút',
    value: 30,
  },
  {
    label: '45 phút',
    value: 45,
  },
  {
    label: '60 phút',
    value: 60,
  },
  {
    label: '90 phút',
    value: 90,
  },
  {
    label: '120 phút',
    value: 120,
  },
]
