import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import {
  SelectFilterEnum,
  SelectFilterType,
  SelectQuarterEnum,
} from 'parkway-web-common'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseWeekPicker } from 'app/components/common/BaseWeekPicker'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import moment from 'moment'
import { Dates, FORMAT_ONLY_MONTH } from 'parkway-web-common'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import { IFilterProps, IWeekFilter } from './type'

export const FilterReport = ({
  handleChangeFilter,
  title18nKey = R.strings.see_with,
  isHaveChooseMonth = true,
  isHaveChooseWeek = true,
  isHaveChooseYear = true,
  isSelectFilter = false,
  selectFilterGroup = 'WEEK_MONTH',
  initSelectEnum = 'WEEK',
  isSelectQuarter,
  isUsingWeekMonthCorrect = false,
  isSelectFilterSecond,
  selectFilterGroupSecond = 'WEEK_MONTH',
  initSelectSecondEnum = 'WEEK',
  otherFilterComponent,
}: IFilterProps) => {
  const { t } = useTranslation()
  const [month, setMonth] = useState<string | undefined>(
    `${moment().month() + 1}`,
  )
  const [year, setYear] = useState<string | undefined>(`${moment().year()}`)
  const [week, setWeek] = useState<IWeekFilter>({
    startDate: undefined,
    endDate: undefined,
  })

  const [selectFilterType, setSelectFilter] =
    useState<SelectFilterType>(initSelectEnum)

  const [selectFilterTypeSecond, setSelectFilterSecond] =
    useState<SelectFilterType>(initSelectSecondEnum)

  const [selectQuarter, setSelectQuarter] = useState<SelectQuarterEnum>(
    SelectQuarterEnum.QUARTER_1,
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      handleChangeFilter?.({
        week,
        month,
        year,
        selectFilterType,
        quarter: selectQuarter,
        selectFilterTypeSecond,
      })
    }, 300)
    return () => clearTimeout(timer)
  }, [
    week,
    selectFilterType,
    year,
    month,
    selectQuarter,
    selectFilterTypeSecond,
  ])

  const convertMonthAndYear = useMemo(() => {
    const getYear = year ?? moment().year()
    const getMonth = month ?? moment().month() + 1

    return {
      year: getYear,
      month: getMonth,
    }
  }, [month, year])

  const onChangeMonth = (_, month: string) => {
    setMonth(month)
  }

  const onChangeYear = (_, year: string) => {
    setYear(year)
  }

  const onChangeSelectFilter = (type: SelectFilterType) => {
    setSelectFilter(type)
  }

  const onChangeSelectFilterSecond = (type: SelectFilterType) => {
    setSelectFilterSecond(type)
  }

  const onChangeSelectQuarter = (type: SelectQuarterEnum) => {
    setSelectQuarter(type)
  }

  const renderSelectFilter = useCallback(() => {
    if (!isSelectFilter) return

    switch (selectFilterGroup) {
      case 'WEEK_MONTH':
        return (
          <BaseCol>
            <BaseForm.Item name="select">
              <S.SelectBaseStyled
                defaultValue={selectFilterType}
                value={selectFilterType}
                placeholder={'Select type'}
                suffixIcon={<SvgTableCloseIcon />}
                onChange={type =>
                  onChangeSelectFilter(type as SelectFilterType)
                }
                allowClear={false}
              >
                <Option value={SelectFilterEnum.WEEK}>
                  {t(R.strings.week)}
                </Option>
                <Option value={SelectFilterEnum.MONTH}>
                  {t(R.strings.month)}
                </Option>
              </S.SelectBaseStyled>
            </BaseForm.Item>
          </BaseCol>
        )
      case 'MONTH_YEAR':
        return (
          <BaseCol>
            <BaseForm.Item name="select">
              <S.SelectBaseStyled
                defaultValue={selectFilterType}
                value={selectFilterType}
                placeholder={'Select type'}
                suffixIcon={<SvgTableCloseIcon />}
                onChange={type =>
                  onChangeSelectFilter(type as SelectFilterType)
                }
                allowClear={false}
              >
                <Option value={SelectFilterEnum.MONTH}>
                  {t(R.strings.month)}
                </Option>
                <Option value={SelectFilterEnum.YEAR}>
                  {t(R.strings.year)}
                </Option>
              </S.SelectBaseStyled>
            </BaseForm.Item>
          </BaseCol>
        )
      case 'AREA_FROM_THEN_UTIL_NOW':
        return (
          <BaseCol>
            <BaseForm.Item name="select">
              <S.SelectBaseStyled
                defaultValue={selectFilterType}
                value={selectFilterType}
                placeholder={'Select type'}
                suffixIcon={<SvgTableCloseIcon />}
                onChange={type =>
                  onChangeSelectFilter(type as SelectFilterType)
                }
                allowClear={false}
              >
                <Option value={SelectFilterEnum.AREA}>
                  {t(R.strings.area)}
                </Option>
                <Option value={SelectFilterEnum.FROM_THEN_UTIL_NOW}>
                  {t(R.strings.from_then_util_now)}
                </Option>
              </S.SelectBaseStyled>
            </BaseForm.Item>
          </BaseCol>
        )
      case 'YEAR_QUARTER':
        return (
          <BaseCol>
            <BaseForm.Item name="select">
              <S.SelectBaseStyled
                defaultValue={selectFilterType}
                value={selectFilterType}
                placeholder={'Select type'}
                suffixIcon={<SvgTableCloseIcon />}
                onChange={type =>
                  onChangeSelectFilter(type as SelectFilterType)
                }
                allowClear={false}
              >
                <Option value={SelectFilterEnum.QUARTER}>
                  {t(R.strings.quarter)}
                </Option>
                <Option value={SelectFilterEnum.YEAR}>
                  {t(R.strings.year)}
                </Option>
              </S.SelectBaseStyled>
            </BaseForm.Item>
          </BaseCol>
        )
      case 'MONTH_QUARTER':
        return (
          <BaseCol>
            <BaseForm.Item name="select">
              <S.SelectBaseStyled
                defaultValue={selectFilterType}
                value={selectFilterType}
                placeholder={'Select type'}
                suffixIcon={<SvgTableCloseIcon />}
                onChange={type =>
                  onChangeSelectFilter(type as SelectFilterType)
                }
                allowClear={false}
              >
                <Option value={SelectFilterEnum.MONTH}>
                  {t(R.strings.month)}
                </Option>
                <Option value={SelectFilterEnum.QUARTER}>
                  {t(R.strings.quarter)}
                </Option>
              </S.SelectBaseStyled>
            </BaseForm.Item>
          </BaseCol>
        )
      case 'WEEK_MONTH_QUARTER_YEAR':
        return (
          <BaseCol>
            <BaseForm.Item name="select">
              <S.SelectBaseStyled
                defaultValue={selectFilterType}
                value={selectFilterType}
                placeholder={'Select type'}
                suffixIcon={<SvgTableCloseIcon />}
                onChange={type =>
                  onChangeSelectFilter(type as SelectFilterType)
                }
                allowClear={false}
              >
                <Option value={SelectFilterEnum.WEEK}>
                  {t(R.strings.week)}
                </Option>

                <Option value={SelectFilterEnum.MONTH}>
                  {t(R.strings.month)}
                </Option>

                <Option value={SelectFilterEnum.QUARTER}>
                  {t(R.strings.quarter)}
                </Option>

                <Option value={SelectFilterEnum.YEAR}>
                  {t(R.strings.year)}
                </Option>
              </S.SelectBaseStyled>
            </BaseForm.Item>
          </BaseCol>
        )
      case 'ALL_SYSTEM_ALL_AREA':
        return (
          <BaseCol>
            <BaseForm.Item name="select">
              <S.SelectBaseStyled
                defaultValue={selectFilterType}
                value={selectFilterType}
                placeholder={'Select type'}
                suffixIcon={<SvgTableCloseIcon />}
                onChange={type =>
                  onChangeSelectFilter(type as SelectFilterType)
                }
                allowClear={false}
              >
                <Option value={SelectFilterEnum.ALL_SYSTEM}>
                  {t(R.strings.all_system)}
                </Option>
                <Option value={SelectFilterEnum.HOCHIMINH}>
                  {t(R.strings.hochiminh)}
                </Option>
                <Option value={SelectFilterEnum.HANOI}>
                  {t(R.strings.hanoi)}
                </Option>
                <Option value={SelectFilterEnum.PROVINCE}>
                  {t(R.strings.province)}
                </Option>
              </S.SelectBaseStyled>
            </BaseForm.Item>
          </BaseCol>
        )
    }
  }, [selectQuarter, selectFilterGroup, isSelectFilter, selectFilterType])()

  const renderSelectFilterSecond = useCallback(() => {
    if (!isSelectFilterSecond) return

    switch (selectFilterGroupSecond) {
      case 'WEEK_MONTH':
        return (
          <BaseCol>
            <BaseForm.Item name="select">
              <S.SelectBaseStyled
                defaultValue={selectFilterTypeSecond}
                value={selectFilterTypeSecond}
                placeholder={'Select type'}
                suffixIcon={<SvgTableCloseIcon />}
                onChange={type =>
                  onChangeSelectFilterSecond(type as SelectFilterType)
                }
                allowClear={false}
              >
                <Option value={SelectFilterEnum.WEEK}>
                  {t(R.strings.week)}
                </Option>
                <Option value={SelectFilterEnum.MONTH}>
                  {t(R.strings.month)}
                </Option>
              </S.SelectBaseStyled>
            </BaseForm.Item>
          </BaseCol>
        )
    }
  }, [selectFilterGroupSecond, isSelectFilterSecond, selectFilterTypeSecond])()

  const renderFilterMonth = useCallback(() => {
    if (!year) return

    if (isHaveChooseMonth) {
      switch (selectFilterGroup) {
        case 'WEEK_MONTH':
          if (
            selectFilterType === SelectFilterEnum.MONTH &&
            !isUsingWeekMonthCorrect
          )
            return
          break
        case 'MONTH_YEAR':
          if (selectFilterType === SelectFilterEnum.YEAR) return
          break
        case 'MONTH_QUARTER':
          if (selectFilterType === SelectFilterEnum.QUARTER) return
          break
        case 'WEEK_MONTH_QUARTER_YEAR':
          if (selectFilterType === SelectFilterEnum.QUARTER) return
          if (selectFilterType === SelectFilterEnum.YEAR) return
          break
      }

      switch (selectFilterGroupSecond) {
        case 'WEEK_MONTH':
          if (
            selectFilterTypeSecond === SelectFilterEnum.MONTH &&
            !isUsingWeekMonthCorrect
          )
            return
          break
      }

      return (
        <BaseCol>
          <BaseForm.Item>
            <S.Picker
              picker="month"
              onChange={onChangeMonth}
              format={FORMAT_ONLY_MONTH}
              value={month ? Dates.getDate(`${year}-${month}-01`) : undefined}
              allowClear={false}
            />
          </BaseForm.Item>
        </BaseCol>
      )
    }
  }, [
    isHaveChooseMonth,
    selectFilterType,
    month,
    year,
    onChangeMonth,
    selectFilterGroupSecond,
    selectFilterTypeSecond,
  ])()

  const onChangeWeek = (data: IWeekFilter) => {
    setWeek(data)
  }

  const renderFilterWeek = useCallback(() => {
    if (!month) {
      return
    }

    if (isHaveChooseWeek) {
      let isDisable = false
      switch (selectFilterGroup) {
        case 'WEEK_MONTH':
          if (selectFilterType === SelectFilterEnum.MONTH) {
            isDisable = true
          }

          break
        case 'MONTH_YEAR':
          if (selectFilterType === SelectFilterEnum.YEAR) {
            isDisable = true
          }
          break
        case 'WEEK_MONTH_QUARTER_YEAR':
          if (selectFilterType === SelectFilterEnum.YEAR) {
            isDisable = true
          }
          if (selectFilterType === SelectFilterEnum.MONTH) {
            isDisable = true
          }
          if (selectFilterType === SelectFilterEnum.QUARTER) {
            isDisable = true
          }
          break
      }
      if (isDisable) return
      return (
        <S.BaseColWrapper $isDisable={isDisable}>
          <BaseWeekPicker
            handleWeekChange={onChangeWeek}
            month={convertMonthAndYear.month}
            year={convertMonthAndYear.year}
          />
        </S.BaseColWrapper>
      )
    }
  }, [
    isHaveChooseMonth,
    selectFilterType,
    convertMonthAndYear,
    month,
    onChangeWeek,
  ])()

  const renderSelectYear = useCallback(() => {
    if (!isHaveChooseYear) return

    switch (selectFilterType) {
      case 'FROM_THEN_UTIL_NOW':
        return
    }

    return (
      <BaseCol>
        <BaseForm.Item>
          <S.Picker
            picker={'year'}
            onChange={onChangeYear}
            value={Dates.getDate(`${year}-01-01`)}
            allowClear={false}
          />
        </BaseForm.Item>
      </BaseCol>
    )
  }, [isHaveChooseYear, selectFilterType, month, year, onChangeYear])()

  const renderSelectQuarter = useCallback(() => {
    if (!isSelectQuarter) return

    switch (selectFilterType) {
      case 'YEAR':
        return
      case 'QUARTER':
        return (
          <BaseCol>
            <BaseForm.Item name="select">
              <S.SelectBaseStyled
                defaultValue={selectQuarter}
                value={selectQuarter}
                placeholder={'Select type'}
                suffixIcon={<SvgTableCloseIcon />}
                onChange={type =>
                  onChangeSelectQuarter(type as SelectQuarterEnum)
                }
                allowClear={false}
              >
                <Option value={SelectQuarterEnum.QUARTER_1}>
                  {t(R.strings.quarter_num, { quarter: 1 })}
                </Option>
                <Option value={SelectQuarterEnum.QUARTER_2}>
                  {t(R.strings.quarter_num, { quarter: 2 })}
                </Option>
                <Option value={SelectQuarterEnum.QUARTER_3}>
                  {t(R.strings.quarter_num, { quarter: 3 })}
                </Option>
                <Option value={SelectQuarterEnum.QUARTER_4}>
                  {t(R.strings.quarter_num, { quarter: 4 })}
                </Option>
              </S.SelectBaseStyled>
            </BaseForm.Item>
          </BaseCol>
        )
    }
  }, [isSelectQuarter, selectQuarter, selectFilterType])()

  return (
    <S.HeadWrapper gutter={20} align={'middle'}>
      <BaseCol>
        <S.TextFilter>{`${t(title18nKey)}:`}</S.TextFilter>
      </BaseCol>
      {renderSelectFilter}
      {renderSelectFilterSecond}
      {renderSelectYear}
      {renderSelectQuarter}
      {renderFilterMonth}
      {renderFilterWeek}
      {otherFilterComponent}
    </S.HeadWrapper>
  )
}
