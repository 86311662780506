import { FormInstance } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { ITreatmentInAppointmentSession } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TreatmentInPhaseModal } from '../../layouts/TreatmentScheduleLayout/layouts/TreatmentPhaseLayout/TreatmentPhaseItem/components'
import { AddTreatmentInTreatmentPhase } from '../../layouts/TreatmentScheduleLayout/layouts/TreatmentPhaseLayout/TreatmentPhaseItem/modal/AddTreatmentInTreatmentPhase'
import { DividerLine } from '../ModalComponent'

interface IProps {
  form: FormInstance<unknown>
  listTreatment?: ITreatmentInAppointmentSession[]
  onAddTreatmentInAppointmentSession?: (
    treatmentInAppointmentSession?: ITreatmentInAppointmentSession,
  ) => void
  onDelete?: (index?: number) => void
  index?: number
  handleSubmit?: (values: unknown) => void
  onChangeTreatmentInAppointmentSession?: (
    treatment?: ITreatmentInAppointmentSession,
  ) => void
}

export const ContentModalAppointmentSession = ({
  form,
  listTreatment = [],
  onAddTreatmentInAppointmentSession,
  onDelete,
  handleSubmit,
  onChangeTreatmentInAppointmentSession,
}: IProps) => {
  const { t } = useTranslation()

  return (
    <BaseForm form={form} onFinish={handleSubmit}>
      <DividerLine />
      <BaseRow>
        <InfoSide>
          <BaseSpace>
            <BaseForm.Item
              name={'appointmentSessionName'}
              label={t(R.strings.name_appointment_session)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.name_appointment_session),
                  }),
                },
              ]}
            >
              <BaseInput
                placeholder={t(R.strings.enter_name_appointment_session)}
              />
            </BaseForm.Item>
            <BaseText
              children={t(R.strings.treatment_information)}
              fontWeight="semibold"
              fontSize="xs"
            />
            <BaseForm.Item
              name={'symptomsDiagnosis'}
              label={t(R.strings.symptoms_diagnosis)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.symptoms_diagnosis),
                  }),
                },
              ]}
            >
              <TextAreaWrapper
                placeholder={t(R.strings.enter_symptoms_diagnosis)}
                showCount
                maxLength={300}
                rows={3}
              />
            </BaseForm.Item>
            <BaseForm.Item
              name={'treatmentDirection'}
              label={t(R.strings.treatment_direction)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.treatment_direction),
                  }),
                },
              ]}
            >
              <TextAreaWrapper
                placeholder={t(R.strings.enter_treatment_direction)}
                showCount
                maxLength={300}
                rows={3}
              />
            </BaseForm.Item>

            <BaseForm.Item
              name={'note'}
              label={t(R.strings.note)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.note),
                  }),
                },
              ]}
            >
              <TextAreaWrapper
                placeholder={t(R.strings.enter_note)}
                showCount
                maxLength={300}
                rows={4}
              />
            </BaseForm.Item>
          </BaseSpace>
        </InfoSide>
        <TreatmentSide>
          <BaseSpace>
            <BaseSpace>
              <BaseRow
                justify={'space-between'}
                align={'middle'}
                gutter={15}
                style={{ marginInline: 0 }}
              >
                <BaseCol>
                  <BaseText
                    children={t(R.strings.procedures_within_appointment)}
                    fontWeight="semibold"
                    fontSize="xs"
                  />
                </BaseCol>
                <BaseCol>
                  <AddTreatmentInTreatmentPhase
                    onChangeTreatmentInAppointmentSession={
                      onAddTreatmentInAppointmentSession
                    }
                  />
                </BaseCol>
              </BaseRow>

              <BaseSpace>
                {listTreatment.map((itm, idx) => {
                  return (
                    <TreatmentInPhaseModal
                      key={idx}
                      item={itm}
                      index={idx + 1}
                      onDelete={() => onDelete?.(idx)}
                      onChangeTreatment={(
                        treatment: ITreatmentInAppointmentSession,
                      ) => onChangeTreatmentInAppointmentSession?.(treatment)}
                    />
                  )
                })}
              </BaseSpace>
            </BaseSpace>
          </BaseSpace>
        </TreatmentSide>
      </BaseRow>
      <DividerLine />
    </BaseForm>
  )
}

const TreatmentSide = styled(BaseCol)`
  background-color: #f4f4f4;
  padding: ${PADDING.md};
  display: flex;
  flex: 1;
  border: 1px solid ${convertedVariables.neutralBlack9Color};
`

const InfoSide = styled(BaseCol)`
  padding: ${PADDING.md};
  display: flex;
  flex: 1;
`

const TextAreaWrapper = styled(TextArea)`
  margin-top: ${PADDING.xxxxs};
  .ant-input-data-count {
    font-weight: ${FONT_WEIGHT.medium};
    font-size: ${FONT_SIZE.xxs};
  }
`
