import { BaseButton } from 'app/components/common/BaseButton'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.xxs};
  background-color: ${convertedVariables.neutralBlack1Color};
`
export const ButtonPayment = styled(BaseButton)`
  background-color: ${convertedVariables.otherBlueColor};
  transition: 1s ease;
  border: unset !important;
  &:hover {
    border-color: ${convertedVariables.otherBlueColor} !important;
    transition: 1s ease;
  }
`
