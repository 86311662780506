import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { PatientSearchContainer } from 'app/containers/DentalTreatmentManagement/PatientSearch'

const PatientSearchPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.patient_search)}</PageTitle>
      <PatientSearchContainer />
    </>
  )
}
const PatientSearchPage = memo(PatientSearchPageCpn, isEqual)

export default PatientSearchPage
