import R from 'app/assets/R'
import { DateUtil } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import { dataNewDashboard } from './data'
import * as S from './styles'

export const NewsDoctorComponent = () => {
  const { t } = useTranslation()
  return (
    <S.RootWrapper>
      <div>
        {dataNewDashboard.map((item, index) => {
          return (
            <S.ItemWrapper key={index}>
              <S.TitleTextItem>{item?.title}</S.TitleTextItem>
              <S.FooterItemWrapper justify={'space-between'} align={'middle'}>
                <S.ReadMoreTextFooterItem>
                  {t(R.strings.show_less)}...
                </S.ReadMoreTextFooterItem>
                <S.DateTextFooterItem>
                  {DateUtil.formatHHssDDMMYYYY(item.date)}
                </S.DateTextFooterItem>
              </S.FooterItemWrapper>
            </S.ItemWrapper>
          )
        })}
      </div>
    </S.RootWrapper>
  )
}