// Message code define
export const TreatmentGroupMessageCode = {
  TreatmentGroupManagement: {
    successGetData: '80000',
    successPutData: '80000',
    successDeleteData: '80000',
    successPostData: '80001',
  },
  CostPlan: {
    successImport: '20003',
    successGetData: '20001',
  },
}

// Endpoint
export const TreatmentGroupEndPoint = {
  services: {
    requestGetData: '/treatment/v1/treatment-group/services',
    request: 'crm/v1/treatment-group',
  },
}

