import HeaderPage from 'app/components/header/components/HeaderPage'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import R from 'app/assets/R'
import {
  INVENTORY_WAREHOUSE_GOODS_ISSUE_CREATE_PATH,
  INVENTORY_WAREHOUSE_GOODS_ISSUE_FOR_UNIT_CREATE_PATH,
} from 'app/components/router/route-path'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { PlusOutlined } from '@ant-design/icons'
import { useContext, useEffect, useMemo, useState } from 'react'
import { WarehouseGoodsIssueContext } from '../../context'
import { HeadingTitlePage } from 'app/components/tables/BaseTableReport/styles'
import { CustomSelectInputSearch } from 'app/containers/InventoryManagement/WarehouseInventory/styles'
import {
  DropdownRender,
  SearchOutlinedStyled,
  SearchWrapper,
} from 'app/components/common/selects/SelectSearch/styles'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { get } from 'lodash'
import { Select } from 'antd'
import { useGetWarehouseUnit } from 'app/react-query/hook/warehouse-unit'
import { usePagination } from 'app/hook'
const { Option } = Select
const Header = ({ filter, handleChangeFilter }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { flattenDataList } = usePagination()

  const [clinics, setClinics] = useState<{ _id: string; name: string }[]>([])
  const { data: unitData } = useGetWarehouseUnit({})
  const { isForUnit } = useContext(WarehouseGoodsIssueContext)

  const units = useMemo(() => {
    return flattenDataList(unitData) ?? []
  }, [unitData])
  useEffect(() => {
    setClinics(units)

    if (!filter?.unitId) {
      const unitId = get(units, '[0]._id', '')
      handleChangeFilter({ ...filter, unitId: unitId })
    }
  }, [units])

  const handleSearchClinic = keyword => {
    const clinics = units?.filter(clinic =>
      clinic?.name?.toLowerCase()?.includes(keyword?.toLowerCase()),
    )
    setClinics(clinics)
  }
  return (
    <>
      {isForUnit ? (
        <>
          <BaseRow align={'middle'} justify={'space-between'}>
            <BaseRow align={'middle'} justify={'start'}>
              <HeadingTitlePage>
                {t(R.strings.warehouse_goods_issue_for_unit)}
              </HeadingTitlePage>
              <CustomSelectInputSearch
                placeholder={t(R.strings.select)}
                onChange={value =>
                  handleChangeFilter({ ...filter, unitId: String(value || '') })
                }
                value={filter?.unitId}
                filterOption={false}
                dropdownRender={menu => (
                  <DropdownRender>
                    <SearchWrapper>
                      <SearchOutlinedStyled
                        className="search__icon"
                        rev=""
                        size={20}
                      />
                      <BaseInput
                        className="search__input"
                        placeholder={t(R.strings.search)}
                        onChange={e => handleSearchClinic(e?.target?.value)}
                      />
                    </SearchWrapper>
                    {menu}
                  </DropdownRender>
                )}
              >
                {clinics?.map((clinic, index) => {
                  return (
                    <Option value={clinic?._id} key={index}>
                      {clinic?.name}
                    </Option>
                  )
                })}
              </CustomSelectInputSearch>
            </BaseRow>
            <BaseCol>
              <S.ButtonCreateAction
                onClick={() =>
                  navigate(
                    INVENTORY_WAREHOUSE_GOODS_ISSUE_FOR_UNIT_CREATE_PATH?.replace(
                      ':unitId',
                      filter?.unitId,
                    ),
                  )
                }
              >
                <BaseRow gutter={8}>
                  <BaseCol>
                    <PlusOutlined rev={undefined} />
                  </BaseCol>
                  <BaseCol>{t(R.strings.warehouse_goods_issue_add)}</BaseCol>
                </BaseRow>
              </S.ButtonCreateAction>
            </BaseCol>
          </BaseRow>
        </>
      ) : (
        <>
          <HeaderPage
            titleI18nKey={R.strings.warehouse_goods_issue}
            rightComponent={
              <>
                <BaseCol>
                  <S.ButtonCreateAction
                    onClick={() =>
                      navigate(INVENTORY_WAREHOUSE_GOODS_ISSUE_CREATE_PATH)
                    }
                  >
                    <BaseRow gutter={8}>
                      <BaseCol>
                        <PlusOutlined rev={undefined} />
                      </BaseCol>
                      <BaseCol>
                        {t(R.strings.warehouse_goods_issue_add)}
                      </BaseCol>
                    </BaseRow>
                  </S.ButtonCreateAction>
                </BaseCol>
              </>
            }
          />
        </>
      )}
    </>
  )
}

export default Header
