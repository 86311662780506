/* eslint-disable @typescript-eslint/no-explicit-any */
import { IParamsGetRoleManagement } from 'app/api/roles/models/role'
import { ParamsList } from 'parkway-web-common'

export const RoleKeys = {
  allRoleManagements: ['RoleManagements'] as const,
  listRoleManagements: () => [...RoleKeys.allRoleManagements, 'listRoleManagements'] as const,
  listRoleManagement: (filters?: IParamsGetRoleManagement) =>
    [...RoleKeys.listRoleManagements(), { filters }] as const,

  allRoleRights: ['RoleRights'] as const,
  listRoleRights: () => [...RoleKeys.allRoleRights, 'listRoleRights'] as const,
  listRoleRight: (filters?: ParamsList & { [key: string]: any }) =>
    [...RoleKeys.listRoleRights(), { filters }] as const,
}
