import R from 'app/assets/R'
import { SvgMedicalExaminationIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useTranslation } from 'react-i18next'
import { IAppointmentSession } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { AppointmentSessionAction } from '../../components'

export const TreatmentInformationLayout = ({
  item,
}: {
  item: IAppointmentSession
}) => {
  const { t } = useTranslation()
  return (
    <BaseSpace>
      <BaseRow align={'middle'} justify={'space-between'} gutter={15}>
        <BaseCol>
          <BaseRow gutter={15} align={'middle'}>
            <SvgMedicalExaminationIcon />
            <BaseCol>
              <BaseText
                children={t(R.strings.treatment_information)}
                fontSize="xs"
                fontWeight="semibold"
              />
            </BaseCol>
          </BaseRow>
        </BaseCol>
        <BaseCol>
          <AppointmentSessionAction />
        </BaseCol>
      </BaseRow>

      <BaseRow gutter={[15, 10]}>
        <BaseCol xl={5}>
          <BaseText children={t(R.strings.symptoms_diagnosis)} opacity="0.5" />
        </BaseCol>
        <BaseCol xl={19}>
          <BaseText
            children={item?.treatmentInformation?.symptomsDiagnosis}
            fontWeight="semibold"
          />
        </BaseCol>
      </BaseRow>

      <BaseRow gutter={[15, 10]}>
        <BaseCol xl={5}>
          <BaseText children={t(R.strings.treatment_direction)} opacity="0.5" />
        </BaseCol>
        <BaseCol xl={19}>
          <BaseText
            children={item?.treatmentInformation?.treatmentDirection}
            fontWeight="semibold"
          />
        </BaseCol>
      </BaseRow>

      <BaseRow gutter={[15, 10]}>
        <BaseCol xl={5}>
          <BaseText children={t(R.strings.note)} opacity="0.5" />
        </BaseCol>
        <BaseCol xl={19}>
          <BaseText
            children={item?.treatmentInformation?.note}
            fontWeight="semibold"
          />
        </BaseCol>
      </BaseRow>
    </BaseSpace>
  )
}
