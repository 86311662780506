import { useState } from 'react'

export const useHook = () => {
  const [isCollapse, setIsCollapse] = useState(false)

  const onChangeCollapse = () => {
    setIsCollapse(!isCollapse)
  }

  return {
    isCollapse,
    onChangeCollapse,
  }
}
