import { requestGetOrganizationalChart } from 'app/api/company/organizational-chart'
import {
  IOrganizationalChart,
  ResponseOrganizationalChartType,
} from 'app/api/company/organizational-chart/model'
import {
  SvgClinicIcon,
  SvgDownArrowIcon,
  SvgLeadingIconIcon,
  SvgMapPinIcon,
  SvgOrganizationalIcon,
} from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS } from 'parkway-web-common'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ItemProfileComponent } from '../ItemProfile'

interface IProps {
  keyIndex: string | number
  icon?: React.ReactNode
  name?: string
  description?: string
  total?: number
  extra?: React.ReactNode
  label?: React.ReactNode
  children?: React.ReactNode
  item?: IOrganizationalChart
  isShowIconExpanse?: boolean
}

export const ItemCollapseLayout = ({
  keyIndex,
  icon,
  description,
  name,
  extra,
  label,
  children,
  item,
  isShowIconExpanse = true,
}: IProps) => {
  const [detail, setDetail] = useState<IOrganizationalChart | undefined>(
    undefined,
  )

  const [loading, setLoading] = useState(false)

  const getDetail = async () => {
    try {
      setLoading(true)

      const res: ResponseOrganizationalChartType =
        await requestGetOrganizationalChart({
          unitId: item?._id,
        })

      res?.data && setDetail(res.data?.[0])
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDetail()
  }, [])

  const renderIcon = useCallback(() => {
    switch (item?.unitType?.name) {
      case 'Area':
        return <SvgMapPinIcon />
      case 'Clinic':
        return <SvgClinicIcon />

      default:
        return <SvgLeadingIconIcon />
    }
  }, [item])()

  return (
    <CollapseWrapper
      items={[
        {
          key: keyIndex,
          label: label ? (
            label
          ) : (
            <BaseRow align={'top'} gutter={[16, 8]}>
              <BaseCol />
              {icon ? icon : renderIcon}
              <BaseCol>
                <BaseSpace size={2}>
                  <BaseText
                    children={item?.name ?? name ?? '-'}
                    fontWeight="semibold"
                    fontSize="xs"
                  />
                  <BaseText
                    children={item?.unitType?.name ?? `${description ?? '-'}`}
                    fontSize="xxxs"
                    fontWeight="medium"
                  />
                </BaseSpace>
              </BaseCol>
              {loading && <Loading isAbsolute size="small" />}
            </BaseRow>
          ),
          extra: extra ? (
            extra
          ) : detail?.children?.length ? (
            <BaseCol>
              <BaseRow align={'middle'} gutter={8}>
                <SvgOrganizationalIcon />
                <BaseCol>
                  <BaseText
                    children={
                      detail?.children?.length ?? item?.numConnections ?? 0
                    }
                    fontWeight="semibold"
                    opacity="0.7"
                    fontSize="xs"
                  />
                </BaseCol>
              </BaseRow>
            </BaseCol>
          ) : null,
          children: children ? (
            children
          ) : (
            <BaseSpace>
              {detail?.managers?.map((manager, indx) => {
                return <ItemProfileComponent profile={manager} key={indx} />
              })}

              {detail?.vices?.length || detail?.profiles?.length ? (
                <BaseSpace
                  className={
                    detail?.managers?.length ? 'child' : 'child-of-child'
                  }
                >
                  
                  {detail?.vices?.map((manager, indx) => {
                    return (
                      <ItemProfileComponent
                        profile={manager}
                        className={
                          detail?.managers?.length
                            ? 'child-of-child-item'
                            : 'child'
                        }
                        key={indx}
                      />
                    )
                  })}

                  {detail?.profiles?.map((manager, indx) => {
                    return (
                      <ItemProfileComponent
                        profile={manager}
                        className={
                          detail?.managers?.length
                            ? 'child-of-child-item'
                            : 'child'
                        }
                        key={indx}
                      />
                    )
                  })}
                </BaseSpace>
              ) : null}

              <BaseSpace className="child">
                {detail?.children?.map((itm, indxChid1) => {
                  return (
                    <ItemCollapseLayout
                      key={indxChid1}
                      keyIndex={indxChid1}
                      total={0}
                      name={itm?.name ?? '-'}
                      description={itm?.unitType?.name}
                      item={itm}
                    />
                  )
                })}
              </BaseSpace>
            </BaseSpace>
          ),
        },
      ]}
      expandIcon={({ isActive }) =>
        !isShowIconExpanse || !detail?.children?.length ? (
          <div
            style={{
              width: 20,
              height: 20,
            }}
          />
        ) : (
          <SvgDownArrowIconWrapper $isActive={isActive} />
        )
      }
    />
  )
}

const CollapseWrapper = styled(BaseCollapse)`
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  background-color: transparent !important;

  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-content {
    border-top: 1px solid ${convertedVariables.neutralBlack9Color};
    background-color: transparent !important;
  }

  .ant-collapse-header {
    background-color: transparent !important;
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
    padding: 0px !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .child {
    padding-left: 50px;
  }

  .child-of-child {
    padding-left: 0px;
  }

  .child-of-child-item {
    padding-left: 40px;
  }
`

const SvgDownArrowIconWrapper = styled(SvgDownArrowIcon)<{
  $isActive?: boolean
}>`
  transform: rotate(${props => (props?.$isActive ? 0 : -180)}deg);
  transition: 0.5s ease;
`
