import { Rule } from 'antd/es/form'
import { ClinicTypeEnum } from '../../type'

export interface IFormData {
  code?: string
  codeChildren?: string
  name?: string
  unitGroupId?: string
  unitTypeId?: string
  jobTitleIdManagement?: string
  description?: string
  _id?: string
  status?: string
  parentid?: string
  nameParent?: string
  jobTitleIdVice?: string
  clinicType?: ClinicTypeEnum
}

export interface IFormRule {
  code?: Rule[]
  unitGroupId?: Rule[]
  unitTypeId?: Rule[]
  jobTitleIdManagement?: Rule[]
  name?: Rule[]
  description?: Rule[]
  _id?: Rule[]
  parentid?: Rule[]
  status?: Rule[]
  clinicType?: Rule[]
}

export enum StatusUnitEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
  deleted = 'deleted',
}

export const initValues: IFormData = {
  code: '',
  name: '',
  _id: '',
  description: '',
  status: StatusUnitEnum.active,
}

export type StatusUnitType = keyof typeof StatusUnitEnum

export interface IPropsUpdateUnit {
  fetchTable: () => void
  id?: string | number
}
