import { convertedVariables } from "app/styles/themes/themeVariables";
import { DateProfileMonthlyRosterTypeEnum } from "./type";

export const DefineDateType = {
    [DateProfileMonthlyRosterTypeEnum.F]: {
        color: convertedVariables.statesGreenColor,
    },
    [DateProfileMonthlyRosterTypeEnum.UL]: {
        color: convertedVariables.statesRedColor,
    },
    [DateProfileMonthlyRosterTypeEnum.E]: {
        color: '#216AE2',
    },
    [DateProfileMonthlyRosterTypeEnum.L]: {
        color: '#216AE2',
    },
    [DateProfileMonthlyRosterTypeEnum.DO]: {
        color: '#629DAA',
    },
    [DateProfileMonthlyRosterTypeEnum.AL]: {
        color: "#9747FF",
    },
    [DateProfileMonthlyRosterTypeEnum.PH]: {
        color: "#9747FF",
    },
    [DateProfileMonthlyRosterTypeEnum.PL]: {
        color: "#9747FF",
    },
    [DateProfileMonthlyRosterTypeEnum.SL]: {
        color: "#9747FF",
    },
    [DateProfileMonthlyRosterTypeEnum.Alc]: {
        color: "#9747FF",
    },
    [DateProfileMonthlyRosterTypeEnum.HL]: {
        color: "#9747FF",
    },
}