import R from 'app/assets/R'
import { SvgBlockItemIcon, SvgFoldersIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import {
  getReportDisplayType,
  setReportDisplayType,
} from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { PADDING } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { BaseSpace } from '../BaseSpace'
import * as S from './styles'
import { IChildReport, IGroupReport, IProps, ShowListType } from './type'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { FunctionPermissionEnum } from 'app/common/permission-module'

export const BaseLayoutReport = ({
  data,
  titleI18nKey,
  modulePermission,
  type,
}: IProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isHavePermissionFunction } = useVerifyPermissionAndRedirect()
  const currentDisplayListType = getReportDisplayType()

  const showType = () => {
    if (type) return type
    return currentDisplayListType ? +currentDisplayListType : ShowListType.BLOCK
  }

  const [typeList, setTypeList] = useState(showType())

  const onClickSetTypeList = () => {
    setReportDisplayType(ShowListType.LIST)
    setTypeList(ShowListType.LIST)
  }

  const onClickSetTypeBlock = () => {
    setReportDisplayType(ShowListType.BLOCK)
    setTypeList(ShowListType.BLOCK)
  }

  const onNavigate = (path?: string) => {
    if (path) {
      navigate(path)
    }
  }

  const isHavePermission = (permission?: FunctionPermissionEnum) => {
    if (!modulePermission || !permission) return true
    return isHavePermissionFunction(modulePermission, permission)
  }

  const dataHavePermissionDisplay = useMemo(() => {
    const dataPermission: IGroupReport[] = []

    data?.map(itm => {
      const permissionFunctions: IChildReport[] = []

      itm?.reports?.forEach(itmChild => {
        if (!itmChild?.permission) {
          permissionFunctions.push(itmChild)
        } else if (isHavePermission(itmChild?.permission)) {
          permissionFunctions.push(itmChild)
        }
      })

      if (permissionFunctions?.length) {
        dataPermission.push({
          ...itm,
          reports: permissionFunctions,
        })
      }
    })
    return dataPermission
  }, [data, modulePermission])

  const RenderListType = (groupItem: IGroupReport) => {
    return (
      <S.ListWrapper>
        {groupItem?.reports?.map((report, rIndex) => {
          return (
            <S.ListItemWrapper
              justify={'space-between'}
              align={'middle'}
              key={rIndex}
              onClick={() => onNavigate(report?.path)}
            >
              <BaseRow align={'middle'}>
                <S.LeftListItemPoint />
                {!report?.isHideLabel && (
                  <S.LabelListText>
                    {report?.labelI18nKey
                      ? t(report?.labelI18nKey)
                      : report?.label}
                  </S.LabelListText>
                )}
                <S.CenterLineSelection />
                <S.DescriptionList>
                  <span>
                    {report?.descriptionI18nKey
                      ? t(report?.descriptionI18nKey)
                      : report?.description}
                  </span>
                </S.DescriptionList>
              </BaseRow>
              <S.DateBlock>
                {`${t(R.strings.last_time_update)}: ${report?.updateAt}`}
              </S.DateBlock>
            </S.ListItemWrapper>
          )
        })}
      </S.ListWrapper>
    )
  }

  const RenderBlockType = (groupItem: IGroupReport) => {
    return (
      <S.BlockWrapper gutter={[16, 16]}>
        {groupItem?.reports?.map((report, rIndex) => {
          return (
            <BaseCol
              key={rIndex}
              span={6}
              onClick={() => onNavigate(report?.path)}
            >
              <S.BlockItemWrapper>
                <BaseSpace size={10} direction="vertical">
                  <BaseRow align={'middle'} wrap={false}>
                    <SvgBlockItemIcon />
                    <BaseRow align={'middle'}>
                      <S.FirstLabelHeadBlock>
                        {t(groupItem?.titleI18nKey || '')}
                      </S.FirstLabelHeadBlock>
                      <S.LabelHeadBlock>
                        {report?.labelI18nKey
                          ? t(report?.labelI18nKey)
                          : report?.label}
                      </S.LabelHeadBlock>
                    </BaseRow>
                  </BaseRow>
                  <S.DescriptionBlock>
                    <span>
                      {report?.descriptionI18nKey
                        ? t(report?.descriptionI18nKey)
                        : report?.description}
                    </span>
                  </S.DescriptionBlock>
                </BaseSpace>
                <BaseSpace direction="vertical">
                  <S.DashedLine dashed />
                  <S.DateBlock>
                    {`${t(R.strings.last_time_update)}: ${report?.updateAt}`}
                  </S.DateBlock>
                </BaseSpace>
              </S.BlockItemWrapper>
            </BaseCol>
          )
        })}
      </S.BlockWrapper>
    )
  }

  return (
    <S.RootWrapper>
      <S.HeadPageWrapper justify={'space-between'} align={'middle'}>
        <S.HeadingTitlePage>{t(titleI18nKey)}</S.HeadingTitlePage>
        <S.SelectionWrapper gutter={10} align={'middle'}>
          <BaseCol span={10}>
            <S.ButtonSelection onClick={onClickSetTypeList}>
              <S.ListSelectIcon
                $isList={isEqual(typeList, ShowListType.LIST)}
              />
            </S.ButtonSelection>
          </BaseCol>
          <BaseCol>
            <S.CenterLineSelection />
          </BaseCol>
          <BaseCol span={10}>
            <S.ButtonSelection onClick={onClickSetTypeBlock}>
              <S.BlockSelectIcon
                $isBlock={isEqual(typeList, ShowListType.BLOCK)}
              />
            </S.ButtonSelection>
          </BaseCol>
        </S.SelectionWrapper>
      </S.HeadPageWrapper>

      {dataHavePermissionDisplay.map((dataItem, index) => {
        return (
          <S.GroupWrapper key={index}>
            <BaseRow
              align={'bottom'}
              gutter={[20, 10]}
              wrap={false}
              style={{ paddingInline: PADDING.xxxs }}
            >
              <SvgFoldersIcon />
              <S.GroupHeadTitle>
                {dataItem?.titleI18nKey
                  ? t(dataItem?.titleI18nKey)
                  : dataItem?.title}
              </S.GroupHeadTitle>
              <S.GroupHeadLine />
            </BaseRow>

            {isEqual(typeList, ShowListType.BLOCK)
              ? RenderBlockType({
                  ...dataItem,
                  titleI18nKey: dataItem?.titleI18nKey || dataItem?.title,
                })
              : RenderListType(dataItem)}
          </S.GroupWrapper>
        )
      })}
    </S.RootWrapper>
  )
}
