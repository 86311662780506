import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import PatientOudatedContainer from 'app/containers/Accounting/PatientOutdated'

const AccountingPatientOutdatedCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>
        {t(R.strings.patient_outdated)}
      </PageTitle>
      <PatientOudatedContainer />
    </>
  )
}
const AccountingPatientOutdated = memo(
  AccountingPatientOutdatedCpn,
  isEqual,
)

export default AccountingPatientOutdated
