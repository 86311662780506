import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import HeaderPage from 'app/components/header/components/HeaderPage'
import * as S from 'app/components/tables/BaseTableReport/styles'
import 'dayjs/locale/vi'
import { useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Filter } from './components/Filter'
import { OrthodonticWithoutPaidProvider } from './context'
import { TabsLayout } from './layouts'
import { RootWrapper } from './styles'

const PatientScheduleWithoutPaidContainer = () => {
  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.ACCOUNTING,
      FunctionPermissionEnum.READ_DAILY_INVOICE,
      R.strings.role,
    )
  }, [])

  return (
    <OrthodonticWithoutPaidProvider>
      <S.BaseReportWrapper>
        <HeaderPage
          titleI18nKey={R.strings.orthodontic_patient_list_zero_payment}
        />
        <RootWrapper>
          <Filter />
          <TabsLayout />
        </RootWrapper>
      </S.BaseReportWrapper>
    </OrthodonticWithoutPaidProvider>
  )
}

export default PatientScheduleWithoutPaidContainer
