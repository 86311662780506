/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPermission } from '../../type'
import { IPropsUpdatePermission } from './type'

export const useModalHook = ({
  permissions,
}: IPropsUpdatePermission) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [permissionSelected, setPermissionSelected] = useState<IPermission[]>(
    [],
  )


  const onChangePermission = (newPermission: IPermission[]) => {
    setPermissionSelected(newPermission)
  }

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const permissionAfterSelected = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize
    const endIndex = startIndex + pageSize
    const listPermissionAfterPagination = permissionSelected?.slice(
      startIndex,
      endIndex,
    )
    return listPermissionAfterPagination
  }, [permissionSelected, currentPage, pageSize])

  useEffect(() => {
    setPermissionSelected(permissions ?? [])
  }, [permissions])

  const onChangePageSize = (newPageSize: number) => {
    setPageSize(newPageSize)
  }

  const onChangePage = (page: number) => {
    setCurrentPage(page)
  }

  return {
    visible,
    t,
    showModal,
    handleCancel,
    onChangePermission,
    permissionSelected,
    permissionAfterSelected,
    pageSize,
    currentPage,
    onChangePageSize,
    onChangePage,
  }
}
