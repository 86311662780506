/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetAllIndustryManagement } from 'app/api/industry'
import { IndustryMessageCode } from 'app/api/industry/constant'
import {
  IIndustry,
  IParamsGetAllIndustry,
} from 'app/api/industry/model/management'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useInfiniteQuery } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { IndustryKeys } from '../query-key/industry'
import { IPayLoadGetList } from 'app/common/interface'

export function useGetIndustryList(params?: IPayLoadGetList, enabled = true) {
  const token = getToken()
  return useInfiniteQuery<
    IResponseQueryList<IIndustry[]>,
    IParamsGetAllIndustry
  >(
    IndustryKeys.listIndustry(),
    async () => {
      try {
        const res: ResponseType<IIndustry[]> =
          await requestGetAllIndustryManagement({
            ...params,
            includes: 'skills',
          })

        if (
          isEqual(
            res?.msgcode,
            IndustryMessageCode.IndustryManagement.successGetData,
          )
        ) {
          const data: IIndustry[] = res?.data || []
          return {
            data,
            total: data.length ?? 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
