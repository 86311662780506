export const notifications = [
  {
    id: 2,
    description: 'loginAttempt',
  },
  {
    id: 1,
    description: 'successPayment',
  },
  {
    id: 3,
    description: 'serverError',
  },
]
