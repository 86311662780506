import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import DividerLine from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/DividerLine'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING, formatMoney } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHook } from './hook'
import { HeadLayout, ProductServiceItem } from './components'
import { ShoppingOutlined, WalletOutlined } from '@ant-design/icons'
import {
  getArray,
  randomMoney,
} from 'app/components/tables/common-table/constant'
import _ from 'lodash'

export const TransactionHistoryItem = () => {
  const { t } = useTranslation()
  const { isCollapse, onChangeCollapse } = useHook()

  return (
    <RootWrapper>
      <HeadLayout />
      <DividerLine />
      {isCollapse ? (
        <ContentItemWrapper size={5}>
          <HeadItemWrapper align={'middle'} gutter={15} wrap={false}>
            <BaseCol xl={1}>
              <BaseText
                children={t(R.strings.ordinal_number)}
                opacity="0.7"
                fontSize="xxxs"
                fontWeight="bold"
              />
            </BaseCol>
            <BaseCol xl={15}>
              <BaseText
                children={t(R.strings.product_service)}
                opacity="0.7"
                fontSize="xxxs"
                fontWeight="bold"
              />
            </BaseCol>
            <BaseCol xl={2}>
              <BaseText
                children={t(R.strings.quantity)}
                opacity="0.7"
                fontSize="xxxs"
                fontWeight="bold"
                textAlign="center"
              />
            </BaseCol>
            <BaseCol xl={2}>
              <BaseText
                children={t(R.strings.unit_price)}
                opacity="0.7"
                fontSize="xxxs"
                fontWeight="bold"
                textAlign="center"
              />
            </BaseCol>
            <BaseCol xl={2}>
              <BaseText
                children={t(R.strings.discount)}
                opacity="0.7"
                fontSize="xxxs"
                fontWeight="bold"
                textAlign="center"
              />
            </BaseCol>
            <BaseCol xl={2}>
              <BaseText
                children={t(R.strings.total_amount)}
                opacity="0.7"
                fontSize="xxxs"
                fontWeight="semibold"
                textAlign="center"
              />
            </BaseCol>
          </HeadItemWrapper>
          <BaseSpace size={10}>
            {getArray(_.random(5)).map(itm => {
              return <ProductServiceItem index={itm} />
            })}
          </BaseSpace>
        </ContentItemWrapper>
      ) : null}

      <FooterItemWrapper
        align={'middle'}
        gutter={[15, 10]}
        justify={'space-between'}
      >
        <BaseCol>
          <BaseRow align={'middle'} gutter={15} wrap={false}>
            <BaseCol>
              <BaseRow align={'middle'} gutter={5} wrap={false}>
                <BaseCol>
                  <ShoppingOutlined rev={undefined} />
                </BaseCol>
                <BaseCol>
                  <BaseText
                    children={`2(${'Gói'})`}
                    fontWeight="semibold"
                    opacity="0.7"
                  />
                </BaseCol>
              </BaseRow>
            </BaseCol>
            <BaseCol>
              <BaseRow align={'middle'} gutter={5} wrap={false}>
                <BaseCol>
                  <WalletOutlined rev={undefined} />
                </BaseCol>
                <BaseCol>
                  <BaseText
                    children={`${formatMoney(randomMoney())}`}
                    fontWeight="semibold"
                    colorText="statesGreenColor"
                  />
                </BaseCol>
              </BaseRow>
            </BaseCol>
          </BaseRow>
        </BaseCol>
        <BaseCol>
          <BaseButton type="link" onClick={onChangeCollapse}>
            <BaseText
              children={
                isCollapse ? t(R.strings.collapse) : t(R.strings.see_detail)
              }
              colorText="collapseBackgroundColor"
              fontSize="xxxs"
              fontWeight="medium"
            />
          </BaseButton>
        </BaseCol>
      </FooterItemWrapper>
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};
`

const FooterItemWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
  cursor: pointer;
`

const ContentItemWrapper = styled(BaseSpace)`
  background-color: #fcfcfc;
  padding: ${PADDING.md};
`

const HeadItemWrapper = styled(BaseRow)`
  padding: ${PADDING.xxs};
`
