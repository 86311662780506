/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { dataAppointmentSessions } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/constant'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { StatusServiceTreatmentScheduleEnum } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/enum'
import { IRefModal } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/components'
import {
  IAppointmentSession,
  ITreatmentInAppointmentSession,
  ITreatmentPhaseTreatmentSchedule,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { FORMAT_CENTER_YYYY_MM_DD, getRandomUuid, moment } from 'parkway-web-common'
import {
  MutableRefObject,
  createContext,
  useContext,
  useRef,
  useState,
} from 'react'
import { IFormTreatmentPhase } from './type'
import { isEqual } from 'lodash'

interface IDataAddTreatmentPhaseContext {
  listAppointmentSession?: IAppointmentSession[]
  modalRef?: MutableRefObject<IRefModal>
  form?: FormInstance<IFormTreatmentPhase>
  handleSubmit?: (values?: IFormTreatmentPhase) => void
  onAddListAppointmentSession?: (
    appointmentSession: IAppointmentSession,
  ) => void
  onDeleteAppointmentSession?: (index: number) => void
  onAddTreatmentInAppointmentSession?: ({
    newTreatment,
    appointmentSessionId,
  }: {
    appointmentSessionId?: string
    newTreatment: ITreatmentInAppointmentSession
  }) => void

  onChangeTreatmentInAppointmentSession?: ({
    newTreatment,
    appointmentSessionId,
  }: {
    appointmentSessionId?: string
    newTreatment: ITreatmentInAppointmentSession
  }) => void

  onDeleteTreatmentInAppointmentSession?: ({
    indexTreatment,
    appointmentSessionId,
  }: {
    appointmentSessionId?: string
    indexTreatment: number
  }) => void
}

export const AddTreatmentPhaseContext =
  createContext<IDataAddTreatmentPhaseContext>({})

export const ProfilePatientProvider = ({
  children,
  idSchedule,
}: {
  idSchedule?: string
  children: any
}) => {
  const { onAddTreatmentPhaseInTreatmentSchedule } = useProfilePatientContext()

  const [form] = BaseForm.useForm<IFormTreatmentPhase>()
  const [listAppointmentSession, setListAppointmentSession] = useState<
    IAppointmentSession[]
  >(dataAppointmentSessions)

  const modalRef = useRef<IRefModal>({})

  const handleSubmit = (values?: IFormTreatmentPhase) => {
    if (!idSchedule) return
    const treatmentPhase: ITreatmentPhaseTreatmentSchedule = {
      appointmentSession: listAppointmentSession,
      name: values?.phaseName ?? '',
      status: StatusServiceTreatmentScheduleEnum.InTreatment,
      endDate: moment(values?.endDate).format(FORMAT_CENTER_YYYY_MM_DD),
      startDate: moment(values?.startDate).format(FORMAT_CENTER_YYYY_MM_DD),
      id: getRandomUuid(),
      phaseApprover: {
        jobTitle: "Bác sĩ",
        levelName: '',
        name: 'Phạm Văn Khánh',
        id: getRandomUuid()
      },
      phaseAttendingPhysician: {
        jobTitle: "Bác sĩ",
        levelName: '',
        name: 'Lê Văn Minh',
        id: getRandomUuid()
      }
    }

    onAddTreatmentPhaseInTreatmentSchedule?.({
      id: idSchedule,
      treatmentPhase,
    })

    form?.resetFields()
    setListAppointmentSession([])
    modalRef?.current?.hide?.()
  }

  const onAddListAppointmentSession = (
    appointmentSession: IAppointmentSession,
  ) => {
    setListAppointmentSession(prev => prev?.concat([appointmentSession]))
  }

  const onDeleteAppointmentSession = (index: number) => {
    setListAppointmentSession(prev => prev?.filter((_, idx) => idx !== index))
  }

  const onAddTreatmentInAppointmentSession = ({
    newTreatment,
    appointmentSessionId,
  }: {
    appointmentSessionId?: string
    newTreatment: ITreatmentInAppointmentSession
  }) => {
    setListAppointmentSession(prev => {
      return prev?.map(item => {
        if (item?.id === appointmentSessionId) {
          return {
            ...item,
            treatmentInAppointmentSession:
              item?.treatmentInAppointmentSession?.concat([newTreatment]),
          }
        }
        return item
      })
    })
  }

  const onChangeTreatmentInAppointmentSession = ({
    newTreatment,
    appointmentSessionId,
  }: {
    appointmentSessionId?: string
    newTreatment: ITreatmentInAppointmentSession
  }) => {
    setListAppointmentSession(prev => {
      return prev?.map(item => {
        if (item?.id === appointmentSessionId) {
          return {
            ...item,
            treatmentInAppointmentSession:
              item?.treatmentInAppointmentSession?.map((itm) => {
                if (isEqual(itm?.id, newTreatment?.id)) {
                  return newTreatment
                }
                return itm
              }),
          }
        }
        return item
      })
    })
  }

  const onDeleteTreatmentInAppointmentSession = ({
    indexTreatment,
    appointmentSessionId,
  }: {
    appointmentSessionId?: string
    indexTreatment: number
  }) => {
    setListAppointmentSession(prev => {
      return prev?.map(item => {
        if (item?.id === appointmentSessionId) {
          return {
            ...item,
            treatmentInAppointmentSession:
              item?.treatmentInAppointmentSession?.filter(
                (_, idx) => idx !== indexTreatment,
              ),
          }
        }
        return item
      })
    })
  }

  return (
    <AddTreatmentPhaseContext.Provider
      value={{
        form,
        modalRef,
        handleSubmit,
        listAppointmentSession,
        onAddListAppointmentSession,
        onAddTreatmentInAppointmentSession,
        onDeleteAppointmentSession,
        onDeleteTreatmentInAppointmentSession,
        onChangeTreatmentInAppointmentSession
      }}
    >
      {children}
    </AddTreatmentPhaseContext.Provider>
  )
}

export function useAddTreatmentPhaseContext() {
  const context = useContext(AddTreatmentPhaseContext)

  if (context === undefined) {
    throw new Error(
      'useAddTreatmentPhaseContext must be used within a AddTreatmentPhaseContext',
    )
  }
  return context
}
