/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestExportExcelReport14 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import {
  GenderReport14Enum,
  IFilterReport14,
  IParamsGetReport14,
  ServiceDataReport14,
  TypeReport14Enum,
} from 'app/api/report/model/report-14'
import R from 'app/assets/R'
import { getTotalWeekNumbersOfMonth } from 'app/common/helpers'
import {
  ExpandedTableValue,
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import {
  IBaseColumnReport,
  IOnCell,
} from 'app/components/tables/BaseTableReport/type'
import { getArray } from 'app/components/tables/common-table/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { convertToTitleCase } from 'app/hook'
import {
  Pagination,
  ResponseType,
  moment
} from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataReport014 } from './custom-hook/data'
import { AgeGroupEnum, GenderEnum } from './enum'
moment.locale('en', {
  week: {
    dow: 1,
  },
});

export const useCommonHook = ({
  expandedRowKeys,
  typeHook,
  handleExpand,
  currentFilter,
  fetchLoading,
}: {
  expandedRowKeys: number[]
  fetchLoading: (value: boolean) => void
  typeHook: TypeReport14Enum
  fetch: (pagination: Pagination, params?: IFilterReport14) => void
  handleExpand: (isExpanded: boolean, key?: number) => void
  currentFilter?: IFilterReport14
}) => {
  const { t } = useTranslation()

  const exportExcel = async () => {
    try {
      if (!currentFilter?.year) return
      const ageData = convertAgeGroupToArrayAge(currentFilter?.age)
      let params: IParamsGetReport14 = {
        year: Number(currentFilter?.year),
        patientGender: convertGenderToParams(currentFilter?.gender),
        location: currentFilter?.clinic,
        patientAgeFrom: ageData?.patientAgeFrom,
        patientAgeTo: ageData?.patientAgeTo,
      }

      switch (typeHook) {
        case TypeReport14Enum.CASE:
          params = {
            ...params,
            exportType: 'CASE',
          }
          break

        case TypeReport14Enum.REVENUE:
          params = {
            ...params,
            exportType: 'REVENUE',
          }
          break
      }
      fetchLoading(true)
      const res: ResponseType<{ url: string }> =
        await requestExportExcelReport14(params)

      if (res?.msgcode === ReportMessageCode.Report14.successGetData) {
        window.open(res?.data?.url ?? '')
        notificationController.success({
          message: t(R.strings.download_excel_success),
        })
      }
    } catch (error: any) {
      console.log(error)
      notificationController.error(error)
    } finally {
      fetchLoading(false)
    }
  }

  function getWeeksInMonth(year: number, month: number): number {
    return getTotalWeekNumbersOfMonth(year, month)
  }

  const columns = useMemo(() => {
    type TypeColumn = IDataReport014

    const onCellGroupService = (record: TypeColumn): IOnCell => {
      if (record?.isTotalTable && expandedRowKeys.length) return { colSpan: 2 }

      if (record?.isTotalTable && !expandedRowKeys.length) return { colSpan: 1 }

      const isExpanded = expandedRowKeys.includes(record?.key ?? 0)
      if (!isExpanded && record?.children && expandedRowKeys?.length) {
        return { rowSpan: 1, colSpan: 2 }
      }

      if (!isExpanded && record?.children) {
        return { rowSpan: 1 }
      }

      if (isExpanded && !record?.children?.length) {
        return { rowSpan: 1 }
      }

      return {
        rowSpan: record.children?.length ? record.children?.length + 1 : 0,
      }
    }

    const onCellService = (record: TypeColumn): IOnCell => {
      if (record?.isTotalTable) {
        return { colSpan: 0 }
      }
      const isExpanded = expandedRowKeys.includes(record.key ?? 0)

      if (!isExpanded && record?.children && !!expandedRowKeys?.length) {
        return { colSpan: 0 }
      }
      return {}
    }

    let options: IBaseColumnReport<TypeColumn> = [
      convertColumnTable<TypeColumn>({
        title: t(R.strings.group_service),
        key: 'groupService',
        onCell: onCellGroupService,
        fixed: 'left',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          if (record?.isTotalTable) {
            return (
              <ExpandedTableValue
                value={convertToTitleCase(record?.groupService ?? '')}
                isTotalTable
              />
            )
          }

          const isExpanded = expandedRowKeys.includes(record.key ?? 0)

          if (!record?.children) return null

          const _handleExpand = () => {
            return handleExpand(!isExpanded, record?.key)
          }

          return (
            <ExpandedTableValue
              isExpanded={isExpanded}
              handleExpand={_handleExpand}
              value={convertToTitleCase(record?.groupService ?? '')}
            />
          )
        },
      }),
    ]

    if (expandedRowKeys?.length) {
      options = options?.concat([
        convertColumnTable<TypeColumn>({
          title: t(R.strings.service),
          key: 'service',
          onCell: onCellService,
          fixed: 'left',
          classNameWidthColumnOverwrite: 'very-big-column',
          render: (_, record) => {
            return (
              <RenderValueTableReport
                record={record}
                valueString={record?.service}
                expandedRowKeys={expandedRowKeys}
                justify="start"
              />
            )
          },
        }),
      ])
    }

    const months = getArray(12)?.map(month => {
      const numberWeeksOfMonth = getWeeksInMonth(
        +(currentFilter?.year ?? moment().year),
        month,
      )
      return {
        month,
        numberWeeksOfMonth,
      }
    })

    const monthsOptions = months?.map(month => {
      const weeksOfMonth = getArray(month?.numberWeeksOfMonth)?.map(itm => {
        return convertColumnTable<any>({
          title: t(R.strings.week_no, { week: itm }),
          key: `M${month.month}W${itm}`,
          render: (_, record) => {
            return RenderValueTableReport({
              record,
              firstLevelValue: record?.[`M${month.month}W${itm}${typeHook}`],
              secondLevelValue: record?.[`M${month.month}W${itm}Total${typeHook}`],
              isFillBlueInTotal: true,
              expandedRowKeys,
              percent: record?.[`M${month.month}W${itm}Percent${typeHook}`],
              percentTotal:
                record?.[`M${month.month}W${itm}PercentTotal${typeHook}`],
              isShowPercent: true,
              isShowValueUpAndDown: true,
              prefix: typeHook === TypeReport14Enum.CASE ? '' : undefined,
              decimal: 2,
            })
          },
        })
      })
      return {
        title: t(R.strings.month_no, { month: month?.month }),
        children: weeksOfMonth,
      }
    })

    const monthsTotal = getArray(12).map(itm => {
      return convertColumnTable<any>({
        title: t(R.strings.month_no, { month: itm }),
        key: `M${itm}`,
        render: (_, record) => {
          return RenderValueTableReport({
            record,
            firstLevelValue: record?.[`M${itm}${typeHook}`],
            secondLevelValue: record?.[`M${itm}Total${typeHook}`],
            isFillBlueInTotal: true,
            expandedRowKeys,
            percent: record?.[`M${itm}Percent${typeHook}`],
            percentTotal: record?.[`M${itm}PercentTotal${typeHook}`],
            isShowPercent: true,
            isShowValueUpAndDown: true,
            prefix: typeHook === TypeReport14Enum.CASE ? '' : undefined,
            decimal: 2,
          })
        },
      })
    })

    const quartersTotal = getArray(4).map(itm => {
      return convertColumnTable<any>({
        title: t(R.strings.quarter_num, { quarter: itm }),
        key: `Q${itm}`,
        render: (_, record) => {
          return RenderValueTableReport({
            record,
            firstLevelValue: record?.[`Q${itm}${typeHook}`],
            secondLevelValue: record?.[`Q${itm}Total${typeHook}`],
            isFillBlueInTotal: true,
            expandedRowKeys,
            percent: record?.[`Q${itm}Percent${typeHook}`],
            percentTotal: record?.[`Q${itm}PercentTotal${typeHook}`],
            isShowPercent: true,
            isShowValueUpAndDown: true,
            prefix: typeHook === TypeReport14Enum.CASE ? '' : undefined,
            decimal: 2,
          })
        },
      })
    })

    return [
      ...options,
      {
        title:
          typeHook === TypeReport14Enum.CASE
            ? t(R.strings.report_14_content_number_of_case)
            : t(R.strings.revenue),
        children: [
          ...monthsOptions,
          ...monthsTotal,
          ...quartersTotal,
          convertColumnTable<any>({
            title: t(R.strings.year_number, { year: currentFilter?.year }),
            key: `Y${currentFilter?.year}`,
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.[`Y${currentFilter?.year}${typeHook}`],
                secondLevelValue: record?.[`Y${currentFilter?.year}Total${typeHook}`],
                isFillBlueInTotal: true,
                expandedRowKeys,
                percent: record?.[`Y${currentFilter?.year}Percent${typeHook}`],
                percentTotal:
                  record?.[`Y${currentFilter?.year}Percent${typeHook}`],
                isShowPercent: true,
                isShowValueUpAndDown: true,
                prefix: typeHook === TypeReport14Enum.CASE ? '' : undefined,
                decimal: 2,
              })
            },
          }),
        ],
      },
    ]
  }, [t, expandedRowKeys, typeHook, currentFilter])

  return {
    columns,
    exportExcel,
  }
}

export const convertDataApiToDataTableReport14Case = ({
  serviceData,
  type,
}: {
  serviceData: ServiceDataReport14
  type: 'total' | 'child'
}) => {
  let dataObjectTable: any = {}

  const months = serviceData.months

  const quarters = serviceData?.quarters

  const years = serviceData?.years

  const monthsOfMonthKeys = Object?.keys(months)
  switch (type) {
    case 'total':
      monthsOfMonthKeys?.forEach(keyOfMonth => {
        const monthData = serviceData?.months?.[keyOfMonth]
        const weekData = monthData?.weeks
        for (const week in weekData) {
          const weekDetail = weekData[week]
          dataObjectTable = {
            ...dataObjectTable,
            [`M${keyOfMonth}W${week}TotalCase`]: weekDetail.case,
            [`M${keyOfMonth}W${week}PercentTotalCase`]: weekDetail.casePercent,
          }
        }
        dataObjectTable = {
          ...dataObjectTable,
          [`M${keyOfMonth}TotalCase`]: monthData.case,
          [`M${keyOfMonth}PercentTotalCase`]: monthData.casePercent,
        }
      })

      for (const quarter in quarters) {
        const quarterDetail = quarters[quarter]
        dataObjectTable = {
          ...dataObjectTable,
          [`Q${quarter}TotalCase`]: quarterDetail.case,
          [`Q${quarter}PercentTotalCase`]: quarterDetail.casePercent,
        }
      }

      for (const year in years) {
        const yearDetail = years[year]
        dataObjectTable = {
          ...dataObjectTable,
          [`Y${year}TotalCase`]: yearDetail.case,
          [`Y${year}PercentTotalCase`]: yearDetail.casePercent,
        }
      }
      return dataObjectTable
    case 'child':
      monthsOfMonthKeys?.forEach(keyOfMonth => {
        const monthData = serviceData?.months?.[keyOfMonth]
        const weekData = monthData?.weeks
        for (const week in weekData) {
          const weekDetail = weekData[week]
          dataObjectTable = {
            ...dataObjectTable,
            [`M${keyOfMonth}W${week}Case`]: weekDetail.case,
            [`M${keyOfMonth}W${week}PercentCase`]: weekDetail.casePercent,
          }
        }
        dataObjectTable = {
          ...dataObjectTable,
          [`M${keyOfMonth}Case`]: monthData.case,
          [`M${keyOfMonth}PercentCase`]: monthData.casePercent,
        }
      })

      for (const quarter in quarters) {
        const quarterDetail = quarters[quarter]
        dataObjectTable = {
          ...dataObjectTable,
          [`Q${quarter}Case`]: quarterDetail.case,
          [`Q${quarter}PercentCase`]: quarterDetail.casePercent,
        }
      }

      for (const year in years) {
        const yearDetail = years[year]
        dataObjectTable = {
          ...dataObjectTable,
          [`Y${year}Case`]: yearDetail.case,
          [`Y${year}PercentCase`]: yearDetail.casePercent,
        }
      }
      return dataObjectTable
  }
}

export const convertDataApiToDataTableReport14Revenue = ({
  serviceData,
  type,
}: {
  serviceData: ServiceDataReport14
  type: 'total' | 'child'
}) => {
  let dataObjectTable: any = {}

  const months = serviceData.months

  const quarters = serviceData?.quarters

  const years = serviceData?.years

  const monthsOfMonthKeys = Object?.keys(months)
  switch (type) {
    case 'total':
      monthsOfMonthKeys?.forEach(keyOfMonth => {
        const monthData = serviceData?.months?.[keyOfMonth]
        const weekData = monthData?.weeks
        for (const week in weekData) {
          const weekDetail = weekData[week]
          dataObjectTable = {
            ...dataObjectTable,
            [`M${keyOfMonth}W${week}TotalRevenue`]: weekDetail.revenue,
            [`M${keyOfMonth}W${week}PercentTotalRevenue`]:
              weekDetail.revenuePercent,
          }
        }
        dataObjectTable = {
          ...dataObjectTable,
          [`M${keyOfMonth}TotalRevenue`]: monthData.revenue,
          [`M${keyOfMonth}PercentTotalRevenue`]: monthData.revenuePercent,
        }
      })

      for (const quarter in quarters) {
        const quarterDetail = quarters[quarter]
        dataObjectTable = {
          ...dataObjectTable,
          [`Q${quarter}TotalRevenue`]: quarterDetail.revenue,
          [`Q${quarter}PercentTotalRevenue`]: quarterDetail.revenuePercent,
        }
      }

      for (const year in years) {
        const yearDetail = years[year]
        dataObjectTable = {
          ...dataObjectTable,
          [`Y${year}TotalRevenue`]: yearDetail.revenue,
          [`Y${year}PercentTotalRevenue`]: yearDetail.revenuePercent,
        }
      }
      return dataObjectTable
    case 'child':
      monthsOfMonthKeys?.forEach(keyOfMonth => {
        const monthData = serviceData?.months?.[keyOfMonth]
        const weekData = monthData?.weeks
        for (const week in weekData) {
          const weekDetail = weekData[week]
          dataObjectTable = {
            ...dataObjectTable,
            [`M${keyOfMonth}W${week}Revenue`]: weekDetail.revenue,
            [`M${keyOfMonth}W${week}PercentRevenue`]: weekDetail.revenuePercent,
          }
        }
        dataObjectTable = {
          ...dataObjectTable,
          [`M${keyOfMonth}Revenue`]: monthData.revenue,
          [`M${keyOfMonth}PercentRevenue`]: monthData.revenuePercent,
        }
      })

      for (const quarter in quarters) {
        const quarterDetail = quarters[quarter]
        dataObjectTable = {
          ...dataObjectTable,
          [`Q${quarter}Revenue`]: quarterDetail.revenue,
          [`Q${quarter}PercentRevenue`]: quarterDetail.revenuePercent,
        }
      }

      for (const year in years) {
        const yearDetail = years[year]
        dataObjectTable = {
          ...dataObjectTable,
          [`Y${year}Revenue`]: yearDetail.revenue,
          [`Y${year}PercentRevenue`]: yearDetail.revenuePercent,
        }
      }
      return dataObjectTable
  }
}

export const convertAgeGroupToArrayAge = (ageGroup?: AgeGroupEnum) => {
  switch (ageGroup) {
    case AgeGroupEnum.AGE_0_8:
      return {
        patientAgeFrom: 0,
        patientAgeTo: 8,
      }
    case AgeGroupEnum.AGE_9_14:
      return {
        patientAgeFrom: 9,
        patientAgeTo: 14,
      }
    case AgeGroupEnum.AGE_15_17:
      return {
        patientAgeFrom: 15,
        patientAgeTo: 17,
      }
    case AgeGroupEnum.AGE_18_23:
      return {
        patientAgeFrom: 18,
        patientAgeTo: 23,
      }
    case AgeGroupEnum.AGE_24_27:
      return {
        patientAgeFrom: 24,
        patientAgeTo: 27,
      }
    case AgeGroupEnum.AGE_28_34:
      return {
        patientAgeFrom: 28,
        patientAgeTo: 34,
      }
    case AgeGroupEnum.AGE_35_44:
      return {
        patientAgeFrom: 35,
        patientAgeTo: 44,
      }
    case AgeGroupEnum.AGE_45_54:
      return {
        patientAgeFrom: 45,
        patientAgeTo: 54,
      }
    case AgeGroupEnum.AGE_MORE_54:
      return {
        patientAgeFrom: 55,
      }
  }
}

export const convertGenderToParams = (gender?: GenderEnum) => {
  switch (gender) {
    case GenderEnum.MALE:
      return GenderReport14Enum.M
    case GenderEnum.FEMALE:
      return GenderReport14Enum.F
    case GenderEnum.OTHER:
      return GenderReport14Enum.Other
  }
}
