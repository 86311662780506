import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { useTranslation } from 'react-i18next'
import CollapseComponent from '../../components/CollapseComponent'
import { useProfilePatientContext } from '../../context'
import ProductServiceListLayout from './layouts/ProductServiceList'
import * as S from './styles'
import TotalInvoiceLayout from './layouts/TotalInvoice'
import DividerLine from '../../components/DividerLine'
import BaseText from 'app/components/common/BaseText'

const PurchaseProductService = () => {
  const { t } = useTranslation()

  const { isOpenPurchaseProductService, handleHidePurchase } =
    useProfilePatientContext()

  return (
    <>
      {isOpenPurchaseProductService ? (
        <CollapseComponent
          items={[
            {
              extra: (
                <BaseRow gutter={[10, 10]} align={'middle'}>
                  <BaseCol>
                    <BaseButton icon={<PlusOutlined rev={undefined} />}>
                      {t(R.strings.add_product_service)}
                    </BaseButton>
                  </BaseCol>
                  <BaseCol>
                    <BaseButton
                      icon={<CloseOutlined rev={undefined} />}
                      onClick={handleHidePurchase}
                    >
                      {t(R.strings.close)}
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
              ),
              children: (
                <S.ContentWrapper direction="vertical" size={15}>
                  <ProductServiceListLayout />
                  <DividerLine />
                  <TotalInvoiceLayout />
                </S.ContentWrapper>
              ),
              key: R.strings.purchase_dental_products_services,
              label: (
                <BaseText
                  children={t(R.strings.purchase_dental_products_services)}
                  fontSize="md"
                  fontWeight="semibold"
                />
              ),
            },
          ]}
          expandIconPosition="end"
          expandIcon={() => null}
        />
      ) : null}
    </>
  )
}

export default PurchaseProductService
