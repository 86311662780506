import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { getArray } from 'app/components/tables/common-table/constant'
import { PADDING } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ModalShowSenderJobTitle } from './layouts'
import { IDataTable } from './type'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseButton } from 'app/components/common/BaseButton'
import { MoreOutlined } from '@ant-design/icons'

export const useHook = () => {
  const { t } = useTranslation()
  const [data, setData] = useState<IDataTable[]>([])

  const getData = () => {
    setData(
      getArray(20).map((item, index) => ({
        stt: index + 1,
        senderJobTitle: [
          'Trưởng phòng',
          'Phó phòng',
          'Nhân viên',
          'Trưởng phòng',
          'Phó phòng',
          'Nhân viên',
        ],
        approverJobTitle: 'Giám đốc',
        department: 'Phòng kế toán',
        approveType: 'Duyệt',
      })),
    )
  }

  useEffect(() => {
    getData()
  }, [])

  const column = useMemo(() => {
    return [
      convertColumnTable<IDataTable>({
        key: 'stt',
        title: '#',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.stt}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        key: 'senderJobTitle',
        title: t(R.strings.sender_job_title),
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          // số senderJobTitle sau khi trừ 4
          const numberSenderJobTitle =
            record?.senderJobTitle?.length && record?.senderJobTitle?.length > 2
              ? record?.senderJobTitle?.length - 2
              : 0

          return (
            <BaseRow gutter={[16, 12]}>
              {record?.senderJobTitle?.slice(0, 2)?.map((item, index) => (
                <BaseCol key={index}>
                  <SenderWrapper>
                    <BaseText
                      children={item}
                      fontWeight="semibold"
                      fontSize="xs"
                    />
                  </SenderWrapper>
                </BaseCol>
              ))}

              {numberSenderJobTitle > 0 && (
                <BaseCol>
                  <ModalShowSenderJobTitle
                    listSenderJobTitle={record?.senderJobTitle ?? []}
                    numberSenderJobTitle={numberSenderJobTitle}
                  />
                </BaseCol>
              )}
            </BaseRow>
          )
        },
      }),
      convertColumnTable<IDataTable>({
        key: 'approverJobTitle',
        title: t(R.strings.approver_job_title),
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.approverJobTitle}
              fontWeight="semibold"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        key: 'department',
        title: t(R.strings.department),
        render: (_, record) => {
          return (
            <BaseText
              children={record?.department}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        key: 'approveType',
        title: t(R.strings.approve_type),
        render: (_, record) => {
          return (
            <BaseText
              children={record?.approveType}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        key: 'approveType',
        title: '',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'number-column',
        render: () => {
          return (
            <BasePopover>
              <BaseButton type='text'>
                <MoreOutlined rev={undefined} />
              </BaseButton>
            </BasePopover>
          )
        },
      }),
    ]
  }, [t])

  return {
    column,
    data,
  }
}

const SenderWrapper = styled.div<{ $isButton?: boolean }>`
  padding: 6px ${PADDING.xxxs};
  border-radius: ${PADDING.xl};
  background-color: #f8f8f8;

  ${({ $isButton }) =>
    $isButton &&
    `
        cursor: pointer;
    `}
`
