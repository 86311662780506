/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IFormChangePassword,
  IPropsChangePassword,
  IRuleChangePassword,
} from './type'
import { notificationController } from 'app/controllers/notification-controller'
import { RoleMessageCode } from 'app/api/roles/constant'
import { isEqual } from 'lodash'
import { IPayloadNewPassword, requestPostSetNewPassword } from 'app/api/auth'
import { ResponseType } from 'parkway-web-common'
import { useNavigate } from 'react-router'
import { LOGOUT_PATH } from 'app/components/router/route-path'

export const useModalHook = (props: IPropsChangePassword) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [form] = BaseForm.useForm<IFormChangePassword>()
  const [isLoading, setIsLoading] = useState(false)
  const [module, setModule] = useState<string | undefined>()

  const handleCancel = () => {
    form.resetFields()
    props?.onHideModal?.()
  }

  const handleSubmit = async (values: IFormChangePassword) => {
    setIsLoading(true)
    try {
      const payload: IPayloadNewPassword = {
        newPassword: values?.newPassword,
        oldPassword: values?.oldPassword,
      }
      const response: ResponseType<string> = await requestPostSetNewPassword(
        payload,
      )
      if (
        isEqual(
          response?.msgcode,
          RoleMessageCode.RoleManagement.successGetData,
        )
      ) {
        notificationController.success({
          message: t(R.strings.change_password),
          description: t(R.strings.change_password_success_and_please_re_login),
        })
        handleCancel()
        props?.onHideModal?.()
        navigate(LOGOUT_PATH)
      } else {
        notificationController.error({
          message: t(R.strings.change_password),
          description: t(response?.message.toLowerCase()),
        })
      }
    } catch (error) {
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeModule = (value: string) => {
    setModule(value)
  }

  const rules: IRuleChangePassword = useMemo(() => {
    return {
      oldPassword: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.old_password),
          }),
        },
      ],
      newPassword: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.new_password),
          }),
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('oldPassword') !== `${value}`) {
              return Promise.resolve()
            }
            return Promise.reject(
              new Error(
                t(R.strings.new_password_need_different_old_password),
              ),
            )
          },
        }),
      ],
      confirmNewPassword: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.confirm_new_password),
          }),
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('newPassword') === `${value}`) {
              return Promise.resolve()
            }
            return Promise.reject(
              new Error(
                t(R.strings.the_new_password_that_you_entered_do_not_match),
              ),
            )
          },
        }),
      ],
    }
  }, [t])

  return {
    form,
    t,
    isLoading,
    handleSubmit,
    handleCancel,
    rules,
    onChangeModule,
    module,
  }
}
