import R from 'app/assets/R'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormRule } from './type'
import { StatusGoodsIssueReceiptType } from '.'
import { notificationController } from 'app/controllers/notification-controller'
import { useNavigate } from 'react-router'
import { INVENTORY_WAREHOUSE_GOODS_ISSUE_PATH } from 'app/components/router/route-path'
import { get } from 'lodash'

import { GoodsIssueReceiptContext } from '../../context'
import { requestWarehouseGoodsReceiptTemplate } from 'app/api/inventory-new'
import { requestCreateWarehouseGoodsReceiptManagement } from 'app/api/inventory-new/api/warehouse-goods-receipt'

export const useHook = () => {
  const { t } = useTranslation()
  const { form, warehouseGoodsIssueReceiptDetail } = useContext(
    GoodsIssueReceiptContext,
  )
  const [isDownloading, setIsDownloading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState<
    StatusGoodsIssueReceiptType | undefined
  >()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigate = useNavigate()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form?.resetFields()
    navigate(INVENTORY_WAREHOUSE_GOODS_ISSUE_PATH)
  }

  const handleCreate = async (orders, goodsIssue) => {
    setIsLoading(true)

    try {
      const orderModify = orders?.map(item => {
        return {
          _id: item?._id,
          productId: item?.productId,
          quantity: Number(item?.quantity || 0),
          price: Number(item?.price || 0),
          measurementUnitId: item?.measurementUnitId,
        }
      })

      if (!orderModify.length) {
        setIsLoading(false)
        return notificationController.error({
          message: t(R.strings.warehouse_purchase_add),
          description: t(R.strings.goods_require),
        })
      }

      if (!orderModify.every(order => order?.quantity > 0)) {
        setIsLoading(false)
        return notificationController.error({
          message: t(R.strings.warehouse_purchase_add),
          description: t(R.strings.goods_please_input_quantity),
        })
      }

      const payload = {
        goodsIssueId: goodsIssue?._id,
        reason: goodsIssue?.reason,
        note: goodsIssue?.note,
        orders: orderModify,
      }

      await requestCreateWarehouseGoodsReceiptManagement(payload)

      setIsLoading(false)
      handleCancel()

      notificationController.success({
        message: t(R.strings.warehouse_purchase_add),
        description: t(R.strings.success),
      })

      navigate(INVENTORY_WAREHOUSE_GOODS_ISSUE_PATH)
    } catch (error) {
      setIsLoading(false)
      return notificationController.error({
        message: t(R.strings.warehouse_purchase_add),
        description: get(error, 'message'),
      })
    }
  }

  const downloadTemplate = async () => {
    setIsDownloading(true)
    try {
      const response = await requestWarehouseGoodsReceiptTemplate({
        goodsIssueId: warehouseGoodsIssueReceiptDetail?._id,
      })
      window.open(response?.data?.Url, '_blank', 'noopener,noreferrer')
    } catch (error) {
      notificationController.error({
        message: t(R.strings.warehouse_purchase_update),
        description: get(error, 'message'),
      })
    }
    setIsDownloading(false)
  }

  const rules: IFormRule = useMemo(() => {
    return {
      warehouseIssueId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.warehouse_export),
          }),
        },
      ],

      warehouseReceiveId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.warehouse_import),
          }),
        },
      ],

      issuerId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.warehouse_goods_issue_issuer),
          }),
        },
      ],

      receiverId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.warehouse_goods_issue_receiver),
          }),
        },
      ],

      createAtDate: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.created_date_ticket),
          }),
        },
      ],
    }
  }, [t])

  return {
    visible,
    t,
    showModal,
    handleCreate,
    handleCancel,
    rules,
    setStatus,
    status,
    isLoading,
    isDownloading,
    downloadTemplate,
  }
}
