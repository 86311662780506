import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import BaseText from 'app/components/common/BaseText'
import { IOrderDebtPayment } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/debt-payment'
import { useTranslation } from 'react-i18next'
import SelectProgramsComponent from './SelectPaymentMethod'
import * as S from './styles'

const PaymentMethodLayout = ({
  orderItem,
}: {
  orderItem: IOrderDebtPayment
}) => {
  const { t } = useTranslation()
  return (
    <S.SpaceWrapper>
      <BaseText fontWeight={"semibold"} fontSize='xs'>
        {t(R.strings.payment_method)}
      </BaseText>

      {orderItem?.paymentMethods?.map((item, index) => {
        return <SelectProgramsComponent item={item} index={index} />
      })}

      <BaseButton icon={<PlusOutlined rev={undefined} />} type="link">
        {t(R.strings.add_payment_method)}
      </BaseButton>
    </S.SpaceWrapper>
  )
}

export default PaymentMethodLayout
