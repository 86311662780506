import { requestGetAccountManagement } from 'app/api/human'
import { HumanMessageCode } from 'app/api/human/constant'
import {
  IParamsGetAccountManagement,
  IResponseGetAccountManagement,
} from 'app/api/human/model/account-management'
import { requestAllProfileManagement } from 'app/api/profile'
import { ProfileMessageCode } from 'app/api/profile/constant'
import { IProfile } from 'app/api/profile/model/management'
import R from 'app/assets/R'
import {
  convertArrayStatusFilterToArrayStatusNumber,
  convertStatusToFilterStatus,
} from 'app/common/helpers'
import {
  IResponseFilter,
  initResponseFilterTableManagement,
} from 'app/components/tables/BaseTableManagement/FilterManagementTable/type'
import {
  RenderValueTable,
  StatusTableEnum,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { initPagination } from 'app/constant'
import { isEqual } from 'lodash'
import { Pagination, ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChangePassword } from './components/ChangePassword'
import { DeleteAccount } from './components/DeleteAccount'
import { UpdateAccount } from './components/UpdateAccount'
import { IGeneralAccountManagementTable } from './type'

export const useHookTable = () => {
  const { t } = useTranslation()

  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const [profile, setProfile] = useState<IProfile[]>()

  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IResponseFilter,
  ) => {
    const initData = {
      pagination: pagination ?? initPagination,
      data: [],
    } as IDataBaseTable<IGeneralAccountManagementTable>
    try {
      const status = convertArrayStatusFilterToArrayStatusNumber(
        newFilter?.status ?? filter?.status,
      )
      let params: IParamsGetAccountManagement = {
        page: pagination?.current,
        pageSize: pagination?.pageSize,
        keyword: newFilter?.search ?? filter?.search,
      }

      if (status !== undefined) {
        params = { ...params, status }
      }

      const response: IResponseGetAccountManagement =
        await requestGetAccountManagement(params)

      if (
        isEqual(
          response?.msgcode,
          HumanMessageCode.AccountManagement.successGetData,
        )
      ) {
        const data = response?.data?.rows
        const metadata = response?.data?.metadata

        const startIndex =
          ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

        const dataTables = data?.map((item, index) => {
          return {
            key: index + 1,
            ordinalNumber: startIndex + index + 1,
            code: item?.code ?? '-',
            email: item?.email ?? '-',
            phone: item?.mobile ?? '-',
            employeeId: item?._id ?? '-',
            status: convertStatusToFilterStatus(item?.status),
            accountName: `${item?.lastname ?? ''} ${item?.firstname ?? ''}`,
          }
        })
        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pageSize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IGeneralAccountManagementTable>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, selectRowsTable, handleTableChange, fetch, rowSelection } =
    useHookTableManagement({ getDataTable })

  const handleChangeFilter = (newFilter: IResponseFilter) => {
    if (!isEqual(newFilter, filter)) {
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initPagination.current,
        },
        newFilter,
      )
    }
  }

  const fetchNewData = () => {
    fetch(initPagination, filter)
  }

  const getProfileAll = async () => {
    try {
      const res: ResponseType<IProfile[]> = await requestAllProfileManagement()
      if (
        isEqual(
          res?.msgcode,
          ProfileMessageCode.ProfileManagement.successGetData,
        )
      ) {
        const data: IProfile[] = res?.data || []
        setProfile(data)
      }
    } catch (error) {
      console.error({ error })
    }
    return []
  }

  useEffect(() => {
    getProfileAll()
  }, [])

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralAccountManagementTable>({
        title: t(R.strings.ordinal_number),
        key: 'ordinalNumber',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => (
          <RenderValueTable value={record.ordinalNumber} type="Number" />
        ),
      }),
      convertColumnTable<IGeneralAccountManagementTable>({
        title: t(R.strings.account_name),
        key: 'accountName',
        fixed: 'left',
        className: 'name-column-full-size',
        render: (_, record) => (
          <RenderValueTable value={record.accountName} type="Main" />
        ),
      }),
      convertColumnTable<IGeneralAccountManagementTable>({
        title: t(R.strings.id),
        key: 'code',
        className: 'id-column',
        render: (_, record) => (
          <RenderValueTable value={record.code} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralAccountManagementTable>({
        title: t(R.strings.phone),
        key: 'phone',
        className: 'phone-column',
        render: (_, record) => (
          <RenderValueTable value={record.phone} type="Phone" />
        ),
      }),
      convertColumnTable<IGeneralAccountManagementTable>({
        title: t(R.strings.email),
        key: 'email',
        className: 'email-column',
        render: (_, record) => (
          <RenderValueTable value={record.email} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralAccountManagementTable>({
        title: t(R.strings.status),
        key: 'status',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <RenderValueTable
            value={record.status}
            type="Status"
            actionComponent={
              <>
                <UpdateAccount
                  id={record?.employeeId}
                  fetchTable={fetchNewData}
                  profile={profile}
                />
                <ChangePassword id={record?.employeeId ?? undefined} />
                {!isEqual(record?.status, StatusTableEnum.deleted) && (
                  <DeleteAccount
                    id={record?.employeeId}
                    fetchTable={fetchNewData}
                    name={record?.accountName}
                  />
                )}
              </>
            }
          />
        ),
      }),
    ]
    return option
  }, [t, fetchNewData])

  return {
    tableData,
    selectRowsTable,
    handleTableChange,
    fetchNewData,
    rowSelection,
    columns,
    handleChangeFilter,
    filter,
    profile,
  }
}
