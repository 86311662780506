import { requestGetOrganizationalChartByJobTitle } from 'app/api/company/organizational-chart'
import {
  IProfileOrganization,
  ResponseOrganizationByJobTitleType,
} from 'app/api/company/organizational-chart/model'
import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { Loading } from 'app/components/common/Loading'
import { useEffect, useMemo, useState } from 'react'
import { MapInteractionCSS } from 'react-map-interaction'
import { Tree } from 'react-organizational-chart'
import { ItemProfileComponent, ItemTreeComponent } from './components'
import { t } from 'i18next'

export const TreeLayout = () => {
  const [data, setData] = useState<IProfileOrganization[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const getData = async () => {
    try {
      setLoading(true)

      const res: ResponseOrganizationByJobTitleType =
        await requestGetOrganizationalChartByJobTitle({})

      res?.data && setData(res.data)
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const isHaveMoreOneChild = useMemo(() => {
    return !!data?.find(child => child?.sizeChildren && child?.sizeChildren > 1)
  }, [data])

  return (
    <MapInteractionCSS
      defaultValue={{
        scale: 1,
        translation: { x: 0, y: 20 },
      }}
      minScale={0.1}
      maxScale={3}
      translationBounds={{
        xMax: 400,
        yMax: 200,
      }}
    >
      <BaseSpace style={{ padding: 10 }}>
        <Tree
          lineWidth={'1px'}
          lineColor={'#365B7E'}
          lineBorderRadius={'10px'}
          lineStyle="dashed"
          label={
            <ItemTreeComponent
              item={{
                name: t(R.strings.parkway_dental),
                unitTypeName: t(R.strings.company),
                avatar: R.images.ic_logo_parkway_collapsed,
                children: data,
              }}
              borderColor={'#365B7E'}
            />
          }
        >
          {data?.map((item, index) => {
            return (
              <ItemProfileComponent
                key={index}
                profile={item}
                isShowLevel
                haveMoreOneChild={isHaveMoreOneChild}
              />
            )
          })}
          {loading && <Loading isAbsolute size="small" />}
        </Tree>
      </BaseSpace>
    </MapInteractionCSS>
  )
}
