import { BaseCol } from 'app/components/common/BaseCol'
import { get, isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { SearchDropdown } from './search-dropdown'
import * as S from './styles'
import {
  IFilterProps,
  IResponseFilter,
  initResponseFilterTableManagement,
} from './type'
import { useTranslation } from 'react-i18next'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import R from 'app/assets/R'
import { Option } from 'app/components/common/selects/BaseSelect'
import { PurchaseOrderStatusName } from 'app/common/constants'
import { Space } from 'antd'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  DropdownRender,
  SearchOutlinedStyled,
  SearchWrapper,
} from 'app/components/common/selects/SelectSearch/styles'
import { ISupplier } from 'app/api/inventory-new/model/management'
import { requestGetSupplierManagement } from 'app/api/inventory-new'

export const FilterManagement = ({
  handleChangeFilter,
  filterData,
}: IFilterProps) => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const [suppliers, setSuppliers] = useState<ISupplier[]>([])
  const [supplierKeyword, setSupplierKeyword] = useState('')

  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  useEffect(() => {
    const timer = setTimeout(async () => {
      const response = await requestGetSupplierManagement({
        keyword: supplierKeyword,
      })
      const data: ISupplier[] = get(response, 'data', [])
      setSuppliers(data)
    }, 500)
    return () => clearTimeout(timer)
  }, [supplierKeyword])

  const [isOverlayOpen, setOverlayOpen] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      handleChangeFilter?.(filter)
    }, 500)
    return () => clearTimeout(timer)
  }, [filter])

  useEffect(() => {
    if (filterData && !isEqual(filterData, filter)) {
      setFilter(filterData)
    }
  }, [filterData])

  useEffect(() => {
    setOverlayOpen(false)
  }, [pathname])

  const onChangeSearch = (search: string) => {
    setFilter(prev => {
      return {
        ...prev,
        search,
      }
    })
  }

  return (
    <S.HeadWrapper gutter={20} align={'middle'}>
      <BaseCol>
        <SearchDropdown
          query={filter?.search ?? ''}
          setQuery={onChangeSearch}
          data={[]}
          isOverlayOpen={isOverlayOpen}
          setOverlayOpen={setOverlayOpen}
        />
      </BaseCol>
      <BaseCol>
        <Space.Compact>
          <S.SelectLabel value={`${t(R.strings.status)}:`} disabled />
          <S.SelectBaseStyled
            placeholder={t(R.strings.all)}
            suffixIcon={<SvgTableCloseIcon />}
            allowClear
            onChange={value =>
              setFilter(prev => ({
                ...prev,
                status: Number(value) || undefined,
              }))
            }
          >
            {Object.entries(PurchaseOrderStatusName).map(([key, value]) => {
              return (
                <Option value={Number(key)} key={key}>
                  {t(value)}
                </Option>
              )
            })}
          </S.SelectBaseStyled>
        </Space.Compact>
      </BaseCol>

      <BaseCol>
        <Space.Compact>
          <S.SelectLabel value={`${t(R.strings.provider)}:`} disabled />
          <S.SelectInputSearch
            placeholder={t(R.strings.all)}
            onChange={value =>
              setFilter(prev => ({
                ...prev,
                supplierId: value,
              }))
            }
            filterOption={false}
            dropdownRender={menu => (
              <DropdownRender>
                <SearchWrapper>
                  <SearchOutlinedStyled
                    className="search__icon"
                    rev=""
                    size={20}
                  />
                  <BaseInput
                    className="search__input"
                    placeholder={t(R.strings.search)}
                    onChange={e => setSupplierKeyword(e?.target?.value)}
                    allowClear
                  />
                </SearchWrapper>
                {menu}
              </DropdownRender>
            )}
            allowClear
          >
            {suppliers?.map((supplier, index) => {
              return (
                <Option value={supplier?._id} key={index}>
                  {supplier?.name}
                </Option>
              )
            })}
          </S.SelectInputSearch>
        </Space.Compact>
      </BaseCol>
    </S.HeadWrapper>
  )
}
