import R from 'app/assets/R'
import { ColorTextType } from 'app/components/common/BaseText'
import { GenderEnum } from 'parkway-web-common'
import {
  DiscountTypeEnum,
  EVerifyRuleKey,
  MethodIssueEnum,
  TargetUserEnum,
  UserDataByVoucherEnum,
  VoucherTemplateStatusEnum,
  VoucherTemplateStatusIssueEnum,
} from './enum'

// Message code define
export const DiscountVoucherMessageCode = {
  DiscountVoucher: {
    successGetData: '20000',
    success: '20000',
  },
}

// Endpoint
export const DiscountEndPoint = {
  DiscountVoucher: {
    requestGetData: '/olddb/v1/voucher-template',
    createData: '/olddb/v1/voucher-template',
    updateData: '/olddb/v1/voucher-template/:id',
    updateDataWithInsert: '/olddb/v1/voucher-template/:id/treatment/add',
    deleteData: '/olddb/v1/voucher-template/:id',
    retry: '/olddb/v1/voucher-template/retry/:id',
    stopApply: '/olddb/v1/voucher-template/stop-apply/:id',
    requestGetDetail: '/olddb/v1/voucher-template/:id',
    userDataByVoucherId: '/olddb/v1/voucher-template/:id/user-data',
    referralDataByVoucherId:
      '/olddb/v1/voucher-template/:id/referral/user-data',
    importUserData: '/olddb/v1/voucher-template/user-data/excel-to-json',
    importReferralData:
      '/olddb/v1/voucher-template/user-data/referral/excel-to-json',

    getVoucherCode: '/olddb/v1/discount-voucher',
    exportVoucherCode: '/olddb/v1/discount-voucher/export',

    importTreatment: '/olddb/v1/voucher-template/treatment/excel-to-json',
    importTreatmentWithVoucherTemplateId:
      '/olddb/v1/voucher-template/:id/treatment/add/excel-to-json',

    requestGetReferralDiscountVoucher: 'olddb/v1/discount-voucher/referral',
    exportListReferralVoucherCode: 'olddb/v1/discount-voucher/referral/export',

    requestGetReferralStatistics: 'olddb/v1/discount-voucher/referral/statistic',
    exportListReferralStatistics: 'olddb/v1/discount-voucher/referral/statistic/export',
  },
}

export const DefineMethodIssueEnum = {
  [MethodIssueEnum.Auto]: {
    keyI18n: R.strings.system_auto_create_voucher,
  },
  [MethodIssueEnum.Fillable]: {
    keyI18n: R.strings.fill_in_voucher_code,
  },
}

export const DefineDiscountTypeEnum = {
  [DiscountTypeEnum.Percent]: {
    keyI18n: R.strings.discount_percent,
  },
  [DiscountTypeEnum.Amount]: {
    keyI18n: R.strings.cash_discount,
  },
}

export const DefineTargetUserEnum = {
  [TargetUserEnum.Customer]: {
    keyI18n: R.strings.customer,
  },
  [TargetUserEnum.VipCustomer]: {
    keyI18n: R.strings.vip_customer,
  },
  [TargetUserEnum.CompanyEmployee]: {
    keyI18n: R.strings.company_employee,
  },
}

export const DefineApplyRuleKey = {
  [EVerifyRuleKey.NewPatient]: {
    keyI18n: R.strings.new_customer,
  },
  [EVerifyRuleKey.PatientId]: {
    keyI18n: R.strings.designated_customer,
  },
  [EVerifyRuleKey.All]: {
    keyI18n: R.strings.all_customers,
  },
}

interface IDefineResponse {
  keyI18n: string
  backgroundStatus: string
  textStatusColor?: ColorTextType
}

export const DefineVoucherTemplateStatusEnum = {
  [VoucherTemplateStatusEnum.WaitingApply]: {
    keyI18n: R.strings.waiting_apply,
    backgroundStatus: 'linear-gradient(90deg, #2F7EFF, #659FFF)',
    textStatusColor: 'backgroundColor',
  } as IDefineResponse,
  [VoucherTemplateStatusEnum.Applying]: {
    keyI18n: R.strings.applying,
    backgroundStatus: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
    textStatusColor: 'backgroundColor',
  } as IDefineResponse,
  [VoucherTemplateStatusEnum.StopApply]: {
    keyI18n: R.strings.stop_applying,
    backgroundStatus: 'linear-gradient(90deg, #FF6B6B, #F44949)',
    textStatusColor: 'backgroundColor',
  } as IDefineResponse,
  [VoucherTemplateStatusEnum.UpComing]: {
    keyI18n: R.strings.up_coming,
    backgroundStatus: 'linear-gradient(90deg, #FFAE49, #FF7617)',
    textStatusColor: 'backgroundColor',
  } as IDefineResponse,
  [VoucherTemplateStatusEnum.Ended]: {
    keyI18n: R.strings.ended,
    backgroundStatus: 'linear-gradient(90deg, #FFAE49, #FF7617)',
    textStatusColor: 'backgroundColor',
  } as IDefineResponse,
}

export const DefineVoucherTemplateStatusIssueEnum = {
  [VoucherTemplateStatusIssueEnum.Waiting]: {
    keyI18n: R.strings.waiting,
    backgroundStatus: 'linear-gradient(90deg, #F8F8F8, #F8F8F8)',
    textStatusColor: 'primaryColor',
  } as IDefineResponse,
  [VoucherTemplateStatusIssueEnum.Creating]: {
    keyI18n: R.strings.creating,
    backgroundStatus: 'linear-gradient(90deg, #2F7EFF, #659FFF)',
    textStatusColor: 'backgroundColor',
  } as IDefineResponse,
  [VoucherTemplateStatusIssueEnum.Error]: {
    keyI18n: R.strings.error,
    backgroundStatus: 'linear-gradient(90deg, #FFAE49, #FF7617)',
    textStatusColor: 'backgroundColor',
  } as IDefineResponse,
  [VoucherTemplateStatusIssueEnum.Done]: {
    keyI18n: R.strings.done,
    backgroundStatus: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
    textStatusColor: 'backgroundColor',
  } as IDefineResponse,
}

export const DefineGender = {
  [GenderEnum.F]: {
    keyI18n: R.strings.female,
  },
  [GenderEnum.M]: {
    keyI18n: R.strings.male,
  },
}

export const DefineUserDataByVoucherEnum = {
  [UserDataByVoucherEnum.NotYetUsed]: {
    keyI18n: R.strings.not_yet_used,
    textColor: 'primaryColor',
  } as { textColor?: ColorTextType; keyI18n: string },
  [UserDataByVoucherEnum.Using]: {
    keyI18n: R.strings.using,
    textColor: 'collapseBackgroundColor',
  } as { textColor?: ColorTextType; keyI18n: string },
  [UserDataByVoucherEnum.Used]: {
    textColor: 'statesGreenColor',
    keyI18n: R.strings.used,
  } as { textColor?: ColorTextType; keyI18n: string },
}

export const URL_FILE_TEMPLATE_PATIENT_IN_VOUCHER =
  'https://cdn.nhakhoaparkway.com/uploads/images/voucher-template/user-data-template.xlsx'

export const URL_FILE_TEMPLATE_TREATMENT_IN_VOUCHER =
  'https://cdn.nhakhoaparkway.com/uploads/documents/discount/template_voucher.xlsx'
