import { StatusTableType } from "app/common/enum"

export interface IResponseFilter {
  status?: StatusTableType[]
  search?: string
}

export interface IFilterProps {
  handleChangeFilter?: (data: IResponseFilter) => void
  filterData?: IResponseFilter
}

export const initResponseFilterTableManagement = {
  search: '',
  status: [],
}

export enum PaidTypeTableEnum {
  deposit = 'deposit',
  installment = 'installment',
  one_time_payment = 'one_time_payment',
  no_paid = 'no_paid',
  end_date = 'end_date',
  net_amount_or_paid = 'net_amount_or_paid',
}

export enum InvoicePaidTypeTableEnum {
  cash = 'cash',
  card = 'card',
  transfer = 'transfer',
  app_momo = 'app_momo',
}

export type PaidTypeTableType = keyof typeof PaidTypeTableEnum
declare type EventValue<DateType> = DateType | null
export declare type RangeValue<DateType> =
  | [EventValue<DateType>, EventValue<DateType>]
  | null

export interface IFilter {
  keyword: string | undefined
  treatments: string[] | undefined
  startDate: string | undefined
  endDate: string | undefined
  search?: string | undefined
  paymentMethod?: string[] | unknown
}


export interface IProps {
  handleChangeFilter
  filterData
}