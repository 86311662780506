import { withLoading } from 'app/hocs/with-loading.hoc'
import CustomerCarePage from 'app/page/menu/customer-care'
import AppointmentPage from 'app/page/menu/customer-care/appointment'
import CheckInCustomerCarePlanPage from 'app/page/menu/customer-care/check-in-customer-care-plan'
import CustomerCarePlanPage from 'app/page/menu/customer-care/customer-care-plan'
import SurveyReportPage from 'app/page/menu/customer-care/survey-report'

import CheckInServicePlanPage from 'app/page/menu/customer-care/check-in-service-plan'
import BookingManagementPage from 'app/page/menu/customer-care/booking'
import { Route } from 'react-router-dom'
import {
  CUSTOMER_BOOKING_FOR_EMPLOYEE_PATH,
  CUSTOMER_BOOKING_PATH,
  CUSTOMER_CARE_CHECK_IN_PLAN_PATH,
  CUSTOMER_CARE_CHECK_IN_SERVICE_PLAN_PATH,
  CUSTOMER_CARE_CREATE_APPOINTMENT_PATH,
  CUSTOMER_CARE_PATH,
  CUSTOMER_CARE_PLAN_PATH,
  CUSTOMER_CARE_SEARCH_APPOINTMENT_PATH,
  CUSTOMER_CARE_SURVEY_REPORT_PATH,
  CUSTOMER_CARE_BOOKING_WEBSITE_REPORT_PATH
} from './route-path'
import SearchAppointmentPage from 'app/page/menu/customer-care/search-appointment'
import BookingWebsiteReportPage from 'app/page/menu/customer-care/booking-website-report'

const CustomerCare = withLoading(CustomerCarePage)
const Appointment = withLoading(AppointmentPage)
const SearchAppointment = withLoading(SearchAppointmentPage)
const CustomerCarePlan = withLoading(CustomerCarePlanPage)
const CheckInCustomerCarePlan = withLoading(CheckInCustomerCarePlanPage)
const CheckInServicePlan = withLoading(CheckInServicePlanPage)
const BookingManagement = withLoading(BookingManagementPage)
const BookingWebsiteReport = withLoading(BookingWebsiteReportPage)
export const CustomerCareRouter = () => {
  return (
    <>
      <Route path={CUSTOMER_CARE_PATH} element={<CustomerCare />} />
      <Route path={CUSTOMER_CARE_PLAN_PATH} element={<CustomerCarePlan />} />
      <Route
        path={CUSTOMER_CARE_SURVEY_REPORT_PATH}
        element={<SurveyReportPage />}
      />
      <Route path={CUSTOMER_BOOKING_PATH} element={<BookingManagement />} />
      <Route
        path={CUSTOMER_BOOKING_FOR_EMPLOYEE_PATH}
        element={<BookingManagement />}
      />
      <Route
        path={CUSTOMER_CARE_CHECK_IN_PLAN_PATH}
        element={<CheckInCustomerCarePlan />}
      />
      <Route
        path={CUSTOMER_CARE_CREATE_APPOINTMENT_PATH}
        element={<Appointment />}
      />
      <Route
        path={CUSTOMER_CARE_SEARCH_APPOINTMENT_PATH}
        element={<SearchAppointment />}
      />
      <Route
        path={CUSTOMER_CARE_CHECK_IN_SERVICE_PLAN_PATH}
        element={<CheckInServicePlan />}
      />
      <Route
        path={CUSTOMER_CARE_BOOKING_WEBSITE_REPORT_PATH}
        element={<BookingWebsiteReport />}
      />
    </>
  )
}
