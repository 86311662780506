import R from 'app/assets/R'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormRule } from './type'
import { StatusWarehousePurchaseType } from '.'
import { notificationController } from 'app/controllers/notification-controller'
import {
  requestCreateWarehousePurchaseManagement,
  requestUpdateWarehousePurchaseManagement,
} from 'app/api/inventory-new'
import { WarehousePurchaseContext } from 'app/containers/InventoryManagement/WarehousePurchase/context'
import moment from 'moment'
import {
  DATE,
  PurchaseOrderFromStatus,
  PurchaseOrderStatusName,
} from 'app/common/constants'
import { useNavigate } from 'react-router'
import { INVENTORY_WAREHOUSE_PURCHASE_PATH } from 'app/components/router/route-path'
import { get } from 'lodash'
import {
  PurchaseOrderStatusEnum,
} from 'app/common/enum'

export const useHook = () => {
  const { t } = useTranslation()
  const { form, warehousePurchaseDetail } = useContext(WarehousePurchaseContext)
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState<
    StatusWarehousePurchaseType | undefined
  >()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form?.resetFields()
    navigate(INVENTORY_WAREHOUSE_PURCHASE_PATH)
  }

  const handleCreate = async (values, orders, discount, status) => {
    setIsLoading(true)

    try {
      const orderModify = orders?.map(item => {
        return {
          productId: item?.productId,
          quantity: Number(item?.quantity || 0),
          price: Number(item?.price || 0),
          measurementUnitId: item?.measurementUnitId,
        }
      })

      const isError = await validatePayload(orderModify, values)
      if (isError) return

      const payload = {
        ...values,
        estimateDeliveryDate: moment(values?.estimateDeliveryDate).format(
          DATE.YYYY_MM_DD,
        ),
        paymentDueDate: moment(values?.paymentDueDate).format(DATE.YYYY_MM_DD),
        orders: orderModify,
        discount,
        status,
      }

      await requestCreateWarehousePurchaseManagement(payload)

      setIsLoading(false)
      handleCancel()

      notificationController.success({
        message: t(R.strings.warehouse_purchase_add),
        description: t(R.strings.success),
      })

      navigate(INVENTORY_WAREHOUSE_PURCHASE_PATH)
    } catch (error) {
      if (get(error, 'errorFields', []).length) {
        notificationController.error({
          message: t(R.strings.warehouse_purchase_add),
          description: t(R.strings.please_input_all_field),
        })
      } else {
        notificationController.error({
          message: t(R.strings.warehouse_purchase_add),
          description: get(error, 'message'),
        })
      }
      setIsLoading(false)
    }
  }

  const handleUpdate = async (values, orders, discount, id) => {
    setIsLoading(true)

    try {
      const orderModify = orders?.map(item => {
        return {
          productId: item?.productId,
          quantity: Number(item?.quantity || 0),
          price: Number(item?.price || 0),
          measurementUnitId: item?.measurementUnitId,
        }
      })

      const isError = await validatePayload(orderModify, values)
      console.log('isError', isError)
      if (isError) return

      const payload = {
        ...values,
        estimateDeliveryDate: moment(values?.estimateDeliveryDate).format(
          DATE.YYYY_MM_DD,
        ),
        paymentDueDate: moment(values?.paymentDueDate).format(DATE.YYYY_MM_DD),
        orders: orderModify,
        discount,
      }

      await requestUpdateWarehousePurchaseManagement({ id, body: payload })

      setIsLoading(false)
      handleCancel()

      notificationController.success({
        message: t(R.strings.warehouse_purchase_update),
        description: t(R.strings.success),
      })

      navigate(INVENTORY_WAREHOUSE_PURCHASE_PATH)
    } catch (error) {
      if (get(error, 'errorFields', []).length) {
        notificationController.error({
          message: t(R.strings.warehouse_purchase_update),
          description: t(R.strings.please_input_all_field),
        })
      } else {
        notificationController.error({
          message: t(R.strings.warehouse_purchase_update),
          description: get(error, 'message'),
        })
      }
      setIsLoading(false)
    }
  }

  const validatePayload = async (orderModify, values) => {
    await form?.validateFields()
    const statusAvailable =
      PurchaseOrderFromStatus[Number(warehousePurchaseDetail?.status)]
      
    if (
      warehousePurchaseDetail?._id &&
      Number(warehousePurchaseDetail?.status) !==
        PurchaseOrderStatusEnum.DRAFT &&
      !statusAvailable?.includes(Number(values?.status))
    ) {
      setIsLoading(false)

      const newStatusAvailable = statusAvailable
        ?.map(item => t(PurchaseOrderStatusName[Number(item)]))
        .join(',')
      notificationController.error({
        message: t(R.strings.warehouse_purchase_add),
        description: t(R.strings.purchase_order_change_message, {
          currentStatus: t(
            PurchaseOrderStatusName[Number(warehousePurchaseDetail?.status)],
          ),
          newStatus: newStatusAvailable,
        }),
      })

      return true
    }

    if (!orderModify?.length) {
      setIsLoading(false)
      notificationController.error({
        message: t(R.strings.warehouse_purchase_add),
        description: t(R.strings.goods_require),
      })

      return true
    }

    if (
      !orderModify.every(order => order?.quantity > 0) ||
      !orderModify.every(order => order?.price > 0)
    ) {
      setIsLoading(false)
      notificationController.error({
        message: t(R.strings.warehouse_purchase_add),
        description: t(R.strings.goods_please_input_quantity_unit_price),
      })
      return true
    }

    return false
  }
  const rules: IFormRule = useMemo(() => {
    return {
      purchaserId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.purchaser),
          }),
        },
      ],

      receiverId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.receiver),
          }),
        },
      ],

      phone: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.phone),
          }),
        },
      ],

      warehouseReceiveId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.warehouse_receive),
          }),
        },
      ],

      address: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.address),
          }),
        },
      ],

      estimateDeliveryDate: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.estimate_delivery_date),
          }),
        },
      ],

      paymentDueDate: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.payment_due_date),
          }),
        },
      ],

      supplierId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.supplier),
          }),
        },
      ],
    }
  }, [t])

  return {
    visible,
    t,
    showModal,
    handleCreate,
    handleUpdate,
    handleCancel,
    rules,
    setStatus,
    status,
    isLoading,
  }
}
