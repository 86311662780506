import { BaseCol } from "app/components/common/BaseCol"
import { convertedVariables } from "app/styles/themes/themeVariables"
import { BORDER_RADIUS, PADDING } from "parkway-web-common"
import styled from "styled-components"

export const SectionWrapper = styled.div`
  width: 100%;
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
  .child-wrapper {
    padding: ${PADDING.md};
  }
`

export const TableWrapper = styled.div`
  border: 1px solid #ebeef2;
  border-radius: ${BORDER_RADIUS};
`

export const LeftSideValueTable = styled(BaseCol)`
  background-color: #f3f4f5;
  justify-content: center;
  display: flex;
  align-items: center;
`

export const RightSideValueTable = styled(BaseCol)`
  background-color: #fbfbfb;
`