import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import Create from 'app/containers/HumanResources/ProfileManagement/CreateProfileContainer'

const ProfileCreatePageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.profile_content_add)}</PageTitle>
      <Create />
    </>
  )
}
const ProfileCreatePage = memo(ProfileCreatePageCpn, isEqual)

export default ProfileCreatePage
