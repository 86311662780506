import React from 'react'
import * as S from './styles'
import { DateUtil, FORMAT_DD_MM_YYYY } from 'parkway-web-common'
import { IDegree } from 'app/api/degree/model/management'
import { IDegreeClassification } from 'app/api/degreeclassification/model/management'
import { getDegreeClassification } from 'app/utils/degreeClassification.util'
import { t } from 'i18next'
import R from 'app/assets/R'
import { BasePopover } from 'app/components/common/BasePopover'
import { DashOutlined } from '@ant-design/icons'

interface DegreeItemProps {
  degree?: IDegree
  degreeClassification?: IDegreeClassification
  start?: Date
  end?: Date
  key: number
  index: number
  documentImageUrl: string
  onRemove?: (key: number) => void
  onUpdate?: (key: number) => void
}

const DegreeItem: React.FC<DegreeItemProps> = ({
  degree,
  key,
  degreeClassification,
  start,
  end,
  documentImageUrl,
  index,
  onRemove,
}) => {
  const openDocument = () => {
    window.open(documentImageUrl, '_blank')
  }
  return (
    <S.BaseRowStyled key={key}>
      <S.DegreeItem xl={6}>
        <S.TitleFormList>{degree?.name}</S.TitleFormList>
        <S.ClassificationWrapper>
          {start && (
            <span>
              {t(R.strings.degree_content_start)}:{' '}
              {DateUtil.formatDate(start, FORMAT_DD_MM_YYYY)}
            </span>
          )}
          {end && (
            <span>
              {t(R.strings.degree_content_end)}:{' '}
              {DateUtil.formatDate(end, FORMAT_DD_MM_YYYY)}
            </span>
          )}
          <span>
            {t(R.strings.degree_field_degree_classification)} :{' '}
            <span className={`tag ${degreeClassification?.key}`}>
              {getDegreeClassification(degreeClassification?.key)}
            </span>
          </span>
        </S.ClassificationWrapper>
      </S.DegreeItem>
      <S.ButtonActionGroup>
        {documentImageUrl && (
          <S.Link onClick={openDocument}>
            {t(R.strings.degree_content_see_degree)}
          </S.Link>
        )}
        <BasePopover
          placement="bottomLeft"
          trigger="click"
          content={
            <>
              <S.ButtonStyled onClick={() => onRemove && onRemove(index)}>
                {t(R.strings.delete)}
              </S.ButtonStyled>
            </>
          }
        >
          <DashOutlined rev={undefined} rotate={90} />
        </BasePopover>
      </S.ButtonActionGroup>
    </S.BaseRowStyled>
  )
}

export default DegreeItem
