import { SearchOutlined } from '@ant-design/icons'
import { DefineGender } from 'app/api/marketing/discount-voucher/constant'
import { IUserDataVoucher } from 'app/api/marketing/discount-voucher/model'
import R from 'app/assets/R'
import { getDetailPatientUrl } from 'app/common/helpers'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { ModalComponent } from 'app/components/common/ModalComponent'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { DateUtil, FONT_SIZE } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useCreateVoucherContext } from '../../context'

export const ModalUserDisplayLayout = ({ fileName }: { fileName: string }) => {
  const { modalDisplayUserRef, listUserData, listUserDataError } =
    useCreateVoucherContext()
  const { t } = useTranslation()

  const [search, setSearch] = useState('')

  const data = useMemo(() => {
    const successData = listUserData?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })

    const errorData = listUserDataError?.map((item, index) => {
      return {
        id: item,
        key: index + 1,
      } as IUserDataVoucher
    })

    return errorData
      ?.concat(successData ?? [])
      ?.filter(itm => {
        // search by id and name
        return (
          itm?.name?.toLowerCase().includes(search.toLowerCase()) ||
          itm?.id?.toLowerCase().includes(search.toLowerCase())
        )
      })
      ?.map((item, index) => {
        return {
          ...item,
          key: index + 1,
        }
      })
  }, [listUserData, listUserDataError, search])

  const handleOpenModal = () => {
    modalDisplayUserRef?.current?.open?.()
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<{ key?: number; _id?: string }>({
        title: '#',
        key: 'key',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.key}
              fontWeight="medium"
              opacity="0.7"
              colorText={record?._id ? 'primaryColor' : 'statesRedColor'}
            />
          )
        },
      }),
      convertColumnTable<IUserDataVoucher>({
        title: t(R.strings.patient_code),
        key: 'id',
        fixed: 'left',
        render: (_, record) => {
          return (
            <RenderValueTable
              type="OtherLink"
              value={record?.id}
              hrefOtherLink={getDetailPatientUrl(record?._id)}
              styleText={{
                fontSize: FONT_SIZE.xxs,
              }}
            />
          )
        },
      }),
      convertColumnTable<IUserDataVoucher>({
        title: t(R.strings.name),
        key: 'name',
        fixed: 'left',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.name ?? '-'}
              fontWeight="medium"
              colorText={record?._id ? 'primaryColor' : 'statesRedColor'}
            />
          )
        },
      }),
      convertColumnTable<IUserDataVoucher>({
        title: t(R.strings.gender),
        key: 'gender',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.gender ? t(DefineGender[record?.gender]?.keyI18n) : '-'
              }
              fontWeight="medium"
              opacity="0.7"
              colorText={record?._id ? 'primaryColor' : 'statesRedColor'}
            />
          )
        },
      }),
      convertColumnTable<IUserDataVoucher>({
        title: t(R.strings.year_of_birth),
        key: 'dob',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.dob ? DateUtil.formatDate(record?.dob, 'YYYY') : '-'
              }
              fontWeight="medium"
              opacity="0.7"
              colorText={record?._id ? 'primaryColor' : 'statesRedColor'}
            />
          )
        },
      }),
      convertColumnTable<IUserDataVoucher>({
        title: t(R.strings.voucher_code),
        key: 'voucherCode',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.voucherCode ?? '-'}
              fontWeight="medium"
              opacity="0.7"
              colorText={record?._id ? 'primaryColor' : 'statesRedColor'}
            />
          )
        },
      }),
      convertColumnTable<IUserDataVoucher>({
        title: t(R.strings.number_of_use),
        key: 'usingTime',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.usingTime ?? '-'}
              fontWeight="medium"
              opacity="0.7"
              colorText={record?._id ? 'primaryColor' : 'statesRedColor'}
            />
          )
        },
      }),
      convertColumnTable<IUserDataVoucher>({
        title: t(R.strings.status),
        key: 'status',
        fixed: 'right',
        render: (_, record) => {
          if (!record?._id) {
            return (
              <BaseText
                children={t(R.strings.error)}
                fontWeight="medium"
                opacity="0.7"
                colorText={'statesRedColor'}
              />
            )
          }

          return (
            <BaseText
              children={t(R.strings.not_yet_used)}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
    ]
  }, [t, data])

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const handleConfirm = () => {
    modalDisplayUserRef?.current?.hide?.()
  }

  return (
    <ModalComponent
      buttonOpenCustom={
        <BaseButton onClick={handleOpenModal} type="link">
          {fileName}
        </BaseButton>
      }
      titleModal={R.strings.customer_list}
      ref={modalDisplayUserRef}
      widthModal={1000}
      isShowCancel={false}
      handleSubmit={handleConfirm}
      renderContent={
        <BaseForm>
          <RootWrapper>
            <BaseRow gutter={[16, 8]} align={'middle'}>
              <BaseCol xl={12}>
                <BaseFormItem hiddenLabel>
                  <BaseInput
                    prefix={<SearchOutlined rev={undefined} />}
                    placeholder={t(R.strings.enter_name)}
                    value={search}
                    onChange={onChangeSearch}
                  />
                </BaseFormItem>
              </BaseCol>
            </BaseRow>

            <BaseTableManagement
              columns={columns}
              dataSource={data}
              pagination={{
                pageSize: 5,
              }}
              rowClassName="row-overwrite-style"
              scroll={{ x: true }}
            />
          </RootWrapper>
        </BaseForm>
      }
    />
  )
}

const RootWrapper = styled(BaseSpace)`
  .ant-table-wrapper .ant-table .ant-table-header {
    border-radius: 0px !important;
  }

  .ant-table-thead > tr > th {
    border: 0px !important;
  }

  .row-overwrite-style .ant-table-cell {
    border-left: 0px !important;
    border-right: 0px !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0px;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0px !important;
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
