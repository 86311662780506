import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { useAdditionalServiceVoucherContext } from '../../context'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

export const FooterLayout = () => {
  const { t } = useTranslation()
  const { handleGoBack, form, isLoading, listService } =
    useAdditionalServiceVoucherContext()

  const isDisableSubmit = useMemo(() => {
    return !listService?.filter(itm => !itm?.isInit)?.length
  }, [listService])

  return (
    <BaseRow align={'middle'} justify={'end'}>
      <BaseCol>
        <BaseRow gutter={16} align={'middle'}>
          <BaseCol>
            <BaseButton onClick={handleGoBack}>
              {t(R.strings.cancel)}
            </BaseButton>
          </BaseCol>
          <BaseCol>
            <BaseButton
              disabled={isDisableSubmit}
              onClick={form?.submit}
              loading={isLoading}
            >
              {t(R.strings.confirm)}
            </BaseButton>
          </BaseCol>
        </BaseRow>
      </BaseCol>
    </BaseRow>
  )
}
