import { IWarehouseInventory } from 'app/api/inventory-new/model/warehouse-inventory'
import { IBaseTableCommon } from 'app/components/tables/common-table/model'

export interface IGeneralWarehouseInventoryManagementTable extends IBaseTableCommon, IWarehouseInventory {
  _id?: string
  ordinalNumber?: number
  productCode?: string
  productName?: string
  productType?: string
  productPurchaseMeasurementUnitName?: string
  availableQuantity?: number
  total?: number
  roadWarehouseInventoriesAvailableQuantity?: number

}


export enum EWarehouseInventoryColumnType {
  AVAILABLE = 'AVAILABLE',
  OUTBOUND = 'OUTBOUND'
}

export const initResponseFilterTableManagement = {
  search: '',
  status: [],
  warehouseId: ''
}