import { PlusOutlined } from '@ant-design/icons'
import {
  DiscountTypeEnum,
  IBillDiscount,
} from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { useCreateCampaignContext } from 'app/containers/Marketing/DiscountCampaignManagement/CreateCampaign/context'
import { IBillDiscountInTable } from 'app/containers/Marketing/DiscountCampaignManagement/CreateCampaign/type'
import { getRandomUuid } from 'parkway-web-common'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
export const ModalAddTotalBill = () => {
  const { onAddTotalBill } = useCreateCampaignContext()
  const { t } = useTranslation()
  const modalRef = useRef<IRefModal>({})
  const [form] = BaseForm.useForm<IBillDiscount>()

  const handleOpenModal = () => {
    modalRef.current?.open?.()
  }

  const handleSubmit = (values: IBillDiscount) => {
    const newBill: IBillDiscountInTable = {
      ...values,
      _id: getRandomUuid(),
    }

    onAddTotalBill?.(newBill)
    modalRef.current?.hide?.()
    form?.resetFields()
  }

  return (
    <ModalComponent
      buttonOpenCustom={
        <BaseButton
          icon={<PlusOutlined rev={undefined} />}
          onClick={handleOpenModal}
        >
          {t(R.strings.add_value_total_bill)}
        </BaseButton>
      }
      titleModal={R.strings.add_value_total_bill}
      ref={modalRef}
      handleSubmit={form?.submit}
      renderContent={
        <BaseForm form={form} onFinish={handleSubmit}>
          <BaseSpace size={4}>
            <BaseForm.Item
              label={t(R.strings.value_total_bill)}
              name={'totalBill'}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.value_total_bill),
                  }),
                },
              ]}
              required
            >
              <NumberInput
                min={0}
                placeholder={t(R.strings.enter_amount_paid)}
                suffix={'đ'}
              />
            </BaseForm.Item>

            <BaseForm.Item
              label={t(R.strings.discount_type)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.discount_type),
                  }),
                },
              ]}
              name={'dType'}
            >
              <BaseSelect
                placeholder={t(R.strings.select_discount_type)}
                options={[
                  {
                    label: t(R.strings.cash),
                    value: DiscountTypeEnum.Amount,
                  },
                  {
                    label: t(R.strings.discount_percent),
                    value: DiscountTypeEnum.Percent,
                  },
                ]}
              />
            </BaseForm.Item>

            <BaseForm.Item
              label={t(R.strings.discount_value)}
              name={'discount'}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.discount_type),
                  }),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const type = getFieldValue('dType') as DiscountTypeEnum
                    switch (type) {
                      case DiscountTypeEnum.Percent:
                        if (+value > 100) {
                          return Promise.reject(
                            t(R.strings.discount_value_must_less_than_100),
                          )
                        }
                        return Promise.resolve()
                      default:
                        return Promise.resolve()
                    }
                  },
                }),
              ]}
              dependencies={['dType']}
            >
              <NumberInput
                min={0}
                placeholder={t(R.strings.enter_discount_value)}
                precision={1}
              />
            </BaseForm.Item>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}

const NumberInput = styled(InputNumber)`
  width: 100%;
`
