/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestExportExcelReport02And03,
  requestGetReport03,
} from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import {
  IParamsGetReport02,
  IResponseGetReport02,
} from 'app/api/report/model/report-02'
import R from 'app/assets/R'
import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import {
  RenderValueTableReport,
  convertColumnTable,
  useBaseTableReportHook,
} from 'app/components/tables/BaseTableReport/hook'
import {
  IBaseColumnReport,
  IDataBaseTable,
} from 'app/components/tables/BaseTableReport/type'
import { initFilterTable } from 'app/hook'
import { isEqual } from 'lodash'
import {
  DateUtil,
  FORMAT_YYYYMMDD,
  PERCENT_PREFIX,
  Pagination,
  ResponseType,
  initialPagination,
} from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataReport003 } from './data'
import { notificationController } from 'app/controllers/notification-controller'
import { IResponseExportExcel } from 'app/api/report/model/common'

export const useTableHook = () => {
  const [filterResponse, setFilterResponse] = useState<IResponseFilter>(
    initFilterTable(),
  )

  const getData = async (
    pagination: Pagination,
    params: IParamsGetReport02,
  ) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IDataReport003>
    try {
      const currentParams: IParamsGetReport02 = {
        year: params?.year ?? filterResponse.year,
        month: params?.month ?? filterResponse?.month,
        startDate: DateUtil.formatDate(
          params?.startDate ?? filterResponse?.week?.startDate ?? '',
          FORMAT_YYYYMMDD,
        ),
        endDate: DateUtil.formatDate(
          params?.endDate ?? filterResponse?.week?.endDate ?? '',
          FORMAT_YYYYMMDD,
        ),
        type: 'report3',
        page: pagination?.current ? pagination?.current - 1 : 0,
      }
      const response: IResponseGetReport02 = await requestGetReport03(
        currentParams,
      )

      if (
        isEqual(response?.msgcode, ReportMessageCode.Report02.successGetData)
      ) {
        const data = response?.data?.rows ?? []
        const newData: IDataReport003[] = data?.map((item, index) => {
          return {
            noNumber: index + 1,
            key: index + 1,
            index: index + 1,
            nameEmployee: item?.employeeName ?? '',
            numberDateReCared: item?.reCareOldCustomerActual ?? 0,
            numberOffAppointmentsScheduled: item?.appointmentBookingTotal ?? 0,
            numberCheckInAchieved: item?.appointmentCheckInTotal ?? 0,
            crv1: item?.CVR1,
            crv2: item?.CVR2,
            crv1GrowthInPeriod: item?.CVR1PeriodPercent,
            crv2GrowthInPeriod: item?.CVR2PeriodPercent,
            crv1_period: item?.previousCVR1,
            crv2_period: item?.previousCVR2,
          }
        })

        const metadata = response?.data?.metadata ?? []

        const newPagination: Pagination = {
          ...pagination,
          total: metadata?.totalItem ?? 0,
        }

        return {
          pagination: newPagination,
          data: newData,
        }
      }

      return initData
    } catch (e) {
      return initData
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({ getTreeTableData: getData })

  const { t } = useTranslation()

  const columns = useMemo(() => {
    const options: IBaseColumnReport<IDataReport003> = [
      convertColumnTable({
        title: t(R.strings.ordinal_number),
        key: 'noNumber',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record: IDataReport003) =>
          RenderValueTableReport({
            record,
            valueString: record?.noNumber,
          }),
      }),
      convertColumnTable({
        title: t(R.strings.name_employee),
        key: 'nameEmployee',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record: IDataReport003) =>
          RenderValueTableReport({
            record,
            valueString: record?.nameEmployee,
          }),
      }),
    ]

    return [
      ...options,
      convertColumnTable({
        title: t(R.strings.number_date_re_cared),
        key: 'numberDateReCared',
        render: (_, record: IDataReport003) =>
          RenderValueTableReport({
            record,
            prefix: ' ',
            firstLevelValue: record?.numberDateReCared,
          }),
      }),
      convertColumnTable({
        title: t(R.strings.number_off_appointments_scheduled),
        key: 'numberOffAppointmentsScheduled',
        render: (_, record: IDataReport003) =>
          RenderValueTableReport({
            record,
            prefix: ' ',
            firstLevelValue: record?.numberOffAppointmentsScheduled,
          }),
      }),
      convertColumnTable({
        title: t(R.strings.crv_1),
        key: 'crv1',
        render: (_, record: IDataReport003) =>
          RenderValueTableReport({
            record,
            prefix: ' ',
            firstLevelValue: record?.crv1,
            decimal: 3,
          }),
      }),
      convertColumnTable({
        title: t(R.strings.crv_1_period),
        key: 'crv1_period',
        render: (_, record: IDataReport003) =>
          RenderValueTableReport({
            record,
            prefix: ' ',
            firstLevelValue: record?.crv1_period,
            decimal: 3,
          }),
      }),
      convertColumnTable({
        title: t(R.strings.number_check_in_achieved),
        key: 'numberCheckInAchieved',
        render: (_, record: IDataReport003) =>
          RenderValueTableReport({
            record,
            prefix: ' ',
            firstLevelValue: record?.numberCheckInAchieved,
            decimal: 3,
          }),
      }),
      convertColumnTable({
        title: t(R.strings.crv_2_period),
        key: 'crv2_period',
        render: (_, record: IDataReport003) =>
          RenderValueTableReport({
            record,
            prefix: ' ',
            firstLevelValue: record?.crv2_period,
            decimal: 3,
          }),
      }),
      convertColumnTable({
        title: t(R.strings.crv_2),
        key: 'crv2',
        render: (_, record: IDataReport003) =>
          RenderValueTableReport({
            record,
            prefix: ' ',
            firstLevelValue: record?.crv2,
            decimal: 3,
          }),
      }),
      convertColumnTable({
        title: t(R.strings.crv_1_growth_in_period),
        key: 'crv1GrowthInPeriod',
        render: (_, record: IDataReport003) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.crv1GrowthInPeriod,
            prefix: PERCENT_PREFIX,
            isShowValueUpAndDown: true,
            percent: record?.crv1GrowthInPeriod,
          }),
      }),
      convertColumnTable({
        title: t(R.strings.crv_2_growth_in_period),
        key: 'crv2GrowthInPeriod',
        render: (_, record: IDataReport003) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.crv2GrowthInPeriod,
            prefix: PERCENT_PREFIX,
            isShowValueUpAndDown: true,
            percent: record?.crv2GrowthInPeriod,
          }),
      }),
    ]
  }, [t, expandedRowKeys])

  const exportExcel = async () => {
    try {
      const currentParams: IParamsGetReport02 = {
        year: filterResponse.year,
        month: filterResponse?.month,
        startDate: DateUtil.formatDate(
          filterResponse?.week?.startDate ?? '',
          FORMAT_YYYYMMDD,
        ),
        endDate: DateUtil.formatDate(
          filterResponse?.week?.endDate ?? '',
          FORMAT_YYYYMMDD,
        ),
        type: 'report3',
      }

      fetchLoading(true)
      const res: ResponseType<IResponseExportExcel> =
        await requestExportExcelReport02And03(currentParams)

      if (
        res?.msgcode === ReportMessageCode.Report02.successGetData &&
        res?.data?.success
      ) {
        window.open(res?.data?.data ?? '')
        notificationController.success({
          message: t(R.strings.download_excel_success),
        })
      }
    } catch (error: any) {
      // console.log(error)
      notificationController.error(error)
    } finally {
      fetchLoading(false)
    }
  }

  const data = useMemo(() => {
    return tableData.data.map(item => {
      const isExpanded = expandedRowKeys.includes(item.childrenKey)
      const oldChild = item?.children?.filter(itm => !itm?.parentKey) ?? []
      if (isExpanded) {
        const newChild = item?.childOfFirstChild ?? []
        return {
          ...item,
          children: [...newChild, ...oldChild],
        }
      }
      return {
        ...item,
        children: oldChild,
      }
    })
  }, [tableData.data, expandedRowKeys])

  const handleChangeFilter = (filter: IResponseFilter) => {
    const newFilter = {
      year: filter?.year,
      month: filter?.month,
      week: filter?.week,
    }
    const oldFilter = {
      year: filterResponse?.year,
      month: filterResponse?.month,
      week: filterResponse?.week,
    }
    setFilterResponse(filter)
    if (!isEqual(newFilter, oldFilter)) {
      fetch(initialPagination, {
        year: filter.year,
        month: filter?.month,
        startDate: filter?.week?.startDate,
        endDate: filter?.week?.endDate,
      })
    }
  }

  return {
    columns,
    tableData,
    data,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleChangeFilter,
    exportExcel
  }
}
