import {
  CalendarOutlined,
  ClockCircleOutlined,
  EditOutlined,
} from '@ant-design/icons'
import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { VerticalLine } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/components/VerticalLine'
import { StatusServiceTreatmentScheduleEnumDefine } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/constant/treatment-phase'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import { TagStatusComponent } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/components'
import {
  IAppointmentSession,
  IDoctorTreatmentSchedule,
  ITreatmentPhaseTreatmentSchedule,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/treatment-schedule'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import { BORDER_RADIUS, moment } from 'parkway-web-common'
import { useMemo } from 'react'
import styled from 'styled-components'
import { TreatmentPhaseAction } from '../../components'
import { AddDoctorModal } from '../../modal'

export const HeaderLayout = ({
  item,
  idSchedule,
}: {
  item: ITreatmentPhaseTreatmentSchedule
  idSchedule?: string
}) => {
  const statusData = useMemo(() => {
    return StatusServiceTreatmentScheduleEnumDefine?.[item?.status]
  }, [item?.status])

  const {
    onChangePhaseApprover,
    onChangePhaseAttendingPhysician,
    onAddAppointmentSession,
  } = useProfilePatientContext()

  const _onChangePhaseApprover = (doctor: IDoctorTreatmentSchedule) => {
    if (!idSchedule || !item?.id) return
    onChangePhaseApprover?.({
      idSchedule,
      idTreatmentPhase: item?.id,
      phaseApprover: doctor,
    })
  }

  const _onChangePhaseAttendingPhysician = (
    doctor: IDoctorTreatmentSchedule,
  ) => {
    if (!idSchedule || !item?.id) return
    onChangePhaseAttendingPhysician?.({
      idSchedule,
      idTreatmentPhase: item?.id,
      phaseAttendingPhysician: doctor,
    })
  }

  const _onChangeAppointmentSession = (
    appointmentSession: IAppointmentSession,
  ) => {
    if (!idSchedule || !item?.id) return

    onAddAppointmentSession?.({
      idSchedule,
      idTreatmentPhase: item?.id,
      appointmentSession,
    })
  }

  const monthDifference = useMemo(() => {
    const startMoment = moment(item?.startDate)
    const endMoment = moment(item?.endDate)
    return endMoment.diff(startMoment, 'months') + 1
  }, [item])

  return (
    <BaseSpace>
      <BaseRow justify={'space-between'} align={'top'} gutter={[15, 10]}>
        <BaseCol>
          <BaseSpace size={10}>
            <BaseText
              children={item?.name}
              fontWeight="semibold"
              fontSize="md"
            />
            <BaseRow style={{ margin: 0 }} gutter={15} align={'middle'}>
              <BaseCol>
                <BaseRow gutter={15}>
                  <ClockCircleOutlined
                    rev={undefined}
                    style={{ opacity: 0.7 }}
                    color={convertedVariables.primaryColor}
                  />
                  <BaseCol>
                    <BaseText
                      children={`${monthDifference} ${t(R.strings.month)}`}
                      opacity="0.7"
                      fontWeight="medium"
                    />
                  </BaseCol>
                </BaseRow>
              </BaseCol>
              <BaseCol>
                <Dot />
              </BaseCol>
              <BaseCol>
                <BaseRow gutter={15}>
                  <CalendarOutlined rev={undefined} />
                  <BaseCol>
                    <BaseText
                      children={`${item?.startDate?.replace(
                        new RegExp('-', 'g'),
                        '/',
                      )} - ${item?.endDate?.replace(
                        new RegExp('-', 'g'),
                        '/',
                      )}`}
                      opacity="0.7"
                      fontWeight="medium"
                    />
                  </BaseCol>
                </BaseRow>
              </BaseCol>
            </BaseRow>
          </BaseSpace>
        </BaseCol>
        <BaseCol>
          <BaseRow gutter={[15, 10]} align={'middle'}>
            <BaseCol>
              <TagStatusComponent
                color={statusData.color}
                textKeyI18n={t(statusData?.i18nKey)}
                backgroundColor={statusData.backgroundColor}
              />
            </BaseCol>
            <BaseCol>
              <VerticalLine />
            </BaseCol>
            <BaseCol>
              <TreatmentPhaseAction
                handleSubmit={_onChangeAppointmentSession}
                idSchedule={idSchedule}
                treatmentPhase={item}
              />
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>
      <BaseRow gutter={[15, 10]} align={'top'}>
        <BaseCol xl={8}>
          <BaseSpace size={8}>
            <BaseRow gutter={10} align={'middle'}>
              <BaseCol>
                <BaseText children={t(R.strings.phase_attending_physician)} />
              </BaseCol>
              <div onClick={event => event?.stopPropagation()}>
                <AddDoctorModal
                  isShowTextOpenModal={false}
                  titleModal={t(R.strings.phase_attending_physician)}
                  iconOpen={
                    <BaseCol>
                      <EditOutlined rev={undefined} />
                    </BaseCol>
                  }
                  onPress={_onChangePhaseAttendingPhysician}
                />
              </div>
            </BaseRow>
            <BaseAvatarProfile
              name={item?.phaseAttendingPhysician?.name}
              jobTitle={item?.phaseAttendingPhysician?.jobTitle}
            />
          </BaseSpace>
        </BaseCol>
        <BaseCol xl={8}>
          <BaseSpace size={8}>
            <BaseRow gutter={5} align={'middle'}>
              <BaseCol>
                <BaseText children={t(R.strings.phase_approver)} />
              </BaseCol>
              <div onClick={event => event?.stopPropagation()}>
                <AddDoctorModal
                  isShowTextOpenModal={false}
                  titleModal={t(R.strings.phase_approver)}
                  iconOpen={
                    <BaseCol>
                      <EditOutlined rev={undefined} />
                    </BaseCol>
                  }
                  onPress={_onChangePhaseApprover}
                />
              </div>
            </BaseRow>
            <BaseAvatarProfile
              name={item?.phaseApprover?.name}
              jobTitle={item?.phaseApprover?.jobTitle}
            />
          </BaseSpace>
        </BaseCol>
      </BaseRow>
    </BaseSpace>
  )
}

const Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${convertedVariables.primaryColor};
  border-radius: ${BORDER_RADIUS};
  opacity: 0.5;
`
