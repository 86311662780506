/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestDetailSkillManagement,
  requestUpdateSkillManagement,
} from 'app/api/skill'
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IFormData,
  IFormRule,
  IPropsUpdateSkill,
  StatusSkillEnum,
} from './type'
import {
  IIndustry,
  ILevelSkill,
  IPayloadUpdateSkillManagement,
  IRangeExperience,
  ISkill,
} from 'app/api/skill/model/management'
import { SkillMessageCode } from 'app/api/skill/constant'
import { requestGetAllIndustryManagement } from 'app/api/industry'
import { IndustryMessageCode } from 'app/api/industry/constant'
import { requestGetAllLevelSkillManagement } from 'app/api/level-skill'
import { LevelSkillMessageCode } from 'app/api/level-skill/constant'
import { requestGetAllRangeExperienceManagement } from 'app/api/range-experience'
import { RangeExperienceMessageCode } from 'app/api/range-experience/constant'
import {
  convertArrayStatusFilterToArrayStatusNumber,
  convertStatusToFilterStatus,
} from 'app/common/helpers'

export const useModalHook = (props: IPropsUpdateSkill) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormData>()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [industries, setIndustry] = useState<IIndustry[]>()
  const [levelSkills, setLevelSkill] = useState<ILevelSkill[]>()
  const [rangeExperiences, setRangeExperience] = useState<IRangeExperience[]>()

  const getDetail = async () => {
    if (!props?.id) {
      return
    }
    try {
      const res: ResponseType<ISkill> = await requestDetailSkillManagement(
        props?.id,
      )

      if (
        isEqual(res?.msgcode, SkillMessageCode.SkillManagement.successGetData)
      ) {
        const data = res?.data

        form.setFieldsValue({
          _id: data._id,
          name: data?.name,
          industry: data?.industry,
          status: convertStatusToFilterStatus(data?.status),
        })
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  useEffect(() => {
    visible && getDetail()
  }, [props?.id, visible])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)
    try {
      const body: IPayloadUpdateSkillManagement = {
        _id: values?._id,
        name: values?.name,
        industry: values?.industry,
        status:
          convertArrayStatusFilterToArrayStatusNumber([
            values?.status,
          ] as any)?.[0] ?? StatusSkillEnum.active,
      }

      const response: ResponseType<string> = await requestUpdateSkillManagement(
        {
          id: props?.id,
          body,
        },
      )

      if (
        isEqual(
          response?.msgcode,
          SkillMessageCode.SkillManagement.successPutData,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        props?.fetchTable?.()
        notificationController.success({
          message: t(R.strings.update),
          description: response?.message,
        })
      } else {
        notificationController.error({
          message: t(R.strings.update),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const getAllIndustry = async () => {
    try {
      const res: ResponseType<IIndustry[]> =
        await requestGetAllIndustryManagement()

      if (
        isEqual(
          res?.msgcode,
          IndustryMessageCode.IndustryManagement.successGetData,
        )
      ) {
        const data: IIndustry[] = res?.data || []
        setIndustry(data)
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  const getAllLevelSkill = async () => {
    try {
      const res: ResponseType<ILevelSkill[]> =
        await requestGetAllLevelSkillManagement()

      if (
        isEqual(
          res?.msgcode,
          LevelSkillMessageCode.LevelSkillManagement.successGetData,
        )
      ) {
        const data: ILevelSkill[] = res?.data || []
        setLevelSkill(data)
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  const getAllRangeExperience = async () => {
    try {
      const res: ResponseType<IRangeExperience[]> =
        await requestGetAllRangeExperienceManagement()

      if (
        isEqual(
          res?.msgcode,
          RangeExperienceMessageCode.RangeExperienceManagement.successGetData,
        )
      ) {
        const data: IRangeExperience[] = res?.data || []
        setRangeExperience(data)
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  useEffect(() => {
    visible && getAllIndustry()
    visible && getAllLevelSkill()
    visible && getAllRangeExperience()
  }, [visible])

  const rules: IFormRule = useMemo(() => {
    return {
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.skill_field_name),
          }),
        },
      ],
      industry: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.skill_field_industry),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    industries,
    levelSkills,
    rangeExperiences,
  }
}
