/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd'
import { KeyImport } from 'app/api/import/constant'
import { requestRequestImportUserData } from 'app/api/marketing/discount-voucher'
import { DiscountVoucherMessageCode } from 'app/api/marketing/discount-voucher/constant'
import { IUserDataVoucher } from 'app/api/marketing/discount-voucher/model'
import { IRefModal } from 'app/components/common/ModalComponent'
import { _DEV_ } from 'app/constant'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useRef, useState } from 'react'
import { IFormData } from '../type'

export const useUserTargetHook = ({
  form,
}: {
  form: FormInstance<IFormData>
}) => {
  const [listUserData, setListUserData] = useState<IUserDataVoucher[]>([])
  const [listUserDataError, setListUserDataError] = useState<string[]>([])
  const [openImportArea, setOpenImportArea] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const modalDisplayUserRef = useRef<IRefModal>({})

  const onCloseModal = () => {
    setOpenImportArea(false)
  }

  const onOpenModal = () => {
    setOpenImportArea(true)
  }

  const onRemoveFile = () => {
    setFile(null)
    setListUserData([])
    setListUserDataError([])
  }

  const onConfirmModal = async (file?: File) => {
    if (!file) return
    try {
      const formData = new FormData()
      formData.append(KeyImport.All.file, file)

      const res: ResponseType<{
        userData: IUserDataVoucher[]
        notFoundIds: string[]
      }> = await requestRequestImportUserData(formData)

      if (
        isEqual(
          res?.msgcode,
          DiscountVoucherMessageCode.DiscountVoucher.success,
        )
      ) {
        const userData = res?.data?.userData ?? []

        setListUserData(userData)
        setListUserDataError(res?.data?.notFoundIds ?? [])
        form.validateFields(['totalVoucher'])
        setFile(file)
        form.setFieldsValue({
          file,
        })
        modalDisplayUserRef?.current?.open?.()
        onCloseModal()
      }
    } catch (error) {
      _DEV_ && console.error(error)
    }
  }

  return {
    listUserData,
    listUserDataError,
    openImportArea,
    file,
    onOpenModal,
    onRemoveFile,
    onConfirmModal,
    modalDisplayUserRef,
    setListUserData,
    setListUserDataError,
    setOpenImportArea,
    onCloseModal,
  }
}
