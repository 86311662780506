import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import JobTitleLevelManagement from 'app/containers/HumanResources/JobTitleLevelManagement'

const JobTitleLevelManagementPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.job_title_level_content_management)}</PageTitle>
      <JobTitleLevelManagement />
    </>
  )
}
const JobTitleLevelManagementPage = memo(JobTitleLevelManagementPageCpn, isEqual)

export default JobTitleLevelManagementPage
