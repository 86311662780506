import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import styled from 'styled-components'
import { useHookTable } from './hook'
import { ChildTableLayout, FilterLayout } from './layouts'
const RosterReportContainer = () => {
  const {
    tableData,
    columns,
    handleTableChange,
    filter,
    handleChangeFilter,
    loading,
    pagination,
    expandedRowKeys,
    handleExpand,
  } = useHookTable()
  return (
    <S.BaseManagementWrapper>
      <HeaderPage titleI18nKey={R.strings.monthly_roster} />
      <RootWrapper>
        <FilterLayout formData={filter} onChange={handleChangeFilter} />
        <BaseTableWrapper
          columns={columns}
          dataSource={tableData.data}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            expandIcon: () => null,
            showExpandColumn: false,
            expandedRowRender: record => (
              <ChildTableLayout filter={filter} record={record} />
            ),
          }}
          rowClassName="row-overwrite-style"
        />
      </RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default RosterReportContainer

const RootWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
`

const BaseTableWrapper = styled(BaseTableManagement)`
  tr.ant-table-expanded-row > td {
    background: ${convertedVariables.backgroundColor};
  }

  tr.ant-table-expanded-row:hover > td {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell {
    border: 0px !important;
    border-bottom: 0.5px solid ${convertedVariables.neutralBlack2Color} !important;
  }

  .all-column:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell-row-hover:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor} !important;
  }

  .ant-table-tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed
    > .ant-table-wrapper
    .ant-table {
    margin-block: -16px;
    margin-inline: 32px -16px;
  }

  .child-column {
    color: rgba(0, 34, 68, 0.4) !important;
  }

  .important-column {
    color: ${convertedVariables.statesRedColor} !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-radius: 0px !important;
  }

  .ant-table-thead > tr > th {
    border: 0px !important;
    font-weight: 600 !important;
  }
`
