/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'
import {
  convertColumnTable,
  useBaseTableReportHook,
} from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { getArray } from 'app/components/tables/common-table/constant'
import { PADDING, Pagination, initialPagination } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable } from './type'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { SvgTreatmentCollapseIcon } from 'app/assets/svg-assets'
import styled from 'styled-components'
import { convertedVariables } from 'app/styles/themes/themeVariables'

export const useHook = () => {
  const { t } = useTranslation()

  const getDataTable = async (pagination?: Pagination) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IDataTable>
    try {
      const dataTables = getArray(4)?.map((item, index) => {
        return {
          key: index + 1,
          condition: 'Hoàn thành bước 1',
          minutes: 60,
          step: `${item}`,
          treatmentName: `Thủ thuật ${item}`,
        } as IDataTable
      })

      return {
        ...initData,
        data: dataTables,
      }
    } catch (error) {
      console.log({ error })

      return initData
    }
  }

  const { tableData, fetch, fetchLoading } = useBaseTableReportHook({
    getTreeTableData: getDataTable,
  })

  const fetchNewData = () => {
    fetch(tableData.pagination)
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<IDataTable>({
        title: t(R.strings.treatment_management_content_treatment_name),
        key: 'treatmentName',
        className: 'name-column',
        render: (_, record) => {
          return (
            <BaseRow
              align={'middle'}
              gutter={[16, 16]}
              style={{ marginLeft: 0 }}
            >
              <SvgTreatmentCollapseIcon />
              <BaseCol>
                <BaseText
                  children={record.treatmentName}
                  fontWeight="medium"
                  fontSize="xs"
                  opacity="0.9"
                />
              </BaseCol>
            </BaseRow>
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.item_management_content_step_perform),
        key: 'step',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => {
          return (
            <BaseText
              children={record.step}
              fontWeight="semibold"
              fontSize="xs"
              opacity="0.5"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.time),
        key: 'minutes',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => {
          return (
            <MinutesWrapper>
              <BaseText
                children={`${record.minutes} ${t(R.strings.minute)}`}
                fontWeight="medium"
                fontSize="xs"
                colorText="statesGreenColor"
              />
            </MinutesWrapper>
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.item_management_content_condition_perform),
        key: 'condition',
        render: (_, record) => {
          return (
            <BaseText
              children={record.condition}
              fontWeight="medium"
              fontSize="xs"
              opacity="0.5"
            />
          )
        },
      }),
    ]
  }, [t, fetchNewData])

  return {
    columns,
    tableData,
    fetch,
    fetchLoading,
  }
}

const MinutesWrapper = styled.div`
  border: 1px solid ${convertedVariables.statesGreenColor};
  background-color: ${convertedVariables.statesGreenLightColor};
  padding: 4px 8px;
  border-radius: ${PADDING.md};
  width: fit-content;
`
