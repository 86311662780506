/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DownloadOutlined } from '@ant-design/icons'
import { Image, Modal, UploadFile, UploadProps } from 'antd'
import { Upload } from 'antd/lib'
import R from 'app/assets/R'
import {
  SvgDeleteDesignIcon,
  SvgImageExampleIcon,
  SvgImageFolderIcon,
} from 'app/assets/svg-assets'
import { DRIVE_ACTION } from 'app/common/config'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useProfilePatientContext } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/context'
import {
  IFolderImageChild,
  IFolderImageParent,
} from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/type/image-patient'
import { getBase64 } from 'app/hook'
import { FONT_SIZE } from 'parkway-web-common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

interface IProps {
  parent: IFolderImageParent
  item: IFolderImageChild
}

export const ChildrenFolderImageItem = ({ item, parent }: IProps) => {
  const { t } = useTranslation()
  const { onChangeImageList, onDeleteChildImagePatient } =
    useProfilePatientContext()

  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as Blob)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
    setPreviewTitle(
      file.name || file?.url!.substring(file.url!.lastIndexOf('/') + 1),
    )
  }

  const handleCancel = () => setPreviewOpen(false)

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    onChangeImageList?.({
      childName: item?.name,
      parentName: parent?.name,
      listImage: newFileList,
    })
  }

  const _onDeleteChildFolder = () => {
    onDeleteChildImagePatient?.({
      childName: item?.name,
      parentName: parent?.name,
    })
  }

  return (
    <BaseSpace>
      <S.CollapseWrapper
        label={
          <BaseRow gutter={15} align={'middle'}>
            <BaseCol style={{ display: 'flex', alignItems: 'center' }}>
              <SvgImageFolderIcon />
            </BaseCol>
            <BaseCol>
              <BaseText
                children={item?.name ?? '-'}
                fontWeight="semibold"
                fontSize="xs"
              />
            </BaseCol>
          </BaseRow>
        }
        extra={
          <BaseRow gutter={[5, 5]} align={'middle'}>
            <BaseCol>
              <BaseButton
                icon={<DownloadOutlined rev={undefined} />}
                type="text"
              />
            </BaseCol>
            <BaseCol>
              <BaseButton
                icon={<SvgDeleteDesignIcon />}
                type="text"
                onClick={_onDeleteChildFolder}
              />
            </BaseCol>
          </BaseRow>
        }
        children={
          <S.ImageWrapper size={10}>
            <Upload
              action={DRIVE_ACTION.UPLOAD_IMAGE}
              listType="picture-card"
              fileList={item.urls ?? []}
              onPreview={handlePreview}
              onChange={handleChange}
              multiple
            >
              <S.UploadWrapper direction="vertical" size={5}>
                <SvgImageExampleIcon width={26} height={26} />
                <BaseRow gutter={[10, 10]} align={'middle'} justify={'center'}>
                  <BaseCol>
                    <S.PlusIcon $size={FONT_SIZE.xxs} rev={undefined} />
                  </BaseCol>
                  <BaseCol>
                    <BaseText colorText="otherBlueColor" fontWeight='medium'>
                      {t(R.strings.add_image)}
                    </BaseText>
                  </BaseCol>
                </BaseRow>
              </S.UploadWrapper>
            </Upload>

            <BaseText
              fontSize="xxs"
              children={`* ${t(
                R.strings.note_maximum_image_upload_size_is_5_MB,
              )}`}
            />
          </S.ImageWrapper>
        }
      />
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <Image alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </BaseSpace>
  )
}
