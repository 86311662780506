/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteFilled, InboxOutlined } from '@ant-design/icons'
import {
  importLimitDiscount,
  updateLimitDiscount,
} from 'app/api/marketing/limit-discount'
import { LimitDiscountMessageCode } from 'app/api/marketing/limit-discount/constant'
import R from 'app/assets/R'
import { SvgExportIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseUpload } from 'app/components/common/BaseUpload'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { BaseButtonsForm } from 'app/components/common/forms/BaseButtonsForm'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { _DEV_ } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import {
  FONT_SIZE,
  PADDING,
  ResponseType,
  formatMoney,
  normFile,
} from 'parkway-web-common'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as S from '../HeaderPage/styles'
import { DefineStatusEnum } from './constant'
import { IResponseImportFile, StatusEnum } from './type'

export const ImportFileModal = () => {
  const refModal = useRef<IRefModal>({})
  const { t } = useTranslation()
  const [fileSelected, setFileSelected] = useState<File | null | undefined>(
    null,
  )
  const [isLoading, setIsLoading] = useState(false)

  const [listService, setListService] = useState<IResponseImportFile[]>([])

  const onSuccessImportService = async (importList: IResponseImportFile[]) => {
    try {
      const data = importList?.map(i => ({
        treatmentId: i?._id || '',
        maximum: Number(i?.maximum) || 0,
      }))
      const response = await updateLimitDiscount({ data })
      if (
        isEqual(
          response?.msgcode,
          LimitDiscountMessageCode.LimitDiscount.successUpdate,
        )
      ) {
        notificationController?.success({
          message: response?.message,
        })
      }
    } catch (error) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.with_error_please_contact_with_admin),
      })
    }
  }

  const _onConfirmModal = async () => {
    if (successData?.length) {
      console.log({ successData, listService })
      onSuccessImportService?.(successData)
    }
    refModal?.current?.hide?.()
    setFileSelected(null)
    setListService([])
  }

  const onRemoveFile = () => {
    setFileSelected(null)
    setListService([])
  }

  const onChangeFile = async (values: { file: any }) => {
    const { file } = values
    const { lastModified, name, type, originFileObj } = file
    const newFile = new File([originFileObj], name, { type, lastModified })
    if (!newFile) return

    setFileSelected(newFile)

    try {
      setIsLoading(true)
      const formData = new FormData()

      formData.append('file', newFile)

      const res: ResponseType<IResponseImportFile[]> =
        await importLimitDiscount(formData)
      if (
        isEqual(
          res?.msgcode,
          LimitDiscountMessageCode.LimitDiscount.successImportFile,
        )
      ) {
        setListService(res.data)
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const onOpenModal = () => {
    refModal.current?.open?.()
  }

  const dataService = useMemo(() => {
    return listService?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [listService])

  const successData = useMemo(() => {
    return listService?.filter(item => isEqual(item.status, StatusEnum.Success))
  }, [listService])

  const errorData = useMemo(() => {
    return listService?.filter(
      item => !isEqual(item.status, StatusEnum.Success),
    )
  }, [listService])

  const handleCancel = () => {
    setFileSelected(null)
    setListService([])
  }

  return (
    <ModalComponent
      titleModal={R.strings.import_file}
      widthModal={1000}
      buttonOpenCustom={
        <BaseCol>
          <S.ButtonAction
            $backgroundColor={'var(--orange-gradient-color)'}
            $color={'var(--white)'}
            onClick={onOpenModal}
          >
            <BaseRow gutter={8} align={'middle'}>
              <S.SvgCol>
                <SvgExportIcon />
              </S.SvgCol>
              <S.BaseTextButton $color={'var(--white)'}>
                {t(R.strings.import)}
              </S.BaseTextButton>
            </BaseRow>
          </S.ButtonAction>
        </BaseCol>
      }
      ref={refModal}
      handleSubmit={_onConfirmModal}
      handleCancel={handleCancel}
      renderContent={
        <BaseSpace size={8}>
          <DraggerInput
            name="dragger"
            valuePropName="file"
            getValueFromEvent={normFile}
            noStyle
          >
            <BaseUpload.Dragger
              name="files"
              onChange={onChangeFile}
              customRequest={() => undefined}
              showUploadList={false}
              accept=".xls, .xlsx"
            >
              <DraggerWrapper>
                <IconMailBox rev={undefined} size={32} />
              </DraggerWrapper>
            </BaseUpload.Dragger>
          </DraggerInput>
          {fileSelected && (
            <FileNameDisplayWrapper
              gutter={20}
              justify={'start'}
              align={'middle'}
            >
              <BaseCol>
                <BaseText
                  fontSize="xs"
                  fontWeight="medium"
                  colorText="statesGreenColor"
                >
                  {fileSelected?.name}
                </BaseText>
              </BaseCol>
              <BaseCol>
                <BaseButton
                  icon={<DeleteFilled rev={undefined} onClick={onRemoveFile} />}
                  size="small"
                />
              </BaseCol>
            </FileNameDisplayWrapper>
          )}

          {fileSelected ? (
            <BaseSpace size={8}>
              <BaseRow
                align={'middle'}
                justify={'space-between'}
                gutter={[8, 8]}
              >
                <BaseCol>
                  <BaseText
                    children={'Danh sách dịch vụ'}
                    fontWeight="medium"
                    fontSize="xs"
                    opacity="0.7"
                  />
                </BaseCol>
                <BaseCol>
                  <BaseRow gutter={8} align={'middle'}>
                    <BaseCol>
                      <BaseText
                        children={`${successData?.length} ${t(
                          R.strings.success,
                        )}`}
                        fontWeight="medium"
                        colorText="statesGreenColor"
                      />
                    </BaseCol>
                    <BaseCol>
                      <BaseText
                        children={`${errorData?.length} ${t(
                          R.strings.error_vn_text,
                        )}`}
                        fontWeight="medium"
                        colorText="statesRedColor"
                      />
                    </BaseCol>
                  </BaseRow>
                </BaseCol>
              </BaseRow>
              <BaseTable
                pagination={{
                  pageSize: 5,
                }}
                columns={[
                  convertColumnTable<IResponseImportFile>({
                    key: 'rowNumber',
                    title: t(R.strings.row),
                    classNameWidthColumnOverwrite: 'small-column',
                    render: (_, record) => {
                      return (
                        <BaseText
                          children={record?.rowNumber}
                          fontWeight="medium"
                          opacity="0.7"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IResponseImportFile>({
                    key: 'name',
                    title: t(R.strings.service),
                    classNameWidthColumnOverwrite: 'very-big-column',
                    render: (_, record) => {
                      return (
                        <BaseText
                          children={record?.name ?? '-'}
                          fontWeight="semibold"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IResponseImportFile>({
                    key: 'maximum',
                    title: t(R.strings.limit_discount),
                    classNameWidthColumnOverwrite: 'small-column',

                    render: (_, record) => {
                      return (
                        <BaseText
                          children={
                            record?.maximum ? `${record?.maximum}%` : '-'
                          }
                          fontWeight="medium"
                          opacity="0.7"
                        />
                      )
                    },
                  }),

                  convertColumnTable<IResponseImportFile>({
                    key: 'status',
                    classNameWidthColumnOverwrite: 'small-column',
                    title: t(R.strings.status),
                    align: 'right',
                    render: (_, record) => {
                      return (
                        <BaseText
                          children={
                            record?.status
                              ? t(DefineStatusEnum[record?.status]?.text)
                              : '-'
                          }
                          fontWeight="medium"
                          style={
                            record?.status
                              ? {
                                  color:
                                    DefineStatusEnum[record?.status]?.color,
                                }
                              : undefined
                          }
                          textAlign="right"
                        />
                      )
                    },
                  }),
                ]}
                dataSource={dataService}
                loading={isLoading}
                scroll={{ x: true }}
              />
            </BaseSpace>
          ) : null}
        </BaseSpace>
      }
    />
  )
}

const DraggerInput = styled(BaseButtonsForm.Item)``

const DraggerWrapper = styled.div`
  padding: ${PADDING.xxl} 0px;
  & svg {
    fill: var(--gray);
  }
`

const IconMailBox = styled(InboxOutlined)`
  & svg {
    width: ${FONT_SIZE.xxxl};
    height: ${FONT_SIZE.xxxl};
  }
`

const FileNameDisplayWrapper = styled(BaseRow)`
  color: var(--primary-color);
  padding-top: ${PADDING.xxs};
  & svg {
    fill: red;
  }
`
const BaseTable = styled(BaseTableManagement)`
  .ant-table-thead > tr > th {
    border: none !important;
  }
  .ant-table-thead > tr > th:last-child {
    text-align: right !important;
  }
`
