import { RequireDoctorLevelLayout, TreatmentPhaseLayout } from './layouts';
import * as S from './styles';

export function ExpandedRowRender<T>({ record }: { record: T }) {

  console.log({record});
  
  return (
    <S.RootWrapper>
      <RequireDoctorLevelLayout />
      <TreatmentPhaseLayout />
    </S.RootWrapper>
  )
}
