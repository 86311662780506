import { useSortable } from '@dnd-kit/sortable'
import R from 'app/assets/R'
import { SvgDeleteDesignIcon, SvgMovefiledIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { ListTreatmentExample } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/constant'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CSS } from '@dnd-kit/utilities'

export const ItemTreatment = ({
  index,
  id,
}: {
  index?: number
  id: number
}) => {
  const { t } = useTranslation()
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <ItemTreatmentWrapper
        justify={'space-between'}
        gutter={[16, 8]}
        align={'middle'}
      >
        <BaseCol>
          <BaseRow gutter={[16, 8]} align={'middle'}>
            <SvgMovefiledIcon />
            <BaseCol>
              <BaseText
                children={`${index ?? '-'}. Buổi hẹn ${id}`}
                fontWeight="semibold"
                fontSize="xs"
              />
            </BaseCol>
          </BaseRow>
        </BaseCol>

        <BaseCol xl={18}>
          <div onClick={event => event?.stopPropagation()}>
            <BaseFormItem hiddenLabel>
              <BaseSelect
                placeholder={t(
                  R.strings.item_management_content_select_treatment,
                )}
              >
                {ListTreatmentExample?.map((item, index) => {
                  return <Option children={item} value={item} key={index} />
                })}
              </BaseSelect>
            </BaseFormItem>
          </div>
        </BaseCol>

        <div onClick={event => event?.stopPropagation()}>
          <BaseButton icon={<SvgDeleteDesignIcon />} type="text" />
        </div>
      </ItemTreatmentWrapper>
    </div>
  )
}
const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`

const ItemTreatmentWrapper = styled(BaseRow)`
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  padding: 4px ${PADDING.xs};
  cursor: move;
`
