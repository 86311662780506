import { RangePickerProps } from 'antd/es/date-picker'
import { TIMESLOT_STEP } from 'app/common/config'
import dayjs from 'dayjs'
import moment from 'moment'
import { extendMoment } from 'moment-range'
import { PageType } from './type'
import { EBookingStatus } from 'app/api/appointment/model'
import R from 'app/assets/R'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const m = extendMoment(moment as any)

export const dayStart = moment().startOf('day').hours(8)
export const dayEnd = moment().startOf('day').hours(18)
export const range = m.range(dayStart, dayEnd)

export const timeslots = Array.from(
  range.by('minutes', { step: TIMESLOT_STEP }),
)

// eslint-disable-next-line arrow-body-style
export const disabledDate: RangePickerProps['disabledDate'] = current => {
  return current && current < dayjs().subtract(1, 'day').endOf('day')
}

export const getStatus = page => {
  return page !== PageType?.CALLCENTER
    ? EBookingStatus.CONFIRM
    : EBookingStatus.REQUEST
}

export const getTitle = (_id, page, title, t) => {
  return _id && page === PageType.CALLCENTER
    ? title
    : _id
    ? t(R.strings.update_schedule)
    : t(R.strings.add_schedule)
}
