import { Bar } from '@ant-design/plots'
import { IResponseFilter } from '../FilterSurveyReport/type'
import { useEffect, useState } from 'react'
import { ResponseType } from 'parkway-web-common'
import { requestGetReviewListImprove } from 'app/api/survey'
import { SurveyMessageCode } from 'app/api/survey/constant'
import { isEqual } from 'lodash'

const colors = {
  review: '#629DAA',
  unReview: '#D6E0EA',
}

enum ValueReportEnum {
  review = 'Đánh giá',
  unReview = 'Không đánh giá',
}

enum TypeReportKeyEnum {
  review = 'review',
  unReview = 'unReview',
}

const convertToLineColor = type => {
  switch (type) {
    case ValueReportEnum.review:
      return TypeReportKeyEnum.review
    case ValueReportEnum.unReview:
      return TypeReportKeyEnum.unReview

    default:
      return TypeReportKeyEnum.unReview
  }
}

interface ReviewListImprove {
  _id: string
  value: number
  type: string
  label: string
}

interface IResponseReportReviewListImprove {
  reviewListImprove?: Array<ReviewListImprove>
  total?: Array<ReviewListImprove>
}

export function ChartNeedToImprove({
  location,
  clinic,
  area,
}: IResponseFilter) {
  const [reportReviewListImprove, setReportReviewListImprove] =
    useState<IResponseReportReviewListImprove>({})

  const fetchReportReviewListImprove = async () => {
    try {
      const param = {
        location: location ?? null,
      }

      const res: ResponseType<IResponseReportReviewListImprove> =
        await requestGetReviewListImprove(param)
      if (
        isEqual(res?.msgcode, SurveyMessageCode.SurveyReport.successGetData)
      ) {
        const data: IResponseReportReviewListImprove = res?.data || {}
        setReportReviewListImprove(data)
      }
    } catch (error) {
      console.error({ error })
    }
    return []
  }

  useEffect(() => {
    fetchReportReviewListImprove()
  }, [clinic, area])

  const reviewListImprove = reportReviewListImprove.reviewListImprove ?? []
  const unReviewListImprove = reportReviewListImprove.total ?? []

  const data = [...reviewListImprove, ...unReviewListImprove]

  const config = {
    data: data,
    xField: 'label',
    yField: 'value',
    stack: true,
    style: {
      maxWidth: 22,
      // eslint-disable-next-line
      fill: (d: any) => {
        return colors[convertToLineColor(d.type)]
      },
    },
    colorField: 'type',
    axis: { y: false },
    label: {
      // eslint-disable-next-line
      text: (d: any) => {
        if (d.type === ValueReportEnum.unReview) {
          return ''
        }
        const foundUnReviewListImprove =
          unReviewListImprove?.find(item => item.label === d.label)?.value ?? 0
        const total = foundUnReviewListImprove + d.value

        const percentage = (d.value / total) * 100
        const text = `${d.value} lượt (${percentage.toFixed(0)}%)`

        return text
      },
      position: 'inside',
    },
    interaction: {
      elementHighlightByColor: false,
    },
    state: {
      active: { linkFill: 'rgba(0,0,0,0.25)', stroke: 'black', lineWidth: 0.5 },
      inactive: { opacity: 0.5 },
    },
    legend: {
      color: {
        layout: {
          justifyContent: 'center',
          alignItems: 'flex-end',
          flexDirection: 'column',
        },
      },
    },
  }
  return <Bar {...config} />
}

export default ChartNeedToImprove
