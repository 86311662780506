import { Button as AntButton } from 'antd'
import { Severity } from 'parkway-web-common'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styled, { css } from 'styled-components'

import { defineColorBySeverity } from 'app/hook'

export const BaseWeekPicker = styled(DatePicker)``

export const WeekPickerText = styled.span<{ $isEmptyText?: boolean }>`
  color: ${props =>
    !props?.$isEmptyText ? 'var(--primary-color)' : '#d9d9d9'}; // need change
`

interface BtnProps {
  $severity?: Severity
  $noStyle?: boolean
}

export const BaseWeekInput = styled(AntButton)<BtnProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  padding: 14px 12px;

  ${props =>
    props.$noStyle &&
    css`
      width: unset;
      padding: 0;
      height: unset;
    `}

  &[disabled],
  &[disabled]:active,
  &[disabled]:focus,
  &[disabled]:hover {
    color: var(--disabled-color);
  }
  ${props =>
    !props.danger &&
    css`
      ${props.$severity &&
      css`
        box-shadow: none;
        text-shadow: none;
        background: rgba(${defineColorBySeverity(props.$severity, true)}, 0.2);

        border-color: ${defineColorBySeverity(props.$severity)};

        color: ${defineColorBySeverity(props.$severity)};

        &:hover {
          background: var(--background-color);

          border-color: rgba(
            ${defineColorBySeverity(props.$severity, true)},
            0.9
          );

          color: rgba(${defineColorBySeverity(props.$severity, true)}, 0.9);
        }

        &:focus {
          background: var(--background-color);

          border-color: rgba(
            ${defineColorBySeverity(props.$severity, true)},
            0.9
          );

          color: rgba(${defineColorBySeverity(props.$severity, true)}, 0.9);
        }
      `}

      ${props.type === 'text' &&
      css`
        &:hover {
          background: transparent;
          color: var(--primary-gradient-button-color-hover);
        }
      `}

      ${props.type === 'primary' &&
      css`
        background: var(--primary-gradient-button-color);
        transition: 2s ease;
        &:hover {
          background: var(--primary-gradient-button-color-hover);
          transition: 2s ease;
        }
      `}

      ${props.type === 'link' &&
      css`
        & span,
        a {
          text-decoration: underline;
        }
      `};
    `}
`
