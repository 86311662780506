import { useTreatmentPlanContext } from 'app/containers/DentalTreatmentManagement/TreatmentPlan/context'
import { useMemo } from 'react'
import { ToothStatusEnum } from 'app/containers/DentalTreatmentManagement/TreatmentPlan/type'
import {
  SvgRemainingToothRootsDisplayIcon,
  SvgTeethWithPathologyDisplayIcon,
  SvgToothLossDisplayIcon,
  SvgToothWithIndicationForExtractionDisplayIcon,
} from 'app/assets/svg-assets'
import { isEqual } from 'lodash'
import { IPropsToothAction } from './type'

export const useHook = ({ item, isUpTooth, index }: IPropsToothAction) => {
  const {
    bridgeToothBottomTabCurrentStatus,
    bridgeToothTopTabCurrentStatus,
    onChangeStatusToothSizeAndMorphology,
    bridgeBottomTabCurrentStatusSelecting,
    onAddBridgeBottomTabCurrentStatus,
    onChangeBridgeBottomTabCurrentStatus,
    onDeleteBridgeBottomTabCurrentStatus,
    onChangeBridgeTopTabCurrentStatus,
    onAddBridgeTopTabCurrentStatus,
    bridgeTopTabCurrentStatusSelecting,
    onDeleteBridgeTopTabCurrentStatus,
  } = useTreatmentPlanContext()

  const currentBridgeTooth = useMemo(() => {
    if (isUpTooth) {
      return bridgeToothTopTabCurrentStatus?.find(
        itm => isEqual(itm?.indexStart, index) || isEqual(index, itm?.indexEnd),
      )
    }

    return bridgeToothBottomTabCurrentStatus?.find(
      itm => isEqual(itm?.indexStart, index) || isEqual(index, itm?.indexEnd),
    )
  }, [bridgeToothBottomTabCurrentStatus, isUpTooth, index, bridgeToothTopTabCurrentStatus])

  const isDisableAction = useMemo(() => {
    if (isUpTooth) {
      if (bridgeBottomTabCurrentStatusSelecting) return true

      if (bridgeTopTabCurrentStatusSelecting) {
        const listTopTabCurrentStatusToothBridge =
          bridgeToothTopTabCurrentStatus?.filter(
            itm => itm?.indexStart !== undefined && itm?.indexEnd !== undefined,
          ) ?? []

        if (!listTopTabCurrentStatusToothBridge?.length) return false

        const nearBridgeRight = listTopTabCurrentStatusToothBridge?.find(
          itm =>
            itm?.indexStart !== undefined &&
            itm?.indexEnd !== undefined &&
            (itm?.indexStart ?? 0) > (bridgeTopTabCurrentStatusSelecting?.indexStart ?? 0),
        )

        if (nearBridgeRight && index >= (nearBridgeRight?.indexEnd ?? 0)) {
          return true
        }

        const nearBridgeLeft = listTopTabCurrentStatusToothBridge?.find(
          itm => (itm?.indexEnd ?? 0) < (bridgeTopTabCurrentStatusSelecting?.indexStart ?? 0),
        )

        if (nearBridgeLeft && index <= (nearBridgeLeft?.indexStart ?? 0)) {
          return true
        }
      }

      return !!bridgeToothTopTabCurrentStatus?.find(
        itm => (itm?.indexStart ?? 0) < index && index < (itm?.indexEnd ?? 0),
      )
    }

    if (bridgeTopTabCurrentStatusSelecting) return true

    if (bridgeBottomTabCurrentStatusSelecting) {
      const listBottomTabCurrentStatusToothBridge =
        bridgeToothBottomTabCurrentStatus?.filter(
          itm => itm?.indexStart !== undefined && itm?.indexEnd !== undefined,
        ) ?? []

      if (!listBottomTabCurrentStatusToothBridge?.length) return false

      const nearBridgeRight = listBottomTabCurrentStatusToothBridge?.find(
        itm =>
          itm?.indexStart !== undefined &&
          itm?.indexEnd !== undefined &&
          (itm?.indexStart ?? 0) > (bridgeBottomTabCurrentStatusSelecting?.indexStart ?? 0),
      )

      if (nearBridgeRight && index >= (nearBridgeRight?.indexEnd ?? 0)) {
        return true
      }

      const nearBridgeLeft = listBottomTabCurrentStatusToothBridge?.find(
        itm => (itm?.indexEnd ?? 0) < (bridgeBottomTabCurrentStatusSelecting?.indexStart ?? 0),
      )

      if (nearBridgeLeft && index <= (nearBridgeLeft?.indexStart ?? 0)) {
        return true
      }
    }

    return !!bridgeToothBottomTabCurrentStatus?.find(
      itm => (itm?.indexStart ?? 0) < index && index < (itm?.indexEnd ?? 0),
    )
  }, [
    bridgeToothBottomTabCurrentStatus,
    bridgeTopTabCurrentStatusSelecting,
    bridgeBottomTabCurrentStatusSelecting,
    index,
    bridgeToothTopTabCurrentStatus,
  ])

  const opacityImage = useMemo(() => {
    if (isDisableAction) return 0.3

    if (isEqual(item?.currentStatus, ToothStatusEnum.toothLoss)) {
      return 0.3
    }
    return 1
  }, [item?.currentStatus, isDisableAction])

  const iconDefine = useMemo(() => {
    switch (item?.currentStatus) {
      case ToothStatusEnum.toothLoss:
        return <SvgToothLossDisplayIcon />

      case ToothStatusEnum.teethWithPathology:
        return <SvgTeethWithPathologyDisplayIcon />

      case ToothStatusEnum.toothWithIndicationForExtraction:
        return <SvgToothWithIndicationForExtractionDisplayIcon />

      case ToothStatusEnum.remainingToothRoots:
        return <SvgRemainingToothRootsDisplayIcon />

      default:
        return null
    }
  }, [item?.currentStatus, bridgeTopTabCurrentStatusSelecting, bridgeBottomTabCurrentStatusSelecting])

  const urlImage = useMemo(() => {
    if (currentBridgeTooth) {
      return item?.imageToothImplant
    }

    switch (item?.currentStatus) {
      case ToothStatusEnum.implantPlacement:
        return item?.imageToothImplant
      default:
        return item?.imageTooth
    }
  }, [item, currentBridgeTooth, index])

  const _onChangeTeethWithPathology = () => {
    if (!item) return

    onChangeStatusToothSizeAndMorphology?.(
      item?.position,
      item.key,
      ToothStatusEnum.teethWithPathology,
    )

    _onDeleteBridgeTooth()
  }

  const _onChangeToothLoss = () => {
    if (!item) return
    onChangeStatusToothSizeAndMorphology?.(
      item?.position,
      item.key,
      ToothStatusEnum.toothLoss,
    )

    _onDeleteBridgeTooth()
  }

  const _onChangeImplantPlacement = () => {
    if (!item) return

    onChangeStatusToothSizeAndMorphology?.(
      item?.position,
      item.key,
      ToothStatusEnum.implantPlacement,
    )

    _onDeleteBridgeTooth()
  }

  const _onChangeRemainingToothRoots = () => {
    if (!item) return

    onChangeStatusToothSizeAndMorphology?.(
      item?.position,
      item.key,
      ToothStatusEnum.remainingToothRoots,
    )

    _onDeleteBridgeTooth()
  }

  const _onChangeToothWithIndicationForExtraction = () => {
    if (!item) return

    onChangeStatusToothSizeAndMorphology?.(
      item?.position,
      item.key,
      ToothStatusEnum.toothWithIndicationForExtraction,
    )

    _onDeleteBridgeTooth()
  }

  const _onDeleteBridgeTooth = () => {
    if (isUpTooth) {
      const itemExist = bridgeToothTopTabCurrentStatus?.filter(
        itm => isEqual(itm?.indexEnd, index) || isEqual(itm?.indexStart, index),
      )

      if (itemExist?.length) {
        onDeleteBridgeTopTabCurrentStatus?.(itemExist?.map(itm => itm?.id ?? ''))
      }
    }

    if (!isUpTooth) {
      const itemExist = bridgeToothBottomTabCurrentStatus?.filter(
        itm => isEqual(itm?.indexEnd, index) || isEqual(itm?.indexStart, index),
      )

      if (itemExist?.length) {
        onDeleteBridgeBottomTabCurrentStatus?.(itemExist?.map(itm => itm?.id ?? ''))
      }
    }
  }

  const _onDeleteStatusTooth = () => {
    if (!item) return
    onChangeStatusToothSizeAndMorphology?.(item?.position, item.key, undefined)

    _onDeleteBridgeTooth()
  }

  const _onAddBridge = () => {
    if (!item) return
    if (isUpTooth) {
      onAddBridgeTopTabCurrentStatus?.(item?.key, index)
      return
    }

    onAddBridgeBottomTabCurrentStatus?.(item?.key, index)
  }

  const _onChangeBridge = () => {
    if (!item) return
    if (isUpTooth) {
      onChangeBridgeTopTabCurrentStatus?.(bridgeTopTabCurrentStatusSelecting?.id, item?.key, index)
      return
    }

    onChangeBridgeBottomTabCurrentStatus?.(bridgeBottomTabCurrentStatusSelecting?.id, item?.key, index)
  }

  return {
    _onDeleteStatusTooth,
    _onChangeImplantPlacement,
    _onChangeRemainingToothRoots,
    _onChangeTeethWithPathology,
    _onChangeToothLoss,
    _onChangeToothWithIndicationForExtraction,
    urlImage,
    iconDefine,
    opacityImage,
    isDisableAction,
    _onAddBridge,
    bridgeBottomTabCurrentStatusSelecting,
    bridgeTopTabCurrentStatusSelecting,
    _onChangeBridge,
    currentBridgeTooth
  }
}
