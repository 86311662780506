/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { isEmpty } from 'lodash'
import { PADDING } from 'parkway-web-common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import QRCode from 'qrcode.react';

const QRGenerate = () => {
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const [isShowQR, setIsShowQR] = useState(false)

  const onChange = (input: any) => {
    setValue(input?.target?.value ?? '')
  }

  const onSubmit = () => {
    setIsShowQR(true)
  }

  const onReset = () => {
    setIsShowQR(false)
    setValue('')
  }

  const downloadQRCode = () => {
    const getElementById = document?.getElementById('qrCodeEl') as any
    console.log({getElementById}, "type", typeof getElementById);
    
    if (getElementById) {
      const qrCodeURL = getElementById
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream')
      console.log(qrCodeURL)
      const aEl = document.createElement('a')
      aEl.href = qrCodeURL
      aEl.download = 'QR_Code.png'
      document.body.appendChild(aEl)
      aEl.click()
      document.body.removeChild(aEl)
    }
  }

  return (
    <RootWrapper>
      <BaseForm layout="vertical" requiredMark="optional">
        <SectionBase title={t(R.strings.qr_generate)}>
          <BaseSpaceWrapper direction="vertical" size={15}>
            {isShowQR && !isEmpty(value) ? (
              <BaseRow justify={'center'} align={'middle'}>
                <QRCode
                  id="qrCodeEl"
                  size={256}
                  style={{ height: 'auto', maxWidth: '300px', width: '300px' }}
                  value={value}
                  viewBox={`0 0 256 256`}
                />
              </BaseRow>
            ) : (
              <FormItem required label={t(R.strings.value_qr)}>
                <FormInput
                  placeholder={t(R.strings.input_link_you_want)}
                  onChange={onChange}
                />
              </FormItem>
            )}
            <BaseRow justify={'end'} align={'middle'}>
              {isShowQR ? (
                <BaseRow gutter={[10, 10]} align={'middle'}>
                  <BaseCol>
                    <BaseButton onClick={downloadQRCode}>
                      {t(R.strings.download)}
                    </BaseButton>
                  </BaseCol>
                  <BaseButton onClick={onReset}>
                    {t(R.strings.reset_QR)}
                  </BaseButton>
                </BaseRow>
              ) : (
                <BaseCol>
                  <BaseButton onClick={onSubmit} disabled={isEmpty(value)}>
                    {t(R.strings.generate)}
                  </BaseButton>
                </BaseCol>
              )}
            </BaseRow>
          </BaseSpaceWrapper>
        </SectionBase>
      </BaseForm>
    </RootWrapper>
  )
}

export default QRGenerate

export const RootWrapper = styled.div`
  padding: ${PADDING.md};
  width: 100%;
`

export const BaseSpaceWrapper = styled(BaseSpace)`
  width: 100%;
  padding-bottom: ${PADDING.md};
`

export const FormItem = styled(BaseForm.Item)``

export const FormInput = styled(BaseInput)``

export const SelectBaseStyled = styled(BaseSelect)``
