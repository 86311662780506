import { FormInstance } from 'antd'
import { IWarehouseGoodsIssue } from 'app/api/inventory-new/model/warehouse-goods-issue'
import { IItem } from 'app/api/item/model'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Dispatch, SetStateAction, createContext, useState } from 'react'

export interface IOrderItem extends IItem {
  quantity?: number
  price?: number
}
interface IGoodsIssueReceiptContext {
  orders?: IOrderItem[]
  setOrders?: Dispatch<SetStateAction<IItem[]>>
  goodsIssues?: IOrderItem[]
  setGoodsIssues?: Dispatch<SetStateAction<IItem[]>>
  form?: FormInstance
  discount?: number
  setDiscount?: Dispatch<SetStateAction<number>>
  warehouseGoodsIssueReceiptDetail?: IWarehouseGoodsIssue
  setGoodsIssueReceiptDetail?: Dispatch<SetStateAction<IItem>>
}
export const GoodsIssueReceiptContext =
  createContext<IGoodsIssueReceiptContext>({ orders: [] })

export const GoodsIssueReceiptProvider = ({ children }) => {
  const [orders, setOrders] = useState<IItem[]>([])
  const [goodsIssues, setGoodsIssues] = useState<IItem[]>([])
  const [warehouseGoodsIssueReceiptDetail, setGoodsIssueReceiptDetail] =
    useState<IItem>({})
  const [form] = BaseForm.useForm()
  const [discount, setDiscount] = useState(0)

  return (
    <GoodsIssueReceiptContext.Provider
      value={{
        orders,
        setOrders,
        form,
        discount,
        setDiscount,
        warehouseGoodsIssueReceiptDetail,
        setGoodsIssueReceiptDetail,
        setGoodsIssues,
        goodsIssues,
      }}
    >
      {children}
    </GoodsIssueReceiptContext.Provider>
  )
}
