import { BaseSpace } from 'app/components/common/BaseSpace'
import styled from 'styled-components'
import { FooterLayout, InfoVoucherLayout, SettingVoucherLayout } from './layouts'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useDetailVoucherContext } from './context'
import { PADDING } from 'parkway-web-common'

export const ContentLayout = () => {
  const { form, handleSubmit } = useDetailVoucherContext()
  return (
    <BaseForm form={form} onFinish={handleSubmit}>
      <RootWrapper>
        <InfoVoucherLayout />
        <SettingVoucherLayout />
        <FooterLayout />
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseSpace)`
  padding-bottom: ${PADDING.xxl};
`
