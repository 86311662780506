// Message code define
export const MeasurementUnitMessageCode = {
  MeasurementUnitManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20001',
  },
}

// Endpoint
export const MeasurementUnitEndPoint = {
  MeasurementUnitManagement: {
    request: 'treatment/v1/measurement-units',
  },
}
