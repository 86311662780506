import {
  IParamsGetTreatmentGroupManagement,
  IPayloadCreateTreatmentGroupManagement,
  IPayloadUpdateTreatmentGroupManagement,
} from './model/management'
import { TreatmentGroupEndPoint } from './constant'
import { ApiClientCRM } from 'app/service/api-service'
import { IParamsGetServices } from './model'

export const requestGetServices = (params: IParamsGetServices) =>
  ApiClientCRM.Get({
    url: TreatmentGroupEndPoint.services.requestGetData,
    params: { params },
  })

export const requestGetTreatmentGroupManagement = (
  params: IParamsGetTreatmentGroupManagement,
) =>
  ApiClientCRM.Get({
    url: TreatmentGroupEndPoint.services.request,
    params: { params },
  })

export const requestCreateTreatmentGroupManagement = (
  body: IPayloadCreateTreatmentGroupManagement,
) =>
  ApiClientCRM.Post({
    url: TreatmentGroupEndPoint.services.request,
    body,
  })

export const requestUpdateTreatmentGroupManagement = ({
  id,
  body,
}: {
  id?: number | string
  body?: IPayloadUpdateTreatmentGroupManagement
}) =>
  ApiClientCRM.Put({
    url: `${TreatmentGroupEndPoint.services.request}/${id}`,
    body,
  })

export const requestDetailTreatmentGroupManagement = (id?: string | number) =>
  ApiClientCRM.Get({
    url: `${TreatmentGroupEndPoint.services.request}/${id}`,
    params: {},
  })

export const requestDeleteTreatmentGroupManagement = (id?: string | number) =>
  ApiClientCRM.Delete({
    url: `${TreatmentGroupEndPoint.services.request}/${id}`,
    body: {},
  })

export const requestGetAllTreatmentGroupManagement = () =>
  ApiClientCRM.Get({
    url: `${TreatmentGroupEndPoint.services.request}/all`,
    params: {},
  })
