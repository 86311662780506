import { BaseCol } from 'app/components/common/BaseCol'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  colors,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseCol)`
  background-color: ${colors.white};
  padding: ${PADDING.xl};
  border-radius: ${BORDER_RADIUS};
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const WrapperSection = styled.div``

export const WrapperSectionFilter = styled(WrapperSection)`
  border-bottom: 1px dashed var(--neutral-black-color);
`

export const Title = styled.span`
  font-size: ${FONT_SIZE.md};
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.bold};
`
