/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd'
import { requestUploadFile } from 'app/api/file'
import { IResponseUploadFile } from 'app/api/file/model'
import { KeyImport } from 'app/api/import/constant'
import {
  CampaignReferralEnum,
  CampaignTypeEnum,
  IDiscountCampaign,
} from 'app/api/marketing/discount-campaign/model'
import {
  DiscountTypeEnum,
  EVerifyRuleKey,
  MethodIssueEnum,
  VerifyRuleConditionEnum,
} from 'app/api/marketing/discount-voucher/enum'
import {
  IPayloadCreateVoucher,
  ITargetUserFile,
  IUserDataVoucher,
  IVerifyRules,
} from 'app/api/marketing/discount-voucher/model'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { IRefModal } from 'app/components/common/ModalComponent'
import { useCreateDiscountVoucher } from 'app/react-query/hook/voucher'
import dayjs from 'dayjs'
import { isEqual } from 'lodash'
import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useUserTargetHook } from './hook/user-target'
import { IFormData, ITreatmentInTable } from './type'
import { useReferralTargetHook } from './hook/referral-target'

interface ICreateVoucherContext {
  form?: FormInstance<IFormData>
  listService?: ITreatmentInTable[]

  handleSubmit?: (values: IFormData) => void
  handleGoBack?: () => void
  onChangeService?: (service: ITreatmentInTable[]) => void
  onDeleteService?: (id?: string) => void
  onChangeMethodIssueVoucher?: (method: MethodIssueEnum) => void
  methodIssueVoucher?: MethodIssueEnum
  isLoading?: boolean
  campaignType?: CampaignTypeEnum
  itemCampaign?: IDiscountCampaign

  openImportArea?: boolean
  onCloseModal?: () => void
  onOpenModal?: () => void
  file?: File | null
  onRemoveFile?: () => void
  onConfirmModal?: (file?: File) => void
  onChangeFile?: (values: { file: any }) => void
  listUserData?: IUserDataVoucher[]
  listUserDataError?: string[]
  modalDisplayUserRef?: MutableRefObject<IRefModal>
  onChangeRuleKey?: (key: EVerifyRuleKey) => void
  verifyRuleKey?: EVerifyRuleKey

  listReferralTargetData?: IUserDataVoucher[]
  listReferralTargetDataError?: string[]
  openImportAreaReferral?: boolean
  fileReferralTargetData?: File | null
  onOpenModalReferral?: () => void
  onRemoveFileReferral?: () => void
  onConfirmModalReferral?: (fileReferralTargetData?: File) => Promise<void>
  modalDisplayReferralRef?: MutableRefObject<IRefModal>
  setListReferralTargetData?: Dispatch<SetStateAction<IUserDataVoucher[]>>
  setListReferralTargetDataError?: Dispatch<SetStateAction<string[]>>
  onCloseModalReferral?: () => void
}
export const CreateVoucherContext = createContext<ICreateVoucherContext>({})

export const CreateVoucherProvider = ({ children }) => {
  const navigate = useNavigate()
  const locationData = useLocation()
  const [form] = BaseForm.useForm<IFormData>()
  const [listService, setListService] = useState<ITreatmentInTable[]>([])
  const [methodIssueVoucher, setMethodIssueVoucher] = useState<
    MethodIssueEnum | undefined
  >(MethodIssueEnum.Auto)

  const itemCampaign: IDiscountCampaign = useMemo(() => {
    return locationData?.state?.item
  }, [locationData])

  const userTargetHook = useUserTargetHook({ form })

  const referralTargetHook = useReferralTargetHook({ form, itemCampaign })

  const [verifyRuleKey, setRuleVerifyKey] = useState<
    EVerifyRuleKey | undefined
  >(undefined)

  const onChangeService = (service: ITreatmentInTable[]) => {
    setListService(service)
  }

  const onDeleteService = (id?: string) => {
    const newListService = listService.filter(item => !isEqual(item?._id, id))
    setListService(newListService)
  }

  const onChangeMethodIssueVoucher = (method: MethodIssueEnum) => {
    setMethodIssueVoucher(method)
  }

  const handleGoBack = () => {
    navigate?.(-1)
  }

  const { mutateAsync: mutateAsyncCreate, isLoading } =
    useCreateDiscountVoucher()

  const handleUpload = async (file?: File) => {
    if (!file) return

    const formData = new FormData()
    formData.append(KeyImport.All.file, file)
    const res: IResponseUploadFile = await requestUploadFile(formData)

    return res
  }

  const handleSubmit = async (values: IFormData) => {
    if (!itemCampaign) return

    let userTargetFile: ITargetUserFile = {}

    if (
      isEqual(verifyRuleKey, EVerifyRuleKey.PatientId) &&
      userTargetHook?.file
    ) {
      const res = await handleUpload(userTargetHook?.file)

      userTargetFile = {
        name: userTargetHook?.file?.name,
        path: res?.data?.fileUrl,
      }
    }

    let referralTargetFile: ITargetUserFile = {}

    if (
      (isEqual(itemCampaign?.referralType, CampaignReferralEnum.Patient) ||
        isEqual(itemCampaign?.referralType, CampaignReferralEnum.Staff)) &&
      referralTargetHook?.fileReferralTargetData
    ) {
      const res = await handleUpload(referralTargetHook?.fileReferralTargetData)

      referralTargetFile = {
        name: referralTargetHook?.fileReferralTargetData?.name,
        path: res?.data?.fileUrl,
      }
    }

    let verifyRules: IVerifyRules[] = []

    if (values?.verifyRuleKey ?? verifyRuleKey) {
      verifyRules = verifyRules?.concat([
        {
          key: values?.verifyRuleKey ?? verifyRuleKey,
          value: true,
          condition: VerifyRuleConditionEnum.Equal,
        },
      ])
    }

    if (values?.numberOfUsesPerCustomer) {
      verifyRules = verifyRules.concat([
        {
          key: EVerifyRuleKey.MaxUsage,
          value: values?.numberOfUsesPerCustomer ?? 0,
          condition: VerifyRuleConditionEnum.Equal,
        },
      ])
    }

    const payload: IPayloadCreateVoucher = {
      campaignId: itemCampaign?._id,
      code: isEqual(values?.methodIssue, MethodIssueEnum.Fillable)
        ? values?.code
        : undefined,
      targetUser: values?.targetUser,
      startDate: dayjs(values?.startDate).toDate(),
      maxLengthCode: values?.prefixLength,
      maxUsage: isEqual(CampaignTypeEnum.Voucher, itemCampaign?.campaignType)
        ? 1
        : values?.numberOfUses,
      methodIssue: values?.methodIssue,
      quantity: values?.totalVoucher,
      prefixCode: isEqual(values?.methodIssue, MethodIssueEnum.Auto)
        ? values?.prefix
        : undefined,

      discountType: isEqual(
        itemCampaign?.campaignType,
        CampaignTypeEnum.Voucher,
      )
        ? DiscountTypeEnum.Amount
        : values?.discountType,
      amount: values?.discountValue,

      treatments: isEqual(itemCampaign?.campaignType, CampaignTypeEnum.Voucher)
        ? listService?.map(item => item?._id ?? '') ?? []
        : undefined,
      endDate: values?.endDate ? dayjs(values?.endDate).toDate() : undefined,
      name: values?.name,

      targetUserFile:
        isEqual(verifyRuleKey, EVerifyRuleKey.PatientId) && userTargetHook?.file
          ? userTargetFile
          : undefined,
      targetUserIds: isEqual(verifyRuleKey, EVerifyRuleKey.PatientId)
        ? userTargetHook?.listUserData?.map(item => item?.id ?? '') ?? []
        : [],

      verifyRules,
      listReferralBy:
        referralTargetHook?.listReferralTargetData?.map(
          item => item?.id ?? '',
        ) ?? [],
      referralFile: referralTargetHook?.fileReferralTargetData
        ? referralTargetFile
        : undefined,
    }

    await mutateAsyncCreate(payload)
  }

  const onChangeRuleKey = (key: EVerifyRuleKey) => {
    setRuleVerifyKey(key)
  }

  return (
    <CreateVoucherContext.Provider
      value={{
        form,
        handleSubmit,
        handleGoBack,
        onChangeService,
        onDeleteService,
        listService,
        methodIssueVoucher,
        onChangeMethodIssueVoucher,
        isLoading,
        campaignType: itemCampaign?.campaignType,
        itemCampaign,
        onChangeRuleKey,
        verifyRuleKey,
        ...userTargetHook,
        ...referralTargetHook,
      }}
    >
      {children}
    </CreateVoucherContext.Provider>
  )
}

export function useCreateVoucherContext() {
  const context = useContext(CreateVoucherContext)

  if (context === undefined) {
    throw new Error(
      'useCreateVoucher must be used within a CreateVoucherProvider',
    )
  }
  return context
}
