import { useCallback } from 'react'
import { useUpdateCampaignContext } from '../../context'
import { CampaignTypeEnum } from 'app/api/marketing/discount-campaign/model'
import { TreatmentTypeLayout } from './layouts/TreatmentTypeLayout'
import { BuyOneGetOneTypeLayout, TotalBillTypeLayout } from './layouts'
import { BundleTypeLayout } from './layouts/BundleTypeLayout'

export const ContentLayout = () => {
  const { campaignType } = useUpdateCampaignContext()

  const renderContent = useCallback(() => {
    switch (campaignType) {
      case CampaignTypeEnum.Treatments:
        return <TreatmentTypeLayout />
      case CampaignTypeEnum.Voucher:
        return null
      case CampaignTypeEnum.BuyOneGetOne:
        return <BuyOneGetOneTypeLayout />
      case CampaignTypeEnum.Bundle:
        return <BundleTypeLayout />
      case CampaignTypeEnum.TotalBill:
        return <TotalBillTypeLayout />
      default:
        return null
    }
  }, [campaignType])()

  return <>{renderContent}</>
}
