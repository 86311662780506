import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import BasePageWrapper from 'app/components/common/BasePageWrapper'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useTranslation } from 'react-i18next'
import { TreatmentPlanProvider } from './context'
import { DiagnosisLayout, TreatmentInformation } from './layouts'
import { useEffect } from 'react'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { FunctionPermissionEnum, ModulePermissionEnum } from 'app/common/permission-module'

const TreatmentPlanContainer = () => {
  const { t } = useTranslation()
  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.DASHBOARD,
      FunctionPermissionEnum.DASHBOARD_READ_DOCTOR,
      R.strings.create_treatment_plan,
    )
  }, [])

  return (
    <TreatmentPlanProvider>
      <BasePageWrapper>
        <BaseSpace>
          <BaseText
            children={t(R.strings.create_treatment_plan)}
            fontSize="xl"
            fontWeight="semibold"
          />
          <TreatmentInformation />

          <BaseRow gutter={[16, 16]} >
            <BaseCol xl={24}>
              <DiagnosisLayout />
            </BaseCol>
            {/* <BaseCol xl={4}>
              <SectionWrapper>
                <div className="child-wrapper">
                  <BaseText
                    children={t(R.strings.note)}
                    fontWeight="semibold"
                    fontSize="md"
                  />
                </div>
                <DividerLine />
              </SectionWrapper>
            </BaseCol> */}
          </BaseRow>
        </BaseSpace>
      </BasePageWrapper>
    </TreatmentPlanProvider>
  )
}

export default TreatmentPlanContainer

// const SectionWrapper = styled.div`
//   width: 100%;
//   background-color: ${convertedVariables.backgroundColor};
//   border-radius: ${BORDER_RADIUS};
//   .child-wrapper {
//     padding: ${PADDING.md};
//   }
// `
