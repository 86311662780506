import { WithChildrenProps } from 'parkway-web-common';
import { getToken } from 'app/service/storage/storage-service';
import React from 'react';
import { Navigate } from 'react-router-dom';

const NotAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token = getToken()

  return !token ? <>{children}</> : <Navigate to="/" replace />;
};

export default NotAuth;
