import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { useHookTable } from './hook'
import { BaseRow } from 'app/components/common/BaseRow'
import { FilterManagement } from './components/FilterManagementTable'
import Export from './components/Export'
import { InventoryProvider } from 'app/context/ProviderContext'
import { MaterialCategoryProvider } from 'app/context/MaterialCategoryContext'
import { UnitProvider } from 'app/context/UnitContext'
const MaterialExportContainer = () => {
  const {
    tableData,
    columns,
    handleTableChange,
    handleChangeFilter,
    onClickExport,
    filter
  } = useHookTable()

  return (
    <InventoryProvider>
      <MaterialCategoryProvider>
        <UnitProvider>
          <S.BaseManagementWrapper>
            <HeaderPage
              titleI18nKey={R.strings.material_export}
              rightComponent={
                <>
                  <Export onPressExport={onClickExport} />
                </>
              }
            />
            <S.RootWrapper>
              <BaseRow>
                <FilterManagement
                  handleChangeFilter={handleChangeFilter}
                  filterData={filter}
                />
              </BaseRow>
              <BaseTableManagement
                columns={columns}
                dataSource={tableData.data}
                pagination={tableData.pagination}
                loading={tableData.loading}
                onChange={handleTableChange}
                expandable={{
                  expandIcon: () => null,
                }}
              />
            </S.RootWrapper>
          </S.BaseManagementWrapper>
        </UnitProvider>
      </MaterialCategoryProvider>
    </InventoryProvider>
  )
}
export default MaterialExportContainer
