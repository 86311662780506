import React from 'react'
import { NotificationsDropdown } from '../components/NotificationsDropdown'
import { ProfileDropdown } from '../components/profile/ProfileDropdown'
import { HeaderSearch } from '../components/HeaderSearch'
import { SettingsDropdown } from '../components/SettingsDropdown'
import * as S from '../styles'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'

interface MobileHeaderProps {
  toggleSlider: () => void
  isSliderOpened: boolean
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({
  toggleSlider,
  isSliderOpened,
}) => {
  return (
    <BaseRow justify="space-between" align="middle">
      <BaseCol>
        <ProfileDropdown />
      </BaseCol>

      <BaseCol>
        <BaseRow align="middle">
          <BaseCol>
            <NotificationsDropdown />
          </BaseCol>

          <BaseCol>
            <HeaderSearch />
          </BaseCol>

          <BaseCol>
            <SettingsDropdown />
          </BaseCol>
        </BaseRow>
      </BaseCol>

      <S.BurgerCol>
        <S.MobileBurger onClick={toggleSlider} isCross={isSliderOpened} />
      </S.BurgerCol>
    </BaseRow>
  )
}
