import React, { useState } from 'react'
import { BaseForm } from '../BaseForm'
import { Rule } from 'antd/es/form'
import R from 'app/assets/R'
import { t } from 'i18next'
import { RemoveCircleGreyIcon, UploadIcon } from 'app/common/icons'
import * as S from 'app/components/common/forms/BaseUploadDragger/styles'
import { UploadListType } from 'antd/es/upload/interface'
import { UploadFile } from 'antd/lib'
import { DRIVE_ACTION } from 'app/common/config'

interface UploadDraggerProps {
  name: string
  label: string
  listType?: UploadListType
  rules?: Rule[]
}

const getBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file as Blob)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })

const BaseUploadDragger: React.FC<UploadDraggerProps> = ({
  name,
  label,
  listType = 'picture',
  rules,
}) => {
  const [file, setFile] = useState<UploadFile[]>([])

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as Blob)
    }
  }

  const normFile = (e: { fileList: UploadFile[] }) => {
    if (Array.isArray(e)) {
      return e
    }

    setFile(e.fileList || file)
    return e && e.fileList
  }

  return (
    <BaseForm.Item
      name={name}
      label={label}
      valuePropName="file"
      getValueFromEvent={normFile}
      rules={rules}
      required
    >
      <S.DraggerStyled
        action={DRIVE_ACTION.UPLOAD_IMAGE}
        listType={listType}
        maxCount={1}
        progress={{ strokeWidth: 6, showInfo: true }}
        onPreview={handlePreview}
        showUploadList={{ removeIcon: <RemoveCircleGreyIcon /> }}
      >
        <S.UploadWrapper>
          <UploadIcon />
          <S.TitleUpload>{t(R.strings.upload)}</S.TitleUpload>
        </S.UploadWrapper>
        <p className="ant-upload-hint">
          {t(R.strings.degree_content_placeholder_upload_document)}
        </p>
      </S.DraggerStyled>
    </BaseForm.Item>
  )
}

export default BaseUploadDragger
