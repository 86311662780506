import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { PADDING } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const DifficultyPackage = ({ level }: { level: number }) => {
  const { t } = useTranslation()

  const background = useMemo(() => {
    switch (level) {
      case 1:
        return 'linear-gradient(90deg, #629DAA, #6DAEBD)'
      case 2:
        return 'linear-gradient(90deg, #FD7B59, #F75428)'
      case 3:
        return 'linear-gradient(90deg, #FFAE49, #FF7617)'
      default:
        return 'linear-gradient(90deg, #629DAA, #6DAEBD)'
    }
  }, [[level]])

  return (
    <RootWrapper $backgroundColor={background}>
      <BaseText
        children={t(R.strings.difficulty_number, { level })}
        colorText="backgroundColor"
        fontWeight='semibold'
      />
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseRow)<{
  $backgroundColor: string
  $border?: string
}>`
  background-image: ${props => props?.$backgroundColor};
  padding: 5px ${PADDING.xxxs};
  border-radius: ${PADDING.xxl};
  margin: unset !important;
  width: fit-content;
`
