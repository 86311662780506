import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { useEffect } from 'react'
import { useTableWeeklyHook } from './hook-custom'

export const useTableHook = () => {
  const hookWeekly = useTableWeeklyHook({})

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.ACCOUNTING,
      FunctionPermissionEnum.ITEM_READ,
      R.strings.role,
    )
  }, [])

  return {
    columns: hookWeekly.columns,
    tableData: hookWeekly.tableData,
    handleTableChange: hookWeekly.handleTableChange,
    handleExpand: hookWeekly.handleExpand,
    expandedRowKeys: hookWeekly.expandedRowKeys,
    handleChangeFilter: hookWeekly.handleChangeFilter,
    exportExcel: hookWeekly.exportExcel,
  }
}
